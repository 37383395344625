// export default App;
import React, { useState } from "react";
import "./App.css";
import OpenLayersMap from "./OpenLayersMap.js"; // Asegúrate de tener la ruta correcta al componente

// import exampleGeojsons,  {exampleGeojsons2} from "./exampleData/exampleGeojsonValladolid";
// import exampleGeojsonWithdrawalAutonomies from "./exampleData/exampleGeojsonWithdrawalAutonomies"
import data from "./exampleData/amianto_censo_calendario_Londres.geojson_con_solar.js"
// import exampleGeojsonSolar from "./exampleData/exampleGeojsonSolar"
// //import exampleGeojsonWithdrawalProvinces from "./exampleGeojsonWithdrawalProvinces"
// import exampleGeojsonWithdrawalMunicipe from "./exampleData/exampleGeojsonWithdrawalMunicipe"
//import exampleGeojsonsValencia from "./exampleData/exampleGeojsonValenciaCentroids";
//import exampleGeojsonsValencia from "./exampleData/exampleGeojsonValenciaCentroidsGeojson.geojson";
//import exampleGeojsonsValencia from "./exampleData/exampleGeojsonValencia";
//import exampleValladolid from "./exampleData/exampleGeojsonValladolid.js"
import "./test.css"
// import { Button } from "@mui/material";
//import exampleGeojsons from "./exampleGeojson_malaga.json"
function App() {
	const sampleGeojsons = JSON.stringify([data])//JSON.stringify(exampleGeojsonsValencia);
	//const sampleGeojsons2 = JSON.stringify(exampleGeojsonWithdrawalProvinces);
  //const [geojsonUsed, setGeojsonUsed] = useState(1);
  return  <div style={{width:"100vw", height:"100vh", overflow:"hidden"}}>
  <OpenLayersMap
    geojsons ={sampleGeojsons}
    style = {{ width: "100%", height: "100%", display: "flex" }}
    defaultSelectedLayer = "solar_heatmap"
    //layers_data = {layerStyle}
    //getBgColorFromFeature = {(feature)=>{return  "116, 86, 171"}}
    mustShowOnClickFeature = {true}
    isDrawEnabled = {false}
    isDrawEnabledOnStartUp = {false}
    //assetsBasePath = {false}
    isAdressSearchBarEnabled = {true}
    isGetCoordinatesOnClickEnabled = {false}
    isGeolocationEnabled = {false}
    layersSelected ={"solar"}
    base_maps_aviables = {["arcgis", "osm"]}
    base_maps_images={["map_satellite.jpg", "map_street.jpg"]}
  /></div>
}



export default App;
