import LayerClass, { legendBoxes, hexToRgb, tooltipBeginning } from "./LayerCommons";

const legend = () =>false;


const background = (feature) => {
 
  return false;
};

const modal = false;
const tooltip =false;

export const LAYER_TRANSPARENT = new LayerClass(
  "Transparente",
  legend,
  background,
  tooltip,
  modal
);
