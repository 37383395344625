import { Cluster, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Point } from 'ol/geom';
import { getCenter } from "ol/extent";
import Vector from "ol/layer/Vector"
import { Fill, Stroke, Text, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";

import { getFeatureType } from "../../commons/geoSpatialFunctions"
import { isMobile } from 'react-device-detect';

const CLUSTER_DISTANCE = 10000000
const CLUSTER_MIN_DISTANCE = 1000000
const DEFAULT_STROKE_COLOR = "#000000";
const DEFAULT_STROKE_WIDTH = 3;
const DEFAULT_FILL_COLOR = '#3399CC';
const DEFAULT_FILL_OPACITY = 0.8;
const BREAKPOINT_ZOOM =0; // mayor valor es más zoom
const STROKE_MULTIPLY = 6;
const CLUSTER_CIRCLE_SIZE = 24;
const STROKE_COLOR = "white";
const STROKE_HOVER_COLOR = "#00ff00";
const STROKE_HOVER_WIDTH = 0.5;
const HOVER_FILL_COLOR = "#44aa44"
var highlightedFeature = null;

export { BREAKPOINT_ZOOM }
export function updateLayerStyle(map, vectorLayer, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, breakpoint_zoom = BREAKPOINT_ZOOM, cluster_distance = CLUSTER_DISTANCE, cluster_min_distance = CLUSTER_MIN_DISTANCE) {
    // let numberOfFeatures = 0;
    // if (vectorLayer && vectorLayer != [] && vectorLayer != false && vectorLayer.length > 0 && map != null && map != undefined) {
    //     const zoom = map?.getView().getZoom() ?? 20;
    //     numberOfFeatures = getNumberOfVisibleFeatures(map);
    //     const currentClusterSource = vectorLayer[0].getSource();

    //     if (zoom < breakpoint_zoom && currentClusterSource instanceof Cluster) {
    //         // Asegurarse de que el source actual es un ClusterSource
    //         vectorLayer[0].setSource(createClusterSource(currentClusterSource.getSource(), cluster_distance, cluster_min_distance))
    //     } else if (currentClusterSource instanceof Cluster) {
    //         vectorLayer[0].setSource(createClusterSource(currentClusterSource.getSource(), 0, 0));
    //     }
    // }

    // vectorLayer[0]?.setStyle(feature => {
    //     return styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)

    // });
}

// Función para crear la fuente del clúster
function createClusterSource(source, distance = 100, minDistance = 5) {
    return source;
    return new Cluster({
        distance: distance,
        minDistance: minDistance,
        source: source,
        geometryFunction: feature => {
            try {
                return new Point(getCenter(feature.getGeometry().getExtent()))
            } catch {
                return feature.getGeometry();
            }
        }
    });
}
// Función principal para obtener la capa vectorial
export function getVectorLayerFromSources(vectorLayers, vectorSources, allPoints, strokeColor, strokeWidth, fillColor, fillOpacity, map, getBgColorFromFeature) {

    const source = new VectorSource({ features: allPoints });

    const clusterSource = createClusterSource(source);
    let numberOfFeatures = 0;
    if (map != null && map != undefined) {
        numberOfFeatures = getNumberOfVisibleFeatures(map);
    }
    const clusters = new VectorLayer({
        source: clusterSource,
        style: (feature) => styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures)
    });

    return [clusters];
}
function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
          result[3],
          16
        )}`
      : null;
  }
export function handleMapPointerMove(map, e, layer, tooltip, tooltipRef, strokeColor, strokeHoverColor, strokeWidth, fillColor, fillPolygonsOpacity, fillPointsOpacity, getBgColorFromFeature = false, isGetCoordinatesOnClickEnabled = false) {
    const view = map.getView();
    const currentZoom = view.getZoom();
    if (currentZoom < layer.breakpoint_zoom || isGetCoordinatesOnClickEnabled) {
        return true;
    }
    const pixel = map.getEventPixel(e.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel);
    map.getTargetElement().style.cursor = hit ? "pointer" : "";
    if (highlightedFeature) {
        const geometryType = getFeatureType(highlightedFeature);
        let style;
        if (geometryType != "Point") {
            style = layer.createGeometryStyle(strokeColor, strokeWidth, fillColor, fillPolygonsOpacity, highlightedFeature, getBgColorFromFeature, false);
        } else {
            //const features =  highlightedFeature.get('features');
            //const size = features.length;
            //style = createClusterStyle(feature, size, strokeColor, strokeWidth, fillColor, fillPointsOpacity, getBgColorFromFeature);

        }
        highlightedFeature.setStyle(style);
        highlightedFeature = null;
    }
    const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
    });
    if (getBgColorFromFeature != false) {
        const geometryType = getFeatureType(feature);
        if (geometryType != "Point") {
            const highlightStyle = layer.createGeometryStyle(STROKE_HOVER_COLOR, STROKE_HOVER_WIDTH, HOVER_FILL_COLOR, 1, feature, ()=>hexToRgb(HOVER_FILL_COLOR), true);
            if (feature) {
                feature.setStyle(highlightStyle);
                highlightedFeature = feature;
            }
        }

    }


    // Tooltip info disabled
    const TOOLTIP_DEFAULT_TEXT = "No hay datos para esta geometría";
    const tooltipIsEnabled = true;
    if (feature && tooltipIsEnabled) {
        let type = getFeatureType(feature);
        //if(type == "Point"){
        let tooltipText = feature.get("tooltip");
        if (layer.getTooltip != false) {
            tooltipText = layer.getTooltip(feature);
        }
        // Recupera el tooltip asociado a la feature
        if (!tooltipText) {
            tooltipText = TOOLTIP_DEFAULT_TEXT;
        }
        tooltipRef.current.innerHTML = tooltipText;
        tooltip.setPosition(e.coordinate);
        // }

    } else {
        tooltipRef.current.innerHTML = "";
        tooltip.setPosition(undefined);
    }
}
function getNumberOfVisibleFeatures(map) {
    let count = 0;
    const extent = map.getView().calculateExtent(map.getSize());
    if (map != undefined && map.getLayers() != undefined) {
        const layers = map.getLayers().getArray();
        if (layers.length > 1) {
            const vectorLayer = layers[1]; // Suponiendo que tu VectorLayer está en esta posición
            if (vectorLayer instanceof Vector) {
                const vectorSource = vectorLayer.getSource();
                if (vectorSource.getFeatures().length > 0) {
                    // Tu lógica aquí
                    vectorSource.forEachFeature((feature) => {
                        if (feature.getGeometry().intersectsExtent(extent)) {
                            count++;
                        }
                    });
                }
            }
        }
        if (layers.length > 2) {
            const vectorLayer = layers[2]; // Suponiendo que tu VectorLayer está en esta posición
            if (vectorLayer instanceof Vector) {
                const vectorSource = vectorLayer.getSource();
                if (vectorSource.getFeatures().length > 0) {
                    // Tu lógica aquí
                    vectorSource.forEachFeature((feature) => {
                        if (feature.getGeometry().intersectsExtent(extent)) {
                            count++;
                        }
                    });
                }
            }
        }
    }
    return count;
}

// Función de estilo
function styleFunction(feature, map, strokeColor, strokeWidth, fillColor, fillOpacity, getBgColorFromFeature, numberOfFeatures) {
    //console.log("feature", feature)


    const zoom = map?.getView().getZoom() ?? 20;
    let breakpointZoomOut = zoom <= BREAKPOINT_ZOOM;
    let breakpointNumberOfFeatures = numberOfFeatures <= 1500
    const geometryType = getFeatureType(feature);
    // console.log("Breakpoint", geometryType, breakpointZoomOut, feature)

        return createGeometryStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature);
    
}


// Estilo para geometrías (zoom < 10)
export function createGeometryStyle(strokeColor, strokeWidth, fillColor, fillOpacity, feature, getBgColorFromFeature, isHigtlighted = false) {
    //console.log("Aquí")
    try {
        if (!feature) {
            return
        }
        const bgColor = getBgColorFromFeature(feature);
        // console.log("getBgColorFromFeature", bgColor, `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})`);
        let backgroundColor = bgColor ? `rgba(${bgColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : fillColor ? `rgba(${fillColor}, ${fillOpacity || DEFAULT_FILL_OPACITY})` : DEFAULT_FILL_COLOR;
        // console.log("backgroundColor2", backgroundColor);
        //if(feature.getGeometry())
        let style = new Style({
            stroke: new Stroke({
                color: strokeColor || DEFAULT_STROKE_COLOR,
                width: isHigtlighted ? (strokeWidth * STROKE_MULTIPLY || DEFAULT_STROKE_WIDTH * STROKE_MULTIPLY) : strokeWidth || DEFAULT_STROKE_WIDTH
            }),
            fill: new Fill({
                color: backgroundColor
            })
        });
        style.setGeometry(feature.getGeometry());
        return style;
    } catch (e) {
        console.error("Problema con los estilos")
        return
    }

}

export function getBgColorFromFeatureGeneric(allPolygons, index, getBgColorFromFeature) {
    let bgColor = false;
    let feature = allPolygons[index].values_
    bgColor = getBgColorFromFeature(feature)
    return bgColor;
}