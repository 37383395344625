import React, { useEffect, useState, useContext } from "react";
import { Modal } from "antd";
import {
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import "./GenericInfoMapModal.css";
import { Loading } from "notiflix";

const GenericInfoMapModal = ({
  buttonAction,
  ModalButton,
  InitialAction = () => {},
  EndAction = ()=>{},
  Title,
  BodyComponent,
  ConfirmText = "Confirm",
  CancelText = "Cancel",
  isModalOpen, 
  setIsModalOpen,
  fullScreenContainerRef,
  isFullScreen
}) => {
  const [body, setBody] = useState(BodyComponent);
  useEffect(()=>{
    //console.log("Body component change inside modal", BodyComponent)
    setBody(BodyComponent)
  }, [BodyComponent])
  return (
    <>
      {/* <Button onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
          }} className='management-control-button'><AddCircleRounded style={buttonStyle}/>{buttonText}</Button> */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsModalOpen(true);
          InitialAction();
        }}
      >
        {ModalButton}
      </div>
      <Modal
        width="auto"
        height="80vh"
        style={{minHeight:"50vh",top:"50px !important", maxWidth:"1400px"}}
        open={isModalOpen}
        getContainer={() => isFullScreen? fullScreenContainerRef.current : false}

        onCancel={(e) => {
          e.stopPropagation();
          setIsModalOpen(false);
          EndAction();
        }}
        footer={[
          <div style={{ display: "flex", placeContent: "space-around" }}>
            {/* <div style={{display:"flex", placeContent:"space-arround"}}>  */}
            {CancelText && (
              <Button
                class="new-user-modal-cancelButton"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen(false);
                  EndAction();
                }}
              >
                {CancelText}
              </Button>
            )}
            <Button
              class="new-user-modal-saveButton"
              onClick={(e) => {
                e.stopPropagation();
                buttonAction();
                setIsModalOpen(false);
                EndAction();
              }}
            >
              {ConfirmText}
            </Button>
            {/* </div> */}
          </div>,
        ]}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p>
            <b style={{ color: "rgba(var(--color-primary))", fontSize: "18px" }}>
              {Title}
            </b>
          </p>
          <hr class="solid" style={{borderColor:"rgb(var(--color-primary))"}} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            {typeof body == "string" ?  <div dangerouslySetInnerHTML={{ __html: body }} /> : body }
             {/* {BodyComponent}  */}
            {/* <div dangerouslySetInnerHTML={{ __html: BodyComponent }} />  */}

          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenericInfoMapModal;
