
import LayerClass, { legendBoxes, hexToRgb } from "./LayerCommons";
import { renderToString } from 'react-dom/server'

import {fullModalInfo,updateLayerStyle,getVectorLayerFromSources, handleMapPointerMove,createGeometryStyle,BREAKPOINT_ZOOM } from "../Styles/StyleRegions"
const legend=() => false;
const background=(feature) => {
    let defaultColor = hexToRgb("#0000FF");
    return defaultColor;
  };

const tooltip = (feature)=>{
  //console.log("tooltip", feature)
    if (
      "values_" in feature &&
      "tooltip" in feature["values_"]
      // "features" in feature.values_ &&
      // feature.values_.features.length >= 1 &&
      // "tooltip" in feature.values_.features[0].values_
    ) {
      return renderToString(<label>{feature.values_["tooltip"]}</label>);
    }else{
      //console.log("Nombre no encontrado en ", feature)
    }
  };



export const LAYER_REGIONS = new LayerClass(
    "Líimites municipales",
    legend,
    background,
    tooltip,
    ()=>false,
    updateLayerStyle,
    getVectorLayerFromSources,
    createGeometryStyle,
    BREAKPOINT_ZOOM ,
    handleMapPointerMove
  );
//LAYER_REGIONS.breakpoint_zoom = 0;
//export default LAYER_REGIONS;