const regionsLimits = {
    "10001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.008086693491471,
              40.28193121297284
            ]
          },
          "bbox": [
            -6.008086693491471,
            40.28193121297284,
            -6.008086693491471,
            40.28193121297284
          ]
        }
      ],
      "bbox": [
        -6.008086693491471,
        40.28193121297284,
        -6.008086693491471,
        40.28193121297284
      ]
    },
    "10002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.806568543797346,
              39.25511343169368
            ]
          },
          "bbox": [
            -5.806568543797346,
            39.25511343169368,
            -5.806568543797346,
            39.25511343169368
          ]
        }
      ],
      "bbox": [
        -5.806568543797346,
        39.25511343169368,
        -5.806568543797346,
        39.25511343169368
      ]
    },
    "10003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.720056706616659,
              40.216915830556
            ]
          },
          "bbox": [
            -6.720056706616659,
            40.216915830556,
            -6.720056706616659,
            40.216915830556
          ]
        }
      ],
      "bbox": [
        -6.720056706616659,
        40.216915830556,
        -6.720056706616659,
        40.216915830556
      ]
    },
    "10004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6444930543515275,
              39.79658617177553
            ]
          },
          "bbox": [
            -6.6444930543515275,
            39.79658617177553,
            -6.6444930543515275,
            39.79658617177553
          ]
        }
      ],
      "bbox": [
        -6.6444930543515275,
        39.79658617177553,
        -6.6444930543515275,
        39.79658617177553
      ]
    },
    "10005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.307585051013729,
              40.13341581011643
            ]
          },
          "bbox": [
            -6.307585051013729,
            40.13341581011643,
            -6.307585051013729,
            40.13341581011643
          ]
        }
      ],
      "bbox": [
        -6.307585051013729,
        40.13341581011643,
        -6.307585051013729,
        40.13341581011643
      ]
    },
    "10006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.200787829398544,
              40.16936127676541
            ]
          },
          "bbox": [
            -6.200787829398544,
            40.16936127676541,
            -6.200787829398544,
            40.16936127676541
          ]
        }
      ],
      "bbox": [
        -6.200787829398544,
        40.16936127676541,
        -6.200787829398544,
        40.16936127676541
      ]
    },
    "10007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2107455437710515,
              39.26325457268168
            ]
          },
          "bbox": [
            -6.2107455437710515,
            39.26325457268168,
            -6.2107455437710515,
            39.26325457268168
          ]
        }
      ],
      "bbox": [
        -6.2107455437710515,
        39.26325457268168,
        -6.2107455437710515,
        39.26325457268168
      ]
    },
    "10008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.8890168540519,
              39.69294111545314
            ]
          },
          "bbox": [
            -6.8890168540519,
            39.69294111545314,
            -6.8890168540519,
            39.69294111545314
          ]
        }
      ],
      "bbox": [
        -6.8890168540519,
        39.69294111545314,
        -6.8890168540519,
        39.69294111545314
      ]
    },
    "10009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.718159788813023,
              39.214176249984384
            ]
          },
          "bbox": [
            -5.718159788813023,
            39.214176249984384,
            -5.718159788813023,
            39.214176249984384
          ]
        }
      ],
      "bbox": [
        -5.718159788813023,
        39.214176249984384,
        -5.718159788813023,
        39.214176249984384
      ]
    },
    "10010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.235745359352424,
              39.16457415802095
            ]
          },
          "bbox": [
            -6.235745359352424,
            39.16457415802095,
            -6.235745359352424,
            39.16457415802095
          ]
        }
      ],
      "bbox": [
        -6.235745359352424,
        39.16457415802095,
        -6.235745359352424,
        39.16457415802095
      ]
    },
    "10011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.638004963808551,
              39.530573390237606
            ]
          },
          "bbox": [
            -5.638004963808551,
            39.530573390237606,
            -5.638004963808551,
            39.530573390237606
          ]
        }
      ],
      "bbox": [
        -5.638004963808551,
        39.530573390237606,
        -5.638004963808551,
        39.530573390237606
      ]
    },
    "10012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.308709409859558,
              39.287777144577724
            ]
          },
          "bbox": [
            -6.308709409859558,
            39.287777144577724,
            -6.308709409859558,
            39.287777144577724
          ]
        }
      ],
      "bbox": [
        -6.308709409859558,
        39.287777144577724,
        -6.308709409859558,
        39.287777144577724
      ]
    },
    "10013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.921965756406648,
              39.56977592175975
            ]
          },
          "bbox": [
            -5.921965756406648,
            39.56977592175975,
            -5.921965756406648,
            39.56977592175975
          ]
        }
      ],
      "bbox": [
        -5.921965756406648,
        39.56977592175975,
        -5.921965756406648,
        39.56977592175975
      ]
    },
    "10014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.710758987267019,
              40.141153234374734
            ]
          },
          "bbox": [
            -5.710758987267019,
            40.141153234374734,
            -5.710758987267019,
            40.141153234374734
          ]
        }
      ],
      "bbox": [
        -5.710758987267019,
        40.141153234374734,
        -5.710758987267019,
        40.141153234374734
      ]
    },
    "10015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.937585758533434,
              40.26282443324037
            ]
          },
          "bbox": [
            -5.937585758533434,
            40.26282443324037,
            -5.937585758533434,
            40.26282443324037
          ]
        }
      ],
      "bbox": [
        -5.937585758533434,
        40.26282443324037,
        -5.937585758533434,
        40.26282443324037
      ]
    },
    "10016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.239668293575002,
              40.01518415770097
            ]
          },
          "bbox": [
            -6.239668293575002,
            40.01518415770097,
            -6.239668293575002,
            40.01518415770097
          ]
        }
      ],
      "bbox": [
        -6.239668293575002,
        40.01518415770097,
        -6.239668293575002,
        40.01518415770097
      ]
    },
    "10017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.186075236405672,
              39.43840058842943
            ]
          },
          "bbox": [
            -5.186075236405672,
            39.43840058842943,
            -5.186075236405672,
            39.43840058842943
          ]
        }
      ],
      "bbox": [
        -5.186075236405672,
        39.43840058842943,
        -5.186075236405672,
        39.43840058842943
      ]
    },
    "10018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.69766370420451,
              39.40459279359486
            ]
          },
          "bbox": [
            -6.69766370420451,
            39.40459279359486,
            -6.69766370420451,
            39.40459279359486
          ]
        }
      ],
      "bbox": [
        -6.69766370420451,
        39.40459279359486,
        -6.69766370420451,
        39.40459279359486
      ]
    },
    "10019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.666226250364568,
              39.80088427783283
            ]
          },
          "bbox": [
            -5.666226250364568,
            39.80088427783283,
            -5.666226250364568,
            39.80088427783283
          ]
        }
      ],
      "bbox": [
        -5.666226250364568,
        39.80088427783283,
        -5.666226250364568,
        39.80088427783283
      ]
    },
    "10020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.058821954924546,
              39.1413707676975
            ]
          },
          "bbox": [
            -6.058821954924546,
            39.1413707676975,
            -6.058821954924546,
            39.1413707676975
          ]
        }
      ],
      "bbox": [
        -6.058821954924546,
        39.1413707676975,
        -6.058821954924546,
        39.1413707676975
      ]
    },
    "10021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.582979988810811,
              39.519600137700415
            ]
          },
          "bbox": [
            -6.582979988810811,
            39.519600137700415,
            -6.582979988810811,
            39.519600137700415
          ]
        }
      ],
      "bbox": [
        -6.582979988810811,
        39.519600137700415,
        -6.582979988810811,
        39.519600137700415
      ]
    },
    "10022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.871746871161648,
              40.04970026531393
            ]
          },
          "bbox": [
            -5.871746871161648,
            40.04970026531393,
            -5.871746871161648,
            40.04970026531393
          ]
        }
      ],
      "bbox": [
        -5.871746871161648,
        40.04970026531393,
        -5.871746871161648,
        40.04970026531393
      ]
    },
    "10023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.145951786309318,
              39.132703022311624
            ]
          },
          "bbox": [
            -6.145951786309318,
            39.132703022311624,
            -6.145951786309318,
            39.132703022311624
          ]
        }
      ],
      "bbox": [
        -6.145951786309318,
        39.132703022311624,
        -6.145951786309318,
        39.132703022311624
      ]
    },
    "10024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.87767760401062,
              40.30465500756672
            ]
          },
          "bbox": [
            -5.87767760401062,
            40.30465500756672,
            -5.87767760401062,
            40.30465500756672
          ]
        }
      ],
      "bbox": [
        -5.87767760401062,
        40.30465500756672,
        -5.87767760401062,
        40.30465500756672
      ]
    },
    "10025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.89292609973857,
              40.08003523531546
            ]
          },
          "bbox": [
            -5.89292609973857,
            40.08003523531546,
            -5.89292609973857,
            40.08003523531546
          ]
        }
      ],
      "bbox": [
        -5.89292609973857,
        40.08003523531546,
        -5.89292609973857,
        40.08003523531546
      ]
    },
    "10026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.610351728132485,
              39.81825459542564
            ]
          },
          "bbox": [
            -5.610351728132485,
            39.81825459542564,
            -5.610351728132485,
            39.81825459542564
          ]
        }
      ],
      "bbox": [
        -5.610351728132485,
        39.81825459542564,
        -5.610351728132485,
        39.81825459542564
      ]
    },
    "10027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.088378343918223,
              39.308466248552335
            ]
          },
          "bbox": [
            -6.088378343918223,
            39.308466248552335,
            -6.088378343918223,
            39.308466248552335
          ]
        }
      ],
      "bbox": [
        -6.088378343918223,
        39.308466248552335,
        -6.088378343918223,
        39.308466248552335
      ]
    },
    "10028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.353587910478325,
              39.8242719302341
            ]
          },
          "bbox": [
            -5.353587910478325,
            39.8242719302341,
            -5.353587910478325,
            39.8242719302341
          ]
        }
      ],
      "bbox": [
        -5.353587910478325,
        39.8242719302341,
        -5.353587910478325,
        39.8242719302341
      ]
    },
    "10029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.508266643025412,
              39.44965821102505
            ]
          },
          "bbox": [
            -5.508266643025412,
            39.44965821102505,
            -5.508266643025412,
            39.44965821102505
          ]
        }
      ],
      "bbox": [
        -5.508266643025412,
        39.44965821102505,
        -5.508266643025412,
        39.44965821102505
      ]
    },
    "10030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.455990520193495,
              39.788149071886124
            ]
          },
          "bbox": [
            -5.455990520193495,
            39.788149071886124,
            -5.455990520193495,
            39.788149071886124
          ]
        }
      ],
      "bbox": [
        -5.455990520193495,
        39.788149071886124,
        -5.455990520193495,
        39.788149071886124
      ]
    },
    "10031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.085394987243415,
              39.34220138762789
            ]
          },
          "bbox": [
            -6.085394987243415,
            39.34220138762789,
            -6.085394987243415,
            39.34220138762789
          ]
        }
      ],
      "bbox": [
        -6.085394987243415,
        39.34220138762789,
        -6.085394987243415,
        39.34220138762789
      ]
    },
    "10032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.761266033999466,
              39.55398314377287
            ]
          },
          "bbox": [
            -6.761266033999466,
            39.55398314377287,
            -6.761266033999466,
            39.55398314377287
          ]
        }
      ],
      "bbox": [
        -6.761266033999466,
        39.55398314377287,
        -6.761266033999466,
        39.55398314377287
      ]
    },
    "10033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.500030462116395,
              39.54506293411647
            ]
          },
          "bbox": [
            -5.500030462116395,
            39.54506293411647,
            -5.500030462116395,
            39.54506293411647
          ]
        }
      ],
      "bbox": [
        -5.500030462116395,
        39.54506293411647,
        -5.500030462116395,
        39.54506293411647
      ]
    },
    "10034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.997960761600736,
              40.125050484809535
            ]
          },
          "bbox": [
            -5.997960761600736,
            40.125050484809535,
            -5.997960761600736,
            40.125050484809535
          ]
        }
      ],
      "bbox": [
        -5.997960761600736,
        40.125050484809535,
        -5.997960761600736,
        40.125050484809535
      ]
    },
    "10035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8059925393406635,
              40.203183780599154
            ]
          },
          "bbox": [
            -5.8059925393406635,
            40.203183780599154,
            -5.8059925393406635,
            40.203183780599154
          ]
        }
      ],
      "bbox": [
        -5.8059925393406635,
        40.203183780599154,
        -5.8059925393406635,
        40.203183780599154
      ]
    },
    "10036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.890033319193015,
              40.11718766419189
            ]
          },
          "bbox": [
            -5.890033319193015,
            40.11718766419189,
            -5.890033319193015,
            40.11718766419189
          ]
        }
      ],
      "bbox": [
        -5.890033319193015,
        40.11718766419189,
        -5.890033319193015,
        40.11718766419189
      ]
    },
    "10037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.44921851270616,
              39.37060600463149
            ]
          },
          "bbox": [
            -6.44921851270616,
            39.37060600463149,
            -6.44921851270616,
            39.37060600463149
          ]
        }
      ],
      "bbox": [
        -6.44921851270616,
        39.37060600463149,
        -6.44921851270616,
        39.37060600463149
      ]
    },
    "10038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.672537684227409,
              39.89703181731466
            ]
          },
          "bbox": [
            -6.672537684227409,
            39.89703181731466,
            -6.672537684227409,
            39.89703181731466
          ]
        }
      ],
      "bbox": [
        -6.672537684227409,
        39.89703181731466,
        -6.672537684227409,
        39.89703181731466
      ]
    },
    "10039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.530779216374603,
              40.24682925566683
            ]
          },
          "bbox": [
            -6.530779216374603,
            40.24682925566683,
            -6.530779216374603,
            40.24682925566683
          ]
        }
      ],
      "bbox": [
        -6.530779216374603,
        40.24682925566683,
        -6.530779216374603,
        40.24682925566683
      ]
    },
    "10040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.551781235900253,
              40.05846392287846
            ]
          },
          "bbox": [
            -6.551781235900253,
            40.05846392287846,
            -6.551781235900253,
            40.05846392287846
          ]
        }
      ],
      "bbox": [
        -6.551781235900253,
        40.05846392287846,
        -6.551781235900253,
        40.05846392287846
      ]
    },
    "10041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.201556049875808,
              40.35434711859655
            ]
          },
          "bbox": [
            -6.201556049875808,
            40.35434711859655,
            -6.201556049875808,
            40.35434711859655
          ]
        }
      ],
      "bbox": [
        -6.201556049875808,
        40.35434711859655,
        -6.201556049875808,
        40.35434711859655
      ]
    },
    "10042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.581839799350256,
              39.713242625040365
            ]
          },
          "bbox": [
            -5.581839799350256,
            39.713242625040365,
            -5.581839799350256,
            39.713242625040365
          ]
        }
      ],
      "bbox": [
        -5.581839799350256,
        39.713242625040365,
        -5.581839799350256,
        39.713242625040365
      ]
    },
    "10043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.765170583202985,
              39.169420415935285
            ]
          },
          "bbox": [
            -5.765170583202985,
            39.169420415935285,
            -5.765170583202985,
            39.169420415935285
          ]
        }
      ],
      "bbox": [
        -5.765170583202985,
        39.169420415935285,
        -5.765170583202985,
        39.169420415935285
      ]
    },
    "10044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.388269611240647,
              39.37027589330499
            ]
          },
          "bbox": [
            -5.388269611240647,
            39.37027589330499,
            -5.388269611240647,
            39.37027589330499
          ]
        }
      ],
      "bbox": [
        -5.388269611240647,
        39.37027589330499,
        -5.388269611240647,
        39.37027589330499
      ]
    },
    "10045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.384050120919312,
              39.79810187529999
            ]
          },
          "bbox": [
            -6.384050120919312,
            39.79810187529999,
            -6.384050120919312,
            39.79810187529999
          ]
        }
      ],
      "bbox": [
        -6.384050120919312,
        39.79810187529999,
        -6.384050120919312,
        39.79810187529999
      ]
    },
    "10046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.178826636205272,
              39.62130754464074
            ]
          },
          "bbox": [
            -7.178826636205272,
            39.62130754464074,
            -7.178826636205272,
            39.62130754464074
          ]
        }
      ],
      "bbox": [
        -7.178826636205272,
        39.62130754464074,
        -7.178826636205272,
        39.62130754464074
      ]
    },
    "10047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.218446532361614,
              40.04906754154591
            ]
          },
          "bbox": [
            -6.218446532361614,
            40.04906754154591,
            -6.218446532361614,
            40.04906754154591
          ]
        }
      ],
      "bbox": [
        -6.218446532361614,
        40.04906754154591,
        -6.218446532361614,
        40.04906754154591
      ]
    },
    "10048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.218865904756805,
              39.64141375057008
            ]
          },
          "bbox": [
            -5.218865904756805,
            39.64141375057008,
            -5.218865904756805,
            39.64141375057008
          ]
        }
      ],
      "bbox": [
        -5.218865904756805,
        39.64141375057008,
        -5.218865904756805,
        39.64141375057008
      ]
    },
    "10049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.473922296046064,
              39.56514435861164
            ]
          },
          "bbox": [
            -6.473922296046064,
            39.56514435861164,
            -6.473922296046064,
            39.56514435861164
          ]
        }
      ],
      "bbox": [
        -6.473922296046064,
        39.56514435861164,
        -6.473922296046064,
        39.56514435861164
      ]
    },
    "10050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.271909829189774,
              40.287265977365735
            ]
          },
          "bbox": [
            -6.271909829189774,
            40.287265977365735,
            -6.271909829189774,
            40.287265977365735
          ]
        }
      ],
      "bbox": [
        -6.271909829189774,
        40.287265977365735,
        -6.271909829189774,
        40.287265977365735
      ]
    },
    "10051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.310505435200281,
              40.4374056818048
            ]
          },
          "bbox": [
            -6.310505435200281,
            40.4374056818048,
            -6.310505435200281,
            40.4374056818048
          ]
        }
      ],
      "bbox": [
        -6.310505435200281,
        40.4374056818048,
        -6.310505435200281,
        40.4374056818048
      ]
    },
    "10052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2806733803824555,
              39.232735741512286
            ]
          },
          "bbox": [
            -6.2806733803824555,
            39.232735741512286,
            -6.2806733803824555,
            39.232735741512286
          ]
        }
      ],
      "bbox": [
        -6.2806733803824555,
        39.232735741512286,
        -6.2806733803824555,
        39.232735741512286
      ]
    },
    "10053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.603860106567307,
              40.01003037451202
            ]
          },
          "bbox": [
            -6.603860106567307,
            40.01003037451202,
            -6.603860106567307,
            40.01003037451202
          ]
        }
      ],
      "bbox": [
        -6.603860106567307,
        40.01003037451202,
        -6.603860106567307,
        40.01003037451202
      ]
    },
    "10054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.940744253473864,
              40.09878769638519
            ]
          },
          "bbox": [
            -5.940744253473864,
            40.09878769638519,
            -5.940744253473864,
            40.09878769638519
          ]
        }
      ],
      "bbox": [
        -5.940744253473864,
        40.09878769638519,
        -5.940744253473864,
        40.09878769638519
      ]
    },
    "10055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.953108498539286,
              40.196066806645184
            ]
          },
          "bbox": [
            -5.953108498539286,
            40.196066806645184,
            -5.953108498539286,
            40.196066806645184
          ]
        }
      ],
      "bbox": [
        -5.953108498539286,
        40.196066806645184,
        -5.953108498539286,
        40.196066806645184
      ]
    },
    "10056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2797400679558955,
              39.79164823516384
            ]
          },
          "bbox": [
            -6.2797400679558955,
            39.79164823516384,
            -6.2797400679558955,
            39.79164823516384
          ]
        }
      ],
      "bbox": [
        -6.2797400679558955,
        39.79164823516384,
        -6.2797400679558955,
        39.79164823516384
      ]
    },
    "10057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.761464607533573,
              39.73932916341934
            ]
          },
          "bbox": [
            -5.761464607533573,
            39.73932916341934,
            -5.761464607533573,
            39.73932916341934
          ]
        }
      ],
      "bbox": [
        -5.761464607533573,
        39.73932916341934,
        -5.761464607533573,
        39.73932916341934
      ]
    },
    "10058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.683454714837535,
              39.9235408387503
            ]
          },
          "bbox": [
            -5.683454714837535,
            39.9235408387503,
            -5.683454714837535,
            39.9235408387503
          ]
        }
      ],
      "bbox": [
        -5.683454714837535,
        39.9235408387503,
        -5.683454714837535,
        39.9235408387503
      ]
    },
    "10059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6620998856024,
              39.967240515335256
            ]
          },
          "bbox": [
            -6.6620998856024,
            39.967240515335256,
            -6.6620998856024,
            39.967240515335256
          ]
        }
      ],
      "bbox": [
        -6.6620998856024,
        39.967240515335256,
        -6.6620998856024,
        39.967240515335256
      ]
    },
    "10060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.434134396996026,
              39.6737152329688
            ]
          },
          "bbox": [
            -5.434134396996026,
            39.6737152329688,
            -5.434134396996026,
            39.6737152329688
          ]
        }
      ],
      "bbox": [
        -5.434134396996026,
        39.6737152329688,
        -5.434134396996026,
        39.6737152329688
      ]
    },
    "10061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.751050610830934,
              39.828105592297575
            ]
          },
          "bbox": [
            -6.751050610830934,
            39.828105592297575,
            -6.751050610830934,
            39.828105592297575
          ]
        }
      ],
      "bbox": [
        -6.751050610830934,
        39.828105592297575,
        -6.751050610830934,
        39.828105592297575
      ]
    },
    "10062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.479366129644046,
              39.627485623205196
            ]
          },
          "bbox": [
            -7.479366129644046,
            39.627485623205196,
            -7.479366129644046,
            39.627485623205196
          ]
        }
      ],
      "bbox": [
        -7.479366129644046,
        39.627485623205196,
        -7.479366129644046,
        39.627485623205196
      ]
    },
    "10063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.242345295191311,
              40.2293890993562
            ]
          },
          "bbox": [
            -6.242345295191311,
            40.2293890993562,
            -6.242345295191311,
            40.2293890993562
          ]
        }
      ],
      "bbox": [
        -6.242345295191311,
        40.2293890993562,
        -6.242345295191311,
        40.2293890993562
      ]
    },
    "10064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.8137770194833225,
              40.0591706374063
            ]
          },
          "bbox": [
            -6.8137770194833225,
            40.0591706374063,
            -6.8137770194833225,
            40.0591706374063
          ]
        }
      ],
      "bbox": [
        -6.8137770194833225,
        40.0591706374063,
        -6.8137770194833225,
        40.0591706374063
      ]
    },
    "10065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.687844911086727,
              40.02741834276493
            ]
          },
          "bbox": [
            -5.687844911086727,
            40.02741834276493,
            -5.687844911086727,
            40.02741834276493
          ]
        }
      ],
      "bbox": [
        -5.687844911086727,
        40.02741834276493,
        -5.687844911086727,
        40.02741834276493
      ]
    },
    "10066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.731338040770079,
              39.337168244181214
            ]
          },
          "bbox": [
            -5.731338040770079,
            39.337168244181214,
            -5.731338040770079,
            39.337168244181214
          ]
        }
      ],
      "bbox": [
        -5.731338040770079,
        39.337168244181214,
        -5.731338040770079,
        39.337168244181214
      ]
    },
    "10067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.505054672578286,
              39.97878372974397
            ]
          },
          "bbox": [
            -6.505054672578286,
            39.97878372974397,
            -6.505054672578286,
            39.97878372974397
          ]
        }
      ],
      "bbox": [
        -6.505054672578286,
        39.97878372974397,
        -6.505054672578286,
        39.97878372974397
      ]
    },
    "10068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.681231617115803,
              40.07295945375467
            ]
          },
          "bbox": [
            -5.681231617115803,
            40.07295945375467,
            -5.681231617115803,
            40.07295945375467
          ]
        }
      ],
      "bbox": [
        -5.681231617115803,
        40.07295945375467,
        -5.681231617115803,
        40.07295945375467
      ]
    },
    "10069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.000079609128275,
              39.44771652196792
            ]
          },
          "bbox": [
            -6.000079609128275,
            39.44771652196792,
            -6.000079609128275,
            39.44771652196792
          ]
        }
      ],
      "bbox": [
        -6.000079609128275,
        39.44771652196792,
        -6.000079609128275,
        39.44771652196792
      ]
    },
    "10070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.648569653558543,
              39.64409295909787
            ]
          },
          "bbox": [
            -5.648569653558543,
            39.64409295909787,
            -5.648569653558543,
            39.64409295909787
          ]
        }
      ],
      "bbox": [
        -5.648569653558543,
        39.64409295909787,
        -5.648569653558543,
        39.64409295909787
      ]
    },
    "10071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.5152193016996724,
              40.3048078539689
            ]
          },
          "bbox": [
            -6.5152193016996724,
            40.3048078539689,
            -6.5152193016996724,
            40.3048078539689
          ]
        }
      ],
      "bbox": [
        -6.5152193016996724,
        40.3048078539689,
        -6.5152193016996724,
        40.3048078539689
      ]
    },
    "10072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.832639863247232,
              40.22286301376948
            ]
          },
          "bbox": [
            -6.832639863247232,
            40.22286301376948,
            -6.832639863247232,
            40.22286301376948
          ]
        }
      ],
      "bbox": [
        -6.832639863247232,
        40.22286301376948,
        -6.832639863247232,
        40.22286301376948
      ]
    },
    "10073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.90713884104983,
              39.18899107477041
            ]
          },
          "bbox": [
            -5.90713884104983,
            39.18899107477041,
            -5.90713884104983,
            39.18899107477041
          ]
        }
      ],
      "bbox": [
        -5.90713884104983,
        39.18899107477041,
        -5.90713884104983,
        39.18899107477041
      ]
    },
    "10075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.521339970919838,
              39.68159928616984
            ]
          },
          "bbox": [
            -5.521339970919838,
            39.68159928616984,
            -5.521339970919838,
            39.68159928616984
          ]
        }
      ],
      "bbox": [
        -5.521339970919838,
        39.68159928616984,
        -5.521339970919838,
        39.68159928616984
      ]
    },
    "10076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.281902914587566,
              40.000951752775634
            ]
          },
          "bbox": [
            -6.281902914587566,
            40.000951752775634,
            -6.281902914587566,
            40.000951752775634
          ]
        }
      ],
      "bbox": [
        -6.281902914587566,
        40.000951752775634,
        -6.281902914587566,
        40.000951752775634
      ]
    },
    "10077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.618407094761251,
              39.42219899821277
            ]
          },
          "bbox": [
            -5.618407094761251,
            39.42219899821277,
            -5.618407094761251,
            39.42219899821277
          ]
        }
      ],
      "bbox": [
        -5.618407094761251,
        39.42219899821277,
        -5.618407094761251,
        39.42219899821277
      ]
    },
    "10078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.812276579789191,
              40.322061093418164
            ]
          },
          "bbox": [
            -5.812276579789191,
            40.322061093418164,
            -5.812276579789191,
            40.322061093418164
          ]
        }
      ],
      "bbox": [
        -5.812276579789191,
        40.322061093418164,
        -5.812276579789191,
        40.322061093418164
      ]
    },
    "10079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7816639553989315,
              40.11475635356851
            ]
          },
          "bbox": [
            -5.7816639553989315,
            40.11475635356851,
            -5.7816639553989315,
            40.11475635356851
          ]
        }
      ],
      "bbox": [
        -5.7816639553989315,
        40.11475635356851,
        -5.7816639553989315,
        40.11475635356851
      ]
    },
    "10080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.90328532297042,
              40.234597121040835
            ]
          },
          "bbox": [
            -5.90328532297042,
            40.234597121040835,
            -5.90328532297042,
            40.234597121040835
          ]
        }
      ],
      "bbox": [
        -5.90328532297042,
        40.234597121040835,
        -5.90328532297042,
        40.234597121040835
      ]
    },
    "10081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.93492028152553,
              40.030633301218536
            ]
          },
          "bbox": [
            -5.93492028152553,
            40.030633301218536,
            -5.93492028152553,
            40.030633301218536
          ]
        }
      ],
      "bbox": [
        -5.93492028152553,
        40.030633301218536,
        -5.93492028152553,
        40.030633301218536
      ]
    },
    "10082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.512869465587509,
              39.68935145688469
            ]
          },
          "bbox": [
            -6.512869465587509,
            39.68935145688469,
            -6.512869465587509,
            39.68935145688469
          ]
        }
      ],
      "bbox": [
        -6.512869465587509,
        39.68935145688469,
        -6.512869465587509,
        39.68935145688469
      ]
    },
    "10083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3501185409529795,
              39.71592923323208
            ]
          },
          "bbox": [
            -5.3501185409529795,
            39.71592923323208,
            -5.3501185409529795,
            39.71592923323208
          ]
        }
      ],
      "bbox": [
        -5.3501185409529795,
        39.71592923323208,
        -5.3501185409529795,
        39.71592923323208
      ]
    },
    "10084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6288524353278735,
              40.199857861983325
            ]
          },
          "bbox": [
            -6.6288524353278735,
            40.199857861983325,
            -6.6288524353278735,
            40.199857861983325
          ]
        }
      ],
      "bbox": [
        -6.6288524353278735,
        40.199857861983325,
        -6.6288524353278735,
        40.199857861983325
      ]
    },
    "10085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.356981418124849,
              39.8486152698248
            ]
          },
          "bbox": [
            -5.356981418124849,
            39.8486152698248,
            -5.356981418124849,
            39.8486152698248
          ]
        }
      ],
      "bbox": [
        -5.356981418124849,
        39.8486152698248,
        -5.356981418124849,
        39.8486152698248
      ]
    },
    "10086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.000281689968806,
              40.242458577370186
            ]
          },
          "bbox": [
            -6.000281689968806,
            40.242458577370186,
            -6.000281689968806,
            40.242458577370186
          ]
        }
      ],
      "bbox": [
        -6.000281689968806,
        40.242458577370186,
        -6.000281689968806,
        40.242458577370186
      ]
    },
    "10087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.320224522997969,
              39.44972396091323
            ]
          },
          "bbox": [
            -5.320224522997969,
            39.44972396091323,
            -5.320224522997969,
            39.44972396091323
          ]
        }
      ],
      "bbox": [
        -5.320224522997969,
        39.44972396091323,
        -5.320224522997969,
        39.44972396091323
      ]
    },
    "10088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.467569785395328,
              40.06280873329256
            ]
          },
          "bbox": [
            -6.467569785395328,
            40.06280873329256,
            -6.467569785395328,
            40.06280873329256
          ]
        }
      ],
      "bbox": [
        -6.467569785395328,
        40.06280873329256,
        -6.467569785395328,
        40.06280873329256
      ]
    },
    "10089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.393671713422063,
              40.04059447427615
            ]
          },
          "bbox": [
            -6.393671713422063,
            40.04059447427615,
            -6.393671713422063,
            40.04059447427615
          ]
        }
      ],
      "bbox": [
        -6.393671713422063,
        40.04059447427615,
        -6.393671713422063,
        40.04059447427615
      ]
    },
    "10090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.141411353924365,
              40.1946815921464
            ]
          },
          "bbox": [
            -6.141411353924365,
            40.1946815921464,
            -6.141411353924365,
            40.1946815921464
          ]
        }
      ],
      "bbox": [
        -6.141411353924365,
        40.1946815921464,
        -6.141411353924365,
        40.1946815921464
      ]
    },
    "10091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.646482476116393,
              40.170259694290976
            ]
          },
          "bbox": [
            -5.646482476116393,
            40.170259694290976,
            -5.646482476116393,
            40.170259694290976
          ]
        }
      ],
      "bbox": [
        -5.646482476116393,
        40.170259694290976,
        -5.646482476116393,
        40.170259694290976
      ]
    },
    "10092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7622572188413255,
              39.36756053906961
            ]
          },
          "bbox": [
            -5.7622572188413255,
            39.36756053906961,
            -5.7622572188413255,
            39.36756053906961
          ]
        }
      ],
      "bbox": [
        -5.7622572188413255,
        39.36756053906961,
        -5.7622572188413255,
        39.36756053906961
      ]
    },
    "10093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.481171404084553,
              40.21864954894179
            ]
          },
          "bbox": [
            -6.481171404084553,
            40.21864954894179,
            -6.481171404084553,
            40.21864954894179
          ]
        }
      ],
      "bbox": [
        -6.481171404084553,
        40.21864954894179,
        -6.481171404084553,
        40.21864954894179
      ]
    },
    "10094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.388791938608113,
              39.59409940941706
            ]
          },
          "bbox": [
            -7.388791938608113,
            39.59409940941706,
            -7.388791938608113,
            39.59409940941706
          ]
        }
      ],
      "bbox": [
        -7.388791938608113,
        39.59409940941706,
        -7.388791938608113,
        39.59409940941706
      ]
    },
    "10095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.864725878004901,
              39.433631524477406
            ]
          },
          "bbox": [
            -6.864725878004901,
            39.433631524477406,
            -6.864725878004901,
            39.433631524477406
          ]
        }
      ],
      "bbox": [
        -6.864725878004901,
        39.433631524477406,
        -6.864725878004901,
        39.433631524477406
      ]
    },
    "10096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.837027807045612,
              40.26954965730472
            ]
          },
          "bbox": [
            -5.837027807045612,
            40.26954965730472,
            -5.837027807045612,
            40.26954965730472
          ]
        }
      ],
      "bbox": [
        -5.837027807045612,
        40.26954965730472,
        -5.837027807045612,
        40.26954965730472
      ]
    },
    "10097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.658805468010477,
              39.723385815884626
            ]
          },
          "bbox": [
            -5.658805468010477,
            39.723385815884626,
            -5.658805468010477,
            39.723385815884626
          ]
        }
      ],
      "bbox": [
        -5.658805468010477,
        39.723385815884626,
        -5.658805468010477,
        39.723385815884626
      ]
    },
    "10098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.349188668549695,
              39.705354045628475
            ]
          },
          "bbox": [
            -6.349188668549695,
            39.705354045628475,
            -6.349188668549695,
            39.705354045628475
          ]
        }
      ],
      "bbox": [
        -6.349188668549695,
        39.705354045628475,
        -6.349188668549695,
        39.705354045628475
      ]
    },
    "10099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.352766783624986,
              39.88913205123686
            ]
          },
          "bbox": [
            -6.352766783624986,
            39.88913205123686,
            -6.352766783624986,
            39.88913205123686
          ]
        }
      ],
      "bbox": [
        -6.352766783624986,
        39.88913205123686,
        -6.352766783624986,
        39.88913205123686
      ]
    },
    "10100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7224994651298795,
              40.16445488268235
            ]
          },
          "bbox": [
            -6.7224994651298795,
            40.16445488268235,
            -6.7224994651298795,
            40.16445488268235
          ]
        }
      ],
      "bbox": [
        -6.7224994651298795,
        40.16445488268235,
        -6.7224994651298795,
        40.16445488268235
      ]
    },
    "10101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.611696192138047,
              40.061715362245735
            ]
          },
          "bbox": [
            -6.611696192138047,
            40.061715362245735,
            -6.611696192138047,
            40.061715362245735
          ]
        }
      ],
      "bbox": [
        -6.611696192138047,
        40.061715362245735,
        -6.611696192138047,
        40.061715362245735
      ]
    },
    "10102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.917291914655238,
              39.33147675298889
            ]
          },
          "bbox": [
            -5.917291914655238,
            39.33147675298889,
            -5.917291914655238,
            39.33147675298889
          ]
        }
      ],
      "bbox": [
        -5.917291914655238,
        39.33147675298889,
        -5.917291914655238,
        39.33147675298889
      ]
    },
    "10103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.831533333363439,
              39.69567781736885
            ]
          },
          "bbox": [
            -5.831533333363439,
            39.69567781736885,
            -5.831533333363439,
            39.69567781736885
          ]
        }
      ],
      "bbox": [
        -5.831533333363439,
        39.69567781736885,
        -5.831533333363439,
        39.69567781736885
      ]
    },
    "10104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.750299157287622,
              40.021806706168505
            ]
          },
          "bbox": [
            -5.750299157287622,
            40.021806706168505,
            -5.750299157287622,
            40.021806706168505
          ]
        }
      ],
      "bbox": [
        -5.750299157287622,
        40.021806706168505,
        -5.750299157287622,
        40.021806706168505
      ]
    },
    "10105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.632384393919913,
              40.09071016434422
            ]
          },
          "bbox": [
            -5.632384393919913,
            40.09071016434422,
            -5.632384393919913,
            40.09071016434422
          ]
        }
      ],
      "bbox": [
        -5.632384393919913,
        40.09071016434422,
        -5.632384393919913,
        40.09071016434422
      ]
    },
    "10106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.000187971654646,
              40.176980270113106
            ]
          },
          "bbox": [
            -6.000187971654646,
            40.176980270113106,
            -6.000187971654646,
            40.176980270113106
          ]
        }
      ],
      "bbox": [
        -6.000187971654646,
        40.176980270113106,
        -6.000187971654646,
        40.176980270113106
      ]
    },
    "10107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.743189996919496,
              40.222365119752055
            ]
          },
          "bbox": [
            -5.743189996919496,
            40.222365119752055,
            -5.743189996919496,
            40.222365119752055
          ]
        }
      ],
      "bbox": [
        -5.743189996919496,
        40.222365119752055,
        -5.743189996919496,
        40.222365119752055
      ]
    },
    "10108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.193498557612406,
              40.44711769789371
            ]
          },
          "bbox": [
            -6.193498557612406,
            40.44711769789371,
            -6.193498557612406,
            40.44711769789371
          ]
        }
      ],
      "bbox": [
        -6.193498557612406,
        40.44711769789371,
        -6.193498557612406,
        40.44711769789371
      ]
    },
    "10109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.481314519841874,
              39.273177279101446
            ]
          },
          "bbox": [
            -5.481314519841874,
            39.273177279101446,
            -5.481314519841874,
            39.273177279101446
          ]
        }
      ],
      "bbox": [
        -5.481314519841874,
        39.273177279101446,
        -5.481314519841874,
        39.273177279101446
      ]
    },
    "10110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.579365177380269,
              40.144562916760535
            ]
          },
          "bbox": [
            -5.579365177380269,
            40.144562916760535,
            -5.579365177380269,
            40.144562916760535
          ]
        }
      ],
      "bbox": [
        -5.579365177380269,
        40.144562916760535,
        -5.579365177380269,
        40.144562916760535
      ]
    },
    "10111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.371118905641191,
              40.18885755073048
            ]
          },
          "bbox": [
            -5.371118905641191,
            40.18885755073048,
            -5.371118905641191,
            40.18885755073048
          ]
        }
      ],
      "bbox": [
        -5.371118905641191,
        40.18885755073048,
        -5.371118905641191,
        40.18885755073048
      ]
    },
    "10112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.642517769998493,
              39.1417093380784
            ]
          },
          "bbox": [
            -5.642517769998493,
            39.1417093380784,
            -5.642517769998493,
            39.1417093380784
          ]
        }
      ],
      "bbox": [
        -5.642517769998493,
        39.1417093380784,
        -5.642517769998493,
        39.1417093380784
      ]
    },
    "10113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.734197858835292,
              39.45373334071123
            ]
          },
          "bbox": [
            -5.734197858835292,
            39.45373334071123,
            -5.734197858835292,
            39.45373334071123
          ]
        }
      ],
      "bbox": [
        -5.734197858835292,
        39.45373334071123,
        -5.734197858835292,
        39.45373334071123
      ]
    },
    "10114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.774965262752058,
              39.95000124017345
            ]
          },
          "bbox": [
            -5.774965262752058,
            39.95000124017345,
            -5.774965262752058,
            39.95000124017345
          ]
        }
      ],
      "bbox": [
        -5.774965262752058,
        39.95000124017345,
        -5.774965262752058,
        39.95000124017345
      ]
    },
    "10115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.499234071043599,
              39.44179066259867
            ]
          },
          "bbox": [
            -6.499234071043599,
            39.44179066259867,
            -6.499234071043599,
            39.44179066259867
          ]
        }
      ],
      "bbox": [
        -6.499234071043599,
        39.44179066259867,
        -6.499234071043599,
        39.44179066259867
      ]
    },
    "10116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0526824423447145,
              39.938094600061156
            ]
          },
          "bbox": [
            -6.0526824423447145,
            39.938094600061156,
            -6.0526824423447145,
            39.938094600061156
          ]
        }
      ],
      "bbox": [
        -6.0526824423447145,
        39.938094600061156,
        -6.0526824423447145,
        39.938094600061156
      ]
    },
    "10117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.279482567991495,
              40.272931273315315
            ]
          },
          "bbox": [
            -6.279482567991495,
            40.272931273315315,
            -6.279482567991495,
            40.272931273315315
          ]
        }
      ],
      "bbox": [
        -6.279482567991495,
        40.272931273315315,
        -6.279482567991495,
        40.272931273315315
      ]
    },
    "10118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.790302897685316,
              39.71601318086082
            ]
          },
          "bbox": [
            -6.790302897685316,
            39.71601318086082,
            -6.790302897685316,
            39.71601318086082
          ]
        }
      ],
      "bbox": [
        -6.790302897685316,
        39.71601318086082,
        -6.790302897685316,
        39.71601318086082
      ]
    },
    "10119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.084380097856673,
              39.579952851044084
            ]
          },
          "bbox": [
            -7.084380097856673,
            39.579952851044084,
            -7.084380097856673,
            39.579952851044084
          ]
        }
      ],
      "bbox": [
        -7.084380097856673,
        39.579952851044084,
        -7.084380097856673,
        39.579952851044084
      ]
    },
    "10120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5517568482871535,
              39.76515528597156
            ]
          },
          "bbox": [
            -5.5517568482871535,
            39.76515528597156,
            -5.5517568482871535,
            39.76515528597156
          ]
        }
      ],
      "bbox": [
        -5.5517568482871535,
        39.76515528597156,
        -5.5517568482871535,
        39.76515528597156
      ]
    },
    "10121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9323272702578,
              39.12388194082437
            ]
          },
          "bbox": [
            -5.9323272702578,
            39.12388194082437,
            -5.9323272702578,
            39.12388194082437
          ]
        }
      ],
      "bbox": [
        -5.9323272702578,
        39.12388194082437,
        -5.9323272702578,
        39.12388194082437
      ]
    },
    "10122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.565727603870314,
              39.855113163265045
            ]
          },
          "bbox": [
            -5.565727603870314,
            39.855113163265045,
            -5.565727603870314,
            39.855113163265045
          ]
        }
      ],
      "bbox": [
        -5.565727603870314,
        39.855113163265045,
        -5.565727603870314,
        39.855113163265045
      ]
    },
    "10123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.229499385216645,
              39.859687207934066
            ]
          },
          "bbox": [
            -6.229499385216645,
            39.859687207934066,
            -6.229499385216645,
            39.859687207934066
          ]
        }
      ],
      "bbox": [
        -6.229499385216645,
        39.859687207934066,
        -6.229499385216645,
        39.859687207934066
      ]
    },
    "10124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.178680753088331,
              40.27069362942974
            ]
          },
          "bbox": [
            -6.178680753088331,
            40.27069362942974,
            -6.178680753088331,
            40.27069362942974
          ]
        }
      ],
      "bbox": [
        -6.178680753088331,
        40.27069362942974,
        -6.178680753088331,
        40.27069362942974
      ]
    },
    "10125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1514707476103005,
              39.66689167621253
            ]
          },
          "bbox": [
            -6.1514707476103005,
            39.66689167621253,
            -6.1514707476103005,
            39.66689167621253
          ]
        }
      ],
      "bbox": [
        -6.1514707476103005,
        39.66689167621253,
        -6.1514707476103005,
        39.66689167621253
      ]
    },
    "10126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.194064839894399,
              39.18329112251699
            ]
          },
          "bbox": [
            -6.194064839894399,
            39.18329112251699,
            -6.194064839894399,
            39.18329112251699
          ]
        }
      ],
      "bbox": [
        -6.194064839894399,
        39.18329112251699,
        -6.194064839894399,
        39.18329112251699
      ]
    },
    "10127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.335989395458975,
              40.054599890259894
            ]
          },
          "bbox": [
            -6.335989395458975,
            40.054599890259894,
            -6.335989395458975,
            40.054599890259894
          ]
        }
      ],
      "bbox": [
        -6.335989395458975,
        40.054599890259894,
        -6.335989395458975,
        40.054599890259894
      ]
    },
    "10128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.700453263949206,
              40.02377827436065
            ]
          },
          "bbox": [
            -6.700453263949206,
            40.02377827436065,
            -6.700453263949206,
            40.02377827436065
          ]
        }
      ],
      "bbox": [
        -6.700453263949206,
        40.02377827436065,
        -6.700453263949206,
        40.02377827436065
      ]
    },
    "10129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.407132317353046,
              40.00036246405838
            ]
          },
          "bbox": [
            -6.407132317353046,
            40.00036246405838,
            -6.407132317353046,
            40.00036246405838
          ]
        }
      ],
      "bbox": [
        -6.407132317353046,
        40.00036246405838,
        -6.407132317353046,
        40.00036246405838
      ]
    },
    "10130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.858531267221858,
              40.173259329945324
            ]
          },
          "bbox": [
            -5.858531267221858,
            40.173259329945324,
            -5.858531267221858,
            40.173259329945324
          ]
        }
      ],
      "bbox": [
        -5.858531267221858,
        40.173259329945324,
        -5.858531267221858,
        40.173259329945324
      ]
    },
    "10131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.557534137964441,
              39.93080303343229
            ]
          },
          "bbox": [
            -5.557534137964441,
            39.93080303343229,
            -5.557534137964441,
            39.93080303343229
          ]
        }
      ],
      "bbox": [
        -5.557534137964441,
        39.93080303343229,
        -5.557534137964441,
        39.93080303343229
      ]
    },
    "10132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.383582187765347,
              39.58695710695512
            ]
          },
          "bbox": [
            -5.383582187765347,
            39.58695710695512,
            -5.383582187765347,
            39.58695710695512
          ]
        }
      ],
      "bbox": [
        -5.383582187765347,
        39.58695710695512,
        -5.383582187765347,
        39.58695710695512
      ]
    },
    "10133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.642540769605784,
              39.64652543777375
            ]
          },
          "bbox": [
            -6.642540769605784,
            39.64652543777375,
            -6.642540769605784,
            39.64652543777375
          ]
        }
      ],
      "bbox": [
        -6.642540769605784,
        39.64652543777375,
        -6.642540769605784,
        39.64652543777375
      ]
    },
    "10134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.428214995490335,
              39.50541469939735
            ]
          },
          "bbox": [
            -5.428214995490335,
            39.50541469939735,
            -5.428214995490335,
            39.50541469939735
          ]
        }
      ],
      "bbox": [
        -5.428214995490335,
        39.50541469939735,
        -5.428214995490335,
        39.50541469939735
      ]
    },
    "10135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.254295196334019,
              40.405812940341924
            ]
          },
          "bbox": [
            -6.254295196334019,
            40.405812940341924,
            -6.254295196334019,
            40.405812940341924
          ]
        }
      ],
      "bbox": [
        -6.254295196334019,
        40.405812940341924,
        -6.254295196334019,
        40.405812940341924
      ]
    },
    "10136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.106776656750396,
              40.11275370022566
            ]
          },
          "bbox": [
            -6.106776656750396,
            40.11275370022566,
            -6.106776656750396,
            40.11275370022566
          ]
        }
      ],
      "bbox": [
        -6.106776656750396,
        40.11275370022566,
        -6.106776656750396,
        40.11275370022566
      ]
    },
    "10137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2762634567083335,
              40.24575835170393
            ]
          },
          "bbox": [
            -6.2762634567083335,
            40.24575835170393,
            -6.2762634567083335,
            40.24575835170393
          ]
        }
      ],
      "bbox": [
        -6.2762634567083335,
        40.24575835170393,
        -6.2762634567083335,
        40.24575835170393
      ]
    },
    "10138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.81505676332398,
              40.02481352474884
            ]
          },
          "bbox": [
            -5.81505676332398,
            40.02481352474884,
            -5.81505676332398,
            40.02481352474884
          ]
        }
      ],
      "bbox": [
        -5.81505676332398,
        40.02481352474884,
        -5.81505676332398,
        40.02481352474884
      ]
    },
    "10139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4052818875235875,
              39.85238474365942
            ]
          },
          "bbox": [
            -6.4052818875235875,
            39.85238474365942,
            -6.4052818875235875,
            39.85238474365942
          ]
        }
      ],
      "bbox": [
        -6.4052818875235875,
        39.85238474365942,
        -6.4052818875235875,
        39.85238474365942
      ]
    },
    "10140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.459640929566854,
              39.86794659865356
            ]
          },
          "bbox": [
            -5.459640929566854,
            39.86794659865356,
            -5.459640929566854,
            39.86794659865356
          ]
        }
      ],
      "bbox": [
        -5.459640929566854,
        39.86794659865356,
        -5.459640929566854,
        39.86794659865356
      ]
    },
    "10141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.389813596621318,
              39.75032206825752
            ]
          },
          "bbox": [
            -5.389813596621318,
            39.75032206825752,
            -5.389813596621318,
            39.75032206825752
          ]
        }
      ],
      "bbox": [
        -5.389813596621318,
        39.75032206825752,
        -5.389813596621318,
        39.75032206825752
      ]
    },
    "10142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6902474453307095,
              40.13550028567897
            ]
          },
          "bbox": [
            -6.6902474453307095,
            40.13550028567897,
            -6.6902474453307095,
            40.13550028567897
          ]
        }
      ],
      "bbox": [
        -6.6902474453307095,
        40.13550028567897,
        -6.6902474453307095,
        40.13550028567897
      ]
    },
    "10143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6186476545764865,
              39.88364719816871
            ]
          },
          "bbox": [
            -6.6186476545764865,
            39.88364719816871,
            -6.6186476545764865,
            39.88364719816871
          ]
        }
      ],
      "bbox": [
        -6.6186476545764865,
        39.88364719816871,
        -6.6186476545764865,
        39.88364719816871
      ]
    },
    "10144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.15691763185813,
              40.31242380845277
            ]
          },
          "bbox": [
            -6.15691763185813,
            40.31242380845277,
            -6.15691763185813,
            40.31242380845277
          ]
        }
      ],
      "bbox": [
        -6.15691763185813,
        40.31242380845277,
        -6.15691763185813,
        40.31242380845277
      ]
    },
    "10145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.939375832992634,
              39.78468884697645
            ]
          },
          "bbox": [
            -6.939375832992634,
            39.78468884697645,
            -6.939375832992634,
            39.78468884697645
          ]
        }
      ],
      "bbox": [
        -6.939375832992634,
        39.78468884697645,
        -6.939375832992634,
        39.78468884697645
      ]
    },
    "10146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.388821073181359,
              40.33484838303735
            ]
          },
          "bbox": [
            -6.388821073181359,
            40.33484838303735,
            -6.388821073181359,
            40.33484838303735
          ]
        }
      ],
      "bbox": [
        -6.388821073181359,
        40.33484838303735,
        -6.388821073181359,
        40.33484838303735
      ]
    },
    "10147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.83743772839667,
              40.11638412350073
            ]
          },
          "bbox": [
            -5.83743772839667,
            40.11638412350073,
            -5.83743772839667,
            40.11638412350073
          ]
        }
      ],
      "bbox": [
        -5.83743772839667,
        40.11638412350073,
        -5.83743772839667,
        40.11638412350073
      ]
    },
    "10148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.14089578081442,
              40.01489132514285
            ]
          },
          "bbox": [
            -6.14089578081442,
            40.01489132514285,
            -6.14089578081442,
            40.01489132514285
          ]
        }
      ],
      "bbox": [
        -6.14089578081442,
        40.01489132514285,
        -6.14089578081442,
        40.01489132514285
      ]
    },
    "10149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0484211015595255,
              39.37237719231371
            ]
          },
          "bbox": [
            -6.0484211015595255,
            39.37237719231371,
            -6.0484211015595255,
            39.37237719231371
          ]
        }
      ],
      "bbox": [
        -6.0484211015595255,
        39.37237719231371,
        -6.0484211015595255,
        39.37237719231371
      ]
    },
    "10150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.559211081029453,
              39.883146106830644
            ]
          },
          "bbox": [
            -6.559211081029453,
            39.883146106830644,
            -6.559211081029453,
            39.883146106830644
          ]
        }
      ],
      "bbox": [
        -6.559211081029453,
        39.883146106830644,
        -6.559211081029453,
        39.883146106830644
      ]
    },
    "10151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.510351219317288,
              39.794461270239744
            ]
          },
          "bbox": [
            -6.510351219317288,
            39.794461270239744,
            -6.510351219317288,
            39.794461270239744
          ]
        }
      ],
      "bbox": [
        -6.510351219317288,
        39.794461270239744,
        -6.510351219317288,
        39.794461270239744
      ]
    },
    "10152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.400421710065149,
              40.13818560198612
            ]
          },
          "bbox": [
            -6.400421710065149,
            40.13818560198612,
            -6.400421710065149,
            40.13818560198612
          ]
        }
      ],
      "bbox": [
        -6.400421710065149,
        40.13818560198612,
        -6.400421710065149,
        40.13818560198612
      ]
    },
    "10153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.869037721790331,
              39.2942288686444
            ]
          },
          "bbox": [
            -5.869037721790331,
            39.2942288686444,
            -5.869037721790331,
            39.2942288686444
          ]
        }
      ],
      "bbox": [
        -5.869037721790331,
        39.2942288686444,
        -5.869037721790331,
        39.2942288686444
      ]
    },
    "10154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.911187955183941,
              40.15861329931797
            ]
          },
          "bbox": [
            -5.911187955183941,
            40.15861329931797,
            -5.911187955183941,
            40.15861329931797
          ]
        }
      ],
      "bbox": [
        -5.911187955183941,
        40.15861329931797,
        -5.911187955183941,
        40.15861329931797
      ]
    },
    "10155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.33296289909504,
              39.923875249940984
            ]
          },
          "bbox": [
            -6.33296289909504,
            39.923875249940984,
            -6.33296289909504,
            39.923875249940984
          ]
        }
      ],
      "bbox": [
        -6.33296289909504,
        39.923875249940984,
        -6.33296289909504,
        39.923875249940984
      ]
    },
    "10156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.472302222153141,
              40.343026308678056
            ]
          },
          "bbox": [
            -6.472302222153141,
            40.343026308678056,
            -6.472302222153141,
            40.343026308678056
          ]
        }
      ],
      "bbox": [
        -6.472302222153141,
        40.343026308678056,
        -6.472302222153141,
        40.343026308678056
      ]
    },
    "10157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5799655117470754,
              40.08756492660984
            ]
          },
          "bbox": [
            -5.5799655117470754,
            40.08756492660984,
            -5.5799655117470754,
            40.08756492660984
          ]
        }
      ],
      "bbox": [
        -5.5799655117470754,
        40.08756492660984,
        -5.5799655117470754,
        40.08756492660984
      ]
    },
    "10158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9751167237636995,
              39.264591868922516
            ]
          },
          "bbox": [
            -5.9751167237636995,
            39.264591868922516,
            -5.9751167237636995,
            39.264591868922516
          ]
        }
      ],
      "bbox": [
        -5.9751167237636995,
        39.264591868922516,
        -5.9751167237636995,
        39.264591868922516
      ]
    },
    "10159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.527761869074914,
              39.620543497539316
            ]
          },
          "bbox": [
            -5.527761869074914,
            39.620543497539316,
            -5.527761869074914,
            39.620543497539316
          ]
        }
      ],
      "bbox": [
        -5.527761869074914,
        39.620543497539316,
        -5.527761869074914,
        39.620543497539316
      ]
    },
    "10160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.707455921016806,
              39.75935588142341
            ]
          },
          "bbox": [
            -5.707455921016806,
            39.75935588142341,
            -5.707455921016806,
            39.75935588142341
          ]
        }
      ],
      "bbox": [
        -5.707455921016806,
        39.75935588142341,
        -5.707455921016806,
        39.75935588142341
      ]
    },
    "10161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0111690253492,
              39.32902474117148
            ]
          },
          "bbox": [
            -6.0111690253492,
            39.32902474117148,
            -6.0111690253492,
            39.32902474117148
          ]
        }
      ],
      "bbox": [
        -6.0111690253492,
        39.32902474117148,
        -6.0111690253492,
        39.32902474117148
      ]
    },
    "10162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.004050125004002,
              39.470862621859126
            ]
          },
          "bbox": [
            -7.004050125004002,
            39.470862621859126,
            -7.004050125004002,
            39.470862621859126
          ]
        }
      ],
      "bbox": [
        -7.004050125004002,
        39.470862621859126,
        -7.004050125004002,
        39.470862621859126
      ]
    },
    "10163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.048585177433128,
              39.29614117717533
            ]
          },
          "bbox": [
            -6.048585177433128,
            39.29614117717533,
            -6.048585177433128,
            39.29614117717533
          ]
        }
      ],
      "bbox": [
        -6.048585177433128,
        39.29614117717533,
        -6.048585177433128,
        39.29614117717533
      ]
    },
    "10164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.808731421778347,
              40.20555593274792
            ]
          },
          "bbox": [
            -6.808731421778347,
            40.20555593274792,
            -6.808731421778347,
            40.20555593274792
          ]
        }
      ],
      "bbox": [
        -6.808731421778347,
        40.20555593274792,
        -6.808731421778347,
        40.20555593274792
      ]
    },
    "10165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.965498203572694,
              39.3423607828207
            ]
          },
          "bbox": [
            -5.965498203572694,
            39.3423607828207,
            -5.965498203572694,
            39.3423607828207
          ]
        }
      ],
      "bbox": [
        -5.965498203572694,
        39.3423607828207,
        -5.965498203572694,
        39.3423607828207
      ]
    },
    "10166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.837082056087687,
              39.34088744035951
            ]
          },
          "bbox": [
            -5.837082056087687,
            39.34088744035951,
            -5.837082056087687,
            39.34088744035951
          ]
        }
      ],
      "bbox": [
        -5.837082056087687,
        39.34088744035951,
        -5.837082056087687,
        39.34088744035951
      ]
    },
    "10167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.329952797423205,
              40.207334784230696
            ]
          },
          "bbox": [
            -6.329952797423205,
            40.207334784230696,
            -6.329952797423205,
            40.207334784230696
          ]
        }
      ],
      "bbox": [
        -6.329952797423205,
        40.207334784230696,
        -6.329952797423205,
        40.207334784230696
      ]
    },
    "10168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.089941292820619,
              39.50503965012208
            ]
          },
          "bbox": [
            -6.089941292820619,
            39.50503965012208,
            -6.089941292820619,
            39.50503965012208
          ]
        }
      ],
      "bbox": [
        -6.089941292820619,
        39.50503965012208,
        -6.089941292820619,
        39.50503965012208
      ]
    },
    "10169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.245786132993393,
              39.61590742905286
            ]
          },
          "bbox": [
            -7.245786132993393,
            39.61590742905286,
            -7.245786132993393,
            39.61590742905286
          ]
        }
      ],
      "bbox": [
        -7.245786132993393,
        39.61590742905286,
        -7.245786132993393,
        39.61590742905286
      ]
    },
    "10170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.382110679412444,
              39.64591159639386
            ]
          },
          "bbox": [
            -6.382110679412444,
            39.64591159639386,
            -6.382110679412444,
            39.64591159639386
          ]
        }
      ],
      "bbox": [
        -6.382110679412444,
        39.64591159639386,
        -6.382110679412444,
        39.64591159639386
      ]
    },
    "10171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.548971350005894,
              40.186931383156804
            ]
          },
          "bbox": [
            -6.548971350005894,
            40.186931383156804,
            -6.548971350005894,
            40.186931383156804
          ]
        }
      ],
      "bbox": [
        -6.548971350005894,
        40.186931383156804,
        -6.548971350005894,
        40.186931383156804
      ]
    },
    "10172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2441057094627075,
              40.16150128814779
            ]
          },
          "bbox": [
            -6.2441057094627075,
            40.16150128814779,
            -6.2441057094627075,
            40.16150128814779
          ]
        }
      ],
      "bbox": [
        -6.2441057094627075,
        40.16150128814779,
        -6.2441057094627075,
        40.16150128814779
      ]
    },
    "10173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.687558330836496,
              39.849678647994246
            ]
          },
          "bbox": [
            -5.687558330836496,
            39.849678647994246,
            -5.687558330836496,
            39.849678647994246
          ]
        }
      ],
      "bbox": [
        -5.687558330836496,
        39.849678647994246,
        -5.687558330836496,
        39.849678647994246
      ]
    },
    "10174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.935015319380748,
              40.21789642819828
            ]
          },
          "bbox": [
            -5.935015319380748,
            40.21789642819828,
            -5.935015319380748,
            40.21789642819828
          ]
        }
      ],
      "bbox": [
        -5.935015319380748,
        40.21789642819828,
        -5.935015319380748,
        40.21789642819828
      ]
    },
    "10175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.103307661395708,
              39.79793223354381
            ]
          },
          "bbox": [
            -6.103307661395708,
            39.79793223354381,
            -6.103307661395708,
            39.79793223354381
          ]
        }
      ],
      "bbox": [
        -6.103307661395708,
        39.79793223354381,
        -6.103307661395708,
        39.79793223354381
      ]
    },
    "10176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.81527461578736,
              39.815896132799836
            ]
          },
          "bbox": [
            -5.81527461578736,
            39.815896132799836,
            -5.81527461578736,
            39.815896132799836
          ]
        }
      ],
      "bbox": [
        -5.81527461578736,
        39.815896132799836,
        -5.81527461578736,
        39.815896132799836
      ]
    },
    "10177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2693386371132185,
              39.40966225178145
            ]
          },
          "bbox": [
            -6.2693386371132185,
            39.40966225178145,
            -6.2693386371132185,
            39.40966225178145
          ]
        }
      ],
      "bbox": [
        -6.2693386371132185,
        39.40966225178145,
        -6.2693386371132185,
        39.40966225178145
      ]
    },
    "10178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.27870980979391,
              39.6675890219526
            ]
          },
          "bbox": [
            -6.27870980979391,
            39.6675890219526,
            -6.27870980979391,
            39.6675890219526
          ]
        }
      ],
      "bbox": [
        -6.27870980979391,
        39.6675890219526,
        -6.27870980979391,
        39.6675890219526
      ]
    },
    "10179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.517107716822076,
              40.13810845277746
            ]
          },
          "bbox": [
            -5.517107716822076,
            40.13810845277746,
            -5.517107716822076,
            40.13810845277746
          ]
        }
      ],
      "bbox": [
        -5.517107716822076,
        40.13810845277746,
        -5.517107716822076,
        40.13810845277746
      ]
    },
    "10180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.479452380738371,
              40.00273770298306
            ]
          },
          "bbox": [
            -5.479452380738371,
            40.00273770298306,
            -5.479452380738371,
            40.00273770298306
          ]
        }
      ],
      "bbox": [
        -5.479452380738371,
        40.00273770298306,
        -5.479452380738371,
        40.00273770298306
      ]
    },
    "10181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.870341139796814,
              39.993066833580706
            ]
          },
          "bbox": [
            -5.870341139796814,
            39.993066833580706,
            -5.870341139796814,
            39.993066833580706
          ]
        }
      ],
      "bbox": [
        -5.870341139796814,
        39.993066833580706,
        -5.870341139796814,
        39.993066833580706
      ]
    },
    "10182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.859580758774467,
              39.88508587487224
            ]
          },
          "bbox": [
            -5.859580758774467,
            39.88508587487224,
            -5.859580758774467,
            39.88508587487224
          ]
        }
      ],
      "bbox": [
        -5.859580758774467,
        39.88508587487224,
        -5.859580758774467,
        39.88508587487224
      ]
    },
    "10183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.681879885332877,
              40.24058842086397
            ]
          },
          "bbox": [
            -5.681879885332877,
            40.24058842086397,
            -5.681879885332877,
            40.24058842086397
          ]
        }
      ],
      "bbox": [
        -5.681879885332877,
        40.24058842086397,
        -5.681879885332877,
        40.24058842086397
      ]
    },
    "10184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.949903862363062,
              40.12833263818824
            ]
          },
          "bbox": [
            -5.949903862363062,
            40.12833263818824,
            -5.949903862363062,
            40.12833263818824
          ]
        }
      ],
      "bbox": [
        -5.949903862363062,
        40.12833263818824,
        -5.949903862363062,
        40.12833263818824
      ]
    },
    "10185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.415276199045878,
              40.254548956683294
            ]
          },
          "bbox": [
            -6.415276199045878,
            40.254548956683294,
            -6.415276199045878,
            40.254548956683294
          ]
        }
      ],
      "bbox": [
        -6.415276199045878,
        40.254548956683294,
        -6.415276199045878,
        40.254548956683294
      ]
    },
    "10186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.761524459555958,
              39.581935738896
            ]
          },
          "bbox": [
            -5.761524459555958,
            39.581935738896,
            -5.761524459555958,
            39.581935738896
          ]
        }
      ],
      "bbox": [
        -5.761524459555958,
        39.581935738896,
        -5.761524459555958,
        39.581935738896
      ]
    },
    "10187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.565737132489408,
              40.21955842873959
            ]
          },
          "bbox": [
            -6.565737132489408,
            40.21955842873959,
            -6.565737132489408,
            40.21955842873959
          ]
        }
      ],
      "bbox": [
        -6.565737132489408,
        40.21955842873959,
        -6.565737132489408,
        40.21955842873959
      ]
    },
    "10188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.096088341229792,
              39.25253069095301
            ]
          },
          "bbox": [
            -6.096088341229792,
            39.25253069095301,
            -6.096088341229792,
            39.25253069095301
          ]
        }
      ],
      "bbox": [
        -6.096088341229792,
        39.25253069095301,
        -6.096088341229792,
        39.25253069095301
      ]
    },
    "10189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.46429808312985,
              39.91008301729457
            ]
          },
          "bbox": [
            -6.46429808312985,
            39.91008301729457,
            -6.46429808312985,
            39.91008301729457
          ]
        }
      ],
      "bbox": [
        -6.46429808312985,
        39.91008301729457,
        -6.46429808312985,
        39.91008301729457
      ]
    },
    "10190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9762286011925,
              39.75480012812288
            ]
          },
          "bbox": [
            -5.9762286011925,
            39.75480012812288,
            -5.9762286011925,
            39.75480012812288
          ]
        }
      ],
      "bbox": [
        -5.9762286011925,
        39.75480012812288,
        -5.9762286011925,
        39.75480012812288
      ]
    },
    "10191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.779715486802749,
              40.03595332908047
            ]
          },
          "bbox": [
            -5.779715486802749,
            40.03595332908047,
            -5.779715486802749,
            40.03595332908047
          ]
        }
      ],
      "bbox": [
        -5.779715486802749,
        40.03595332908047,
        -5.779715486802749,
        40.03595332908047
      ]
    },
    "10192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.175633415023956,
              39.32558976686527
            ]
          },
          "bbox": [
            -6.175633415023956,
            39.32558976686527,
            -6.175633415023956,
            39.32558976686527
          ]
        }
      ],
      "bbox": [
        -6.175633415023956,
        39.32558976686527,
        -6.175633415023956,
        39.32558976686527
      ]
    },
    "10193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.259831074310238,
              39.35890317717923
            ]
          },
          "bbox": [
            -6.259831074310238,
            39.35890317717923,
            -6.259831074310238,
            39.35890317717923
          ]
        }
      ],
      "bbox": [
        -6.259831074310238,
        39.35890317717923,
        -6.259831074310238,
        39.35890317717923
      ]
    },
    "10194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.231540183410839,
              39.34078860765363
            ]
          },
          "bbox": [
            -6.231540183410839,
            39.34078860765363,
            -6.231540183410839,
            39.34078860765363
          ]
        }
      ],
      "bbox": [
        -6.231540183410839,
        39.34078860765363,
        -6.231540183410839,
        39.34078860765363
      ]
    },
    "10195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9573028871343405,
              39.53607131210424
            ]
          },
          "bbox": [
            -5.9573028871343405,
            39.53607131210424,
            -5.9573028871343405,
            39.53607131210424
          ]
        }
      ],
      "bbox": [
        -5.9573028871343405,
        39.53607131210424,
        -5.9573028871343405,
        39.53607131210424
      ]
    },
    "10196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.902257793712787,
              40.14276832285591
            ]
          },
          "bbox": [
            -5.902257793712787,
            40.14276832285591,
            -5.902257793712787,
            40.14276832285591
          ]
        }
      ],
      "bbox": [
        -5.902257793712787,
        40.14276832285591,
        -5.902257793712787,
        40.14276832285591
      ]
    },
    "10197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.615495220661151,
              39.75355790303121
            ]
          },
          "bbox": [
            -5.615495220661151,
            39.75355790303121,
            -5.615495220661151,
            39.75355790303121
          ]
        }
      ],
      "bbox": [
        -5.615495220661151,
        39.75355790303121,
        -5.615495220661151,
        39.75355790303121
      ]
    },
    "10198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.12989978848765,
              39.29145220667337
            ]
          },
          "bbox": [
            -6.12989978848765,
            39.29145220667337,
            -6.12989978848765,
            39.29145220667337
          ]
        }
      ],
      "bbox": [
        -6.12989978848765,
        39.29145220667337,
        -6.12989978848765,
        39.29145220667337
      ]
    },
    "10199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.528758547135434,
              39.82783847699608
            ]
          },
          "bbox": [
            -5.528758547135434,
            39.82783847699608,
            -5.528758547135434,
            39.82783847699608
          ]
        }
      ],
      "bbox": [
        -5.528758547135434,
        39.82783847699608,
        -5.528758547135434,
        39.82783847699608
      ]
    },
    "10200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.297577921211414,
              39.71464944912019
            ]
          },
          "bbox": [
            -5.297577921211414,
            39.71464944912019,
            -5.297577921211414,
            39.71464944912019
          ]
        }
      ],
      "bbox": [
        -5.297577921211414,
        39.71464944912019,
        -5.297577921211414,
        39.71464944912019
      ]
    },
    "10201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.065626580054896,
              39.20381557023473
            ]
          },
          "bbox": [
            -6.065626580054896,
            39.20381557023473,
            -6.065626580054896,
            39.20381557023473
          ]
        }
      ],
      "bbox": [
        -6.065626580054896,
        39.20381557023473,
        -6.065626580054896,
        39.20381557023473
      ]
    },
    "10202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.23672439349416,
              40.08360538317686
            ]
          },
          "bbox": [
            -6.23672439349416,
            40.08360538317686,
            -6.23672439349416,
            40.08360538317686
          ]
        }
      ],
      "bbox": [
        -6.23672439349416,
        40.08360538317686,
        -6.23672439349416,
        40.08360538317686
      ]
    },
    "10203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.215495257041323,
              39.441334160062475
            ]
          },
          "bbox": [
            -7.215495257041323,
            39.441334160062475,
            -7.215495257041323,
            39.441334160062475
          ]
        }
      ],
      "bbox": [
        -7.215495257041323,
        39.441334160062475,
        -7.215495257041323,
        39.441334160062475
      ]
    },
    "10204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.491477982420302,
              40.12541963273588
            ]
          },
          "bbox": [
            -5.491477982420302,
            40.12541963273588,
            -5.491477982420302,
            40.12541963273588
          ]
        }
      ],
      "bbox": [
        -5.491477982420302,
        40.12541963273588,
        -5.491477982420302,
        40.12541963273588
      ]
    },
    "10205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.936906454396697,
              40.18227046938113
            ]
          },
          "bbox": [
            -6.936906454396697,
            40.18227046938113,
            -6.936906454396697,
            40.18227046938113
          ]
        }
      ],
      "bbox": [
        -6.936906454396697,
        40.18227046938113,
        -6.936906454396697,
        40.18227046938113
      ]
    },
    "10206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.541583574957177,
              40.14330124341797
            ]
          },
          "bbox": [
            -5.541583574957177,
            40.14330124341797,
            -5.541583574957177,
            40.14330124341797
          ]
        }
      ],
      "bbox": [
        -5.541583574957177,
        40.14330124341797,
        -5.541583574957177,
        40.14330124341797
      ]
    },
    "10207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.480479444866911,
              40.14728925586345
            ]
          },
          "bbox": [
            -6.480479444866911,
            40.14728925586345,
            -6.480479444866911,
            40.14728925586345
          ]
        }
      ],
      "bbox": [
        -6.480479444866911,
        40.14728925586345,
        -6.480479444866911,
        40.14728925586345
      ]
    },
    "10208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.810114618855053,
              39.65567026810991
            ]
          },
          "bbox": [
            -6.810114618855053,
            39.65567026810991,
            -6.810114618855053,
            39.65567026810991
          ]
        }
      ],
      "bbox": [
        -6.810114618855053,
        39.65567026810991,
        -6.810114618855053,
        39.65567026810991
      ]
    },
    "10209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.886484366606975,
              39.23903806502078
            ]
          },
          "bbox": [
            -5.886484366606975,
            39.23903806502078,
            -5.886484366606975,
            39.23903806502078
          ]
        }
      ],
      "bbox": [
        -5.886484366606975,
        39.23903806502078,
        -5.886484366606975,
        39.23903806502078
      ]
    },
    "10210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.803990101692119,
              40.15707998585446
            ]
          },
          "bbox": [
            -6.803990101692119,
            40.15707998585446,
            -6.803990101692119,
            40.15707998585446
          ]
        }
      ],
      "bbox": [
        -6.803990101692119,
        40.15707998585446,
        -6.803990101692119,
        40.15707998585446
      ]
    },
    "10211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.404126804645881,
              40.20459947257911
            ]
          },
          "bbox": [
            -6.404126804645881,
            40.20459947257911,
            -6.404126804645881,
            40.20459947257911
          ]
        }
      ],
      "bbox": [
        -6.404126804645881,
        40.20459947257911,
        -6.404126804645881,
        40.20459947257911
      ]
    },
    "10212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.428154800437899,
              40.16018090266751
            ]
          },
          "bbox": [
            -5.428154800437899,
            40.16018090266751,
            -5.428154800437899,
            40.16018090266751
          ]
        }
      ],
      "bbox": [
        -5.428154800437899,
        40.16018090266751,
        -5.428154800437899,
        40.16018090266751
      ]
    },
    "10213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2569261207674876,
              39.65044892861975
            ]
          },
          "bbox": [
            -5.2569261207674876,
            39.65044892861975,
            -5.2569261207674876,
            39.65044892861975
          ]
        }
      ],
      "bbox": [
        -5.2569261207674876,
        39.65044892861975,
        -5.2569261207674876,
        39.65044892861975
      ]
    },
    "10214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.042794482306417,
              40.14782197946576
            ]
          },
          "bbox": [
            -6.042794482306417,
            40.14782197946576,
            -6.042794482306417,
            40.14782197946576
          ]
        }
      ],
      "bbox": [
        -6.042794482306417,
        40.14782197946576,
        -6.042794482306417,
        40.14782197946576
      ]
    },
    "10215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.626219698111386,
              40.16398391349278
            ]
          },
          "bbox": [
            -6.626219698111386,
            40.16398391349278,
            -6.626219698111386,
            40.16398391349278
          ]
        }
      ],
      "bbox": [
        -6.626219698111386,
        40.16398391349278,
        -6.626219698111386,
        40.16398391349278
      ]
    },
    "10216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.072711602221352,
              40.26613199485715
            ]
          },
          "bbox": [
            -6.072711602221352,
            40.26613199485715,
            -6.072711602221352,
            40.26613199485715
          ]
        }
      ],
      "bbox": [
        -6.072711602221352,
        40.26613199485715,
        -6.072711602221352,
        40.26613199485715
      ]
    },
    "10217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.035776085882525,
              39.237826866888454
            ]
          },
          "bbox": [
            -6.035776085882525,
            39.237826866888454,
            -6.035776085882525,
            39.237826866888454
          ]
        }
      ],
      "bbox": [
        -6.035776085882525,
        39.237826866888454,
        -6.035776085882525,
        39.237826866888454
      ]
    },
    "10218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.833932004627783,
              39.910128437071734
            ]
          },
          "bbox": [
            -6.833932004627783,
            39.910128437071734,
            -6.833932004627783,
            39.910128437071734
          ]
        }
      ],
      "bbox": [
        -6.833932004627783,
        39.910128437071734,
        -6.833932004627783,
        39.910128437071734
      ]
    },
    "10219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.653157361303478,
              39.27679593373692
            ]
          },
          "bbox": [
            -5.653157361303478,
            39.27679593373692,
            -5.653157361303478,
            39.27679593373692
          ]
        }
      ],
      "bbox": [
        -5.653157361303478,
        39.27679593373692,
        -5.653157361303478,
        39.27679593373692
      ]
    },
    "10901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.463472567512238,
              39.98411298307105
            ]
          },
          "bbox": [
            -5.463472567512238,
            39.98411298307105,
            -5.463472567512238,
            39.98411298307105
          ]
        }
      ],
      "bbox": [
        -5.463472567512238,
        39.98411298307105,
        -5.463472567512238,
        39.98411298307105
      ]
    },
    "10902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7266117315907845,
              40.04274614159971
            ]
          },
          "bbox": [
            -6.7266117315907845,
            40.04274614159971,
            -6.7266117315907845,
            40.04274614159971
          ]
        }
      ],
      "bbox": [
        -6.7266117315907845,
        40.04274614159971,
        -6.7266117315907845,
        40.04274614159971
      ]
    },
    "10903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.31943659991267,
              39.966301863029564
            ]
          },
          "bbox": [
            -6.31943659991267,
            39.966301863029564,
            -6.31943659991267,
            39.966301863029564
          ]
        }
      ],
      "bbox": [
        -6.31943659991267,
        39.966301863029564,
        -6.31943659991267,
        39.966301863029564
      ]
    },
    "10904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4832129357635955,
              40.025570951181145
            ]
          },
          "bbox": [
            -5.4832129357635955,
            40.025570951181145,
            -5.4832129357635955,
            40.025570951181145
          ]
        }
      ],
      "bbox": [
        -5.4832129357635955,
        40.025570951181145,
        -5.4832129357635955,
        40.025570951181145
      ]
    },
    "10905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.391768976920811,
              40.070271242701196
            ]
          },
          "bbox": [
            -5.391768976920811,
            40.070271242701196,
            -5.391768976920811,
            40.070271242701196
          ]
        }
      ],
      "bbox": [
        -5.391768976920811,
        40.070271242701196,
        -5.391768976920811,
        40.070271242701196
      ]
    },
    "11001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.694559518026432,
              36.44402383905671
            ]
          },
          "bbox": [
            -5.694559518026432,
            36.44402383905671,
            -5.694559518026432,
            36.44402383905671
          ]
        }
      ],
      "bbox": [
        -5.694559518026432,
        36.44402383905671,
        -5.694559518026432,
        36.44402383905671
      ]
    },
    "11002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.149522053786035,
              36.92159111956168
            ]
          },
          "bbox": [
            -5.149522053786035,
            36.92159111956168,
            -5.149522053786035,
            36.92159111956168
          ]
        }
      ],
      "bbox": [
        -5.149522053786035,
        36.92159111956168,
        -5.149522053786035,
        36.92159111956168
      ]
    },
    "11003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.644889448444595,
              36.65510748096262
            ]
          },
          "bbox": [
            -5.644889448444595,
            36.65510748096262,
            -5.644889448444595,
            36.65510748096262
          ]
        }
      ],
      "bbox": [
        -5.644889448444595,
        36.65510748096262,
        -5.644889448444595,
        36.65510748096262
      ]
    },
    "11004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4781861414351205,
              36.10729447036141
            ]
          },
          "bbox": [
            -5.4781861414351205,
            36.10729447036141,
            -5.4781861414351205,
            36.10729447036141
          ]
        }
      ],
      "bbox": [
        -5.4781861414351205,
        36.10729447036141,
        -5.4781861414351205,
        36.10729447036141
      ]
    },
    "11005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.434090106113419,
              36.89109774760999
            ]
          },
          "bbox": [
            -5.434090106113419,
            36.89109774760999,
            -5.434090106113419,
            36.89109774760999
          ]
        }
      ],
      "bbox": [
        -5.434090106113419,
        36.89109774760999,
        -5.434090106113419,
        36.89109774760999
      ]
    },
    "11006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.754603678218338,
              36.74349714963984
            ]
          },
          "bbox": [
            -5.754603678218338,
            36.74349714963984,
            -5.754603678218338,
            36.74349714963984
          ]
        }
      ],
      "bbox": [
        -5.754603678218338,
        36.74349714963984,
        -5.754603678218338,
        36.74349714963984
      ]
    },
    "11007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.899464027787922,
              36.20023797562168
            ]
          },
          "bbox": [
            -5.899464027787922,
            36.20023797562168,
            -5.899464027787922,
            36.20023797562168
          ]
        }
      ],
      "bbox": [
        -5.899464027787922,
        36.20023797562168,
        -5.899464027787922,
        36.20023797562168
      ]
    },
    "11008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.558788042873224,
              36.22068072952156
            ]
          },
          "bbox": [
            -5.558788042873224,
            36.22068072952156,
            -5.558788042873224,
            36.22068072952156
          ]
        }
      ],
      "bbox": [
        -5.558788042873224,
        36.22068072952156,
        -5.558788042873224,
        36.22068072952156
      ]
    },
    "11009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.452560513583094,
              36.71240533224827
            ]
          },
          "bbox": [
            -5.452560513583094,
            36.71240533224827,
            -5.452560513583094,
            36.71240533224827
          ]
        }
      ],
      "bbox": [
        -5.452560513583094,
        36.71240533224827,
        -5.452560513583094,
        36.71240533224827
      ]
    },
    "11010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.712567543642617,
              36.84920279843438
            ]
          },
          "bbox": [
            -5.712567543642617,
            36.84920279843438,
            -5.712567543642617,
            36.84920279843438
          ]
        }
      ],
      "bbox": [
        -5.712567543642617,
        36.84920279843438,
        -5.712567543642617,
        36.84920279843438
      ]
    },
    "11011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5108839776229965,
              36.753607818246664
            ]
          },
          "bbox": [
            -5.5108839776229965,
            36.753607818246664,
            -5.5108839776229965,
            36.753607818246664
          ]
        }
      ],
      "bbox": [
        -5.5108839776229965,
        36.753607818246664,
        -5.5108839776229965,
        36.753607818246664
      ]
    },
    "11012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.266728905933022,
              36.495018109670156
            ]
          },
          "bbox": [
            -6.266728905933022,
            36.495018109670156,
            -6.266728905933022,
            36.495018109670156
          ]
        }
      ],
      "bbox": [
        -6.266728905933022,
        36.495018109670156,
        -6.266728905933022,
        36.495018109670156
      ]
    },
    "11013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.464740199217635,
              36.311569252239565
            ]
          },
          "bbox": [
            -5.464740199217635,
            36.311569252239565,
            -5.464740199217635,
            36.311569252239565
          ]
        }
      ],
      "bbox": [
        -5.464740199217635,
        36.311569252239565,
        -5.464740199217635,
        36.311569252239565
      ]
    },
    "11014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.078025510332971,
              36.31144937011278
            ]
          },
          "bbox": [
            -6.078025510332971,
            36.31144937011278,
            -6.078025510332971,
            36.31144937011278
          ]
        }
      ],
      "bbox": [
        -6.078025510332971,
        36.31144937011278,
        -6.078025510332971,
        36.31144937011278
      ]
    },
    "11015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1101395354435235,
              36.39973487470842
            ]
          },
          "bbox": [
            -6.1101395354435235,
            36.39973487470842,
            -6.1101395354435235,
            36.39973487470842
          ]
        }
      ],
      "bbox": [
        -6.1101395354435235,
        36.39973487470842,
        -6.1101395354435235,
        36.39973487470842
      ]
    },
    "11016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.407033193894981,
              36.72665666074608
            ]
          },
          "bbox": [
            -6.407033193894981,
            36.72665666074608,
            -6.407033193894981,
            36.72665666074608
          ]
        }
      ],
      "bbox": [
        -6.407033193894981,
        36.72665666074608,
        -6.407033193894981,
        36.72665666074608
      ]
    },
    "11017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.801524724215534,
              36.87857350027411
            ]
          },
          "bbox": [
            -5.801524724215534,
            36.87857350027411,
            -5.801524724215534,
            36.87857350027411
          ]
        }
      ],
      "bbox": [
        -5.801524724215534,
        36.87857350027411,
        -5.801524724215534,
        36.87857350027411
      ]
    },
    "11018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.345222924835724,
              36.84645511972608
            ]
          },
          "bbox": [
            -5.345222924835724,
            36.84645511972608,
            -5.345222924835724,
            36.84645511972608
          ]
        }
      ],
      "bbox": [
        -5.345222924835724,
        36.84645511972608,
        -5.345222924835724,
        36.84645511972608
      ]
    },
    "11019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.38953608857326,
              36.77092799647957
            ]
          },
          "bbox": [
            -5.38953608857326,
            36.77092799647957,
            -5.38953608857326,
            36.77092799647957
          ]
        }
      ],
      "bbox": [
        -5.38953608857326,
        36.77092799647957,
        -5.38953608857326,
        36.77092799647957
      ]
    },
    "11020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.966684679916416,
              36.68194214902897
            ]
          },
          "bbox": [
            -5.966684679916416,
            36.68194214902897,
            -5.966684679916416,
            36.68194214902897
          ]
        }
      ],
      "bbox": [
        -5.966684679916416,
        36.68194214902897,
        -5.966684679916416,
        36.68194214902897
      ]
    },
    "11021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.473149258424848,
              36.438597793911036
            ]
          },
          "bbox": [
            -5.473149258424848,
            36.438597793911036,
            -5.473149258424848,
            36.438597793911036
          ]
        }
      ],
      "bbox": [
        -5.473149258424848,
        36.438597793911036,
        -5.473149258424848,
        36.438597793911036
      ]
    },
    "11022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.341520301794473,
              36.20162202559084
            ]
          },
          "bbox": [
            -5.341520301794473,
            36.20162202559084,
            -5.341520301794473,
            36.20162202559084
          ]
        }
      ],
      "bbox": [
        -5.341520301794473,
        36.20162202559084,
        -5.341520301794473,
        36.20162202559084
      ]
    },
    "11023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.867221885754755,
              36.39651617278028
            ]
          },
          "bbox": [
            -5.867221885754755,
            36.39651617278028,
            -5.867221885754755,
            36.39651617278028
          ]
        }
      ],
      "bbox": [
        -5.867221885754755,
        36.39651617278028,
        -5.867221885754755,
        36.39651617278028
      ]
    },
    "11024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.281781548719058,
              36.95080151787451
            ]
          },
          "bbox": [
            -5.281781548719058,
            36.95080151787451,
            -5.281781548719058,
            36.95080151787451
          ]
        }
      ],
      "bbox": [
        -5.281781548719058,
        36.95080151787451,
        -5.281781548719058,
        36.95080151787451
      ]
    },
    "11025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.853228908260984,
              36.52249739202726
            ]
          },
          "bbox": [
            -5.853228908260984,
            36.52249739202726,
            -5.853228908260984,
            36.52249739202726
          ]
        }
      ],
      "bbox": [
        -5.853228908260984,
        36.52249739202726,
        -5.853228908260984,
        36.52249739202726
      ]
    },
    "11026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.546077487896015,
              36.7836562442402
            ]
          },
          "bbox": [
            -5.546077487896015,
            36.7836562442402,
            -5.546077487896015,
            36.7836562442402
          ]
        }
      ],
      "bbox": [
        -5.546077487896015,
        36.7836562442402,
        -5.546077487896015,
        36.7836562442402
      ]
    },
    "11027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.235096178179756,
              36.631407448148956
            ]
          },
          "bbox": [
            -6.235096178179756,
            36.631407448148956,
            -6.235096178179756,
            36.631407448148956
          ]
        }
      ],
      "bbox": [
        -6.235096178179756,
        36.631407448148956,
        -6.235096178179756,
        36.631407448148956
      ]
    },
    "11028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.111326534539114,
              36.52348878256483
            ]
          },
          "bbox": [
            -6.111326534539114,
            36.52348878256483,
            -6.111326534539114,
            36.52348878256483
          ]
        }
      ],
      "bbox": [
        -6.111326534539114,
        36.52348878256483,
        -6.111326534539114,
        36.52348878256483
      ]
    },
    "11029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.47625571945191,
              36.97912113411646
            ]
          },
          "bbox": [
            -5.47625571945191,
            36.97912113411646,
            -5.47625571945191,
            36.97912113411646
          ]
        }
      ],
      "bbox": [
        -5.47625571945191,
        36.97912113411646,
        -5.47625571945191,
        36.97912113411646
      ]
    },
    "11030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.355389746016574,
              36.66779273709409
            ]
          },
          "bbox": [
            -6.355389746016574,
            36.66779273709409,
            -6.355389746016574,
            36.66779273709409
          ]
        }
      ],
      "bbox": [
        -6.355389746016574,
        36.66779273709409,
        -6.355389746016574,
        36.66779273709409
      ]
    },
    "11031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2048242266110085,
              36.453486326662585
            ]
          },
          "bbox": [
            -6.2048242266110085,
            36.453486326662585,
            -6.2048242266110085,
            36.453486326662585
          ]
        }
      ],
      "bbox": [
        -6.2048242266110085,
        36.453486326662585,
        -6.2048242266110085,
        36.453486326662585
      ]
    },
    "11032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.308873455676294,
              36.80635825518264
            ]
          },
          "bbox": [
            -6.308873455676294,
            36.80635825518264,
            -6.308873455676294,
            36.80635825518264
          ]
        }
      ],
      "bbox": [
        -6.308873455676294,
        36.80635825518264,
        -6.308873455676294,
        36.80635825518264
      ]
    },
    "11033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.351294956062096,
              36.25882364700208
            ]
          },
          "bbox": [
            -5.351294956062096,
            36.25882364700208,
            -5.351294956062096,
            36.25882364700208
          ]
        }
      ],
      "bbox": [
        -5.351294956062096,
        36.25882364700208,
        -5.351294956062096,
        36.25882364700208
      ]
    },
    "11034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.176183346825668,
              36.87133190347975
            ]
          },
          "bbox": [
            -5.176183346825668,
            36.87133190347975,
            -5.176183346825668,
            36.87133190347975
          ]
        }
      ],
      "bbox": [
        -5.176183346825668,
        36.87133190347975,
        -5.176183346825668,
        36.87133190347975
      ]
    },
    "11035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.683668311705968,
              36.12577816444238
            ]
          },
          "bbox": [
            -5.683668311705968,
            36.12577816444238,
            -5.683668311705968,
            36.12577816444238
          ]
        }
      ],
      "bbox": [
        -5.683668311705968,
        36.12577816444238,
        -5.683668311705968,
        36.12577816444238
      ]
    },
    "11036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.23114036971518,
              36.91061597480693
            ]
          },
          "bbox": [
            -5.23114036971518,
            36.91061597480693,
            -5.23114036971518,
            36.91061597480693
          ]
        }
      ],
      "bbox": [
        -5.23114036971518,
        36.91061597480693,
        -5.23114036971518,
        36.91061597480693
      ]
    },
    "11037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.215301850075251,
              36.87068436330698
            ]
          },
          "bbox": [
            -6.215301850075251,
            36.87068436330698,
            -6.215301850075251,
            36.87068436330698
          ]
        }
      ],
      "bbox": [
        -6.215301850075251,
        36.87068436330698,
        -6.215301850075251,
        36.87068436330698
      ]
    },
    "11038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.463733430232974,
              36.65858347637937
            ]
          },
          "bbox": [
            -5.463733430232974,
            36.65858347637937,
            -5.463733430232974,
            36.65858347637937
          ]
        }
      ],
      "bbox": [
        -5.463733430232974,
        36.65858347637937,
        -5.463733430232974,
        36.65858347637937
      ]
    },
    "11039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.941971044758901,
              36.28193148152169
            ]
          },
          "bbox": [
            -5.941971044758901,
            36.28193148152169,
            -5.941971044758901,
            36.28193148152169
          ]
        }
      ],
      "bbox": [
        -5.941971044758901,
        36.28193148152169,
        -5.941971044758901,
        36.28193148152169
      ]
    },
    "11040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.367125449012591,
              36.68639243360572
            ]
          },
          "bbox": [
            -5.367125449012591,
            36.68639243360572,
            -5.367125449012591,
            36.68639243360572
          ]
        }
      ],
      "bbox": [
        -5.367125449012591,
        36.68639243360572,
        -5.367125449012591,
        36.68639243360572
      ]
    },
    "11041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.616579848406925,
              36.8729512546709
            ]
          },
          "bbox": [
            -5.616579848406925,
            36.8729512546709,
            -5.616579848406925,
            36.8729512546709
          ]
        }
      ],
      "bbox": [
        -5.616579848406925,
        36.8729512546709,
        -5.616579848406925,
        36.8729512546709
      ]
    },
    "11042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.444860992010922,
              36.82995045301959
            ]
          },
          "bbox": [
            -5.444860992010922,
            36.82995045301959,
            -5.444860992010922,
            36.82995045301959
          ]
        }
      ],
      "bbox": [
        -5.444860992010922,
        36.82995045301959,
        -5.444860992010922,
        36.82995045301959
      ]
    },
    "11901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.775397499245149,
              36.33175957880045
            ]
          },
          "bbox": [
            -5.775397499245149,
            36.33175957880045,
            -5.775397499245149,
            36.33175957880045
          ]
        }
      ],
      "bbox": [
        -5.775397499245149,
        36.33175957880045,
        -5.775397499245149,
        36.33175957880045
      ]
    },
    "11902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.741728238805911,
              36.61679507474606
            ]
          },
          "bbox": [
            -5.741728238805911,
            36.61679507474606,
            -5.741728238805911,
            36.61679507474606
          ]
        }
      ],
      "bbox": [
        -5.741728238805911,
        36.61679507474606,
        -5.741728238805911,
        36.61679507474606
      ]
    },
    "11903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.363085929027444,
              36.35892689632605
            ]
          },
          "bbox": [
            -5.363085929027444,
            36.35892689632605,
            -5.363085929027444,
            36.35892689632605
          ]
        }
      ],
      "bbox": [
        -5.363085929027444,
        36.35892689632605,
        -5.363085929027444,
        36.35892689632605
      ]
    },
    "12001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1858109329445633,
              40.234725475173285
            ]
          },
          "bbox": [
            -0.1858109329445633,
            40.234725475173285,
            -0.1858109329445633,
            40.234725475173285
          ]
        }
      ],
      "bbox": [
        -0.1858109329445633,
        40.234725475173285,
        -0.1858109329445633,
        40.234725475173285
      ]
    },
    "12002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3487880477050723,
              39.89382839418709
            ]
          },
          "bbox": [
            -0.3487880477050723,
            39.89382839418709,
            -0.3487880477050723,
            39.89382839418709
          ]
        }
      ],
      "bbox": [
        -0.3487880477050723,
        39.89382839418709,
        -0.3487880477050723,
        39.89382839418709
      ]
    },
    "12003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.017018794363375313,
              40.3730860896886
            ]
          },
          "bbox": [
            0.017018794363375313,
            40.3730860896886,
            0.017018794363375313,
            40.3730860896886
          ]
        }
      ],
      "bbox": [
        0.017018794363375313,
        40.3730860896886,
        0.017018794363375313,
        40.3730860896886
      ]
    },
    "12004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.23126720588257677,
              40.29589523315082
            ]
          },
          "bbox": [
            0.23126720588257677,
            40.29589523315082,
            0.23126720588257677,
            40.29589523315082
          ]
        }
      ],
      "bbox": [
        0.23126720588257677,
        40.29589523315082,
        0.23126720588257677,
        40.29589523315082
      ]
    },
    "12006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.34475355118656703,
              39.920875627793315
            ]
          },
          "bbox": [
            -0.34475355118656703,
            39.920875627793315,
            -0.34475355118656703,
            39.920875627793315
          ]
        }
      ],
      "bbox": [
        -0.34475355118656703,
        39.920875627793315,
        -0.34475355118656703,
        39.920875627793315
      ]
    },
    "12007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.28146063949362415,
              39.83861546103143
            ]
          },
          "bbox": [
            -0.28146063949362415,
            39.83861546103143,
            -0.28146063949362415,
            39.83861546103143
          ]
        }
      ],
      "bbox": [
        -0.28146063949362415,
        39.83861546103143,
        -0.28146063949362415,
        39.83861546103143
      ]
    },
    "12008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.420810206810683,
              39.91543076575995
            ]
          },
          "bbox": [
            -0.420810206810683,
            39.91543076575995,
            -0.420810206810683,
            39.91543076575995
          ]
        }
      ],
      "bbox": [
        -0.420810206810683,
        39.91543076575995,
        -0.420810206810683,
        39.91543076575995
      ]
    },
    "12009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.06766427616304384,
              39.95098484937498
            ]
          },
          "bbox": [
            -0.06766427616304384,
            39.95098484937498,
            -0.06766427616304384,
            39.95098484937498
          ]
        }
      ],
      "bbox": [
        -0.06766427616304384,
        39.95098484937498,
        -0.06766427616304384,
        39.95098484937498
      ]
    },
    "12010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4079848375550747,
              39.87227061180132
            ]
          },
          "bbox": [
            -0.4079848375550747,
            39.87227061180132,
            -0.4079848375550747,
            39.87227061180132
          ]
        }
      ],
      "bbox": [
        -0.4079848375550747,
        39.87227061180132,
        -0.4079848375550747,
        39.87227061180132
      ]
    },
    "12011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2263247583293555,
              39.751228869601825
            ]
          },
          "bbox": [
            -0.2263247583293555,
            39.751228869601825,
            -0.2263247583293555,
            39.751228869601825
          ]
        }
      ],
      "bbox": [
        -0.2263247583293555,
        39.751228869601825,
        -0.2263247583293555,
        39.751228869601825
      ]
    },
    "12012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5845885215626143,
              39.81486542438263
            ]
          },
          "bbox": [
            -0.5845885215626143,
            39.81486542438263,
            -0.5845885215626143,
            39.81486542438263
          ]
        }
      ],
      "bbox": [
        -0.5845885215626143,
        39.81486542438263,
        -0.5845885215626143,
        39.81486542438263
      ]
    },
    "12013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.48852389976132976,
              40.08332174176935
            ]
          },
          "bbox": [
            -0.48852389976132976,
            40.08332174176935,
            -0.48852389976132976,
            40.08332174176935
          ]
        }
      ],
      "bbox": [
        -0.48852389976132976,
        40.08332174176935,
        -0.48852389976132976,
        40.08332174176935
      ]
    },
    "12014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.10971003454653186,
              40.44467096872386
            ]
          },
          "bbox": [
            -0.10971003454653186,
            40.44467096872386,
            -0.10971003454653186,
            40.44467096872386
          ]
        }
      ],
      "bbox": [
        -0.10971003454653186,
        40.44467096872386,
        -0.10971003454653186,
        40.44467096872386
      ]
    },
    "12015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.34080819505700616,
              40.06713633197527
            ]
          },
          "bbox": [
            -0.34080819505700616,
            40.06713633197527,
            -0.34080819505700616,
            40.06713633197527
          ]
        }
      ],
      "bbox": [
        -0.34080819505700616,
        40.06713633197527,
        -0.34080819505700616,
        40.06713633197527
      ]
    },
    "12016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24665710681666148,
              39.895555600902966
            ]
          },
          "bbox": [
            -0.24665710681666148,
            39.895555600902966,
            -0.24665710681666148,
            39.895555600902966
          ]
        }
      ],
      "bbox": [
        -0.24665710681666148,
        39.895555600902966,
        -0.24665710681666148,
        39.895555600902966
      ]
    },
    "12017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.38495368083907855,
              39.991957640276766
            ]
          },
          "bbox": [
            -0.38495368083907855,
            39.991957640276766,
            -0.38495368083907855,
            39.991957640276766
          ]
        }
      ],
      "bbox": [
        -0.38495368083907855,
        39.991957640276766,
        -0.38495368083907855,
        39.991957640276766
      ]
    },
    "12018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3665061316518919,
              39.85065729273307
            ]
          },
          "bbox": [
            -0.3665061316518919,
            39.85065729273307,
            -0.3665061316518919,
            39.85065729273307
          ]
        }
      ],
      "bbox": [
        -0.3665061316518919,
        39.85065729273307,
        -0.3665061316518919,
        39.85065729273307
      ]
    },
    "12020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6961447139363802,
              39.998706657535855
            ]
          },
          "bbox": [
            -0.6961447139363802,
            39.998706657535855,
            -0.6961447139363802,
            39.998706657535855
          ]
        }
      ],
      "bbox": [
        -0.6961447139363802,
        39.998706657535855,
        -0.6961447139363802,
        39.998706657535855
      ]
    },
    "12021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.19605074512222612,
              39.921321688677445
            ]
          },
          "bbox": [
            -0.19605074512222612,
            39.921321688677445,
            -0.19605074512222612,
            39.921321688677445
          ]
        }
      ],
      "bbox": [
        -0.19605074512222612,
        39.921321688677445,
        -0.19605074512222612,
        39.921321688677445
      ]
    },
    "12022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7365798212986899,
              39.900992108548195
            ]
          },
          "bbox": [
            -0.7365798212986899,
            39.900992108548195,
            -0.7365798212986899,
            39.900992108548195
          ]
        }
      ],
      "bbox": [
        -0.7365798212986899,
        39.900992108548195,
        -0.7365798212986899,
        39.900992108548195
      ]
    },
    "12024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6056932198128895,
              39.97023748943096
            ]
          },
          "bbox": [
            -0.6056932198128895,
            39.97023748943096,
            -0.6056932198128895,
            39.97023748943096
          ]
        }
      ],
      "bbox": [
        -0.6056932198128895,
        39.97023748943096,
        -0.6056932198128895,
        39.97023748943096
      ]
    },
    "12025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1953119554859537,
              40.27323566404854
            ]
          },
          "bbox": [
            -0.1953119554859537,
            40.27323566404854,
            -0.1953119554859537,
            40.27323566404854
          ]
        }
      ],
      "bbox": [
        -0.1953119554859537,
        40.27323566404854,
        -0.1953119554859537,
        40.27323566404854
      ]
    },
    "12026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.16794302235950406,
              40.38647073557089
            ]
          },
          "bbox": [
            -0.16794302235950406,
            40.38647073557089,
            -0.16794302235950406,
            40.38647073557089
          ]
        }
      ],
      "bbox": [
        -0.16794302235950406,
        40.38647073557089,
        -0.16794302235950406,
        40.38647073557089
      ]
    },
    "12027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4025478713562125,
              40.443559578892604
            ]
          },
          "bbox": [
            0.4025478713562125,
            40.443559578892604,
            0.4025478713562125,
            40.443559578892604
          ]
        }
      ],
      "bbox": [
        0.4025478713562125,
        40.443559578892604,
        0.4025478713562125,
        40.443559578892604
      ]
    },
    "12028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.042238967432277445,
              40.056260690664665
            ]
          },
          "bbox": [
            0.042238967432277445,
            40.056260690664665,
            0.042238967432277445,
            40.056260690664665
          ]
        }
      ],
      "bbox": [
        0.042238967432277445,
        40.056260690664665,
        0.042238967432277445,
        40.056260690664665
      ]
    },
    "12029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.057563495788506704,
              40.20782703578202
            ]
          },
          "bbox": [
            0.057563495788506704,
            40.20782703578202,
            0.057563495788506704,
            40.20782703578202
          ]
        }
      ],
      "bbox": [
        0.057563495788506704,
        40.20782703578202,
        0.057563495788506704,
        40.20782703578202
      ]
    },
    "12031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.07010581128177333,
              40.05069661583346
            ]
          },
          "bbox": [
            -0.07010581128177333,
            40.05069661583346,
            -0.07010581128177333,
            40.05069661583346
          ]
        }
      ],
      "bbox": [
        -0.07010581128177333,
        40.05069661583346,
        -0.07010581128177333,
        40.05069661583346
      ]
    },
    "12032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.07467625725290704,
              39.88967251770509
            ]
          },
          "bbox": [
            -0.07467625725290704,
            39.88967251770509,
            -0.07467625725290704,
            39.88967251770509
          ]
        }
      ],
      "bbox": [
        -0.07467625725290704,
        39.88967251770509,
        -0.07467625725290704,
        39.88967251770509
      ]
    },
    "12033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.09384122494867579,
              40.144362362120326
            ]
          },
          "bbox": [
            0.09384122494867579,
            40.144362362120326,
            0.09384122494867579,
            40.144362362120326
          ]
        }
      ],
      "bbox": [
        0.09384122494867579,
        40.144362362120326,
        0.09384122494867579,
        40.144362362120326
      ]
    },
    "12034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3525118800227583,
              40.467874940639796
            ]
          },
          "bbox": [
            0.3525118800227583,
            40.467874940639796,
            0.3525118800227583,
            40.467874940639796
          ]
        }
      ],
      "bbox": [
        0.3525118800227583,
        40.467874940639796,
        0.3525118800227583,
        40.467874940639796
      ]
    },
    "12036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2334802413415532,
              40.56143723374234
            ]
          },
          "bbox": [
            0.2334802413415532,
            40.56143723374234,
            0.2334802413415532,
            40.56143723374234
          ]
        }
      ],
      "bbox": [
        0.2334802413415532,
        40.56143723374234,
        0.2334802413415532,
        40.56143723374234
      ]
    },
    "12037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.042755722242432105,
              40.669759567834
            ]
          },
          "bbox": [
            0.042755722242432105,
            40.669759567834,
            0.042755722242432105,
            40.669759567834
          ]
        }
      ],
      "bbox": [
        0.042755722242432105,
        40.669759567834,
        0.042755722242432105,
        40.669759567834
      ]
    },
    "12038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1993574243453931,
              40.50614225891213
            ]
          },
          "bbox": [
            -0.1993574243453931,
            40.50614225891213,
            -0.1993574243453931,
            40.50614225891213
          ]
        }
      ],
      "bbox": [
        -0.1993574243453931,
        40.50614225891213,
        -0.1993574243453931,
        40.50614225891213
      ]
    },
    "12039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4484639062092239,
              39.8543738255282
            ]
          },
          "bbox": [
            -0.4484639062092239,
            39.8543738255282,
            -0.4484639062092239,
            39.8543738255282
          ]
        }
      ],
      "bbox": [
        -0.4484639062092239,
        39.8543738255282,
        -0.4484639062092239,
        39.8543738255282
      ]
    },
    "12040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.04342438997047339,
              39.99734804521916
            ]
          },
          "bbox": [
            -0.04342438997047339,
            39.99734804521916,
            -0.04342438997047339,
            39.99734804521916
          ]
        }
      ],
      "bbox": [
        -0.04342438997047339,
        39.99734804521916,
        -0.04342438997047339,
        39.99734804521916
      ]
    },
    "12041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3669294685268881,
              40.1460177212065
            ]
          },
          "bbox": [
            -0.3669294685268881,
            40.1460177212065,
            -0.3669294685268881,
            40.1460177212065
          ]
        }
      ],
      "bbox": [
        -0.3669294685268881,
        40.1460177212065,
        -0.3669294685268881,
        40.1460177212065
      ]
    },
    "12042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.019982241717501643,
              40.47446968150052
            ]
          },
          "bbox": [
            0.019982241717501643,
            40.47446968150052,
            0.019982241717501643,
            40.47446968150052
          ]
        }
      ],
      "bbox": [
        0.019982241717501643,
        40.47446968150052,
        0.019982241717501643,
        40.47446968150052
      ]
    },
    "12043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5719196415084317,
              39.986829599933465
            ]
          },
          "bbox": [
            -0.5719196415084317,
            39.986829599933465,
            -0.5719196415084317,
            39.986829599933465
          ]
        }
      ],
      "bbox": [
        -0.5719196415084317,
        39.986829599933465,
        -0.5719196415084317,
        39.986829599933465
      ]
    },
    "12044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2821711086181753,
              40.44335575991394
            ]
          },
          "bbox": [
            0.2821711086181753,
            40.44335575991394,
            0.2821711086181753,
            40.44335575991394
          ]
        }
      ],
      "bbox": [
        0.2821711086181753,
        40.44335575991394,
        0.2821711086181753,
        40.44335575991394
      ]
    },
    "12045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.21952404298884212,
              40.5763225335028
            ]
          },
          "bbox": [
            -0.21952404298884212,
            40.5763225335028,
            -0.21952404298884212,
            40.5763225335028
          ]
        }
      ],
      "bbox": [
        -0.21952404298884212,
        40.5763225335028,
        -0.21952404298884212,
        40.5763225335028
      ]
    },
    "12046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.45564953847236284,
              40.051166187667626
            ]
          },
          "bbox": [
            -0.45564953847236284,
            40.051166187667626,
            -0.45564953847236284,
            40.051166187667626
          ]
        }
      ],
      "bbox": [
        -0.45564953847236284,
        40.051166187667626,
        -0.45564953847236284,
        40.051166187667626
      ]
    },
    "12048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5288793007952615,
              40.171643712953745
            ]
          },
          "bbox": [
            -0.5288793007952615,
            40.171643712953745,
            -0.5288793007952615,
            40.171643712953745
          ]
        }
      ],
      "bbox": [
        -0.5288793007952615,
        40.171643712953745,
        -0.5288793007952615,
        40.171643712953745
      ]
    },
    "12049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.16244442051759267,
              40.121681337501215
            ]
          },
          "bbox": [
            -0.16244442051759267,
            40.121681337501215,
            -0.16244442051759267,
            40.121681337501215
          ]
        }
      ],
      "bbox": [
        -0.16244442051759267,
        40.121681337501215,
        -0.16244442051759267,
        40.121681337501215
      ]
    },
    "12050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.11826745325685292,
              40.32374273982216
            ]
          },
          "bbox": [
            0.11826745325685292,
            40.32374273982216,
            0.11826745325685292,
            40.32374273982216
          ]
        }
      ],
      "bbox": [
        0.11826745325685292,
        40.32374273982216,
        0.11826745325685292,
        40.32374273982216
      ]
    },
    "12051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12343368872972263,
              40.30814045696881
            ]
          },
          "bbox": [
            -0.12343368872972263,
            40.30814045696881,
            -0.12343368872972263,
            40.30814045696881
          ]
        }
      ],
      "bbox": [
        -0.12343368872972263,
        40.30814045696881,
        -0.12343368872972263,
        40.30814045696881
      ]
    },
    "12052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.1342034248594232,
              40.5339553021314
            ]
          },
          "bbox": [
            0.1342034248594232,
            40.5339553021314,
            0.1342034248594232,
            40.5339553021314
          ]
        }
      ],
      "bbox": [
        0.1342034248594232,
        40.5339553021314,
        0.1342034248594232,
        40.5339553021314
      ]
    },
    "12053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.18098823752105372,
              39.7796755870193
            ]
          },
          "bbox": [
            -0.18098823752105372,
            39.7796755870193,
            -0.18098823752105372,
            39.7796755870193
          ]
        }
      ],
      "bbox": [
        -0.18098823752105372,
        39.7796755870193,
        -0.18098823752105372,
        39.7796755870193
      ]
    },
    "12055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.28966529091300297,
              40.2308150140035
            ]
          },
          "bbox": [
            -0.28966529091300297,
            40.2308150140035,
            -0.28966529091300297,
            40.2308150140035
          ]
        }
      ],
      "bbox": [
        -0.28966529091300297,
        40.2308150140035,
        -0.28966529091300297,
        40.2308150140035
      ]
    },
    "12056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.32402460891029483,
              39.84915227158979
            ]
          },
          "bbox": [
            -0.32402460891029483,
            39.84915227158979,
            -0.32402460891029483,
            39.84915227158979
          ]
        }
      ],
      "bbox": [
        -0.32402460891029483,
        39.84915227158979,
        -0.32402460891029483,
        39.84915227158979
      ]
    },
    "12057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.30506396242275535,
              39.88698902594284
            ]
          },
          "bbox": [
            -0.30506396242275535,
            39.88698902594284,
            -0.30506396242275535,
            39.88698902594284
          ]
        }
      ],
      "bbox": [
        -0.30506396242275535,
        39.88698902594284,
        -0.30506396242275535,
        39.88698902594284
      ]
    },
    "12058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3660294249714704,
              40.02083761135529
            ]
          },
          "bbox": [
            -0.3660294249714704,
            40.02083761135529,
            -0.3660294249714704,
            40.02083761135529
          ]
        }
      ],
      "bbox": [
        -0.3660294249714704,
        40.02083761135529,
        -0.3660294249714704,
        40.02083761135529
      ]
    },
    "12059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3152784484313083,
              40.01257133678967
            ]
          },
          "bbox": [
            -0.3152784484313083,
            40.01257133678967,
            -0.3152784484313083,
            40.01257133678967
          ]
        }
      ],
      "bbox": [
        -0.3152784484313083,
        40.01257133678967,
        -0.3152784484313083,
        40.01257133678967
      ]
    },
    "12060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.22212375178660757,
              40.12652410664217
            ]
          },
          "bbox": [
            -0.22212375178660757,
            40.12652410664217,
            -0.22212375178660757,
            40.12652410664217
          ]
        }
      ],
      "bbox": [
        -0.22212375178660757,
        40.12652410664217,
        -0.22212375178660757,
        40.12652410664217
      ]
    },
    "12061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2122875563632146,
              40.657207187732574
            ]
          },
          "bbox": [
            -0.2122875563632146,
            40.657207187732574,
            -0.2122875563632146,
            40.657207187732574
          ]
        }
      ],
      "bbox": [
        -0.2122875563632146,
        40.657207187732574,
        -0.2122875563632146,
        40.657207187732574
      ]
    },
    "12063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6090860314774169,
              40.05699336991174
            ]
          },
          "bbox": [
            -0.6090860314774169,
            40.05699336991174,
            -0.6090860314774169,
            40.05699336991174
          ]
        }
      ],
      "bbox": [
        -0.6090860314774169,
        40.05699336991174,
        -0.6090860314774169,
        40.05699336991174
      ]
    },
    "12064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4255292201105597,
              40.02068823604993
            ]
          },
          "bbox": [
            -0.4255292201105597,
            40.02068823604993,
            -0.4255292201105597,
            40.02068823604993
          ]
        }
      ],
      "bbox": [
        -0.4255292201105597,
        40.02068823604993,
        -0.4255292201105597,
        40.02068823604993
      ]
    },
    "12065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.49935786070116517,
              39.929768997890875
            ]
          },
          "bbox": [
            -0.49935786070116517,
            39.929768997890875,
            -0.49935786070116517,
            39.929768997890875
          ]
        }
      ],
      "bbox": [
        -0.49935786070116517,
        39.929768997890875,
        -0.49935786070116517,
        39.929768997890875
      ]
    },
    "12067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.46551763410940555,
              39.83597342950974
            ]
          },
          "bbox": [
            -0.46551763410940555,
            39.83597342950974,
            -0.46551763410940555,
            39.83597342950974
          ]
        }
      ],
      "bbox": [
        -0.46551763410940555,
        39.83597342950974,
        -0.46551763410940555,
        39.83597342950974
      ]
    },
    "12068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.015050678820108389,
              40.70877810290974
            ]
          },
          "bbox": [
            -0.015050678820108389,
            40.70877810290974,
            -0.015050678820108389,
            40.70877810290974
          ]
        }
      ],
      "bbox": [
        -0.015050678820108389,
        40.70877810290974,
        -0.015050678820108389,
        40.70877810290974
      ]
    },
    "12069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5087473317948749,
              39.99174701971677
            ]
          },
          "bbox": [
            -0.5087473317948749,
            39.99174701971677,
            -0.5087473317948749,
            39.99174701971677
          ]
        }
      ],
      "bbox": [
        -0.5087473317948749,
        39.99174701971677,
        -0.5087473317948749,
        39.99174701971677
      ]
    },
    "12070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.23862243280880943,
              40.50230296480043
            ]
          },
          "bbox": [
            0.23862243280880943,
            40.50230296480043,
            0.23862243280880943,
            40.50230296480043
          ]
        }
      ],
      "bbox": [
        0.23862243280880943,
        40.50230296480043,
        0.23862243280880943,
        40.50230296480043
      ]
    },
    "12071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5845535659460954,
              39.88967796648467
            ]
          },
          "bbox": [
            -0.5845535659460954,
            39.88967796648467,
            -0.5845535659460954,
            39.88967796648467
          ]
        }
      ],
      "bbox": [
        -0.5845535659460954,
        39.88967796648467,
        -0.5845535659460954,
        39.88967796648467
      ]
    },
    "12072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2809668029487002,
              40.149548930698415
            ]
          },
          "bbox": [
            -0.2809668029487002,
            40.149548930698415,
            -0.2809668029487002,
            40.149548930698415
          ]
        }
      ],
      "bbox": [
        -0.2809668029487002,
        40.149548930698415,
        -0.2809668029487002,
        40.149548930698415
      ]
    },
    "12073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3772807777131843,
              40.09567264473237
            ]
          },
          "bbox": [
            -0.3772807777131843,
            40.09567264473237,
            -0.3772807777131843,
            40.09567264473237
          ]
        }
      ],
      "bbox": [
        -0.3772807777131843,
        40.09567264473237,
        -0.3772807777131843,
        40.09567264473237
      ]
    },
    "12074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.20241888981203301,
              39.767320189462545
            ]
          },
          "bbox": [
            -0.20241888981203301,
            39.767320189462545,
            -0.20241888981203301,
            39.767320189462545
          ]
        }
      ],
      "bbox": [
        -0.20241888981203301,
        39.767320189462545,
        -0.20241888981203301,
        39.767320189462545
      ]
    },
    "12075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.27769472705608866,
              40.61154800395488
            ]
          },
          "bbox": [
            -0.27769472705608866,
            40.61154800395488,
            -0.27769472705608866,
            40.61154800395488
          ]
        }
      ],
      "bbox": [
        -0.27769472705608866,
        40.61154800395488,
        -0.27769472705608866,
        40.61154800395488
      ]
    },
    "12076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.45653045200798115,
              39.943747984909216
            ]
          },
          "bbox": [
            -0.45653045200798115,
            39.943747984909216,
            -0.45653045200798115,
            39.943747984909216
          ]
        }
      ],
      "bbox": [
        -0.45653045200798115,
        39.943747984909216,
        -0.45653045200798115,
        39.943747984909216
      ]
    },
    "12077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.15309580759595648,
              39.798555686993495
            ]
          },
          "bbox": [
            -0.15309580759595648,
            39.798555686993495,
            -0.15309580759595648,
            39.798555686993495
          ]
        }
      ],
      "bbox": [
        -0.15309580759595648,
        39.798555686993495,
        -0.15309580759595648,
        39.798555686993495
      ]
    },
    "12078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5503812798496398,
              40.029236612693694
            ]
          },
          "bbox": [
            -0.5503812798496398,
            40.029236612693694,
            -0.5503812798496398,
            40.029236612693694
          ]
        }
      ],
      "bbox": [
        -0.5503812798496398,
        40.029236612693694,
        -0.5503812798496398,
        40.029236612693694
      ]
    },
    "12079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5350026368954186,
              40.069328743823604
            ]
          },
          "bbox": [
            -0.5350026368954186,
            40.069328743823604,
            -0.5350026368954186,
            40.069328743823604
          ]
        }
      ],
      "bbox": [
        -0.5350026368954186,
        40.069328743823604,
        -0.5350026368954186,
        40.069328743823604
      ]
    },
    "12080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.07551233992090796,
              40.59790593232146
            ]
          },
          "bbox": [
            -0.07551233992090796,
            40.59790593232146,
            -0.07551233992090796,
            40.59790593232146
          ]
        }
      ],
      "bbox": [
        -0.07551233992090796,
        40.59790593232146,
        -0.07551233992090796,
        40.59790593232146
      ]
    },
    "12081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5005066703644777,
              39.88135233940047
            ]
          },
          "bbox": [
            -0.5005066703644777,
            39.88135233940047,
            -0.5005066703644777,
            39.88135233940047
          ]
        }
      ],
      "bbox": [
        -0.5005066703644777,
        39.88135233940047,
        -0.5005066703644777,
        39.88135233940047
      ]
    },
    "12082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1595543122560674,
              39.85283207272733
            ]
          },
          "bbox": [
            -0.1595543122560674,
            39.85283207272733,
            -0.1595543122560674,
            39.85283207272733
          ]
        }
      ],
      "bbox": [
        -0.1595543122560674,
        39.85283207272733,
        -0.1595543122560674,
        39.85283207272733
      ]
    },
    "12083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.33937139986141934,
              40.641613567521254
            ]
          },
          "bbox": [
            -0.33937139986141934,
            40.641613567521254,
            -0.33937139986141934,
            40.641613567521254
          ]
        }
      ],
      "bbox": [
        -0.33937139986141934,
        40.641613567521254,
        -0.33937139986141934,
        40.641613567521254
      ]
    },
    "12084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.22783392377660255,
              39.979723524302734
            ]
          },
          "bbox": [
            -0.22783392377660255,
            39.979723524302734,
            -0.22783392377660255,
            39.979723524302734
          ]
        }
      ],
      "bbox": [
        -0.22783392377660255,
        39.979723524302734,
        -0.22783392377660255,
        39.979723524302734
      ]
    },
    "12085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.12292805936219188,
              40.09174982695984
            ]
          },
          "bbox": [
            0.12292805936219188,
            40.09174982695984,
            0.12292805936219188,
            40.09174982695984
          ]
        }
      ],
      "bbox": [
        0.12292805936219188,
        40.09174982695984,
        0.12292805936219188,
        40.09174982695984
      ]
    },
    "12087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.19023856283946927,
              40.70989803212169
            ]
          },
          "bbox": [
            -0.19023856283946927,
            40.70989803212169,
            -0.19023856283946927,
            40.70989803212169
          ]
        }
      ],
      "bbox": [
        -0.19023856283946927,
        40.70989803212169,
        -0.19023856283946927,
        40.70989803212169
      ]
    },
    "12088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.48152356532746726,
              39.97012308533491
            ]
          },
          "bbox": [
            -0.48152356532746726,
            39.97012308533491,
            -0.48152356532746726,
            39.97012308533491
          ]
        }
      ],
      "bbox": [
        -0.48152356532746726,
        39.97012308533491,
        -0.48152356532746726,
        39.97012308533491
      ]
    },
    "12089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.35499030563278516,
              40.35669997978357
            ]
          },
          "bbox": [
            0.35499030563278516,
            40.35669997978357,
            0.35499030563278516,
            40.35669997978357
          ]
        }
      ],
      "bbox": [
        0.35499030563278516,
        40.35669997978357,
        0.35499030563278516,
        40.35669997978357
      ]
    },
    "12090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6497687254490823,
              40.01422134897423
            ]
          },
          "bbox": [
            -0.6497687254490823,
            40.01422134897423,
            -0.6497687254490823,
            40.01422134897423
          ]
        }
      ],
      "bbox": [
        -0.6497687254490823,
        40.01422134897423,
        -0.6497687254490823,
        40.01422134897423
      ]
    },
    "12091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2679667202634444,
              40.5393991069077
            ]
          },
          "bbox": [
            -0.2679667202634444,
            40.5393991069077,
            -0.2679667202634444,
            40.5393991069077
          ]
        }
      ],
      "bbox": [
        -0.2679667202634444,
        40.5393991069077,
        -0.2679667202634444,
        40.5393991069077
      ]
    },
    "12092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5809055839187772,
              40.10251353506487
            ]
          },
          "bbox": [
            -0.5809055839187772,
            40.10251353506487,
            -0.5809055839187772,
            40.10251353506487
          ]
        }
      ],
      "bbox": [
        -0.5809055839187772,
        40.10251353506487,
        -0.5809055839187772,
        40.10251353506487
      ]
    },
    "12093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.16538435589980358,
              40.68583502160881
            ]
          },
          "bbox": [
            0.16538435589980358,
            40.68583502160881,
            0.16538435589980358,
            40.68583502160881
          ]
        }
      ],
      "bbox": [
        0.16538435589980358,
        40.68583502160881,
        0.16538435589980358,
        40.68583502160881
      ]
    },
    "12094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.0009098372217942279,
              40.10329055192517
            ]
          },
          "bbox": [
            -0.0009098372217942279,
            40.10329055192517,
            -0.0009098372217942279,
            40.10329055192517
          ]
        }
      ],
      "bbox": [
        -0.0009098372217942279,
        40.10329055192517,
        -0.0009098372217942279,
        40.10329055192517
      ]
    },
    "12095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.27791253814888794,
              40.027072013178746
            ]
          },
          "bbox": [
            -0.27791253814888794,
            40.027072013178746,
            -0.27791253814888794,
            40.027072013178746
          ]
        }
      ],
      "bbox": [
        -0.27791253814888794,
        40.027072013178746,
        -0.27791253814888794,
        40.027072013178746
      ]
    },
    "12096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.22452053425504598,
              40.62223812343307
            ]
          },
          "bbox": [
            0.22452053425504598,
            40.62223812343307,
            0.22452053425504598,
            40.62223812343307
          ]
        }
      ],
      "bbox": [
        0.22452053425504598,
        40.62223812343307,
        0.22452053425504598,
        40.62223812343307
      ]
    },
    "12097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7343371965732197,
              39.85257077761149
            ]
          },
          "bbox": [
            -0.7343371965732197,
            39.85257077761149,
            -0.7343371965732197,
            39.85257077761149
          ]
        }
      ],
      "bbox": [
        -0.7343371965732197,
        39.85257077761149,
        -0.7343371965732197,
        39.85257077761149
      ]
    },
    "12098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.14729970468531267,
              40.41755498044462
            ]
          },
          "bbox": [
            0.14729970468531267,
            40.41755498044462,
            0.14729970468531267,
            40.41755498044462
          ]
        }
      ],
      "bbox": [
        0.14729970468531267,
        40.41755498044462,
        0.14729970468531267,
        40.41755498044462
      ]
    },
    "12099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.35939089265350616,
              40.53701517051425
            ]
          },
          "bbox": [
            0.35939089265350616,
            40.53701517051425,
            0.35939089265350616,
            40.53701517051425
          ]
        }
      ],
      "bbox": [
        0.35939089265350616,
        40.53701517051425,
        0.35939089265350616,
        40.53701517051425
      ]
    },
    "12100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.1739718299263054,
              40.466051058122346
            ]
          },
          "bbox": [
            0.1739718299263054,
            40.466051058122346,
            0.1739718299263054,
            40.466051058122346
          ]
        }
      ],
      "bbox": [
        0.1739718299263054,
        40.466051058122346,
        0.1739718299263054,
        40.466051058122346
      ]
    },
    "12101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.33908918145129474,
              40.59452707018871
            ]
          },
          "bbox": [
            0.33908918145129474,
            40.59452707018871,
            0.33908918145129474,
            40.59452707018871
          ]
        }
      ],
      "bbox": [
        0.33908918145129474,
        40.59452707018871,
        0.33908918145129474,
        40.59452707018871
      ]
    },
    "12102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.27343808590659796,
              40.37470597905581
            ]
          },
          "bbox": [
            0.27343808590659796,
            40.37470597905581,
            0.27343808590659796,
            40.37470597905581
          ]
        }
      ],
      "bbox": [
        0.27343808590659796,
        40.37470597905581,
        0.27343808590659796,
        40.37470597905581
      ]
    },
    "12103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.037573069486148504,
              40.30994006095463
            ]
          },
          "bbox": [
            0.037573069486148504,
            40.30994006095463,
            0.037573069486148504,
            40.30994006095463
          ]
        }
      ],
      "bbox": [
        0.037573069486148504,
        40.30994006095463,
        0.037573069486148504,
        40.30994006095463
      ]
    },
    "12104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4630973512821967,
              39.787764514324536
            ]
          },
          "bbox": [
            -0.4630973512821967,
            39.787764514324536,
            -0.4630973512821967,
            39.787764514324536
          ]
        }
      ],
      "bbox": [
        -0.4630973512821967,
        39.787764514324536,
        -0.4630973512821967,
        39.787764514324536
      ]
    },
    "12105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.027076301797540614,
              40.26348545064827
            ]
          },
          "bbox": [
            -0.027076301797540614,
            40.26348545064827,
            -0.027076301797540614,
            40.26348545064827
          ]
        }
      ],
      "bbox": [
        -0.027076301797540614,
        40.26348545064827,
        -0.027076301797540614,
        40.26348545064827
      ]
    },
    "12106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.37599134100984594,
              39.819414310074016
            ]
          },
          "bbox": [
            -0.37599134100984594,
            39.819414310074016,
            -0.37599134100984594,
            39.819414310074016
          ]
        }
      ],
      "bbox": [
        -0.37599134100984594,
        39.819414310074016,
        -0.37599134100984594,
        39.819414310074016
      ]
    },
    "12107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.40142130969032425,
              39.80182989645116
            ]
          },
          "bbox": [
            -0.40142130969032425,
            39.80182989645116,
            -0.40142130969032425,
            39.80182989645116
          ]
        }
      ],
      "bbox": [
        -0.40142130969032425,
        39.80182989645116,
        -0.40142130969032425,
        39.80182989645116
      ]
    },
    "12108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.35333333832883806,
              39.953694580521436
            ]
          },
          "bbox": [
            -0.35333333832883806,
            39.953694580521436,
            -0.35333333832883806,
            39.953694580521436
          ]
        }
      ],
      "bbox": [
        -0.35333333832883806,
        39.953694580521436,
        -0.35333333832883806,
        39.953694580521436
      ]
    },
    "12109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.30066465280989485,
              39.945355091548876
            ]
          },
          "bbox": [
            -0.30066465280989485,
            39.945355091548876,
            -0.30066465280989485,
            39.945355091548876
          ]
        }
      ],
      "bbox": [
        -0.30066465280989485,
        39.945355091548876,
        -0.30066465280989485,
        39.945355091548876
      ]
    },
    "12110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6749444572324479,
              39.88872286672239
            ]
          },
          "bbox": [
            -0.6749444572324479,
            39.88872286672239,
            -0.6749444572324479,
            39.88872286672239
          ]
        }
      ],
      "bbox": [
        -0.6749444572324479,
        39.88872286672239,
        -0.6749444572324479,
        39.88872286672239
      ]
    },
    "12111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.07044744435787446,
              40.43292616820789
            ]
          },
          "bbox": [
            0.07044744435787446,
            40.43292616820789,
            0.07044744435787446,
            40.43292616820789
          ]
        }
      ],
      "bbox": [
        0.07044744435787446,
        40.43292616820789,
        0.07044744435787446,
        40.43292616820789
      ]
    },
    "12112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2623762423967345,
              40.644351871383414
            ]
          },
          "bbox": [
            -0.2623762423967345,
            40.644351871383414,
            -0.2623762423967345,
            40.644351871383414
          ]
        }
      ],
      "bbox": [
        -0.2623762423967345,
        40.644351871383414,
        -0.2623762423967345,
        40.644351871383414
      ]
    },
    "12113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.375812869236788,
              40.051659925814135
            ]
          },
          "bbox": [
            -0.375812869236788,
            40.051659925814135,
            -0.375812869236788,
            40.051659925814135
          ]
        }
      ],
      "bbox": [
        -0.375812869236788,
        40.051659925814135,
        -0.375812869236788,
        40.051659925814135
      ]
    },
    "12114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.686322457976953,
              39.92888577876199
            ]
          },
          "bbox": [
            -0.686322457976953,
            39.92888577876199,
            -0.686322457976953,
            39.92888577876199
          ]
        }
      ],
      "bbox": [
        -0.686322457976953,
        39.92888577876199,
        -0.686322457976953,
        39.92888577876199
      ]
    },
    "12115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7744594602004131,
              39.96078719251053
            ]
          },
          "bbox": [
            -0.7744594602004131,
            39.96078719251053,
            -0.7744594602004131,
            39.96078719251053
          ]
        }
      ],
      "bbox": [
        -0.7744594602004131,
        39.96078719251053,
        -0.7744594602004131,
        39.96078719251053
      ]
    },
    "12116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4492114587153984,
              39.994867914223796
            ]
          },
          "bbox": [
            -0.4492114587153984,
            39.994867914223796,
            -0.4492114587153984,
            39.994867914223796
          ]
        }
      ],
      "bbox": [
        -0.4492114587153984,
        39.994867914223796,
        -0.4492114587153984,
        39.994867914223796
      ]
    },
    "12117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2017091156808421,
              40.2097141509239
            ]
          },
          "bbox": [
            0.2017091156808421,
            40.2097141509239,
            0.2017091156808421,
            40.2097141509239
          ]
        }
      ],
      "bbox": [
        0.2017091156808421,
        40.2097141509239,
        0.2017091156808421,
        40.2097141509239
      ]
    },
    "12118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.40435733451988487,
              40.04891311790404
            ]
          },
          "bbox": [
            -0.40435733451988487,
            40.04891311790404,
            -0.40435733451988487,
            40.04891311790404
          ]
        }
      ],
      "bbox": [
        -0.40435733451988487,
        40.04891311790404,
        -0.40435733451988487,
        40.04891311790404
      ]
    },
    "12121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3185740645540237,
              40.54914585581083
            ]
          },
          "bbox": [
            0.3185740645540237,
            40.54914585581083,
            0.3185740645540237,
            40.54914585581083
          ]
        }
      ],
      "bbox": [
        0.3185740645540237,
        40.54914585581083,
        0.3185740645540237,
        40.54914585581083
      ]
    },
    "12122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.13371936110198165,
              40.17835124934262
            ]
          },
          "bbox": [
            -0.13371936110198165,
            40.17835124934262,
            -0.13371936110198165,
            40.17835124934262
          ]
        }
      ],
      "bbox": [
        -0.13371936110198165,
        40.17835124934262,
        -0.13371936110198165,
        40.17835124934262
      ]
    },
    "12123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.33372452476263664,
              40.03709614438911
            ]
          },
          "bbox": [
            -0.33372452476263664,
            40.03709614438911,
            -0.33372452476263664,
            40.03709614438911
          ]
        }
      ],
      "bbox": [
        -0.33372452476263664,
        40.03709614438911,
        -0.33372452476263664,
        40.03709614438911
      ]
    },
    "12125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.45819700942556596,
              39.897769277367615
            ]
          },
          "bbox": [
            -0.45819700942556596,
            39.897769277367615,
            -0.45819700942556596,
            39.897769277367615
          ]
        }
      ],
      "bbox": [
        -0.45819700942556596,
        39.897769277367615,
        -0.45819700942556596,
        39.897769277367615
      ]
    },
    "12127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.08870870021712218,
              40.607966643421015
            ]
          },
          "bbox": [
            0.08870870021712218,
            40.607966643421015,
            0.08870870021712218,
            40.607966643421015
          ]
        }
      ],
      "bbox": [
        0.08870870021712218,
        40.607966643421015,
        0.08870870021712218,
        40.607966643421015
      ]
    },
    "12128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.07094115771944196,
              40.121756539454545
            ]
          },
          "bbox": [
            -0.07094115771944196,
            40.121756539454545,
            -0.07094115771944196,
            40.121756539454545
          ]
        }
      ],
      "bbox": [
        -0.07094115771944196,
        40.121756539454545,
        -0.07094115771944196,
        40.121756539454545
      ]
    },
    "12129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2636434403149861,
              40.431485514136305
            ]
          },
          "bbox": [
            -0.2636434403149861,
            40.431485514136305,
            -0.2636434403149861,
            40.431485514136305
          ]
        }
      ],
      "bbox": [
        -0.2636434403149861,
        40.431485514136305,
        -0.2636434403149861,
        40.431485514136305
      ]
    },
    "12130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.42284229624229774,
              40.205624399572514
            ]
          },
          "bbox": [
            -0.42284229624229774,
            40.205624399572514,
            -0.42284229624229774,
            40.205624399572514
          ]
        }
      ],
      "bbox": [
        -0.42284229624229774,
        40.205624399572514,
        -0.42284229624229774,
        40.205624399572514
      ]
    },
    "12131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.40695494747538324,
              39.95882957532819
            ]
          },
          "bbox": [
            -0.40695494747538324,
            39.95882957532819,
            -0.40695494747538324,
            39.95882957532819
          ]
        }
      ],
      "bbox": [
        -0.40695494747538324,
        39.95882957532819,
        -0.40695494747538324,
        39.95882957532819
      ]
    },
    "12133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6387792376456667,
              40.05753446898011
            ]
          },
          "bbox": [
            -0.6387792376456667,
            40.05753446898011,
            -0.6387792376456667,
            40.05753446898011
          ]
        }
      ],
      "bbox": [
        -0.6387792376456667,
        40.05753446898011,
        -0.6387792376456667,
        40.05753446898011
      ]
    },
    "12134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.06324813320105561,
              40.3540190708609
            ]
          },
          "bbox": [
            -0.06324813320105561,
            40.3540190708609,
            -0.06324813320105561,
            40.3540190708609
          ]
        }
      ],
      "bbox": [
        -0.06324813320105561,
        40.3540190708609,
        -0.06324813320105561,
        40.3540190708609
      ]
    },
    "12135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12880503087530212,
              39.933053800619916
            ]
          },
          "bbox": [
            -0.12880503087530212,
            39.933053800619916,
            -0.12880503087530212,
            39.933053800619916
          ]
        }
      ],
      "bbox": [
        -0.12880503087530212,
        39.933053800619916,
        -0.12880503087530212,
        39.933053800619916
      ]
    },
    "12136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.19234327730563563,
              39.86373203748039
            ]
          },
          "bbox": [
            -0.19234327730563563,
            39.86373203748039,
            -0.19234327730563563,
            39.86373203748039
          ]
        }
      ],
      "bbox": [
        -0.19234327730563563,
        39.86373203748039,
        -0.19234327730563563,
        39.86373203748039
      ]
    },
    "12137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.20912520365467224,
              40.679383655737105
            ]
          },
          "bbox": [
            -0.20912520365467224,
            40.679383655737105,
            -0.20912520365467224,
            40.679383655737105
          ]
        }
      ],
      "bbox": [
        -0.20912520365467224,
        40.679383655737105,
        -0.20912520365467224,
        40.679383655737105
      ]
    },
    "12138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.43536667787154953,
              40.51024997079085
            ]
          },
          "bbox": [
            0.43536667787154953,
            40.51024997079085,
            0.43536667787154953,
            40.51024997079085
          ]
        }
      ],
      "bbox": [
        0.43536667787154953,
        40.51024997079085,
        0.43536667787154953,
        40.51024997079085
      ]
    },
    "12139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2974667148505781,
              40.306419469397504
            ]
          },
          "bbox": [
            -0.2974667148505781,
            40.306419469397504,
            -0.2974667148505781,
            40.306419469397504
          ]
        }
      ],
      "bbox": [
        -0.2974667148505781,
        40.306419469397504,
        -0.2974667148505781,
        40.306419469397504
      ]
    },
    "12140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6301259441615806,
              39.93220863226342
            ]
          },
          "bbox": [
            -0.6301259441615806,
            39.93220863226342,
            -0.6301259441615806,
            39.93220863226342
          ]
        }
      ],
      "bbox": [
        -0.6301259441615806,
        39.93220863226342,
        -0.6301259441615806,
        39.93220863226342
      ]
    },
    "12141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.16491475765823072,
              40.741707609516396
            ]
          },
          "bbox": [
            -0.16491475765823072,
            40.741707609516396,
            -0.16491475765823072,
            40.741707609516396
          ]
        }
      ],
      "bbox": [
        -0.16491475765823072,
        40.741707609516396,
        -0.16491475765823072,
        40.741707609516396
      ]
    },
    "12142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4472147127776836,
              40.13153394388224
            ]
          },
          "bbox": [
            -0.4472147127776836,
            40.13153394388224,
            -0.4472147127776836,
            40.13153394388224
          ]
        }
      ],
      "bbox": [
        -0.4472147127776836,
        40.13153394388224,
        -0.4472147127776836,
        40.13153394388224
      ]
    },
    "12901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12940144027815395,
              39.88944207832041
            ]
          },
          "bbox": [
            -0.12940144027815395,
            39.88944207832041,
            -0.12940144027815395,
            39.88944207832041
          ]
        }
      ],
      "bbox": [
        -0.12940144027815395,
        39.88944207832041,
        -0.12940144027815395,
        39.88944207832041
      ]
    },
    "12902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12682346541483766,
              40.06876655575621
            ]
          },
          "bbox": [
            -0.12682346541483766,
            40.06876655575621,
            -0.12682346541483766,
            40.06876655575621
          ]
        }
      ],
      "bbox": [
        -0.12682346541483766,
        40.06876655575621,
        -0.12682346541483766,
        40.06876655575621
      ]
    },
    "13001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.451468912963448,
              38.87820772798224
            ]
          },
          "bbox": [
            -4.451468912963448,
            38.87820772798224,
            -4.451468912963448,
            38.87820772798224
          ]
        }
      ],
      "bbox": [
        -4.451468912963448,
        38.87820772798224,
        -4.451468912963448,
        38.87820772798224
      ]
    },
    "13002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.818777730231042,
              39.010514519108305
            ]
          },
          "bbox": [
            -4.818777730231042,
            39.010514519108305,
            -4.818777730231042,
            39.010514519108305
          ]
        }
      ],
      "bbox": [
        -4.818777730231042,
        39.010514519108305,
        -4.818777730231042,
        39.010514519108305
      ]
    },
    "13003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8104365123299875,
              38.67475269979818
            ]
          },
          "bbox": [
            -4.8104365123299875,
            38.67475269979818,
            -4.8104365123299875,
            38.67475269979818
          ]
        }
      ],
      "bbox": [
        -4.8104365123299875,
        38.67475269979818,
        -4.8104365123299875,
        38.67475269979818
      ]
    },
    "13004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.789940933727827,
              38.60998897590742
            ]
          },
          "bbox": [
            -2.789940933727827,
            38.60998897590742,
            -2.789940933727827,
            38.60998897590742
          ]
        }
      ],
      "bbox": [
        -2.789940933727827,
        38.60998897590742,
        -2.789940933727827,
        38.60998897590742
      ]
    },
    "13005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.239303416921571,
              39.284257838779496
            ]
          },
          "bbox": [
            -3.239303416921571,
            39.284257838779496,
            -3.239303416921571,
            39.284257838779496
          ]
        }
      ],
      "bbox": [
        -3.239303416921571,
        39.284257838779496,
        -3.239303416921571,
        39.284257838779496
      ]
    },
    "13006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.415055436871478,
              39.30999192942848
            ]
          },
          "bbox": [
            -4.415055436871478,
            39.30999192942848,
            -4.415055436871478,
            39.30999192942848
          ]
        }
      ],
      "bbox": [
        -4.415055436871478,
        39.30999192942848,
        -4.415055436871478,
        39.30999192942848
      ]
    },
    "13007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.119318994413669,
              38.97753991233323
            ]
          },
          "bbox": [
            -4.119318994413669,
            38.97753991233323,
            -4.119318994413669,
            38.97753991233323
          ]
        }
      ],
      "bbox": [
        -4.119318994413669,
        38.97753991233323,
        -4.119318994413669,
        38.97753991233323
      ]
    },
    "13008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1453966403233955,
              38.755576814692915
            ]
          },
          "bbox": [
            -3.1453966403233955,
            38.755576814692915,
            -3.1453966403233955,
            38.755576814692915
          ]
        }
      ],
      "bbox": [
        -3.1453966403233955,
        38.755576814692915,
        -3.1453966403233955,
        38.755576814692915
      ]
    },
    "13009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.870655416261728,
              38.72033580059846
            ]
          },
          "bbox": [
            -3.870655416261728,
            38.72033580059846,
            -3.870655416261728,
            38.72033580059846
          ]
        }
      ],
      "bbox": [
        -3.870655416261728,
        38.72033580059846,
        -3.870655416261728,
        38.72033580059846
      ]
    },
    "13010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0587515426587912,
              38.9157549083082
            ]
          },
          "bbox": [
            -3.0587515426587912,
            38.9157549083082,
            -3.0587515426587912,
            38.9157549083082
          ]
        }
      ],
      "bbox": [
        -3.0587515426587912,
        38.9157549083082,
        -3.0587515426587912,
        38.9157549083082
      ]
    },
    "13011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.741655229987081,
              38.80482376003644
            ]
          },
          "bbox": [
            -4.741655229987081,
            38.80482376003644,
            -4.741655229987081,
            38.80482376003644
          ]
        }
      ],
      "bbox": [
        -4.741655229987081,
        38.80482376003644,
        -4.741655229987081,
        38.80482376003644
      ]
    },
    "13012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.667080641179008,
              38.772631521136304
            ]
          },
          "bbox": [
            -4.667080641179008,
            38.772631521136304,
            -4.667080641179008,
            38.772631521136304
          ]
        }
      ],
      "bbox": [
        -4.667080641179008,
        38.772631521136304,
        -4.667080641179008,
        38.772631521136304
      ]
    },
    "13013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7296263017128304,
              38.8993556437166
            ]
          },
          "bbox": [
            -3.7296263017128304,
            38.8993556437166,
            -3.7296263017128304,
            38.8993556437166
          ]
        }
      ],
      "bbox": [
        -3.7296263017128304,
        38.8993556437166,
        -3.7296263017128304,
        38.8993556437166
      ]
    },
    "13014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9620626419454816,
              38.62056576211001
            ]
          },
          "bbox": [
            -2.9620626419454816,
            38.62056576211001,
            -2.9620626419454816,
            38.62056576211001
          ]
        }
      ],
      "bbox": [
        -2.9620626419454816,
        38.62056576211001,
        -2.9620626419454816,
        38.62056576211001
      ]
    },
    "13015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.495217228466737,
              38.65492347068792
            ]
          },
          "bbox": [
            -4.495217228466737,
            38.65492347068792,
            -4.495217228466737,
            38.65492347068792
          ]
        }
      ],
      "bbox": [
        -4.495217228466737,
        38.65492347068792,
        -4.495217228466737,
        38.65492347068792
      ]
    },
    "13016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4885327690565098,
              38.46909686526596
            ]
          },
          "bbox": [
            -3.4885327690565098,
            38.46909686526596,
            -3.4885327690565098,
            38.46909686526596
          ]
        }
      ],
      "bbox": [
        -3.4885327690565098,
        38.46909686526596,
        -3.4885327690565098,
        38.46909686526596
      ]
    },
    "13017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.841415812945066,
              39.45714702032086
            ]
          },
          "bbox": [
            -4.841415812945066,
            39.45714702032086,
            -4.841415812945066,
            39.45714702032086
          ]
        }
      ],
      "bbox": [
        -4.841415812945066,
        39.45714702032086,
        -4.841415812945066,
        39.45714702032086
      ]
    },
    "13018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5076413068310397,
              39.20743138531332
            ]
          },
          "bbox": [
            -3.5076413068310397,
            39.20743138531332,
            -3.5076413068310397,
            39.20743138531332
          ]
        }
      ],
      "bbox": [
        -3.5076413068310397,
        39.20743138531332,
        -3.5076413068310397,
        39.20743138531332
      ]
    },
    "13019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.05252909476614,
              39.07757387590327
            ]
          },
          "bbox": [
            -3.05252909476614,
            39.07757387590327,
            -3.05252909476614,
            39.07757387590327
          ]
        }
      ],
      "bbox": [
        -3.05252909476614,
        39.07757387590327,
        -3.05252909476614,
        39.07757387590327
      ]
    },
    "13020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.023626620240478,
              38.74103259400044
            ]
          },
          "bbox": [
            -4.023626620240478,
            38.74103259400044,
            -4.023626620240478,
            38.74103259400044
          ]
        }
      ],
      "bbox": [
        -4.023626620240478,
        38.74103259400044,
        -4.023626620240478,
        38.74103259400044
      ]
    },
    "13021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.557769135103348,
              39.16357403799476
            ]
          },
          "bbox": [
            -4.557769135103348,
            39.16357403799476,
            -4.557769135103348,
            39.16357403799476
          ]
        }
      ],
      "bbox": [
        -4.557769135103348,
        39.16357403799476,
        -4.557769135103348,
        39.16357403799476
      ]
    },
    "13022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.94695252806873,
              38.81696100990833
            ]
          },
          "bbox": [
            -3.94695252806873,
            38.81696100990833,
            -3.94695252806873,
            38.81696100990833
          ]
        }
      ],
      "bbox": [
        -3.94695252806873,
        38.81696100990833,
        -3.94695252806873,
        38.81696100990833
      ]
    },
    "13023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.624103660277152,
              38.91002581668379
            ]
          },
          "bbox": [
            -3.624103660277152,
            38.91002581668379,
            -3.624103660277152,
            38.91002581668379
          ]
        }
      ],
      "bbox": [
        -3.624103660277152,
        38.91002581668379,
        -3.624103660277152,
        38.91002581668379
      ]
    },
    "13024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.361245261817436,
              38.57515566658603
            ]
          },
          "bbox": [
            -4.361245261817436,
            38.57515566658603,
            -4.361245261817436,
            38.57515566658603
          ]
        }
      ],
      "bbox": [
        -4.361245261817436,
        38.57515566658603,
        -4.361245261817436,
        38.57515566658603
      ]
    },
    "13025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.294838660155399,
              38.85116058830015
            ]
          },
          "bbox": [
            -4.294838660155399,
            38.85116058830015,
            -4.294838660155399,
            38.85116058830015
          ]
        }
      ],
      "bbox": [
        -4.294838660155399,
        38.85116058830015,
        -4.294838660155399,
        38.85116058830015
      ]
    },
    "13026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.249330244472719,
              38.56167414946491
            ]
          },
          "bbox": [
            -4.249330244472719,
            38.56167414946491,
            -4.249330244472719,
            38.56167414946491
          ]
        }
      ],
      "bbox": [
        -4.249330244472719,
        38.56167414946491,
        -4.249330244472719,
        38.56167414946491
      ]
    },
    "13027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7604782622544493,
              38.61480690212016
            ]
          },
          "bbox": [
            -3.7604782622544493,
            38.61480690212016,
            -3.7604782622544493,
            38.61480690212016
          ]
        }
      ],
      "bbox": [
        -3.7604782622544493,
        38.61480690212016,
        -3.7604782622544493,
        38.61480690212016
      ]
    },
    "13028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0814278081235638,
              39.36214317983814
            ]
          },
          "bbox": [
            -3.0814278081235638,
            39.36214317983814,
            -3.0814278081235638,
            39.36214317983814
          ]
        }
      ],
      "bbox": [
        -3.0814278081235638,
        39.36214317983814,
        -3.0814278081235638,
        39.36214317983814
      ]
    },
    "13029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.018276906040067,
              38.836754781981234
            ]
          },
          "bbox": [
            -4.018276906040067,
            38.836754781981234,
            -4.018276906040067,
            38.836754781981234
          ]
        }
      ],
      "bbox": [
        -4.018276906040067,
        38.836754781981234,
        -4.018276906040067,
        38.836754781981234
      ]
    },
    "13030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.054885657609456,
              38.82968869129493
            ]
          },
          "bbox": [
            -4.054885657609456,
            38.82968869129493,
            -4.054885657609456,
            38.82968869129493
          ]
        }
      ],
      "bbox": [
        -4.054885657609456,
        38.82968869129493,
        -4.054885657609456,
        38.82968869129493
      ]
    },
    "13031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.835401366111364,
              39.0497643436329
            ]
          },
          "bbox": [
            -3.835401366111364,
            39.0497643436329,
            -3.835401366111364,
            39.0497643436329
          ]
        }
      ],
      "bbox": [
        -3.835401366111364,
        39.0497643436329,
        -3.835401366111364,
        39.0497643436329
      ]
    },
    "13032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9822346260785677,
              38.84848430466282
            ]
          },
          "bbox": [
            -2.9822346260785677,
            38.84848430466282,
            -2.9822346260785677,
            38.84848430466282
          ]
        }
      ],
      "bbox": [
        -2.9822346260785677,
        38.84848430466282,
        -2.9822346260785677,
        38.84848430466282
      ]
    },
    "13033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.282793156944303,
              38.53279185061347
            ]
          },
          "bbox": [
            -3.282793156944303,
            38.53279185061347,
            -3.282793156944303,
            38.53279185061347
          ]
        }
      ],
      "bbox": [
        -3.282793156944303,
        38.53279185061347,
        -3.282793156944303,
        38.53279185061347
      ]
    },
    "13034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9733539667737063,
              38.9710291157027
            ]
          },
          "bbox": [
            -3.9733539667737063,
            38.9710291157027,
            -3.9733539667737063,
            38.9710291157027
          ]
        }
      ],
      "bbox": [
        -3.9733539667737063,
        38.9710291157027,
        -3.9733539667737063,
        38.9710291157027
      ]
    },
    "13035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.127306778017905,
              38.87590295521799
            ]
          },
          "bbox": [
            -4.127306778017905,
            38.87590295521799,
            -4.127306778017905,
            38.87590295521799
          ]
        }
      ],
      "bbox": [
        -4.127306778017905,
        38.87590295521799,
        -4.127306778017905,
        38.87590295521799
      ]
    },
    "13036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.036320759811113,
              39.321051808410466
            ]
          },
          "bbox": [
            -4.036320759811113,
            39.321051808410466,
            -4.036320759811113,
            39.321051808410466
          ]
        }
      ],
      "bbox": [
        -4.036320759811113,
        39.321051808410466,
        -4.036320759811113,
        39.321051808410466
      ]
    },
    "13037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.087220684238753,
              38.66000548596467
            ]
          },
          "bbox": [
            -3.087220684238753,
            38.66000548596467,
            -3.087220684238753,
            38.66000548596467
          ]
        }
      ],
      "bbox": [
        -3.087220684238753,
        38.66000548596467,
        -3.087220684238753,
        38.66000548596467
      ]
    },
    "13038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.876672804115965,
              38.807677423876186
            ]
          },
          "bbox": [
            -4.876672804115965,
            38.807677423876186,
            -4.876672804115965,
            38.807677423876186
          ]
        }
      ],
      "bbox": [
        -4.876672804115965,
        38.807677423876186,
        -4.876672804115965,
        38.807677423876186
      ]
    },
    "13039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6114138196056316,
              39.0674739818324
            ]
          },
          "bbox": [
            -3.6114138196056316,
            39.0674739818324,
            -3.6114138196056316,
            39.0674739818324
          ]
        }
      ],
      "bbox": [
        -3.6114138196056316,
        39.0674739818324,
        -3.6114138196056316,
        39.0674739818324
      ]
    },
    "13040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.948317256751274,
              39.123739248407844
            ]
          },
          "bbox": [
            -3.948317256751274,
            39.123739248407844,
            -3.948317256751274,
            39.123739248407844
          ]
        }
      ],
      "bbox": [
        -3.948317256751274,
        39.123739248407844,
        -3.948317256751274,
        39.123739248407844
      ]
    },
    "13041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.512368663339352,
              39.206702002836685
            ]
          },
          "bbox": [
            -4.512368663339352,
            39.206702002836685,
            -4.512368663339352,
            39.206702002836685
          ]
        }
      ],
      "bbox": [
        -4.512368663339352,
        39.206702002836685,
        -4.512368663339352,
        39.206702002836685
      ]
    },
    "13042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.290967778903565,
              38.42975287869728
            ]
          },
          "bbox": [
            -4.290967778903565,
            38.42975287869728,
            -4.290967778903565,
            38.42975287869728
          ]
        }
      ],
      "bbox": [
        -4.290967778903565,
        38.42975287869728,
        -4.290967778903565,
        38.42975287869728
      ]
    },
    "13043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.963127785050682,
              38.76141650755204
            ]
          },
          "bbox": [
            -2.963127785050682,
            38.76141650755204,
            -2.963127785050682,
            38.76141650755204
          ]
        }
      ],
      "bbox": [
        -2.963127785050682,
        38.76141650755204,
        -2.963127785050682,
        38.76141650755204
      ]
    },
    "13044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.833902540702646,
              39.2454312620327
            ]
          },
          "bbox": [
            -3.833902540702646,
            39.2454312620327,
            -3.833902540702646,
            39.2454312620327
          ]
        }
      ],
      "bbox": [
        -3.833902540702646,
        39.2454312620327,
        -3.833902540702646,
        39.2454312620327
      ]
    },
    "13045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.695513142194503,
              38.763943277204724
            ]
          },
          "bbox": [
            -3.695513142194503,
            38.763943277204724,
            -3.695513142194503,
            38.763943277204724
          ]
        }
      ],
      "bbox": [
        -3.695513142194503,
        38.763943277204724,
        -3.695513142194503,
        38.763943277204724
      ]
    },
    "13046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.955157026648575,
              38.72267718613203
            ]
          },
          "bbox": [
            -4.955157026648575,
            38.72267718613203,
            -4.955157026648575,
            38.72267718613203
          ]
        }
      ],
      "bbox": [
        -4.955157026648575,
        38.72267718613203,
        -4.955157026648575,
        38.72267718613203
      ]
    },
    "13047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.401590054147321,
              39.30235795700539
            ]
          },
          "bbox": [
            -3.401590054147321,
            39.30235795700539,
            -3.401590054147321,
            39.30235795700539
          ]
        }
      ],
      "bbox": [
        -3.401590054147321,
        39.30235795700539,
        -3.401590054147321,
        39.30235795700539
      ]
    },
    "13048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.172965501783278,
              38.55557226501782
            ]
          },
          "bbox": [
            -4.172965501783278,
            38.55557226501782,
            -4.172965501783278,
            38.55557226501782
          ]
        }
      ],
      "bbox": [
        -4.172965501783278,
        38.55557226501782,
        -4.172965501783278,
        38.55557226501782
      ]
    },
    "13049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.638160293817654,
              39.36134478130411
            ]
          },
          "bbox": [
            -4.638160293817654,
            39.36134478130411,
            -4.638160293817654,
            39.36134478130411
          ]
        }
      ],
      "bbox": [
        -4.638160293817654,
        39.36134478130411,
        -4.638160293817654,
        39.36134478130411
      ]
    },
    "13050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5132087209133367,
              39.218999755484866
            ]
          },
          "bbox": [
            -3.5132087209133367,
            39.218999755484866,
            -3.5132087209133367,
            39.218999755484866
          ]
        }
      ],
      "bbox": [
        -3.5132087209133367,
        39.218999755484866,
        -3.5132087209133367,
        39.218999755484866
      ]
    },
    "13051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.377671779162639,
              38.98861968362441
            ]
          },
          "bbox": [
            -4.377671779162639,
            38.98861968362441,
            -4.377671779162639,
            38.98861968362441
          ]
        }
      ],
      "bbox": [
        -4.377671779162639,
        38.98861968362441,
        -4.377671779162639,
        38.98861968362441
      ]
    },
    "13052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.985705467153736,
              39.21386890434923
            ]
          },
          "bbox": [
            -3.985705467153736,
            39.21386890434923,
            -3.985705467153736,
            39.21386890434923
          ]
        }
      ],
      "bbox": [
        -3.985705467153736,
        39.21386890434923,
        -3.985705467153736,
        39.21386890434923
      ]
    },
    "13053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.376409713859227,
              39.02746461309712
            ]
          },
          "bbox": [
            -3.376409713859227,
            39.02746461309712,
            -3.376409713859227,
            39.02746461309712
          ]
        }
      ],
      "bbox": [
        -3.376409713859227,
        39.02746461309712,
        -3.376409713859227,
        39.02746461309712
      ]
    },
    "13054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3245122030477683,
              38.91570744244369
            ]
          },
          "bbox": [
            -3.3245122030477683,
            38.91570744244369,
            -3.3245122030477683,
            38.91570744244369
          ]
        }
      ],
      "bbox": [
        -3.3245122030477683,
        38.91570744244369,
        -3.3245122030477683,
        38.91570744244369
      ]
    },
    "13055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.984504531937478,
              38.48357093714386
            ]
          },
          "bbox": [
            -3.984504531937478,
            38.48357093714386,
            -3.984504531937478,
            38.48357093714386
          ]
        }
      ],
      "bbox": [
        -3.984504531937478,
        38.48357093714386,
        -3.984504531937478,
        38.48357093714386
      ]
    },
    "13056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.916187206945997,
              39.02082398116193
            ]
          },
          "bbox": [
            -3.916187206945997,
            39.02082398116193,
            -3.916187206945997,
            39.02082398116193
          ]
        }
      ],
      "bbox": [
        -3.916187206945997,
        39.02082398116193,
        -3.916187206945997,
        39.02082398116193
      ]
    },
    "13057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.891445948992644,
              38.635867097301016
            ]
          },
          "bbox": [
            -2.891445948992644,
            38.635867097301016,
            -2.891445948992644,
            38.635867097301016
          ]
        }
      ],
      "bbox": [
        -2.891445948992644,
        38.635867097301016,
        -2.891445948992644,
        38.635867097301016
      ]
    },
    "13058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.578468940792485,
              38.78804375251622
            ]
          },
          "bbox": [
            -3.578468940792485,
            38.78804375251622,
            -3.578468940792485,
            38.78804375251622
          ]
        }
      ],
      "bbox": [
        -3.578468940792485,
        38.78804375251622,
        -3.578468940792485,
        38.78804375251622
      ]
    },
    "13059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.618777391917744,
              39.255497596617005
            ]
          },
          "bbox": [
            -4.618777391917744,
            39.255497596617005,
            -4.618777391917744,
            39.255497596617005
          ]
        }
      ],
      "bbox": [
        -4.618777391917744,
        39.255497596617005,
        -4.618777391917744,
        39.255497596617005
      ]
    },
    "13060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.539212234821897,
              39.46314984912412
            ]
          },
          "bbox": [
            -4.539212234821897,
            39.46314984912412,
            -4.539212234821897,
            39.46314984912412
          ]
        }
      ],
      "bbox": [
        -4.539212234821897,
        39.46314984912412,
        -4.539212234821897,
        39.46314984912412
      ]
    },
    "13061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9414688609681976,
              39.39873002361849
            ]
          },
          "bbox": [
            -2.9414688609681976,
            39.39873002361849,
            -2.9414688609681976,
            39.39873002361849
          ]
        }
      ],
      "bbox": [
        -2.9414688609681976,
        39.39873002361849,
        -2.9414688609681976,
        39.39873002361849
      ]
    },
    "13062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.071656776555404,
              39.059345895431406
            ]
          },
          "bbox": [
            -4.071656776555404,
            39.059345895431406,
            -4.071656776555404,
            39.059345895431406
          ]
        }
      ],
      "bbox": [
        -4.071656776555404,
        39.059345895431406,
        -4.071656776555404,
        39.059345895431406
      ]
    },
    "13063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.302088203092935,
              39.08923920350179
            ]
          },
          "bbox": [
            -4.302088203092935,
            39.08923920350179,
            -4.302088203092935,
            39.08923920350179
          ]
        }
      ],
      "bbox": [
        -4.302088203092935,
        39.08923920350179,
        -4.302088203092935,
        39.08923920350179
      ]
    },
    "13064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.000633962558465,
              38.930844592651376
            ]
          },
          "bbox": [
            -4.000633962558465,
            38.930844592651376,
            -4.000633962558465,
            38.930844592651376
          ]
        }
      ],
      "bbox": [
        -4.000633962558465,
        38.930844592651376,
        -4.000633962558465,
        38.930844592651376
      ]
    },
    "13065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1845254514112495,
              39.200166584087384
            ]
          },
          "bbox": [
            -4.1845254514112495,
            39.200166584087384,
            -4.1845254514112495,
            39.200166584087384
          ]
        }
      ],
      "bbox": [
        -4.1845254514112495,
        39.200166584087384,
        -4.1845254514112495,
        39.200166584087384
      ]
    },
    "13066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8407318951440077,
              38.88480945627713
            ]
          },
          "bbox": [
            -3.8407318951440077,
            38.88480945627713,
            -3.8407318951440077,
            38.88480945627713
          ]
        }
      ],
      "bbox": [
        -3.8407318951440077,
        38.88480945627713,
        -3.8407318951440077,
        38.88480945627713
      ]
    },
    "13067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.191497481003036,
              38.924007609745345
            ]
          },
          "bbox": [
            -4.191497481003036,
            38.924007609745345,
            -4.191497481003036,
            38.924007609745345
          ]
        }
      ],
      "bbox": [
        -4.191497481003036,
        38.924007609745345,
        -4.191497481003036,
        38.924007609745345
      ]
    },
    "13068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.661573013980035,
              39.10551093560725
            ]
          },
          "bbox": [
            -4.661573013980035,
            39.10551093560725,
            -4.661573013980035,
            39.10551093560725
          ]
        }
      ],
      "bbox": [
        -4.661573013980035,
        39.10551093560725,
        -4.661573013980035,
        39.10551093560725
      ]
    },
    "13069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9199844167020665,
              38.559773798597334
            ]
          },
          "bbox": [
            -2.9199844167020665,
            38.559773798597334,
            -2.9199844167020665,
            38.559773798597334
          ]
        }
      ],
      "bbox": [
        -2.9199844167020665,
        38.559773798597334,
        -2.9199844167020665,
        38.559773798597334
      ]
    },
    "13070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.479974983012577,
              39.277930614076794
            ]
          },
          "bbox": [
            -3.479974983012577,
            39.277930614076794,
            -3.479974983012577,
            39.277930614076794
          ]
        }
      ],
      "bbox": [
        -3.479974983012577,
        39.277930614076794,
        -3.479974983012577,
        39.277930614076794
      ]
    },
    "13071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.060901686118069,
              38.64915224634967
            ]
          },
          "bbox": [
            -4.060901686118069,
            38.64915224634967,
            -4.060901686118069,
            38.64915224634967
          ]
        }
      ],
      "bbox": [
        -4.060901686118069,
        38.64915224634967,
        -4.060901686118069,
        38.64915224634967
      ]
    },
    "13072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.252305548719343,
              39.429064121298495
            ]
          },
          "bbox": [
            -4.252305548719343,
            39.429064121298495,
            -4.252305548719343,
            39.429064121298495
          ]
        }
      ],
      "bbox": [
        -4.252305548719343,
        39.429064121298495,
        -4.252305548719343,
        39.429064121298495
      ]
    },
    "13073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.603437082147249,
              38.9617262896726
            ]
          },
          "bbox": [
            -4.603437082147249,
            38.9617262896726,
            -4.603437082147249,
            38.9617262896726
          ]
        }
      ],
      "bbox": [
        -4.603437082147249,
        38.9617262896726,
        -4.603437082147249,
        38.9617262896726
      ]
    },
    "13074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.242969663297093,
              38.84471409544345
            ]
          },
          "bbox": [
            -3.242969663297093,
            38.84471409544345,
            -3.242969663297093,
            38.84471409544345
          ]
        }
      ],
      "bbox": [
        -3.242969663297093,
        38.84471409544345,
        -3.242969663297093,
        38.84471409544345
      ]
    },
    "13075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8389297287926576,
              38.46413602854407
            ]
          },
          "bbox": [
            -3.8389297287926576,
            38.46413602854407,
            -3.8389297287926576,
            38.46413602854407
          ]
        }
      ],
      "bbox": [
        -3.8389297287926576,
        38.46413602854407,
        -3.8389297287926576,
        38.46413602854407
      ]
    },
    "13076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.879132160426076,
              38.634483415477284
            ]
          },
          "bbox": [
            -2.879132160426076,
            38.634483415477284,
            -2.879132160426076,
            38.634483415477284
          ]
        }
      ],
      "bbox": [
        -2.879132160426076,
        38.634483415477284,
        -2.879132160426076,
        38.634483415477284
      ]
    },
    "13077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.504632213799847,
              38.639249897988165
            ]
          },
          "bbox": [
            -3.504632213799847,
            38.639249897988165,
            -3.504632213799847,
            38.639249897988165
          ]
        }
      ],
      "bbox": [
        -3.504632213799847,
        38.639249897988165,
        -3.504632213799847,
        38.639249897988165
      ]
    },
    "13078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.841858110833633,
              39.25584152507321
            ]
          },
          "bbox": [
            -2.841858110833633,
            39.25584152507321,
            -2.841858110833633,
            39.25584152507321
          ]
        }
      ],
      "bbox": [
        -2.841858110833633,
        39.25584152507321,
        -2.841858110833633,
        39.25584152507321
      ]
    },
    "13079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.212898177956669,
              38.92834487716549
            ]
          },
          "bbox": [
            -3.212898177956669,
            38.92834487716549,
            -3.212898177956669,
            38.92834487716549
          ]
        }
      ],
      "bbox": [
        -3.212898177956669,
        38.92834487716549,
        -3.212898177956669,
        38.92834487716549
      ]
    },
    "13080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1069884098962985,
              38.4572533087454
            ]
          },
          "bbox": [
            -4.1069884098962985,
            38.4572533087454,
            -4.1069884098962985,
            38.4572533087454
          ]
        }
      ],
      "bbox": [
        -4.1069884098962985,
        38.4572533087454,
        -4.1069884098962985,
        38.4572533087454
      ]
    },
    "13081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.851744314839882,
              38.58214508811241
            ]
          },
          "bbox": [
            -2.851744314839882,
            38.58214508811241,
            -2.851744314839882,
            38.58214508811241
          ]
        }
      ],
      "bbox": [
        -2.851744314839882,
        38.58214508811241,
        -2.851744314839882,
        38.58214508811241
      ]
    },
    "13082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.969638822794397,
              39.20788137473909
            ]
          },
          "bbox": [
            -2.969638822794397,
            39.20788137473909,
            -2.969638822794397,
            39.20788137473909
          ]
        }
      ],
      "bbox": [
        -2.969638822794397,
        39.20788137473909,
        -2.969638822794397,
        39.20788137473909
      ]
    },
    "13083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7638912313707675,
              39.05011448347975
            ]
          },
          "bbox": [
            -3.7638912313707675,
            39.05011448347975,
            -3.7638912313707675,
            39.05011448347975
          ]
        }
      ],
      "bbox": [
        -3.7638912313707675,
        39.05011448347975,
        -3.7638912313707675,
        39.05011448347975
      ]
    },
    "13084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.228484113879918,
              38.59872019775469
            ]
          },
          "bbox": [
            -3.228484113879918,
            38.59872019775469,
            -3.228484113879918,
            38.59872019775469
          ]
        }
      ],
      "bbox": [
        -3.228484113879918,
        38.59872019775469,
        -3.228484113879918,
        38.59872019775469
      ]
    },
    "13085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.35147052365537,
              38.63613342315246
            ]
          },
          "bbox": [
            -3.35147052365537,
            38.63613342315246,
            -3.35147052365537,
            38.63613342315246
          ]
        }
      ],
      "bbox": [
        -3.35147052365537,
        38.63613342315246,
        -3.35147052365537,
        38.63613342315246
      ]
    },
    "13086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.742277165173719,
              38.95434789535276
            ]
          },
          "bbox": [
            -4.742277165173719,
            38.95434789535276,
            -4.742277165173719,
            38.95434789535276
          ]
        }
      ],
      "bbox": [
        -4.742277165173719,
        38.95434789535276,
        -4.742277165173719,
        38.95434789535276
      ]
    },
    "13087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4033727818334123,
              38.78647580981235
            ]
          },
          "bbox": [
            -3.4033727818334123,
            38.78647580981235,
            -3.4033727818334123,
            38.78647580981235
          ]
        }
      ],
      "bbox": [
        -3.4033727818334123,
        38.78647580981235,
        -3.4033727818334123,
        38.78647580981235
      ]
    },
    "13088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.786361038722423,
              38.8375193744116
            ]
          },
          "bbox": [
            -3.786361038722423,
            38.8375193744116,
            -3.786361038722423,
            38.8375193744116
          ]
        }
      ],
      "bbox": [
        -3.786361038722423,
        38.8375193744116,
        -3.786361038722423,
        38.8375193744116
      ]
    },
    "13089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8429081891917733,
              38.81161254212232
            ]
          },
          "bbox": [
            -2.8429081891917733,
            38.81161254212232,
            -2.8429081891917733,
            38.81161254212232
          ]
        }
      ],
      "bbox": [
        -2.8429081891917733,
        38.81161254212232,
        -2.8429081891917733,
        38.81161254212232
      ]
    },
    "13090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.057175364773253,
              38.50764280511559
            ]
          },
          "bbox": [
            -3.057175364773253,
            38.50764280511559,
            -3.057175364773253,
            38.50764280511559
          ]
        }
      ],
      "bbox": [
        -3.057175364773253,
        38.50764280511559,
        -3.057175364773253,
        38.50764280511559
      ]
    },
    "13091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1838750285110295,
              38.79669843580707
            ]
          },
          "bbox": [
            -4.1838750285110295,
            38.79669843580707,
            -4.1838750285110295,
            38.79669843580707
          ]
        }
      ],
      "bbox": [
        -4.1838750285110295,
        38.79669843580707,
        -4.1838750285110295,
        38.79669843580707
      ]
    },
    "13092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7037236758640435,
              38.696358843061795
            ]
          },
          "bbox": [
            -2.7037236758640435,
            38.696358843061795,
            -2.7037236758640435,
            38.696358843061795
          ]
        }
      ],
      "bbox": [
        -2.7037236758640435,
        38.696358843061795,
        -2.7037236758640435,
        38.696358843061795
      ]
    },
    "13093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.035545147718736,
              38.72023940403951
            ]
          },
          "bbox": [
            -3.035545147718736,
            38.72023940403951,
            -3.035545147718736,
            38.72023940403951
          ]
        }
      ],
      "bbox": [
        -3.035545147718736,
        38.72023940403951,
        -3.035545147718736,
        38.72023940403951
      ]
    },
    "13094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.879282562121015,
              38.60409450096376
            ]
          },
          "bbox": [
            -3.879282562121015,
            38.60409450096376,
            -3.879282562121015,
            38.60409450096376
          ]
        }
      ],
      "bbox": [
        -3.879282562121015,
        38.60409450096376,
        -3.879282562121015,
        38.60409450096376
      ]
    },
    "13095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.972773932675437,
              38.84960223011315
            ]
          },
          "bbox": [
            -3.972773932675437,
            38.84960223011315,
            -3.972773932675437,
            38.84960223011315
          ]
        }
      ],
      "bbox": [
        -3.972773932675437,
        38.84960223011315,
        -3.972773932675437,
        38.84960223011315
      ]
    },
    "13096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.644018266240393,
              39.215772274901184
            ]
          },
          "bbox": [
            -3.644018266240393,
            39.215772274901184,
            -3.644018266240393,
            39.215772274901184
          ]
        }
      ],
      "bbox": [
        -3.644018266240393,
        39.215772274901184,
        -3.644018266240393,
        39.215772274901184
      ]
    },
    "13097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4349247966300807,
              39.21057461283424
            ]
          },
          "bbox": [
            -3.4349247966300807,
            39.21057461283424,
            -3.4349247966300807,
            39.21057461283424
          ]
        }
      ],
      "bbox": [
        -3.4349247966300807,
        39.21057461283424,
        -3.4349247966300807,
        39.21057461283424
      ]
    },
    "13098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5823657599046874,
              38.51130817990386
            ]
          },
          "bbox": [
            -3.5823657599046874,
            38.51130817990386,
            -3.5823657599046874,
            38.51130817990386
          ]
        }
      ],
      "bbox": [
        -3.5823657599046874,
        38.51130817990386,
        -3.5823657599046874,
        38.51130817990386
      ]
    },
    "13901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3041803627490784,
              39.21097045070238
            ]
          },
          "bbox": [
            -4.3041803627490784,
            39.21097045070238,
            -4.3041803627490784,
            39.21097045070238
          ]
        }
      ],
      "bbox": [
        -4.3041803627490784,
        39.21097045070238,
        -4.3041803627490784,
        39.21097045070238
      ]
    },
    "13902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9016183289190076,
              39.00065904599207
            ]
          },
          "bbox": [
            -2.9016183289190076,
            39.00065904599207,
            -2.9016183289190076,
            39.00065904599207
          ]
        }
      ],
      "bbox": [
        -2.9016183289190076,
        39.00065904599207,
        -2.9016183289190076,
        39.00065904599207
      ]
    },
    "13903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.042296362142802,
              39.29906561054783
            ]
          },
          "bbox": [
            -3.042296362142802,
            39.29906561054783,
            -3.042296362142802,
            39.29906561054783
          ]
        }
      ],
      "bbox": [
        -3.042296362142802,
        39.29906561054783,
        -3.042296362142802,
        39.29906561054783
      ]
    },
    "13904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3461665798061446,
              39.11609269807441
            ]
          },
          "bbox": [
            -3.3461665798061446,
            39.11609269807441,
            -3.3461665798061446,
            39.11609269807441
          ]
        }
      ],
      "bbox": [
        -3.3461665798061446,
        39.11609269807441,
        -3.3461665798061446,
        39.11609269807441
      ]
    },
    "14001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.567578897537277,
              38.09034984044624
            ]
          },
          "bbox": [
            -4.567578897537277,
            38.09034984044624,
            -4.567578897537277,
            38.09034984044624
          ]
        }
      ],
      "bbox": [
        -4.567578897537277,
        38.09034984044624,
        -4.567578897537277,
        38.09034984044624
      ]
    },
    "14002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.677600237073973,
              37.462063850437794
            ]
          },
          "bbox": [
            -4.677600237073973,
            37.462063850437794,
            -4.677600237073973,
            37.462063850437794
          ]
        }
      ],
      "bbox": [
        -4.677600237073973,
        37.462063850437794,
        -4.677600237073973,
        37.462063850437794
      ]
    },
    "14003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9357054714576165,
              38.29399978402062
            ]
          },
          "bbox": [
            -4.9357054714576165,
            38.29399978402062,
            -4.9357054714576165,
            38.29399978402062
          ]
        }
      ],
      "bbox": [
        -4.9357054714576165,
        38.29399978402062,
        -4.9357054714576165,
        38.29399978402062
      ]
    },
    "14004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.067330453606005,
              37.4357878343514
            ]
          },
          "bbox": [
            -4.067330453606005,
            37.4357878343514,
            -4.067330453606005,
            37.4357878343514
          ]
        }
      ],
      "bbox": [
        -4.067330453606005,
        37.4357878343514,
        -4.067330453606005,
        37.4357878343514
      ]
    },
    "14005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.010259394715605,
              37.8387804961346
            ]
          },
          "bbox": [
            -5.010259394715605,
            37.8387804961346,
            -5.010259394715605,
            37.8387804961346
          ]
        }
      ],
      "bbox": [
        -5.010259394715605,
        37.8387804961346,
        -5.010259394715605,
        37.8387804961346
      ]
    },
    "14006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.867281315969291,
              38.32429721600359
            ]
          },
          "bbox": [
            -4.867281315969291,
            38.32429721600359,
            -4.867281315969291,
            38.32429721600359
          ]
        }
      ],
      "bbox": [
        -4.867281315969291,
        38.32429721600359,
        -4.867281315969291,
        38.32429721600359
      ]
    },
    "14007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3130017731737995,
              37.67906491021346
            ]
          },
          "bbox": [
            -4.3130017731737995,
            37.67906491021346,
            -4.3130017731737995,
            37.67906491021346
          ]
        }
      ],
      "bbox": [
        -4.3130017731737995,
        37.67906491021346,
        -4.3130017731737995,
        37.67906491021346
      ]
    },
    "14008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1488454516828766,
              38.591467536391555
            ]
          },
          "bbox": [
            -5.1488454516828766,
            38.591467536391555,
            -5.1488454516828766,
            38.591467536391555
          ]
        }
      ],
      "bbox": [
        -5.1488454516828766,
        38.591467536391555,
        -5.1488454516828766,
        38.591467536391555
      ]
    },
    "14009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.184088067461817,
              38.26555285449783
            ]
          },
          "bbox": [
            -5.184088067461817,
            38.26555285449783,
            -5.184088067461817,
            38.26555285449783
          ]
        }
      ],
      "bbox": [
        -5.184088067461817,
        38.26555285449783,
        -5.184088067461817,
        38.26555285449783
      ]
    },
    "14010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.542280083379828,
              37.247739075905734
            ]
          },
          "bbox": [
            -4.542280083379828,
            37.247739075905734,
            -4.542280083379828,
            37.247739075905734
          ]
        }
      ],
      "bbox": [
        -4.542280083379828,
        37.247739075905734,
        -4.542280083379828,
        37.247739075905734
      ]
    },
    "14011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.454164189613155,
              38.42446147595673
            ]
          },
          "bbox": [
            -5.454164189613155,
            38.42446147595673,
            -5.454164189613155,
            38.42446147595673
          ]
        }
      ],
      "bbox": [
        -5.454164189613155,
        38.42446147595673,
        -5.454164189613155,
        38.42446147595673
      ]
    },
    "14012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.372646139837742,
              37.916239809446004
            ]
          },
          "bbox": [
            -4.372646139837742,
            37.916239809446004,
            -4.372646139837742,
            37.916239809446004
          ]
        }
      ],
      "bbox": [
        -4.372646139837742,
        37.916239809446004,
        -4.372646139837742,
        37.916239809446004
      ]
    },
    "14013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.449891975134623,
              37.48963095010222
            ]
          },
          "bbox": [
            -4.449891975134623,
            37.48963095010222,
            -4.449891975134623,
            37.48963095010222
          ]
        }
      ],
      "bbox": [
        -4.449891975134623,
        37.48963095010222,
        -4.449891975134623,
        37.48963095010222
      ]
    },
    "14014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3294309844332775,
              37.84862306790686
            ]
          },
          "bbox": [
            -4.3294309844332775,
            37.84862306790686,
            -4.3294309844332775,
            37.84862306790686
          ]
        }
      ],
      "bbox": [
        -4.3294309844332775,
        37.84862306790686,
        -4.3294309844332775,
        37.84862306790686
      ]
    },
    "14015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.303945991723122,
              37.44851203628509
            ]
          },
          "bbox": [
            -4.303945991723122,
            37.44851203628509,
            -4.303945991723122,
            37.44851203628509
          ]
        }
      ],
      "bbox": [
        -4.303945991723122,
        37.44851203628509,
        -4.303945991723122,
        37.44851203628509
      ]
    },
    "14016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.367366936049139,
              38.276727779067066
            ]
          },
          "bbox": [
            -4.367366936049139,
            38.276727779067066,
            -4.367366936049139,
            38.276727779067066
          ]
        }
      ],
      "bbox": [
        -4.367366936049139,
        38.276727779067066,
        -4.367366936049139,
        38.276727779067066
      ]
    },
    "14017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9322825749521995,
              37.67852177864096
            ]
          },
          "bbox": [
            -4.9322825749521995,
            37.67852177864096,
            -4.9322825749521995,
            37.67852177864096
          ]
        }
      ],
      "bbox": [
        -4.9322825749521995,
        37.67852177864096,
        -4.9322825749521995,
        37.67852177864096
      ]
    },
    "14018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.49017045402572,
              37.933573752708526
            ]
          },
          "bbox": [
            -4.49017045402572,
            37.933573752708526,
            -4.49017045402572,
            37.933573752708526
          ]
        }
      ],
      "bbox": [
        -4.49017045402572,
        37.933573752708526,
        -4.49017045402572,
        37.933573752708526
      ]
    },
    "14019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.479466410805978,
              37.68235281725178
            ]
          },
          "bbox": [
            -4.479466410805978,
            37.68235281725178,
            -4.479466410805978,
            37.68235281725178
          ]
        }
      ],
      "bbox": [
        -4.479466410805978,
        37.68235281725178,
        -4.479466410805978,
        37.68235281725178
      ]
    },
    "14020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.491626720282383,
              38.40535181319754
            ]
          },
          "bbox": [
            -4.491626720282383,
            38.40535181319754,
            -4.491626720282383,
            38.40535181319754
          ]
        }
      ],
      "bbox": [
        -4.491626720282383,
        38.40535181319754,
        -4.491626720282383,
        38.40535181319754
      ]
    },
    "14021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.701840662804723,
              37.84927498894039
            ]
          },
          "bbox": [
            -4.701840662804723,
            37.84927498894039,
            -4.701840662804723,
            37.84927498894039
          ]
        }
      ],
      "bbox": [
        -4.701840662804723,
        37.84927498894039,
        -4.701840662804723,
        37.84927498894039
      ]
    },
    "14022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.36923197811161,
              37.552574970315774
            ]
          },
          "bbox": [
            -4.36923197811161,
            37.552574970315774,
            -4.36923197811161,
            37.552574970315774
          ]
        }
      ],
      "bbox": [
        -4.36923197811161,
        37.552574970315774,
        -4.36923197811161,
        37.552574970315774
      ]
    },
    "14023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.853641118163369,
              38.47048516012321
            ]
          },
          "bbox": [
            -4.853641118163369,
            38.47048516012321,
            -4.853641118163369,
            38.47048516012321
          ]
        }
      ],
      "bbox": [
        -4.853641118163369,
        38.47048516012321,
        -4.853641118163369,
        38.47048516012321
      ]
    },
    "14024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4752822926942954,
              37.278014074152324
            ]
          },
          "bbox": [
            -4.4752822926942954,
            37.278014074152324,
            -4.4752822926942954,
            37.278014074152324
          ]
        }
      ],
      "bbox": [
        -4.4752822926942954,
        37.278014074152324,
        -4.4752822926942954,
        37.278014074152324
      ]
    },
    "14025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.556938159221322,
              37.66943195417175
            ]
          },
          "bbox": [
            -4.556938159221322,
            37.66943195417175,
            -4.556938159221322,
            37.66943195417175
          ]
        }
      ],
      "bbox": [
        -4.556938159221322,
        37.66943195417175,
        -4.556938159221322,
        37.66943195417175
      ]
    },
    "14026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.076418507006385,
              38.13278882266715
            ]
          },
          "bbox": [
            -5.076418507006385,
            38.13278882266715,
            -5.076418507006385,
            38.13278882266715
          ]
        }
      ],
      "bbox": [
        -5.076418507006385,
        38.13278882266715,
        -5.076418507006385,
        38.13278882266715
      ]
    },
    "14027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.728504865156672,
              37.68592837899349
            ]
          },
          "bbox": [
            -4.728504865156672,
            37.68592837899349,
            -4.728504865156672,
            37.68592837899349
          ]
        }
      ],
      "bbox": [
        -4.728504865156672,
        37.68592837899349,
        -4.728504865156672,
        37.68592837899349
      ]
    },
    "14028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.048542019468535,
              38.41972107279565
            ]
          },
          "bbox": [
            -5.048542019468535,
            38.41972107279565,
            -5.048542019468535,
            38.41972107279565
          ]
        }
      ],
      "bbox": [
        -5.048542019468535,
        38.41972107279565,
        -5.048542019468535,
        38.41972107279565
      ]
    },
    "14029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.427810495590982,
              38.26466132247672
            ]
          },
          "bbox": [
            -5.427810495590982,
            38.26466132247672,
            -5.427810495590982,
            38.26466132247672
          ]
        }
      ],
      "bbox": [
        -5.427810495590982,
        38.26466132247672,
        -5.427810495590982,
        38.26466132247672
      ]
    },
    "14030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.089524344446464,
              37.72246728029322
            ]
          },
          "bbox": [
            -5.089524344446464,
            37.72246728029322,
            -5.089524344446464,
            37.72246728029322
          ]
        }
      ],
      "bbox": [
        -5.089524344446464,
        37.72246728029322,
        -5.089524344446464,
        37.72246728029322
      ]
    },
    "14031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.129107286859886,
              37.518515428938336
            ]
          },
          "bbox": [
            -4.129107286859886,
            37.518515428938336,
            -4.129107286859886,
            37.518515428938336
          ]
        }
      ],
      "bbox": [
        -4.129107286859886,
        37.518515428938336,
        -4.129107286859886,
        37.518515428938336
      ]
    },
    "14032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.337499090998414,
              38.360377974797515
            ]
          },
          "bbox": [
            -5.337499090998414,
            38.360377974797515,
            -5.337499090998414,
            38.360377974797515
          ]
        }
      ],
      "bbox": [
        -5.337499090998414,
        38.360377974797515,
        -5.337499090998414,
        38.360377974797515
      ]
    },
    "14033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.972896387097072,
              37.751766431320064
            ]
          },
          "bbox": [
            -4.972896387097072,
            37.751766431320064,
            -4.972896387097072,
            37.751766431320064
          ]
        }
      ],
      "bbox": [
        -4.972896387097072,
        37.751766431320064,
        -4.972896387097072,
        37.751766431320064
      ]
    },
    "14034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.756541530844995,
              38.52440720564743
            ]
          },
          "bbox": [
            -4.756541530844995,
            38.52440720564743,
            -4.756541530844995,
            38.52440720564743
          ]
        }
      ],
      "bbox": [
        -4.756541530844995,
        38.52440720564743,
        -4.756541530844995,
        38.52440720564743
      ]
    },
    "14035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.188758819283089,
              38.4535010460111
            ]
          },
          "bbox": [
            -5.188758819283089,
            38.4535010460111,
            -5.188758819283089,
            38.4535010460111
          ]
        }
      ],
      "bbox": [
        -5.188758819283089,
        38.4535010460111,
        -5.188758819283089,
        38.4535010460111
      ]
    },
    "14036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.328704986324307,
              37.947946466768286
            ]
          },
          "bbox": [
            -5.328704986324307,
            37.947946466768286,
            -5.328704986324307,
            37.947946466768286
          ]
        }
      ],
      "bbox": [
        -5.328704986324307,
        37.947946466768286,
        -5.328704986324307,
        37.947946466768286
      ]
    },
    "14037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.307368094677733,
              37.26599552206771
            ]
          },
          "bbox": [
            -4.307368094677733,
            37.26599552206771,
            -4.307368094677733,
            37.26599552206771
          ]
        }
      ],
      "bbox": [
        -4.307368094677733,
        37.26599552206771,
        -4.307368094677733,
        37.26599552206771
      ]
    },
    "14038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.541884421862457,
              37.35720751826813
            ]
          },
          "bbox": [
            -4.541884421862457,
            37.35720751826813,
            -4.541884421862457,
            37.35720751826813
          ]
        }
      ],
      "bbox": [
        -4.541884421862457,
        37.35720751826813,
        -4.541884421862457,
        37.35720751826813
      ]
    },
    "14039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.238496835506721,
              37.556522277963964
            ]
          },
          "bbox": [
            -4.238496835506721,
            37.556522277963964,
            -4.238496835506721,
            37.556522277963964
          ]
        }
      ],
      "bbox": [
        -4.238496835506721,
        37.556522277963964,
        -4.238496835506721,
        37.556522277963964
      ]
    },
    "14040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.751071247363683,
              37.55086291733798
            ]
          },
          "bbox": [
            -4.751071247363683,
            37.55086291733798,
            -4.751071247363683,
            37.55086291733798
          ]
        }
      ],
      "bbox": [
        -4.751071247363683,
        37.55086291733798,
        -4.751071247363683,
        37.55086291733798
      ]
    },
    "14041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.656833523725982,
              37.655373290806516
            ]
          },
          "bbox": [
            -4.656833523725982,
            37.655373290806516,
            -4.656833523725982,
            37.655373290806516
          ]
        }
      ],
      "bbox": [
        -4.656833523725982,
        37.655373290806516,
        -4.656833523725982,
        37.655373290806516
      ]
    },
    "14042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.638492965413156,
              37.57208605595756
            ]
          },
          "bbox": [
            -4.638492965413156,
            37.57208605595756,
            -4.638492965413156,
            37.57208605595756
          ]
        }
      ],
      "bbox": [
        -4.638492965413156,
        37.57208605595756,
        -4.638492965413156,
        37.57208605595756
      ]
    },
    "14043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3657022704301784,
              38.10415897040629
            ]
          },
          "bbox": [
            -4.3657022704301784,
            38.10415897040629,
            -4.3657022704301784,
            38.10415897040629
          ]
        }
      ],
      "bbox": [
        -4.3657022704301784,
        38.10415897040629,
        -4.3657022704301784,
        38.10415897040629
      ]
    },
    "14044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.581276373898589,
              37.47585808009916
            ]
          },
          "bbox": [
            -4.581276373898589,
            37.47585808009916,
            -4.581276373898589,
            37.47585808009916
          ]
        }
      ],
      "bbox": [
        -4.581276373898589,
        37.47585808009916,
        -4.581276373898589,
        37.47585808009916
      ]
    },
    "14045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.628258589652064,
              37.42673960905036
            ]
          },
          "bbox": [
            -4.628258589652064,
            37.42673960905036,
            -4.628258589652064,
            37.42673960905036
          ]
        }
      ],
      "bbox": [
        -4.628258589652064,
        37.42673960905036,
        -4.628258589652064,
        37.42673960905036
      ]
    },
    "14046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.447572260205367,
              37.60011834490402
            ]
          },
          "bbox": [
            -4.447572260205367,
            37.60011834490402,
            -4.447572260205367,
            37.60011834490402
          ]
        }
      ],
      "bbox": [
        -4.447572260205367,
        37.60011834490402,
        -4.447572260205367,
        37.60011834490402
      ]
    },
    "14047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.760408857207197,
              38.074023836499485
            ]
          },
          "bbox": [
            -4.760408857207197,
            38.074023836499485,
            -4.760408857207197,
            38.074023836499485
          ]
        }
      ],
      "bbox": [
        -4.760408857207197,
        38.074023836499485,
        -4.760408857207197,
        38.074023836499485
      ]
    },
    "14048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.591452019862312,
              37.248903324118764
            ]
          },
          "bbox": [
            -4.591452019862312,
            37.248903324118764,
            -4.591452019862312,
            37.248903324118764
          ]
        }
      ],
      "bbox": [
        -4.591452019862312,
        37.248903324118764,
        -4.591452019862312,
        37.248903324118764
      ]
    },
    "14049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.292128777374854,
              37.67255871886964
            ]
          },
          "bbox": [
            -5.292128777374854,
            37.67255871886964,
            -5.292128777374854,
            37.67255871886964
          ]
        }
      ],
      "bbox": [
        -5.292128777374854,
        37.67255871886964,
        -5.292128777374854,
        37.67255871886964
      ]
    },
    "14050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.455492326517964,
              37.96934998087848
            ]
          },
          "bbox": [
            -4.455492326517964,
            37.96934998087848,
            -4.455492326517964,
            37.96934998087848
          ]
        }
      ],
      "bbox": [
        -4.455492326517964,
        37.96934998087848,
        -4.455492326517964,
        37.96934998087848
      ]
    },
    "14051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.741713081924516,
              38.42170006695524
            ]
          },
          "bbox": [
            -4.741713081924516,
            38.42170006695524,
            -4.741713081924516,
            38.42170006695524
          ]
        }
      ],
      "bbox": [
        -4.741713081924516,
        38.42170006695524,
        -4.741713081924516,
        38.42170006695524
      ]
    },
    "14052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.244143807612674,
              38.3178516763365
            ]
          },
          "bbox": [
            -5.244143807612674,
            38.3178516763365,
            -5.244143807612674,
            38.3178516763365
          ]
        }
      ],
      "bbox": [
        -5.244143807612674,
        38.3178516763365,
        -5.244143807612674,
        38.3178516763365
      ]
    },
    "14053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.117892194614158,
              37.83782341935381
            ]
          },
          "bbox": [
            -5.117892194614158,
            37.83782341935381,
            -5.117892194614158,
            37.83782341935381
          ]
        }
      ],
      "bbox": [
        -5.117892194614158,
        37.83782341935381,
        -5.117892194614158,
        37.83782341935381
      ]
    },
    "14054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.798746340948573,
              38.26293163056032
            ]
          },
          "bbox": [
            -4.798746340948573,
            38.26293163056032,
            -4.798746340948573,
            38.26293163056032
          ]
        }
      ],
      "bbox": [
        -4.798746340948573,
        38.26293163056032,
        -4.798746340948573,
        38.26293163056032
      ]
    },
    "14055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.192642819878022,
              37.43135223526779
            ]
          },
          "bbox": [
            -4.192642819878022,
            37.43135223526779,
            -4.192642819878022,
            37.43135223526779
          ]
        }
      ],
      "bbox": [
        -4.192642819878022,
        37.43135223526779,
        -4.192642819878022,
        37.43135223526779
      ]
    },
    "14056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.761183762202902,
              37.402435416840106
            ]
          },
          "bbox": [
            -4.761183762202902,
            37.402435416840106,
            -4.761183762202902,
            37.402435416840106
          ]
        }
      ],
      "bbox": [
        -4.761183762202902,
        37.402435416840106,
        -4.761183762202902,
        37.402435416840106
      ]
    },
    "14057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.788350758040937,
              37.66877249695914
            ]
          },
          "bbox": [
            -4.788350758040937,
            37.66877249695914,
            -4.788350758040937,
            37.66877249695914
          ]
        }
      ],
      "bbox": [
        -4.788350758040937,
        37.66877249695914,
        -4.788350758040937,
        37.66877249695914
      ]
    },
    "14058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.368669633474294,
              37.347914422504054
            ]
          },
          "bbox": [
            -4.368669633474294,
            37.347914422504054,
            -4.368669633474294,
            37.347914422504054
          ]
        }
      ],
      "bbox": [
        -4.368669633474294,
        37.347914422504054,
        -4.368669633474294,
        37.347914422504054
      ]
    },
    "14059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.831920052358707,
              37.66726904393433
            ]
          },
          "bbox": [
            -4.831920052358707,
            37.66726904393433,
            -4.831920052358707,
            37.66726904393433
          ]
        }
      ],
      "bbox": [
        -4.831920052358707,
        37.66726904393433,
        -4.831920052358707,
        37.66726904393433
      ]
    },
    "14060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.852191955339217,
              37.53362877166954
            ]
          },
          "bbox": [
            -4.852191955339217,
            37.53362877166954,
            -4.852191955339217,
            37.53362877166954
          ]
        }
      ],
      "bbox": [
        -4.852191955339217,
        37.53362877166954,
        -4.852191955339217,
        37.53362877166954
      ]
    },
    "14061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8971893772904345,
              38.60588553163758
            ]
          },
          "bbox": [
            -4.8971893772904345,
            38.60588553163758,
            -4.8971893772904345,
            38.60588553163758
          ]
        }
      ],
      "bbox": [
        -4.8971893772904345,
        38.60588553163758,
        -4.8971893772904345,
        38.60588553163758
      ]
    },
    "14062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.634706809379393,
              38.462481470153584
            ]
          },
          "bbox": [
            -4.634706809379393,
            38.462481470153584,
            -4.634706809379393,
            38.462481470153584
          ]
        }
      ],
      "bbox": [
        -4.634706809379393,
        38.462481470153584,
        -4.634706809379393,
        38.462481470153584
      ]
    },
    "14063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.241596248450313,
              37.77001413773954
            ]
          },
          "bbox": [
            -4.241596248450313,
            37.77001413773954,
            -4.241596248450313,
            37.77001413773954
          ]
        }
      ],
      "bbox": [
        -4.241596248450313,
        37.77001413773954,
        -4.241596248450313,
        37.77001413773954
      ]
    },
    "14064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.375240843457624,
              38.45898403500494
            ]
          },
          "bbox": [
            -5.375240843457624,
            38.45898403500494,
            -5.375240843457624,
            38.45898403500494
          ]
        }
      ],
      "bbox": [
        -5.375240843457624,
        38.45898403500494,
        -5.375240843457624,
        38.45898403500494
      ]
    },
    "14065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.854378135041592,
              37.69679212416828
            ]
          },
          "bbox": [
            -4.854378135041592,
            37.69679212416828,
            -4.854378135041592,
            37.69679212416828
          ]
        }
      ],
      "bbox": [
        -4.854378135041592,
        37.69679212416828,
        -4.854378135041592,
        37.69679212416828
      ]
    },
    "14066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.298677188656426,
              37.98107391480227
            ]
          },
          "bbox": [
            -4.298677188656426,
            37.98107391480227,
            -4.298677188656426,
            37.98107391480227
          ]
        }
      ],
      "bbox": [
        -4.298677188656426,
        37.98107391480227,
        -4.298677188656426,
        37.98107391480227
      ]
    },
    "14067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.556499864234918,
              37.9544684294389
            ]
          },
          "bbox": [
            -4.556499864234918,
            37.9544684294389,
            -4.556499864234918,
            37.9544684294389
          ]
        }
      ],
      "bbox": [
        -4.556499864234918,
        37.9544684294389,
        -4.556499864234918,
        37.9544684294389
      ]
    },
    "14068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.90018387239886,
              38.138632509123575
            ]
          },
          "bbox": [
            -4.90018387239886,
            38.138632509123575,
            -4.90018387239886,
            38.138632509123575
          ]
        }
      ],
      "bbox": [
        -4.90018387239886,
        38.138632509123575,
        -4.90018387239886,
        38.138632509123575
      ]
    },
    "14069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.62968926631726,
              38.27606002531299
            ]
          },
          "bbox": [
            -4.62968926631726,
            38.27606002531299,
            -4.62968926631726,
            38.27606002531299
          ]
        }
      ],
      "bbox": [
        -4.62968926631726,
        38.27606002531299,
        -4.62968926631726,
        38.27606002531299
      ]
    },
    "14070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.038958909974334,
              38.34794039802986
            ]
          },
          "bbox": [
            -5.038958909974334,
            38.34794039802986,
            -5.038958909974334,
            38.34794039802986
          ]
        }
      ],
      "bbox": [
        -5.038958909974334,
        38.34794039802986,
        -5.038958909974334,
        38.34794039802986
      ]
    },
    "14071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.189355191238985,
              38.14362448370766
            ]
          },
          "bbox": [
            -5.189355191238985,
            38.14362448370766,
            -5.189355191238985,
            38.14362448370766
          ]
        }
      ],
      "bbox": [
        -5.189355191238985,
        38.14362448370766,
        -5.189355191238985,
        38.14362448370766
      ]
    },
    "14072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.974997011067715,
              38.45482585905109
            ]
          },
          "bbox": [
            -4.974997011067715,
            38.45482585905109,
            -4.974997011067715,
            38.45482585905109
          ]
        }
      ],
      "bbox": [
        -4.974997011067715,
        38.45482585905109,
        -4.974997011067715,
        38.45482585905109
      ]
    },
    "14073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.008081618301887,
              38.01340663902395
            ]
          },
          "bbox": [
            -5.008081618301887,
            38.01340663902395,
            -5.008081618301887,
            38.01340663902395
          ]
        }
      ],
      "bbox": [
        -5.008081618301887,
        38.01340663902395,
        -5.008081618301887,
        38.01340663902395
      ]
    },
    "14074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9938435063673525,
              38.598815027849405
            ]
          },
          "bbox": [
            -4.9938435063673525,
            38.598815027849405,
            -4.9938435063673525,
            38.598815027849405
          ]
        }
      ],
      "bbox": [
        -4.9938435063673525,
        38.598815027849405,
        -4.9938435063673525,
        38.598815027849405
      ]
    },
    "14075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.332508292319334,
              37.52653079825027
            ]
          },
          "bbox": [
            -4.332508292319334,
            37.52653079825027,
            -4.332508292319334,
            37.52653079825027
          ]
        }
      ],
      "bbox": [
        -4.332508292319334,
        37.52653079825027,
        -4.332508292319334,
        37.52653079825027
      ]
    },
    "14901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.142285634020861,
              37.67522838011258
            ]
          },
          "bbox": [
            -5.142285634020861,
            37.67522838011258,
            -5.142285634020861,
            37.67522838011258
          ]
        }
      ],
      "bbox": [
        -5.142285634020861,
        37.67522838011258,
        -5.142285634020861,
        37.67522838011258
      ]
    },
    "14902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8706956660872285,
              37.626426106046075
            ]
          },
          "bbox": [
            -4.8706956660872285,
            37.626426106046075,
            -4.8706956660872285,
            37.626426106046075
          ]
        }
      ],
      "bbox": [
        -4.8706956660872285,
        37.626426106046075,
        -4.8706956660872285,
        37.626426106046075
      ]
    },
    "15001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.294993750305329,
              43.20893350524327
            ]
          },
          "bbox": [
            -8.294993750305329,
            43.20893350524327,
            -8.294993750305329,
            43.20893350524327
          ]
        }
      ],
      "bbox": [
        -8.294993750305329,
        43.20893350524327,
        -8.294993750305329,
        43.20893350524327
      ]
    },
    "15002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.646062045282896,
              42.890904205093946
            ]
          },
          "bbox": [
            -8.646062045282896,
            42.890904205093946,
            -8.646062045282896,
            42.890904205093946
          ]
        }
      ],
      "bbox": [
        -8.646062045282896,
        42.890904205093946,
        -8.646062045282896,
        42.890904205093946
      ]
    },
    "15003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.012839000680945,
              43.2227201368285
            ]
          },
          "bbox": [
            -8.012839000680945,
            43.2227201368285,
            -8.012839000680945,
            43.2227201368285
          ]
        }
      ],
      "bbox": [
        -8.012839000680945,
        43.2227201368285,
        -8.012839000680945,
        43.2227201368285
      ]
    },
    "15004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.256865352650706,
              43.43769570237997
            ]
          },
          "bbox": [
            -8.256865352650706,
            43.43769570237997,
            -8.256865352650706,
            43.43769570237997
          ]
        }
      ],
      "bbox": [
        -8.256865352650706,
        43.43769570237997,
        -8.256865352650706,
        43.43769570237997
      ]
    },
    "15005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.508153460579356,
              43.299587765427546
            ]
          },
          "bbox": [
            -8.508153460579356,
            43.299587765427546,
            -8.508153460579356,
            43.299587765427546
          ]
        }
      ],
      "bbox": [
        -8.508153460579356,
        43.299587765427546,
        -8.508153460579356,
        43.299587765427546
      ]
    },
    "15006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.182271058324433,
              42.92689140550677
            ]
          },
          "bbox": [
            -8.182271058324433,
            42.92689140550677,
            -8.182271058324433,
            42.92689140550677
          ]
        }
      ],
      "bbox": [
        -8.182271058324433,
        42.92689140550677,
        -8.182271058324433,
        42.92689140550677
      ]
    },
    "15007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.752828543729043,
              42.96341803791649
            ]
          },
          "bbox": [
            -8.752828543729043,
            42.96341803791649,
            -8.752828543729043,
            42.96341803791649
          ]
        }
      ],
      "bbox": [
        -8.752828543729043,
        42.96341803791649,
        -8.752828543729043,
        42.96341803791649
      ]
    },
    "15008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.248821077013647,
              43.31151325118344
            ]
          },
          "bbox": [
            -8.248821077013647,
            43.31151325118344,
            -8.248821077013647,
            43.31151325118344
          ]
        }
      ],
      "bbox": [
        -8.248821077013647,
        43.31151325118344,
        -8.248821077013647,
        43.31151325118344
      ]
    },
    "15009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.223236098796573,
              43.27513904547549
            ]
          },
          "bbox": [
            -8.223236098796573,
            43.27513904547549,
            -8.223236098796573,
            43.27513904547549
          ]
        }
      ],
      "bbox": [
        -8.223236098796573,
        43.27513904547549,
        -8.223236098796573,
        43.27513904547549
      ]
    },
    "15010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.13077517374856,
              43.008318535506994
            ]
          },
          "bbox": [
            -8.13077517374856,
            43.008318535506994,
            -8.13077517374856,
            43.008318535506994
          ]
        }
      ],
      "bbox": [
        -8.13077517374856,
        43.008318535506994,
        -8.13077517374856,
        43.008318535506994
      ]
    },
    "15011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.889027819998338,
              42.67898225927343
            ]
          },
          "bbox": [
            -8.889027819998338,
            42.67898225927343,
            -8.889027819998338,
            42.67898225927343
          ]
        }
      ],
      "bbox": [
        -8.889027819998338,
        42.67898225927343,
        -8.889027819998338,
        42.67898225927343
      ]
    },
    "15012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.422627138186654,
              42.82756199523558
            ]
          },
          "bbox": [
            -8.422627138186654,
            42.82756199523558,
            -8.422627138186654,
            42.82756199523558
          ]
        }
      ],
      "bbox": [
        -8.422627138186654,
        42.82756199523558,
        -8.422627138186654,
        42.82756199523558
      ]
    },
    "15013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.720919717331,
              42.85179953101688
            ]
          },
          "bbox": [
            -8.720919717331,
            42.85179953101688,
            -8.720919717331,
            42.85179953101688
          ]
        }
      ],
      "bbox": [
        -8.720919717331,
        42.85179953101688,
        -8.720919717331,
        42.85179953101688
      ]
    },
    "15014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.895518466085264,
              43.1943680345445
            ]
          },
          "bbox": [
            -8.895518466085264,
            43.1943680345445,
            -8.895518466085264,
            43.1943680345445
          ]
        }
      ],
      "bbox": [
        -8.895518466085264,
        43.1943680345445,
        -8.895518466085264,
        43.1943680345445
      ]
    },
    "15015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.130856841860316,
              43.43426861649651
            ]
          },
          "bbox": [
            -8.130856841860316,
            43.43426861649651,
            -8.130856841860316,
            43.43426861649651
          ]
        }
      ],
      "bbox": [
        -8.130856841860316,
        43.43426861649651,
        -8.130856841860316,
        43.43426861649651
      ]
    },
    "15016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.145705059139804,
              43.15984172492155
            ]
          },
          "bbox": [
            -9.145705059139804,
            43.15984172492155,
            -9.145705059139804,
            43.15984172492155
          ]
        }
      ],
      "bbox": [
        -9.145705059139804,
        43.15984172492155,
        -9.145705059139804,
        43.15984172492155
      ]
    },
    "15017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.328883566339188,
              43.27982359306345
            ]
          },
          "bbox": [
            -8.328883566339188,
            43.27982359306345,
            -8.328883566339188,
            43.27982359306345
          ]
        }
      ],
      "bbox": [
        -8.328883566339188,
        43.27982359306345,
        -8.328883566339188,
        43.27982359306345
      ]
    },
    "15018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.041166024787861,
              43.44495567357619
            ]
          },
          "bbox": [
            -8.041166024787861,
            43.44495567357619,
            -8.041166024787861,
            43.44495567357619
          ]
        }
      ],
      "bbox": [
        -8.041166024787861,
        43.44495567357619,
        -8.041166024787861,
        43.44495567357619
      ]
    },
    "15019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.668667560248076,
              43.21262900460117
            ]
          },
          "bbox": [
            -8.668667560248076,
            43.21262900460117,
            -8.668667560248076,
            43.21262900460117
          ]
        }
      ],
      "bbox": [
        -8.668667560248076,
        43.21262900460117,
        -8.668667560248076,
        43.21262900460117
      ]
    },
    "15020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.101895493374421,
              42.84541319674959
            ]
          },
          "bbox": [
            -9.101895493374421,
            42.84541319674959,
            -9.101895493374421,
            42.84541319674959
          ]
        }
      ],
      "bbox": [
        -9.101895493374421,
        42.84541319674959,
        -9.101895493374421,
        42.84541319674959
      ]
    },
    "15021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.359983588170234,
              43.212005580064506
            ]
          },
          "bbox": [
            -8.359983588170234,
            43.212005580064506,
            -8.359983588170234,
            43.212005580064506
          ]
        }
      ],
      "bbox": [
        -8.359983588170234,
        43.212005580064506,
        -8.359983588170234,
        43.212005580064506
      ]
    },
    "15022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.00510604975123,
              43.673564858629774
            ]
          },
          "bbox": [
            -8.00510604975123,
            43.673564858629774,
            -8.00510604975123,
            43.673564858629774
          ]
        }
      ],
      "bbox": [
        -8.00510604975123,
        43.673564858629774,
        -8.00510604975123,
        43.673564858629774
      ]
    },
    "15023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.195163163566725,
              42.96943325812391
            ]
          },
          "bbox": [
            -9.195163163566725,
            42.96943325812391,
            -9.195163163566725,
            42.96943325812391
          ]
        }
      ],
      "bbox": [
        -9.195163163566725,
        42.96943325812391,
        -9.195163163566725,
        42.96943325812391
      ]
    },
    "15024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.483405686375018,
              43.167051764118725
            ]
          },
          "bbox": [
            -8.483405686375018,
            43.167051764118725,
            -8.483405686375018,
            43.167051764118725
          ]
        }
      ],
      "bbox": [
        -8.483405686375018,
        43.167051764118725,
        -8.483405686375018,
        43.167051764118725
      ]
    },
    "15025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.962003304809674,
              43.61179066433015
            ]
          },
          "bbox": [
            -7.962003304809674,
            43.61179066433015,
            -7.962003304809674,
            43.61179066433015
          ]
        }
      ],
      "bbox": [
        -7.962003304809674,
        43.61179066433015,
        -7.962003304809674,
        43.61179066433015
      ]
    },
    "15027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.138262335271902,
              43.24188520794793
            ]
          },
          "bbox": [
            -8.138262335271902,
            43.24188520794793,
            -8.138262335271902,
            43.24188520794793
          ]
        }
      ],
      "bbox": [
        -8.138262335271902,
        43.24188520794793,
        -8.138262335271902,
        43.24188520794793
      ]
    },
    "15028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.198326942279044,
              42.93150544943649
            ]
          },
          "bbox": [
            -9.198326942279044,
            42.93150544943649,
            -9.198326942279044,
            42.93150544943649
          ]
        }
      ],
      "bbox": [
        -9.198326942279044,
        42.93150544943649,
        -9.198326942279044,
        42.93150544943649
      ]
    },
    "15029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.767971930256865,
              43.160761566757024
            ]
          },
          "bbox": [
            -8.767971930256865,
            43.160761566757024,
            -8.767971930256865,
            43.160761566757024
          ]
        }
      ],
      "bbox": [
        -8.767971930256865,
        43.160761566757024,
        -8.767971930256865,
        43.160761566757024
      ]
    },
    "15030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.422240483253034,
              43.34611383685746
            ]
          },
          "bbox": [
            -8.422240483253034,
            43.34611383685746,
            -8.422240483253034,
            43.34611383685746
          ]
        }
      ],
      "bbox": [
        -8.422240483253034,
        43.34611383685746,
        -8.422240483253034,
        43.34611383685746
      ]
    },
    "15031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.406148275481604,
              43.27330761337529
            ]
          },
          "bbox": [
            -8.406148275481604,
            43.27330761337529,
            -8.406148275481604,
            43.27330761337529
          ]
        }
      ],
      "bbox": [
        -8.406148275481604,
        43.27330761337529,
        -8.406148275481604,
        43.27330761337529
      ]
    },
    "15032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.045741220656042,
              43.13513915434956
            ]
          },
          "bbox": [
            -8.045741220656042,
            43.13513915434956,
            -8.045741220656042,
            43.13513915434956
          ]
        }
      ],
      "bbox": [
        -8.045741220656042,
        43.13513915434956,
        -8.045741220656042,
        43.13513915434956
      ]
    },
    "15033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.720128239718669,
              42.722813124192506
            ]
          },
          "bbox": [
            -8.720128239718669,
            42.722813124192506,
            -8.720128239718669,
            42.722813124192506
          ]
        }
      ],
      "bbox": [
        -8.720128239718669,
        42.722813124192506,
        -8.720128239718669,
        42.722813124192506
      ]
    },
    "15034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.097037409639984,
              42.98842078432525
            ]
          },
          "bbox": [
            -9.097037409639984,
            42.98842078432525,
            -9.097037409639984,
            42.98842078432525
          ]
        }
      ],
      "bbox": [
        -9.097037409639984,
        42.98842078432525,
        -9.097037409639984,
        42.98842078432525
      ]
    },
    "15035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.159859547446281,
              43.462093162246276
            ]
          },
          "bbox": [
            -8.159859547446281,
            43.462093162246276,
            -8.159859547446281,
            43.462093162246276
          ]
        }
      ],
      "bbox": [
        -8.159859547446281,
        43.462093162246276,
        -8.159859547446281,
        43.462093162246276
      ]
    },
    "15036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.270436625378354,
              43.509823872216906
            ]
          },
          "bbox": [
            -8.270436625378354,
            43.509823872216906,
            -8.270436625378354,
            43.509823872216906
          ]
        }
      ],
      "bbox": [
        -8.270436625378354,
        43.509823872216906,
        -8.270436625378354,
        43.509823872216906
      ]
    },
    "15037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.260363462842765,
              42.9374158293726
            ]
          },
          "bbox": [
            -9.260363462842765,
            42.9374158293726,
            -9.260363462842765,
            42.9374158293726
          ]
        }
      ],
      "bbox": [
        -9.260363462842765,
        42.9374158293726,
        -9.260363462842765,
        42.9374158293726
      ]
    },
    "15038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.277543410955097,
              43.045269073481116
            ]
          },
          "bbox": [
            -8.277543410955097,
            43.045269073481116,
            -8.277543410955097,
            43.045269073481116
          ]
        }
      ],
      "bbox": [
        -8.277543410955097,
        43.045269073481116,
        -8.277543410955097,
        43.045269073481116
      ]
    },
    "15039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.069746300785274,
              43.2855365436456
            ]
          },
          "bbox": [
            -8.069746300785274,
            43.2855365436456,
            -8.069746300785274,
            43.2855365436456
          ]
        }
      ],
      "bbox": [
        -8.069746300785274,
        43.2855365436456,
        -8.069746300785274,
        43.2855365436456
      ]
    },
    "15040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.019103685053029,
              43.1875835353792
            ]
          },
          "bbox": [
            -9.019103685053029,
            43.1875835353792,
            -9.019103685053029,
            43.1875835353792
          ]
        }
      ],
      "bbox": [
        -9.019103685053029,
        43.1875835353792,
        -9.019103685053029,
        43.1875835353792
      ]
    },
    "15041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.553516819787983,
              43.23230190297119
            ]
          },
          "bbox": [
            -8.553516819787983,
            43.23230190297119,
            -8.553516819787983,
            43.23230190297119
          ]
        }
      ],
      "bbox": [
        -8.553516819787983,
        43.23230190297119,
        -8.553516819787983,
        43.23230190297119
      ]
    },
    "15042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.835913728236527,
              42.76532844461618
            ]
          },
          "bbox": [
            -8.835913728236527,
            42.76532844461618,
            -8.835913728236527,
            42.76532844461618
          ]
        }
      ],
      "bbox": [
        -8.835913728236527,
        42.76532844461618,
        -8.835913728236527,
        42.76532844461618
      ]
    },
    "15043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.81409517147575,
              43.2970485142149
            ]
          },
          "bbox": [
            -8.81409517147575,
            43.2970485142149,
            -8.81409517147575,
            43.2970485142149
          ]
        }
      ],
      "bbox": [
        -8.81409517147575,
        43.2970485142149,
        -8.81409517147575,
        43.2970485142149
      ]
    },
    "15044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.734223222932967,
              43.652360436360276
            ]
          },
          "bbox": [
            -7.734223222932967,
            43.652360436360276,
            -7.734223222932967,
            43.652360436360276
          ]
        }
      ],
      "bbox": [
        -7.734223222932967,
        43.652360436360276,
        -7.734223222932967,
        43.652360436360276
      ]
    },
    "15045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.991687156742037,
              42.93001536807472
            ]
          },
          "bbox": [
            -8.991687156742037,
            42.93001536807472,
            -8.991687156742037,
            42.93001536807472
          ]
        }
      ],
      "bbox": [
        -8.991687156742037,
        42.93001536807472,
        -8.991687156742037,
        42.93001536807472
      ]
    },
    "15046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.037258690860181,
              42.93372957032134
            ]
          },
          "bbox": [
            -8.037258690860181,
            42.93372957032134,
            -8.037258690860181,
            42.93372957032134
          ]
        }
      ],
      "bbox": [
        -8.037258690860181,
        42.93372957032134,
        -8.037258690860181,
        42.93372957032134
      ]
    },
    "15047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.234302386435782,
              43.09910089989062
            ]
          },
          "bbox": [
            -8.234302386435782,
            43.09910089989062,
            -8.234302386435782,
            43.09910089989062
          ]
        }
      ],
      "bbox": [
        -8.234302386435782,
        43.09910089989062,
        -8.234302386435782,
        43.09910089989062
      ]
    },
    "15048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.17891894045535,
              43.35608112543916
            ]
          },
          "bbox": [
            -8.17891894045535,
            43.35608112543916,
            -8.17891894045535,
            43.35608112543916
          ]
        }
      ],
      "bbox": [
        -8.17891894045535,
        43.35608112543916,
        -8.17891894045535,
        43.35608112543916
      ]
    },
    "15049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.9790633436832215,
              43.565313041885126
            ]
          },
          "bbox": [
            -7.9790633436832215,
            43.565313041885126,
            -7.9790633436832215,
            43.565313041885126
          ]
        }
      ],
      "bbox": [
        -7.9790633436832215,
        43.565313041885126,
        -7.9790633436832215,
        43.565313041885126
      ]
    },
    "15050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.999283672773041,
              43.347747589003
            ]
          },
          "bbox": [
            -7.999283672773041,
            43.347747589003,
            -7.999283672773041,
            43.347747589003
          ]
        }
      ],
      "bbox": [
        -7.999283672773041,
        43.347747589003,
        -7.999283672773041,
        43.347747589003
      ]
    },
    "15051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.233700642955656,
              43.45013898071625
            ]
          },
          "bbox": [
            -8.233700642955656,
            43.45013898071625,
            -8.233700642955656,
            43.45013898071625
          ]
        }
      ],
      "bbox": [
        -8.233700642955656,
        43.45013898071625,
        -8.233700642955656,
        43.45013898071625
      ]
    },
    "15052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.192894830369033,
              43.057840977221296
            ]
          },
          "bbox": [
            -9.192894830369033,
            43.057840977221296,
            -9.192894830369033,
            43.057840977221296
          ]
        }
      ],
      "bbox": [
        -9.192894830369033,
        43.057840977221296,
        -9.192894830369033,
        43.057840977221296
      ]
    },
    "15053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.034759191019933,
              42.804139410265634
            ]
          },
          "bbox": [
            -9.034759191019933,
            42.804139410265634,
            -9.034759191019933,
            42.804139410265634
          ]
        }
      ],
      "bbox": [
        -9.034759191019933,
        42.804139410265634,
        -9.034759191019933,
        42.804139410265634
      ]
    },
    "15054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.167506036863072,
              43.53882677701416
            ]
          },
          "bbox": [
            -8.167506036863072,
            43.53882677701416,
            -8.167506036863072,
            43.53882677701416
          ]
        }
      ],
      "bbox": [
        -8.167506036863072,
        43.53882677701416,
        -8.167506036863072,
        43.53882677701416
      ]
    },
    "15055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.126166203716842,
              43.49811977974696
            ]
          },
          "bbox": [
            -8.126166203716842,
            43.49811977974696,
            -8.126166203716842,
            43.49811977974696
          ]
        }
      ],
      "bbox": [
        -8.126166203716842,
        43.49811977974696,
        -8.126166203716842,
        43.49811977974696
      ]
    },
    "15056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.818362926017944,
              42.90867952058134
            ]
          },
          "bbox": [
            -8.818362926017944,
            42.90867952058134,
            -8.818362926017944,
            42.90867952058134
          ]
        }
      ],
      "bbox": [
        -8.818362926017944,
        42.90867952058134,
        -8.818362926017944,
        42.90867952058134
      ]
    },
    "15057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.871488412008581,
              42.800772358435495
            ]
          },
          "bbox": [
            -8.871488412008581,
            42.800772358435495,
            -8.871488412008581,
            42.800772358435495
          ]
        }
      ],
      "bbox": [
        -8.871488412008581,
        42.800772358435495,
        -8.871488412008581,
        42.800772358435495
      ]
    },
    "15058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.330648109319412,
              43.34997947855858
            ]
          },
          "bbox": [
            -8.330648109319412,
            43.34997947855858,
            -8.330648109319412,
            43.34997947855858
          ]
        }
      ],
      "bbox": [
        -8.330648109319412,
        43.34997947855858,
        -8.330648109319412,
        43.34997947855858
      ]
    },
    "15059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.399237899071123,
              43.092075942136496
            ]
          },
          "bbox": [
            -8.399237899071123,
            43.092075942136496,
            -8.399237899071123,
            43.092075942136496
          ]
        }
      ],
      "bbox": [
        -8.399237899071123,
        43.092075942136496,
        -8.399237899071123,
        43.092075942136496
      ]
    },
    "15060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.387435603506228,
              42.99828314831964
            ]
          },
          "bbox": [
            -8.387435603506228,
            42.99828314831964,
            -8.387435603506228,
            42.99828314831964
          ]
        }
      ],
      "bbox": [
        -8.387435603506228,
        42.99828314831964,
        -8.387435603506228,
        42.99828314831964
      ]
    },
    "15061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.828981307315411,
              43.65155002753145
            ]
          },
          "bbox": [
            -7.828981307315411,
            43.65155002753145,
            -7.828981307315411,
            43.65155002753145
          ]
        }
      ],
      "bbox": [
        -7.828981307315411,
        43.65155002753145,
        -7.828981307315411,
        43.65155002753145
      ]
    },
    "15062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.922031456936205,
              42.85097207214381
            ]
          },
          "bbox": [
            -8.922031456936205,
            42.85097207214381,
            -8.922031456936205,
            42.85097207214381
          ]
        }
      ],
      "bbox": [
        -8.922031456936205,
        42.85097207214381,
        -8.922031456936205,
        42.85097207214381
      ]
    },
    "15064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.161422768332978,
              43.295234849239996
            ]
          },
          "bbox": [
            -8.161422768332978,
            43.295234849239996,
            -8.161422768332978,
            43.295234849239996
          ]
        }
      ],
      "bbox": [
        -8.161422768332978,
        43.295234849239996,
        -8.161422768332978,
        43.295234849239996
      ]
    },
    "15065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.631141238574159,
              42.75727191633239
            ]
          },
          "bbox": [
            -8.631141238574159,
            42.75727191633239,
            -8.631141238574159,
            42.75727191633239
          ]
        }
      ],
      "bbox": [
        -8.631141238574159,
        42.75727191633239,
        -8.631141238574159,
        42.75727191633239
      ]
    },
    "15066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.323035545112926,
              42.93825740374179
            ]
          },
          "bbox": [
            -8.323035545112926,
            42.93825740374179,
            -8.323035545112926,
            42.93825740374179
          ]
        }
      ],
      "bbox": [
        -8.323035545112926,
        42.93825740374179,
        -8.323035545112926,
        42.93825740374179
      ]
    },
    "15067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.948147464750107,
              42.6259266554939
            ]
          },
          "bbox": [
            -8.948147464750107,
            42.6259266554939,
            -8.948147464750107,
            42.6259266554939
          ]
        }
      ],
      "bbox": [
        -8.948147464750107,
        42.6259266554939,
        -8.948147464750107,
        42.6259266554939
      ]
    },
    "15068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.878312054948129,
              43.26114684597502
            ]
          },
          "bbox": [
            -8.878312054948129,
            43.26114684597502,
            -8.878312054948129,
            43.26114684597502
          ]
        }
      ],
      "bbox": [
        -8.878312054948129,
        43.26114684597502,
        -8.878312054948129,
        43.26114684597502
      ]
    },
    "15069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.154495126704678,
              43.40028974898159
            ]
          },
          "bbox": [
            -8.154495126704678,
            43.40028974898159,
            -8.154495126704678,
            43.40028974898159
          ]
        }
      ],
      "bbox": [
        -8.154495126704678,
        43.40028974898159,
        -8.154495126704678,
        43.40028974898159
      ]
    },
    "15070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.873018575105163,
              43.46372581419779
            ]
          },
          "bbox": [
            -7.873018575105163,
            43.46372581419779,
            -7.873018575105163,
            43.46372581419779
          ]
        }
      ],
      "bbox": [
        -7.873018575105163,
        43.46372581419779,
        -7.873018575105163,
        43.46372581419779
      ]
    },
    "15071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.989076204343979,
              42.685505795333285
            ]
          },
          "bbox": [
            -8.989076204343979,
            42.685505795333285,
            -8.989076204343979,
            42.685505795333285
          ]
        }
      ],
      "bbox": [
        -8.989076204343979,
        42.685505795333285,
        -8.989076204343979,
        42.685505795333285
      ]
    },
    "15072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.785773938374795,
              42.68414432794551
            ]
          },
          "bbox": [
            -8.785773938374795,
            42.68414432794551,
            -8.785773938374795,
            42.68414432794551
          ]
        }
      ],
      "bbox": [
        -8.785773938374795,
        42.68414432794551,
        -8.785773938374795,
        42.68414432794551
      ]
    },
    "15073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.011758168780602,
              42.575473968238974
            ]
          },
          "bbox": [
            -9.011758168780602,
            42.575473968238974,
            -9.011758168780602,
            42.575473968238974
          ]
        }
      ],
      "bbox": [
        -9.011758168780602,
        42.575473968238974,
        -9.011758168780602,
        42.575473968238974
      ]
    },
    "15074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.72519560452381,
              42.78856520212541
            ]
          },
          "bbox": [
            -8.72519560452381,
            42.78856520212541,
            -8.72519560452381,
            42.78856520212541
          ]
        }
      ],
      "bbox": [
        -8.72519560452381,
        42.78856520212541,
        -8.72519560452381,
        42.78856520212541
      ]
    },
    "15075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.281762235132678,
              43.35382554272225
            ]
          },
          "bbox": [
            -8.281762235132678,
            43.35382554272225,
            -8.281762235132678,
            43.35382554272225
          ]
        }
      ],
      "bbox": [
        -8.281762235132678,
        43.35382554272225,
        -8.281762235132678,
        43.35382554272225
      ]
    },
    "15076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.047486763963906,
              43.52855749289907
            ]
          },
          "bbox": [
            -8.047486763963906,
            43.52855749289907,
            -8.047486763963906,
            43.52855749289907
          ]
        }
      ],
      "bbox": [
        -8.047486763963906,
        43.52855749289907,
        -8.047486763963906,
        43.52855749289907
      ]
    },
    "15077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.80685112270087,
              43.045408854796065
            ]
          },
          "bbox": [
            -8.80685112270087,
            43.045408854796065,
            -8.80685112270087,
            43.045408854796065
          ]
        }
      ],
      "bbox": [
        -8.80685112270087,
        43.045408854796065,
        -8.80685112270087,
        43.045408854796065
      ]
    },
    "15078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.517120209186539,
              42.9105382796635
            ]
          },
          "bbox": [
            -8.517120209186539,
            42.9105382796635,
            -8.517120209186539,
            42.9105382796635
          ]
        }
      ],
      "bbox": [
        -8.517120209186539,
        42.9105382796635,
        -8.517120209186539,
        42.9105382796635
      ]
    },
    "15079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.070747253028959,
              42.86833840639161
            ]
          },
          "bbox": [
            -8.070747253028959,
            42.86833840639161,
            -8.070747253028959,
            42.86833840639161
          ]
        }
      ],
      "bbox": [
        -8.070747253028959,
        42.86833840639161,
        -8.070747253028959,
        42.86833840639161
      ]
    },
    "15080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.99750550247135,
              43.05644631515509
            ]
          },
          "bbox": [
            -7.99750550247135,
            43.05644631515509,
            -7.99750550247135,
            43.05644631515509
          ]
        }
      ],
      "bbox": [
        -7.99750550247135,
        43.05644631515509,
        -7.99750550247135,
        43.05644631515509
      ]
    },
    "15081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.920324913724266,
              43.524798981161865
            ]
          },
          "bbox": [
            -7.920324913724266,
            43.524798981161865,
            -7.920324913724266,
            43.524798981161865
          ]
        }
      ],
      "bbox": [
        -7.920324913724266,
        43.524798981161865,
        -7.920324913724266,
        43.524798981161865
      ]
    },
    "15082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.564838849513333,
              42.797182274701456
            ]
          },
          "bbox": [
            -8.564838849513333,
            42.797182274701456,
            -8.564838849513333,
            42.797182274701456
          ]
        }
      ],
      "bbox": [
        -8.564838849513333,
        42.797182274701456,
        -8.564838849513333,
        42.797182274701456
      ]
    },
    "15083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.954434057863655,
              42.96978565696631
            ]
          },
          "bbox": [
            -7.954434057863655,
            42.96978565696631,
            -7.954434057863655,
            42.96978565696631
          ]
        }
      ],
      "bbox": [
        -7.954434057863655,
        42.96978565696631,
        -7.954434057863655,
        42.96978565696631
      ]
    },
    "15084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.560690753745838,
              43.088098480941966
            ]
          },
          "bbox": [
            -8.560690753745838,
            43.088098480941966,
            -8.560690753745838,
            43.088098480941966
          ]
        }
      ],
      "bbox": [
        -8.560690753745838,
        43.088098480941966,
        -8.560690753745838,
        43.088098480941966
      ]
    },
    "15085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.312068096526739,
              42.8574491380052
            ]
          },
          "bbox": [
            -8.312068096526739,
            42.8574491380052,
            -8.312068096526739,
            42.8574491380052
          ]
        }
      ],
      "bbox": [
        -8.312068096526739,
        42.8574491380052,
        -8.312068096526739,
        42.8574491380052
      ]
    },
    "15086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.539083243859455,
              43.01123136568892
            ]
          },
          "bbox": [
            -8.539083243859455,
            43.01123136568892,
            -8.539083243859455,
            43.01123136568892
          ]
        }
      ],
      "bbox": [
        -8.539083243859455,
        43.01123136568892,
        -8.539083243859455,
        43.01123136568892
      ]
    },
    "15087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.123047118607117,
              43.60315426939973
            ]
          },
          "bbox": [
            -8.123047118607117,
            43.60315426939973,
            -8.123047118607117,
            43.60315426939973
          ]
        }
      ],
      "bbox": [
        -8.123047118607117,
        43.60315426939973,
        -8.123047118607117,
        43.60315426939973
      ]
    },
    "15088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.654137303131359,
              43.01963445841702
            ]
          },
          "bbox": [
            -8.654137303131359,
            43.01963445841702,
            -8.654137303131359,
            43.01963445841702
          ]
        }
      ],
      "bbox": [
        -8.654137303131359,
        43.01963445841702,
        -8.654137303131359,
        43.01963445841702
      ]
    },
    "15089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.464319884748935,
              42.780767747285445
            ]
          },
          "bbox": [
            -8.464319884748935,
            42.780767747285445,
            -8.464319884748935,
            42.780767747285445
          ]
        }
      ],
      "bbox": [
        -8.464319884748935,
        42.780767747285445,
        -8.464319884748935,
        42.780767747285445
      ]
    },
    "15090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.099556989332758,
              43.07206729974994
            ]
          },
          "bbox": [
            -8.099556989332758,
            43.07206729974994,
            -8.099556989332758,
            43.07206729974994
          ]
        }
      ],
      "bbox": [
        -8.099556989332758,
        43.07206729974994,
        -8.099556989332758,
        43.07206729974994
      ]
    },
    "15091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.123375151857772,
              43.35659421109902
            ]
          },
          "bbox": [
            -8.123375151857772,
            43.35659421109902,
            -8.123375151857772,
            43.35659421109902
          ]
        }
      ],
      "bbox": [
        -8.123375151857772,
        43.35659421109902,
        -8.123375151857772,
        43.35659421109902
      ]
    },
    "15092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -9.037208005974378,
              43.095736967619985
            ]
          },
          "bbox": [
            -9.037208005974378,
            43.095736967619985,
            -9.037208005974378,
            43.095736967619985
          ]
        }
      ],
      "bbox": [
        -9.037208005974378,
        43.095736967619985,
        -9.037208005974378,
        43.095736967619985
      ]
    },
    "15093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.92536076617241,
              43.09089747172419
            ]
          },
          "bbox": [
            -8.92536076617241,
            43.09089747172419,
            -8.92536076617241,
            43.09089747172419
          ]
        }
      ],
      "bbox": [
        -8.92536076617241,
        43.09089747172419,
        -8.92536076617241,
        43.09089747172419
      ]
    },
    "15901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.901357735880499,
              43.72646444640143
            ]
          },
          "bbox": [
            -7.901357735880499,
            43.72646444640143,
            -7.901357735880499,
            43.72646444640143
          ]
        }
      ],
      "bbox": [
        -7.901357735880499,
        43.72646444640143,
        -7.901357735880499,
        43.72646444640143
      ]
    },
    "15902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.189123176867493,
              43.18284677010536
            ]
          },
          "bbox": [
            -8.189123176867493,
            43.18284677010536,
            -8.189123176867493,
            43.18284677010536
          ]
        }
      ],
      "bbox": [
        -8.189123176867493,
        43.18284677010536,
        -8.189123176867493,
        43.18284677010536
      ]
    },
    "16001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3839919176613753,
              40.03754090103589
            ]
          },
          "bbox": [
            -2.3839919176613753,
            40.03754090103589,
            -2.3839919176613753,
            40.03754090103589
          ]
        }
      ],
      "bbox": [
        -2.3839919176613753,
        40.03754090103589,
        -2.3839919176613753,
        40.03754090103589
      ]
    },
    "16002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.980777974184578,
              39.90819924299287
            ]
          },
          "bbox": [
            -2.980777974184578,
            39.90819924299287,
            -2.980777974184578,
            39.90819924299287
          ]
        }
      ],
      "bbox": [
        -2.980777974184578,
        39.90819924299287,
        -2.980777974184578,
        39.90819924299287
      ]
    },
    "16003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0693183460438864,
              39.53475221940406
            ]
          },
          "bbox": [
            -2.0693183460438864,
            39.53475221940406,
            -2.0693183460438864,
            39.53475221940406
          ]
        }
      ],
      "bbox": [
        -2.0693183460438864,
        39.53475221940406,
        -2.0693183460438864,
        39.53475221940406
      ]
    },
    "16004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.234792074086486,
              39.80510109915794
            ]
          },
          "bbox": [
            -2.234792074086486,
            39.80510109915794,
            -2.234792074086486,
            39.80510109915794
          ]
        }
      ],
      "bbox": [
        -2.234792074086486,
        39.80510109915794,
        -2.234792074086486,
        39.80510109915794
      ]
    },
    "16005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2843555747100086,
              40.36429669633641
            ]
          },
          "bbox": [
            -2.2843555747100086,
            40.36429669633641,
            -2.2843555747100086,
            40.36429669633641
          ]
        }
      ],
      "bbox": [
        -2.2843555747100086,
        40.36429669633641,
        -2.2843555747100086,
        40.36429669633641
      ]
    },
    "16006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3994816536722716,
              40.47235747922413
            ]
          },
          "bbox": [
            -2.3994816536722716,
            40.47235747922413,
            -2.3994816536722716,
            40.47235747922413
          ]
        }
      ],
      "bbox": [
        -2.3994816536722716,
        40.47235747922413,
        -2.3994816536722716,
        40.47235747922413
      ]
    },
    "16007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.498769069505862,
              39.49783190636391
            ]
          },
          "bbox": [
            -2.498769069505862,
            39.49783190636391,
            -2.498769069505862,
            39.49783190636391
          ]
        }
      ],
      "bbox": [
        -2.498769069505862,
        39.49783190636391,
        -2.498769069505862,
        39.49783190636391
      ]
    },
    "16008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5130108634467705,
              40.03177148864736
            ]
          },
          "bbox": [
            -1.5130108634467705,
            40.03177148864736,
            -1.5130108634467705,
            40.03177148864736
          ]
        }
      ],
      "bbox": [
        -1.5130108634467705,
        40.03177148864736,
        -1.5130108634467705,
        40.03177148864736
      ]
    },
    "16009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3204035160952565,
              40.535499424684375
            ]
          },
          "bbox": [
            -2.3204035160952565,
            40.535499424684375,
            -2.3204035160952565,
            40.535499424684375
          ]
        }
      ],
      "bbox": [
        -2.3204035160952565,
        40.535499424684375,
        -2.3204035160952565,
        40.535499424684375
      ]
    },
    "16010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8083441539759897,
              40.054542107279495
            ]
          },
          "bbox": [
            -2.8083441539759897,
            40.054542107279495,
            -2.8083441539759897,
            40.054542107279495
          ]
        }
      ],
      "bbox": [
        -2.8083441539759897,
        40.054542107279495,
        -2.8083441539759897,
        40.054542107279495
      ]
    },
    "16011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.601503458513302,
              40.42372576603792
            ]
          },
          "bbox": [
            -2.601503458513302,
            40.42372576603792,
            -2.601503458513302,
            40.42372576603792
          ]
        }
      ],
      "bbox": [
        -2.601503458513302,
        40.42372576603792,
        -2.601503458513302,
        40.42372576603792
      ]
    },
    "16012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5804234048611128,
              39.717146940582374
            ]
          },
          "bbox": [
            -2.5804234048611128,
            39.717146940582374,
            -2.5804234048611128,
            39.717146940582374
          ]
        }
      ],
      "bbox": [
        -2.5804234048611128,
        39.717146940582374,
        -2.5804234048611128,
        39.717146940582374
      ]
    },
    "16013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4438948413826687,
              40.026571578718475
            ]
          },
          "bbox": [
            -1.4438948413826687,
            40.026571578718475,
            -1.4438948413826687,
            40.026571578718475
          ]
        }
      ],
      "bbox": [
        -1.4438948413826687,
        40.026571578718475,
        -1.4438948413826687,
        40.026571578718475
      ]
    },
    "16014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3227962018287598,
              39.72920677451355
            ]
          },
          "bbox": [
            -1.3227962018287598,
            39.72920677451355,
            -1.3227962018287598,
            39.72920677451355
          ]
        }
      ],
      "bbox": [
        -1.3227962018287598,
        39.72920677451355,
        -1.3227962018287598,
        39.72920677451355
      ]
    },
    "16015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.387217271078433,
              39.690806944923544
            ]
          },
          "bbox": [
            -2.387217271078433,
            39.690806944923544,
            -2.387217271078433,
            39.690806944923544
          ]
        }
      ],
      "bbox": [
        -2.387217271078433,
        39.690806944923544,
        -2.387217271078433,
        39.690806944923544
      ]
    },
    "16016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.874537917872118,
              39.87419697649892
            ]
          },
          "bbox": [
            -2.874537917872118,
            39.87419697649892,
            -2.874537917872118,
            39.87419697649892
          ]
        }
      ],
      "bbox": [
        -2.874537917872118,
        39.87419697649892,
        -2.874537917872118,
        39.87419697649892
      ]
    },
    "16017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.889293157344829,
              39.70350193737925
            ]
          },
          "bbox": [
            -1.889293157344829,
            39.70350193737925,
            -1.889293157344829,
            39.70350193737925
          ]
        }
      ],
      "bbox": [
        -1.889293157344829,
        39.70350193737925,
        -1.889293157344829,
        39.70350193737925
      ]
    },
    "16018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7814857676399627,
              39.82739997387
            ]
          },
          "bbox": [
            -2.7814857676399627,
            39.82739997387,
            -2.7814857676399627,
            39.82739997387
          ]
        }
      ],
      "bbox": [
        -2.7814857676399627,
        39.82739997387,
        -2.7814857676399627,
        39.82739997387
      ]
    },
    "16019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.396896381605531,
              39.92490148449239
            ]
          },
          "bbox": [
            -2.396896381605531,
            39.92490148449239,
            -2.396896381605531,
            39.92490148449239
          ]
        }
      ],
      "bbox": [
        -2.396896381605531,
        39.92490148449239,
        -2.396896381605531,
        39.92490148449239
      ]
    },
    "16020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.379891722802989,
              40.513355159682085
            ]
          },
          "bbox": [
            -2.379891722802989,
            40.513355159682085,
            -2.379891722802989,
            40.513355159682085
          ]
        }
      ],
      "bbox": [
        -2.379891722802989,
        40.513355159682085,
        -2.379891722802989,
        40.513355159682085
      ]
    },
    "16022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0897807580021337,
              40.3435625210811
            ]
          },
          "bbox": [
            -2.0897807580021337,
            40.3435625210811,
            -2.0897807580021337,
            40.3435625210811
          ]
        }
      ],
      "bbox": [
        -2.0897807580021337,
        40.3435625210811,
        -2.0897807580021337,
        40.3435625210811
      ]
    },
    "16023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.218634133173399,
              40.11934005328481
            ]
          },
          "bbox": [
            -2.218634133173399,
            40.11934005328481,
            -2.218634133173399,
            40.11934005328481
          ]
        }
      ],
      "bbox": [
        -2.218634133173399,
        40.11934005328481,
        -2.218634133173399,
        40.11934005328481
      ]
    },
    "16024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8320928105844876,
              39.83103470199931
            ]
          },
          "bbox": [
            -1.8320928105844876,
            39.83103470199931,
            -1.8320928105844876,
            39.83103470199931
          ]
        }
      ],
      "bbox": [
        -1.8320928105844876,
        39.83103470199931,
        -1.8320928105844876,
        39.83103470199931
      ]
    },
    "16025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3502690263079007,
              40.30781676392478
            ]
          },
          "bbox": [
            -2.3502690263079007,
            40.30781676392478,
            -2.3502690263079007,
            40.30781676392478
          ]
        }
      ],
      "bbox": [
        -2.3502690263079007,
        40.30781676392478,
        -2.3502690263079007,
        40.30781676392478
      ]
    },
    "16026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2455018547229435,
              39.51483411792343
            ]
          },
          "bbox": [
            -2.2455018547229435,
            39.51483411792343,
            -2.2455018547229435,
            39.51483411792343
          ]
        }
      ],
      "bbox": [
        -2.2455018547229435,
        39.51483411792343,
        -2.2455018547229435,
        39.51483411792343
      ]
    },
    "16027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.962358288495545,
              40.116867053186546
            ]
          },
          "bbox": [
            -2.962358288495545,
            40.116867053186546,
            -2.962358288495545,
            40.116867053186546
          ]
        }
      ],
      "bbox": [
        -2.962358288495545,
        40.116867053186546,
        -2.962358288495545,
        40.116867053186546
      ]
    },
    "16029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.037685842660056,
              39.68281898517256
            ]
          },
          "bbox": [
            -2.037685842660056,
            39.68281898517256,
            -2.037685842660056,
            39.68281898517256
          ]
        }
      ],
      "bbox": [
        -2.037685842660056,
        39.68281898517256,
        -2.037685842660056,
        39.68281898517256
      ]
    },
    "16030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2247042794606595,
              40.210011131849825
            ]
          },
          "bbox": [
            -2.2247042794606595,
            40.210011131849825,
            -2.2247042794606595,
            40.210011131849825
          ]
        }
      ],
      "bbox": [
        -2.2247042794606595,
        40.210011131849825,
        -2.2247042794606595,
        40.210011131849825
      ]
    },
    "16031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8329626909717776,
              40.193227181547066
            ]
          },
          "bbox": [
            -1.8329626909717776,
            40.193227181547066,
            -1.8329626909717776,
            40.193227181547066
          ]
        }
      ],
      "bbox": [
        -1.8329626909717776,
        40.193227181547066,
        -1.8329626909717776,
        40.193227181547066
      ]
    },
    "16032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0382402124219126,
              40.05147874588132
            ]
          },
          "bbox": [
            -3.0382402124219126,
            40.05147874588132,
            -3.0382402124219126,
            40.05147874588132
          ]
        }
      ],
      "bbox": [
        -3.0382402124219126,
        40.05147874588132,
        -3.0382402124219126,
        40.05147874588132
      ]
    },
    "16033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.703232058644863,
              39.56551540252105
            ]
          },
          "bbox": [
            -2.703232058644863,
            39.56551540252105,
            -2.703232058644863,
            39.56551540252105
          ]
        }
      ],
      "bbox": [
        -2.703232058644863,
        39.56551540252105,
        -2.703232058644863,
        39.56551540252105
      ]
    },
    "16034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3293069371983854,
              39.8148123143702
            ]
          },
          "bbox": [
            -2.3293069371983854,
            39.8148123143702,
            -2.3293069371983854,
            39.8148123143702
          ]
        }
      ],
      "bbox": [
        -2.3293069371983854,
        39.8148123143702,
        -2.3293069371983854,
        39.8148123143702
      ]
    },
    "16035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0530250485589003,
              40.55944161449819
            ]
          },
          "bbox": [
            -2.0530250485589003,
            40.55944161449819,
            -2.0530250485589003,
            40.55944161449819
          ]
        }
      ],
      "bbox": [
        -2.0530250485589003,
        40.55944161449819,
        -2.0530250485589003,
        40.55944161449819
      ]
    },
    "16036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6229479741789008,
              39.97414523560553
            ]
          },
          "bbox": [
            -1.6229479741789008,
            39.97414523560553,
            -1.6229479741789008,
            39.97414523560553
          ]
        }
      ],
      "bbox": [
        -1.6229479741789008,
        39.97414523560553,
        -1.6229479741789008,
        39.97414523560553
      ]
    },
    "16038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.46523734951284,
              40.33906065042676
            ]
          },
          "bbox": [
            -2.46523734951284,
            40.33906065042676,
            -2.46523734951284,
            40.33906065042676
          ]
        }
      ],
      "bbox": [
        -2.46523734951284,
        40.33906065042676,
        -2.46523734951284,
        40.33906065042676
      ]
    },
    "16039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.157168703229006,
              39.6521789811386
            ]
          },
          "bbox": [
            -2.157168703229006,
            39.6521789811386,
            -2.157168703229006,
            39.6521789811386
          ]
        }
      ],
      "bbox": [
        -2.157168703229006,
        39.6521789811386,
        -2.157168703229006,
        39.6521789811386
      ]
    },
    "16040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.984432134794543,
              40.12531559578411
            ]
          },
          "bbox": [
            -1.984432134794543,
            40.12531559578411,
            -1.984432134794543,
            40.12531559578411
          ]
        }
      ],
      "bbox": [
        -1.984432134794543,
        40.12531559578411,
        -1.984432134794543,
        40.12531559578411
      ]
    },
    "16041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.762658528203197,
              40.373110936279126
            ]
          },
          "bbox": [
            -2.762658528203197,
            40.373110936279126,
            -2.762658528203197,
            40.373110936279126
          ]
        }
      ],
      "bbox": [
        -2.762658528203197,
        40.373110936279126,
        -2.762658528203197,
        40.373110936279126
      ]
    },
    "16042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.795850839507711,
              39.61635101189965
            ]
          },
          "bbox": [
            -1.795850839507711,
            39.61635101189965,
            -1.795850839507711,
            39.61635101189965
          ]
        }
      ],
      "bbox": [
        -1.795850839507711,
        39.61635101189965,
        -1.795850839507711,
        39.61635101189965
      ]
    },
    "16043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5401715164256249,
              39.96734746417075
            ]
          },
          "bbox": [
            -1.5401715164256249,
            39.96734746417075,
            -1.5401715164256249,
            39.96734746417075
          ]
        }
      ],
      "bbox": [
        -1.5401715164256249,
        39.96734746417075,
        -1.5401715164256249,
        39.96734746417075
      ]
    },
    "16044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6866466705546808,
              40.09214249877866
            ]
          },
          "bbox": [
            -1.6866466705546808,
            40.09214249877866,
            -1.6866466705546808,
            40.09214249877866
          ]
        }
      ],
      "bbox": [
        -1.6866466705546808,
        40.09214249877866,
        -1.6866466705546808,
        40.09214249877866
      ]
    },
    "16045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.468939163730831,
              40.38672173614077
            ]
          },
          "bbox": [
            -2.468939163730831,
            40.38672173614077,
            -2.468939163730831,
            40.38672173614077
          ]
        }
      ],
      "bbox": [
        -2.468939163730831,
        40.38672173614077,
        -2.468939163730831,
        40.38672173614077
      ]
    },
    "16046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9018188159685558,
              39.977791770709274
            ]
          },
          "bbox": [
            -1.9018188159685558,
            39.977791770709274,
            -1.9018188159685558,
            39.977791770709274
          ]
        }
      ],
      "bbox": [
        -1.9018188159685558,
        39.977791770709274,
        -1.9018188159685558,
        39.977791770709274
      ]
    },
    "16047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2295215422110797,
              39.55588205203966
            ]
          },
          "bbox": [
            -2.2295215422110797,
            39.55588205203966,
            -2.2295215422110797,
            39.55588205203966
          ]
        }
      ],
      "bbox": [
        -2.2295215422110797,
        39.55588205203966,
        -2.2295215422110797,
        39.55588205203966
      ]
    },
    "16048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2406160915217956,
              40.44091259586001
            ]
          },
          "bbox": [
            -2.2406160915217956,
            40.44091259586001,
            -2.2406160915217956,
            40.44091259586001
          ]
        }
      ],
      "bbox": [
        -2.2406160915217956,
        40.44091259586001,
        -2.2406160915217956,
        40.44091259586001
      ]
    },
    "16049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3271640780291727,
              39.5417076600724
            ]
          },
          "bbox": [
            -2.3271640780291727,
            39.5417076600724,
            -2.3271640780291727,
            39.5417076600724
          ]
        }
      ],
      "bbox": [
        -2.3271640780291727,
        39.5417076600724,
        -2.3271640780291727,
        39.5417076600724
      ]
    },
    "16050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3941365087654445,
              40.35714096136314
            ]
          },
          "bbox": [
            -2.3941365087654445,
            40.35714096136314,
            -2.3941365087654445,
            40.35714096136314
          ]
        }
      ],
      "bbox": [
        -2.3941365087654445,
        40.35714096136314,
        -2.3941365087654445,
        40.35714096136314
      ]
    },
    "16051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6470983177738914,
              40.4128726536217
            ]
          },
          "bbox": [
            -2.6470983177738914,
            40.4128726536217,
            -2.6470983177738914,
            40.4128726536217
          ]
        }
      ],
      "bbox": [
        -2.6470983177738914,
        40.4128726536217,
        -2.6470983177738914,
        40.4128726536217
      ]
    },
    "16052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6660796961503173,
              40.03617960279891
            ]
          },
          "bbox": [
            -1.6660796961503173,
            40.03617960279891,
            -1.6660796961503173,
            40.03617960279891
          ]
        }
      ],
      "bbox": [
        -1.6660796961503173,
        40.03617960279891,
        -1.6660796961503173,
        40.03617960279891
      ]
    },
    "16053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1904365402107873,
              40.512340605544644
            ]
          },
          "bbox": [
            -2.1904365402107873,
            40.512340605544644,
            -2.1904365402107873,
            40.512340605544644
          ]
        }
      ],
      "bbox": [
        -2.1904365402107873,
        40.512340605544644,
        -2.1904365402107873,
        40.512340605544644
      ]
    },
    "16055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7958885046614002,
              39.8943422093719
            ]
          },
          "bbox": [
            -1.7958885046614002,
            39.8943422093719,
            -1.7958885046614002,
            39.8943422093719
          ]
        }
      ],
      "bbox": [
        -1.7958885046614002,
        39.8943422093719,
        -1.7958885046614002,
        39.8943422093719
      ]
    },
    "16056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7023970887317876,
              39.79915273062698
            ]
          },
          "bbox": [
            -1.7023970887317876,
            39.79915273062698,
            -1.7023970887317876,
            39.79915273062698
          ]
        }
      ],
      "bbox": [
        -1.7023970887317876,
        39.79915273062698,
        -1.7023970887317876,
        39.79915273062698
      ]
    },
    "16057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.193909730337452,
              40.584051456014606
            ]
          },
          "bbox": [
            -2.193909730337452,
            40.584051456014606,
            -2.193909730337452,
            40.584051456014606
          ]
        }
      ],
      "bbox": [
        -2.193909730337452,
        40.584051456014606,
        -2.193909730337452,
        40.584051456014606
      ]
    },
    "16058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.55192351749416,
              39.59133891514338
            ]
          },
          "bbox": [
            -2.55192351749416,
            39.59133891514338,
            -2.55192351749416,
            39.59133891514338
          ]
        }
      ],
      "bbox": [
        -2.55192351749416,
        39.59133891514338,
        -2.55192351749416,
        39.59133891514338
      ]
    },
    "16060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.112267545957817,
              39.30490863393027
            ]
          },
          "bbox": [
            -2.112267545957817,
            39.30490863393027,
            -2.112267545957817,
            39.30490863393027
          ]
        }
      ],
      "bbox": [
        -2.112267545957817,
        39.30490863393027,
        -2.112267545957817,
        39.30490863393027
      ]
    },
    "16061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3289101676438677,
              39.34777801388605
            ]
          },
          "bbox": [
            -2.3289101676438677,
            39.34777801388605,
            -2.3289101676438677,
            39.34777801388605
          ]
        }
      ],
      "bbox": [
        -2.3289101676438677,
        39.34777801388605,
        -2.3289101676438677,
        39.34777801388605
      ]
    },
    "16062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4074525800243258,
              40.00998430994933
            ]
          },
          "bbox": [
            -1.4074525800243258,
            40.00998430994933,
            -1.4074525800243258,
            40.00998430994933
          ]
        }
      ],
      "bbox": [
        -1.4074525800243258,
        40.00998430994933,
        -1.4074525800243258,
        40.00998430994933
      ]
    },
    "16063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.159867475121908,
              39.34446619968147
            ]
          },
          "bbox": [
            -2.159867475121908,
            39.34446619968147,
            -2.159867475121908,
            39.34446619968147
          ]
        }
      ],
      "bbox": [
        -2.159867475121908,
        39.34446619968147,
        -2.159867475121908,
        39.34446619968147
      ]
    },
    "16064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2613194809309927,
              39.29426496402137
            ]
          },
          "bbox": [
            -2.2613194809309927,
            39.29426496402137,
            -2.2613194809309927,
            39.29426496402137
          ]
        }
      ],
      "bbox": [
        -2.2613194809309927,
        39.29426496402137,
        -2.2613194809309927,
        39.29426496402137
      ]
    },
    "16065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3804663427733823,
              39.29599946024092
            ]
          },
          "bbox": [
            -2.3804663427733823,
            39.29599946024092,
            -2.3804663427733823,
            39.29599946024092
          ]
        }
      ],
      "bbox": [
        -2.3804663427733823,
        39.29599946024092,
        -2.3804663427733823,
        39.29599946024092
      ]
    },
    "16066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.041001628474454,
              39.384054849819066
            ]
          },
          "bbox": [
            -2.041001628474454,
            39.384054849819066,
            -2.041001628474454,
            39.384054849819066
          ]
        }
      ],
      "bbox": [
        -2.041001628474454,
        39.384054849819066,
        -2.041001628474454,
        39.384054849819066
      ]
    },
    "16067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5399548114142987,
              40.39700979600074
            ]
          },
          "bbox": [
            -2.5399548114142987,
            40.39700979600074,
            -2.5399548114142987,
            40.39700979600074
          ]
        }
      ],
      "bbox": [
        -2.5399548114142987,
        40.39700979600074,
        -2.5399548114142987,
        40.39700979600074
      ]
    },
    "16068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7513434861299884,
              39.53048159750109
            ]
          },
          "bbox": [
            -1.7513434861299884,
            39.53048159750109,
            -1.7513434861299884,
            39.53048159750109
          ]
        }
      ],
      "bbox": [
        -1.7513434861299884,
        39.53048159750109,
        -1.7513434861299884,
        39.53048159750109
      ]
    },
    "16070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.104857382967258,
              40.379689027675695
            ]
          },
          "bbox": [
            -2.104857382967258,
            40.379689027675695,
            -2.104857382967258,
            40.379689027675695
          ]
        }
      ],
      "bbox": [
        -2.104857382967258,
        40.379689027675695,
        -2.104857382967258,
        40.379689027675695
      ]
    },
    "16071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3880491984000876,
              40.29470196693407
            ]
          },
          "bbox": [
            -2.3880491984000876,
            40.29470196693407,
            -2.3880491984000876,
            40.29470196693407
          ]
        }
      ],
      "bbox": [
        -2.3880491984000876,
        40.29470196693407,
        -2.3880491984000876,
        40.29470196693407
      ]
    },
    "16072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3567252269744667,
              39.67152419689561
            ]
          },
          "bbox": [
            -2.3567252269744667,
            39.67152419689561,
            -2.3567252269744667,
            39.67152419689561
          ]
        }
      ],
      "bbox": [
        -2.3567252269744667,
        39.67152419689561,
        -2.3567252269744667,
        39.67152419689561
      ]
    },
    "16073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4358658571545813,
              39.78779311840345
            ]
          },
          "bbox": [
            -2.4358658571545813,
            39.78779311840345,
            -2.4358658571545813,
            39.78779311840345
          ]
        }
      ],
      "bbox": [
        -2.4358658571545813,
        39.78779311840345,
        -2.4358658571545813,
        39.78779311840345
      ]
    },
    "16074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8690574557306996,
              40.061711236455956
            ]
          },
          "bbox": [
            -1.8690574557306996,
            40.061711236455956,
            -1.8690574557306996,
            40.061711236455956
          ]
        }
      ],
      "bbox": [
        -1.8690574557306996,
        40.061711236455956,
        -1.8690574557306996,
        40.061711236455956
      ]
    },
    "16078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9834272366866461,
              40.22962567827277
            ]
          },
          "bbox": [
            -1.9834272366866461,
            40.22962567827277,
            -1.9834272366866461,
            40.22962567827277
          ]
        }
      ],
      "bbox": [
        -1.9834272366866461,
        40.22962567827277,
        -1.9834272366866461,
        40.22962567827277
      ]
    },
    "16079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0056073312326044,
              40.57518392048709
            ]
          },
          "bbox": [
            -2.0056073312326044,
            40.57518392048709,
            -2.0056073312326044,
            40.57518392048709
          ]
        }
      ],
      "bbox": [
        -2.0056073312326044,
        40.57518392048709,
        -2.0056073312326044,
        40.57518392048709
      ]
    },
    "16081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.038734284377955,
              39.76547610195634
            ]
          },
          "bbox": [
            -2.038734284377955,
            39.76547610195634,
            -2.038734284377955,
            39.76547610195634
          ]
        }
      ],
      "bbox": [
        -2.038734284377955,
        39.76547610195634,
        -2.038734284377955,
        39.76547610195634
      ]
    },
    "16082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6280851189708356,
              39.66700469129749
            ]
          },
          "bbox": [
            -1.6280851189708356,
            39.66700469129749,
            -1.6280851189708356,
            39.66700469129749
          ]
        }
      ],
      "bbox": [
        -1.6280851189708356,
        39.66700469129749,
        -1.6280851189708356,
        39.66700469129749
      ]
    },
    "16083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.302188252060467,
              39.928607807511476
            ]
          },
          "bbox": [
            -2.302188252060467,
            39.928607807511476,
            -2.302188252060467,
            39.928607807511476
          ]
        }
      ],
      "bbox": [
        -2.302188252060467,
        39.928607807511476,
        -2.302188252060467,
        39.928607807511476
      ]
    },
    "16084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1428244968094976,
              40.40578345938279
            ]
          },
          "bbox": [
            -2.1428244968094976,
            40.40578345938279,
            -2.1428244968094976,
            40.40578345938279
          ]
        }
      ],
      "bbox": [
        -2.1428244968094976,
        40.40578345938279,
        -2.1428244968094976,
        40.40578345938279
      ]
    },
    "16085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.211406531625705,
              40.395841506677115
            ]
          },
          "bbox": [
            -2.211406531625705,
            40.395841506677115,
            -2.211406531625705,
            40.395841506677115
          ]
        }
      ],
      "bbox": [
        -2.211406531625705,
        40.395841506677115,
        -2.211406531625705,
        40.395841506677115
      ]
    },
    "16086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0428974318078947,
              39.92761896906752
            ]
          },
          "bbox": [
            -3.0428974318078947,
            39.92761896906752,
            -3.0428974318078947,
            39.92761896906752
          ]
        }
      ],
      "bbox": [
        -3.0428974318078947,
        39.92761896906752,
        -3.0428974318078947,
        39.92761896906752
      ]
    },
    "16087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6414988269744017,
              39.68221337137243
            ]
          },
          "bbox": [
            -2.6414988269744017,
            39.68221337137243,
            -2.6414988269744017,
            39.68221337137243
          ]
        }
      ],
      "bbox": [
        -2.6414988269744017,
        39.68221337137243,
        -2.6414988269744017,
        39.68221337137243
      ]
    },
    "16088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4668613212949904,
              39.92904366803038
            ]
          },
          "bbox": [
            -1.4668613212949904,
            39.92904366803038,
            -1.4668613212949904,
            39.92904366803038
          ]
        }
      ],
      "bbox": [
        -1.4668613212949904,
        39.92904366803038,
        -1.4668613212949904,
        39.92904366803038
      ]
    },
    "16089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.000598248203075,
              39.930754675919125
            ]
          },
          "bbox": [
            -2.000598248203075,
            39.930754675919125,
            -2.000598248203075,
            39.930754675919125
          ]
        }
      ],
      "bbox": [
        -2.000598248203075,
        39.930754675919125,
        -2.000598248203075,
        39.930754675919125
      ]
    },
    "16091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1547390132596482,
              40.46097461449798
            ]
          },
          "bbox": [
            -2.1547390132596482,
            40.46097461449798,
            -2.1547390132596482,
            40.46097461449798
          ]
        }
      ],
      "bbox": [
        -2.1547390132596482,
        40.46097461449798,
        -2.1547390132596482,
        40.46097461449798
      ]
    },
    "16092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9547029750450509,
              39.642750607201585
            ]
          },
          "bbox": [
            -1.9547029750450509,
            39.642750607201585,
            -1.9547029750450509,
            39.642750607201585
          ]
        }
      ],
      "bbox": [
        -1.9547029750450509,
        39.642750607201585,
        -1.9547029750450509,
        39.642750607201585
      ]
    },
    "16093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.374185849655194,
              39.80625181744608
            ]
          },
          "bbox": [
            -1.374185849655194,
            39.80625181744608,
            -1.374185849655194,
            39.80625181744608
          ]
        }
      ],
      "bbox": [
        -1.374185849655194,
        39.80625181744608,
        -1.374185849655194,
        39.80625181744608
      ]
    },
    "16094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.534517531819008,
              40.297441367297544
            ]
          },
          "bbox": [
            -2.534517531819008,
            40.297441367297544,
            -2.534517531819008,
            40.297441367297544
          ]
        }
      ],
      "bbox": [
        -2.534517531819008,
        40.297441367297544,
        -2.534517531819008,
        40.297441367297544
      ]
    },
    "16095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2734315784049426,
              39.89602044047814
            ]
          },
          "bbox": [
            -1.2734315784049426,
            39.89602044047814,
            -1.2734315784049426,
            39.89602044047814
          ]
        }
      ],
      "bbox": [
        -1.2734315784049426,
        39.89602044047814,
        -1.2734315784049426,
        39.89602044047814
      ]
    },
    "16096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6776656630566007,
              39.516493585278916
            ]
          },
          "bbox": [
            -1.6776656630566007,
            39.516493585278916,
            -1.6776656630566007,
            39.516493585278916
          ]
        }
      ],
      "bbox": [
        -1.6776656630566007,
        39.516493585278916,
        -1.6776656630566007,
        39.516493585278916
      ]
    },
    "16097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4808454776281523,
              39.832061176313246
            ]
          },
          "bbox": [
            -1.4808454776281523,
            39.832061176313246,
            -1.4808454776281523,
            39.832061176313246
          ]
        }
      ],
      "bbox": [
        -1.4808454776281523,
        39.832061176313246,
        -1.4808454776281523,
        39.832061176313246
      ]
    },
    "16098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6131437344406319,
              39.40090403675539
            ]
          },
          "bbox": [
            -1.6131437344406319,
            39.40090403675539,
            -1.6131437344406319,
            39.40090403675539
          ]
        }
      ],
      "bbox": [
        -1.6131437344406319,
        39.40090403675539,
        -1.6131437344406319,
        39.40090403675539
      ]
    },
    "16099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.425105132931979,
              39.726547201063525
            ]
          },
          "bbox": [
            -2.425105132931979,
            39.726547201063525,
            -2.425105132931979,
            39.726547201063525
          ]
        }
      ],
      "bbox": [
        -2.425105132931979,
        39.726547201063525,
        -2.425105132931979,
        39.726547201063525
      ]
    },
    "16100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.853861422159479,
              39.597759739035794
            ]
          },
          "bbox": [
            -2.853861422159479,
            39.597759739035794,
            -2.853861422159479,
            39.597759739035794
          ]
        }
      ],
      "bbox": [
        -2.853861422159479,
        39.597759739035794,
        -2.853861422159479,
        39.597759739035794
      ]
    },
    "16101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.723397364321402,
              39.84782765629833
            ]
          },
          "bbox": [
            -2.723397364321402,
            39.84782765629833,
            -2.723397364321402,
            39.84782765629833
          ]
        }
      ],
      "bbox": [
        -2.723397364321402,
        39.84782765629833,
        -2.723397364321402,
        39.84782765629833
      ]
    },
    "16102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2685074796715723,
              39.60606438546422
            ]
          },
          "bbox": [
            -2.2685074796715723,
            39.60606438546422,
            -2.2685074796715723,
            39.60606438546422
          ]
        }
      ],
      "bbox": [
        -2.2685074796715723,
        39.60606438546422,
        -2.2685074796715723,
        39.60606438546422
      ]
    },
    "16103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8242329920490596,
              39.70853860425482
            ]
          },
          "bbox": [
            -2.8242329920490596,
            39.70853860425482,
            -2.8242329920490596,
            39.70853860425482
          ]
        }
      ],
      "bbox": [
        -2.8242329920490596,
        39.70853860425482,
        -2.8242329920490596,
        39.70853860425482
      ]
    },
    "16104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.186835149472922,
              39.6915617467593
            ]
          },
          "bbox": [
            -2.186835149472922,
            39.6915617467593,
            -2.186835149472922,
            39.6915617467593
          ]
        }
      ],
      "bbox": [
        -2.186835149472922,
        39.6915617467593,
        -2.186835149472922,
        39.6915617467593
      ]
    },
    "16106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.033110322738249,
              39.847606400191616
            ]
          },
          "bbox": [
            -3.033110322738249,
            39.847606400191616,
            -3.033110322738249,
            39.847606400191616
          ]
        }
      ],
      "bbox": [
        -3.033110322738249,
        39.847606400191616,
        -3.033110322738249,
        39.847606400191616
      ]
    },
    "16107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.81491279735789,
              40.276910422913794
            ]
          },
          "bbox": [
            -1.81491279735789,
            40.276910422913794,
            -1.81491279735789,
            40.276910422913794
          ]
        }
      ],
      "bbox": [
        -1.81491279735789,
        40.276910422913794,
        -1.81491279735789,
        40.276910422913794
      ]
    },
    "16108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.904718893545768,
              40.045763205453795
            ]
          },
          "bbox": [
            -2.904718893545768,
            40.045763205453795,
            -2.904718893545768,
            40.045763205453795
          ]
        }
      ],
      "bbox": [
        -2.904718893545768,
        40.045763205453795,
        -2.904718893545768,
        40.045763205453795
      ]
    },
    "16109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5976603801816742,
              40.038292963287915
            ]
          },
          "bbox": [
            -1.5976603801816742,
            40.038292963287915,
            -1.5976603801816742,
            40.038292963287915
          ]
        }
      ],
      "bbox": [
        -1.5976603801816742,
        40.038292963287915,
        -1.5976603801816742,
        40.038292963287915
      ]
    },
    "16110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.480220203025239,
              39.977155044569386
            ]
          },
          "bbox": [
            -2.480220203025239,
            39.977155044569386,
            -2.480220203025239,
            39.977155044569386
          ]
        }
      ],
      "bbox": [
        -2.480220203025239,
        39.977155044569386,
        -2.480220203025239,
        39.977155044569386
      ]
    },
    "16111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7065500088210765,
              40.1505525624409
            ]
          },
          "bbox": [
            -1.7065500088210765,
            40.1505525624409,
            -1.7065500088210765,
            40.1505525624409
          ]
        }
      ],
      "bbox": [
        -1.7065500088210765,
        40.1505525624409,
        -1.7065500088210765,
        40.1505525624409
      ]
    },
    "16112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.636360899275865,
              40.15858071441843
            ]
          },
          "bbox": [
            -2.636360899275865,
            40.15858071441843,
            -2.636360899275865,
            40.15858071441843
          ]
        }
      ],
      "bbox": [
        -2.636360899275865,
        40.15858071441843,
        -2.636360899275865,
        40.15858071441843
      ]
    },
    "16113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7360893779820965,
              39.45674724553886
            ]
          },
          "bbox": [
            -1.7360893779820965,
            39.45674724553886,
            -1.7360893779820965,
            39.45674724553886
          ]
        }
      ],
      "bbox": [
        -1.7360893779820965,
        39.45674724553886,
        -1.7360893779820965,
        39.45674724553886
      ]
    },
    "16115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6732056924677874,
              40.19181915974246
            ]
          },
          "bbox": [
            -1.6732056924677874,
            40.19181915974246,
            -1.6732056924677874,
            40.19181915974246
          ]
        }
      ],
      "bbox": [
        -1.6732056924677874,
        40.19181915974246,
        -1.6732056924677874,
        40.19181915974246
      ]
    },
    "16116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9957933057278456,
              40.51354471948371
            ]
          },
          "bbox": [
            -1.9957933057278456,
            40.51354471948371,
            -1.9957933057278456,
            40.51354471948371
          ]
        }
      ],
      "bbox": [
        -1.9957933057278456,
        40.51354471948371,
        -1.9957933057278456,
        40.51354471948371
      ]
    },
    "16117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3555091568484254,
              39.89678230570584
            ]
          },
          "bbox": [
            -1.3555091568484254,
            39.89678230570584,
            -1.3555091568484254,
            39.89678230570584
          ]
        }
      ],
      "bbox": [
        -1.3555091568484254,
        39.89678230570584,
        -1.3555091568484254,
        39.89678230570584
      ]
    },
    "16118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7115566375507738,
              39.36928494081152
            ]
          },
          "bbox": [
            -1.7115566375507738,
            39.36928494081152,
            -1.7115566375507738,
            39.36928494081152
          ]
        }
      ],
      "bbox": [
        -1.7115566375507738,
        39.36928494081152,
        -1.7115566375507738,
        39.36928494081152
      ]
    },
    "16119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9769328302851332,
              40.16347597339426
            ]
          },
          "bbox": [
            -2.9769328302851332,
            40.16347597339426,
            -2.9769328302851332,
            40.16347597339426
          ]
        }
      ],
      "bbox": [
        -2.9769328302851332,
        40.16347597339426,
        -2.9769328302851332,
        40.16347597339426
      ]
    },
    "16121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.002828985272398,
              40.30329593013207
            ]
          },
          "bbox": [
            -2.002828985272398,
            40.30329593013207,
            -2.002828985272398,
            40.30329593013207
          ]
        }
      ],
      "bbox": [
        -2.002828985272398,
        40.30329593013207,
        -2.002828985272398,
        40.30329593013207
      ]
    },
    "16122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1252280280335403,
              40.17302248432256
            ]
          },
          "bbox": [
            -2.1252280280335403,
            40.17302248432256,
            -2.1252280280335403,
            40.17302248432256
          ]
        }
      ],
      "bbox": [
        -2.1252280280335403,
        40.17302248432256,
        -2.1252280280335403,
        40.17302248432256
      ]
    },
    "16123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9833493669350533,
              40.54154814690307
            ]
          },
          "bbox": [
            -1.9833493669350533,
            40.54154814690307,
            -1.9833493669350533,
            40.54154814690307
          ]
        }
      ],
      "bbox": [
        -1.9833493669350533,
        40.54154814690307,
        -1.9833493669350533,
        40.54154814690307
      ]
    },
    "16124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7329240427566486,
              39.37504805382412
            ]
          },
          "bbox": [
            -2.7329240427566486,
            39.37504805382412,
            -2.7329240427566486,
            39.37504805382412
          ]
        }
      ],
      "bbox": [
        -2.7329240427566486,
        39.37504805382412,
        -2.7329240427566486,
        39.37504805382412
      ]
    },
    "16125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5697630130987648,
              39.50776065239015
            ]
          },
          "bbox": [
            -1.5697630130987648,
            39.50776065239015,
            -1.5697630130987648,
            39.50776065239015
          ]
        }
      ],
      "bbox": [
        -1.5697630130987648,
        39.50776065239015,
        -1.5697630130987648,
        39.50776065239015
      ]
    },
    "16126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4700516894909845,
              39.67112739452011
            ]
          },
          "bbox": [
            -1.4700516894909845,
            39.67112739452011,
            -1.4700516894909845,
            39.67112739452011
          ]
        }
      ],
      "bbox": [
        -1.4700516894909845,
        39.67112739452011,
        -1.4700516894909845,
        39.67112739452011
      ]
    },
    "16128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7729183023602726,
              39.56090994919057
            ]
          },
          "bbox": [
            -2.7729183023602726,
            39.56090994919057,
            -2.7729183023602726,
            39.56090994919057
          ]
        }
      ],
      "bbox": [
        -2.7729183023602726,
        39.56090994919057,
        -2.7729183023602726,
        39.56090994919057
      ]
    },
    "16129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5079734224242958,
              39.74079103847805
            ]
          },
          "bbox": [
            -2.5079734224242958,
            39.74079103847805,
            -2.5079734224242958,
            39.74079103847805
          ]
        }
      ],
      "bbox": [
        -2.5079734224242958,
        39.74079103847805,
        -2.5079734224242958,
        39.74079103847805
      ]
    },
    "16130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6563013468958787,
              39.86468397043746
            ]
          },
          "bbox": [
            -2.6563013468958787,
            39.86468397043746,
            -2.6563013468958787,
            39.86468397043746
          ]
        }
      ],
      "bbox": [
        -2.6563013468958787,
        39.86468397043746,
        -2.6563013468958787,
        39.86468397043746
      ]
    },
    "16131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9202961518572248,
              39.8095329240417
            ]
          },
          "bbox": [
            -1.9202961518572248,
            39.8095329240417,
            -1.9202961518572248,
            39.8095329240417
          ]
        }
      ],
      "bbox": [
        -1.9202961518572248,
        39.8095329240417,
        -1.9202961518572248,
        39.8095329240417
      ]
    },
    "16132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3052481941561727,
              39.87223465470042
            ]
          },
          "bbox": [
            -2.3052481941561727,
            39.87223465470042,
            -2.3052481941561727,
            39.87223465470042
          ]
        }
      ],
      "bbox": [
        -2.3052481941561727,
        39.87223465470042,
        -2.3052481941561727,
        39.87223465470042
      ]
    },
    "16133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8692831805713754,
              39.45899503533908
            ]
          },
          "bbox": [
            -2.8692831805713754,
            39.45899503533908,
            -2.8692831805713754,
            39.45899503533908
          ]
        }
      ],
      "bbox": [
        -2.8692831805713754,
        39.45899503533908,
        -2.8692831805713754,
        39.45899503533908
      ]
    },
    "16134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.909725096954487,
              39.56309879541644
            ]
          },
          "bbox": [
            -1.909725096954487,
            39.56309879541644,
            -1.909725096954487,
            39.56309879541644
          ]
        }
      ],
      "bbox": [
        -1.909725096954487,
        39.56309879541644,
        -1.909725096954487,
        39.56309879541644
      ]
    },
    "16135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.353725787853022,
              39.967146938451535
            ]
          },
          "bbox": [
            -1.353725787853022,
            39.967146938451535,
            -1.353725787853022,
            39.967146938451535
          ]
        }
      ],
      "bbox": [
        -1.353725787853022,
        39.967146938451535,
        -1.353725787853022,
        39.967146938451535
      ]
    },
    "16137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4941651451375169,
              39.756821565429576
            ]
          },
          "bbox": [
            -1.4941651451375169,
            39.756821565429576,
            -1.4941651451375169,
            39.756821565429576
          ]
        }
      ],
      "bbox": [
        -1.4941651451375169,
        39.756821565429576,
        -1.4941651451375169,
        39.756821565429576
      ]
    },
    "16139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.330530263030549,
              39.75269746617385
            ]
          },
          "bbox": [
            -2.330530263030549,
            39.75269746617385,
            -2.330530263030549,
            39.75269746617385
          ]
        }
      ],
      "bbox": [
        -2.330530263030549,
        39.75269746617385,
        -2.330530263030549,
        39.75269746617385
      ]
    },
    "16140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4702009989424356,
              40.30787875852766
            ]
          },
          "bbox": [
            -2.4702009989424356,
            40.30787875852766,
            -2.4702009989424356,
            40.30787875852766
          ]
        }
      ],
      "bbox": [
        -2.4702009989424356,
        40.30787875852766,
        -2.4702009989424356,
        40.30787875852766
      ]
    },
    "16141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.065162429724923,
              39.82551360335379
            ]
          },
          "bbox": [
            -2.065162429724923,
            39.82551360335379,
            -2.065162429724923,
            39.82551360335379
          ]
        }
      ],
      "bbox": [
        -2.065162429724923,
        39.82551360335379,
        -2.065162429724923,
        39.82551360335379
      ]
    },
    "16142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.109621653779819,
              39.61877242070663
            ]
          },
          "bbox": [
            -2.109621653779819,
            39.61877242070663,
            -2.109621653779819,
            39.61877242070663
          ]
        }
      ],
      "bbox": [
        -2.109621653779819,
        39.61877242070663,
        -2.109621653779819,
        39.61877242070663
      ]
    },
    "16143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.421597964863741,
              40.29822803442714
            ]
          },
          "bbox": [
            -2.421597964863741,
            40.29822803442714,
            -2.421597964863741,
            40.29822803442714
          ]
        }
      ],
      "bbox": [
        -2.421597964863741,
        40.29822803442714,
        -2.421597964863741,
        40.29822803442714
      ]
    },
    "16145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7604995974507407,
              39.642681501914694
            ]
          },
          "bbox": [
            -2.7604995974507407,
            39.642681501914694,
            -2.7604995974507407,
            39.642681501914694
          ]
        }
      ],
      "bbox": [
        -2.7604995974507407,
        39.642681501914694,
        -2.7604995974507407,
        39.642681501914694
      ]
    },
    "16146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7932288087383934,
              39.969310563810886
            ]
          },
          "bbox": [
            -1.7932288087383934,
            39.969310563810886,
            -1.7932288087383934,
            39.969310563810886
          ]
        }
      ],
      "bbox": [
        -1.7932288087383934,
        39.969310563810886,
        -1.7932288087383934,
        39.969310563810886
      ]
    },
    "16147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7166565059787289,
              39.945325656822
            ]
          },
          "bbox": [
            -1.7166565059787289,
            39.945325656822,
            -1.7166565059787289,
            39.945325656822
          ]
        }
      ],
      "bbox": [
        -1.7166565059787289,
        39.945325656822,
        -1.7166565059787289,
        39.945325656822
      ]
    },
    "16148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6250863417597263,
              39.939140470386654
            ]
          },
          "bbox": [
            -2.6250863417597263,
            39.939140470386654,
            -2.6250863417597263,
            39.939140470386654
          ]
        }
      ],
      "bbox": [
        -2.6250863417597263,
        39.939140470386654,
        -2.6250863417597263,
        39.939140470386654
      ]
    },
    "16149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.023690202609041,
              40.07187459146857
            ]
          },
          "bbox": [
            -2.023690202609041,
            40.07187459146857,
            -2.023690202609041,
            40.07187459146857
          ]
        }
      ],
      "bbox": [
        -2.023690202609041,
        40.07187459146857,
        -2.023690202609041,
        40.07187459146857
      ]
    },
    "16150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7666681493238972,
              39.715604106377306
            ]
          },
          "bbox": [
            -1.7666681493238972,
            39.715604106377306,
            -1.7666681493238972,
            39.715604106377306
          ]
        }
      ],
      "bbox": [
        -1.7666681493238972,
        39.715604106377306,
        -1.7666681493238972,
        39.715604106377306
      ]
    },
    "16151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8541653382870686,
              40.07564557735326
            ]
          },
          "bbox": [
            -2.8541653382870686,
            40.07564557735326,
            -2.8541653382870686,
            40.07564557735326
          ]
        }
      ],
      "bbox": [
        -2.8541653382870686,
        40.07564557735326,
        -2.8541653382870686,
        40.07564557735326
      ]
    },
    "16152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2188070162856715,
              39.86770275700556
            ]
          },
          "bbox": [
            -2.2188070162856715,
            39.86770275700556,
            -2.2188070162856715,
            39.86770275700556
          ]
        }
      ],
      "bbox": [
        -2.2188070162856715,
        39.86770275700556,
        -2.2188070162856715,
        39.86770275700556
      ]
    },
    "16153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.751803938997138,
              39.47952724871119
            ]
          },
          "bbox": [
            -2.751803938997138,
            39.47952724871119,
            -2.751803938997138,
            39.47952724871119
          ]
        }
      ],
      "bbox": [
        -2.751803938997138,
        39.47952724871119,
        -2.751803938997138,
        39.47952724871119
      ]
    },
    "16154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.638252472983219,
              39.4585316666839
            ]
          },
          "bbox": [
            -2.638252472983219,
            39.4585316666839,
            -2.638252472983219,
            39.4585316666839
          ]
        }
      ],
      "bbox": [
        -2.638252472983219,
        39.4585316666839,
        -2.638252472983219,
        39.4585316666839
      ]
    },
    "16155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8846960548029998,
              39.4916544497954
            ]
          },
          "bbox": [
            -1.8846960548029998,
            39.4916544497954,
            -1.8846960548029998,
            39.4916544497954
          ]
        }
      ],
      "bbox": [
        -1.8846960548029998,
        39.4916544497954,
        -1.8846960548029998,
        39.4916544497954
      ]
    },
    "16156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.550303010957275,
              40.24105267672851
            ]
          },
          "bbox": [
            -2.550303010957275,
            40.24105267672851,
            -2.550303010957275,
            40.24105267672851
          ]
        }
      ],
      "bbox": [
        -2.550303010957275,
        40.24105267672851,
        -2.550303010957275,
        40.24105267672851
      ]
    },
    "16157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.569793109942539,
              39.58294324257872
            ]
          },
          "bbox": [
            -1.569793109942539,
            39.58294324257872,
            -1.569793109942539,
            39.58294324257872
          ]
        }
      ],
      "bbox": [
        -1.569793109942539,
        39.58294324257872,
        -1.569793109942539,
        39.58294324257872
      ]
    },
    "16158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.103016261690936,
              39.44587275479125
            ]
          },
          "bbox": [
            -2.103016261690936,
            39.44587275479125,
            -2.103016261690936,
            39.44587275479125
          ]
        }
      ],
      "bbox": [
        -2.103016261690936,
        39.44587275479125,
        -2.103016261690936,
        39.44587275479125
      ]
    },
    "16159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4171560992073315,
              39.61469534083804
            ]
          },
          "bbox": [
            -2.4171560992073315,
            39.61469534083804,
            -2.4171560992073315,
            39.61469534083804
          ]
        }
      ],
      "bbox": [
        -2.4171560992073315,
        39.61469534083804,
        -2.4171560992073315,
        39.61469534083804
      ]
    },
    "16160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.536819805200914,
              40.088196093413366
            ]
          },
          "bbox": [
            -2.536819805200914,
            40.088196093413366,
            -2.536819805200914,
            40.088196093413366
          ]
        }
      ],
      "bbox": [
        -2.536819805200914,
        40.088196093413366,
        -2.536819805200914,
        40.088196093413366
      ]
    },
    "16161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0852239763378093,
              39.72105450757295
            ]
          },
          "bbox": [
            -2.0852239763378093,
            39.72105450757295,
            -2.0852239763378093,
            39.72105450757295
          ]
        }
      ],
      "bbox": [
        -2.0852239763378093,
        39.72105450757295,
        -2.0852239763378093,
        39.72105450757295
      ]
    },
    "16162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6085605589655736,
              40.28145201447583
            ]
          },
          "bbox": [
            -2.6085605589655736,
            40.28145201447583,
            -2.6085605589655736,
            40.28145201447583
          ]
        }
      ],
      "bbox": [
        -2.6085605589655736,
        40.28145201447583,
        -2.6085605589655736,
        40.28145201447583
      ]
    },
    "16163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0743856007907358,
              40.29921592303388
            ]
          },
          "bbox": [
            -2.0743856007907358,
            40.29921592303388,
            -2.0743856007907358,
            40.29921592303388
          ]
        }
      ],
      "bbox": [
        -2.0743856007907358,
        40.29921592303388,
        -2.0743856007907358,
        40.29921592303388
      ]
    },
    "16165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.04787298550199,
              40.426614217853114
            ]
          },
          "bbox": [
            -2.04787298550199,
            40.426614217853114,
            -2.04787298550199,
            40.426614217853114
          ]
        }
      ],
      "bbox": [
        -2.04787298550199,
        40.426614217853114,
        -2.04787298550199,
        40.426614217853114
      ]
    },
    "16166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1939126213523443,
              39.33173695749069
            ]
          },
          "bbox": [
            -2.1939126213523443,
            39.33173695749069,
            -2.1939126213523443,
            39.33173695749069
          ]
        }
      ],
      "bbox": [
        -2.1939126213523443,
        39.33173695749069,
        -2.1939126213523443,
        39.33173695749069
      ]
    },
    "16167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9407759695543745,
              39.80915196153042
            ]
          },
          "bbox": [
            -2.9407759695543745,
            39.80915196153042,
            -2.9407759695543745,
            39.80915196153042
          ]
        }
      ],
      "bbox": [
        -2.9407759695543745,
        39.80915196153042,
        -2.9407759695543745,
        39.80915196153042
      ]
    },
    "16169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2646873735547564,
              40.5963172013614
            ]
          },
          "bbox": [
            -2.2646873735547564,
            40.5963172013614,
            -2.2646873735547564,
            40.5963172013614
          ]
        }
      ],
      "bbox": [
        -2.2646873735547564,
        40.5963172013614,
        -2.2646873735547564,
        40.5963172013614
      ]
    },
    "16170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.317350531410909,
              40.45660542878019
            ]
          },
          "bbox": [
            -2.317350531410909,
            40.45660542878019,
            -2.317350531410909,
            40.45660542878019
          ]
        }
      ],
      "bbox": [
        -2.317350531410909,
        40.45660542878019,
        -2.317350531410909,
        40.45660542878019
      ]
    },
    "16171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5710429888756856,
              39.380200329067286
            ]
          },
          "bbox": [
            -2.5710429888756856,
            39.380200329067286,
            -2.5710429888756856,
            39.380200329067286
          ]
        }
      ],
      "bbox": [
        -2.5710429888756856,
        39.380200329067286,
        -2.5710429888756856,
        39.380200329067286
      ]
    },
    "16172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8063722233507176,
              39.787369807329874
            ]
          },
          "bbox": [
            -2.8063722233507176,
            39.787369807329874,
            -2.8063722233507176,
            39.787369807329874
          ]
        }
      ],
      "bbox": [
        -2.8063722233507176,
        39.787369807329874,
        -2.8063722233507176,
        39.787369807329874
      ]
    },
    "16173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.767710879203835,
              40.24052672381325
            ]
          },
          "bbox": [
            -2.767710879203835,
            40.24052672381325,
            -2.767710879203835,
            40.24052672381325
          ]
        }
      ],
      "bbox": [
        -2.767710879203835,
        40.24052672381325,
        -2.767710879203835,
        40.24052672381325
      ]
    },
    "16174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6780862567481878,
              39.566466528286696
            ]
          },
          "bbox": [
            -1.6780862567481878,
            39.566466528286696,
            -1.6780862567481878,
            39.566466528286696
          ]
        }
      ],
      "bbox": [
        -1.6780862567481878,
        39.566466528286696,
        -1.6780862567481878,
        39.566466528286696
      ]
    },
    "16175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9405496588438207,
              39.3376922658892
            ]
          },
          "bbox": [
            -1.9405496588438207,
            39.3376922658892,
            -1.9405496588438207,
            39.3376922658892
          ]
        }
      ],
      "bbox": [
        -1.9405496588438207,
        39.3376922658892,
        -1.9405496588438207,
        39.3376922658892
      ]
    },
    "16176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6105059357761307,
              39.57602633480672
            ]
          },
          "bbox": [
            -2.6105059357761307,
            39.57602633480672,
            -2.6105059357761307,
            39.57602633480672
          ]
        }
      ],
      "bbox": [
        -2.6105059357761307,
        39.57602633480672,
        -2.6105059357761307,
        39.57602633480672
      ]
    },
    "16177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8984708437901752,
              39.88640752890857
            ]
          },
          "bbox": [
            -1.8984708437901752,
            39.88640752890857,
            -1.8984708437901752,
            39.88640752890857
          ]
        }
      ],
      "bbox": [
        -1.8984708437901752,
        39.88640752890857,
        -1.8984708437901752,
        39.88640752890857
      ]
    },
    "16181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7988665139721194,
              39.98752050180024
            ]
          },
          "bbox": [
            -2.7988665139721194,
            39.98752050180024,
            -2.7988665139721194,
            39.98752050180024
          ]
        }
      ],
      "bbox": [
        -2.7988665139721194,
        39.98752050180024,
        -2.7988665139721194,
        39.98752050180024
      ]
    },
    "16185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.850878116671976,
              40.167092792489555
            ]
          },
          "bbox": [
            -2.850878116671976,
            40.167092792489555,
            -2.850878116671976,
            40.167092792489555
          ]
        }
      ],
      "bbox": [
        -2.850878116671976,
        40.167092792489555,
        -2.850878116671976,
        40.167092792489555
      ]
    },
    "16186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8036025440822705,
              39.899372154763775
            ]
          },
          "bbox": [
            -2.8036025440822705,
            39.899372154763775,
            -2.8036025440822705,
            39.899372154763775
          ]
        }
      ],
      "bbox": [
        -2.8036025440822705,
        39.899372154763775,
        -2.8036025440822705,
        39.899372154763775
      ]
    },
    "16187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5547644778024179,
              40.08369215484143
            ]
          },
          "bbox": [
            -1.5547644778024179,
            40.08369215484143,
            -1.5547644778024179,
            40.08369215484143
          ]
        }
      ],
      "bbox": [
        -1.5547644778024179,
        40.08369215484143,
        -1.5547644778024179,
        40.08369215484143
      ]
    },
    "16188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5225453876649437,
              40.50071585581647
            ]
          },
          "bbox": [
            -2.5225453876649437,
            40.50071585581647,
            -2.5225453876649437,
            40.50071585581647
          ]
        }
      ],
      "bbox": [
        -2.5225453876649437,
        40.50071585581647,
        -2.5225453876649437,
        40.50071585581647
      ]
    },
    "16189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.488271324691447,
              40.13340278654135
            ]
          },
          "bbox": [
            -1.488271324691447,
            40.13340278654135,
            -1.488271324691447,
            40.13340278654135
          ]
        }
      ],
      "bbox": [
        -1.488271324691447,
        40.13340278654135,
        -1.488271324691447,
        40.13340278654135
      ]
    },
    "16190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.433039563476918,
              39.408276200192276
            ]
          },
          "bbox": [
            -2.433039563476918,
            39.408276200192276,
            -2.433039563476918,
            39.408276200192276
          ]
        }
      ],
      "bbox": [
        -2.433039563476918,
        39.408276200192276,
        -2.433039563476918,
        39.408276200192276
      ]
    },
    "16191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3855705163884076,
              39.84626264488926
            ]
          },
          "bbox": [
            -2.3855705163884076,
            39.84626264488926,
            -2.3855705163884076,
            39.84626264488926
          ]
        }
      ],
      "bbox": [
        -2.3855705163884076,
        39.84626264488926,
        -2.3855705163884076,
        39.84626264488926
      ]
    },
    "16192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5611738368834587,
              39.874560081544125
            ]
          },
          "bbox": [
            -1.5611738368834587,
            39.874560081544125,
            -1.5611738368834587,
            39.874560081544125
          ]
        }
      ],
      "bbox": [
        -1.5611738368834587,
        39.874560081544125,
        -1.5611738368834587,
        39.874560081544125
      ]
    },
    "16193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.404752529500579,
              40.43072636125563
            ]
          },
          "bbox": [
            -2.404752529500579,
            40.43072636125563,
            -2.404752529500579,
            40.43072636125563
          ]
        }
      ],
      "bbox": [
        -2.404752529500579,
        40.43072636125563,
        -2.404752529500579,
        40.43072636125563
      ]
    },
    "16194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2252186508943668,
              39.94921170261095
            ]
          },
          "bbox": [
            -1.2252186508943668,
            39.94921170261095,
            -1.2252186508943668,
            39.94921170261095
          ]
        }
      ],
      "bbox": [
        -1.2252186508943668,
        39.94921170261095,
        -1.2252186508943668,
        39.94921170261095
      ]
    },
    "16195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4237161095680073,
              39.554276839593065
            ]
          },
          "bbox": [
            -2.4237161095680073,
            39.554276839593065,
            -2.4237161095680073,
            39.554276839593065
          ]
        }
      ],
      "bbox": [
        -2.4237161095680073,
        39.554276839593065,
        -2.4237161095680073,
        39.554276839593065
      ]
    },
    "16196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.809958108448695,
              39.48255418289546
            ]
          },
          "bbox": [
            -2.809958108448695,
            39.48255418289546,
            -2.809958108448695,
            39.48255418289546
          ]
        }
      ],
      "bbox": [
        -2.809958108448695,
        39.48255418289546,
        -2.809958108448695,
        39.48255418289546
      ]
    },
    "16197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0499900392786525,
              40.48876502630224
            ]
          },
          "bbox": [
            -2.0499900392786525,
            40.48876502630224,
            -2.0499900392786525,
            40.48876502630224
          ]
        }
      ],
      "bbox": [
        -2.0499900392786525,
        40.48876502630224,
        -2.0499900392786525,
        40.48876502630224
      ]
    },
    "16198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1682504018340287,
              39.402900214824996
            ]
          },
          "bbox": [
            -2.1682504018340287,
            39.402900214824996,
            -2.1682504018340287,
            39.402900214824996
          ]
        }
      ],
      "bbox": [
        -2.1682504018340287,
        39.402900214824996,
        -2.1682504018340287,
        39.402900214824996
      ]
    },
    "16199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9677849309235518,
              39.75168615607202
            ]
          },
          "bbox": [
            -1.9677849309235518,
            39.75168615607202,
            -1.9677849309235518,
            39.75168615607202
          ]
        }
      ],
      "bbox": [
        -1.9677849309235518,
        39.75168615607202,
        -1.9677849309235518,
        39.75168615607202
      ]
    },
    "16202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2727363137765715,
              39.82873935738913
            ]
          },
          "bbox": [
            -1.2727363137765715,
            39.82873935738913,
            -1.2727363137765715,
            39.82873935738913
          ]
        }
      ],
      "bbox": [
        -1.2727363137765715,
        39.82873935738913,
        -1.2727363137765715,
        39.82873935738913
      ]
    },
    "16203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0057446785406357,
              39.99659923150999
            ]
          },
          "bbox": [
            -3.0057446785406357,
            39.99659923150999,
            -3.0057446785406357,
            39.99659923150999
          ]
        }
      ],
      "bbox": [
        -3.0057446785406357,
        39.99659923150999,
        -3.0057446785406357,
        39.99659923150999
      ]
    },
    "16204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.154187794261375,
              39.50299281752724
            ]
          },
          "bbox": [
            -2.154187794261375,
            39.50299281752724,
            -2.154187794261375,
            39.50299281752724
          ]
        }
      ],
      "bbox": [
        -2.154187794261375,
        39.50299281752724,
        -2.154187794261375,
        39.50299281752724
      ]
    },
    "16205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6105096892627242,
              40.132949450855655
            ]
          },
          "bbox": [
            -1.6105096892627242,
            40.132949450855655,
            -1.6105096892627242,
            40.132949450855655
          ]
        }
      ],
      "bbox": [
        -1.6105096892627242,
        40.132949450855655,
        -1.6105096892627242,
        40.132949450855655
      ]
    },
    "16206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.582942896126129,
              40.333714009047014
            ]
          },
          "bbox": [
            -2.582942896126129,
            40.333714009047014,
            -2.582942896126129,
            40.333714009047014
          ]
        }
      ],
      "bbox": [
        -2.582942896126129,
        40.333714009047014,
        -2.582942896126129,
        40.333714009047014
      ]
    },
    "16209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.288161103379255,
              40.3039085827935
            ]
          },
          "bbox": [
            -2.288161103379255,
            40.3039085827935,
            -2.288161103379255,
            40.3039085827935
          ]
        }
      ],
      "bbox": [
        -2.288161103379255,
        40.3039085827935,
        -2.288161103379255,
        40.3039085827935
      ]
    },
    "16211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5404477243122554,
              40.01504900658402
            ]
          },
          "bbox": [
            -2.5404477243122554,
            40.01504900658402,
            -2.5404477243122554,
            40.01504900658402
          ]
        }
      ],
      "bbox": [
        -2.5404477243122554,
        40.01504900658402,
        -2.5404477243122554,
        40.01504900658402
      ]
    },
    "16212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9437816109781174,
              39.88042350323829
            ]
          },
          "bbox": [
            -2.9437816109781174,
            39.88042350323829,
            -2.9437816109781174,
            39.88042350323829
          ]
        }
      ],
      "bbox": [
        -2.9437816109781174,
        39.88042350323829,
        -2.9437816109781174,
        39.88042350323829
      ]
    },
    "16213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2972245700346146,
              39.66309037815258
            ]
          },
          "bbox": [
            -2.2972245700346146,
            39.66309037815258,
            -2.2972245700346146,
            39.66309037815258
          ]
        }
      ],
      "bbox": [
        -2.2972245700346146,
        39.66309037815258,
        -2.2972245700346146,
        39.66309037815258
      ]
    },
    "16215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8443148981331772,
              40.362572332588336
            ]
          },
          "bbox": [
            -1.8443148981331772,
            40.362572332588336,
            -1.8443148981331772,
            40.362572332588336
          ]
        }
      ],
      "bbox": [
        -1.8443148981331772,
        40.362572332588336,
        -1.8443148981331772,
        40.362572332588336
      ]
    },
    "16216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.746165296408979,
              39.70262536249432
            ]
          },
          "bbox": [
            -2.746165296408979,
            39.70262536249432,
            -2.746165296408979,
            39.70262536249432
          ]
        }
      ],
      "bbox": [
        -2.746165296408979,
        39.70262536249432,
        -2.746165296408979,
        39.70262536249432
      ]
    },
    "16217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9161865115426435,
              39.98118401837396
            ]
          },
          "bbox": [
            -2.9161865115426435,
            39.98118401837396,
            -2.9161865115426435,
            39.98118401837396
          ]
        }
      ],
      "bbox": [
        -2.9161865115426435,
        39.98118401837396,
        -2.9161865115426435,
        39.98118401837396
      ]
    },
    "16218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.865759102190177,
              39.95606562466453
            ]
          },
          "bbox": [
            -2.865759102190177,
            39.95606562466453,
            -2.865759102190177,
            39.95606562466453
          ]
        }
      ],
      "bbox": [
        -2.865759102190177,
        39.95606562466453,
        -2.865759102190177,
        39.95606562466453
      ]
    },
    "16219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9757951927820492,
              40.219191836621526
            ]
          },
          "bbox": [
            -1.9757951927820492,
            40.219191836621526,
            -1.9757951927820492,
            40.219191836621526
          ]
        }
      ],
      "bbox": [
        -1.9757951927820492,
        40.219191836621526,
        -1.9757951927820492,
        40.219191836621526
      ]
    },
    "16224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.743054074540061,
              40.22518705255825
            ]
          },
          "bbox": [
            -1.743054074540061,
            40.22518705255825,
            -1.743054074540061,
            40.22518705255825
          ]
        }
      ],
      "bbox": [
        -1.743054074540061,
        40.22518705255825,
        -1.743054074540061,
        40.22518705255825
      ]
    },
    "16225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.772261427962511,
              40.03351017695392
            ]
          },
          "bbox": [
            -1.772261427962511,
            40.03351017695392,
            -1.772261427962511,
            40.03351017695392
          ]
        }
      ],
      "bbox": [
        -1.772261427962511,
        40.03351017695392,
        -1.772261427962511,
        40.03351017695392
      ]
    },
    "16227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7783401156861207,
              40.12930027130801
            ]
          },
          "bbox": [
            -1.7783401156861207,
            40.12930027130801,
            -1.7783401156861207,
            40.12930027130801
          ]
        }
      ],
      "bbox": [
        -1.7783401156861207,
        40.12930027130801,
        -1.7783401156861207,
        40.12930027130801
      ]
    },
    "16228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.456374939277426,
              40.49571060868622
            ]
          },
          "bbox": [
            -2.456374939277426,
            40.49571060868622,
            -2.456374939277426,
            40.49571060868622
          ]
        }
      ],
      "bbox": [
        -2.456374939277426,
        40.49571060868622,
        -2.456374939277426,
        40.49571060868622
      ]
    },
    "16231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0066224736853977,
              39.55062685294391
            ]
          },
          "bbox": [
            -2.0066224736853977,
            39.55062685294391,
            -2.0066224736853977,
            39.55062685294391
          ]
        }
      ],
      "bbox": [
        -2.0066224736853977,
        39.55062685294391,
        -2.0066224736853977,
        39.55062685294391
      ]
    },
    "16234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1202509069627857,
              40.626310862275346
            ]
          },
          "bbox": [
            -2.1202509069627857,
            40.626310862275346,
            -2.1202509069627857,
            40.626310862275346
          ]
        }
      ],
      "bbox": [
        -2.1202509069627857,
        40.626310862275346,
        -2.1202509069627857,
        40.626310862275346
      ]
    },
    "16236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.230604651497934,
              39.713708346246726
            ]
          },
          "bbox": [
            -2.230604651497934,
            39.713708346246726,
            -2.230604651497934,
            39.713708346246726
          ]
        }
      ],
      "bbox": [
        -2.230604651497934,
        39.713708346246726,
        -2.230604651497934,
        39.713708346246726
      ]
    },
    "16237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.030816529917874,
              39.62276939417837
            ]
          },
          "bbox": [
            -2.030816529917874,
            39.62276939417837,
            -2.030816529917874,
            39.62276939417837
          ]
        }
      ],
      "bbox": [
        -2.030816529917874,
        39.62276939417837,
        -2.030816529917874,
        39.62276939417837
      ]
    },
    "16238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.298902039807281,
              39.426575829256606
            ]
          },
          "bbox": [
            -2.298902039807281,
            39.426575829256606,
            -2.298902039807281,
            39.426575829256606
          ]
        }
      ],
      "bbox": [
        -2.298902039807281,
        39.426575829256606,
        -2.298902039807281,
        39.426575829256606
      ]
    },
    "16239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9443199728706895,
              40.443553553250304
            ]
          },
          "bbox": [
            -1.9443199728706895,
            40.443553553250304,
            -1.9443199728706895,
            40.443553553250304
          ]
        }
      ],
      "bbox": [
        -1.9443199728706895,
        40.443553553250304,
        -1.9443199728706895,
        40.443553553250304
      ]
    },
    "16240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8016190933328673,
              40.122327633904725
            ]
          },
          "bbox": [
            -2.8016190933328673,
            40.122327633904725,
            -2.8016190933328673,
            40.122327633904725
          ]
        }
      ],
      "bbox": [
        -2.8016190933328673,
        40.122327633904725,
        -2.8016190933328673,
        40.122327633904725
      ]
    },
    "16242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.319173973698134,
              40.39802637848109
            ]
          },
          "bbox": [
            -2.319173973698134,
            40.39802637848109,
            -2.319173973698134,
            40.39802637848109
          ]
        }
      ],
      "bbox": [
        -2.319173973698134,
        40.39802637848109,
        -2.319173973698134,
        40.39802637848109
      ]
    },
    "16243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.630585172976094,
              39.62016633420168
            ]
          },
          "bbox": [
            -2.630585172976094,
            39.62016633420168,
            -2.630585172976094,
            39.62016633420168
          ]
        }
      ],
      "bbox": [
        -2.630585172976094,
        39.62016633420168,
        -2.630585172976094,
        39.62016633420168
      ]
    },
    "16244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8184459995480606,
              39.33813975725711
            ]
          },
          "bbox": [
            -1.8184459995480606,
            39.33813975725711,
            -1.8184459995480606,
            39.33813975725711
          ]
        }
      ],
      "bbox": [
        -1.8184459995480606,
        39.33813975725711,
        -1.8184459995480606,
        39.33813975725711
      ]
    },
    "16245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0829579458195946,
              40.2370241641256
            ]
          },
          "bbox": [
            -2.0829579458195946,
            40.2370241641256,
            -2.0829579458195946,
            40.2370241641256
          ]
        }
      ],
      "bbox": [
        -2.0829579458195946,
        40.2370241641256,
        -2.0829579458195946,
        40.2370241641256
      ]
    },
    "16246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.664265639099145,
              40.34751242829564
            ]
          },
          "bbox": [
            -2.664265639099145,
            40.34751242829564,
            -2.664265639099145,
            40.34751242829564
          ]
        }
      ],
      "bbox": [
        -2.664265639099145,
        40.34751242829564,
        -2.664265639099145,
        40.34751242829564
      ]
    },
    "16247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5113953140337477,
              39.68172463146979
            ]
          },
          "bbox": [
            -2.5113953140337477,
            39.68172463146979,
            -2.5113953140337477,
            39.68172463146979
          ]
        }
      ],
      "bbox": [
        -2.5113953140337477,
        39.68172463146979,
        -2.5113953140337477,
        39.68172463146979
      ]
    },
    "16248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6326493859729867,
              39.47127359515486
            ]
          },
          "bbox": [
            -1.6326493859729867,
            39.47127359515486,
            -1.6326493859729867,
            39.47127359515486
          ]
        }
      ],
      "bbox": [
        -1.6326493859729867,
        39.47127359515486,
        -1.6326493859729867,
        39.47127359515486
      ]
    },
    "16249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.917213644502665,
              39.72788753195957
            ]
          },
          "bbox": [
            -2.917213644502665,
            39.72788753195957,
            -2.917213644502665,
            39.72788753195957
          ]
        }
      ],
      "bbox": [
        -2.917213644502665,
        39.72788753195957,
        -2.917213644502665,
        39.72788753195957
      ]
    },
    "16250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5051252362601963,
              40.21943265257563
            ]
          },
          "bbox": [
            -2.5051252362601963,
            40.21943265257563,
            -2.5051252362601963,
            40.21943265257563
          ]
        }
      ],
      "bbox": [
        -2.5051252362601963,
        40.21943265257563,
        -2.5051252362601963,
        40.21943265257563
      ]
    },
    "16251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9437287465151707,
              39.423424767576
            ]
          },
          "bbox": [
            -1.9437287465151707,
            39.423424767576,
            -1.9437287465151707,
            39.423424767576
          ]
        }
      ],
      "bbox": [
        -1.9437287465151707,
        39.423424767576,
        -1.9437287465151707,
        39.423424767576
      ]
    },
    "16253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5809205818841856,
              39.802368795737046
            ]
          },
          "bbox": [
            -2.5809205818841856,
            39.802368795737046,
            -2.5809205818841856,
            39.802368795737046
          ]
        }
      ],
      "bbox": [
        -2.5809205818841856,
        39.802368795737046,
        -2.5809205818841856,
        39.802368795737046
      ]
    },
    "16254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2778729062975445,
              40.22442731559056
            ]
          },
          "bbox": [
            -2.2778729062975445,
            40.22442731559056,
            -2.2778729062975445,
            40.22442731559056
          ]
        }
      ],
      "bbox": [
        -2.2778729062975445,
        40.22442731559056,
        -2.2778729062975445,
        40.22442731559056
      ]
    },
    "16255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5307976420784972,
              39.63370068081568
            ]
          },
          "bbox": [
            -2.5307976420784972,
            39.63370068081568,
            -2.5307976420784972,
            39.63370068081568
          ]
        }
      ],
      "bbox": [
        -2.5307976420784972,
        39.63370068081568,
        -2.5307976420784972,
        39.63370068081568
      ]
    },
    "16258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6458817235508714,
              39.87211574912724
            ]
          },
          "bbox": [
            -1.6458817235508714,
            39.87211574912724,
            -1.6458817235508714,
            39.87211574912724
          ]
        }
      ],
      "bbox": [
        -1.6458817235508714,
        39.87211574912724,
        -1.6458817235508714,
        39.87211574912724
      ]
    },
    "16259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4858529477203786,
              40.443516622737526
            ]
          },
          "bbox": [
            -2.4858529477203786,
            40.443516622737526,
            -2.4858529477203786,
            40.443516622737526
          ]
        }
      ],
      "bbox": [
        -2.4858529477203786,
        40.443516622737526,
        -2.4858529477203786,
        40.443516622737526
      ]
    },
    "16263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.262259987934937,
              39.993514030955545
            ]
          },
          "bbox": [
            -2.262259987934937,
            39.993514030955545,
            -2.262259987934937,
            39.993514030955545
          ]
        }
      ],
      "bbox": [
        -2.262259987934937,
        39.993514030955545,
        -2.262259987934937,
        39.993514030955545
      ]
    },
    "16264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6809233853393137,
              39.75969596493285
            ]
          },
          "bbox": [
            -2.6809233853393137,
            39.75969596493285,
            -2.6809233853393137,
            39.75969596493285
          ]
        }
      ],
      "bbox": [
        -2.6809233853393137,
        39.75969596493285,
        -2.6809233853393137,
        39.75969596493285
      ]
    },
    "16265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4014747578981432,
              40.08427442761966
            ]
          },
          "bbox": [
            -2.4014747578981432,
            40.08427442761966,
            -2.4014747578981432,
            40.08427442761966
          ]
        }
      ],
      "bbox": [
        -2.4014747578981432,
        40.08427442761966,
        -2.4014747578981432,
        40.08427442761966
      ]
    },
    "16266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4501653003053785,
              39.88518423942567
            ]
          },
          "bbox": [
            -2.4501653003053785,
            39.88518423942567,
            -2.4501653003053785,
            39.88518423942567
          ]
        }
      ],
      "bbox": [
        -2.4501653003053785,
        39.88518423942567,
        -2.4501653003053785,
        39.88518423942567
      ]
    },
    "16269": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5093149500601384,
              39.83296787722784
            ]
          },
          "bbox": [
            -2.5093149500601384,
            39.83296787722784,
            -2.5093149500601384,
            39.83296787722784
          ]
        }
      ],
      "bbox": [
        -2.5093149500601384,
        39.83296787722784,
        -2.5093149500601384,
        39.83296787722784
      ]
    },
    "16270": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.907120437503595,
              39.94687379893515
            ]
          },
          "bbox": [
            -2.907120437503595,
            39.94687379893515,
            -2.907120437503595,
            39.94687379893515
          ]
        }
      ],
      "bbox": [
        -2.907120437503595,
        39.94687379893515,
        -2.907120437503595,
        39.94687379893515
      ]
    },
    "16271": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6496712776757214,
              39.44283754239049
            ]
          },
          "bbox": [
            -1.6496712776757214,
            39.44283754239049,
            -1.6496712776757214,
            39.44283754239049
          ]
        }
      ],
      "bbox": [
        -1.6496712776757214,
        39.44283754239049,
        -1.6496712776757214,
        39.44283754239049
      ]
    },
    "16272": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4113185080914907,
              40.23029029906036
            ]
          },
          "bbox": [
            -2.4113185080914907,
            40.23029029906036,
            -2.4113185080914907,
            40.23029029906036
          ]
        }
      ],
      "bbox": [
        -2.4113185080914907,
        40.23029029906036,
        -2.4113185080914907,
        40.23029029906036
      ]
    },
    "16273": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2811510980932748,
              39.77441756452726
            ]
          },
          "bbox": [
            -2.2811510980932748,
            39.77441756452726,
            -2.2811510980932748,
            39.77441756452726
          ]
        }
      ],
      "bbox": [
        -2.2811510980932748,
        39.77441756452726,
        -2.2811510980932748,
        39.77441756452726
      ]
    },
    "16274": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5918143268008016,
              39.751678677116246
            ]
          },
          "bbox": [
            -1.5918143268008016,
            39.751678677116246,
            -1.5918143268008016,
            39.751678677116246
          ]
        }
      ],
      "bbox": [
        -1.5918143268008016,
        39.751678677116246,
        -1.5918143268008016,
        39.751678677116246
      ]
    },
    "16275": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3845341557336126,
              40.58282517639092
            ]
          },
          "bbox": [
            -2.3845341557336126,
            40.58282517639092,
            -2.3845341557336126,
            40.58282517639092
          ]
        }
      ],
      "bbox": [
        -2.3845341557336126,
        40.58282517639092,
        -2.3845341557336126,
        40.58282517639092
      ]
    },
    "16276": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7356836713906918,
              39.75846466787131
            ]
          },
          "bbox": [
            -1.7356836713906918,
            39.75846466787131,
            -1.7356836713906918,
            39.75846466787131
          ]
        }
      ],
      "bbox": [
        -1.7356836713906918,
        39.75846466787131,
        -1.7356836713906918,
        39.75846466787131
      ]
    },
    "16277": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5452831284134856,
              39.89674653746998
            ]
          },
          "bbox": [
            -2.5452831284134856,
            39.89674653746998,
            -2.5452831284134856,
            39.89674653746998
          ]
        }
      ],
      "bbox": [
        -2.5452831284134856,
        39.89674653746998,
        -2.5452831284134856,
        39.89674653746998
      ]
    },
    "16278": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6313522267598841,
              40.2265829478057
            ]
          },
          "bbox": [
            -1.6313522267598841,
            40.2265829478057,
            -1.6313522267598841,
            40.2265829478057
          ]
        }
      ],
      "bbox": [
        -1.6313522267598841,
        40.2265829478057,
        -1.6313522267598841,
        40.2265829478057
      ]
    },
    "16279": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.127515198435275,
              40.027996584674526
            ]
          },
          "bbox": [
            -3.127515198435275,
            40.027996584674526,
            -3.127515198435275,
            40.027996584674526
          ]
        }
      ],
      "bbox": [
        -3.127515198435275,
        40.027996584674526,
        -3.127515198435275,
        40.027996584674526
      ]
    },
    "16280": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1396253983453613,
              40.26685936193542
            ]
          },
          "bbox": [
            -2.1396253983453613,
            40.26685936193542,
            -2.1396253983453613,
            40.26685936193542
          ]
        }
      ],
      "bbox": [
        -2.1396253983453613,
        40.26685936193542,
        -2.1396253983453613,
        40.26685936193542
      ]
    },
    "16901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7026740238765403,
              40.025661312765536
            ]
          },
          "bbox": [
            -2.7026740238765403,
            40.025661312765536,
            -2.7026740238765403,
            40.025661312765536
          ]
        }
      ],
      "bbox": [
        -2.7026740238765403,
        40.025661312765536,
        -2.7026740238765403,
        40.025661312765536
      ]
    },
    "16902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.170259060299306,
              39.92399089470467
            ]
          },
          "bbox": [
            -2.170259060299306,
            39.92399089470467,
            -2.170259060299306,
            39.92399089470467
          ]
        }
      ],
      "bbox": [
        -2.170259060299306,
        39.92399089470467,
        -2.170259060299306,
        39.92399089470467
      ]
    },
    "16903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1470588149846668,
              39.79583315028842
            ]
          },
          "bbox": [
            -2.1470588149846668,
            39.79583315028842,
            -2.1470588149846668,
            39.79583315028842
          ]
        }
      ],
      "bbox": [
        -2.1470588149846668,
        39.79583315028842,
        -2.1470588149846668,
        39.79583315028842
      ]
    },
    "16904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3070932982208903,
              40.12996069617408
            ]
          },
          "bbox": [
            -2.3070932982208903,
            40.12996069617408,
            -2.3070932982208903,
            40.12996069617408
          ]
        }
      ],
      "bbox": [
        -2.3070932982208903,
        40.12996069617408,
        -2.3070932982208903,
        40.12996069617408
      ]
    },
    "16905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.088716205164967,
              39.95386035982475
            ]
          },
          "bbox": [
            -2.088716205164967,
            39.95386035982475,
            -2.088716205164967,
            39.95386035982475
          ]
        }
      ],
      "bbox": [
        -2.088716205164967,
        39.95386035982475,
        -2.088716205164967,
        39.95386035982475
      ]
    },
    "16906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.469774117035403,
              40.10836323831754
            ]
          },
          "bbox": [
            -2.469774117035403,
            40.10836323831754,
            -2.469774117035403,
            40.10836323831754
          ]
        }
      ],
      "bbox": [
        -2.469774117035403,
        40.10836323831754,
        -2.469774117035403,
        40.10836323831754
      ]
    },
    "16908": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.022324862268026,
              39.4788271660334
            ]
          },
          "bbox": [
            -2.022324862268026,
            39.4788271660334,
            -2.022324862268026,
            39.4788271660334
          ]
        }
      ],
      "bbox": [
        -2.022324862268026,
        39.4788271660334,
        -2.022324862268026,
        39.4788271660334
      ]
    },
    "16909": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1872454258645644,
              40.28538281817773
            ]
          },
          "bbox": [
            -2.1872454258645644,
            40.28538281817773,
            -2.1872454258645644,
            40.28538281817773
          ]
        }
      ],
      "bbox": [
        -2.1872454258645644,
        40.28538281817773,
        -2.1872454258645644,
        40.28538281817773
      ]
    },
    "16910": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.409212010232881,
              40.139766910473845
            ]
          },
          "bbox": [
            -2.409212010232881,
            40.139766910473845,
            -2.409212010232881,
            40.139766910473845
          ]
        }
      ],
      "bbox": [
        -2.409212010232881,
        40.139766910473845,
        -2.409212010232881,
        40.139766910473845
      ]
    },
    "17001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8407488339983566,
              42.40582778636301
            ]
          },
          "bbox": [
            2.8407488339983566,
            42.40582778636301,
            2.8407488339983566,
            42.40582778636301
          ]
        }
      ],
      "bbox": [
        2.8407488339983566,
        42.40582778636301,
        2.8407488339983566,
        42.40582778636301
      ]
    },
    "17002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7720414741486623,
              41.9282232188988
            ]
          },
          "bbox": [
            2.7720414741486623,
            41.9282232188988,
            2.7720414741486623,
            41.9282232188988
          ]
        }
      ],
      "bbox": [
        2.7720414741486623,
        41.9282232188988,
        2.7720414741486623,
        41.9282232188988
      ]
    },
    "17003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.662504980644249,
              42.31814421150424
            ]
          },
          "bbox": [
            2.662504980644249,
            42.31814421150424,
            2.662504980644249,
            42.31814421150424
          ]
        }
      ],
      "bbox": [
        2.662504980644249,
        42.31814421150424,
        2.662504980644249,
        42.31814421150424
      ]
    },
    "17004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.081117281207002,
              42.10736088564734
            ]
          },
          "bbox": [
            3.081117281207002,
            42.10736088564734,
            3.081117281207002,
            42.10736088564734
          ]
        }
      ],
      "bbox": [
        3.081117281207002,
        42.10736088564734,
        3.081117281207002,
        42.10736088564734
      ]
    },
    "17006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9359580301795676,
              42.34669995456895
            ]
          },
          "bbox": [
            1.9359580301795676,
            42.34669995456895,
            1.9359580301795676,
            42.34669995456895
          ]
        }
      ],
      "bbox": [
        1.9359580301795676,
        42.34669995456895,
        1.9359580301795676,
        42.34669995456895
      ]
    },
    "17007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6017980481148535,
              42.01045749939023
            ]
          },
          "bbox": [
            2.6017980481148535,
            42.01045749939023,
            2.6017980481148535,
            42.01045749939023
          ]
        }
      ],
      "bbox": [
        2.6017980481148535,
        42.01045749939023,
        2.6017980481148535,
        42.01045749939023
      ]
    },
    "17008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6375899054455956,
              41.94408073107878
            ]
          },
          "bbox": [
            2.6375899054455956,
            41.94408073107878,
            2.6375899054455956,
            41.94408073107878
          ]
        }
      ],
      "bbox": [
        2.6375899054455956,
        41.94408073107878,
        2.6375899054455956,
        41.94408073107878
      ]
    },
    "17009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4910605717220373,
              41.824555941711054
            ]
          },
          "bbox": [
            2.4910605717220373,
            41.824555941711054,
            2.4910605717220373,
            41.824555941711054
          ]
        }
      ],
      "bbox": [
        2.4910605717220373,
        41.824555941711054,
        2.4910605717220373,
        41.824555941711054
      ]
    },
    "17010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6300450337586416,
              42.209554010406
            ]
          },
          "bbox": [
            2.6300450337586416,
            42.209554010406,
            2.6300450337586416,
            42.209554010406
          ]
        }
      ],
      "bbox": [
        2.6300450337586416,
        42.209554010406,
        2.6300450337586416,
        42.209554010406
      ]
    },
    "17012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9144466004560834,
              42.25278953001986
            ]
          },
          "bbox": [
            2.9144466004560834,
            42.25278953001986,
            2.9144466004560834,
            42.25278953001986
          ]
        }
      ],
      "bbox": [
        2.9144466004560834,
        42.25278953001986,
        2.9144466004560834,
        42.25278953001986
      ]
    },
    "17013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1999458264180416,
              41.947858752801324
            ]
          },
          "bbox": [
            3.1999458264180416,
            41.947858752801324,
            3.1999458264180416,
            41.947858752801324
          ]
        }
      ],
      "bbox": [
        3.1999458264180416,
        41.947858752801324,
        3.1999458264180416,
        41.947858752801324
      ]
    },
    "17014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7986623457342414,
              42.40579759127356
            ]
          },
          "bbox": [
            2.7986623457342414,
            42.40579759127356,
            2.7986623457342414,
            42.40579759127356
          ]
        }
      ],
      "bbox": [
        2.7986623457342414,
        42.40579759127356,
        2.7986623457342414,
        42.40579759127356
      ]
    },
    "17015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7711988038246176,
              42.122215205749924
            ]
          },
          "bbox": [
            2.7711988038246176,
            42.122215205749924,
            2.7711988038246176,
            42.122215205749924
          ]
        }
      ],
      "bbox": [
        2.7711988038246176,
        42.122215205749924,
        2.7711988038246176,
        42.122215205749924
      ]
    },
    "17016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.916172500675412,
              42.141260109867716
            ]
          },
          "bbox": [
            2.916172500675412,
            42.141260109867716,
            2.916172500675412,
            42.141260109867716
          ]
        }
      ],
      "bbox": [
        2.916172500675412,
        42.141260109867716,
        2.916172500675412,
        42.141260109867716
      ]
    },
    "17019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.698215078784321,
              42.20144430582911
            ]
          },
          "bbox": [
            2.698215078784321,
            42.20144430582911,
            2.698215078784321,
            42.20144430582911
          ]
        }
      ],
      "bbox": [
        2.698215078784321,
        42.20144430582911,
        2.698215078784321,
        42.20144430582911
      ]
    },
    "17020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.716477754320971,
              41.95314107970353
            ]
          },
          "bbox": [
            2.716477754320971,
            41.95314107970353,
            2.716477754320971,
            41.95314107970353
          ]
        }
      ],
      "bbox": [
        2.716477754320971,
        41.95314107970353,
        2.716477754320971,
        41.95314107970353
      ]
    },
    "17021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7154386026668003,
              42.23919909453765
            ]
          },
          "bbox": [
            2.7154386026668003,
            42.23919909453765,
            2.7154386026668003,
            42.23919909453765
          ]
        }
      ],
      "bbox": [
        2.7154386026668003,
        42.23919909453765,
        2.7154386026668003,
        42.23919909453765
      ]
    },
    "17023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.782961153692876,
              41.689010039740005
            ]
          },
          "bbox": [
            2.782961153692876,
            41.689010039740005,
            2.782961153692876,
            41.689010039740005
          ]
        }
      ],
      "bbox": [
        2.782961153692876,
        41.689010039740005,
        2.782961153692876,
        41.689010039740005
      ]
    },
    "17024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8826857395481713,
              42.416439054683785
            ]
          },
          "bbox": [
            1.8826857395481713,
            42.416439054683785,
            1.8826857395481713,
            42.416439054683785
          ]
        }
      ],
      "bbox": [
        1.8826857395481713,
        42.416439054683785,
        1.8826857395481713,
        42.416439054683785
      ]
    },
    "17025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9105012548846627,
              42.047249862439095
            ]
          },
          "bbox": [
            2.9105012548846627,
            42.047249862439095,
            2.9105012548846627,
            42.047249862439095
          ]
        }
      ],
      "bbox": [
        2.9105012548846627,
        42.047249862439095,
        2.9105012548846627,
        42.047249862439095
      ]
    },
    "17026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9305084603715694,
              42.217850126184075
            ]
          },
          "bbox": [
            2.9305084603715694,
            42.217850126184075,
            2.9305084603715694,
            42.217850126184075
          ]
        }
      ],
      "bbox": [
        2.9305084603715694,
        42.217850126184075,
        2.9305084603715694,
        42.217850126184075
      ]
    },
    "17027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5562555306493073,
              41.75327180573561
            ]
          },
          "bbox": [
            2.5562555306493073,
            41.75327180573561,
            2.5562555306493073,
            41.75327180573561
          ]
        }
      ],
      "bbox": [
        2.5562555306493073,
        41.75327180573561,
        2.5562555306493073,
        41.75327180573561
      ]
    },
    "17028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6719061921812197,
              41.912385805878884
            ]
          },
          "bbox": [
            2.6719061921812197,
            41.912385805878884,
            2.6719061921812197,
            41.912385805878884
          ]
        }
      ],
      "bbox": [
        2.6719061921812197,
        41.912385805878884,
        2.6719061921812197,
        41.912385805878884
      ]
    },
    "17029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8663905866942527,
              42.32918691793784
            ]
          },
          "bbox": [
            2.8663905866942527,
            42.32918691793784,
            2.8663905866942527,
            42.32918691793784
          ]
        }
      ],
      "bbox": [
        2.8663905866942527,
        42.32918691793784,
        2.8663905866942527,
        42.32918691793784
      ]
    },
    "17030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.968075991094291,
              42.30819085411444
            ]
          },
          "bbox": [
            2.968075991094291,
            42.30819085411444,
            2.968075991094291,
            42.30819085411444
          ]
        }
      ],
      "bbox": [
        2.968075991094291,
        42.30819085411444,
        2.968075991094291,
        42.30819085411444
      ]
    },
    "17031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7859095118281823,
              42.24791609451115
            ]
          },
          "bbox": [
            2.7859095118281823,
            42.24791609451115,
            2.7859095118281823,
            42.24791609451115
          ]
        }
      ],
      "bbox": [
        2.7859095118281823,
        42.24791609451115,
        2.7859095118281823,
        42.24791609451115
      ]
    },
    "17032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.273526160451842,
              42.29072207164295
            ]
          },
          "bbox": [
            3.273526160451842,
            42.29072207164295,
            3.273526160451842,
            42.29072207164295
          ]
        }
      ],
      "bbox": [
        3.273526160451842,
        42.29072207164295,
        3.273526160451842,
        42.29072207164295
      ]
    },
    "17033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8087230086819686,
              41.83415051407056
            ]
          },
          "bbox": [
            2.8087230086819686,
            41.83415051407056,
            2.8087230086819686,
            41.83415051407056
          ]
        }
      ],
      "bbox": [
        2.8087230086819686,
        41.83415051407056,
        2.8087230086819686,
        41.83415051407056
      ]
    },
    "17034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.073005203373631,
              41.863358343977275
            ]
          },
          "bbox": [
            3.073005203373631,
            41.863358343977275,
            3.073005203373631,
            41.863358343977275
          ]
        }
      ],
      "bbox": [
        3.073005203373631,
        41.863358343977275,
        3.073005203373631,
        41.863358343977275
      ]
    },
    "17035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7620620307236057,
              42.084424277388905
            ]
          },
          "bbox": [
            2.7620620307236057,
            42.084424277388905,
            2.7620620307236057,
            42.084424277388905
          ]
        }
      ],
      "bbox": [
        2.7620620307236057,
        42.084424277388905,
        2.7620620307236057,
        42.084424277388905
      ]
    },
    "17036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.139371604260175,
              42.237069555422295
            ]
          },
          "bbox": [
            2.139371604260175,
            42.237069555422295,
            2.139371604260175,
            42.237069555422295
          ]
        }
      ],
      "bbox": [
        2.139371604260175,
        42.237069555422295,
        2.139371604260175,
        42.237069555422295
      ]
    },
    "17037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1349124983466337,
              42.28510503800554
            ]
          },
          "bbox": [
            2.1349124983466337,
            42.28510503800554,
            2.1349124983466337,
            42.28510503800554
          ]
        }
      ],
      "bbox": [
        2.1349124983466337,
        42.28510503800554,
        2.1349124983466337,
        42.28510503800554
      ]
    },
    "17038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.832064470728327,
              41.89805352463581
            ]
          },
          "bbox": [
            2.832064470728327,
            41.89805352463581,
            2.832064470728327,
            41.89805352463581
          ]
        }
      ],
      "bbox": [
        2.832064470728327,
        41.89805352463581,
        2.832064470728327,
        41.89805352463581
      ]
    },
    "17039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4310585224338492,
              42.31048432999014
            ]
          },
          "bbox": [
            2.4310585224338492,
            42.31048432999014,
            2.4310585224338492,
            42.31048432999014
          ]
        }
      ],
      "bbox": [
        2.4310585224338492,
        42.31048432999014,
        2.4310585224338492,
        42.31048432999014
      ]
    },
    "17041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.928265673051161,
              42.4180596414704
            ]
          },
          "bbox": [
            2.928265673051161,
            42.4180596414704,
            2.928265673051161,
            42.4180596414704
          ]
        }
      ],
      "bbox": [
        2.928265673051161,
        42.4180596414704,
        2.928265673051161,
        42.4180596414704
      ]
    },
    "17042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9075937005115633,
              42.371390396996475
            ]
          },
          "bbox": [
            2.9075937005115633,
            42.371390396996475,
            2.9075937005115633,
            42.371390396996475
          ]
        }
      ],
      "bbox": [
        2.9075937005115633,
        42.371390396996475,
        2.9075937005115633,
        42.371390396996475
      ]
    },
    "17043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1713319565149014,
              42.37861156857136
            ]
          },
          "bbox": [
            2.1713319565149014,
            42.37861156857136,
            2.1713319565149014,
            42.37861156857136
          ]
        }
      ],
      "bbox": [
        2.1713319565149014,
        42.37861156857136,
        2.1713319565149014,
        42.37861156857136
      ]
    },
    "17044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.887982240453988,
              41.879563572840695
            ]
          },
          "bbox": [
            2.887982240453988,
            41.879563572840695,
            2.887982240453988,
            41.879563572840695
          ]
        }
      ],
      "bbox": [
        2.887982240453988,
        41.879563572840695,
        2.887982240453988,
        41.879563572840695
      ]
    },
    "17046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5433943630659996,
              42.218642870473204
            ]
          },
          "bbox": [
            2.5433943630659996,
            42.218642870473204,
            2.5433943630659996,
            42.218642870473204
          ]
        }
      ],
      "bbox": [
        2.5433943630659996,
        42.218642870473204,
        2.5433943630659996,
        42.218642870473204
      ]
    },
    "17049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8778805323804244,
              42.01791513447506
            ]
          },
          "bbox": [
            2.8778805323804244,
            42.01791513447506,
            2.8778805323804244,
            42.01791513447506
          ]
        }
      ],
      "bbox": [
        2.8778805323804244,
        42.01791513447506,
        2.8778805323804244,
        42.01791513447506
      ]
    },
    "17050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9085154199117094,
              42.066669912673994
            ]
          },
          "bbox": [
            2.9085154199117094,
            42.066669912673994,
            2.9085154199117094,
            42.066669912673994
          ]
        }
      ],
      "bbox": [
        2.9085154199117094,
        42.066669912673994,
        2.9085154199117094,
        42.066669912673994
      ]
    },
    "17051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.830579642051198,
              42.28036587179887
            ]
          },
          "bbox": [
            2.830579642051198,
            42.28036587179887,
            2.830579642051198,
            42.28036587179887
          ]
        }
      ],
      "bbox": [
        2.830579642051198,
        42.28036587179887,
        2.830579642051198,
        42.28036587179887
      ]
    },
    "17052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.007095132464628,
              42.211963277376306
            ]
          },
          "bbox": [
            3.007095132464628,
            42.211963277376306,
            3.007095132464628,
            42.211963277376306
          ]
        }
      ],
      "bbox": [
        3.007095132464628,
        42.211963277376306,
        3.007095132464628,
        42.211963277376306
      ]
    },
    "17054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1235417092223186,
              42.402679685002425
            ]
          },
          "bbox": [
            3.1235417092223186,
            42.402679685002425,
            3.1235417092223186,
            42.402679685002425
          ]
        }
      ],
      "bbox": [
        3.1235417092223186,
        42.402679685002425,
        3.1235417092223186,
        42.402679685002425
      ]
    },
    "17055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9885233028253304,
              42.08414855085493
            ]
          },
          "bbox": [
            2.9885233028253304,
            42.08414855085493,
            2.9885233028253304,
            42.08414855085493
          ]
        }
      ],
      "bbox": [
        2.9885233028253304,
        42.08414855085493,
        2.9885233028253304,
        42.08414855085493
      ]
    },
    "17056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.824119676773252,
              42.090479116946014
            ]
          },
          "bbox": [
            2.824119676773252,
            42.090479116946014,
            2.824119676773252,
            42.090479116946014
          ]
        }
      ],
      "bbox": [
        2.824119676773252,
        42.090479116946014,
        2.824119676773252,
        42.090479116946014
      ]
    },
    "17057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0175247509932603,
              41.99522338886726
            ]
          },
          "bbox": [
            3.0175247509932603,
            41.99522338886726,
            3.0175247509932603,
            41.99522338886726
          ]
        }
      ],
      "bbox": [
        3.0175247509932603,
        41.99522338886726,
        3.0175247509932603,
        41.99522338886726
      ]
    },
    "17058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7993104931551733,
              42.1929969669955
            ]
          },
          "bbox": [
            2.7993104931551733,
            42.1929969669955,
            2.7993104931551733,
            42.1929969669955
          ]
        }
      ],
      "bbox": [
        2.7993104931551733,
        42.1929969669955,
        2.7993104931551733,
        42.1929969669955
      ]
    },
    "17060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8290546075491694,
              42.36380683916511
            ]
          },
          "bbox": [
            2.8290546075491694,
            42.36380683916511,
            2.8290546075491694,
            42.36380683916511
          ]
        }
      ],
      "bbox": [
        2.8290546075491694,
        42.36380683916511,
        2.8290546075491694,
        42.36380683916511
      ]
    },
    "17061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8669440292711224,
              42.36245492642174
            ]
          },
          "bbox": [
            1.8669440292711224,
            42.36245492642174,
            1.8669440292711224,
            42.36245492642174
          ]
        }
      ],
      "bbox": [
        1.8669440292711224,
        42.36245492642174,
        1.8669440292711224,
        42.36245492642174
      ]
    },
    "17063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.423057441474573,
              41.87378012636073
            ]
          },
          "bbox": [
            2.423057441474573,
            41.87378012636073,
            2.423057441474573,
            41.87378012636073
          ]
        }
      ],
      "bbox": [
        2.423057441474573,
        41.87378012636073,
        2.423057441474573,
        41.87378012636073
      ]
    },
    "17064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0055589050784763,
              42.42928468660703
            ]
          },
          "bbox": [
            3.0055589050784763,
            42.42928468660703,
            3.0055589050784763,
            42.42928468660703
          ]
        }
      ],
      "bbox": [
        3.0055589050784763,
        42.42928468660703,
        3.0055589050784763,
        42.42928468660703
      ]
    },
    "17065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8014577623599974,
              42.17124766053551
            ]
          },
          "bbox": [
            2.8014577623599974,
            42.17124766053551,
            2.8014577623599974,
            42.17124766053551
          ]
        }
      ],
      "bbox": [
        2.8014577623599974,
        42.17124766053551,
        2.8014577623599974,
        42.17124766053551
      ]
    },
    "17066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9721745399891777,
              42.270681462186786
            ]
          },
          "bbox": [
            2.9721745399891777,
            42.270681462186786,
            2.9721745399891777,
            42.270681462186786
          ]
        }
      ],
      "bbox": [
        2.9721745399891777,
        42.270681462186786,
        2.9721745399891777,
        42.270681462186786
      ]
    },
    "17067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.962463762927575,
              42.04577702055961
            ]
          },
          "bbox": [
            2.962463762927575,
            42.04577702055961,
            2.962463762927575,
            42.04577702055961
          ]
        }
      ],
      "bbox": [
        2.962463762927575,
        42.04577702055961,
        2.962463762927575,
        42.04577702055961
      ]
    },
    "17068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0004471174667278,
              42.050587893111285
            ]
          },
          "bbox": [
            3.0004471174667278,
            42.050587893111285,
            3.0004471174667278,
            42.050587893111285
          ]
        }
      ],
      "bbox": [
        3.0004471174667278,
        42.050587893111285,
        3.0004471174667278,
        42.050587893111285
      ]
    },
    "17069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.919877564349756,
              42.385698467854475
            ]
          },
          "bbox": [
            1.919877564349756,
            42.385698467854475,
            1.919877564349756,
            42.385698467854475
          ]
        }
      ],
      "bbox": [
        1.919877564349756,
        42.385698467854475,
        1.919877564349756,
        42.385698467854475
      ]
    },
    "17070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1081953287881,
              42.01471043996727
            ]
          },
          "bbox": [
            3.1081953287881,
            42.01471043996727,
            3.1081953287881,
            42.01471043996727
          ]
        }
      ],
      "bbox": [
        3.1081953287881,
        42.01471043996727,
        3.1081953287881,
        42.01471043996727
      ]
    },
    "17071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8053521113800506,
              42.135953010255285
            ]
          },
          "bbox": [
            2.8053521113800506,
            42.135953010255285,
            2.8053521113800506,
            42.135953010255285
          ]
        }
      ],
      "bbox": [
        2.8053521113800506,
        42.135953010255285,
        2.8053521113800506,
        42.135953010255285
      ]
    },
    "17073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8118717041696244,
              41.92799691605333
            ]
          },
          "bbox": [
            2.8118717041696244,
            41.92799691605333,
            2.8118717041696244,
            41.92799691605333
          ]
        }
      ],
      "bbox": [
        2.8118717041696244,
        41.92799691605333,
        2.8118717041696244,
        41.92799691605333
      ]
    },
    "17074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.035811357078253,
              42.242775013779315
            ]
          },
          "bbox": [
            3.035811357078253,
            42.242775013779315,
            3.035811357078253,
            42.242775013779315
          ]
        }
      ],
      "bbox": [
        3.035811357078253,
        42.242775013779315,
        3.035811357078253,
        42.242775013779315
      ]
    },
    "17075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.953379564382312,
              42.18227439870214
            ]
          },
          "bbox": [
            2.953379564382312,
            42.18227439870214,
            2.953379564382312,
            42.18227439870214
          ]
        }
      ],
      "bbox": [
        2.953379564382312,
        42.18227439870214,
        2.953379564382312,
        42.18227439870214
      ]
    },
    "17076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0240669744436808,
              42.105839390977884
            ]
          },
          "bbox": [
            3.0240669744436808,
            42.105839390977884,
            3.0240669744436808,
            42.105839390977884
          ]
        }
      ],
      "bbox": [
        3.0240669744436808,
        42.105839390977884,
        3.0240669744436808,
        42.105839390977884
      ]
    },
    "17077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0625459974220752,
              42.34363349033878
            ]
          },
          "bbox": [
            3.0625459974220752,
            42.34363349033878,
            3.0625459974220752,
            42.34363349033878
          ]
        }
      ],
      "bbox": [
        3.0625459974220752,
        42.34363349033878,
        3.0625459974220752,
        42.34363349033878
      ]
    },
    "17078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8288145712546928,
              42.43805352471344
            ]
          },
          "bbox": [
            1.8288145712546928,
            42.43805352471344,
            1.8288145712546928,
            42.43805352471344
          ]
        }
      ],
      "bbox": [
        1.8288145712546928,
        42.43805352471344,
        1.8288145712546928,
        42.43805352471344
      ]
    },
    "17079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.835361023075883,
              41.9846618384432
            ]
          },
          "bbox": [
            2.835361023075883,
            41.9846618384432,
            2.835361023075883,
            41.9846618384432
          ]
        }
      ],
      "bbox": [
        2.835361023075883,
        41.9846618384432,
        2.835361023075883,
        41.9846618384432
      ]
    },
    "17080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.070289344260556,
              42.250610297647064
            ]
          },
          "bbox": [
            2.070289344260556,
            42.250610297647064,
            2.070289344260556,
            42.250610297647064
          ]
        }
      ],
      "bbox": [
        2.070289344260556,
        42.250610297647064,
        2.070289344260556,
        42.250610297647064
      ]
    },
    "17081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1170243284287724,
              42.025289431708984
            ]
          },
          "bbox": [
            3.1170243284287724,
            42.025289431708984,
            3.1170243284287724,
            42.025289431708984
          ]
        }
      ],
      "bbox": [
        3.1170243284287724,
        42.025289431708984,
        3.1170243284287724,
        42.025289431708984
      ]
    },
    "17082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8676503680566867,
              42.451270019877114
            ]
          },
          "bbox": [
            1.8676503680566867,
            42.451270019877114,
            1.8676503680566867,
            42.451270019877114
          ]
        }
      ],
      "bbox": [
        1.8676503680566867,
        42.451270019877114,
        1.8676503680566867,
        42.451270019877114
      ]
    },
    "17083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.630599272151508,
              41.740781675016045
            ]
          },
          "bbox": [
            2.630599272151508,
            41.740781675016045,
            2.630599272151508,
            41.740781675016045
          ]
        }
      ],
      "bbox": [
        2.630599272151508,
        41.740781675016045,
        2.630599272151508,
        41.740781675016045
      ]
    },
    "17084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8234528461429407,
              42.39110093507627
            ]
          },
          "bbox": [
            1.8234528461429407,
            42.39110093507627,
            1.8234528461429407,
            42.39110093507627
          ]
        }
      ],
      "bbox": [
        1.8234528461429407,
        42.39110093507627,
        1.8234528461429407,
        42.39110093507627
      ]
    },
    "17085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.013232243008707,
              42.07305655152381
            ]
          },
          "bbox": [
            3.013232243008707,
            42.07305655152381,
            3.013232243008707,
            42.07305655152381
          ]
        }
      ],
      "bbox": [
        3.013232243008707,
        42.07305655152381,
        3.013232243008707,
        42.07305655152381
      ]
    },
    "17086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9033827314363627,
              42.43959796845358
            ]
          },
          "bbox": [
            2.9033827314363627,
            42.43959796845358,
            2.9033827314363627,
            42.43959796845358
          ]
        }
      ],
      "bbox": [
        2.9033827314363627,
        42.43959796845358,
        2.9033827314363627,
        42.43959796845358
      ]
    },
    "17087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.907966312015984,
              42.013587816829045
            ]
          },
          "bbox": [
            2.907966312015984,
            42.013587816829045,
            2.907966312015984,
            42.013587816829045
          ]
        }
      ],
      "bbox": [
        2.907966312015984,
        42.013587816829045,
        2.907966312015984,
        42.013587816829045
      ]
    },
    "17088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8101851657221273,
              42.25447915944202
            ]
          },
          "bbox": [
            2.8101851657221273,
            42.25447915944202,
            2.8101851657221273,
            42.25447915944202
          ]
        }
      ],
      "bbox": [
        2.8101851657221273,
        42.25447915944202,
        2.8101851657221273,
        42.25447915944202
      ]
    },
    "17089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.908216002028297,
              41.81488955985753
            ]
          },
          "bbox": [
            2.908216002028297,
            41.81488955985753,
            2.908216002028297,
            41.81488955985753
          ]
        }
      ],
      "bbox": [
        2.908216002028297,
        41.81488955985753,
        2.908216002028297,
        41.81488955985753
      ]
    },
    "17090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8692669038839993,
              41.92281787921417
            ]
          },
          "bbox": [
            2.8692669038839993,
            41.92281787921417,
            2.8692669038839993,
            41.92281787921417
          ]
        }
      ],
      "bbox": [
        2.8692669038839993,
        41.92281787921417,
        2.8692669038839993,
        41.92281787921417
      ]
    },
    "17091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3471183477915813,
              42.34169708629212
            ]
          },
          "bbox": [
            2.3471183477915813,
            42.34169708629212,
            2.3471183477915813,
            42.34169708629212
          ]
        }
      ],
      "bbox": [
        2.3471183477915813,
        42.34169708629212,
        2.3471183477915813,
        42.34169708629212
      ]
    },
    "17092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.126813126865278,
              42.36629790688523
            ]
          },
          "bbox": [
            3.126813126865278,
            42.36629790688523,
            3.126813126865278,
            42.36629790688523
          ]
        }
      ],
      "bbox": [
        3.126813126865278,
        42.36629790688523,
        3.126813126865278,
        42.36629790688523
      ]
    },
    "17093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.912489533029104,
              42.29445747932013
            ]
          },
          "bbox": [
            2.912489533029104,
            42.29445747932013,
            2.912489533029104,
            42.29445747932013
          ]
        }
      ],
      "bbox": [
        2.912489533029104,
        42.29445747932013,
        2.912489533029104,
        42.29445747932013
      ]
    },
    "17094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9814546798363495,
              42.46624229592637
            ]
          },
          "bbox": [
            1.9814546798363495,
            42.46624229592637,
            1.9814546798363495,
            42.46624229592637
          ]
        }
      ],
      "bbox": [
        1.9814546798363495,
        42.46624229592637,
        1.9814546798363495,
        42.46624229592637
      ]
    },
    "17095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.834629684990945,
              41.72233611326293
            ]
          },
          "bbox": [
            2.834629684990945,
            41.72233611326293,
            2.834629684990945,
            41.72233611326293
          ]
        }
      ],
      "bbox": [
        2.834629684990945,
        41.72233611326293,
        2.834629684990945,
        41.72233611326293
      ]
    },
    "17096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1002996458433865,
              42.16650147610633
            ]
          },
          "bbox": [
            2.1002996458433865,
            42.16650147610633,
            2.1002996458433865,
            42.16650147610633
          ]
        }
      ],
      "bbox": [
        2.1002996458433865,
        42.16650147610633,
        2.1002996458433865,
        42.16650147610633
      ]
    },
    "17097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9530372316792177,
              41.98582458493548
            ]
          },
          "bbox": [
            2.9530372316792177,
            41.98582458493548,
            2.9530372316792177,
            41.98582458493548
          ]
        }
      ],
      "bbox": [
        2.9530372316792177,
        41.98582458493548,
        2.9530372316792177,
        41.98582458493548
      ]
    },
    "17098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7490281496166715,
              42.21101902397306
            ]
          },
          "bbox": [
            2.7490281496166715,
            42.21101902397306,
            2.7490281496166715,
            42.21101902397306
          ]
        }
      ],
      "bbox": [
        2.7490281496166715,
        42.21101902397306,
        2.7490281496166715,
        42.21101902397306
      ]
    },
    "17099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7659428336136775,
              42.45773032152617
            ]
          },
          "bbox": [
            1.7659428336136775,
            42.45773032152617,
            1.7659428336136775,
            42.45773032152617
          ]
        }
      ],
      "bbox": [
        1.7659428336136775,
        42.45773032152617,
        1.7659428336136775,
        42.45773032152617
      ]
    },
    "17100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9629442146111318,
              42.34332987502559
            ]
          },
          "bbox": [
            2.9629442146111318,
            42.34332987502559,
            2.9629442146111318,
            42.34332987502559
          ]
        }
      ],
      "bbox": [
        2.9629442146111318,
        42.34332987502559,
        2.9629442146111318,
        42.34332987502559
      ]
    },
    "17101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.648890480133617,
              41.77531837316789
            ]
          },
          "bbox": [
            2.648890480133617,
            41.77531837316789,
            2.648890480133617,
            41.77531837316789
          ]
        }
      ],
      "bbox": [
        2.648890480133617,
        41.77531837316789,
        2.648890480133617,
        41.77531837316789
      ]
    },
    "17102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7326280777919414,
              42.38422125074335
            ]
          },
          "bbox": [
            2.7326280777919414,
            42.38422125074335,
            2.7326280777919414,
            42.38422125074335
          ]
        }
      ],
      "bbox": [
        2.7326280777919414,
        42.38422125074335,
        2.7326280777919414,
        42.38422125074335
      ]
    },
    "17103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7247011628267157,
              41.763476402237806
            ]
          },
          "bbox": [
            2.7247011628267157,
            41.763476402237806,
            2.7247011628267157,
            41.763476402237806
          ]
        }
      ],
      "bbox": [
        2.7247011628267157,
        41.763476402237806,
        2.7247011628267157,
        41.763476402237806
      ]
    },
    "17105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.63124640959558,
              42.12201950585114
            ]
          },
          "bbox": [
            2.63124640959558,
            42.12201950585114,
            2.63124640959558,
            42.12201950585114
          ]
        }
      ],
      "bbox": [
        2.63124640959558,
        42.12201950585114,
        2.63124640959558,
        42.12201950585114
      ]
    },
    "17106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.009370856712425,
              42.349767814688555
            ]
          },
          "bbox": [
            3.009370856712425,
            42.349767814688555,
            3.009370856712425,
            42.349767814688555
          ]
        }
      ],
      "bbox": [
        3.009370856712425,
        42.349767814688555,
        3.009370856712425,
        42.349767814688555
      ]
    },
    "17107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.400290598594805,
              42.369644485147695
            ]
          },
          "bbox": [
            2.400290598594805,
            42.369644485147695,
            2.400290598594805,
            42.369644485147695
          ]
        }
      ],
      "bbox": [
        2.400290598594805,
        42.369644485147695,
        2.400290598594805,
        42.369644485147695
      ]
    },
    "17109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.553465348695881,
              42.27384453683063
            ]
          },
          "bbox": [
            2.553465348695881,
            42.27384453683063,
            2.553465348695881,
            42.27384453683063
          ]
        }
      ],
      "bbox": [
        2.553465348695881,
        42.27384453683063,
        2.553465348695881,
        42.27384453683063
      ]
    },
    "17110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1487231903437274,
              41.893978594803016
            ]
          },
          "bbox": [
            3.1487231903437274,
            41.893978594803016,
            3.1487231903437274,
            41.893978594803016
          ]
        }
      ],
      "bbox": [
        3.1487231903437274,
        41.893978594803016,
        3.1487231903437274,
        41.893978594803016
      ]
    },
    "17111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8618365109228248,
              42.217696004342564
            ]
          },
          "bbox": [
            2.8618365109228248,
            42.217696004342564,
            2.8618365109228248,
            42.217696004342564
          ]
        }
      ],
      "bbox": [
        2.8618365109228248,
        42.217696004342564,
        2.8618365109228248,
        42.217696004342564
      ]
    },
    "17112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.252663753713852,
              42.26459472316063
            ]
          },
          "bbox": [
            2.252663753713852,
            42.26459472316063,
            2.252663753713852,
            42.26459472316063
          ]
        }
      ],
      "bbox": [
        2.252663753713852,
        42.26459472316063,
        2.252663753713852,
        42.26459472316063
      ]
    },
    "17114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4939645061434477,
              42.176908160386084
            ]
          },
          "bbox": [
            2.4939645061434477,
            42.176908160386084,
            2.4939645061434477,
            42.176908160386084
          ]
        }
      ],
      "bbox": [
        2.4939645061434477,
        42.176908160386084,
        2.4939645061434477,
        42.176908160386084
      ]
    },
    "17115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8945144228351913,
              42.21594175255879
            ]
          },
          "bbox": [
            2.8945144228351913,
            42.21594175255879,
            2.8945144228351913,
            42.21594175255879
          ]
        }
      ],
      "bbox": [
        2.8945144228351913,
        42.21594175255879,
        2.8945144228351913,
        42.21594175255879
      ]
    },
    "17116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5625909202966652,
              41.941762952577264
            ]
          },
          "bbox": [
            2.5625909202966652,
            41.941762952577264,
            2.5625909202966652,
            41.941762952577264
          ]
        }
      ],
      "bbox": [
        2.5625909202966652,
        41.941762952577264,
        2.5625909202966652,
        41.941762952577264
      ]
    },
    "17117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.168858336451983,
              41.9134644914124
            ]
          },
          "bbox": [
            3.168858336451983,
            41.9134644914124,
            3.168858336451983,
            41.9134644914124
          ]
        }
      ],
      "bbox": [
        3.168858336451983,
        41.9134644914124,
        3.168858336451983,
        41.9134644914124
      ]
    },
    "17118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1301679423325917,
              41.865965212835924
            ]
          },
          "bbox": [
            3.1301679423325917,
            41.865965212835924,
            3.1301679423325917,
            41.865965212835924
          ]
        }
      ],
      "bbox": [
        3.1301679423325917,
        41.865965212835924,
        3.1301679423325917,
        41.865965212835924
      ]
    },
    "17119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.975262524006795,
              42.17949554595552
            ]
          },
          "bbox": [
            2.975262524006795,
            42.17949554595552,
            2.975262524006795,
            42.17949554595552
          ]
        }
      ],
      "bbox": [
        2.975262524006795,
        42.17949554595552,
        2.975262524006795,
        42.17949554595552
      ]
    },
    "17120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.141969638633864,
              42.30052054149084
            ]
          },
          "bbox": [
            3.141969638633864,
            42.30052054149084,
            3.141969638633864,
            42.30052054149084
          ]
        }
      ],
      "bbox": [
        3.141969638633864,
        42.30052054149084,
        3.141969638633864,
        42.30052054149084
      ]
    },
    "17121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1198246291832135,
              41.98381655999356
            ]
          },
          "bbox": [
            3.1198246291832135,
            41.98381655999356,
            3.1198246291832135,
            41.98381655999356
          ]
        }
      ],
      "bbox": [
        3.1198246291832135,
        41.98381655999356,
        3.1198246291832135,
        41.98381655999356
      ]
    },
    "17123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8037606593684385,
              42.05619410034562
            ]
          },
          "bbox": [
            2.8037606593684385,
            42.05619410034562,
            2.8037606593684385,
            42.05619410034562
          ]
        }
      ],
      "bbox": [
        2.8037606593684385,
        42.05619410034562,
        2.8037606593684385,
        42.05619410034562
      ]
    },
    "17124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1688978173188085,
              41.98256538807738
            ]
          },
          "bbox": [
            3.1688978173188085,
            41.98256538807738,
            3.1688978173188085,
            41.98256538807738
          ]
        }
      ],
      "bbox": [
        3.1688978173188085,
        41.98256538807738,
        3.1688978173188085,
        41.98256538807738
      ]
    },
    "17125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.230623834070596,
              42.306415384034835
            ]
          },
          "bbox": [
            2.230623834070596,
            42.306415384034835,
            2.230623834070596,
            42.306415384034835
          ]
        }
      ],
      "bbox": [
        2.230623834070596,
        42.306415384034835,
        2.230623834070596,
        42.306415384034835
      ]
    },
    "17126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0423608906801576,
              42.02003452754698
            ]
          },
          "bbox": [
            3.0423608906801576,
            42.02003452754698,
            3.0423608906801576,
            42.02003452754698
          ]
        }
      ],
      "bbox": [
        3.0423608906801576,
        42.02003452754698,
        3.0423608906801576,
        42.02003452754698
      ]
    },
    "17128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1138519244489755,
              42.3092456685164
            ]
          },
          "bbox": [
            3.1138519244489755,
            42.3092456685164,
            3.1138519244489755,
            42.3092456685164
          ]
        }
      ],
      "bbox": [
        3.1138519244489755,
        42.3092456685164,
        3.1138519244489755,
        42.3092456685164
      ]
    },
    "17129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.069993191082427,
              42.306862367266895
            ]
          },
          "bbox": [
            3.069993191082427,
            42.306862367266895,
            3.069993191082427,
            42.306862367266895
          ]
        }
      ],
      "bbox": [
        3.069993191082427,
        42.306862367266895,
        3.069993191082427,
        42.306862367266895
      ]
    },
    "17130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.971217991420636,
              42.01759680926395
            ]
          },
          "bbox": [
            2.971217991420636,
            42.01759680926395,
            2.971217991420636,
            42.01759680926395
          ]
        }
      ],
      "bbox": [
        2.971217991420636,
        42.01759680926395,
        2.971217991420636,
        42.01759680926395
      ]
    },
    "17132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0297310297856117,
              42.30195642221891
            ]
          },
          "bbox": [
            3.0297310297856117,
            42.30195642221891,
            3.0297310297856117,
            42.30195642221891
          ]
        }
      ],
      "bbox": [
        3.0297310297856117,
        42.30195642221891,
        3.0297310297856117,
        42.30195642221891
      ]
    },
    "17134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1027628539975067,
              42.3218601966606
            ]
          },
          "bbox": [
            2.1027628539975067,
            42.3218601966606,
            2.1027628539975067,
            42.3218601966606
          ]
        }
      ],
      "bbox": [
        2.1027628539975067,
        42.3218601966606,
        2.1027628539975067,
        42.3218601966606
      ]
    },
    "17135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9258073195840257,
              42.32027882276414
            ]
          },
          "bbox": [
            2.9258073195840257,
            42.32027882276414,
            2.9258073195840257,
            42.32027882276414
          ]
        }
      ],
      "bbox": [
        2.9258073195840257,
        42.32027882276414,
        2.9258073195840257,
        42.32027882276414
      ]
    },
    "17136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.911598319882567,
              42.18475714418702
            ]
          },
          "bbox": [
            2.911598319882567,
            42.18475714418702,
            2.911598319882567,
            42.18475714418702
          ]
        }
      ],
      "bbox": [
        2.911598319882567,
        42.18475714418702,
        2.911598319882567,
        42.18475714418702
      ]
    },
    "17137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.730468308434781,
              42.12181550785179
            ]
          },
          "bbox": [
            2.730468308434781,
            42.12181550785179,
            2.730468308434781,
            42.12181550785179
          ]
        }
      ],
      "bbox": [
        2.730468308434781,
        42.12181550785179,
        2.730468308434781,
        42.12181550785179
      ]
    },
    "17138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1381344673093152,
              42.42714337523726
            ]
          },
          "bbox": [
            3.1381344673093152,
            42.42714337523726,
            3.1381344673093152,
            42.42714337523726
          ]
        }
      ],
      "bbox": [
        3.1381344673093152,
        42.42714337523726,
        3.1381344673093152,
        42.42714337523726
      ]
    },
    "17139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.474008256266403,
              42.14737628133289
            ]
          },
          "bbox": [
            2.474008256266403,
            42.14737628133289,
            2.474008256266403,
            42.14737628133289
          ]
        }
      ],
      "bbox": [
        2.474008256266403,
        42.14737628133289,
        2.474008256266403,
        42.14737628133289
      ]
    },
    "17140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.2163394419720714,
              42.32491119030621
            ]
          },
          "bbox": [
            3.2163394419720714,
            42.32491119030621,
            3.2163394419720714,
            42.32491119030621
          ]
        }
      ],
      "bbox": [
        3.2163394419720714,
        42.32491119030621,
        3.2163394419720714,
        42.32491119030621
      ]
    },
    "17141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9384804030393885,
              42.416120018008264
            ]
          },
          "bbox": [
            1.9384804030393885,
            42.416120018008264,
            1.9384804030393885,
            42.416120018008264
          ]
        }
      ],
      "bbox": [
        1.9384804030393885,
        42.416120018008264,
        1.9384804030393885,
        42.416120018008264
      ]
    },
    "17142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8871606429050636,
              41.953237136555664
            ]
          },
          "bbox": [
            2.8871606429050636,
            41.953237136555664,
            2.8871606429050636,
            41.953237136555664
          ]
        }
      ],
      "bbox": [
        2.8871606429050636,
        41.953237136555664,
        2.8871606429050636,
        41.953237136555664
      ]
    },
    "17143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0465173756222064,
              42.3997807493008
            ]
          },
          "bbox": [
            3.0465173756222064,
            42.3997807493008,
            3.0465173756222064,
            42.3997807493008
          ]
        }
      ],
      "bbox": [
        3.0465173756222064,
        42.3997807493008,
        3.0465173756222064,
        42.3997807493008
      ]
    },
    "17144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1718590896217034,
              41.94944703319155
            ]
          },
          "bbox": [
            3.1718590896217034,
            41.94944703319155,
            3.1718590896217034,
            41.94944703319155
          ]
        }
      ],
      "bbox": [
        3.1718590896217034,
        41.94944703319155,
        3.1718590896217034,
        41.94944703319155
      ]
    },
    "17145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1715617236669886,
              42.299892468707796
            ]
          },
          "bbox": [
            2.1715617236669886,
            42.299892468707796,
            2.1715617236669886,
            42.299892468707796
          ]
        }
      ],
      "bbox": [
        2.1715617236669886,
        42.299892468707796,
        2.1715617236669886,
        42.299892468707796
      ]
    },
    "17146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5210809136443437,
              41.761165001570006
            ]
          },
          "bbox": [
            2.5210809136443437,
            41.761165001570006,
            2.5210809136443437,
            41.761165001570006
          ]
        }
      ],
      "bbox": [
        2.5210809136443437,
        41.761165001570006,
        2.5210809136443437,
        41.761165001570006
      ]
    },
    "17147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2137635429149594,
              42.18626812990139
            ]
          },
          "bbox": [
            2.2137635429149594,
            42.18626812990139,
            2.2137635429149594,
            42.18626812990139
          ]
        }
      ],
      "bbox": [
        2.2137635429149594,
        42.18626812990139,
        2.2137635429149594,
        42.18626812990139
      ]
    },
    "17148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6718734446794077,
              41.82183338070173
            ]
          },
          "bbox": [
            2.6718734446794077,
            41.82183338070173,
            2.6718734446794077,
            41.82183338070173
          ]
        }
      ],
      "bbox": [
        2.6718734446794077,
        41.82183338070173,
        2.6718734446794077,
        41.82183338070173
      ]
    },
    "17149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3887045677181002,
              42.19043521895153
            ]
          },
          "bbox": [
            2.3887045677181002,
            42.19043521895153,
            2.3887045677181002,
            42.19043521895153
          ]
        }
      ],
      "bbox": [
        2.3887045677181002,
        42.19043521895153,
        2.3887045677181002,
        42.19043521895153
      ]
    },
    "17150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7962678810180015,
              41.89327144193135
            ]
          },
          "bbox": [
            2.7962678810180015,
            41.89327144193135,
            2.7962678810180015,
            41.89327144193135
          ]
        }
      ],
      "bbox": [
        2.7962678810180015,
        41.89327144193135,
        2.7962678810180015,
        41.89327144193135
      ]
    },
    "17151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.045944013579724,
              42.2231945254358
            ]
          },
          "bbox": [
            3.045944013579724,
            42.2231945254358,
            3.045944013579724,
            42.2231945254358
          ]
        }
      ],
      "bbox": [
        3.045944013579724,
        42.2231945254358,
        3.045944013579724,
        42.2231945254358
      ]
    },
    "17152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1995076832738603,
              42.27119459021505
            ]
          },
          "bbox": [
            3.1995076832738603,
            42.27119459021505,
            3.1995076832738603,
            42.27119459021505
          ]
        }
      ],
      "bbox": [
        3.1995076832738603,
        42.27119459021505,
        3.1995076832738603,
        42.27119459021505
      ]
    },
    "17153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0099002789509224,
              42.017011664483434
            ]
          },
          "bbox": [
            3.0099002789509224,
            42.017011664483434,
            3.0099002789509224,
            42.017011664483434
          ]
        }
      ],
      "bbox": [
        3.0099002789509224,
        42.017011664483434,
        3.0099002789509224,
        42.017011664483434
      ]
    },
    "17154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.635012872774646,
              42.2651370630851
            ]
          },
          "bbox": [
            2.635012872774646,
            42.2651370630851,
            2.635012872774646,
            42.2651370630851
          ]
        }
      ],
      "bbox": [
        2.635012872774646,
        42.2651370630851,
        2.635012872774646,
        42.2651370630851
      ]
    },
    "17155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.78487076121654,
              41.973534702106555
            ]
          },
          "bbox": [
            2.78487076121654,
            41.973534702106555,
            2.78487076121654,
            41.973534702106555
          ]
        }
      ],
      "bbox": [
        2.78487076121654,
        41.973534702106555,
        2.78487076121654,
        41.973534702106555
      ]
    },
    "17157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.823247827814026,
              41.86970913365311
            ]
          },
          "bbox": [
            2.823247827814026,
            41.86970913365311,
            2.823247827814026,
            41.86970913365311
          ]
        }
      ],
      "bbox": [
        2.823247827814026,
        41.86970913365311,
        2.823247827814026,
        41.86970913365311
      ]
    },
    "17158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9665894389019773,
              42.38844315343933
            ]
          },
          "bbox": [
            2.9665894389019773,
            42.38844315343933,
            2.9665894389019773,
            42.38844315343933
          ]
        }
      ],
      "bbox": [
        2.9665894389019773,
        42.38844315343933,
        2.9665894389019773,
        42.38844315343933
      ]
    },
    "17159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.586887112917475,
              41.78137942859787
            ]
          },
          "bbox": [
            2.586887112917475,
            41.78137942859787,
            2.586887112917475,
            41.78137942859787
          ]
        }
      ],
      "bbox": [
        2.586887112917475,
        41.78137942859787,
        2.586887112917475,
        41.78137942859787
      ]
    },
    "17160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.011143055243667,
              41.78314908779198
            ]
          },
          "bbox": [
            3.011143055243667,
            41.78314908779198,
            3.011143055243667,
            41.78314908779198
          ]
        }
      ],
      "bbox": [
        3.011143055243667,
        41.78314908779198,
        3.011143055243667,
        41.78314908779198
      ]
    },
    "17161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5073349704353873,
              42.098762388089234
            ]
          },
          "bbox": [
            2.5073349704353873,
            42.098762388089234,
            2.5073349704353873,
            42.098762388089234
          ]
        }
      ],
      "bbox": [
        2.5073349704353873,
        42.098762388089234,
        2.5073349704353873,
        42.098762388089234
      ]
    },
    "17162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.664866179234837,
              42.18606987883147
            ]
          },
          "bbox": [
            2.664866179234837,
            42.18606987883147,
            2.664866179234837,
            42.18606987883147
          ]
        }
      ],
      "bbox": [
        2.664866179234837,
        42.18606987883147,
        2.664866179234837,
        42.18606987883147
      ]
    },
    "17163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7474708105167363,
              42.00105779026193
            ]
          },
          "bbox": [
            2.7474708105167363,
            42.00105779026193,
            2.7474708105167363,
            42.00105779026193
          ]
        }
      ],
      "bbox": [
        2.7474708105167363,
        42.00105779026193,
        2.7474708105167363,
        42.00105779026193
      ]
    },
    "17164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.500783530274399,
              41.90215015564214
            ]
          },
          "bbox": [
            2.500783530274399,
            41.90215015564214,
            2.500783530274399,
            41.90215015564214
          ]
        }
      ],
      "bbox": [
        2.500783530274399,
        41.90215015564214,
        2.500783530274399,
        41.90215015564214
      ]
    },
    "17165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5940049488387773,
              42.209820426294385
            ]
          },
          "bbox": [
            2.5940049488387773,
            42.209820426294385,
            2.5940049488387773,
            42.209820426294385
          ]
        }
      ],
      "bbox": [
        2.5940049488387773,
        42.209820426294385,
        2.5940049488387773,
        42.209820426294385
      ]
    },
    "17166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.953813401718925,
              42.07396733827577
            ]
          },
          "bbox": [
            2.953813401718925,
            42.07396733827577,
            2.953813401718925,
            42.07396733827577
          ]
        }
      ],
      "bbox": [
        2.953813401718925,
        42.07396733827577,
        2.953813401718925,
        42.07396733827577
      ]
    },
    "17167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3019113134358977,
              42.23388878046103
            ]
          },
          "bbox": [
            2.3019113134358977,
            42.23388878046103,
            2.3019113134358977,
            42.23388878046103
          ]
        }
      ],
      "bbox": [
        2.3019113134358977,
        42.23388878046103,
        2.3019113134358977,
        42.23388878046103
      ]
    },
    "17168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.938317563251406,
              42.04623660537194
            ]
          },
          "bbox": [
            2.938317563251406,
            42.04623660537194,
            2.938317563251406,
            42.04623660537194
          ]
        }
      ],
      "bbox": [
        2.938317563251406,
        42.04623660537194,
        2.938317563251406,
        42.04623660537194
      ]
    },
    "17169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.848722277361401,
              42.045368521353765
            ]
          },
          "bbox": [
            2.848722277361401,
            42.045368521353765,
            2.848722277361401,
            42.045368521353765
          ]
        }
      ],
      "bbox": [
        2.848722277361401,
        42.045368521353765,
        2.848722277361401,
        42.045368521353765
      ]
    },
    "17170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.305330097467618,
              42.18595180873856
            ]
          },
          "bbox": [
            2.305330097467618,
            42.18595180873856,
            2.305330097467618,
            42.18595180873856
          ]
        }
      ],
      "bbox": [
        2.305330097467618,
        42.18595180873856,
        2.305330097467618,
        42.18595180873856
      ]
    },
    "17171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7698605293113223,
              42.325853825640195
            ]
          },
          "bbox": [
            2.7698605293113223,
            42.325853825640195,
            2.7698605293113223,
            42.325853825640195
          ]
        }
      ],
      "bbox": [
        2.7698605293113223,
        42.325853825640195,
        2.7698605293113223,
        42.325853825640195
      ]
    },
    "17172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6710553766406924,
              42.03767495962743
            ]
          },
          "bbox": [
            2.6710553766406924,
            42.03767495962743,
            2.6710553766406924,
            42.03767495962743
          ]
        }
      ],
      "bbox": [
        2.6710553766406924,
        42.03767495962743,
        2.6710553766406924,
        42.03767495962743
      ]
    },
    "17173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.928053401278431,
              42.0054650499655
            ]
          },
          "bbox": [
            2.928053401278431,
            42.0054650499655,
            2.928053401278431,
            42.0054650499655
          ]
        }
      ],
      "bbox": [
        2.928053401278431,
        42.0054650499655,
        2.928053401278431,
        42.0054650499655
      ]
    },
    "17174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6778257343435024,
              42.12878075707619
            ]
          },
          "bbox": [
            2.6778257343435024,
            42.12878075707619,
            2.6778257343435024,
            42.12878075707619
          ]
        }
      ],
      "bbox": [
        2.6778257343435024,
        42.12878075707619,
        2.6778257343435024,
        42.12878075707619
      ]
    },
    "17175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.995537990637719,
              42.17647911601119
            ]
          },
          "bbox": [
            2.995537990637719,
            42.17647911601119,
            2.995537990637719,
            42.17647911601119
          ]
        }
      ],
      "bbox": [
        2.995537990637719,
        42.17647911601119,
        2.995537990637719,
        42.17647911601119
      ]
    },
    "17176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9884868730074134,
              42.150993475504734
            ]
          },
          "bbox": [
            2.9884868730074134,
            42.150993475504734,
            2.9884868730074134,
            42.150993475504734
          ]
        }
      ],
      "bbox": [
        2.9884868730074134,
        42.150993475504734,
        2.9884868730074134,
        42.150993475504734
      ]
    },
    "17177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3616120681713206,
              42.2653381495364
            ]
          },
          "bbox": [
            2.3616120681713206,
            42.2653381495364,
            2.3616120681713206,
            42.2653381495364
          ]
        }
      ],
      "bbox": [
        2.3616120681713206,
        42.2653381495364,
        2.3616120681713206,
        42.2653381495364
      ]
    },
    "17178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0921730420556544,
              42.18661465125894
            ]
          },
          "bbox": [
            3.0921730420556544,
            42.18661465125894,
            3.0921730420556544,
            42.18661465125894
          ]
        }
      ],
      "bbox": [
        3.0921730420556544,
        42.18661465125894,
        3.0921730420556544,
        42.18661465125894
      ]
    },
    "17180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6252450654593087,
              41.868741156020455
            ]
          },
          "bbox": [
            2.6252450654593087,
            41.868741156020455,
            2.6252450654593087,
            41.868741156020455
          ]
        }
      ],
      "bbox": [
        2.6252450654593087,
        41.868741156020455,
        2.6252450654593087,
        41.868741156020455
      ]
    },
    "17183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6021058837920616,
              42.07470126692961
            ]
          },
          "bbox": [
            2.6021058837920616,
            42.07470126692961,
            2.6021058837920616,
            42.07470126692961
          ]
        }
      ],
      "bbox": [
        2.6021058837920616,
        42.07470126692961,
        2.6021058837920616,
        42.07470126692961
      ]
    },
    "17184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5692452654589313,
              42.15118566798907
            ]
          },
          "bbox": [
            2.5692452654589313,
            42.15118566798907,
            2.5692452654589313,
            42.15118566798907
          ]
        }
      ],
      "bbox": [
        2.5692452654589313,
        42.15118566798907,
        2.5692452654589313,
        42.15118566798907
      ]
    },
    "17185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.535444087622882,
              42.20250743873572
            ]
          },
          "bbox": [
            2.535444087622882,
            42.20250743873572,
            2.535444087622882,
            42.20250743873572
          ]
        }
      ],
      "bbox": [
        2.535444087622882,
        42.20250743873572,
        2.535444087622882,
        42.20250743873572
      ]
    },
    "17186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8123133863258527,
              42.014008790947244
            ]
          },
          "bbox": [
            2.8123133863258527,
            42.014008790947244,
            2.8123133863258527,
            42.014008790947244
          ]
        }
      ],
      "bbox": [
        2.8123133863258527,
        42.014008790947244,
        2.8123133863258527,
        42.014008790947244
      ]
    },
    "17187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9615169308635267,
              42.12580644106589
            ]
          },
          "bbox": [
            2.9615169308635267,
            42.12580644106589,
            2.9615169308635267,
            42.12580644106589
          ]
        }
      ],
      "bbox": [
        2.9615169308635267,
        42.12580644106589,
        2.9615169308635267,
        42.12580644106589
      ]
    },
    "17188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1871415489463573,
              42.316717431662134
            ]
          },
          "bbox": [
            3.1871415489463573,
            42.316717431662134,
            3.1871415489463573,
            42.316717431662134
          ]
        }
      ],
      "bbox": [
        3.1871415489463573,
        42.316717431662134,
        3.1871415489463573,
        42.316717431662134
      ]
    },
    "17189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6001047941804316,
              41.97124524759363
            ]
          },
          "bbox": [
            2.6001047941804316,
            41.97124524759363,
            2.6001047941804316,
            41.97124524759363
          ]
        }
      ],
      "bbox": [
        2.6001047941804316,
        41.97124524759363,
        2.6001047941804316,
        41.97124524759363
      ]
    },
    "17190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.738053450276439,
              42.167530499344785
            ]
          },
          "bbox": [
            2.738053450276439,
            42.167530499344785,
            2.738053450276439,
            42.167530499344785
          ]
        }
      ],
      "bbox": [
        2.738053450276439,
        42.167530499344785,
        2.738053450276439,
        42.167530499344785
      ]
    },
    "17191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.065539084759043,
              42.03091759327073
            ]
          },
          "bbox": [
            3.065539084759043,
            42.03091759327073,
            3.065539084759043,
            42.03091759327073
          ]
        }
      ],
      "bbox": [
        3.065539084759043,
        42.03091759327073,
        3.065539084759043,
        42.03091759327073
      ]
    },
    "17192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.294524257444345,
              42.4000275479942
            ]
          },
          "bbox": [
            2.294524257444345,
            42.4000275479942,
            2.294524257444345,
            42.4000275479942
          ]
        }
      ],
      "bbox": [
        2.294524257444345,
        42.4000275479942,
        2.294524257444345,
        42.4000275479942
      ]
    },
    "17193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.739347116933075,
              41.82767491885738
            ]
          },
          "bbox": [
            2.739347116933075,
            41.82767491885738,
            2.739347116933075,
            41.82767491885738
          ]
        }
      ],
      "bbox": [
        2.739347116933075,
        41.82767491885738,
        2.739347116933075,
        41.82767491885738
      ]
    },
    "17194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.518762356207137,
              41.995383564765426
            ]
          },
          "bbox": [
            2.518762356207137,
            41.995383564765426,
            2.518762356207137,
            41.995383564765426
          ]
        }
      ],
      "bbox": [
        2.518762356207137,
        41.995383564765426,
        2.518762356207137,
        41.995383564765426
      ]
    },
    "17196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8428726847929418,
              42.311623338559215
            ]
          },
          "bbox": [
            2.8428726847929418,
            42.311623338559215,
            2.8428726847929418,
            42.311623338559215
          ]
        }
      ],
      "bbox": [
        2.8428726847929418,
        42.311623338559215,
        2.8428726847929418,
        42.311623338559215
      ]
    },
    "17197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1292529060909136,
              41.95151580016227
            ]
          },
          "bbox": [
            3.1292529060909136,
            41.95151580016227,
            3.1292529060909136,
            41.95151580016227
          ]
        }
      ],
      "bbox": [
        3.1292529060909136,
        41.95151580016227,
        3.1292529060909136,
        41.95151580016227
      ]
    },
    "17198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0305462620228454,
              42.18631526694427
            ]
          },
          "bbox": [
            3.0305462620228454,
            42.18631526694427,
            3.0305462620228454,
            42.18631526694427
          ]
        }
      ],
      "bbox": [
        3.0305462620228454,
        42.18631526694427,
        3.0305462620228454,
        42.18631526694427
      ]
    },
    "17199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1608543861336185,
              42.0571102992404
            ]
          },
          "bbox": [
            3.1608543861336185,
            42.0571102992404,
            3.1608543861336185,
            42.0571102992404
          ]
        }
      ],
      "bbox": [
        3.1608543861336185,
        42.0571102992404,
        3.1608543861336185,
        42.0571102992404
      ]
    },
    "17200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.620999383515818,
              42.24350105530555
            ]
          },
          "bbox": [
            2.620999383515818,
            42.24350105530555,
            2.620999383515818,
            42.24350105530555
          ]
        }
      ],
      "bbox": [
        2.620999383515818,
        42.24350105530555,
        2.620999383515818,
        42.24350105530555
      ]
    },
    "17201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.035793401570564,
              42.325868812990116
            ]
          },
          "bbox": [
            2.035793401570564,
            42.325868812990116,
            2.035793401570564,
            42.325868812990116
          ]
        }
      ],
      "bbox": [
        2.035793401570564,
        42.325868812990116,
        2.035793401570564,
        42.325868812990116
      ]
    },
    "17202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9152277244879428,
              41.741630716192006
            ]
          },
          "bbox": [
            2.9152277244879428,
            41.741630716192006,
            2.9152277244879428,
            41.741630716192006
          ]
        }
      ],
      "bbox": [
        2.9152277244879428,
        41.741630716192006,
        2.9152277244879428,
        41.741630716192006
      ]
    },
    "17203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0371956353042875,
              42.0397880053414
            ]
          },
          "bbox": [
            3.0371956353042875,
            42.0397880053414,
            3.0371956353042875,
            42.0397880053414
          ]
        }
      ],
      "bbox": [
        3.0371956353042875,
        42.0397880053414,
        3.0371956353042875,
        42.0397880053414
      ]
    },
    "17204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1040209252635544,
              42.050982183992446
            ]
          },
          "bbox": [
            3.1040209252635544,
            42.050982183992446,
            3.1040209252635544,
            42.050982183992446
          ]
        }
      ],
      "bbox": [
        3.1040209252635544,
        42.050982183992446,
        3.1040209252635544,
        42.050982183992446
      ]
    },
    "17205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0712011754045005,
              41.99965874248977
            ]
          },
          "bbox": [
            3.0712011754045005,
            41.99965874248977,
            3.0712011754045005,
            41.99965874248977
          ]
        }
      ],
      "bbox": [
        3.0712011754045005,
        41.99965874248977,
        3.0712011754045005,
        41.99965874248977
      ]
    },
    "17206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8590702211721335,
              42.33066028971634
            ]
          },
          "bbox": [
            1.8590702211721335,
            42.33066028971634,
            1.8590702211721335,
            42.33066028971634
          ]
        }
      ],
      "bbox": [
        1.8590702211721335,
        42.33066028971634,
        1.8590702211721335,
        42.33066028971634
      ]
    },
    "17208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.433579135975417,
              42.237689918923095
            ]
          },
          "bbox": [
            2.433579135975417,
            42.237689918923095,
            2.433579135975417,
            42.237689918923095
          ]
        }
      ],
      "bbox": [
        2.433579135975417,
        42.237689918923095,
        2.433579135975417,
        42.237689918923095
      ]
    },
    "17209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1242640723494475,
              41.886089647756215
            ]
          },
          "bbox": [
            3.1242640723494475,
            41.886089647756215,
            3.1242640723494475,
            41.886089647756215
          ]
        }
      ],
      "bbox": [
        3.1242640723494475,
        41.886089647756215,
        3.1242640723494475,
        41.886089647756215
      ]
    },
    "17210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0415803710176954,
              42.15111784756242
            ]
          },
          "bbox": [
            3.0415803710176954,
            42.15111784756242,
            3.0415803710176954,
            42.15111784756242
          ]
        }
      ],
      "bbox": [
        3.0415803710176954,
        42.15111784756242,
        3.0415803710176954,
        42.15111784756242
      ]
    },
    "17211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.042916251089708,
              42.068146850594964
            ]
          },
          "bbox": [
            3.042916251089708,
            42.068146850594964,
            3.042916251089708,
            42.068146850594964
          ]
        }
      ],
      "bbox": [
        3.042916251089708,
        42.068146850594964,
        3.042916251089708,
        42.068146850594964
      ]
    },
    "17212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.327481868168066,
              42.14219132408579
            ]
          },
          "bbox": [
            2.327481868168066,
            42.14219132408579,
            2.327481868168066,
            42.14219132408579
          ]
        }
      ],
      "bbox": [
        2.327481868168066,
        42.14219132408579,
        2.327481868168066,
        42.14219132408579
      ]
    },
    "17213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8119347144901337,
              41.77444658233192
            ]
          },
          "bbox": [
            2.8119347144901337,
            41.77444658233192,
            2.8119347144901337,
            41.77444658233192
          ]
        }
      ],
      "bbox": [
        2.8119347144901337,
        41.77444658233192,
        2.8119347144901337,
        41.77444658233192
      ]
    },
    "17214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9811880270146713,
              42.28336808616067
            ]
          },
          "bbox": [
            2.9811880270146713,
            42.28336808616067,
            2.9811880270146713,
            42.28336808616067
          ]
        }
      ],
      "bbox": [
        2.9811880270146713,
        42.28336808616067,
        2.9811880270146713,
        42.28336808616067
      ]
    },
    "17215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.781536770372205,
              41.95334335566547
            ]
          },
          "bbox": [
            2.781536770372205,
            41.95334335566547,
            2.781536770372205,
            41.95334335566547
          ]
        }
      ],
      "bbox": [
        2.781536770372205,
        41.95334335566547,
        2.781536770372205,
        41.95334335566547
      ]
    },
    "17216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.924127309306508,
              42.09706327716337
            ]
          },
          "bbox": [
            2.924127309306508,
            42.09706327716337,
            2.924127309306508,
            42.09706327716337
          ]
        }
      ],
      "bbox": [
        2.924127309306508,
        42.09706327716337,
        2.924127309306508,
        42.09706327716337
      ]
    },
    "17217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.06314950927046,
              42.12768461037106
            ]
          },
          "bbox": [
            3.06314950927046,
            42.12768461037106,
            3.06314950927046,
            42.12768461037106
          ]
        }
      ],
      "bbox": [
        3.06314950927046,
        42.12768461037106,
        3.06314950927046,
        42.12768461037106
      ]
    },
    "17218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8647934028362143,
              42.134272228722246
            ]
          },
          "bbox": [
            2.8647934028362143,
            42.134272228722246,
            2.8647934028362143,
            42.134272228722246
          ]
        }
      ],
      "bbox": [
        2.8647934028362143,
        42.134272228722246,
        2.8647934028362143,
        42.134272228722246
      ]
    },
    "17220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.372578199151739,
              41.84442471009477
            ]
          },
          "bbox": [
            2.372578199151739,
            41.84442471009477,
            2.372578199151739,
            41.84442471009477
          ]
        }
      ],
      "bbox": [
        2.372578199151739,
        41.84442471009477,
        2.372578199151739,
        41.84442471009477
      ]
    },
    "17221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9451709865681766,
              42.2497058450639
            ]
          },
          "bbox": [
            2.9451709865681766,
            42.2497058450639,
            2.9451709865681766,
            42.2497058450639
          ]
        }
      ],
      "bbox": [
        2.9451709865681766,
        42.2497058450639,
        2.9451709865681766,
        42.2497058450639
      ]
    },
    "17222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.956203299087616,
              42.13872036186207
            ]
          },
          "bbox": [
            2.956203299087616,
            42.13872036186207,
            2.956203299087616,
            42.13872036186207
          ]
        }
      ],
      "bbox": [
        2.956203299087616,
        42.13872036186207,
        2.956203299087616,
        42.13872036186207
      ]
    },
    "17223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.102934285275191,
              42.33273056039011
            ]
          },
          "bbox": [
            3.102934285275191,
            42.33273056039011,
            3.102934285275191,
            42.33273056039011
          ]
        }
      ],
      "bbox": [
        3.102934285275191,
        42.33273056039011,
        3.102934285275191,
        42.33273056039011
      ]
    },
    "17224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2786239564699744,
              42.34175795074313
            ]
          },
          "bbox": [
            2.2786239564699744,
            42.34175795074313,
            2.2786239564699744,
            42.34175795074313
          ]
        }
      ],
      "bbox": [
        2.2786239564699744,
        42.34175795074313,
        2.2786239564699744,
        42.34175795074313
      ]
    },
    "17225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0566433744959127,
              42.19926193219358
            ]
          },
          "bbox": [
            3.0566433744959127,
            42.19926193219358,
            3.0566433744959127,
            42.19926193219358
          ]
        }
      ],
      "bbox": [
        3.0566433744959127,
        42.19926193219358,
        3.0566433744959127,
        42.19926193219358
      ]
    },
    "17226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9729629398522124,
              42.22200705937814
            ]
          },
          "bbox": [
            2.9729629398522124,
            42.22200705937814,
            2.9729629398522124,
            42.22200705937814
          ]
        }
      ],
      "bbox": [
        2.9729629398522124,
        42.22200705937814,
        2.9729629398522124,
        42.22200705937814
      ]
    },
    "17227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.066804731381611,
              42.37611998229485
            ]
          },
          "bbox": [
            3.066804731381611,
            42.37611998229485,
            3.066804731381611,
            42.37611998229485
          ]
        }
      ],
      "bbox": [
        3.066804731381611,
        42.37611998229485,
        3.066804731381611,
        42.37611998229485
      ]
    },
    "17228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8799912856314185,
              42.25685797721798
            ]
          },
          "bbox": [
            2.8799912856314185,
            42.25685797721798,
            2.8799912856314185,
            42.25685797721798
          ]
        }
      ],
      "bbox": [
        2.8799912856314185,
        42.25685797721798,
        2.8799912856314185,
        42.25685797721798
      ]
    },
    "17230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0212413868533305,
              42.26217100196679
            ]
          },
          "bbox": [
            3.0212413868533305,
            42.26217100196679,
            3.0212413868533305,
            42.26217100196679
          ]
        }
      ],
      "bbox": [
        3.0212413868533305,
        42.26217100196679,
        3.0212413868533305,
        42.26217100196679
      ]
    },
    "17232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.987546090659415,
              42.10932046113995
            ]
          },
          "bbox": [
            2.987546090659415,
            42.10932046113995,
            2.987546090659415,
            42.10932046113995
          ]
        }
      ],
      "bbox": [
        2.987546090659415,
        42.10932046113995,
        2.987546090659415,
        42.10932046113995
      ]
    },
    "17234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9013044988617427,
              42.33971056455183
            ]
          },
          "bbox": [
            2.9013044988617427,
            42.33971056455183,
            2.9013044988617427,
            42.33971056455183
          ]
        }
      ],
      "bbox": [
        2.9013044988617427,
        42.33971056455183,
        2.9013044988617427,
        42.33971056455183
      ]
    },
    "17902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0856993474950394,
              41.93880437906036
            ]
          },
          "bbox": [
            3.0856993474950394,
            41.93880437906036,
            3.0856993474950394,
            41.93880437906036
          ]
        }
      ],
      "bbox": [
        3.0856993474950394,
        41.93880437906036,
        3.0856993474950394,
        41.93880437906036
      ]
    },
    "17903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.650455200108505,
              41.97539456716594
            ]
          },
          "bbox": [
            2.650455200108505,
            41.97539456716594,
            2.650455200108505,
            41.97539456716594
          ]
        }
      ],
      "bbox": [
        2.650455200108505,
        41.97539456716594,
        2.650455200108505,
        41.97539456716594
      ]
    },
    "18001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8205071595143174,
              37.013616033374504
            ]
          },
          "bbox": [
            -3.8205071595143174,
            37.013616033374504,
            -3.8205071595143174,
            37.013616033374504
          ]
        }
      ],
      "bbox": [
        -3.8205071595143174,
        37.013616033374504,
        -3.8205071595143174,
        37.013616033374504
      ]
    },
    "18002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2425449220893983,
              37.58815679782506
            ]
          },
          "bbox": [
            -3.2425449220893983,
            37.58815679782506,
            -3.2425449220893983,
            37.58815679782506
          ]
        }
      ],
      "bbox": [
        -3.2425449220893983,
        37.58815679782506,
        -3.2425449220893983,
        37.58815679782506
      ]
    },
    "18003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.659066415177947,
              37.30498913824425
            ]
          },
          "bbox": [
            -3.659066415177947,
            37.30498913824425,
            -3.659066415177947,
            37.30498913824425
          ]
        }
      ],
      "bbox": [
        -3.659066415177947,
        37.30498913824425,
        -3.659066415177947,
        37.30498913824425
      ]
    },
    "18004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2072158312727863,
              36.84287598211708
            ]
          },
          "bbox": [
            -3.2072158312727863,
            36.84287598211708,
            -3.2072158312727863,
            36.84287598211708
          ]
        }
      ],
      "bbox": [
        -3.2072158312727863,
        36.84287598211708,
        -3.2072158312727863,
        36.84287598211708
      ]
    },
    "18005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1404201394973885,
              37.23300326528767
            ]
          },
          "bbox": [
            -3.1404201394973885,
            37.23300326528767,
            -3.1404201394973885,
            37.23300326528767
          ]
        }
      ],
      "bbox": [
        -3.1404201394973885,
        37.23300326528767,
        -3.1404201394973885,
        37.23300326528767
      ]
    },
    "18006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.187894895711729,
              36.78062686096039
            ]
          },
          "bbox": [
            -3.187894895711729,
            36.78062686096039,
            -3.187894895711729,
            36.78062686096039
          ]
        }
      ],
      "bbox": [
        -3.187894895711729,
        36.78062686096039,
        -3.187894895711729,
        36.78062686096039
      ]
    },
    "18007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6881587422776003,
              36.927971585042556
            ]
          },
          "bbox": [
            -3.6881587422776003,
            36.927971585042556,
            -3.6881587422776003,
            36.927971585042556
          ]
        }
      ],
      "bbox": [
        -3.6881587422776003,
        36.927971585042556,
        -3.6881587422776003,
        36.927971585042556
      ]
    },
    "18010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.07695405023174,
              37.159373681663745
            ]
          },
          "bbox": [
            -3.07695405023174,
            37.159373681663745,
            -3.07695405023174,
            37.159373681663745
          ]
        }
      ],
      "bbox": [
        -3.07695405023174,
        37.159373681663745,
        -3.07695405023174,
        37.159373681663745
      ]
    },
    "18011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5550514199303667,
              37.247367644997034
            ]
          },
          "bbox": [
            -3.5550514199303667,
            37.247367644997034,
            -3.5550514199303667,
            37.247367644997034
          ]
        }
      ],
      "bbox": [
        -3.5550514199303667,
        37.247367644997034,
        -3.5550514199303667,
        37.247367644997034
      ]
    },
    "18012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.190371133684479,
              37.300055780783836
            ]
          },
          "bbox": [
            -4.190371133684479,
            37.300055780783836,
            -4.190371133684479,
            37.300055780783836
          ]
        }
      ],
      "bbox": [
        -4.190371133684479,
        37.300055780783836,
        -4.190371133684479,
        37.300055780783836
      ]
    },
    "18013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9977578743096847,
              36.999823472501156
            ]
          },
          "bbox": [
            -3.9977578743096847,
            36.999823472501156,
            -3.9977578743096847,
            36.999823472501156
          ]
        }
      ],
      "bbox": [
        -3.9977578743096847,
        36.999823472501156,
        -3.9977578743096847,
        36.999823472501156
      ]
    },
    "18014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.701054586367778,
              37.066213532443385
            ]
          },
          "bbox": [
            -3.701054586367778,
            37.066213532443385,
            -3.701054586367778,
            37.066213532443385
          ]
        }
      ],
      "bbox": [
        -3.701054586367778,
        37.066213532443385,
        -3.701054586367778,
        37.066213532443385
      ]
    },
    "18015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1611529609554765,
              37.60875143005541
            ]
          },
          "bbox": [
            -3.1611529609554765,
            37.60875143005541,
            -3.1611529609554765,
            37.60875143005541
          ]
        }
      ],
      "bbox": [
        -3.1611529609554765,
        37.60875143005541,
        -3.1611529609554765,
        37.60875143005541
      ]
    },
    "18016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2847293021953687,
              36.90256014810519
            ]
          },
          "bbox": [
            -3.2847293021953687,
            36.90256014810519,
            -3.2847293021953687,
            36.90256014810519
          ]
        }
      ],
      "bbox": [
        -3.2847293021953687,
        36.90256014810519,
        -3.2847293021953687,
        36.90256014810519
      ]
    },
    "18017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.724597025950328,
              36.76864399247235
            ]
          },
          "bbox": [
            -3.724597025950328,
            36.76864399247235,
            -3.724597025950328,
            36.76864399247235
          ]
        }
      ],
      "bbox": [
        -3.724597025950328,
        36.76864399247235,
        -3.724597025950328,
        36.76864399247235
      ]
    },
    "18018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.109759400804887,
              37.18614717953681
            ]
          },
          "bbox": [
            -3.109759400804887,
            37.18614717953681,
            -3.109759400804887,
            37.18614717953681
          ]
        }
      ],
      "bbox": [
        -3.109759400804887,
        37.18614717953681,
        -3.109759400804887,
        37.18614717953681
      ]
    },
    "18020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.875578408053783,
              36.949615246867594
            ]
          },
          "bbox": [
            -3.875578408053783,
            36.949615246867594,
            -3.875578408053783,
            36.949615246867594
          ]
        }
      ],
      "bbox": [
        -3.875578408053783,
        36.949615246867594,
        -3.875578408053783,
        36.949615246867594
      ]
    },
    "18021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.61938110613408,
              37.14804091467496
            ]
          },
          "bbox": [
            -3.61938110613408,
            37.14804091467496,
            -3.61938110613408,
            37.14804091467496
          ]
        }
      ],
      "bbox": [
        -3.61938110613408,
        37.14804091467496,
        -3.61938110613408,
        37.14804091467496
      ]
    },
    "18022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.705313438306185,
              37.25687684281504
            ]
          },
          "bbox": [
            -3.705313438306185,
            37.25687684281504,
            -3.705313438306185,
            37.25687684281504
          ]
        }
      ],
      "bbox": [
        -3.705313438306185,
        37.25687684281504,
        -3.705313438306185,
        37.25687684281504
      ]
    },
    "18023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7734029245413527,
              37.41427161152822
            ]
          },
          "bbox": [
            -2.7734029245413527,
            37.41427161152822,
            -2.7734029245413527,
            37.41427161152822
          ]
        }
      ],
      "bbox": [
        -2.7734029245413527,
        37.41427161152822,
        -2.7734029245413527,
        37.41427161152822
      ]
    },
    "18024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4646890850215843,
              37.236820517692905
            ]
          },
          "bbox": [
            -3.4646890850215843,
            37.236820517692905,
            -3.4646890850215843,
            37.236820517692905
          ]
        }
      ],
      "bbox": [
        -3.4646890850215843,
        37.236820517692905,
        -3.4646890850215843,
        37.236820517692905
      ]
    },
    "18025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.213175857082375,
              37.27612726983584
            ]
          },
          "bbox": [
            -3.213175857082375,
            37.27612726983584,
            -3.213175857082375,
            37.27612726983584
          ]
        }
      ],
      "bbox": [
        -3.213175857082375,
        37.27612726983584,
        -3.213175857082375,
        37.27612726983584
      ]
    },
    "18027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.164885631025756,
              37.35626129325317
            ]
          },
          "bbox": [
            -3.164885631025756,
            37.35626129325317,
            -3.164885631025756,
            37.35626129325317
          ]
        }
      ],
      "bbox": [
        -3.164885631025756,
        37.35626129325317,
        -3.164885631025756,
        37.35626129325317
      ]
    },
    "18028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6707381127880563,
              37.44297543824457
            ]
          },
          "bbox": [
            -3.6707381127880563,
            37.44297543824457,
            -3.6707381127880563,
            37.44297543824457
          ]
        }
      ],
      "bbox": [
        -3.6707381127880563,
        37.44297543824457,
        -3.6707381127880563,
        37.44297543824457
      ]
    },
    "18029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.697990664900245,
              37.62883028190329
            ]
          },
          "bbox": [
            -2.697990664900245,
            37.62883028190329,
            -2.697990664900245,
            37.62883028190329
          ]
        }
      ],
      "bbox": [
        -2.697990664900245,
        37.62883028190329,
        -2.697990664900245,
        37.62883028190329
      ]
    },
    "18030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1964639952940743,
              37.025569217394256
            ]
          },
          "bbox": [
            -3.1964639952940743,
            37.025569217394256,
            -3.1964639952940743,
            37.025569217394256
          ]
        }
      ],
      "bbox": [
        -3.1964639952940743,
        37.025569217394256,
        -3.1964639952940743,
        37.025569217394256
      ]
    },
    "18032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3711280794339418,
              36.98895905640564
            ]
          },
          "bbox": [
            -3.3711280794339418,
            36.98895905640564,
            -3.3711280794339418,
            36.98895905640564
          ]
        }
      ],
      "bbox": [
        -3.3711280794339418,
        36.98895905640564,
        -3.3711280794339418,
        36.98895905640564
      ]
    },
    "18033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2788812698440237,
              36.95788156625362
            ]
          },
          "bbox": [
            -3.2788812698440237,
            36.95788156625362,
            -3.2788812698440237,
            36.95788156625362
          ]
        }
      ],
      "bbox": [
        -3.2788812698440237,
        36.95788156625362,
        -3.2788812698440237,
        36.95788156625362
      ]
    },
    "18034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.914681354259351,
              37.07603601209675
            ]
          },
          "bbox": [
            -3.914681354259351,
            37.07603601209675,
            -3.914681354259351,
            37.07603601209675
          ]
        }
      ],
      "bbox": [
        -3.914681354259351,
        37.07603601209675,
        -3.914681354259351,
        37.07603601209675
      ]
    },
    "18035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.165819289788771,
              36.92792384022979
            ]
          },
          "bbox": [
            -3.165819289788771,
            36.92792384022979,
            -3.165819289788771,
            36.92792384022979
          ]
        }
      ],
      "bbox": [
        -3.165819289788771,
        36.92792384022979,
        -3.165819289788771,
        36.92792384022979
      ]
    },
    "18036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5706333600038764,
              37.131765789712595
            ]
          },
          "bbox": [
            -3.5706333600038764,
            37.131765789712595,
            -3.5706333600038764,
            37.131765789712595
          ]
        }
      ],
      "bbox": [
        -3.5706333600038764,
        37.131765789712595,
        -3.5706333600038764,
        37.131765789712595
      ]
    },
    "18037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.627923306723665,
              37.27998294434031
            ]
          },
          "bbox": [
            -3.627923306723665,
            37.27998294434031,
            -3.627923306723665,
            37.27998294434031
          ]
        }
      ],
      "bbox": [
        -3.627923306723665,
        37.27998294434031,
        -3.627923306723665,
        37.27998294434031
      ]
    },
    "18038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5993770624042507,
              37.5055976692643
            ]
          },
          "bbox": [
            -3.5993770624042507,
            37.5055976692643,
            -3.5993770624042507,
            37.5055976692643
          ]
        }
      ],
      "bbox": [
        -3.5993770624042507,
        37.5055976692643,
        -3.5993770624042507,
        37.5055976692643
      ]
    },
    "18039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6815027324881244,
              37.41595895526904
            ]
          },
          "bbox": [
            -2.6815027324881244,
            37.41595895526904,
            -2.6815027324881244,
            37.41595895526904
          ]
        }
      ],
      "bbox": [
        -2.6815027324881244,
        37.41595895526904,
        -2.6815027324881244,
        37.41595895526904
      ]
    },
    "18040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.425229006286349,
              36.9601247943847
            ]
          },
          "bbox": [
            -3.425229006286349,
            36.9601247943847,
            -3.425229006286349,
            36.9601247943847
          ]
        }
      ],
      "bbox": [
        -3.425229006286349,
        36.9601247943847,
        -3.425229006286349,
        36.9601247943847
      ]
    },
    "18042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3442333175116086,
              37.013330616253484
            ]
          },
          "bbox": [
            -3.3442333175116086,
            37.013330616253484,
            -3.3442333175116086,
            37.013330616253484
          ]
        }
      ],
      "bbox": [
        -3.3442333175116086,
        37.013330616253484,
        -3.3442333175116086,
        37.013330616253484
      ]
    },
    "18043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3941663733470313,
              36.91852407218985
            ]
          },
          "bbox": [
            -3.3941663733470313,
            36.91852407218985,
            -3.3941663733470313,
            36.91852407218985
          ]
        }
      ],
      "bbox": [
        -3.3941663733470313,
        36.91852407218985,
        -3.3941663733470313,
        36.91852407218985
      ]
    },
    "18044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2409771177337716,
              36.915322696575465
            ]
          },
          "bbox": [
            -3.2409771177337716,
            36.915322696575465,
            -3.2409771177337716,
            36.915322696575465
          ]
        }
      ],
      "bbox": [
        -3.2409771177337716,
        36.915322696575465,
        -3.2409771177337716,
        36.915322696575465
      ]
    },
    "18045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.678195464515373,
              37.73594043515476
            ]
          },
          "bbox": [
            -2.678195464515373,
            37.73594043515476,
            -2.678195464515373,
            37.73594043515476
          ]
        }
      ],
      "bbox": [
        -2.678195464515373,
        37.73594043515476,
        -2.678195464515373,
        37.73594043515476
      ]
    },
    "18046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.774270938417811,
              37.82698022351807
            ]
          },
          "bbox": [
            -2.774270938417811,
            37.82698022351807,
            -2.774270938417811,
            37.82698022351807
          ]
        }
      ],
      "bbox": [
        -2.774270938417811,
        37.82698022351807,
        -2.774270938417811,
        37.82698022351807
      ]
    },
    "18047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.537411914889645,
              37.15546135951609
            ]
          },
          "bbox": [
            -3.537411914889645,
            37.15546135951609,
            -3.537411914889645,
            37.15546135951609
          ]
        }
      ],
      "bbox": [
        -3.537411914889645,
        37.15546135951609,
        -3.537411914889645,
        37.15546135951609
      ]
    },
    "18048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8260050979363776,
              37.170117889444136
            ]
          },
          "bbox": [
            -3.8260050979363776,
            37.170117889444136,
            -3.8260050979363776,
            37.170117889444136
          ]
        }
      ],
      "bbox": [
        -3.8260050979363776,
        37.170117889444136,
        -3.8260050979363776,
        37.170117889444136
      ]
    },
    "18049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1766583607759302,
              37.230425017901815
            ]
          },
          "bbox": [
            -3.1766583607759302,
            37.230425017901815,
            -3.1766583607759302,
            37.230425017901815
          ]
        }
      ],
      "bbox": [
        -3.1766583607759302,
        37.230425017901815,
        -3.1766583607759302,
        37.230425017901815
      ]
    },
    "18050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5588628014406427,
              37.298046430920095
            ]
          },
          "bbox": [
            -3.5588628014406427,
            37.298046430920095,
            -3.5588628014406427,
            37.298046430920095
          ]
        }
      ],
      "bbox": [
        -3.5588628014406427,
        37.298046430920095,
        -3.5588628014406427,
        37.298046430920095
      ]
    },
    "18051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7128454982328845,
              37.38558745308138
            ]
          },
          "bbox": [
            -3.7128454982328845,
            37.38558745308138,
            -3.7128454982328845,
            37.38558745308138
          ]
        }
      ],
      "bbox": [
        -3.7128454982328845,
        37.38558745308138,
        -3.7128454982328845,
        37.38558745308138
      ]
    },
    "18053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8182721741363492,
              37.689050604630715
            ]
          },
          "bbox": [
            -2.8182721741363492,
            37.689050604630715,
            -2.8182721741363492,
            37.689050604630715
          ]
        }
      ],
      "bbox": [
        -2.8182721741363492,
        37.689050604630715,
        -2.8182721741363492,
        37.689050604630715
      ]
    },
    "18054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2354552581140927,
              37.301931162441896
            ]
          },
          "bbox": [
            -3.2354552581140927,
            37.301931162441896,
            -3.2354552581140927,
            37.301931162441896
          ]
        }
      ],
      "bbox": [
        -3.2354552581140927,
        37.301931162441896,
        -3.2354552581140927,
        37.301931162441896
      ]
    },
    "18056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5198049971868577,
              37.56795503967993
            ]
          },
          "bbox": [
            -2.5198049971868577,
            37.56795503967993,
            -2.5198049971868577,
            37.56795503967993
          ]
        }
      ],
      "bbox": [
        -2.5198049971868577,
        37.56795503967993,
        -2.5198049971868577,
        37.56795503967993
      ]
    },
    "18057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6784697921447815,
              37.158598267298586
            ]
          },
          "bbox": [
            -3.6784697921447815,
            37.158598267298586,
            -3.6784697921447815,
            37.158598267298586
          ]
        }
      ],
      "bbox": [
        -3.6784697921447815,
        37.158598267298586,
        -3.6784697921447815,
        37.158598267298586
      ]
    },
    "18059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7871480549308405,
              37.17821006547449
            ]
          },
          "bbox": [
            -3.7871480549308405,
            37.17821006547449,
            -3.7871480549308405,
            37.17821006547449
          ]
        }
      ],
      "bbox": [
        -3.7871480549308405,
        37.17821006547449,
        -3.7871480549308405,
        37.17821006547449
      ]
    },
    "18061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8444000247384436,
              37.12203021447413
            ]
          },
          "bbox": [
            -3.8444000247384436,
            37.12203021447413,
            -3.8444000247384436,
            37.12203021447413
          ]
        }
      ],
      "bbox": [
        -3.8444000247384436,
        37.12203021447413,
        -3.8444000247384436,
        37.12203021447413
      ]
    },
    "18062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6422294777619966,
              37.14950157337608
            ]
          },
          "bbox": [
            -3.6422294777619966,
            37.14950157337608,
            -3.6422294777619966,
            37.14950157337608
          ]
        }
      ],
      "bbox": [
        -3.6422294777619966,
        37.14950157337608,
        -3.6422294777619966,
        37.14950157337608
      ]
    },
    "18063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.285077992362189,
              37.36510907774065
            ]
          },
          "bbox": [
            -3.285077992362189,
            37.36510907774065,
            -3.285077992362189,
            37.36510907774065
          ]
        }
      ],
      "bbox": [
        -3.285077992362189,
        37.36510907774065,
        -3.285077992362189,
        37.36510907774065
      ]
    },
    "18064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0780599243688562,
              37.594154026572575
            ]
          },
          "bbox": [
            -3.0780599243688562,
            37.594154026572575,
            -3.0780599243688562,
            37.594154026572575
          ]
        }
      ],
      "bbox": [
        -3.0780599243688562,
        37.594154026572575,
        -3.0780599243688562,
        37.594154026572575
      ]
    },
    "18065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5598875197260265,
              37.485323580208465
            ]
          },
          "bbox": [
            -3.5598875197260265,
            37.485323580208465,
            -3.5598875197260265,
            37.485323580208465
          ]
        }
      ],
      "bbox": [
        -3.5598875197260265,
        37.485323580208465,
        -3.5598875197260265,
        37.485323580208465
      ]
    },
    "18066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5944875604813387,
              37.34519209317769
            ]
          },
          "bbox": [
            -3.5944875604813387,
            37.34519209317769,
            -3.5944875604813387,
            37.34519209317769
          ]
        }
      ],
      "bbox": [
        -3.5944875604813387,
        37.34519209317769,
        -3.5944875604813387,
        37.34519209317769
      ]
    },
    "18067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.352086451116292,
              37.32468217461296
            ]
          },
          "bbox": [
            -3.352086451116292,
            37.32468217461296,
            -3.352086451116292,
            37.32468217461296
          ]
        }
      ],
      "bbox": [
        -3.352086451116292,
        37.32468217461296,
        -3.352086451116292,
        37.32468217461296
      ]
    },
    "18068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.518583910102237,
              37.06206753829138
            ]
          },
          "bbox": [
            -3.518583910102237,
            37.06206753829138,
            -3.518583910102237,
            37.06206753829138
          ]
        }
      ],
      "bbox": [
        -3.518583910102237,
        37.06206753829138,
        -3.518583910102237,
        37.06206753829138
      ]
    },
    "18069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.955425775320026,
              37.21335163447359
            ]
          },
          "bbox": [
            -2.955425775320026,
            37.21335163447359,
            -2.955425775320026,
            37.21335163447359
          ]
        }
      ],
      "bbox": [
        -2.955425775320026,
        37.21335163447359,
        -2.955425775320026,
        37.21335163447359
      ]
    },
    "18070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4901938549151166,
              37.186006857371765
            ]
          },
          "bbox": [
            -3.4901938549151166,
            37.186006857371765,
            -3.4901938549151166,
            37.186006857371765
          ]
        }
      ],
      "bbox": [
        -3.4901938549151166,
        37.186006857371765,
        -3.4901938549151166,
        37.186006857371765
      ]
    },
    "18071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.513273002982341,
              37.02221463444481
            ]
          },
          "bbox": [
            -3.513273002982341,
            37.02221463444481,
            -3.513273002982341,
            37.02221463444481
          ]
        }
      ],
      "bbox": [
        -3.513273002982341,
        37.02221463444481,
        -3.513273002982341,
        37.02221463444481
      ]
    },
    "18072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.776369206138271,
              37.05115122490537
            ]
          },
          "bbox": [
            -3.776369206138271,
            37.05115122490537,
            -3.776369206138271,
            37.05115122490537
          ]
        }
      ],
      "bbox": [
        -3.776369206138271,
        37.05115122490537,
        -3.776369206138271,
        37.05115122490537
      ]
    },
    "18074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.025441968214322,
              37.17824955610724
            ]
          },
          "bbox": [
            -3.025441968214322,
            37.17824955610724,
            -3.025441968214322,
            37.17824955610724
          ]
        }
      ],
      "bbox": [
        -3.025441968214322,
        37.17824955610724,
        -3.025441968214322,
        37.17824955610724
      ]
    },
    "18076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1679410693017602,
              37.42223869347466
            ]
          },
          "bbox": [
            -3.1679410693017602,
            37.42223869347466,
            -3.1679410693017602,
            37.42223869347466
          ]
        }
      ],
      "bbox": [
        -3.1679410693017602,
        37.42223869347466,
        -3.1679410693017602,
        37.42223869347466
      ]
    },
    "18077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8468934888187793,
              36.95210582943221
            ]
          },
          "bbox": [
            -3.8468934888187793,
            36.95210582943221,
            -3.8468934888187793,
            36.95210582943221
          ]
        }
      ],
      "bbox": [
        -3.8468934888187793,
        36.95210582943221,
        -3.8468934888187793,
        36.95210582943221
      ]
    },
    "18078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9260202546298064,
              37.52216331630611
            ]
          },
          "bbox": [
            -2.9260202546298064,
            37.52216331630611,
            -2.9260202546298064,
            37.52216331630611
          ]
        }
      ],
      "bbox": [
        -2.9260202546298064,
        37.52216331630611,
        -2.9260202546298064,
        37.52216331630611
      ]
    },
    "18079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7853227825468605,
              37.21882272520412
            ]
          },
          "bbox": [
            -3.7853227825468605,
            37.21882272520412,
            -3.7853227825468605,
            37.21882272520412
          ]
        }
      ],
      "bbox": [
        -3.7853227825468605,
        37.21882272520412,
        -3.7853227825468605,
        37.21882272520412
      ]
    },
    "18082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5635128368496396,
              37.7176130803107
            ]
          },
          "bbox": [
            -2.5635128368496396,
            37.7176130803107,
            -2.5635128368496396,
            37.7176130803107
          ]
        }
      ],
      "bbox": [
        -2.5635128368496396,
        37.7176130803107,
        -2.5635128368496396,
        37.7176130803107
      ]
    },
    "18083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3037269055492047,
              37.47759696229399
            ]
          },
          "bbox": [
            -3.3037269055492047,
            37.47759696229399,
            -3.3037269055492047,
            37.47759696229399
          ]
        }
      ],
      "bbox": [
        -3.3037269055492047,
        37.47759696229399,
        -3.3037269055492047,
        37.47759696229399
      ]
    },
    "18084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.589708794994926,
              37.09400487509056
            ]
          },
          "bbox": [
            -3.589708794994926,
            37.09400487509056,
            -3.589708794994926,
            37.09400487509056
          ]
        }
      ],
      "bbox": [
        -3.589708794994926,
        37.09400487509056,
        -3.589708794994926,
        37.09400487509056
      ]
    },
    "18085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9510927484525915,
              37.374139228265584
            ]
          },
          "bbox": [
            -2.9510927484525915,
            37.374139228265584,
            -2.9510927484525915,
            37.374139228265584
          ]
        }
      ],
      "bbox": [
        -2.9510927484525915,
        37.374139228265584,
        -2.9510927484525915,
        37.374139228265584
      ]
    },
    "18086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0361221255274145,
              37.51978714967265
            ]
          },
          "bbox": [
            -3.0361221255274145,
            37.51978714967265,
            -3.0361221255274145,
            37.51978714967265
          ]
        }
      ],
      "bbox": [
        -3.0361221255274145,
        37.51978714967265,
        -3.0361221255274145,
        37.51978714967265
      ]
    },
    "18087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5912692683075265,
              37.18572438813592
            ]
          },
          "bbox": [
            -3.5912692683075265,
            37.18572438813592,
            -3.5912692683075265,
            37.18572438813592
          ]
        }
      ],
      "bbox": [
        -3.5912692683075265,
        37.18572438813592,
        -3.5912692683075265,
        37.18572438813592
      ]
    },
    "18088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.393292272652628,
              37.529778447707855
            ]
          },
          "bbox": [
            -3.393292272652628,
            37.529778447707855,
            -3.393292272652628,
            37.529778447707855
          ]
        }
      ],
      "bbox": [
        -3.393292272652628,
        37.529778447707855,
        -3.393292272652628,
        37.529778447707855
      ]
    },
    "18089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.080579439238186,
              37.38941016745162
            ]
          },
          "bbox": [
            -3.080579439238186,
            37.38941016745162,
            -3.080579439238186,
            37.38941016745162
          ]
        }
      ],
      "bbox": [
        -3.080579439238186,
        37.38941016745162,
        -3.080579439238186,
        37.38941016745162
      ]
    },
    "18093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.39167601310838,
              36.73783857269518
            ]
          },
          "bbox": [
            -3.39167601310838,
            36.73783857269518,
            -3.39167601310838,
            36.73783857269518
          ]
        }
      ],
      "bbox": [
        -3.39167601310838,
        36.73783857269518,
        -3.39167601310838,
        36.73783857269518
      ]
    },
    "18094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3575120486950096,
              37.14496017309968
            ]
          },
          "bbox": [
            -3.3575120486950096,
            37.14496017309968,
            -3.3575120486950096,
            37.14496017309968
          ]
        }
      ],
      "bbox": [
        -3.3575120486950096,
        37.14496017309968,
        -3.3575120486950096,
        37.14496017309968
      ]
    },
    "18095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6016328042682098,
              37.25722425200223
            ]
          },
          "bbox": [
            -3.6016328042682098,
            37.25722425200223,
            -3.6016328042682098,
            37.25722425200223
          ]
        }
      ],
      "bbox": [
        -3.6016328042682098,
        37.25722425200223,
        -3.6016328042682098,
        37.25722425200223
      ]
    },
    "18096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.264995679571265,
              37.41589303772142
            ]
          },
          "bbox": [
            -3.264995679571265,
            37.41589303772142,
            -3.264995679571265,
            37.41589303772142
          ]
        }
      ],
      "bbox": [
        -3.264995679571265,
        37.41589303772142,
        -3.264995679571265,
        37.41589303772142
      ]
    },
    "18097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9356415574485912,
              37.187969527724924
            ]
          },
          "bbox": [
            -2.9356415574485912,
            37.187969527724924,
            -2.9356415574485912,
            37.187969527724924
          ]
        }
      ],
      "bbox": [
        -2.9356415574485912,
        37.187969527724924,
        -2.9356415574485912,
        37.187969527724924
      ]
    },
    "18098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.586903878307949,
              37.89181315191555
            ]
          },
          "bbox": [
            -2.586903878307949,
            37.89181315191555,
            -2.586903878307949,
            37.89181315191555
          ]
        }
      ],
      "bbox": [
        -2.586903878307949,
        37.89181315191555,
        -2.586903878307949,
        37.89181315191555
      ]
    },
    "18099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4539788906953186,
              37.29147630563081
            ]
          },
          "bbox": [
            -3.4539788906953186,
            37.29147630563081,
            -3.4539788906953186,
            37.29147630563081
          ]
        }
      ],
      "bbox": [
        -3.4539788906953186,
        37.29147630563081,
        -3.4539788906953186,
        37.29147630563081
      ]
    },
    "18100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.047037089166069,
              37.19677474962234
            ]
          },
          "bbox": [
            -4.047037089166069,
            37.19677474962234,
            -4.047037089166069,
            37.19677474962234
          ]
        }
      ],
      "bbox": [
        -4.047037089166069,
        37.19677474962234,
        -4.047037089166069,
        37.19677474962234
      ]
    },
    "18101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5665684943658404,
              37.14816136038916
            ]
          },
          "bbox": [
            -3.5665684943658404,
            37.14816136038916,
            -3.5665684943658404,
            37.14816136038916
          ]
        }
      ],
      "bbox": [
        -3.5665684943658404,
        37.14816136038916,
        -3.5665684943658404,
        37.14816136038916
      ]
    },
    "18102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.898076671728328,
              37.28257293980814
            ]
          },
          "bbox": [
            -3.898076671728328,
            37.28257293980814,
            -3.898076671728328,
            37.28257293980814
          ]
        }
      ],
      "bbox": [
        -3.898076671728328,
        37.28257293980814,
        -3.898076671728328,
        37.28257293980814
      ]
    },
    "18103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6391330607940993,
              36.7892505342543
            ]
          },
          "bbox": [
            -3.6391330607940993,
            36.7892505342543,
            -3.6391330607940993,
            36.7892505342543
          ]
        }
      ],
      "bbox": [
        -3.6391330607940993,
        36.7892505342543,
        -3.6391330607940993,
        36.7892505342543
      ]
    },
    "18105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5343162341781635,
              37.402700166019855
            ]
          },
          "bbox": [
            -3.5343162341781635,
            37.402700166019855,
            -3.5343162341781635,
            37.402700166019855
          ]
        }
      ],
      "bbox": [
        -3.5343162341781635,
        37.402700166019855,
        -3.5343162341781635,
        37.402700166019855
      ]
    },
    "18106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.915570154575894,
              36.93423248107212
            ]
          },
          "bbox": [
            -3.915570154575894,
            36.93423248107212,
            -3.915570154575894,
            36.93423248107212
          ]
        }
      ],
      "bbox": [
        -3.915570154575894,
        36.93423248107212,
        -3.915570154575894,
        36.93423248107212
      ]
    },
    "18107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7929234574397928,
              36.93970197861165
            ]
          },
          "bbox": [
            -3.7929234574397928,
            36.93970197861165,
            -3.7929234574397928,
            36.93970197861165
          ]
        }
      ],
      "bbox": [
        -3.7929234574397928,
        36.93970197861165,
        -3.7929234574397928,
        36.93970197861165
      ]
    },
    "18108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1951831429148454,
              37.152587399914594
            ]
          },
          "bbox": [
            -3.1951831429148454,
            37.152587399914594,
            -3.1951831429148454,
            37.152587399914594
          ]
        }
      ],
      "bbox": [
        -3.1951831429148454,
        37.152587399914594,
        -3.1951831429148454,
        37.152587399914594
      ]
    },
    "18109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.664594383562702,
              36.79662878930211
            ]
          },
          "bbox": [
            -3.664594383562702,
            36.79662878930211,
            -3.664594383562702,
            36.79662878930211
          ]
        }
      ],
      "bbox": [
        -3.664594383562702,
        36.79662878930211,
        -3.664594383562702,
        36.79662878930211
      ]
    },
    "18111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5887595775208583,
              37.216482295092554
            ]
          },
          "bbox": [
            -3.5887595775208583,
            37.216482295092554,
            -3.5887595775208583,
            37.216482295092554
          ]
        }
      ],
      "bbox": [
        -3.5887595775208583,
        37.216482295092554,
        -3.5887595775208583,
        37.216482295092554
      ]
    },
    "18112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.230636806120399,
              36.96362934562717
            ]
          },
          "bbox": [
            -3.230636806120399,
            36.96362934562717,
            -3.230636806120399,
            36.96362934562717
          ]
        }
      ],
      "bbox": [
        -3.230636806120399,
        36.96362934562717,
        -3.230636806120399,
        36.96362934562717
      ]
    },
    "18114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0423750413573885,
              37.22497946534903
            ]
          },
          "bbox": [
            -3.0423750413573885,
            37.22497946534903,
            -3.0423750413573885,
            37.22497946534903
          ]
        }
      ],
      "bbox": [
        -3.0423750413573885,
        37.22497946534903,
        -3.0423750413573885,
        37.22497946534903
      ]
    },
    "18115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.844360286875072,
              37.1851450037687
            ]
          },
          "bbox": [
            -3.844360286875072,
            37.1851450037687,
            -3.844360286875072,
            37.1851450037687
          ]
        }
      ],
      "bbox": [
        -3.844360286875072,
        37.1851450037687,
        -3.844360286875072,
        37.1851450037687
      ]
    },
    "18116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4642654520710785,
              36.950244757889394
            ]
          },
          "bbox": [
            -3.4642654520710785,
            36.950244757889394,
            -3.4642654520710785,
            36.950244757889394
          ]
        }
      ],
      "bbox": [
        -3.4642654520710785,
        36.950244757889394,
        -3.4642654520710785,
        36.950244757889394
      ]
    },
    "18117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1383072616016086,
              37.147312598277686
            ]
          },
          "bbox": [
            -3.1383072616016086,
            37.147312598277686,
            -3.1383072616016086,
            37.147312598277686
          ]
        }
      ],
      "bbox": [
        -3.1383072616016086,
        37.147312598277686,
        -3.1383072616016086,
        37.147312598277686
      ]
    },
    "18119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5273397291246384,
              36.95093039631727
            ]
          },
          "bbox": [
            -3.5273397291246384,
            36.95093039631727,
            -3.5273397291246384,
            36.95093039631727
          ]
        }
      ],
      "bbox": [
        -3.5273397291246384,
        36.95093039631727,
        -3.5273397291246384,
        36.95093039631727
      ]
    },
    "18120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6876571265159868,
              36.8441298279254
            ]
          },
          "bbox": [
            -3.6876571265159868,
            36.8441298279254,
            -3.6876571265159868,
            36.8441298279254
          ]
        }
      ],
      "bbox": [
        -3.6876571265159868,
        36.8441298279254,
        -3.6876571265159868,
        36.8441298279254
      ]
    },
    "18121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2103678182375543,
              36.91112442820457
            ]
          },
          "bbox": [
            -3.2103678182375543,
            36.91112442820457,
            -3.2103678182375543,
            36.91112442820457
          ]
        }
      ],
      "bbox": [
        -3.2103678182375543,
        36.91112442820457,
        -3.2103678182375543,
        36.91112442820457
      ]
    },
    "18122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1855744767191885,
              37.15500807947889
            ]
          },
          "bbox": [
            -4.1855744767191885,
            37.15500807947889,
            -4.1855744767191885,
            37.15500807947889
          ]
        }
      ],
      "bbox": [
        -4.1855744767191885,
        37.15500807947889,
        -4.1855744767191885,
        37.15500807947889
      ]
    },
    "18123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2489199329552747,
              37.20435247086605
            ]
          },
          "bbox": [
            -3.2489199329552747,
            37.20435247086605,
            -3.2489199329552747,
            37.20435247086605
          ]
        }
      ],
      "bbox": [
        -3.2489199329552747,
        37.20435247086605,
        -3.2489199329552747,
        37.20435247086605
      ]
    },
    "18124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3842050022779553,
              36.78190107722312
            ]
          },
          "bbox": [
            -3.3842050022779553,
            36.78190107722312,
            -3.3842050022779553,
            36.78190107722312
          ]
        }
      ],
      "bbox": [
        -3.3842050022779553,
        36.78190107722312,
        -3.3842050022779553,
        36.78190107722312
      ]
    },
    "18126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.735738983196724,
              37.10013046484785
            ]
          },
          "bbox": [
            -3.735738983196724,
            37.10013046484785,
            -3.735738983196724,
            37.10013046484785
          ]
        }
      ],
      "bbox": [
        -3.735738983196724,
        37.10013046484785,
        -3.735738983196724,
        37.10013046484785
      ]
    },
    "18127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6402269557829117,
              37.21070168290367
            ]
          },
          "bbox": [
            -3.6402269557829117,
            37.21070168290367,
            -3.6402269557829117,
            37.21070168290367
          ]
        }
      ],
      "bbox": [
        -3.6402269557829117,
        37.21070168290367,
        -3.6402269557829117,
        37.21070168290367
      ]
    },
    "18128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.19630458097729,
              37.29883364045429
            ]
          },
          "bbox": [
            -3.19630458097729,
            37.29883364045429,
            -3.19630458097729,
            37.29883364045429
          ]
        }
      ],
      "bbox": [
        -3.19630458097729,
        37.29883364045429,
        -3.19630458097729,
        37.29883364045429
      ]
    },
    "18132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7943413980585303,
              37.356387490282046
            ]
          },
          "bbox": [
            -3.7943413980585303,
            37.356387490282046,
            -3.7943413980585303,
            37.356387490282046
          ]
        }
      ],
      "bbox": [
        -3.7943413980585303,
        37.356387490282046,
        -3.7943413980585303,
        37.356387490282046
      ]
    },
    "18133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6018045940567607,
              36.792091971274985
            ]
          },
          "bbox": [
            -3.6018045940567607,
            36.792091971274985,
            -3.6018045940567607,
            36.792091971274985
          ]
        }
      ],
      "bbox": [
        -3.6018045940567607,
        36.792091971274985,
        -3.6018045940567607,
        36.792091971274985
      ]
    },
    "18134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.475163842369163,
              37.10512883137693
            ]
          },
          "bbox": [
            -3.475163842369163,
            37.10512883137693,
            -3.475163842369163,
            37.10512883137693
          ]
        }
      ],
      "bbox": [
        -3.475163842369163,
        37.10512883137693,
        -3.475163842369163,
        37.10512883137693
      ]
    },
    "18135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.031824504646268,
              37.33219690971638
            ]
          },
          "bbox": [
            -4.031824504646268,
            37.33219690971638,
            -4.031824504646268,
            37.33219690971638
          ]
        }
      ],
      "bbox": [
        -4.031824504646268,
        37.33219690971638,
        -4.031824504646268,
        37.33219690971638
      ]
    },
    "18136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5092386706836884,
              37.56011998229777
            ]
          },
          "bbox": [
            -3.5092386706836884,
            37.56011998229777,
            -3.5092386706836884,
            37.56011998229777
          ]
        }
      ],
      "bbox": [
        -3.5092386706836884,
        37.56011998229777,
        -3.5092386706836884,
        37.56011998229777
      ]
    },
    "18137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.721815545032143,
              37.47103432093386
            ]
          },
          "bbox": [
            -3.721815545032143,
            37.47103432093386,
            -3.721815545032143,
            37.47103432093386
          ]
        }
      ],
      "bbox": [
        -3.721815545032143,
        37.47103432093386,
        -3.721815545032143,
        37.47103432093386
      ]
    },
    "18138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9507076176866436,
              37.180842330239635
            ]
          },
          "bbox": [
            -3.9507076176866436,
            37.180842330239635,
            -3.9507076176866436,
            37.180842330239635
          ]
        }
      ],
      "bbox": [
        -3.9507076176866436,
        37.180842330239635,
        -3.9507076176866436,
        37.180842330239635
      ]
    },
    "18140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4842499801261786,
              36.74511679674043
            ]
          },
          "bbox": [
            -3.4842499801261786,
            36.74511679674043,
            -3.4842499801261786,
            36.74511679674043
          ]
        }
      ],
      "bbox": [
        -3.4842499801261786,
        36.74511679674043,
        -3.4842499801261786,
        36.74511679674043
      ]
    },
    "18141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1303999756441483,
              36.866978775820016
            ]
          },
          "bbox": [
            -3.1303999756441483,
            36.866978775820016,
            -3.1303999756441483,
            36.866978775820016
          ]
        }
      ],
      "bbox": [
        -3.1303999756441483,
        36.866978775820016,
        -3.1303999756441483,
        36.866978775820016
      ]
    },
    "18143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.502730464870561,
              36.991670463759746
            ]
          },
          "bbox": [
            -3.502730464870561,
            36.991670463759746,
            -3.502730464870561,
            36.991670463759746
          ]
        }
      ],
      "bbox": [
        -3.502730464870561,
        36.991670463759746,
        -3.502730464870561,
        36.991670463759746
      ]
    },
    "18144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5557219788193457,
              37.265388800894634
            ]
          },
          "bbox": [
            -3.5557219788193457,
            37.265388800894634,
            -3.5557219788193457,
            37.265388800894634
          ]
        }
      ],
      "bbox": [
        -3.5557219788193457,
        37.265388800894634,
        -3.5557219788193457,
        37.265388800894634
      ]
    },
    "18145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6164526278757823,
              37.12334826462275
            ]
          },
          "bbox": [
            -3.6164526278757823,
            37.12334826462275,
            -3.6164526278757823,
            37.12334826462275
          ]
        }
      ],
      "bbox": [
        -3.6164526278757823,
        37.12334826462275,
        -3.6164526278757823,
        37.12334826462275
      ]
    },
    "18146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.398243955947492,
              37.719172343553744
            ]
          },
          "bbox": [
            -2.398243955947492,
            37.719172343553744,
            -2.398243955947492,
            37.719172343553744
          ]
        }
      ],
      "bbox": [
        -2.398243955947492,
        37.719172343553744,
        -2.398243955947492,
        37.719172343553744
      ]
    },
    "18147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3985017959934862,
              36.86499539846552
            ]
          },
          "bbox": [
            -3.3985017959934862,
            36.86499539846552,
            -3.3985017959934862,
            36.86499539846552
          ]
        }
      ],
      "bbox": [
        -3.3985017959934862,
        36.86499539846552,
        -3.3985017959934862,
        36.86499539846552
      ]
    },
    "18148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7448813245394827,
              36.8380701758718
            ]
          },
          "bbox": [
            -3.7448813245394827,
            36.8380701758718,
            -3.7448813245394827,
            36.8380701758718
          ]
        }
      ],
      "bbox": [
        -3.7448813245394827,
        36.8380701758718,
        -3.7448813245394827,
        36.8380701758718
      ]
    },
    "18149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6518631540601723,
              37.074215802854
            ]
          },
          "bbox": [
            -3.6518631540601723,
            37.074215802854,
            -3.6518631540601723,
            37.074215802854
          ]
        }
      ],
      "bbox": [
        -3.6518631540601723,
        37.074215802854,
        -3.6518631540601723,
        37.074215802854
      ]
    },
    "18150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6699952798641844,
              37.01409045518862
            ]
          },
          "bbox": [
            -3.6699952798641844,
            37.01409045518862,
            -3.6699952798641844,
            37.01409045518862
          ]
        }
      ],
      "bbox": [
        -3.6699952798641844,
        37.01409045518862,
        -3.6699952798641844,
        37.01409045518862
      ]
    },
    "18151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3792564512044594,
              36.963448545360244
            ]
          },
          "bbox": [
            -3.3792564512044594,
            36.963448545360244,
            -3.3792564512044594,
            36.963448545360244
          ]
        }
      ],
      "bbox": [
        -3.3792564512044594,
        36.963448545360244,
        -3.3792564512044594,
        36.963448545360244
      ]
    },
    "18152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2204487418295757,
              37.513477835769315
            ]
          },
          "bbox": [
            -3.2204487418295757,
            37.513477835769315,
            -3.2204487418295757,
            37.513477835769315
          ]
        }
      ],
      "bbox": [
        -3.2204487418295757,
        37.513477835769315,
        -3.2204487418295757,
        37.513477835769315
      ]
    },
    "18153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6301354583744856,
              37.24171205130744
            ]
          },
          "bbox": [
            -3.6301354583744856,
            37.24171205130744,
            -3.6301354583744856,
            37.24171205130744
          ]
        }
      ],
      "bbox": [
        -3.6301354583744856,
        37.24171205130744,
        -3.6301354583744856,
        37.24171205130744
      ]
    },
    "18154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3113152711444696,
              37.27842759185576
            ]
          },
          "bbox": [
            -3.3113152711444696,
            37.27842759185576,
            -3.3113152711444696,
            37.27842759185576
          ]
        }
      ],
      "bbox": [
        -3.3113152711444696,
        37.27842759185576,
        -3.3113152711444696,
        37.27842759185576
      ]
    },
    "18157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.504271130867458,
              37.159436471182204
            ]
          },
          "bbox": [
            -3.504271130867458,
            37.159436471182204,
            -3.504271130867458,
            37.159436471182204
          ]
        }
      ],
      "bbox": [
        -3.504271130867458,
        37.159436471182204,
        -3.504271130867458,
        37.159436471182204
      ]
    },
    "18158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8093093895018537,
              37.23841750976316
            ]
          },
          "bbox": [
            -3.8093093895018537,
            37.23841750976316,
            -3.8093093895018537,
            37.23841750976316
          ]
        }
      ],
      "bbox": [
        -3.8093093895018537,
        37.23841750976316,
        -3.8093093895018537,
        37.23841750976316
      ]
    },
    "18159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4050032194482713,
              37.430684818804195
            ]
          },
          "bbox": [
            -3.4050032194482713,
            37.430684818804195,
            -3.4050032194482713,
            37.430684818804195
          ]
        }
      ],
      "bbox": [
        -3.4050032194482713,
        37.430684818804195,
        -3.4050032194482713,
        37.430684818804195
      ]
    },
    "18161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2408355753746076,
              37.24861711512797
            ]
          },
          "bbox": [
            -3.2408355753746076,
            37.24861711512797,
            -3.2408355753746076,
            37.24861711512797
          ]
        }
      ],
      "bbox": [
        -3.2408355753746076,
        37.24861711512797,
        -3.2408355753746076,
        37.24861711512797
      ]
    },
    "18162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.301186548551456,
              36.77702799207135
            ]
          },
          "bbox": [
            -3.301186548551456,
            36.77702799207135,
            -3.301186548551456,
            36.77702799207135
          ]
        }
      ],
      "bbox": [
        -3.301186548551456,
        36.77702799207135,
        -3.301186548551456,
        36.77702799207135
      ]
    },
    "18163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3059855368442,
              36.9715859311263
            ]
          },
          "bbox": [
            -3.3059855368442,
            36.9715859311263,
            -3.3059855368442,
            36.9715859311263
          ]
        }
      ],
      "bbox": [
        -3.3059855368442,
        36.9715859311263,
        -3.3059855368442,
        36.9715859311263
      ]
    },
    "18164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4022548739043197,
              37.928454811627
            ]
          },
          "bbox": [
            -2.4022548739043197,
            37.928454811627,
            -2.4022548739043197,
            37.928454811627
          ]
        }
      ],
      "bbox": [
        -2.4022548739043197,
        37.928454811627,
        -2.4022548739043197,
        37.928454811627
      ]
    },
    "18165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6096539795030598,
              37.2260243080945
            ]
          },
          "bbox": [
            -3.6096539795030598,
            37.2260243080945,
            -3.6096539795030598,
            37.2260243080945
          ]
        }
      ],
      "bbox": [
        -3.6096539795030598,
        37.2260243080945,
        -3.6096539795030598,
        37.2260243080945
      ]
    },
    "18167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1996105204010012,
              37.34001804534975
            ]
          },
          "bbox": [
            -3.1996105204010012,
            37.34001804534975,
            -3.1996105204010012,
            37.34001804534975
          ]
        }
      ],
      "bbox": [
        -3.1996105204010012,
        37.34001804534975,
        -3.1996105204010012,
        37.34001804534975
      ]
    },
    "18168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.415384422106424,
              37.23081001264598
            ]
          },
          "bbox": [
            -3.415384422106424,
            37.23081001264598,
            -3.415384422106424,
            37.23081001264598
          ]
        }
      ],
      "bbox": [
        -3.415384422106424,
        37.23081001264598,
        -3.415384422106424,
        37.23081001264598
      ]
    },
    "18170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.338849368926009,
              36.78241243830691
            ]
          },
          "bbox": [
            -3.338849368926009,
            36.78241243830691,
            -3.338849368926009,
            36.78241243830691
          ]
        }
      ],
      "bbox": [
        -3.338849368926009,
        36.78241243830691,
        -3.338849368926009,
        36.78241243830691
      ]
    },
    "18171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.038709667108626,
              37.13790918849318
            ]
          },
          "bbox": [
            -4.038709667108626,
            37.13790918849318,
            -4.038709667108626,
            37.13790918849318
          ]
        }
      ],
      "bbox": [
        -4.038709667108626,
        37.13790918849318,
        -4.038709667108626,
        37.13790918849318
      ]
    },
    "18173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5834490661455582,
              36.76195860066666
            ]
          },
          "bbox": [
            -3.5834490661455582,
            36.76195860066666,
            -3.5834490661455582,
            36.76195860066666
          ]
        }
      ],
      "bbox": [
        -3.5834490661455582,
        36.76195860066666,
        -3.5834490661455582,
        36.76195860066666
      ]
    },
    "18174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9745048172745956,
              37.064209237828535
            ]
          },
          "bbox": [
            -3.9745048172745956,
            37.064209237828535,
            -3.9745048172745956,
            37.064209237828535
          ]
        }
      ],
      "bbox": [
        -3.9745048172745956,
        37.064209237828535,
        -3.9745048172745956,
        37.064209237828535
      ]
    },
    "18175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.74015477326802,
              37.18250988216381
            ]
          },
          "bbox": [
            -3.74015477326802,
            37.18250988216381,
            -3.74015477326802,
            37.18250988216381
          ]
        }
      ],
      "bbox": [
        -3.74015477326802,
        37.18250988216381,
        -3.74015477326802,
        37.18250988216381
      ]
    },
    "18176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.399273723446904,
              36.95635714670833
            ]
          },
          "bbox": [
            -3.399273723446904,
            36.95635714670833,
            -3.399273723446904,
            36.95635714670833
          ]
        }
      ],
      "bbox": [
        -3.399273723446904,
        36.95635714670833,
        -3.399273723446904,
        36.95635714670833
      ]
    },
    "18177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.262646893583142,
              36.79032973834115
            ]
          },
          "bbox": [
            -3.262646893583142,
            36.79032973834115,
            -3.262646893583142,
            36.79032973834115
          ]
        }
      ],
      "bbox": [
        -3.262646893583142,
        36.79032973834115,
        -3.262646893583142,
        36.79032973834115
      ]
    },
    "18178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3588500340414273,
              37.498539498758724
            ]
          },
          "bbox": [
            -3.3588500340414273,
            37.498539498758724,
            -3.3588500340414273,
            37.498539498758724
          ]
        }
      ],
      "bbox": [
        -3.3588500340414273,
        37.498539498758724,
        -3.3588500340414273,
        37.498539498758724
      ]
    },
    "18179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2819642663300743,
              36.8537839104212
            ]
          },
          "bbox": [
            -3.2819642663300743,
            36.8537839104212,
            -3.2819642663300743,
            36.8537839104212
          ]
        }
      ],
      "bbox": [
        -3.2819642663300743,
        36.8537839104212,
        -3.2819642663300743,
        36.8537839104212
      ]
    },
    "18180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2597940462088117,
              37.04987528808198
            ]
          },
          "bbox": [
            -3.2597940462088117,
            37.04987528808198,
            -3.2597940462088117,
            37.04987528808198
          ]
        }
      ],
      "bbox": [
        -3.2597940462088117,
        37.04987528808198,
        -3.2597940462088117,
        37.04987528808198
      ]
    },
    "18181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0834037091083077,
              36.844586014958175
            ]
          },
          "bbox": [
            -3.0834037091083077,
            36.844586014958175,
            -3.0834037091083077,
            36.844586014958175
          ]
        }
      ],
      "bbox": [
        -3.0834037091083077,
        36.844586014958175,
        -3.0834037091083077,
        36.844586014958175
      ]
    },
    "18182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.067825442319682,
              36.94758517858604
            ]
          },
          "bbox": [
            -3.067825442319682,
            36.94758517858604,
            -3.067825442319682,
            36.94758517858604
          ]
        }
      ],
      "bbox": [
        -3.067825442319682,
        36.94758517858604,
        -3.067825442319682,
        36.94758517858604
      ]
    },
    "18183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.094380981206755,
              37.025692534960854
            ]
          },
          "bbox": [
            -3.094380981206755,
            37.025692534960854,
            -3.094380981206755,
            37.025692534960854
          ]
        }
      ],
      "bbox": [
        -3.094380981206755,
        37.025692534960854,
        -3.094380981206755,
        37.025692534960854
      ]
    },
    "18184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4899094255197607,
              36.82044663780301
            ]
          },
          "bbox": [
            -3.4899094255197607,
            36.82044663780301,
            -3.4899094255197607,
            36.82044663780301
          ]
        }
      ],
      "bbox": [
        -3.4899094255197607,
        36.82044663780301,
        -3.4899094255197607,
        36.82044663780301
      ]
    },
    "18185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.834500834827263,
              37.0685815068617
            ]
          },
          "bbox": [
            -3.834500834827263,
            37.0685815068617,
            -3.834500834827263,
            37.0685815068617
          ]
        }
      ],
      "bbox": [
        -3.834500834827263,
        37.0685815068617,
        -3.834500834827263,
        37.0685815068617
      ]
    },
    "18187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1116841649903706,
              37.53050634317316
            ]
          },
          "bbox": [
            -3.1116841649903706,
            37.53050634317316,
            -3.1116841649903706,
            37.53050634317316
          ]
        }
      ],
      "bbox": [
        -3.1116841649903706,
        37.53050634317316,
        -3.1116841649903706,
        37.53050634317316
      ]
    },
    "18188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.006863785122293,
              37.229566709640956
            ]
          },
          "bbox": [
            -4.006863785122293,
            37.229566709640956,
            -4.006863785122293,
            37.229566709640956
          ]
        }
      ],
      "bbox": [
        -4.006863785122293,
        37.229566709640956,
        -4.006863785122293,
        37.229566709640956
      ]
    },
    "18189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.538452292741596,
              37.2364798622685
            ]
          },
          "bbox": [
            -3.538452292741596,
            37.2364798622685,
            -3.538452292741596,
            37.2364798622685
          ]
        }
      ],
      "bbox": [
        -3.538452292741596,
        37.2364798622685,
        -3.538452292741596,
        37.2364798622685
      ]
    },
    "18192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.154144349244942,
              36.996155411511744
            ]
          },
          "bbox": [
            -4.154144349244942,
            36.996155411511744,
            -4.154144349244942,
            36.996155411511744
          ]
        }
      ],
      "bbox": [
        -4.154144349244942,
        36.996155411511744,
        -4.154144349244942,
        36.996155411511744
      ]
    },
    "18193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.571601381217596,
              37.11012070377947
            ]
          },
          "bbox": [
            -3.571601381217596,
            37.11012070377947,
            -3.571601381217596,
            37.11012070377947
          ]
        }
      ],
      "bbox": [
        -3.571601381217596,
        37.11012070377947,
        -3.571601381217596,
        37.11012070377947
      ]
    },
    "18194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8470944143485535,
              37.5721637890556
            ]
          },
          "bbox": [
            -2.8470944143485535,
            37.5721637890556,
            -2.8470944143485535,
            37.5721637890556
          ]
        }
      ],
      "bbox": [
        -2.8470944143485535,
        37.5721637890556,
        -2.8470944143485535,
        37.5721637890556
      ]
    },
    "18901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3356972849560416,
              36.92526241008935
            ]
          },
          "bbox": [
            -3.3356972849560416,
            36.92526241008935,
            -3.3356972849560416,
            36.92526241008935
          ]
        }
      ],
      "bbox": [
        -3.3356972849560416,
        36.92526241008935,
        -3.3356972849560416,
        36.92526241008935
      ]
    },
    "18902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5950394629958558,
              36.92861710911134
            ]
          },
          "bbox": [
            -3.5950394629958558,
            36.92861710911134,
            -3.5950394629958558,
            36.92861710911134
          ]
        }
      ],
      "bbox": [
        -3.5950394629958558,
        36.92861710911134,
        -3.5950394629958558,
        36.92861710911134
      ]
    },
    "18903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.037002555837016,
              37.039915085168886
            ]
          },
          "bbox": [
            -3.037002555837016,
            37.039915085168886,
            -3.037002555837016,
            37.039915085168886
          ]
        }
      ],
      "bbox": [
        -3.037002555837016,
        37.039915085168886,
        -3.037002555837016,
        37.039915085168886
      ]
    },
    "18904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.142421033641594,
              37.01924588309474
            ]
          },
          "bbox": [
            -3.142421033641594,
            37.01924588309474,
            -3.142421033641594,
            37.01924588309474
          ]
        }
      ],
      "bbox": [
        -3.142421033641594,
        37.01924588309474,
        -3.142421033641594,
        37.01924588309474
      ]
    },
    "18905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.696679355674803,
              37.13269221316799
            ]
          },
          "bbox": [
            -3.696679355674803,
            37.13269221316799,
            -3.696679355674803,
            37.13269221316799
          ]
        }
      ],
      "bbox": [
        -3.696679355674803,
        37.13269221316799,
        -3.696679355674803,
        37.13269221316799
      ]
    },
    "18906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.603361045081112,
              36.85198097591103
            ]
          },
          "bbox": [
            -3.603361045081112,
            36.85198097591103,
            -3.603361045081112,
            36.85198097591103
          ]
        }
      ],
      "bbox": [
        -3.603361045081112,
        36.85198097591103,
        -3.603361045081112,
        36.85198097591103
      ]
    },
    "18907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0002250738451774,
              37.28461798262546
            ]
          },
          "bbox": [
            -3.0002250738451774,
            37.28461798262546,
            -3.0002250738451774,
            37.28461798262546
          ]
        }
      ],
      "bbox": [
        -3.0002250738451774,
        37.28461798262546,
        -3.0002250738451774,
        37.28461798262546
      ]
    },
    "18908": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.605037701492558,
              36.97598411736701
            ]
          },
          "bbox": [
            -3.605037701492558,
            36.97598411736701,
            -3.605037701492558,
            36.97598411736701
          ]
        }
      ],
      "bbox": [
        -3.605037701492558,
        36.97598411736701,
        -3.605037701492558,
        36.97598411736701
      ]
    },
    "18909": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.312161234077422,
              37.43563490598773
            ]
          },
          "bbox": [
            -3.312161234077422,
            37.43563490598773,
            -3.312161234077422,
            37.43563490598773
          ]
        }
      ],
      "bbox": [
        -3.312161234077422,
        37.43563490598773,
        -3.312161234077422,
        37.43563490598773
      ]
    },
    "18910": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.542167640969794,
              36.89483601899918
            ]
          },
          "bbox": [
            -3.542167640969794,
            36.89483601899918,
            -3.542167640969794,
            36.89483601899918
          ]
        }
      ],
      "bbox": [
        -3.542167640969794,
        36.89483601899918,
        -3.542167640969794,
        36.89483601899918
      ]
    },
    "18911": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.692711556746333,
              37.16980822366021
            ]
          },
          "bbox": [
            -3.692711556746333,
            37.16980822366021,
            -3.692711556746333,
            37.16980822366021
          ]
        }
      ],
      "bbox": [
        -3.692711556746333,
        37.16980822366021,
        -3.692711556746333,
        37.16980822366021
      ]
    },
    "18912": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9130528061853,
              37.61287275514441
            ]
          },
          "bbox": [
            -2.9130528061853,
            37.61287275514441,
            -2.9130528061853,
            37.61287275514441
          ]
        }
      ],
      "bbox": [
        -2.9130528061853,
        37.61287275514441,
        -2.9130528061853,
        37.61287275514441
      ]
    },
    "18913": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.174226939026453,
              37.25240424307081
            ]
          },
          "bbox": [
            -4.174226939026453,
            37.25240424307081,
            -4.174226939026453,
            37.25240424307081
          ]
        }
      ],
      "bbox": [
        -4.174226939026453,
        37.25240424307081,
        -4.174226939026453,
        37.25240424307081
      ]
    },
    "18914": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8225900893920954,
              37.236514166830986
            ]
          },
          "bbox": [
            -3.8225900893920954,
            37.236514166830986,
            -3.8225900893920954,
            37.236514166830986
          ]
        }
      ],
      "bbox": [
        -3.8225900893920954,
        37.236514166830986,
        -3.8225900893920954,
        37.236514166830986
      ]
    },
    "18915": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.50670642859623,
              37.49019034281837
            ]
          },
          "bbox": [
            -3.50670642859623,
            37.49019034281837,
            -3.50670642859623,
            37.49019034281837
          ]
        }
      ],
      "bbox": [
        -3.50670642859623,
        37.49019034281837,
        -3.50670642859623,
        37.49019034281837
      ]
    },
    "18916": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4805954899393203,
              36.70886441460527
            ]
          },
          "bbox": [
            -3.4805954899393203,
            36.70886441460527,
            -3.4805954899393203,
            36.70886441460527
          ]
        }
      ],
      "bbox": [
        -3.4805954899393203,
        36.70886441460527,
        -3.4805954899393203,
        36.70886441460527
      ]
    },
    "19001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4690684341498317,
              40.885863975577024
            ]
          },
          "bbox": [
            -2.4690684341498317,
            40.885863975577024,
            -2.4690684341498317,
            40.885863975577024
          ]
        }
      ],
      "bbox": [
        -2.4690684341498317,
        40.885863975577024,
        -2.4690684341498317,
        40.885863975577024
      ]
    },
    "19002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.220732038048433,
              40.902245909969324
            ]
          },
          "bbox": [
            -2.220732038048433,
            40.902245909969324,
            -2.220732038048433,
            40.902245909969324
          ]
        }
      ],
      "bbox": [
        -2.220732038048433,
        40.902245909969324,
        -2.220732038048433,
        40.902245909969324
      ]
    },
    "19003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6739163948406903,
              40.67082104274524
            ]
          },
          "bbox": [
            -1.6739163948406903,
            40.67082104274524,
            -1.6739163948406903,
            40.67082104274524
          ]
        }
      ],
      "bbox": [
        -1.6739163948406903,
        40.67082104274524,
        -1.6739163948406903,
        40.67082104274524
      ]
    },
    "19004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7235449010838386,
              40.87474642567369
            ]
          },
          "bbox": [
            -2.7235449010838386,
            40.87474642567369,
            -2.7235449010838386,
            40.87474642567369
          ]
        }
      ],
      "bbox": [
        -2.7235449010838386,
        40.87474642567369,
        -2.7235449010838386,
        40.87474642567369
      ]
    },
    "19005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.106329531944944,
              40.840628973381364
            ]
          },
          "bbox": [
            -3.106329531944944,
            40.840628973381364,
            -3.106329531944944,
            40.840628973381364
          ]
        }
      ],
      "bbox": [
        -3.106329531944944,
        40.840628973381364,
        -3.106329531944944,
        40.840628973381364
      ]
    },
    "19006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8506216186802145,
              40.28649106117827
            ]
          },
          "bbox": [
            -2.8506216186802145,
            40.28649106117827,
            -2.8506216186802145,
            40.28649106117827
          ]
        }
      ],
      "bbox": [
        -2.8506216186802145,
        40.28649106117827,
        -2.8506216186802145,
        40.28649106117827
      ]
    },
    "19007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0379372825526465,
              40.30701072949317
            ]
          },
          "bbox": [
            -3.0379372825526465,
            40.30701072949317,
            -3.0379372825526465,
            40.30701072949317
          ]
        }
      ],
      "bbox": [
        -3.0379372825526465,
        40.30701072949317,
        -3.0379372825526465,
        40.30701072949317
      ]
    },
    "19008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0569093781648666,
              41.21890914740983
            ]
          },
          "bbox": [
            -3.0569093781648666,
            41.21890914740983,
            -3.0569093781648666,
            41.21890914740983
          ]
        }
      ],
      "bbox": [
        -3.0569093781648666,
        41.21890914740983,
        -3.0569093781648666,
        41.21890914740983
      ]
    },
    "19009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5818788298637054,
              40.463434327831514
            ]
          },
          "bbox": [
            -2.5818788298637054,
            40.463434327831514,
            -2.5818788298637054,
            40.463434327831514
          ]
        }
      ],
      "bbox": [
        -2.5818788298637054,
        40.463434327831514,
        -2.5818788298637054,
        40.463434327831514
      ]
    },
    "19010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7830326163445305,
              41.21342131487463
            ]
          },
          "bbox": [
            -2.7830326163445305,
            41.21342131487463,
            -2.7830326163445305,
            41.21342131487463
          ]
        }
      ],
      "bbox": [
        -2.7830326163445305,
        41.21342131487463,
        -2.7830326163445305,
        41.21342131487463
      ]
    },
    "19011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4827013354016167,
              40.99769808387648
            ]
          },
          "bbox": [
            -2.4827013354016167,
            40.99769808387648,
            -2.4827013354016167,
            40.99769808387648
          ]
        }
      ],
      "bbox": [
        -2.4827013354016167,
        40.99769808387648,
        -2.4827013354016167,
        40.99769808387648
      ]
    },
    "19013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7510651183380892,
              40.62638243727353
            ]
          },
          "bbox": [
            -1.7510651183380892,
            40.62638243727353,
            -1.7510651183380892,
            40.62638243727353
          ]
        }
      ],
      "bbox": [
        -1.7510651183380892,
        40.62638243727353,
        -1.7510651183380892,
        40.62638243727353
      ]
    },
    "19015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0513389083141833,
              40.69113736173915
            ]
          },
          "bbox": [
            -3.0513389083141833,
            40.69113736173915,
            -3.0513389083141833,
            40.69113736173915
          ]
        }
      ],
      "bbox": [
        -3.0513389083141833,
        40.69113736173915,
        -3.0513389083141833,
        40.69113736173915
      ]
    },
    "19016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.949919688184616,
              41.136706225533906
            ]
          },
          "bbox": [
            -1.949919688184616,
            41.136706225533906,
            -1.949919688184616,
            41.136706225533906
          ]
        }
      ],
      "bbox": [
        -1.949919688184616,
        41.136706225533906,
        -1.949919688184616,
        41.136706225533906
      ]
    },
    "19017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6700674217863867,
              40.948831133997246
            ]
          },
          "bbox": [
            -2.6700674217863867,
            40.948831133997246,
            -2.6700674217863867,
            40.948831133997246
          ]
        }
      ],
      "bbox": [
        -2.6700674217863867,
        40.948831133997246,
        -2.6700674217863867,
        40.948831133997246
      ]
    },
    "19018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8325697760043513,
              40.51724036643584
            ]
          },
          "bbox": [
            -2.8325697760043513,
            40.51724036643584,
            -2.8325697760043513,
            40.51724036643584
          ]
        }
      ],
      "bbox": [
        -2.8325697760043513,
        40.51724036643584,
        -2.8325697760043513,
        40.51724036643584
      ]
    },
    "19019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.651518938208514,
              40.5878680474907
            ]
          },
          "bbox": [
            -2.651518938208514,
            40.5878680474907,
            -2.651518938208514,
            40.5878680474907
          ]
        }
      ],
      "bbox": [
        -2.651518938208514,
        40.5878680474907,
        -2.651518938208514,
        40.5878680474907
      ]
    },
    "19020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7618866440517236,
              40.89533717003118
            ]
          },
          "bbox": [
            -2.7618866440517236,
            40.89533717003118,
            -2.7618866440517236,
            40.89533717003118
          ]
        }
      ],
      "bbox": [
        -2.7618866440517236,
        40.89533717003118,
        -2.7618866440517236,
        40.89533717003118
      ]
    },
    "19021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.037965802544942,
              40.27219497460498
            ]
          },
          "bbox": [
            -3.037965802544942,
            40.27219497460498,
            -3.037965802544942,
            40.27219497460498
          ]
        }
      ],
      "bbox": [
        -3.037965802544942,
        40.27219497460498,
        -3.037965802544942,
        40.27219497460498
      ]
    },
    "19022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8410966759228016,
              40.2878771989951
            ]
          },
          "bbox": [
            -2.8410966759228016,
            40.2878771989951,
            -2.8410966759228016,
            40.2878771989951
          ]
        }
      ],
      "bbox": [
        -2.8410966759228016,
        40.2878771989951,
        -2.8410966759228016,
        40.2878771989951
      ]
    },
    "19023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.741385895646737,
              40.56618469549573
            ]
          },
          "bbox": [
            -2.741385895646737,
            40.56618469549573,
            -2.741385895646737,
            40.56618469549573
          ]
        }
      ],
      "bbox": [
        -2.741385895646737,
        40.56618469549573,
        -2.741385895646737,
        40.56618469549573
      ]
    },
    "19024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2456403453710574,
              40.5915729431993
            ]
          },
          "bbox": [
            -3.2456403453710574,
            40.5915729431993,
            -3.2456403453710574,
            40.5915729431993
          ]
        }
      ],
      "bbox": [
        -3.2456403453710574,
        40.5915729431993,
        -3.2456403453710574,
        40.5915729431993
      ]
    },
    "19027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.63196290855792,
              40.6101756954727
            ]
          },
          "bbox": [
            -1.63196290855792,
            40.6101756954727,
            -1.63196290855792,
            40.6101756954727
          ]
        }
      ],
      "bbox": [
        -1.63196290855792,
        40.6101756954727,
        -1.63196290855792,
        40.6101756954727
      ]
    },
    "19031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.858941165521831,
              41.06736115708499
            ]
          },
          "bbox": [
            -2.858941165521831,
            41.06736115708499,
            -2.858941165521831,
            41.06736115708499
          ]
        }
      ],
      "bbox": [
        -2.858941165521831,
        41.06736115708499,
        -2.858941165521831,
        41.06736115708499
      ]
    },
    "19032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.352510330929752,
              41.00185285601849
            ]
          },
          "bbox": [
            -2.352510330929752,
            41.00185285601849,
            -2.352510330929752,
            41.00185285601849
          ]
        }
      ],
      "bbox": [
        -2.352510330929752,
        41.00185285601849,
        -2.352510330929752,
        41.00185285601849
      ]
    },
    "19033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.153198547087278,
              40.967821847010704
            ]
          },
          "bbox": [
            -2.153198547087278,
            40.967821847010704,
            -2.153198547087278,
            40.967821847010704
          ]
        }
      ],
      "bbox": [
        -2.153198547087278,
        40.967821847010704,
        -2.153198547087278,
        40.967821847010704
      ]
    },
    "19034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7402446873055046,
              40.73433702645623
            ]
          },
          "bbox": [
            -1.7402446873055046,
            40.73433702645623,
            -1.7402446873055046,
            40.73433702645623
          ]
        }
      ],
      "bbox": [
        -1.7402446873055046,
        40.73433702645623,
        -1.7402446873055046,
        40.73433702645623
      ]
    },
    "19036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.07774334337289,
              40.4912920516072
            ]
          },
          "bbox": [
            -3.07774334337289,
            40.4912920516072,
            -3.07774334337289,
            40.4912920516072
          ]
        }
      ],
      "bbox": [
        -3.07774334337289,
        40.4912920516072,
        -3.07774334337289,
        40.4912920516072
      ]
    },
    "19037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.151764480845717,
              40.982965958028586
            ]
          },
          "bbox": [
            -3.151764480845717,
            40.982965958028586,
            -3.151764480845717,
            40.982965958028586
          ]
        }
      ],
      "bbox": [
        -3.151764480845717,
        40.982965958028586,
        -3.151764480845717,
        40.982965958028586
      ]
    },
    "19038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4014369291480246,
              40.67245907410836
            ]
          },
          "bbox": [
            -2.4014369291480246,
            40.67245907410836,
            -2.4014369291480246,
            40.67245907410836
          ]
        }
      ],
      "bbox": [
        -2.4014369291480246,
        40.67245907410836,
        -2.4014369291480246,
        40.67245907410836
      ]
    },
    "19039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.816143240295129,
              40.88887592948212
            ]
          },
          "bbox": [
            -2.816143240295129,
            40.88887592948212,
            -2.816143240295129,
            40.88887592948212
          ]
        }
      ],
      "bbox": [
        -2.816143240295129,
        40.88887592948212,
        -2.816143240295129,
        40.88887592948212
      ]
    },
    "19040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.32283675270577,
              40.73646718332443
            ]
          },
          "bbox": [
            -2.32283675270577,
            40.73646718332443,
            -2.32283675270577,
            40.73646718332443
          ]
        }
      ],
      "bbox": [
        -2.32283675270577,
        40.73646718332443,
        -2.32283675270577,
        40.73646718332443
      ]
    },
    "19041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.036050843815255,
              40.52512375923828
            ]
          },
          "bbox": [
            -3.036050843815255,
            40.52512375923828,
            -3.036050843815255,
            40.52512375923828
          ]
        }
      ],
      "bbox": [
        -3.036050843815255,
        40.52512375923828,
        -3.036050843815255,
        40.52512375923828
      ]
    },
    "19042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.133783216176648,
              41.075380367911194
            ]
          },
          "bbox": [
            -3.133783216176648,
            41.075380367911194,
            -3.133783216176648,
            41.075380367911194
          ]
        }
      ],
      "bbox": [
        -3.133783216176648,
        41.075380367911194,
        -3.133783216176648,
        41.075380367911194
      ]
    },
    "19043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0101080139578307,
              40.660569863006195
            ]
          },
          "bbox": [
            -3.0101080139578307,
            40.660569863006195,
            -3.0101080139578307,
            40.660569863006195
          ]
        }
      ],
      "bbox": [
        -3.0101080139578307,
        40.660569863006195,
        -3.0101080139578307,
        40.660569863006195
      ]
    },
    "19044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.869930731842966,
              41.20830318449339
            ]
          },
          "bbox": [
            -2.869930731842966,
            41.20830318449339,
            -2.869930731842966,
            41.20830318449339
          ]
        }
      ],
      "bbox": [
        -2.869930731842966,
        41.20830318449339,
        -2.869930731842966,
        41.20830318449339
      ]
    },
    "19045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.779116114634163,
              40.51327920488229
            ]
          },
          "bbox": [
            -2.779116114634163,
            40.51327920488229,
            -2.779116114634163,
            40.51327920488229
          ]
        }
      ],
      "bbox": [
        -2.779116114634163,
        40.51327920488229,
        -2.779116114634163,
        40.51327920488229
      ]
    },
    "19046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2630261286304463,
              40.55676349609909
            ]
          },
          "bbox": [
            -3.2630261286304463,
            40.55676349609909,
            -3.2630261286304463,
            40.55676349609909
          ]
        }
      ],
      "bbox": [
        -3.2630261286304463,
        40.55676349609909,
        -3.2630261286304463,
        40.55676349609909
      ]
    },
    "19047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7747832627090214,
              41.00130300756504
            ]
          },
          "bbox": [
            -2.7747832627090214,
            41.00130300756504,
            -2.7747832627090214,
            41.00130300756504
          ]
        }
      ],
      "bbox": [
        -2.7747832627090214,
        41.00130300756504,
        -2.7747832627090214,
        41.00130300756504
      ]
    },
    "19048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.001379706358115,
              40.71862907995642
            ]
          },
          "bbox": [
            -2.001379706358115,
            40.71862907995642,
            -2.001379706358115,
            40.71862907995642
          ]
        }
      ],
      "bbox": [
        -2.001379706358115,
        40.71862907995642,
        -2.001379706358115,
        40.71862907995642
      ]
    },
    "19049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9184299969707554,
              41.29816312096832
            ]
          },
          "bbox": [
            -2.9184299969707554,
            41.29816312096832,
            -2.9184299969707554,
            41.29816312096832
          ]
        }
      ],
      "bbox": [
        -2.9184299969707554,
        41.29816312096832,
        -2.9184299969707554,
        41.29816312096832
      ]
    },
    "19050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.759727496187341,
              40.78476142347956
            ]
          },
          "bbox": [
            -2.759727496187341,
            40.78476142347956,
            -2.759727496187341,
            40.78476142347956
          ]
        }
      ],
      "bbox": [
        -2.759727496187341,
        40.78476142347956,
        -2.759727496187341,
        40.78476142347956
      ]
    },
    "19051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8121366094004228,
              40.57008006371959
            ]
          },
          "bbox": [
            -2.8121366094004228,
            40.57008006371959,
            -2.8121366094004228,
            40.57008006371959
          ]
        }
      ],
      "bbox": [
        -2.8121366094004228,
        40.57008006371959,
        -2.8121366094004228,
        40.57008006371959
      ]
    },
    "19052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8881274916160136,
              41.123698023552734
            ]
          },
          "bbox": [
            -2.8881274916160136,
            41.123698023552734,
            -2.8881274916160136,
            41.123698023552734
          ]
        }
      ],
      "bbox": [
        -2.8881274916160136,
        41.123698023552734,
        -2.8881274916160136,
        41.123698023552734
      ]
    },
    "19053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8541377750285375,
              40.7399221879189
            ]
          },
          "bbox": [
            -2.8541377750285375,
            40.7399221879189,
            -2.8541377750285375,
            40.7399221879189
          ]
        }
      ],
      "bbox": [
        -2.8541377750285375,
        40.7399221879189,
        -2.8541377750285375,
        40.7399221879189
      ]
    },
    "19054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7627595186724188,
              40.66873715589701
            ]
          },
          "bbox": [
            -2.7627595186724188,
            40.66873715589701,
            -2.7627595186724188,
            40.66873715589701
          ]
        }
      ],
      "bbox": [
        -2.7627595186724188,
        40.66873715589701,
        -2.7627595186724188,
        40.66873715589701
      ]
    },
    "19055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.877589567862364,
              40.93612035922811
            ]
          },
          "bbox": [
            -2.877589567862364,
            40.93612035922811,
            -2.877589567862364,
            40.93612035922811
          ]
        }
      ],
      "bbox": [
        -2.877589567862364,
        40.93612035922811,
        -2.877589567862364,
        40.93612035922811
      ]
    },
    "19057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0725179856389255,
              41.13591624073822
            ]
          },
          "bbox": [
            -3.0725179856389255,
            41.13591624073822,
            -3.0725179856389255,
            41.13591624073822
          ]
        }
      ],
      "bbox": [
        -3.0725179856389255,
        41.13591624073822,
        -3.0725179856389255,
        41.13591624073822
      ]
    },
    "19058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2421327933997044,
              40.63390446824328
            ]
          },
          "bbox": [
            -3.2421327933997044,
            40.63390446824328,
            -3.2421327933997044,
            40.63390446824328
          ]
        }
      ],
      "bbox": [
        -3.2421327933997044,
        40.63390446824328,
        -3.2421327933997044,
        40.63390446824328
      ]
    },
    "19059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6712041856064512,
              40.86451460346008
            ]
          },
          "bbox": [
            -1.6712041856064512,
            40.86451460346008,
            -1.6712041856064512,
            40.86451460346008
          ]
        }
      ],
      "bbox": [
        -1.6712041856064512,
        40.86451460346008,
        -1.6712041856064512,
        40.86451460346008
      ]
    },
    "19060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3276444391811144,
              41.0546299672273
            ]
          },
          "bbox": [
            -3.3276444391811144,
            41.0546299672273,
            -3.3276444391811144,
            41.0546299672273
          ]
        }
      ],
      "bbox": [
        -3.3276444391811144,
        41.0546299672273,
        -3.3276444391811144,
        41.0546299672273
      ]
    },
    "19061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1404264245885996,
              41.26146364671928
            ]
          },
          "bbox": [
            -3.1404264245885996,
            41.26146364671928,
            -3.1404264245885996,
            41.26146364671928
          ]
        }
      ],
      "bbox": [
        -3.1404264245885996,
        41.26146364671928,
        -3.1404264245885996,
        41.26146364671928
      ]
    },
    "19064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.509229303010452,
              40.799681161037185
            ]
          },
          "bbox": [
            -2.509229303010452,
            40.799681161037185,
            -2.509229303010452,
            40.799681161037185
          ]
        }
      ],
      "bbox": [
        -2.509229303010452,
        40.799681161037185,
        -2.509229303010452,
        40.799681161037185
      ]
    },
    "19065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.292894324495573,
              41.22649741222225
            ]
          },
          "bbox": [
            -3.292894324495573,
            41.22649741222225,
            -3.292894324495573,
            41.22649741222225
          ]
        }
      ],
      "bbox": [
        -3.292894324495573,
        41.22649741222225,
        -3.292894324495573,
        41.22649741222225
      ]
    },
    "19066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0701492600194293,
              40.77392370495754
            ]
          },
          "bbox": [
            -3.0701492600194293,
            40.77392370495754,
            -3.0701492600194293,
            40.77392370495754
          ]
        }
      ],
      "bbox": [
        -3.0701492600194293,
        40.77392370495754,
        -3.0701492600194293,
        40.77392370495754
      ]
    },
    "19067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.42686691979754,
              41.12773499582456
            ]
          },
          "bbox": [
            -3.42686691979754,
            41.12773499582456,
            -3.42686691979754,
            41.12773499582456
          ]
        }
      ],
      "bbox": [
        -3.42686691979754,
        41.12773499582456,
        -3.42686691979754,
        41.12773499582456
      ]
    },
    "19070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.365725143782984,
              40.848434294798814
            ]
          },
          "bbox": [
            -3.365725143782984,
            40.848434294798814,
            -3.365725143782984,
            40.848434294798814
          ]
        }
      ],
      "bbox": [
        -3.365725143782984,
        40.848434294798814,
        -3.365725143782984,
        40.848434294798814
      ]
    },
    "19071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4226317212052395,
              40.728059440283594
            ]
          },
          "bbox": [
            -3.4226317212052395,
            40.728059440283594,
            -3.4226317212052395,
            40.728059440283594
          ]
        }
      ],
      "bbox": [
        -3.4226317212052395,
        40.728059440283594,
        -3.4226317212052395,
        40.728059440283594
      ]
    },
    "19073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.963921972267786,
              40.87278577503065
            ]
          },
          "bbox": [
            -2.963921972267786,
            40.87278577503065,
            -2.963921972267786,
            40.87278577503065
          ]
        }
      ],
      "bbox": [
        -2.963921972267786,
        40.87278577503065,
        -2.963921972267786,
        40.87278577503065
      ]
    },
    "19074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.973993298841894,
              40.69996171133358
            ]
          },
          "bbox": [
            -2.973993298841894,
            40.69996171133358,
            -2.973993298841894,
            40.69996171133358
          ]
        }
      ],
      "bbox": [
        -2.973993298841894,
        40.69996171133358,
        -2.973993298841894,
        40.69996171133358
      ]
    },
    "19075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7867409674493904,
              40.93187758215699
            ]
          },
          "bbox": [
            -2.7867409674493904,
            40.93187758215699,
            -2.7867409674493904,
            40.93187758215699
          ]
        }
      ],
      "bbox": [
        -2.7867409674493904,
        40.93187758215699,
        -2.7867409674493904,
        40.93187758215699
      ]
    },
    "19076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7487756825309346,
              40.82661856698453
            ]
          },
          "bbox": [
            -1.7487756825309346,
            40.82661856698453,
            -1.7487756825309346,
            40.82661856698453
          ]
        }
      ],
      "bbox": [
        -1.7487756825309346,
        40.82661856698453,
        -1.7487756825309346,
        40.82661856698453
      ]
    },
    "19078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4306441804696832,
              40.55993549011293
            ]
          },
          "bbox": [
            -2.4306441804696832,
            40.55993549011293,
            -2.4306441804696832,
            40.55993549011293
          ]
        }
      ],
      "bbox": [
        -2.4306441804696832,
        40.55993549011293,
        -2.4306441804696832,
        40.55993549011293
      ]
    },
    "19079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8710099535800873,
              40.801180162193326
            ]
          },
          "bbox": [
            -1.8710099535800873,
            40.801180162193326,
            -1.8710099535800873,
            40.801180162193326
          ]
        }
      ],
      "bbox": [
        -1.8710099535800873,
        40.801180162193326,
        -1.8710099535800873,
        40.801180162193326
      ]
    },
    "19080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.874842411886907,
              40.99031199203346
            ]
          },
          "bbox": [
            -2.874842411886907,
            40.99031199203346,
            -2.874842411886907,
            40.99031199203346
          ]
        }
      ],
      "bbox": [
        -2.874842411886907,
        40.99031199203346,
        -2.874842411886907,
        40.99031199203346
      ]
    },
    "19081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8430257517922883,
              40.97829807773793
            ]
          },
          "bbox": [
            -2.8430257517922883,
            40.97829807773793,
            -2.8430257517922883,
            40.97829807773793
          ]
        }
      ],
      "bbox": [
        -2.8430257517922883,
        40.97829807773793,
        -2.8430257517922883,
        40.97829807773793
      ]
    },
    "19082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.057756116668323,
              40.65091330226454
            ]
          },
          "bbox": [
            -3.057756116668323,
            40.65091330226454,
            -3.057756116668323,
            40.65091330226454
          ]
        }
      ],
      "bbox": [
        -3.057756116668323,
        40.65091330226454,
        -3.057756116668323,
        40.65091330226454
      ]
    },
    "19086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5860409287452555,
              40.749784496531156
            ]
          },
          "bbox": [
            -2.5860409287452555,
            40.749784496531156,
            -2.5860409287452555,
            40.749784496531156
          ]
        }
      ],
      "bbox": [
        -2.5860409287452555,
        40.749784496531156,
        -2.5860409287452555,
        40.749784496531156
      ]
    },
    "19087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8177148323574546,
              41.1982148271394
            ]
          },
          "bbox": [
            -2.8177148323574546,
            41.1982148271394,
            -2.8177148323574546,
            41.1982148271394
          ]
        }
      ],
      "bbox": [
        -2.8177148323574546,
        41.1982148271394,
        -2.8177148323574546,
        41.1982148271394
      ]
    },
    "19088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1047157014809508,
              40.75089286643534
            ]
          },
          "bbox": [
            -3.1047157014809508,
            40.75089286643534,
            -3.1047157014809508,
            40.75089286643534
          ]
        }
      ],
      "bbox": [
        -3.1047157014809508,
        40.75089286643534,
        -3.1047157014809508,
        40.75089286643534
      ]
    },
    "19089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.219678876328276,
              41.003144323801784
            ]
          },
          "bbox": [
            -2.219678876328276,
            41.003144323801784,
            -2.219678876328276,
            41.003144323801784
          ]
        }
      ],
      "bbox": [
        -2.219678876328276,
        41.003144323801784,
        -2.219678876328276,
        41.003144323801784
      ]
    },
    "19090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1380921140833946,
              40.87587550409744
            ]
          },
          "bbox": [
            -2.1380921140833946,
            40.87587550409744,
            -2.1380921140833946,
            40.87587550409744
          ]
        }
      ],
      "bbox": [
        -2.1380921140833946,
        40.87587550409744,
        -2.1380921140833946,
        40.87587550409744
      ]
    },
    "19091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.737613513420414,
              40.84233091651213
            ]
          },
          "bbox": [
            -2.737613513420414,
            40.84233091651213,
            -2.737613513420414,
            40.84233091651213
          ]
        }
      ],
      "bbox": [
        -2.737613513420414,
        40.84233091651213,
        -2.737613513420414,
        40.84233091651213
      ]
    },
    "19092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.126624831621653,
              40.93905963508803
            ]
          },
          "bbox": [
            -3.126624831621653,
            40.93905963508803,
            -3.126624831621653,
            40.93905963508803
          ]
        }
      ],
      "bbox": [
        -3.126624831621653,
        40.93905963508803,
        -3.126624831621653,
        40.93905963508803
      ]
    },
    "19095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1011459361665343,
              41.21355317707779
            ]
          },
          "bbox": [
            -3.1011459361665343,
            41.21355317707779,
            -3.1011459361665343,
            41.21355317707779
          ]
        }
      ],
      "bbox": [
        -3.1011459361665343,
        41.21355317707779,
        -3.1011459361665343,
        41.21355317707779
      ]
    },
    "19096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1107873748717,
              41.189323918084774
            ]
          },
          "bbox": [
            -3.1107873748717,
            41.189323918084774,
            -3.1107873748717,
            41.189323918084774
          ]
        }
      ],
      "bbox": [
        -3.1107873748717,
        41.189323918084774,
        -3.1107873748717,
        41.189323918084774
      ]
    },
    "19097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.977163646730391,
              41.04718233543448
            ]
          },
          "bbox": [
            -2.977163646730391,
            41.04718233543448,
            -2.977163646730391,
            41.04718233543448
          ]
        }
      ],
      "bbox": [
        -2.977163646730391,
        41.04718233543448,
        -2.977163646730391,
        41.04718233543448
      ]
    },
    "19098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.052365151065348,
              40.869586541232366
            ]
          },
          "bbox": [
            -3.052365151065348,
            40.869586541232366,
            -3.052365151065348,
            40.869586541232366
          ]
        }
      ],
      "bbox": [
        -3.052365151065348,
        40.869586541232366,
        -3.052365151065348,
        40.869586541232366
      ]
    },
    "19099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0242389467126025,
              40.84248159603363
            ]
          },
          "bbox": [
            -2.0242389467126025,
            40.84248159603363,
            -2.0242389467126025,
            40.84248159603363
          ]
        }
      ],
      "bbox": [
        -2.0242389467126025,
        40.84248159603363,
        -2.0242389467126025,
        40.84248159603363
      ]
    },
    "19102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4105167700169843,
              40.813584469369616
            ]
          },
          "bbox": [
            -3.4105167700169843,
            40.813584469369616,
            -3.4105167700169843,
            40.813584469369616
          ]
        }
      ],
      "bbox": [
        -3.4105167700169843,
        40.813584469369616,
        -3.4105167700169843,
        40.813584469369616
      ]
    },
    "19103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8104870224444558,
              40.509355738469715
            ]
          },
          "bbox": [
            -1.8104870224444558,
            40.509355738469715,
            -1.8104870224444558,
            40.509355738469715
          ]
        }
      ],
      "bbox": [
        -1.8104870224444558,
        40.509355738469715,
        -1.8104870224444558,
        40.509355738469715
      ]
    },
    "19104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8325196421291334,
              40.603602384195355
            ]
          },
          "bbox": [
            -1.8325196421291334,
            40.603602384195355,
            -1.8325196421291334,
            40.603602384195355
          ]
        }
      ],
      "bbox": [
        -1.8325196421291334,
        40.603602384195355,
        -1.8325196421291334,
        40.603602384195355
      ]
    },
    "19105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.194619805121645,
              40.57030669378514
            ]
          },
          "bbox": [
            -3.194619805121645,
            40.57030669378514,
            -3.194619805121645,
            40.57030669378514
          ]
        }
      ],
      "bbox": [
        -3.194619805121645,
        40.57030669378514,
        -3.194619805121645,
        40.57030669378514
      ]
    },
    "19106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.699838264736235,
              40.5918284719143
            ]
          },
          "bbox": [
            -2.699838264736235,
            40.5918284719143,
            -2.699838264736235,
            40.5918284719143
          ]
        }
      ],
      "bbox": [
        -2.699838264736235,
        40.5918284719143,
        -2.699838264736235,
        40.5918284719143
      ]
    },
    "19107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0430731827765336,
              40.23799756496252
            ]
          },
          "bbox": [
            -3.0430731827765336,
            40.23799756496252,
            -3.0430731827765336,
            40.23799756496252
          ]
        }
      ],
      "bbox": [
        -3.0430731827765336,
        40.23799756496252,
        -3.0430731827765336,
        40.23799756496252
      ]
    },
    "19108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.714981034450834,
              40.63372549332376
            ]
          },
          "bbox": [
            -2.714981034450834,
            40.63372549332376,
            -2.714981034450834,
            40.63372549332376
          ]
        }
      ],
      "bbox": [
        -2.714981034450834,
        40.63372549332376,
        -2.714981034450834,
        40.63372549332376
      ]
    },
    "19109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7136949769871026,
              40.97431906700111
            ]
          },
          "bbox": [
            -1.7136949769871026,
            40.97431906700111,
            -1.7136949769871026,
            40.97431906700111
          ]
        }
      ],
      "bbox": [
        -1.7136949769871026,
        40.97431906700111,
        -1.7136949769871026,
        40.97431906700111
      ]
    },
    "19110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.566357521219958,
              40.55391067552074
            ]
          },
          "bbox": [
            -2.566357521219958,
            40.55391067552074,
            -2.566357521219958,
            40.55391067552074
          ]
        }
      ],
      "bbox": [
        -2.566357521219958,
        40.55391067552074,
        -2.566357521219958,
        40.55391067552074
      ]
    },
    "19111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.060532654695906,
              40.400934304623284
            ]
          },
          "bbox": [
            -3.060532654695906,
            40.400934304623284,
            -3.060532654695906,
            40.400934304623284
          ]
        }
      ],
      "bbox": [
        -3.060532654695906,
        40.400934304623284,
        -3.060532654695906,
        40.400934304623284
      ]
    },
    "19112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0034082484719433,
              40.4159806529697
            ]
          },
          "bbox": [
            -3.0034082484719433,
            40.4159806529697,
            -3.0034082484719433,
            40.4159806529697
          ]
        }
      ],
      "bbox": [
        -3.0034082484719433,
        40.4159806529697,
        -3.0034082484719433,
        40.4159806529697
      ]
    },
    "19113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.046050025189085,
              40.89858654784861
            ]
          },
          "bbox": [
            -3.046050025189085,
            40.89858654784861,
            -3.046050025189085,
            40.89858654784861
          ]
        }
      ],
      "bbox": [
        -3.046050025189085,
        40.89858654784861,
        -3.046050025189085,
        40.89858654784861
      ]
    },
    "19114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.369629010099917,
              40.85965886382978
            ]
          },
          "bbox": [
            -2.369629010099917,
            40.85965886382978,
            -2.369629010099917,
            40.85965886382978
          ]
        }
      ],
      "bbox": [
        -2.369629010099917,
        40.85965886382978,
        -2.369629010099917,
        40.85965886382978
      ]
    },
    "19115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.025675124921282,
              41.00595297350641
            ]
          },
          "bbox": [
            -2.025675124921282,
            41.00595297350641,
            -2.025675124921282,
            41.00595297350641
          ]
        }
      ],
      "bbox": [
        -2.025675124921282,
        41.00595297350641,
        -2.025675124921282,
        41.00595297350641
      ]
    },
    "19116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.529787122905637,
              41.063817170902155
            ]
          },
          "bbox": [
            -2.529787122905637,
            41.063817170902155,
            -2.529787122905637,
            41.063817170902155
          ]
        }
      ],
      "bbox": [
        -2.529787122905637,
        41.063817170902155,
        -2.529787122905637,
        41.063817170902155
      ]
    },
    "19117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1883276421371747,
              40.72902871555581
            ]
          },
          "bbox": [
            -3.1883276421371747,
            40.72902871555581,
            -3.1883276421371747,
            40.72902871555581
          ]
        }
      ],
      "bbox": [
        -3.1883276421371747,
        40.72902871555581,
        -3.1883276421371747,
        40.72902871555581
      ]
    },
    "19118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.02774400133837,
              40.74058504372195
            ]
          },
          "bbox": [
            -2.02774400133837,
            40.74058504372195,
            -2.02774400133837,
            40.74058504372195
          ]
        }
      ],
      "bbox": [
        -2.02774400133837,
        40.74058504372195,
        -2.02774400133837,
        40.74058504372195
      ]
    },
    "19119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0959326408095382,
              40.920296475604786
            ]
          },
          "bbox": [
            -3.0959326408095382,
            40.920296475604786,
            -3.0959326408095382,
            40.920296475604786
          ]
        }
      ],
      "bbox": [
        -3.0959326408095382,
        40.920296475604786,
        -3.0959326408095382,
        40.920296475604786
      ]
    },
    "19120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3110789568786587,
              40.7803533082768
            ]
          },
          "bbox": [
            -3.3110789568786587,
            40.7803533082768,
            -3.3110789568786587,
            40.7803533082768
          ]
        }
      ],
      "bbox": [
        -3.3110789568786587,
        40.7803533082768,
        -3.3110789568786587,
        40.7803533082768
      ]
    },
    "19121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.873203377166568,
              40.507096639106216
            ]
          },
          "bbox": [
            -2.873203377166568,
            40.507096639106216,
            -2.873203377166568,
            40.507096639106216
          ]
        }
      ],
      "bbox": [
        -2.873203377166568,
        40.507096639106216,
        -2.873203377166568,
        40.507096639106216
      ]
    },
    "19122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8192636037494498,
              41.05516814424602
            ]
          },
          "bbox": [
            -1.8192636037494498,
            41.05516814424602,
            -1.8192636037494498,
            41.05516814424602
          ]
        }
      ],
      "bbox": [
        -1.8192636037494498,
        41.05516814424602,
        -1.8192636037494498,
        41.05516814424602
      ]
    },
    "19123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.99003321438132,
              40.52023079760435
            ]
          },
          "bbox": [
            -2.99003321438132,
            40.52023079760435,
            -2.99003321438132,
            40.52023079760435
          ]
        }
      ],
      "bbox": [
        -2.99003321438132,
        40.52023079760435,
        -2.99003321438132,
        40.52023079760435
      ]
    },
    "19124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1031522270325245,
              40.37443335271834
            ]
          },
          "bbox": [
            -3.1031522270325245,
            40.37443335271834,
            -3.1031522270325245,
            40.37443335271834
          ]
        }
      ],
      "bbox": [
        -3.1031522270325245,
        40.37443335271834,
        -3.1031522270325245,
        40.37443335271834
      ]
    },
    "19125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8872189003492146,
              40.83087824038759
            ]
          },
          "bbox": [
            -2.8872189003492146,
            40.83087824038759,
            -2.8872189003492146,
            40.83087824038759
          ]
        }
      ],
      "bbox": [
        -2.8872189003492146,
        40.83087824038759,
        -2.8872189003492146,
        40.83087824038759
      ]
    },
    "19126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3506277404167553,
              40.708833828839055
            ]
          },
          "bbox": [
            -3.3506277404167553,
            40.708833828839055,
            -3.3506277404167553,
            40.708833828839055
          ]
        }
      ],
      "bbox": [
        -3.3506277404167553,
        40.708833828839055,
        -3.3506277404167553,
        40.708833828839055
      ]
    },
    "19127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1968699962654124,
              41.20577730028822
            ]
          },
          "bbox": [
            -3.1968699962654124,
            41.20577730028822,
            -3.1968699962654124,
            41.20577730028822
          ]
        }
      ],
      "bbox": [
        -3.1968699962654124,
        41.20577730028822,
        -3.1968699962654124,
        41.20577730028822
      ]
    },
    "19129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.009053614882283,
              41.14049496608223
            ]
          },
          "bbox": [
            -3.009053614882283,
            41.14049496608223,
            -3.009053614882283,
            41.14049496608223
          ]
        }
      ],
      "bbox": [
        -3.009053614882283,
        41.14049496608223,
        -3.009053614882283,
        41.14049496608223
      ]
    },
    "19130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.175767830277135,
              40.64010028989455
            ]
          },
          "bbox": [
            -3.175767830277135,
            40.64010028989455,
            -3.175767830277135,
            40.64010028989455
          ]
        }
      ],
      "bbox": [
        -3.175767830277135,
        40.64010028989455,
        -3.175767830277135,
        40.64010028989455
      ]
    },
    "19132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.705028439824915,
              40.71774427361072
            ]
          },
          "bbox": [
            -2.705028439824915,
            40.71774427361072,
            -2.705028439824915,
            40.71774427361072
          ]
        }
      ],
      "bbox": [
        -2.705028439824915,
        40.71774427361072,
        -2.705028439824915,
        40.71774427361072
      ]
    },
    "19133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1139464054717663,
              40.78711198489961
            ]
          },
          "bbox": [
            -3.1139464054717663,
            40.78711198489961,
            -3.1139464054717663,
            40.78711198489961
          ]
        }
      ],
      "bbox": [
        -3.1139464054717663,
        40.78711198489961,
        -3.1139464054717663,
        40.78711198489961
      ]
    },
    "19134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9568613854476793,
              40.89472593065648
            ]
          },
          "bbox": [
            -1.9568613854476793,
            40.89472593065648,
            -1.9568613854476793,
            40.89472593065648
          ]
        }
      ],
      "bbox": [
        -1.9568613854476793,
        40.89472593065648,
        -1.9568613854476793,
        40.89472593065648
      ]
    },
    "19135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9969129401247874,
              41.080144085260656
            ]
          },
          "bbox": [
            -2.9969129401247874,
            41.080144085260656,
            -2.9969129401247874,
            41.080144085260656
          ]
        }
      ],
      "bbox": [
        -2.9969129401247874,
        41.080144085260656,
        -2.9969129401247874,
        41.080144085260656
      ]
    },
    "19136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0163873932259397,
              41.261660037366156
            ]
          },
          "bbox": [
            -3.0163873932259397,
            41.261660037366156,
            -3.0163873932259397,
            41.261660037366156
          ]
        }
      ],
      "bbox": [
        -3.0163873932259397,
        41.261660037366156,
        -3.0163873932259397,
        41.261660037366156
      ]
    },
    "19138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0287124279550004,
              40.84263029447874
            ]
          },
          "bbox": [
            -3.0287124279550004,
            40.84263029447874,
            -3.0287124279550004,
            40.84263029447874
          ]
        }
      ],
      "bbox": [
        -3.0287124279550004,
        40.84263029447874,
        -3.0287124279550004,
        40.84263029447874
      ]
    },
    "19139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6654666366671504,
              40.81906074499737
            ]
          },
          "bbox": [
            -1.6654666366671504,
            40.81906074499737,
            -1.6654666366671504,
            40.81906074499737
          ]
        }
      ],
      "bbox": [
        -1.6654666366671504,
        40.81906074499737,
        -1.6654666366671504,
        40.81906074499737
      ]
    },
    "19142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0393293877872054,
              40.447715216595824
            ]
          },
          "bbox": [
            -3.0393293877872054,
            40.447715216595824,
            -3.0393293877872054,
            40.447715216595824
          ]
        }
      ],
      "bbox": [
        -3.0393293877872054,
        40.447715216595824,
        -3.0393293877872054,
        40.447715216595824
      ]
    },
    "19143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0655417186758553,
              40.566827769919385
            ]
          },
          "bbox": [
            -3.0655417186758553,
            40.566827769919385,
            -3.0655417186758553,
            40.566827769919385
          ]
        }
      ],
      "bbox": [
        -3.0655417186758553,
        40.566827769919385,
        -3.0655417186758553,
        40.566827769919385
      ]
    },
    "19145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4087211381547124,
              40.9557787687967
            ]
          },
          "bbox": [
            -2.4087211381547124,
            40.9557787687967,
            -2.4087211381547124,
            40.9557787687967
          ]
        }
      ],
      "bbox": [
        -2.4087211381547124,
        40.9557787687967,
        -2.4087211381547124,
        40.9557787687967
      ]
    },
    "19146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1707765661826466,
              41.140095864045406
            ]
          },
          "bbox": [
            -3.1707765661826466,
            41.140095864045406,
            -3.1707765661826466,
            41.140095864045406
          ]
        }
      ],
      "bbox": [
        -3.1707765661826466,
        41.140095864045406,
        -3.1707765661826466,
        41.140095864045406
      ]
    },
    "19147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7905452403371642,
              41.052790067205585
            ]
          },
          "bbox": [
            -2.7905452403371642,
            41.052790067205585,
            -2.7905452403371642,
            41.052790067205585
          ]
        }
      ],
      "bbox": [
        -2.7905452403371642,
        41.052790067205585,
        -2.7905452403371642,
        41.052790067205585
      ]
    },
    "19148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.288336692421006,
              40.82324281140729
            ]
          },
          "bbox": [
            -2.288336692421006,
            40.82324281140729,
            -2.288336692421006,
            40.82324281140729
          ]
        }
      ],
      "bbox": [
        -2.288336692421006,
        40.82324281140729,
        -2.288336692421006,
        40.82324281140729
      ]
    },
    "19150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9618985459287317,
              40.46267063597274
            ]
          },
          "bbox": [
            -2.9618985459287317,
            40.46267063597274,
            -2.9618985459287317,
            40.46267063597274
          ]
        }
      ],
      "bbox": [
        -2.9618985459287317,
        40.46267063597274,
        -2.9618985459287317,
        40.46267063597274
      ]
    },
    "19151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1536098747572168,
              40.84958829361841
            ]
          },
          "bbox": [
            -3.1536098747572168,
            40.84958829361841,
            -3.1536098747572168,
            40.84958829361841
          ]
        }
      ],
      "bbox": [
        -3.1536098747572168,
        40.84958829361841,
        -3.1536098747572168,
        40.84958829361841
      ]
    },
    "19152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.950642516320187,
              40.19626754288573
            ]
          },
          "bbox": [
            -2.950642516320187,
            40.19626754288573,
            -2.950642516320187,
            40.19626754288573
          ]
        }
      ],
      "bbox": [
        -2.950642516320187,
        40.19626754288573,
        -2.950642516320187,
        40.19626754288573
      ]
    },
    "19153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.370626338011339,
              40.991753493050204
            ]
          },
          "bbox": [
            -2.370626338011339,
            40.991753493050204,
            -2.370626338011339,
            40.991753493050204
          ]
        }
      ],
      "bbox": [
        -2.370626338011339,
        40.991753493050204,
        -2.370626338011339,
        40.991753493050204
      ]
    },
    "19154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.670248857501722,
              40.866782003416944
            ]
          },
          "bbox": [
            -2.670248857501722,
            40.866782003416944,
            -2.670248857501722,
            40.866782003416944
          ]
        }
      ],
      "bbox": [
        -2.670248857501722,
        40.866782003416944,
        -2.670248857501722,
        40.866782003416944
      ]
    },
    "19155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.89340633337932,
              40.607560854093805
            ]
          },
          "bbox": [
            -2.89340633337932,
            40.607560854093805,
            -2.89340633337932,
            40.607560854093805
          ]
        }
      ],
      "bbox": [
        -2.89340633337932,
        40.607560854093805,
        -2.89340633337932,
        40.607560854093805
      ]
    },
    "19156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9359912004364874,
              40.92894605164682
            ]
          },
          "bbox": [
            -2.9359912004364874,
            40.92894605164682,
            -2.9359912004364874,
            40.92894605164682
          ]
        }
      ],
      "bbox": [
        -2.9359912004364874,
        40.92894605164682,
        -2.9359912004364874,
        40.92894605164682
      ]
    },
    "19157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.906595552490901,
              40.96617961338164
            ]
          },
          "bbox": [
            -2.906595552490901,
            40.96617961338164,
            -2.906595552490901,
            40.96617961338164
          ]
        }
      ],
      "bbox": [
        -2.906595552490901,
        40.96617961338164,
        -2.906595552490901,
        40.96617961338164
      ]
    },
    "19159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.853363273240588,
              40.85996376852581
            ]
          },
          "bbox": [
            -2.853363273240588,
            40.85996376852581,
            -2.853363273240588,
            40.85996376852581
          ]
        }
      ],
      "bbox": [
        -2.853363273240588,
        40.85996376852581,
        -2.853363273240588,
        40.85996376852581
      ]
    },
    "19160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.115516995461076,
              40.440893425734636
            ]
          },
          "bbox": [
            -3.115516995461076,
            40.440893425734636,
            -3.115516995461076,
            40.440893425734636
          ]
        }
      ],
      "bbox": [
        -3.115516995461076,
        40.440893425734636,
        -3.115516995461076,
        40.440893425734636
      ]
    },
    "19161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.046298138160495,
              40.61466412769681
            ]
          },
          "bbox": [
            -3.046298138160495,
            40.61466412769681,
            -3.046298138160495,
            40.61466412769681
          ]
        }
      ],
      "bbox": [
        -3.046298138160495,
        40.61466412769681,
        -3.046298138160495,
        40.61466412769681
      ]
    },
    "19162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4470993392202796,
              40.967212411420554
            ]
          },
          "bbox": [
            -2.4470993392202796,
            40.967212411420554,
            -2.4470993392202796,
            40.967212411420554
          ]
        }
      ],
      "bbox": [
        -2.4470993392202796,
        40.967212411420554,
        -2.4470993392202796,
        40.967212411420554
      ]
    },
    "19163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.279331675603243,
              41.02905125605052
            ]
          },
          "bbox": [
            -2.279331675603243,
            41.02905125605052,
            -2.279331675603243,
            41.02905125605052
          ]
        }
      ],
      "bbox": [
        -2.279331675603243,
        41.02905125605052,
        -2.279331675603243,
        41.02905125605052
      ]
    },
    "19165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3229850581458686,
              41.13980355559128
            ]
          },
          "bbox": [
            -3.3229850581458686,
            41.13980355559128,
            -3.3229850581458686,
            41.13980355559128
          ]
        }
      ],
      "bbox": [
        -3.3229850581458686,
        41.13980355559128,
        -3.3229850581458686,
        41.13980355559128
      ]
    },
    "19166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2467339075120214,
              40.78526645408042
            ]
          },
          "bbox": [
            -3.2467339075120214,
            40.78526645408042,
            -3.2467339075120214,
            40.78526645408042
          ]
        }
      ],
      "bbox": [
        -3.2467339075120214,
        40.78526645408042,
        -3.2467339075120214,
        40.78526645408042
      ]
    },
    "19167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.264402860810169,
              40.83275190411803
            ]
          },
          "bbox": [
            -3.264402860810169,
            40.83275190411803,
            -3.264402860810169,
            40.83275190411803
          ]
        }
      ],
      "bbox": [
        -3.264402860810169,
        40.83275190411803,
        -3.264402860810169,
        40.83275190411803
      ]
    },
    "19168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7389532946319295,
              40.96120713378741
            ]
          },
          "bbox": [
            -2.7389532946319295,
            40.96120713378741,
            -2.7389532946319295,
            40.96120713378741
          ]
        }
      ],
      "bbox": [
        -2.7389532946319295,
        40.96120713378741,
        -2.7389532946319295,
        40.96120713378741
      ]
    },
    "19169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.669000301441034,
              40.6255085874033
            ]
          },
          "bbox": [
            -2.669000301441034,
            40.6255085874033,
            -2.669000301441034,
            40.6255085874033
          ]
        }
      ],
      "bbox": [
        -2.669000301441034,
        40.6255085874033,
        -2.669000301441034,
        40.6255085874033
      ]
    },
    "19170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.136330091297333,
              41.05126810458276
            ]
          },
          "bbox": [
            -2.136330091297333,
            41.05126810458276,
            -2.136330091297333,
            41.05126810458276
          ]
        }
      ],
      "bbox": [
        -2.136330091297333,
        41.05126810458276,
        -2.136330091297333,
        41.05126810458276
      ]
    },
    "19171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2094410989953586,
              40.68122805688659
            ]
          },
          "bbox": [
            -3.2094410989953586,
            40.68122805688659,
            -3.2094410989953586,
            40.68122805688659
          ]
        }
      ],
      "bbox": [
        -3.2094410989953586,
        40.68122805688659,
        -3.2094410989953586,
        40.68122805688659
      ]
    },
    "19172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.698984267882083,
              40.82566981656015
            ]
          },
          "bbox": [
            -2.698984267882083,
            40.82566981656015,
            -2.698984267882083,
            40.82566981656015
          ]
        }
      ],
      "bbox": [
        -2.698984267882083,
        40.82566981656015,
        -2.698984267882083,
        40.82566981656015
      ]
    },
    "19173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3003259621973107,
              40.8736333533331
            ]
          },
          "bbox": [
            -3.3003259621973107,
            40.8736333533331,
            -3.3003259621973107,
            40.8736333533331
          ]
        }
      ],
      "bbox": [
        -3.3003259621973107,
        40.8736333533331,
        -3.3003259621973107,
        40.8736333533331
      ]
    },
    "19174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.833860109878576,
              40.94149268344071
            ]
          },
          "bbox": [
            -2.833860109878576,
            40.94149268344071,
            -2.833860109878576,
            40.94149268344071
          ]
        }
      ],
      "bbox": [
        -2.833860109878576,
        40.94149268344071,
        -2.833860109878576,
        40.94149268344071
      ]
    },
    "19175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1859371113127786,
              40.97847507629734
            ]
          },
          "bbox": [
            -2.1859371113127786,
            40.97847507629734,
            -2.1859371113127786,
            40.97847507629734
          ]
        }
      ],
      "bbox": [
        -2.1859371113127786,
        40.97847507629734,
        -2.1859371113127786,
        40.97847507629734
      ]
    },
    "19176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.019674994479983,
              40.26239529476043
            ]
          },
          "bbox": [
            -3.019674994479983,
            40.26239529476043,
            -3.019674994479983,
            40.26239529476043
          ]
        }
      ],
      "bbox": [
        -3.019674994479983,
        40.26239529476043,
        -3.019674994479983,
        40.26239529476043
      ]
    },
    "19177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9384159102298026,
              40.985513088203604
            ]
          },
          "bbox": [
            -2.9384159102298026,
            40.985513088203604,
            -2.9384159102298026,
            40.985513088203604
          ]
        }
      ],
      "bbox": [
        -2.9384159102298026,
        40.985513088203604,
        -2.9384159102298026,
        40.985513088203604
      ]
    },
    "19178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8645728504740433,
              40.63522905525586
            ]
          },
          "bbox": [
            -1.8645728504740433,
            40.63522905525586,
            -1.8645728504740433,
            40.63522905525586
          ]
        }
      ],
      "bbox": [
        -1.8645728504740433,
        40.63522905525586,
        -1.8645728504740433,
        40.63522905525586
      ]
    },
    "19179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.995578991114289,
              40.94599608796802
            ]
          },
          "bbox": [
            -2.995578991114289,
            40.94599608796802,
            -2.995578991114289,
            40.94599608796802
          ]
        }
      ],
      "bbox": [
        -2.995578991114289,
        40.94599608796802,
        -2.995578991114289,
        40.94599608796802
      ]
    },
    "19181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9623101163534202,
              41.25432263439524
            ]
          },
          "bbox": [
            -2.9623101163534202,
            41.25432263439524,
            -2.9623101163534202,
            41.25432263439524
          ]
        }
      ],
      "bbox": [
        -2.9623101163534202,
        41.25432263439524,
        -2.9623101163534202,
        41.25432263439524
      ]
    },
    "19182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.24163055647685,
              40.93957058905545
            ]
          },
          "bbox": [
            -3.24163055647685,
            40.93957058905545,
            -3.24163055647685,
            40.93957058905545
          ]
        }
      ],
      "bbox": [
        -3.24163055647685,
        40.93957058905545,
        -3.24163055647685,
        40.93957058905545
      ]
    },
    "19183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8815739569240382,
              41.091862314364526
            ]
          },
          "bbox": [
            -1.8815739569240382,
            41.091862314364526,
            -1.8815739569240382,
            41.091862314364526
          ]
        }
      ],
      "bbox": [
        -1.8815739569240382,
        41.091862314364526,
        -1.8815739569240382,
        41.091862314364526
      ]
    },
    "19184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.575377028713859,
              40.505245370637276
            ]
          },
          "bbox": [
            -2.575377028713859,
            40.505245370637276,
            -2.575377028713859,
            40.505245370637276
          ]
        }
      ],
      "bbox": [
        -2.575377028713859,
        40.505245370637276,
        -2.575377028713859,
        40.505245370637276
      ]
    },
    "19185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.937038712145201,
              41.18420772959473
            ]
          },
          "bbox": [
            -2.937038712145201,
            41.18420772959473,
            -2.937038712145201,
            41.18420772959473
          ]
        }
      ],
      "bbox": [
        -2.937038712145201,
        41.18420772959473,
        -2.937038712145201,
        41.18420772959473
      ]
    },
    "19186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7116194069931336,
              40.926293004263506
            ]
          },
          "bbox": [
            -2.7116194069931336,
            40.926293004263506,
            -2.7116194069931336,
            40.926293004263506
          ]
        }
      ],
      "bbox": [
        -2.7116194069931336,
        40.926293004263506,
        -2.7116194069931336,
        40.926293004263506
      ]
    },
    "19187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9363953668205394,
              40.88943349104523
            ]
          },
          "bbox": [
            -2.9363953668205394,
            40.88943349104523,
            -2.9363953668205394,
            40.88943349104523
          ]
        }
      ],
      "bbox": [
        -2.9363953668205394,
        40.88943349104523,
        -2.9363953668205394,
        40.88943349104523
      ]
    },
    "19188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.030263658875194,
              41.09534922562875
            ]
          },
          "bbox": [
            -2.030263658875194,
            41.09534922562875,
            -2.030263658875194,
            41.09534922562875
          ]
        }
      ],
      "bbox": [
        -2.030263658875194,
        41.09534922562875,
        -2.030263658875194,
        41.09534922562875
      ]
    },
    "19189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1737881369752556,
              40.798579092148266
            ]
          },
          "bbox": [
            -3.1737881369752556,
            40.798579092148266,
            -3.1737881369752556,
            40.798579092148266
          ]
        }
      ],
      "bbox": [
        -3.1737881369752556,
        40.798579092148266,
        -3.1737881369752556,
        40.798579092148266
      ]
    },
    "19190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8082758324977515,
              40.873885166744785
            ]
          },
          "bbox": [
            -1.8082758324977515,
            40.873885166744785,
            -1.8082758324977515,
            40.873885166744785
          ]
        }
      ],
      "bbox": [
        -1.8082758324977515,
        40.873885166744785,
        -1.8082758324977515,
        40.873885166744785
      ]
    },
    "19191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1136145500769565,
              40.99757475655775
            ]
          },
          "bbox": [
            -3.1136145500769565,
            40.99757475655775,
            -3.1136145500769565,
            40.99757475655775
          ]
        }
      ],
      "bbox": [
        -3.1136145500769565,
        40.99757475655775,
        -3.1136145500769565,
        40.99757475655775
      ]
    },
    "19192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1182562897456534,
              40.31894056262213
            ]
          },
          "bbox": [
            -3.1182562897456534,
            40.31894056262213,
            -3.1182562897456534,
            40.31894056262213
          ]
        }
      ],
      "bbox": [
        -3.1182562897456534,
        40.31894056262213,
        -3.1182562897456534,
        40.31894056262213
      ]
    },
    "19193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1173842247880756,
              40.90392120935163
            ]
          },
          "bbox": [
            -3.1173842247880756,
            40.90392120935163,
            -3.1173842247880756,
            40.90392120935163
          ]
        }
      ],
      "bbox": [
        -3.1173842247880756,
        40.90392120935163,
        -3.1173842247880756,
        40.90392120935163
      ]
    },
    "19194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.93825280364244,
              40.50346876426213
            ]
          },
          "bbox": [
            -2.93825280364244,
            40.50346876426213,
            -2.93825280364244,
            40.50346876426213
          ]
        }
      ],
      "bbox": [
        -2.93825280364244,
        40.50346876426213,
        -2.93825280364244,
        40.50346876426213
      ]
    },
    "19195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7024786978454418,
              40.77160524633311
            ]
          },
          "bbox": [
            -1.7024786978454418,
            40.77160524633311,
            -1.7024786978454418,
            40.77160524633311
          ]
        }
      ],
      "bbox": [
        -1.7024786978454418,
        40.77160524633311,
        -1.7024786978454418,
        40.77160524633311
      ]
    },
    "19196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.946502338989363,
              40.81763215328227
            ]
          },
          "bbox": [
            -2.946502338989363,
            40.81763215328227,
            -2.946502338989363,
            40.81763215328227
          ]
        }
      ],
      "bbox": [
        -2.946502338989363,
        40.81763215328227,
        -2.946502338989363,
        40.81763215328227
      ]
    },
    "19197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.087761354908418,
              41.10388189060346
            ]
          },
          "bbox": [
            -3.087761354908418,
            41.10388189060346,
            -3.087761354908418,
            41.10388189060346
          ]
        }
      ],
      "bbox": [
        -3.087761354908418,
        41.10388189060346,
        -3.087761354908418,
        41.10388189060346
      ]
    },
    "19198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8599553923956407,
              41.02916736021702
            ]
          },
          "bbox": [
            -2.8599553923956407,
            41.02916736021702,
            -2.8599553923956407,
            41.02916736021702
          ]
        }
      ],
      "bbox": [
        -2.8599553923956407,
        41.02916736021702,
        -2.8599553923956407,
        41.02916736021702
      ]
    },
    "19199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.397124192501656,
              40.774104518303126
            ]
          },
          "bbox": [
            -2.397124192501656,
            40.774104518303126,
            -2.397124192501656,
            40.774104518303126
          ]
        }
      ],
      "bbox": [
        -2.397124192501656,
        40.774104518303126,
        -2.397124192501656,
        40.774104518303126
      ]
    },
    "19200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.761119959105569,
              40.600549035943196
            ]
          },
          "bbox": [
            -2.761119959105569,
            40.600549035943196,
            -2.761119959105569,
            40.600549035943196
          ]
        }
      ],
      "bbox": [
        -2.761119959105569,
        40.600549035943196,
        -2.761119959105569,
        40.600549035943196
      ]
    },
    "19201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2082241991648544,
              40.84267843193513
            ]
          },
          "bbox": [
            -2.2082241991648544,
            40.84267843193513,
            -2.2082241991648544,
            40.84267843193513
          ]
        }
      ],
      "bbox": [
        -2.2082241991648544,
        40.84267843193513,
        -2.2082241991648544,
        40.84267843193513
      ]
    },
    "19202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.741518996944423,
              41.12372075180122
            ]
          },
          "bbox": [
            -2.741518996944423,
            41.12372075180122,
            -2.741518996944423,
            41.12372075180122
          ]
        }
      ],
      "bbox": [
        -2.741518996944423,
        41.12372075180122,
        -2.741518996944423,
        41.12372075180122
      ]
    },
    "19203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1350402214335187,
              41.11334470858406
            ]
          },
          "bbox": [
            -3.1350402214335187,
            41.11334470858406,
            -3.1350402214335187,
            41.11334470858406
          ]
        }
      ],
      "bbox": [
        -3.1350402214335187,
        41.11334470858406,
        -3.1350402214335187,
        41.11334470858406
      ]
    },
    "19204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7309896970221526,
              40.53246270023813
            ]
          },
          "bbox": [
            -1.7309896970221526,
            40.53246270023813,
            -1.7309896970221526,
            40.53246270023813
          ]
        }
      ],
      "bbox": [
        -1.7309896970221526,
        40.53246270023813,
        -1.7309896970221526,
        40.53246270023813
      ]
    },
    "19208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.915198355539754,
              41.058974703470454
            ]
          },
          "bbox": [
            -2.915198355539754,
            41.058974703470454,
            -2.915198355539754,
            41.058974703470454
          ]
        }
      ],
      "bbox": [
        -2.915198355539754,
        41.058974703470454,
        -2.915198355539754,
        41.058974703470454
      ]
    },
    "19209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9308243263271896,
              40.94390169613909
            ]
          },
          "bbox": [
            -1.9308243263271896,
            40.94390169613909,
            -1.9308243263271896,
            40.94390169613909
          ]
        }
      ],
      "bbox": [
        -1.9308243263271896,
        40.94390169613909,
        -1.9308243263271896,
        40.94390169613909
      ]
    },
    "19210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7342597736002237,
              41.24268410717991
            ]
          },
          "bbox": [
            -2.7342597736002237,
            41.24268410717991,
            -2.7342597736002237,
            41.24268410717991
          ]
        }
      ],
      "bbox": [
        -2.7342597736002237,
        41.24268410717991,
        -2.7342597736002237,
        41.24268410717991
      ]
    },
    "19211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.651157823195215,
              40.561780890544355
            ]
          },
          "bbox": [
            -2.651157823195215,
            40.561780890544355,
            -2.651157823195215,
            40.561780890544355
          ]
        }
      ],
      "bbox": [
        -2.651157823195215,
        40.561780890544355,
        -2.651157823195215,
        40.561780890544355
      ]
    },
    "19212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9088977567811867,
              40.40388986969878
            ]
          },
          "bbox": [
            -2.9088977567811867,
            40.40388986969878,
            -2.9088977567811867,
            40.40388986969878
          ]
        }
      ],
      "bbox": [
        -2.9088977567811867,
        40.40388986969878,
        -2.9088977567811867,
        40.40388986969878
      ]
    },
    "19213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.562808566584869,
              40.78428585619623
            ]
          },
          "bbox": [
            -1.562808566584869,
            40.78428585619623,
            -1.562808566584869,
            40.78428585619623
          ]
        }
      ],
      "bbox": [
        -1.562808566584869,
        40.78428585619623,
        -1.562808566584869,
        40.78428585619623
      ]
    },
    "19214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0928361059071805,
              40.67720446181097
            ]
          },
          "bbox": [
            -2.0928361059071805,
            40.67720446181097,
            -2.0928361059071805,
            40.67720446181097
          ]
        }
      ],
      "bbox": [
        -2.0928361059071805,
        40.67720446181097,
        -2.0928361059071805,
        40.67720446181097
      ]
    },
    "19215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8881599644628033,
              40.57433992724519
            ]
          },
          "bbox": [
            -2.8881599644628033,
            40.57433992724519,
            -2.8881599644628033,
            40.57433992724519
          ]
        }
      ],
      "bbox": [
        -2.8881599644628033,
        40.57433992724519,
        -2.8881599644628033,
        40.57433992724519
      ]
    },
    "19216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8991358251500736,
              40.58941072439497
            ]
          },
          "bbox": [
            -1.8991358251500736,
            40.58941072439497,
            -1.8991358251500736,
            40.58941072439497
          ]
        }
      ],
      "bbox": [
        -1.8991358251500736,
        40.58941072439497,
        -1.8991358251500736,
        40.58941072439497
      ]
    },
    "19217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.48358310593254,
              40.60915801250426
            ]
          },
          "bbox": [
            -2.48358310593254,
            40.60915801250426,
            -2.48358310593254,
            40.60915801250426
          ]
        }
      ],
      "bbox": [
        -2.48358310593254,
        40.60915801250426,
        -2.48358310593254,
        40.60915801250426
      ]
    },
    "19218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9427349925309,
              41.019685893301116
            ]
          },
          "bbox": [
            -2.9427349925309,
            41.019685893301116,
            -2.9427349925309,
            41.019685893301116
          ]
        }
      ],
      "bbox": [
        -2.9427349925309,
        41.019685893301116,
        -2.9427349925309,
        41.019685893301116
      ]
    },
    "19219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8760485370404552,
              40.67300278074923
            ]
          },
          "bbox": [
            -1.8760485370404552,
            40.67300278074923,
            -1.8760485370404552,
            40.67300278074923
          ]
        }
      ],
      "bbox": [
        -1.8760485370404552,
        40.67300278074923,
        -1.8760485370404552,
        40.67300278074923
      ]
    },
    "19220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.173459888487162,
              40.46089381533727
            ]
          },
          "bbox": [
            -3.173459888487162,
            40.46089381533727,
            -3.173459888487162,
            40.46089381533727
          ]
        }
      ],
      "bbox": [
        -3.173459888487162,
        40.46089381533727,
        -3.173459888487162,
        40.46089381533727
      ]
    },
    "19221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.732828390736461,
              40.663323434159196
            ]
          },
          "bbox": [
            -1.732828390736461,
            40.663323434159196,
            -1.732828390736461,
            40.663323434159196
          ]
        }
      ],
      "bbox": [
        -1.732828390736461,
        40.663323434159196,
        -1.732828390736461,
        40.663323434159196
      ]
    },
    "19222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6192658267992768,
              40.794968461176516
            ]
          },
          "bbox": [
            -1.6192658267992768,
            40.794968461176516,
            -1.6192658267992768,
            40.794968461176516
          ]
        }
      ],
      "bbox": [
        -1.6192658267992768,
        40.794968461176516,
        -1.6192658267992768,
        40.794968461176516
      ]
    },
    "19223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0141504588288397,
              40.62835901691215
            ]
          },
          "bbox": [
            -2.0141504588288397,
            40.62835901691215,
            -2.0141504588288397,
            40.62835901691215
          ]
        }
      ],
      "bbox": [
        -2.0141504588288397,
        40.62835901691215,
        -2.0141504588288397,
        40.62835901691215
      ]
    },
    "19224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0297084766559697,
              40.34775707755702
            ]
          },
          "bbox": [
            -3.0297084766559697,
            40.34775707755702,
            -3.0297084766559697,
            40.34775707755702
          ]
        }
      ],
      "bbox": [
        -3.0297084766559697,
        40.34775707755702,
        -3.0297084766559697,
        40.34775707755702
      ]
    },
    "19225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1798664257361504,
              40.496064529532454
            ]
          },
          "bbox": [
            -3.1798664257361504,
            40.496064529532454,
            -3.1798664257361504,
            40.496064529532454
          ]
        }
      ],
      "bbox": [
        -3.1798664257361504,
        40.496064529532454,
        -3.1798664257361504,
        40.496064529532454
      ]
    },
    "19226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0098702037761744,
              41.182209592083886
            ]
          },
          "bbox": [
            -3.0098702037761744,
            41.182209592083886,
            -3.0098702037761744,
            41.182209592083886
          ]
        }
      ],
      "bbox": [
        -3.0098702037761744,
        41.182209592083886,
        -3.0098702037761744,
        41.182209592083886
      ]
    },
    "19227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8029234057650165,
              40.78373195076169
            ]
          },
          "bbox": [
            -1.8029234057650165,
            40.78373195076169,
            -1.8029234057650165,
            40.78373195076169
          ]
        }
      ],
      "bbox": [
        -1.8029234057650165,
        40.78373195076169,
        -1.8029234057650165,
        40.78373195076169
      ]
    },
    "19228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2320026109065463,
              40.894997645074405
            ]
          },
          "bbox": [
            -3.2320026109065463,
            40.894997645074405,
            -3.2320026109065463,
            40.894997645074405
          ]
        }
      ],
      "bbox": [
        -3.2320026109065463,
        40.894997645074405,
        -3.2320026109065463,
        40.894997645074405
      ]
    },
    "19229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3000834776606633,
              40.91857031086495
            ]
          },
          "bbox": [
            -3.3000834776606633,
            40.91857031086495,
            -3.3000834776606633,
            40.91857031086495
          ]
        }
      ],
      "bbox": [
        -3.3000834776606633,
        40.91857031086495,
        -3.3000834776606633,
        40.91857031086495
      ]
    },
    "19230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2828514207593704,
              40.611847516561
            ]
          },
          "bbox": [
            -3.2828514207593704,
            40.611847516561,
            -3.2828514207593704,
            40.611847516561
          ]
        }
      ],
      "bbox": [
        -3.2828514207593704,
        40.611847516561,
        -3.2828514207593704,
        40.611847516561
      ]
    },
    "19231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.844049528871486,
              41.08964020133447
            ]
          },
          "bbox": [
            -2.844049528871486,
            41.08964020133447,
            -2.844049528871486,
            41.08964020133447
          ]
        }
      ],
      "bbox": [
        -2.844049528871486,
        41.08964020133447,
        -2.844049528871486,
        41.08964020133447
      ]
    },
    "19232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3326119819228137,
              40.62068740228323
            ]
          },
          "bbox": [
            -2.3326119819228137,
            40.62068740228323,
            -2.3326119819228137,
            40.62068740228323
          ]
        }
      ],
      "bbox": [
        -2.3326119819228137,
        40.62068740228323,
        -2.3326119819228137,
        40.62068740228323
      ]
    },
    "19233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0169095807716633,
              40.48882395938804
            ]
          },
          "bbox": [
            -3.0169095807716633,
            40.48882395938804,
            -3.0169095807716633,
            40.48882395938804
          ]
        }
      ],
      "bbox": [
        -3.0169095807716633,
        40.48882395938804,
        -3.0169095807716633,
        40.48882395938804
      ]
    },
    "19234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2823000675493614,
              40.97354126170811
            ]
          },
          "bbox": [
            -3.2823000675493614,
            40.97354126170811,
            -3.2823000675493614,
            40.97354126170811
          ]
        }
      ],
      "bbox": [
        -3.2823000675493614,
        40.97354126170811,
        -3.2823000675493614,
        40.97354126170811
      ]
    },
    "19235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2871780645041233,
              40.9046870545232
            ]
          },
          "bbox": [
            -2.2871780645041233,
            40.9046870545232,
            -2.2871780645041233,
            40.9046870545232
          ]
        }
      ],
      "bbox": [
        -2.2871780645041233,
        40.9046870545232,
        -2.2871780645041233,
        40.9046870545232
      ]
    },
    "19237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.927045144873204,
              40.884613337362026
            ]
          },
          "bbox": [
            -1.927045144873204,
            40.884613337362026,
            -1.927045144873204,
            40.884613337362026
          ]
        }
      ],
      "bbox": [
        -1.927045144873204,
        40.884613337362026,
        -1.927045144873204,
        40.884613337362026
      ]
    },
    "19238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.816199803785154,
              41.125181843582595
            ]
          },
          "bbox": [
            -2.816199803785154,
            41.125181843582595,
            -2.816199803785154,
            41.125181843582595
          ]
        }
      ],
      "bbox": [
        -2.816199803785154,
        41.125181843582595,
        -2.816199803785154,
        41.125181843582595
      ]
    },
    "19239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2183034523850322,
              40.8449918661373
            ]
          },
          "bbox": [
            -3.2183034523850322,
            40.8449918661373,
            -3.2183034523850322,
            40.8449918661373
          ]
        }
      ],
      "bbox": [
        -3.2183034523850322,
        40.8449918661373,
        -3.2183034523850322,
        40.8449918661373
      ]
    },
    "19240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9419542267993237,
              41.112164240190275
            ]
          },
          "bbox": [
            -2.9419542267993237,
            41.112164240190275,
            -2.9419542267993237,
            41.112164240190275
          ]
        }
      ],
      "bbox": [
        -2.9419542267993237,
        41.112164240190275,
        -2.9419542267993237,
        41.112164240190275
      ]
    },
    "19241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8887990330150086,
              41.28035706473134
            ]
          },
          "bbox": [
            -2.8887990330150086,
            41.28035706473134,
            -2.8887990330150086,
            41.28035706473134
          ]
        }
      ],
      "bbox": [
        -2.8887990330150086,
        41.28035706473134,
        -2.8887990330150086,
        41.28035706473134
      ]
    },
    "19242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9844178804250956,
              40.57236524561332
            ]
          },
          "bbox": [
            -2.9844178804250956,
            40.57236524561332,
            -2.9844178804250956,
            40.57236524561332
          ]
        }
      ],
      "bbox": [
        -2.9844178804250956,
        40.57236524561332,
        -2.9844178804250956,
        40.57236524561332
      ]
    },
    "19243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8501967325000424,
              40.933169145955844
            ]
          },
          "bbox": [
            -1.8501967325000424,
            40.933169145955844,
            -1.8501967325000424,
            40.933169145955844
          ]
        }
      ],
      "bbox": [
        -1.8501967325000424,
        40.933169145955844,
        -1.8501967325000424,
        40.933169145955844
      ]
    },
    "19244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4143669773097014,
              40.82735283959695
            ]
          },
          "bbox": [
            -2.4143669773097014,
            40.82735283959695,
            -2.4143669773097014,
            40.82735283959695
          ]
        }
      ],
      "bbox": [
        -2.4143669773097014,
        40.82735283959695,
        -2.4143669773097014,
        40.82735283959695
      ]
    },
    "19245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7108928341315974,
              40.46602532599437
            ]
          },
          "bbox": [
            -2.7108928341315974,
            40.46602532599437,
            -2.7108928341315974,
            40.46602532599437
          ]
        }
      ],
      "bbox": [
        -2.7108928341315974,
        40.46602532599437,
        -2.7108928341315974,
        40.46602532599437
      ]
    },
    "19246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3388285500005916,
              40.90669563060736
            ]
          },
          "bbox": [
            -2.3388285500005916,
            40.90669563060736,
            -2.3388285500005916,
            40.90669563060736
          ]
        }
      ],
      "bbox": [
        -2.3388285500005916,
        40.90669563060736,
        -2.3388285500005916,
        40.90669563060736
      ]
    },
    "19247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.489945835433114,
              40.54319784210172
            ]
          },
          "bbox": [
            -2.489945835433114,
            40.54319784210172,
            -2.489945835433114,
            40.54319784210172
          ]
        }
      ],
      "bbox": [
        -2.489945835433114,
        40.54319784210172,
        -2.489945835433114,
        40.54319784210172
      ]
    },
    "19248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.034100993794091,
              40.987752211763265
            ]
          },
          "bbox": [
            -3.034100993794091,
            40.987752211763265,
            -3.034100993794091,
            40.987752211763265
          ]
        }
      ],
      "bbox": [
        -3.034100993794091,
        40.987752211763265,
        -3.034100993794091,
        40.987752211763265
      ]
    },
    "19249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.813060753579163,
              40.63074830183832
            ]
          },
          "bbox": [
            -2.813060753579163,
            40.63074830183832,
            -2.813060753579163,
            40.63074830183832
          ]
        }
      ],
      "bbox": [
        -2.813060753579163,
        40.63074830183832,
        -2.813060753579163,
        40.63074830183832
      ]
    },
    "19250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.809134641410819,
              41.08711079612848
            ]
          },
          "bbox": [
            -2.809134641410819,
            41.08711079612848,
            -2.809134641410819,
            41.08711079612848
          ]
        }
      ],
      "bbox": [
        -2.809134641410819,
        41.08711079612848,
        -2.809134641410819,
        41.08711079612848
      ]
    },
    "19251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.555123379353862,
              41.02497444538054
            ]
          },
          "bbox": [
            -2.555123379353862,
            41.02497444538054,
            -2.555123379353862,
            41.02497444538054
          ]
        }
      ],
      "bbox": [
        -2.555123379353862,
        41.02497444538054,
        -2.555123379353862,
        41.02497444538054
      ]
    },
    "19252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8238815598341924,
              40.423733399857774
            ]
          },
          "bbox": [
            -2.8238815598341924,
            40.423733399857774,
            -2.8238815598341924,
            40.423733399857774
          ]
        }
      ],
      "bbox": [
        -2.8238815598341924,
        40.423733399857774,
        -2.8238815598341924,
        40.423733399857774
      ]
    },
    "19254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1104981872086728,
              40.94133486435253
            ]
          },
          "bbox": [
            -2.1104981872086728,
            40.94133486435253,
            -2.1104981872086728,
            40.94133486435253
          ]
        }
      ],
      "bbox": [
        -2.1104981872086728,
        40.94133486435253,
        -2.1104981872086728,
        40.94133486435253
      ]
    },
    "19255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.600271549701751,
              40.727520002564226
            ]
          },
          "bbox": [
            -1.600271549701751,
            40.727520002564226,
            -1.600271549701751,
            40.727520002564226
          ]
        }
      ],
      "bbox": [
        -1.600271549701751,
        40.727520002564226,
        -1.600271549701751,
        40.727520002564226
      ]
    },
    "19256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6363504036380494,
              41.20214750909365
            ]
          },
          "bbox": [
            -2.6363504036380494,
            41.20214750909365,
            -2.6363504036380494,
            41.20214750909365
          ]
        }
      ],
      "bbox": [
        -2.6363504036380494,
        41.20214750909365,
        -2.6363504036380494,
        41.20214750909365
      ]
    },
    "19257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.656615671440372,
              41.10325791008576
            ]
          },
          "bbox": [
            -2.656615671440372,
            41.10325791008576,
            -2.656615671440372,
            41.10325791008576
          ]
        }
      ],
      "bbox": [
        -2.656615671440372,
        41.10325791008576,
        -2.656615671440372,
        41.10325791008576
      ]
    },
    "19258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.692049883715051,
              40.75809411681839
            ]
          },
          "bbox": [
            -2.692049883715051,
            40.75809411681839,
            -2.692049883715051,
            40.75809411681839
          ]
        }
      ],
      "bbox": [
        -2.692049883715051,
        40.75809411681839,
        -2.692049883715051,
        40.75809411681839
      ]
    },
    "19259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0659241094958136,
              41.25964177178391
            ]
          },
          "bbox": [
            -3.0659241094958136,
            41.25964177178391,
            -3.0659241094958136,
            41.25964177178391
          ]
        }
      ],
      "bbox": [
        -3.0659241094958136,
        41.25964177178391,
        -3.0659241094958136,
        41.25964177178391
      ]
    },
    "19260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6313209037232763,
              40.875766739870855
            ]
          },
          "bbox": [
            -2.6313209037232763,
            40.875766739870855,
            -2.6313209037232763,
            40.875766739870855
          ]
        }
      ],
      "bbox": [
        -2.6313209037232763,
        40.875766739870855,
        -2.6313209037232763,
        40.875766739870855
      ]
    },
    "19261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.395427581575288,
              40.9127592697132
            ]
          },
          "bbox": [
            -2.395427581575288,
            40.9127592697132,
            -2.395427581575288,
            40.9127592697132
          ]
        }
      ],
      "bbox": [
        -2.395427581575288,
        40.9127592697132,
        -2.395427581575288,
        40.9127592697132
      ]
    },
    "19262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2301064997140183,
              41.03045170919057
            ]
          },
          "bbox": [
            -3.2301064997140183,
            41.03045170919057,
            -3.2301064997140183,
            41.03045170919057
          ]
        }
      ],
      "bbox": [
        -3.2301064997140183,
        41.03045170919057,
        -3.2301064997140183,
        41.03045170919057
      ]
    },
    "19263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.085872509242942,
              40.82416081265567
            ]
          },
          "bbox": [
            -3.085872509242942,
            40.82416081265567,
            -3.085872509242942,
            40.82416081265567
          ]
        }
      ],
      "bbox": [
        -3.085872509242942,
        40.82416081265567,
        -3.085872509242942,
        40.82416081265567
      ]
    },
    "19264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.977258327016854,
              40.6739159790963
            ]
          },
          "bbox": [
            -1.977258327016854,
            40.6739159790963,
            -1.977258327016854,
            40.6739159790963
          ]
        }
      ],
      "bbox": [
        -1.977258327016854,
        40.6739159790963,
        -1.977258327016854,
        40.6739159790963
      ]
    },
    "19265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9385641188632892,
              41.025917273475244
            ]
          },
          "bbox": [
            -1.9385641188632892,
            41.025917273475244,
            -1.9385641188632892,
            41.025917273475244
          ]
        }
      ],
      "bbox": [
        -1.9385641188632892,
        41.025917273475244,
        -1.9385641188632892,
        41.025917273475244
      ]
    },
    "19266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.950433272199599,
              40.546333691933896
            ]
          },
          "bbox": [
            -2.950433272199599,
            40.546333691933896,
            -2.950433272199599,
            40.546333691933896
          ]
        }
      ],
      "bbox": [
        -2.950433272199599,
        40.546333691933896,
        -2.950433272199599,
        40.546333691933896
      ]
    },
    "19267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.90577642562356,
              40.70088796441233
            ]
          },
          "bbox": [
            -1.90577642562356,
            40.70088796441233,
            -1.90577642562356,
            40.70088796441233
          ]
        }
      ],
      "bbox": [
        -1.90577642562356,
        40.70088796441233,
        -1.90577642562356,
        40.70088796441233
      ]
    },
    "19268": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9217023294489826,
              40.7479531080691
            ]
          },
          "bbox": [
            -1.9217023294489826,
            40.7479531080691,
            -1.9217023294489826,
            40.7479531080691
          ]
        }
      ],
      "bbox": [
        -1.9217023294489826,
        40.7479531080691,
        -1.9217023294489826,
        40.7479531080691
      ]
    },
    "19269": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.006133124525276,
              41.008330816614496
            ]
          },
          "bbox": [
            -3.006133124525276,
            41.008330816614496,
            -3.006133124525276,
            41.008330816614496
          ]
        }
      ],
      "bbox": [
        -3.006133124525276,
        41.008330816614496,
        -3.006133124525276,
        41.008330816614496
      ]
    },
    "19270": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.757245219357352,
              41.215164277111825
            ]
          },
          "bbox": [
            -2.757245219357352,
            41.215164277111825,
            -2.757245219357352,
            41.215164277111825
          ]
        }
      ],
      "bbox": [
        -2.757245219357352,
        41.215164277111825,
        -2.757245219357352,
        41.215164277111825
      ]
    },
    "19271": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6812945782052888,
              40.713738595523985
            ]
          },
          "bbox": [
            -1.6812945782052888,
            40.713738595523985,
            -1.6812945782052888,
            40.713738595523985
          ]
        }
      ],
      "bbox": [
        -1.6812945782052888,
        40.713738595523985,
        -1.6812945782052888,
        40.713738595523985
      ]
    },
    "19272": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.58034652201611,
              40.66580826348547
            ]
          },
          "bbox": [
            -1.58034652201611,
            40.66580826348547,
            -1.58034652201611,
            40.66580826348547
          ]
        }
      ],
      "bbox": [
        -1.58034652201611,
        40.66580826348547,
        -1.58034652201611,
        40.66580826348547
      ]
    },
    "19274": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0387847683798737,
              40.74500425543727
            ]
          },
          "bbox": [
            -3.0387847683798737,
            40.74500425543727,
            -3.0387847683798737,
            40.74500425543727
          ]
        }
      ],
      "bbox": [
        -3.0387847683798737,
        40.74500425543727,
        -3.0387847683798737,
        40.74500425543727
      ]
    },
    "19277": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8149959863069538,
              40.73201485294258
            ]
          },
          "bbox": [
            -1.8149959863069538,
            40.73201485294258,
            -1.8149959863069538,
            40.73201485294258
          ]
        }
      ],
      "bbox": [
        -1.8149959863069538,
        40.73201485294258,
        -1.8149959863069538,
        40.73201485294258
      ]
    },
    "19278": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.538662351473203,
              40.84341636746948
            ]
          },
          "bbox": [
            -2.538662351473203,
            40.84341636746948,
            -2.538662351473203,
            40.84341636746948
          ]
        }
      ],
      "bbox": [
        -2.538662351473203,
        40.84341636746948,
        -2.538662351473203,
        40.84341636746948
      ]
    },
    "19279": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.085349641859975,
              40.801621258145744
            ]
          },
          "bbox": [
            -3.085349641859975,
            40.801621258145744,
            -3.085349641859975,
            40.801621258145744
          ]
        }
      ],
      "bbox": [
        -3.085349641859975,
        40.801621258145744,
        -3.085349641859975,
        40.801621258145744
      ]
    },
    "19280": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3469200048555416,
              40.66630931393791
            ]
          },
          "bbox": [
            -3.3469200048555416,
            40.66630931393791,
            -3.3469200048555416,
            40.66630931393791
          ]
        }
      ],
      "bbox": [
        -3.3469200048555416,
        40.66630931393791,
        -3.3469200048555416,
        40.66630931393791
      ]
    },
    "19281": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9064697067351766,
              41.01385356124449
            ]
          },
          "bbox": [
            -2.9064697067351766,
            41.01385356124449,
            -2.9064697067351766,
            41.01385356124449
          ]
        }
      ],
      "bbox": [
        -2.9064697067351766,
        41.01385356124449,
        -2.9064697067351766,
        41.01385356124449
      ]
    },
    "19282": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5775764712325895,
              40.92306627688874
            ]
          },
          "bbox": [
            -2.5775764712325895,
            40.92306627688874,
            -2.5775764712325895,
            40.92306627688874
          ]
        }
      ],
      "bbox": [
        -2.5775764712325895,
        40.92306627688874,
        -2.5775764712325895,
        40.92306627688874
      ]
    },
    "19283": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.056040423841542,
              40.88480170649223
            ]
          },
          "bbox": [
            -2.056040423841542,
            40.88480170649223,
            -2.056040423841542,
            40.88480170649223
          ]
        }
      ],
      "bbox": [
        -2.056040423841542,
        40.88480170649223,
        -2.056040423841542,
        40.88480170649223
      ]
    },
    "19284": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8512349505482046,
              40.75579462054658
            ]
          },
          "bbox": [
            -1.8512349505482046,
            40.75579462054658,
            -1.8512349505482046,
            40.75579462054658
          ]
        }
      ],
      "bbox": [
        -1.8512349505482046,
        40.75579462054658,
        -1.8512349505482046,
        40.75579462054658
      ]
    },
    "19285": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8823100204809207,
              40.96820863334424
            ]
          },
          "bbox": [
            -1.8823100204809207,
            40.96820863334424,
            -1.8823100204809207,
            40.96820863334424
          ]
        }
      ],
      "bbox": [
        -1.8823100204809207,
        40.96820863334424,
        -1.8823100204809207,
        40.96820863334424
      ]
    },
    "19286": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1239056314329923,
              40.71219902489641
            ]
          },
          "bbox": [
            -3.1239056314329923,
            40.71219902489641,
            -3.1239056314329923,
            40.71219902489641
          ]
        }
      ],
      "bbox": [
        -3.1239056314329923,
        40.71219902489641,
        -3.1239056314329923,
        40.71219902489641
      ]
    },
    "19287": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7812397287289754,
              40.99366635627832
            ]
          },
          "bbox": [
            -1.7812397287289754,
            40.99366635627832,
            -1.7812397287289754,
            40.99366635627832
          ]
        }
      ],
      "bbox": [
        -1.7812397287289754,
        40.99366635627832,
        -1.7812397287289754,
        40.99366635627832
      ]
    },
    "19288": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3661247794725795,
              40.95798007969835
            ]
          },
          "bbox": [
            -3.3661247794725795,
            40.95798007969835,
            -3.3661247794725795,
            40.95798007969835
          ]
        }
      ],
      "bbox": [
        -3.3661247794725795,
        40.95798007969835,
        -3.3661247794725795,
        40.95798007969835
      ]
    },
    "19289": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8066058775416585,
              40.67207840788825
            ]
          },
          "bbox": [
            -1.8066058775416585,
            40.67207840788825,
            -1.8066058775416585,
            40.67207840788825
          ]
        }
      ],
      "bbox": [
        -1.8066058775416585,
        40.67207840788825,
        -1.8066058775416585,
        40.67207840788825
      ]
    },
    "19290": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.98425174624677,
              40.78139795455028
            ]
          },
          "bbox": [
            -2.98425174624677,
            40.78139795455028,
            -2.98425174624677,
            40.78139795455028
          ]
        }
      ],
      "bbox": [
        -2.98425174624677,
        40.78139795455028,
        -2.98425174624677,
        40.78139795455028
      ]
    },
    "19291": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5555542334162413,
              40.66863820379334
            ]
          },
          "bbox": [
            -2.5555542334162413,
            40.66863820379334,
            -2.5555542334162413,
            40.66863820379334
          ]
        }
      ],
      "bbox": [
        -2.5555542334162413,
        40.66863820379334,
        -2.5555542334162413,
        40.66863820379334
      ]
    },
    "19293": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4510691621572493,
              40.82904486110389
            ]
          },
          "bbox": [
            -3.4510691621572493,
            40.82904486110389,
            -3.4510691621572493,
            40.82904486110389
          ]
        }
      ],
      "bbox": [
        -3.4510691621572493,
        40.82904486110389,
        -3.4510691621572493,
        40.82904486110389
      ]
    },
    "19294": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0111782061196948,
              41.226892839790224
            ]
          },
          "bbox": [
            -3.0111782061196948,
            41.226892839790224,
            -3.0111782061196948,
            41.226892839790224
          ]
        }
      ],
      "bbox": [
        -3.0111782061196948,
        41.226892839790224,
        -3.0111782061196948,
        41.226892839790224
      ]
    },
    "19296": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9230638844389603,
              40.854749763229925
            ]
          },
          "bbox": [
            -2.9230638844389603,
            40.854749763229925,
            -2.9230638844389603,
            40.854749763229925
          ]
        }
      ],
      "bbox": [
        -2.9230638844389603,
        40.854749763229925,
        -2.9230638844389603,
        40.854749763229925
      ]
    },
    "19297": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.125820743024479,
              40.51282338904973
            ]
          },
          "bbox": [
            -3.125820743024479,
            40.51282338904973,
            -3.125820743024479,
            40.51282338904973
          ]
        }
      ],
      "bbox": [
        -3.125820743024479,
        40.51282338904973,
        -3.125820743024479,
        40.51282338904973
      ]
    },
    "19298": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.993366924804261,
              40.82260016609678
            ]
          },
          "bbox": [
            -2.993366924804261,
            40.82260016609678,
            -2.993366924804261,
            40.82260016609678
          ]
        }
      ],
      "bbox": [
        -2.993366924804261,
        40.82260016609678,
        -2.993366924804261,
        40.82260016609678
      ]
    },
    "19299": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9617645734827946,
              40.6631475922882
            ]
          },
          "bbox": [
            -2.9617645734827946,
            40.6631475922882,
            -2.9617645734827946,
            40.6631475922882
          ]
        }
      ],
      "bbox": [
        -2.9617645734827946,
        40.6631475922882,
        -2.9617645734827946,
        40.6631475922882
      ]
    },
    "19300": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.30672642525882,
              40.647767384663155
            ]
          },
          "bbox": [
            -3.30672642525882,
            40.647767384663155,
            -3.30672642525882,
            40.647767384663155
          ]
        }
      ],
      "bbox": [
        -3.30672642525882,
        40.647767384663155,
        -3.30672642525882,
        40.647767384663155
      ]
    },
    "19301": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8745449953951705,
              40.45317945236651
            ]
          },
          "bbox": [
            -2.8745449953951705,
            40.45317945236651,
            -2.8745449953951705,
            40.45317945236651
          ]
        }
      ],
      "bbox": [
        -2.8745449953951705,
        40.45317945236651,
        -2.8745449953951705,
        40.45317945236651
      ]
    },
    "19302": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.005287603712365,
              40.717759354948775
            ]
          },
          "bbox": [
            -3.005287603712365,
            40.717759354948775,
            -3.005287603712365,
            40.717759354948775
          ]
        }
      ],
      "bbox": [
        -3.005287603712365,
        40.717759354948775,
        -3.005287603712365,
        40.717759354948775
      ]
    },
    "19303": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6743944549615364,
              41.22781489837774
            ]
          },
          "bbox": [
            -2.6743944549615364,
            41.22781489837774,
            -2.6743944549615364,
            41.22781489837774
          ]
        }
      ],
      "bbox": [
        -2.6743944549615364,
        41.22781489837774,
        -2.6743944549615364,
        41.22781489837774
      ]
    },
    "19304": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.375141897489485,
              40.75830148213423
            ]
          },
          "bbox": [
            -3.375141897489485,
            40.75830148213423,
            -3.375141897489485,
            40.75830148213423
          ]
        }
      ],
      "bbox": [
        -3.375141897489485,
        40.75830148213423,
        -3.375141897489485,
        40.75830148213423
      ]
    },
    "19305": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4006405594411726,
              40.90940421892966
            ]
          },
          "bbox": [
            -3.4006405594411726,
            40.90940421892966,
            -3.4006405594411726,
            40.90940421892966
          ]
        }
      ],
      "bbox": [
        -3.4006405594411726,
        40.90940421892966,
        -3.4006405594411726,
        40.90940421892966
      ]
    },
    "19306": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7349371981496233,
              40.80811077682707
            ]
          },
          "bbox": [
            -2.7349371981496233,
            40.80811077682707,
            -2.7349371981496233,
            40.80811077682707
          ]
        }
      ],
      "bbox": [
        -2.7349371981496233,
        40.80811077682707,
        -2.7349371981496233,
        40.80811077682707
      ]
    },
    "19307": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3203386405972517,
              40.976268196472674
            ]
          },
          "bbox": [
            -3.3203386405972517,
            40.976268196472674,
            -3.3203386405972517,
            40.976268196472674
          ]
        }
      ],
      "bbox": [
        -3.3203386405972517,
        40.976268196472674,
        -3.3203386405972517,
        40.976268196472674
      ]
    },
    "19308": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.959652828664457,
              40.6135173007365
            ]
          },
          "bbox": [
            -2.959652828664457,
            40.6135173007365,
            -2.959652828664457,
            40.6135173007365
          ]
        }
      ],
      "bbox": [
        -2.959652828664457,
        40.6135173007365,
        -2.959652828664457,
        40.6135173007365
      ]
    },
    "19309": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9894273142127454,
              40.77827849710728
            ]
          },
          "bbox": [
            -1.9894273142127454,
            40.77827849710728,
            -1.9894273142127454,
            40.77827849710728
          ]
        }
      ],
      "bbox": [
        -1.9894273142127454,
        40.77827849710728,
        -1.9894273142127454,
        40.77827849710728
      ]
    },
    "19310": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.393600359790106,
              40.717555735794505
            ]
          },
          "bbox": [
            -2.393600359790106,
            40.717555735794505,
            -2.393600359790106,
            40.717555735794505
          ]
        }
      ],
      "bbox": [
        -2.393600359790106,
        40.717555735794505,
        -2.393600359790106,
        40.717555735794505
      ]
    },
    "19311": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2350411845177915,
              41.13441063617915
            ]
          },
          "bbox": [
            -3.2350411845177915,
            41.13441063617915,
            -3.2350411845177915,
            41.13441063617915
          ]
        }
      ],
      "bbox": [
        -3.2350411845177915,
        41.13441063617915,
        -3.2350411845177915,
        41.13441063617915
      ]
    },
    "19314": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7862531976283824,
              41.028343213413514
            ]
          },
          "bbox": [
            -2.7862531976283824,
            41.028343213413514,
            -2.7862531976283824,
            41.028343213413514
          ]
        }
      ],
      "bbox": [
        -2.7862531976283824,
        41.028343213413514,
        -2.7862531976283824,
        41.028343213413514
      ]
    },
    "19317": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2344337393497775,
              40.66920428911489
            ]
          },
          "bbox": [
            -2.2344337393497775,
            40.66920428911489,
            -2.2344337393497775,
            40.66920428911489
          ]
        }
      ],
      "bbox": [
        -2.2344337393497775,
        40.66920428911489,
        -2.2344337393497775,
        40.66920428911489
      ]
    },
    "19318": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.916305291865462,
              40.903041287735874
            ]
          },
          "bbox": [
            -2.916305291865462,
            40.903041287735874,
            -2.916305291865462,
            40.903041287735874
          ]
        }
      ],
      "bbox": [
        -2.916305291865462,
        40.903041287735874,
        -2.916305291865462,
        40.903041287735874
      ]
    },
    "19319": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3045863062329635,
              40.59217434848429
            ]
          },
          "bbox": [
            -3.3045863062329635,
            40.59217434848429,
            -3.3045863062329635,
            40.59217434848429
          ]
        }
      ],
      "bbox": [
        -3.3045863062329635,
        40.59217434848429,
        -3.3045863062329635,
        40.59217434848429
      ]
    },
    "19321": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.028459332454782,
              41.10272012380956
            ]
          },
          "bbox": [
            -3.028459332454782,
            41.10272012380956,
            -3.028459332454782,
            41.10272012380956
          ]
        }
      ],
      "bbox": [
        -3.028459332454782,
        41.10272012380956,
        -3.028459332454782,
        41.10272012380956
      ]
    },
    "19322": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8011288367725755,
              40.9650543191974
            ]
          },
          "bbox": [
            -2.8011288367725755,
            40.9650543191974,
            -2.8011288367725755,
            40.9650543191974
          ]
        }
      ],
      "bbox": [
        -2.8011288367725755,
        40.9650543191974,
        -2.8011288367725755,
        40.9650543191974
      ]
    },
    "19323": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.342139285837058,
              40.836892446339256
            ]
          },
          "bbox": [
            -3.342139285837058,
            40.836892446339256,
            -3.342139285837058,
            40.836892446339256
          ]
        }
      ],
      "bbox": [
        -3.342139285837058,
        40.836892446339256,
        -3.342139285837058,
        40.836892446339256
      ]
    },
    "19324": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9967741277326192,
              41.12864812660347
            ]
          },
          "bbox": [
            -1.9967741277326192,
            41.12864812660347,
            -1.9967741277326192,
            41.12864812660347
          ]
        }
      ],
      "bbox": [
        -1.9967741277326192,
        41.12864812660347,
        -1.9967741277326192,
        41.12864812660347
      ]
    },
    "19325": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.348655718450079,
              40.790991476692575
            ]
          },
          "bbox": [
            -3.348655718450079,
            40.790991476692575,
            -3.348655718450079,
            40.790991476692575
          ]
        }
      ],
      "bbox": [
        -3.348655718450079,
        40.790991476692575,
        -3.348655718450079,
        40.790991476692575
      ]
    },
    "19326": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.102111722005288,
              40.54033352894098
            ]
          },
          "bbox": [
            -3.102111722005288,
            40.54033352894098,
            -3.102111722005288,
            40.54033352894098
          ]
        }
      ],
      "bbox": [
        -3.102111722005288,
        40.54033352894098,
        -3.102111722005288,
        40.54033352894098
      ]
    },
    "19327": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9573052880778623,
              40.35136607651975
            ]
          },
          "bbox": [
            -2.9573052880778623,
            40.35136607651975,
            -2.9573052880778623,
            40.35136607651975
          ]
        }
      ],
      "bbox": [
        -2.9573052880778623,
        40.35136607651975,
        -2.9573052880778623,
        40.35136607651975
      ]
    },
    "19329": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8550850997337673,
              40.61860715851199
            ]
          },
          "bbox": [
            -2.8550850997337673,
            40.61860715851199,
            -2.8550850997337673,
            40.61860715851199
          ]
        }
      ],
      "bbox": [
        -2.8550850997337673,
        40.61860715851199,
        -2.8550850997337673,
        40.61860715851199
      ]
    },
    "19330": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.843316504347128,
              40.64856894432281
            ]
          },
          "bbox": [
            -2.843316504347128,
            40.64856894432281,
            -2.843316504347128,
            40.64856894432281
          ]
        }
      ],
      "bbox": [
        -2.843316504347128,
        40.64856894432281,
        -2.843316504347128,
        40.64856894432281
      ]
    },
    "19331": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1751029247745146,
              40.759090090647554
            ]
          },
          "bbox": [
            -3.1751029247745146,
            40.759090090647554,
            -3.1751029247745146,
            40.759090090647554
          ]
        }
      ],
      "bbox": [
        -3.1751029247745146,
        40.759090090647554,
        -3.1751029247745146,
        40.759090090647554
      ]
    },
    "19332": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6614556065165904,
              40.929130192989014
            ]
          },
          "bbox": [
            -1.6614556065165904,
            40.929130192989014,
            -1.6614556065165904,
            40.929130192989014
          ]
        }
      ],
      "bbox": [
        -1.6614556065165904,
        40.929130192989014,
        -1.6614556065165904,
        40.929130192989014
      ]
    },
    "19333": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1869127546978397,
              40.75955376175714
            ]
          },
          "bbox": [
            -2.1869127546978397,
            40.75955376175714,
            -2.1869127546978397,
            40.75955376175714
          ]
        }
      ],
      "bbox": [
        -2.1869127546978397,
        40.75955376175714,
        -2.1869127546978397,
        40.75955376175714
      ]
    },
    "19334": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0567422487283835,
              41.06181259653321
            ]
          },
          "bbox": [
            -3.0567422487283835,
            41.06181259653321,
            -3.0567422487283835,
            41.06181259653321
          ]
        }
      ],
      "bbox": [
        -3.0567422487283835,
        41.06181259653321,
        -3.0567422487283835,
        41.06181259653321
      ]
    },
    "19335": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8886507168341224,
              40.32756320288891
            ]
          },
          "bbox": [
            -2.8886507168341224,
            40.32756320288891,
            -2.8886507168341224,
            40.32756320288891
          ]
        }
      ],
      "bbox": [
        -2.8886507168341224,
        40.32756320288891,
        -2.8886507168341224,
        40.32756320288891
      ]
    },
    "19901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1248235032102327,
              41.04482573358462
            ]
          },
          "bbox": [
            -3.1248235032102327,
            41.04482573358462,
            -3.1248235032102327,
            41.04482573358462
          ]
        }
      ],
      "bbox": [
        -3.1248235032102327,
        41.04482573358462,
        -3.1248235032102327,
        41.04482573358462
      ]
    },
    "20001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1056636698384907,
              43.03854822277885
            ]
          },
          "bbox": [
            -2.1056636698384907,
            43.03854822277885,
            -2.1056636698384907,
            43.03854822277885
          ]
        }
      ],
      "bbox": [
        -2.1056636698384907,
        43.03854822277885,
        -2.1056636698384907,
        43.03854822277885
      ]
    },
    "20002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0564987917739885,
              43.21275749763885
            ]
          },
          "bbox": [
            -2.0564987917739885,
            43.21275749763885,
            -2.0564987917739885,
            43.21275749763885
          ]
        }
      ],
      "bbox": [
        -2.0564987917739885,
        43.21275749763885,
        -2.0564987917739885,
        43.21275749763885
      ]
    },
    "20003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.232796807271268,
              43.26415904424991
            ]
          },
          "bbox": [
            -2.232796807271268,
            43.26415904424991,
            -2.232796807271268,
            43.26415904424991
          ]
        }
      ],
      "bbox": [
        -2.232796807271268,
        43.26415904424991,
        -2.232796807271268,
        43.26415904424991
      ]
    },
    "20004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.139328350389443,
              43.13159627274025
            ]
          },
          "bbox": [
            -2.139328350389443,
            43.13159627274025,
            -2.139328350389443,
            43.13159627274025
          ]
        }
      ],
      "bbox": [
        -2.139328350389443,
        43.13159627274025,
        -2.139328350389443,
        43.13159627274025
      ]
    },
    "20005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0988269548160865,
              43.09889335943728
            ]
          },
          "bbox": [
            -2.0988269548160865,
            43.09889335943728,
            -2.0988269548160865,
            43.09889335943728
          ]
        }
      ],
      "bbox": [
        -2.0988269548160865,
        43.09889335943728,
        -2.0988269548160865,
        43.09889335943728
      ]
    },
    "20006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1141558754850314,
              43.17047767852826
            ]
          },
          "bbox": [
            -2.1141558754850314,
            43.17047767852826,
            -2.1141558754850314,
            43.17047767852826
          ]
        }
      ],
      "bbox": [
        -2.1141558754850314,
        43.17047767852826,
        -2.1141558754850314,
        43.17047767852826
      ]
    },
    "20007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0682113304193686,
              43.09696114263733
            ]
          },
          "bbox": [
            -2.0682113304193686,
            43.09696114263733,
            -2.0682113304193686,
            43.09696114263733
          ]
        }
      ],
      "bbox": [
        -2.0682113304193686,
        43.09696114263733,
        -2.0682113304193686,
        43.09696114263733
      ]
    },
    "20008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0717224360699524,
              43.04832498207538
            ]
          },
          "bbox": [
            -2.0717224360699524,
            43.04832498207538,
            -2.0717224360699524,
            43.04832498207538
          ]
        }
      ],
      "bbox": [
        -2.0717224360699524,
        43.04832498207538,
        -2.0717224360699524,
        43.04832498207538
      ]
    },
    "20009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0132049445320956,
              43.21615305073185
            ]
          },
          "bbox": [
            -2.0132049445320956,
            43.21615305073185,
            -2.0132049445320956,
            43.21615305073185
          ]
        }
      ],
      "bbox": [
        -2.0132049445320956,
        43.21615305073185,
        -2.0132049445320956,
        43.21615305073185
      ]
    },
    "20010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0749836596549236,
              43.168235307935284
            ]
          },
          "bbox": [
            -2.0749836596549236,
            43.168235307935284,
            -2.0749836596549236,
            43.168235307935284
          ]
        }
      ],
      "bbox": [
        -2.0749836596549236,
        43.168235307935284,
        -2.0749836596549236,
        43.168235307935284
      ]
    },
    "20011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3754140341691916,
              43.0977958468783
            ]
          },
          "bbox": [
            -2.3754140341691916,
            43.0977958468783,
            -2.3754140341691916,
            43.0977958468783
          ]
        }
      ],
      "bbox": [
        -2.3754140341691916,
        43.0977958468783,
        -2.3754140341691916,
        43.0977958468783
      ]
    },
    "20012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1633519734104327,
              43.059231561920804
            ]
          },
          "bbox": [
            -2.1633519734104327,
            43.059231561920804,
            -2.1633519734104327,
            43.059231561920804
          ]
        }
      ],
      "bbox": [
        -2.1633519734104327,
        43.059231561920804,
        -2.1633519734104327,
        43.059231561920804
      ]
    },
    "20013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.485603025807342,
              43.019040126695266
            ]
          },
          "bbox": [
            -2.485603025807342,
            43.019040126695266,
            -2.485603025807342,
            43.019040126695266
          ]
        }
      ],
      "bbox": [
        -2.485603025807342,
        43.019040126695266,
        -2.485603025807342,
        43.019040126695266
      ]
    },
    "20014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.115381370468417,
              43.20398317557195
            ]
          },
          "bbox": [
            -2.115381370468417,
            43.20398317557195,
            -2.115381370468417,
            43.20398317557195
          ]
        }
      ],
      "bbox": [
        -2.115381370468417,
        43.20398317557195,
        -2.115381370468417,
        43.20398317557195
      ]
    },
    "20015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.161998552681102,
              42.97360088622872
            ]
          },
          "bbox": [
            -2.161998552681102,
            42.97360088622872,
            -2.161998552681102,
            42.97360088622872
          ]
        }
      ],
      "bbox": [
        -2.161998552681102,
        42.97360088622872,
        -2.161998552681102,
        42.97360088622872
      ]
    },
    "20016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.14936609176269,
              43.24315457484247
            ]
          },
          "bbox": [
            -2.14936609176269,
            43.24315457484247,
            -2.14936609176269,
            43.24315457484247
          ]
        }
      ],
      "bbox": [
        -2.14936609176269,
        43.24315457484247,
        -2.14936609176269,
        43.24315457484247
      ]
    },
    "20017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.329007633432899,
              43.17420004808039
            ]
          },
          "bbox": [
            -2.329007633432899,
            43.17420004808039,
            -2.329007633432899,
            43.17420004808039
          ]
        }
      ],
      "bbox": [
        -2.329007633432899,
        43.17420004808039,
        -2.329007633432899,
        43.17420004808039
      ]
    },
    "20018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.259763305050189,
              43.16249207671954
            ]
          },
          "bbox": [
            -2.259763305050189,
            43.16249207671954,
            -2.259763305050189,
            43.16249207671954
          ]
        }
      ],
      "bbox": [
        -2.259763305050189,
        43.16249207671954,
        -2.259763305050189,
        43.16249207671954
      ]
    },
    "20019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.219473555508246,
              43.07240001919322
            ]
          },
          "bbox": [
            -2.219473555508246,
            43.07240001919322,
            -2.219473555508246,
            43.07240001919322
          ]
        }
      ],
      "bbox": [
        -2.219473555508246,
        43.07240001919322,
        -2.219473555508246,
        43.07240001919322
      ]
    },
    "20020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.203268802106686,
              43.12629127539251
            ]
          },
          "bbox": [
            -2.203268802106686,
            43.12629127539251,
            -2.203268802106686,
            43.12629127539251
          ]
        }
      ],
      "bbox": [
        -2.203268802106686,
        43.12629127539251,
        -2.203268802106686,
        43.12629127539251
      ]
    },
    "20021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0392491223252924,
              43.135742625132
            ]
          },
          "bbox": [
            -2.0392491223252924,
            43.135742625132,
            -2.0392491223252924,
            43.135742625132
          ]
        }
      ],
      "bbox": [
        -2.0392491223252924,
        43.135742625132,
        -2.0392491223252924,
        43.135742625132
      ]
    },
    "20022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9606040220058196,
              43.13149161168639
            ]
          },
          "bbox": [
            -1.9606040220058196,
            43.13149161168639,
            -1.9606040220058196,
            43.13149161168639
          ]
        }
      ],
      "bbox": [
        -1.9606040220058196,
        43.13149161168639,
        -1.9606040220058196,
        43.13149161168639
      ]
    },
    "20023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.025891451937678,
              43.14603792082672
            ]
          },
          "bbox": [
            -2.025891451937678,
            43.14603792082672,
            -2.025891451937678,
            43.14603792082672
          ]
        }
      ],
      "bbox": [
        -2.025891451937678,
        43.14603792082672,
        -2.025891451937678,
        43.14603792082672
      ]
    },
    "20024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.165140539480746,
              43.1351931322866
            ]
          },
          "bbox": [
            -2.165140539480746,
            43.1351931322866,
            -2.165140539480746,
            43.1351931322866
          ]
        }
      ],
      "bbox": [
        -2.165140539480746,
        43.1351931322866,
        -2.165140539480746,
        43.1351931322866
      ]
    },
    "20025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2960576057891706,
              42.965047571798735
            ]
          },
          "bbox": [
            -2.2960576057891706,
            42.965047571798735,
            -2.2960576057891706,
            42.965047571798735
          ]
        }
      ],
      "bbox": [
        -2.2960576057891706,
        42.965047571798735,
        -2.2960576057891706,
        42.965047571798735
      ]
    },
    "20026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.292534664222139,
              43.01013318808412
            ]
          },
          "bbox": [
            -2.292534664222139,
            43.01013318808412,
            -2.292534664222139,
            43.01013318808412
          ]
        }
      ],
      "bbox": [
        -2.292534664222139,
        43.01013318808412,
        -2.292534664222139,
        43.01013318808412
      ]
    },
    "20027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.242436902382276,
              43.23634493688601
            ]
          },
          "bbox": [
            -2.242436902382276,
            43.23634493688601,
            -2.242436902382276,
            43.23634493688601
          ]
        }
      ],
      "bbox": [
        -2.242436902382276,
        43.23634493688601,
        -2.242436902382276,
        43.23634493688601
      ]
    },
    "20028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0773336131096105,
              43.21681006808431
            ]
          },
          "bbox": [
            -2.0773336131096105,
            43.21681006808431,
            -2.0773336131096105,
            43.21681006808431
          ]
        }
      ],
      "bbox": [
        -2.0773336131096105,
        43.21681006808431,
        -2.0773336131096105,
        43.21681006808431
      ]
    },
    "20029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.320382053415794,
              43.26372780796341
            ]
          },
          "bbox": [
            -2.320382053415794,
            43.26372780796341,
            -2.320382053415794,
            43.26372780796341
          ]
        }
      ],
      "bbox": [
        -2.320382053415794,
        43.26372780796341,
        -2.320382053415794,
        43.26372780796341
      ]
    },
    "20030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.466083359264752,
              43.196307617947184
            ]
          },
          "bbox": [
            -2.466083359264752,
            43.196307617947184,
            -2.466083359264752,
            43.196307617947184
          ]
        }
      ],
      "bbox": [
        -2.466083359264752,
        43.196307617947184,
        -2.466083359264752,
        43.196307617947184
      ]
    },
    "20031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9745633495094208,
              43.162926745398515
            ]
          },
          "bbox": [
            -1.9745633495094208,
            43.162926745398515,
            -1.9745633495094208,
            43.162926745398515
          ]
        }
      ],
      "bbox": [
        -1.9745633495094208,
        43.162926745398515,
        -1.9745633495094208,
        43.162926745398515
      ]
    },
    "20032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4033953815393274,
              43.21451919536871
            ]
          },
          "bbox": [
            -2.4033953815393274,
            43.21451919536871,
            -2.4033953815393274,
            43.21451919536871
          ]
        }
      ],
      "bbox": [
        -2.4033953815393274,
        43.21451919536871,
        -2.4033953815393274,
        43.21451919536871
      ]
    },
    "20033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4826327520884317,
              43.148073100397944
            ]
          },
          "bbox": [
            -2.4826327520884317,
            43.148073100397944,
            -2.4826327520884317,
            43.148073100397944
          ]
        }
      ],
      "bbox": [
        -2.4826327520884317,
        43.148073100397944,
        -2.4826327520884317,
        43.148073100397944
      ]
    },
    "20034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5331107227949152,
              42.99951129677789
            ]
          },
          "bbox": [
            -2.5331107227949152,
            42.99951129677789,
            -2.5331107227949152,
            42.99951129677789
          ]
        }
      ],
      "bbox": [
        -2.5331107227949152,
        42.99951129677789,
        -2.5331107227949152,
        42.99951129677789
      ]
    },
    "20035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2674234317421433,
              43.083957651919306
            ]
          },
          "bbox": [
            -2.2674234317421433,
            43.083957651919306,
            -2.2674234317421433,
            43.083957651919306
          ]
        }
      ],
      "bbox": [
        -2.2674234317421433,
        43.083957651919306,
        -2.2674234317421433,
        43.083957651919306
      ]
    },
    "20036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8253178806497654,
              43.361787788598384
            ]
          },
          "bbox": [
            -1.8253178806497654,
            43.361787788598384,
            -1.8253178806497654,
            43.361787788598384
          ]
        }
      ],
      "bbox": [
        -1.8253178806497654,
        43.361787788598384,
        -1.8253178806497654,
        43.361787788598384
      ]
    },
    "20037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1308716725870145,
              43.053843826752626
            ]
          },
          "bbox": [
            -2.1308716725870145,
            43.053843826752626,
            -2.1308716725870145,
            43.053843826752626
          ]
        }
      ],
      "bbox": [
        -2.1308716725870145,
        43.053843826752626,
        -2.1308716725870145,
        43.053843826752626
      ]
    },
    "20038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.296038325189429,
              43.05210431567145
            ]
          },
          "bbox": [
            -2.296038325189429,
            43.05210431567145,
            -2.296038325189429,
            43.05210431567145
          ]
        }
      ],
      "bbox": [
        -2.296038325189429,
        43.05210431567145,
        -2.296038325189429,
        43.05210431567145
      ]
    },
    "20039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.213740344959076,
              43.29411251926016
            ]
          },
          "bbox": [
            -2.213740344959076,
            43.29411251926016,
            -2.213740344959076,
            43.29411251926016
          ]
        }
      ],
      "bbox": [
        -2.213740344959076,
        43.29411251926016,
        -2.213740344959076,
        43.29411251926016
      ]
    },
    "20040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.942969104279619,
              43.23601268976827
            ]
          },
          "bbox": [
            -1.942969104279619,
            43.23601268976827,
            -1.942969104279619,
            43.23601268976827
          ]
        }
      ],
      "bbox": [
        -1.942969104279619,
        43.23601268976827,
        -1.942969104279619,
        43.23601268976827
      ]
    },
    "20041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0952712037763668,
              43.153983482402566
            ]
          },
          "bbox": [
            -2.0952712037763668,
            43.153983482402566,
            -2.0952712037763668,
            43.153983482402566
          ]
        }
      ],
      "bbox": [
        -2.0952712037763668,
        43.153983482402566,
        -2.0952712037763668,
        43.153983482402566
      ]
    },
    "20042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0489776214673183,
              43.142497479704716
            ]
          },
          "bbox": [
            -2.0489776214673183,
            43.142497479704716,
            -2.0489776214673183,
            43.142497479704716
          ]
        }
      ],
      "bbox": [
        -2.0489776214673183,
        43.142497479704716,
        -2.0489776214673183,
        43.142497479704716
      ]
    },
    "20043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.232941942169125,
              42.98708567222595
            ]
          },
          "bbox": [
            -2.232941942169125,
            42.98708567222595,
            -2.232941942169125,
            42.98708567222595
          ]
        }
      ],
      "bbox": [
        -2.232941942169125,
        42.98708567222595,
        -2.232941942169125,
        42.98708567222595
      ]
    },
    "20044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.120697342809511,
              43.09100783869554
            ]
          },
          "bbox": [
            -2.120697342809511,
            43.09100783869554,
            -2.120697342809511,
            43.09100783869554
          ]
        }
      ],
      "bbox": [
        -2.120697342809511,
        43.09100783869554,
        -2.120697342809511,
        43.09100783869554
      ]
    },
    "20045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7845959022893532,
              43.318786795135935
            ]
          },
          "bbox": [
            -1.7845959022893532,
            43.318786795135935,
            -1.7845959022893532,
            43.318786795135935
          ]
        }
      ],
      "bbox": [
        -1.7845959022893532,
        43.318786795135935,
        -1.7845959022893532,
        43.318786795135935
      ]
    },
    "20046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0572712560621267,
              43.16543892174288
            ]
          },
          "bbox": [
            -2.0572712560621267,
            43.16543892174288,
            -2.0572712560621267,
            43.16543892174288
          ]
        }
      ],
      "bbox": [
        -2.0572712560621267,
        43.16543892174288,
        -2.0572712560621267,
        43.16543892174288
      ]
    },
    "20047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.180848355784982,
              43.082192576243735
            ]
          },
          "bbox": [
            -2.180848355784982,
            43.082192576243735,
            -2.180848355784982,
            43.082192576243735
          ]
        }
      ],
      "bbox": [
        -2.180848355784982,
        43.082192576243735,
        -2.180848355784982,
        43.082192576243735
      ]
    },
    "20048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1218015780158215,
              43.185998878097635
            ]
          },
          "bbox": [
            -2.1218015780158215,
            43.185998878097635,
            -2.1218015780158215,
            43.185998878097635
          ]
        }
      ],
      "bbox": [
        -2.1218015780158215,
        43.185998878097635,
        -2.1218015780158215,
        43.185998878097635
      ]
    },
    "20049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.171592570603497,
              43.021034296242384
            ]
          },
          "bbox": [
            -2.171592570603497,
            43.021034296242384,
            -2.171592570603497,
            43.021034296242384
          ]
        }
      ],
      "bbox": [
        -2.171592570603497,
        43.021034296242384,
        -2.171592570603497,
        43.021034296242384
      ]
    },
    "20050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0538718077254896,
              43.121210128000214
            ]
          },
          "bbox": [
            -2.0538718077254896,
            43.121210128000214,
            -2.0538718077254896,
            43.121210128000214
          ]
        }
      ],
      "bbox": [
        -2.0538718077254896,
        43.121210128000214,
        -2.0538718077254896,
        43.121210128000214
      ]
    },
    "20051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.342283430035297,
              43.02899286307686
            ]
          },
          "bbox": [
            -2.342283430035297,
            43.02899286307686,
            -2.342283430035297,
            43.02899286307686
          ]
        }
      ],
      "bbox": [
        -2.342283430035297,
        43.02899286307686,
        -2.342283430035297,
        43.02899286307686
      ]
    },
    "20052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.152090042177071,
              43.08908368837095
            ]
          },
          "bbox": [
            -2.152090042177071,
            43.08908368837095,
            -2.152090042177071,
            43.08908368837095
          ]
        }
      ],
      "bbox": [
        -2.152090042177071,
        43.08908368837095,
        -2.152090042177071,
        43.08908368837095
      ]
    },
    "20053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8774943525520997,
              43.327645005282555
            ]
          },
          "bbox": [
            -1.8774943525520997,
            43.327645005282555,
            -1.8774943525520997,
            43.327645005282555
          ]
        }
      ],
      "bbox": [
        -1.8774943525520997,
        43.327645005282555,
        -1.8774943525520997,
        43.327645005282555
      ]
    },
    "20054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.030928678462026,
              43.09392670189356
            ]
          },
          "bbox": [
            -2.030928678462026,
            43.09392670189356,
            -2.030928678462026,
            43.09392670189356
          ]
        }
      ],
      "bbox": [
        -2.030928678462026,
        43.09392670189356,
        -2.030928678462026,
        43.09392670189356
      ]
    },
    "20055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.499559977419762,
              43.0702192216076
            ]
          },
          "bbox": [
            -2.499559977419762,
            43.0702192216076,
            -2.499559977419762,
            43.0702192216076
          ]
        }
      ],
      "bbox": [
        -2.499559977419762,
        43.0702192216076,
        -2.499559977419762,
        43.0702192216076
      ]
    },
    "20056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3989970960127422,
              43.29356228548861
            ]
          },
          "bbox": [
            -2.3989970960127422,
            43.29356228548861,
            -2.3989970960127422,
            43.29356228548861
          ]
        }
      ],
      "bbox": [
        -2.3989970960127422,
        43.29356228548861,
        -2.3989970960127422,
        43.29356228548861
      ]
    },
    "20057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2813082779575877,
              43.02800630719762
            ]
          },
          "bbox": [
            -2.2813082779575877,
            43.02800630719762,
            -2.2813082779575877,
            43.02800630719762
          ]
        }
      ],
      "bbox": [
        -2.2813082779575877,
        43.02800630719762,
        -2.2813082779575877,
        43.02800630719762
      ]
    },
    "20058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.206790958468714,
              43.02424140927832
            ]
          },
          "bbox": [
            -2.206790958468714,
            43.02424140927832,
            -2.206790958468714,
            43.02424140927832
          ]
        }
      ],
      "bbox": [
        -2.206790958468714,
        43.02424140927832,
        -2.206790958468714,
        43.02424140927832
      ]
    },
    "20059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4138731615402276,
              43.01120175801977
            ]
          },
          "bbox": [
            -2.4138731615402276,
            43.01120175801977,
            -2.4138731615402276,
            43.01120175801977
          ]
        }
      ],
      "bbox": [
        -2.4138731615402276,
        43.01120175801977,
        -2.4138731615402276,
        43.01120175801977
      ]
    },
    "20060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0023025089704185,
              43.09010829161878
            ]
          },
          "bbox": [
            -2.0023025089704185,
            43.09010829161878,
            -2.0023025089704185,
            43.09010829161878
          ]
        }
      ],
      "bbox": [
        -2.0023025089704185,
        43.09010829161878,
        -2.0023025089704185,
        43.09010829161878
      ]
    },
    "20061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1075055330431285,
              43.28756991079731
            ]
          },
          "bbox": [
            -2.1075055330431285,
            43.28756991079731,
            -2.1075055330431285,
            43.28756991079731
          ]
        }
      ],
      "bbox": [
        -2.1075055330431285,
        43.28756991079731,
        -2.1075055330431285,
        43.28756991079731
      ]
    },
    "20062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.251486475027224,
              43.04213593828568
            ]
          },
          "bbox": [
            -2.251486475027224,
            43.04213593828568,
            -2.251486475027224,
            43.04213593828568
          ]
        }
      ],
      "bbox": [
        -2.251486475027224,
        43.04213593828568,
        -2.251486475027224,
        43.04213593828568
      ]
    },
    "20063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8390351037916022,
              43.276468581684725
            ]
          },
          "bbox": [
            -1.8390351037916022,
            43.276468581684725,
            -1.8390351037916022,
            43.276468581684725
          ]
        }
      ],
      "bbox": [
        -1.8390351037916022,
        43.276468581684725,
        -1.8390351037916022,
        43.276468581684725
      ]
    },
    "20064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8950501112743554,
              43.3387422125154
            ]
          },
          "bbox": [
            -1.8950501112743554,
            43.3387422125154,
            -1.8950501112743554,
            43.3387422125154
          ]
        }
      ],
      "bbox": [
        -1.8950501112743554,
        43.3387422125154,
        -1.8950501112743554,
        43.3387422125154
      ]
    },
    "20065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4114871038229158,
              43.17777762689588
            ]
          },
          "bbox": [
            -2.4114871038229158,
            43.17777762689588,
            -2.4114871038229158,
            43.17777762689588
          ]
        }
      ],
      "bbox": [
        -2.4114871038229158,
        43.17777762689588,
        -2.4114871038229158,
        43.17777762689588
      ]
    },
    "20066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.185304123769179,
              43.175601033033296
            ]
          },
          "bbox": [
            -2.185304123769179,
            43.175601033033296,
            -2.185304123769179,
            43.175601033033296
          ]
        }
      ],
      "bbox": [
        -2.185304123769179,
        43.175601033033296,
        -2.185304123769179,
        43.175601033033296
      ]
    },
    "20067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8884233156646724,
              43.26445309878243
            ]
          },
          "bbox": [
            -1.8884233156646724,
            43.26445309878243,
            -1.8884233156646724,
            43.26445309878243
          ]
        }
      ],
      "bbox": [
        -1.8884233156646724,
        43.26445309878243,
        -1.8884233156646724,
        43.26445309878243
      ]
    },
    "20068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5642553431084667,
              42.97526228127149
            ]
          },
          "bbox": [
            -2.5642553431084667,
            42.97526228127149,
            -2.5642553431084667,
            42.97526228127149
          ]
        }
      ],
      "bbox": [
        -2.5642553431084667,
        42.97526228127149,
        -2.5642553431084667,
        42.97526228127149
      ]
    },
    "20069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.98811516306943,
              43.29532043685433
            ]
          },
          "bbox": [
            -1.98811516306943,
            43.29532043685433,
            -1.98811516306943,
            43.29532043685433
          ]
        }
      ],
      "bbox": [
        -1.98811516306943,
        43.29532043685433,
        -1.98811516306943,
        43.29532043685433
      ]
    },
    "20070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.259604822761421,
              42.99646770795685
            ]
          },
          "bbox": [
            -2.259604822761421,
            42.99646770795685,
            -2.259604822761421,
            42.99646770795685
          ]
        }
      ],
      "bbox": [
        -2.259604822761421,
        42.99646770795685,
        -2.259604822761421,
        42.99646770795685
      ]
    },
    "20071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.080991431756009,
              43.1062669056323
            ]
          },
          "bbox": [
            -2.080991431756009,
            43.1062669056323,
            -2.080991431756009,
            43.1062669056323
          ]
        }
      ],
      "bbox": [
        -2.080991431756009,
        43.1062669056323,
        -2.080991431756009,
        43.1062669056323
      ]
    },
    "20072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9715692219309078,
              43.22715131422157
            ]
          },
          "bbox": [
            -1.9715692219309078,
            43.22715131422157,
            -1.9715692219309078,
            43.22715131422157
          ]
        }
      ],
      "bbox": [
        -1.9715692219309078,
        43.22715131422157,
        -1.9715692219309078,
        43.22715131422157
      ]
    },
    "20073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0625717751160457,
              43.273472277081055
            ]
          },
          "bbox": [
            -2.0625717751160457,
            43.273472277081055,
            -2.0625717751160457,
            43.273472277081055
          ]
        }
      ],
      "bbox": [
        -2.0625717751160457,
        43.273472277081055,
        -2.0625717751160457,
        43.273472277081055
      ]
    },
    "20074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4288081788687443,
              43.12477136377127
            ]
          },
          "bbox": [
            -2.4288081788687443,
            43.12477136377127,
            -2.4288081788687443,
            43.12477136377127
          ]
        }
      ],
      "bbox": [
        -2.4288081788687443,
        43.12477136377127,
        -2.4288081788687443,
        43.12477136377127
      ]
    },
    "20075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.030633206568683,
              43.180203403684104
            ]
          },
          "bbox": [
            -2.030633206568683,
            43.180203403684104,
            -2.030633206568683,
            43.180203403684104
          ]
        }
      ],
      "bbox": [
        -2.030633206568683,
        43.180203403684104,
        -2.030633206568683,
        43.180203403684104
      ]
    },
    "20076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.181388448952444,
              43.05905364454129
            ]
          },
          "bbox": [
            -2.181388448952444,
            43.05905364454129,
            -2.181388448952444,
            43.05905364454129
          ]
        }
      ],
      "bbox": [
        -2.181388448952444,
        43.05905364454129,
        -2.181388448952444,
        43.05905364454129
      ]
    },
    "20077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.331387377633416,
              43.09698947780623
            ]
          },
          "bbox": [
            -2.331387377633416,
            43.09698947780623,
            -2.331387377633416,
            43.09698947780623
          ]
        }
      ],
      "bbox": [
        -2.331387377633416,
        43.09698947780623,
        -2.331387377633416,
        43.09698947780623
      ]
    },
    "20078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1378603598889874,
              43.025532372639795
            ]
          },
          "bbox": [
            -2.1378603598889874,
            43.025532372639795,
            -2.1378603598889874,
            43.025532372639795
          ]
        }
      ],
      "bbox": [
        -2.1378603598889874,
        43.025532372639795,
        -2.1378603598889874,
        43.025532372639795
      ]
    },
    "20079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1690938760405136,
              43.27406851879796
            ]
          },
          "bbox": [
            -2.1690938760405136,
            43.27406851879796,
            -2.1690938760405136,
            43.27406851879796
          ]
        }
      ],
      "bbox": [
        -2.1690938760405136,
        43.27406851879796,
        -2.1690938760405136,
        43.27406851879796
      ]
    },
    "20080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.309961934743084,
              43.102259257706464
            ]
          },
          "bbox": [
            -2.309961934743084,
            43.102259257706464,
            -2.309961934743084,
            43.102259257706464
          ]
        }
      ],
      "bbox": [
        -2.309961934743084,
        43.102259257706464,
        -2.309961934743084,
        43.102259257706464
      ]
    },
    "20081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.245012179665456,
              43.286061472031356
            ]
          },
          "bbox": [
            -2.245012179665456,
            43.286061472031356,
            -2.245012179665456,
            43.286061472031356
          ]
        }
      ],
      "bbox": [
        -2.245012179665456,
        43.286061472031356,
        -2.245012179665456,
        43.286061472031356
      ]
    },
    "20901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.381475269139667,
              43.246436746649046
            ]
          },
          "bbox": [
            -2.381475269139667,
            43.246436746649046,
            -2.381475269139667,
            43.246436746649046
          ]
        }
      ],
      "bbox": [
        -2.381475269139667,
        43.246436746649046,
        -2.381475269139667,
        43.246436746649046
      ]
    },
    "20902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0128788281563077,
              43.26050066669648
            ]
          },
          "bbox": [
            -2.0128788281563077,
            43.26050066669648,
            -2.0128788281563077,
            43.26050066669648
          ]
        }
      ],
      "bbox": [
        -2.0128788281563077,
        43.26050066669648,
        -2.0128788281563077,
        43.26050066669648
      ]
    },
    "20903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9337047894705668,
              43.27771833234438
            ]
          },
          "bbox": [
            -1.9337047894705668,
            43.27771833234438,
            -1.9337047894705668,
            43.27771833234438
          ]
        }
      ],
      "bbox": [
        -1.9337047894705668,
        43.27771833234438,
        -1.9337047894705668,
        43.27771833234438
      ]
    },
    "20904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1321060595881085,
              43.0723545046912
            ]
          },
          "bbox": [
            -2.1321060595881085,
            43.0723545046912,
            -2.1321060595881085,
            43.0723545046912
          ]
        }
      ],
      "bbox": [
        -2.1321060595881085,
        43.0723545046912,
        -2.1321060595881085,
        43.0723545046912
      ]
    },
    "20905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1064083720903373,
              43.07814917984498
            ]
          },
          "bbox": [
            -2.1064083720903373,
            43.07814917984498,
            -2.1064083720903373,
            43.07814917984498
          ]
        }
      ],
      "bbox": [
        -2.1064083720903373,
        43.07814917984498,
        -2.1064083720903373,
        43.07814917984498
      ]
    },
    "20906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.150615335761344,
              43.065323795986934
            ]
          },
          "bbox": [
            -2.150615335761344,
            43.065323795986934,
            -2.150615335761344,
            43.065323795986934
          ]
        }
      ],
      "bbox": [
        -2.150615335761344,
        43.065323795986934,
        -2.150615335761344,
        43.065323795986934
      ]
    },
    "20907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0183603539520982,
              43.10660134430379
            ]
          },
          "bbox": [
            -2.0183603539520982,
            43.10660134430379,
            -2.0183603539520982,
            43.10660134430379
          ]
        }
      ],
      "bbox": [
        -2.0183603539520982,
        43.10660134430379,
        -2.0183603539520982,
        43.10660134430379
      ]
    },
    "21001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.655774814720478,
              37.84946810873664
            ]
          },
          "bbox": [
            -6.655774814720478,
            37.84946810873664,
            -6.655774814720478,
            37.84946810873664
          ]
        }
      ],
      "bbox": [
        -6.655774814720478,
        37.84946810873664,
        -6.655774814720478,
        37.84946810873664
      ]
    },
    "21002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.020014259564268,
              37.28142250136891
            ]
          },
          "bbox": [
            -7.020014259564268,
            37.28142250136891,
            -7.020014259564268,
            37.28142250136891
          ]
        }
      ],
      "bbox": [
        -7.020014259564268,
        37.28142250136891,
        -7.020014259564268,
        37.28142250136891
      ]
    },
    "21003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.340923330207723,
              37.55108933159128
            ]
          },
          "bbox": [
            -7.340923330207723,
            37.55108933159128,
            -7.340923330207723,
            37.55108933159128
          ]
        }
      ],
      "bbox": [
        -7.340923330207723,
        37.55108933159128,
        -7.340923330207723,
        37.55108933159128
      ]
    },
    "21004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.782909575804017,
              37.80028447124266
            ]
          },
          "bbox": [
            -6.782909575804017,
            37.80028447124266,
            -6.782909575804017,
            37.80028447124266
          ]
        }
      ],
      "bbox": [
        -6.782909575804017,
        37.80028447124266,
        -6.782909575804017,
        37.80028447124266
      ]
    },
    "21005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.5336446461275886,
              37.100662725152766
            ]
          },
          "bbox": [
            -6.5336446461275886,
            37.100662725152766,
            -6.5336446461275886,
            37.100662725152766
          ]
        }
      ],
      "bbox": [
        -6.5336446461275886,
        37.100662725152766,
        -6.5336446461275886,
        37.100662725152766
      ]
    },
    "21006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.093428874712056,
              37.56259378376679
            ]
          },
          "bbox": [
            -7.093428874712056,
            37.56259378376679,
            -7.093428874712056,
            37.56259378376679
          ]
        }
      ],
      "bbox": [
        -7.093428874712056,
        37.56259378376679,
        -7.093428874712056,
        37.56259378376679
      ]
    },
    "21007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.55711631896117,
              37.87210199632599
            ]
          },
          "bbox": [
            -6.55711631896117,
            37.87210199632599,
            -6.55711631896117,
            37.87210199632599
          ]
        }
      ],
      "bbox": [
        -6.55711631896117,
        37.87210199632599,
        -6.55711631896117,
        37.87210199632599
      ]
    },
    "21008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.989660340337102,
              37.94775537819301
            ]
          },
          "bbox": [
            -6.989660340337102,
            37.94775537819301,
            -6.989660340337102,
            37.94775537819301
          ]
        }
      ],
      "bbox": [
        -6.989660340337102,
        37.94775537819301,
        -6.989660340337102,
        37.94775537819301
      ]
    },
    "21009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.41800466788152,
              38.00777537344501
            ]
          },
          "bbox": [
            -6.41800466788152,
            38.00777537344501,
            -6.41800466788152,
            38.00777537344501
          ]
        }
      ],
      "bbox": [
        -6.41800466788152,
        38.00777537344501,
        -6.41800466788152,
        38.00777537344501
      ]
    },
    "21010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.384396709465083,
              37.26862165479579
            ]
          },
          "bbox": [
            -7.384396709465083,
            37.26862165479579,
            -7.384396709465083,
            37.26862165479579
          ]
        }
      ],
      "bbox": [
        -7.384396709465083,
        37.26862165479579,
        -7.384396709465083,
        37.26862165479579
      ]
    },
    "21011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.810101111933515,
              37.46213183651573
            ]
          },
          "bbox": [
            -6.810101111933515,
            37.46213183651573,
            -6.810101111933515,
            37.46213183651573
          ]
        }
      ],
      "bbox": [
        -6.810101111933515,
        37.46213183651573,
        -6.810101111933515,
        37.46213183651573
      ]
    },
    "21012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.508824724833291,
              37.566134924231555
            ]
          },
          "bbox": [
            -6.508824724833291,
            37.566134924231555,
            -6.508824724833291,
            37.566134924231555
          ]
        }
      ],
      "bbox": [
        -6.508824724833291,
        37.566134924231555,
        -6.508824724833291,
        37.566134924231555
      ]
    },
    "21013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.535889516509516,
              37.33011982622457
            ]
          },
          "bbox": [
            -6.535889516509516,
            37.33011982622457,
            -6.535889516509516,
            37.33011982622457
          ]
        }
      ],
      "bbox": [
        -6.535889516509516,
        37.33011982622457,
        -6.535889516509516,
        37.33011982622457
      ]
    },
    "21014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.689219563949114,
              37.277567103042756
            ]
          },
          "bbox": [
            -6.689219563949114,
            37.277567103042756,
            -6.689219563949114,
            37.277567103042756
          ]
        }
      ],
      "bbox": [
        -6.689219563949114,
        37.277567103042756,
        -6.689219563949114,
        37.277567103042756
      ]
    },
    "21015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.107027030564402,
              37.76682767186458
            ]
          },
          "bbox": [
            -7.107027030564402,
            37.76682767186458,
            -7.107027030564402,
            37.76682767186458
          ]
        }
      ],
      "bbox": [
        -7.107027030564402,
        37.76682767186458,
        -7.107027030564402,
        37.76682767186458
      ]
    },
    "21016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.332353839753794,
              37.95962957373001
            ]
          },
          "bbox": [
            -6.332353839753794,
            37.95962957373001,
            -6.332353839753794,
            37.95962957373001
          ]
        }
      ],
      "bbox": [
        -6.332353839753794,
        37.95962957373001,
        -6.332353839753794,
        37.95962957373001
      ]
    },
    "21017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.91911307768451,
              37.60159161741781
            ]
          },
          "bbox": [
            -6.91911307768451,
            37.60159161741781,
            -6.91911307768451,
            37.60159161741781
          ]
        }
      ],
      "bbox": [
        -6.91911307768451,
        37.60159161741781,
        -6.91911307768451,
        37.60159161741781
      ]
    },
    "21018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.648833122048647,
              37.70561493585945
            ]
          },
          "bbox": [
            -6.648833122048647,
            37.70561493585945,
            -6.648833122048647,
            37.70561493585945
          ]
        }
      ],
      "bbox": [
        -6.648833122048647,
        37.70561493585945,
        -6.648833122048647,
        37.70561493585945
      ]
    },
    "21019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.582749304022887,
              37.77032274260638
            ]
          },
          "bbox": [
            -6.582749304022887,
            37.77032274260638,
            -6.582749304022887,
            37.77032274260638
          ]
        }
      ],
      "bbox": [
        -6.582749304022887,
        37.77032274260638,
        -6.582749304022887,
        37.77032274260638
      ]
    },
    "21020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.500581495647093,
              37.99396820913087
            ]
          },
          "bbox": [
            -6.500581495647093,
            37.99396820913087,
            -6.500581495647093,
            37.99396820913087
          ]
        }
      ],
      "bbox": [
        -6.500581495647093,
        37.99396820913087,
        -6.500581495647093,
        37.99396820913087
      ]
    },
    "21021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.155017837482833,
              37.313545051659915
            ]
          },
          "bbox": [
            -7.155017837482833,
            37.313545051659915,
            -7.155017837482833,
            37.313545051659915
          ]
        }
      ],
      "bbox": [
        -7.155017837482833,
        37.313545051659915,
        -7.155017837482833,
        37.313545051659915
      ]
    },
    "21022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.687084484457341,
              37.888062118735654
            ]
          },
          "bbox": [
            -6.687084484457341,
            37.888062118735654,
            -6.687084484457341,
            37.888062118735654
          ]
        }
      ],
      "bbox": [
        -6.687084484457341,
        37.888062118735654,
        -6.687084484457341,
        37.888062118735654
      ]
    },
    "21023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.014957538874559,
              37.708222696467615
            ]
          },
          "bbox": [
            -7.014957538874559,
            37.708222696467615,
            -7.014957538874559,
            37.708222696467615
          ]
        }
      ],
      "bbox": [
        -7.014957538874559,
        37.708222696467615,
        -7.014957538874559,
        37.708222696467615
      ]
    },
    "21024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.493904338676734,
              37.933122558367685
            ]
          },
          "bbox": [
            -6.493904338676734,
            37.933122558367685,
            -6.493904338676734,
            37.933122558367685
          ]
        }
      ],
      "bbox": [
        -6.493904338676734,
        37.933122558367685,
        -6.493904338676734,
        37.933122558367685
      ]
    },
    "21025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.917816379978892,
              37.85503689372298
            ]
          },
          "bbox": [
            -6.917816379978892,
            37.85503689372298,
            -6.917816379978892,
            37.85503689372298
          ]
        }
      ],
      "bbox": [
        -6.917816379978892,
        37.85503689372298,
        -6.917816379978892,
        37.85503689372298
      ]
    },
    "21026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6248971246099035,
              37.958905038569405
            ]
          },
          "bbox": [
            -6.6248971246099035,
            37.958905038569405,
            -6.6248971246099035,
            37.958905038569405
          ]
        }
      ],
      "bbox": [
        -6.6248971246099035,
        37.958905038569405,
        -6.6248971246099035,
        37.958905038569405
      ]
    },
    "21027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.696655034339843,
              38.07531924251731
            ]
          },
          "bbox": [
            -6.696655034339843,
            38.07531924251731,
            -6.696655034339843,
            38.07531924251731
          ]
        }
      ],
      "bbox": [
        -6.696655034339843,
        38.07531924251731,
        -6.696655034339843,
        38.07531924251731
      ]
    },
    "21028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.798192261205267,
              38.05819551812268
            ]
          },
          "bbox": [
            -6.798192261205267,
            38.05819551812268,
            -6.798192261205267,
            38.05819551812268
          ]
        }
      ],
      "bbox": [
        -6.798192261205267,
        38.05819551812268,
        -6.798192261205267,
        38.05819551812268
      ]
    },
    "21029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.659777640951484,
              38.033319006542726
            ]
          },
          "bbox": [
            -6.659777640951484,
            38.033319006542726,
            -6.659777640951484,
            38.033319006542726
          ]
        }
      ],
      "bbox": [
        -6.659777640951484,
        38.033319006542726,
        -6.659777640951484,
        38.033319006542726
      ]
    },
    "21030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.394739958726796,
              37.35515953122609
            ]
          },
          "bbox": [
            -6.394739958726796,
            37.35515953122609,
            -6.394739958726796,
            37.35515953122609
          ]
        }
      ],
      "bbox": [
        -6.394739958726796,
        37.35515953122609,
        -6.394739958726796,
        37.35515953122609
      ]
    },
    "21031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.896805633816223,
              38.128066642923415
            ]
          },
          "bbox": [
            -6.896805633816223,
            38.128066642923415,
            -6.896805633816223,
            38.128066642923415
          ]
        }
      ],
      "bbox": [
        -6.896805633816223,
        38.128066642923415,
        -6.896805633816223,
        38.128066642923415
      ]
    },
    "21032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.390902222215061,
              37.49978317121455
            ]
          },
          "bbox": [
            -6.390902222215061,
            37.49978317121455,
            -6.390902222215061,
            37.49978317121455
          ]
        }
      ],
      "bbox": [
        -6.390902222215061,
        37.49978317121455,
        -6.390902222215061,
        37.49978317121455
      ]
    },
    "21033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.660356267788522,
              37.908002034134135
            ]
          },
          "bbox": [
            -6.660356267788522,
            37.908002034134135,
            -6.660356267788522,
            37.908002034134135
          ]
        }
      ],
      "bbox": [
        -6.660356267788522,
        37.908002034134135,
        -6.660356267788522,
        37.908002034134135
      ]
    },
    "21034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.691790375271591,
              37.92738785983874
            ]
          },
          "bbox": [
            -6.691790375271591,
            37.92738785983874,
            -6.691790375271591,
            37.92738785983874
          ]
        }
      ],
      "bbox": [
        -6.691790375271591,
        37.92738785983874,
        -6.691790375271591,
        37.92738785983874
      ]
    },
    "21035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.9945037404844665,
              37.396049966215784
            ]
          },
          "bbox": [
            -6.9945037404844665,
            37.396049966215784,
            -6.9945037404844665,
            37.396049966215784
          ]
        }
      ],
      "bbox": [
        -6.9945037404844665,
        37.396049966215784,
        -6.9945037404844665,
        37.396049966215784
      ]
    },
    "21036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.508913298256277,
              37.772140234630506
            ]
          },
          "bbox": [
            -6.508913298256277,
            37.772140234630506,
            -6.508913298256277,
            37.772140234630506
          ]
        }
      ],
      "bbox": [
        -6.508913298256277,
        37.772140234630506,
        -6.508913298256277,
        37.772140234630506
      ]
    },
    "21037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.429598939501775,
              37.518522062799995
            ]
          },
          "bbox": [
            -7.429598939501775,
            37.518522062799995,
            -7.429598939501775,
            37.518522062799995
          ]
        }
      ],
      "bbox": [
        -7.429598939501775,
        37.518522062799995,
        -7.429598939501775,
        37.518522062799995
      ]
    },
    "21038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.444443153012068,
              37.83345271890528
            ]
          },
          "bbox": [
            -6.444443153012068,
            37.83345271890528,
            -6.444443153012068,
            37.83345271890528
          ]
        }
      ],
      "bbox": [
        -6.444443153012068,
        37.83345271890528,
        -6.444443153012068,
        37.83345271890528
      ]
    },
    "21039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.572267468640608,
              38.001288607495205
            ]
          },
          "bbox": [
            -6.572267468640608,
            38.001288607495205,
            -6.572267468640608,
            38.001288607495205
          ]
        }
      ],
      "bbox": [
        -6.572267468640608,
        38.001288607495205,
        -6.572267468640608,
        38.001288607495205
      ]
    },
    "21040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.401122402630964,
              37.168082993577
            ]
          },
          "bbox": [
            -6.401122402630964,
            37.168082993577,
            -6.401122402630964,
            37.168082993577
          ]
        }
      ],
      "bbox": [
        -6.401122402630964,
        37.168082993577,
        -6.401122402630964,
        37.168082993577
      ]
    },
    "21041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.929388825165577,
              37.27229211442891
            ]
          },
          "bbox": [
            -6.929388825165577,
            37.27229211442891,
            -6.929388825165577,
            37.27229211442891
          ]
        }
      ],
      "bbox": [
        -6.929388825165577,
        37.27229211442891,
        -6.929388825165577,
        37.27229211442891
      ]
    },
    "21042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.28717897379889,
              37.225570293193755
            ]
          },
          "bbox": [
            -7.28717897379889,
            37.225570293193755,
            -7.28717897379889,
            37.225570293193755
          ]
        }
      ],
      "bbox": [
        -7.28717897379889,
        37.225570293193755,
        -7.28717897379889,
        37.225570293193755
      ]
    },
    "21043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.749932949258397,
              37.91247036019448
            ]
          },
          "bbox": [
            -6.749932949258397,
            37.91247036019448,
            -6.749932949258397,
            37.91247036019448
          ]
        }
      ],
      "bbox": [
        -6.749932949258397,
        37.91247036019448,
        -6.749932949258397,
        37.91247036019448
      ]
    },
    "21044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.224296233521067,
              37.280112735401204
            ]
          },
          "bbox": [
            -7.224296233521067,
            37.280112735401204,
            -7.224296233521067,
            37.280112735401204
          ]
        }
      ],
      "bbox": [
        -7.224296233521067,
        37.280112735401204,
        -7.224296233521067,
        37.280112735401204
      ]
    },
    "21045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.606745108239853,
              37.85444888131544
            ]
          },
          "bbox": [
            -6.606745108239853,
            37.85444888131544,
            -6.606745108239853,
            37.85444888131544
          ]
        }
      ],
      "bbox": [
        -6.606745108239853,
        37.85444888131544,
        -6.606745108239853,
        37.85444888131544
      ]
    },
    "21046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.736416869896544,
              37.251567414060275
            ]
          },
          "bbox": [
            -6.736416869896544,
            37.251567414060275,
            -6.736416869896544,
            37.251567414060275
          ]
        }
      ],
      "bbox": [
        -6.736416869896544,
        37.251567414060275,
        -6.736416869896544,
        37.251567414060275
      ]
    },
    "21047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.448229041434801,
              37.403481440586354
            ]
          },
          "bbox": [
            -6.448229041434801,
            37.403481440586354,
            -6.448229041434801,
            37.403481440586354
          ]
        }
      ],
      "bbox": [
        -6.448229041434801,
        37.403481440586354,
        -6.448229041434801,
        37.403481440586354
      ]
    },
    "21048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.621995920401446,
              37.90221841724858
            ]
          },
          "bbox": [
            -6.621995920401446,
            37.90221841724858,
            -6.621995920401446,
            37.90221841724858
          ]
        }
      ],
      "bbox": [
        -6.621995920401446,
        37.90221841724858,
        -6.621995920401446,
        37.90221841724858
      ]
    },
    "21049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.583759765036355,
              37.70018420213757
            ]
          },
          "bbox": [
            -6.583759765036355,
            37.70018420213757,
            -6.583759765036355,
            37.70018420213757
          ]
        }
      ],
      "bbox": [
        -6.583759765036355,
        37.70018420213757,
        -6.583759765036355,
        37.70018420213757
      ]
    },
    "21050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.808002574944454,
              37.20909837508591
            ]
          },
          "bbox": [
            -6.808002574944454,
            37.20909837508591,
            -6.808002574944454,
            37.20909837508591
          ]
        }
      ],
      "bbox": [
        -6.808002574944454,
        37.20909837508591,
        -6.808002574944454,
        37.20909837508591
      ]
    },
    "21051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.758398329764613,
              37.97420869094016
            ]
          },
          "bbox": [
            -6.758398329764613,
            37.97420869094016,
            -6.758398329764613,
            37.97420869094016
          ]
        }
      ],
      "bbox": [
        -6.758398329764613,
        37.97420869094016,
        -6.758398329764613,
        37.97420869094016
      ]
    },
    "21052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.529021379926839,
              37.70509822332697
            ]
          },
          "bbox": [
            -6.529021379926839,
            37.70509822332697,
            -6.529021379926839,
            37.70509822332697
          ]
        }
      ],
      "bbox": [
        -6.529021379926839,
        37.70509822332697,
        -6.529021379926839,
        37.70509822332697
      ]
    },
    "21053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6786492850095955,
              37.434947758541
            ]
          },
          "bbox": [
            -6.6786492850095955,
            37.434947758541,
            -6.6786492850095955,
            37.434947758541
          ]
        }
      ],
      "bbox": [
        -6.6786492850095955,
        37.434947758541,
        -6.6786492850095955,
        37.434947758541
      ]
    },
    "21054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.555748074940826,
              37.40287874974207
            ]
          },
          "bbox": [
            -6.555748074940826,
            37.40287874974207,
            -6.555748074940826,
            37.40287874974207
          ]
        }
      ],
      "bbox": [
        -6.555748074940826,
        37.40287874974207,
        -6.555748074940826,
        37.40287874974207
      ]
    },
    "21055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.892372448522797,
              37.19382594758374
            ]
          },
          "bbox": [
            -6.892372448522797,
            37.19382594758374,
            -6.892372448522797,
            37.19382594758374
          ]
        }
      ],
      "bbox": [
        -6.892372448522797,
        37.19382594758374,
        -6.892372448522797,
        37.19382594758374
      ]
    },
    "21056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.465653695170381,
              37.47189379305245
            ]
          },
          "bbox": [
            -6.465653695170381,
            37.47189379305245,
            -6.465653695170381,
            37.47189379305245
          ]
        }
      ],
      "bbox": [
        -6.465653695170381,
        37.47189379305245,
        -6.465653695170381,
        37.47189379305245
      ]
    },
    "21057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.316480488913154,
              37.74683310155078
            ]
          },
          "bbox": [
            -7.316480488913154,
            37.74683310155078,
            -7.316480488913154,
            37.74683310155078
          ]
        }
      ],
      "bbox": [
        -7.316480488913154,
        37.74683310155078,
        -7.316480488913154,
        37.74683310155078
      ]
    },
    "21058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.30187884132654,
              37.63553766853275
            ]
          },
          "bbox": [
            -7.30187884132654,
            37.63553766853275,
            -7.30187884132654,
            37.63553766853275
          ]
        }
      ],
      "bbox": [
        -7.30187884132654,
        37.63553766853275,
        -7.30187884132654,
        37.63553766853275
      ]
    },
    "21059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.465874148471716,
              37.892187592893606
            ]
          },
          "bbox": [
            -6.465874148471716,
            37.892187592893606,
            -6.465874148471716,
            37.892187592893606
          ]
        }
      ],
      "bbox": [
        -6.465874148471716,
        37.892187592893606,
        -6.465874148471716,
        37.892187592893606
      ]
    },
    "21060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.017186093415294,
              37.216543018667984
            ]
          },
          "bbox": [
            -7.017186093415294,
            37.216543018667984,
            -7.017186093415294,
            37.216543018667984
          ]
        }
      ],
      "bbox": [
        -7.017186093415294,
        37.216543018667984,
        -7.017186093415294,
        37.216543018667984
      ]
    },
    "21061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.617646868756585,
              37.29069531356811
            ]
          },
          "bbox": [
            -6.617646868756585,
            37.29069531356811,
            -6.617646868756585,
            37.29069531356811
          ]
        }
      ],
      "bbox": [
        -6.617646868756585,
        37.29069531356811,
        -6.617646868756585,
        37.29069531356811
      ]
    },
    "21062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.188840745951633,
              37.9247859036536
            ]
          },
          "bbox": [
            -7.188840745951633,
            37.9247859036536,
            -7.188840745951633,
            37.9247859036536
          ]
        }
      ],
      "bbox": [
        -7.188840745951633,
        37.9247859036536,
        -7.188840745951633,
        37.9247859036536
      ]
    },
    "21063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.098828764804481,
              37.433197533763874
            ]
          },
          "bbox": [
            -7.098828764804481,
            37.433197533763874,
            -7.098828764804481,
            37.433197533763874
          ]
        }
      ],
      "bbox": [
        -7.098828764804481,
        37.433197533763874,
        -7.098828764804481,
        37.433197533763874
      ]
    },
    "21064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.843077241883488,
              37.34018116368581
            ]
          },
          "bbox": [
            -6.843077241883488,
            37.34018116368581,
            -6.843077241883488,
            37.34018116368581
          ]
        }
      ],
      "bbox": [
        -6.843077241883488,
        37.34018116368581,
        -6.843077241883488,
        37.34018116368581
      ]
    },
    "21065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.403503964841802,
              37.44918880439836
            ]
          },
          "bbox": [
            -7.403503964841802,
            37.44918880439836,
            -7.403503964841802,
            37.44918880439836
          ]
        }
      ],
      "bbox": [
        -7.403503964841802,
        37.44918880439836,
        -7.403503964841802,
        37.44918880439836
      ]
    },
    "21066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.378848730751568,
              37.38555762596135
            ]
          },
          "bbox": [
            -7.378848730751568,
            37.38555762596135,
            -7.378848730751568,
            37.38555762596135
          ]
        }
      ],
      "bbox": [
        -7.378848730751568,
        37.38555762596135,
        -7.378848730751568,
        37.38555762596135
      ]
    },
    "21067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.720135777922278,
              37.85397723806905
            ]
          },
          "bbox": [
            -6.720135777922278,
            37.85397723806905,
            -6.720135777922278,
            37.85397723806905
          ]
        }
      ],
      "bbox": [
        -6.720135777922278,
        37.85397723806905,
        -6.720135777922278,
        37.85397723806905
      ]
    },
    "21068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.203376749638048,
              37.79638273721112
            ]
          },
          "bbox": [
            -7.203376749638048,
            37.79638273721112,
            -7.203376749638048,
            37.79638273721112
          ]
        }
      ],
      "bbox": [
        -7.203376749638048,
        37.79638273721112,
        -7.203376749638048,
        37.79638273721112
      ]
    },
    "21069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.219174778310682,
              37.87941857396251
            ]
          },
          "bbox": [
            -6.219174778310682,
            37.87941857396251,
            -6.219174778310682,
            37.87941857396251
          ]
        }
      ],
      "bbox": [
        -6.219174778310682,
        37.87941857396251,
        -6.219174778310682,
        37.87941857396251
      ]
    },
    "21070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.848521065093983,
              37.413761074174126
            ]
          },
          "bbox": [
            -6.848521065093983,
            37.413761074174126,
            -6.848521065093983,
            37.413761074174126
          ]
        }
      ],
      "bbox": [
        -6.848521065093983,
        37.413761074174126,
        -6.848521065093983,
        37.413761074174126
      ]
    },
    "21071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.671177489038619,
              37.951728409810585
            ]
          },
          "bbox": [
            -6.671177489038619,
            37.951728409810585,
            -6.671177489038619,
            37.951728409810585
          ]
        }
      ],
      "bbox": [
        -6.671177489038619,
        37.951728409810585,
        -6.671177489038619,
        37.951728409810585
      ]
    },
    "21072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.739949229520807,
              37.559143952298776
            ]
          },
          "bbox": [
            -6.739949229520807,
            37.559143952298776,
            -6.739949229520807,
            37.559143952298776
          ]
        }
      ],
      "bbox": [
        -6.739949229520807,
        37.559143952298776,
        -6.739949229520807,
        37.559143952298776
      ]
    },
    "21073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.330746357163093,
              37.30767191289902
            ]
          },
          "bbox": [
            -7.330746357163093,
            37.30767191289902,
            -7.330746357163093,
            37.30767191289902
          ]
        }
      ],
      "bbox": [
        -7.330746357163093,
        37.30767191289902,
        -7.330746357163093,
        37.30767191289902
      ]
    },
    "21074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.493481480321095,
              37.402332532578654
            ]
          },
          "bbox": [
            -6.493481480321095,
            37.402332532578654,
            -6.493481480321095,
            37.402332532578654
          ]
        }
      ],
      "bbox": [
        -6.493481480321095,
        37.402332532578654,
        -6.493481480321095,
        37.402332532578654
      ]
    },
    "21075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.014255020585162,
              37.61889517037866
            ]
          },
          "bbox": [
            -7.014255020585162,
            37.61889517037866,
            -7.014255020585162,
            37.61889517037866
          ]
        }
      ],
      "bbox": [
        -7.014255020585162,
        37.61889517037866,
        -7.014255020585162,
        37.61889517037866
      ]
    },
    "21076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.207877116099351,
              37.45520659950577
            ]
          },
          "bbox": [
            -7.207877116099351,
            37.45520659950577,
            -7.207877116099351,
            37.45520659950577
          ]
        }
      ],
      "bbox": [
        -7.207877116099351,
        37.45520659950577,
        -7.207877116099351,
        37.45520659950577
      ]
    },
    "21077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.628208490789163,
              37.40078867757712
            ]
          },
          "bbox": [
            -6.628208490789163,
            37.40078867757712,
            -6.628208490789163,
            37.40078867757712
          ]
        }
      ],
      "bbox": [
        -6.628208490789163,
        37.40078867757712,
        -6.628208490789163,
        37.40078867757712
      ]
    },
    "21078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6756742024399465,
              37.63701153570946
            ]
          },
          "bbox": [
            -6.6756742024399465,
            37.63701153570946,
            -6.6756742024399465,
            37.63701153570946
          ]
        }
      ],
      "bbox": [
        -6.6756742024399465,
        37.63701153570946,
        -6.6756742024399465,
        37.63701153570946
      ]
    },
    "21079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.36062528789506,
              37.8230524841152
            ]
          },
          "bbox": [
            -6.36062528789506,
            37.8230524841152,
            -6.36062528789506,
            37.8230524841152
          ]
        }
      ],
      "bbox": [
        -6.36062528789506,
        37.8230524841152,
        -6.36062528789506,
        37.8230524841152
      ]
    },
    "21902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.873578620493165,
              37.69067850874531
            ]
          },
          "bbox": [
            -6.873578620493165,
            37.69067850874531,
            -6.873578620493165,
            37.69067850874531
          ]
        }
      ],
      "bbox": [
        -6.873578620493165,
        37.69067850874531,
        -6.873578620493165,
        37.69067850874531
      ]
    },
    "22001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.06547509634155829,
              42.13661767940552
            ]
          },
          "bbox": [
            -0.06547509634155829,
            42.13661767940552,
            -0.06547509634155829,
            42.13661767940552
          ]
        }
      ],
      "bbox": [
        -0.06547509634155829,
        42.13661767940552,
        -0.06547509634155829,
        42.13661767940552
      ]
    },
    "22002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.19021829216792982,
              42.25686690794052
            ]
          },
          "bbox": [
            0.19021829216792982,
            42.25686690794052,
            0.19021829216792982,
            42.25686690794052
          ]
        }
      ],
      "bbox": [
        0.19021829216792982,
        42.25686690794052,
        0.19021829216792982,
        42.25686690794052
      ]
    },
    "22003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.02500174263464652,
              42.198214057736536
            ]
          },
          "bbox": [
            -0.02500174263464652,
            42.198214057736536,
            -0.02500174263464652,
            42.198214057736536
          ]
        }
      ],
      "bbox": [
        -0.02500174263464652,
        42.198214057736536,
        -0.02500174263464652,
        42.198214057736536
      ]
    },
    "22004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8302102817121323,
              42.322952549669246
            ]
          },
          "bbox": [
            -0.8302102817121323,
            42.322952549669246,
            -0.8302102817121323,
            42.322952549669246
          ]
        }
      ],
      "bbox": [
        -0.8302102817121323,
        42.322952549669246,
        -0.8302102817121323,
        42.322952549669246
      ]
    },
    "22006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6022171637674317,
              42.70821635171492
            ]
          },
          "bbox": [
            -0.6022171637674317,
            42.70821635171492,
            -0.6022171637674317,
            42.70821635171492
          ]
        }
      ],
      "bbox": [
        -0.6022171637674317,
        42.70821635171492,
        -0.6022171637674317,
        42.70821635171492
      ]
    },
    "22007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.16541955139951692,
              41.739863268375544
            ]
          },
          "bbox": [
            0.16541955139951692,
            41.739863268375544,
            0.16541955139951692,
            41.739863268375544
          ]
        }
      ],
      "bbox": [
        0.16541955139951692,
        41.739863268375544,
        0.16541955139951692,
        41.739863268375544
      ]
    },
    "22008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.15456787817592837,
              41.73512789531455
            ]
          },
          "bbox": [
            -0.15456787817592837,
            41.73512789531455,
            -0.15456787817592837,
            41.73512789531455
          ]
        }
      ],
      "bbox": [
        -0.15456787817592837,
        41.73512789531455,
        -0.15456787817592837,
        41.73512789531455
      ]
    },
    "22009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.49407614964477864,
              41.84865605936141
            ]
          },
          "bbox": [
            0.49407614964477864,
            41.84865605936141,
            0.49407614964477864,
            41.84865605936141
          ]
        }
      ],
      "bbox": [
        0.49407614964477864,
        41.84865605936141,
        0.49407614964477864,
        41.84865605936141
      ]
    },
    "22011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3420285363288663,
              42.05267287298267
            ]
          },
          "bbox": [
            -0.3420285363288663,
            42.05267287298267,
            -0.3420285363288663,
            42.05267287298267
          ]
        }
      ],
      "bbox": [
        -0.3420285363288663,
        42.05267287298267,
        -0.3420285363288663,
        42.05267287298267
      ]
    },
    "22012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3820238580666589,
              42.02586940719791
            ]
          },
          "bbox": [
            -0.3820238580666589,
            42.02586940719791,
            -0.3820238580666589,
            42.02586940719791
          ]
        }
      ],
      "bbox": [
        -0.3820238580666589,
        42.02586940719791,
        -0.3820238580666589,
        42.02586940719791
      ]
    },
    "22013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.23210010235794304,
              41.89743925509119
            ]
          },
          "bbox": [
            -0.23210010235794304,
            41.89743925509119,
            -0.23210010235794304,
            41.89743925509119
          ]
        }
      ],
      "bbox": [
        -0.23210010235794304,
        41.89743925509119,
        -0.23210010235794304,
        41.89743925509119
      ]
    },
    "22014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6832913160572927,
              42.098366840789915
            ]
          },
          "bbox": [
            -0.6832913160572927,
            42.098366840789915,
            -0.6832913160572927,
            42.098366840789915
          ]
        }
      ],
      "bbox": [
        -0.6832913160572927,
        42.098366840789915,
        -0.6832913160572927,
        42.098366840789915
      ]
    },
    "22015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.275315572171859,
              42.08193970709368
            ]
          },
          "bbox": [
            -0.275315572171859,
            42.08193970709368,
            -0.275315572171859,
            42.08193970709368
          ]
        }
      ],
      "bbox": [
        -0.275315572171859,
        42.08193970709368,
        -0.275315572171859,
        42.08193970709368
      ]
    },
    "22016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4356176356988388,
              41.87901085187761
            ]
          },
          "bbox": [
            0.4356176356988388,
            41.87901085187761,
            0.4356176356988388,
            41.87901085187761
          ]
        }
      ],
      "bbox": [
        0.4356176356988388,
        41.87901085187761,
        0.4356176356988388,
        41.87901085187761
      ]
    },
    "22017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.07782802671173149,
              41.72871331110105
            ]
          },
          "bbox": [
            0.07782802671173149,
            41.72871331110105,
            0.07782802671173149,
            41.72871331110105
          ]
        }
      ],
      "bbox": [
        0.07782802671173149,
        41.72871331110105,
        0.07782802671173149,
        41.72871331110105
      ]
    },
    "22018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4483968684210029,
              41.79148297575129
            ]
          },
          "bbox": [
            -0.4483968684210029,
            41.79148297575129,
            -0.4483968684210029,
            41.79148297575129
          ]
        }
      ],
      "bbox": [
        -0.4483968684210029,
        41.79148297575129,
        -0.4483968684210029,
        41.79148297575129
      ]
    },
    "22019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4801331504167815,
              42.1640219646154
            ]
          },
          "bbox": [
            -0.4801331504167815,
            42.1640219646154,
            -0.4801331504167815,
            42.1640219646154
          ]
        }
      ],
      "bbox": [
        -0.4801331504167815,
        42.1640219646154,
        -0.4801331504167815,
        42.1640219646154
      ]
    },
    "22020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.15177468342868167,
              41.82592448779149
            ]
          },
          "bbox": [
            0.15177468342868167,
            41.82592448779149,
            0.15177468342868167,
            41.82592448779149
          ]
        }
      ],
      "bbox": [
        0.15177468342868167,
        41.82592448779149,
        0.15177468342868167,
        41.82592448779149
      ]
    },
    "22021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5837404103886887,
              42.055302088265485
            ]
          },
          "bbox": [
            -0.5837404103886887,
            42.055302088265485,
            -0.5837404103886887,
            42.055302088265485
          ]
        }
      ],
      "bbox": [
        -0.5837404103886887,
        42.055302088265485,
        -0.5837404103886887,
        42.055302088265485
      ]
    },
    "22022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.23643930814695868,
              41.95036414828273
            ]
          },
          "bbox": [
            0.23643930814695868,
            41.95036414828273,
            0.23643930814695868,
            41.95036414828273
          ]
        }
      ],
      "bbox": [
        0.23643930814695868,
        41.95036414828273,
        0.23643930814695868,
        41.95036414828273
      ]
    },
    "22023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.43241338521032346,
              41.93123962255468
            ]
          },
          "bbox": [
            -0.43241338521032346,
            41.93123962255468,
            -0.43241338521032346,
            41.93123962255468
          ]
        }
      ],
      "bbox": [
        -0.43241338521032346,
        41.93123962255468,
        -0.43241338521032346,
        41.93123962255468
      ]
    },
    "22024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.0071203648071929745,
              42.17908473416386
            ]
          },
          "bbox": [
            0.0071203648071929745,
            42.17908473416386,
            0.0071203648071929745,
            42.17908473416386
          ]
        }
      ],
      "bbox": [
        0.0071203648071929745,
        42.17908473416386,
        0.0071203648071929745,
        42.17908473416386
      ]
    },
    "22025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4128053292340253,
              41.78429831294837
            ]
          },
          "bbox": [
            0.4128053292340253,
            41.78429831294837,
            0.4128053292340253,
            41.78429831294837
          ]
        }
      ],
      "bbox": [
        0.4128053292340253,
        41.78429831294837,
        0.4128053292340253,
        41.78429831294837
      ]
    },
    "22027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1587073669655171,
              42.08885175124101
            ]
          },
          "bbox": [
            -0.1587073669655171,
            42.08885175124101,
            -0.1587073669655171,
            42.08885175124101
          ]
        }
      ],
      "bbox": [
        -0.1587073669655171,
        42.08885175124101,
        -0.1587073669655171,
        42.08885175124101
      ]
    },
    "22028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7685334510549846,
              42.81084065737934
            ]
          },
          "bbox": [
            -0.7685334510549846,
            42.81084065737934,
            -0.7685334510549846,
            42.81084065737934
          ]
        }
      ],
      "bbox": [
        -0.7685334510549846,
        42.81084065737934,
        -0.7685334510549846,
        42.81084065737934
      ]
    },
    "22029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1721811385930427,
              42.02437261863661
            ]
          },
          "bbox": [
            -0.1721811385930427,
            42.02437261863661,
            -0.1721811385930427,
            42.02437261863661
          ]
        }
      ],
      "bbox": [
        -0.1721811385930427,
        42.02437261863661,
        -0.1721811385930427,
        42.02437261863661
      ]
    },
    "22032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6545109852431178,
              42.72813376558245
            ]
          },
          "bbox": [
            -0.6545109852431178,
            42.72813376558245,
            -0.6545109852431178,
            42.72813376558245
          ]
        }
      ],
      "bbox": [
        -0.6545109852431178,
        42.72813376558245,
        -0.6545109852431178,
        42.72813376558245
      ]
    },
    "22035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6748884789740704,
              42.28993856439615
            ]
          },
          "bbox": [
            0.6748884789740704,
            42.28993856439615,
            0.6748884789740704,
            42.28993856439615
          ]
        }
      ],
      "bbox": [
        0.6748884789740704,
        42.28993856439615,
        0.6748884789740704,
        42.28993856439615
      ]
    },
    "22036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.28104881621784483,
              42.05119047614449
            ]
          },
          "bbox": [
            -0.28104881621784483,
            42.05119047614449,
            -0.28104881621784483,
            42.05119047614449
          ]
        }
      ],
      "bbox": [
        -0.28104881621784483,
        42.05119047614449,
        -0.28104881621784483,
        42.05119047614449
      ]
    },
    "22037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4791441328994197,
              42.32207621927038
            ]
          },
          "bbox": [
            -0.4791441328994197,
            42.32207621927038,
            -0.4791441328994197,
            42.32207621927038
          ]
        }
      ],
      "bbox": [
        -0.4791441328994197,
        42.32207621927038,
        -0.4791441328994197,
        42.32207621927038
      ]
    },
    "22039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7009987521662433,
              42.250281582441275
            ]
          },
          "bbox": [
            -0.7009987521662433,
            42.250281582441275,
            -0.7009987521662433,
            42.250281582441275
          ]
        }
      ],
      "bbox": [
        -0.7009987521662433,
        42.250281582441275,
        -0.7009987521662433,
        42.250281582441275
      ]
    },
    "22040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.31025926225637446,
              41.98137453933882
            ]
          },
          "bbox": [
            0.31025926225637446,
            41.98137453933882,
            0.31025926225637446,
            41.98137453933882
          ]
        }
      ],
      "bbox": [
        0.31025926225637446,
        41.98137453933882,
        0.31025926225637446,
        41.98137453933882
      ]
    },
    "22041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.028810325529174976,
              42.06865417554528
            ]
          },
          "bbox": [
            -0.028810325529174976,
            42.06865417554528,
            -0.028810325529174976,
            42.06865417554528
          ]
        }
      ],
      "bbox": [
        -0.028810325529174976,
        42.06865417554528,
        -0.028810325529174976,
        42.06865417554528
      ]
    },
    "22042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.03883352416745444,
              42.097127887356685
            ]
          },
          "bbox": [
            -0.03883352416745444,
            42.097127887356685,
            -0.03883352416745444,
            42.097127887356685
          ]
        }
      ],
      "bbox": [
        -0.03883352416745444,
        42.097127887356685,
        -0.03883352416745444,
        42.097127887356685
      ]
    },
    "22043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4645000153068262,
              41.9580705881112
            ]
          },
          "bbox": [
            0.4645000153068262,
            41.9580705881112,
            0.4645000153068262,
            41.9580705881112
          ]
        }
      ],
      "bbox": [
        0.4645000153068262,
        41.9580705881112,
        0.4645000153068262,
        41.9580705881112
      ]
    },
    "22044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8071632066356319,
              42.50115063858185
            ]
          },
          "bbox": [
            -0.8071632066356319,
            42.50115063858185,
            -0.8071632066356319,
            42.50115063858185
          ]
        }
      ],
      "bbox": [
        -0.8071632066356319,
        42.50115063858185,
        -0.8071632066356319,
        42.50115063858185
      ]
    },
    "22045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.556009128387023,
              41.913000816542905
            ]
          },
          "bbox": [
            0.556009128387023,
            41.913000816542905,
            0.556009128387023,
            41.913000816542905
          ]
        }
      ],
      "bbox": [
        0.556009128387023,
        41.913000816542905,
        0.556009128387023,
        41.913000816542905
      ]
    },
    "22046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.13922630056159502,
              41.59139240471648
            ]
          },
          "bbox": [
            0.13922630056159502,
            41.59139240471648,
            0.13922630056159502,
            41.59139240471648
          ]
        }
      ],
      "bbox": [
        0.13922630056159502,
        41.59139240471648,
        0.13922630056159502,
        41.59139240471648
      ]
    },
    "22047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.45694600346368025,
              42.18907137111922
            ]
          },
          "bbox": [
            -0.45694600346368025,
            42.18907137111922,
            -0.45694600346368025,
            42.18907137111922
          ]
        }
      ],
      "bbox": [
        -0.45694600346368025,
        42.18907137111922,
        -0.45694600346368025,
        42.18907137111922
      ]
    },
    "22048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.12257785854242538,
              42.03573157944641
            ]
          },
          "bbox": [
            0.12257785854242538,
            42.03573157944641,
            0.12257785854242538,
            42.03573157944641
          ]
        }
      ],
      "bbox": [
        0.12257785854242538,
        42.03573157944641,
        0.12257785854242538,
        42.03573157944641
      ]
    },
    "22049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4370724796578746,
              41.98211503269065
            ]
          },
          "bbox": [
            -0.4370724796578746,
            41.98211503269065,
            -0.4370724796578746,
            41.98211503269065
          ]
        }
      ],
      "bbox": [
        -0.4370724796578746,
        41.98211503269065,
        -0.4370724796578746,
        41.98211503269065
      ]
    },
    "22050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.09808596443383101,
              42.0344690757519
            ]
          },
          "bbox": [
            -0.09808596443383101,
            42.0344690757519,
            -0.09808596443383101,
            42.0344690757519
          ]
        }
      ],
      "bbox": [
        -0.09808596443383101,
        42.0344690757519,
        -0.09808596443383101,
        42.0344690757519
      ]
    },
    "22051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.06368470976646731,
              42.249385252812836
            ]
          },
          "bbox": [
            0.06368470976646731,
            42.249385252812836,
            0.06368470976646731,
            42.249385252812836
          ]
        }
      ],
      "bbox": [
        0.06368470976646731,
        42.249385252812836,
        0.06368470976646731,
        42.249385252812836
      ]
    },
    "22052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2386553450783642,
              41.71283345378324
            ]
          },
          "bbox": [
            0.2386553450783642,
            41.71283345378324,
            0.2386553450783642,
            41.71283345378324
          ]
        }
      ],
      "bbox": [
        0.2386553450783642,
        41.71283345378324,
        0.2386553450783642,
        41.71283345378324
      ]
    },
    "22053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4792088359250725,
              42.083990932132025
            ]
          },
          "bbox": [
            0.4792088359250725,
            42.083990932132025,
            0.4792088359250725,
            42.083990932132025
          ]
        }
      ],
      "bbox": [
        0.4792088359250725,
        42.083990932132025,
        0.4792088359250725,
        42.083990932132025
      ]
    },
    "22054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5718555205887396,
              42.63648255973382
            ]
          },
          "bbox": [
            0.5718555205887396,
            42.63648255973382,
            0.5718555205887396,
            42.63648255973382
          ]
        }
      ],
      "bbox": [
        0.5718555205887396,
        42.63648255973382,
        0.5718555205887396,
        42.63648255973382
      ]
    },
    "22055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.003588551289932501,
              41.96448481043476
            ]
          },
          "bbox": [
            -0.003588551289932501,
            41.96448481043476,
            -0.003588551289932501,
            41.96448481043476
          ]
        }
      ],
      "bbox": [
        -0.003588551289932501,
        41.96448481043476,
        -0.003588551289932501,
        41.96448481043476
      ]
    },
    "22057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.17693929149989243,
              42.6695880127052
            ]
          },
          "bbox": [
            0.17693929149989243,
            42.6695880127052,
            0.17693929149989243,
            42.6695880127052
          ]
        }
      ],
      "bbox": [
        0.17693929149989243,
        42.6695880127052,
        0.17693929149989243,
        42.6695880127052
      ]
    },
    "22058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.09097611537829328,
              42.25027940607506
            ]
          },
          "bbox": [
            -0.09097611537829328,
            42.25027940607506,
            -0.09097611537829328,
            42.25027940607506
          ]
        }
      ],
      "bbox": [
        -0.09097611537829328,
        42.25027940607506,
        -0.09097611537829328,
        42.25027940607506
      ]
    },
    "22059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.31468124827920285,
              42.62885061433364
            ]
          },
          "bbox": [
            -0.31468124827920285,
            42.62885061433364,
            -0.31468124827920285,
            42.62885061433364
          ]
        }
      ],
      "bbox": [
        -0.31468124827920285,
        42.62885061433364,
        -0.31468124827920285,
        42.62885061433364
      ]
    },
    "22060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.20893220535845766,
              41.80884130554964
            ]
          },
          "bbox": [
            0.20893220535845766,
            41.80884130554964,
            0.20893220535845766,
            41.80884130554964
          ]
        }
      ],
      "bbox": [
        0.20893220535845766,
        41.80884130554964,
        0.20893220535845766,
        41.80884130554964
      ]
    },
    "22061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.29208645694233465,
              41.84538269139188
            ]
          },
          "bbox": [
            0.29208645694233465,
            41.84538269139188,
            0.29208645694233465,
            41.84538269139188
          ]
        }
      ],
      "bbox": [
        0.29208645694233465,
        41.84538269139188,
        0.29208645694233465,
        41.84538269139188
      ]
    },
    "22062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5242841158718462,
              42.487855826062344
            ]
          },
          "bbox": [
            0.5242841158718462,
            42.487855826062344,
            0.5242841158718462,
            42.487855826062344
          ]
        }
      ],
      "bbox": [
        0.5242841158718462,
        42.487855826062344,
        0.5242841158718462,
        42.487855826062344
      ]
    },
    "22063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7351952820655641,
              42.207869689625056
            ]
          },
          "bbox": [
            -0.7351952820655641,
            42.207869689625056,
            -0.7351952820655641,
            42.207869689625056
          ]
        }
      ],
      "bbox": [
        -0.7351952820655641,
        42.207869689625056,
        -0.7351952820655641,
        42.207869689625056
      ]
    },
    "22064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.20058478918070946,
              42.071367406754916
            ]
          },
          "bbox": [
            -0.20058478918070946,
            42.071367406754916,
            -0.20058478918070946,
            42.071367406754916
          ]
        }
      ],
      "bbox": [
        -0.20058478918070946,
        42.071367406754916,
        -0.20058478918070946,
        42.071367406754916
      ]
    },
    "22066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.002390255066486368,
              42.415257950982415
            ]
          },
          "bbox": [
            0.002390255066486368,
            42.415257950982415,
            0.002390255066486368,
            42.415257950982415
          ]
        }
      ],
      "bbox": [
        0.002390255066486368,
        42.415257950982415,
        0.002390255066486368,
        42.415257950982415
      ]
    },
    "22067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6912143592795424,
              42.41172134397702
            ]
          },
          "bbox": [
            0.6912143592795424,
            42.41172134397702,
            0.6912143592795424,
            42.41172134397702
          ]
        }
      ],
      "bbox": [
        0.6912143592795424,
        42.41172134397702,
        0.6912143592795424,
        42.41172134397702
      ]
    },
    "22068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5820907003235131,
              42.67454888595365
            ]
          },
          "bbox": [
            -0.5820907003235131,
            42.67454888595365,
            -0.5820907003235131,
            42.67454888595365
          ]
        }
      ],
      "bbox": [
        -0.5820907003235131,
        42.67454888595365,
        -0.5820907003235131,
        42.67454888595365
      ]
    },
    "22069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.14061679828678497,
              42.567229476413004
            ]
          },
          "bbox": [
            -0.14061679828678497,
            42.567229476413004,
            -0.14061679828678497,
            42.567229476413004
          ]
        }
      ],
      "bbox": [
        -0.14061679828678497,
        42.567229476413004,
        -0.14061679828678497,
        42.567229476413004
      ]
    },
    "22072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5101875568169013,
              42.40352119722234
            ]
          },
          "bbox": [
            -0.5101875568169013,
            42.40352119722234,
            -0.5101875568169013,
            42.40352119722234
          ]
        }
      ],
      "bbox": [
        -0.5101875568169013,
        42.40352119722234,
        -0.5101875568169013,
        42.40352119722234
      ]
    },
    "22074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.39519961977674234,
              42.42908153260901
            ]
          },
          "bbox": [
            0.39519961977674234,
            42.42908153260901,
            0.39519961977674234,
            42.42908153260901
          ]
        }
      ],
      "bbox": [
        0.39519961977674234,
        42.42908153260901,
        0.39519961977674234,
        42.42908153260901
      ]
    },
    "22075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5305638961685292,
              41.94913629475345
            ]
          },
          "bbox": [
            0.5305638961685292,
            41.94913629475345,
            0.5305638961685292,
            41.94913629475345
          ]
        }
      ],
      "bbox": [
        0.5305638961685292,
        41.94913629475345,
        0.5305638961685292,
        41.94913629475345
      ]
    },
    "22076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.861812848039507,
              42.60925380143316
            ]
          },
          "bbox": [
            -0.861812848039507,
            42.60925380143316,
            -0.861812848039507,
            42.60925380143316
          ]
        }
      ],
      "bbox": [
        -0.861812848039507,
        42.60925380143316,
        -0.861812848039507,
        42.60925380143316
      ]
    },
    "22077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.07014397693142978,
              41.49588920139267
            ]
          },
          "bbox": [
            0.07014397693142978,
            41.49588920139267,
            0.07014397693142978,
            41.49588920139267
          ]
        }
      ],
      "bbox": [
        0.07014397693142978,
        41.49588920139267,
        0.07014397693142978,
        41.49588920139267
      ]
    },
    "22078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4813823837121612,
              42.753471172326066
            ]
          },
          "bbox": [
            -0.4813823837121612,
            42.753471172326066,
            -0.4813823837121612,
            42.753471172326066
          ]
        }
      ],
      "bbox": [
        -0.4813823837121612,
        42.753471172326066,
        -0.4813823837121612,
        42.753471172326066
      ]
    },
    "22079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.18749633223565615,
              41.842640171319395
            ]
          },
          "bbox": [
            -0.18749633223565615,
            41.842640171319395,
            -0.18749633223565615,
            41.842640171319395
          ]
        }
      ],
      "bbox": [
        -0.18749633223565615,
        41.842640171319395,
        -0.18749633223565615,
        41.842640171319395
      ]
    },
    "22080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.438041495544534,
              42.1806500534294
            ]
          },
          "bbox": [
            0.438041495544534,
            42.1806500534294,
            0.438041495544534,
            42.1806500534294
          ]
        }
      ],
      "bbox": [
        0.438041495544534,
        42.1806500534294,
        0.438041495544534,
        42.1806500534294
      ]
    },
    "22081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.17739795343190007,
              42.21413387106241
            ]
          },
          "bbox": [
            -0.17739795343190007,
            42.21413387106241,
            -0.17739795343190007,
            42.21413387106241
          ]
        }
      ],
      "bbox": [
        -0.17739795343190007,
        42.21413387106241,
        -0.17739795343190007,
        42.21413387106241
      ]
    },
    "22082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.14122187543263506,
              41.96279049407863
            ]
          },
          "bbox": [
            0.14122187543263506,
            41.96279049407863,
            0.14122187543263506,
            41.96279049407863
          ]
        }
      ],
      "bbox": [
        0.14122187543263506,
        41.96279049407863,
        0.14122187543263506,
        41.96279049407863
      ]
    },
    "22083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.20882711915646818,
              41.64595591661905
            ]
          },
          "bbox": [
            -0.20882711915646818,
            41.64595591661905,
            -0.20882711915646818,
            41.64595591661905
          ]
        }
      ],
      "bbox": [
        -0.20882711915646818,
        41.64595591661905,
        -0.20882711915646818,
        41.64595591661905
      ]
    },
    "22084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5202859139798699,
              42.53021608795422
            ]
          },
          "bbox": [
            0.5202859139798699,
            42.53021608795422,
            0.5202859139798699,
            42.53021608795422
          ]
        }
      ],
      "bbox": [
        0.5202859139798699,
        42.53021608795422,
        0.5202859139798699,
        42.53021608795422
      ]
    },
    "22085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.007903488860359405,
              41.80009644422732
            ]
          },
          "bbox": [
            -0.007903488860359405,
            41.80009644422732,
            -0.007903488860359405,
            41.80009644422732
          ]
        }
      ],
      "bbox": [
        -0.007903488860359405,
        41.80009644422732,
        -0.007903488860359405,
        41.80009644422732
      ]
    },
    "22086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5555494870831393,
              42.63846393958827
            ]
          },
          "bbox": [
            -0.5555494870831393,
            42.63846393958827,
            -0.5555494870831393,
            42.63846393958827
          ]
        }
      ],
      "bbox": [
        -0.5555494870831393,
        42.63846393958827,
        -0.5555494870831393,
        42.63846393958827
      ]
    },
    "22087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.575680827993487,
              42.20659430308746
            ]
          },
          "bbox": [
            0.575680827993487,
            42.20659430308746,
            0.575680827993487,
            42.20659430308746
          ]
        }
      ],
      "bbox": [
        0.575680827993487,
        42.20659430308746,
        0.575680827993487,
        42.20659430308746
      ]
    },
    "22088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.05048737888004145,
              42.056620602670826
            ]
          },
          "bbox": [
            0.05048737888004145,
            42.056620602670826,
            0.05048737888004145,
            42.056620602670826
          ]
        }
      ],
      "bbox": [
        0.05048737888004145,
        42.056620602670826,
        0.05048737888004145,
        42.056620602670826
      ]
    },
    "22089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5420538044843151,
              41.87928619710334
            ]
          },
          "bbox": [
            0.5420538044843151,
            41.87928619710334,
            0.5420538044843151,
            41.87928619710334
          ]
        }
      ],
      "bbox": [
        0.5420538044843151,
        41.87928619710334,
        0.5420538044843151,
        41.87928619710334
      ]
    },
    "22090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.06698336344707172,
              42.18415337730801
            ]
          },
          "bbox": [
            0.06698336344707172,
            42.18415337730801,
            0.06698336344707172,
            42.18415337730801
          ]
        }
      ],
      "bbox": [
        0.06698336344707172,
        42.18415337730801,
        0.06698336344707172,
        42.18415337730801
      ]
    },
    "22094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.15422151258370972,
              41.667851182181515
            ]
          },
          "bbox": [
            0.15422151258370972,
            41.667851182181515,
            0.15422151258370972,
            41.667851182181515
          ]
        }
      ],
      "bbox": [
        0.15422151258370972,
        41.667851182181515,
        0.15422151258370972,
        41.667851182181515
      ]
    },
    "22095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.44446563907444886,
              42.52574334327553
            ]
          },
          "bbox": [
            0.44446563907444886,
            42.52574334327553,
            0.44446563907444886,
            42.52574334327553
          ]
        }
      ],
      "bbox": [
        0.44446563907444886,
        42.52574334327553,
        0.44446563907444886,
        42.52574334327553
      ]
    },
    "22096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4849258005315861,
              42.15790028570319
            ]
          },
          "bbox": [
            -0.4849258005315861,
            42.15790028570319,
            -0.4849258005315861,
            42.15790028570319
          ]
        }
      ],
      "bbox": [
        -0.4849258005315861,
        42.15790028570319,
        -0.4849258005315861,
        42.15790028570319
      ]
    },
    "22099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2988289166409155,
              41.7658429149525
            ]
          },
          "bbox": [
            0.2988289166409155,
            41.7658429149525,
            0.2988289166409155,
            41.7658429149525
          ]
        }
      ],
      "bbox": [
        0.2988289166409155,
        41.7658429149525,
        0.2988289166409155,
        41.7658429149525
      ]
    },
    "22102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.25028924794232865,
              42.07926819238382
            ]
          },
          "bbox": [
            0.25028924794232865,
            42.07926819238382,
            0.25028924794232865,
            42.07926819238382
          ]
        }
      ],
      "bbox": [
        0.25028924794232865,
        42.07926819238382,
        0.25028924794232865,
        42.07926819238382
      ]
    },
    "22103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.24923554335354595,
              42.04516320036551
            ]
          },
          "bbox": [
            0.24923554335354595,
            42.04516320036551,
            0.24923554335354595,
            42.04516320036551
          ]
        }
      ],
      "bbox": [
        0.24923554335354595,
        42.04516320036551,
        0.24923554335354595,
        42.04516320036551
      ]
    },
    "22105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5495916651194309,
              42.01015573770297
            ]
          },
          "bbox": [
            0.5495916651194309,
            42.01015573770297,
            0.5495916651194309,
            42.01015573770297
          ]
        }
      ],
      "bbox": [
        0.5495916651194309,
        42.01015573770297,
        0.5495916651194309,
        42.01015573770297
      ]
    },
    "22106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8799131990711001,
              42.72811078670477
            ]
          },
          "bbox": [
            -0.8799131990711001,
            42.72811078670477,
            -0.8799131990711001,
            42.72811078670477
          ]
        }
      ],
      "bbox": [
        -0.8799131990711001,
        42.72811078670477,
        -0.8799131990711001,
        42.72811078670477
      ]
    },
    "22107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.01297303681422333,
              42.595984250913624
            ]
          },
          "bbox": [
            0.01297303681422333,
            42.595984250913624,
            0.01297303681422333,
            42.595984250913624
          ]
        }
      ],
      "bbox": [
        0.01297303681422333,
        42.595984250913624,
        0.01297303681422333,
        42.595984250913624
      ]
    },
    "22109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.06598906043917649,
              42.485042647062585
            ]
          },
          "bbox": [
            -0.06598906043917649,
            42.485042647062585,
            -0.06598906043917649,
            42.485042647062585
          ]
        }
      ],
      "bbox": [
        -0.06598906043917649,
        42.485042647062585,
        -0.06598906043917649,
        42.485042647062585
      ]
    },
    "22110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.23882480753969476,
              42.001139223922245
            ]
          },
          "bbox": [
            0.23882480753969476,
            42.001139223922245,
            0.23882480753969476,
            42.001139223922245
          ]
        }
      ],
      "bbox": [
        0.23882480753969476,
        42.001139223922245,
        0.23882480753969476,
        42.001139223922245
      ]
    },
    "22111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.36690140638734087,
              42.41590386796316
            ]
          },
          "bbox": [
            0.36690140638734087,
            42.41590386796316,
            0.36690140638734087,
            42.41590386796316
          ]
        }
      ],
      "bbox": [
        0.36690140638734087,
        42.41590386796316,
        0.36690140638734087,
        42.41590386796316
      ]
    },
    "22112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.21608969704603054,
              41.48327288104909
            ]
          },
          "bbox": [
            0.21608969704603054,
            41.48327288104909,
            0.21608969704603054,
            41.48327288104909
          ]
        }
      ],
      "bbox": [
        0.21608969704603054,
        41.48327288104909,
        0.21608969704603054,
        41.48327288104909
      ]
    },
    "22113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.27112023773749533,
              42.365164439790895
            ]
          },
          "bbox": [
            0.27112023773749533,
            42.365164439790895,
            0.27112023773749533,
            42.365164439790895
          ]
        }
      ],
      "bbox": [
        0.27112023773749533,
        42.365164439790895,
        0.27112023773749533,
        42.365164439790895
      ]
    },
    "22114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.34928987826303576,
              42.662908750052424
            ]
          },
          "bbox": [
            0.34928987826303576,
            42.662908750052424,
            0.34928987826303576,
            42.662908750052424
          ]
        }
      ],
      "bbox": [
        0.34928987826303576,
        42.662908750052424,
        0.34928987826303576,
        42.662908750052424
      ]
    },
    "22115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.20245922414781534,
              42.13061359545943
            ]
          },
          "bbox": [
            0.20245922414781534,
            42.13061359545943,
            0.20245922414781534,
            42.13061359545943
          ]
        }
      ],
      "bbox": [
        0.20245922414781534,
        42.13061359545943,
        0.20245922414781534,
        42.13061359545943
      ]
    },
    "22116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.35446319521217773,
              41.92375532018027
            ]
          },
          "bbox": [
            -0.35446319521217773,
            41.92375532018027,
            -0.35446319521217773,
            41.92375532018027
          ]
        }
      ],
      "bbox": [
        -0.35446319521217773,
        41.92375532018027,
        -0.35446319521217773,
        41.92375532018027
      ]
    },
    "22117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.38475694507017094,
              42.209525190054904
            ]
          },
          "bbox": [
            0.38475694507017094,
            42.209525190054904,
            0.38475694507017094,
            42.209525190054904
          ]
        }
      ],
      "bbox": [
        0.38475694507017094,
        42.209525190054904,
        0.38475694507017094,
        42.209525190054904
      ]
    },
    "22119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.758539811751013,
              42.016489773779504
            ]
          },
          "bbox": [
            -0.758539811751013,
            42.016489773779504,
            -0.758539811751013,
            42.016489773779504
          ]
        }
      ],
      "bbox": [
        -0.758539811751013,
        42.016489773779504,
        -0.758539811751013,
        42.016489773779504
      ]
    },
    "22122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2939419452271581,
              42.68543102154214
            ]
          },
          "bbox": [
            -0.2939419452271581,
            42.68543102154214,
            -0.2939419452271581,
            42.68543102154214
          ]
        }
      ],
      "bbox": [
        -0.2939419452271581,
        42.68543102154214,
        -0.2939419452271581,
        42.68543102154214
      ]
    },
    "22124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1863540870798395,
              41.9258657714745
            ]
          },
          "bbox": [
            -0.1863540870798395,
            41.9258657714745,
            -0.1863540870798395,
            41.9258657714745
          ]
        }
      ],
      "bbox": [
        -0.1863540870798395,
        41.9258657714745,
        -0.1863540870798395,
        41.9258657714745
      ]
    },
    "22125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.42989086947695837,
              42.12791333620337
            ]
          },
          "bbox": [
            -0.42989086947695837,
            42.12791333620337,
            -0.42989086947695837,
            42.12791333620337
          ]
        }
      ],
      "bbox": [
        -0.42989086947695837,
        42.12791333620337,
        -0.42989086947695837,
        42.12791333620337
      ]
    },
    "22126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.19580585688999808,
              42.1539437624902
            ]
          },
          "bbox": [
            -0.19580585688999808,
            42.1539437624902,
            -0.19580585688999808,
            42.1539437624902
          ]
        }
      ],
      "bbox": [
        -0.19580585688999808,
        42.1539437624902,
        -0.19580585688999808,
        42.1539437624902
      ]
    },
    "22127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4335101389567762,
              42.2076105339383
            ]
          },
          "bbox": [
            -0.4335101389567762,
            42.2076105339383,
            -0.4335101389567762,
            42.2076105339383
          ]
        }
      ],
      "bbox": [
        -0.4335101389567762,
        42.2076105339383,
        -0.4335101389567762,
        42.2076105339383
      ]
    },
    "22128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.054578255867075806,
              41.94174742923544
            ]
          },
          "bbox": [
            0.054578255867075806,
            41.94174742923544,
            0.054578255867075806,
            41.94174742923544
          ]
        }
      ],
      "bbox": [
        0.054578255867075806,
        41.94174742923544,
        0.054578255867075806,
        41.94174742923544
      ]
    },
    "22129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5347350597107704,
              42.3040847524559
            ]
          },
          "bbox": [
            0.5347350597107704,
            42.3040847524559,
            0.5347350597107704,
            42.3040847524559
          ]
        }
      ],
      "bbox": [
        0.5347350597107704,
        42.3040847524559,
        0.5347350597107704,
        42.3040847524559
      ]
    },
    "22130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5512652405886864,
              42.5506570211619
            ]
          },
          "bbox": [
            -0.5512652405886864,
            42.5506570211619,
            -0.5512652405886864,
            42.5506570211619
          ]
        }
      ],
      "bbox": [
        -0.5512652405886864,
        42.5506570211619,
        -0.5512652405886864,
        42.5506570211619
      ]
    },
    "22131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6506928253843594,
              42.70020305945174
            ]
          },
          "bbox": [
            -0.6506928253843594,
            42.70020305945174,
            -0.6506928253843594,
            42.70020305945174
          ]
        }
      ],
      "bbox": [
        -0.6506928253843594,
        42.70020305945174,
        -0.6506928253843594,
        42.70020305945174
      ]
    },
    "22133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.11286538350858946,
              42.457337423162535
            ]
          },
          "bbox": [
            0.11286538350858946,
            42.457337423162535,
            0.11286538350858946,
            42.457337423162535
          ]
        }
      ],
      "bbox": [
        0.11286538350858946,
        42.457337423162535,
        0.11286538350858946,
        42.457337423162535
      ]
    },
    "22135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.010088612297084907,
              42.01361268760488
            ]
          },
          "bbox": [
            -0.010088612297084907,
            42.01361268760488,
            -0.010088612297084907,
            42.01361268760488
          ]
        }
      ],
      "bbox": [
        -0.010088612297084907,
        42.01361268760488,
        -0.010088612297084907,
        42.01361268760488
      ]
    },
    "22136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24971275820727895,
              41.860439958422276
            ]
          },
          "bbox": [
            -0.24971275820727895,
            41.860439958422276,
            -0.24971275820727895,
            41.860439958422276
          ]
        }
      ],
      "bbox": [
        -0.24971275820727895,
        41.860439958422276,
        -0.24971275820727895,
        41.860439958422276
      ]
    },
    "22137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3488816558682578,
              41.743860504892915
            ]
          },
          "bbox": [
            -0.3488816558682578,
            41.743860504892915,
            -0.3488816558682578,
            41.743860504892915
          ]
        }
      ],
      "bbox": [
        -0.3488816558682578,
        41.743860504892915,
        -0.3488816558682578,
        41.743860504892915
      ]
    },
    "22139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.05355777667250433,
              41.99092972467981
            ]
          },
          "bbox": [
            -0.05355777667250433,
            41.99092972467981,
            -0.05355777667250433,
            41.99092972467981
          ]
        }
      ],
      "bbox": [
        -0.05355777667250433,
        41.99092972467981,
        -0.05355777667250433,
        41.99092972467981
      ]
    },
    "22141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.07930287965902942,
              42.06503763433957
            ]
          },
          "bbox": [
            -0.07930287965902942,
            42.06503763433957,
            -0.07930287965902942,
            42.06503763433957
          ]
        }
      ],
      "bbox": [
        -0.07930287965902942,
        42.06503763433957,
        -0.07930287965902942,
        42.06503763433957
      ]
    },
    "22142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5209544663705065,
              42.189731068274455
            ]
          },
          "bbox": [
            0.5209544663705065,
            42.189731068274455,
            0.5209544663705065,
            42.189731068274455
          ]
        }
      ],
      "bbox": [
        0.5209544663705065,
        42.189731068274455,
        0.5209544663705065,
        42.189731068274455
      ]
    },
    "22143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5969228181761388,
              42.465076560337444
            ]
          },
          "bbox": [
            0.5969228181761388,
            42.465076560337444,
            0.5969228181761388,
            42.465076560337444
          ]
        }
      ],
      "bbox": [
        0.5969228181761388,
        42.465076560337444,
        0.5969228181761388,
        42.465076560337444
      ]
    },
    "22144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.1951027870666827,
              42.50939339459734
            ]
          },
          "bbox": [
            0.1951027870666827,
            42.50939339459734,
            0.1951027870666827,
            42.50939339459734
          ]
        }
      ],
      "bbox": [
        0.1951027870666827,
        42.50939339459734,
        0.1951027870666827,
        42.50939339459734
      ]
    },
    "22149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6464248093248565,
              42.3184919746248
            ]
          },
          "bbox": [
            -0.6464248093248565,
            42.3184919746248,
            -0.6464248093248565,
            42.3184919746248
          ]
        }
      ],
      "bbox": [
        -0.6464248093248565,
        42.3184919746248,
        -0.6464248093248565,
        42.3184919746248
      ]
    },
    "22150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3006595920187689,
              42.217305462231934
            ]
          },
          "bbox": [
            -0.3006595920187689,
            42.217305462231934,
            -0.3006595920187689,
            42.217305462231934
          ]
        }
      ],
      "bbox": [
        -0.3006595920187689,
        42.217305462231934,
        -0.3006595920187689,
        42.217305462231934
      ]
    },
    "22151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6626228806869701,
              42.212493933444144
            ]
          },
          "bbox": [
            -0.6626228806869701,
            42.212493933444144,
            -0.6626228806869701,
            42.212493933444144
          ]
        }
      ],
      "bbox": [
        -0.6626228806869701,
        42.212493933444144,
        -0.6626228806869701,
        42.212493933444144
      ]
    },
    "22155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6121701805606553,
              42.229961652076845
            ]
          },
          "bbox": [
            0.6121701805606553,
            42.229961652076845,
            0.6121701805606553,
            42.229961652076845
          ]
        }
      ],
      "bbox": [
        0.6121701805606553,
        42.229961652076845,
        0.6121701805606553,
        42.229961652076845
      ]
    },
    "22156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.37334376179880724,
              42.082320683320354
            ]
          },
          "bbox": [
            -0.37334376179880724,
            42.082320683320354,
            -0.37334376179880724,
            42.082320683320354
          ]
        }
      ],
      "bbox": [
        -0.37334376179880724,
        42.082320683320354,
        -0.37334376179880724,
        42.082320683320354
      ]
    },
    "22157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6809784773026742,
              42.53971855488623
            ]
          },
          "bbox": [
            0.6809784773026742,
            42.53971855488623,
            0.6809784773026742,
            42.53971855488623
          ]
        }
      ],
      "bbox": [
        0.6809784773026742,
        42.53971855488623,
        0.6809784773026742,
        42.53971855488623
      ]
    },
    "22158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.16827395987012275,
              41.89947704838188
            ]
          },
          "bbox": [
            0.16827395987012275,
            41.89947704838188,
            0.16827395987012275,
            41.89947704838188
          ]
        }
      ],
      "bbox": [
        0.16827395987012275,
        41.89947704838188,
        0.16827395987012275,
        41.89947704838188
      ]
    },
    "22160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.17524880729376444,
              42.19814260607276
            ]
          },
          "bbox": [
            0.17524880729376444,
            42.19814260607276,
            0.17524880729376444,
            42.19814260607276
          ]
        }
      ],
      "bbox": [
        0.17524880729376444,
        42.19814260607276,
        0.17524880729376444,
        42.19814260607276
      ]
    },
    "22162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2809001611389859,
              42.02891006931303
            ]
          },
          "bbox": [
            -0.2809001611389859,
            42.02891006931303,
            -0.2809001611389859,
            42.02891006931303
          ]
        }
      ],
      "bbox": [
        -0.2809001611389859,
        42.02891006931303,
        -0.2809001611389859,
        42.02891006931303
      ]
    },
    "22163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.34605967951956007,
              42.29053745108862
            ]
          },
          "bbox": [
            -0.34605967951956007,
            42.29053745108862,
            -0.34605967951956007,
            42.29053745108862
          ]
        }
      ],
      "bbox": [
        -0.34605967951956007,
        42.29053745108862,
        -0.34605967951956007,
        42.29053745108862
      ]
    },
    "22164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2661889946243539,
              42.10736831633919
            ]
          },
          "bbox": [
            0.2661889946243539,
            42.10736831633919,
            0.2661889946243539,
            42.10736831633919
          ]
        }
      ],
      "bbox": [
        0.2661889946243539,
        42.10736831633919,
        0.2661889946243539,
        42.10736831633919
      ]
    },
    "22165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.04362092502420336,
              41.63150018679494
            ]
          },
          "bbox": [
            0.04362092502420336,
            41.63150018679494,
            0.04362092502420336,
            41.63150018679494
          ]
        }
      ],
      "bbox": [
        0.04362092502420336,
        41.63150018679494,
        0.04362092502420336,
        41.63150018679494
      ]
    },
    "22167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.22091805711086035,
              41.66702163490836
            ]
          },
          "bbox": [
            0.22091805711086035,
            41.66702163490836,
            0.22091805711086035,
            41.66702163490836
          ]
        }
      ],
      "bbox": [
        0.22091805711086035,
        41.66702163490836,
        0.22091805711086035,
        41.66702163490836
      ]
    },
    "22168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.24099286721734725,
              42.32060203484751
            ]
          },
          "bbox": [
            0.24099286721734725,
            42.32060203484751,
            0.24099286721734725,
            42.32060203484751
          ]
        }
      ],
      "bbox": [
        0.24099286721734725,
        42.32060203484751,
        0.24099286721734725,
        42.32060203484751
      ]
    },
    "22170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.23978120437845094,
              42.736669745533135
            ]
          },
          "bbox": [
            -0.23978120437845094,
            42.736669745533135,
            -0.23978120437845094,
            42.736669745533135
          ]
        }
      ],
      "bbox": [
        -0.23978120437845094,
        42.736669745533135,
        -0.23978120437845094,
        42.736669745533135
      ]
    },
    "22172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.04557110531335454,
              41.46680017732653
            ]
          },
          "bbox": [
            -0.04557110531335454,
            41.46680017732653,
            -0.04557110531335454,
            41.46680017732653
          ]
        }
      ],
      "bbox": [
        -0.04557110531335454,
        41.46680017732653,
        -0.04557110531335454,
        41.46680017732653
      ]
    },
    "22173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7164821797345177,
              42.402693985412604
            ]
          },
          "bbox": [
            -0.7164821797345177,
            42.402693985412604,
            -0.7164821797345177,
            42.402693985412604
          ]
        }
      ],
      "bbox": [
        -0.7164821797345177,
        42.402693985412604,
        -0.7164821797345177,
        42.402693985412604
      ]
    },
    "22174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.04232727728833844,
              41.89254897550679
            ]
          },
          "bbox": [
            -0.04232727728833844,
            41.89254897550679,
            -0.04232727728833844,
            41.89254897550679
          ]
        }
      ],
      "bbox": [
        -0.04232727728833844,
        41.89254897550679,
        -0.04232727728833844,
        41.89254897550679
      ]
    },
    "22175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.38406773951720813,
              42.00083762541361
            ]
          },
          "bbox": [
            0.38406773951720813,
            42.00083762541361,
            0.38406773951720813,
            42.00083762541361
          ]
        }
      ],
      "bbox": [
        0.38406773951720813,
        42.00083762541361,
        0.38406773951720813,
        42.00083762541361
      ]
    },
    "22176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.013163462785124428,
              42.04694090745984
            ]
          },
          "bbox": [
            -0.013163462785124428,
            42.04694090745984,
            -0.013163462785124428,
            42.04694090745984
          ]
        }
      ],
      "bbox": [
        -0.013163462785124428,
        42.04694090745984,
        -0.013163462785124428,
        42.04694090745984
      ]
    },
    "22177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3434008049087898,
              42.27333548390623
            ]
          },
          "bbox": [
            0.3434008049087898,
            42.27333548390623,
            0.3434008049087898,
            42.27333548390623
          ]
        }
      ],
      "bbox": [
        0.3434008049087898,
        42.27333548390623,
        0.3434008049087898,
        42.27333548390623
      ]
    },
    "22178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12902295995264837,
              41.999073623482175
            ]
          },
          "bbox": [
            -0.12902295995264837,
            41.999073623482175,
            -0.12902295995264837,
            41.999073623482175
          ]
        }
      ],
      "bbox": [
        -0.12902295995264837,
        41.999073623482175,
        -0.12902295995264837,
        41.999073623482175
      ]
    },
    "22181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3187775988644661,
              42.00309027623961
            ]
          },
          "bbox": [
            -0.3187775988644661,
            42.00309027623961,
            -0.3187775988644661,
            42.00309027623961
          ]
        }
      ],
      "bbox": [
        -0.3187775988644661,
        42.00309027623961,
        -0.3187775988644661,
        42.00309027623961
      ]
    },
    "22182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.30139445144869,
              42.54866296956867
            ]
          },
          "bbox": [
            0.30139445144869,
            42.54866296956867,
            0.30139445144869,
            42.54866296956867
          ]
        }
      ],
      "bbox": [
        0.30139445144869,
        42.54866296956867,
        0.30139445144869,
        42.54866296956867
      ]
    },
    "22184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3226481017147086,
              41.856052576585725
            ]
          },
          "bbox": [
            -0.3226481017147086,
            41.856052576585725,
            -0.3226481017147086,
            41.856052576585725
          ]
        }
      ],
      "bbox": [
        -0.3226481017147086,
        41.856052576585725,
        -0.3226481017147086,
        41.856052576585725
      ]
    },
    "22186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.027912630318775087,
              42.076936416774885
            ]
          },
          "bbox": [
            0.027912630318775087,
            42.076936416774885,
            0.027912630318775087,
            42.076936416774885
          ]
        }
      ],
      "bbox": [
        0.027912630318775087,
        42.076936416774885,
        0.027912630318775087,
        42.076936416774885
      ]
    },
    "22187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.29660992249887685,
              42.13663729895773
            ]
          },
          "bbox": [
            0.29660992249887685,
            42.13663729895773,
            0.29660992249887685,
            42.13663729895773
          ]
        }
      ],
      "bbox": [
        0.29660992249887685,
        42.13663729895773,
        0.29660992249887685,
        42.13663729895773
      ]
    },
    "22188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6692363719496303,
              42.16926831988706
            ]
          },
          "bbox": [
            0.6692363719496303,
            42.16926831988706,
            0.6692363719496303,
            42.16926831988706
          ]
        }
      ],
      "bbox": [
        0.6692363719496303,
        42.16926831988706,
        0.6692363719496303,
        42.16926831988706
      ]
    },
    "22189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.11061526306631282,
              42.56104111750748
            ]
          },
          "bbox": [
            0.11061526306631282,
            42.56104111750748,
            0.11061526306631282,
            42.56104111750748
          ]
        }
      ],
      "bbox": [
        0.11061526306631282,
        42.56104111750748,
        0.11061526306631282,
        42.56104111750748
      ]
    },
    "22190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.20346640857717924,
              42.464039030364816
            ]
          },
          "bbox": [
            0.20346640857717924,
            42.464039030364816,
            0.20346640857717924,
            42.464039030364816
          ]
        }
      ],
      "bbox": [
        0.20346640857717924,
        42.464039030364816,
        0.20346640857717924,
        42.464039030364816
      ]
    },
    "22193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.16042594631609405,
              41.85262997386674
            ]
          },
          "bbox": [
            0.16042594631609405,
            41.85262997386674,
            0.16042594631609405,
            41.85262997386674
          ]
        }
      ],
      "bbox": [
        0.16042594631609405,
        41.85262997386674,
        0.16042594631609405,
        41.85262997386674
      ]
    },
    "22195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3580507130210949,
              42.152542134465726
            ]
          },
          "bbox": [
            -0.3580507130210949,
            42.152542134465726,
            -0.3580507130210949,
            42.152542134465726
          ]
        }
      ],
      "bbox": [
        -0.3580507130210949,
        42.152542134465726,
        -0.3580507130210949,
        42.152542134465726
      ]
    },
    "22197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.47415514451821644,
              41.86590428926909
            ]
          },
          "bbox": [
            -0.47415514451821644,
            41.86590428926909,
            -0.47415514451821644,
            41.86590428926909
          ]
        }
      ],
      "bbox": [
        -0.47415514451821644,
        41.86590428926909,
        -0.47415514451821644,
        41.86590428926909
      ]
    },
    "22199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3021971367781406,
              42.452141785981105
            ]
          },
          "bbox": [
            -0.3021971367781406,
            42.452141785981105,
            -0.3021971367781406,
            42.452141785981105
          ]
        }
      ],
      "bbox": [
        -0.3021971367781406,
        42.452141785981105,
        -0.3021971367781406,
        42.452141785981105
      ]
    },
    "22200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4558439709865852,
              42.60409030894485
            ]
          },
          "bbox": [
            0.4558439709865852,
            42.60409030894485,
            0.4558439709865852,
            42.60409030894485
          ]
        }
      ],
      "bbox": [
        0.4558439709865852,
        42.60409030894485,
        0.4558439709865852,
        42.60409030894485
      ]
    },
    "22201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.07285599648839314,
              42.119604123694636
            ]
          },
          "bbox": [
            0.07285599648839314,
            42.119604123694636,
            0.07285599648839314,
            42.119604123694636
          ]
        }
      ],
      "bbox": [
        0.07285599648839314,
        42.119604123694636,
        0.07285599648839314,
        42.119604123694636
      ]
    },
    "22202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.0874036739646482,
              42.09495361601333
            ]
          },
          "bbox": [
            0.0874036739646482,
            42.09495361601333,
            0.0874036739646482,
            42.09495361601333
          ]
        }
      ],
      "bbox": [
        0.0874036739646482,
        42.09495361601333,
        0.0874036739646482,
        42.09495361601333
      ]
    },
    "22203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.18024577404265055,
              41.98133288380533
            ]
          },
          "bbox": [
            -0.18024577404265055,
            41.98133288380533,
            -0.18024577404265055,
            41.98133288380533
          ]
        }
      ],
      "bbox": [
        -0.18024577404265055,
        41.98133288380533,
        -0.18024577404265055,
        41.98133288380533
      ]
    },
    "22204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.34452623410321637,
              42.77455924256873
            ]
          },
          "bbox": [
            -0.34452623410321637,
            42.77455924256873,
            -0.34452623410321637,
            42.77455924256873
          ]
        }
      ],
      "bbox": [
        -0.34452623410321637,
        42.77455924256873,
        -0.34452623410321637,
        42.77455924256873
      ]
    },
    "22205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3308323542747325,
              41.89736948673467
            ]
          },
          "bbox": [
            0.3308323542747325,
            41.89736948673467,
            0.3308323542747325,
            41.89736948673467
          ]
        }
      ],
      "bbox": [
        0.3308323542747325,
        41.89736948673467,
        0.3308323542747325,
        41.89736948673467
      ]
    },
    "22206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4544096572560594,
              42.01161506977352
            ]
          },
          "bbox": [
            -0.4544096572560594,
            42.01161506977352,
            -0.4544096572560594,
            42.01161506977352
          ]
        }
      ],
      "bbox": [
        -0.4544096572560594,
        42.01161506977352,
        -0.4544096572560594,
        42.01161506977352
      ]
    },
    "22207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.38630969664917375,
              42.622984239884005
            ]
          },
          "bbox": [
            0.38630969664917375,
            42.622984239884005,
            0.38630969664917375,
            42.622984239884005
          ]
        }
      ],
      "bbox": [
        0.38630969664917375,
        42.622984239884005,
        0.38630969664917375,
        42.622984239884005
      ]
    },
    "22208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7152343533277926,
              42.56347528525293
            ]
          },
          "bbox": [
            -0.7152343533277926,
            42.56347528525293,
            -0.7152343533277926,
            42.56347528525293
          ]
        }
      ],
      "bbox": [
        -0.7152343533277926,
        42.56347528525293,
        -0.7152343533277926,
        42.56347528525293
      ]
    },
    "22209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6823278222686835,
              42.53310762432002
            ]
          },
          "bbox": [
            -0.6823278222686835,
            42.53310762432002,
            -0.6823278222686835,
            42.53310762432002
          ]
        }
      ],
      "bbox": [
        -0.6823278222686835,
        42.53310762432002,
        -0.6823278222686835,
        42.53310762432002
      ]
    },
    "22212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3596867332916998,
              42.317324321068476
            ]
          },
          "bbox": [
            0.3596867332916998,
            42.317324321068476,
            0.3596867332916998,
            42.317324321068476
          ]
        }
      ],
      "bbox": [
        0.3596867332916998,
        42.317324321068476,
        0.3596867332916998,
        42.317324321068476
      ]
    },
    "22213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.177682561777701,
              41.76939483547688
            ]
          },
          "bbox": [
            -0.177682561777701,
            41.76939483547688,
            -0.177682561777701,
            41.76939483547688
          ]
        }
      ],
      "bbox": [
        -0.177682561777701,
        41.76939483547688,
        -0.177682561777701,
        41.76939483547688
      ]
    },
    "22214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.25715175659686135,
              42.19580075016832
            ]
          },
          "bbox": [
            0.25715175659686135,
            42.19580075016832,
            0.25715175659686135,
            42.19580075016832
          ]
        }
      ],
      "bbox": [
        0.25715175659686135,
        42.19580075016832,
        0.25715175659686135,
        42.19580075016832
      ]
    },
    "22215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.39718831241991437,
              42.499203829806824
            ]
          },
          "bbox": [
            0.39718831241991437,
            42.499203829806824,
            0.39718831241991437,
            42.499203829806824
          ]
        }
      ],
      "bbox": [
        0.39718831241991437,
        42.499203829806824,
        0.39718831241991437,
        42.499203829806824
      ]
    },
    "22217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.0924299031000131,
              41.6734366586315
            ]
          },
          "bbox": [
            -0.0924299031000131,
            41.6734366586315,
            -0.0924299031000131,
            41.6734366586315
          ]
        }
      ],
      "bbox": [
        -0.0924299031000131,
        41.6734366586315,
        -0.0924299031000131,
        41.6734366586315
      ]
    },
    "22218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5107728807372591,
              41.903093481658665
            ]
          },
          "bbox": [
            -0.5107728807372591,
            41.903093481658665,
            -0.5107728807372591,
            41.903093481658665
          ]
        }
      ],
      "bbox": [
        -0.5107728807372591,
        41.903093481658665,
        -0.5107728807372591,
        41.903093481658665
      ]
    },
    "22220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24595650458516038,
              41.99633221055441
            ]
          },
          "bbox": [
            -0.24595650458516038,
            41.99633221055441,
            -0.24595650458516038,
            41.99633221055441
          ]
        }
      ],
      "bbox": [
        -0.24595650458516038,
        41.99633221055441,
        -0.24595650458516038,
        41.99633221055441
      ]
    },
    "22221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4775143201462753,
              42.55129621838044
            ]
          },
          "bbox": [
            0.4775143201462753,
            42.55129621838044,
            0.4775143201462753,
            42.55129621838044
          ]
        }
      ],
      "bbox": [
        0.4775143201462753,
        42.55129621838044,
        0.4775143201462753,
        42.55129621838044
      ]
    },
    "22222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2569485270136432,
              42.14082575634532
            ]
          },
          "bbox": [
            -0.2569485270136432,
            42.14082575634532,
            -0.2569485270136432,
            42.14082575634532
          ]
        }
      ],
      "bbox": [
        -0.2569485270136432,
        42.14082575634532,
        -0.2569485270136432,
        42.14082575634532
      ]
    },
    "22223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7202354327270859,
              42.33882729860177
            ]
          },
          "bbox": [
            0.7202354327270859,
            42.33882729860177,
            0.7202354327270859,
            42.33882729860177
          ]
        }
      ],
      "bbox": [
        0.7202354327270859,
        42.33882729860177,
        0.7202354327270859,
        42.33882729860177
      ]
    },
    "22225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.38273150128984473,
              41.81566980467701
            ]
          },
          "bbox": [
            0.38273150128984473,
            41.81566980467701,
            0.38273150128984473,
            41.81566980467701
          ]
        }
      ],
      "bbox": [
        0.38273150128984473,
        41.81566980467701,
        0.38273150128984473,
        41.81566980467701
      ]
    },
    "22226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5669242415523823,
              41.956695732467814
            ]
          },
          "bbox": [
            -0.5669242415523823,
            41.956695732467814,
            -0.5669242415523823,
            41.956695732467814
          ]
        }
      ],
      "bbox": [
        -0.5669242415523823,
        41.956695732467814,
        -0.5669242415523823,
        41.956695732467814
      ]
    },
    "22227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.21696582367922967,
              42.593378874357825
            ]
          },
          "bbox": [
            0.21696582367922967,
            42.593378874357825,
            0.21696582367922967,
            42.593378874357825
          ]
        }
      ],
      "bbox": [
        0.21696582367922967,
        42.593378874357825,
        0.21696582367922967,
        42.593378874357825
      ]
    },
    "22228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.35377255749711073,
              42.12849360782528
            ]
          },
          "bbox": [
            -0.35377255749711073,
            42.12849360782528,
            -0.35377255749711073,
            42.12849360782528
          ]
        }
      ],
      "bbox": [
        -0.35377255749711073,
        42.12849360782528,
        -0.35377255749711073,
        42.12849360782528
      ]
    },
    "22229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5647336967306412,
              42.13459468131549
            ]
          },
          "bbox": [
            0.5647336967306412,
            42.13459468131549,
            0.5647336967306412,
            42.13459468131549
          ]
        }
      ],
      "bbox": [
        0.5647336967306412,
        42.13459468131549,
        0.5647336967306412,
        42.13459468131549
      ]
    },
    "22230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.13393818990309195,
              42.68072415395501
            ]
          },
          "bbox": [
            -0.13393818990309195,
            42.68072415395501,
            -0.13393818990309195,
            42.68072415395501
          ]
        }
      ],
      "bbox": [
        -0.13393818990309195,
        42.68072415395501,
        -0.13393818990309195,
        42.68072415395501
      ]
    },
    "22232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5325891831005846,
              41.918385972276106
            ]
          },
          "bbox": [
            -0.5325891831005846,
            41.918385972276106,
            -0.5325891831005846,
            41.918385972276106
          ]
        }
      ],
      "bbox": [
        -0.5325891831005846,
        41.918385972276106,
        -0.5325891831005846,
        41.918385972276106
      ]
    },
    "22233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5414168245284073,
              42.379453927674874
            ]
          },
          "bbox": [
            0.5414168245284073,
            42.379453927674874,
            0.5414168245284073,
            42.379453927674874
          ]
        }
      ],
      "bbox": [
        0.5414168245284073,
        42.379453927674874,
        0.5414168245284073,
        42.379453927674874
      ]
    },
    "22234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.30359136277280707,
              41.450465492594574
            ]
          },
          "bbox": [
            0.30359136277280707,
            41.450465492594574,
            0.30359136277280707,
            41.450465492594574
          ]
        }
      ],
      "bbox": [
        0.30359136277280707,
        41.450465492594574,
        0.30359136277280707,
        41.450465492594574
      ]
    },
    "22235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.09924302544443915,
              41.960551325472466
            ]
          },
          "bbox": [
            -0.09924302544443915,
            41.960551325472466,
            -0.09924302544443915,
            41.960551325472466
          ]
        }
      ],
      "bbox": [
        -0.09924302544443915,
        41.960551325472466,
        -0.09924302544443915,
        41.960551325472466
      ]
    },
    "22236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.45342473364194513,
              41.95760770209274
            ]
          },
          "bbox": [
            -0.45342473364194513,
            41.95760770209274,
            -0.45342473364194513,
            41.95760770209274
          ]
        }
      ],
      "bbox": [
        -0.45342473364194513,
        41.95760770209274,
        -0.45342473364194513,
        41.95760770209274
      ]
    },
    "22239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3073104590725823,
              41.967577170364144
            ]
          },
          "bbox": [
            -0.3073104590725823,
            41.967577170364144,
            -0.3073104590725823,
            41.967577170364144
          ]
        }
      ],
      "bbox": [
        -0.3073104590725823,
        41.967577170364144,
        -0.3073104590725823,
        41.967577170364144
      ]
    },
    "22242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12041314719237903,
              41.54998293462981
            ]
          },
          "bbox": [
            -0.12041314719237903,
            41.54998293462981,
            -0.12041314719237903,
            41.54998293462981
          ]
        }
      ],
      "bbox": [
        -0.12041314719237903,
        41.54998293462981,
        -0.12041314719237903,
        41.54998293462981
      ]
    },
    "22243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.463207505776845,
              42.42946185018417
            ]
          },
          "bbox": [
            0.463207505776845,
            42.42946185018417,
            0.463207505776845,
            42.42946185018417
          ]
        }
      ],
      "bbox": [
        0.463207505776845,
        42.42946185018417,
        0.463207505776845,
        42.42946185018417
      ]
    },
    "22244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.49104232934355196,
              42.38235758790402
            ]
          },
          "bbox": [
            0.49104232934355196,
            42.38235758790402,
            0.49104232934355196,
            42.38235758790402
          ]
        }
      ],
      "bbox": [
        0.49104232934355196,
        42.38235758790402,
        0.49104232934355196,
        42.38235758790402
      ]
    },
    "22245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2548817331259183,
              41.58059955716316
            ]
          },
          "bbox": [
            0.2548817331259183,
            41.58059955716316,
            0.2548817331259183,
            41.58059955716316
          ]
        }
      ],
      "bbox": [
        0.2548817331259183,
        41.58059955716316,
        0.2548817331259183,
        41.58059955716316
      ]
    },
    "22246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6043846383072657,
              42.38104169789742
            ]
          },
          "bbox": [
            0.6043846383072657,
            42.38104169789742,
            0.6043846383072657,
            42.38104169789742
          ]
        }
      ],
      "bbox": [
        0.6043846383072657,
        42.38104169789742,
        0.6043846383072657,
        42.38104169789742
      ]
    },
    "22247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6343925096940219,
              42.07286989955116
            ]
          },
          "bbox": [
            0.6343925096940219,
            42.07286989955116,
            0.6343925096940219,
            42.07286989955116
          ]
        }
      ],
      "bbox": [
        0.6343925096940219,
        42.07286989955116,
        0.6343925096940219,
        42.07286989955116
      ]
    },
    "22248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.45805210486123754,
              42.04318781732123
            ]
          },
          "bbox": [
            -0.45805210486123754,
            42.04318781732123,
            -0.45805210486123754,
            42.04318781732123
          ]
        }
      ],
      "bbox": [
        -0.45805210486123754,
        42.04318781732123,
        -0.45805210486123754,
        42.04318781732123
      ]
    },
    "22249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.45646263176868496,
              42.54888688456126
            ]
          },
          "bbox": [
            0.45646263176868496,
            42.54888688456126,
            0.45646263176868496,
            42.54888688456126
          ]
        }
      ],
      "bbox": [
        0.45646263176868496,
        42.54888688456126,
        0.45646263176868496,
        42.54888688456126
      ]
    },
    "22250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5102102561846737,
              42.6841368159366
            ]
          },
          "bbox": [
            -0.5102102561846737,
            42.6841368159366,
            -0.5102102561846737,
            42.6841368159366
          ]
        }
      ],
      "bbox": [
        -0.5102102561846737,
        42.6841368159366,
        -0.5102102561846737,
        42.6841368159366
      ]
    },
    "22251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.03911440434890559,
              41.667245626659536
            ]
          },
          "bbox": [
            -0.03911440434890559,
            41.667245626659536,
            -0.03911440434890559,
            41.667245626659536
          ]
        }
      ],
      "bbox": [
        -0.03911440434890559,
        41.667245626659536,
        -0.03911440434890559,
        41.667245626659536
      ]
    },
    "22252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2375758094463899,
              42.49119827618379
            ]
          },
          "bbox": [
            -0.2375758094463899,
            42.49119827618379,
            -0.2375758094463899,
            42.49119827618379
          ]
        }
      ],
      "bbox": [
        -0.2375758094463899,
        42.49119827618379,
        -0.2375758094463899,
        42.49119827618379
      ]
    },
    "22253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.22767790919333009,
              42.63194873277359
            ]
          },
          "bbox": [
            -0.22767790919333009,
            42.63194873277359,
            -0.22767790919333009,
            42.63194873277359
          ]
        }
      ],
      "bbox": [
        -0.22767790919333009,
        42.63194873277359,
        -0.22767790919333009,
        42.63194873277359
      ]
    },
    "22254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2855446498509145,
              41.625574708575854
            ]
          },
          "bbox": [
            0.2855446498509145,
            41.625574708575854,
            0.2855446498509145,
            41.625574708575854
          ]
        }
      ],
      "bbox": [
        0.2855446498509145,
        41.625574708575854,
        0.2855446498509145,
        41.625574708575854
      ]
    },
    "22901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7294820897926376,
              42.734918609061154
            ]
          },
          "bbox": [
            -0.7294820897926376,
            42.734918609061154,
            -0.7294820897926376,
            42.734918609061154
          ]
        }
      ],
      "bbox": [
        -0.7294820897926376,
        42.734918609061154,
        -0.7294820897926376,
        42.734918609061154
      ]
    },
    "22902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7630601420573396,
              42.58817874225762
            ]
          },
          "bbox": [
            -0.7630601420573396,
            42.58817874225762,
            -0.7630601420573396,
            42.58817874225762
          ]
        }
      ],
      "bbox": [
        -0.7630601420573396,
        42.58817874225762,
        -0.7630601420573396,
        42.58817874225762
      ]
    },
    "22903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.08256761293030558,
              41.81950047235169
            ]
          },
          "bbox": [
            0.08256761293030558,
            41.81950047235169,
            0.08256761293030558,
            41.81950047235169
          ]
        }
      ],
      "bbox": [
        0.08256761293030558,
        41.81950047235169,
        0.08256761293030558,
        41.81950047235169
      ]
    },
    "22904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5415048524819798,
              42.24848602128402
            ]
          },
          "bbox": [
            -0.5415048524819798,
            42.24848602128402,
            -0.5415048524819798,
            42.24848602128402
          ]
        }
      ],
      "bbox": [
        -0.5415048524819798,
        42.24848602128402,
        -0.5415048524819798,
        42.24848602128402
      ]
    },
    "22905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6329434430261005,
              42.16272905944752
            ]
          },
          "bbox": [
            -0.6329434430261005,
            42.16272905944752,
            -0.6329434430261005,
            42.16272905944752
          ]
        }
      ],
      "bbox": [
        -0.6329434430261005,
        42.16272905944752,
        -0.6329434430261005,
        42.16272905944752
      ]
    },
    "22906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.026536032168840904,
              42.12229788259893
            ]
          },
          "bbox": [
            0.026536032168840904,
            42.12229788259893,
            0.026536032168840904,
            42.12229788259893
          ]
        }
      ],
      "bbox": [
        0.026536032168840904,
        42.12229788259893,
        0.026536032168840904,
        42.12229788259893
      ]
    },
    "22907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.09397222720204929,
              42.345468033348304
            ]
          },
          "bbox": [
            0.09397222720204929,
            42.345468033348304,
            0.09397222720204929,
            42.345468033348304
          ]
        }
      ],
      "bbox": [
        0.09397222720204929,
        42.345468033348304,
        0.09397222720204929,
        42.345468033348304
      ]
    },
    "22908": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.14670428469933136,
              42.12452309820468
            ]
          },
          "bbox": [
            0.14670428469933136,
            42.12452309820468,
            0.14670428469933136,
            42.12452309820468
          ]
        }
      ],
      "bbox": [
        0.14670428469933136,
        42.12452309820468,
        0.14670428469933136,
        42.12452309820468
      ]
    },
    "22909": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3263109572822981,
              41.70871601246197
            ]
          },
          "bbox": [
            0.3263109572822981,
            41.70871601246197,
            0.3263109572822981,
            41.70871601246197
          ]
        }
      ],
      "bbox": [
        0.3263109572822981,
        41.70871601246197,
        0.3263109572822981,
        41.70871601246197
      ]
    },
    "23001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4579214864504237,
              37.775037523629145
            ]
          },
          "bbox": [
            -3.4579214864504237,
            37.775037523629145,
            -3.4579214864504237,
            37.775037523629145
          ]
        }
      ],
      "bbox": [
        -3.4579214864504237,
        37.775037523629145,
        -3.4579214864504237,
        37.775037523629145
      ]
    },
    "23002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9320478974601736,
              37.45344862785974
            ]
          },
          "bbox": [
            -3.9320478974601736,
            37.45344862785974,
            -3.9320478974601736,
            37.45344862785974
          ]
        }
      ],
      "bbox": [
        -3.9320478974601736,
        37.45344862785974,
        -3.9320478974601736,
        37.45344862785974
      ]
    },
    "23003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.097416981840083,
              37.60295847148972
            ]
          },
          "bbox": [
            -4.097416981840083,
            37.60295847148972,
            -4.097416981840083,
            37.60295847148972
          ]
        }
      ],
      "bbox": [
        -4.097416981840083,
        37.60295847148972,
        -4.097416981840083,
        37.60295847148972
      ]
    },
    "23004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.360772472734167,
              38.40357818918697
            ]
          },
          "bbox": [
            -3.360772472734167,
            38.40357818918697,
            -3.360772472734167,
            38.40357818918697
          ]
        }
      ],
      "bbox": [
        -3.360772472734167,
        38.40357818918697,
        -3.360772472734167,
        38.40357818918697
      ]
    },
    "23005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.050437619341505,
              38.22362144125126
            ]
          },
          "bbox": [
            -4.050437619341505,
            38.22362144125126,
            -4.050437619341505,
            38.22362144125126
          ]
        }
      ],
      "bbox": [
        -4.050437619341505,
        38.22362144125126,
        -4.050437619341505,
        38.22362144125126
      ]
    },
    "23006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.078426964347561,
              37.92635781575978
            ]
          },
          "bbox": [
            -4.078426964347561,
            37.92635781575978,
            -4.078426964347561,
            37.92635781575978
          ]
        }
      ],
      "bbox": [
        -4.078426964347561,
        37.92635781575978,
        -4.078426964347561,
        37.92635781575978
      ]
    },
    "23007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.122022306341537,
              37.983441801187205
            ]
          },
          "bbox": [
            -4.122022306341537,
            37.983441801187205,
            -4.122022306341537,
            37.983441801187205
          ]
        }
      ],
      "bbox": [
        -4.122022306341537,
        37.983441801187205,
        -4.122022306341537,
        37.983441801187205
      ]
    },
    "23008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.415612617835731,
              38.20563997249954
            ]
          },
          "bbox": [
            -3.415612617835731,
            38.20563997249954,
            -3.415612617835731,
            38.20563997249954
          ]
        }
      ],
      "bbox": [
        -3.415612617835731,
        38.20563997249954,
        -3.415612617835731,
        38.20563997249954
      ]
    },
    "23009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.490822907154396,
              37.945312907049924
            ]
          },
          "bbox": [
            -3.490822907154396,
            37.945312907049924,
            -3.490822907154396,
            37.945312907049924
          ]
        }
      ],
      "bbox": [
        -3.490822907154396,
        37.945312907049924,
        -3.490822907154396,
        37.945312907049924
      ]
    },
    "23010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.778942139153458,
              38.09267689883187
            ]
          },
          "bbox": [
            -3.778942139153458,
            38.09267689883187,
            -3.778942139153458,
            38.09267689883187
          ]
        }
      ],
      "bbox": [
        -3.778942139153458,
        38.09267689883187,
        -3.778942139153458,
        38.09267689883187
      ]
    },
    "23011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7698764062364774,
              38.28506813442383
            ]
          },
          "bbox": [
            -3.7698764062364774,
            38.28506813442383,
            -3.7698764062364774,
            38.28506813442383
          ]
        }
      ],
      "bbox": [
        -3.7698764062364774,
        38.28506813442383,
        -3.7698764062364774,
        38.28506813442383
      ]
    },
    "23012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.866704401550898,
              38.25352765471194
            ]
          },
          "bbox": [
            -2.866704401550898,
            38.25352765471194,
            -2.866704401550898,
            38.25352765471194
          ]
        }
      ],
      "bbox": [
        -2.866704401550898,
        38.25352765471194,
        -2.866704401550898,
        38.25352765471194
      ]
    },
    "23014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5702961272357614,
              37.97347029527438
            ]
          },
          "bbox": [
            -3.5702961272357614,
            37.97347029527438,
            -3.5702961272357614,
            37.97347029527438
          ]
        }
      ],
      "bbox": [
        -3.5702961272357614,
        37.97347029527438,
        -3.5702961272357614,
        37.97347029527438
      ]
    },
    "23015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.370315001171603,
              37.7321514454269
            ]
          },
          "bbox": [
            -3.370315001171603,
            37.7321514454269,
            -3.370315001171603,
            37.7321514454269
          ]
        }
      ],
      "bbox": [
        -3.370315001171603,
        37.7321514454269,
        -3.370315001171603,
        37.7321514454269
      ]
    },
    "23016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6564088747552765,
              38.35319781353023
            ]
          },
          "bbox": [
            -2.6564088747552765,
            38.35319781353023,
            -2.6564088747552765,
            38.35319781353023
          ]
        }
      ],
      "bbox": [
        -2.6564088747552765,
        38.35319781353023,
        -2.6564088747552765,
        38.35319781353023
      ]
    },
    "23017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.246239749701407,
              37.702694934189516
            ]
          },
          "bbox": [
            -3.246239749701407,
            37.702694934189516,
            -3.246239749701407,
            37.702694934189516
          ]
        }
      ],
      "bbox": [
        -3.246239749701407,
        37.702694934189516,
        -3.246239749701407,
        37.702694934189516
      ]
    },
    "23018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5470211845102453,
              37.67489453649321
            ]
          },
          "bbox": [
            -3.5470211845102453,
            37.67489453649321,
            -3.5470211845102453,
            37.67489453649321
          ]
        }
      ],
      "bbox": [
        -3.5470211845102453,
        37.67489453649321,
        -3.5470211845102453,
        37.67489453649321
      ]
    },
    "23019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6530164992072662,
              37.58507127797794
            ]
          },
          "bbox": [
            -3.6530164992072662,
            37.58507127797794,
            -3.6530164992072662,
            37.58507127797794
          ]
        }
      ],
      "bbox": [
        -3.6530164992072662,
        37.58507127797794,
        -3.6530164992072662,
        37.58507127797794
      ]
    },
    "23020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5019423682822035,
              38.059133906951146
            ]
          },
          "bbox": [
            -3.5019423682822035,
            38.059133906951146,
            -3.5019423682822035,
            38.059133906951146
          ]
        }
      ],
      "bbox": [
        -3.5019423682822035,
        38.059133906951146,
        -3.5019423682822035,
        38.059133906951146
      ]
    },
    "23021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.62953560247306,
              38.217299798451116
            ]
          },
          "bbox": [
            -3.62953560247306,
            38.217299798451116,
            -3.62953560247306,
            38.217299798451116
          ]
        }
      ],
      "bbox": [
        -3.62953560247306,
        38.217299798451116,
        -3.62953560247306,
        38.217299798451116
      ]
    },
    "23024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6154858562972825,
              38.30688752437495
            ]
          },
          "bbox": [
            -3.6154858562972825,
            38.30688752437495,
            -3.6154858562972825,
            38.30688752437495
          ]
        }
      ],
      "bbox": [
        -3.6154858562972825,
        38.30688752437495,
        -3.6154858562972825,
        38.30688752437495
      ]
    },
    "23025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.141799817114811,
              38.29548072243694
            ]
          },
          "bbox": [
            -3.141799817114811,
            38.29548072243694,
            -3.141799817114811,
            38.29548072243694
          ]
        }
      ],
      "bbox": [
        -3.141799817114811,
        38.29548072243694,
        -3.141799817114811,
        38.29548072243694
      ]
    },
    "23026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9482680228634393,
              37.54847251029491
            ]
          },
          "bbox": [
            -3.9482680228634393,
            37.54847251029491,
            -3.9482680228634393,
            37.54847251029491
          ]
        }
      ],
      "bbox": [
        -3.9482680228634393,
        37.54847251029491,
        -3.9482680228634393,
        37.54847251029491
      ]
    },
    "23027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.885123883492496,
              37.95965480762972
            ]
          },
          "bbox": [
            -3.885123883492496,
            37.95965480762972,
            -3.885123883492496,
            37.95965480762972
          ]
        }
      ],
      "bbox": [
        -3.885123883492496,
        37.95965480762972,
        -3.885123883492496,
        37.95965480762972
      ]
    },
    "23028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.964753664171251,
              37.91182272310385
            ]
          },
          "bbox": [
            -2.964753664171251,
            37.91182272310385,
            -2.964753664171251,
            37.91182272310385
          ]
        }
      ],
      "bbox": [
        -2.964753664171251,
        37.91182272310385,
        -2.964753664171251,
        37.91182272310385
      ]
    },
    "23029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.987112239241159,
              38.35243403540404
            ]
          },
          "bbox": [
            -2.987112239241159,
            38.35243403540404,
            -2.987112239241159,
            38.35243403540404
          ]
        }
      ],
      "bbox": [
        -2.987112239241159,
        38.35243403540404,
        -2.987112239241159,
        38.35243403540404
      ]
    },
    "23030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0344743384750372,
              37.99076386703704
            ]
          },
          "bbox": [
            -3.0344743384750372,
            37.99076386703704,
            -3.0344743384750372,
            37.99076386703704
          ]
        }
      ],
      "bbox": [
        -3.0344743384750372,
        37.99076386703704,
        -3.0344743384750372,
        37.99076386703704
      ]
    },
    "23031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.031839545911965,
              37.87526102228918
            ]
          },
          "bbox": [
            -4.031839545911965,
            37.87526102228918,
            -4.031839545911965,
            37.87526102228918
          ]
        }
      ],
      "bbox": [
        -4.031839545911965,
        37.87526102228918,
        -4.031839545911965,
        37.87526102228918
      ]
    },
    "23032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.861760420220233,
              38.02091023882123
            ]
          },
          "bbox": [
            -3.861760420220233,
            38.02091023882123,
            -3.861760420220233,
            38.02091023882123
          ]
        }
      ],
      "bbox": [
        -3.861760420220233,
        38.02091023882123,
        -3.861760420220233,
        38.02091023882123
      ]
    },
    "23033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8239463425176283,
              37.49261961484265
            ]
          },
          "bbox": [
            -3.8239463425176283,
            37.49261961484265,
            -3.8239463425176283,
            37.49261961484265
          ]
        }
      ],
      "bbox": [
        -3.8239463425176283,
        37.49261961484265,
        -3.8239463425176283,
        37.49261961484265
      ]
    },
    "23034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9118738705337197,
              37.63101911840558
            ]
          },
          "bbox": [
            -3.9118738705337197,
            37.63101911840558,
            -3.9118738705337197,
            37.63101911840558
          ]
        }
      ],
      "bbox": [
        -3.9118738705337197,
        37.63101911840558,
        -3.9118738705337197,
        37.63101911840558
      ]
    },
    "23035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9018017408166945,
              37.902040378380256
            ]
          },
          "bbox": [
            -3.9018017408166945,
            37.902040378380256,
            -3.9018017408166945,
            37.902040378380256
          ]
        }
      ],
      "bbox": [
        -3.9018017408166945,
        37.902040378380256,
        -3.9018017408166945,
        37.902040378380256
      ]
    },
    "23037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7521254543746636,
              38.441593806396774
            ]
          },
          "bbox": [
            -2.7521254543746636,
            38.441593806396774,
            -2.7521254543746636,
            38.441593806396774
          ]
        }
      ],
      "bbox": [
        -2.7521254543746636,
        38.441593806396774,
        -2.7521254543746636,
        38.441593806396774
      ]
    },
    "23038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6989214458788093,
              37.75231108388119
            ]
          },
          "bbox": [
            -3.6989214458788093,
            37.75231108388119,
            -3.6989214458788093,
            37.75231108388119
          ]
        }
      ],
      "bbox": [
        -3.6989214458788093,
        37.75231108388119,
        -3.6989214458788093,
        37.75231108388119
      ]
    },
    "23039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7578949456617896,
              38.149205497093845
            ]
          },
          "bbox": [
            -3.7578949456617896,
            38.149205497093845,
            -3.7578949456617896,
            38.149205497093845
          ]
        }
      ],
      "bbox": [
        -3.7578949456617896,
        38.149205497093845,
        -3.7578949456617896,
        38.149205497093845
      ]
    },
    "23040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9861211236108947,
              37.96584287420788
            ]
          },
          "bbox": [
            -3.9861211236108947,
            37.96584287420788,
            -3.9861211236108947,
            37.96584287420788
          ]
        }
      ],
      "bbox": [
        -3.9861211236108947,
        37.96584287420788,
        -3.9861211236108947,
        37.96584287420788
      ]
    },
    "23041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.153893742692883,
              37.79562047918145
            ]
          },
          "bbox": [
            -4.153893742692883,
            37.79562047918145,
            -4.153893742692883,
            37.79562047918145
          ]
        }
      ],
      "bbox": [
        -4.153893742692883,
        37.79562047918145,
        -4.153893742692883,
        37.79562047918145
      ]
    },
    "23042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0030124451591984,
              37.72548990973034
            ]
          },
          "bbox": [
            -3.0030124451591984,
            37.72548990973034,
            -3.0030124451591984,
            37.72548990973034
          ]
        }
      ],
      "bbox": [
        -3.0030124451591984,
        37.72548990973034,
        -3.0030124451591984,
        37.72548990973034
      ]
    },
    "23043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7324253169186896,
              38.185916226769514
            ]
          },
          "bbox": [
            -2.7324253169186896,
            38.185916226769514,
            -2.7324253169186896,
            38.185916226769514
          ]
        }
      ],
      "bbox": [
        -2.7324253169186896,
        38.185916226769514,
        -2.7324253169186896,
        38.185916226769514
      ]
    },
    "23044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3971259600093457,
              37.64749055009973
            ]
          },
          "bbox": [
            -3.3971259600093457,
            37.64749055009973,
            -3.3971259600093457,
            37.64749055009973
          ]
        }
      ],
      "bbox": [
        -3.3971259600093457,
        37.64749055009973,
        -3.3971259600093457,
        37.64749055009973
      ]
    },
    "23045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.065616400783768,
              37.69658766206689
            ]
          },
          "bbox": [
            -3.065616400783768,
            37.69658766206689,
            -3.065616400783768,
            37.69658766206689
          ]
        }
      ],
      "bbox": [
        -3.065616400783768,
        37.69658766206689,
        -3.065616400783768,
        37.69658766206689
      ]
    },
    "23046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5402440647879283,
              38.049210011422126
            ]
          },
          "bbox": [
            -3.5402440647879283,
            38.049210011422126,
            -3.5402440647879283,
            38.049210011422126
          ]
        }
      ],
      "bbox": [
        -3.5402440647879283,
        38.049210011422126,
        -3.5402440647879283,
        38.049210011422126
      ]
    },
    "23047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9305958421749048,
              37.96505938987337
            ]
          },
          "bbox": [
            -2.9305958421749048,
            37.96505938987337,
            -2.9305958421749048,
            37.96505938987337
          ]
        }
      ],
      "bbox": [
        -2.9305958421749048,
        37.96505938987337,
        -2.9305958421749048,
        37.96505938987337
      ]
    },
    "23048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.987078246623346,
              38.13092662858385
            ]
          },
          "bbox": [
            -2.987078246623346,
            38.13092662858385,
            -2.987078246623346,
            38.13092662858385
          ]
        }
      ],
      "bbox": [
        -2.987078246623346,
        38.13092662858385,
        -2.987078246623346,
        38.13092662858385
      ]
    },
    "23049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.75082547738458,
              38.01724434526006
            ]
          },
          "bbox": [
            -3.75082547738458,
            38.01724434526006,
            -3.75082547738458,
            38.01724434526006
          ]
        }
      ],
      "bbox": [
        -3.75082547738458,
        38.01724434526006,
        -3.75082547738458,
        38.01724434526006
      ]
    },
    "23050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7513910614276846,
              37.80921839764572
            ]
          },
          "bbox": [
            -3.7513910614276846,
            37.80921839764572,
            -3.7513910614276846,
            37.80921839764572
          ]
        }
      ],
      "bbox": [
        -3.7513910614276846,
        37.80921839764572,
        -3.7513910614276846,
        37.80921839764572
      ]
    },
    "23051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.916256827079066,
              37.73864665808082
            ]
          },
          "bbox": [
            -3.916256827079066,
            37.73864665808082,
            -3.916256827079066,
            37.73864665808082
          ]
        }
      ],
      "bbox": [
        -3.916256827079066,
        37.73864665808082,
        -3.916256827079066,
        37.73864665808082
      ]
    },
    "23052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5164958944705202,
              37.83976295339619
            ]
          },
          "bbox": [
            -3.5164958944705202,
            37.83976295339619,
            -3.5164958944705202,
            37.83976295339619
          ]
        }
      ],
      "bbox": [
        -3.5164958944705202,
        37.83976295339619,
        -3.5164958944705202,
        37.83976295339619
      ]
    },
    "23053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3315378471668504,
              37.832072944584084
            ]
          },
          "bbox": [
            -3.3315378471668504,
            37.832072944584084,
            -3.3315378471668504,
            37.832072944584084
          ]
        }
      ],
      "bbox": [
        -3.3315378471668504,
        37.832072944584084,
        -3.3315378471668504,
        37.832072944584084
      ]
    },
    "23054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1960511568294963,
              37.76793188638007
            ]
          },
          "bbox": [
            -3.1960511568294963,
            37.76793188638007,
            -3.1960511568294963,
            37.76793188638007
          ]
        }
      ],
      "bbox": [
        -3.1960511568294963,
        37.76793188638007,
        -3.1960511568294963,
        37.76793188638007
      ]
    },
    "23055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6367899784139874,
              38.09619231461563
            ]
          },
          "bbox": [
            -3.6367899784139874,
            38.09619231461563,
            -3.6367899784139874,
            38.09619231461563
          ]
        }
      ],
      "bbox": [
        -3.6367899784139874,
        38.09619231461563,
        -3.6367899784139874,
        38.09619231461563
      ]
    },
    "23056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.231992700734371,
              37.961416982178676
            ]
          },
          "bbox": [
            -4.231992700734371,
            37.961416982178676,
            -4.231992700734371,
            37.961416982178676
          ]
        }
      ],
      "bbox": [
        -4.231992700734371,
        37.961416982178676,
        -4.231992700734371,
        37.961416982178676
      ]
    },
    "23057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5768798156852757,
              38.01414652705966
            ]
          },
          "bbox": [
            -3.5768798156852757,
            38.01414652705966,
            -3.5768798156852757,
            38.01414652705966
          ]
        }
      ],
      "bbox": [
        -3.5768798156852757,
        38.01414652705966,
        -3.5768798156852757,
        38.01414652705966
      ]
    },
    "23058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6104651198571633,
              37.83563697612514
            ]
          },
          "bbox": [
            -3.6104651198571633,
            37.83563697612514,
            -3.6104651198571633,
            37.83563697612514
          ]
        }
      ],
      "bbox": [
        -3.6104651198571633,
        37.83563697612514,
        -3.6104651198571633,
        37.83563697612514
      ]
    },
    "23059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1712924351877065,
              38.06954523965475
            ]
          },
          "bbox": [
            -4.1712924351877065,
            38.06954523965475,
            -4.1712924351877065,
            38.06954523965475
          ]
        }
      ],
      "bbox": [
        -4.1712924351877065,
        38.06954523965475,
        -4.1712924351877065,
        38.06954523965475
      ]
    },
    "23060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.018035461766903,
              37.68974041418092
            ]
          },
          "bbox": [
            -4.018035461766903,
            37.68974041418092,
            -4.018035461766903,
            37.68974041418092
          ]
        }
      ],
      "bbox": [
        -4.018035461766903,
        37.68974041418092,
        -4.018035461766903,
        37.68974041418092
      ]
    },
    "23061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8082837429441208,
              37.96229810556653
            ]
          },
          "bbox": [
            -3.8082837429441208,
            37.96229810556653,
            -3.8082837429441208,
            37.96229810556653
          ]
        }
      ],
      "bbox": [
        -3.8082837429441208,
        37.96229810556653,
        -3.8082837429441208,
        37.96229810556653
      ]
    },
    "23062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1014272643182283,
              38.38492056142718
            ]
          },
          "bbox": [
            -3.1014272643182283,
            38.38492056142718,
            -3.1014272643182283,
            38.38492056142718
          ]
        }
      ],
      "bbox": [
        -3.1014272643182283,
        38.38492056142718,
        -3.1014272643182283,
        38.38492056142718
      ]
    },
    "23063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3367060542239995,
              38.21906541398198
            ]
          },
          "bbox": [
            -3.3367060542239995,
            38.21906541398198,
            -3.3367060542239995,
            38.21906541398198
          ]
        }
      ],
      "bbox": [
        -3.3367060542239995,
        38.21906541398198,
        -3.3367060542239995,
        38.21906541398198
      ]
    },
    "23064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.660707242939787,
              37.53725701981359
            ]
          },
          "bbox": [
            -3.660707242939787,
            37.53725701981359,
            -3.660707242939787,
            37.53725701981359
          ]
        }
      ],
      "bbox": [
        -3.660707242939787,
        37.53725701981359,
        -3.660707242939787,
        37.53725701981359
      ]
    },
    "23065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.73605646794384,
              38.36821682069077
            ]
          },
          "bbox": [
            -2.73605646794384,
            38.36821682069077,
            -2.73605646794384,
            38.36821682069077
          ]
        }
      ],
      "bbox": [
        -2.73605646794384,
        38.36821682069077,
        -2.73605646794384,
        38.36821682069077
      ]
    },
    "23066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0987360845932233,
              37.88055823338728
            ]
          },
          "bbox": [
            -3.0987360845932233,
            37.88055823338728,
            -3.0987360845932233,
            37.88055823338728
          ]
        }
      ],
      "bbox": [
        -3.0987360845932233,
        37.88055823338728,
        -3.0987360845932233,
        37.88055823338728
      ]
    },
    "23067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.631504730478174,
              37.7210842329699
            ]
          },
          "bbox": [
            -3.631504730478174,
            37.7210842329699,
            -3.631504730478174,
            37.7210842329699
          ]
        }
      ],
      "bbox": [
        -3.631504730478174,
        37.7210842329699,
        -3.631504730478174,
        37.7210842329699
      ]
    },
    "23069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.205466919225949,
              37.85687610781722
            ]
          },
          "bbox": [
            -4.205466919225949,
            37.85687610781722,
            -4.205466919225949,
            37.85687610781722
          ]
        }
      ],
      "bbox": [
        -4.205466919225949,
        37.85687610781722,
        -4.205466919225949,
        37.85687610781722
      ]
    },
    "23070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.947440256990264,
              37.71247773125207
            ]
          },
          "bbox": [
            -2.947440256990264,
            37.71247773125207,
            -2.947440256990264,
            37.71247773125207
          ]
        }
      ],
      "bbox": [
        -2.947440256990264,
        37.71247773125207,
        -2.947440256990264,
        37.71247773125207
      ]
    },
    "23071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.813352217285766,
              38.33682956759744
            ]
          },
          "bbox": [
            -2.813352217285766,
            38.33682956759744,
            -2.813352217285766,
            38.33682956759744
          ]
        }
      ],
      "bbox": [
        -2.813352217285766,
        38.33682956759744,
        -2.813352217285766,
        38.33682956759744
      ]
    },
    "23072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.788374505500184,
              38.376588173132
            ]
          },
          "bbox": [
            -2.788374505500184,
            38.376588173132,
            -2.788374505500184,
            38.376588173132
          ]
        }
      ],
      "bbox": [
        -2.788374505500184,
        38.376588173132,
        -2.788374505500184,
        38.376588173132
      ]
    },
    "23073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.088065527876854,
              37.78198515826231
            ]
          },
          "bbox": [
            -3.088065527876854,
            37.78198515826231,
            -3.088065527876854,
            37.78198515826231
          ]
        }
      ],
      "bbox": [
        -3.088065527876854,
        37.78198515826231,
        -3.088065527876854,
        37.78198515826231
      ]
    },
    "23074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4540040559747416,
              38.077649439265464
            ]
          },
          "bbox": [
            -3.4540040559747416,
            38.077649439265464,
            -3.4540040559747416,
            38.077649439265464
          ]
        }
      ],
      "bbox": [
        -3.4540040559747416,
        38.077649439265464,
        -3.4540040559747416,
        38.077649439265464
      ]
    },
    "23075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2604286407820533,
              38.093575376840434
            ]
          },
          "bbox": [
            -3.2604286407820533,
            38.093575376840434,
            -3.2604286407820533,
            38.093575376840434
          ]
        }
      ],
      "bbox": [
        -3.2604286407820533,
        38.093575376840434,
        -3.2604286407820533,
        38.093575376840434
      ]
    },
    "23076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5401094087354763,
              38.3694325365428
            ]
          },
          "bbox": [
            -3.5401094087354763,
            38.3694325365428,
            -3.5401094087354763,
            38.3694325365428
          ]
        }
      ],
      "bbox": [
        -3.5401094087354763,
        38.3694325365428,
        -3.5401094087354763,
        38.3694325365428
      ]
    },
    "23077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.190064777926206,
              37.74004181007601
            ]
          },
          "bbox": [
            -4.190064777926206,
            37.74004181007601,
            -4.190064777926206,
            37.74004181007601
          ]
        }
      ],
      "bbox": [
        -4.190064777926206,
        37.74004181007601,
        -4.190064777926206,
        37.74004181007601
      ]
    },
    "23079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.250977078034643,
              38.298411020977575
            ]
          },
          "bbox": [
            -3.250977078034643,
            38.298411020977575,
            -3.250977078034643,
            38.298411020977575
          ]
        }
      ],
      "bbox": [
        -3.250977078034643,
        38.298411020977575,
        -3.250977078034643,
        38.298411020977575
      ]
    },
    "23080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.007822390420801,
              38.01821472346614
            ]
          },
          "bbox": [
            -3.007822390420801,
            38.01821472346614,
            -3.007822390420801,
            38.01821472346614
          ]
        }
      ],
      "bbox": [
        -3.007822390420801,
        38.01821472346614,
        -3.007822390420801,
        38.01821472346614
      ]
    },
    "23081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.658932230237688,
              38.25306264763197
            ]
          },
          "bbox": [
            -2.658932230237688,
            38.25306264763197,
            -2.658932230237688,
            38.25306264763197
          ]
        }
      ],
      "bbox": [
        -2.658932230237688,
        38.25306264763197,
        -2.658932230237688,
        38.25306264763197
      ]
    },
    "23082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5757489374684104,
              38.3810112058394
            ]
          },
          "bbox": [
            -2.5757489374684104,
            38.3810112058394,
            -2.5757489374684104,
            38.3810112058394
          ]
        }
      ],
      "bbox": [
        -2.5757489374684104,
        38.3810112058394,
        -2.5757489374684104,
        38.3810112058394
      ]
    },
    "23084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.941269175843594,
              38.21166361205278
            ]
          },
          "bbox": [
            -2.941269175843594,
            38.21166361205278,
            -2.941269175843594,
            38.21166361205278
          ]
        }
      ],
      "bbox": [
        -2.941269175843594,
        38.21166361205278,
        -2.941269175843594,
        38.21166361205278
      ]
    },
    "23085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.660854939961966,
              37.984195381129894
            ]
          },
          "bbox": [
            -3.660854939961966,
            37.984195381129894,
            -3.660854939961966,
            37.984195381129894
          ]
        }
      ],
      "bbox": [
        -3.660854939961966,
        37.984195381129894,
        -3.660854939961966,
        37.984195381129894
      ]
    },
    "23086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.912680874585748,
              37.82582321894334
            ]
          },
          "bbox": [
            -3.912680874585748,
            37.82582321894334,
            -3.912680874585748,
            37.82582321894334
          ]
        }
      ],
      "bbox": [
        -3.912680874585748,
        37.82582321894334,
        -3.912680874585748,
        37.82582321894334
      ]
    },
    "23087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.04389135247184,
              37.79493161847358
            ]
          },
          "bbox": [
            -4.04389135247184,
            37.79493161847358,
            -4.04389135247184,
            37.79493161847358
          ]
        }
      ],
      "bbox": [
        -4.04389135247184,
        37.79493161847358,
        -4.04389135247184,
        37.79493161847358
      ]
    },
    "23088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.240709268367162,
              38.01161214712376
            ]
          },
          "bbox": [
            -3.240709268367162,
            38.01161214712376,
            -3.240709268367162,
            38.01161214712376
          ]
        }
      ],
      "bbox": [
        -3.240709268367162,
        38.01161214712376,
        -3.240709268367162,
        38.01161214712376
      ]
    },
    "23090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5299044109630757,
              37.78187819770403
            ]
          },
          "bbox": [
            -3.5299044109630757,
            37.78187819770403,
            -3.5299044109630757,
            37.78187819770403
          ]
        }
      ],
      "bbox": [
        -3.5299044109630757,
        37.78187819770403,
        -3.5299044109630757,
        37.78187819770403
      ]
    },
    "23091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6791168405211576,
              38.41689153155383
            ]
          },
          "bbox": [
            -2.6791168405211576,
            38.41689153155383,
            -2.6791168405211576,
            38.41689153155383
          ]
        }
      ],
      "bbox": [
        -2.6791168405211576,
        38.41689153155383,
        -2.6791168405211576,
        38.41689153155383
      ]
    },
    "23092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.309364802791172,
              37.993581202603295
            ]
          },
          "bbox": [
            -3.309364802791172,
            37.993581202603295,
            -3.309364802791172,
            37.993581202603295
          ]
        }
      ],
      "bbox": [
        -3.309364802791172,
        37.993581202603295,
        -3.309364802791172,
        37.993581202603295
      ]
    },
    "23093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7909448570066417,
              37.580960539513164
            ]
          },
          "bbox": [
            -3.7909448570066417,
            37.580960539513164,
            -3.7909448570066417,
            37.580960539513164
          ]
        }
      ],
      "bbox": [
        -3.7909448570066417,
        37.580960539513164,
        -3.7909448570066417,
        37.580960539513164
      ]
    },
    "23094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4793251646120607,
              38.224749972577634
            ]
          },
          "bbox": [
            -3.4793251646120607,
            38.224749972577634,
            -3.4793251646120607,
            38.224749972577634
          ]
        }
      ],
      "bbox": [
        -3.4793251646120607,
        38.224749972577634,
        -3.4793251646120607,
        38.224749972577634
      ]
    },
    "23095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.040777966208013,
              38.08601016903617
            ]
          },
          "bbox": [
            -3.040777966208013,
            38.08601016903617,
            -3.040777966208013,
            38.08601016903617
          ]
        }
      ],
      "bbox": [
        -3.040777966208013,
        38.08601016903617,
        -3.040777966208013,
        38.08601016903617
      ]
    },
    "23096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8926786704831655,
              38.14032566255368
            ]
          },
          "bbox": [
            -3.8926786704831655,
            38.14032566255368,
            -3.8926786704831655,
            38.14032566255368
          ]
        }
      ],
      "bbox": [
        -3.8926786704831655,
        38.14032566255368,
        -3.8926786704831655,
        38.14032566255368
      ]
    },
    "23097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.947140386119366,
              38.171368899562616
            ]
          },
          "bbox": [
            -2.947140386119366,
            38.171368899562616,
            -2.947140386119366,
            38.171368899562616
          ]
        }
      ],
      "bbox": [
        -2.947140386119366,
        38.171368899562616,
        -2.947140386119366,
        38.171368899562616
      ]
    },
    "23098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.000051263779778,
              37.84339263103052
            ]
          },
          "bbox": [
            -4.000051263779778,
            37.84339263103052,
            -4.000051263779778,
            37.84339263103052
          ]
        }
      ],
      "bbox": [
        -4.000051263779778,
        37.84339263103052,
        -4.000051263779778,
        37.84339263103052
      ]
    },
    "23099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.837494080650863,
              37.68478990287023
            ]
          },
          "bbox": [
            -3.837494080650863,
            37.68478990287023,
            -3.837494080650863,
            37.68478990287023
          ]
        }
      ],
      "bbox": [
        -3.837494080650863,
        37.68478990287023,
        -3.837494080650863,
        37.68478990287023
      ]
    },
    "23101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6707501038457195,
              38.482395663216266
            ]
          },
          "bbox": [
            -2.6707501038457195,
            38.482395663216266,
            -2.6707501038457195,
            38.482395663216266
          ]
        }
      ],
      "bbox": [
        -2.6707501038457195,
        38.482395663216266,
        -2.6707501038457195,
        38.482395663216266
      ]
    },
    "23901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6370634950105827,
              37.64181477433438
            ]
          },
          "bbox": [
            -3.6370634950105827,
            37.64181477433438,
            -3.6370634950105827,
            37.64181477433438
          ]
        }
      ],
      "bbox": [
        -3.6370634950105827,
        37.64181477433438,
        -3.6370634950105827,
        37.64181477433438
      ]
    },
    "23902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.423676175414784,
              37.838168721942054
            ]
          },
          "bbox": [
            -3.423676175414784,
            37.838168721942054,
            -3.423676175414784,
            37.838168721942054
          ]
        }
      ],
      "bbox": [
        -3.423676175414784,
        37.838168721942054,
        -3.423676175414784,
        37.838168721942054
      ]
    },
    "23903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6937902291746205,
              37.929865782339846
            ]
          },
          "bbox": [
            -3.6937902291746205,
            37.929865782339846,
            -3.6937902291746205,
            37.929865782339846
          ]
        }
      ],
      "bbox": [
        -3.6937902291746205,
        37.929865782339846,
        -3.6937902291746205,
        37.929865782339846
      ]
    },
    "23904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6742888462214247,
              38.09794130081694
            ]
          },
          "bbox": [
            -2.6742888462214247,
            38.09794130081694,
            -2.6742888462214247,
            38.09794130081694
          ]
        }
      ],
      "bbox": [
        -2.6742888462214247,
        38.09794130081694,
        -2.6742888462214247,
        38.09794130081694
      ]
    },
    "23905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8863262359123247,
              38.3086835765769
            ]
          },
          "bbox": [
            -2.8863262359123247,
            38.3086835765769,
            -2.8863262359123247,
            38.3086835765769
          ]
        }
      ],
      "bbox": [
        -2.8863262359123247,
        38.3086835765769,
        -2.8863262359123247,
        38.3086835765769
      ]
    },
    "24001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.121686872698347,
              43.033670030154695
            ]
          },
          "bbox": [
            -5.121686872698347,
            43.033670030154695,
            -5.121686872698347,
            43.033670030154695
          ]
        }
      ],
      "bbox": [
        -5.121686872698347,
        43.033670030154695,
        -5.121686872698347,
        43.033670030154695
      ]
    },
    "24002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.578244633839425,
              42.21178358775807
            ]
          },
          "bbox": [
            -5.578244633839425,
            42.21178358775807,
            -5.578244633839425,
            42.21178358775807
          ]
        }
      ],
      "bbox": [
        -5.578244633839425,
        42.21178358775807,
        -5.578244633839425,
        42.21178358775807
      ]
    },
    "24003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8467987695546455,
              42.15303724949739
            ]
          },
          "bbox": [
            -5.8467987695546455,
            42.15303724949739,
            -5.8467987695546455,
            42.15303724949739
          ]
        }
      ],
      "bbox": [
        -5.8467987695546455,
        42.15303724949739,
        -5.8467987695546455,
        42.15303724949739
      ]
    },
    "24004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0099515913833805,
              42.67169802707794
            ]
          },
          "bbox": [
            -5.0099515913833805,
            42.67169802707794,
            -5.0099515913833805,
            42.67169802707794
          ]
        }
      ],
      "bbox": [
        -5.0099515913833805,
        42.67169802707794,
        -5.0099515913833805,
        42.67169802707794
      ]
    },
    "24005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.687391493402722,
              42.175331446512594
            ]
          },
          "bbox": [
            -5.687391493402722,
            42.175331446512594,
            -5.687391493402722,
            42.175331446512594
          ]
        }
      ],
      "bbox": [
        -5.687391493402722,
        42.175331446512594,
        -5.687391493402722,
        42.175331446512594
      ]
    },
    "24006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.588579473640229,
              42.42993609169311
            ]
          },
          "bbox": [
            -5.588579473640229,
            42.42993609169311,
            -5.588579473640229,
            42.42993609169311
          ]
        }
      ],
      "bbox": [
        -5.588579473640229,
        42.42993609169311,
        -5.588579473640229,
        42.42993609169311
      ]
    },
    "24007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.683088985180021,
              42.65259335893817
            ]
          },
          "bbox": [
            -6.683088985180021,
            42.65259335893817,
            -6.683088985180021,
            42.65259335893817
          ]
        }
      ],
      "bbox": [
        -6.683088985180021,
        42.65259335893817,
        -6.683088985180021,
        42.65259335893817
      ]
    },
    "24008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.11565577492405,
              42.46257147726616
            ]
          },
          "bbox": [
            -6.11565577492405,
            42.46257147726616,
            -6.11565577492405,
            42.46257147726616
          ]
        }
      ],
      "bbox": [
        -6.11565577492405,
        42.46257147726616,
        -6.11565577492405,
        42.46257147726616
      ]
    },
    "24009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.919355924818515,
              42.71552164954823
            ]
          },
          "bbox": [
            -6.919355924818515,
            42.71552164954823,
            -6.919355924818515,
            42.71552164954823
          ]
        }
      ],
      "bbox": [
        -6.919355924818515,
        42.71552164954823,
        -6.919355924818515,
        42.71552164954823
      ]
    },
    "24010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.906253602061686,
              42.29274629113753
            ]
          },
          "bbox": [
            -5.906253602061686,
            42.29274629113753,
            -5.906253602061686,
            42.29274629113753
          ]
        }
      ],
      "bbox": [
        -5.906253602061686,
        42.29274629113753,
        -5.906253602061686,
        42.29274629113753
      ]
    },
    "24011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.987811989312852,
              42.612515965005315
            ]
          },
          "bbox": [
            -6.987811989312852,
            42.612515965005315,
            -6.987811989312852,
            42.612515965005315
          ]
        }
      ],
      "bbox": [
        -6.987811989312852,
        42.612515965005315,
        -6.987811989312852,
        42.612515965005315
      ]
    },
    "24012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.856064592105961,
              42.84873016986678
            ]
          },
          "bbox": [
            -5.856064592105961,
            42.84873016986678,
            -5.856064592105961,
            42.84873016986678
          ]
        }
      ],
      "bbox": [
        -5.856064592105961,
        42.84873016986678,
        -5.856064592105961,
        42.84873016986678
      ]
    },
    "24014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4370276354867215,
              42.647897066595895
            ]
          },
          "bbox": [
            -6.4370276354867215,
            42.647897066595895,
            -6.4370276354867215,
            42.647897066595895
          ]
        }
      ],
      "bbox": [
        -6.4370276354867215,
        42.647897066595895,
        -6.4370276354867215,
        42.647897066595895
      ]
    },
    "24015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.941984410718745,
              42.53455694097422
            ]
          },
          "bbox": [
            -5.941984410718745,
            42.53455694097422,
            -5.941984410718745,
            42.53455694097422
          ]
        }
      ],
      "bbox": [
        -5.941984410718745,
        42.53455694097422,
        -5.941984410718745,
        42.53455694097422
      ]
    },
    "24016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.672540321094152,
              42.38119523521708
            ]
          },
          "bbox": [
            -6.672540321094152,
            42.38119523521708,
            -6.672540321094152,
            42.38119523521708
          ]
        }
      ],
      "bbox": [
        -6.672540321094152,
        42.38119523521708,
        -6.672540321094152,
        42.38119523521708
      ]
    },
    "24017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.687840034705226,
              42.35118863120709
            ]
          },
          "bbox": [
            -5.687840034705226,
            42.35118863120709,
            -5.687840034705226,
            42.35118863120709
          ]
        }
      ],
      "bbox": [
        -5.687840034705226,
        42.35118863120709,
        -5.687840034705226,
        42.35118863120709
      ]
    },
    "24018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.149736079949292,
              42.388411320443275
            ]
          },
          "bbox": [
            -5.149736079949292,
            42.388411320443275,
            -5.149736079949292,
            42.388411320443275
          ]
        }
      ],
      "bbox": [
        -5.149736079949292,
        42.388411320443275,
        -5.149736079949292,
        42.388411320443275
      ]
    },
    "24019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.578087376538085,
              42.73752760286688
            ]
          },
          "bbox": [
            -6.578087376538085,
            42.73752760286688,
            -6.578087376538085,
            42.73752760286688
          ]
        }
      ],
      "bbox": [
        -6.578087376538085,
        42.73752760286688,
        -6.578087376538085,
        42.73752760286688
      ]
    },
    "24020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.860126465625873,
              42.99559163918258
            ]
          },
          "bbox": [
            -4.860126465625873,
            42.99559163918258,
            -4.860126465625873,
            42.99559163918258
          ]
        }
      ],
      "bbox": [
        -4.860126465625873,
        42.99559163918258,
        -4.860126465625873,
        42.99559163918258
      ]
    },
    "24021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.285880196006645,
              42.903204421333996
            ]
          },
          "bbox": [
            -5.285880196006645,
            42.903204421333996,
            -5.285880196006645,
            42.903204421333996
          ]
        }
      ],
      "bbox": [
        -5.285880196006645,
        42.903204421333996,
        -5.285880196006645,
        42.903204421333996
      ]
    },
    "24022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7327543792614915,
              42.48242779461521
            ]
          },
          "bbox": [
            -6.7327543792614915,
            42.48242779461521,
            -6.7327543792614915,
            42.48242779461521
          ]
        }
      ],
      "bbox": [
        -6.7327543792614915,
        42.48242779461521,
        -6.7327543792614915,
        42.48242779461521
      ]
    },
    "24023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.177955612108952,
              42.511228327611825
            ]
          },
          "bbox": [
            -6.177955612108952,
            42.511228327611825,
            -6.177955612108952,
            42.511228327611825
          ]
        }
      ],
      "bbox": [
        -6.177955612108952,
        42.511228327611825,
        -6.177955612108952,
        42.511228327611825
      ]
    },
    "24024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.208349753224458,
              42.43690630236005
            ]
          },
          "bbox": [
            -5.208349753224458,
            42.43690630236005,
            -5.208349753224458,
            42.43690630236005
          ]
        }
      ],
      "bbox": [
        -5.208349753224458,
        42.43690630236005,
        -5.208349753224458,
        42.43690630236005
      ]
    },
    "24025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.03300413002472,
              43.062990982426456
            ]
          },
          "bbox": [
            -5.03300413002472,
            43.062990982426456,
            -5.03300413002472,
            43.062990982426456
          ]
        }
      ],
      "bbox": [
        -5.03300413002472,
        43.062990982426456,
        -5.03300413002472,
        43.062990982426456
      ]
    },
    "24026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.800205177206058,
              42.43866700818004
            ]
          },
          "bbox": [
            -5.800205177206058,
            42.43866700818004,
            -5.800205177206058,
            42.43866700818004
          ]
        }
      ],
      "bbox": [
        -5.800205177206058,
        42.43866700818004,
        -5.800205177206058,
        42.43866700818004
      ]
    },
    "24027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.62571667685639,
              42.619677065320644
            ]
          },
          "bbox": [
            -6.62571667685639,
            42.619677065320644,
            -6.62571667685639,
            42.619677065320644
          ]
        }
      ],
      "bbox": [
        -6.62571667685639,
        42.619677065320644,
        -6.62571667685639,
        42.619677065320644
      ]
    },
    "24028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.525372002270888,
              42.403670202418084
            ]
          },
          "bbox": [
            -5.525372002270888,
            42.403670202418084,
            -5.525372002270888,
            42.403670202418084
          ]
        }
      ],
      "bbox": [
        -5.525372002270888,
        42.403670202418084,
        -5.525372002270888,
        42.403670202418084
      ]
    },
    "24029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.167321257141288,
              42.97143314937677
            ]
          },
          "bbox": [
            -6.167321257141288,
            42.97143314937677,
            -6.167321257141288,
            42.97143314937677
          ]
        }
      ],
      "bbox": [
        -6.167321257141288,
        42.97143314937677,
        -6.167321257141288,
        42.97143314937677
      ]
    },
    "24030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.731022279802506,
              42.626355914393734
            ]
          },
          "bbox": [
            -6.731022279802506,
            42.626355914393734,
            -6.731022279802506,
            42.626355914393734
          ]
        }
      ],
      "bbox": [
        -6.731022279802506,
        42.626355914393734,
        -6.731022279802506,
        42.626355914393734
      ]
    },
    "24031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.086707960007998,
              42.39104908712856
            ]
          },
          "bbox": [
            -5.086707960007998,
            42.39104908712856,
            -5.086707960007998,
            42.39104908712856
          ]
        }
      ],
      "bbox": [
        -5.086707960007998,
        42.39104908712856,
        -5.086707960007998,
        42.39104908712856
      ]
    },
    "24032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.499729300892809,
              42.146783527452946
            ]
          },
          "bbox": [
            -5.499729300892809,
            42.146783527452946,
            -5.499729300892809,
            42.146783527452946
          ]
        }
      ],
      "bbox": [
        -5.499729300892809,
        42.146783527452946,
        -5.499729300892809,
        42.146783527452946
      ]
    },
    "24033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.52579777023959,
              42.43869635841799
            ]
          },
          "bbox": [
            -5.52579777023959,
            42.43869635841799,
            -5.52579777023959,
            42.43869635841799
          ]
        }
      ],
      "bbox": [
        -5.52579777023959,
        42.43869635841799,
        -5.52579777023959,
        42.43869635841799
      ]
    },
    "24034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.677009276111587,
              42.58099660322019
            ]
          },
          "bbox": [
            -6.677009276111587,
            42.58099660322019,
            -6.677009276111587,
            42.58099660322019
          ]
        }
      ],
      "bbox": [
        -6.677009276111587,
        42.58099660322019,
        -6.677009276111587,
        42.58099660322019
      ]
    },
    "24036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.753927806282332,
              42.8398201849457
            ]
          },
          "bbox": [
            -6.753927806282332,
            42.8398201849457,
            -6.753927806282332,
            42.8398201849457
          ]
        }
      ],
      "bbox": [
        -6.753927806282332,
        42.8398201849457,
        -6.753927806282332,
        42.8398201849457
      ]
    },
    "24037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.549672118170953,
              42.96967504337053
            ]
          },
          "bbox": [
            -5.549672118170953,
            42.96967504337053,
            -5.549672118170953,
            42.96967504337053
          ]
        }
      ],
      "bbox": [
        -5.549672118170953,
        42.96967504337053,
        -5.549672118170953,
        42.96967504337053
      ]
    },
    "24038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.733093426832388,
              42.55033527084131
            ]
          },
          "bbox": [
            -6.733093426832388,
            42.55033527084131,
            -6.733093426832388,
            42.55033527084131
          ]
        }
      ],
      "bbox": [
        -6.733093426832388,
        42.55033527084131,
        -6.733093426832388,
        42.55033527084131
      ]
    },
    "24039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.846853970250402,
              42.58761757141408
            ]
          },
          "bbox": [
            -5.846853970250402,
            42.58761757141408,
            -5.846853970250402,
            42.58761757141408
          ]
        }
      ],
      "bbox": [
        -5.846853970250402,
        42.58761757141408,
        -5.846853970250402,
        42.58761757141408
      ]
    },
    "24040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.753936729476705,
              42.800557779004485
            ]
          },
          "bbox": [
            -5.753936729476705,
            42.800557779004485,
            -5.753936729476705,
            42.800557779004485
          ]
        }
      ],
      "bbox": [
        -5.753936729476705,
        42.800557779004485,
        -5.753936729476705,
        42.800557779004485
      ]
    },
    "24041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.789255117302461,
              42.485393715721024
            ]
          },
          "bbox": [
            -6.789255117302461,
            42.485393715721024,
            -6.789255117302461,
            42.485393715721024
          ]
        }
      ],
      "bbox": [
        -6.789255117302461,
        42.485393715721024,
        -6.789255117302461,
        42.485393715721024
      ]
    },
    "24042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.412725960499145,
              42.20826136320383
            ]
          },
          "bbox": [
            -5.412725960499145,
            42.20826136320383,
            -5.412725960499145,
            42.20826136320383
          ]
        }
      ],
      "bbox": [
        -5.412725960499145,
        42.20826136320383,
        -5.412725960499145,
        42.20826136320383
      ]
    },
    "24043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.545959076108488,
              42.34845320147154
            ]
          },
          "bbox": [
            -6.545959076108488,
            42.34845320147154,
            -6.545959076108488,
            42.34845320147154
          ]
        }
      ],
      "bbox": [
        -6.545959076108488,
        42.34845320147154,
        -6.545959076108488,
        42.34845320147154
      ]
    },
    "24044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.146644344638571,
              42.323610392976654
            ]
          },
          "bbox": [
            -6.146644344638571,
            42.323610392976654,
            -6.146644344638571,
            42.323610392976654
          ]
        }
      ],
      "bbox": [
        -6.146644344638571,
        42.323610392976654,
        -6.146644344638571,
        42.323610392976654
      ]
    },
    "24046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.998547272474275,
              42.19512701136615
            ]
          },
          "bbox": [
            -5.998547272474275,
            42.19512701136615,
            -5.998547272474275,
            42.19512701136615
          ]
        }
      ],
      "bbox": [
        -5.998547272474275,
        42.19512701136615,
        -5.998547272474275,
        42.19512701136615
      ]
    },
    "24047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.189734220395414,
              42.20993334365039
            ]
          },
          "bbox": [
            -6.189734220395414,
            42.20993334365039,
            -6.189734220395414,
            42.20993334365039
          ]
        }
      ],
      "bbox": [
        -6.189734220395414,
        42.20993334365039,
        -6.189734220395414,
        42.20993334365039
      ]
    },
    "24049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.442729177571744,
              42.578527280141365
            ]
          },
          "bbox": [
            -6.442729177571744,
            42.578527280141365,
            -6.442729177571744,
            42.578527280141365
          ]
        }
      ],
      "bbox": [
        -6.442729177571744,
        42.578527280141365,
        -6.442729177571744,
        42.578527280141365
      ]
    },
    "24050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.234880060202711,
              42.340886223674545
            ]
          },
          "bbox": [
            -5.234880060202711,
            42.340886223674545,
            -5.234880060202711,
            42.340886223674545
          ]
        }
      ],
      "bbox": [
        -5.234880060202711,
        42.340886223674545,
        -5.234880060202711,
        42.340886223674545
      ]
    },
    "24051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.973835893374447,
              42.52449959756084
            ]
          },
          "bbox": [
            -4.973835893374447,
            42.52449959756084,
            -4.973835893374447,
            42.52449959756084
          ]
        }
      ],
      "bbox": [
        -4.973835893374447,
        42.52449959756084,
        -4.973835893374447,
        42.52449959756084
      ]
    },
    "24052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0654021308862545,
              42.72120039999596
            ]
          },
          "bbox": [
            -5.0654021308862545,
            42.72120039999596,
            -5.0654021308862545,
            42.72120039999596
          ]
        }
      ],
      "bbox": [
        -5.0654021308862545,
        42.72120039999596,
        -5.0654021308862545,
        42.72120039999596
      ]
    },
    "24053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.840191698315924,
              42.257488937763945
            ]
          },
          "bbox": [
            -5.840191698315924,
            42.257488937763945,
            -5.840191698315924,
            42.257488937763945
          ]
        }
      ],
      "bbox": [
        -5.840191698315924,
        42.257488937763945,
        -5.840191698315924,
        42.257488937763945
      ]
    },
    "24054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.605710367867681,
              42.108228383199915
            ]
          },
          "bbox": [
            -5.605710367867681,
            42.108228383199915,
            -5.605710367867681,
            42.108228383199915
          ]
        }
      ],
      "bbox": [
        -5.605710367867681,
        42.108228383199915,
        -5.605710367867681,
        42.108228383199915
      ]
    },
    "24055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.786019014171555,
              42.60440660649447
            ]
          },
          "bbox": [
            -5.786019014171555,
            42.60440660649447,
            -5.786019014171555,
            42.60440660649447
          ]
        }
      ],
      "bbox": [
        -5.786019014171555,
        42.60440660649447,
        -5.786019014171555,
        42.60440660649447
      ]
    },
    "24056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.117673308905073,
              42.797029002875306
            ]
          },
          "bbox": [
            -5.117673308905073,
            42.797029002875306,
            -5.117673308905073,
            42.797029002875306
          ]
        }
      ],
      "bbox": [
        -5.117673308905073,
        42.797029002875306,
        -5.117673308905073,
        42.797029002875306
      ]
    },
    "24057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.5119848632212864,
              42.609153038215226
            ]
          },
          "bbox": [
            -6.5119848632212864,
            42.609153038215226,
            -6.5119848632212864,
            42.609153038215226
          ]
        }
      ],
      "bbox": [
        -6.5119848632212864,
        42.609153038215226,
        -6.5119848632212864,
        42.609153038215226
      ]
    },
    "24058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4542160025068,
              42.4113662581958
            ]
          },
          "bbox": [
            -5.4542160025068,
            42.4113662581958,
            -5.4542160025068,
            42.4113662581958
          ]
        }
      ],
      "bbox": [
        -5.4542160025068,
        42.4113662581958,
        -5.4542160025068,
        42.4113662581958
      ]
    },
    "24059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.855273365386549,
              42.57436764548109
            ]
          },
          "bbox": [
            -6.855273365386549,
            42.57436764548109,
            -6.855273365386549,
            42.57436764548109
          ]
        }
      ],
      "bbox": [
        -6.855273365386549,
        42.57436764548109,
        -6.855273365386549,
        42.57436764548109
      ]
    },
    "24060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.132496837645188,
              42.92317895892189
            ]
          },
          "bbox": [
            -5.132496837645188,
            42.92317895892189,
            -5.132496837645188,
            42.92317895892189
          ]
        }
      ],
      "bbox": [
        -5.132496837645188,
        42.92317895892189,
        -5.132496837645188,
        42.92317895892189
      ]
    },
    "24061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6658974430630575,
              42.722862482570825
            ]
          },
          "bbox": [
            -5.6658974430630575,
            42.722862482570825,
            -5.6658974430630575,
            42.722862482570825
          ]
        }
      ],
      "bbox": [
        -5.6658974430630575,
        42.722862482570825,
        -5.6658974430630575,
        42.722862482570825
      ]
    },
    "24062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.510689800368924,
              42.37849104141116
            ]
          },
          "bbox": [
            -5.510689800368924,
            42.37849104141116,
            -5.510689800368924,
            42.37849104141116
          ]
        }
      ],
      "bbox": [
        -5.510689800368924,
        42.37849104141116,
        -5.510689800368924,
        42.37849104141116
      ]
    },
    "24063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.152085952502979,
              42.665335620906426
            ]
          },
          "bbox": [
            -5.152085952502979,
            42.665335620906426,
            -5.152085952502979,
            42.665335620906426
          ]
        }
      ],
      "bbox": [
        -5.152085952502979,
        42.665335620906426,
        -5.152085952502979,
        42.665335620906426
      ]
    },
    "24064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.573756724576881,
              42.651156346759215
            ]
          },
          "bbox": [
            -6.573756724576881,
            42.651156346759215,
            -6.573756724576881,
            42.651156346759215
          ]
        }
      ],
      "bbox": [
        -6.573756724576881,
        42.651156346759215,
        -6.573756724576881,
        42.651156346759215
      ]
    },
    "24065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.674757723222859,
              42.4871362967561
            ]
          },
          "bbox": [
            -5.674757723222859,
            42.4871362967561,
            -5.674757723222859,
            42.4871362967561
          ]
        }
      ],
      "bbox": [
        -5.674757723222859,
        42.4871362967561,
        -5.674757723222859,
        42.4871362967561
      ]
    },
    "24066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.095911523084407,
              42.32874284922995
            ]
          },
          "bbox": [
            -6.095911523084407,
            42.32874284922995,
            -6.095911523084407,
            42.32874284922995
          ]
        }
      ],
      "bbox": [
        -6.095911523084407,
        42.32874284922995,
        -6.095911523084407,
        42.32874284922995
      ]
    },
    "24067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.641861321630696,
              42.26307087488039
            ]
          },
          "bbox": [
            -6.641861321630696,
            42.26307087488039,
            -6.641861321630696,
            42.26307087488039
          ]
        }
      ],
      "bbox": [
        -6.641861321630696,
        42.26307087488039,
        -6.641861321630696,
        42.26307087488039
      ]
    },
    "24068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.231154674146502,
              42.78738428989144
            ]
          },
          "bbox": [
            -5.231154674146502,
            42.78738428989144,
            -5.231154674146502,
            42.78738428989144
          ]
        }
      ],
      "bbox": [
        -5.231154674146502,
        42.78738428989144,
        -5.231154674146502,
        42.78738428989144
      ]
    },
    "24069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9654311745708,
              42.318788710273544
            ]
          },
          "bbox": [
            -4.9654311745708,
            42.318788710273544,
            -4.9654311745708,
            42.318788710273544
          ]
        }
      ],
      "bbox": [
        -4.9654311745708,
        42.318788710273544,
        -4.9654311745708,
        42.318788710273544
      ]
    },
    "24070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.617854953748443,
              42.779806410286106
            ]
          },
          "bbox": [
            -6.617854953748443,
            42.779806410286106,
            -6.617854953748443,
            42.779806410286106
          ]
        }
      ],
      "bbox": [
        -6.617854953748443,
        42.779806410286106,
        -6.617854953748443,
        42.779806410286106
      ]
    },
    "24071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.327675890824561,
              42.653660390402706
            ]
          },
          "bbox": [
            -6.327675890824561,
            42.653660390402706,
            -6.327675890824561,
            42.653660390402706
          ]
        }
      ],
      "bbox": [
        -6.327675890824561,
        42.653660390402706,
        -6.327675890824561,
        42.653660390402706
      ]
    },
    "24073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.535923829765963,
              42.347516491907314
            ]
          },
          "bbox": [
            -5.535923829765963,
            42.347516491907314,
            -5.535923829765963,
            42.347516491907314
          ]
        }
      ],
      "bbox": [
        -5.535923829765963,
        42.347516491907314,
        -5.535923829765963,
        42.347516491907314
      ]
    },
    "24074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.459174614718764,
              42.17647135901183
            ]
          },
          "bbox": [
            -5.459174614718764,
            42.17647135901183,
            -5.459174614718764,
            42.17647135901183
          ]
        }
      ],
      "bbox": [
        -5.459174614718764,
        42.17647135901183,
        -5.459174614718764,
        42.17647135901183
      ]
    },
    "24076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.533705645272461,
              42.738583244158306
            ]
          },
          "bbox": [
            -5.533705645272461,
            42.738583244158306,
            -5.533705645272461,
            42.738583244158306
          ]
        }
      ],
      "bbox": [
        -5.533705645272461,
        42.738583244158306,
        -5.533705645272461,
        42.738583244158306
      ]
    },
    "24077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.147766704500189,
              42.33996986927669
            ]
          },
          "bbox": [
            -5.147766704500189,
            42.33996986927669,
            -5.147766704500189,
            42.33996986927669
          ]
        }
      ],
      "bbox": [
        -5.147766704500189,
        42.33996986927669,
        -5.147766704500189,
        42.33996986927669
      ]
    },
    "24078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.402807018471305,
              42.13210336607718
            ]
          },
          "bbox": [
            -5.402807018471305,
            42.13210336607718,
            -5.402807018471305,
            42.13210336607718
          ]
        }
      ],
      "bbox": [
        -5.402807018471305,
        42.13210336607718,
        -5.402807018471305,
        42.13210336607718
      ]
    },
    "24079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2767800220455445,
              42.650856410552265
            ]
          },
          "bbox": [
            -5.2767800220455445,
            42.650856410552265,
            -5.2767800220455445,
            42.650856410552265
          ]
        }
      ],
      "bbox": [
        -5.2767800220455445,
        42.650856410552265,
        -5.2767800220455445,
        42.650856410552265
      ]
    },
    "24080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.015698803891388,
              42.31614589442303
            ]
          },
          "bbox": [
            -5.015698803891388,
            42.31614589442303,
            -5.015698803891388,
            42.31614589442303
          ]
        }
      ],
      "bbox": [
        -5.015698803891388,
        42.31614589442303,
        -5.015698803891388,
        42.31614589442303
      ]
    },
    "24081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.422479144925797,
              42.3825206383808
            ]
          },
          "bbox": [
            -5.422479144925797,
            42.3825206383808,
            -5.422479144925797,
            42.3825206383808
          ]
        }
      ],
      "bbox": [
        -5.422479144925797,
        42.3825206383808,
        -5.422479144925797,
        42.3825206383808
      ]
    },
    "24082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8827767492769025,
              42.46308088335315
            ]
          },
          "bbox": [
            -5.8827767492769025,
            42.46308088335315,
            -5.8827767492769025,
            42.46308088335315
          ]
        }
      ],
      "bbox": [
        -5.8827767492769025,
        42.46308088335315,
        -5.8827767492769025,
        42.46308088335315
      ]
    },
    "24083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.26638543289685,
              42.73638951737418
            ]
          },
          "bbox": [
            -6.26638543289685,
            42.73638951737418,
            -6.26638543289685,
            42.73638951737418
          ]
        }
      ],
      "bbox": [
        -6.26638543289685,
        42.73638951737418,
        -6.26638543289685,
        42.73638951737418
      ]
    },
    "24084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.284507924740756,
              42.23964524526121
            ]
          },
          "bbox": [
            -5.284507924740756,
            42.23964524526121,
            -5.284507924740756,
            42.23964524526121
          ]
        }
      ],
      "bbox": [
        -5.284507924740756,
        42.23964524526121,
        -5.284507924740756,
        42.23964524526121
      ]
    },
    "24086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.187995268075035,
              42.294549548075295
            ]
          },
          "bbox": [
            -5.187995268075035,
            42.294549548075295,
            -5.187995268075035,
            42.294549548075295
          ]
        }
      ],
      "bbox": [
        -5.187995268075035,
        42.294549548075295,
        -5.187995268075035,
        42.294549548075295
      ]
    },
    "24087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.759728710143665,
              42.316720021876
            ]
          },
          "bbox": [
            -5.759728710143665,
            42.316720021876,
            -5.759728710143665,
            42.316720021876
          ]
        }
      ],
      "bbox": [
        -5.759728710143665,
        42.316720021876,
        -5.759728710143665,
        42.316720021876
      ]
    },
    "24088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.654707548584508,
              42.234280023557986
            ]
          },
          "bbox": [
            -5.654707548584508,
            42.234280023557986,
            -5.654707548584508,
            42.234280023557986
          ]
        }
      ],
      "bbox": [
        -5.654707548584508,
        42.234280023557986,
        -5.654707548584508,
        42.234280023557986
      ]
    },
    "24089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.576318919854644,
              42.588569057133185
            ]
          },
          "bbox": [
            -5.576318919854644,
            42.588569057133185,
            -5.576318919854644,
            42.588569057133185
          ]
        }
      ],
      "bbox": [
        -5.576318919854644,
        42.588569057133185,
        -5.576318919854644,
        42.588569057133185
      ]
    },
    "24090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.373103134193558,
              42.38776425807522
            ]
          },
          "bbox": [
            -6.373103134193558,
            42.38776425807522,
            -6.373103134193558,
            42.38776425807522
          ]
        }
      ],
      "bbox": [
        -6.373103134193558,
        42.38776425807522,
        -6.373103134193558,
        42.38776425807522
      ]
    },
    "24091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.23624500402239,
              42.33478851206962
            ]
          },
          "bbox": [
            -6.23624500402239,
            42.33478851206962,
            -6.23624500402239,
            42.33478851206962
          ]
        }
      ],
      "bbox": [
        -6.23624500402239,
        42.33478851206962,
        -6.23624500402239,
        42.33478851206962
      ]
    },
    "24092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.870591800849444,
              42.63924679673
            ]
          },
          "bbox": [
            -5.870591800849444,
            42.63924679673,
            -5.870591800849444,
            42.63924679673
          ]
        }
      ],
      "bbox": [
        -5.870591800849444,
        42.63924679673,
        -5.870591800849444,
        42.63924679673
      ]
    },
    "24093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.109259783124335,
              42.56832339575898
            ]
          },
          "bbox": [
            -6.109259783124335,
            42.56832339575898,
            -6.109259783124335,
            42.56832339575898
          ]
        }
      ],
      "bbox": [
        -6.109259783124335,
        42.56832339575898,
        -6.109259783124335,
        42.56832339575898
      ]
    },
    "24094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.374177703609569,
              42.49986782805049
            ]
          },
          "bbox": [
            -5.374177703609569,
            42.49986782805049,
            -5.374177703609569,
            42.49986782805049
          ]
        }
      ],
      "bbox": [
        -5.374177703609569,
        42.49986782805049,
        -5.374177703609569,
        42.49986782805049
      ]
    },
    "24095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.446349029097596,
              42.512337102393325
            ]
          },
          "bbox": [
            -5.446349029097596,
            42.512337102393325,
            -5.446349029097596,
            42.512337102393325
          ]
        }
      ],
      "bbox": [
        -5.446349029097596,
        42.512337102393325,
        -5.446349029097596,
        42.512337102393325
      ]
    },
    "24096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.196251325034364,
              43.05571527111717
            ]
          },
          "bbox": [
            -5.196251325034364,
            43.05571527111717,
            -5.196251325034364,
            43.05571527111717
          ]
        }
      ],
      "bbox": [
        -5.196251325034364,
        43.05571527111717,
        -5.196251325034364,
        43.05571527111717
      ]
    },
    "24097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.373400461046129,
              42.33474055317907
            ]
          },
          "bbox": [
            -5.373400461046129,
            42.33474055317907,
            -5.373400461046129,
            42.33474055317907
          ]
        }
      ],
      "bbox": [
        -5.373400461046129,
        42.33474055317907,
        -5.373400461046129,
        42.33474055317907
      ]
    },
    "24098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.512146345667697,
              42.84776508265557
            ]
          },
          "bbox": [
            -5.512146345667697,
            42.84776508265557,
            -5.512146345667697,
            42.84776508265557
          ]
        }
      ],
      "bbox": [
        -5.512146345667697,
        42.84776508265557,
        -5.512146345667697,
        42.84776508265557
      ]
    },
    "24099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.366622029880585,
              42.265579102119
            ]
          },
          "bbox": [
            -5.366622029880585,
            42.265579102119,
            -5.366622029880585,
            42.265579102119
          ]
        }
      ],
      "bbox": [
        -5.366622029880585,
        42.265579102119,
        -5.366622029880585,
        42.265579102119
      ]
    },
    "24100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.458215630158339,
              42.52590415540269
            ]
          },
          "bbox": [
            -6.458215630158339,
            42.52590415540269,
            -6.458215630158339,
            42.52590415540269
          ]
        }
      ],
      "bbox": [
        -6.458215630158339,
        42.52590415540269,
        -6.458215630158339,
        42.52590415540269
      ]
    },
    "24101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.192710474916132,
              42.834933365356335
            ]
          },
          "bbox": [
            -6.192710474916132,
            42.834933365356335,
            -6.192710474916132,
            42.834933365356335
          ]
        }
      ],
      "bbox": [
        -6.192710474916132,
        42.834933365356335,
        -6.192710474916132,
        42.834933365356335
      ]
    },
    "24102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.400312031045102,
              42.711427211640256
            ]
          },
          "bbox": [
            -6.400312031045102,
            42.711427211640256,
            -6.400312031045102,
            42.711427211640256
          ]
        }
      ],
      "bbox": [
        -6.400312031045102,
        42.711427211640256,
        -6.400312031045102,
        42.711427211640256
      ]
    },
    "24103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.9927919036380315,
              42.54386672351854
            ]
          },
          "bbox": [
            -6.9927919036380315,
            42.54386672351854,
            -6.9927919036380315,
            42.54386672351854
          ]
        }
      ],
      "bbox": [
        -6.9927919036380315,
        42.54386672351854,
        -6.9927919036380315,
        42.54386672351854
      ]
    },
    "24104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.869566380607951,
              42.68903572028184
            ]
          },
          "bbox": [
            -5.869566380607951,
            42.68903572028184,
            -5.869566380607951,
            42.68903572028184
          ]
        }
      ],
      "bbox": [
        -5.869566380607951,
        42.68903572028184,
        -5.869566380607951,
        42.68903572028184
      ]
    },
    "24105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.583510752750857,
              42.51787436043921
            ]
          },
          "bbox": [
            -5.583510752750857,
            42.51787436043921,
            -5.583510752750857,
            42.51787436043921
          ]
        }
      ],
      "bbox": [
        -5.583510752750857,
        42.51787436043921,
        -5.583510752750857,
        42.51787436043921
      ]
    },
    "24106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.042292739403061,
              43.136298140135295
            ]
          },
          "bbox": [
            -5.042292739403061,
            43.136298140135295,
            -5.042292739403061,
            43.136298140135295
          ]
        }
      ],
      "bbox": [
        -5.042292739403061,
        43.136298140135295,
        -5.042292739403061,
        43.136298140135295
      ]
    },
    "24107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.45508160607336,
              42.33487437600986
            ]
          },
          "bbox": [
            -5.45508160607336,
            42.33487437600986,
            -5.45508160607336,
            42.33487437600986
          ]
        }
      ],
      "bbox": [
        -5.45508160607336,
        42.33487437600986,
        -5.45508160607336,
        42.33487437600986
      ]
    },
    "24108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.947736905143189,
              42.31427934966852
            ]
          },
          "bbox": [
            -5.947736905143189,
            42.31427934966852,
            -5.947736905143189,
            42.31427934966852
          ]
        }
      ],
      "bbox": [
        -5.947736905143189,
        42.31427934966852,
        -5.947736905143189,
        42.31427934966852
      ]
    },
    "24109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.406759713812545,
              42.875140225440305
            ]
          },
          "bbox": [
            -6.406759713812545,
            42.875140225440305,
            -6.406759713812545,
            42.875140225440305
          ]
        }
      ],
      "bbox": [
        -6.406759713812545,
        42.875140225440305,
        -6.406759713812545,
        42.875140225440305
      ]
    },
    "24110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.492061022585866,
              42.822932609985266
            ]
          },
          "bbox": [
            -6.492061022585866,
            42.822932609985266,
            -6.492061022585866,
            42.822932609985266
          ]
        }
      ],
      "bbox": [
        -6.492061022585866,
        42.822932609985266,
        -6.492061022585866,
        42.822932609985266
      ]
    },
    "24112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.648958032731036,
              42.87881871319476
            ]
          },
          "bbox": [
            -6.648958032731036,
            42.87881871319476,
            -6.648958032731036,
            42.87881871319476
          ]
        }
      ],
      "bbox": [
        -6.648958032731036,
        42.87881871319476,
        -6.648958032731036,
        42.87881871319476
      ]
    },
    "24113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.666751622187924,
              42.30381385410527
            ]
          },
          "bbox": [
            -5.666751622187924,
            42.30381385410527,
            -5.666751622187924,
            42.30381385410527
          ]
        }
      ],
      "bbox": [
        -5.666751622187924,
        42.30381385410527,
        -5.666751622187924,
        42.30381385410527
      ]
    },
    "24114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6911719947980375,
              42.87407279043529
            ]
          },
          "bbox": [
            -5.6911719947980375,
            42.87407279043529,
            -5.6911719947980375,
            42.87407279043529
          ]
        }
      ],
      "bbox": [
        -5.6911719947980375,
        42.87407279043529,
        -5.6911719947980375,
        42.87407279043529
      ]
    },
    "24115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.564178161812425,
              42.497211502187966
            ]
          },
          "bbox": [
            -6.564178161812425,
            42.497211502187966,
            -6.564178161812425,
            42.497211502187966
          ]
        }
      ],
      "bbox": [
        -6.564178161812425,
        42.497211502187966,
        -6.564178161812425,
        42.497211502187966
      ]
    },
    "24116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.916726911870247,
              43.159119615513575
            ]
          },
          "bbox": [
            -4.916726911870247,
            43.159119615513575,
            -4.916726911870247,
            43.159119615513575
          ]
        }
      ],
      "bbox": [
        -4.916726911870247,
        43.159119615513575,
        -4.916726911870247,
        43.159119615513575
      ]
    },
    "24117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.767064928414974,
              42.163518177112046
            ]
          },
          "bbox": [
            -5.767064928414974,
            42.163518177112046,
            -5.767064928414974,
            42.163518177112046
          ]
        }
      ],
      "bbox": [
        -5.767064928414974,
        42.163518177112046,
        -5.767064928414974,
        42.163518177112046
      ]
    },
    "24118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.032886734791023,
              42.78842845896348
            ]
          },
          "bbox": [
            -5.032886734791023,
            42.78842845896348,
            -5.032886734791023,
            42.78842845896348
          ]
        }
      ],
      "bbox": [
        -5.032886734791023,
        42.78842845896348,
        -5.032886734791023,
        42.78842845896348
      ]
    },
    "24119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.672337361944678,
              42.482302190773936
            ]
          },
          "bbox": [
            -6.672337361944678,
            42.482302190773936,
            -6.672337361944678,
            42.482302190773936
          ]
        }
      ],
      "bbox": [
        -6.672337361944678,
        42.482302190773936,
        -6.672337361944678,
        42.482302190773936
      ]
    },
    "24120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.976638927897761,
              42.89792068387869
            ]
          },
          "bbox": [
            -4.976638927897761,
            42.89792068387869,
            -4.976638927897761,
            42.89792068387869
          ]
        }
      ],
      "bbox": [
        -4.976638927897761,
        42.89792068387869,
        -4.976638927897761,
        42.89792068387869
      ]
    },
    "24121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.290131736814168,
              43.028744497992705
            ]
          },
          "bbox": [
            -5.290131736814168,
            43.028744497992705,
            -5.290131736814168,
            43.028744497992705
          ]
        }
      ],
      "bbox": [
        -5.290131736814168,
        43.028744497992705,
        -5.290131736814168,
        43.028744497992705
      ]
    },
    "24122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.77923609873373,
              42.41890216583327
            ]
          },
          "bbox": [
            -6.77923609873373,
            42.41890216583327,
            -6.77923609873373,
            42.41890216583327
          ]
        }
      ],
      "bbox": [
        -6.77923609873373,
        42.41890216583327,
        -6.77923609873373,
        42.41890216583327
      ]
    },
    "24123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.004698726783457,
              42.65847886049088
            ]
          },
          "bbox": [
            -6.004698726783457,
            42.65847886049088,
            -6.004698726783457,
            42.65847886049088
          ]
        }
      ],
      "bbox": [
        -6.004698726783457,
        42.65847886049088,
        -6.004698726783457,
        42.65847886049088
      ]
    },
    "24124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.861469653718254,
              42.20076990962292
            ]
          },
          "bbox": [
            -5.861469653718254,
            42.20076990962292,
            -5.861469653718254,
            42.20076990962292
          ]
        }
      ],
      "bbox": [
        -5.861469653718254,
        42.20076990962292,
        -5.861469653718254,
        42.20076990962292
      ]
    },
    "24125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.078367272882868,
              42.25776027387143
            ]
          },
          "bbox": [
            -6.078367272882868,
            42.25776027387143,
            -6.078367272882868,
            42.25776027387143
          ]
        }
      ],
      "bbox": [
        -6.078367272882868,
        42.25776027387143,
        -6.078367272882868,
        42.25776027387143
      ]
    },
    "24127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8558150425211055,
              42.29330490202104
            ]
          },
          "bbox": [
            -5.8558150425211055,
            42.29330490202104,
            -5.8558150425211055,
            42.29330490202104
          ]
        }
      ],
      "bbox": [
        -5.8558150425211055,
        42.29330490202104,
        -5.8558150425211055,
        42.29330490202104
      ]
    },
    "24129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.198468066032565,
              42.9504944487179
            ]
          },
          "bbox": [
            -5.198468066032565,
            42.9504944487179,
            -5.198468066032565,
            42.9504944487179
          ]
        }
      ],
      "bbox": [
        -5.198468066032565,
        42.9504944487179,
        -5.198468066032565,
        42.9504944487179
      ]
    },
    "24130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.014284159856689,
              42.97340410526007
            ]
          },
          "bbox": [
            -5.014284159856689,
            42.97340410526007,
            -5.014284159856689,
            42.97340410526007
          ]
        }
      ],
      "bbox": [
        -5.014284159856689,
        42.97340410526007,
        -5.014284159856689,
        42.97340410526007
      ]
    },
    "24131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.977092850855147,
              42.36571037311891
            ]
          },
          "bbox": [
            -5.977092850855147,
            42.36571037311891,
            -5.977092850855147,
            42.36571037311891
          ]
        }
      ],
      "bbox": [
        -5.977092850855147,
        42.36571037311891,
        -5.977092850855147,
        42.36571037311891
      ]
    },
    "24132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.020347452588367,
              42.807011912090736
            ]
          },
          "bbox": [
            -6.020347452588367,
            42.807011912090736,
            -6.020347452588367,
            42.807011912090736
          ]
        }
      ],
      "bbox": [
        -6.020347452588367,
        42.807011912090736,
        -6.020347452588367,
        42.807011912090736
      ]
    },
    "24133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.766442355221057,
              42.70350313483844
            ]
          },
          "bbox": [
            -5.766442355221057,
            42.70350313483844,
            -5.766442355221057,
            42.70350313483844
          ]
        }
      ],
      "bbox": [
        -5.766442355221057,
        42.70350313483844,
        -5.766442355221057,
        42.70350313483844
      ]
    },
    "24134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6180876748754045,
              42.80231675196724
            ]
          },
          "bbox": [
            -5.6180876748754045,
            42.80231675196724,
            -5.6180876748754045,
            42.80231675196724
          ]
        }
      ],
      "bbox": [
        -5.6180876748754045,
        42.80231675196724,
        -5.6180876748754045,
        42.80231675196724
      ]
    },
    "24136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.786102911549226,
              42.225652737721305
            ]
          },
          "bbox": [
            -5.786102911549226,
            42.225652737721305,
            -5.786102911549226,
            42.225652737721305
          ]
        }
      ],
      "bbox": [
        -5.786102911549226,
        42.225652737721305,
        -5.786102911549226,
        42.225652737721305
      ]
    },
    "24137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.167105775769596,
              42.83797292083057
            ]
          },
          "bbox": [
            -5.167105775769596,
            42.83797292083057,
            -5.167105775769596,
            42.83797292083057
          ]
        }
      ],
      "bbox": [
        -5.167105775769596,
        42.83797292083057,
        -5.167105775769596,
        42.83797292083057
      ]
    },
    "24139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.00601981582933,
              42.36911656537162
            ]
          },
          "bbox": [
            -5.00601981582933,
            42.36911656537162,
            -5.00601981582933,
            42.36911656537162
          ]
        }
      ],
      "bbox": [
        -5.00601981582933,
        42.36911656537162,
        -5.00601981582933,
        42.36911656537162
      ]
    },
    "24141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.719397446663873,
              42.12875035550506
            ]
          },
          "bbox": [
            -5.719397446663873,
            42.12875035550506,
            -5.719397446663873,
            42.12875035550506
          ]
        }
      ],
      "bbox": [
        -5.719397446663873,
        42.12875035550506,
        -5.719397446663873,
        42.12875035550506
      ]
    },
    "24142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6757281401813415,
              42.62595621662034
            ]
          },
          "bbox": [
            -5.6757281401813415,
            42.62595621662034,
            -5.6757281401813415,
            42.62595621662034
          ]
        }
      ],
      "bbox": [
        -5.6757281401813415,
        42.62595621662034,
        -5.6757281401813415,
        42.62595621662034
      ]
    },
    "24143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.635424642441766,
              42.67226984969907
            ]
          },
          "bbox": [
            -6.635424642441766,
            42.67226984969907,
            -6.635424642441766,
            42.67226984969907
          ]
        }
      ],
      "bbox": [
        -6.635424642441766,
        42.67226984969907,
        -6.635424642441766,
        42.67226984969907
      ]
    },
    "24144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.912746091194174,
              42.39251737970286
            ]
          },
          "bbox": [
            -5.912746091194174,
            42.39251737970286,
            -5.912746091194174,
            42.39251737970286
          ]
        }
      ],
      "bbox": [
        -5.912746091194174,
        42.39251737970286,
        -5.912746091194174,
        42.39251737970286
      ]
    },
    "24145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.018865877487059,
              42.985439636729055
            ]
          },
          "bbox": [
            -6.018865877487059,
            42.985439636729055,
            -6.018865877487059,
            42.985439636729055
          ]
        }
      ],
      "bbox": [
        -6.018865877487059,
        42.985439636729055,
        -6.018865877487059,
        42.985439636729055
      ]
    },
    "24146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.939390400937482,
              42.15880720856416
            ]
          },
          "bbox": [
            -5.939390400937482,
            42.15880720856416,
            -5.939390400937482,
            42.15880720856416
          ]
        }
      ],
      "bbox": [
        -5.939390400937482,
        42.15880720856416,
        -5.939390400937482,
        42.15880720856416
      ]
    },
    "24148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.006842203555905,
              42.462576726150985
            ]
          },
          "bbox": [
            -6.006842203555905,
            42.462576726150985,
            -6.006842203555905,
            42.462576726150985
          ]
        }
      ],
      "bbox": [
        -6.006842203555905,
        42.462576726150985,
        -6.006842203555905,
        42.462576726150985
      ]
    },
    "24149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.580783253288893,
              42.2902269640969
            ]
          },
          "bbox": [
            -5.580783253288893,
            42.2902269640969,
            -5.580783253288893,
            42.2902269640969
          ]
        }
      ],
      "bbox": [
        -5.580783253288893,
        42.2902269640969,
        -5.580783253288893,
        42.2902269640969
      ]
    },
    "24150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.719845478442171,
              42.41223374675176
            ]
          },
          "bbox": [
            -5.719845478442171,
            42.41223374675176,
            -5.719845478442171,
            42.41223374675176
          ]
        }
      ],
      "bbox": [
        -5.719845478442171,
        42.41223374675176,
        -5.719845478442171,
        42.41223374675176
      ]
    },
    "24151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.414082804477958,
              42.75208479064194
            ]
          },
          "bbox": [
            -5.414082804477958,
            42.75208479064194,
            -5.414082804477958,
            42.75208479064194
          ]
        }
      ],
      "bbox": [
        -5.414082804477958,
        42.75208479064194,
        -5.414082804477958,
        42.75208479064194
      ]
    },
    "24152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.290363321892723,
              42.469317275590775
            ]
          },
          "bbox": [
            -6.290363321892723,
            42.469317275590775,
            -6.290363321892723,
            42.469317275590775
          ]
        }
      ],
      "bbox": [
        -6.290363321892723,
        42.469317275590775,
        -6.290363321892723,
        42.469317275590775
      ]
    },
    "24153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.310067609741311,
              42.36103436315601
            ]
          },
          "bbox": [
            -5.310067609741311,
            42.36103436315601,
            -5.310067609741311,
            42.36103436315601
          ]
        }
      ],
      "bbox": [
        -5.310067609741311,
        42.36103436315601,
        -5.310067609741311,
        42.36103436315601
      ]
    },
    "24154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.911204227165543,
              42.24312709609044
            ]
          },
          "bbox": [
            -5.911204227165543,
            42.24312709609044,
            -5.911204227165543,
            42.24312709609044
          ]
        }
      ],
      "bbox": [
        -5.911204227165543,
        42.24312709609044,
        -5.911204227165543,
        42.24312709609044
      ]
    },
    "24155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.930869127825957,
              42.363381235418935
            ]
          },
          "bbox": [
            -5.930869127825957,
            42.363381235418935,
            -5.930869127825957,
            42.363381235418935
          ]
        }
      ],
      "bbox": [
        -5.930869127825957,
        42.363381235418935,
        -5.930869127825957,
        42.363381235418935
      ]
    },
    "24156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.135701697311541,
              42.49659720286087
            ]
          },
          "bbox": [
            -5.135701697311541,
            42.49659720286087,
            -5.135701697311541,
            42.49659720286087
          ]
        }
      ],
      "bbox": [
        -5.135701697311541,
        42.49659720286087,
        -5.135701697311541,
        42.49659720286087
      ]
    },
    "24157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.747917836229688,
              42.357112510030355
            ]
          },
          "bbox": [
            -5.747917836229688,
            42.357112510030355,
            -5.747917836229688,
            42.357112510030355
          ]
        }
      ],
      "bbox": [
        -5.747917836229688,
        42.357112510030355,
        -5.747917836229688,
        42.357112510030355
      ]
    },
    "24158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.836535824902658,
              42.738275037728194
            ]
          },
          "bbox": [
            -5.836535824902658,
            42.738275037728194,
            -5.836535824902658,
            42.738275037728194
          ]
        }
      ],
      "bbox": [
        -5.836535824902658,
        42.738275037728194,
        -5.836535824902658,
        42.738275037728194
      ]
    },
    "24159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.841999229026499,
              42.492780683009016
            ]
          },
          "bbox": [
            -5.841999229026499,
            42.492780683009016,
            -5.841999229026499,
            42.492780683009016
          ]
        }
      ],
      "bbox": [
        -5.841999229026499,
        42.492780683009016,
        -5.841999229026499,
        42.492780683009016
      ]
    },
    "24160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3449763379220006,
              42.45318445191611
            ]
          },
          "bbox": [
            -5.3449763379220006,
            42.45318445191611,
            -5.3449763379220006,
            42.45318445191611
          ]
        }
      ],
      "bbox": [
        -5.3449763379220006,
        42.45318445191611,
        -5.3449763379220006,
        42.45318445191611
      ]
    },
    "24161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1105219403002184,
              42.4021710640046
            ]
          },
          "bbox": [
            -6.1105219403002184,
            42.4021710640046,
            -6.1105219403002184,
            42.4021710640046
          ]
        }
      ],
      "bbox": [
        -6.1105219403002184,
        42.4021710640046,
        -6.1105219403002184,
        42.4021710640046
      ]
    },
    "24162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6178652420468405,
              42.54055615420762
            ]
          },
          "bbox": [
            -5.6178652420468405,
            42.54055615420762,
            -5.6178652420468405,
            42.54055615420762
          ]
        }
      ],
      "bbox": [
        -5.6178652420468405,
        42.54055615420762,
        -5.6178652420468405,
        42.54055615420762
      ]
    },
    "24163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.62911170947217,
              42.6583183173268
            ]
          },
          "bbox": [
            -5.62911170947217,
            42.6583183173268,
            -5.62911170947217,
            42.6583183173268
          ]
        }
      ],
      "bbox": [
        -5.62911170947217,
        42.6583183173268,
        -5.62911170947217,
        42.6583183173268
      ]
    },
    "24164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.922424670292767,
              42.91819513951747
            ]
          },
          "bbox": [
            -5.922424670292767,
            42.91819513951747,
            -5.922424670292767,
            42.91819513951747
          ]
        }
      ],
      "bbox": [
        -5.922424670292767,
        42.91819513951747,
        -5.922424670292767,
        42.91819513951747
      ]
    },
    "24165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.871587368559994,
              42.52021549665238
            ]
          },
          "bbox": [
            -6.871587368559994,
            42.52021549665238,
            -6.871587368559994,
            42.52021549665238
          ]
        }
      ],
      "bbox": [
        -6.871587368559994,
        42.52021549665238,
        -6.871587368559994,
        42.52021549665238
      ]
    },
    "24166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.894679373441405,
              42.33638929777415
            ]
          },
          "bbox": [
            -5.894679373441405,
            42.33638929777415,
            -5.894679373441405,
            42.33638929777415
          ]
        }
      ],
      "bbox": [
        -5.894679373441405,
        42.33638929777415,
        -5.894679373441405,
        42.33638929777415
      ]
    },
    "24167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.87568434505249,
              42.77955002520597
            ]
          },
          "bbox": [
            -5.87568434505249,
            42.77955002520597,
            -5.87568434505249,
            42.77955002520597
          ]
        }
      ],
      "bbox": [
        -5.87568434505249,
        42.77955002520597,
        -5.87568434505249,
        42.77955002520597
      ]
    },
    "24168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.570234483866677,
              42.24148841343332
            ]
          },
          "bbox": [
            -5.570234483866677,
            42.24148841343332,
            -5.570234483866677,
            42.24148841343332
          ]
        }
      ],
      "bbox": [
        -5.570234483866677,
        42.24148841343332,
        -5.570234483866677,
        42.24148841343332
      ]
    },
    "24169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.504027273834687,
              42.71582450853715
            ]
          },
          "bbox": [
            -6.504027273834687,
            42.71582450853715,
            -6.504027273834687,
            42.71582450853715
          ]
        }
      ],
      "bbox": [
        -6.504027273834687,
        42.71582450853715,
        -6.504027273834687,
        42.71582450853715
      ]
    },
    "24170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.322921366999051,
              42.576656456212625
            ]
          },
          "bbox": [
            -6.322921366999051,
            42.576656456212625,
            -6.322921366999051,
            42.576656456212625
          ]
        }
      ],
      "bbox": [
        -6.322921366999051,
        42.576656456212625,
        -6.322921366999051,
        42.576656456212625
      ]
    },
    "24171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.874324565577853,
              42.64728863811907
            ]
          },
          "bbox": [
            -6.874324565577853,
            42.64728863811907,
            -6.874324565577853,
            42.64728863811907
          ]
        }
      ],
      "bbox": [
        -6.874324565577853,
        42.64728863811907,
        -6.874324565577853,
        42.64728863811907
      ]
    },
    "24172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.415866767343314,
              42.26704940106271
            ]
          },
          "bbox": [
            -6.415866767343314,
            42.26704940106271,
            -6.415866767343314,
            42.26704940106271
          ]
        }
      ],
      "bbox": [
        -6.415866767343314,
        42.26704940106271,
        -6.415866767343314,
        42.26704940106271
      ]
    },
    "24173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.88515738266142,
              42.551197105378165
            ]
          },
          "bbox": [
            -5.88515738266142,
            42.551197105378165,
            -5.88515738266142,
            42.551197105378165
          ]
        }
      ],
      "bbox": [
        -5.88515738266142,
        42.551197105378165,
        -5.88515738266142,
        42.551197105378165
      ]
    },
    "24174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.778744520457644,
              42.378450325062026
            ]
          },
          "bbox": [
            -5.778744520457644,
            42.378450325062026,
            -5.778744520457644,
            42.378450325062026
          ]
        }
      ],
      "bbox": [
        -5.778744520457644,
        42.378450325062026,
        -5.778744520457644,
        42.378450325062026
      ]
    },
    "24175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.474590610357368,
              42.61038682760942
            ]
          },
          "bbox": [
            -5.474590610357368,
            42.61038682760942,
            -5.474590610357368,
            42.61038682760942
          ]
        }
      ],
      "bbox": [
        -5.474590610357368,
        42.61038682760942,
        -5.474590610357368,
        42.61038682760942
      ]
    },
    "24176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.823734112679086,
              42.31096090042796
            ]
          },
          "bbox": [
            -5.823734112679086,
            42.31096090042796,
            -5.823734112679086,
            42.31096090042796
          ]
        }
      ],
      "bbox": [
        -5.823734112679086,
        42.31096090042796,
        -5.823734112679086,
        42.31096090042796
      ]
    },
    "24177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.421651470235644,
              42.98131803062354
            ]
          },
          "bbox": [
            -5.421651470235644,
            42.98131803062354,
            -5.421651470235644,
            42.98131803062354
          ]
        }
      ],
      "bbox": [
        -5.421651470235644,
        42.98131803062354,
        -5.421651470235644,
        42.98131803062354
      ]
    },
    "24178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.424124064390765,
              42.189540460581824
            ]
          },
          "bbox": [
            -5.424124064390765,
            42.189540460581824,
            -5.424124064390765,
            42.189540460581824
          ]
        }
      ],
      "bbox": [
        -5.424124064390765,
        42.189540460581824,
        -5.424124064390765,
        42.189540460581824
      ]
    },
    "24179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.422130220377713,
              42.88194745595017
            ]
          },
          "bbox": [
            -5.422130220377713,
            42.88194745595017,
            -5.422130220377713,
            42.88194745595017
          ]
        }
      ],
      "bbox": [
        -5.422130220377713,
        42.88194745595017,
        -5.422130220377713,
        42.88194745595017
      ]
    },
    "24180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.225879382824319,
              42.55427295452037
            ]
          },
          "bbox": [
            -5.225879382824319,
            42.55427295452037,
            -5.225879382824319,
            42.55427295452037
          ]
        }
      ],
      "bbox": [
        -5.225879382824319,
        42.55427295452037,
        -5.225879382824319,
        42.55427295452037
      ]
    },
    "24181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.444704360854189,
              42.08631263932471
            ]
          },
          "bbox": [
            -5.444704360854189,
            42.08631263932471,
            -5.444704360854189,
            42.08631263932471
          ]
        }
      ],
      "bbox": [
        -5.444704360854189,
        42.08631263932471,
        -5.444704360854189,
        42.08631263932471
      ]
    },
    "24182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.029468397820727,
              42.39198415467765
            ]
          },
          "bbox": [
            -6.029468397820727,
            42.39198415467765,
            -6.029468397820727,
            42.39198415467765
          ]
        }
      ],
      "bbox": [
        -6.029468397820727,
        42.39198415467765,
        -6.029468397820727,
        42.39198415467765
      ]
    },
    "24183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.973987101664818,
              42.82567035920663
            ]
          },
          "bbox": [
            -4.973987101664818,
            42.82567035920663,
            -4.973987101664818,
            42.82567035920663
          ]
        }
      ],
      "bbox": [
        -4.973987101664818,
        42.82567035920663,
        -4.973987101664818,
        42.82567035920663
      ]
    },
    "24184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0230971752246045,
              42.72831502728245
            ]
          },
          "bbox": [
            -6.0230971752246045,
            42.72831502728245,
            -6.0230971752246045,
            42.72831502728245
          ]
        }
      ],
      "bbox": [
        -6.0230971752246045,
        42.72831502728245,
        -6.0230971752246045,
        42.72831502728245
      ]
    },
    "24185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.152554353848926,
              42.406742477001806
            ]
          },
          "bbox": [
            -6.152554353848926,
            42.406742477001806,
            -6.152554353848926,
            42.406742477001806
          ]
        }
      ],
      "bbox": [
        -6.152554353848926,
        42.406742477001806,
        -6.152554353848926,
        42.406742477001806
      ]
    },
    "24187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.648470557847709,
              42.411716865556954
            ]
          },
          "bbox": [
            -5.648470557847709,
            42.411716865556954,
            -5.648470557847709,
            42.411716865556954
          ]
        }
      ],
      "bbox": [
        -5.648470557847709,
        42.411716865556954,
        -5.648470557847709,
        42.411716865556954
      ]
    },
    "24188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.500894339527245,
              42.283212694044124
            ]
          },
          "bbox": [
            -5.500894339527245,
            42.283212694044124,
            -5.500894339527245,
            42.283212694044124
          ]
        }
      ],
      "bbox": [
        -5.500894339527245,
        42.283212694044124,
        -5.500894339527245,
        42.283212694044124
      ]
    },
    "24189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.693304411900405,
              42.572232354648925
            ]
          },
          "bbox": [
            -5.693304411900405,
            42.572232354648925,
            -5.693304411900405,
            42.572232354648925
          ]
        }
      ],
      "bbox": [
        -5.693304411900405,
        42.572232354648925,
        -5.693304411900405,
        42.572232354648925
      ]
    },
    "24190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2866894384864915,
              42.30669101395947
            ]
          },
          "bbox": [
            -5.2866894384864915,
            42.30669101395947,
            -5.2866894384864915,
            42.30669101395947
          ]
        }
      ],
      "bbox": [
        -5.2866894384864915,
        42.30669101395947,
        -5.2866894384864915,
        42.30669101395947
      ]
    },
    "24191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.208246963603032,
              42.34870883338108
            ]
          },
          "bbox": [
            -5.208246963603032,
            42.34870883338108,
            -5.208246963603032,
            42.34870883338108
          ]
        }
      ],
      "bbox": [
        -5.208246963603032,
        42.34870883338108,
        -5.208246963603032,
        42.34870883338108
      ]
    },
    "24193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.40707744052433,
              42.823426975587225
            ]
          },
          "bbox": [
            -5.40707744052433,
            42.823426975587225,
            -5.40707744052433,
            42.823426975587225
          ]
        }
      ],
      "bbox": [
        -5.40707744052433,
        42.823426975587225,
        -5.40707744052433,
        42.823426975587225
      ]
    },
    "24194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.567197184135346,
              42.89645213836945
            ]
          },
          "bbox": [
            -5.567197184135346,
            42.89645213836945,
            -5.567197184135346,
            42.89645213836945
          ]
        }
      ],
      "bbox": [
        -5.567197184135346,
        42.89645213836945,
        -5.567197184135346,
        42.89645213836945
      ]
    },
    "24196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.732036625650658,
              42.754376602018205
            ]
          },
          "bbox": [
            -6.732036625650658,
            42.754376602018205,
            -6.732036625650658,
            42.754376602018205
          ]
        }
      ],
      "bbox": [
        -6.732036625650658,
        42.754376602018205,
        -6.732036625650658,
        42.754376602018205
      ]
    },
    "24197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5466626521533104,
              42.48876478282755
            ]
          },
          "bbox": [
            -5.5466626521533104,
            42.48876478282755,
            -5.5466626521533104,
            42.48876478282755
          ]
        }
      ],
      "bbox": [
        -5.5466626521533104,
        42.48876478282755,
        -5.5466626521533104,
        42.48876478282755
      ]
    },
    "24198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.977059352557354,
              42.67860623236505
            ]
          },
          "bbox": [
            -6.977059352557354,
            42.67860623236505,
            -6.977059352557354,
            42.67860623236505
          ]
        }
      ],
      "bbox": [
        -6.977059352557354,
        42.67860623236505,
        -6.977059352557354,
        42.67860623236505
      ]
    },
    "24199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.329293205539452,
              42.798206378319335
            ]
          },
          "bbox": [
            -5.329293205539452,
            42.798206378319335,
            -5.329293205539452,
            42.798206378319335
          ]
        }
      ],
      "bbox": [
        -5.329293205539452,
        42.798206378319335,
        -5.329293205539452,
        42.798206378319335
      ]
    },
    "24201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.385664924882552,
              42.67384538293702
            ]
          },
          "bbox": [
            -5.385664924882552,
            42.67384538293702,
            -5.385664924882552,
            42.67384538293702
          ]
        }
      ],
      "bbox": [
        -5.385664924882552,
        42.67384538293702,
        -5.385664924882552,
        42.67384538293702
      ]
    },
    "24202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.329388455450384,
              42.963378489991
            ]
          },
          "bbox": [
            -6.329388455450384,
            42.963378489991,
            -6.329388455450384,
            42.963378489991
          ]
        }
      ],
      "bbox": [
        -6.329388455450384,
        42.963378489991,
        -6.329388455450384,
        42.963378489991
      ]
    },
    "24203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.445147145962545,
              42.25477411897544
            ]
          },
          "bbox": [
            -5.445147145962545,
            42.25477411897544,
            -5.445147145962545,
            42.25477411897544
          ]
        }
      ],
      "bbox": [
        -5.445147145962545,
        42.25477411897544,
        -5.445147145962545,
        42.25477411897544
      ]
    },
    "24205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.771999660610046,
              42.520171164295114
            ]
          },
          "bbox": [
            -5.771999660610046,
            42.520171164295114,
            -5.771999660610046,
            42.520171164295114
          ]
        }
      ],
      "bbox": [
        -5.771999660610046,
        42.520171164295114,
        -5.771999660610046,
        42.520171164295114
      ]
    },
    "24206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.775448504694048,
              42.55394926532789
            ]
          },
          "bbox": [
            -6.775448504694048,
            42.55394926532789,
            -6.775448504694048,
            42.55394926532789
          ]
        }
      ],
      "bbox": [
        -6.775448504694048,
        42.55394926532789,
        -6.775448504694048,
        42.55394926532789
      ]
    },
    "24207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.582558123533479,
              42.26766737451578
            ]
          },
          "bbox": [
            -5.582558123533479,
            42.26766737451578,
            -5.582558123533479,
            42.26766737451578
          ]
        }
      ],
      "bbox": [
        -5.582558123533479,
        42.26766737451578,
        -5.582558123533479,
        42.26766737451578
      ]
    },
    "24209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.808455555021296,
              42.69943446476217
            ]
          },
          "bbox": [
            -6.808455555021296,
            42.69943446476217,
            -6.808455555021296,
            42.69943446476217
          ]
        }
      ],
      "bbox": [
        -6.808455555021296,
        42.69943446476217,
        -6.808455555021296,
        42.69943446476217
      ]
    },
    "24210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.177532841118523,
              42.63509841212899
            ]
          },
          "bbox": [
            -6.177532841118523,
            42.63509841212899,
            -6.177532841118523,
            42.63509841212899
          ]
        }
      ],
      "bbox": [
        -6.177532841118523,
        42.63509841212899,
        -6.177532841118523,
        42.63509841212899
      ]
    },
    "24211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.59141281493488,
              42.1827314672651
            ]
          },
          "bbox": [
            -5.59141281493488,
            42.1827314672651,
            -5.59141281493488,
            42.1827314672651
          ]
        }
      ],
      "bbox": [
        -5.59141281493488,
        42.1827314672651,
        -5.59141281493488,
        42.1827314672651
      ]
    },
    "24212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.600256606105908,
              42.33977039278724
            ]
          },
          "bbox": [
            -5.600256606105908,
            42.33977039278724,
            -5.600256606105908,
            42.33977039278724
          ]
        }
      ],
      "bbox": [
        -5.600256606105908,
        42.33977039278724,
        -5.600256606105908,
        42.33977039278724
      ]
    },
    "24213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.091400919677128,
              42.58395076240204
            ]
          },
          "bbox": [
            -5.091400919677128,
            42.58395076240204,
            -5.091400919677128,
            42.58395076240204
          ]
        }
      ],
      "bbox": [
        -5.091400919677128,
        42.58395076240204,
        -5.091400919677128,
        42.58395076240204
      ]
    },
    "24214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.01959618428044,
              42.577722135056355
            ]
          },
          "bbox": [
            -6.01959618428044,
            42.577722135056355,
            -6.01959618428044,
            42.577722135056355
          ]
        }
      ],
      "bbox": [
        -6.01959618428044,
        42.577722135056355,
        -6.01959618428044,
        42.577722135056355
      ]
    },
    "24215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.052374180912898,
              42.450016300964
            ]
          },
          "bbox": [
            -5.052374180912898,
            42.450016300964,
            -5.052374180912898,
            42.450016300964
          ]
        }
      ],
      "bbox": [
        -5.052374180912898,
        42.450016300964,
        -5.052374180912898,
        42.450016300964
      ]
    },
    "24216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.018933950963622,
              42.30552527819341
            ]
          },
          "bbox": [
            -6.018933950963622,
            42.30552527819341,
            -6.018933950963622,
            42.30552527819341
          ]
        }
      ],
      "bbox": [
        -6.018933950963622,
        42.30552527819341,
        -6.018933950963622,
        42.30552527819341
      ]
    },
    "24217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.307796186355307,
              42.40423939773373
            ]
          },
          "bbox": [
            -5.307796186355307,
            42.40423939773373,
            -5.307796186355307,
            42.40423939773373
          ]
        }
      ],
      "bbox": [
        -5.307796186355307,
        42.40423939773373,
        -5.307796186355307,
        42.40423939773373
      ]
    },
    "24218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.471820753711122,
              42.463371823644316
            ]
          },
          "bbox": [
            -5.471820753711122,
            42.463371823644316,
            -5.471820753711122,
            42.463371823644316
          ]
        }
      ],
      "bbox": [
        -5.471820753711122,
        42.463371823644316,
        -5.471820753711122,
        42.463371823644316
      ]
    },
    "24219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.055559506569009,
              42.50251515801714
            ]
          },
          "bbox": [
            -6.055559506569009,
            42.50251515801714,
            -6.055559506569009,
            42.50251515801714
          ]
        }
      ],
      "bbox": [
        -6.055559506569009,
        42.50251515801714,
        -6.055559506569009,
        42.50251515801714
      ]
    },
    "24221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.578539663731504,
              42.121570837628276
            ]
          },
          "bbox": [
            -5.578539663731504,
            42.121570837628276,
            -5.578539663731504,
            42.121570837628276
          ]
        }
      ],
      "bbox": [
        -5.578539663731504,
        42.121570837628276,
        -5.578539663731504,
        42.121570837628276
      ]
    },
    "24222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.545497125489722,
              42.65018628022191
            ]
          },
          "bbox": [
            -5.545497125489722,
            42.65018628022191,
            -5.545497125489722,
            42.65018628022191
          ]
        }
      ],
      "bbox": [
        -5.545497125489722,
        42.65018628022191,
        -5.545497125489722,
        42.65018628022191
      ]
    },
    "24223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.91807661194227,
              42.43250883001449
            ]
          },
          "bbox": [
            -5.91807661194227,
            42.43250883001449,
            -5.91807661194227,
            42.43250883001449
          ]
        }
      ],
      "bbox": [
        -5.91807661194227,
        42.43250883001449,
        -5.91807661194227,
        42.43250883001449
      ]
    },
    "24224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.928672724224139,
              42.47258296628602
            ]
          },
          "bbox": [
            -5.928672724224139,
            42.47258296628602,
            -5.928672724224139,
            42.47258296628602
          ]
        }
      ],
      "bbox": [
        -5.928672724224139,
        42.47258296628602,
        -5.928672724224139,
        42.47258296628602
      ]
    },
    "24225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.390942744457423,
              42.546712618948355
            ]
          },
          "bbox": [
            -5.390942744457423,
            42.546712618948355,
            -5.390942744457423,
            42.546712618948355
          ]
        }
      ],
      "bbox": [
        -5.390942744457423,
        42.546712618948355,
        -5.390942744457423,
        42.546712618948355
      ]
    },
    "24226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.032018171253895,
              42.566559231429615
            ]
          },
          "bbox": [
            -5.032018171253895,
            42.566559231429615,
            -5.032018171253895,
            42.566559231429615
          ]
        }
      ],
      "bbox": [
        -5.032018171253895,
        42.566559231429615,
        -5.032018171253895,
        42.566559231429615
      ]
    },
    "24227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.505099081941518,
              42.523114293223195
            ]
          },
          "bbox": [
            -5.505099081941518,
            42.523114293223195,
            -5.505099081941518,
            42.523114293223195
          ]
        }
      ],
      "bbox": [
        -5.505099081941518,
        42.523114293223195,
        -5.505099081941518,
        42.523114293223195
      ]
    },
    "24228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.850258724389051,
              42.37351755449436
            ]
          },
          "bbox": [
            -5.850258724389051,
            42.37351755449436,
            -5.850258724389051,
            42.37351755449436
          ]
        }
      ],
      "bbox": [
        -5.850258724389051,
        42.37351755449436,
        -5.850258724389051,
        42.37351755449436
      ]
    },
    "24229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.94568852980897,
              42.56460544856228
            ]
          },
          "bbox": [
            -4.94568852980897,
            42.56460544856228,
            -4.94568852980897,
            42.56460544856228
          ]
        }
      ],
      "bbox": [
        -4.94568852980897,
        42.56460544856228,
        -4.94568852980897,
        42.56460544856228
      ]
    },
    "24230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.731114561527722,
              42.264449851426996
            ]
          },
          "bbox": [
            -5.731114561527722,
            42.264449851426996,
            -5.731114561527722,
            42.264449851426996
          ]
        }
      ],
      "bbox": [
        -5.731114561527722,
        42.264449851426996,
        -5.731114561527722,
        42.264449851426996
      ]
    },
    "24901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.706180760196972,
              42.97119493730754
            ]
          },
          "bbox": [
            -5.706180760196972,
            42.97119493730754,
            -5.706180760196972,
            42.97119493730754
          ]
        }
      ],
      "bbox": [
        -5.706180760196972,
        42.97119493730754,
        -5.706180760196972,
        42.97119493730754
      ]
    },
    "24902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.530270264003861,
              42.188641472394444
            ]
          },
          "bbox": [
            -5.530270264003861,
            42.188641472394444,
            -5.530270264003861,
            42.188641472394444
          ]
        }
      ],
      "bbox": [
        -5.530270264003861,
        42.188641472394444,
        -5.530270264003861,
        42.188641472394444
      ]
    },
    "25001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1180276688133166,
              42.17572309445243
            ]
          },
          "bbox": [
            1.1180276688133166,
            42.17572309445243,
            1.1180276688133166,
            42.17572309445243
          ]
        }
      ],
      "bbox": [
        1.1180276688133166,
        42.17572309445243,
        1.1180276688133166,
        42.17572309445243
      ]
    },
    "25002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7390482232186592,
              42.00063113622275
            ]
          },
          "bbox": [
            0.7390482232186592,
            42.00063113622275,
            0.7390482232186592,
            42.00063113622275
          ]
        }
      ],
      "bbox": [
        0.7390482232186592,
        42.00063113622275,
        0.7390482232186592,
        42.00063113622275
      ]
    },
    "25003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0683179201450657,
              41.78755569803575
            ]
          },
          "bbox": [
            1.0683179201450657,
            41.78755569803575,
            1.0683179201450657,
            41.78755569803575
          ]
        }
      ],
      "bbox": [
        1.0683179201450657,
        41.78755569803575,
        1.0683179201450657,
        41.78755569803575
      ]
    },
    "25004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.758752623180119,
              41.604901083535175
            ]
          },
          "bbox": [
            0.758752623180119,
            41.604901083535175,
            0.758752623180119,
            41.604901083535175
          ]
        }
      ],
      "bbox": [
        0.758752623180119,
        41.604901083535175,
        0.758752623180119,
        41.604901083535175
      ]
    },
    "25005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5205359956211355,
              42.32244768875251
            ]
          },
          "bbox": [
            1.5205359956211355,
            42.32244768875251,
            1.5205359956211355,
            42.32244768875251
          ]
        }
      ],
      "bbox": [
        1.5205359956211355,
        42.32244768875251,
        1.5205359956211355,
        42.32244768875251
      ]
    },
    "25007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6174809866885904,
              41.56627293892222
            ]
          },
          "bbox": [
            0.6174809866885904,
            41.56627293892222,
            0.6174809866885904,
            41.56627293892222
          ]
        }
      ],
      "bbox": [
        0.6174809866885904,
        41.56627293892222,
        0.6174809866885904,
        41.56627293892222
      ]
    },
    "25008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6706343767077866,
              41.75936257446595
            ]
          },
          "bbox": [
            0.6706343767077866,
            41.75936257446595,
            0.6706343767077866,
            41.75936257446595
          ]
        }
      ],
      "bbox": [
        0.6706343767077866,
        41.75936257446595,
        0.6706343767077866,
        41.75936257446595
      ]
    },
    "25010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6258067753718644,
              41.46969724389325
            ]
          },
          "bbox": [
            0.6258067753718644,
            41.46969724389325,
            0.6258067753718644,
            41.46969724389325
          ]
        }
      ],
      "bbox": [
        0.6258067753718644,
        41.46969724389325,
        0.6258067753718644,
        41.46969724389325
      ]
    },
    "25011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4659174793511317,
              41.60923980862301
            ]
          },
          "bbox": [
            0.4659174793511317,
            41.60923980862301,
            0.4659174793511317,
            41.60923980862301
          ]
        }
      ],
      "bbox": [
        0.4659174793511317,
        41.60923980862301,
        0.4659174793511317,
        41.60923980862301
      ]
    },
    "25012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6995428993757019,
              41.64870981873326
            ]
          },
          "bbox": [
            0.6995428993757019,
            41.64870981873326,
            0.6995428993757019,
            41.64870981873326
          ]
        }
      ],
      "bbox": [
        0.6995428993757019,
        41.64870981873326,
        0.6995428993757019,
        41.64870981873326
      ]
    },
    "25013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5659725794052861,
              41.83016613513276
            ]
          },
          "bbox": [
            0.5659725794052861,
            41.83016613513276,
            0.5659725794052861,
            41.83016613513276
          ]
        }
      ],
      "bbox": [
        0.5659725794052861,
        41.83016613513276,
        0.5659725794052861,
        41.83016613513276
      ]
    },
    "25014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6345996646575144,
              41.51401818607262
            ]
          },
          "bbox": [
            0.6345996646575144,
            41.51401818607262,
            0.6345996646575144,
            41.51401818607262
          ]
        }
      ],
      "bbox": [
        0.6345996646575144,
        41.51401818607262,
        0.6345996646575144,
        41.51401818607262
      ]
    },
    "25015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.635074093253329,
              41.82342701657923
            ]
          },
          "bbox": [
            0.635074093253329,
            41.82342701657923,
            0.635074093253329,
            41.82342701657923
          ]
        }
      ],
      "bbox": [
        0.635074093253329,
        41.82342701657923,
        0.635074093253329,
        41.82342701657923
      ]
    },
    "25016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5617773602406033,
              41.738967551996105
            ]
          },
          "bbox": [
            0.5617773602406033,
            41.738967551996105,
            0.5617773602406033,
            41.738967551996105
          ]
        }
      ],
      "bbox": [
        0.5617773602406033,
        41.738967551996105,
        0.5617773602406033,
        41.738967551996105
      ]
    },
    "25017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3517755058513403,
              42.579325719019934
            ]
          },
          "bbox": [
            1.3517755058513403,
            42.579325719019934,
            1.3517755058513403,
            42.579325719019934
          ]
        }
      ],
      "bbox": [
        1.3517755058513403,
        42.579325719019934,
        1.3517755058513403,
        42.579325719019934
      ]
    },
    "25019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4575017676051973,
              41.73139115727825
            ]
          },
          "bbox": [
            0.4575017676051973,
            41.73139115727825,
            0.4575017676051973,
            41.73139115727825
          ]
        }
      ],
      "bbox": [
        0.4575017676051973,
        41.73139115727825,
        0.4575017676051973,
        41.73139115727825
      ]
    },
    "25020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4067988642264337,
              41.32108711614913
            ]
          },
          "bbox": [
            0.4067988642264337,
            41.32108711614913,
            0.4067988642264337,
            41.32108711614913
          ]
        }
      ],
      "bbox": [
        0.4067988642264337,
        41.32108711614913,
        0.4067988642264337,
        41.32108711614913
      ]
    },
    "25021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5391288815229586,
              41.78438842646102
            ]
          },
          "bbox": [
            0.5391288815229586,
            41.78438842646102,
            0.5391288815229586,
            41.78438842646102
          ]
        }
      ],
      "bbox": [
        0.5391288815229586,
        41.78438842646102,
        0.5391288815229586,
        41.78438842646102
      ]
    },
    "25022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9447789642019845,
              41.922609795772296
            ]
          },
          "bbox": [
            0.9447789642019845,
            41.922609795772296,
            0.9447789642019845,
            41.922609795772296
          ]
        }
      ],
      "bbox": [
        0.9447789642019845,
        41.922609795772296,
        0.9447789642019845,
        41.922609795772296
      ]
    },
    "25023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5552669972529726,
              41.6701929799848
            ]
          },
          "bbox": [
            0.5552669972529726,
            41.6701929799848,
            0.5552669972529726,
            41.6701929799848
          ]
        }
      ],
      "bbox": [
        0.5552669972529726,
        41.6701929799848,
        0.5552669972529726,
        41.6701929799848
      ]
    },
    "25024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.060624121766617,
              42.68283030665842
            ]
          },
          "bbox": [
            1.060624121766617,
            42.68283030665842,
            1.060624121766617,
            42.68283030665842
          ]
        }
      ],
      "bbox": [
        1.060624121766617,
        42.68283030665842,
        1.060624121766617,
        42.68283030665842
      ]
    },
    "25025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.924233595479963,
              42.701045651414844
            ]
          },
          "bbox": [
            0.924233595479963,
            42.701045651414844,
            0.924233595479963,
            42.701045651414844
          ]
        }
      ],
      "bbox": [
        0.924233595479963,
        42.701045651414844,
        0.924233595479963,
        42.701045651414844
      ]
    },
    "25027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0587161662553217,
              41.662573170077124
            ]
          },
          "bbox": [
            1.0587161662553217,
            41.662573170077124,
            1.0587161662553217,
            41.662573170077124
          ]
        }
      ],
      "bbox": [
        1.0587161662553217,
        41.662573170077124,
        1.0587161662553217,
        41.662573170077124
      ]
    },
    "25029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9367298763615102,
              41.54443754628974
            ]
          },
          "bbox": [
            0.9367298763615102,
            41.54443754628974,
            0.9367298763615102,
            41.54443754628974
          ]
        }
      ],
      "bbox": [
        0.9367298763615102,
        41.54443754628974,
        0.9367298763615102,
        41.54443754628974
      ]
    },
    "25030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6139951625665496,
              42.37947732205061
            ]
          },
          "bbox": [
            1.6139951625665496,
            42.37947732205061,
            1.6139951625665496,
            42.37947732205061
          ]
        }
      ],
      "bbox": [
        1.6139951625665496,
        42.37947732205061,
        1.6139951625665496,
        42.37947732205061
      ]
    },
    "25031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7079290641640773,
              42.76055099869615
            ]
          },
          "bbox": [
            0.7079290641640773,
            42.76055099869615,
            0.7079290641640773,
            42.76055099869615
          ]
        }
      ],
      "bbox": [
        0.7079290641640773,
        42.76055099869615,
        0.7079290641640773,
        42.76055099869615
      ]
    },
    "25032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5847688273303306,
              42.3516466087892
            ]
          },
          "bbox": [
            1.5847688273303306,
            42.3516466087892,
            1.5847688273303306,
            42.3516466087892
          ]
        }
      ],
      "bbox": [
        1.5847688273303306,
        42.3516466087892,
        1.5847688273303306,
        42.3516466087892
      ]
    },
    "25033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6981006067965705,
              41.54383460472344
            ]
          },
          "bbox": [
            0.6981006067965705,
            41.54383460472344,
            0.6981006067965705,
            41.54383460472344
          ]
        }
      ],
      "bbox": [
        0.6981006067965705,
        41.54383460472344,
        0.6981006067965705,
        41.54383460472344
      ]
    },
    "25034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.081326774481439,
              41.93615657389036
            ]
          },
          "bbox": [
            1.081326774481439,
            41.93615657389036,
            1.081326774481439,
            41.93615657389036
          ]
        }
      ],
      "bbox": [
        1.081326774481439,
        41.93615657389036,
        1.081326774481439,
        41.93615657389036
      ]
    },
    "25035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8782128008508184,
              41.80361866619451
            ]
          },
          "bbox": [
            0.8782128008508184,
            41.80361866619451,
            0.8782128008508184,
            41.80361866619451
          ]
        }
      ],
      "bbox": [
        0.8782128008508184,
        41.80361866619451,
        0.8782128008508184,
        41.80361866619451
      ]
    },
    "25036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6751226385878865,
              41.49623830908075
            ]
          },
          "bbox": [
            0.6751226385878865,
            41.49623830908075,
            0.6751226385878865,
            41.49623830908075
          ]
        }
      ],
      "bbox": [
        0.6751226385878865,
        41.49623830908075,
        0.6751226385878865,
        41.49623830908075
      ]
    },
    "25037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7843564372108063,
              41.91893073648669
            ]
          },
          "bbox": [
            0.7843564372108063,
            41.91893073648669,
            0.7843564372108063,
            41.91893073648669
          ]
        }
      ],
      "bbox": [
        0.7843564372108063,
        41.91893073648669,
        0.7843564372108063,
        41.91893073648669
      ]
    },
    "25038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.47174116610503475,
              41.47493549589435
            ]
          },
          "bbox": [
            0.47174116610503475,
            41.47493549589435,
            0.47174116610503475,
            41.47493549589435
          ]
        }
      ],
      "bbox": [
        0.47174116610503475,
        41.47493549589435,
        0.47174116610503475,
        41.47493549589435
      ]
    },
    "25039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0607942223593312,
              42.32148790278046
            ]
          },
          "bbox": [
            1.0607942223593312,
            42.32148790278046,
            1.0607942223593312,
            42.32148790278046
          ]
        }
      ],
      "bbox": [
        1.0607942223593312,
        42.32148790278046,
        1.0607942223593312,
        42.32148790278046
      ]
    },
    "25040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7920274767531987,
              41.783103818522115
            ]
          },
          "bbox": [
            0.7920274767531987,
            41.783103818522115,
            0.7920274767531987,
            41.783103818522115
          ]
        }
      ],
      "bbox": [
        0.7920274767531987,
        41.783103818522115,
        0.7920274767531987,
        41.783103818522115
      ]
    },
    "25041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0457439882373347,
              41.68823243310663
            ]
          },
          "bbox": [
            1.0457439882373347,
            41.68823243310663,
            1.0457439882373347,
            41.68823243310663
          ]
        }
      ],
      "bbox": [
        1.0457439882373347,
        41.68823243310663,
        1.0457439882373347,
        41.68823243310663
      ]
    },
    "25042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1812814542896917,
              42.02759046162007
            ]
          },
          "bbox": [
            1.1812814542896917,
            42.02759046162007,
            1.1812814542896917,
            42.02759046162007
          ]
        }
      ],
      "bbox": [
        1.1812814542896917,
        42.02759046162007,
        1.1812814542896917,
        42.02759046162007
      ]
    },
    "25043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.856911644488696,
              42.53509382325775
            ]
          },
          "bbox": [
            0.856911644488696,
            42.53509382325775,
            0.856911644488696,
            42.53509382325775
          ]
        }
      ],
      "bbox": [
        0.856911644488696,
        42.53509382325775,
        0.856911644488696,
        42.53509382325775
      ]
    },
    "25044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3026695094320269,
              42.01066395385157
            ]
          },
          "bbox": [
            1.3026695094320269,
            42.01066395385157,
            1.3026695094320269,
            42.01066395385157
          ]
        }
      ],
      "bbox": [
        1.3026695094320269,
        42.01066395385157,
        1.3026695094320269,
        42.01066395385157
      ]
    },
    "25045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7002575915222976,
              42.84146528219779
            ]
          },
          "bbox": [
            0.7002575915222976,
            42.84146528219779,
            0.7002575915222976,
            42.84146528219779
          ]
        }
      ],
      "bbox": [
        0.7002575915222976,
        42.84146528219779,
        0.7002575915222976,
        42.84146528219779
      ]
    },
    "25046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9927424848913167,
              41.57023223366058
            ]
          },
          "bbox": [
            0.9927424848913167,
            41.57023223366058,
            0.9927424848913167,
            41.57023223366058
          ]
        }
      ],
      "bbox": [
        0.9927424848913167,
        41.57023223366058,
        0.9927424848913167,
        41.57023223366058
      ]
    },
    "25050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0062064255021796,
              41.629710422735315
            ]
          },
          "bbox": [
            1.0062064255021796,
            41.629710422735315,
            1.0062064255021796,
            41.629710422735315
          ]
        }
      ],
      "bbox": [
        1.0062064255021796,
        41.629710422735315,
        1.0062064255021796,
        41.629710422735315
      ]
    },
    "25051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7687341450901486,
              42.365942628428
            ]
          },
          "bbox": [
            1.7687341450901486,
            42.365942628428,
            1.7687341450901486,
            42.365942628428
          ]
        }
      ],
      "bbox": [
        1.7687341450901486,
        42.365942628428,
        1.7687341450901486,
        42.365942628428
      ]
    },
    "25052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.816792368210994,
              41.68126753948541
            ]
          },
          "bbox": [
            0.816792368210994,
            41.68126753948541,
            0.816792368210994,
            41.68126753948541
          ]
        }
      ],
      "bbox": [
        0.816792368210994,
        41.68126753948541,
        0.816792368210994,
        41.68126753948541
      ]
    },
    "25053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6438592771969115,
              41.69300432118965
            ]
          },
          "bbox": [
            0.6438592771969115,
            41.69300432118965,
            0.6438592771969115,
            41.69300432118965
          ]
        }
      ],
      "bbox": [
        0.6438592771969115,
        41.69300432118965,
        0.6438592771969115,
        41.69300432118965
      ]
    },
    "25055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3735223242120929,
              41.87119010932328
            ]
          },
          "bbox": [
            1.3735223242120929,
            41.87119010932328,
            1.3735223242120929,
            41.87119010932328
          ]
        }
      ],
      "bbox": [
        1.3735223242120929,
        41.87119010932328,
        1.3735223242120929,
        41.87119010932328
      ]
    },
    "25056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6384007999980706,
              41.317270227930024
            ]
          },
          "bbox": [
            0.6384007999980706,
            41.317270227930024,
            0.6384007999980706,
            41.317270227930024
          ]
        }
      ],
      "bbox": [
        0.6384007999980706,
        41.317270227930024,
        0.6384007999980706,
        41.317270227930024
      ]
    },
    "25057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7039860375139032,
              42.71903295397416
            ]
          },
          "bbox": [
            0.7039860375139032,
            42.71903295397416,
            0.7039860375139032,
            42.71903295397416
          ]
        }
      ],
      "bbox": [
        0.7039860375139032,
        42.71903295397416,
        0.7039860375139032,
        42.71903295397416
      ]
    },
    "25058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8658222322138914,
              41.49855064036017
            ]
          },
          "bbox": [
            0.8658222322138914,
            41.49855064036017,
            0.8658222322138914,
            41.49855064036017
          ]
        }
      ],
      "bbox": [
        0.8658222322138914,
        41.49855064036017,
        0.8658222322138914,
        41.49855064036017
      ]
    },
    "25059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6889771467146515,
              42.77811643003529
            ]
          },
          "bbox": [
            0.6889771467146515,
            42.77811643003529,
            0.6889771467146515,
            42.77811643003529
          ]
        }
      ],
      "bbox": [
        0.6889771467146515,
        42.77811643003529,
        0.6889771467146515,
        42.77811643003529
      ]
    },
    "25060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2050151562270495,
              41.83790469988557
            ]
          },
          "bbox": [
            1.2050151562270495,
            41.83790469988557,
            1.2050151562270495,
            41.83790469988557
          ]
        }
      ],
      "bbox": [
        1.2050151562270495,
        41.83790469988557,
        1.2050151562270495,
        41.83790469988557
      ]
    },
    "25061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.234930128428115,
              42.23944559607362
            ]
          },
          "bbox": [
            1.234930128428115,
            42.23944559607362,
            1.234930128428115,
            42.23944559607362
          ]
        }
      ],
      "bbox": [
        1.234930128428115,
        42.23944559607362,
        1.234930128428115,
        42.23944559607362
      ]
    },
    "25062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8674621329632962,
              41.91390111981049
            ]
          },
          "bbox": [
            0.8674621329632962,
            41.91390111981049,
            0.8674621329632962,
            41.91390111981049
          ]
        }
      ],
      "bbox": [
        0.8674621329632962,
        41.91390111981049,
        0.8674621329632962,
        41.91390111981049
      ]
    },
    "25063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7963480096422481,
              42.81626633010207
            ]
          },
          "bbox": [
            0.7963480096422481,
            42.81626633010207,
            0.7963480096422481,
            42.81626633010207
          ]
        }
      ],
      "bbox": [
        0.7963480096422481,
        42.81626633010207,
        0.7963480096422481,
        42.81626633010207
      ]
    },
    "25064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.40411992828885,
              42.01230730226609
            ]
          },
          "bbox": [
            1.40411992828885,
            42.01230730226609,
            1.40411992828885,
            42.01230730226609
          ]
        }
      ],
      "bbox": [
        1.40411992828885,
        42.01230730226609,
        1.40411992828885,
        42.01230730226609
      ]
    },
    "25067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7610302202453796,
              41.492640054996876
            ]
          },
          "bbox": [
            0.7610302202453796,
            41.492640054996876,
            0.7610302202453796,
            41.492640054996876
          ]
        }
      ],
      "bbox": [
        0.7610302202453796,
        41.492640054996876,
        0.7610302202453796,
        41.492640054996876
      ]
    },
    "25068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9711418873838493,
              41.652458944965915
            ]
          },
          "bbox": [
            0.9711418873838493,
            41.652458944965915,
            0.9711418873838493,
            41.652458944965915
          ]
        }
      ],
      "bbox": [
        0.9711418873838493,
        41.652458944965915,
        0.9711418873838493,
        41.652458944965915
      ]
    },
    "25069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.717436947484817,
              41.81383536861009
            ]
          },
          "bbox": [
            0.717436947484817,
            41.81383536861009,
            0.717436947484817,
            41.81383536861009
          ]
        }
      ],
      "bbox": [
        0.717436947484817,
        41.81383536861009,
        0.717436947484817,
        41.81383536861009
      ]
    },
    "25070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9895689409905993,
              41.75083025005492
            ]
          },
          "bbox": [
            0.9895689409905993,
            41.75083025005492,
            0.9895689409905993,
            41.75083025005492
          ]
        }
      ],
      "bbox": [
        0.9895689409905993,
        41.75083025005492,
        0.9895689409905993,
        41.75083025005492
      ]
    },
    "25071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6136275464094516,
              42.315500167258456
            ]
          },
          "bbox": [
            1.6136275464094516,
            42.315500167258456,
            1.6136275464094516,
            42.315500167258456
          ]
        }
      ],
      "bbox": [
        1.6136275464094516,
        42.315500167258456,
        1.6136275464094516,
        42.315500167258456
      ]
    },
    "25072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2819489299347215,
              41.679876189860046
            ]
          },
          "bbox": [
            1.2819489299347215,
            41.679876189860046,
            1.2819489299347215,
            41.679876189860046
          ]
        }
      ],
      "bbox": [
        1.2819489299347215,
        41.679876189860046,
        1.2819489299347215,
        41.679876189860046
      ]
    },
    "25073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8410384808928831,
              41.436189375902686
            ]
          },
          "bbox": [
            0.8410384808928831,
            41.436189375902686,
            0.8410384808928831,
            41.436189375902686
          ]
        }
      ],
      "bbox": [
        0.8410384808928831,
        41.436189375902686,
        0.8410384808928831,
        41.436189375902686
      ]
    },
    "25074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.145508467271833,
              41.543058539844694
            ]
          },
          "bbox": [
            1.145508467271833,
            41.543058539844694,
            1.145508467271833,
            41.543058539844694
          ]
        }
      ],
      "bbox": [
        1.145508467271833,
        41.543058539844694,
        1.145508467271833,
        41.543058539844694
      ]
    },
    "25075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5989389100532119,
              41.947289236691475
            ]
          },
          "bbox": [
            1.5989389100532119,
            41.947289236691475,
            1.5989389100532119,
            41.947289236691475
          ]
        }
      ],
      "bbox": [
        1.5989389100532119,
        41.947289236691475,
        1.5989389100532119,
        41.947289236691475
      ]
    },
    "25076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.693073953049949,
              41.45722278353226
            ]
          },
          "bbox": [
            0.693073953049949,
            41.45722278353226,
            0.693073953049949,
            41.45722278353226
          ]
        }
      ],
      "bbox": [
        0.693073953049949,
        41.45722278353226,
        0.693073953049949,
        41.45722278353226
      ]
    },
    "25077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2418569460478273,
              42.15757146204098
            ]
          },
          "bbox": [
            1.2418569460478273,
            42.15757146204098,
            1.2418569460478273,
            42.15757146204098
          ]
        }
      ],
      "bbox": [
        1.2418569460478273,
        42.15757146204098,
        1.2418569460478273,
        42.15757146204098
      ]
    },
    "25078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6796435245912598,
              41.686542763739666
            ]
          },
          "bbox": [
            0.6796435245912598,
            41.686542763739666,
            0.6796435245912598,
            41.686542763739666
          ]
        }
      ],
      "bbox": [
        0.6796435245912598,
        41.686542763739666,
        0.6796435245912598,
        41.686542763739666
      ]
    },
    "25079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.961212033869992,
              41.838025348152016
            ]
          },
          "bbox": [
            0.961212033869992,
            41.838025348152016,
            0.961212033869992,
            41.838025348152016
          ]
        }
      ],
      "bbox": [
        0.961212033869992,
        41.838025348152016,
        0.961212033869992,
        41.838025348152016
      ]
    },
    "25082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0441993812250765,
              42.565847887620926
            ]
          },
          "bbox": [
            1.0441993812250765,
            42.565847887620926,
            1.0441993812250765,
            42.565847887620926
          ]
        }
      ],
      "bbox": [
        1.0441993812250765,
        42.565847887620926,
        1.0441993812250765,
        42.565847887620926
      ]
    },
    "25085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3810991132023627,
              41.703666603597405
            ]
          },
          "bbox": [
            1.3810991132023627,
            41.703666603597405,
            1.3810991132023627,
            41.703666603597405
          ]
        }
      ],
      "bbox": [
        1.3810991132023627,
        41.703666603597405,
        1.3810991132023627,
        41.703666603597405
      ]
    },
    "25087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.268707760006593,
              42.5879121347015
            ]
          },
          "bbox": [
            1.268707760006593,
            42.5879121347015,
            1.268707760006593,
            42.5879121347015
          ]
        }
      ],
      "bbox": [
        1.268707760006593,
        42.5879121347015,
        1.268707760006593,
        42.5879121347015
      ]
    },
    "25088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5253750878834686,
              42.38176991909691
            ]
          },
          "bbox": [
            1.5253750878834686,
            42.38176991909691,
            1.5253750878834686,
            42.38176991909691
          ]
        }
      ],
      "bbox": [
        1.5253750878834686,
        42.38176991909691,
        1.5253750878834686,
        42.38176991909691
      ]
    },
    "25089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.296418693266171,
              42.48317487387807
            ]
          },
          "bbox": [
            1.296418693266171,
            42.48317487387807,
            1.296418693266171,
            42.48317487387807
          ]
        }
      ],
      "bbox": [
        1.296418693266171,
        42.48317487387807,
        1.296418693266171,
        42.48317487387807
      ]
    },
    "25092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9243967026200868,
              41.49679345327348
            ]
          },
          "bbox": [
            0.9243967026200868,
            41.49679345327348,
            0.9243967026200868,
            41.49679345327348
          ]
        }
      ],
      "bbox": [
        0.9243967026200868,
        41.49679345327348,
        0.9243967026200868,
        41.49679345327348
      ]
    },
    "25093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.866672242782912,
              41.62911939237336
            ]
          },
          "bbox": [
            0.866672242782912,
            41.62911939237336,
            0.866672242782912,
            41.62911939237336
          ]
        }
      ],
      "bbox": [
        0.866672242782912,
        41.62911939237336,
        0.866672242782912,
        41.62911939237336
      ]
    },
    "25094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0115201290256226,
              41.87342695695809
            ]
          },
          "bbox": [
            1.0115201290256226,
            41.87342695695809,
            1.0115201290256226,
            41.87342695695809
          ]
        }
      ],
      "bbox": [
        1.0115201290256226,
        41.87342695695809,
        1.0115201290256226,
        41.87342695695809
      ]
    },
    "25096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0015246297719012,
              41.716484110376605
            ]
          },
          "bbox": [
            1.0015246297719012,
            41.716484110376605,
            1.0015246297719012,
            41.716484110376605
          ]
        }
      ],
      "bbox": [
        1.0015246297719012,
        41.716484110376605,
        1.0015246297719012,
        41.716484110376605
      ]
    },
    "25097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0343548289270557,
              41.461023234937514
            ]
          },
          "bbox": [
            1.0343548289270557,
            41.461023234937514,
            1.0343548289270557,
            41.461023234937514
          ]
        }
      ],
      "bbox": [
        1.0343548289270557,
        41.461023234937514,
        1.0343548289270557,
        41.461023234937514
      ]
    },
    "25098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9896617649812854,
              42.072789783733235
            ]
          },
          "bbox": [
            0.9896617649812854,
            42.072789783733235,
            0.9896617649812854,
            42.072789783733235
          ]
        }
      ],
      "bbox": [
        0.9896617649812854,
        42.072789783733235,
        0.9896617649812854,
        42.072789783733235
      ]
    },
    "25099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9266817362567428,
              41.62484265454488
            ]
          },
          "bbox": [
            0.9266817362567428,
            41.62484265454488,
            0.9266817362567428,
            41.62484265454488
          ]
        }
      ],
      "bbox": [
        0.9266817362567428,
        41.62484265454488,
        0.9266817362567428,
        41.62484265454488
      ]
    },
    "25100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6688639852541363,
              42.20858860859552
            ]
          },
          "bbox": [
            1.6688639852541363,
            42.20858860859552,
            1.6688639852541363,
            42.20858860859552
          ]
        }
      ],
      "bbox": [
        1.6688639852541363,
        42.20858860859552,
        1.6688639852541363,
        42.20858860859552
      ]
    },
    "25101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6504354509706819,
              41.3658051673173
            ]
          },
          "bbox": [
            0.6504354509706819,
            41.3658051673173,
            0.6504354509706819,
            41.3658051673173
          ]
        }
      ],
      "bbox": [
        0.6504354509706819,
        41.3658051673173,
        0.6504354509706819,
        41.3658051673173
      ]
    },
    "25103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2141280120800877,
              41.655457828679225
            ]
          },
          "bbox": [
            1.2141280120800877,
            41.655457828679225,
            1.2141280120800877,
            41.655457828679225
          ]
        }
      ],
      "bbox": [
        1.2141280120800877,
        41.655457828679225,
        1.2141280120800877,
        41.655457828679225
      ]
    },
    "25104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2206704758326448,
              41.62011221405116
            ]
          },
          "bbox": [
            1.2206704758326448,
            41.62011221405116,
            1.2206704758326448,
            41.62011221405116
          ]
        }
      ],
      "bbox": [
        1.2206704758326448,
        41.62011221405116,
        1.2206704758326448,
        41.62011221405116
      ]
    },
    "25105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6534538218747603,
              41.43490404825956
            ]
          },
          "bbox": [
            0.6534538218747603,
            41.43490404825956,
            0.6534538218747603,
            41.43490404825956
          ]
        }
      ],
      "bbox": [
        0.6534538218747603,
        41.43490404825956,
        0.6534538218747603,
        41.43490404825956
      ]
    },
    "25109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.185252421839414,
              41.565565830146944
            ]
          },
          "bbox": [
            1.185252421839414,
            41.565565830146944,
            1.185252421839414,
            41.565565830146944
          ]
        }
      ],
      "bbox": [
        1.185252421839414,
        41.565565830146944,
        1.185252421839414,
        41.565565830146944
      ]
    },
    "25110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2873975568656952,
              41.78912886833535
            ]
          },
          "bbox": [
            1.2873975568656952,
            41.78912886833535,
            1.2873975568656952,
            41.78912886833535
          ]
        }
      ],
      "bbox": [
        1.2873975568656952,
        41.78912886833535,
        1.2873975568656952,
        41.78912886833535
      ]
    },
    "25111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6415101901433151,
              42.13960432700819
            ]
          },
          "bbox": [
            1.6415101901433151,
            42.13960432700819,
            1.6415101901433151,
            42.13960432700819
          ]
        }
      ],
      "bbox": [
        1.6415101901433151,
        42.13960432700819,
        1.6415101901433151,
        42.13960432700819
      ]
    },
    "25112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.609631081408532,
              41.868094188233755
            ]
          },
          "bbox": [
            0.609631081408532,
            41.868094188233755,
            0.609631081408532,
            41.868094188233755
          ]
        }
      ],
      "bbox": [
        0.609631081408532,
        41.868094188233755,
        0.609631081408532,
        41.868094188233755
      ]
    },
    "25114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.39036124867658,
              41.76948450631581
            ]
          },
          "bbox": [
            1.39036124867658,
            41.76948450631581,
            1.39036124867658,
            41.76948450631581
          ]
        }
      ],
      "bbox": [
        1.39036124867658,
        41.76948450631581,
        1.39036124867658,
        41.76948450631581
      ]
    },
    "25115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0380674143606825,
              42.12679567351201
            ]
          },
          "bbox": [
            1.0380674143606825,
            42.12679567351201,
            1.0380674143606825,
            42.12679567351201
          ]
        }
      ],
      "bbox": [
        1.0380674143606825,
        42.12679567351201,
        1.0380674143606825,
        42.12679567351201
      ]
    },
    "25118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7864754741068791,
              41.38033114582267
            ]
          },
          "bbox": [
            0.7864754741068791,
            41.38033114582267,
            0.7864754741068791,
            41.38033114582267
          ]
        }
      ],
      "bbox": [
        0.7864754741068791,
        41.38033114582267,
        0.7864754741068791,
        41.38033114582267
      ]
    },
    "25119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8236962714895285,
              41.534278700438904
            ]
          },
          "bbox": [
            0.8236962714895285,
            41.534278700438904,
            0.8236962714895285,
            41.534278700438904
          ]
        }
      ],
      "bbox": [
        0.8236962714895285,
        41.534278700438904,
        0.8236962714895285,
        41.534278700438904
      ]
    },
    "25120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5812247108697237,
              41.6313293991274
            ]
          },
          "bbox": [
            0.5812247108697237,
            41.6313293991274,
            0.5812247108697237,
            41.6313293991274
          ]
        }
      ],
      "bbox": [
        0.5812247108697237,
        41.6313293991274,
        0.5812247108697237,
        41.6313293991274
      ]
    },
    "25121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7133336274390375,
              42.80939858100892
            ]
          },
          "bbox": [
            0.7133336274390375,
            42.80939858100892,
            0.7133336274390375,
            42.80939858100892
          ]
        }
      ],
      "bbox": [
        0.7133336274390375,
        42.80939858100892,
        0.7133336274390375,
        42.80939858100892
      ]
    },
    "25122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8902143396483643,
              41.70763396953745
            ]
          },
          "bbox": [
            0.8902143396483643,
            41.70763396953745,
            0.8902143396483643,
            41.70763396953745
          ]
        }
      ],
      "bbox": [
        0.8902143396483643,
        41.70763396953745,
        0.8902143396483643,
        41.70763396953745
      ]
    },
    "25123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2770740713375668,
              42.673010061373304
            ]
          },
          "bbox": [
            1.2770740713375668,
            42.673010061373304,
            1.2770740713375668,
            42.673010061373304
          ]
        }
      ],
      "bbox": [
        1.2770740713375668,
        42.673010061373304,
        1.2770740713375668,
        42.673010061373304
      ]
    },
    "25124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4768495473432868,
              42.06287450652154
            ]
          },
          "bbox": [
            1.4768495473432868,
            42.06287450652154,
            1.4768495473432868,
            42.06287450652154
          ]
        }
      ],
      "bbox": [
        1.4768495473432868,
        42.06287450652154,
        1.4768495473432868,
        42.06287450652154
      ]
    },
    "25125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.538496979178742,
              41.38712188818377
            ]
          },
          "bbox": [
            0.538496979178742,
            41.38712188818377,
            0.538496979178742,
            41.38712188818377
          ]
        }
      ],
      "bbox": [
        0.538496979178742,
        41.38712188818377,
        0.538496979178742,
        41.38712188818377
      ]
    },
    "25126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.19039858284433,
              42.48730175392953
            ]
          },
          "bbox": [
            1.19039858284433,
            42.48730175392953,
            1.19039858284433,
            42.48730175392953
          ]
        }
      ],
      "bbox": [
        1.19039858284433,
        42.48730175392953,
        1.19039858284433,
        42.48730175392953
      ]
    },
    "25127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.667380340299366,
              42.434881034242416
            ]
          },
          "bbox": [
            1.667380340299366,
            42.434881034242416,
            1.667380340299366,
            42.434881034242416
          ]
        }
      ],
      "bbox": [
        1.667380340299366,
        42.434881034242416,
        1.667380340299366,
        42.434881034242416
      ]
    },
    "25128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9285598313895007,
              42.05861010727894
            ]
          },
          "bbox": [
            0.9285598313895007,
            42.05861010727894,
            0.9285598313895007,
            42.05861010727894
          ]
        }
      ],
      "bbox": [
        0.9285598313895007,
        42.05861010727894,
        0.9285598313895007,
        42.05861010727894
      ]
    },
    "25129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4621204207811362,
              41.92344406361043
            ]
          },
          "bbox": [
            1.4621204207811362,
            41.92344406361043,
            1.4621204207811362,
            41.92344406361043
          ]
        }
      ],
      "bbox": [
        1.4621204207811362,
        41.92344406361043,
        1.4621204207811362,
        41.92344406361043
      ]
    },
    "25130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0238366028303383,
              41.53168123878073
            ]
          },
          "bbox": [
            1.0238366028303383,
            41.53168123878073,
            1.0238366028303383,
            41.53168123878073
          ]
        }
      ],
      "bbox": [
        1.0238366028303383,
        41.53168123878073,
        1.0238366028303383,
        41.53168123878073
      ]
    },
    "25131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.35955894915223946,
              41.45835194480586
            ]
          },
          "bbox": [
            0.35955894915223946,
            41.45835194480586,
            0.35955894915223946,
            41.45835194480586
          ]
        }
      ],
      "bbox": [
        0.35955894915223946,
        41.45835194480586,
        0.35955894915223946,
        41.45835194480586
      ]
    },
    "25132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.332402069269251,
              41.80076146559251
            ]
          },
          "bbox": [
            1.332402069269251,
            41.80076146559251,
            1.332402069269251,
            41.80076146559251
          ]
        }
      ],
      "bbox": [
        1.332402069269251,
        41.80076146559251,
        1.332402069269251,
        41.80076146559251
      ]
    },
    "25133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5064906447202073,
              41.343260030362195
            ]
          },
          "bbox": [
            0.5064906447202073,
            41.343260030362195,
            0.5064906447202073,
            41.343260030362195
          ]
        }
      ],
      "bbox": [
        0.5064906447202073,
        41.343260030362195,
        0.5064906447202073,
        41.343260030362195
      ]
    },
    "25134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7301032432437516,
              41.73824982087457
            ]
          },
          "bbox": [
            0.7301032432437516,
            41.73824982087457,
            0.7301032432437516,
            41.73824982087457
          ]
        }
      ],
      "bbox": [
        0.7301032432437516,
        41.73824982087457,
        0.7301032432437516,
        41.73824982087457
      ]
    },
    "25135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8952697182065872,
              41.59822023217227
            ]
          },
          "bbox": [
            0.8952697182065872,
            41.59822023217227,
            0.8952697182065872,
            41.59822023217227
          ]
        }
      ],
      "bbox": [
        0.8952697182065872,
        41.59822023217227,
        0.8952697182065872,
        41.59822023217227
      ]
    },
    "25136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5420376937027471,
              41.78400351124643
            ]
          },
          "bbox": [
            1.5420376937027471,
            41.78400351124643,
            1.5420376937027471,
            41.78400351124643
          ]
        }
      ],
      "bbox": [
        1.5420376937027471,
        41.78400351124643,
        1.5420376937027471,
        41.78400351124643
      ]
    },
    "25137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.885173300847958,
              41.6236667594031
            ]
          },
          "bbox": [
            0.885173300847958,
            41.6236667594031,
            0.885173300847958,
            41.6236667594031
          ]
        }
      ],
      "bbox": [
        0.885173300847958,
        41.6236667594031,
        0.885173300847958,
        41.6236667594031
      ]
    },
    "25138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9713934034962401,
              41.79657737445007
            ]
          },
          "bbox": [
            0.9713934034962401,
            41.79657737445007,
            0.9713934034962401,
            41.79657737445007
          ]
        }
      ],
      "bbox": [
        0.9713934034962401,
        41.79657737445007,
        0.9713934034962401,
        41.79657737445007
      ]
    },
    "25139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6929438986328706,
              42.32168453230033
            ]
          },
          "bbox": [
            1.6929438986328706,
            42.32168453230033,
            1.6929438986328706,
            42.32168453230033
          ]
        }
      ],
      "bbox": [
        1.6929438986328706,
        42.32168453230033,
        1.6929438986328706,
        42.32168453230033
      ]
    },
    "25140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.328739873287325,
              42.38525350869067
            ]
          },
          "bbox": [
            1.328739873287325,
            42.38525350869067,
            1.328739873287325,
            42.38525350869067
          ]
        }
      ],
      "bbox": [
        1.328739873287325,
        42.38525350869067,
        1.328739873287325,
        42.38525350869067
      ]
    },
    "25141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2704932598702738,
              41.58777663453877
            ]
          },
          "bbox": [
            1.2704932598702738,
            41.58777663453877,
            1.2704932598702738,
            41.58777663453877
          ]
        }
      ],
      "bbox": [
        1.2704932598702738,
        41.58777663453877,
        1.2704932598702738,
        41.58777663453877
      ]
    },
    "25142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5981681209418903,
              41.55387335971661
            ]
          },
          "bbox": [
            0.5981681209418903,
            41.55387335971661,
            0.5981681209418903,
            41.55387335971661
          ]
        }
      ],
      "bbox": [
        0.5981681209418903,
        41.55387335971661,
        0.5981681209418903,
        41.55387335971661
      ]
    },
    "25143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2124681385685274,
              41.59928531712508
            ]
          },
          "bbox": [
            1.2124681385685274,
            41.59928531712508,
            1.2124681385685274,
            41.59928531712508
          ]
        }
      ],
      "bbox": [
        1.2124681385685274,
        41.59928531712508,
        1.2124681385685274,
        41.59928531712508
      ]
    },
    "25145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1177568884662135,
              41.54664659671539
            ]
          },
          "bbox": [
            1.1177568884662135,
            41.54664659671539,
            1.1177568884662135,
            41.54664659671539
          ]
        }
      ],
      "bbox": [
        1.1177568884662135,
        41.54664659671539,
        1.1177568884662135,
        41.54664659671539
      ]
    },
    "25146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6348211604790301,
              42.04738350621616
            ]
          },
          "bbox": [
            1.6348211604790301,
            42.04738350621616,
            1.6348211604790301,
            42.04738350621616
          ]
        }
      ],
      "bbox": [
        1.6348211604790301,
        42.04738350621616,
        1.6348211604790301,
        42.04738350621616
      ]
    },
    "25148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4390698158375483,
              42.12282767877332
            ]
          },
          "bbox": [
            1.4390698158375483,
            42.12282767877332,
            1.4390698158375483,
            42.12282767877332
          ]
        }
      ],
      "bbox": [
        1.4390698158375483,
        42.12282767877332,
        1.4390698158375483,
        42.12282767877332
      ]
    },
    "25149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3229634830841783,
              42.09477807693667
            ]
          },
          "bbox": [
            1.3229634830841783,
            42.09477807693667,
            1.3229634830841783,
            42.09477807693667
          ]
        }
      ],
      "bbox": [
        1.3229634830841783,
        42.09477807693667,
        1.3229634830841783,
        42.09477807693667
      ]
    },
    "25150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1731071580958798,
              41.85716973283675
            ]
          },
          "bbox": [
            1.1731071580958798,
            41.85716973283675,
            1.1731071580958798,
            41.85716973283675
          ]
        }
      ],
      "bbox": [
        1.1731071580958798,
        41.85716973283675,
        1.1731071580958798,
        41.85716973283675
      ]
    },
    "25151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.530582242194394,
              41.99252527501828
            ]
          },
          "bbox": [
            1.530582242194394,
            41.99252527501828,
            1.530582242194394,
            41.99252527501828
          ]
        }
      ],
      "bbox": [
        1.530582242194394,
        41.99252527501828,
        1.530582242194394,
        41.99252527501828
      ]
    },
    "25152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3395559980059066,
              41.69303673740915
            ]
          },
          "bbox": [
            1.3395559980059066,
            41.69303673740915,
            1.3395559980059066,
            41.69303673740915
          ]
        }
      ],
      "bbox": [
        1.3395559980059066,
        41.69303673740915,
        1.3395559980059066,
        41.69303673740915
      ]
    },
    "25153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9602811682206117,
              41.4941309317147
            ]
          },
          "bbox": [
            0.9602811682206117,
            41.4941309317147,
            0.9602811682206117,
            41.4941309317147
          ]
        }
      ],
      "bbox": [
        0.9602811682206117,
        41.4941309317147,
        0.9602811682206117,
        41.4941309317147
      ]
    },
    "25154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0767549033402806,
              41.4968452705393
            ]
          },
          "bbox": [
            1.0767549033402806,
            41.4968452705393,
            1.0767549033402806,
            41.4968452705393
          ]
        }
      ],
      "bbox": [
        1.0767549033402806,
        41.4968452705393,
        1.0767549033402806,
        41.4968452705393
      ]
    },
    "25155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3194620921649103,
              42.21091738494761
            ]
          },
          "bbox": [
            1.3194620921649103,
            42.21091738494761,
            1.3194620921649103,
            42.21091738494761
          ]
        }
      ],
      "bbox": [
        1.3194620921649103,
        42.21091738494761,
        1.3194620921649103,
        42.21091738494761
      ]
    },
    "25156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.682006069170996,
              41.89987850615638
            ]
          },
          "bbox": [
            0.682006069170996,
            41.89987850615638,
            0.682006069170996,
            41.89987850615638
          ]
        }
      ],
      "bbox": [
        0.682006069170996,
        41.89987850615638,
        0.682006069170996,
        41.89987850615638
      ]
    },
    "25157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1473165198598614,
              41.746134296869485
            ]
          },
          "bbox": [
            1.1473165198598614,
            41.746134296869485,
            1.1473165198598614,
            41.746134296869485
          ]
        }
      ],
      "bbox": [
        1.1473165198598614,
        41.746134296869485,
        1.1473165198598614,
        41.746134296869485
      ]
    },
    "25161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.009497848535772,
              42.24078182114968
            ]
          },
          "bbox": [
            1.009497848535772,
            42.24078182114968,
            1.009497848535772,
            42.24078182114968
          ]
        }
      ],
      "bbox": [
        1.009497848535772,
        42.24078182114968,
        1.009497848535772,
        42.24078182114968
      ]
    },
    "25163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.583140916891293,
              42.17601004356536
            ]
          },
          "bbox": [
            1.583140916891293,
            42.17601004356536,
            1.583140916891293,
            42.17601004356536
          ]
        }
      ],
      "bbox": [
        1.583140916891293,
        42.17601004356536,
        1.583140916891293,
        42.17601004356536
      ]
    },
    "25164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9496193406081304,
              41.729348171185094
            ]
          },
          "bbox": [
            0.9496193406081304,
            41.729348171185094,
            0.9496193406081304,
            41.729348171185094
          ]
        }
      ],
      "bbox": [
        0.9496193406081304,
        41.729348171185094,
        0.9496193406081304,
        41.729348171185094
      ]
    },
    "25165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.263469738319052,
              42.08020146346139
            ]
          },
          "bbox": [
            1.263469738319052,
            42.08020146346139,
            1.263469738319052,
            42.08020146346139
          ]
        }
      ],
      "bbox": [
        1.263469738319052,
        42.08020146346139,
        1.263469738319052,
        42.08020146346139
      ]
    },
    "25166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.36864004763396,
              41.951664737002766
            ]
          },
          "bbox": [
            1.36864004763396,
            41.951664737002766,
            1.36864004763396,
            41.951664737002766
          ]
        }
      ],
      "bbox": [
        1.36864004763396,
        41.951664737002766,
        1.36864004763396,
        41.951664737002766
      ]
    },
    "25167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5501279472515728,
              41.8444846852765
            ]
          },
          "bbox": [
            1.5501279472515728,
            41.8444846852765,
            1.5501279472515728,
            41.8444846852765
          ]
        }
      ],
      "bbox": [
        1.5501279472515728,
        41.8444846852765,
        1.5501279472515728,
        41.8444846852765
      ]
    },
    "25168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8711448623887422,
              41.67570720670482
            ]
          },
          "bbox": [
            0.8711448623887422,
            41.67570720670482,
            0.8711448623887422,
            41.67570720670482
          ]
        }
      ],
      "bbox": [
        0.8711448623887422,
        41.67570720670482,
        0.8711448623887422,
        41.67570720670482
      ]
    },
    "25169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8821253269266728,
              41.381289961250225
            ]
          },
          "bbox": [
            0.8821253269266728,
            41.381289961250225,
            0.8821253269266728,
            41.381289961250225
          ]
        }
      ],
      "bbox": [
        0.8821253269266728,
        41.381289961250225,
        0.8821253269266728,
        41.381289961250225
      ]
    },
    "25170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7302022835157305,
              41.33870643570908
            ]
          },
          "bbox": [
            0.7302022835157305,
            41.33870643570908,
            0.7302022835157305,
            41.33870643570908
          ]
        }
      ],
      "bbox": [
        0.7302022835157305,
        41.33870643570908,
        0.7302022835157305,
        41.33870643570908
      ]
    },
    "25171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9806323241012107,
              42.27046735853703
            ]
          },
          "bbox": [
            0.9806323241012107,
            42.27046735853703,
            0.9806323241012107,
            42.27046735853703
          ]
        }
      ],
      "bbox": [
        0.9806323241012107,
        42.27046735853703,
        0.9806323241012107,
        42.27046735853703
      ]
    },
    "25172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1663249732305765,
              41.915587844751876
            ]
          },
          "bbox": [
            1.1663249732305765,
            41.915587844751876,
            1.1663249732305765,
            41.915587844751876
          ]
        }
      ],
      "bbox": [
        1.1663249732305765,
        41.915587844751876,
        1.1663249732305765,
        41.915587844751876
      ]
    },
    "25173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8004093164685039,
              42.39399122982141
            ]
          },
          "bbox": [
            0.8004093164685039,
            42.39399122982141,
            0.8004093164685039,
            42.39399122982141
          ]
        }
      ],
      "bbox": [
        0.8004093164685039,
        42.39399122982141,
        0.8004093164685039,
        42.39399122982141
      ]
    },
    "25174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6425881692080712,
              41.733528009750266
            ]
          },
          "bbox": [
            0.6425881692080712,
            41.733528009750266,
            0.6425881692080712,
            41.733528009750266
          ]
        }
      ],
      "bbox": [
        0.6425881692080712,
        41.733528009750266,
        0.6425881692080712,
        41.733528009750266
      ]
    },
    "25175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8331675793522482,
              42.366245783690026
            ]
          },
          "bbox": [
            1.8331675793522482,
            42.366245783690026,
            1.8331675793522482,
            42.366245783690026
          ]
        }
      ],
      "bbox": [
        1.8331675793522482,
        42.366245783690026,
        1.8331675793522482,
        42.366245783690026
      ]
    },
    "25176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0464825596815661,
              41.601550758795206
            ]
          },
          "bbox": [
            1.0464825596815661,
            41.601550758795206,
            1.0464825596815661,
            41.601550758795206
          ]
        }
      ],
      "bbox": [
        1.0464825596815661,
        41.601550758795206,
        1.0464825596815661,
        41.601550758795206
      ]
    },
    "25177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0337489059823701,
              41.80066721897978
            ]
          },
          "bbox": [
            1.0337489059823701,
            41.80066721897978,
            1.0337489059823701,
            41.80066721897978
          ]
        }
      ],
      "bbox": [
        1.0337489059823701,
        41.80066721897978,
        1.0337489059823701,
        41.80066721897978
      ]
    },
    "25179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.727014052936054,
              42.387892227294294
            ]
          },
          "bbox": [
            1.727014052936054,
            42.387892227294294,
            1.727014052936054,
            42.387892227294294
          ]
        }
      ],
      "bbox": [
        1.727014052936054,
        42.387892227294294,
        1.727014052936054,
        42.387892227294294
      ]
    },
    "25180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8787657963772177,
              41.55842680317019
            ]
          },
          "bbox": [
            0.8787657963772177,
            41.55842680317019,
            0.8787657963772177,
            41.55842680317019
          ]
        }
      ],
      "bbox": [
        0.8787657963772177,
        41.55842680317019,
        0.8787657963772177,
        41.55842680317019
      ]
    },
    "25182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7370905866441018,
              41.5425357250531
            ]
          },
          "bbox": [
            0.7370905866441018,
            41.5425357250531,
            0.7370905866441018,
            41.5425357250531
          ]
        }
      ],
      "bbox": [
        0.7370905866441018,
        41.5425357250531,
        0.7370905866441018,
        41.5425357250531
      ]
    },
    "25183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1466068942404548,
              42.46115479379151
            ]
          },
          "bbox": [
            1.1466068942404548,
            42.46115479379151,
            1.1466068942404548,
            42.46115479379151
          ]
        }
      ],
      "bbox": [
        1.1466068942404548,
        42.46115479379151,
        1.1466068942404548,
        42.46115479379151
      ]
    },
    "25186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5537339667289418,
              41.92237817487171
            ]
          },
          "bbox": [
            1.5537339667289418,
            41.92237817487171,
            1.5537339667289418,
            41.92237817487171
          ]
        }
      ],
      "bbox": [
        1.5537339667289418,
        41.92237817487171,
        1.5537339667289418,
        41.92237817487171
      ]
    },
    "25189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5921588307448168,
              41.69500950701586
            ]
          },
          "bbox": [
            0.5921588307448168,
            41.69500950701586,
            0.5921588307448168,
            41.69500950701586
          ]
        }
      ],
      "bbox": [
        0.5921588307448168,
        41.69500950701586,
        0.5921588307448168,
        41.69500950701586
      ]
    },
    "25190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9217004075888046,
              42.22193453865339
            ]
          },
          "bbox": [
            0.9217004075888046,
            42.22193453865339,
            0.9217004075888046,
            42.22193453865339
          ]
        }
      ],
      "bbox": [
        0.9217004075888046,
        42.22193453865339,
        0.9217004075888046,
        42.22193453865339
      ]
    },
    "25191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3083784719473142,
              41.86516066541149
            ]
          },
          "bbox": [
            1.3083784719473142,
            41.86516066541149,
            1.3083784719473142,
            41.86516066541149
          ]
        }
      ],
      "bbox": [
        1.3083784719473142,
        41.86516066541149,
        1.3083784719473142,
        41.86516066541149
      ]
    },
    "25192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4057662178601753,
              41.65512762741139
            ]
          },
          "bbox": [
            1.4057662178601753,
            41.65512762741139,
            1.4057662178601753,
            41.65512762741139
          ]
        }
      ],
      "bbox": [
        1.4057662178601753,
        41.65512762741139,
        1.4057662178601753,
        41.65512762741139
      ]
    },
    "25193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.587443406923011,
              42.132630927586455
            ]
          },
          "bbox": [
            1.587443406923011,
            42.132630927586455,
            1.587443406923011,
            42.132630927586455
          ]
        }
      ],
      "bbox": [
        1.587443406923011,
        42.132630927586455,
        1.587443406923011,
        42.132630927586455
      ]
    },
    "25194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3586116081540534,
              41.733312474396705
            ]
          },
          "bbox": [
            1.3586116081540534,
            41.733312474396705,
            1.3586116081540534,
            41.733312474396705
          ]
        }
      ],
      "bbox": [
        1.3586116081540534,
        41.733312474396705,
        1.3586116081540534,
        41.733312474396705
      ]
    },
    "25196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7675580984928335,
              42.07755046527266
            ]
          },
          "bbox": [
            0.7675580984928335,
            42.07755046527266,
            0.7675580984928335,
            42.07755046527266
          ]
        }
      ],
      "bbox": [
        0.7675580984928335,
        42.07755046527266,
        0.7675580984928335,
        42.07755046527266
      ]
    },
    "25197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3447322324277167,
              41.763827687234674
            ]
          },
          "bbox": [
            1.3447322324277167,
            41.763827687234674,
            1.3447322324277167,
            41.763827687234674
          ]
        }
      ],
      "bbox": [
        1.3447322324277167,
        41.763827687234674,
        1.3447322324277167,
        41.763827687234674
      ]
    },
    "25200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5600964408121129,
              41.464030267242734
            ]
          },
          "bbox": [
            0.5600964408121129,
            41.464030267242734,
            0.5600964408121129,
            41.464030267242734
          ]
        }
      ],
      "bbox": [
        0.5600964408121129,
        41.464030267242734,
        0.5600964408121129,
        41.464030267242734
      ]
    },
    "25201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8864484674993552,
              42.40842765328304
            ]
          },
          "bbox": [
            0.8864484674993552,
            42.40842765328304,
            0.8864484674993552,
            42.40842765328304
          ]
        }
      ],
      "bbox": [
        0.8864484674993552,
        42.40842765328304,
        0.8864484674993552,
        42.40842765328304
      ]
    },
    "25202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9237302646202903,
              42.32158434757606
            ]
          },
          "bbox": [
            0.9237302646202903,
            42.32158434757606,
            0.9237302646202903,
            42.32158434757606
          ]
        }
      ],
      "bbox": [
        0.9237302646202903,
        42.32158434757606,
        0.9237302646202903,
        42.32158434757606
      ]
    },
    "25204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.42298465735320756,
              41.414405863578814
            ]
          },
          "bbox": [
            0.42298465735320756,
            41.414405863578814,
            0.42298465735320756,
            41.414405863578814
          ]
        }
      ],
      "bbox": [
        0.42298465735320756,
        41.414405863578814,
        0.42298465735320756,
        41.414405863578814
      ]
    },
    "25205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8336278140512762,
              41.62608754918024
            ]
          },
          "bbox": [
            0.8336278140512762,
            41.62608754918024,
            0.8336278140512762,
            41.62608754918024
          ]
        }
      ],
      "bbox": [
        0.8336278140512762,
        41.62608754918024,
        0.8336278140512762,
        41.62608754918024
      ]
    },
    "25206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6989957274397937,
              41.42035945088563
            ]
          },
          "bbox": [
            0.6989957274397937,
            41.42035945088563,
            0.6989957274397937,
            41.42035945088563
          ]
        }
      ],
      "bbox": [
        0.6989957274397937,
        41.42035945088563,
        0.6989957274397937,
        41.42035945088563
      ]
    },
    "25207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5183481243602857,
              41.99272874300223
            ]
          },
          "bbox": [
            1.5183481243602857,
            41.99272874300223,
            1.5183481243602857,
            41.99272874300223
          ]
        }
      ],
      "bbox": [
        1.5183481243602857,
        41.99272874300223,
        1.5183481243602857,
        41.99272874300223
      ]
    },
    "25208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1461041836168817,
              42.37594721887312
            ]
          },
          "bbox": [
            1.1461041836168817,
            42.37594721887312,
            1.1461041836168817,
            42.37594721887312
          ]
        }
      ],
      "bbox": [
        1.1461041836168817,
        42.37594721887312,
        1.1461041836168817,
        42.37594721887312
      ]
    },
    "25209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0801373740836295,
              42.44303096446813
            ]
          },
          "bbox": [
            1.0801373740836295,
            42.44303096446813,
            1.0801373740836295,
            42.44303096446813
          ]
        }
      ],
      "bbox": [
        1.0801373740836295,
        42.44303096446813,
        1.0801373740836295,
        42.44303096446813
      ]
    },
    "25210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.46656273582457863,
              41.5394194001888
            ]
          },
          "bbox": [
            0.46656273582457863,
            41.5394194001888,
            0.46656273582457863,
            41.5394194001888
          ]
        }
      ],
      "bbox": [
        0.46656273582457863,
        41.5394194001888,
        0.46656273582457863,
        41.5394194001888
      ]
    },
    "25211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5692973116282182,
              41.55567768354608
            ]
          },
          "bbox": [
            0.5692973116282182,
            41.55567768354608,
            0.5692973116282182,
            41.55567768354608
          ]
        }
      ],
      "bbox": [
        0.5692973116282182,
        41.55567768354608,
        0.5692973116282182,
        41.55567768354608
      ]
    },
    "25212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5882380372139437,
              41.52010277105423
            ]
          },
          "bbox": [
            0.5882380372139437,
            41.52010277105423,
            0.5882380372139437,
            41.52010277105423
          ]
        }
      ],
      "bbox": [
        0.5882380372139437,
        41.52010277105423,
        0.5882380372139437,
        41.52010277105423
      ]
    },
    "25215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8961400597975605,
              42.1782018290745
            ]
          },
          "bbox": [
            0.8961400597975605,
            42.1782018290745,
            0.8961400597975605,
            42.1782018290745
          ]
        }
      ],
      "bbox": [
        0.8961400597975605,
        42.1782018290745,
        0.8961400597975605,
        42.1782018290745
      ]
    },
    "25216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3611238216355535,
              41.58505152627285
            ]
          },
          "bbox": [
            1.3611238216355535,
            41.58505152627285,
            1.3611238216355535,
            41.58505152627285
          ]
        }
      ],
      "bbox": [
        1.3611238216355535,
        41.58505152627285,
        1.3611238216355535,
        41.58505152627285
      ]
    },
    "25217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1419113411837114,
              41.67262754853624
            ]
          },
          "bbox": [
            1.1419113411837114,
            41.67262754853624,
            1.1419113411837114,
            41.67262754853624
          ]
        }
      ],
      "bbox": [
        1.1419113411837114,
        41.67262754853624,
        1.1419113411837114,
        41.67262754853624
      ]
    },
    "25218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.021674379436259,
              41.429562309465766
            ]
          },
          "bbox": [
            1.021674379436259,
            41.429562309465766,
            1.021674379436259,
            41.429562309465766
          ]
        }
      ],
      "bbox": [
        1.021674379436259,
        41.429562309465766,
        1.021674379436259,
        41.429562309465766
      ]
    },
    "25219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.277134120743355,
              41.72235252833888
            ]
          },
          "bbox": [
            1.277134120743355,
            41.72235252833888,
            1.277134120743355,
            41.72235252833888
          ]
        }
      ],
      "bbox": [
        1.277134120743355,
        41.72235252833888,
        1.277134120743355,
        41.72235252833888
      ]
    },
    "25220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7868981419171126,
              41.71980827613823
            ]
          },
          "bbox": [
            0.7868981419171126,
            41.71980827613823,
            0.7868981419171126,
            41.71980827613823
          ]
        }
      ],
      "bbox": [
        0.7868981419171126,
        41.71980827613823,
        0.7868981419171126,
        41.71980827613823
      ]
    },
    "25221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.234265253621212,
              42.518490006612396
            ]
          },
          "bbox": [
            1.234265253621212,
            42.518490006612396,
            1.234265253621212,
            42.518490006612396
          ]
        }
      ],
      "bbox": [
        1.234265253621212,
        42.518490006612396,
        1.234265253621212,
        42.518490006612396
      ]
    },
    "25222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.235457219889492,
              41.956482312321285
            ]
          },
          "bbox": [
            1.235457219889492,
            41.956482312321285,
            1.235457219889492,
            41.956482312321285
          ]
        }
      ],
      "bbox": [
        1.235457219889492,
        41.956482312321285,
        1.235457219889492,
        41.956482312321285
      ]
    },
    "25223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4418909731313574,
              41.84184430666517
            ]
          },
          "bbox": [
            1.4418909731313574,
            41.84184430666517,
            1.4418909731313574,
            41.84184430666517
          ]
        }
      ],
      "bbox": [
        1.4418909731313574,
        41.84184430666517,
        1.4418909731313574,
        41.84184430666517
      ]
    },
    "25224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7213238040566656,
              41.3949475021259
            ]
          },
          "bbox": [
            0.7213238040566656,
            41.3949475021259,
            0.7213238040566656,
            41.3949475021259
          ]
        }
      ],
      "bbox": [
        0.7213238040566656,
        41.3949475021259,
        0.7213238040566656,
        41.3949475021259
      ]
    },
    "25225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0591448052237271,
              41.71068090012826
            ]
          },
          "bbox": [
            1.0591448052237271,
            41.71068090012826,
            1.0591448052237271,
            41.71068090012826
          ]
        }
      ],
      "bbox": [
        1.0591448052237271,
        41.71068090012826,
        1.0591448052237271,
        41.71068090012826
      ]
    },
    "25226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5984207149209829,
              41.42076530970976
            ]
          },
          "bbox": [
            0.5984207149209829,
            41.42076530970976,
            0.5984207149209829,
            41.42076530970976
          ]
        }
      ],
      "bbox": [
        0.5984207149209829,
        41.42076530970976,
        0.5984207149209829,
        41.42076530970976
      ]
    },
    "25227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9727678544609406,
              42.444052926919255
            ]
          },
          "bbox": [
            0.9727678544609406,
            42.444052926919255,
            0.9727678544609406,
            42.444052926919255
          ]
        }
      ],
      "bbox": [
        0.9727678544609406,
        42.444052926919255,
        0.9727678544609406,
        42.444052926919255
      ]
    },
    "25228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5569720533458129,
              41.69333240959716
            ]
          },
          "bbox": [
            0.5569720533458129,
            41.69333240959716,
            0.5569720533458129,
            41.69333240959716
          ]
        }
      ],
      "bbox": [
        0.5569720533458129,
        41.69333240959716,
        0.5569720533458129,
        41.69333240959716
      ]
    },
    "25230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8096573346835357,
              41.58377971489544
            ]
          },
          "bbox": [
            0.8096573346835357,
            41.58377971489544,
            0.8096573346835357,
            41.58377971489544
          ]
        }
      ],
      "bbox": [
        0.8096573346835357,
        41.58377971489544,
        0.8096573346835357,
        41.58377971489544
      ]
    },
    "25231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7031566276877278,
              41.71231636728563
            ]
          },
          "bbox": [
            0.7031566276877278,
            41.71231636728563,
            0.7031566276877278,
            41.71231636728563
          ]
        }
      ],
      "bbox": [
        0.7031566276877278,
        41.71231636728563,
        0.7031566276877278,
        41.71231636728563
      ]
    },
    "25232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5194947521563441,
              41.53016219780147
            ]
          },
          "bbox": [
            0.5194947521563441,
            41.53016219780147,
            0.5194947521563441,
            41.53016219780147
          ]
        }
      ],
      "bbox": [
        0.5194947521563441,
        41.53016219780147,
        0.5194947521563441,
        41.53016219780147
      ]
    },
    "25233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6359977262207318,
              41.67631106907059
            ]
          },
          "bbox": [
            0.6359977262207318,
            41.67631106907059,
            0.6359977262207318,
            41.67631106907059
          ]
        }
      ],
      "bbox": [
        0.6359977262207318,
        41.67631106907059,
        0.6359977262207318,
        41.67631106907059
      ]
    },
    "25234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8022343247801184,
              42.21518759696094
            ]
          },
          "bbox": [
            0.8022343247801184,
            42.21518759696094,
            0.8022343247801184,
            42.21518759696094
          ]
        }
      ],
      "bbox": [
        0.8022343247801184,
        42.21518759696094,
        0.8022343247801184,
        42.21518759696094
      ]
    },
    "25238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.123233053958828,
              41.501824753432565
            ]
          },
          "bbox": [
            1.123233053958828,
            41.501824753432565,
            1.123233053958828,
            41.501824753432565
          ]
        }
      ],
      "bbox": [
        1.123233053958828,
        41.501824753432565,
        1.123233053958828,
        41.501824753432565
      ]
    },
    "25239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.454564980747556,
              42.443283189514425
            ]
          },
          "bbox": [
            1.454564980747556,
            42.443283189514425,
            1.454564980747556,
            42.443283189514425
          ]
        }
      ],
      "bbox": [
        1.454564980747556,
        42.443283189514425,
        1.454564980747556,
        42.443283189514425
      ]
    },
    "25240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8339464840385532,
              41.75238073737445
            ]
          },
          "bbox": [
            0.8339464840385532,
            41.75238073737445,
            0.8339464840385532,
            41.75238073737445
          ]
        }
      ],
      "bbox": [
        0.8339464840385532,
        41.75238073737445,
        0.8339464840385532,
        41.75238073737445
      ]
    },
    "25242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1267077890616755,
              41.59508596538466
            ]
          },
          "bbox": [
            1.1267077890616755,
            41.59508596538466,
            1.1267077890616755,
            41.59508596538466
          ]
        }
      ],
      "bbox": [
        1.1267077890616755,
        41.59508596538466,
        1.1267077890616755,
        41.59508596538466
      ]
    },
    "25243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7871141968110801,
              42.70598573150521
            ]
          },
          "bbox": [
            0.7871141968110801,
            42.70598573150521,
            0.7871141968110801,
            42.70598573150521
          ]
        }
      ],
      "bbox": [
        0.7871141968110801,
        42.70598573150521,
        0.7871141968110801,
        42.70598573150521
      ]
    },
    "25244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.088254543475156,
              41.64338338059215
            ]
          },
          "bbox": [
            1.088254543475156,
            41.64338338059215,
            1.088254543475156,
            41.64338338059215
          ]
        }
      ],
      "bbox": [
        1.088254543475156,
        41.64338338059215,
        1.088254543475156,
        41.64338338059215
      ]
    },
    "25245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7581963670682059,
              42.54130969570418
            ]
          },
          "bbox": [
            0.7581963670682059,
            42.54130969570418,
            0.7581963670682059,
            42.54130969570418
          ]
        }
      ],
      "bbox": [
        0.7581963670682059,
        42.54130969570418,
        0.7581963670682059,
        42.54130969570418
      ]
    },
    "25247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.732755249731371,
              42.755348536773084
            ]
          },
          "bbox": [
            0.732755249731371,
            42.755348536773084,
            0.732755249731371,
            42.755348536773084
          ]
        }
      ],
      "bbox": [
        0.732755249731371,
        42.755348536773084,
        0.732755249731371,
        42.755348536773084
      ]
    },
    "25248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9552145801113298,
              41.60167036274263
            ]
          },
          "bbox": [
            0.9552145801113298,
            41.60167036274263,
            0.9552145801113298,
            41.60167036274263
          ]
        }
      ],
      "bbox": [
        0.9552145801113298,
        41.60167036274263,
        0.9552145801113298,
        41.60167036274263
      ]
    },
    "25250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0071901345865983,
              41.99339969259169
            ]
          },
          "bbox": [
            1.0071901345865983,
            41.99339969259169,
            1.0071901345865983,
            41.99339969259169
          ]
        }
      ],
      "bbox": [
        1.0071901345865983,
        41.99339969259169,
        1.0071901345865983,
        41.99339969259169
      ]
    },
    "25251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6206439604359739,
              41.71156460525182
            ]
          },
          "bbox": [
            0.6206439604359739,
            41.71156460525182,
            0.6206439604359739,
            41.71156460525182
          ]
        }
      ],
      "bbox": [
        0.6206439604359739,
        41.71156460525182,
        0.6206439604359739,
        41.71156460525182
      ]
    },
    "25252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9212003038443979,
              41.66221492338364
            ]
          },
          "bbox": [
            0.9212003038443979,
            41.66221492338364,
            0.9212003038443979,
            41.66221492338364
          ]
        }
      ],
      "bbox": [
        0.9212003038443979,
        41.66221492338364,
        0.9212003038443979,
        41.66221492338364
      ]
    },
    "25253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9515835950031415,
              41.3863922957987
            ]
          },
          "bbox": [
            0.9515835950031415,
            41.3863922957987,
            0.9515835950031415,
            41.3863922957987
          ]
        }
      ],
      "bbox": [
        0.9515835950031415,
        41.3863922957987,
        0.9515835950031415,
        41.3863922957987
      ]
    },
    "25254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7349288064473187,
              41.681493938822086
            ]
          },
          "bbox": [
            0.7349288064473187,
            41.681493938822086,
            0.7349288064473187,
            41.681493938822086
          ]
        }
      ],
      "bbox": [
        0.7349288064473187,
        41.681493938822086,
        0.7349288064473187,
        41.681493938822086
      ]
    },
    "25255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9758070606231662,
              41.439868806970146
            ]
          },
          "bbox": [
            0.9758070606231662,
            41.439868806970146,
            0.9758070606231662,
            41.439868806970146
          ]
        }
      ],
      "bbox": [
        0.9758070606231662,
        41.439868806970146,
        0.9758070606231662,
        41.439868806970146
      ]
    },
    "25901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2164555118867346,
              42.58703726708427
            ]
          },
          "bbox": [
            1.2164555118867346,
            42.58703726708427,
            1.2164555118867346,
            42.58703726708427
          ]
        }
      ],
      "bbox": [
        1.2164555118867346,
        42.58703726708427,
        1.2164555118867346,
        42.58703726708427
      ]
    },
    "25902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0649709560402434,
              41.5594672378513
            ]
          },
          "bbox": [
            1.0649709560402434,
            41.5594672378513,
            1.0649709560402434,
            41.5594672378513
          ]
        }
      ],
      "bbox": [
        1.0649709560402434,
        41.5594672378513,
        1.0649709560402434,
        41.5594672378513
      ]
    },
    "25904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8505300418023231,
              42.098229757089804
            ]
          },
          "bbox": [
            0.8505300418023231,
            42.098229757089804,
            0.8505300418023231,
            42.098229757089804
          ]
        }
      ],
      "bbox": [
        0.8505300418023231,
        42.098229757089804,
        0.8505300418023231,
        42.098229757089804
      ]
    },
    "25907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2601487086161665,
              41.792611900321205
            ]
          },
          "bbox": [
            1.2601487086161665,
            41.792611900321205,
            1.2601487086161665,
            41.792611900321205
          ]
        }
      ],
      "bbox": [
        1.2601487086161665,
        41.792611900321205,
        1.2601487086161665,
        41.792611900321205
      ]
    },
    "25908": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.399419716834976,
              42.19514705945786
            ]
          },
          "bbox": [
            1.399419716834976,
            42.19514705945786,
            1.399419716834976,
            42.19514705945786
          ]
        }
      ],
      "bbox": [
        1.399419716834976,
        42.19514705945786,
        1.399419716834976,
        42.19514705945786
      ]
    },
    "25909": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.496964706465561,
              42.247008912537304
            ]
          },
          "bbox": [
            1.496964706465561,
            42.247008912537304,
            1.496964706465561,
            42.247008912537304
          ]
        }
      ],
      "bbox": [
        1.496964706465561,
        42.247008912537304,
        1.496964706465561,
        42.247008912537304
      ]
    },
    "25910": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6032437645744904,
              42.24596978393735
            ]
          },
          "bbox": [
            1.6032437645744904,
            42.24596978393735,
            1.6032437645744904,
            42.24596978393735
          ]
        }
      ],
      "bbox": [
        1.6032437645744904,
        42.24596978393735,
        1.6032437645744904,
        42.24596978393735
      ]
    },
    "25911": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2065298226386414,
              41.735601601152894
            ]
          },
          "bbox": [
            1.2065298226386414,
            41.735601601152894,
            1.2065298226386414,
            41.735601601152894
          ]
        }
      ],
      "bbox": [
        1.2065298226386414,
        41.735601601152894,
        1.2065298226386414,
        41.735601601152894
      ]
    },
    "25912": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3827370333156606,
              41.66750889663352
            ]
          },
          "bbox": [
            0.3827370333156606,
            41.66750889663352,
            0.3827370333156606,
            41.66750889663352
          ]
        }
      ],
      "bbox": [
        0.3827370333156606,
        41.66750889663352,
        0.3827370333156606,
        41.66750889663352
      ]
    },
    "25913": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8236899802926472,
              42.32389303347747
            ]
          },
          "bbox": [
            1.8236899802926472,
            42.32389303347747,
            1.8236899802926472,
            42.32389303347747
          ]
        }
      ],
      "bbox": [
        1.8236899802926472,
        42.32389303347747,
        1.8236899802926472,
        42.32389303347747
      ]
    },
    "26001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.703634741980258,
              42.56673619587001
            ]
          },
          "bbox": [
            -2.703634741980258,
            42.56673619587001,
            -2.703634741980258,
            42.56673619587001
          ]
        }
      ],
      "bbox": [
        -2.703634741980258,
        42.56673619587001,
        -2.703634741980258,
        42.56673619587001
      ]
    },
    "26002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2823538943896446,
              42.432742552484925
            ]
          },
          "bbox": [
            -2.2823538943896446,
            42.432742552484925,
            -2.2823538943896446,
            42.432742552484925
          ]
        }
      ],
      "bbox": [
        -2.2823538943896446,
        42.432742552484925,
        -2.2823538943896446,
        42.432742552484925
      ]
    },
    "26003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9842888614905827,
              41.955048334352384
            ]
          },
          "bbox": [
            -1.9842888614905827,
            41.955048334352384,
            -1.9842888614905827,
            41.955048334352384
          ]
        }
      ],
      "bbox": [
        -1.9842888614905827,
        41.955048334352384,
        -1.9842888614905827,
        41.955048334352384
      ]
    },
    "26004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4412173729532007,
              42.15866650959167
            ]
          },
          "bbox": [
            -2.4412173729532007,
            42.15866650959167,
            -2.4412173729532007,
            42.15866650959167
          ]
        }
      ],
      "bbox": [
        -2.4412173729532007,
        42.15866650959167,
        -2.4412173729532007,
        42.15866650959167
      ]
    },
    "26005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4684302292499334,
              42.3657297182103
            ]
          },
          "bbox": [
            -2.4684302292499334,
            42.3657297182103,
            -2.4684302292499334,
            42.3657297182103
          ]
        }
      ],
      "bbox": [
        -2.4684302292499334,
        42.3657297182103,
        -2.4684302292499334,
        42.3657297182103
      ]
    },
    "26006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4108047561146484,
              42.40089092027666
            ]
          },
          "bbox": [
            -2.4108047561146484,
            42.40089092027666,
            -2.4108047561146484,
            42.40089092027666
          ]
        }
      ],
      "bbox": [
        -2.4108047561146484,
        42.40089092027666,
        -2.4108047561146484,
        42.40089092027666
      ]
    },
    "26007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1575900733350886,
              42.3998927758777
            ]
          },
          "bbox": [
            -2.1575900733350886,
            42.3998927758777,
            -2.1575900733350886,
            42.3998927758777
          ]
        }
      ],
      "bbox": [
        -2.1575900733350886,
        42.3998927758777,
        -2.1575900733350886,
        42.3998927758777
      ]
    },
    "26008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9100548549503031,
              42.22858452007345
            ]
          },
          "bbox": [
            -1.9100548549503031,
            42.22858452007345,
            -1.9100548549503031,
            42.22858452007345
          ]
        }
      ],
      "bbox": [
        -1.9100548549503031,
        42.22858452007345,
        -1.9100548549503031,
        42.22858452007345
      ]
    },
    "26009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8180078977711256,
              42.42140919200576
            ]
          },
          "bbox": [
            -2.8180078977711256,
            42.42140919200576,
            -2.8180078977711256,
            42.42140919200576
          ]
        }
      ],
      "bbox": [
        -2.8180078977711256,
        42.42140919200576,
        -2.8180078977711256,
        42.42140919200576
      ]
    },
    "26010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6746308044179816,
              42.406289596485735
            ]
          },
          "bbox": [
            -2.6746308044179816,
            42.406289596485735,
            -2.6746308044179816,
            42.406289596485735
          ]
        }
      ],
      "bbox": [
        -2.6746308044179816,
        42.406289596485735,
        -2.6746308044179816,
        42.406289596485735
      ]
    },
    "26011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8311212140252853,
              42.16361314305251
            ]
          },
          "bbox": [
            -1.8311212140252853,
            42.16361314305251,
            -1.8311212140252853,
            42.16361314305251
          ]
        }
      ],
      "bbox": [
        -1.8311212140252853,
        42.16361314305251,
        -1.8311212140252853,
        42.16361314305251
      ]
    },
    "26012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.587837043104089,
              42.23226936221106
            ]
          },
          "bbox": [
            -2.587837043104089,
            42.23226936221106,
            -2.587837043104089,
            42.23226936221106
          ]
        }
      ],
      "bbox": [
        -2.587837043104089,
        42.23226936221106,
        -2.587837043104089,
        42.23226936221106
      ]
    },
    "26013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9026616319949907,
              42.571832690603856
            ]
          },
          "bbox": [
            -2.9026616319949907,
            42.571832690603856,
            -2.9026616319949907,
            42.571832690603856
          ]
        }
      ],
      "bbox": [
        -2.9026616319949907,
        42.571832690603856,
        -2.9026616319949907,
        42.571832690603856
      ]
    },
    "26014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7741360139447235,
              42.25133293419119
            ]
          },
          "bbox": [
            -2.7741360139447235,
            42.25133293419119,
            -2.7741360139447235,
            42.25133293419119
          ]
        }
      ],
      "bbox": [
        -2.7741360139447235,
        42.25133293419119,
        -2.7741360139447235,
        42.25133293419119
      ]
    },
    "26015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.718201247064446,
              42.37784935845684
            ]
          },
          "bbox": [
            -2.718201247064446,
            42.37784935845684,
            -2.718201247064446,
            42.37784935845684
          ]
        }
      ],
      "bbox": [
        -2.718201247064446,
        42.37784935845684,
        -2.718201247064446,
        42.37784935845684
      ]
    },
    "26016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6894382185626093,
              42.37745029436351
            ]
          },
          "bbox": [
            -2.6894382185626093,
            42.37745029436351,
            -2.6894382185626093,
            42.37745029436351
          ]
        }
      ],
      "bbox": [
        -2.6894382185626093,
        42.37745029436351,
        -2.6894382185626093,
        42.37745029436351
      ]
    },
    "26017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2382635391523498,
              42.21632045533286
            ]
          },
          "bbox": [
            -2.2382635391523498,
            42.21632045533286,
            -2.2382635391523498,
            42.21632045533286
          ]
        }
      ],
      "bbox": [
        -2.2382635391523498,
        42.21632045533286,
        -2.2382635391523498,
        42.21632045533286
      ]
    },
    "26018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0951874357835614,
              42.21657852162388
            ]
          },
          "bbox": [
            -2.0951874357835614,
            42.21657852162388,
            -2.0951874357835614,
            42.21657852162388
          ]
        }
      ],
      "bbox": [
        -2.0951874357835614,
        42.21657852162388,
        -2.0951874357835614,
        42.21657852162388
      ]
    },
    "26019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.251893448157248,
              42.429607943991165
            ]
          },
          "bbox": [
            -2.251893448157248,
            42.429607943991165,
            -2.251893448157248,
            42.429607943991165
          ]
        }
      ],
      "bbox": [
        -2.251893448157248,
        42.429607943991165,
        -2.251893448157248,
        42.429607943991165
      ]
    },
    "26020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.142392131992201,
              42.35418133672574
            ]
          },
          "bbox": [
            -2.142392131992201,
            42.35418133672574,
            -2.142392131992201,
            42.35418133672574
          ]
        }
      ],
      "bbox": [
        -2.142392131992201,
        42.35418133672574,
        -2.142392131992201,
        42.35418133672574
      ]
    },
    "26021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9851103248070032,
              42.19671421124016
            ]
          },
          "bbox": [
            -1.9851103248070032,
            42.19671421124016,
            -1.9851103248070032,
            42.19671421124016
          ]
        }
      ],
      "bbox": [
        -1.9851103248070032,
        42.19671421124016,
        -1.9851103248070032,
        42.19671421124016
      ]
    },
    "26022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8013648731168623,
              42.437355512301224
            ]
          },
          "bbox": [
            -2.8013648731168623,
            42.437355512301224,
            -2.8013648731168623,
            42.437355512301224
          ]
        }
      ],
      "bbox": [
        -2.8013648731168623,
        42.437355512301224,
        -2.8013648731168623,
        42.437355512301224
      ]
    },
    "26023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.804795998104237,
              42.362491167238844
            ]
          },
          "bbox": [
            -2.804795998104237,
            42.362491167238844,
            -2.804795998104237,
            42.362491167238844
          ]
        }
      ],
      "bbox": [
        -2.804795998104237,
        42.362491167238844,
        -2.804795998104237,
        42.362491167238844
      ]
    },
    "26024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.914103818475655,
              42.46595392845342
            ]
          },
          "bbox": [
            -2.914103818475655,
            42.46595392845342,
            -2.914103818475655,
            42.46595392845342
          ]
        }
      ],
      "bbox": [
        -2.914103818475655,
        42.46595392845342,
        -2.914103818475655,
        42.46595392845342
      ]
    },
    "26025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9537553952332383,
              42.51162870004906
            ]
          },
          "bbox": [
            -2.9537553952332383,
            42.51162870004906,
            -2.9537553952332383,
            42.51162870004906
          ]
        }
      ],
      "bbox": [
        -2.9537553952332383,
        42.51162870004906,
        -2.9537553952332383,
        42.51162870004906
      ]
    },
    "26026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7586197596970985,
              42.340579762453494
            ]
          },
          "bbox": [
            -2.7586197596970985,
            42.340579762453494,
            -2.7586197596970985,
            42.340579762453494
          ]
        }
      ],
      "bbox": [
        -2.7586197596970985,
        42.340579762453494,
        -2.7586197596970985,
        42.340579762453494
      ]
    },
    "26027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8755602844829644,
              42.31844964972638
            ]
          },
          "bbox": [
            -2.8755602844829644,
            42.31844964972638,
            -2.8755602844829644,
            42.31844964972638
          ]
        }
      ],
      "bbox": [
        -2.8755602844829644,
        42.31844964972638,
        -2.8755602844829644,
        42.31844964972638
      ]
    },
    "26028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1499321839241206,
              42.265172573705044
            ]
          },
          "bbox": [
            -2.1499321839241206,
            42.265172573705044,
            -2.1499321839241206,
            42.265172573705044
          ]
        }
      ],
      "bbox": [
        -2.1499321839241206,
        42.265172573705044,
        -2.1499321839241206,
        42.265172573705044
      ]
    },
    "26029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1680331733418483,
              42.250281934175874
            ]
          },
          "bbox": [
            -2.1680331733418483,
            42.250281934175874,
            -2.1680331733418483,
            42.250281934175874
          ]
        }
      ],
      "bbox": [
        -2.1680331733418483,
        42.250281934175874,
        -2.1680331733418483,
        42.250281934175874
      ]
    },
    "26030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6703561647225755,
              42.36435399951344
            ]
          },
          "bbox": [
            -2.6703561647225755,
            42.36435399951344,
            -2.6703561647225755,
            42.36435399951344
          ]
        }
      ],
      "bbox": [
        -2.6703561647225755,
        42.36435399951344,
        -2.6703561647225755,
        42.36435399951344
      ]
    },
    "26031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7586596879912584,
              42.31780476970771
            ]
          },
          "bbox": [
            -2.7586596879912584,
            42.31780476970771,
            -2.7586596879912584,
            42.31780476970771
          ]
        }
      ],
      "bbox": [
        -2.7586596879912584,
        42.31780476970771,
        -2.7586596879912584,
        42.31780476970771
      ]
    },
    "26032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7822475128576136,
              42.17229421130036
            ]
          },
          "bbox": [
            -2.7822475128576136,
            42.17229421130036,
            -2.7822475128576136,
            42.17229421130036
          ]
        }
      ],
      "bbox": [
        -2.7822475128576136,
        42.17229421130036,
        -2.7822475128576136,
        42.17229421130036
      ]
    },
    "26033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8321415131388212,
              42.60473412373573
            ]
          },
          "bbox": [
            -2.8321415131388212,
            42.60473412373573,
            -2.8321415131388212,
            42.60473412373573
          ]
        }
      ],
      "bbox": [
        -2.8321415131388212,
        42.60473412373573,
        -2.8321415131388212,
        42.60473412373573
      ]
    },
    "26034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7908748117762485,
              42.51712309690749
            ]
          },
          "bbox": [
            -2.7908748117762485,
            42.51712309690749,
            -2.7908748117762485,
            42.51712309690749
          ]
        }
      ],
      "bbox": [
        -2.7908748117762485,
        42.51712309690749,
        -2.7908748117762485,
        42.51712309690749
      ]
    },
    "26035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.518254221220653,
              42.188516880567356
            ]
          },
          "bbox": [
            -2.518254221220653,
            42.188516880567356,
            -2.518254221220653,
            42.188516880567356
          ]
        }
      ],
      "bbox": [
        -2.518254221220653,
        42.188516880567356,
        -2.518254221220653,
        42.188516880567356
      ]
    },
    "26036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9560554880799412,
              42.29547919416337
            ]
          },
          "bbox": [
            -1.9560554880799412,
            42.29547919416337,
            -1.9560554880799412,
            42.29547919416337
          ]
        }
      ],
      "bbox": [
        -1.9560554880799412,
        42.29547919416337,
        -1.9560554880799412,
        42.29547919416337
      ]
    },
    "26037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7165567571957396,
              42.35282036974857
            ]
          },
          "bbox": [
            -2.7165567571957396,
            42.35282036974857,
            -2.7165567571957396,
            42.35282036974857
          ]
        }
      ],
      "bbox": [
        -2.7165567571957396,
        42.35282036974857,
        -2.7165567571957396,
        42.35282036974857
      ]
    },
    "26038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.058740474106043,
              42.1670119308834
            ]
          },
          "bbox": [
            -3.058740474106043,
            42.1670119308834,
            -3.058740474106043,
            42.1670119308834
          ]
        }
      ],
      "bbox": [
        -3.058740474106043,
        42.1670119308834,
        -3.058740474106043,
        42.1670119308834
      ]
    },
    "26039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.834189106328806,
              42.397151701783756
            ]
          },
          "bbox": [
            -2.834189106328806,
            42.397151701783756,
            -2.834189106328806,
            42.397151701783756
          ]
        }
      ],
      "bbox": [
        -2.834189106328806,
        42.397151701783756,
        -2.834189106328806,
        42.397151701783756
      ]
    },
    "26040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8591080862368115,
              42.395906953753126
            ]
          },
          "bbox": [
            -2.8591080862368115,
            42.395906953753126,
            -2.8591080862368115,
            42.395906953753126
          ]
        }
      ],
      "bbox": [
        -2.8591080862368115,
        42.395906953753126,
        -2.8591080862368115,
        42.395906953753126
      ]
    },
    "26041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7671679146153676,
              42.37443537882677
            ]
          },
          "bbox": [
            -2.7671679146153676,
            42.37443537882677,
            -2.7671679146153676,
            42.37443537882677
          ]
        }
      ],
      "bbox": [
        -2.7671679146153676,
        42.37443537882677,
        -2.7671679146153676,
        42.37443537882677
      ]
    },
    "26042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.905276984227128,
              42.547232020274556
            ]
          },
          "bbox": [
            -2.905276984227128,
            42.547232020274556,
            -2.905276984227128,
            42.547232020274556
          ]
        }
      ],
      "bbox": [
        -2.905276984227128,
        42.547232020274556,
        -2.905276984227128,
        42.547232020274556
      ]
    },
    "26043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.920631230047706,
              42.51188789852562
            ]
          },
          "bbox": [
            -2.920631230047706,
            42.51188789852562,
            -2.920631230047706,
            42.51188789852562
          ]
        }
      ],
      "bbox": [
        -2.920631230047706,
        42.51188789852562,
        -2.920631230047706,
        42.51188789852562
      ]
    },
    "26044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6538180144286816,
              42.3223823349698
            ]
          },
          "bbox": [
            -2.6538180144286816,
            42.3223823349698,
            -2.6538180144286816,
            42.3223823349698
          ]
        }
      ],
      "bbox": [
        -2.6538180144286816,
        42.3223823349698,
        -2.6538180144286816,
        42.3223823349698
      ]
    },
    "26045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.001955953273652,
              42.6228043175924
            ]
          },
          "bbox": [
            -3.001955953273652,
            42.6228043175924,
            -3.001955953273652,
            42.6228043175924
          ]
        }
      ],
      "bbox": [
        -3.001955953273652,
        42.6228043175924,
        -3.001955953273652,
        42.6228043175924
      ]
    },
    "26046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6540240986747725,
              42.47408040378092
            ]
          },
          "bbox": [
            -2.6540240986747725,
            42.47408040378092,
            -2.6540240986747725,
            42.47408040378092
          ]
        }
      ],
      "bbox": [
        -2.6540240986747725,
        42.47408040378092,
        -2.6540240986747725,
        42.47408040378092
      ]
    },
    "26047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.943357158176996,
              42.018020970645914
            ]
          },
          "bbox": [
            -1.943357158176996,
            42.018020970645914,
            -1.943357158176996,
            42.018020970645914
          ]
        }
      ],
      "bbox": [
        -1.943357158176996,
        42.018020970645914,
        -1.943357158176996,
        42.018020970645914
      ]
    },
    "26048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.861717660932498,
              42.48243783634684
            ]
          },
          "bbox": [
            -2.861717660932498,
            42.48243783634684,
            -2.861717660932498,
            42.48243783634684
          ]
        }
      ],
      "bbox": [
        -2.861717660932498,
        42.48243783634684,
        -2.861717660932498,
        42.48243783634684
      ]
    },
    "26049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9260187543292475,
              42.57975602391955
            ]
          },
          "bbox": [
            -2.9260187543292475,
            42.57975602391955,
            -2.9260187543292475,
            42.57975602391955
          ]
        }
      ],
      "bbox": [
        -2.9260187543292475,
        42.57975602391955,
        -2.9260187543292475,
        42.57975602391955
      ]
    },
    "26050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.882993752839958,
              42.421168427598055
            ]
          },
          "bbox": [
            -2.882993752839958,
            42.421168427598055,
            -2.882993752839958,
            42.421168427598055
          ]
        }
      ],
      "bbox": [
        -2.882993752839958,
        42.421168427598055,
        -2.882993752839958,
        42.421168427598055
      ]
    },
    "26051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4268780254737705,
              42.36266463103661
            ]
          },
          "bbox": [
            -2.4268780254737705,
            42.36266463103661,
            -2.4268780254737705,
            42.36266463103661
          ]
        }
      ],
      "bbox": [
        -2.4268780254737705,
        42.36266463103661,
        -2.4268780254737705,
        42.36266463103661
      ]
    },
    "26052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8087355224760624,
              42.38813546911278
            ]
          },
          "bbox": [
            -2.8087355224760624,
            42.38813546911278,
            -2.8087355224760624,
            42.38813546911278
          ]
        }
      ],
      "bbox": [
        -2.8087355224760624,
        42.38813546911278,
        -2.8087355224760624,
        42.38813546911278
      ]
    },
    "26053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2162463407090427,
              42.35552411306434
            ]
          },
          "bbox": [
            -2.2162463407090427,
            42.35552411306434,
            -2.2162463407090427,
            42.35552411306434
          ]
        }
      ],
      "bbox": [
        -2.2162463407090427,
        42.35552411306434,
        -2.2162463407090427,
        42.35552411306434
      ]
    },
    "26054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0978377582085,
              42.064197801846966
            ]
          },
          "bbox": [
            -2.0978377582085,
            42.064197801846966,
            -2.0978377582085,
            42.064197801846966
          ]
        }
      ],
      "bbox": [
        -2.0978377582085,
        42.064197801846966,
        -2.0978377582085,
        42.064197801846966
      ]
    },
    "26055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.002708158788081,
              42.42714705286507
            ]
          },
          "bbox": [
            -3.002708158788081,
            42.42714705286507,
            -3.002708158788081,
            42.42714705286507
          ]
        }
      ],
      "bbox": [
        -3.002708158788081,
        42.42714705286507,
        -3.002708158788081,
        42.42714705286507
      ]
    },
    "26056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9708828740106386,
              42.549167439115855
            ]
          },
          "bbox": [
            -2.9708828740106386,
            42.549167439115855,
            -2.9708828740106386,
            42.549167439115855
          ]
        }
      ],
      "bbox": [
        -2.9708828740106386,
        42.549167439115855,
        -2.9708828740106386,
        42.549167439115855
      ]
    },
    "26057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5926210482340943,
              42.35827932146248
            ]
          },
          "bbox": [
            -2.5926210482340943,
            42.35827932146248,
            -2.5926210482340943,
            42.35827932146248
          ]
        }
      ],
      "bbox": [
        -2.5926210482340943,
        42.35827932146248,
        -2.5926210482340943,
        42.35827932146248
      ]
    },
    "26058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.252516409014425,
              42.1364643210949
            ]
          },
          "bbox": [
            -2.252516409014425,
            42.1364643210949,
            -2.252516409014425,
            42.1364643210949
          ]
        }
      ],
      "bbox": [
        -2.252516409014425,
        42.1364643210949,
        -2.252516409014425,
        42.1364643210949
      ]
    },
    "26059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.515557114241626,
              42.39279717400204
            ]
          },
          "bbox": [
            -2.515557114241626,
            42.39279717400204,
            -2.515557114241626,
            42.39279717400204
          ]
        }
      ],
      "bbox": [
        -2.515557114241626,
        42.39279717400204,
        -2.515557114241626,
        42.39279717400204
      ]
    },
    "26060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8497725782280727,
              42.31641420201938
            ]
          },
          "bbox": [
            -2.8497725782280727,
            42.31641420201938,
            -2.8497725782280727,
            42.31641420201938
          ]
        }
      ],
      "bbox": [
        -2.8497725782280727,
        42.31641420201938,
        -2.8497725782280727,
        42.31641420201938
      ]
    },
    "26061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.024443261636786,
              42.25993236228944
            ]
          },
          "bbox": [
            -3.024443261636786,
            42.25993236228944,
            -3.024443261636786,
            42.25993236228944
          ]
        }
      ],
      "bbox": [
        -3.024443261636786,
        42.25993236228944,
        -3.024443261636786,
        42.25993236228944
      ]
    },
    "26062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.047776729868099,
              42.61642885802139
            ]
          },
          "bbox": [
            -3.047776729868099,
            42.61642885802139,
            -3.047776729868099,
            42.61642885802139
          ]
        }
      ],
      "bbox": [
        -3.047776729868099,
        42.61642885802139,
        -3.047776729868099,
        42.61642885802139
      ]
    },
    "26063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.999513313880877,
              42.58782341882786
            ]
          },
          "bbox": [
            -2.999513313880877,
            42.58782341882786,
            -2.999513313880877,
            42.58782341882786
          ]
        }
      ],
      "bbox": [
        -2.999513313880877,
        42.58782341882786,
        -2.999513313880877,
        42.58782341882786
      ]
    },
    "26064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.575665588618053,
              42.4654743285013
            ]
          },
          "bbox": [
            -2.575665588618053,
            42.4654743285013,
            -2.575665588618053,
            42.4654743285013
          ]
        }
      ],
      "bbox": [
        -2.575665588618053,
        42.4654743285013,
        -2.575665588618053,
        42.4654743285013
      ]
    },
    "26065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.95541949204633,
              42.621601138173205
            ]
          },
          "bbox": [
            -2.95541949204633,
            42.621601138173205,
            -2.95541949204633,
            42.621601138173205
          ]
        }
      ],
      "bbox": [
        -2.95541949204633,
        42.621601138173205,
        -2.95541949204633,
        42.621601138173205
      ]
    },
    "26066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2310250734977664,
              42.365740050857184
            ]
          },
          "bbox": [
            -2.2310250734977664,
            42.365740050857184,
            -2.2310250734977664,
            42.365740050857184
          ]
        }
      ],
      "bbox": [
        -2.2310250734977664,
        42.365740050857184,
        -2.2310250734977664,
        42.365740050857184
      ]
    },
    "26067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6117707236621452,
              42.16963439786696
            ]
          },
          "bbox": [
            -2.6117707236621452,
            42.16963439786696,
            -2.6117707236621452,
            42.16963439786696
          ]
        }
      ],
      "bbox": [
        -2.6117707236621452,
        42.16963439786696,
        -2.6117707236621452,
        42.16963439786696
      ]
    },
    "26068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.818464687352006,
              42.55036648842188
            ]
          },
          "bbox": [
            -2.818464687352006,
            42.55036648842188,
            -2.818464687352006,
            42.55036648842188
          ]
        }
      ],
      "bbox": [
        -2.818464687352006,
        42.55036648842188,
        -2.818464687352006,
        42.55036648842188
      ]
    },
    "26069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.022012775795905,
              42.452192232849264
            ]
          },
          "bbox": [
            -3.022012775795905,
            42.452192232849264,
            -3.022012775795905,
            42.452192232849264
          ]
        }
      ],
      "bbox": [
        -3.022012775795905,
        42.452192232849264,
        -3.022012775795905,
        42.452192232849264
      ]
    },
    "26070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.98869886774034,
              42.11698503737629
            ]
          },
          "bbox": [
            -1.98869886774034,
            42.11698503737629,
            -1.98869886774034,
            42.11698503737629
          ]
        }
      ],
      "bbox": [
        -1.98869886774034,
        42.11698503737629,
        -1.98869886774034,
        42.11698503737629
      ]
    },
    "26071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8617075355612256,
              42.584699503386645
            ]
          },
          "bbox": [
            -2.8617075355612256,
            42.584699503386645,
            -2.8617075355612256,
            42.584699503386645
          ]
        }
      ],
      "bbox": [
        -2.8617075355612256,
        42.584699503386645,
        -2.8617075355612256,
        42.584699503386645
      ]
    },
    "26072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1660676599993516,
              42.22243713315423
            ]
          },
          "bbox": [
            -2.1660676599993516,
            42.22243713315423,
            -2.1660676599993516,
            42.22243713315423
          ]
        }
      ],
      "bbox": [
        -2.1660676599993516,
        42.22243713315423,
        -2.1660676599993516,
        42.22243713315423
      ]
    },
    "26073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0122659327502515,
              42.49964200362222
            ]
          },
          "bbox": [
            -3.0122659327502515,
            42.49964200362222,
            -3.0122659327502515,
            42.49964200362222
          ]
        }
      ],
      "bbox": [
        -3.0122659327502515,
        42.49964200362222,
        -3.0122659327502515,
        42.49964200362222
      ]
    },
    "26074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8646031487432175,
              42.44737044867836
            ]
          },
          "bbox": [
            -2.8646031487432175,
            42.44737044867836,
            -2.8646031487432175,
            42.44737044867836
          ]
        }
      ],
      "bbox": [
        -2.8646031487432175,
        42.44737044867836,
        -2.8646031487432175,
        42.44737044867836
      ]
    },
    "26075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.779960794998628,
              42.4512808198336
            ]
          },
          "bbox": [
            -2.779960794998628,
            42.4512808198336,
            -2.779960794998628,
            42.4512808198336
          ]
        }
      ],
      "bbox": [
        -2.779960794998628,
        42.4512808198336,
        -2.779960794998628,
        42.4512808198336
      ]
    },
    "26076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.734958094440727,
              42.46275295137993
            ]
          },
          "bbox": [
            -2.734958094440727,
            42.46275295137993,
            -2.734958094440727,
            42.46275295137993
          ]
        }
      ],
      "bbox": [
        -2.734958094440727,
        42.46275295137993,
        -2.734958094440727,
        42.46275295137993
      ]
    },
    "26077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.409536955699701,
              42.2126876373822
            ]
          },
          "bbox": [
            -2.409536955699701,
            42.2126876373822,
            -2.409536955699701,
            42.2126876373822
          ]
        }
      ],
      "bbox": [
        -2.409536955699701,
        42.2126876373822,
        -2.409536955699701,
        42.2126876373822
      ]
    },
    "26078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.580528580855252,
              42.396675327167976
            ]
          },
          "bbox": [
            -2.580528580855252,
            42.396675327167976,
            -2.580528580855252,
            42.396675327167976
          ]
        }
      ],
      "bbox": [
        -2.580528580855252,
        42.396675327167976,
        -2.580528580855252,
        42.396675327167976
      ]
    },
    "26079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.666794622183022,
              42.43211699607121
            ]
          },
          "bbox": [
            -2.666794622183022,
            42.43211699607121,
            -2.666794622183022,
            42.43211699607121
          ]
        }
      ],
      "bbox": [
        -2.666794622183022,
        42.43211699607121,
        -2.666794622183022,
        42.43211699607121
      ]
    },
    "26080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.026349926212928,
              42.059024551040146
            ]
          },
          "bbox": [
            -2.026349926212928,
            42.059024551040146,
            -2.026349926212928,
            42.059024551040146
          ]
        }
      ],
      "bbox": [
        -2.026349926212928,
        42.059024551040146,
        -2.026349926212928,
        42.059024551040146
      ]
    },
    "26081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.494148971419574,
              42.214723949726576
            ]
          },
          "bbox": [
            -2.494148971419574,
            42.214723949726576,
            -2.494148971419574,
            42.214723949726576
          ]
        }
      ],
      "bbox": [
        -2.494148971419574,
        42.214723949726576,
        -2.494148971419574,
        42.214723949726576
      ]
    },
    "26082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.547233761212844,
              42.15788083004289
            ]
          },
          "bbox": [
            -2.547233761212844,
            42.15788083004289,
            -2.547233761212844,
            42.15788083004289
          ]
        }
      ],
      "bbox": [
        -2.547233761212844,
        42.15788083004289,
        -2.547233761212844,
        42.15788083004289
      ]
    },
    "26083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.339492519836759,
              42.333932453541586
            ]
          },
          "bbox": [
            -2.339492519836759,
            42.333932453541586,
            -2.339492519836759,
            42.333932453541586
          ]
        }
      ],
      "bbox": [
        -2.339492519836759,
        42.333932453541586,
        -2.339492519836759,
        42.333932453541586
      ]
    },
    "26084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4712354376384345,
              42.418169734532604
            ]
          },
          "bbox": [
            -2.4712354376384345,
            42.418169734532604,
            -2.4712354376384345,
            42.418169734532604
          ]
        }
      ],
      "bbox": [
        -2.4712354376384345,
        42.418169734532604,
        -2.4712354376384345,
        42.418169734532604
      ]
    },
    "26086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7058196559245338,
              42.31853192034879
            ]
          },
          "bbox": [
            -2.7058196559245338,
            42.31853192034879,
            -2.7058196559245338,
            42.31853192034879
          ]
        }
      ],
      "bbox": [
        -2.7058196559245338,
        42.31853192034879,
        -2.7058196559245338,
        42.31853192034879
      ]
    },
    "26087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0450223958113734,
              42.50827890108677
            ]
          },
          "bbox": [
            -3.0450223958113734,
            42.50827890108677,
            -3.0450223958113734,
            42.50827890108677
          ]
        }
      ],
      "bbox": [
        -3.0450223958113734,
        42.50827890108677,
        -3.0450223958113734,
        42.50827890108677
      ]
    },
    "26088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4072516238155246,
              42.329389686446724
            ]
          },
          "bbox": [
            -2.4072516238155246,
            42.329389686446724,
            -2.4072516238155246,
            42.329389686446724
          ]
        }
      ],
      "bbox": [
        -2.4072516238155246,
        42.329389686446724,
        -2.4072516238155246,
        42.329389686446724
      ]
    },
    "26089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.454322342225251,
              42.463911703851664
            ]
          },
          "bbox": [
            -2.454322342225251,
            42.463911703851664,
            -2.454322342225251,
            42.463911703851664
          ]
        }
      ],
      "bbox": [
        -2.454322342225251,
        42.463911703851664,
        -2.454322342225251,
        42.463911703851664
      ]
    },
    "26091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5932670547554437,
              42.07582164312067
            ]
          },
          "bbox": [
            -2.5932670547554437,
            42.07582164312067,
            -2.5932670547554437,
            42.07582164312067
          ]
        }
      ],
      "bbox": [
        -2.5932670547554437,
        42.07582164312067,
        -2.5932670547554437,
        42.07582164312067
      ]
    },
    "26092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6647737983022717,
              42.39089765430789
            ]
          },
          "bbox": [
            -2.6647737983022717,
            42.39089765430789,
            -2.6647737983022717,
            42.39089765430789
          ]
        }
      ],
      "bbox": [
        -2.6647737983022717,
        42.39089765430789,
        -2.6647737983022717,
        42.39089765430789
      ]
    },
    "26093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9214801318121575,
              42.124110203706316
            ]
          },
          "bbox": [
            -2.9214801318121575,
            42.124110203706316,
            -2.9214801318121575,
            42.124110203706316
          ]
        }
      ],
      "bbox": [
        -2.9214801318121575,
        42.124110203706316,
        -2.9214801318121575,
        42.124110203706316
      ]
    },
    "26094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.910514709310326,
              42.38409372750374
            ]
          },
          "bbox": [
            -2.910514709310326,
            42.38409372750374,
            -2.910514709310326,
            42.38409372750374
          ]
        }
      ],
      "bbox": [
        -2.910514709310326,
        42.38409372750374,
        -2.910514709310326,
        42.38409372750374
      ]
    },
    "26095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.79745103877893,
              42.29083804984908
            ]
          },
          "bbox": [
            -2.79745103877893,
            42.29083804984908,
            -2.79745103877893,
            42.29083804984908
          ]
        }
      ],
      "bbox": [
        -2.79745103877893,
        42.29083804984908,
        -2.79745103877893,
        42.29083804984908
      ]
    },
    "26096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5575410748457488,
              42.387876418007686
            ]
          },
          "bbox": [
            -2.5575410748457488,
            42.387876418007686,
            -2.5575410748457488,
            42.387876418007686
          ]
        }
      ],
      "bbox": [
        -2.5575410748457488,
        42.387876418007686,
        -2.5575410748457488,
        42.387876418007686
      ]
    },
    "26098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3305651948857387,
              42.20072541176444
            ]
          },
          "bbox": [
            -2.3305651948857387,
            42.20072541176444,
            -2.3305651948857387,
            42.20072541176444
          ]
        }
      ],
      "bbox": [
        -2.3305651948857387,
        42.20072541176444,
        -2.3305651948857387,
        42.20072541176444
      ]
    },
    "26099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3094919849040125,
              42.4025617678166
            ]
          },
          "bbox": [
            -2.3094919849040125,
            42.4025617678166,
            -2.3094919849040125,
            42.4025617678166
          ]
        }
      ],
      "bbox": [
        -2.3094919849040125,
        42.4025617678166,
        -2.3094919849040125,
        42.4025617678166
      ]
    },
    "26100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1297763150482387,
              42.12787113882438
            ]
          },
          "bbox": [
            -2.1297763150482387,
            42.12787113882438,
            -2.1297763150482387,
            42.12787113882438
          ]
        }
      ],
      "bbox": [
        -2.1297763150482387,
        42.12787113882438,
        -2.1297763150482387,
        42.12787113882438
      ]
    },
    "26101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.545183362886811,
              42.22189916191831
            ]
          },
          "bbox": [
            -2.545183362886811,
            42.22189916191831,
            -2.545183362886811,
            42.22189916191831
          ]
        }
      ],
      "bbox": [
        -2.545183362886811,
        42.22189916191831,
        -2.545183362886811,
        42.22189916191831
      ]
    },
    "26102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.744597347911464,
              42.41890397349012
            ]
          },
          "bbox": [
            -2.744597347911464,
            42.41890397349012,
            -2.744597347911464,
            42.41890397349012
          ]
        }
      ],
      "bbox": [
        -2.744597347911464,
        42.41890397349012,
        -2.744597347911464,
        42.41890397349012
      ]
    },
    "26103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.482444137394666,
              42.32704870543423
            ]
          },
          "bbox": [
            -2.482444137394666,
            42.32704870543423,
            -2.482444137394666,
            42.32704870543423
          ]
        }
      ],
      "bbox": [
        -2.482444137394666,
        42.32704870543423,
        -2.482444137394666,
        42.32704870543423
      ]
    },
    "26104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.089439225140057,
              41.969866782982535
            ]
          },
          "bbox": [
            -2.089439225140057,
            41.969866782982535,
            -2.089439225140057,
            41.969866782982535
          ]
        }
      ],
      "bbox": [
        -2.089439225140057,
        41.969866782982535,
        -2.089439225140057,
        41.969866782982535
      ]
    },
    "26105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5649292530870063,
              42.428939623235316
            ]
          },
          "bbox": [
            -2.5649292530870063,
            42.428939623235316,
            -2.5649292530870063,
            42.428939623235316
          ]
        }
      ],
      "bbox": [
        -2.5649292530870063,
        42.428939623235316,
        -2.5649292530870063,
        42.428939623235316
      ]
    },
    "26106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.615756477087877,
              42.288759666303505
            ]
          },
          "bbox": [
            -2.615756477087877,
            42.288759666303505,
            -2.615756477087877,
            42.288759666303505
          ]
        }
      ],
      "bbox": [
        -2.615756477087877,
        42.288759666303505,
        -2.615756477087877,
        42.288759666303505
      ]
    },
    "26107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.683378503116248,
              42.22088361303927
            ]
          },
          "bbox": [
            -2.683378503116248,
            42.22088361303927,
            -2.683378503116248,
            42.22088361303927
          ]
        }
      ],
      "bbox": [
        -2.683378503116248,
        42.22088361303927,
        -2.683378503116248,
        42.22088361303927
      ]
    },
    "26108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2077586195679624,
              42.30244263739132
            ]
          },
          "bbox": [
            -2.2077586195679624,
            42.30244263739132,
            -2.2077586195679624,
            42.30244263739132
          ]
        }
      ],
      "bbox": [
        -2.2077586195679624,
        42.30244263739132,
        -2.2077586195679624,
        42.30244263739132
      ]
    },
    "26109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0029103003225397,
              42.52421763131011
            ]
          },
          "bbox": [
            -3.0029103003225397,
            42.52421763131011,
            -3.0029103003225397,
            42.52421763131011
          ]
        }
      ],
      "bbox": [
        -3.0029103003225397,
        42.52421763131011,
        -3.0029103003225397,
        42.52421763131011
      ]
    },
    "26110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9999926324284476,
              42.35158902782092
            ]
          },
          "bbox": [
            -2.9999926324284476,
            42.35158902782092,
            -2.9999926324284476,
            42.35158902782092
          ]
        }
      ],
      "bbox": [
        -2.9999926324284476,
        42.35158902782092,
        -2.9999926324284476,
        42.35158902782092
      ]
    },
    "26111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8264894769544813,
              42.537329962823506
            ]
          },
          "bbox": [
            -2.8264894769544813,
            42.537329962823506,
            -2.8264894769544813,
            42.537329962823506
          ]
        }
      ],
      "bbox": [
        -2.8264894769544813,
        42.537329962823506,
        -2.8264894769544813,
        42.537329962823506
      ]
    },
    "26112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7143250085790216,
              42.16108930976223
            ]
          },
          "bbox": [
            -2.7143250085790216,
            42.16108930976223,
            -2.7143250085790216,
            42.16108930976223
          ]
        }
      ],
      "bbox": [
        -2.7143250085790216,
        42.16108930976223,
        -2.7143250085790216,
        42.16108930976223
      ]
    },
    "26113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9268487199727,
              42.3056311734235
            ]
          },
          "bbox": [
            -2.9268487199727,
            42.3056311734235,
            -2.9268487199727,
            42.3056311734235
          ]
        }
      ],
      "bbox": [
        -2.9268487199727,
        42.3056311734235,
        -2.9268487199727,
        42.3056311734235
      ]
    },
    "26114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6963706822630242,
              42.29434239817932
            ]
          },
          "bbox": [
            -2.6963706822630242,
            42.29434239817932,
            -2.6963706822630242,
            42.29434239817932
          ]
        }
      ],
      "bbox": [
        -2.6963706822630242,
        42.29434239817932,
        -2.6963706822630242,
        42.29434239817932
      ]
    },
    "26115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5955204669373395,
              42.19086500073393
            ]
          },
          "bbox": [
            -2.5955204669373395,
            42.19086500073393,
            -2.5955204669373395,
            42.19086500073393
          ]
        }
      ],
      "bbox": [
        -2.5955204669373395,
        42.19086500073393,
        -2.5955204669373395,
        42.19086500073393
      ]
    },
    "26117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.036218318536276,
              42.32780663587112
            ]
          },
          "bbox": [
            -2.036218318536276,
            42.32780663587112,
            -2.036218318536276,
            42.32780663587112
          ]
        }
      ],
      "bbox": [
        -2.036218318536276,
        42.32780663587112,
        -2.036218318536276,
        42.32780663587112
      ]
    },
    "26118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.642785725910086,
              42.18619523846857
            ]
          },
          "bbox": [
            -2.642785725910086,
            42.18619523846857,
            -2.642785725910086,
            42.18619523846857
          ]
        }
      ],
      "bbox": [
        -2.642785725910086,
        42.18619523846857,
        -2.642785725910086,
        42.18619523846857
      ]
    },
    "26119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1818397915811194,
              42.166314686128345
            ]
          },
          "bbox": [
            -2.1818397915811194,
            42.166314686128345,
            -2.1818397915811194,
            42.166314686128345
          ]
        }
      ],
      "bbox": [
        -2.1818397915811194,
        42.166314686128345,
        -2.1818397915811194,
        42.166314686128345
      ]
    },
    "26120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.043596914168954,
              42.20431237582257
            ]
          },
          "bbox": [
            -2.043596914168954,
            42.20431237582257,
            -2.043596914168954,
            42.20431237582257
          ]
        }
      ],
      "bbox": [
        -2.043596914168954,
        42.20431237582257,
        -2.043596914168954,
        42.20431237582257
      ]
    },
    "26121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4773260355850097,
              42.18523913840034
            ]
          },
          "bbox": [
            -2.4773260355850097,
            42.18523913840034,
            -2.4773260355850097,
            42.18523913840034
          ]
        }
      ],
      "bbox": [
        -2.4773260355850097,
        42.18523913840034,
        -2.4773260355850097,
        42.18523913840034
      ]
    },
    "26122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.720410806839584,
              42.19122335865729
            ]
          },
          "bbox": [
            -2.720410806839584,
            42.19122335865729,
            -2.720410806839584,
            42.19122335865729
          ]
        }
      ],
      "bbox": [
        -2.720410806839584,
        42.19122335865729,
        -2.720410806839584,
        42.19122335865729
      ]
    },
    "26123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2001182277456786,
              42.34646457346144
            ]
          },
          "bbox": [
            -2.2001182277456786,
            42.34646457346144,
            -2.2001182277456786,
            42.34646457346144
          ]
        }
      ],
      "bbox": [
        -2.2001182277456786,
        42.34646457346144,
        -2.2001182277456786,
        42.34646457346144
      ]
    },
    "26124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.369383601372426,
              42.361645539474516
            ]
          },
          "bbox": [
            -2.369383601372426,
            42.361645539474516,
            -2.369383601372426,
            42.361645539474516
          ]
        }
      ],
      "bbox": [
        -2.369383601372426,
        42.361645539474516,
        -2.369383601372426,
        42.361645539474516
      ]
    },
    "26125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8510281016133048,
              42.23058286211197
            ]
          },
          "bbox": [
            -1.8510281016133048,
            42.23058286211197,
            -1.8510281016133048,
            42.23058286211197
          ]
        }
      ],
      "bbox": [
        -1.8510281016133048,
        42.23058286211197,
        -1.8510281016133048,
        42.23058286211197
      ]
    },
    "26126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.290900736143199,
              42.25533201029602
            ]
          },
          "bbox": [
            -2.290900736143199,
            42.25533201029602,
            -2.290900736143199,
            42.25533201029602
          ]
        }
      ],
      "bbox": [
        -2.290900736143199,
        42.25533201029602,
        -2.290900736143199,
        42.25533201029602
      ]
    },
    "26127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8373762896706203,
              42.51915389080269
            ]
          },
          "bbox": [
            -2.8373762896706203,
            42.51915389080269,
            -2.8373762896706203,
            42.51915389080269
          ]
        }
      ],
      "bbox": [
        -2.8373762896706203,
        42.51915389080269,
        -2.8373762896706203,
        42.51915389080269
      ]
    },
    "26128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.950093200493765,
              42.59099095800202
            ]
          },
          "bbox": [
            -2.950093200493765,
            42.59099095800202,
            -2.950093200493765,
            42.59099095800202
          ]
        }
      ],
      "bbox": [
        -2.950093200493765,
        42.59099095800202,
        -2.950093200493765,
        42.59099095800202
      ]
    },
    "26129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7366040984669096,
              42.503919357494865
            ]
          },
          "bbox": [
            -2.7366040984669096,
            42.503919357494865,
            -2.7366040984669096,
            42.503919357494865
          ]
        }
      ],
      "bbox": [
        -2.7366040984669096,
        42.503919357494865,
        -2.7366040984669096,
        42.503919357494865
      ]
    },
    "26130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8999391518364344,
              42.29545095739237
            ]
          },
          "bbox": [
            -2.8999391518364344,
            42.29545095739237,
            -2.8999391518364344,
            42.29545095739237
          ]
        }
      ],
      "bbox": [
        -2.8999391518364344,
        42.29545095739237,
        -2.8999391518364344,
        42.29545095739237
      ]
    },
    "26131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1006022803645346,
              42.54683117053309
            ]
          },
          "bbox": [
            -3.1006022803645346,
            42.54683117053309,
            -3.1006022803645346,
            42.54683117053309
          ]
        }
      ],
      "bbox": [
        -3.1006022803645346,
        42.54683117053309,
        -3.1006022803645346,
        42.54683117053309
      ]
    },
    "26132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4600343868133514,
              42.22551776430861
            ]
          },
          "bbox": [
            -2.4600343868133514,
            42.22551776430861,
            -2.4600343868133514,
            42.22551776430861
          ]
        }
      ],
      "bbox": [
        -2.4600343868133514,
        42.22551776430861,
        -2.4600343868133514,
        42.22551776430861
      ]
    },
    "26134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6366659254154414,
              42.35888390152188
            ]
          },
          "bbox": [
            -2.6366659254154414,
            42.35888390152188,
            -2.6366659254154414,
            42.35888390152188
          ]
        }
      ],
      "bbox": [
        -2.6366659254154414,
        42.35888390152188,
        -2.6366659254154414,
        42.35888390152188
      ]
    },
    "26135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3094937699965046,
              42.307744615798796
            ]
          },
          "bbox": [
            -2.3094937699965046,
            42.307744615798796,
            -2.3094937699965046,
            42.307744615798796
          ]
        }
      ],
      "bbox": [
        -2.3094937699965046,
        42.307744615798796,
        -2.3094937699965046,
        42.307744615798796
      ]
    },
    "26136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.192796656495273,
              42.22633923772045
            ]
          },
          "bbox": [
            -2.192796656495273,
            42.22633923772045,
            -2.192796656495273,
            42.22633923772045
          ]
        }
      ],
      "bbox": [
        -2.192796656495273,
        42.22633923772045,
        -2.192796656495273,
        42.22633923772045
      ]
    },
    "26138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9567857848804433,
              42.43298121814444
            ]
          },
          "bbox": [
            -2.9567857848804433,
            42.43298121814444,
            -2.9567857848804433,
            42.43298121814444
          ]
        }
      ],
      "bbox": [
        -2.9567857848804433,
        42.43298121814444,
        -2.9567857848804433,
        42.43298121814444
      ]
    },
    "26139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.862985136758715,
              42.47860869217332
            ]
          },
          "bbox": [
            -2.862985136758715,
            42.47860869217332,
            -2.862985136758715,
            42.47860869217332
          ]
        }
      ],
      "bbox": [
        -2.862985136758715,
        42.47860869217332,
        -2.862985136758715,
        42.47860869217332
      ]
    },
    "26140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.990312124947177,
              42.389745390713315
            ]
          },
          "bbox": [
            -2.990312124947177,
            42.389745390713315,
            -2.990312124947177,
            42.389745390713315
          ]
        }
      ],
      "bbox": [
        -2.990312124947177,
        42.389745390713315,
        -2.990312124947177,
        42.389745390713315
      ]
    },
    "26141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9454974313170044,
              42.37056596179267
            ]
          },
          "bbox": [
            -2.9454974313170044,
            42.37056596179267,
            -2.9454974313170044,
            42.37056596179267
          ]
        }
      ],
      "bbox": [
        -2.9454974313170044,
        42.37056596179267,
        -2.9454974313170044,
        42.37056596179267
      ]
    },
    "26142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.74674595606854,
              42.57341163445954
            ]
          },
          "bbox": [
            -2.74674595606854,
            42.57341163445954,
            -2.74674595606854,
            42.57341163445954
          ]
        }
      ],
      "bbox": [
        -2.74674595606854,
        42.57341163445954,
        -2.74674595606854,
        42.57341163445954
      ]
    },
    "26143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5556170370719062,
              42.35784117435861
            ]
          },
          "bbox": [
            -2.5556170370719062,
            42.35784117435861,
            -2.5556170370719062,
            42.35784117435861
          ]
        }
      ],
      "bbox": [
        -2.5556170370719062,
        42.35784117435861,
        -2.5556170370719062,
        42.35784117435861
      ]
    },
    "26144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.528115890368871,
              42.34123797784493
            ]
          },
          "bbox": [
            -2.528115890368871,
            42.34123797784493,
            -2.528115890368871,
            42.34123797784493
          ]
        }
      ],
      "bbox": [
        -2.528115890368871,
        42.34123797784493,
        -2.528115890368871,
        42.34123797784493
      ]
    },
    "26145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.606452972492455,
              42.39503599384497
            ]
          },
          "bbox": [
            -2.606452972492455,
            42.39503599384497,
            -2.606452972492455,
            42.39503599384497
          ]
        }
      ],
      "bbox": [
        -2.606452972492455,
        42.39503599384497,
        -2.606452972492455,
        42.39503599384497
      ]
    },
    "26146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4280478402994072,
              42.28148949131625
            ]
          },
          "bbox": [
            -2.4280478402994072,
            42.28148949131625,
            -2.4280478402994072,
            42.28148949131625
          ]
        }
      ],
      "bbox": [
        -2.4280478402994072,
        42.28148949131625,
        -2.4280478402994072,
        42.28148949131625
      ]
    },
    "26147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.44020406781083,
              42.25552954361154
            ]
          },
          "bbox": [
            -2.44020406781083,
            42.25552954361154,
            -2.44020406781083,
            42.25552954361154
          ]
        }
      ],
      "bbox": [
        -2.44020406781083,
        42.25552954361154,
        -2.44020406781083,
        42.25552954361154
      ]
    },
    "26148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9408750619914077,
              42.54346644870121
            ]
          },
          "bbox": [
            -2.9408750619914077,
            42.54346644870121,
            -2.9408750619914077,
            42.54346644870121
          ]
        }
      ],
      "bbox": [
        -2.9408750619914077,
        42.54346644870121,
        -2.9408750619914077,
        42.54346644870121
      ]
    },
    "26149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.862249861072351,
              42.272136679061276
            ]
          },
          "bbox": [
            -2.862249861072351,
            42.272136679061276,
            -2.862249861072351,
            42.272136679061276
          ]
        }
      ],
      "bbox": [
        -2.862249861072351,
        42.272136679061276,
        -2.862249861072351,
        42.272136679061276
      ]
    },
    "26150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.070195258441841,
              42.491857591513664
            ]
          },
          "bbox": [
            -3.070195258441841,
            42.491857591513664,
            -3.070195258441841,
            42.491857591513664
          ]
        }
      ],
      "bbox": [
        -3.070195258441841,
        42.491857591513664,
        -3.070195258441841,
        42.491857591513664
      ]
    },
    "26151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6319632054853903,
              42.25749260385603
            ]
          },
          "bbox": [
            -2.6319632054853903,
            42.25749260385603,
            -2.6319632054853903,
            42.25749260385603
          ]
        }
      ],
      "bbox": [
        -2.6319632054853903,
        42.25749260385603,
        -2.6319632054853903,
        42.25749260385603
      ]
    },
    "26152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8415619943861383,
              42.4129874685112
            ]
          },
          "bbox": [
            -2.8415619943861383,
            42.4129874685112,
            -2.8415619943861383,
            42.4129874685112
          ]
        }
      ],
      "bbox": [
        -2.8415619943861383,
        42.4129874685112,
        -2.8415619943861383,
        42.4129874685112
      ]
    },
    "26153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.527986232529176,
              42.24602573101667
            ]
          },
          "bbox": [
            -2.527986232529176,
            42.24602573101667,
            -2.527986232529176,
            42.24602573101667
          ]
        }
      ],
      "bbox": [
        -2.527986232529176,
        42.24602573101667,
        -2.527986232529176,
        42.24602573101667
      ]
    },
    "26154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.686603378538789,
              42.50816456058512
            ]
          },
          "bbox": [
            -2.686603378538789,
            42.50816456058512,
            -2.686603378538789,
            42.50816456058512
          ]
        }
      ],
      "bbox": [
        -2.686603378538789,
        42.50816456058512,
        -2.686603378538789,
        42.50816456058512
      ]
    },
    "26155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0475523398392226,
              42.55767935164074
            ]
          },
          "bbox": [
            -3.0475523398392226,
            42.55767935164074,
            -3.0475523398392226,
            42.55767935164074
          ]
        }
      ],
      "bbox": [
        -3.0475523398392226,
        42.55767935164074,
        -3.0475523398392226,
        42.55767935164074
      ]
    },
    "26157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.712873618350069,
              42.40008087528478
            ]
          },
          "bbox": [
            -2.712873618350069,
            42.40008087528478,
            -2.712873618350069,
            42.40008087528478
          ]
        }
      ],
      "bbox": [
        -2.712873618350069,
        42.40008087528478,
        -2.712873618350069,
        42.40008087528478
      ]
    },
    "26158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1138203374568434,
              42.29141856077543
            ]
          },
          "bbox": [
            -2.1138203374568434,
            42.29141856077543,
            -2.1138203374568434,
            42.29141856077543
          ]
        }
      ],
      "bbox": [
        -2.1138203374568434,
        42.29141856077543,
        -2.1138203374568434,
        42.29141856077543
      ]
    },
    "26160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.701928641818076,
              42.45711896785349
            ]
          },
          "bbox": [
            -2.701928641818076,
            42.45711896785349,
            -2.701928641818076,
            42.45711896785349
          ]
        }
      ],
      "bbox": [
        -2.701928641818076,
        42.45711896785349,
        -2.701928641818076,
        42.45711896785349
      ]
    },
    "26161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0708619330914155,
              41.98705015834485
            ]
          },
          "bbox": [
            -2.0708619330914155,
            41.98705015834485,
            -2.0708619330914155,
            41.98705015834485
          ]
        }
      ],
      "bbox": [
        -2.0708619330914155,
        41.98705015834485,
        -2.0708619330914155,
        41.98705015834485
      ]
    },
    "26162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0791043833449865,
              42.321948175181745
            ]
          },
          "bbox": [
            -3.0791043833449865,
            42.321948175181745,
            -3.0791043833449865,
            42.321948175181745
          ]
        }
      ],
      "bbox": [
        -3.0791043833449865,
        42.321948175181745,
        -3.0791043833449865,
        42.321948175181745
      ]
    },
    "26163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6309291433579243,
              42.4028317203108
            ]
          },
          "bbox": [
            -2.6309291433579243,
            42.4028317203108,
            -2.6309291433579243,
            42.4028317203108
          ]
        }
      ],
      "bbox": [
        -2.6309291433579243,
        42.4028317203108,
        -2.6309291433579243,
        42.4028317203108
      ]
    },
    "26164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8398286727406172,
              42.16933724812201
            ]
          },
          "bbox": [
            -2.8398286727406172,
            42.16933724812201,
            -2.8398286727406172,
            42.16933724812201
          ]
        }
      ],
      "bbox": [
        -2.8398286727406172,
        42.16933724812201,
        -2.8398286727406172,
        42.16933724812201
      ]
    },
    "26165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5324478028697857,
              42.297530642167104
            ]
          },
          "bbox": [
            -2.5324478028697857,
            42.297530642167104,
            -2.5324478028697857,
            42.297530642167104
          ]
        }
      ],
      "bbox": [
        -2.5324478028697857,
        42.297530642167104,
        -2.5324478028697857,
        42.297530642167104
      ]
    },
    "26166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8928706596190708,
              42.60682551107528
            ]
          },
          "bbox": [
            -2.8928706596190708,
            42.60682551107528,
            -2.8928706596190708,
            42.60682551107528
          ]
        }
      ],
      "bbox": [
        -2.8928706596190708,
        42.60682551107528,
        -2.8928706596190708,
        42.60682551107528
      ]
    },
    "26167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9707042363579954,
              42.49000455432491
            ]
          },
          "bbox": [
            -2.9707042363579954,
            42.49000455432491,
            -2.9707042363579954,
            42.49000455432491
          ]
        }
      ],
      "bbox": [
        -2.9707042363579954,
        42.49000455432491,
        -2.9707042363579954,
        42.49000455432491
      ]
    },
    "26168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.401317932832026,
              42.42857783269583
            ]
          },
          "bbox": [
            -2.401317932832026,
            42.42857783269583,
            -2.401317932832026,
            42.42857783269583
          ]
        }
      ],
      "bbox": [
        -2.401317932832026,
        42.42857783269583,
        -2.401317932832026,
        42.42857783269583
      ]
    },
    "26169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6492731649589545,
              42.153252072204275
            ]
          },
          "bbox": [
            -2.6492731649589545,
            42.153252072204275,
            -2.6492731649589545,
            42.153252072204275
          ]
        }
      ],
      "bbox": [
        -2.6492731649589545,
        42.153252072204275,
        -2.6492731649589545,
        42.153252072204275
      ]
    },
    "26170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0716700209372227,
              42.31287506389992
            ]
          },
          "bbox": [
            -2.0716700209372227,
            42.31287506389992,
            -2.0716700209372227,
            42.31287506389992
          ]
        }
      ],
      "bbox": [
        -2.0716700209372227,
        42.31287506389992,
        -2.0716700209372227,
        42.31287506389992
      ]
    },
    "26171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8704761038007907,
              42.3651311305005
            ]
          },
          "bbox": [
            -2.8704761038007907,
            42.3651311305005,
            -2.8704761038007907,
            42.3651311305005
          ]
        }
      ],
      "bbox": [
        -2.8704761038007907,
        42.3651311305005,
        -2.8704761038007907,
        42.3651311305005
      ]
    },
    "26172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.891664992719792,
              42.36963337372435
            ]
          },
          "bbox": [
            -2.891664992719792,
            42.36963337372435,
            -2.891664992719792,
            42.36963337372435
          ]
        }
      ],
      "bbox": [
        -2.891664992719792,
        42.36963337372435,
        -2.891664992719792,
        42.36963337372435
      ]
    },
    "26173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0601626117805885,
              42.134228151599345
            ]
          },
          "bbox": [
            -2.0601626117805885,
            42.134228151599345,
            -2.0601626117805885,
            42.134228151599345
          ]
        }
      ],
      "bbox": [
        -2.0601626117805885,
        42.134228151599345,
        -2.0601626117805885,
        42.134228151599345
      ]
    },
    "26174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.058470279324195,
              42.406624060864
            ]
          },
          "bbox": [
            -3.058470279324195,
            42.406624060864,
            -3.058470279324195,
            42.406624060864
          ]
        }
      ],
      "bbox": [
        -3.058470279324195,
        42.406624060864,
        -3.058470279324195,
        42.406624060864
      ]
    },
    "26175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9860456827965414,
              42.15205468242044
            ]
          },
          "bbox": [
            -2.9860456827965414,
            42.15205468242044,
            -2.9860456827965414,
            42.15205468242044
          ]
        }
      ],
      "bbox": [
        -2.9860456827965414,
        42.15205468242044,
        -2.9860456827965414,
        42.15205468242044
      ]
    },
    "26176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.815415058313161,
              42.319507680922534
            ]
          },
          "bbox": [
            -2.815415058313161,
            42.319507680922534,
            -2.815415058313161,
            42.319507680922534
          ]
        }
      ],
      "bbox": [
        -2.815415058313161,
        42.319507680922534,
        -2.815415058313161,
        42.319507680922534
      ]
    },
    "26177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6919425151303518,
              42.06773287633709
            ]
          },
          "bbox": [
            -2.6919425151303518,
            42.06773287633709,
            -2.6919425151303518,
            42.06773287633709
          ]
        }
      ],
      "bbox": [
        -2.6919425151303518,
        42.06773287633709,
        -2.6919425151303518,
        42.06773287633709
      ]
    },
    "26178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8808852821881503,
              42.1174131650596
            ]
          },
          "bbox": [
            -2.8808852821881503,
            42.1174131650596,
            -2.8808852821881503,
            42.1174131650596
          ]
        }
      ],
      "bbox": [
        -2.8808852821881503,
        42.1174131650596,
        -2.8808852821881503,
        42.1174131650596
      ]
    },
    "26179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8247562384825193,
              42.07947063808308
            ]
          },
          "bbox": [
            -2.8247562384825193,
            42.07947063808308,
            -2.8247562384825193,
            42.07947063808308
          ]
        }
      ],
      "bbox": [
        -2.8247562384825193,
        42.07947063808308,
        -2.8247562384825193,
        42.07947063808308
      ]
    },
    "26180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.874998742888363,
              42.51856727972232
            ]
          },
          "bbox": [
            -2.874998742888363,
            42.51856727972232,
            -2.874998742888363,
            42.51856727972232
          ]
        }
      ],
      "bbox": [
        -2.874998742888363,
        42.51856727972232,
        -2.874998742888363,
        42.51856727972232
      ]
    },
    "26181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3544723515941106,
              42.173190307795196
            ]
          },
          "bbox": [
            -2.3544723515941106,
            42.173190307795196,
            -2.3544723515941106,
            42.173190307795196
          ]
        }
      ],
      "bbox": [
        -2.3544723515941106,
        42.173190307795196,
        -2.3544723515941106,
        42.173190307795196
      ]
    },
    "26183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.044300879277258,
              42.32133731503217
            ]
          },
          "bbox": [
            -3.044300879277258,
            42.32133731503217,
            -3.044300879277258,
            42.32133731503217
          ]
        }
      ],
      "bbox": [
        -3.044300879277258,
        42.32133731503217,
        -3.044300879277258,
        42.32133731503217
      ]
    },
    "27001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.4921497776542925,
              43.372557417489375
            ]
          },
          "bbox": [
            -7.4921497776542925,
            43.372557417489375,
            -7.4921497776542925,
            43.372557417489375
          ]
        }
      ],
      "bbox": [
        -7.4921497776542925,
        43.372557417489375,
        -7.4921497776542925,
        43.372557417489375
      ]
    },
    "27002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.433857854108204,
              43.50246123130485
            ]
          },
          "bbox": [
            -7.433857854108204,
            43.50246123130485,
            -7.433857854108204,
            43.50246123130485
          ]
        }
      ],
      "bbox": [
        -7.433857854108204,
        43.50246123130485,
        -7.433857854108204,
        43.50246123130485
      ]
    },
    "27003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.9045994558037185,
              42.77466930947229
            ]
          },
          "bbox": [
            -7.9045994558037185,
            42.77466930947229,
            -7.9045994558037185,
            42.77466930947229
          ]
        }
      ],
      "bbox": [
        -7.9045994558037185,
        42.77466930947229,
        -7.9045994558037185,
        42.77466930947229
      ]
    },
    "27004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.214766589529415,
              43.042527259280064
            ]
          },
          "bbox": [
            -7.214766589529415,
            43.042527259280064,
            -7.214766589529415,
            43.042527259280064
          ]
        }
      ],
      "bbox": [
        -7.214766589529415,
        43.042527259280064,
        -7.214766589529415,
        43.042527259280064
      ]
    },
    "27005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.229599200073013,
              43.51913516671631
            ]
          },
          "bbox": [
            -7.229599200073013,
            43.51913516671631,
            -7.229599200073013,
            43.51913516671631
          ]
        }
      ],
      "bbox": [
        -7.229599200073013,
        43.51913516671631,
        -7.229599200073013,
        43.51913516671631
      ]
    },
    "27006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.143311503459164,
              42.88265496215397
            ]
          },
          "bbox": [
            -7.143311503459164,
            42.88265496215397,
            -7.143311503459164,
            42.88265496215397
          ]
        }
      ],
      "bbox": [
        -7.143311503459164,
        42.88265496215397,
        -7.143311503459164,
        42.88265496215397
      ]
    },
    "27007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.693339797924935,
              43.157922389370654
            ]
          },
          "bbox": [
            -7.693339797924935,
            43.157922389370654,
            -7.693339797924935,
            43.157922389370654
          ]
        }
      ],
      "bbox": [
        -7.693339797924935,
        43.157922389370654,
        -7.693339797924935,
        43.157922389370654
      ]
    },
    "27008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.4954204092343035,
              42.635567154460624
            ]
          },
          "bbox": [
            -7.4954204092343035,
            42.635567154460624,
            -7.4954204092343035,
            42.635567154460624
          ]
        }
      ],
      "bbox": [
        -7.4954204092343035,
        42.635567154460624,
        -7.4954204092343035,
        42.635567154460624
      ]
    },
    "27009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.809423944733738,
              42.52372146362144
            ]
          },
          "bbox": [
            -7.809423944733738,
            42.52372146362144,
            -7.809423944733738,
            42.52372146362144
          ]
        }
      ],
      "bbox": [
        -7.809423944733738,
        42.52372146362144,
        -7.809423944733738,
        42.52372146362144
      ]
    },
    "27010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.439458617994913,
              43.17379987785848
            ]
          },
          "bbox": [
            -7.439458617994913,
            43.17379987785848,
            -7.439458617994913,
            43.17379987785848
          ]
        }
      ],
      "bbox": [
        -7.439458617994913,
        43.17379987785848,
        -7.439458617994913,
        43.17379987785848
      ]
    },
    "27011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.335509891259256,
              43.02316473749425
            ]
          },
          "bbox": [
            -7.335509891259256,
            43.02316473749425,
            -7.335509891259256,
            43.02316473749425
          ]
        }
      ],
      "bbox": [
        -7.335509891259256,
        43.02316473749425,
        -7.335509891259256,
        43.02316473749425
      ]
    },
    "27012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.966636042541152,
              42.83034473899205
            ]
          },
          "bbox": [
            -6.966636042541152,
            42.83034473899205,
            -6.966636042541152,
            42.83034473899205
          ]
        }
      ],
      "bbox": [
        -6.966636042541152,
        42.83034473899205,
        -6.966636042541152,
        42.83034473899205
      ]
    },
    "27013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.431641420734289,
              43.64391187128541
            ]
          },
          "bbox": [
            -7.431641420734289,
            43.64391187128541,
            -7.431641420734289,
            43.64391187128541
          ]
        }
      ],
      "bbox": [
        -7.431641420734289,
        43.64391187128541,
        -7.431641420734289,
        43.64391187128541
      ]
    },
    "27014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.434455473685798,
              42.930655955779216
            ]
          },
          "bbox": [
            -7.434455473685798,
            42.930655955779216,
            -7.434455473685798,
            42.930655955779216
          ]
        }
      ],
      "bbox": [
        -7.434455473685798,
        42.930655955779216,
        -7.434455473685798,
        42.930655955779216
      ]
    },
    "27015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.535428130744403,
              43.221888214354415
            ]
          },
          "bbox": [
            -7.535428130744403,
            43.221888214354415,
            -7.535428130744403,
            43.221888214354415
          ]
        }
      ],
      "bbox": [
        -7.535428130744403,
        43.221888214354415,
        -7.535428130744403,
        43.221888214354415
      ]
    },
    "27016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.804096708819956,
              42.612183591726534
            ]
          },
          "bbox": [
            -7.804096708819956,
            42.612183591726534,
            -7.804096708819956,
            42.612183591726534
          ]
        }
      ],
      "bbox": [
        -7.804096708819956,
        42.612183591726534,
        -7.804096708819956,
        42.612183591726534
      ]
    },
    "27017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.1744418594157136,
              42.612706579761465
            ]
          },
          "bbox": [
            -7.1744418594157136,
            42.612706579761465,
            -7.1744418594157136,
            42.612706579761465
          ]
        }
      ],
      "bbox": [
        -7.1744418594157136,
        42.612706579761465,
        -7.1744418594157136,
        42.612706579761465
      ]
    },
    "27018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.080199316528848,
              43.13382921746073
            ]
          },
          "bbox": [
            -7.080199316528848,
            43.13382921746073,
            -7.080199316528848,
            43.13382921746073
          ]
        }
      ],
      "bbox": [
        -7.080199316528848,
        43.13382921746073,
        -7.080199316528848,
        43.13382921746073
      ]
    },
    "27019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.3294900586247005,
              43.576905663759156
            ]
          },
          "bbox": [
            -7.3294900586247005,
            43.576905663759156,
            -7.3294900586247005,
            43.576905663759156
          ]
        }
      ],
      "bbox": [
        -7.3294900586247005,
        43.576905663759156,
        -7.3294900586247005,
        43.576905663759156
      ]
    },
    "27020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.813538794579202,
              43.040813529989826
            ]
          },
          "bbox": [
            -7.813538794579202,
            43.040813529989826,
            -7.813538794579202,
            43.040813529989826
          ]
        }
      ],
      "bbox": [
        -7.813538794579202,
        43.040813529989826,
        -7.813538794579202,
        43.040813529989826
      ]
    },
    "27021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.795959271456669,
              43.3738228654563
            ]
          },
          "bbox": [
            -7.795959271456669,
            43.3738228654563,
            -7.795959271456669,
            43.3738228654563
          ]
        }
      ],
      "bbox": [
        -7.795959271456669,
        43.3738228654563,
        -7.795959271456669,
        43.3738228654563
      ]
    },
    "27022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.856160095247229,
              43.2066198840925
            ]
          },
          "bbox": [
            -7.856160095247229,
            43.2066198840925,
            -7.856160095247229,
            43.2066198840925
          ]
        }
      ],
      "bbox": [
        -7.856160095247229,
        43.2066198840925,
        -7.856160095247229,
        43.2066198840925
      ]
    },
    "27023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.65787863879436,
              42.901632843899556
            ]
          },
          "bbox": [
            -7.65787863879436,
            42.901632843899556,
            -7.65787863879436,
            42.901632843899556
          ]
        }
      ],
      "bbox": [
        -7.65787863879436,
        42.901632843899556,
        -7.65787863879436,
        42.901632843899556
      ]
    },
    "27024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.3691151313786225,
              42.671590884729184
            ]
          },
          "bbox": [
            -7.3691151313786225,
            42.671590884729184,
            -7.3691151313786225,
            42.671590884729184
          ]
        }
      ],
      "bbox": [
        -7.3691151313786225,
        42.671590884729184,
        -7.3691151313786225,
        42.671590884729184
      ]
    },
    "27025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.511330345172413,
              43.66894143733395
            ]
          },
          "bbox": [
            -7.511330345172413,
            43.66894143733395,
            -7.511330345172413,
            43.66894143733395
          ]
        }
      ],
      "bbox": [
        -7.511330345172413,
        43.66894143733395,
        -7.511330345172413,
        43.66894143733395
      ]
    },
    "27026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.335677289655051,
              42.84543037838126
            ]
          },
          "bbox": [
            -7.335677289655051,
            42.84543037838126,
            -7.335677289655051,
            42.84543037838126
          ]
        }
      ],
      "bbox": [
        -7.335677289655051,
        42.84543037838126,
        -7.335677289655051,
        42.84543037838126
      ]
    },
    "27027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.283546035503797,
              43.454253487115196
            ]
          },
          "bbox": [
            -7.283546035503797,
            43.454253487115196,
            -7.283546035503797,
            43.454253487115196
          ]
        }
      ],
      "bbox": [
        -7.283546035503797,
        43.454253487115196,
        -7.283546035503797,
        43.454253487115196
      ]
    },
    "27028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.564790127685996,
              43.001305118885824
            ]
          },
          "bbox": [
            -7.564790127685996,
            43.001305118885824,
            -7.564790127685996,
            43.001305118885824
          ]
        }
      ],
      "bbox": [
        -7.564790127685996,
        43.001305118885824,
        -7.564790127685996,
        43.001305118885824
      ]
    },
    "27029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.260292099816984,
              43.22247524966608
            ]
          },
          "bbox": [
            -7.260292099816984,
            43.22247524966608,
            -7.260292099816984,
            43.22247524966608
          ]
        }
      ],
      "bbox": [
        -7.260292099816984,
        43.22247524966608,
        -7.260292099816984,
        43.22247524966608
      ]
    },
    "27030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.368141130224361,
              43.43953701436395
            ]
          },
          "bbox": [
            -7.368141130224361,
            43.43953701436395,
            -7.368141130224361,
            43.43953701436395
          ]
        }
      ],
      "bbox": [
        -7.368141130224361,
        43.43953701436395,
        -7.368141130224361,
        43.43953701436395
      ]
    },
    "27031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.493260648546692,
              42.51814880937368
            ]
          },
          "bbox": [
            -7.493260648546692,
            42.51814880937368,
            -7.493260648546692,
            42.51814880937368
          ]
        }
      ],
      "bbox": [
        -7.493260648546692,
        42.51814880937368,
        -7.493260648546692,
        42.51814880937368
      ]
    },
    "27032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.802563075024532,
              42.81693814484419
            ]
          },
          "bbox": [
            -7.802563075024532,
            42.81693814484419,
            -7.802563075024532,
            42.81693814484419
          ]
        }
      ],
      "bbox": [
        -7.802563075024532,
        42.81693814484419,
        -7.802563075024532,
        42.81693814484419
      ]
    },
    "27033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.664522209428991,
              43.48086245376823
            ]
          },
          "bbox": [
            -7.664522209428991,
            43.48086245376823,
            -7.664522209428991,
            43.48086245376823
          ]
        }
      ],
      "bbox": [
        -7.664522209428991,
        43.48086245376823,
        -7.664522209428991,
        43.48086245376823
      ]
    },
    "27034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.964275083096481,
              42.95749825561687
            ]
          },
          "bbox": [
            -6.964275083096481,
            42.95749825561687,
            -6.964275083096481,
            42.95749825561687
          ]
        }
      ],
      "bbox": [
        -6.964275083096481,
        42.95749825561687,
        -6.964275083096481,
        42.95749825561687
      ]
    },
    "27035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.890364100469576,
              43.12600337033169
            ]
          },
          "bbox": [
            -6.890364100469576,
            43.12600337033169,
            -6.890364100469576,
            43.12600337033169
          ]
        }
      ],
      "bbox": [
        -6.890364100469576,
        43.12600337033169,
        -6.890364100469576,
        43.12600337033169
      ]
    },
    "27037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.1012242265227,
              42.77510642535188
            ]
          },
          "bbox": [
            -7.1012242265227,
            42.77510642535188,
            -7.1012242265227,
            42.77510642535188
          ]
        }
      ],
      "bbox": [
        -7.1012242265227,
        42.77510642535188,
        -7.1012242265227,
        42.77510642535188
      ]
    },
    "27038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.642106465956282,
              43.56042303312241
            ]
          },
          "bbox": [
            -7.642106465956282,
            43.56042303312241,
            -7.642106465956282,
            43.56042303312241
          ]
        }
      ],
      "bbox": [
        -7.642106465956282,
        43.56042303312241,
        -7.642106465956282,
        43.56042303312241
      ]
    },
    "27039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.6051181963903876,
              43.1005661902206
            ]
          },
          "bbox": [
            -7.6051181963903876,
            43.1005661902206,
            -7.6051181963903876,
            43.1005661902206
          ]
        }
      ],
      "bbox": [
        -7.6051181963903876,
        43.1005661902206,
        -7.6051181963903876,
        43.1005661902206
      ]
    },
    "27040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.870313913567692,
              42.89618953644315
            ]
          },
          "bbox": [
            -7.870313913567692,
            42.89618953644315,
            -7.870313913567692,
            42.89618953644315
          ]
        }
      ],
      "bbox": [
        -7.870313913567692,
        42.89618953644315,
        -7.870313913567692,
        42.89618953644315
      ]
    },
    "27041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.6479399193024475,
              42.506063640719496
            ]
          },
          "bbox": [
            -7.6479399193024475,
            42.506063640719496,
            -7.6479399193024475,
            42.506063640719496
          ]
        }
      ],
      "bbox": [
        -7.6479399193024475,
        42.506063640719496,
        -7.6479399193024475,
        42.506063640719496
      ]
    },
    "27042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.577778854914525,
              42.74226057862259
            ]
          },
          "bbox": [
            -7.577778854914525,
            42.74226057862259,
            -7.577778854914525,
            42.74226057862259
          ]
        }
      ],
      "bbox": [
        -7.577778854914525,
        42.74226057862259,
        -7.577778854914525,
        42.74226057862259
      ]
    },
    "27043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.52474617057718,
              42.834573798492706
            ]
          },
          "bbox": [
            -7.52474617057718,
            42.834573798492706,
            -7.52474617057718,
            42.834573798492706
          ]
        }
      ],
      "bbox": [
        -7.52474617057718,
        42.834573798492706,
        -7.52474617057718,
        42.834573798492706
      ]
    },
    "27044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.35624093287476,
              43.30040709924213
            ]
          },
          "bbox": [
            -7.35624093287476,
            43.30040709924213,
            -7.35624093287476,
            43.30040709924213
          ]
        }
      ],
      "bbox": [
        -7.35624093287476,
        43.30040709924213,
        -7.35624093287476,
        43.30040709924213
      ]
    },
    "27045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.08356429400131,
              42.692391513869914
            ]
          },
          "bbox": [
            -7.08356429400131,
            42.692391513869914,
            -7.08356429400131,
            42.692391513869914
          ]
        }
      ],
      "bbox": [
        -7.08356429400131,
        42.692391513869914,
        -7.08356429400131,
        42.692391513869914
      ]
    },
    "27046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.331429836218825,
              43.14117605895339
            ]
          },
          "bbox": [
            -7.331429836218825,
            43.14117605895339,
            -7.331429836218825,
            43.14117605895339
          ]
        }
      ],
      "bbox": [
        -7.331429836218825,
        43.14117605895339,
        -7.331429836218825,
        43.14117605895339
      ]
    },
    "27047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.367293795876673,
              42.564056561495036
            ]
          },
          "bbox": [
            -7.367293795876673,
            42.564056561495036,
            -7.367293795876673,
            42.564056561495036
          ]
        }
      ],
      "bbox": [
        -7.367293795876673,
        42.564056561495036,
        -7.367293795876673,
        42.564056561495036
      ]
    },
    "27048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.183985073098025,
              43.32514701846755
            ]
          },
          "bbox": [
            -7.183985073098025,
            43.32514701846755,
            -7.183985073098025,
            43.32514701846755
          ]
        }
      ],
      "bbox": [
        -7.183985073098025,
        43.32514701846755,
        -7.183985073098025,
        43.32514701846755
      ]
    },
    "27049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.683268019144356,
              42.817136377464365
            ]
          },
          "bbox": [
            -7.683268019144356,
            42.817136377464365,
            -7.683268019144356,
            42.817136377464365
          ]
        }
      ],
      "bbox": [
        -7.683268019144356,
        42.817136377464365,
        -7.683268019144356,
        42.817136377464365
      ]
    },
    "27050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.193190414891529,
              42.49097755519846
            ]
          },
          "bbox": [
            -7.193190414891529,
            42.49097755519846,
            -7.193190414891529,
            42.49097755519846
          ]
        }
      ],
      "bbox": [
        -7.193190414891529,
        42.49097755519846,
        -7.193190414891529,
        42.49097755519846
      ]
    },
    "27051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.112939637154003,
              43.512294927570714
            ]
          },
          "bbox": [
            -7.112939637154003,
            43.512294927570714,
            -7.112939637154003,
            43.512294927570714
          ]
        }
      ],
      "bbox": [
        -7.112939637154003,
        43.512294927570714,
        -7.112939637154003,
        43.512294927570714
      ]
    },
    "27052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.294277253044109,
              42.43525688608549
            ]
          },
          "bbox": [
            -7.294277253044109,
            42.43525688608549,
            -7.294277253044109,
            42.43525688608549
          ]
        }
      ],
      "bbox": [
        -7.294277253044109,
        42.43525688608549,
        -7.294277253044109,
        42.43525688608549
      ]
    },
    "27053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.1958770441746305,
              43.19113170020136
            ]
          },
          "bbox": [
            -7.1958770441746305,
            43.19113170020136,
            -7.1958770441746305,
            43.19113170020136
          ]
        }
      ],
      "bbox": [
        -7.1958770441746305,
        43.19113170020136,
        -7.1958770441746305,
        43.19113170020136
      ]
    },
    "27054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.264784453079105,
              43.324787591690544
            ]
          },
          "bbox": [
            -7.264784453079105,
            43.324787591690544,
            -7.264784453079105,
            43.324787591690544
          ]
        }
      ],
      "bbox": [
        -7.264784453079105,
        43.324787591690544,
        -7.264784453079105,
        43.324787591690544
      ]
    },
    "27055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.277081935620164,
              42.71231583190904
            ]
          },
          "bbox": [
            -7.277081935620164,
            42.71231583190904,
            -7.277081935620164,
            42.71231583190904
          ]
        }
      ],
      "bbox": [
        -7.277081935620164,
        42.71231583190904,
        -7.277081935620164,
        42.71231583190904
      ]
    },
    "27056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.622470207506863,
              43.124644328114236
            ]
          },
          "bbox": [
            -7.622470207506863,
            43.124644328114236,
            -7.622470207506863,
            43.124644328114236
          ]
        }
      ],
      "bbox": [
        -7.622470207506863,
        43.124644328114236,
        -7.622470207506863,
        43.124644328114236
      ]
    },
    "27057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.431898178936252,
              42.771974013708046
            ]
          },
          "bbox": [
            -7.431898178936252,
            42.771974013708046,
            -7.431898178936252,
            42.771974013708046
          ]
        }
      ],
      "bbox": [
        -7.431898178936252,
        42.771974013708046,
        -7.431898178936252,
        42.771974013708046
      ]
    },
    "27058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.648242593807123,
              42.63954867796879
            ]
          },
          "bbox": [
            -7.648242593807123,
            42.63954867796879,
            -7.648242593807123,
            42.63954867796879
          ]
        }
      ],
      "bbox": [
        -7.648242593807123,
        42.63954867796879,
        -7.648242593807123,
        42.63954867796879
      ]
    },
    "27059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.554799678974914,
              42.43331086751551
            ]
          },
          "bbox": [
            -7.554799678974914,
            42.43331086751551,
            -7.554799678974914,
            42.43331086751551
          ]
        }
      ],
      "bbox": [
        -7.554799678974914,
        42.43331086751551,
        -7.554799678974914,
        42.43331086751551
      ]
    },
    "27060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.760122595060527,
              42.71828237475796
            ]
          },
          "bbox": [
            -7.760122595060527,
            42.71828237475796,
            -7.760122595060527,
            42.71828237475796
          ]
        }
      ],
      "bbox": [
        -7.760122595060527,
        42.71828237475796,
        -7.760122595060527,
        42.71828237475796
      ]
    },
    "27061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.178904444468938,
              43.44025014332262
            ]
          },
          "bbox": [
            -7.178904444468938,
            43.44025014332262,
            -7.178904444468938,
            43.44025014332262
          ]
        }
      ],
      "bbox": [
        -7.178904444468938,
        43.44025014332262,
        -7.178904444468938,
        43.44025014332262
      ]
    },
    "27062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.216570681850579,
              42.76174837980592
            ]
          },
          "bbox": [
            -7.216570681850579,
            42.76174837980592,
            -7.216570681850579,
            42.76174837980592
          ]
        }
      ],
      "bbox": [
        -7.216570681850579,
        42.76174837980592,
        -7.216570681850579,
        42.76174837980592
      ]
    },
    "27063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.481095608200806,
              43.53806327459498
            ]
          },
          "bbox": [
            -7.481095608200806,
            43.53806327459498,
            -7.481095608200806,
            43.53806327459498
          ]
        }
      ],
      "bbox": [
        -7.481095608200806,
        43.53806327459498,
        -7.481095608200806,
        43.53806327459498
      ]
    },
    "27064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.671231771347057,
              43.68279052496271
            ]
          },
          "bbox": [
            -7.671231771347057,
            43.68279052496271,
            -7.671231771347057,
            43.68279052496271
          ]
        }
      ],
      "bbox": [
        -7.671231771347057,
        43.68279052496271,
        -7.671231771347057,
        43.68279052496271
      ]
    },
    "27065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.66191315556897,
              43.31756884392536
            ]
          },
          "bbox": [
            -7.66191315556897,
            43.31756884392536,
            -7.66191315556897,
            43.31756884392536
          ]
        }
      ],
      "bbox": [
        -7.66191315556897,
        43.31756884392536,
        -7.66191315556897,
        43.31756884392536
      ]
    },
    "27066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.5881985032774555,
              43.633446398464514
            ]
          },
          "bbox": [
            -7.5881985032774555,
            43.633446398464514,
            -7.5881985032774555,
            43.633446398464514
          ]
        }
      ],
      "bbox": [
        -7.5881985032774555,
        43.633446398464514,
        -7.5881985032774555,
        43.633446398464514
      ]
    },
    "27901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.252554355095869,
              42.90674209826365
            ]
          },
          "bbox": [
            -7.252554355095869,
            42.90674209826365,
            -7.252554355095869,
            42.90674209826365
          ]
        }
      ],
      "bbox": [
        -7.252554355095869,
        42.90674209826365,
        -7.252554355095869,
        42.90674209826365
      ]
    },
    "27902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.365975151203535,
              43.657279101693135
            ]
          },
          "bbox": [
            -7.365975151203535,
            43.657279101693135,
            -7.365975151203535,
            43.657279101693135
          ]
        }
      ],
      "bbox": [
        -7.365975151203535,
        43.657279101693135,
        -7.365975151203535,
        43.657279101693135
      ]
    },
    "28001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6355625196909607,
              41.093157742750535
            ]
          },
          "bbox": [
            -3.6355625196909607,
            41.093157742750535,
            -3.6355625196909607,
            41.093157742750535
          ]
        }
      ],
      "bbox": [
        -3.6355625196909607,
        41.093157742750535,
        -3.6355625196909607,
        41.093157742750535
      ]
    },
    "28002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4802854929747555,
              40.523945337578155
            ]
          },
          "bbox": [
            -3.4802854929747555,
            40.523945337578155,
            -3.4802854929747555,
            40.523945337578155
          ]
        }
      ],
      "bbox": [
        -3.4802854929747555,
        40.523945337578155,
        -3.4802854929747555,
        40.523945337578155
      ]
    },
    "28003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8498235588206557,
              40.92032308583844
            ]
          },
          "bbox": [
            -3.8498235588206557,
            40.92032308583844,
            -3.8498235588206557,
            40.92032308583844
          ]
        }
      ],
      "bbox": [
        -3.8498235588206557,
        40.92032308583844,
        -3.8498235588206557,
        40.92032308583844
      ]
    },
    "28004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.989184883238388,
              40.232430139801664
            ]
          },
          "bbox": [
            -3.989184883238388,
            40.232430139801664,
            -3.989184883238388,
            40.232430139801664
          ]
        }
      ],
      "bbox": [
        -3.989184883238388,
        40.232430139801664,
        -3.989184883238388,
        40.232430139801664
      ]
    },
    "28005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.36942007920946,
              40.49452740068593
            ]
          },
          "bbox": [
            -3.36942007920946,
            40.49452740068593,
            -3.36942007920946,
            40.49452740068593
          ]
        }
      ],
      "bbox": [
        -3.36942007920946,
        40.49452740068593,
        -3.36942007920946,
        40.49452740068593
      ]
    },
    "28006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.630342895052993,
              40.53468729640223
            ]
          },
          "bbox": [
            -3.630342895052993,
            40.53468729640223,
            -3.630342895052993,
            40.53468729640223
          ]
        }
      ],
      "bbox": [
        -3.630342895052993,
        40.53468729640223,
        -3.630342895052993,
        40.53468729640223
      ]
    },
    "28007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8371662685606713,
              40.35851196867184
            ]
          },
          "bbox": [
            -3.8371662685606713,
            40.35851196867184,
            -3.8371662685606713,
            40.35851196867184
          ]
        }
      ],
      "bbox": [
        -3.8371662685606713,
        40.35851196867184,
        -3.8371662685606713,
        40.35851196867184
      ]
    },
    "28008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.223782199086752,
              40.30927114332252
            ]
          },
          "bbox": [
            -4.223782199086752,
            40.30927114332252,
            -4.223782199086752,
            40.30927114332252
          ]
        }
      ],
      "bbox": [
        -4.223782199086752,
        40.30927114332252,
        -4.223782199086752,
        40.30927114332252
      ]
    },
    "28009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5297261322341336,
              40.60824483947897
            ]
          },
          "bbox": [
            -3.5297261322341336,
            40.60824483947897,
            -3.5297261322341336,
            40.60824483947897
          ]
        }
      ],
      "bbox": [
        -3.5297261322341336,
        40.60824483947897,
        -3.5297261322341336,
        40.60824483947897
      ]
    },
    "28010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.020820459967184,
              40.66201209404814
            ]
          },
          "bbox": [
            -4.020820459967184,
            40.66201209404814,
            -4.020820459967184,
            40.66201209404814
          ]
        }
      ],
      "bbox": [
        -4.020820459967184,
        40.66201209404814,
        -4.020820459967184,
        40.66201209404814
      ]
    },
    "28011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.176668504453671,
              40.34056809160682
            ]
          },
          "bbox": [
            -3.176668504453671,
            40.34056809160682,
            -3.176668504453671,
            40.34056809160682
          ]
        }
      ],
      "bbox": [
        -3.176668504453671,
        40.34056809160682,
        -3.176668504453671,
        40.34056809160682
      ]
    },
    "28012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.276887632987283,
              40.46634369231607
            ]
          },
          "bbox": [
            -3.276887632987283,
            40.46634369231607,
            -3.276887632987283,
            40.46634369231607
          ]
        }
      ],
      "bbox": [
        -3.276887632987283,
        40.46634369231607,
        -3.276887632987283,
        40.46634369231607
      ]
    },
    "28013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.678741849136279,
              39.99489760921366
            ]
          },
          "bbox": [
            -3.678741849136279,
            39.99489760921366,
            -3.678741849136279,
            39.99489760921366
          ]
        }
      ],
      "bbox": [
        -3.678741849136279,
        39.99489760921366,
        -3.678741849136279,
        39.99489760921366
      ]
    },
    "28014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.445637391077643,
              40.29240592664928
            ]
          },
          "bbox": [
            -3.445637391077643,
            40.29240592664928,
            -3.445637391077643,
            40.29240592664928
          ]
        }
      ],
      "bbox": [
        -3.445637391077643,
        40.29240592664928,
        -3.445637391077643,
        40.29240592664928
      ]
    },
    "28015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.915776822338814,
              40.28000127493276
            ]
          },
          "bbox": [
            -3.915776822338814,
            40.28000127493276,
            -3.915776822338814,
            40.28000127493276
          ]
        }
      ],
      "bbox": [
        -3.915776822338814,
        40.28000127493276,
        -3.915776822338814,
        40.28000127493276
      ]
    },
    "28016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4666801283006334,
              40.93952146690699
            ]
          },
          "bbox": [
            -3.4666801283006334,
            40.93952146690699,
            -3.4666801283006334,
            40.93952146690699
          ]
        }
      ],
      "bbox": [
        -3.4666801283006334,
        40.93952146690699,
        -3.4666801283006334,
        40.93952146690699
      ]
    },
    "28017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.926114512793677,
              40.2226081071465
            ]
          },
          "bbox": [
            -3.926114512793677,
            40.2226081071465,
            -3.926114512793677,
            40.2226081071465
          ]
        }
      ],
      "bbox": [
        -3.926114512793677,
        40.2226081071465,
        -3.926114512793677,
        40.2226081071465
      ]
    },
    "28018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9369146758008227,
              40.705768068859946
            ]
          },
          "bbox": [
            -3.9369146758008227,
            40.705768068859946,
            -3.9369146758008227,
            40.705768068859946
          ]
        }
      ],
      "bbox": [
        -3.9369146758008227,
        40.705768068859946,
        -3.9369146758008227,
        40.705768068859946
      ]
    },
    "28019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.333704770460605,
              40.13754182959032
            ]
          },
          "bbox": [
            -3.333704770460605,
            40.13754182959032,
            -3.333704770460605,
            40.13754182959032
          ]
        }
      ],
      "bbox": [
        -3.333704770460605,
        40.13754182959032,
        -3.333704770460605,
        40.13754182959032
      ]
    },
    "28020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.528101116198794,
              40.97367342075623
            ]
          },
          "bbox": [
            -3.528101116198794,
            40.97367342075623,
            -3.528101116198794,
            40.97367342075623
          ]
        }
      ],
      "bbox": [
        -3.528101116198794,
        40.97367342075623,
        -3.528101116198794,
        40.97367342075623
      ]
    },
    "28021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5578680543547265,
              40.8819071877576
            ]
          },
          "bbox": [
            -3.5578680543547265,
            40.8819071877576,
            -3.5578680543547265,
            40.8819071877576
          ]
        }
      ],
      "bbox": [
        -3.5578680543547265,
        40.8819071877576,
        -3.5578680543547265,
        40.8819071877576
      ]
    },
    "28022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8926089585378265,
              40.42039374122047
            ]
          },
          "bbox": [
            -3.8926089585378265,
            40.42039374122047,
            -3.8926089585378265,
            40.42039374122047
          ]
        }
      ],
      "bbox": [
        -3.8926089585378265,
        40.42039374122047,
        -3.8926089585378265,
        40.42039374122047
      ]
    },
    "28023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.922041569075275,
              40.70919154826023
            ]
          },
          "bbox": [
            -3.922041569075275,
            40.70919154826023,
            -3.922041569075275,
            40.70919154826023
          ]
        }
      ],
      "bbox": [
        -3.922041569075275,
        40.70919154826023,
        -3.922041569075275,
        40.70919154826023
      ]
    },
    "28024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6597934039096223,
              41.05956613162875
            ]
          },
          "bbox": [
            -3.6597934039096223,
            41.05956613162875,
            -3.6597934039096223,
            41.05956613162875
          ]
        }
      ],
      "bbox": [
        -3.6597934039096223,
        41.05956613162875,
        -3.6597934039096223,
        41.05956613162875
      ]
    },
    "28025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1210651206899374,
              40.24063244269336
            ]
          },
          "bbox": [
            -3.1210651206899374,
            40.24063244269336,
            -3.1210651206899374,
            40.24063244269336
          ]
        }
      ],
      "bbox": [
        -3.1210651206899374,
        40.24063244269336,
        -3.1210651206899374,
        40.24063244269336
      ]
    },
    "28026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.994906424124679,
              40.397129338280536
            ]
          },
          "bbox": [
            -3.994906424124679,
            40.397129338280536,
            -3.994906424124679,
            40.397129338280536
          ]
        }
      ],
      "bbox": [
        -3.994906424124679,
        40.397129338280536,
        -3.994906424124679,
        40.397129338280536
      ]
    },
    "28027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6272194895521532,
              40.988460881731385
            ]
          },
          "bbox": [
            -3.6272194895521532,
            40.988460881731385,
            -3.6272194895521532,
            40.988460881731385
          ]
        }
      ],
      "bbox": [
        -3.6272194895521532,
        40.988460881731385,
        -3.6272194895521532,
        40.988460881731385
      ]
    },
    "28028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.728336267691451,
              40.84159108814609
            ]
          },
          "bbox": [
            -3.728336267691451,
            40.84159108814609,
            -3.728336267691451,
            40.84159108814609
          ]
        }
      ],
      "bbox": [
        -3.728336267691451,
        40.84159108814609,
        -3.728336267691451,
        40.84159108814609
      ]
    },
    "28029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.634657595001464,
              40.82562335057874
            ]
          },
          "bbox": [
            -3.634657595001464,
            40.82562335057874,
            -3.634657595001464,
            40.82562335057874
          ]
        }
      ],
      "bbox": [
        -3.634657595001464,
        40.82562335057874,
        -3.634657595001464,
        40.82562335057874
      ]
    },
    "28030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6132418290584645,
              40.86289592616813
            ]
          },
          "bbox": [
            -3.6132418290584645,
            40.86289592616813,
            -3.6132418290584645,
            40.86289592616813
          ]
        }
      ],
      "bbox": [
        -3.6132418290584645,
        40.86289592616813,
        -3.6132418290584645,
        40.86289592616813
      ]
    },
    "28031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.432579176763982,
              40.29848571601043
            ]
          },
          "bbox": [
            -4.432579176763982,
            40.29848571601043,
            -4.432579176763982,
            40.29848571601043
          ]
        }
      ],
      "bbox": [
        -4.432579176763982,
        40.29848571601043,
        -4.432579176763982,
        40.29848571601043
      ]
    },
    "28032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.375895691458987,
              40.56226064735108
            ]
          },
          "bbox": [
            -3.375895691458987,
            40.56226064735108,
            -3.375895691458987,
            40.56226064735108
          ]
        }
      ],
      "bbox": [
        -3.375895691458987,
        40.56226064735108,
        -3.375895691458987,
        40.56226064735108
      ]
    },
    "28033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3732732651218162,
              40.322813526841955
            ]
          },
          "bbox": [
            -3.3732732651218162,
            40.322813526841955,
            -3.3732732651218162,
            40.322813526841955
          ]
        }
      ],
      "bbox": [
        -3.3732732651218162,
        40.322813526841955,
        -3.3732732651218162,
        40.322813526841955
      ]
    },
    "28034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7601754654791484,
              40.8930261517544
            ]
          },
          "bbox": [
            -3.7601754654791484,
            40.8930261517544,
            -3.7601754654791484,
            40.8930261517544
          ]
        }
      ],
      "bbox": [
        -3.7601754654791484,
        40.8930261517544,
        -3.7601754654791484,
        40.8930261517544
      ]
    },
    "28035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2462404749481535,
              40.25440554534324
            ]
          },
          "bbox": [
            -3.2462404749481535,
            40.25440554534324,
            -3.2462404749481535,
            40.25440554534324
          ]
        }
      ],
      "bbox": [
        -3.2462404749481535,
        40.25440554534324,
        -3.2462404749481535,
        40.25440554534324
      ]
    },
    "28036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.832377334498133,
              40.17221537682184
            ]
          },
          "bbox": [
            -3.832377334498133,
            40.17221537682184,
            -3.832377334498133,
            40.17221537682184
          ]
        }
      ],
      "bbox": [
        -3.832377334498133,
        40.17221537682184,
        -3.832377334498133,
        40.17221537682184
      ]
    },
    "28037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.508485395006344,
              40.24397520356247
            ]
          },
          "bbox": [
            -4.508485395006344,
            40.24397520356247,
            -4.508485395006344,
            40.24397520356247
          ]
        }
      ],
      "bbox": [
        -4.508485395006344,
        40.24397520356247,
        -4.508485395006344,
        40.24397520356247
      ]
    },
    "28038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.059847612073871,
              40.75588174815783
            ]
          },
          "bbox": [
            -4.059847612073871,
            40.75588174815783,
            -4.059847612073871,
            40.75588174815783
          ]
        }
      ],
      "bbox": [
        -4.059847612073871,
        40.75588174815783,
        -4.059847612073871,
        40.75588174815783
      ]
    },
    "28039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.524199176047751,
              40.91665857490912
            ]
          },
          "bbox": [
            -3.524199176047751,
            40.91665857490912,
            -3.524199176047751,
            40.91665857490912
          ]
        }
      ],
      "bbox": [
        -3.524199176047751,
        40.91665857490912,
        -3.524199176047751,
        40.91665857490912
      ]
    },
    "28040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6073358745496344,
              40.151616220133576
            ]
          },
          "bbox": [
            -3.6073358745496344,
            40.151616220133576,
            -3.6073358745496344,
            40.151616220133576
          ]
        }
      ],
      "bbox": [
        -3.6073358745496344,
        40.151616220133576,
        -3.6073358745496344,
        40.151616220133576
      ]
    },
    "28041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5074788589103796,
              40.56825417156899
            ]
          },
          "bbox": [
            -3.5074788589103796,
            40.56825417156899,
            -3.5074788589103796,
            40.56825417156899
          ]
        }
      ],
      "bbox": [
        -3.5074788589103796,
        40.56825417156899,
        -3.5074788589103796,
        40.56825417156899
      ]
    },
    "28042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.192887136589571,
              40.40583889084821
            ]
          },
          "bbox": [
            -4.192887136589571,
            40.40583889084821,
            -4.192887136589571,
            40.40583889084821
          ]
        }
      ],
      "bbox": [
        -4.192887136589571,
        40.40583889084821,
        -4.192887136589571,
        40.40583889084821
      ]
    },
    "28043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4516769639690024,
              40.0810789846624
            ]
          },
          "bbox": [
            -3.4516769639690024,
            40.0810789846624,
            -3.4516769639690024,
            40.0810789846624
          ]
        }
      ],
      "bbox": [
        -3.4516769639690024,
        40.0810789846624,
        -3.4516769639690024,
        40.0810789846624
      ]
    },
    "28044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.018954925790607,
              40.53755504901022
            ]
          },
          "bbox": [
            -4.018954925790607,
            40.53755504901022,
            -4.018954925790607,
            40.53755504901022
          ]
        }
      ],
      "bbox": [
        -4.018954925790607,
        40.53755504901022,
        -4.018954925790607,
        40.53755504901022
      ]
    },
    "28045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7459067908924255,
              40.66396292171964
            ]
          },
          "bbox": [
            -3.7459067908924255,
            40.66396292171964,
            -3.7459067908924255,
            40.66396292171964
          ]
        }
      ],
      "bbox": [
        -3.7459067908924255,
        40.66396292171964,
        -3.7459067908924255,
        40.66396292171964
      ]
    },
    "28046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.03211993449541,
              40.693736222100945
            ]
          },
          "bbox": [
            -4.03211993449541,
            40.693736222100945,
            -4.03211993449541,
            40.693736222100945
          ]
        }
      ],
      "bbox": [
        -4.03211993449541,
        40.693736222100945,
        -4.03211993449541,
        40.693736222100945
      ]
    },
    "28047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9884550268867933,
              40.64133215609852
            ]
          },
          "bbox": [
            -3.9884550268867933,
            40.64133215609852,
            -3.9884550268867933,
            40.64133215609852
          ]
        }
      ],
      "bbox": [
        -3.9884550268867933,
        40.64133215609852,
        -3.9884550268867933,
        40.64133215609852
      ]
    },
    "28048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2481385236993856,
              40.418703005219484
            ]
          },
          "bbox": [
            -3.2481385236993856,
            40.418703005219484,
            -3.2481385236993856,
            40.418703005219484
          ]
        }
      ],
      "bbox": [
        -3.2481385236993856,
        40.418703005219484,
        -3.2481385236993856,
        40.418703005219484
      ]
    },
    "28049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5530094585625704,
              40.42673331830582
            ]
          },
          "bbox": [
            -3.5530094585625704,
            40.42673331830582,
            -3.5530094585625704,
            40.42673331830582
          ]
        }
      ],
      "bbox": [
        -3.5530094585625704,
        40.42673331830582,
        -3.5530094585625704,
        40.42673331830582
      ]
    },
    "28050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8368164679713397,
              40.19084434607254
            ]
          },
          "bbox": [
            -3.8368164679713397,
            40.19084434607254,
            -3.8368164679713397,
            40.19084434607254
          ]
        }
      ],
      "bbox": [
        -3.8368164679713397,
        40.19084434607254,
        -3.8368164679713397,
        40.19084434607254
      ]
    },
    "28051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.210531755609391,
              40.370710465338554
            ]
          },
          "bbox": [
            -4.210531755609391,
            40.370710465338554,
            -4.210531755609391,
            40.370710465338554
          ]
        }
      ],
      "bbox": [
        -4.210531755609391,
        40.370710465338554,
        -4.210531755609391,
        40.370710465338554
      ]
    },
    "28052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.478441026287953,
              40.16243337644905
            ]
          },
          "bbox": [
            -3.478441026287953,
            40.16243337644905,
            -3.478441026287953,
            40.16243337644905
          ]
        }
      ],
      "bbox": [
        -3.478441026287953,
        40.16243337644905,
        -3.478441026287953,
        40.16243337644905
      ]
    },
    "28053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4447426494279467,
              40.55944275671912
            ]
          },
          "bbox": [
            -3.4447426494279467,
            40.55944275671912,
            -3.4447426494279467,
            40.55944275671912
          ]
        }
      ],
      "bbox": [
        -3.4447426494279467,
        40.55944275671912,
        -3.4447426494279467,
        40.55944275671912
      ]
    },
    "28054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.094862021930816,
              40.585419505168325
            ]
          },
          "bbox": [
            -4.094862021930816,
            40.585419505168325,
            -4.094862021930816,
            40.585419505168325
          ]
        }
      ],
      "bbox": [
        -4.094862021930816,
        40.585419505168325,
        -4.094862021930816,
        40.585419505168325
      ]
    },
    "28055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1087577001843023,
              40.14625138389419
            ]
          },
          "bbox": [
            -3.1087577001843023,
            40.14625138389419,
            -3.1087577001843023,
            40.14625138389419
          ]
        }
      ],
      "bbox": [
        -3.1087577001843023,
        40.14625138389419,
        -3.1087577001843023,
        40.14625138389419
      ]
    },
    "28056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.183208063866055,
              40.47891117138958
            ]
          },
          "bbox": [
            -4.183208063866055,
            40.47891117138958,
            -4.183208063866055,
            40.47891117138958
          ]
        }
      ],
      "bbox": [
        -4.183208063866055,
        40.47891117138958,
        -4.183208063866055,
        40.47891117138958
      ]
    },
    "28057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.409473043746979,
              40.60902638553227
            ]
          },
          "bbox": [
            -3.409473043746979,
            40.60902638553227,
            -3.409473043746979,
            40.60902638553227
          ]
        }
      ],
      "bbox": [
        -3.409473043746979,
        40.60902638553227,
        -3.409473043746979,
        40.60902638553227
      ]
    },
    "28058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7968193921019853,
              40.28302915281451
            ]
          },
          "bbox": [
            -3.7968193921019853,
            40.28302915281451,
            -3.7968193921019853,
            40.28302915281451
          ]
        }
      ],
      "bbox": [
        -3.7968193921019853,
        40.28302915281451,
        -3.7968193921019853,
        40.28302915281451
      ]
    },
    "28059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.523718337602859,
              40.64218754509566
            ]
          },
          "bbox": [
            -3.523718337602859,
            40.64218754509566,
            -3.523718337602859,
            40.64218754509566
          ]
        }
      ],
      "bbox": [
        -3.523718337602859,
        40.64218754509566,
        -3.523718337602859,
        40.64218754509566
      ]
    },
    "28060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.159175995225485,
              40.110972780835304
            ]
          },
          "bbox": [
            -3.159175995225485,
            40.110972780835304,
            -3.159175995225485,
            40.110972780835304
          ]
        }
      ],
      "bbox": [
        -3.159175995225485,
        40.110972780835304,
        -3.159175995225485,
        40.110972780835304
      ]
    },
    "28061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.986043732799913,
              40.57484296765144
            ]
          },
          "bbox": [
            -3.986043732799913,
            40.57484296765144,
            -3.986043732799913,
            40.57484296765144
          ]
        }
      ],
      "bbox": [
        -3.986043732799913,
        40.57484296765144,
        -3.986043732799913,
        40.57484296765144
      ]
    },
    "28062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.684102108930445,
              40.92926821728283
            ]
          },
          "bbox": [
            -3.684102108930445,
            40.92926821728283,
            -3.684102108930445,
            40.92926821728283
          ]
        }
      ],
      "bbox": [
        -3.684102108930445,
        40.92926821728283,
        -3.684102108930445,
        40.92926821728283
      ]
    },
    "28063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.704258060184821,
              40.96696647645698
            ]
          },
          "bbox": [
            -3.704258060184821,
            40.96696647645698,
            -3.704258060184821,
            40.96696647645698
          ]
        }
      ],
      "bbox": [
        -3.704258060184821,
        40.96696647645698,
        -3.704258060184821,
        40.96696647645698
      ]
    },
    "28064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6735806518795093,
              41.035648643308704
            ]
          },
          "bbox": [
            -3.6735806518795093,
            41.035648643308704,
            -3.6735806518795093,
            41.035648643308704
          ]
        }
      ],
      "bbox": [
        -3.6735806518795093,
        41.035648643308704,
        -3.6735806518795093,
        41.035648643308704
      ]
    },
    "28065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6733766423333805,
              40.29922720659393
            ]
          },
          "bbox": [
            -3.6733766423333805,
            40.29922720659393,
            -3.6733766423333805,
            40.29922720659393
          ]
        }
      ],
      "bbox": [
        -3.6733766423333805,
        40.29922720659393,
        -3.6733766423333805,
        40.29922720659393
      ]
    },
    "28066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8425596339466015,
              40.217921829375186
            ]
          },
          "bbox": [
            -3.8425596339466015,
            40.217921829375186,
            -3.8425596339466015,
            40.217921829375186
          ]
        }
      ],
      "bbox": [
        -3.8425596339466015,
        40.217921829375186,
        -3.8425596339466015,
        40.217921829375186
      ]
    },
    "28067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6840299189019143,
              40.76852486687261
            ]
          },
          "bbox": [
            -3.6840299189019143,
            40.76852486687261,
            -3.6840299189019143,
            40.76852486687261
          ]
        }
      ],
      "bbox": [
        -3.6840299189019143,
        40.76852486687261,
        -3.6840299189019143,
        40.76852486687261
      ]
    },
    "28068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.109782463754711,
              40.677274375978456
            ]
          },
          "bbox": [
            -4.109782463754711,
            40.677274375978456,
            -4.109782463754711,
            40.677274375978456
          ]
        }
      ],
      "bbox": [
        -4.109782463754711,
        40.677274375978456,
        -4.109782463754711,
        40.677274375978456
      ]
    },
    "28069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4546896310697712,
              41.069297117782945
            ]
          },
          "bbox": [
            -3.4546896310697712,
            41.069297117782945,
            -3.4546896310697712,
            41.069297117782945
          ]
        }
      ],
      "bbox": [
        -3.4546896310697712,
        41.069297117782945,
        -3.4546896310697712,
        41.069297117782945
      ]
    },
    "28070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5825297171051176,
              41.07980185533049
            ]
          },
          "bbox": [
            -3.5825297171051176,
            41.07980185533049,
            -3.5825297171051176,
            41.07980185533049
          ]
        }
      ],
      "bbox": [
        -3.5825297171051176,
        41.07980185533049,
        -3.5825297171051176,
        41.07980185533049
      ]
    },
    "28071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5436520994016187,
              41.09119470595418
            ]
          },
          "bbox": [
            -3.5436520994016187,
            41.09119470595418,
            -3.5436520994016187,
            41.09119470595418
          ]
        }
      ],
      "bbox": [
        -3.5436520994016187,
        41.09119470595418,
        -3.5436520994016187,
        41.09119470595418
      ]
    },
    "28072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8930782385993505,
              40.61596824550763
            ]
          },
          "bbox": [
            -3.8930782385993505,
            40.61596824550763,
            -3.8930782385993505,
            40.61596824550763
          ]
        }
      ],
      "bbox": [
        -3.8930782385993505,
        40.61596824550763,
        -3.8930782385993505,
        40.61596824550763
      ]
    },
    "28073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.82621136575015,
              40.251539650105144
            ]
          },
          "bbox": [
            -3.82621136575015,
            40.251539650105144,
            -3.82621136575015,
            40.251539650105144
          ]
        }
      ],
      "bbox": [
        -3.82621136575015,
        40.251539650105144,
        -3.82621136575015,
        40.251539650105144
      ]
    },
    "28074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7714089039475853,
              40.330726614196095
            ]
          },
          "bbox": [
            -3.7714089039475853,
            40.330726614196095,
            -3.7714089039475853,
            40.330726614196095
          ]
        }
      ],
      "bbox": [
        -3.7714089039475853,
        40.330726614196095,
        -3.7714089039475853,
        40.330726614196095
      ]
    },
    "28075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.417526146956551,
              40.38236524453499
            ]
          },
          "bbox": [
            -3.417526146956551,
            40.38236524453499,
            -3.417526146956551,
            40.38236524453499
          ]
        }
      ],
      "bbox": [
        -3.417526146956551,
        40.38236524453499,
        -3.417526146956551,
        40.38236524453499
      ]
    },
    "28076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.784319006544911,
              40.962212919376846
            ]
          },
          "bbox": [
            -3.784319006544911,
            40.962212919376846,
            -3.784319006544911,
            40.962212919376846
          ]
        }
      ],
      "bbox": [
        -3.784319006544911,
        40.962212919376846,
        -3.784319006544911,
        40.962212919376846
      ]
    },
    "28078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.572111459273281,
              41.04139776874092
            ]
          },
          "bbox": [
            -3.572111459273281,
            41.04139776874092,
            -3.572111459273281,
            41.04139776874092
          ]
        }
      ],
      "bbox": [
        -3.572111459273281,
        41.04139776874092,
        -3.572111459273281,
        41.04139776874092
      ]
    },
    "28079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6964382210430697,
              40.46539137196477
            ]
          },
          "bbox": [
            -3.6964382210430697,
            40.46539137196477,
            -3.6964382210430697,
            40.46539137196477
          ]
        }
      ],
      "bbox": [
        -3.6964382210430697,
        40.46539137196477,
        -3.6964382210430697,
        40.46539137196477
      ]
    },
    "28080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.895034000600664,
              40.46870977987689
            ]
          },
          "bbox": [
            -3.895034000600664,
            40.46870977987689,
            -3.895034000600664,
            40.46870977987689
          ]
        }
      ],
      "bbox": [
        -3.895034000600664,
        40.46870977987689,
        -3.895034000600664,
        40.46870977987689
      ]
    },
    "28082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8778305162015037,
              40.75043559824136
            ]
          },
          "bbox": [
            -3.8778305162015037,
            40.75043559824136,
            -3.8778305162015037,
            40.75043559824136
          ]
        }
      ],
      "bbox": [
        -3.8778305162015037,
        40.75043559824136,
        -3.8778305162015037,
        40.75043559824136
      ]
    },
    "28083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.323024811446371,
              40.556972735443026
            ]
          },
          "bbox": [
            -3.323024811446371,
            40.556972735443026,
            -3.323024811446371,
            40.556972735443026
          ]
        }
      ],
      "bbox": [
        -3.323024811446371,
        40.556972735443026,
        -3.323024811446371,
        40.556972735443026
      ]
    },
    "28084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4751805523373083,
              40.400168013291186
            ]
          },
          "bbox": [
            -3.4751805523373083,
            40.400168013291186,
            -3.4751805523373083,
            40.400168013291186
          ]
        }
      ],
      "bbox": [
        -3.4751805523373083,
        40.400168013291186,
        -3.4751805523373083,
        40.400168013291186
      ]
    },
    "28085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.777463906782119,
              40.79700088680842
            ]
          },
          "bbox": [
            -3.777463906782119,
            40.79700088680842,
            -3.777463906782119,
            40.79700088680842
          ]
        }
      ],
      "bbox": [
        -3.777463906782119,
        40.79700088680842,
        -3.777463906782119,
        40.79700088680842
      ]
    },
    "28086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5712159396057266,
              40.700492140927516
            ]
          },
          "bbox": [
            -3.5712159396057266,
            40.700492140927516,
            -3.5712159396057266,
            40.700492140927516
          ]
        }
      ],
      "bbox": [
        -3.5712159396057266,
        40.700492140927516,
        -3.5712159396057266,
        40.700492140927516
      ]
    },
    "28087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.093023964852219,
              40.720980927771215
            ]
          },
          "bbox": [
            -4.093023964852219,
            40.720980927771215,
            -4.093023964852219,
            40.720980927771215
          ]
        }
      ],
      "bbox": [
        -4.093023964852219,
        40.720980927771215,
        -4.093023964852219,
        40.720980927771215
      ]
    },
    "28088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5115229553106353,
              41.09188386788546
            ]
          },
          "bbox": [
            -3.5115229553106353,
            41.09188386788546,
            -3.5115229553106353,
            41.09188386788546
          ]
        }
      ],
      "bbox": [
        -3.5115229553106353,
        41.09188386788546,
        -3.5115229553106353,
        41.09188386788546
      ]
    },
    "28089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8798134425553195,
              40.25778055096783
            ]
          },
          "bbox": [
            -3.8798134425553195,
            40.25778055096783,
            -3.8798134425553195,
            40.25778055096783
          ]
        }
      ],
      "bbox": [
        -3.8798134425553195,
        40.25778055096783,
        -3.8798134425553195,
        40.25778055096783
      ]
    },
    "28090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9469944969586823,
              40.66450302517095
            ]
          },
          "bbox": [
            -3.9469944969586823,
            40.66450302517095,
            -3.9469944969586823,
            40.66450302517095
          ]
        }
      ],
      "bbox": [
        -3.9469944969586823,
        40.66450302517095,
        -3.9469944969586823,
        40.66450302517095
      ]
    },
    "28091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4481628663377895,
              40.23122080857218
            ]
          },
          "bbox": [
            -3.4481628663377895,
            40.23122080857218,
            -3.4481628663377895,
            40.23122080857218
          ]
        }
      ],
      "bbox": [
        -3.4481628663377895,
        40.23122080857218,
        -3.4481628663377895,
        40.23122080857218
      ]
    },
    "28092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8903001841064473,
              40.318768462902966
            ]
          },
          "bbox": [
            -3.8903001841064473,
            40.318768462902966,
            -3.8903001841064473,
            40.318768462902966
          ]
        }
      ],
      "bbox": [
        -3.8903001841064473,
        40.318768462902966,
        -3.8903001841064473,
        40.318768462902966
      ]
    },
    "28093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0052525836173025,
              40.748974529842826
            ]
          },
          "bbox": [
            -4.0052525836173025,
            40.748974529842826,
            -4.0052525836173025,
            40.748974529842826
          ]
        }
      ],
      "bbox": [
        -4.0052525836173025,
        40.748974529842826,
        -4.0052525836173025,
        40.748974529842826
      ]
    },
    "28094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.676910717986673,
              40.81725017625036
            ]
          },
          "bbox": [
            -3.676910717986673,
            40.81725017625036,
            -3.676910717986673,
            40.81725017625036
          ]
        }
      ],
      "bbox": [
        -3.676910717986673,
        40.81725017625036,
        -3.676910717986673,
        40.81725017625036
      ]
    },
    "28095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.129870045988913,
              40.44008191921415
            ]
          },
          "bbox": [
            -4.129870045988913,
            40.44008191921415,
            -4.129870045988913,
            40.44008191921415
          ]
        }
      ],
      "bbox": [
        -4.129870045988913,
        40.44008191921415,
        -4.129870045988913,
        40.44008191921415
      ]
    },
    "28096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.014082540102361,
              40.28885069879189
            ]
          },
          "bbox": [
            -4.014082540102361,
            40.28885069879189,
            -4.014082540102361,
            40.28885069879189
          ]
        }
      ],
      "bbox": [
        -4.014082540102361,
        40.28885069879189,
        -4.014082540102361,
        40.28885069879189
      ]
    },
    "28097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7291085831056425,
              41.005664708531654
            ]
          },
          "bbox": [
            -3.7291085831056425,
            41.005664708531654,
            -3.7291085831056425,
            41.005664708531654
          ]
        }
      ],
      "bbox": [
        -3.7291085831056425,
        41.005664708531654,
        -3.7291085831056425,
        41.005664708531654
      ]
    },
    "28099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.270949465101916,
              40.382985949076335
            ]
          },
          "bbox": [
            -4.270949465101916,
            40.382985949076335,
            -4.270949465101916,
            40.382985949076335
          ]
        }
      ],
      "bbox": [
        -4.270949465101916,
        40.382985949076335,
        -4.270949465101916,
        40.382985949076335
      ]
    },
    "28100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2533308203570135,
              40.3750191207416
            ]
          },
          "bbox": [
            -3.2533308203570135,
            40.3750191207416,
            -3.2533308203570135,
            40.3750191207416
          ]
        }
      ],
      "bbox": [
        -3.2533308203570135,
        40.3750191207416,
        -3.2533308203570135,
        40.3750191207416
      ]
    },
    "28101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.206008152703202,
              40.368668270650986
            ]
          },
          "bbox": [
            -3.206008152703202,
            40.368668270650986,
            -3.206008152703202,
            40.368668270650986
          ]
        }
      ],
      "bbox": [
        -3.206008152703202,
        40.368668270650986,
        -3.206008152703202,
        40.368668270650986
      ]
    },
    "28102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.210599771939123,
              40.28978744163562
            ]
          },
          "bbox": [
            -3.210599771939123,
            40.28978744163562,
            -3.210599771939123,
            40.28978744163562
          ]
        }
      ],
      "bbox": [
        -3.210599771939123,
        40.28978744163562,
        -3.210599771939123,
        40.28978744163562
      ]
    },
    "28104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5246427948527215,
              40.51429526489732
            ]
          },
          "bbox": [
            -3.5246427948527215,
            40.51429526489732,
            -3.5246427948527215,
            40.51429526489732
          ]
        }
      ],
      "bbox": [
        -3.5246427948527215,
        40.51429526489732,
        -3.5246427948527215,
        40.51429526489732
      ]
    },
    "28106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7669255577769793,
              40.230606614953636
            ]
          },
          "bbox": [
            -3.7669255577769793,
            40.230606614953636,
            -3.7669255577769793,
            40.230606614953636
          ]
        }
      ],
      "bbox": [
        -3.7669255577769793,
        40.230606614953636,
        -3.7669255577769793,
        40.230606614953636
      ]
    },
    "28107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.484918924009865,
              40.88268363413119
            ]
          },
          "bbox": [
            -3.484918924009865,
            40.88268363413119,
            -3.484918924009865,
            40.88268363413119
          ]
        }
      ],
      "bbox": [
        -3.484918924009865,
        40.88268363413119,
        -3.484918924009865,
        40.88268363413119
      ]
    },
    "28108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6249091698535048,
              40.741931569303716
            ]
          },
          "bbox": [
            -3.6249091698535048,
            40.741931569303716,
            -3.6249091698535048,
            40.741931569303716
          ]
        }
      ],
      "bbox": [
        -3.6249091698535048,
        40.741931569303716,
        -3.6249091698535048,
        40.741931569303716
      ]
    },
    "28109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.327991831053836,
              40.3600085544861
            ]
          },
          "bbox": [
            -4.327991831053836,
            40.3600085544861,
            -4.327991831053836,
            40.3600085544861
          ]
        }
      ],
      "bbox": [
        -4.327991831053836,
        40.3600085544861,
        -4.327991831053836,
        40.3600085544861
      ]
    },
    "28110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3528095486159315,
              40.22633814607474
            ]
          },
          "bbox": [
            -3.3528095486159315,
            40.22633814607474,
            -3.3528095486159315,
            40.22633814607474
          ]
        }
      ],
      "bbox": [
        -3.3528095486159315,
        40.22633814607474,
        -3.3528095486159315,
        40.22633814607474
      ]
    },
    "28111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.178082008033801,
              40.4075705652431
            ]
          },
          "bbox": [
            -3.178082008033801,
            40.4075705652431,
            -3.178082008033801,
            40.4075705652431
          ]
        }
      ],
      "bbox": [
        -3.178082008033801,
        40.4075705652431,
        -3.178082008033801,
        40.4075705652431
      ]
    },
    "28112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.831203926171089,
              40.93809217928284
            ]
          },
          "bbox": [
            -3.831203926171089,
            40.93809217928284,
            -3.831203926171089,
            40.93809217928284
          ]
        }
      ],
      "bbox": [
        -3.831203926171089,
        40.93809217928284,
        -3.831203926171089,
        40.93809217928284
      ]
    },
    "28113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.685785272668403,
              40.246977536199516
            ]
          },
          "bbox": [
            -3.685785272668403,
            40.246977536199516,
            -3.685785272668403,
            40.246977536199516
          ]
        }
      ],
      "bbox": [
        -3.685785272668403,
        40.246977536199516,
        -3.685785272668403,
        40.246977536199516
      ]
    },
    "28114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5991278406412928,
              41.02928765322351
            ]
          },
          "bbox": [
            -3.5991278406412928,
            41.02928765322351,
            -3.5991278406412928,
            41.02928765322351
          ]
        }
      ],
      "bbox": [
        -3.5991278406412928,
        41.02928765322351,
        -3.5991278406412928,
        41.02928765322351
      ]
    },
    "28115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8136462122605583,
              40.42874146332123
            ]
          },
          "bbox": [
            -3.8136462122605583,
            40.42874146332123,
            -3.8136462122605583,
            40.42874146332123
          ]
        }
      ],
      "bbox": [
        -3.8136462122605583,
        40.42874146332123,
        -3.8136462122605583,
        40.42874146332123
      ]
    },
    "28116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.308978968373541,
              40.36142393933313
            ]
          },
          "bbox": [
            -3.308978968373541,
            40.36142393933313,
            -3.308978968373541,
            40.36142393933313
          ]
        }
      ],
      "bbox": [
        -3.308978968373541,
        40.36142393933313,
        -3.308978968373541,
        40.36142393933313
      ]
    },
    "28117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5095670934930903,
              41.03968414783016
            ]
          },
          "bbox": [
            -3.5095670934930903,
            41.03968414783016,
            -3.5095670934930903,
            41.03968414783016
          ]
        }
      ],
      "bbox": [
        -3.5095670934930903,
        41.03968414783016,
        -3.5095670934930903,
        41.03968414783016
      ]
    },
    "28118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.451808102932251,
              41.003713755103234
            ]
          },
          "bbox": [
            -3.451808102932251,
            41.003713755103234,
            -3.451808102932251,
            41.003713755103234
          ]
        }
      ],
      "bbox": [
        -3.451808102932251,
        41.003713755103234,
        -3.451808102932251,
        41.003713755103234
      ]
    },
    "28119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.060586072085904,
              40.418488085749686
            ]
          },
          "bbox": [
            -4.060586072085904,
            40.418488085749686,
            -4.060586072085904,
            40.418488085749686
          ]
        }
      ],
      "bbox": [
        -4.060586072085904,
        40.418488085749686,
        -4.060586072085904,
        40.418488085749686
      ]
    },
    "28120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8955365069489294,
              40.86405402684623
            ]
          },
          "bbox": [
            -3.8955365069489294,
            40.86405402684623,
            -3.8955365069489294,
            40.86405402684623
          ]
        }
      ],
      "bbox": [
        -3.8955365069489294,
        40.86405402684623,
        -3.8955365069489294,
        40.86405402684623
      ]
    },
    "28121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5940120366193633,
              40.816346897438336
            ]
          },
          "bbox": [
            -3.5940120366193633,
            40.816346897438336,
            -3.5940120366193633,
            40.816346897438336
          ]
        }
      ],
      "bbox": [
        -3.5940120366193633,
        40.816346897438336,
        -3.5940120366193633,
        40.816346897438336
      ]
    },
    "28122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.411385327263862,
              40.662551523861296
            ]
          },
          "bbox": [
            -3.411385327263862,
            40.662551523861296,
            -3.411385327263862,
            40.662551523861296
          ]
        }
      ],
      "bbox": [
        -3.411385327263862,
        40.662551523861296,
        -3.411385327263862,
        40.662551523861296
      ]
    },
    "28123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5283874097855343,
              40.3399306441124
            ]
          },
          "bbox": [
            -3.5283874097855343,
            40.3399306441124,
            -3.5283874097855343,
            40.3399306441124
          ]
        }
      ],
      "bbox": [
        -3.5283874097855343,
        40.3399306441124,
        -3.5283874097855343,
        40.3399306441124
      ]
    },
    "28124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5204566445283123,
              40.94853786507383
            ]
          },
          "bbox": [
            -3.5204566445283123,
            40.94853786507383,
            -3.5204566445283123,
            40.94853786507383
          ]
        }
      ],
      "bbox": [
        -3.5204566445283123,
        40.94853786507383,
        -3.5204566445283123,
        40.94853786507383
      ]
    },
    "28125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.242016075597504,
              40.48038079525884
            ]
          },
          "bbox": [
            -4.242016075597504,
            40.48038079525884,
            -4.242016075597504,
            40.48038079525884
          ]
        }
      ],
      "bbox": [
        -4.242016075597504,
        40.48038079525884,
        -4.242016075597504,
        40.48038079525884
      ]
    },
    "28126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.604927970845851,
              41.122791419447964
            ]
          },
          "bbox": [
            -3.604927970845851,
            41.122791419447964,
            -3.604927970845851,
            41.122791419447964
          ]
        }
      ],
      "bbox": [
        -3.604927970845851,
        41.122791419447964,
        -3.604927970845851,
        41.122791419447964
      ]
    },
    "28127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8919186996094774,
              40.521764920578946
            ]
          },
          "bbox": [
            -3.8919186996094774,
            40.521764920578946,
            -3.8919186996094774,
            40.521764920578946
          ]
        }
      ],
      "bbox": [
        -3.8919186996094774,
        40.521764920578946,
        -3.8919186996094774,
        40.521764920578946
      ]
    },
    "28128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.515396538669234,
              40.30273026299125
            ]
          },
          "bbox": [
            -4.515396538669234,
            40.30273026299125,
            -4.515396538669234,
            40.30273026299125
          ]
        }
      ],
      "bbox": [
        -4.515396538669234,
        40.30273026299125,
        -4.515396538669234,
        40.30273026299125
      ]
    },
    "28129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.632011176304822,
              40.686019863923576
            ]
          },
          "bbox": [
            -3.632011176304822,
            40.686019863923576,
            -3.632011176304822,
            40.686019863923576
          ]
        }
      ],
      "bbox": [
        -3.632011176304822,
        40.686019863923576,
        -3.632011176304822,
        40.686019863923576
      ]
    },
    "28130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4847932289497723,
              40.43602424849135
            ]
          },
          "bbox": [
            -3.4847932289497723,
            40.43602424849135,
            -3.4847932289497723,
            40.43602424849135
          ]
        }
      ],
      "bbox": [
        -3.4847932289497723,
        40.43602424849135,
        -3.4847932289497723,
        40.43602424849135
      ]
    },
    "28131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1301930689504225,
              40.61877604745786
            ]
          },
          "bbox": [
            -4.1301930689504225,
            40.61877604745786,
            -4.1301930689504225,
            40.61877604745786
          ]
        }
      ],
      "bbox": [
        -4.1301930689504225,
        40.61877604745786,
        -4.1301930689504225,
        40.61877604745786
      ]
    },
    "28132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.561772585365886,
              40.24304819756399
            ]
          },
          "bbox": [
            -3.561772585365886,
            40.24304819756399,
            -3.561772585365886,
            40.24304819756399
          ]
        }
      ],
      "bbox": [
        -3.561772585365886,
        40.24304819756399,
        -3.561772585365886,
        40.24304819756399
      ]
    },
    "28133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.365901729071216,
              40.361898692168744
            ]
          },
          "bbox": [
            -4.365901729071216,
            40.361898692168744,
            -4.365901729071216,
            40.361898692168744
          ]
        }
      ],
      "bbox": [
        -4.365901729071216,
        40.361898692168744,
        -4.365901729071216,
        40.361898692168744
      ]
    },
    "28134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5993913152915598,
              40.57505934224482
            ]
          },
          "bbox": [
            -3.5993913152915598,
            40.57505934224482,
            -3.5993913152915598,
            40.57505934224482
          ]
        }
      ],
      "bbox": [
        -3.5993913152915598,
        40.57505934224482,
        -3.5993913152915598,
        40.57505934224482
      ]
    },
    "28135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.245260330361367,
              40.59469285795192
            ]
          },
          "bbox": [
            -4.245260330361367,
            40.59469285795192,
            -4.245260330361367,
            40.59469285795192
          ]
        }
      ],
      "bbox": [
        -4.245260330361367,
        40.59469285795192,
        -4.245260330361367,
        40.59469285795192
      ]
    },
    "28136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2235249696512254,
              40.47129897283644
            ]
          },
          "bbox": [
            -3.2235249696512254,
            40.47129897283644,
            -3.2235249696512254,
            40.47129897283644
          ]
        }
      ],
      "bbox": [
        -3.2235249696512254,
        40.47129897283644,
        -3.2235249696512254,
        40.47129897283644
      ]
    },
    "28137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2556212259932558,
              40.51268815721626
            ]
          },
          "bbox": [
            -3.2556212259932558,
            40.51268815721626,
            -3.2556212259932558,
            40.51268815721626
          ]
        }
      ],
      "bbox": [
        -3.2556212259932558,
        40.51268815721626,
        -3.2556212259932558,
        40.51268815721626
      ]
    },
    "28138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6222968663287745,
              41.03710804677362
            ]
          },
          "bbox": [
            -3.6222968663287745,
            41.03710804677362,
            -3.6222968663287745,
            41.03710804677362
          ]
        }
      ],
      "bbox": [
        -3.6222968663287745,
        41.03710804677362,
        -3.6222968663287745,
        41.03710804677362
      ]
    },
    "28140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.893369722860853,
              40.207152156014
            ]
          },
          "bbox": [
            -3.893369722860853,
            40.207152156014,
            -3.893369722860853,
            40.207152156014
          ]
        }
      ],
      "bbox": [
        -3.893369722860853,
        40.207152156014,
        -3.893369722860853,
        40.207152156014
      ]
    },
    "28141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.038637837393742,
              40.35391693562312
            ]
          },
          "bbox": [
            -4.038637837393742,
            40.35391693562312,
            -4.038637837393742,
            40.35391693562312
          ]
        }
      ],
      "bbox": [
        -4.038637837393742,
        40.35391693562312,
        -4.038637837393742,
        40.35391693562312
      ]
    },
    "28143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5686200580747633,
              41.14164237482191
            ]
          },
          "bbox": [
            -3.5686200580747633,
            41.14164237482191,
            -3.5686200580747633,
            41.14164237482191
          ]
        }
      ],
      "bbox": [
        -3.5686200580747633,
        41.14164237482191,
        -3.5686200580747633,
        41.14164237482191
      ]
    },
    "28144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7879979670237667,
              40.747528929264185
            ]
          },
          "bbox": [
            -3.7879979670237667,
            40.747528929264185,
            -3.7879979670237667,
            40.747528929264185
          ]
        }
      ],
      "bbox": [
        -3.7879979670237667,
        40.747528929264185,
        -3.7879979670237667,
        40.747528929264185
      ]
    },
    "28145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.491167405300543,
              40.73703691824261
            ]
          },
          "bbox": [
            -3.491167405300543,
            40.73703691824261,
            -3.491167405300543,
            40.73703691824261
          ]
        }
      ],
      "bbox": [
        -3.491167405300543,
        40.73703691824261,
        -3.491167405300543,
        40.73703691824261
      ]
    },
    "28146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3021564560653776,
              40.23336426588623
            ]
          },
          "bbox": [
            -3.3021564560653776,
            40.23336426588623,
            -3.3021564560653776,
            40.23336426588623
          ]
        }
      ],
      "bbox": [
        -3.3021564560653776,
        40.23336426588623,
        -3.3021564560653776,
        40.23336426588623
      ]
    },
    "28147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5607989272070757,
              40.1458612308622
            ]
          },
          "bbox": [
            -3.5607989272070757,
            40.1458612308622,
            -3.5607989272070757,
            40.1458612308622
          ]
        }
      ],
      "bbox": [
        -3.5607989272070757,
        40.1458612308622,
        -3.5607989272070757,
        40.1458612308622
      ]
    },
    "28148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4602884569323447,
              40.46999243716724
            ]
          },
          "bbox": [
            -3.4602884569323447,
            40.46999243716724,
            -3.4602884569323447,
            40.46999243716724
          ]
        }
      ],
      "bbox": [
        -3.4602884569323447,
        40.46999243716724,
        -3.4602884569323447,
        40.46999243716724
      ]
    },
    "28149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7983491546442596,
              40.20304365200035
            ]
          },
          "bbox": [
            -3.7983491546442596,
            40.20304365200035,
            -3.7983491546442596,
            40.20304365200035
          ]
        }
      ],
      "bbox": [
        -3.7983491546442596,
        40.20304365200035,
        -3.7983491546442596,
        40.20304365200035
      ]
    },
    "28150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.747150045669554,
              40.174555404895905
            ]
          },
          "bbox": [
            -3.747150045669554,
            40.174555404895905,
            -3.747150045669554,
            40.174555404895905
          ]
        }
      ],
      "bbox": [
        -3.747150045669554,
        40.174555404895905,
        -3.747150045669554,
        40.174555404895905
      ]
    },
    "28151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5473321688074164,
              40.82271635975528
            ]
          },
          "bbox": [
            -3.5473321688074164,
            40.82271635975528,
            -3.5473321688074164,
            40.82271635975528
          ]
        }
      ],
      "bbox": [
        -3.5473321688074164,
        40.82271635975528,
        -3.5473321688074164,
        40.82271635975528
      ]
    },
    "28152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.926199804997031,
              40.575726187333125
            ]
          },
          "bbox": [
            -3.926199804997031,
            40.575726187333125,
            -3.926199804997031,
            40.575726187333125
          ]
        }
      ],
      "bbox": [
        -3.926199804997031,
        40.575726187333125,
        -3.926199804997031,
        40.575726187333125
      ]
    },
    "28153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5004003941106494,
              40.833669402473944
            ]
          },
          "bbox": [
            -3.5004003941106494,
            40.833669402473944,
            -3.5004003941106494,
            40.833669402473944
          ]
        }
      ],
      "bbox": [
        -3.5004003941106494,
        40.833669402473944,
        -3.5004003941106494,
        40.833669402473944
      ]
    },
    "28154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3684220178067332,
              40.410175870152315
            ]
          },
          "bbox": [
            -3.3684220178067332,
            40.410175870152315,
            -3.3684220178067332,
            40.410175870152315
          ]
        }
      ],
      "bbox": [
        -3.3684220178067332,
        40.410175870152315,
        -3.3684220178067332,
        40.410175870152315
      ]
    },
    "28155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1992923443774943,
              40.198256654371384
            ]
          },
          "bbox": [
            -3.1992923443774943,
            40.198256654371384,
            -3.1992923443774943,
            40.198256654371384
          ]
        }
      ],
      "bbox": [
        -3.1992923443774943,
        40.198256654371384,
        -3.1992923443774943,
        40.198256654371384
      ]
    },
    "28156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3502791559556857,
              40.622675792897
            ]
          },
          "bbox": [
            -3.3502791559556857,
            40.622675792897,
            -3.3502791559556857,
            40.622675792897
          ]
        }
      ],
      "bbox": [
        -3.3502791559556857,
        40.622675792897,
        -3.3502791559556857,
        40.622675792897
      ]
    },
    "28157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3722433941921244,
              40.176113571073856
            ]
          },
          "bbox": [
            -3.3722433941921244,
            40.176113571073856,
            -3.3722433941921244,
            40.176113571073856
          ]
        }
      ],
      "bbox": [
        -3.3722433941921244,
        40.176113571073856,
        -3.3722433941921244,
        40.176113571073856
      ]
    },
    "28158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6656212200997724,
              40.86278330874371
            ]
          },
          "bbox": [
            -3.6656212200997724,
            40.86278330874371,
            -3.6656212200997724,
            40.86278330874371
          ]
        }
      ],
      "bbox": [
        -3.6656212200997724,
        40.86278330874371,
        -3.6656212200997724,
        40.86278330874371
      ]
    },
    "28159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2995871798982535,
              40.50696590730972
            ]
          },
          "bbox": [
            -4.2995871798982535,
            40.50696590730972,
            -4.2995871798982535,
            40.50696590730972
          ]
        }
      ],
      "bbox": [
        -4.2995871798982535,
        40.50696590730972,
        -4.2995871798982535,
        40.50696590730972
      ]
    },
    "28160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.068659646426034,
              40.50400034218334
            ]
          },
          "bbox": [
            -4.068659646426034,
            40.50400034218334,
            -4.068659646426034,
            40.50400034218334
          ]
        }
      ],
      "bbox": [
        -4.068659646426034,
        40.50400034218334,
        -4.068659646426034,
        40.50400034218334
      ]
    },
    "28161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6575867383151177,
              40.1834374463559
            ]
          },
          "bbox": [
            -3.6575867383151177,
            40.1834374463559,
            -3.6575867383151177,
            40.1834374463559
          ]
        }
      ],
      "bbox": [
        -3.6575867383151177,
        40.1834374463559,
        -3.6575867383151177,
        40.1834374463559
      ]
    },
    "28162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4618852980027524,
              40.636346284335175
            ]
          },
          "bbox": [
            -3.4618852980027524,
            40.636346284335175,
            -3.4618852980027524,
            40.636346284335175
          ]
        }
      ],
      "bbox": [
        -3.4618852980027524,
        40.636346284335175,
        -3.4618852980027524,
        40.636346284335175
      ]
    },
    "28163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4708973908471275,
              40.76417313489696
            ]
          },
          "bbox": [
            -3.4708973908471275,
            40.76417313489696,
            -3.4708973908471275,
            40.76417313489696
          ]
        }
      ],
      "bbox": [
        -3.4708973908471275,
        40.76417313489696,
        -3.4708973908471275,
        40.76417313489696
      ]
    },
    "28164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5106970935518627,
              40.69026726786185
            ]
          },
          "bbox": [
            -3.5106970935518627,
            40.69026726786185,
            -3.5106970935518627,
            40.69026726786185
          ]
        }
      ],
      "bbox": [
        -3.5106970935518627,
        40.69026726786185,
        -3.5106970935518627,
        40.69026726786185
      ]
    },
    "28165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3036553254311958,
              40.29505654688868
            ]
          },
          "bbox": [
            -3.3036553254311958,
            40.29505654688868,
            -3.3036553254311958,
            40.29505654688868
          ]
        }
      ],
      "bbox": [
        -3.3036553254311958,
        40.29505654688868,
        -3.3036553254311958,
        40.29505654688868
      ]
    },
    "28166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.300907619626844,
              40.40481334383087
            ]
          },
          "bbox": [
            -3.300907619626844,
            40.40481334383087,
            -3.300907619626844,
            40.40481334383087
          ]
        }
      ],
      "bbox": [
        -3.300907619626844,
        40.40481334383087,
        -3.300907619626844,
        40.40481334383087
      ]
    },
    "28167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4758467321019766,
              40.3638282529905
            ]
          },
          "bbox": [
            -3.4758467321019766,
            40.3638282529905,
            -3.4758467321019766,
            40.3638282529905
          ]
        }
      ],
      "bbox": [
        -3.4758467321019766,
        40.3638282529905,
        -3.4758467321019766,
        40.3638282529905
      ]
    },
    "28168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.560886160580878,
              40.76905630914627
            ]
          },
          "bbox": [
            -3.560886160580878,
            40.76905630914627,
            -3.560886160580878,
            40.76905630914627
          ]
        }
      ],
      "bbox": [
        -3.560886160580878,
        40.76905630914627,
        -3.560886160580878,
        40.76905630914627
      ]
    },
    "28169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6221783292422773,
              40.79728305368738
            ]
          },
          "bbox": [
            -3.6221783292422773,
            40.79728305368738,
            -3.6221783292422773,
            40.79728305368738
          ]
        }
      ],
      "bbox": [
        -3.6221783292422773,
        40.79728305368738,
        -3.6221783292422773,
        40.79728305368738
      ]
    },
    "28170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.499241951823405,
              40.112609540590356
            ]
          },
          "bbox": [
            -3.499241951823405,
            40.112609540590356,
            -3.499241951823405,
            40.112609540590356
          ]
        }
      ],
      "bbox": [
        -3.499241951823405,
        40.112609540590356,
        -3.499241951823405,
        40.112609540590356
      ]
    },
    "28171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.306216494270479,
              40.275211811023205
            ]
          },
          "bbox": [
            -4.306216494270479,
            40.275211811023205,
            -4.306216494270479,
            40.275211811023205
          ]
        }
      ],
      "bbox": [
        -4.306216494270479,
        40.275211811023205,
        -4.306216494270479,
        40.275211811023205
      ]
    },
    "28172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.325100884274331,
              40.44229152653074
            ]
          },
          "bbox": [
            -3.325100884274331,
            40.44229152653074,
            -3.325100884274331,
            40.44229152653074
          ]
        }
      ],
      "bbox": [
        -3.325100884274331,
        40.44229152653074,
        -3.325100884274331,
        40.44229152653074
      ]
    },
    "28173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.248839638998554,
              40.07616058531791
            ]
          },
          "bbox": [
            -3.248839638998554,
            40.07616058531791,
            -3.248839638998554,
            40.07616058531791
          ]
        }
      ],
      "bbox": [
        -3.248839638998554,
        40.07616058531791,
        -3.248839638998554,
        40.07616058531791
      ]
    },
    "28174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.121194335579168,
              40.29170929114072
            ]
          },
          "bbox": [
            -4.121194335579168,
            40.29170929114072,
            -4.121194335579168,
            40.29170929114072
          ]
        }
      ],
      "bbox": [
        -4.121194335579168,
        40.29170929114072,
        -4.121194335579168,
        40.29170929114072
      ]
    },
    "28175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.143709868697012,
              40.34246534889882
            ]
          },
          "bbox": [
            -4.143709868697012,
            40.34246534889882,
            -4.143709868697012,
            40.34246534889882
          ]
        }
      ],
      "bbox": [
        -4.143709868697012,
        40.34246534889882,
        -4.143709868697012,
        40.34246534889882
      ]
    },
    "28176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.985366604510782,
              40.447833913397105
            ]
          },
          "bbox": [
            -3.985366604510782,
            40.447833913397105,
            -3.985366604510782,
            40.447833913397105
          ]
        }
      ],
      "bbox": [
        -3.985366604510782,
        40.447833913397105,
        -3.985366604510782,
        40.447833913397105
      ]
    },
    "28177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.968427711408157,
              40.4903679922749
            ]
          },
          "bbox": [
            -3.968427711408157,
            40.4903679922749,
            -3.968427711408157,
            40.4903679922749
          ]
        }
      ],
      "bbox": [
        -3.968427711408157,
        40.4903679922749,
        -3.968427711408157,
        40.4903679922749
      ]
    },
    "28178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.090442394704016,
              40.37287015623293
            ]
          },
          "bbox": [
            -4.090442394704016,
            40.37287015623293,
            -4.090442394704016,
            40.37287015623293
          ]
        }
      ],
      "bbox": [
        -4.090442394704016,
        40.37287015623293,
        -4.090442394704016,
        40.37287015623293
      ]
    },
    "28179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2410155209729403,
              40.332193482073556
            ]
          },
          "bbox": [
            -3.2410155209729403,
            40.332193482073556,
            -3.2410155209729403,
            40.332193482073556
          ]
        }
      ],
      "bbox": [
        -3.2410155209729403,
        40.332193482073556,
        -3.2410155209729403,
        40.332193482073556
      ]
    },
    "28180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.268219624567072,
              40.130012122059064
            ]
          },
          "bbox": [
            -3.268219624567072,
            40.130012122059064,
            -3.268219624567072,
            40.130012122059064
          ]
        }
      ],
      "bbox": [
        -3.268219624567072,
        40.130012122059064,
        -3.268219624567072,
        40.130012122059064
      ]
    },
    "28181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.936714530225302,
              40.36081020308148
            ]
          },
          "bbox": [
            -3.936714530225302,
            40.36081020308148,
            -3.936714530225302,
            40.36081020308148
          ]
        }
      ],
      "bbox": [
        -3.936714530225302,
        40.36081020308148,
        -3.936714530225302,
        40.36081020308148
      ]
    },
    "28182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.701970138099666,
              41.02802126847315
            ]
          },
          "bbox": [
            -3.701970138099666,
            41.02802126847315,
            -3.701970138099666,
            41.02802126847315
          ]
        }
      ],
      "bbox": [
        -3.701970138099666,
        41.02802126847315,
        -3.701970138099666,
        41.02802126847315
      ]
    },
    "28183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.179657605351479,
              40.54335052787705
            ]
          },
          "bbox": [
            -4.179657605351479,
            40.54335052787705,
            -4.179657605351479,
            40.54335052787705
          ]
        }
      ],
      "bbox": [
        -4.179657605351479,
        40.54335052787705,
        -4.179657605351479,
        40.54335052787705
      ]
    },
    "28901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6210114548425074,
              40.91638402520617
            ]
          },
          "bbox": [
            -3.6210114548425074,
            40.91638402520617,
            -3.6210114548425074,
            40.91638402520617
          ]
        }
      ],
      "bbox": [
        -3.6210114548425074,
        40.91638402520617,
        -3.6210114548425074,
        40.91638402520617
      ]
    },
    "28902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5608748570364295,
              40.978097610086664
            ]
          },
          "bbox": [
            -3.5608748570364295,
            40.978097610086664,
            -3.5608748570364295,
            40.978097610086664
          ]
        }
      ],
      "bbox": [
        -3.5608748570364295,
        40.978097610086664,
        -3.5608748570364295,
        40.978097610086664
      ]
    },
    "28903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.725940504336282,
              40.611244895864616
            ]
          },
          "bbox": [
            -3.725940504336282,
            40.611244895864616,
            -3.725940504336282,
            40.611244895864616
          ]
        }
      ],
      "bbox": [
        -3.725940504336282,
        40.611244895864616,
        -3.725940504336282,
        40.611244895864616
      ]
    },
    "29001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.667739455730227,
              37.227139612900125
            ]
          },
          "bbox": [
            -4.667739455730227,
            37.227139612900125,
            -4.667739455730227,
            37.227139612900125
          ]
        }
      ],
      "bbox": [
        -4.667739455730227,
        37.227139612900125,
        -4.667739455730227,
        37.227139612900125
      ]
    },
    "29002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.116603745901595,
              36.91637696517357
            ]
          },
          "bbox": [
            -4.116603745901595,
            36.91637696517357,
            -4.116603745901595,
            36.91637696517357
          ]
        }
      ],
      "bbox": [
        -4.116603745901595,
        36.91637696517357,
        -4.116603745901595,
        36.91637696517357
      ]
    },
    "29003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.248020236322754,
              36.992058493730944
            ]
          },
          "bbox": [
            -4.248020236322754,
            36.992058493730944,
            -4.248020236322754,
            36.992058493730944
          ]
        }
      ],
      "bbox": [
        -4.248020236322754,
        36.992058493730944,
        -4.248020236322754,
        36.992058493730944
      ]
    },
    "29004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.264041092271584,
              36.96442766969902
            ]
          },
          "bbox": [
            -4.264041092271584,
            36.96442766969902,
            -4.264041092271584,
            36.96442766969902
          ]
        }
      ],
      "bbox": [
        -4.264041092271584,
        36.96442766969902,
        -4.264041092271584,
        36.96442766969902
      ]
    },
    "29005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.044192686182408,
              36.76637853123874
            ]
          },
          "bbox": [
            -4.044192686182408,
            36.76637853123874,
            -4.044192686182408,
            36.76637853123874
          ]
        }
      ],
      "bbox": [
        -4.044192686182408,
        36.76637853123874,
        -4.044192686182408,
        36.76637853123874
      ]
    },
    "29006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2887918306644,
              36.574717007396195
            ]
          },
          "bbox": [
            -5.2887918306644,
            36.574717007396195,
            -5.2887918306644,
            36.574717007396195
          ]
        }
      ],
      "bbox": [
        -5.2887918306644,
        36.574717007396195,
        -5.2887918306644,
        36.574717007396195
      ]
    },
    "29007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.583064419287282,
              36.66150072679854
            ]
          },
          "bbox": [
            -4.583064419287282,
            36.66150072679854,
            -4.583064419287282,
            36.66150072679854
          ]
        }
      ],
      "bbox": [
        -4.583064419287282,
        36.66150072679854,
        -4.583064419287282,
        36.66150072679854
      ]
    },
    "29008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.683759417111106,
              36.649141332208345
            ]
          },
          "bbox": [
            -4.683759417111106,
            36.649141332208345,
            -4.683759417111106,
            36.649141332208345
          ]
        }
      ],
      "bbox": [
        -4.683759417111106,
        36.649141332208345,
        -4.683759417111106,
        36.649141332208345
      ]
    },
    "29009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.221695274165139,
              36.821821310522594
            ]
          },
          "bbox": [
            -4.221695274165139,
            36.821821310522594,
            -4.221695274165139,
            36.821821310522594
          ]
        }
      ],
      "bbox": [
        -4.221695274165139,
        36.821821310522594,
        -4.221695274165139,
        36.821821310522594
      ]
    },
    "29010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.019241994817802,
              37.023141851370184
            ]
          },
          "bbox": [
            -5.019241994817802,
            37.023141851370184,
            -5.019241994817802,
            37.023141851370184
          ]
        }
      ],
      "bbox": [
        -5.019241994817802,
        37.023141851370184,
        -5.019241994817802,
        37.023141851370184
      ]
    },
    "29011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.54842974270766,
              36.84185918537815
            ]
          },
          "bbox": [
            -4.54842974270766,
            36.84185918537815,
            -4.54842974270766,
            36.84185918537815
          ]
        }
      ],
      "bbox": [
        -4.54842974270766,
        36.84185918537815,
        -4.54842974270766,
        36.84185918537815
      ]
    },
    "29012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6989315557412175,
              36.85689989990711
            ]
          },
          "bbox": [
            -4.6989315557412175,
            36.85689989990711,
            -4.6989315557412175,
            36.85689989990711
          ]
        }
      ],
      "bbox": [
        -4.6989315557412175,
        36.85689989990711,
        -4.6989315557412175,
        36.85689989990711
      ]
    },
    "29013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.859010261342191,
              36.731661390104215
            ]
          },
          "bbox": [
            -4.859010261342191,
            36.731661390104215,
            -4.859010261342191,
            36.731661390104215
          ]
        }
      ],
      "bbox": [
        -4.859010261342191,
        36.731661390104215,
        -4.859010261342191,
        36.731661390104215
      ]
    },
    "29014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.217181394227964,
              36.655490078337166
            ]
          },
          "bbox": [
            -5.217181394227964,
            36.655490078337166,
            -5.217181394227964,
            36.655490078337166
          ]
        }
      ],
      "bbox": [
        -5.217181394227964,
        36.655490078337166,
        -5.217181394227964,
        36.655490078337166
      ]
    },
    "29015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.58363202700641,
              37.03618310490493
            ]
          },
          "bbox": [
            -4.58363202700641,
            37.03618310490493,
            -4.58363202700641,
            37.03618310490493
          ]
        }
      ],
      "bbox": [
        -4.58363202700641,
        37.03618310490493,
        -4.58363202700641,
        37.03618310490493
      ]
    },
    "29016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9960351078480736,
              36.82721617767802
            ]
          },
          "bbox": [
            -3.9960351078480736,
            36.82721617767802,
            -3.9960351078480736,
            36.82721617767802
          ]
        }
      ],
      "bbox": [
        -3.9960351078480736,
        36.82721617767802,
        -3.9960351078480736,
        36.82721617767802
      ]
    },
    "29017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.391971713220028,
              37.10574382241226
            ]
          },
          "bbox": [
            -4.391971713220028,
            37.10574382241226,
            -4.391971713220028,
            37.10574382241226
          ]
        }
      ],
      "bbox": [
        -4.391971713220028,
        37.10574382241226,
        -4.391971713220028,
        37.10574382241226
      ]
    },
    "29018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.847121815706209,
              36.8927598669836
            ]
          },
          "bbox": [
            -4.847121815706209,
            36.8927598669836,
            -4.847121815706209,
            36.8927598669836
          ]
        }
      ],
      "bbox": [
        -4.847121815706209,
        36.8927598669836,
        -4.847121815706209,
        36.8927598669836
      ]
    },
    "29019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.055463051259571,
              36.81287321303692
            ]
          },
          "bbox": [
            -4.055463051259571,
            36.81287321303692,
            -4.055463051259571,
            36.81287321303692
          ]
        }
      ],
      "bbox": [
        -4.055463051259571,
        36.81287321303692,
        -4.055463051259571,
        36.81287321303692
      ]
    },
    "29020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.135900676456624,
              36.79635535396963
            ]
          },
          "bbox": [
            -5.135900676456624,
            36.79635535396963,
            -5.135900676456624,
            36.79635535396963
          ]
        }
      ],
      "bbox": [
        -5.135900676456624,
        36.79635535396963,
        -5.135900676456624,
        36.79635535396963
      ]
    },
    "29021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.24679026950864,
              36.640602199299046
            ]
          },
          "bbox": [
            -5.24679026950864,
            36.640602199299046,
            -5.24679026950864,
            36.640602199299046
          ]
        }
      ],
      "bbox": [
        -5.24679026950864,
        36.640602199299046,
        -5.24679026950864,
        36.640602199299046
      ]
    },
    "29022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.276530240551091,
              36.61428121400229
            ]
          },
          "bbox": [
            -5.276530240551091,
            36.61428121400229,
            -5.276530240551091,
            36.61428121400229
          ]
        }
      ],
      "bbox": [
        -5.276530240551091,
        36.61428121400229,
        -5.276530240551091,
        36.61428121400229
      ]
    },
    "29023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.04941659155935,
              36.545542520008766
            ]
          },
          "bbox": [
            -5.04941659155935,
            36.545542520008766,
            -5.04941659155935,
            36.545542520008766
          ]
        }
      ],
      "bbox": [
        -5.04941659155935,
        36.545542520008766,
        -5.04941659155935,
        36.545542520008766
      ]
    },
    "29024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.302826135686168,
              36.58709773191589
            ]
          },
          "bbox": [
            -5.302826135686168,
            36.58709773191589,
            -5.302826135686168,
            36.58709773191589
          ]
        }
      ],
      "bbox": [
        -5.302826135686168,
        36.58709773191589,
        -5.302826135686168,
        36.58709773191589
      ]
    },
    "29025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.561408404735349,
              36.597379667256945
            ]
          },
          "bbox": [
            -4.561408404735349,
            36.597379667256945,
            -4.561408404735349,
            36.597379667256945
          ]
        }
      ],
      "bbox": [
        -4.561408404735349,
        36.597379667256945,
        -4.561408404735349,
        36.597379667256945
      ]
    },
    "29026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.197976155376518,
              36.843070023520276
            ]
          },
          "bbox": [
            -4.197976155376518,
            36.843070023520276,
            -4.197976155376518,
            36.843070023520276
          ]
        }
      ],
      "bbox": [
        -4.197976155376518,
        36.843070023520276,
        -4.197976155376518,
        36.843070023520276
      ]
    },
    "29027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.165135123862277,
              36.78770578542785
            ]
          },
          "bbox": [
            -4.165135123862277,
            36.78770578542785,
            -4.165135123862277,
            36.78770578542785
          ]
        }
      ],
      "bbox": [
        -4.165135123862277,
        36.78770578542785,
        -4.165135123862277,
        36.78770578542785
      ]
    },
    "29028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.260958043931919,
              36.70160011897816
            ]
          },
          "bbox": [
            -5.260958043931919,
            36.70160011897816,
            -5.260958043931919,
            36.70160011897816
          ]
        }
      ],
      "bbox": [
        -5.260958043931919,
        36.70160011897816,
        -5.260958043931919,
        36.70160011897816
      ]
    },
    "29029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.300617962716347,
              36.5485494004455
            ]
          },
          "bbox": [
            -5.300617962716347,
            36.5485494004455,
            -5.300617962716347,
            36.5485494004455
          ]
        }
      ],
      "bbox": [
        -5.300617962716347,
        36.5485494004455,
        -5.300617962716347,
        36.5485494004455
      ]
    },
    "29030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.251148274538588,
              36.82130627500941
            ]
          },
          "bbox": [
            -4.251148274538588,
            36.82130627500941,
            -4.251148274538588,
            36.82130627500941
          ]
        }
      ],
      "bbox": [
        -4.251148274538588,
        36.82130627500941,
        -4.251148274538588,
        36.82130627500941
      ]
    },
    "29031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.954447608429002,
              36.80372030685493
            ]
          },
          "bbox": [
            -4.954447608429002,
            36.80372030685493,
            -4.954447608429002,
            36.80372030685493
          ]
        }
      ],
      "bbox": [
        -4.954447608429002,
        36.80372030685493,
        -4.954447608429002,
        36.80372030685493
      ]
    },
    "29032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.863049142419239,
              37.033660792982246
            ]
          },
          "bbox": [
            -4.863049142419239,
            37.033660792982246,
            -4.863049142419239,
            37.033660792982246
          ]
        }
      ],
      "bbox": [
        -4.863049142419239,
        37.033660792982246,
        -4.863049142419239,
        37.033660792982246
      ]
    },
    "29033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0924341588696445,
              36.87381859302076
            ]
          },
          "bbox": [
            -4.0924341588696445,
            36.87381859302076,
            -4.0924341588696445,
            36.87381859302076
          ]
        }
      ],
      "bbox": [
        -4.0924341588696445,
        36.87381859302076,
        -4.0924341588696445,
        36.87381859302076
      ]
    },
    "29034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.971179069836367,
              36.86330659756749
            ]
          },
          "bbox": [
            -3.971179069836367,
            36.86330659756749,
            -3.971179069836367,
            36.86330659756749
          ]
        }
      ],
      "bbox": [
        -3.971179069836367,
        36.86330659756749,
        -3.971179069836367,
        36.86330659756749
      ]
    },
    "29035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.037371201974862,
              36.94892094497772
            ]
          },
          "bbox": [
            -5.037371201974862,
            36.94892094497772,
            -5.037371201974862,
            36.94892094497772
          ]
        }
      ],
      "bbox": [
        -5.037371201974862,
        36.94892094497772,
        -5.037371201974862,
        36.94892094497772
      ]
    },
    "29036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8215446726180495,
              36.845593846824784
            ]
          },
          "bbox": [
            -4.8215446726180495,
            36.845593846824784,
            -4.8215446726180495,
            36.845593846824784
          ]
        }
      ],
      "bbox": [
        -4.8215446726180495,
        36.845593846824784,
        -4.8215446726180495,
        36.845593846824784
      ]
    },
    "29037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.155080410692671,
              36.65995889591227
            ]
          },
          "bbox": [
            -5.155080410692671,
            36.65995889591227,
            -5.155080410692671,
            36.65995889591227
          ]
        }
      ],
      "bbox": [
        -5.155080410692671,
        36.65995889591227,
        -5.155080410692671,
        36.65995889591227
      ]
    },
    "29038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.642731990980966,
              36.73706393748145
            ]
          },
          "bbox": [
            -4.642731990980966,
            36.73706393748145,
            -4.642731990980966,
            36.73706393748145
          ]
        }
      ],
      "bbox": [
        -4.642731990980966,
        36.73706393748145,
        -4.642731990980966,
        36.73706393748145
      ]
    },
    "29039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.43145332980428,
              36.88230053667961
            ]
          },
          "bbox": [
            -4.43145332980428,
            36.88230053667961,
            -4.43145332980428,
            36.88230053667961
          ]
        }
      ],
      "bbox": [
        -4.43145332980428,
        36.88230053667961,
        -4.43145332980428,
        36.88230053667961
      ]
    },
    "29040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.823635819486112,
              36.79536054889345
            ]
          },
          "bbox": [
            -4.823635819486112,
            36.79536054889345,
            -4.823635819486112,
            36.79536054889345
          ]
        }
      ],
      "bbox": [
        -4.823635819486112,
        36.79536054889345,
        -4.823635819486112,
        36.79536054889345
      ]
    },
    "29041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.275547422766074,
              36.43250083433313
            ]
          },
          "bbox": [
            -5.275547422766074,
            36.43250083433313,
            -5.275547422766074,
            36.43250083433313
          ]
        }
      ],
      "bbox": [
        -5.275547422766074,
        36.43250083433313,
        -5.275547422766074,
        36.43250083433313
      ]
    },
    "29042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7688950316637255,
              36.67133116199688
            ]
          },
          "bbox": [
            -4.7688950316637255,
            36.67133116199688,
            -4.7688950316637255,
            36.67133116199688
          ]
        }
      ],
      "bbox": [
        -4.7688950316637255,
        36.67133116199688,
        -4.7688950316637255,
        36.67133116199688
      ]
    },
    "29043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.329426332165769,
              36.915900128287824
            ]
          },
          "bbox": [
            -4.329426332165769,
            36.915900128287824,
            -4.329426332165769,
            36.915900128287824
          ]
        }
      ],
      "bbox": [
        -4.329426332165769,
        36.915900128287824,
        -4.329426332165769,
        36.915900128287824
      ]
    },
    "29044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.257412697824063,
              36.85546140366187
            ]
          },
          "bbox": [
            -4.257412697824063,
            36.85546140366187,
            -4.257412697824063,
            36.85546140366187
          ]
        }
      ],
      "bbox": [
        -4.257412697824063,
        36.85546140366187,
        -4.257412697824063,
        36.85546140366187
      ]
    },
    "29045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9218346386444556,
              36.834845996513984
            ]
          },
          "bbox": [
            -3.9218346386444556,
            36.834845996513984,
            -3.9218346386444556,
            36.834845996513984
          ]
        }
      ],
      "bbox": [
        -3.9218346386444556,
        36.834845996513984,
        -3.9218346386444556,
        36.834845996513984
      ]
    },
    "29046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.43114275117255,
              36.57595650799627
            ]
          },
          "bbox": [
            -5.43114275117255,
            36.57595650799627,
            -5.43114275117255,
            36.57595650799627
          ]
        }
      ],
      "bbox": [
        -5.43114275117255,
        36.57595650799627,
        -5.43114275117255,
        36.57595650799627
      ]
    },
    "29047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.475438563009433,
              37.22650524119939
            ]
          },
          "bbox": [
            -4.475438563009433,
            37.22650524119939,
            -4.475438563009433,
            37.22650524119939
          ]
        }
      ],
      "bbox": [
        -4.475438563009433,
        37.22650524119939,
        -4.475438563009433,
        37.22650524119939
      ]
    },
    "29048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.046345266434856,
              36.87302545778631
            ]
          },
          "bbox": [
            -5.046345266434856,
            36.87302545778631,
            -5.046345266434856,
            36.87302545778631
          ]
        }
      ],
      "bbox": [
        -5.046345266434856,
        36.87302545778631,
        -5.046345266434856,
        36.87302545778631
      ]
    },
    "29049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.420499069368936,
              37.25229221513166
            ]
          },
          "bbox": [
            -4.420499069368936,
            37.25229221513166,
            -4.420499069368936,
            37.25229221513166
          ]
        }
      ],
      "bbox": [
        -4.420499069368936,
        37.25229221513166,
        -4.420499069368936,
        37.25229221513166
      ]
    },
    "29050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.225842684635436,
              36.86220072788444
            ]
          },
          "bbox": [
            -4.225842684635436,
            36.86220072788444,
            -4.225842684635436,
            36.86220072788444
          ]
        }
      ],
      "bbox": [
        -4.225842684635436,
        36.86220072788444,
        -4.225842684635436,
        36.86220072788444
      ]
    },
    "29051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.140857662749636,
              36.46644747648058
            ]
          },
          "bbox": [
            -5.140857662749636,
            36.46644747648058,
            -5.140857662749636,
            36.46644747648058
          ]
        }
      ],
      "bbox": [
        -5.140857662749636,
        36.46644747648058,
        -5.140857662749636,
        36.46644747648058
      ]
    },
    "29052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.197692619463583,
              36.60491892546545
            ]
          },
          "bbox": [
            -5.197692619463583,
            36.60491892546545,
            -5.197692619463583,
            36.60491892546545
          ]
        }
      ],
      "bbox": [
        -5.197692619463583,
        36.60491892546545,
        -5.197692619463583,
        36.60491892546545
      ]
    },
    "29053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.895576933862006,
              36.804954269203705
            ]
          },
          "bbox": [
            -3.895576933862006,
            36.804954269203705,
            -3.895576933862006,
            36.804954269203705
          ]
        }
      ],
      "bbox": [
        -3.895576933862006,
        36.804954269203705,
        -3.895576933862006,
        36.804954269203705
      ]
    },
    "29054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.61452480382676,
              36.558347122983434
            ]
          },
          "bbox": [
            -4.61452480382676,
            36.558347122983434,
            -4.61452480382676,
            36.558347122983434
          ]
        }
      ],
      "bbox": [
        -4.61452480382676,
        36.558347122983434,
        -4.61452480382676,
        36.558347122983434
      ]
    },
    "29055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7549685519050335,
              37.12330158544245
            ]
          },
          "bbox": [
            -4.7549685519050335,
            37.12330158544245,
            -4.7549685519050335,
            37.12330158544245
          ]
        }
      ],
      "bbox": [
        -4.7549685519050335,
        37.12330158544245,
        -4.7549685519050335,
        37.12330158544245
      ]
    },
    "29056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.347619659197,
              36.50097744712076
            ]
          },
          "bbox": [
            -5.347619659197,
            36.50097744712076,
            -5.347619659197,
            36.50097744712076
          ]
        }
      ],
      "bbox": [
        -5.347619659197,
        36.50097744712076,
        -5.347619659197,
        36.50097744712076
      ]
    },
    "29057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.224113663082223,
              36.5244006877781
            ]
          },
          "bbox": [
            -5.224113663082223,
            36.5244006877781,
            -5.224113663082223,
            36.5244006877781
          ]
        }
      ],
      "bbox": [
        -5.224113663082223,
        36.5244006877781,
        -5.224113663082223,
        36.5244006877781
      ]
    },
    "29058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.839947043238424,
              36.67385938140883
            ]
          },
          "bbox": [
            -4.839947043238424,
            36.67385938140883,
            -4.839947043238424,
            36.67385938140883
          ]
        }
      ],
      "bbox": [
        -4.839947043238424,
        36.67385938140883,
        -4.839947043238424,
        36.67385938140883
      ]
    },
    "29059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.69062101857252,
              37.134848686605515
            ]
          },
          "bbox": [
            -4.69062101857252,
            37.134848686605515,
            -4.69062101857252,
            37.134848686605515
          ]
        }
      ],
      "bbox": [
        -4.69062101857252,
        37.134848686605515,
        -4.69062101857252,
        37.134848686605515
      ]
    },
    "29060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.09484947165175,
              36.62187549717426
            ]
          },
          "bbox": [
            -5.09484947165175,
            36.62187549717426,
            -5.09484947165175,
            36.62187549717426
          ]
        }
      ],
      "bbox": [
        -5.09484947165175,
        36.62187549717426,
        -5.09484947165175,
        36.62187549717426
      ]
    },
    "29061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.963915848038837,
              36.60046707353311
            ]
          },
          "bbox": [
            -4.963915848038837,
            36.60046707353311,
            -4.963915848038837,
            36.60046707353311
          ]
        }
      ],
      "bbox": [
        -4.963915848038837,
        36.60046707353311,
        -4.963915848038837,
        36.60046707353311
      ]
    },
    "29062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1916352166849915,
              36.78400647575187
            ]
          },
          "bbox": [
            -4.1916352166849915,
            36.78400647575187,
            -4.1916352166849915,
            36.78400647575187
          ]
        }
      ],
      "bbox": [
        -4.1916352166849915,
        36.78400647575187,
        -4.1916352166849915,
        36.78400647575187
      ]
    },
    "29063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.278180942739791,
              36.6567697906302
            ]
          },
          "bbox": [
            -5.278180942739791,
            36.6567697906302,
            -5.278180942739791,
            36.6567697906302
          ]
        }
      ],
      "bbox": [
        -5.278180942739791,
        36.6567697906302,
        -5.278180942739791,
        36.6567697906302
      ]
    },
    "29064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.204104568729262,
              36.56085493878867
            ]
          },
          "bbox": [
            -5.204104568729262,
            36.56085493878867,
            -5.204104568729262,
            36.56085493878867
          ]
        }
      ],
      "bbox": [
        -5.204104568729262,
        36.56085493878867,
        -5.204104568729262,
        36.56085493878867
      ]
    },
    "29065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.161590306362389,
              36.591845889358524
            ]
          },
          "bbox": [
            -5.161590306362389,
            36.591845889358524,
            -5.161590306362389,
            36.591845889358524
          ]
        }
      ],
      "bbox": [
        -5.161590306362389,
        36.591845889358524,
        -5.161590306362389,
        36.591845889358524
      ]
    },
    "29066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2221745917900355,
              36.76978387130579
            ]
          },
          "bbox": [
            -4.2221745917900355,
            36.76978387130579,
            -4.2221745917900355,
            36.76978387130579
          ]
        }
      ],
      "bbox": [
        -4.2221745917900355,
        36.76978387130579,
        -4.2221745917900355,
        36.76978387130579
      ]
    },
    "29067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.423941819789322,
              36.767534247441475
            ]
          },
          "bbox": [
            -4.423941819789322,
            36.767534247441475,
            -4.423941819789322,
            36.767534247441475
          ]
        }
      ],
      "bbox": [
        -4.423941819789322,
        36.767534247441475,
        -4.423941819789322,
        36.767534247441475
      ]
    },
    "29068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.261816782154251,
              36.35260910123221
            ]
          },
          "bbox": [
            -5.261816782154251,
            36.35260910123221,
            -5.261816782154251,
            36.35260910123221
          ]
        }
      ],
      "bbox": [
        -5.261816782154251,
        36.35260910123221,
        -5.261816782154251,
        36.35260910123221
      ]
    },
    "29069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.876442248400277,
              36.51431645510261
            ]
          },
          "bbox": [
            -4.876442248400277,
            36.51431645510261,
            -4.876442248400277,
            36.51431645510261
          ]
        }
      ],
      "bbox": [
        -4.876442248400277,
        36.51431645510261,
        -4.876442248400277,
        36.51431645510261
      ]
    },
    "29070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.684672586748108,
              36.559741911495735
            ]
          },
          "bbox": [
            -4.684672586748108,
            36.559741911495735,
            -4.684672586748108,
            36.559741911495735
          ]
        }
      ],
      "bbox": [
        -4.684672586748108,
        36.559741911495735,
        -4.684672586748108,
        36.559741911495735
      ]
    },
    "29071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.259365124246333,
              36.766744517537276
            ]
          },
          "bbox": [
            -4.259365124246333,
            36.766744517537276,
            -4.259365124246333,
            36.766744517537276
          ]
        }
      ],
      "bbox": [
        -4.259365124246333,
        36.766744517537276,
        -4.259365124246333,
        36.766744517537276
      ]
    },
    "29072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.647146440117964,
              37.14358488496785
            ]
          },
          "bbox": [
            -4.647146440117964,
            37.14358488496785,
            -4.647146440117964,
            37.14358488496785
          ]
        }
      ],
      "bbox": [
        -4.647146440117964,
        37.14358488496785,
        -4.647146440117964,
        37.14358488496785
      ]
    },
    "29073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.854180863705663,
              36.627804385912235
            ]
          },
          "bbox": [
            -4.854180863705663,
            36.627804385912235,
            -4.854180863705663,
            36.627804385912235
          ]
        }
      ],
      "bbox": [
        -4.854180863705663,
        36.627804385912235,
        -4.854180863705663,
        36.627804385912235
      ]
    },
    "29074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2857051837302516,
              36.728049963767326
            ]
          },
          "bbox": [
            -5.2857051837302516,
            36.728049963767326,
            -5.2857051837302516,
            36.728049963767326
          ]
        }
      ],
      "bbox": [
        -5.2857051837302516,
        36.728049963767326,
        -5.2857051837302516,
        36.728049963767326
      ]
    },
    "29075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.829972738130993,
              36.7858131294579
            ]
          },
          "bbox": [
            -3.829972738130993,
            36.7858131294579,
            -3.829972738130993,
            36.7858131294579
          ]
        }
      ],
      "bbox": [
        -3.829972738130993,
        36.7858131294579,
        -3.829972738130993,
        36.7858131294579
      ]
    },
    "29076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8383360477906505,
              36.5662766991087
            ]
          },
          "bbox": [
            -4.8383360477906505,
            36.5662766991087,
            -4.8383360477906505,
            36.5662766991087
          ]
        }
      ],
      "bbox": [
        -4.8383360477906505,
        36.5662766991087,
        -4.8383360477906505,
        36.5662766991087
      ]
    },
    "29077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.082902320180677,
              36.666607619961816
            ]
          },
          "bbox": [
            -5.082902320180677,
            36.666607619961816,
            -5.082902320180677,
            36.666607619961816
          ]
        }
      ],
      "bbox": [
        -5.082902320180677,
        36.666607619961816,
        -5.082902320180677,
        36.666607619961816
      ]
    },
    "29079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.193562862680793,
              36.932193554873834
            ]
          },
          "bbox": [
            -4.193562862680793,
            36.932193554873834,
            -4.193562862680793,
            36.932193554873834
          ]
        }
      ],
      "bbox": [
        -4.193562862680793,
        36.932193554873834,
        -4.193562862680793,
        36.932193554873834
      ]
    },
    "29080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.707169813991729,
              36.7659510211154
            ]
          },
          "bbox": [
            -4.707169813991729,
            36.7659510211154,
            -4.707169813991729,
            36.7659510211154
          ]
        }
      ],
      "bbox": [
        -4.707169813991729,
        36.7659510211154,
        -4.707169813991729,
        36.7659510211154
      ]
    },
    "29081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.129649303015828,
              36.586375937890566
            ]
          },
          "bbox": [
            -5.129649303015828,
            36.586375937890566,
            -5.129649303015828,
            36.586375937890566
          ]
        }
      ],
      "bbox": [
        -5.129649303015828,
        36.586375937890566,
        -5.129649303015828,
        36.586375937890566
      ]
    },
    "29082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.270055164235545,
              36.73058386803643
            ]
          },
          "bbox": [
            -4.270055164235545,
            36.73058386803643,
            -4.270055164235545,
            36.73058386803643
          ]
        }
      ],
      "bbox": [
        -4.270055164235545,
        36.73058386803643,
        -4.270055164235545,
        36.73058386803643
      ]
    },
    "29083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.279318865639374,
              36.92300394647714
            ]
          },
          "bbox": [
            -4.279318865639374,
            36.92300394647714,
            -4.279318865639374,
            36.92300394647714
          ]
        }
      ],
      "bbox": [
        -4.279318865639374,
        36.92300394647714,
        -4.279318865639374,
        36.92300394647714
      ]
    },
    "29084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.128547927516391,
              36.777847424178006
            ]
          },
          "bbox": [
            -5.128547927516391,
            36.777847424178006,
            -5.128547927516391,
            36.777847424178006
          ]
        }
      ],
      "bbox": [
        -5.128547927516391,
        36.777847424178006,
        -5.128547927516391,
        36.777847424178006
      ]
    },
    "29085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.014737522256342,
              36.85863857370164
            ]
          },
          "bbox": [
            -4.014737522256342,
            36.85863857370164,
            -4.014737522256342,
            36.85863857370164
          ]
        }
      ],
      "bbox": [
        -4.014737522256342,
        36.85863857370164,
        -4.014737522256342,
        36.85863857370164
      ]
    },
    "29086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.00909459159613,
              36.7956104196887
            ]
          },
          "bbox": [
            -4.00909459159613,
            36.7956104196887,
            -4.00909459159613,
            36.7956104196887
          ]
        }
      ],
      "bbox": [
        -4.00909459159613,
        36.7956104196887,
        -4.00909459159613,
        36.7956104196887
      ]
    },
    "29087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.044634317577917,
              36.8633875895018
            ]
          },
          "bbox": [
            -4.044634317577917,
            36.8633875895018,
            -4.044634317577917,
            36.8633875895018
          ]
        }
      ],
      "bbox": [
        -4.044634317577917,
        36.8633875895018,
        -4.044634317577917,
        36.8633875895018
      ]
    },
    "29088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.855848961662234,
              37.14511463334584
            ]
          },
          "bbox": [
            -4.855848961662234,
            37.14511463334584,
            -4.855848961662234,
            37.14511463334584
          ]
        }
      ],
      "bbox": [
        -4.855848961662234,
        37.14511463334584,
        -4.855848961662234,
        37.14511463334584
      ]
    },
    "29089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.926738278701363,
              36.978080711526275
            ]
          },
          "bbox": [
            -4.926738278701363,
            36.978080711526275,
            -4.926738278701363,
            36.978080711526275
          ]
        }
      ],
      "bbox": [
        -4.926738278701363,
        36.978080711526275,
        -4.926738278701363,
        36.978080711526275
      ]
    },
    "29090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.942187187450586,
              36.67776862292874
            ]
          },
          "bbox": [
            -4.942187187450586,
            36.67776862292874,
            -4.942187187450586,
            36.67776862292874
          ]
        }
      ],
      "bbox": [
        -4.942187187450586,
        36.67776862292874,
        -4.942187187450586,
        36.67776862292874
      ]
    },
    "29091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9539211680935984,
              36.76450202770563
            ]
          },
          "bbox": [
            -3.9539211680935984,
            36.76450202770563,
            -3.9539211680935984,
            36.76450202770563
          ]
        }
      ],
      "bbox": [
        -3.9539211680935984,
        36.76450202770563,
        -3.9539211680935984,
        36.76450202770563
      ]
    },
    "29092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2951405103946705,
              36.759635448403394
            ]
          },
          "bbox": [
            -4.2951405103946705,
            36.759635448403394,
            -4.2951405103946705,
            36.759635448403394
          ]
        }
      ],
      "bbox": [
        -4.2951405103946705,
        36.759635448403394,
        -4.2951405103946705,
        36.759635448403394
      ]
    },
    "29093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.67429461437615,
              36.93887768889335
            ]
          },
          "bbox": [
            -4.67429461437615,
            36.93887768889335,
            -4.67429461437615,
            36.93887768889335
          ]
        }
      ],
      "bbox": [
        -4.67429461437615,
        36.93887768889335,
        -4.67429461437615,
        36.93887768889335
      ]
    },
    "29094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1348230126445,
              36.77251438107012
            ]
          },
          "bbox": [
            -4.1348230126445,
            36.77251438107012,
            -4.1348230126445,
            36.77251438107012
          ]
        }
      ],
      "bbox": [
        -4.1348230126445,
        36.77251438107012,
        -4.1348230126445,
        36.77251438107012
      ]
    },
    "29095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.417445800000924,
              37.1886142845133
            ]
          },
          "bbox": [
            -4.417445800000924,
            37.1886142845133,
            -4.417445800000924,
            37.1886142845133
          ]
        }
      ],
      "bbox": [
        -4.417445800000924,
        37.1886142845133,
        -4.417445800000924,
        37.1886142845133
      ]
    },
    "29096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.367561106230462,
              37.00856038739739
            ]
          },
          "bbox": [
            -4.367561106230462,
            37.00856038739739,
            -4.367561106230462,
            37.00856038739739
          ]
        }
      ],
      "bbox": [
        -4.367561106230462,
        37.00856038739739,
        -4.367561106230462,
        37.00856038739739
      ]
    },
    "29097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3195538053771525,
              37.040235889201206
            ]
          },
          "bbox": [
            -4.3195538053771525,
            37.040235889201206,
            -4.3195538053771525,
            37.040235889201206
          ]
        }
      ],
      "bbox": [
        -4.3195538053771525,
        37.040235889201206,
        -4.3195538053771525,
        37.040235889201206
      ]
    },
    "29098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.342013249972164,
              37.18140917352422
            ]
          },
          "bbox": [
            -4.342013249972164,
            37.18140917352422,
            -4.342013249972164,
            37.18140917352422
          ]
        }
      ],
      "bbox": [
        -4.342013249972164,
        37.18140917352422,
        -4.342013249972164,
        37.18140917352422
      ]
    },
    "29099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.163482140594432,
              36.8671130804176
            ]
          },
          "bbox": [
            -4.163482140594432,
            36.8671130804176,
            -4.163482140594432,
            36.8671130804176
          ]
        }
      ],
      "bbox": [
        -4.163482140594432,
        36.8671130804176,
        -4.163482140594432,
        36.8671130804176
      ]
    },
    "29100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.937931963106064,
              36.734585209128824
            ]
          },
          "bbox": [
            -4.937931963106064,
            36.734585209128824,
            -4.937931963106064,
            36.734585209128824
          ]
        }
      ],
      "bbox": [
        -4.937931963106064,
        36.734585209128824,
        -4.937931963106064,
        36.734585209128824
      ]
    },
    "29901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5157215314759345,
              36.62586915638207
            ]
          },
          "bbox": [
            -4.5157215314759345,
            36.62586915638207,
            -4.5157215314759345,
            36.62586915638207
          ]
        }
      ],
      "bbox": [
        -4.5157215314759345,
        36.62586915638207,
        -4.5157215314759345,
        36.62586915638207
      ]
    },
    "29902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.528101945982945,
              36.92936082360051
            ]
          },
          "bbox": [
            -4.528101945982945,
            36.92936082360051,
            -4.528101945982945,
            36.92936082360051
          ]
        }
      ],
      "bbox": [
        -4.528101945982945,
        36.92936082360051,
        -4.528101945982945,
        36.92936082360051
      ]
    },
    "29903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.29322567227311,
              36.821408432036414
            ]
          },
          "bbox": [
            -5.29322567227311,
            36.821408432036414,
            -5.29322567227311,
            36.821408432036414
          ]
        }
      ],
      "bbox": [
        -5.29322567227311,
        36.821408432036414,
        -5.29322567227311,
        36.821408432036414
      ]
    },
    "29904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9913933040560545,
              36.8605439464874
            ]
          },
          "bbox": [
            -4.9913933040560545,
            36.8605439464874,
            -4.9913933040560545,
            36.8605439464874
          ]
        }
      ],
      "bbox": [
        -4.9913933040560545,
        36.8605439464874,
        -4.9913933040560545,
        36.8605439464874
      ]
    },
    "30001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0517909443061138,
              38.254894028428545
            ]
          },
          "bbox": [
            -1.0517909443061138,
            38.254894028428545,
            -1.0517909443061138,
            38.254894028428545
          ]
        }
      ],
      "bbox": [
        -1.0517909443061138,
        38.254894028428545,
        -1.0517909443061138,
        38.254894028428545
      ]
    },
    "30002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3211230627967392,
              38.25345292601349
            ]
          },
          "bbox": [
            -1.3211230627967392,
            38.25345292601349,
            -1.3211230627967392,
            38.25345292601349
          ]
        }
      ],
      "bbox": [
        -1.3211230627967392,
        38.25345292601349,
        -1.3211230627967392,
        38.25345292601349
      ]
    },
    "30003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5900628569045792,
              37.46794825653874
            ]
          },
          "bbox": [
            -1.5900628569045792,
            37.46794825653874,
            -1.5900628569045792,
            37.46794825653874
          ]
        }
      ],
      "bbox": [
        -1.5900628569045792,
        37.46794825653874,
        -1.5900628569045792,
        37.46794825653874
      ]
    },
    "30004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3826919152739225,
              38.02963469498112
            ]
          },
          "bbox": [
            -1.3826919152739225,
            38.02963469498112,
            -1.3826919152739225,
            38.02963469498112
          ]
        }
      ],
      "bbox": [
        -1.3826919152739225,
        38.02963469498112,
        -1.3826919152739225,
        38.02963469498112
      ]
    },
    "30005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2318563864150858,
              37.97181006899527
            ]
          },
          "bbox": [
            -1.2318563864150858,
            37.97181006899527,
            -1.2318563864150858,
            37.97181006899527
          ]
        }
      ],
      "bbox": [
        -1.2318563864150858,
        37.97181006899527,
        -1.2318563864150858,
        37.97181006899527
      ]
    },
    "30006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.616525887248727,
              37.790953886558995
            ]
          },
          "bbox": [
            -1.616525887248727,
            37.790953886558995,
            -1.616525887248727,
            37.790953886558995
          ]
        }
      ],
      "bbox": [
        -1.616525887248727,
        37.790953886558995,
        -1.616525887248727,
        37.790953886558995
      ]
    },
    "30007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.273009766627705,
              38.057425668895675
            ]
          },
          "bbox": [
            -1.273009766627705,
            38.057425668895675,
            -1.273009766627705,
            38.057425668895675
          ]
        }
      ],
      "bbox": [
        -1.273009766627705,
        38.057425668895675,
        -1.273009766627705,
        38.057425668895675
      ]
    },
    "30008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.387414362278744,
              37.82236347868054
            ]
          },
          "bbox": [
            -1.387414362278744,
            37.82236347868054,
            -1.387414362278744,
            37.82236347868054
          ]
        }
      ],
      "bbox": [
        -1.387414362278744,
        37.82236347868054,
        -1.387414362278744,
        37.82236347868054
      ]
    },
    "30009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.295199550079959,
              38.11159357971552
            ]
          },
          "bbox": [
            -1.295199550079959,
            38.11159357971552,
            -1.295199550079959,
            38.11159357971552
          ]
        }
      ],
      "bbox": [
        -1.295199550079959,
        38.11159357971552,
        -1.295199550079959,
        38.11159357971552
      ]
    },
    "30010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9917689068752961,
              38.02671071551212
            ]
          },
          "bbox": [
            -0.9917689068752961,
            38.02671071551212,
            -0.9917689068752961,
            38.02671071551212
          ]
        }
      ],
      "bbox": [
        -0.9917689068752961,
        38.02671071551212,
        -0.9917689068752961,
        38.02671071551212
      ]
    },
    "30011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2974909011085023,
              38.21292688724492
            ]
          },
          "bbox": [
            -1.2974909011085023,
            38.21292688724492,
            -1.2974909011085023,
            38.21292688724492
          ]
        }
      ],
      "bbox": [
        -1.2974909011085023,
        38.21292688724492,
        -1.2974909011085023,
        38.21292688724492
      ]
    },
    "30012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6731243987280144,
              38.041205025377316
            ]
          },
          "bbox": [
            -1.6731243987280144,
            38.041205025377316,
            -1.6731243987280144,
            38.041205025377316
          ]
        }
      ],
      "bbox": [
        -1.6731243987280144,
        38.041205025377316,
        -1.6731243987280144,
        38.041205025377316
      ]
    },
    "30013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6564716885623012,
              38.23725632097614
            ]
          },
          "bbox": [
            -1.6564716885623012,
            38.23725632097614,
            -1.6564716885623012,
            38.23725632097614
          ]
        }
      ],
      "bbox": [
        -1.6564716885623012,
        38.23725632097614,
        -1.6564716885623012,
        38.23725632097614
      ]
    },
    "30014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3671610610999945,
              38.050636384548525
            ]
          },
          "bbox": [
            -1.3671610610999945,
            38.050636384548525,
            -1.3671610610999945,
            38.050636384548525
          ]
        }
      ],
      "bbox": [
        -1.3671610610999945,
        38.050636384548525,
        -1.3671610610999945,
        38.050636384548525
      ]
    },
    "30015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0267797085900683,
              38.00696637638569
            ]
          },
          "bbox": [
            -2.0267797085900683,
            38.00696637638569,
            -2.0267797085900683,
            38.00696637638569
          ]
        }
      ],
      "bbox": [
        -2.0267797085900683,
        38.00696637638569,
        -2.0267797085900683,
        38.00696637638569
      ]
    },
    "30016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.989351492920766,
              37.63865185930125
            ]
          },
          "bbox": [
            -0.989351492920766,
            37.63865185930125,
            -0.989351492920766,
            37.63865185930125
          ]
        }
      ],
      "bbox": [
        -0.989351492920766,
        37.63865185930125,
        -0.989351492920766,
        37.63865185930125
      ]
    },
    "30017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7463602498659907,
              38.075606717042476
            ]
          },
          "bbox": [
            -1.7463602498659907,
            38.075606717042476,
            -1.7463602498659907,
            38.075606717042476
          ]
        }
      ],
      "bbox": [
        -1.7463602498659907,
        38.075606717042476,
        -1.7463602498659907,
        38.075606717042476
      ]
    },
    "30018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2812355369438868,
              38.085866712964794
            ]
          },
          "bbox": [
            -1.2812355369438868,
            38.085866712964794,
            -1.2812355369438868,
            38.085866712964794
          ]
        }
      ],
      "bbox": [
        -1.2812355369438868,
        38.085866712964794,
        -1.2812355369438868,
        38.085866712964794
      ]
    },
    "30019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4698604159845776,
              38.27388409210809
            ]
          },
          "bbox": [
            -1.4698604159845776,
            38.27388409210809,
            -1.4698604159845776,
            38.27388409210809
          ]
        }
      ],
      "bbox": [
        -1.4698604159845776,
        38.27388409210809,
        -1.4698604159845776,
        38.27388409210809
      ]
    },
    "30020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1360368293095187,
              38.20311999520516
            ]
          },
          "bbox": [
            -1.1360368293095187,
            38.20311999520516,
            -1.1360368293095187,
            38.20311999520516
          ]
        }
      ],
      "bbox": [
        -1.1360368293095187,
        38.20311999520516,
        -1.1360368293095187,
        38.20311999520516
      ]
    },
    "30021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2139866761568532,
              37.73221251160257
            ]
          },
          "bbox": [
            -1.2139866761568532,
            37.73221251160257,
            -1.2139866761568532,
            37.73221251160257
          ]
        }
      ],
      "bbox": [
        -1.2139866761568532,
        37.73221251160257,
        -1.2139866761568532,
        37.73221251160257
      ]
    },
    "30022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3097332577966243,
              38.46922579854029
            ]
          },
          "bbox": [
            -1.3097332577966243,
            38.46922579854029,
            -1.3097332577966243,
            38.46922579854029
          ]
        }
      ],
      "bbox": [
        -1.3097332577966243,
        38.46922579854029,
        -1.3097332577966243,
        38.46922579854029
      ]
    },
    "30023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3407327833324185,
              37.90502465296894
            ]
          },
          "bbox": [
            -1.3407327833324185,
            37.90502465296894,
            -1.3407327833324185,
            37.90502465296894
          ]
        }
      ],
      "bbox": [
        -1.3407327833324185,
        37.90502465296894,
        -1.3407327833324185,
        37.90502465296894
      ]
    },
    "30024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.754543063772927,
              37.6990673702327
            ]
          },
          "bbox": [
            -1.754543063772927,
            37.6990673702327,
            -1.754543063772927,
            37.6990673702327
          ]
        }
      ],
      "bbox": [
        -1.754543063772927,
        37.6990673702327,
        -1.754543063772927,
        37.6990673702327
      ]
    },
    "30025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2508797067069746,
              38.101254969332665
            ]
          },
          "bbox": [
            -1.2508797067069746,
            38.101254969332665,
            -1.2508797067069746,
            38.101254969332665
          ]
        }
      ],
      "bbox": [
        -1.2508797067069746,
        38.101254969332665,
        -1.2508797067069746,
        38.101254969332665
      ]
    },
    "30026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3523607181705832,
              37.62074276704246
            ]
          },
          "bbox": [
            -1.3523607181705832,
            37.62074276704246,
            -1.3523607181705832,
            37.62074276704246
          ]
        }
      ],
      "bbox": [
        -1.3523607181705832,
        37.62074276704246,
        -1.3523607181705832,
        37.62074276704246
      ]
    },
    "30027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1993439066156717,
              38.12280039092813
            ]
          },
          "bbox": [
            -1.1993439066156717,
            38.12280039092813,
            -1.1993439066156717,
            38.12280039092813
          ]
        }
      ],
      "bbox": [
        -1.1993439066156717,
        38.12280039092813,
        -1.1993439066156717,
        38.12280039092813
      ]
    },
    "30028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9911398080009393,
              38.19875615238393
            ]
          },
          "bbox": [
            -1.9911398080009393,
            38.19875615238393,
            -1.9911398080009393,
            38.19875615238393
          ]
        }
      ],
      "bbox": [
        -1.9911398080009393,
        38.19875615238393,
        -1.9911398080009393,
        38.19875615238393
      ]
    },
    "30029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.540549432673406,
              38.00842572553798
            ]
          },
          "bbox": [
            -1.540549432673406,
            38.00842572553798,
            -1.540549432673406,
            38.00842572553798
          ]
        }
      ],
      "bbox": [
        -1.540549432673406,
        38.00842572553798,
        -1.540549432673406,
        38.00842572553798
      ]
    },
    "30030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1116089957909545,
              37.92338441979997
            ]
          },
          "bbox": [
            -1.1116089957909545,
            37.92338441979997,
            -1.1116089957909545,
            37.92338441979997
          ]
        }
      ],
      "bbox": [
        -1.1116089957909545,
        37.92338441979997,
        -1.1116089957909545,
        37.92338441979997
      ]
    },
    "30031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3615186113487392,
              38.106553273862836
            ]
          },
          "bbox": [
            -1.3615186113487392,
            38.106553273862836,
            -1.3615186113487392,
            38.106553273862836
          ]
        }
      ],
      "bbox": [
        -1.3615186113487392,
        38.106553273862836,
        -1.3615186113487392,
        38.106553273862836
      ]
    },
    "30032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.50365115475572,
              37.977453909484716
            ]
          },
          "bbox": [
            -1.50365115475572,
            37.977453909484716,
            -1.50365115475572,
            37.977453909484716
          ]
        }
      ],
      "bbox": [
        -1.50365115475572,
        37.977453909484716,
        -1.50365115475572,
        37.977453909484716
      ]
    },
    "30033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.816479077800169,
              37.54702109483618
            ]
          },
          "bbox": [
            -1.816479077800169,
            37.54702109483618,
            -1.816479077800169,
            37.54702109483618
          ]
        }
      ],
      "bbox": [
        -1.816479077800169,
        37.54702109483618,
        -1.816479077800169,
        37.54702109483618
      ]
    },
    "30034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.436529394182592,
              38.14918865498764
            ]
          },
          "bbox": [
            -1.436529394182592,
            38.14918865498764,
            -1.436529394182592,
            38.14918865498764
          ]
        }
      ],
      "bbox": [
        -1.436529394182592,
        38.14918865498764,
        -1.436529394182592,
        38.14918865498764
      ]
    },
    "30035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8372123995769344,
              37.80950827531649
            ]
          },
          "bbox": [
            -0.8372123995769344,
            37.80950827531649,
            -0.8372123995769344,
            37.80950827531649
          ]
        }
      ],
      "bbox": [
        -0.8372123995769344,
        37.80950827531649,
        -0.8372123995769344,
        37.80950827531649
      ]
    },
    "30036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7891931020908118,
              37.83352793674413
            ]
          },
          "bbox": [
            -0.7891931020908118,
            37.83352793674413,
            -0.7891931020908118,
            37.83352793674413
          ]
        }
      ],
      "bbox": [
        -0.7891931020908118,
        37.83352793674413,
        -0.7891931020908118,
        37.83352793674413
      ]
    },
    "30037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9670633770644055,
              37.77748794162607
            ]
          },
          "bbox": [
            -0.9670633770644055,
            37.77748794162607,
            -0.9670633770644055,
            37.77748794162607
          ]
        }
      ],
      "bbox": [
        -0.9670633770644055,
        37.77748794162607,
        -0.9670633770644055,
        37.77748794162607
      ]
    },
    "30038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.268140363517365,
              38.020994059966526
            ]
          },
          "bbox": [
            -1.268140363517365,
            38.020994059966526,
            -1.268140363517365,
            38.020994059966526
          ]
        }
      ],
      "bbox": [
        -1.268140363517365,
        38.020994059966526,
        -1.268140363517365,
        38.020994059966526
      ]
    },
    "30039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.527165765047697,
              37.77744215187573
            ]
          },
          "bbox": [
            -1.527165765047697,
            37.77744215187573,
            -1.527165765047697,
            37.77744215187573
          ]
        }
      ],
      "bbox": [
        -1.527165765047697,
        37.77744215187573,
        -1.527165765047697,
        37.77744215187573
      ]
    },
    "30040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2874143648579155,
              38.16419799699282
            ]
          },
          "bbox": [
            -1.2874143648579155,
            38.16419799699282,
            -1.2874143648579155,
            38.16419799699282
          ]
        }
      ],
      "bbox": [
        -1.2874143648579155,
        38.16419799699282,
        -1.2874143648579155,
        38.16419799699282
      ]
    },
    "30041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8799941221978251,
              37.613314759478506
            ]
          },
          "bbox": [
            -0.8799941221978251,
            37.613314759478506,
            -0.8799941221978251,
            37.613314759478506
          ]
        }
      ],
      "bbox": [
        -0.8799941221978251,
        37.613314759478506,
        -0.8799941221978251,
        37.613314759478506
      ]
    },
    "30042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.32314883405323,
              38.10062264954978
            ]
          },
          "bbox": [
            -1.32314883405323,
            38.10062264954978,
            -1.32314883405323,
            38.10062264954978
          ]
        }
      ],
      "bbox": [
        -1.32314883405323,
        38.10062264954978,
        -1.32314883405323,
        38.10062264954978
      ]
    },
    "30043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1508291701800917,
              38.60651282917326
            ]
          },
          "bbox": [
            -1.1508291701800917,
            38.60651282917326,
            -1.1508291701800917,
            38.60651282917326
          ]
        }
      ],
      "bbox": [
        -1.1508291701800917,
        38.60651282917326,
        -1.1508291701800917,
        38.60651282917326
      ]
    },
    "30901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0568089139036323,
              38.08570787901206
            ]
          },
          "bbox": [
            -1.0568089139036323,
            38.08570787901206,
            -1.0568089139036323,
            38.08570787901206
          ]
        }
      ],
      "bbox": [
        -1.0568089139036323,
        38.08570787901206,
        -1.0568089139036323,
        38.08570787901206
      ]
    },
    "30902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8658197543236469,
              37.74330089929033
            ]
          },
          "bbox": [
            -0.8658197543236469,
            37.74330089929033,
            -0.8658197543236469,
            37.74330089929033
          ]
        }
      ],
      "bbox": [
        -0.8658197543236469,
        37.74330089929033,
        -0.8658197543236469,
        37.74330089929033
      ]
    },
    "31001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.137790873650156,
              42.6436230550035
            ]
          },
          "bbox": [
            -2.137790873650156,
            42.6436230550035,
            -2.137790873650156,
            42.6436230550035
          ]
        }
      ],
      "bbox": [
        -2.137790873650156,
        42.6436230550035,
        -2.137790873650156,
        42.6436230550035
      ]
    },
    "31002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0403698683537765,
              42.75237206029408
            ]
          },
          "bbox": [
            -2.0403698683537765,
            42.75237206029408,
            -2.0403698683537765,
            42.75237206029408
          ]
        }
      ],
      "bbox": [
        -2.0403698683537765,
        42.75237206029408,
        -2.0403698683537765,
        42.75237206029408
      ]
    },
    "31003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2006077942820848,
              42.89704424863821
            ]
          },
          "bbox": [
            -1.2006077942820848,
            42.89704424863821,
            -1.2006077942820848,
            42.89704424863821
          ]
        }
      ],
      "bbox": [
        -1.2006077942820848,
        42.89704424863821,
        -1.2006077942820848,
        42.89704424863821
      ]
    },
    "31004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2085350310585503,
              42.91143256235425
            ]
          },
          "bbox": [
            -1.2085350310585503,
            42.91143256235425,
            -1.2085350310585503,
            42.91143256235425
          ]
        }
      ],
      "bbox": [
        -1.2085350310585503,
        42.91143256235425,
        -1.2085350310585503,
        42.91143256235425
      ]
    },
    "31005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9894367196889577,
              42.614958892768364
            ]
          },
          "bbox": [
            -1.9894367196889577,
            42.614958892768364,
            -1.9894367196889577,
            42.614958892768364
          ]
        }
      ],
      "bbox": [
        -1.9894367196889577,
        42.614958892768364,
        -1.9894367196889577,
        42.614958892768364
      ]
    },
    "31006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5859512216288612,
              41.96142940173837
            ]
          },
          "bbox": [
            -1.5859512216288612,
            41.96142940173837,
            -1.5859512216288612,
            41.96142940173837
          ]
        }
      ],
      "bbox": [
        -1.5859512216288612,
        41.96142940173837,
        -1.5859512216288612,
        41.96142940173837
      ]
    },
    "31007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7355580133666322,
              42.69404187166022
            ]
          },
          "bbox": [
            -1.7355580133666322,
            42.69404187166022,
            -1.7355580133666322,
            42.69404187166022
          ]
        }
      ],
      "bbox": [
        -1.7355580133666322,
        42.69404187166022,
        -1.7355580133666322,
        42.69404187166022
      ]
    },
    "31008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.384599334053573,
              42.61085149469084
            ]
          },
          "bbox": [
            -2.384599334053573,
            42.61085149469084,
            -2.384599334053573,
            42.61085149469084
          ]
        }
      ],
      "bbox": [
        -2.384599334053573,
        42.61085149469084,
        -2.384599334053573,
        42.61085149469084
      ]
    },
    "31009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3548195747627116,
              42.59271267139951
            ]
          },
          "bbox": [
            -1.3548195747627116,
            42.59271267139951,
            -1.3548195747627116,
            42.59271267139951
          ]
        }
      ],
      "bbox": [
        -1.3548195747627116,
        42.59271267139951,
        -1.3548195747627116,
        42.59271267139951
      ]
    },
    "31010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1828729736198555,
              42.91310668083175
            ]
          },
          "bbox": [
            -2.1828729736198555,
            42.91310668083175,
            -2.1828729736198555,
            42.91310668083175
          ]
        }
      ],
      "bbox": [
        -2.1828729736198555,
        42.91310668083175,
        -2.1828729736198555,
        42.91310668083175
      ]
    },
    "31011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0823410727176364,
              42.7115851687214
            ]
          },
          "bbox": [
            -2.0823410727176364,
            42.7115851687214,
            -2.0823410727176364,
            42.7115851687214
          ]
        }
      ],
      "bbox": [
        -2.0823410727176364,
        42.7115851687214,
        -2.0823410727176364,
        42.7115851687214
      ]
    },
    "31012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0111370860214,
              42.54100470787634
            ]
          },
          "bbox": [
            -2.0111370860214,
            42.54100470787634,
            -2.0111370860214,
            42.54100470787634
          ]
        }
      ],
      "bbox": [
        -2.0111370860214,
        42.54100470787634,
        -2.0111370860214,
        42.54100470787634
      ]
    },
    "31013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.126811130836454,
              42.77261452335516
            ]
          },
          "bbox": [
            -2.126811130836454,
            42.77261452335516,
            -2.126811130836454,
            42.77261452335516
          ]
        }
      ],
      "bbox": [
        -2.126811130836454,
        42.77261452335516,
        -2.126811130836454,
        42.77261452335516
      ]
    },
    "31014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.187151911811246,
              42.664689123550616
            ]
          },
          "bbox": [
            -2.187151911811246,
            42.664689123550616,
            -2.187151911811246,
            42.664689123550616
          ]
        }
      ],
      "bbox": [
        -2.187151911811246,
        42.664689123550616,
        -2.187151911811246,
        42.664689123550616
      ]
    },
    "31015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.94666500840182,
              42.3836073286944
            ]
          },
          "bbox": [
            -1.94666500840182,
            42.3836073286944,
            -1.94666500840182,
            42.3836073286944
          ]
        }
      ],
      "bbox": [
        -1.94666500840182,
        42.3836073286944,
        -1.94666500840182,
        42.3836073286944
      ]
    },
    "31016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.644189302518599,
              42.83732537929899
            ]
          },
          "bbox": [
            -1.644189302518599,
            42.83732537929899,
            -1.644189302518599,
            42.83732537929899
          ]
        }
      ],
      "bbox": [
        -1.644189302518599,
        42.83732537929899,
        -1.644189302518599,
        42.83732537929899
      ]
    },
    "31017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5888329496007227,
              42.97107563128627
            ]
          },
          "bbox": [
            -1.5888329496007227,
            42.97107563128627,
            -1.5888329496007227,
            42.97107563128627
          ]
        }
      ],
      "bbox": [
        -1.5888329496007227,
        42.97107563128627,
        -1.5888329496007227,
        42.97107563128627
      ]
    },
    "31018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7274622857107123,
              42.637241844022896
            ]
          },
          "bbox": [
            -1.7274622857107123,
            42.637241844022896,
            -1.7274622857107123,
            42.637241844022896
          ]
        }
      ],
      "bbox": [
        -1.7274622857107123,
        42.637241844022896,
        -1.7274622857107123,
        42.637241844022896
      ]
    },
    "31019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3570271163341536,
              42.78780527554456
            ]
          },
          "bbox": [
            -1.3570271163341536,
            42.78780527554456,
            -1.3570271163341536,
            42.78780527554456
          ]
        }
      ],
      "bbox": [
        -1.3570271163341536,
        42.78780527554456,
        -1.3570271163341536,
        42.78780527554456
      ]
    },
    "31020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.001392918617338,
              43.03414626318283
            ]
          },
          "bbox": [
            -2.001392918617338,
            43.03414626318283,
            -2.001392918617338,
            43.03414626318283
          ]
        }
      ],
      "bbox": [
        -2.001392918617338,
        43.03414626318283,
        -2.001392918617338,
        43.03414626318283
      ]
    },
    "31021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2313361476631663,
              42.77087990887615
            ]
          },
          "bbox": [
            -2.2313361476631663,
            42.77087990887615,
            -2.2313361476631663,
            42.77087990887615
          ]
        }
      ],
      "bbox": [
        -2.2313361476631663,
        42.77087990887615,
        -2.2313361476631663,
        42.77087990887615
      ]
    },
    "31022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7398501022690842,
              43.19134833073379
            ]
          },
          "bbox": [
            -1.7398501022690842,
            43.19134833073379,
            -1.7398501022690842,
            43.19134833073379
          ]
        }
      ],
      "bbox": [
        -1.7398501022690842,
        43.19134833073379,
        -1.7398501022690842,
        43.19134833073379
      ]
    },
    "31023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5702904568414264,
              42.77329472901443
            ]
          },
          "bbox": [
            -1.5702904568414264,
            42.77329472901443,
            -1.5702904568414264,
            42.77329472901443
          ]
        }
      ],
      "bbox": [
        -1.5702904568414264,
        42.77329472901443,
        -1.5702904568414264,
        42.77329472901443
      ]
    },
    "31024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.897582004620947,
              43.19585659914115
            ]
          },
          "bbox": [
            -1.897582004620947,
            43.19585659914115,
            -1.897582004620947,
            43.19585659914115
          ]
        }
      ],
      "bbox": [
        -1.897582004620947,
        43.19585659914115,
        -1.897582004620947,
        43.19585659914115
      ]
    },
    "31025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8677018698402634,
              42.91033602803012
            ]
          },
          "bbox": [
            -1.8677018698402634,
            42.91033602803012,
            -1.8677018698402634,
            42.91033602803012
          ]
        }
      ],
      "bbox": [
        -1.8677018698402634,
        42.91033602803012,
        -1.8677018698402634,
        42.91033602803012
      ]
    },
    "31026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.359105629721845,
              42.566448968776115
            ]
          },
          "bbox": [
            -2.359105629721845,
            42.566448968776115,
            -2.359105629721845,
            42.566448968776115
          ]
        }
      ],
      "bbox": [
        -2.359105629721845,
        42.566448968776115,
        -2.359105629721845,
        42.566448968776115
      ]
    },
    "31027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.039757862469378,
              42.93182505774674
            ]
          },
          "bbox": [
            -2.039757862469378,
            42.93182505774674,
            -2.039757862469378,
            42.93182505774674
          ]
        }
      ],
      "bbox": [
        -2.039757862469378,
        42.93182505774674,
        -2.039757862469378,
        42.93182505774674
      ]
    },
    "31028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.348465651938673,
              42.87511391896906
            ]
          },
          "bbox": [
            -1.348465651938673,
            42.87511391896906,
            -1.348465651938673,
            42.87511391896906
          ]
        }
      ],
      "bbox": [
        -1.348465651938673,
        42.87511391896906,
        -1.348465651938673,
        42.87511391896906
      ]
    },
    "31029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1893816408736986,
              42.5440804462927
            ]
          },
          "bbox": [
            -2.1893816408736986,
            42.5440804462927,
            -2.1893816408736986,
            42.5440804462927
          ]
        }
      ],
      "bbox": [
        -2.1893816408736986,
        42.5440804462927,
        -2.1893816408736986,
        42.5440804462927
      ]
    },
    "31030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.061489541337939,
              42.57756361829795
            ]
          },
          "bbox": [
            -2.061489541337939,
            42.57756361829795,
            -2.061489541337939,
            42.57756361829795
          ]
        }
      ],
      "bbox": [
        -2.061489541337939,
        42.57756361829795,
        -2.061489541337939,
        42.57756361829795
      ]
    },
    "31031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.955336946066524,
              43.07851483921204
            ]
          },
          "bbox": [
            -1.955336946066524,
            43.07851483921204,
            -1.955336946066524,
            43.07851483921204
          ]
        }
      ],
      "bbox": [
        -1.955336946066524,
        43.07851483921204,
        -1.955336946066524,
        43.07851483921204
      ]
    },
    "31032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5726849573420418,
              42.186638626869524
            ]
          },
          "bbox": [
            -1.5726849573420418,
            42.186638626869524,
            -1.5726849573420418,
            42.186638626869524
          ]
        }
      ],
      "bbox": [
        -1.5726849573420418,
        42.186638626869524,
        -1.5726849573420418,
        42.186638626869524
      ]
    },
    "31033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2755070827513837,
              42.9671704626954
            ]
          },
          "bbox": [
            -1.2755070827513837,
            42.9671704626954,
            -1.2755070827513837,
            42.9671704626954
          ]
        }
      ],
      "bbox": [
        -1.2755070827513837,
        42.9671704626954,
        -1.2755070827513837,
        42.9671704626954
      ]
    },
    "31034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2645453173899228,
              42.94229020451418
            ]
          },
          "bbox": [
            -1.2645453173899228,
            42.94229020451418,
            -1.2645453173899228,
            42.94229020451418
          ]
        }
      ],
      "bbox": [
        -1.2645453173899228,
        42.94229020451418,
        -1.2645453173899228,
        42.94229020451418
      ]
    },
    "31035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2882115258180806,
              42.54402045145014
            ]
          },
          "bbox": [
            -2.2882115258180806,
            42.54402045145014,
            -2.2882115258180806,
            42.54402045145014
          ]
        }
      ],
      "bbox": [
        -2.2882115258180806,
        42.54402045145014,
        -2.2882115258180806,
        42.54402045145014
      ]
    },
    "31036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.105229793779609,
              42.55203825978729
            ]
          },
          "bbox": [
            -2.105229793779609,
            42.55203825978729,
            -2.105229793779609,
            42.55203825978729
          ]
        }
      ],
      "bbox": [
        -2.105229793779609,
        42.55203825978729,
        -2.105229793779609,
        42.55203825978729
      ]
    },
    "31037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0035862112257004,
              42.93277121321314
            ]
          },
          "bbox": [
            -2.0035862112257004,
            42.93277121321314,
            -2.0035862112257004,
            42.93277121321314
          ]
        }
      ],
      "bbox": [
        -2.0035862112257004,
        42.93277121321314,
        -2.0035862112257004,
        42.93277121321314
      ]
    },
    "31038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.759213428732364,
              42.58282746287169
            ]
          },
          "bbox": [
            -1.759213428732364,
            42.58282746287169,
            -1.759213428732364,
            42.58282746287169
          ]
        }
      ],
      "bbox": [
        -1.759213428732364,
        42.58282746287169,
        -1.759213428732364,
        42.58282746287169
      ]
    },
    "31039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.840850216535644,
              42.686655551810915
            ]
          },
          "bbox": [
            -1.840850216535644,
            42.686655551810915,
            -1.840850216535644,
            42.686655551810915
          ]
        }
      ],
      "bbox": [
        -1.840850216535644,
        42.686655551810915,
        -1.840850216535644,
        42.686655551810915
      ]
    },
    "31040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7126301537444726,
              42.94718675369766
            ]
          },
          "bbox": [
            -1.7126301537444726,
            42.94718675369766,
            -1.7126301537444726,
            42.94718675369766
          ]
        }
      ],
      "bbox": [
        -1.7126301537444726,
        42.94718675369766,
        -1.7126301537444726,
        42.94718675369766
      ]
    },
    "31041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0468465598444268,
              42.64873958704102
            ]
          },
          "bbox": [
            -2.0468465598444268,
            42.64873958704102,
            -2.0468465598444268,
            42.64873958704102
          ]
        }
      ],
      "bbox": [
        -2.0468465598444268,
        42.64873958704102,
        -2.0468465598444268,
        42.64873958704102
      ]
    },
    "31042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8744437572567783,
              42.29933085422802
            ]
          },
          "bbox": [
            -1.8744437572567783,
            42.29933085422802,
            -1.8744437572567783,
            42.29933085422802
          ]
        }
      ],
      "bbox": [
        -1.8744437572567783,
        42.29933085422802,
        -1.8744437572567783,
        42.29933085422802
      ]
    },
    "31043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.350879356993689,
              42.60591310845847
            ]
          },
          "bbox": [
            -2.350879356993689,
            42.60591310845847,
            -2.350879356993689,
            42.60591310845847
          ]
        }
      ],
      "bbox": [
        -2.350879356993689,
        42.60591310845847,
        -2.350879356993689,
        42.60591310845847
      ]
    },
    "31044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.108000650643009,
              42.907011135922104
            ]
          },
          "bbox": [
            -2.108000650643009,
            42.907011135922104,
            -2.108000650643009,
            42.907011135922104
          ]
        }
      ],
      "bbox": [
        -2.108000650643009,
        42.907011135922104,
        -2.108000650643009,
        42.907011135922104
      ]
    },
    "31045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6711793823815193,
              42.612320171205226
            ]
          },
          "bbox": [
            -1.6711793823815193,
            42.612320171205226,
            -1.6711793823815193,
            42.612320171205226
          ]
        }
      ],
      "bbox": [
        -1.6711793823815193,
        42.612320171205226,
        -1.6711793823815193,
        42.612320171205226
      ]
    },
    "31046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1329264794023546,
              42.58143817476047
            ]
          },
          "bbox": [
            -2.1329264794023546,
            42.58143817476047,
            -2.1329264794023546,
            42.58143817476047
          ]
        }
      ],
      "bbox": [
        -2.1329264794023546,
        42.58143817476047,
        -2.1329264794023546,
        42.58143817476047
      ]
    },
    "31047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3078294543862063,
              42.534578555722845
            ]
          },
          "bbox": [
            -2.3078294543862063,
            42.534578555722845,
            -2.3078294543862063,
            42.534578555722845
          ]
        }
      ],
      "bbox": [
        -2.3078294543862063,
        42.534578555722845,
        -2.3078294543862063,
        42.534578555722845
      ]
    },
    "31048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6628066865033047,
              41.97099977297374
            ]
          },
          "bbox": [
            -1.6628066865033047,
            41.97099977297374,
            -1.6628066865033047,
            41.97099977297374
          ]
        }
      ],
      "bbox": [
        -1.6628066865033047,
        41.97099977297374,
        -1.6628066865033047,
        41.97099977297374
      ]
    },
    "31049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8056994472459564,
              43.02627754091442
            ]
          },
          "bbox": [
            -1.8056994472459564,
            43.02627754091442,
            -1.8056994472459564,
            43.02627754091442
          ]
        }
      ],
      "bbox": [
        -1.8056994472459564,
        43.02627754091442,
        -1.8056994472459564,
        43.02627754091442
      ]
    },
    "31050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5061638100260517,
              43.15905142756104
            ]
          },
          "bbox": [
            -1.5061638100260517,
            43.15905142756104,
            -1.5061638100260517,
            43.15905142756104
          ]
        }
      ],
      "bbox": [
        -1.5061638100260517,
        43.15905142756104,
        -1.5061638100260517,
        43.15905142756104
      ]
    },
    "31051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5986453654111257,
              42.46370284533674
            ]
          },
          "bbox": [
            -1.5986453654111257,
            42.46370284533674,
            -1.5986453654111257,
            42.46370284533674
          ]
        }
      ],
      "bbox": [
        -1.5986453654111257,
        42.46370284533674,
        -1.5986453654111257,
        42.46370284533674
      ]
    },
    "31052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.829885216069992,
              42.75009258795999
            ]
          },
          "bbox": [
            -1.829885216069992,
            42.75009258795999,
            -1.829885216069992,
            42.75009258795999
          ]
        }
      ],
      "bbox": [
        -1.829885216069992,
        42.75009258795999,
        -1.829885216069992,
        42.75009258795999
      ]
    },
    "31053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.840995788216805,
              42.51925107232925
            ]
          },
          "bbox": [
            -1.840995788216805,
            42.51925107232925,
            -1.840995788216805,
            42.51925107232925
          ]
        }
      ],
      "bbox": [
        -1.840995788216805,
        42.51925107232925,
        -1.840995788216805,
        42.51925107232925
      ]
    },
    "31054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6186478539752107,
              43.14400980905402
            ]
          },
          "bbox": [
            -1.6186478539752107,
            43.14400980905402,
            -1.6186478539752107,
            43.14400980905402
          ]
        }
      ],
      "bbox": [
        -1.6186478539752107,
        43.14400980905402,
        -1.6186478539752107,
        43.14400980905402
      ]
    },
    "31055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9760432826186753,
              43.01604471516924
            ]
          },
          "bbox": [
            -1.9760432826186753,
            43.01604471516924,
            -1.9760432826186753,
            43.01604471516924
          ]
        }
      ],
      "bbox": [
        -1.9760432826186753,
        43.01604471516924,
        -1.9760432826186753,
        43.01604471516924
      ]
    },
    "31056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6774998472018579,
              42.67724040529922
            ]
          },
          "bbox": [
            -1.6774998472018579,
            42.67724040529922,
            -1.6774998472018579,
            42.67724040529922
          ]
        }
      ],
      "bbox": [
        -1.6774998472018579,
        42.67724040529922,
        -1.6774998472018579,
        42.67724040529922
      ]
    },
    "31057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4523440126966483,
              41.97310724454602
            ]
          },
          "bbox": [
            -1.4523440126966483,
            41.97310724454602,
            -1.4523440126966483,
            41.97310724454602
          ]
        }
      ],
      "bbox": [
        -1.4523440126966483,
        41.97310724454602,
        -1.4523440126966483,
        41.97310724454602
      ]
    },
    "31058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3382311971383285,
              42.99074799273631
            ]
          },
          "bbox": [
            -1.3382311971383285,
            42.99074799273631,
            -1.3382311971383285,
            42.99074799273631
          ]
        }
      ],
      "bbox": [
        -1.3382311971383285,
        42.99074799273631,
        -1.3382311971383285,
        42.99074799273631
      ]
    },
    "31059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9951913169907775,
              42.73694472185052
            ]
          },
          "bbox": [
            -0.9951913169907775,
            42.73694472185052,
            -0.9951913169907775,
            42.73694472185052
          ]
        }
      ],
      "bbox": [
        -0.9951913169907775,
        42.73694472185052,
        -0.9951913169907775,
        42.73694472185052
      ]
    },
    "31060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6162170331607346,
              42.82439242470729
            ]
          },
          "bbox": [
            -1.6162170331607346,
            42.82439242470729,
            -1.6162170331607346,
            42.82439242470729
          ]
        }
      ],
      "bbox": [
        -1.6162170331607346,
        42.82439242470729,
        -1.6162170331607346,
        42.82439242470729
      ]
    },
    "31061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2382945112438515,
              42.53934232594899
            ]
          },
          "bbox": [
            -2.2382945112438515,
            42.53934232594899,
            -2.2382945112438515,
            42.53934232594899
          ]
        }
      ],
      "bbox": [
        -2.2382945112438515,
        42.53934232594899,
        -2.2382945112438515,
        42.53934232594899
      ]
    },
    "31062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5230985454309758,
              42.04791424830469
            ]
          },
          "bbox": [
            -1.5230985454309758,
            42.04791424830469,
            -1.5230985454309758,
            42.04791424830469
          ]
        }
      ],
      "bbox": [
        -1.5230985454309758,
        42.04791424830469,
        -1.5230985454309758,
        42.04791424830469
      ]
    },
    "31063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4245698787680587,
              42.63474535409329
            ]
          },
          "bbox": [
            -2.4245698787680587,
            42.63474535409329,
            -2.4245698787680587,
            42.63474535409329
          ]
        }
      ],
      "bbox": [
        -2.4245698787680587,
        42.63474535409329,
        -2.4245698787680587,
        42.63474535409329
      ]
    },
    "31064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6850610611607517,
              42.226579132677045
            ]
          },
          "bbox": [
            -1.6850610611607517,
            42.226579132677045,
            -1.6850610611607517,
            42.226579132677045
          ]
        }
      ],
      "bbox": [
        -1.6850610611607517,
        42.226579132677045,
        -1.6850610611607517,
        42.226579132677045
      ]
    },
    "31065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6577768582231578,
              42.3241828509304
            ]
          },
          "bbox": [
            -1.6577768582231578,
            42.3241828509304,
            -1.6577768582231578,
            42.3241828509304
          ]
        }
      ],
      "bbox": [
        -1.6577768582231578,
        42.3241828509304,
        -1.6577768582231578,
        42.3241828509304
      ]
    },
    "31066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9814286446562654,
              42.414045248050705
            ]
          },
          "bbox": [
            -1.9814286446562654,
            42.414045248050705,
            -1.9814286446562654,
            42.414045248050705
          ]
        }
      ],
      "bbox": [
        -1.9814286446562654,
        42.414045248050705,
        -1.9814286446562654,
        42.414045248050705
      ]
    },
    "31067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.413690853092718,
              42.35249471153726
            ]
          },
          "bbox": [
            -1.413690853092718,
            42.35249471153726,
            -1.413690853092718,
            42.35249471153726
          ]
        }
      ],
      "bbox": [
        -1.413690853092718,
        42.35249471153726,
        -1.413690853092718,
        42.35249471153726
      ]
    },
    "31068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7081130631378472,
              42.01169337968424
            ]
          },
          "bbox": [
            -1.7081130631378472,
            42.01169337968424,
            -1.7081130631378472,
            42.01169337968424
          ]
        }
      ],
      "bbox": [
        -1.7081130631378472,
        42.01169337968424,
        -1.7081130631378472,
        42.01169337968424
      ]
    },
    "31069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.366231239691618,
              42.47097094658687
            ]
          },
          "bbox": [
            -1.366231239691618,
            42.47097094658687,
            -1.366231239691618,
            42.47097094658687
          ]
        }
      ],
      "bbox": [
        -1.366231239691618,
        42.47097094658687,
        -1.366231239691618,
        42.47097094658687
      ]
    },
    "31070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6794865724344963,
              42.15593625329225
            ]
          },
          "bbox": [
            -1.6794865724344963,
            42.15593625329225,
            -1.6794865724344963,
            42.15593625329225
          ]
        }
      ],
      "bbox": [
        -1.6794865724344963,
        42.15593625329225,
        -1.6794865724344963,
        42.15593625329225
      ]
    },
    "31071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.069197221144914,
              42.66641589205833
            ]
          },
          "bbox": [
            -1.069197221144914,
            42.66641589205833,
            -1.069197221144914,
            42.66641589205833
          ]
        }
      ],
      "bbox": [
        -1.069197221144914,
        42.66641589205833,
        -1.069197221144914,
        42.66641589205833
      ]
    },
    "31072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7942175560298466,
              42.06605943681777
            ]
          },
          "bbox": [
            -1.7942175560298466,
            42.06605943681777,
            -1.7942175560298466,
            42.06605943681777
          ]
        }
      ],
      "bbox": [
        -1.7942175560298466,
        42.06605943681777,
        -1.7942175560298466,
        42.06605943681777
      ]
    },
    "31073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2293169963351014,
              42.88385439308629
            ]
          },
          "bbox": [
            -2.2293169963351014,
            42.88385439308629,
            -2.2293169963351014,
            42.88385439308629
          ]
        }
      ],
      "bbox": [
        -2.2293169963351014,
        42.88385439308629,
        -2.2293169963351014,
        42.88385439308629
      ]
    },
    "31074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.899321950594366,
              42.66013583002121
            ]
          },
          "bbox": [
            -1.899321950594366,
            42.66013583002121,
            -1.899321950594366,
            42.66013583002121
          ]
        }
      ],
      "bbox": [
        -1.899321950594366,
        42.66013583002121,
        -1.899321950594366,
        42.66013583002121
      ]
    },
    "31075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.825494574122841,
              42.7919067857869
            ]
          },
          "bbox": [
            -1.825494574122841,
            42.7919067857869,
            -1.825494574122841,
            42.7919067857869
          ]
        }
      ],
      "bbox": [
        -1.825494574122841,
        42.7919067857869,
        -1.825494574122841,
        42.7919067857869
      ]
    },
    "31076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7323652887409764,
              42.75931847372558
            ]
          },
          "bbox": [
            -1.7323652887409764,
            42.75931847372558,
            -1.7323652887409764,
            42.75931847372558
          ]
        }
      ],
      "bbox": [
        -1.7323652887409764,
        42.75931847372558,
        -1.7323652887409764,
        42.75931847372558
      ]
    },
    "31077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7924063211111685,
              42.11300175975341
            ]
          },
          "bbox": [
            -1.7924063211111685,
            42.11300175975341,
            -1.7924063211111685,
            42.11300175975341
          ]
        }
      ],
      "bbox": [
        -1.7924063211111685,
        42.11300175975341,
        -1.7924063211111685,
        42.11300175975341
      ]
    },
    "31078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4445627083291508,
              41.93847203643874
            ]
          },
          "bbox": [
            -1.4445627083291508,
            41.93847203643874,
            -1.4445627083291508,
            41.93847203643874
          ]
        }
      ],
      "bbox": [
        -1.4445627083291508,
        41.93847203643874,
        -1.4445627083291508,
        41.93847203643874
      ]
    },
    "31079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2668902370794455,
              42.58757727141733
            ]
          },
          "bbox": [
            -2.2668902370794455,
            42.58757727141733,
            -2.2668902370794455,
            42.58757727141733
          ]
        }
      ],
      "bbox": [
        -2.2668902370794455,
        42.58757727141733,
        -2.2668902370794455,
        42.58757727141733
      ]
    },
    "31080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0407247639703234,
              42.56244383116681
            ]
          },
          "bbox": [
            -2.0407247639703234,
            42.56244383116681,
            -2.0407247639703234,
            42.56244383116681
          ]
        }
      ],
      "bbox": [
        -2.0407247639703234,
        42.56244383116681,
        -2.0407247639703234,
        42.56244383116681
      ]
    },
    "31081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.663117717543721,
              43.09031116396472
            ]
          },
          "bbox": [
            -1.663117717543721,
            43.09031116396472,
            -1.663117717543721,
            43.09031116396472
          ]
        }
      ],
      "bbox": [
        -1.663117717543721,
        43.09031116396472,
        -1.663117717543721,
        43.09031116396472
      ]
    },
    "31082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6181472425789813,
              43.23357231399516
            ]
          },
          "bbox": [
            -1.6181472425789813,
            43.23357231399516,
            -1.6181472425789813,
            43.23357231399516
          ]
        }
      ],
      "bbox": [
        -1.6181472425789813,
        43.23357231399516,
        -1.6181472425789813,
        43.23357231399516
      ]
    },
    "31083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8283800145925377,
              42.78091157359666
            ]
          },
          "bbox": [
            -1.8283800145925377,
            42.78091157359666,
            -1.8283800145925377,
            42.78091157359666
          ]
        }
      ],
      "bbox": [
        -1.8283800145925377,
        42.78091157359666,
        -1.8283800145925377,
        42.78091157359666
      ]
    },
    "31084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0794235270130033,
              42.92205823337295
            ]
          },
          "bbox": [
            -2.0794235270130033,
            42.92205823337295,
            -2.0794235270130033,
            42.92205823337295
          ]
        }
      ],
      "bbox": [
        -2.0794235270130033,
        42.92205823337295,
        -2.0794235270130033,
        42.92205823337295
      ]
    },
    "31085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8086743284417006,
              42.80063837057494
            ]
          },
          "bbox": [
            -1.8086743284417006,
            42.80063837057494,
            -1.8086743284417006,
            42.80063837057494
          ]
        }
      ],
      "bbox": [
        -1.8086743284417006,
        42.80063837057494,
        -1.8086743284417006,
        42.80063837057494
      ]
    },
    "31086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.54859082603033,
              42.82316874137755
            ]
          },
          "bbox": [
            -1.54859082603033,
            42.82316874137755,
            -1.54859082603033,
            42.82316874137755
          ]
        }
      ],
      "bbox": [
        -1.54859082603033,
        42.82316874137755,
        -1.54859082603033,
        42.82316874137755
      ]
    },
    "31087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6908609820325509,
              43.13805981263629
            ]
          },
          "bbox": [
            -1.6908609820325509,
            43.13805981263629,
            -1.6908609820325509,
            43.13805981263629
          ]
        }
      ],
      "bbox": [
        -1.6908609820325509,
        43.13805981263629,
        -1.6908609820325509,
        43.13805981263629
      ]
    },
    "31088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5906474521703455,
              42.727463036936946
            ]
          },
          "bbox": [
            -1.5906474521703455,
            42.727463036936946,
            -1.5906474521703455,
            42.727463036936946
          ]
        }
      ],
      "bbox": [
        -1.5906474521703455,
        42.727463036936946,
        -1.5906474521703455,
        42.727463036936946
      ]
    },
    "31089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7440801775300911,
              42.66440528847029
            ]
          },
          "bbox": [
            -1.7440801775300911,
            42.66440528847029,
            -1.7440801775300911,
            42.66440528847029
          ]
        }
      ],
      "bbox": [
        -1.7440801775300911,
        42.66440528847029,
        -1.7440801775300911,
        42.66440528847029
      ]
    },
    "31090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8043920889917544,
              43.10540133421148
            ]
          },
          "bbox": [
            -1.8043920889917544,
            43.10540133421148,
            -1.8043920889917544,
            43.10540133421148
          ]
        }
      ],
      "bbox": [
        -1.8043920889917544,
        43.10540133421148,
        -1.8043920889917544,
        43.10540133421148
      ]
    },
    "31091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.023446116338829,
              42.897645355985354
            ]
          },
          "bbox": [
            -2.023446116338829,
            42.897645355985354,
            -2.023446116338829,
            42.897645355985354
          ]
        }
      ],
      "bbox": [
        -2.023446116338829,
        42.897645355985354,
        -2.023446116338829,
        42.897645355985354
      ]
    },
    "31092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4280556608300434,
              42.97200307372944
            ]
          },
          "bbox": [
            -1.4280556608300434,
            42.97200307372944,
            -1.4280556608300434,
            42.97200307372944
          ]
        }
      ],
      "bbox": [
        -1.4280556608300434,
        42.97200307372944,
        -1.4280556608300434,
        42.97200307372944
      ]
    },
    "31093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0667047396790623,
              42.89541834179633
            ]
          },
          "bbox": [
            -1.0667047396790623,
            42.89541834179633,
            -1.0667047396790623,
            42.89541834179633
          ]
        }
      ],
      "bbox": [
        -1.0667047396790623,
        42.89541834179633,
        -1.0667047396790623,
        42.89541834179633
      ]
    },
    "31094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.460672697234576,
              42.567212759535956
            ]
          },
          "bbox": [
            -1.460672697234576,
            42.567212759535956,
            -1.460672697234576,
            42.567212759535956
          ]
        }
      ],
      "bbox": [
        -1.460672697234576,
        42.567212759535956,
        -1.460672697234576,
        42.567212759535956
      ]
    },
    "31095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0911071520859315,
              42.858998153417105
            ]
          },
          "bbox": [
            -1.0911071520859315,
            42.858998153417105,
            -1.0911071520859315,
            42.858998153417105
          ]
        }
      ],
      "bbox": [
        -1.0911071520859315,
        42.858998153417105,
        -1.0911071520859315,
        42.858998153417105
      ]
    },
    "31096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.302162468008187,
              42.596633425447386
            ]
          },
          "bbox": [
            -2.302162468008187,
            42.596633425447386,
            -2.302162468008187,
            42.596633425447386
          ]
        }
      ],
      "bbox": [
        -2.302162468008187,
        42.596633425447386,
        -2.302162468008187,
        42.596633425447386
      ]
    },
    "31097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0269860917553784,
              42.666575048539755
            ]
          },
          "bbox": [
            -2.0269860917553784,
            42.666575048539755,
            -2.0269860917553784,
            42.666575048539755
          ]
        }
      ],
      "bbox": [
        -2.0269860917553784,
        42.666575048539755,
        -2.0269860917553784,
        42.666575048539755
      ]
    },
    "31098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5271324399148967,
              42.94604728848193
            ]
          },
          "bbox": [
            -1.5271324399148967,
            42.94604728848193,
            -1.5271324399148967,
            42.94604728848193
          ]
        }
      ],
      "bbox": [
        -1.5271324399148967,
        42.94604728848193,
        -1.5271324399148967,
        42.94604728848193
      ]
    },
    "31099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.173216664328913,
              42.61225086132137
            ]
          },
          "bbox": [
            -2.173216664328913,
            42.61225086132137,
            -2.173216664328913,
            42.61225086132137
          ]
        }
      ],
      "bbox": [
        -2.173216664328913,
        42.61225086132137,
        -2.173216664328913,
        42.61225086132137
      ]
    },
    "31100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2075631268497267,
              42.76858499428347
            ]
          },
          "bbox": [
            -2.2075631268497267,
            42.76858499428347,
            -2.2075631268497267,
            42.76858499428347
          ]
        }
      ],
      "bbox": [
        -2.2075631268497267,
        42.76858499428347,
        -2.2075631268497267,
        42.76858499428347
      ]
    },
    "31101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6341246308854258,
              42.86925910111652
            ]
          },
          "bbox": [
            -1.6341246308854258,
            42.86925910111652,
            -1.6341246308854258,
            42.86925910111652
          ]
        }
      ],
      "bbox": [
        -1.6341246308854258,
        42.86925910111652,
        -1.6341246308854258,
        42.86925910111652
      ]
    },
    "31102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.838525642509876,
              43.09932846573695
            ]
          },
          "bbox": [
            -1.838525642509876,
            43.09932846573695,
            -1.838525642509876,
            43.09932846573695
          ]
        }
      ],
      "bbox": [
        -1.838525642509876,
        43.09932846573695,
        -1.838525642509876,
        43.09932846573695
      ]
    },
    "31103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.455964669206518,
              42.608885409433206
            ]
          },
          "bbox": [
            -1.455964669206518,
            42.608885409433206,
            -1.455964669206518,
            42.608885409433206
          ]
        }
      ],
      "bbox": [
        -1.455964669206518,
        42.608885409433206,
        -1.455964669206518,
        42.608885409433206
      ]
    },
    "31104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.809925935069746,
              42.419808960354935
            ]
          },
          "bbox": [
            -1.809925935069746,
            42.419808960354935,
            -1.809925935069746,
            42.419808960354935
          ]
        }
      ],
      "bbox": [
        -1.809925935069746,
        42.419808960354935,
        -1.809925935069746,
        42.419808960354935
      ]
    },
    "31105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8638478526277684,
              42.04413970209338
            ]
          },
          "bbox": [
            -1.8638478526277684,
            42.04413970209338,
            -1.8638478526277684,
            42.04413970209338
          ]
        }
      ],
      "bbox": [
        -1.8638478526277684,
        42.04413970209338,
        -1.8638478526277684,
        42.04413970209338
      ]
    },
    "31106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5688313925599702,
              42.00912333756925
            ]
          },
          "bbox": [
            -1.5688313925599702,
            42.00912333756925,
            -1.5688313925599702,
            42.00912333756925
          ]
        }
      ],
      "bbox": [
        -1.5688313925599702,
        42.00912333756925,
        -1.5688313925599702,
        42.00912333756925
      ]
    },
    "31107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8062654585227431,
              42.290040377077275
            ]
          },
          "bbox": [
            -1.8062654585227431,
            42.290040377077275,
            -1.8062654585227431,
            42.290040377077275
          ]
        }
      ],
      "bbox": [
        -1.8062654585227431,
        42.290040377077275,
        -1.8062654585227431,
        42.290040377077275
      ]
    },
    "31108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4431036201634029,
              42.03456052543553
            ]
          },
          "bbox": [
            -1.4431036201634029,
            42.03456052543553,
            -1.4431036201634029,
            42.03456052543553
          ]
        }
      ],
      "bbox": [
        -1.4431036201634029,
        42.03456052543553,
        -1.4431036201634029,
        42.03456052543553
      ]
    },
    "31109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6708142711229645,
              42.747600695985064
            ]
          },
          "bbox": [
            -1.6708142711229645,
            42.747600695985064,
            -1.6708142711229645,
            42.747600695985064
          ]
        }
      ],
      "bbox": [
        -1.6708142711229645,
        42.747600695985064,
        -1.6708142711229645,
        42.747600695985064
      ]
    },
    "31110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4173195207346931,
              42.49842012625009
            ]
          },
          "bbox": [
            -1.4173195207346931,
            42.49842012625009,
            -1.4173195207346931,
            42.49842012625009
          ]
        }
      ],
      "bbox": [
        -1.4173195207346931,
        42.49842012625009,
        -1.4173195207346931,
        42.49842012625009
      ]
    },
    "31111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1021037603066293,
              42.792448820364086
            ]
          },
          "bbox": [
            -1.1021037603066293,
            42.792448820364086,
            -1.1021037603066293,
            42.792448820364086
          ]
        }
      ],
      "bbox": [
        -1.1021037603066293,
        42.792448820364086,
        -1.1021037603066293,
        42.792448820364086
      ]
    },
    "31112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2474296227433872,
              42.91893355676553
            ]
          },
          "bbox": [
            -1.2474296227433872,
            42.91893355676553,
            -1.2474296227433872,
            42.91893355676553
          ]
        }
      ],
      "bbox": [
        -1.2474296227433872,
        42.91893355676553,
        -1.2474296227433872,
        42.91893355676553
      ]
    },
    "31113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8969537207667331,
              42.78234319507695
            ]
          },
          "bbox": [
            -0.8969537207667331,
            42.78234319507695,
            -0.8969537207667331,
            42.78234319507695
          ]
        }
      ],
      "bbox": [
        -0.8969537207667331,
        42.78234319507695,
        -0.8969537207667331,
        42.78234319507695
      ]
    },
    "31114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6644134039710317,
              42.594324596457405
            ]
          },
          "bbox": [
            -1.6644134039710317,
            42.594324596457405,
            -1.6644134039710317,
            42.594324596457405
          ]
        }
      ],
      "bbox": [
        -1.6644134039710317,
        42.594324596457405,
        -1.6644134039710317,
        42.594324596457405
      ]
    },
    "31115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3046538028369095,
              42.95560283752046
            ]
          },
          "bbox": [
            -1.3046538028369095,
            42.95560283752046,
            -1.3046538028369095,
            42.95560283752046
          ]
        }
      ],
      "bbox": [
        -1.3046538028369095,
        42.95560283752046,
        -1.3046538028369095,
        42.95560283752046
      ]
    },
    "31116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.399095383401242,
              42.648697597581105
            ]
          },
          "bbox": [
            -2.399095383401242,
            42.648697597581105,
            -2.399095383401242,
            42.648697597581105
          ]
        }
      ],
      "bbox": [
        -2.399095383401242,
        42.648697597581105,
        -2.399095383401242,
        42.648697597581105
      ]
    },
    "31117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8296189255199362,
              43.18934770451284
            ]
          },
          "bbox": [
            -1.8296189255199362,
            43.18934770451284,
            -1.8296189255199362,
            43.18934770451284
          ]
        }
      ],
      "bbox": [
        -1.8296189255199362,
        43.18934770451284,
        -1.8296189255199362,
        43.18934770451284
      ]
    },
    "31118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8960574562261974,
              42.83096068795434
            ]
          },
          "bbox": [
            -1.8960574562261974,
            42.83096068795434,
            -1.8960574562261974,
            42.83096068795434
          ]
        }
      ],
      "bbox": [
        -1.8960574562261974,
        42.83096068795434,
        -1.8960574562261974,
        42.83096068795434
      ]
    },
    "31119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0660063629244623,
              42.8188127004662
            ]
          },
          "bbox": [
            -1.0660063629244623,
            42.8188127004662,
            -1.0660063629244623,
            42.8188127004662
          ]
        }
      ],
      "bbox": [
        -1.0660063629244623,
        42.8188127004662,
        -1.0660063629244623,
        42.8188127004662
      ]
    },
    "31120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.922379426154133,
              42.76385383903847
            ]
          },
          "bbox": [
            -1.922379426154133,
            42.76385383903847,
            -1.922379426154133,
            42.76385383903847
          ]
        }
      ],
      "bbox": [
        -1.922379426154133,
        42.76385383903847,
        -1.922379426154133,
        42.76385383903847
      ]
    },
    "31121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8644413609991914,
              42.72072079800728
            ]
          },
          "bbox": [
            -1.8644413609991914,
            42.72072079800728,
            -1.8644413609991914,
            42.72072079800728
          ]
        }
      ],
      "bbox": [
        -1.8644413609991914,
        42.72072079800728,
        -1.8644413609991914,
        42.72072079800728
      ]
    },
    "31122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.59294043228027,
              42.828922362144546
            ]
          },
          "bbox": [
            -1.59294043228027,
            42.828922362144546,
            -1.59294043228027,
            42.828922362144546
          ]
        }
      ],
      "bbox": [
        -1.59294043228027,
        42.828922362144546,
        -1.59294043228027,
        42.828922362144546
      ]
    },
    "31123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9611131710436358,
              42.92702110736311
            ]
          },
          "bbox": [
            -1.9611131710436358,
            42.92702110736311,
            -1.9611131710436358,
            42.92702110736311
          ]
        }
      ],
      "bbox": [
        -1.9611131710436358,
        42.92702110736311,
        -1.9611131710436358,
        42.92702110736311
      ]
    },
    "31124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4570580503093902,
              42.67427830446749
            ]
          },
          "bbox": [
            -1.4570580503093902,
            42.67427830446749,
            -1.4570580503093902,
            42.67427830446749
          ]
        }
      ],
      "bbox": [
        -1.4570580503093902,
        42.67427830446749,
        -1.4570580503093902,
        42.67427830446749
      ]
    },
    "31125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.089113224397701,
              42.643294912447075
            ]
          },
          "bbox": [
            -2.089113224397701,
            42.643294912447075,
            -2.089113224397701,
            42.643294912447075
          ]
        }
      ],
      "bbox": [
        -2.089113224397701,
        42.643294912447075,
        -2.089113224397701,
        42.643294912447075
      ]
    },
    "31126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7943420222566506,
              42.9557093255762
            ]
          },
          "bbox": [
            -1.7943420222566506,
            42.9557093255762,
            -1.7943420222566506,
            42.9557093255762
          ]
        }
      ],
      "bbox": [
        -1.7943420222566506,
        42.9557093255762,
        -1.7943420222566506,
        42.9557093255762
      ]
    },
    "31127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.936858173879749,
              42.91268928124516
            ]
          },
          "bbox": [
            -1.936858173879749,
            42.91268928124516,
            -1.936858173879749,
            42.91268928124516
          ]
        }
      ],
      "bbox": [
        -1.936858173879749,
        42.91268928124516,
        -1.936858173879749,
        42.91268928124516
      ]
    },
    "31128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.855067471504125,
              42.90400000648795
            ]
          },
          "bbox": [
            -0.855067471504125,
            42.90400000648795,
            -0.855067471504125,
            42.90400000648795
          ]
        }
      ],
      "bbox": [
        -0.855067471504125,
        42.90400000648795,
        -0.855067471504125,
        42.90400000648795
      ]
    },
    "31129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7204841355875504,
              43.13534775973108
            ]
          },
          "bbox": [
            -1.7204841355875504,
            43.13534775973108,
            -1.7204841355875504,
            43.13534775973108
          ]
        }
      ],
      "bbox": [
        -1.7204841355875504,
        43.13534775973108,
        -1.7204841355875504,
        43.13534775973108
      ]
    },
    "31130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.125166970319742,
              42.90526366739306
            ]
          },
          "bbox": [
            -2.125166970319742,
            42.90526366739306,
            -2.125166970319742,
            42.90526366739306
          ]
        }
      ],
      "bbox": [
        -2.125166970319742,
        42.90526366739306,
        -2.125166970319742,
        42.90526366739306
      ]
    },
    "31131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7735570460078451,
              42.88552292138193
            ]
          },
          "bbox": [
            -1.7735570460078451,
            42.88552292138193,
            -1.7735570460078451,
            42.88552292138193
          ]
        }
      ],
      "bbox": [
        -1.7735570460078451,
        42.88552292138193,
        -1.7735570460078451,
        42.88552292138193
      ]
    },
    "31132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4186256810235145,
              42.72982595545565
            ]
          },
          "bbox": [
            -1.4186256810235145,
            42.72982595545565,
            -1.4186256810235145,
            42.72982595545565
          ]
        }
      ],
      "bbox": [
        -1.4186256810235145,
        42.72982595545565,
        -1.4186256810235145,
        42.72982595545565
      ]
    },
    "31133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.03678042680045,
              42.927999111178
            ]
          },
          "bbox": [
            -1.03678042680045,
            42.927999111178,
            -1.03678042680045,
            42.927999111178
          ]
        }
      ],
      "bbox": [
        -1.03678042680045,
        42.927999111178,
        -1.03678042680045,
        42.927999111178
      ]
    },
    "31134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1510446988709986,
              42.89939710527813
            ]
          },
          "bbox": [
            -1.1510446988709986,
            42.89939710527813,
            -1.1510446988709986,
            42.89939710527813
          ]
        }
      ],
      "bbox": [
        -1.1510446988709986,
        42.89939710527813,
        -1.1510446988709986,
        42.89939710527813
      ]
    },
    "31135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.290121732713072,
              42.503783345221684
            ]
          },
          "bbox": [
            -1.290121732713072,
            42.503783345221684,
            -1.290121732713072,
            42.503783345221684
          ]
        }
      ],
      "bbox": [
        -1.290121732713072,
        42.503783345221684,
        -1.290121732713072,
        42.503783345221684
      ]
    },
    "31136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7067688599261257,
              42.90333517035627
            ]
          },
          "bbox": [
            -1.7067688599261257,
            42.90333517035627,
            -1.7067688599261257,
            42.90333517035627
          ]
        }
      ],
      "bbox": [
        -1.7067688599261257,
        42.90333517035627,
        -1.7067688599261257,
        42.90333517035627
      ]
    },
    "31137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.756249004082952,
              43.0983010440344
            ]
          },
          "bbox": [
            -1.756249004082952,
            43.0983010440344,
            -1.756249004082952,
            43.0983010440344
          ]
        }
      ],
      "bbox": [
        -1.756249004082952,
        43.0983010440344,
        -1.756249004082952,
        43.0983010440344
      ]
    },
    "31138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.017420704778075,
              42.934327425345415
            ]
          },
          "bbox": [
            -2.017420704778075,
            42.934327425345415,
            -2.017420704778075,
            42.934327425345415
          ]
        }
      ],
      "bbox": [
        -2.017420704778075,
        42.934327425345415,
        -2.017420704778075,
        42.934327425345415
      ]
    },
    "31139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.253682386721271,
              42.71361792543982
            ]
          },
          "bbox": [
            -2.253682386721271,
            42.71361792543982,
            -2.253682386721271,
            42.71361792543982
          ]
        }
      ],
      "bbox": [
        -2.253682386721271,
        42.71361792543982,
        -2.253682386721271,
        42.71361792543982
      ]
    },
    "31140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6097895874819024,
              43.00925555187806
            ]
          },
          "bbox": [
            -1.6097895874819024,
            43.00925555187806,
            -1.6097895874819024,
            43.00925555187806
          ]
        }
      ],
      "bbox": [
        -1.6097895874819024,
        43.00925555187806,
        -1.6097895874819024,
        43.00925555187806
      ]
    },
    "31141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4603866505316394,
              42.600322222823635
            ]
          },
          "bbox": [
            -2.4603866505316394,
            42.600322222823635,
            -2.4603866505316394,
            42.600322222823635
          ]
        }
      ],
      "bbox": [
        -2.4603866505316394,
        42.600322222823635,
        -2.4603866505316394,
        42.600322222823635
      ]
    },
    "31142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8648119612204415,
              42.54888403670781
            ]
          },
          "bbox": [
            -1.8648119612204415,
            42.54888403670781,
            -1.8648119612204415,
            42.54888403670781
          ]
        }
      ],
      "bbox": [
        -1.8648119612204415,
        42.54888403670781,
        -1.8648119612204415,
        42.54888403670781
      ]
    },
    "31143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2581966742062516,
              42.775644587933684
            ]
          },
          "bbox": [
            -2.2581966742062516,
            42.775644587933684,
            -2.2581966742062516,
            42.775644587933684
          ]
        }
      ],
      "bbox": [
        -2.2581966742062516,
        42.775644587933684,
        -2.2581966742062516,
        42.775644587933684
      ]
    },
    "31144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9063285095653515,
              43.00027208813001
            ]
          },
          "bbox": [
            -1.9063285095653515,
            43.00027208813001,
            -1.9063285095653515,
            43.00027208813001
          ]
        }
      ],
      "bbox": [
        -1.9063285095653515,
        43.00027208813001,
        -1.9063285095653515,
        43.00027208813001
      ]
    },
    "31145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.231276580991044,
              42.49124578200017
            ]
          },
          "bbox": [
            -2.231276580991044,
            42.49124578200017,
            -2.231276580991044,
            42.49124578200017
          ]
        }
      ],
      "bbox": [
        -2.231276580991044,
        42.49124578200017,
        -2.231276580991044,
        42.49124578200017
      ]
    },
    "31146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.412748046560587,
              42.61474183341233
            ]
          },
          "bbox": [
            -1.412748046560587,
            42.61474183341233,
            -1.412748046560587,
            42.61474183341233
          ]
        }
      ],
      "bbox": [
        -1.412748046560587,
        42.61474183341233,
        -1.412748046560587,
        42.61474183341233
      ]
    },
    "31147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7775459710146564,
              42.71844195181099
            ]
          },
          "bbox": [
            -1.7775459710146564,
            42.71844195181099,
            -1.7775459710146564,
            42.71844195181099
          ]
        }
      ],
      "bbox": [
        -1.7775459710146564,
        42.71844195181099,
        -1.7775459710146564,
        42.71844195181099
      ]
    },
    "31148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.180297137288192,
              42.64308612525834
            ]
          },
          "bbox": [
            -2.180297137288192,
            42.64308612525834,
            -2.180297137288192,
            42.64308612525834
          ]
        }
      ],
      "bbox": [
        -2.180297137288192,
        42.64308612525834,
        -2.180297137288192,
        42.64308612525834
      ]
    },
    "31149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.893133859994507,
              43.10310003572627
            ]
          },
          "bbox": [
            -1.893133859994507,
            43.10310003572627,
            -1.893133859994507,
            43.10310003572627
          ]
        }
      ],
      "bbox": [
        -1.893133859994507,
        43.10310003572627,
        -1.893133859994507,
        43.10310003572627
      ]
    },
    "31150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5596995578640271,
              42.59975464045631
            ]
          },
          "bbox": [
            -1.5596995578640271,
            42.59975464045631,
            -1.5596995578640271,
            42.59975464045631
          ]
        }
      ],
      "bbox": [
        -1.5596995578640271,
        42.59975464045631,
        -1.5596995578640271,
        42.59975464045631
      ]
    },
    "31151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4995077681336908,
              42.562549698670594
            ]
          },
          "bbox": [
            -1.4995077681336908,
            42.562549698670594,
            -1.4995077681336908,
            42.562549698670594
          ]
        }
      ],
      "bbox": [
        -1.4995077681336908,
        42.562549698670594,
        -1.4995077681336908,
        42.562549698670594
      ]
    },
    "31152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9596222659128744,
              42.47853467326774
            ]
          },
          "bbox": [
            -1.9596222659128744,
            42.47853467326774,
            -1.9596222659128744,
            42.47853467326774
          ]
        }
      ],
      "bbox": [
        -1.9596222659128744,
        42.47853467326774,
        -1.9596222659128744,
        42.47853467326774
      ]
    },
    "31153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.738093197676408,
              43.2586885086759
            ]
          },
          "bbox": [
            -1.738093197676408,
            43.2586885086759,
            -1.738093197676408,
            43.2586885086759
          ]
        }
      ],
      "bbox": [
        -1.738093197676408,
        43.2586885086759,
        -1.738093197676408,
        43.2586885086759
      ]
    },
    "31154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0075968938827424,
              42.793376741763836
            ]
          },
          "bbox": [
            -2.0075968938827424,
            42.793376741763836,
            -2.0075968938827424,
            42.793376741763836
          ]
        }
      ],
      "bbox": [
        -2.0075968938827424,
        42.793376741763836,
        -2.0075968938827424,
        42.793376741763836
      ]
    },
    "31155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2613924244970003,
              42.61474410351724
            ]
          },
          "bbox": [
            -1.2613924244970003,
            42.61474410351724,
            -1.2613924244970003,
            42.61474410351724
          ]
        }
      ],
      "bbox": [
        -1.2613924244970003,
        42.61474410351724,
        -1.2613924244970003,
        42.61474410351724
      ]
    },
    "31156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4672359214067785,
              42.83021531805007
            ]
          },
          "bbox": [
            -1.4672359214067785,
            42.83021531805007,
            -1.4672359214067785,
            42.83021531805007
          ]
        }
      ],
      "bbox": [
        -1.4672359214067785,
        42.83021531805007,
        -1.4672359214067785,
        42.83021531805007
      ]
    },
    "31157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0757821813153985,
              42.40425272256204
            ]
          },
          "bbox": [
            -2.0757821813153985,
            42.40425272256204,
            -2.0757821813153985,
            42.40425272256204
          ]
        }
      ],
      "bbox": [
        -2.0757821813153985,
        42.40425272256204,
        -2.0757821813153985,
        42.40425272256204
      ]
    },
    "31158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3588595201395788,
              42.78173922203703
            ]
          },
          "bbox": [
            -1.3588595201395788,
            42.78173922203703,
            -1.3588595201395788,
            42.78173922203703
          ]
        }
      ],
      "bbox": [
        -1.3588595201395788,
        42.78173922203703,
        -1.3588595201395788,
        42.78173922203703
      ]
    },
    "31159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2898380496763695,
              42.647889868087965
            ]
          },
          "bbox": [
            -1.2898380496763695,
            42.647889868087965,
            -1.2898380496763695,
            42.647889868087965
          ]
        }
      ],
      "bbox": [
        -1.2898380496763695,
        42.647889868087965,
        -1.2898380496763695,
        42.647889868087965
      ]
    },
    "31160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.132206738814872,
              42.57036263739641
            ]
          },
          "bbox": [
            -2.132206738814872,
            42.57036263739641,
            -2.132206738814872,
            42.57036263739641
          ]
        }
      ],
      "bbox": [
        -2.132206738814872,
        42.57036263739641,
        -2.132206738814872,
        42.57036263739641
      ]
    },
    "31161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8580591369591142,
              42.655026285689885
            ]
          },
          "bbox": [
            -1.8580591369591142,
            42.655026285689885,
            -1.8580591369591142,
            42.655026285689885
          ]
        }
      ],
      "bbox": [
        -1.8580591369591142,
        42.655026285689885,
        -1.8580591369591142,
        42.655026285689885
      ]
    },
    "31162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4448588270142433,
              42.62344957129068
            ]
          },
          "bbox": [
            -2.4448588270142433,
            42.62344957129068,
            -2.4448588270142433,
            42.62344957129068
          ]
        }
      ],
      "bbox": [
        -2.4448588270142433,
        42.62344957129068,
        -2.4448588270142433,
        42.62344957129068
      ]
    },
    "31163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7222279113792105,
              42.341985643155354
            ]
          },
          "bbox": [
            -1.7222279113792105,
            42.341985643155354,
            -1.7222279113792105,
            42.341985643155354
          ]
        }
      ],
      "bbox": [
        -1.7222279113792105,
        42.341985643155354,
        -1.7222279113792105,
        42.341985643155354
      ]
    },
    "31164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.528901005938575,
              42.34612224915991
            ]
          },
          "bbox": [
            -1.528901005938575,
            42.34612224915991,
            -1.528901005938575,
            42.34612224915991
          ]
        }
      ],
      "bbox": [
        -1.528901005938575,
        42.34612224915991,
        -1.528901005938575,
        42.34612224915991
      ]
    },
    "31165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.196142460425093,
              42.45271461542743
            ]
          },
          "bbox": [
            -2.196142460425093,
            42.45271461542743,
            -2.196142460425093,
            42.45271461542743
          ]
        }
      ],
      "bbox": [
        -2.196142460425093,
        42.45271461542743,
        -2.196142460425093,
        42.45271461542743
      ]
    },
    "31166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2476638862962144,
              42.65428528464176
            ]
          },
          "bbox": [
            -2.2476638862962144,
            42.65428528464176,
            -2.2476638862962144,
            42.65428528464176
          ]
        }
      ],
      "bbox": [
        -2.2476638862962144,
        42.65428528464176,
        -2.2476638862962144,
        42.65428528464176
      ]
    },
    "31167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8408157905689926,
              42.61177434081112
            ]
          },
          "bbox": [
            -1.8408157905689926,
            42.61177434081112,
            -1.8408157905689926,
            42.61177434081112
          ]
        }
      ],
      "bbox": [
        -1.8408157905689926,
        42.61177434081112,
        -1.8408157905689926,
        42.61177434081112
      ]
    },
    "31168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1203890626496533,
              42.682015938810125
            ]
          },
          "bbox": [
            -2.1203890626496533,
            42.682015938810125,
            -2.1203890626496533,
            42.682015938810125
          ]
        }
      ],
      "bbox": [
        -2.1203890626496533,
        42.682015938810125,
        -2.1203890626496533,
        42.682015938810125
      ]
    },
    "31169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7655738239892906,
              42.23607602682764
            ]
          },
          "bbox": [
            -1.7655738239892906,
            42.23607602682764,
            -1.7655738239892906,
            42.23607602682764
          ]
        }
      ],
      "bbox": [
        -1.7655738239892906,
        42.23607602682764,
        -1.7655738239892906,
        42.23607602682764
      ]
    },
    "31170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2810459810544925,
              42.621393214127515
            ]
          },
          "bbox": [
            -2.2810459810544925,
            42.621393214127515,
            -2.2810459810544925,
            42.621393214127515
          ]
        }
      ],
      "bbox": [
        -2.2810459810544925,
        42.621393214127515,
        -2.2810459810544925,
        42.621393214127515
      ]
    },
    "31171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8302360352598028,
              42.48219976539945
            ]
          },
          "bbox": [
            -1.8302360352598028,
            42.48219976539945,
            -1.8302360352598028,
            42.48219976539945
          ]
        }
      ],
      "bbox": [
        -1.8302360352598028,
        42.48219976539945,
        -1.8302360352598028,
        42.48219976539945
      ]
    },
    "31172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5265286412885382,
              42.70454372772052
            ]
          },
          "bbox": [
            -1.5265286412885382,
            42.70454372772052,
            -1.5265286412885382,
            42.70454372772052
          ]
        }
      ],
      "bbox": [
        -1.5265286412885382,
        42.70454372772052,
        -1.5265286412885382,
        42.70454372772052
      ]
    },
    "31173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7047154954286037,
              41.9675684718363
            ]
          },
          "bbox": [
            -1.7047154954286037,
            41.9675684718363,
            -1.7047154954286037,
            41.9675684718363
          ]
        }
      ],
      "bbox": [
        -1.7047154954286037,
        41.9675684718363,
        -1.7047154954286037,
        41.9675684718363
      ]
    },
    "31174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.996177435478903,
              42.598130306949706
            ]
          },
          "bbox": [
            -1.996177435478903,
            42.598130306949706,
            -1.996177435478903,
            42.598130306949706
          ]
        }
      ],
      "bbox": [
        -1.996177435478903,
        42.598130306949706,
        -1.996177435478903,
        42.598130306949706
      ]
    },
    "31175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2340479212271016,
              42.59874936116375
            ]
          },
          "bbox": [
            -2.2340479212271016,
            42.59874936116375,
            -2.2340479212271016,
            42.59874936116375
          ]
        }
      ],
      "bbox": [
        -2.2340479212271016,
        42.59874936116375,
        -2.2340479212271016,
        42.59874936116375
      ]
    },
    "31176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6693916257367651,
              42.04403012874122
            ]
          },
          "bbox": [
            -1.6693916257367651,
            42.04403012874122,
            -1.6693916257367651,
            42.04403012874122
          ]
        }
      ],
      "bbox": [
        -1.6693916257367651,
        42.04403012874122,
        -1.6693916257367651,
        42.04403012874122
      ]
    },
    "31177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1507874150443897,
              42.66098069955958
            ]
          },
          "bbox": [
            -2.1507874150443897,
            42.66098069955958,
            -2.1507874150443897,
            42.66098069955958
          ]
        }
      ],
      "bbox": [
        -2.1507874150443897,
        42.66098069955958,
        -2.1507874150443897,
        42.66098069955958
      ]
    },
    "31178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5997060792424291,
              42.344039992850234
            ]
          },
          "bbox": [
            -1.5997060792424291,
            42.344039992850234,
            -1.5997060792424291,
            42.344039992850234
          ]
        }
      ],
      "bbox": [
        -1.5997060792424291,
        42.344039992850234,
        -1.5997060792424291,
        42.344039992850234
      ]
    },
    "31179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.470477851119036,
              42.39978605463968
            ]
          },
          "bbox": [
            -1.470477851119036,
            42.39978605463968,
            -1.470477851119036,
            42.39978605463968
          ]
        }
      ],
      "bbox": [
        -1.470477851119036,
        42.39978605463968,
        -1.470477851119036,
        42.39978605463968
      ]
    },
    "31180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.76172946354096,
              42.68887312580159
            ]
          },
          "bbox": [
            -1.76172946354096,
            42.68887312580159,
            -1.76172946354096,
            42.68887312580159
          ]
        }
      ],
      "bbox": [
        -1.76172946354096,
        42.68887312580159,
        -1.76172946354096,
        42.68887312580159
      ]
    },
    "31181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1158891709201835,
              42.72928110315853
            ]
          },
          "bbox": [
            -1.1158891709201835,
            42.72928110315853,
            -1.1158891709201835,
            42.72928110315853
          ]
        }
      ],
      "bbox": [
        -1.1158891709201835,
        42.72928110315853,
        -1.1158891709201835,
        42.72928110315853
      ]
    },
    "31182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.286927643928537,
              42.64087921652203
            ]
          },
          "bbox": [
            -2.286927643928537,
            42.64087921652203,
            -2.286927643928537,
            42.64087921652203
          ]
        }
      ],
      "bbox": [
        -2.286927643928537,
        42.64087921652203,
        -2.286927643928537,
        42.64087921652203
      ]
    },
    "31183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7852977825844045,
              42.66168862385963
            ]
          },
          "bbox": [
            -1.7852977825844045,
            42.66168862385963,
            -1.7852977825844045,
            42.66168862385963
          ]
        }
      ],
      "bbox": [
        -1.7852977825844045,
        42.66168862385963,
        -1.7852977825844045,
        42.66168862385963
      ]
    },
    "31184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1630406034466145,
              42.63823126993152
            ]
          },
          "bbox": [
            -2.1630406034466145,
            42.63823126993152,
            -2.1630406034466145,
            42.63823126993152
          ]
        }
      ],
      "bbox": [
        -2.1630406034466145,
        42.63823126993152,
        -2.1630406034466145,
        42.63823126993152
      ]
    },
    "31185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.075886437326595,
              42.958610561793364
            ]
          },
          "bbox": [
            -1.075886437326595,
            42.958610561793364,
            -1.075886437326595,
            42.958610561793364
          ]
        }
      ],
      "bbox": [
        -1.075886437326595,
        42.958610561793364,
        -1.075886437326595,
        42.958610561793364
      ]
    },
    "31186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6465353409447376,
              42.93210429366074
            ]
          },
          "bbox": [
            -1.6465353409447376,
            42.93210429366074,
            -1.6465353409447376,
            42.93210429366074
          ]
        }
      ],
      "bbox": [
        -1.6465353409447376,
        42.93210429366074,
        -1.6465353409447376,
        42.93210429366074
      ]
    },
    "31187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6917570716225276,
              43.090796494281676
            ]
          },
          "bbox": [
            -1.6917570716225276,
            43.090796494281676,
            -1.6917570716225276,
            43.090796494281676
          ]
        }
      ],
      "bbox": [
        -1.6917570716225276,
        43.090796494281676,
        -1.6917570716225276,
        43.090796494281676
      ]
    },
    "31188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6030747943334045,
              42.89625697808538
            ]
          },
          "bbox": [
            -1.6030747943334045,
            42.89625697808538,
            -1.6030747943334045,
            42.89625697808538
          ]
        }
      ],
      "bbox": [
        -1.6030747943334045,
        42.89625697808538,
        -1.6030747943334045,
        42.89625697808538
      ]
    },
    "31189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.201360799068941,
              42.887496954367016
            ]
          },
          "bbox": [
            -2.201360799068941,
            42.887496954367016,
            -2.201360799068941,
            42.887496954367016
          ]
        }
      ],
      "bbox": [
        -2.201360799068941,
        42.887496954367016,
        -2.201360799068941,
        42.887496954367016
      ]
    },
    "31190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1401012117985196,
              42.624315980623415
            ]
          },
          "bbox": [
            -2.1401012117985196,
            42.624315980623415,
            -2.1401012117985196,
            42.624315980623415
          ]
        }
      ],
      "bbox": [
        -2.1401012117985196,
        42.624315980623415,
        -2.1401012117985196,
        42.624315980623415
      ]
    },
    "31191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6749362991671857,
              42.44523558762745
            ]
          },
          "bbox": [
            -1.6749362991671857,
            42.44523558762745,
            -1.6749362991671857,
            42.44523558762745
          ]
        }
      ],
      "bbox": [
        -1.6749362991671857,
        42.44523558762745,
        -1.6749362991671857,
        42.44523558762745
      ]
    },
    "31192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5993339964623718,
              42.64270322962647
            ]
          },
          "bbox": [
            -1.5993339964623718,
            42.64270322962647,
            -1.5993339964623718,
            42.64270322962647
          ]
        }
      ],
      "bbox": [
        -1.5993339964623718,
        42.64270322962647,
        -1.5993339964623718,
        42.64270322962647
      ]
    },
    "31193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7656724265968184,
              42.82084060988056
            ]
          },
          "bbox": [
            -1.7656724265968184,
            42.82084060988056,
            -1.7656724265968184,
            42.82084060988056
          ]
        }
      ],
      "bbox": [
        -1.7656724265968184,
        42.82084060988056,
        -1.7656724265968184,
        42.82084060988056
      ]
    },
    "31194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8513079303190614,
              42.86033370046063
            ]
          },
          "bbox": [
            -1.8513079303190614,
            42.86033370046063,
            -1.8513079303190614,
            42.86033370046063
          ]
        }
      ],
      "bbox": [
        -1.8513079303190614,
        42.86033370046063,
        -1.8513079303190614,
        42.86033370046063
      ]
    },
    "31195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2142364479142527,
              43.007528959470484
            ]
          },
          "bbox": [
            -1.2142364479142527,
            43.007528959470484,
            -1.2142364479142527,
            43.007528959470484
          ]
        }
      ],
      "bbox": [
        -1.2142364479142527,
        43.007528959470484,
        -1.2142364479142527,
        43.007528959470484
      ]
    },
    "31196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.252353429609548,
              42.97372077345037
            ]
          },
          "bbox": [
            -1.252353429609548,
            42.97372077345037,
            -1.252353429609548,
            42.97372077345037
          ]
        }
      ],
      "bbox": [
        -1.252353429609548,
        42.97372077345037,
        -1.252353429609548,
        42.97372077345037
      ]
    },
    "31197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5948002077720307,
              42.59902387869788
            ]
          },
          "bbox": [
            -1.5948002077720307,
            42.59902387869788,
            -1.5948002077720307,
            42.59902387869788
          ]
        }
      ],
      "bbox": [
        -1.5948002077720307,
        42.59902387869788,
        -1.5948002077720307,
        42.59902387869788
      ]
    },
    "31198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0730158133656633,
              42.87535284499067
            ]
          },
          "bbox": [
            -1.0730158133656633,
            42.87535284499067,
            -1.0730158133656633,
            42.87535284499067
          ]
        }
      ],
      "bbox": [
        -1.0730158133656633,
        42.87535284499067,
        -1.0730158133656633,
        42.87535284499067
      ]
    },
    "31199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3043919390883782,
              42.90655966793525
            ]
          },
          "bbox": [
            -1.3043919390883782,
            42.90655966793525,
            -1.3043919390883782,
            42.90655966793525
          ]
        }
      ],
      "bbox": [
        -1.3043919390883782,
        42.90655966793525,
        -1.3043919390883782,
        42.90655966793525
      ]
    },
    "31200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9446193361963022,
              42.57996834949869
            ]
          },
          "bbox": [
            -1.9446193361963022,
            42.57996834949869,
            -1.9446193361963022,
            42.57996834949869
          ]
        }
      ],
      "bbox": [
        -1.9446193361963022,
        42.57996834949869,
        -1.9446193361963022,
        42.57996834949869
      ]
    },
    "31201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6513797404423276,
              42.81498012592544
            ]
          },
          "bbox": [
            -1.6513797404423276,
            42.81498012592544,
            -1.6513797404423276,
            42.81498012592544
          ]
        }
      ],
      "bbox": [
        -1.6513797404423276,
        42.81498012592544,
        -1.6513797404423276,
        42.81498012592544
      ]
    },
    "31202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.80244556292657,
              42.356510475552255
            ]
          },
          "bbox": [
            -1.80244556292657,
            42.356510475552255,
            -1.80244556292657,
            42.356510475552255
          ]
        }
      ],
      "bbox": [
        -1.80244556292657,
        42.356510475552255,
        -1.80244556292657,
        42.356510475552255
      ]
    },
    "31203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1114834404248772,
              42.44658523790207
            ]
          },
          "bbox": [
            -1.1114834404248772,
            42.44658523790207,
            -1.1114834404248772,
            42.44658523790207
          ]
        }
      ],
      "bbox": [
        -1.1114834404248772,
        42.44658523790207,
        -1.1114834404248772,
        42.44658523790207
      ]
    },
    "31204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2101517018168235,
              42.62083263676479
            ]
          },
          "bbox": [
            -2.2101517018168235,
            42.62083263676479,
            -2.2101517018168235,
            42.62083263676479
          ]
        }
      ],
      "bbox": [
        -2.2101517018168235,
        42.62083263676479,
        -2.2101517018168235,
        42.62083263676479
      ]
    },
    "31205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5827221887108622,
              42.424978101127344
            ]
          },
          "bbox": [
            -1.5827221887108622,
            42.424978101127344,
            -1.5827221887108622,
            42.424978101127344
          ]
        }
      ],
      "bbox": [
        -1.5827221887108622,
        42.424978101127344,
        -1.5827221887108622,
        42.424978101127344
      ]
    },
    "31206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.81685430828187,
              42.69511896894183
            ]
          },
          "bbox": [
            -1.81685430828187,
            42.69511896894183,
            -1.81685430828187,
            42.69511896894183
          ]
        }
      ],
      "bbox": [
        -1.81685430828187,
        42.69511896894183,
        -1.81685430828187,
        42.69511896894183
      ]
    },
    "31207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.658206323489825,
              42.56737743142579
            ]
          },
          "bbox": [
            -1.658206323489825,
            42.56737743142579,
            -1.658206323489825,
            42.56737743142579
          ]
        }
      ],
      "bbox": [
        -1.658206323489825,
        42.56737743142579,
        -1.658206323489825,
        42.56737743142579
      ]
    },
    "31208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5207248205403658,
              41.98109438545985
            ]
          },
          "bbox": [
            -1.5207248205403658,
            41.98109438545985,
            -1.5207248205403658,
            41.98109438545985
          ]
        }
      ],
      "bbox": [
        -1.5207248205403658,
        41.98109438545985,
        -1.5207248205403658,
        41.98109438545985
      ]
    },
    "31209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.195256919753564,
              42.684415824081746
            ]
          },
          "bbox": [
            -1.195256919753564,
            42.684415824081746,
            -1.195256919753564,
            42.684415824081746
          ]
        }
      ],
      "bbox": [
        -1.195256919753564,
        42.684415824081746,
        -1.195256919753564,
        42.684415824081746
      ]
    },
    "31210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.953763353685712,
              42.80603147237996
            ]
          },
          "bbox": [
            -0.953763353685712,
            42.80603147237996,
            -0.953763353685712,
            42.80603147237996
          ]
        }
      ],
      "bbox": [
        -0.953763353685712,
        42.80603147237996,
        -0.953763353685712,
        42.80603147237996
      ]
    },
    "31211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3080682100201724,
              43.01424934116844
            ]
          },
          "bbox": [
            -1.3080682100201724,
            43.01424934116844,
            -1.3080682100201724,
            43.01424934116844
          ]
        }
      ],
      "bbox": [
        -1.3080682100201724,
        43.01424934116844,
        -1.3080682100201724,
        43.01424934116844
      ]
    },
    "31212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3939767036120265,
              42.57311209881667
            ]
          },
          "bbox": [
            -1.3939767036120265,
            42.57311209881667,
            -1.3939767036120265,
            42.57311209881667
          ]
        }
      ],
      "bbox": [
        -1.3939767036120265,
        42.57311209881667,
        -1.3939767036120265,
        42.57311209881667
      ]
    },
    "31213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7762452849755854,
              43.08715274835545
            ]
          },
          "bbox": [
            -1.7762452849755854,
            43.08715274835545,
            -1.7762452849755854,
            43.08715274835545
          ]
        }
      ],
      "bbox": [
        -1.7762452849755854,
        43.08715274835545,
        -1.7762452849755854,
        43.08715274835545
      ]
    },
    "31214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8814894129611204,
              42.77861034126321
            ]
          },
          "bbox": [
            -1.8814894129611204,
            42.77861034126321,
            -1.8814894129611204,
            42.77861034126321
          ]
        }
      ],
      "bbox": [
        -1.8814894129611204,
        42.77861034126321,
        -1.8814894129611204,
        42.77861034126321
      ]
    },
    "31215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9191459569806852,
              42.345412693483986
            ]
          },
          "bbox": [
            -1.9191459569806852,
            42.345412693483986,
            -1.9191459569806852,
            42.345412693483986
          ]
        }
      ],
      "bbox": [
        -1.9191459569806852,
        42.345412693483986,
        -1.9191459569806852,
        42.345412693483986
      ]
    },
    "31216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.271687188692255,
              42.56921064994109
            ]
          },
          "bbox": [
            -1.271687188692255,
            42.56921064994109,
            -1.271687188692255,
            42.56921064994109
          ]
        }
      ],
      "bbox": [
        -1.271687188692255,
        42.56921064994109,
        -1.271687188692255,
        42.56921064994109
      ]
    },
    "31217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5737969259556823,
              42.51793507127465
            ]
          },
          "bbox": [
            -1.5737969259556823,
            42.51793507127465,
            -1.5737969259556823,
            42.51793507127465
          ]
        }
      ],
      "bbox": [
        -1.5737969259556823,
        42.51793507127465,
        -1.5737969259556823,
        42.51793507127465
      ]
    },
    "31219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2531886349426182,
              42.5386674610719
            ]
          },
          "bbox": [
            -2.2531886349426182,
            42.5386674610719,
            -2.2531886349426182,
            42.5386674610719
          ]
        }
      ],
      "bbox": [
        -2.2531886349426182,
        42.5386674610719,
        -2.2531886349426182,
        42.5386674610719
      ]
    },
    "31220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5495810701561796,
              42.38924389679333
            ]
          },
          "bbox": [
            -1.5495810701561796,
            42.38924389679333,
            -1.5495810701561796,
            42.38924389679333
          ]
        }
      ],
      "bbox": [
        -1.5495810701561796,
        42.38924389679333,
        -1.5495810701561796,
        42.38924389679333
      ]
    },
    "31221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.669838238445362,
              43.13535079003184
            ]
          },
          "bbox": [
            -1.669838238445362,
            43.13535079003184,
            -1.669838238445362,
            43.13535079003184
          ]
        }
      ],
      "bbox": [
        -1.669838238445362,
        43.13535079003184,
        -1.669838238445362,
        43.13535079003184
      ]
    },
    "31222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1042804619876516,
              42.84033059935437
            ]
          },
          "bbox": [
            -1.1042804619876516,
            42.84033059935437,
            -1.1042804619876516,
            42.84033059935437
          ]
        }
      ],
      "bbox": [
        -1.1042804619876516,
        42.84033059935437,
        -1.1042804619876516,
        42.84033059935437
      ]
    },
    "31223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0418096721793035,
              42.384671368208
            ]
          },
          "bbox": [
            -2.0418096721793035,
            42.384671368208,
            -2.0418096721793035,
            42.384671368208
          ]
        }
      ],
      "bbox": [
        -2.0418096721793035,
        42.384671368208,
        -2.0418096721793035,
        42.384671368208
      ]
    },
    "31224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0869070073234197,
              42.47056176473039
            ]
          },
          "bbox": [
            -2.0869070073234197,
            42.47056176473039,
            -2.0869070073234197,
            42.47056176473039
          ]
        }
      ],
      "bbox": [
        -2.0869070073234197,
        42.47056176473039,
        -2.0869070073234197,
        42.47056176473039
      ]
    },
    "31225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.214550616088404,
              42.61121427960941
            ]
          },
          "bbox": [
            -2.214550616088404,
            42.61121427960941,
            -2.214550616088404,
            42.61121427960941
          ]
        }
      ],
      "bbox": [
        -2.214550616088404,
        42.61121427960941,
        -2.214550616088404,
        42.61121427960941
      ]
    },
    "31226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6648749200849164,
              43.179144205503846
            ]
          },
          "bbox": [
            -1.6648749200849164,
            43.179144205503846,
            -1.6648749200849164,
            43.179144205503846
          ]
        }
      ],
      "bbox": [
        -1.6648749200849164,
        43.179144205503846,
        -1.6648749200849164,
        43.179144205503846
      ]
    },
    "31227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.71008281994599,
              42.515808941249155
            ]
          },
          "bbox": [
            -1.71008281994599,
            42.515808941249155,
            -1.71008281994599,
            42.515808941249155
          ]
        }
      ],
      "bbox": [
        -1.71008281994599,
        42.515808941249155,
        -1.71008281994599,
        42.515808941249155
      ]
    },
    "31228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6405701744230299,
              42.68596248760044
            ]
          },
          "bbox": [
            -1.6405701744230299,
            42.68596248760044,
            -1.6405701744230299,
            42.68596248760044
          ]
        }
      ],
      "bbox": [
        -1.6405701744230299,
        42.68596248760044,
        -1.6405701744230299,
        42.68596248760044
      ]
    },
    "31229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6932568554708585,
              42.64727651742211
            ]
          },
          "bbox": [
            -1.6932568554708585,
            42.64727651742211,
            -1.6932568554708585,
            42.64727651742211
          ]
        }
      ],
      "bbox": [
        -1.6932568554708585,
        42.64727651742211,
        -1.6932568554708585,
        42.64727651742211
      ]
    },
    "31230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3215546798808697,
              42.62189215394849
            ]
          },
          "bbox": [
            -2.3215546798808697,
            42.62189215394849,
            -2.3215546798808697,
            42.62189215394849
          ]
        }
      ],
      "bbox": [
        -2.3215546798808697,
        42.62189215394849,
        -2.3215546798808697,
        42.62189215394849
      ]
    },
    "31231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.270939195790141,
              42.52091143107423
            ]
          },
          "bbox": [
            -2.270939195790141,
            42.52091143107423,
            -2.270939195790141,
            42.52091143107423
          ]
        }
      ],
      "bbox": [
        -2.270939195790141,
        42.52091143107423,
        -2.270939195790141,
        42.52091143107423
      ]
    },
    "31232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6445886283666387,
              42.08118652624855
            ]
          },
          "bbox": [
            -1.6445886283666387,
            42.08118652624855,
            -1.6445886283666387,
            42.08118652624855
          ]
        }
      ],
      "bbox": [
        -1.6445886283666387,
        42.08118652624855,
        -1.6445886283666387,
        42.08118652624855
      ]
    },
    "31233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6845363283596075,
              41.982991976072434
            ]
          },
          "bbox": [
            -1.6845363283596075,
            41.982991976072434,
            -1.6845363283596075,
            41.982991976072434
          ]
        }
      ],
      "bbox": [
        -1.6845363283596075,
        41.982991976072434,
        -1.6845363283596075,
        41.982991976072434
      ]
    },
    "31234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7065607323613177,
              42.6860888579953
            ]
          },
          "bbox": [
            -1.7065607323613177,
            42.6860888579953,
            -1.7065607323613177,
            42.6860888579953
          ]
        }
      ],
      "bbox": [
        -1.7065607323613177,
        42.6860888579953,
        -1.7065607323613177,
        42.6860888579953
      ]
    },
    "31235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4916883347042567,
              42.475225612522415
            ]
          },
          "bbox": [
            -1.4916883347042567,
            42.475225612522415,
            -1.4916883347042567,
            42.475225612522415
          ]
        }
      ],
      "bbox": [
        -1.4916883347042567,
        42.475225612522415,
        -1.4916883347042567,
        42.475225612522415
      ]
    },
    "31236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6865942252134247,
              43.009607768947696
            ]
          },
          "bbox": [
            -1.6865942252134247,
            43.009607768947696,
            -1.6865942252134247,
            43.009607768947696
          ]
        }
      ],
      "bbox": [
        -1.6865942252134247,
        43.009607768947696,
        -1.6865942252134247,
        43.009607768947696
      ]
    },
    "31237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4795809097472319,
              42.729129283254224
            ]
          },
          "bbox": [
            -1.4795809097472319,
            42.729129283254224,
            -1.4795809097472319,
            42.729129283254224
          ]
        }
      ],
      "bbox": [
        -1.4795809097472319,
        42.729129283254224,
        -1.4795809097472319,
        42.729129283254224
      ]
    },
    "31238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.623737485282955,
              42.66298214488888
            ]
          },
          "bbox": [
            -1.623737485282955,
            42.66298214488888,
            -1.623737485282955,
            42.66298214488888
          ]
        }
      ],
      "bbox": [
        -1.623737485282955,
        42.66298214488888,
        -1.623737485282955,
        42.66298214488888
      ]
    },
    "31239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5108264286987065,
              43.2770687745857
            ]
          },
          "bbox": [
            -1.5108264286987065,
            43.2770687745857,
            -1.5108264286987065,
            43.2770687745857
          ]
        }
      ],
      "bbox": [
        -1.5108264286987065,
        43.2770687745857,
        -1.5108264286987065,
        43.2770687745857
      ]
    },
    "31240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1452479672173332,
              42.90501832939347
            ]
          },
          "bbox": [
            -2.1452479672173332,
            42.90501832939347,
            -2.1452479672173332,
            42.90501832939347
          ]
        }
      ],
      "bbox": [
        -2.1452479672173332,
        42.90501832939347,
        -2.1452479672173332,
        42.90501832939347
      ]
    },
    "31241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2263566948778872,
              42.79164491898377
            ]
          },
          "bbox": [
            -1.2263566948778872,
            42.79164491898377,
            -1.2263566948778872,
            42.79164491898377
          ]
        }
      ],
      "bbox": [
        -1.2263566948778872,
        42.79164491898377,
        -1.2263566948778872,
        42.79164491898377
      ]
    },
    "31242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.327674283523843,
              42.69077052326216
            ]
          },
          "bbox": [
            -1.327674283523843,
            42.69077052326216,
            -1.327674283523843,
            42.69077052326216
          ]
        }
      ],
      "bbox": [
        -1.327674283523843,
        42.69077052326216,
        -1.327674283523843,
        42.69077052326216
      ]
    },
    "31243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4529070665760317,
              42.77871638198514
            ]
          },
          "bbox": [
            -1.4529070665760317,
            42.77871638198514,
            -1.4529070665760317,
            42.77871638198514
          ]
        }
      ],
      "bbox": [
        -1.4529070665760317,
        42.77871638198514,
        -1.4529070665760317,
        42.77871638198514
      ]
    },
    "31244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7093970410668453,
              43.0824401532641
            ]
          },
          "bbox": [
            -1.7093970410668453,
            43.0824401532641,
            -1.7093970410668453,
            43.0824401532641
          ]
        }
      ],
      "bbox": [
        -1.7093970410668453,
        43.0824401532641,
        -1.7093970410668453,
        43.0824401532641
      ]
    },
    "31245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9306614146502301,
              42.83499260898536
            ]
          },
          "bbox": [
            -0.9306614146502301,
            42.83499260898536,
            -0.9306614146502301,
            42.83499260898536
          ]
        }
      ],
      "bbox": [
        -0.9306614146502301,
        42.83499260898536,
        -0.9306614146502301,
        42.83499260898536
      ]
    },
    "31246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7527242037490594,
              42.71455234134425
            ]
          },
          "bbox": [
            -1.7527242037490594,
            42.71455234134425,
            -1.7527242037490594,
            42.71455234134425
          ]
        }
      ],
      "bbox": [
        -1.7527242037490594,
        42.71455234134425,
        -1.7527242037490594,
        42.71455234134425
      ]
    },
    "31247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9594018886421277,
              42.91482160065277
            ]
          },
          "bbox": [
            -0.9594018886421277,
            42.91482160065277,
            -0.9594018886421277,
            42.91482160065277
          ]
        }
      ],
      "bbox": [
        -0.9594018886421277,
        42.91482160065277,
        -0.9594018886421277,
        42.91482160065277
      ]
    },
    "31248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3140574420336348,
              43.06522737174289
            ]
          },
          "bbox": [
            -1.3140574420336348,
            43.06522737174289,
            -1.3140574420336348,
            43.06522737174289
          ]
        }
      ],
      "bbox": [
        -1.3140574420336348,
        43.06522737174289,
        -1.3140574420336348,
        43.06522737174289
      ]
    },
    "31249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.615840142566552,
              42.215244768926084
            ]
          },
          "bbox": [
            -1.615840142566552,
            42.215244768926084,
            -1.615840142566552,
            42.215244768926084
          ]
        }
      ],
      "bbox": [
        -1.615840142566552,
        42.215244768926084,
        -1.615840142566552,
        42.215244768926084
      ]
    },
    "31250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6684492784706866,
              43.28757541277546
            ]
          },
          "bbox": [
            -1.6684492784706866,
            43.28757541277546,
            -1.6684492784706866,
            43.28757541277546
          ]
        }
      ],
      "bbox": [
        -1.6684492784706866,
        43.28757541277546,
        -1.6684492784706866,
        43.28757541277546
      ]
    },
    "31251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.358705334453912,
              42.50615485060045
            ]
          },
          "bbox": [
            -2.358705334453912,
            42.50615485060045,
            -2.358705334453912,
            42.50615485060045
          ]
        }
      ],
      "bbox": [
        -2.358705334453912,
        42.50615485060045,
        -2.358705334453912,
        42.50615485060045
      ]
    },
    "31252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.007815940955439,
              42.81691606631638
            ]
          },
          "bbox": [
            -1.007815940955439,
            42.81691606631638,
            -1.007815940955439,
            42.81691606631638
          ]
        }
      ],
      "bbox": [
        -1.007815940955439,
        42.81691606631638,
        -1.007815940955439,
        42.81691606631638
      ]
    },
    "31253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.842708711171081,
              42.76836392871184
            ]
          },
          "bbox": [
            -1.842708711171081,
            42.76836392871184,
            -1.842708711171081,
            42.76836392871184
          ]
        }
      ],
      "bbox": [
        -1.842708711171081,
        42.76836392871184,
        -1.842708711171081,
        42.76836392871184
      ]
    },
    "31254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.725552717092333,
              42.273912370926865
            ]
          },
          "bbox": [
            -1.725552717092333,
            42.273912370926865,
            -1.725552717092333,
            42.273912370926865
          ]
        }
      ],
      "bbox": [
        -1.725552717092333,
        42.273912370926865,
        -1.725552717092333,
        42.273912370926865
      ]
    },
    "31255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.127854608404985,
              42.61409847099918
            ]
          },
          "bbox": [
            -2.127854608404985,
            42.61409847099918,
            -2.127854608404985,
            42.61409847099918
          ]
        }
      ],
      "bbox": [
        -2.127854608404985,
        42.61409847099918,
        -2.127854608404985,
        42.61409847099918
      ]
    },
    "31256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2083215758033214,
              42.949626774730596
            ]
          },
          "bbox": [
            -1.2083215758033214,
            42.949626774730596,
            -1.2083215758033214,
            42.949626774730596
          ]
        }
      ],
      "bbox": [
        -1.2083215758033214,
        42.949626774730596,
        -1.2083215758033214,
        42.949626774730596
      ]
    },
    "31257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9547289132342531,
              42.63957180926837
            ]
          },
          "bbox": [
            -1.9547289132342531,
            42.63957180926837,
            -1.9547289132342531,
            42.63957180926837
          ]
        }
      ],
      "bbox": [
        -1.9547289132342531,
        42.63957180926837,
        -1.9547289132342531,
        42.63957180926837
      ]
    },
    "31258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6098079854632745,
              42.83303972977509
            ]
          },
          "bbox": [
            -1.6098079854632745,
            42.83303972977509,
            -1.6098079854632745,
            42.83303972977509
          ]
        }
      ],
      "bbox": [
        -1.6098079854632745,
        42.83303972977509,
        -1.6098079854632745,
        42.83303972977509
      ]
    },
    "31259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.690537647841343,
              43.21913486902495
            ]
          },
          "bbox": [
            -1.690537647841343,
            43.21913486902495,
            -1.690537647841343,
            43.21913486902495
          ]
        }
      ],
      "bbox": [
        -1.690537647841343,
        43.21913486902495,
        -1.690537647841343,
        43.21913486902495
      ]
    },
    "31260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.04370484477538,
              42.79528301286579
            ]
          },
          "bbox": [
            -2.04370484477538,
            42.79528301286579,
            -2.04370484477538,
            42.79528301286579
          ]
        }
      ],
      "bbox": [
        -2.04370484477538,
        42.79528301286579,
        -2.04370484477538,
        42.79528301286579
      ]
    },
    "31261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.192688855766492,
              42.626878965982755
            ]
          },
          "bbox": [
            -1.192688855766492,
            42.626878965982755,
            -1.192688855766492,
            42.626878965982755
          ]
        }
      ],
      "bbox": [
        -1.192688855766492,
        42.626878965982755,
        -1.192688855766492,
        42.626878965982755
      ]
    },
    "31262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.799864037242406,
              42.766334614232065
            ]
          },
          "bbox": [
            -1.799864037242406,
            42.766334614232065,
            -1.799864037242406,
            42.766334614232065
          ]
        }
      ],
      "bbox": [
        -1.799864037242406,
        42.766334614232065,
        -1.799864037242406,
        42.766334614232065
      ]
    },
    "31263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7558145611380647,
              43.130212070595995
            ]
          },
          "bbox": [
            -1.7558145611380647,
            43.130212070595995,
            -1.7558145611380647,
            43.130212070595995
          ]
        }
      ],
      "bbox": [
        -1.7558145611380647,
        43.130212070595995,
        -1.7558145611380647,
        43.130212070595995
      ]
    },
    "31264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5446510133014628,
              43.26894264970458
            ]
          },
          "bbox": [
            -1.5446510133014628,
            43.26894264970458,
            -1.5446510133014628,
            43.26894264970458
          ]
        }
      ],
      "bbox": [
        -1.5446510133014628,
        43.26894264970458,
        -1.5446510133014628,
        43.26894264970458
      ]
    },
    "31265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2955285018818063,
              42.69205620226704
            ]
          },
          "bbox": [
            -2.2955285018818063,
            42.69205620226704,
            -2.2955285018818063,
            42.69205620226704
          ]
        }
      ],
      "bbox": [
        -2.2955285018818063,
        42.69205620226704,
        -2.2955285018818063,
        42.69205620226704
      ]
    },
    "31901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.689515846759457,
              42.80399420665055
            ]
          },
          "bbox": [
            -1.689515846759457,
            42.80399420665055,
            -1.689515846759457,
            42.80399420665055
          ]
        }
      ],
      "bbox": [
        -1.689515846759457,
        42.80399420665055,
        -1.689515846759457,
        42.80399420665055
      ]
    },
    "31902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7034098201766164,
              42.858123539998616
            ]
          },
          "bbox": [
            -1.7034098201766164,
            42.858123539998616,
            -1.7034098201766164,
            42.858123539998616
          ]
        }
      ],
      "bbox": [
        -1.7034098201766164,
        42.858123539998616,
        -1.7034098201766164,
        42.858123539998616
      ]
    },
    "31903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6697466193350028,
              42.843192309187685
            ]
          },
          "bbox": [
            -1.6697466193350028,
            42.843192309187685,
            -1.6697466193350028,
            42.843192309187685
          ]
        }
      ],
      "bbox": [
        -1.6697466193350028,
        42.843192309187685,
        -1.6697466193350028,
        42.843192309187685
      ]
    },
    "31904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8242698855870836,
              42.92396954782932
            ]
          },
          "bbox": [
            -1.8242698855870836,
            42.92396954782932,
            -1.8242698855870836,
            42.92396954782932
          ]
        }
      ],
      "bbox": [
        -1.8242698855870836,
        42.92396954782932,
        -1.8242698855870836,
        42.92396954782932
      ]
    },
    "31905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.643578445785102,
              42.731706966826856
            ]
          },
          "bbox": [
            -1.643578445785102,
            42.731706966826856,
            -1.643578445785102,
            42.731706966826856
          ]
        }
      ],
      "bbox": [
        -1.643578445785102,
        42.731706966826856,
        -1.643578445785102,
        42.731706966826856
      ]
    },
    "31906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7045231843288864,
              42.82609330778006
            ]
          },
          "bbox": [
            -1.7045231843288864,
            42.82609330778006,
            -1.7045231843288864,
            42.82609330778006
          ]
        }
      ],
      "bbox": [
        -1.7045231843288864,
        42.82609330778006,
        -1.7045231843288864,
        42.82609330778006
      ]
    },
    "31907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6951804901351053,
              42.790374047413856
            ]
          },
          "bbox": [
            -1.6951804901351053,
            42.790374047413856,
            -1.6951804901351053,
            42.790374047413856
          ]
        }
      ],
      "bbox": [
        -1.6951804901351053,
        42.790374047413856,
        -1.6951804901351053,
        42.790374047413856
      ]
    },
    "31908": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9042150467987762,
              43.01103178053845
            ]
          },
          "bbox": [
            -1.9042150467987762,
            43.01103178053845,
            -1.9042150467987762,
            43.01103178053845
          ]
        }
      ],
      "bbox": [
        -1.9042150467987762,
        43.01103178053845,
        -1.9042150467987762,
        43.01103178053845
      ]
    },
    "32001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.804661423350975,
              42.190440841618326
            ]
          },
          "bbox": [
            -7.804661423350975,
            42.190440841618326,
            -7.804661423350975,
            42.190440841618326
          ]
        }
      ],
      "bbox": [
        -7.804661423350975,
        42.190440841618326,
        -7.804661423350975,
        42.190440841618326
      ]
    },
    "32002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.9455821155295805,
              42.39846935009224
            ]
          },
          "bbox": [
            -7.9455821155295805,
            42.39846935009224,
            -7.9455821155295805,
            42.39846935009224
          ]
        }
      ],
      "bbox": [
        -7.9455821155295805,
        42.39846935009224,
        -7.9455821155295805,
        42.39846935009224
      ]
    },
    "32003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.132201472492811,
              42.251322746651354
            ]
          },
          "bbox": [
            -8.132201472492811,
            42.251322746651354,
            -8.132201472492811,
            42.251322746651354
          ]
        }
      ],
      "bbox": [
        -8.132201472492811,
        42.251322746651354,
        -8.132201472492811,
        42.251322746651354
      ]
    },
    "32004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.272675444016112,
              42.387926271906885
            ]
          },
          "bbox": [
            -8.272675444016112,
            42.387926271906885,
            -8.272675444016112,
            42.387926271906885
          ]
        }
      ],
      "bbox": [
        -8.272675444016112,
        42.387926271906885,
        -8.272675444016112,
        42.387926271906885
      ]
    },
    "32005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.7375783207243805,
              41.93510479328972
            ]
          },
          "bbox": [
            -7.7375783207243805,
            41.93510479328972,
            -7.7375783207243805,
            41.93510479328972
          ]
        }
      ],
      "bbox": [
        -7.7375783207243805,
        41.93510479328972,
        -7.7375783207243805,
        41.93510479328972
      ]
    },
    "32006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.970897969098278,
              42.03161641180802
            ]
          },
          "bbox": [
            -7.970897969098278,
            42.03161641180802,
            -7.970897969098278,
            42.03161641180802
          ]
        }
      ],
      "bbox": [
        -7.970897969098278,
        42.03161641180802,
        -7.970897969098278,
        42.03161641180802
      ]
    },
    "32007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.67857851435098,
              42.22601509133175
            ]
          },
          "bbox": [
            -7.67857851435098,
            42.22601509133175,
            -7.67857851435098,
            42.22601509133175
          ]
        }
      ],
      "bbox": [
        -7.67857851435098,
        42.22601509133175,
        -7.67857851435098,
        42.22601509133175
      ]
    },
    "32008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.907357315316173,
              42.30063023446622
            ]
          },
          "bbox": [
            -7.907357315316173,
            42.30063023446622,
            -7.907357315316173,
            42.30063023446622
          ]
        }
      ],
      "bbox": [
        -7.907357315316173,
        42.30063023446622,
        -7.907357315316173,
        42.30063023446622
      ]
    },
    "32009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.982269976134014,
              42.415263221645596
            ]
          },
          "bbox": [
            -6.982269976134014,
            42.415263221645596,
            -6.982269976134014,
            42.415263221645596
          ]
        }
      ],
      "bbox": [
        -6.982269976134014,
        42.415263221645596,
        -6.982269976134014,
        42.415263221645596
      ]
    },
    "32010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.133335104662004,
              42.32533103086573
            ]
          },
          "bbox": [
            -8.133335104662004,
            42.32533103086573,
            -8.133335104662004,
            42.32533103086573
          ]
        }
      ],
      "bbox": [
        -8.133335104662004,
        42.32533103086573,
        -8.133335104662004,
        42.32533103086573
      ]
    },
    "32011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.27980037367842,
              42.480223369034725
            ]
          },
          "bbox": [
            -8.27980037367842,
            42.480223369034725,
            -8.27980037367842,
            42.480223369034725
          ]
        }
      ],
      "bbox": [
        -8.27980037367842,
        42.480223369034725,
        -8.27980037367842,
        42.480223369034725
      ]
    },
    "32012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.758406187578754,
              41.98068580667832
            ]
          },
          "bbox": [
            -7.758406187578754,
            41.98068580667832,
            -7.758406187578754,
            41.98068580667832
          ]
        }
      ],
      "bbox": [
        -7.758406187578754,
        41.98068580667832,
        -7.758406187578754,
        41.98068580667832
      ]
    },
    "32013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.177008554567426,
              42.44346391494474
            ]
          },
          "bbox": [
            -8.177008554567426,
            42.44346391494474,
            -8.177008554567426,
            42.44346391494474
          ]
        }
      ],
      "bbox": [
        -8.177008554567426,
        42.44346391494474,
        -8.177008554567426,
        42.44346391494474
      ]
    },
    "32014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.897491553685507,
              42.14516529646246
            ]
          },
          "bbox": [
            -7.897491553685507,
            42.14516529646246,
            -7.897491553685507,
            42.14516529646246
          ]
        }
      ],
      "bbox": [
        -7.897491553685507,
        42.14516529646246,
        -7.897491553685507,
        42.14516529646246
      ]
    },
    "32015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.106964370240441,
              42.296685612207924
            ]
          },
          "bbox": [
            -7.106964370240441,
            42.296685612207924,
            -7.106964370240441,
            42.296685612207924
          ]
        }
      ],
      "bbox": [
        -7.106964370240441,
        42.296685612207924,
        -7.106964370240441,
        42.296685612207924
      ]
    },
    "32016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.856557490777903,
              41.93432951995464
            ]
          },
          "bbox": [
            -7.856557490777903,
            41.93432951995464,
            -7.856557490777903,
            41.93432951995464
          ]
        }
      ],
      "bbox": [
        -7.856557490777903,
        41.93432951995464,
        -7.856557490777903,
        41.93432951995464
      ]
    },
    "32017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.845278078794775,
              42.34522184100393
            ]
          },
          "bbox": [
            -6.845278078794775,
            42.34522184100393,
            -6.845278078794775,
            42.34522184100393
          ]
        }
      ],
      "bbox": [
        -6.845278078794775,
        42.34522184100393,
        -6.845278078794775,
        42.34522184100393
      ]
    },
    "32018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.188503394695191,
              42.3290897111498
            ]
          },
          "bbox": [
            -8.188503394695191,
            42.3290897111498,
            -8.188503394695191,
            42.3290897111498
          ]
        }
      ],
      "bbox": [
        -8.188503394695191,
        42.3290897111498,
        -8.188503394695191,
        42.3290897111498
      ]
    },
    "32019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.07686882819598,
              42.44142164101214
            ]
          },
          "bbox": [
            -8.07686882819598,
            42.44142164101214,
            -8.07686882819598,
            42.44142164101214
          ]
        }
      ],
      "bbox": [
        -8.07686882819598,
        42.44142164101214,
        -8.07686882819598,
        42.44142164101214
      ]
    },
    "32020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.0215557357217,
              42.23889894524609
            ]
          },
          "bbox": [
            -8.0215557357217,
            42.23889894524609,
            -8.0215557357217,
            42.23889894524609
          ]
        }
      ],
      "bbox": [
        -8.0215557357217,
        42.23889894524609,
        -8.0215557357217,
        42.23889894524609
      ]
    },
    "32021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.358077432888418,
              42.0390353911511
            ]
          },
          "bbox": [
            -7.358077432888418,
            42.0390353911511,
            -7.358077432888418,
            42.0390353911511
          ]
        }
      ],
      "bbox": [
        -7.358077432888418,
        42.0390353911511,
        -7.358077432888418,
        42.0390353911511
      ]
    },
    "32022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.064646208399711,
              42.29432149405196
            ]
          },
          "bbox": [
            -8.064646208399711,
            42.29432149405196,
            -8.064646208399711,
            42.29432149405196
          ]
        }
      ],
      "bbox": [
        -8.064646208399711,
        42.29432149405196,
        -8.064646208399711,
        42.29432149405196
      ]
    },
    "32023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.404116899556455,
              42.374325599839786
            ]
          },
          "bbox": [
            -7.404116899556455,
            42.374325599839786,
            -7.404116899556455,
            42.374325599839786
          ]
        }
      ],
      "bbox": [
        -7.404116899556455,
        42.374325599839786,
        -7.404116899556455,
        42.374325599839786
      ]
    },
    "32024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.973375444873521,
              42.15875057025118
            ]
          },
          "bbox": [
            -7.973375444873521,
            42.15875057025118,
            -7.973375444873521,
            42.15875057025118
          ]
        }
      ],
      "bbox": [
        -7.973375444873521,
        42.15875057025118,
        -7.973375444873521,
        42.15875057025118
      ]
    },
    "32025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.077298432421887,
              42.33946858043454
            ]
          },
          "bbox": [
            -8.077298432421887,
            42.33946858043454,
            -8.077298432421887,
            42.33946858043454
          ]
        }
      ],
      "bbox": [
        -8.077298432421887,
        42.33946858043454,
        -8.077298432421887,
        42.33946858043454
      ]
    },
    "32026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.838404179628445,
              42.40664051597895
            ]
          },
          "bbox": [
            -7.838404179628445,
            42.40664051597895,
            -7.838404179628445,
            42.40664051597895
          ]
        }
      ],
      "bbox": [
        -7.838404179628445,
        42.40664051597895,
        -7.838404179628445,
        42.40664051597895
      ]
    },
    "32027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.149327847908637,
              42.20774905971345
            ]
          },
          "bbox": [
            -8.149327847908637,
            42.20774905971345,
            -8.149327847908637,
            42.20774905971345
          ]
        }
      ],
      "bbox": [
        -8.149327847908637,
        42.20774905971345,
        -8.149327847908637,
        42.20774905971345
      ]
    },
    "32028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.59431561130929,
              41.962374821553006
            ]
          },
          "bbox": [
            -7.59431561130929,
            41.962374821553006,
            -7.59431561130929,
            41.962374821553006
          ]
        }
      ],
      "bbox": [
        -7.59431561130929,
        41.962374821553006,
        -7.59431561130929,
        41.962374821553006
      ]
    },
    "32029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.3755031487484555,
              42.2506609365429
            ]
          },
          "bbox": [
            -7.3755031487484555,
            42.2506609365429,
            -7.3755031487484555,
            42.2506609365429
          ]
        }
      ],
      "bbox": [
        -7.3755031487484555,
        42.2506609365429,
        -7.3755031487484555,
        42.2506609365429
      ]
    },
    "32030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.139409829397898,
              41.94286011768944
            ]
          },
          "bbox": [
            -8.139409829397898,
            41.94286011768944,
            -8.139409829397898,
            41.94286011768944
          ]
        }
      ],
      "bbox": [
        -8.139409829397898,
        41.94286011768944,
        -8.139409829397898,
        41.94286011768944
      ]
    },
    "32031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.696502217903383,
              42.33348399855745
            ]
          },
          "bbox": [
            -7.696502217903383,
            42.33348399855745,
            -7.696502217903383,
            42.33348399855745
          ]
        }
      ],
      "bbox": [
        -7.696502217903383,
        42.33348399855745,
        -7.696502217903383,
        42.33348399855745
      ]
    },
    "32032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.706179832595924,
              42.04458833261509
            ]
          },
          "bbox": [
            -7.706179832595924,
            42.04458833261509,
            -7.706179832595924,
            42.04458833261509
          ]
        }
      ],
      "bbox": [
        -7.706179832595924,
        42.04458833261509,
        -7.706179832595924,
        42.04458833261509
      ]
    },
    "32033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.098440020041057,
              42.189096716709365
            ]
          },
          "bbox": [
            -8.098440020041057,
            42.189096716709365,
            -8.098440020041057,
            42.189096716709365
          ]
        }
      ],
      "bbox": [
        -8.098440020041057,
        42.189096716709365,
        -8.098440020041057,
        42.189096716709365
      ]
    },
    "32034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.167846424402435,
              42.038951403243146
            ]
          },
          "bbox": [
            -7.167846424402435,
            42.038951403243146,
            -7.167846424402435,
            42.038951403243146
          ]
        }
      ],
      "bbox": [
        -7.167846424402435,
        42.038951403243146,
        -7.167846424402435,
        42.038951403243146
      ]
    },
    "32035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.141004608543387,
              42.51397838207207
            ]
          },
          "bbox": [
            -8.141004608543387,
            42.51397838207207,
            -8.141004608543387,
            42.51397838207207
          ]
        }
      ],
      "bbox": [
        -8.141004608543387,
        42.51397838207207,
        -8.141004608543387,
        42.51397838207207
      ]
    },
    "32036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.716619265888575,
              42.17993618626787
            ]
          },
          "bbox": [
            -7.716619265888575,
            42.17993618626787,
            -7.716619265888575,
            42.17993618626787
          ]
        }
      ],
      "bbox": [
        -7.716619265888575,
        42.17993618626787,
        -7.716619265888575,
        42.17993618626787
      ]
    },
    "32037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.636338929335841,
              42.32165733159618
            ]
          },
          "bbox": [
            -7.636338929335841,
            42.32165733159618,
            -7.636338929335841,
            42.32165733159618
          ]
        }
      ],
      "bbox": [
        -7.636338929335841,
        42.32165733159618,
        -7.636338929335841,
        42.32165733159618
      ]
    },
    "32038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.165897925852644,
              42.35422384971574
            ]
          },
          "bbox": [
            -7.165897925852644,
            42.35422384971574,
            -7.165897925852644,
            42.35422384971574
          ]
        }
      ],
      "bbox": [
        -7.165897925852644,
        42.35422384971574,
        -7.165897925852644,
        42.35422384971574
      ]
    },
    "32039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.453848821277593,
              42.09977481049862
            ]
          },
          "bbox": [
            -7.453848821277593,
            42.09977481049862,
            -7.453848821277593,
            42.09977481049862
          ]
        }
      ],
      "bbox": [
        -7.453848821277593,
        42.09977481049862,
        -7.453848821277593,
        42.09977481049862
      ]
    },
    "32040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.156709570025868,
              42.37325282294168
            ]
          },
          "bbox": [
            -8.156709570025868,
            42.37325282294168,
            -8.156709570025868,
            42.37325282294168
          ]
        }
      ],
      "bbox": [
        -8.156709570025868,
        42.37325282294168,
        -8.156709570025868,
        42.37325282294168
      ]
    },
    "32041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.046998474615597,
              42.00129091566877
            ]
          },
          "bbox": [
            -8.046998474615597,
            42.00129091566877,
            -8.046998474615597,
            42.00129091566877
          ]
        }
      ],
      "bbox": [
        -8.046998474615597,
        42.00129091566877,
        -8.046998474615597,
        42.00129091566877
      ]
    },
    "32042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.087064682106272,
              41.87556440135873
            ]
          },
          "bbox": [
            -8.087064682106272,
            41.87556440135873,
            -8.087064682106272,
            41.87556440135873
          ]
        }
      ],
      "bbox": [
        -8.087064682106272,
        41.87556440135873,
        -8.087064682106272,
        41.87556440135873
      ]
    },
    "32043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.600204536293425,
              42.25679918150566
            ]
          },
          "bbox": [
            -7.600204536293425,
            42.25679918150566,
            -7.600204536293425,
            42.25679918150566
          ]
        }
      ],
      "bbox": [
        -7.600204536293425,
        42.25679918150566,
        -7.600204536293425,
        42.25679918150566
      ]
    },
    "32044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.220449426147111,
              42.26998311493052
            ]
          },
          "bbox": [
            -7.220449426147111,
            42.26998311493052,
            -7.220449426147111,
            42.26998311493052
          ]
        }
      ],
      "bbox": [
        -7.220449426147111,
        42.26998311493052,
        -7.220449426147111,
        42.26998311493052
      ]
    },
    "32045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.018174488690477,
              42.41940143003122
            ]
          },
          "bbox": [
            -8.018174488690477,
            42.41940143003122,
            -8.018174488690477,
            42.41940143003122
          ]
        }
      ],
      "bbox": [
        -8.018174488690477,
        42.41940143003122,
        -8.018174488690477,
        42.41940143003122
      ]
    },
    "32046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.221803573639642,
              42.2745648270458
            ]
          },
          "bbox": [
            -8.221803573639642,
            42.2745648270458,
            -8.221803573639642,
            42.2745648270458
          ]
        }
      ],
      "bbox": [
        -8.221803573639642,
        42.2745648270458,
        -8.221803573639642,
        42.2745648270458
      ]
    },
    "32047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.903194240987088,
              42.218658572060946
            ]
          },
          "bbox": [
            -7.903194240987088,
            42.218658572060946,
            -7.903194240987088,
            42.218658572060946
          ]
        }
      ],
      "bbox": [
        -7.903194240987088,
        42.218658572060946,
        -7.903194240987088,
        42.218658572060946
      ]
    },
    "32048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.0338118657252515,
              42.00708180143988
            ]
          },
          "bbox": [
            -7.0338118657252515,
            42.00708180143988,
            -7.0338118657252515,
            42.00708180143988
          ]
        }
      ],
      "bbox": [
        -7.0338118657252515,
        42.00708180143988,
        -7.0338118657252515,
        42.00708180143988
      ]
    },
    "32049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.503702807880848,
              42.281285246864925
            ]
          },
          "bbox": [
            -7.503702807880848,
            42.281285246864925,
            -7.503702807880848,
            42.281285246864925
          ]
        }
      ],
      "bbox": [
        -7.503702807880848,
        42.281285246864925,
        -7.503702807880848,
        42.281285246864925
      ]
    },
    "32050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.521478165018572,
              41.947401437034465
            ]
          },
          "bbox": [
            -7.521478165018572,
            41.947401437034465,
            -7.521478165018572,
            41.947401437034465
          ]
        }
      ],
      "bbox": [
        -7.521478165018572,
        41.947401437034465,
        -7.521478165018572,
        41.947401437034465
      ]
    },
    "32051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.9614398373478235,
              41.93368346108757
            ]
          },
          "bbox": [
            -7.9614398373478235,
            41.93368346108757,
            -7.9614398373478235,
            41.93368346108757
          ]
        }
      ],
      "bbox": [
        -7.9614398373478235,
        41.93368346108757,
        -7.9614398373478235,
        41.93368346108757
      ]
    },
    "32052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.707915442012535,
              42.39611014869196
            ]
          },
          "bbox": [
            -7.707915442012535,
            42.39611014869196,
            -7.707915442012535,
            42.39611014869196
          ]
        }
      ],
      "bbox": [
        -7.707915442012535,
        42.39611014869196,
        -7.707915442012535,
        42.39611014869196
      ]
    },
    "32053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.521412922405674,
              41.87025664749204
            ]
          },
          "bbox": [
            -7.521412922405674,
            41.87025664749204,
            -7.521412922405674,
            41.87025664749204
          ]
        }
      ],
      "bbox": [
        -7.521412922405674,
        41.87025664749204,
        -7.521412922405674,
        41.87025664749204
      ]
    },
    "32054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.889734642584348,
              42.350706607853674
            ]
          },
          "bbox": [
            -7.889734642584348,
            42.350706607853674,
            -7.889734642584348,
            42.350706607853674
          ]
        }
      ],
      "bbox": [
        -7.889734642584348,
        42.350706607853674,
        -7.889734642584348,
        42.350706607853674
      ]
    },
    "32055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.7459990639041765,
              42.27756500991465
            ]
          },
          "bbox": [
            -7.7459990639041765,
            42.27756500991465,
            -7.7459990639041765,
            42.27756500991465
          ]
        }
      ],
      "bbox": [
        -7.7459990639041765,
        42.27756500991465,
        -7.7459990639041765,
        42.27756500991465
      ]
    },
    "32056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.15491684886999,
              42.1185155277666
            ]
          },
          "bbox": [
            -8.15491684886999,
            42.1185155277666,
            -8.15491684886999,
            42.1185155277666
          ]
        }
      ],
      "bbox": [
        -8.15491684886999,
        42.1185155277666,
        -8.15491684886999,
        42.1185155277666
      ]
    },
    "32057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.56180183905503,
              42.36281020322375
            ]
          },
          "bbox": [
            -7.56180183905503,
            42.36281020322375,
            -7.56180183905503,
            42.36281020322375
          ]
        }
      ],
      "bbox": [
        -7.56180183905503,
        42.36281020322375,
        -7.56180183905503,
        42.36281020322375
      ]
    },
    "32058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.779869030945877,
              42.34336220218571
            ]
          },
          "bbox": [
            -7.779869030945877,
            42.34336220218571,
            -7.779869030945877,
            42.34336220218571
          ]
        }
      ],
      "bbox": [
        -7.779869030945877,
        42.34336220218571,
        -7.779869030945877,
        42.34336220218571
      ]
    },
    "32059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.8019417206872275,
              42.45547408132681
            ]
          },
          "bbox": [
            -7.8019417206872275,
            42.45547408132681,
            -7.8019417206872275,
            42.45547408132681
          ]
        }
      ],
      "bbox": [
        -7.8019417206872275,
        42.45547408132681,
        -7.8019417206872275,
        42.45547408132681
      ]
    },
    "32060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.097779977144713,
              42.36717023379592
            ]
          },
          "bbox": [
            -7.097779977144713,
            42.36717023379592,
            -7.097779977144713,
            42.36717023379592
          ]
        }
      ],
      "bbox": [
        -7.097779977144713,
        42.36717023379592,
        -7.097779977144713,
        42.36717023379592
      ]
    },
    "32061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.011560941945062,
              42.5229499122394
            ]
          },
          "bbox": [
            -8.011560941945062,
            42.5229499122394,
            -8.011560941945062,
            42.5229499122394
          ]
        }
      ],
      "bbox": [
        -8.011560941945062,
        42.5229499122394,
        -8.011560941945062,
        42.5229499122394
      ]
    },
    "32062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.844168929506972,
              42.01843671355911
            ]
          },
          "bbox": [
            -7.844168929506972,
            42.01843671355911,
            -7.844168929506972,
            42.01843671355911
          ]
        }
      ],
      "bbox": [
        -7.844168929506972,
        42.01843671355911,
        -7.844168929506972,
        42.01843671355911
      ]
    },
    "32063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.278369194611309,
              42.32548669826884
            ]
          },
          "bbox": [
            -7.278369194611309,
            42.32548669826884,
            -7.278369194611309,
            42.32548669826884
          ]
        }
      ],
      "bbox": [
        -7.278369194611309,
        42.32548669826884,
        -7.278369194611309,
        42.32548669826884
      ]
    },
    "32064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.139534803647468,
              42.16872313675194
            ]
          },
          "bbox": [
            -8.139534803647468,
            42.16872313675194,
            -8.139534803647468,
            42.16872313675194
          ]
        }
      ],
      "bbox": [
        -8.139534803647468,
        42.16872313675194,
        -8.139534803647468,
        42.16872313675194
      ]
    },
    "32065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.010934064899732,
              42.37072282099303
            ]
          },
          "bbox": [
            -8.010934064899732,
            42.37072282099303,
            -8.010934064899732,
            42.37072282099303
          ]
        }
      ],
      "bbox": [
        -8.010934064899732,
        42.37072282099303,
        -8.010934064899732,
        42.37072282099303
      ]
    },
    "32066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.09067547963342,
              42.12409795014219
            ]
          },
          "bbox": [
            -8.09067547963342,
            42.12409795014219,
            -8.09067547963342,
            42.12409795014219
          ]
        }
      ],
      "bbox": [
        -8.09067547963342,
        42.12409795014219,
        -8.09067547963342,
        42.12409795014219
      ]
    },
    "32067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.854648636210163,
              42.090764751242155
            ]
          },
          "bbox": [
            -7.854648636210163,
            42.090764751242155,
            -7.854648636210163,
            42.090764751242155
          ]
        }
      ],
      "bbox": [
        -7.854648636210163,
        42.090764751242155,
        -7.854648636210163,
        42.090764751242155
      ]
    },
    "32068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.037502161574563,
              42.18267999501939
            ]
          },
          "bbox": [
            -8.037502161574563,
            42.18267999501939,
            -8.037502161574563,
            42.18267999501939
          ]
        }
      ],
      "bbox": [
        -8.037502161574563,
        42.18267999501939,
        -8.037502161574563,
        42.18267999501939
      ]
    },
    "32069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.136602980293127,
              42.29053275099499
            ]
          },
          "bbox": [
            -8.136602980293127,
            42.29053275099499,
            -8.136602980293127,
            42.29053275099499
          ]
        }
      ],
      "bbox": [
        -8.136602980293127,
        42.29053275099499,
        -8.136602980293127,
        42.29053275099499
      ]
    },
    "32070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.313322857729987,
              42.385938257871864
            ]
          },
          "bbox": [
            -7.313322857729987,
            42.385938257871864,
            -7.313322857729987,
            42.385938257871864
          ]
        }
      ],
      "bbox": [
        -7.313322857729987,
        42.385938257871864,
        -7.313322857729987,
        42.385938257871864
      ]
    },
    "32071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.274924873494825,
              41.97317419323927
            ]
          },
          "bbox": [
            -7.274924873494825,
            41.97317419323927,
            -7.274924873494825,
            41.97317419323927
          ]
        }
      ],
      "bbox": [
        -7.274924873494825,
        41.97317419323927,
        -7.274924873494825,
        41.97317419323927
      ]
    },
    "32072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.1237043410730125,
              42.411790743510686
            ]
          },
          "bbox": [
            -7.1237043410730125,
            42.411790743510686,
            -7.1237043410730125,
            42.411790743510686
          ]
        }
      ],
      "bbox": [
        -7.1237043410730125,
        42.411790743510686,
        -7.1237043410730125,
        42.411790743510686
      ]
    },
    "32073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.896152587315569,
              42.46808905779201
            ]
          },
          "bbox": [
            -6.896152587315569,
            42.46808905779201,
            -6.896152587315569,
            42.46808905779201
          ]
        }
      ],
      "bbox": [
        -6.896152587315569,
        42.46808905779201,
        -6.896152587315569,
        42.46808905779201
      ]
    },
    "32074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.062613971815018,
              42.371147119353296
            ]
          },
          "bbox": [
            -8.062613971815018,
            42.371147119353296,
            -8.062613971815018,
            42.371147119353296
          ]
        }
      ],
      "bbox": [
        -8.062613971815018,
        42.371147119353296,
        -8.062613971815018,
        42.371147119353296
      ]
    },
    "32075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.853723757157632,
              42.27791005162869
            ]
          },
          "bbox": [
            -7.853723757157632,
            42.27791005162869,
            -7.853723757157632,
            42.27791005162869
          ]
        }
      ],
      "bbox": [
        -7.853723757157632,
        42.27791005162869,
        -7.853723757157632,
        42.27791005162869
      ]
    },
    "32076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.95630430431214,
              42.49868122071242
            ]
          },
          "bbox": [
            -7.95630430431214,
            42.49868122071242,
            -7.95630430431214,
            42.49868122071242
          ]
        }
      ],
      "bbox": [
        -7.95630430431214,
        42.49868122071242,
        -7.95630430431214,
        42.49868122071242
      ]
    },
    "32077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.747319936703627,
              42.111913797715
            ]
          },
          "bbox": [
            -7.747319936703627,
            42.111913797715,
            -7.747319936703627,
            42.111913797715
          ]
        }
      ],
      "bbox": [
        -7.747319936703627,
        42.111913797715,
        -7.747319936703627,
        42.111913797715
      ]
    },
    "32078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.6032248542996355,
              42.10688779859747
            ]
          },
          "bbox": [
            -7.6032248542996355,
            42.10688779859747,
            -7.6032248542996355,
            42.10688779859747
          ]
        }
      ],
      "bbox": [
        -7.6032248542996355,
        42.10688779859747,
        -7.6032248542996355,
        42.10688779859747
      ]
    },
    "32079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.826225639104982,
              42.248268368482435
            ]
          },
          "bbox": [
            -7.826225639104982,
            42.248268368482435,
            -7.826225639104982,
            42.248268368482435
          ]
        }
      ],
      "bbox": [
        -7.826225639104982,
        42.248268368482435,
        -7.826225639104982,
        42.248268368482435
      ]
    },
    "32080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.476920160296517,
              42.37023353573081
            ]
          },
          "bbox": [
            -7.476920160296517,
            42.37023353573081,
            -7.476920160296517,
            42.37023353573081
          ]
        }
      ],
      "bbox": [
        -7.476920160296517,
        42.37023353573081,
        -7.476920160296517,
        42.37023353573081
      ]
    },
    "32081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.966429023380383,
              42.30666932494858
            ]
          },
          "bbox": [
            -7.966429023380383,
            42.30666932494858,
            -7.966429023380383,
            42.30666932494858
          ]
        }
      ],
      "bbox": [
        -7.966429023380383,
        42.30666932494858,
        -7.966429023380383,
        42.30666932494858
      ]
    },
    "32082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.623159190584486,
              42.028205767298445
            ]
          },
          "bbox": [
            -7.623159190584486,
            42.028205767298445,
            -7.623159190584486,
            42.028205767298445
          ]
        }
      ],
      "bbox": [
        -7.623159190584486,
        42.028205767298445,
        -7.623159190584486,
        42.028205767298445
      ]
    },
    "32083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.9588514971383155,
              42.265187848737824
            ]
          },
          "bbox": [
            -6.9588514971383155,
            42.265187848737824,
            -6.9588514971383155,
            42.265187848737824
          ]
        }
      ],
      "bbox": [
        -6.9588514971383155,
        42.265187848737824,
        -6.9588514971383155,
        42.265187848737824
      ]
    },
    "32084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.991274639447772,
              42.094828566179174
            ]
          },
          "bbox": [
            -7.991274639447772,
            42.094828566179174,
            -7.991274639447772,
            42.094828566179174
          ]
        }
      ],
      "bbox": [
        -7.991274639447772,
        42.094828566179174,
        -7.991274639447772,
        42.094828566179174
      ]
    },
    "32085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.416248495646627,
              41.90628164760061
            ]
          },
          "bbox": [
            -7.416248495646627,
            41.90628164760061,
            -7.416248495646627,
            41.90628164760061
          ]
        }
      ],
      "bbox": [
        -7.416248495646627,
        41.90628164760061,
        -7.416248495646627,
        41.90628164760061
      ]
    },
    "32086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.079502048045747,
              42.15366349724487
            ]
          },
          "bbox": [
            -7.079502048045747,
            42.15366349724487,
            -7.079502048045747,
            42.15366349724487
          ]
        }
      ],
      "bbox": [
        -7.079502048045747,
        42.15366349724487,
        -7.079502048045747,
        42.15366349724487
      ]
    },
    "32087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.891467334653854,
              42.462992643629605
            ]
          },
          "bbox": [
            -7.891467334653854,
            42.462992643629605,
            -7.891467334653854,
            42.462992643629605
          ]
        }
      ],
      "bbox": [
        -7.891467334653854,
        42.462992643629605,
        -7.891467334653854,
        42.462992643629605
      ]
    },
    "32088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.053569376341501,
              42.44831495010802
            ]
          },
          "bbox": [
            -7.053569376341501,
            42.44831495010802,
            -7.053569376341501,
            42.44831495010802
          ]
        }
      ],
      "bbox": [
        -7.053569376341501,
        42.44831495010802,
        -7.053569376341501,
        42.44831495010802
      ]
    },
    "32089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.561480606727231,
              42.172674038183466
            ]
          },
          "bbox": [
            -7.561480606727231,
            42.172674038183466,
            -7.561480606727231,
            42.172674038183466
          ]
        }
      ],
      "bbox": [
        -7.561480606727231,
        42.172674038183466,
        -7.561480606727231,
        42.172674038183466
      ]
    },
    "32090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.803232917665622,
              42.096778487056476
            ]
          },
          "bbox": [
            -7.803232917665622,
            42.096778487056476,
            -7.803232917665622,
            42.096778487056476
          ]
        }
      ],
      "bbox": [
        -7.803232917665622,
        42.096778487056476,
        -7.803232917665622,
        42.096778487056476
      ]
    },
    "32091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.301179477306348,
              41.901581822146234
            ]
          },
          "bbox": [
            -7.301179477306348,
            41.901581822146234,
            -7.301179477306348,
            41.901581822146234
          ]
        }
      ],
      "bbox": [
        -7.301179477306348,
        41.901581822146234,
        -7.301179477306348,
        41.901581822146234
      ]
    },
    "32092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.266309332021005,
              42.15120319671646
            ]
          },
          "bbox": [
            -7.266309332021005,
            42.15120319671646,
            -7.266309332021005,
            42.15120319671646
          ]
        }
      ],
      "bbox": [
        -7.266309332021005,
        42.15120319671646,
        -7.266309332021005,
        42.15120319671646
      ]
    },
    "33001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.714904880292462,
              43.23630543321619
            ]
          },
          "bbox": [
            -6.714904880292462,
            43.23630543321619,
            -6.714904880292462,
            43.23630543321619
          ]
        }
      ],
      "bbox": [
        -6.714904880292462,
        43.23630543321619,
        -6.714904880292462,
        43.23630543321619
      ]
    },
    "33002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5954787871269085,
              43.10868435456158
            ]
          },
          "bbox": [
            -5.5954787871269085,
            43.10868435456158,
            -5.5954787871269085,
            43.10868435456158
          ]
        }
      ],
      "bbox": [
        -5.5954787871269085,
        43.10868435456158,
        -5.5954787871269085,
        43.10868435456158
      ]
    },
    "33003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.093662798322816,
              43.24275682824224
            ]
          },
          "bbox": [
            -5.093662798322816,
            43.24275682824224,
            -5.093662798322816,
            43.24275682824224
          ]
        }
      ],
      "bbox": [
        -5.093662798322816,
        43.24275682824224,
        -5.093662798322816,
        43.24275682824224
      ]
    },
    "33004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9192511152874046,
              43.55919092035962
            ]
          },
          "bbox": [
            -5.9192511152874046,
            43.55919092035962,
            -5.9192511152874046,
            43.55919092035962
          ]
        }
      ],
      "bbox": [
        -5.9192511152874046,
        43.55919092035962,
        -5.9192511152874046,
        43.55919092035962
      ]
    },
    "33005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.25282924270662,
              43.268247033216525
            ]
          },
          "bbox": [
            -6.25282924270662,
            43.268247033216525,
            -6.25282924270662,
            43.268247033216525
          ]
        }
      ],
      "bbox": [
        -6.25282924270662,
        43.268247033216525,
        -6.25282924270662,
        43.268247033216525
      ]
    },
    "33006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.561418078714435,
              43.323733123564956
            ]
          },
          "bbox": [
            -5.561418078714435,
            43.323733123564956,
            -5.561418078714435,
            43.323733123564956
          ]
        }
      ],
      "bbox": [
        -5.561418078714435,
        43.323733123564956,
        -5.561418078714435,
        43.323733123564956
      ]
    },
    "33007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.822478272741659,
              43.417475979915736
            ]
          },
          "bbox": [
            -6.822478272741659,
            43.417475979915736,
            -6.822478272741659,
            43.417475979915736
          ]
        }
      ],
      "bbox": [
        -6.822478272741659,
        43.417475979915736,
        -6.822478272741659,
        43.417475979915736
      ]
    },
    "33008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.828037152634288,
              43.27223967752909
            ]
          },
          "bbox": [
            -4.828037152634288,
            43.27223967752909,
            -4.828037152634288,
            43.27223967752909
          ]
        }
      ],
      "bbox": [
        -4.828037152634288,
        43.27223967752909,
        -4.828037152634288,
        43.27223967752909
      ]
    },
    "33009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.421808081314342,
              43.40495726083308
            ]
          },
          "bbox": [
            -5.421808081314342,
            43.40495726083308,
            -5.421808081314342,
            43.40495726083308
          ]
        }
      ],
      "bbox": [
        -5.421808081314342,
        43.40495726083308,
        -5.421808081314342,
        43.40495726083308
      ]
    },
    "33010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.056086147219423,
              43.448674922581425
            ]
          },
          "bbox": [
            -6.056086147219423,
            43.448674922581425,
            -6.056086147219423,
            43.448674922581425
          ]
        }
      ],
      "bbox": [
        -6.056086147219423,
        43.448674922581425,
        -6.056086147219423,
        43.448674922581425
      ]
    },
    "33011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.551923617120226,
              43.10314811635939
            ]
          },
          "bbox": [
            -6.551923617120226,
            43.10314811635939,
            -6.551923617120226,
            43.10314811635939
          ]
        }
      ],
      "bbox": [
        -6.551923617120226,
        43.10314811635939,
        -6.551923617120226,
        43.10314811635939
      ]
    },
    "33012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0554891520786445,
              43.3252927878343
            ]
          },
          "bbox": [
            -5.0554891520786445,
            43.3252927878343,
            -5.0554891520786445,
            43.3252927878343
          ]
        }
      ],
      "bbox": [
        -5.0554891520786445,
        43.3252927878343,
        -5.0554891520786445,
        43.3252927878343
      ]
    },
    "33013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.191823608640537,
              43.46049482446918
            ]
          },
          "bbox": [
            -5.191823608640537,
            43.46049482446918,
            -5.191823608640537,
            43.46049482446918
          ]
        }
      ],
      "bbox": [
        -5.191823608640537,
        43.46049482446918,
        -5.191823608640537,
        43.46049482446918
      ]
    },
    "33014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.791155045684804,
              43.54994458954959
            ]
          },
          "bbox": [
            -5.791155045684804,
            43.54994458954959,
            -5.791155045684804,
            43.54994458954959
          ]
        }
      ],
      "bbox": [
        -5.791155045684804,
        43.54994458954959,
        -5.791155045684804,
        43.54994458954959
      ]
    },
    "33015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.33303984294234,
              43.16221970555163
            ]
          },
          "bbox": [
            -5.33303984294234,
            43.16221970555163,
            -5.33303984294234,
            43.16221970555163
          ]
        }
      ],
      "bbox": [
        -5.33303984294234,
        43.16221970555163,
        -5.33303984294234,
        43.16221970555163
      ]
    },
    "33016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.992604281172801,
              43.54926449893486
            ]
          },
          "bbox": [
            -5.992604281172801,
            43.54926449893486,
            -5.992604281172801,
            43.54926449893486
          ]
        }
      ],
      "bbox": [
        -5.992604281172801,
        43.54926449893486,
        -5.992604281172801,
        43.54926449893486
      ]
    },
    "33017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.970254740318144,
              43.47430680592895
            ]
          },
          "bbox": [
            -6.970254740318144,
            43.47430680592895,
            -6.970254740318144,
            43.47430680592895
          ]
        }
      ],
      "bbox": [
        -6.970254740318144,
        43.47430680592895,
        -6.970254740318144,
        43.47430680592895
      ]
    },
    "33018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.762793191775562,
              43.51027022280202
            ]
          },
          "bbox": [
            -6.762793191775562,
            43.51027022280202,
            -6.762793191775562,
            43.51027022280202
          ]
        }
      ],
      "bbox": [
        -6.762793191775562,
        43.51027022280202,
        -6.762793191775562,
        43.51027022280202
      ]
    },
    "33019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2843903698600085,
              43.46840102506381
            ]
          },
          "bbox": [
            -5.2843903698600085,
            43.46840102506381,
            -5.2843903698600085,
            43.46840102506381
          ]
        }
      ],
      "bbox": [
        -5.2843903698600085,
        43.46840102506381,
        -5.2843903698600085,
        43.46840102506381
      ]
    },
    "33020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.879168332660057,
              43.51581853172286
            ]
          },
          "bbox": [
            -5.879168332660057,
            43.51581853172286,
            -5.879168332660057,
            43.51581853172286
          ]
        }
      ],
      "bbox": [
        -5.879168332660057,
        43.51581853172286,
        -5.879168332660057,
        43.51581853172286
      ]
    },
    "33021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.229182780859559,
              43.543997787648046
            ]
          },
          "bbox": [
            -6.229182780859559,
            43.543997787648046,
            -6.229182780859559,
            43.543997787648046
          ]
        }
      ],
      "bbox": [
        -6.229182780859559,
        43.543997787648046,
        -6.229182780859559,
        43.543997787648046
      ]
    },
    "33022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.560192410850691,
              42.94677309666748
            ]
          },
          "bbox": [
            -6.560192410850691,
            42.94677309666748,
            -6.560192410850691,
            42.94677309666748
          ]
        }
      ],
      "bbox": [
        -6.560192410850691,
        42.94677309666748,
        -6.560192410850691,
        42.94677309666748
      ]
    },
    "33023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.841714780544751,
              43.50981023962342
            ]
          },
          "bbox": [
            -6.841714780544751,
            43.50981023962342,
            -6.841714780544751,
            43.50981023962342
          ]
        }
      ],
      "bbox": [
        -6.841714780544751,
        43.50981023962342,
        -6.841714780544751,
        43.50981023962342
      ]
    },
    "33024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.682532834905997,
              43.50079022982795
            ]
          },
          "bbox": [
            -5.682532834905997,
            43.50079022982795,
            -5.682532834905997,
            43.50079022982795
          ]
        }
      ],
      "bbox": [
        -5.682532834905997,
        43.50079022982795,
        -5.682532834905997,
        43.50079022982795
      ]
    },
    "33025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.848578976700183,
              43.603223533874036
            ]
          },
          "bbox": [
            -5.848578976700183,
            43.603223533874036,
            -5.848578976700183,
            43.603223533874036
          ]
        }
      ],
      "bbox": [
        -5.848578976700183,
        43.603223533874036,
        -5.848578976700183,
        43.603223533874036
      ]
    },
    "33026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.096745601625419,
              43.320450868471646
            ]
          },
          "bbox": [
            -6.096745601625419,
            43.320450868471646,
            -6.096745601625419,
            43.320450868471646
          ]
        }
      ],
      "bbox": [
        -6.096745601625419,
        43.320450868471646,
        -6.096745601625419,
        43.320450868471646
      ]
    },
    "33027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.89894693039021,
              43.19642733325557
            ]
          },
          "bbox": [
            -6.89894693039021,
            43.19642733325557,
            -6.89894693039021,
            43.19642733325557
          ]
        }
      ],
      "bbox": [
        -6.89894693039021,
        43.19642733325557,
        -6.89894693039021,
        43.19642733325557
      ]
    },
    "33028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.8127375426724335,
              43.004521486429084
            ]
          },
          "bbox": [
            -6.8127375426724335,
            43.004521486429084,
            -6.8127375426724335,
            43.004521486429084
          ]
        }
      ],
      "bbox": [
        -6.8127375426724335,
        43.004521486429084,
        -6.8127375426724335,
        43.004521486429084
      ]
    },
    "33029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.849876876863202,
              43.340677615823644
            ]
          },
          "bbox": [
            -6.849876876863202,
            43.340677615823644,
            -6.849876876863202,
            43.340677615823644
          ]
        }
      ],
      "bbox": [
        -6.849876876863202,
        43.340677615823644,
        -6.849876876863202,
        43.340677615823644
      ]
    },
    "33030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.966311522518556,
              43.49722858383215
            ]
          },
          "bbox": [
            -5.966311522518556,
            43.49722858383215,
            -5.966311522518556,
            43.49722858383215
          ]
        }
      ],
      "bbox": [
        -5.966311522518556,
        43.49722858383215,
        -5.966311522518556,
        43.49722858383215
      ]
    },
    "33031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.693614594385975,
              43.29697861763034
            ]
          },
          "bbox": [
            -5.693614594385975,
            43.29697861763034,
            -5.693614594385975,
            43.29697861763034
          ]
        }
      ],
      "bbox": [
        -5.693614594385975,
        43.29697861763034,
        -5.693614594385975,
        43.29697861763034
      ]
    },
    "33032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.544738069666223,
              43.22827667971547
            ]
          },
          "bbox": [
            -5.544738069666223,
            43.22827667971547,
            -5.544738069666223,
            43.22827667971547
          ]
        }
      ],
      "bbox": [
        -5.544738069666223,
        43.22827667971547,
        -5.544738069666223,
        43.22827667971547
      ]
    },
    "33033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.821615876482831,
              43.07343858695386
            ]
          },
          "bbox": [
            -5.821615876482831,
            43.07343858695386,
            -5.821615876482831,
            43.07343858695386
          ]
        }
      ],
      "bbox": [
        -5.821615876482831,
        43.07343858695386,
        -5.821615876482831,
        43.07343858695386
      ]
    },
    "33034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.467728932377832,
              43.49475049566292
            ]
          },
          "bbox": [
            -6.467728932377832,
            43.49475049566292,
            -6.467728932377832,
            43.49475049566292
          ]
        }
      ],
      "bbox": [
        -6.467728932377832,
        43.49475049566292,
        -6.467728932377832,
        43.49475049566292
      ]
    },
    "33035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.861977736252027,
              43.453711990363274
            ]
          },
          "bbox": [
            -5.861977736252027,
            43.453711990363274,
            -5.861977736252027,
            43.453711990363274
          ]
        }
      ],
      "bbox": [
        -5.861977736252027,
        43.453711990363274,
        -5.861977736252027,
        43.453711990363274
      ]
    },
    "33036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.832468200426193,
              43.39800323925477
            ]
          },
          "bbox": [
            -4.832468200426193,
            43.39800323925477,
            -4.832468200426193,
            43.39800323925477
          ]
        }
      ],
      "bbox": [
        -4.832468200426193,
        43.39800323925477,
        -4.832468200426193,
        43.39800323925477
      ]
    },
    "33037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.754221267827725,
              43.233501216706884
            ]
          },
          "bbox": [
            -5.754221267827725,
            43.233501216706884,
            -5.754221267827725,
            43.233501216706884
          ]
        }
      ],
      "bbox": [
        -5.754221267827725,
        43.233501216706884,
        -5.754221267827725,
        43.233501216706884
      ]
    },
    "33038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.901953885622003,
              43.2668690206395
            ]
          },
          "bbox": [
            -5.901953885622003,
            43.2668690206395,
            -5.901953885622003,
            43.2668690206395
          ]
        }
      ],
      "bbox": [
        -5.901953885622003,
        43.2668690206395,
        -5.901953885622003,
        43.2668690206395
      ]
    },
    "33039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.098294467515128,
              43.54911525807519
            ]
          },
          "bbox": [
            -6.098294467515128,
            43.54911525807519,
            -6.098294467515128,
            43.54911525807519
          ]
        }
      ],
      "bbox": [
        -6.098294467515128,
        43.54911525807519,
        -6.098294467515128,
        43.54911525807519
      ]
    },
    "33040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.493177993731891,
              43.35169429148563
            ]
          },
          "bbox": [
            -5.493177993731891,
            43.35169429148563,
            -5.493177993731891,
            43.35169429148563
          ]
        }
      ],
      "bbox": [
        -5.493177993731891,
        43.35169429148563,
        -5.493177993731891,
        43.35169429148563
      ]
    },
    "33041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.676037571467929,
              43.52929488905468
            ]
          },
          "bbox": [
            -6.676037571467929,
            43.52929488905468,
            -6.676037571467929,
            43.52929488905468
          ]
        }
      ],
      "bbox": [
        -6.676037571467929,
        43.52929488905468,
        -6.676037571467929,
        43.52929488905468
      ]
    },
    "33042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.702015536389287,
              43.40130724034593
            ]
          },
          "bbox": [
            -5.702015536389287,
            43.40130724034593,
            -5.702015536389287,
            43.40130724034593
          ]
        }
      ],
      "bbox": [
        -5.702015536389287,
        43.40130724034593,
        -5.702015536389287,
        43.40130724034593
      ]
    },
    "33043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.954843380009205,
              43.30354638192713
            ]
          },
          "bbox": [
            -4.954843380009205,
            43.30354638192713,
            -4.954843380009205,
            43.30354638192713
          ]
        }
      ],
      "bbox": [
        -4.954843380009205,
        43.30354638192713,
        -4.954843380009205,
        43.30354638192713
      ]
    },
    "33044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.873671653966203,
              43.34974478642904
            ]
          },
          "bbox": [
            -5.873671653966203,
            43.34974478642904,
            -5.873671653966203,
            43.34974478642904
          ]
        }
      ],
      "bbox": [
        -5.873671653966203,
        43.34974478642904,
        -5.873671653966203,
        43.34974478642904
      ]
    },
    "33045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.187818973725608,
              43.36648704282685
            ]
          },
          "bbox": [
            -5.187818973725608,
            43.36648704282685,
            -5.187818973725608,
            43.36648704282685
          ]
        }
      ],
      "bbox": [
        -5.187818973725608,
        43.36648704282685,
        -5.187818973725608,
        43.36648704282685
      ]
    },
    "33046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.715389602383363,
              43.32079454526767
            ]
          },
          "bbox": [
            -4.715389602383363,
            43.32079454526767,
            -4.715389602383363,
            43.32079454526767
          ]
        }
      ],
      "bbox": [
        -4.715389602383363,
        43.32079454526767,
        -4.715389602383363,
        43.32079454526767
      ]
    },
    "33047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.597012353638343,
              43.309638090014005
            ]
          },
          "bbox": [
            -4.597012353638343,
            43.309638090014005,
            -4.597012353638343,
            43.309638090014005
          ]
        }
      ],
      "bbox": [
        -4.597012353638343,
        43.309638090014005,
        -4.597012353638343,
        43.309638090014005
      ]
    },
    "33048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.8690987926419895,
              43.27194569260638
            ]
          },
          "bbox": [
            -6.8690987926419895,
            43.27194569260638,
            -6.8690987926419895,
            43.27194569260638
          ]
        }
      ],
      "bbox": [
        -6.8690987926419895,
        43.27194569260638,
        -6.8690987926419895,
        43.27194569260638
      ]
    },
    "33049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.337661810858464,
              43.330031271434564
            ]
          },
          "bbox": [
            -5.337661810858464,
            43.330031271434564,
            -5.337661810858464,
            43.330031271434564
          ]
        }
      ],
      "bbox": [
        -5.337661810858464,
        43.330031271434564,
        -5.337661810858464,
        43.330031271434564
      ]
    },
    "33050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.172125871907006,
              43.1885366331501
            ]
          },
          "bbox": [
            -5.172125871907006,
            43.1885366331501,
            -5.172125871907006,
            43.1885366331501
          ]
        }
      ],
      "bbox": [
        -5.172125871907006,
        43.1885366331501,
        -5.172125871907006,
        43.1885366331501
      ]
    },
    "33051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1500475665336065,
              43.48508767795018
            ]
          },
          "bbox": [
            -6.1500475665336065,
            43.48508767795018,
            -6.1500475665336065,
            43.48508767795018
          ]
        }
      ],
      "bbox": [
        -6.1500475665336065,
        43.48508767795018,
        -6.1500475665336065,
        43.48508767795018
      ]
    },
    "33052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.044171168045473,
              43.22812480045562
            ]
          },
          "bbox": [
            -6.044171168045473,
            43.22812480045562,
            -6.044171168045473,
            43.22812480045562
          ]
        }
      ],
      "bbox": [
        -6.044171168045473,
        43.22812480045562,
        -6.044171168045473,
        43.22812480045562
      ]
    },
    "33053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.960478524872781,
              43.14006947921423
            ]
          },
          "bbox": [
            -5.960478524872781,
            43.14006947921423,
            -5.960478524872781,
            43.14006947921423
          ]
        }
      ],
      "bbox": [
        -5.960478524872781,
        43.14006947921423,
        -5.960478524872781,
        43.14006947921423
      ]
    },
    "33054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.972556623790251,
              43.421264663820295
            ]
          },
          "bbox": [
            -5.972556623790251,
            43.421264663820295,
            -5.972556623790251,
            43.421264663820295
          ]
        }
      ],
      "bbox": [
        -5.972556623790251,
        43.421264663820295,
        -5.972556623790251,
        43.421264663820295
      ]
    },
    "33055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5641510112189305,
              43.37133168861646
            ]
          },
          "bbox": [
            -4.5641510112189305,
            43.37133168861646,
            -4.5641510112189305,
            43.37133168861646
          ]
        }
      ],
      "bbox": [
        -4.5641510112189305,
        43.37133168861646,
        -4.5641510112189305,
        43.37133168861646
      ]
    },
    "33056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.08377047407866,
              43.445003461696
            ]
          },
          "bbox": [
            -5.08377047407866,
            43.445003461696,
            -5.08377047407866,
            43.445003461696
          ]
        }
      ],
      "bbox": [
        -5.08377047407866,
        43.445003461696,
        -5.08377047407866,
        43.445003461696
      ]
    },
    "33057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8835969490869955,
              43.30489008638426
            ]
          },
          "bbox": [
            -5.8835969490869955,
            43.30489008638426,
            -5.8835969490869955,
            43.30489008638426
          ]
        }
      ],
      "bbox": [
        -5.8835969490869955,
        43.30489008638426,
        -5.8835969490869955,
        43.30489008638426
      ]
    },
    "33058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.891977627517024,
              43.21396327598447
            ]
          },
          "bbox": [
            -5.891977627517024,
            43.21396327598447,
            -5.891977627517024,
            43.21396327598447
          ]
        }
      ],
      "bbox": [
        -5.891977627517024,
        43.21396327598447,
        -5.891977627517024,
        43.21396327598447
      ]
    },
    "33059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.249478150043418,
              43.41341301853097
            ]
          },
          "bbox": [
            -6.249478150043418,
            43.41341301853097,
            -6.249478150043418,
            43.41341301853097
          ]
        }
      ],
      "bbox": [
        -6.249478150043418,
        43.41341301853097,
        -6.249478150043418,
        43.41341301853097
      ]
    },
    "33060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6152981821251275,
              43.27443201988213
            ]
          },
          "bbox": [
            -5.6152981821251275,
            43.27443201988213,
            -5.6152981821251275,
            43.27443201988213
          ]
        }
      ],
      "bbox": [
        -5.6152981821251275,
        43.27443201988213,
        -5.6152981821251275,
        43.27443201988213
      ]
    },
    "33061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.938008721909701,
              43.279578068592265
            ]
          },
          "bbox": [
            -6.938008721909701,
            43.279578068592265,
            -6.938008721909701,
            43.279578068592265
          ]
        }
      ],
      "bbox": [
        -6.938008721909701,
        43.279578068592265,
        -6.938008721909701,
        43.279578068592265
      ]
    },
    "33062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.027702424404276,
              43.25576971315418
            ]
          },
          "bbox": [
            -7.027702424404276,
            43.25576971315418,
            -7.027702424404276,
            43.25576971315418
          ]
        }
      ],
      "bbox": [
        -7.027702424404276,
        43.25576971315418,
        -7.027702424404276,
        43.25576971315418
      ]
    },
    "33063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.1390164075835365,
              43.404697533356135
            ]
          },
          "bbox": [
            -7.1390164075835365,
            43.404697533356135,
            -7.1390164075835365,
            43.404697533356135
          ]
        }
      ],
      "bbox": [
        -7.1390164075835365,
        43.404697533356135,
        -7.1390164075835365,
        43.404697533356135
      ]
    },
    "33064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9811845139440685,
              43.2878945852017
            ]
          },
          "bbox": [
            -5.9811845139440685,
            43.2878945852017,
            -5.9811845139440685,
            43.2878945852017
          ]
        }
      ],
      "bbox": [
        -5.9811845139440685,
        43.2878945852017,
        -5.9811845139440685,
        43.2878945852017
      ]
    },
    "33065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.551579984524404,
              43.412167274253555
            ]
          },
          "bbox": [
            -5.551579984524404,
            43.412167274253555,
            -5.551579984524404,
            43.412167274253555
          ]
        }
      ],
      "bbox": [
        -5.551579984524404,
        43.412167274253555,
        -5.551579984524404,
        43.412167274253555
      ]
    },
    "33066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.685334785123981,
              43.39305343730373
            ]
          },
          "bbox": [
            -5.685334785123981,
            43.39305343730373,
            -5.685334785123981,
            43.39305343730373
          ]
        }
      ],
      "bbox": [
        -5.685334785123981,
        43.39305343730373,
        -5.685334785123981,
        43.39305343730373
      ]
    },
    "33067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4640826808565635,
              43.193350794824326
            ]
          },
          "bbox": [
            -5.4640826808565635,
            43.193350794824326,
            -5.4640826808565635,
            43.193350794824326
          ]
        }
      ],
      "bbox": [
        -5.4640826808565635,
        43.193350794824326,
        -5.4640826808565635,
        43.193350794824326
      ]
    },
    "33068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.249214237349382,
              43.09739223928512
            ]
          },
          "bbox": [
            -6.249214237349382,
            43.09739223928512,
            -6.249214237349382,
            43.09739223928512
          ]
        }
      ],
      "bbox": [
        -6.249214237349382,
        43.09739223928512,
        -6.249214237349382,
        43.09739223928512
      ]
    },
    "33069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.055392364764771,
              43.52541771445333
            ]
          },
          "bbox": [
            -6.055392364764771,
            43.52541771445333,
            -6.055392364764771,
            43.52541771445333
          ]
        }
      ],
      "bbox": [
        -6.055392364764771,
        43.52541771445333,
        -6.055392364764771,
        43.52541771445333
      ]
    },
    "33070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.922310545141702,
              43.52537286165346
            ]
          },
          "bbox": [
            -6.922310545141702,
            43.52537286165346,
            -6.922310545141702,
            43.52537286165346
          ]
        }
      ],
      "bbox": [
        -6.922310545141702,
        43.52537286165346,
        -6.922310545141702,
        43.52537286165346
      ]
    },
    "33071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.076603328476635,
              43.35951342963045
            ]
          },
          "bbox": [
            -7.076603328476635,
            43.35951342963045,
            -7.076603328476635,
            43.35951342963045
          ]
        }
      ],
      "bbox": [
        -7.076603328476635,
        43.35951342963045,
        -7.076603328476635,
        43.35951342963045
      ]
    },
    "33072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.107282313998435,
              43.1408286615817
            ]
          },
          "bbox": [
            -6.107282313998435,
            43.1408286615817,
            -6.107282313998435,
            43.1408286615817
          ]
        }
      ],
      "bbox": [
        -6.107282313998435,
        43.1408286615817,
        -6.107282313998435,
        43.1408286615817
      ]
    },
    "33073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.484032012845278,
              43.33745770779906
            ]
          },
          "bbox": [
            -6.484032012845278,
            43.33745770779906,
            -6.484032012845278,
            43.33745770779906
          ]
        }
      ],
      "bbox": [
        -6.484032012845278,
        43.33745770779906,
        -6.484032012845278,
        43.33745770779906
      ]
    },
    "33074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.025493412472279,
              43.42167002818703
            ]
          },
          "bbox": [
            -7.025493412472279,
            43.42167002818703,
            -7.025493412472279,
            43.42167002818703
          ]
        }
      ],
      "bbox": [
        -7.025493412472279,
        43.42167002818703,
        -7.025493412472279,
        43.42167002818703
      ]
    },
    "33075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.989604674400609,
              43.32463146200989
            ]
          },
          "bbox": [
            -6.989604674400609,
            43.32463146200989,
            -6.989604674400609,
            43.32463146200989
          ]
        }
      ],
      "bbox": [
        -6.989604674400609,
        43.32463146200989,
        -6.989604674400609,
        43.32463146200989
      ]
    },
    "33076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.45938192540315,
              43.484974382896425
            ]
          },
          "bbox": [
            -5.45938192540315,
            43.484974382896425,
            -5.45938192540315,
            43.484974382896425
          ]
        }
      ],
      "bbox": [
        -5.45938192540315,
        43.484974382896425,
        -5.45938192540315,
        43.484974382896425
      ]
    },
    "33077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.705836218803768,
              43.41210073197469
            ]
          },
          "bbox": [
            -6.705836218803768,
            43.41210073197469,
            -6.705836218803768,
            43.41210073197469
          ]
        }
      ],
      "bbox": [
        -6.705836218803768,
        43.41210073197469,
        -6.705836218803768,
        43.41210073197469
      ]
    },
    "33078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.108998596436827,
              43.26151688132875
            ]
          },
          "bbox": [
            -6.108998596436827,
            43.26151688132875,
            -6.108998596436827,
            43.26151688132875
          ]
        }
      ],
      "bbox": [
        -6.108998596436827,
        43.26151688132875,
        -6.108998596436827,
        43.26151688132875
      ]
    },
    "34001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.846605944125529,
              42.05910704596323
            ]
          },
          "bbox": [
            -4.846605944125529,
            42.05910704596323,
            -4.846605944125529,
            42.05910704596323
          ]
        }
      ],
      "bbox": [
        -4.846605944125529,
        42.05910704596323,
        -4.846605944125529,
        42.05910704596323
      ]
    },
    "34003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.419048927075109,
              42.435408631615836
            ]
          },
          "bbox": [
            -4.419048927075109,
            42.435408631615836,
            -4.419048927075109,
            42.435408631615836
          ]
        }
      ],
      "bbox": [
        -4.419048927075109,
        42.435408631615836,
        -4.419048927075109,
        42.435408631615836
      ]
    },
    "34004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.282060795254401,
              42.792473939394576
            ]
          },
          "bbox": [
            -4.282060795254401,
            42.792473939394576,
            -4.282060795254401,
            42.792473939394576
          ]
        }
      ],
      "bbox": [
        -4.282060795254401,
        42.792473939394576,
        -4.282060795254401,
        42.792473939394576
      ]
    },
    "34005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.303982781151475,
              42.67277510616676
            ]
          },
          "bbox": [
            -4.303982781151475,
            42.67277510616676,
            -4.303982781151475,
            42.67277510616676
          ]
        }
      ],
      "bbox": [
        -4.303982781151475,
        42.67277510616676,
        -4.303982781151475,
        42.67277510616676
      ]
    },
    "34006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3684502722040595,
              41.800501276648106
            ]
          },
          "bbox": [
            -4.3684502722040595,
            41.800501276648106,
            -4.3684502722040595,
            41.800501276648106
          ]
        }
      ],
      "bbox": [
        -4.3684502722040595,
        41.800501276648106,
        -4.3684502722040595,
        41.800501276648106
      ]
    },
    "34009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4862541011854535,
              42.221663141265985
            ]
          },
          "bbox": [
            -4.4862541011854535,
            42.221663141265985,
            -4.4862541011854535,
            42.221663141265985
          ]
        }
      ],
      "bbox": [
        -4.4862541011854535,
        42.221663141265985,
        -4.4862541011854535,
        42.221663141265985
      ]
    },
    "34010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.767311658556328,
              41.89740298941491
            ]
          },
          "bbox": [
            -4.767311658556328,
            41.89740298941491,
            -4.767311658556328,
            41.89740298941491
          ]
        }
      ],
      "bbox": [
        -4.767311658556328,
        41.89740298941491,
        -4.767311658556328,
        41.89740298941491
      ]
    },
    "34011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.443282583734583,
              42.148700945234964
            ]
          },
          "bbox": [
            -4.443282583734583,
            42.148700945234964,
            -4.443282583734583,
            42.148700945234964
          ]
        }
      ],
      "bbox": [
        -4.443282583734583,
        42.148700945234964,
        -4.443282583734583,
        42.148700945234964
      ]
    },
    "34012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.088752075166201,
              41.93036222539336
            ]
          },
          "bbox": [
            -4.088752075166201,
            41.93036222539336,
            -4.088752075166201,
            41.93036222539336
          ]
        }
      ],
      "bbox": [
        -4.088752075166201,
        41.93036222539336,
        -4.088752075166201,
        41.93036222539336
      ]
    },
    "34015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.487103793984398,
              42.33942682414192
            ]
          },
          "bbox": [
            -4.487103793984398,
            42.33942682414192,
            -4.487103793984398,
            42.33942682414192
          ]
        }
      ],
      "bbox": [
        -4.487103793984398,
        42.33942682414192,
        -4.487103793984398,
        42.33942682414192
      ]
    },
    "34017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.319335332738238,
              42.16223165522798
            ]
          },
          "bbox": [
            -4.319335332738238,
            42.16223165522798,
            -4.319335332738238,
            42.16223165522798
          ]
        }
      ],
      "bbox": [
        -4.319335332738238,
        42.16223165522798,
        -4.319335332738238,
        42.16223165522798
      ]
    },
    "34018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.632868371401372,
              41.9842452087957
            ]
          },
          "bbox": [
            -4.632868371401372,
            41.9842452087957,
            -4.632868371401372,
            41.9842452087957
          ]
        }
      ],
      "bbox": [
        -4.632868371401372,
        41.9842452087957,
        -4.632868371401372,
        41.9842452087957
      ]
    },
    "34019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8508074751830605,
              42.08791203500039
            ]
          },
          "bbox": [
            -4.8508074751830605,
            42.08791203500039,
            -4.8508074751830605,
            42.08791203500039
          ]
        }
      ],
      "bbox": [
        -4.8508074751830605,
        42.08791203500039,
        -4.8508074751830605,
        42.08791203500039
      ]
    },
    "34020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.703866425691492,
              42.642408513039456
            ]
          },
          "bbox": [
            -4.703866425691492,
            42.642408513039456,
            -4.703866425691492,
            42.642408513039456
          ]
        }
      ],
      "bbox": [
        -4.703866425691492,
        42.642408513039456,
        -4.703866425691492,
        42.642408513039456
      ]
    },
    "34022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.203495571914064,
              41.9403838847776
            ]
          },
          "bbox": [
            -4.203495571914064,
            41.9403838847776,
            -4.203495571914064,
            41.9403838847776
          ]
        }
      ],
      "bbox": [
        -4.203495571914064,
        41.9403838847776,
        -4.203495571914064,
        41.9403838847776
      ]
    },
    "34023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.470850710690059,
              41.929202409381546
            ]
          },
          "bbox": [
            -4.470850710690059,
            41.929202409381546,
            -4.470850710690059,
            41.929202409381546
          ]
        }
      ],
      "bbox": [
        -4.470850710690059,
        41.929202409381546,
        -4.470850710690059,
        41.929202409381546
      ]
    },
    "34024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.764332433039146,
              42.01965233608125
            ]
          },
          "bbox": [
            -4.764332433039146,
            42.01965233608125,
            -4.764332433039146,
            42.01965233608125
          ]
        }
      ],
      "bbox": [
        -4.764332433039146,
        42.01965233608125,
        -4.764332433039146,
        42.01965233608125
      ]
    },
    "34025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4993941567567575,
              42.48676376270852
            ]
          },
          "bbox": [
            -4.4993941567567575,
            42.48676376270852,
            -4.4993941567567575,
            42.48676376270852
          ]
        }
      ],
      "bbox": [
        -4.4993941567567575,
        42.48676376270852,
        -4.4993941567567575,
        42.48676376270852
      ]
    },
    "34027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.306710434836491,
              42.88339935695168
            ]
          },
          "bbox": [
            -4.306710434836491,
            42.88339935695168,
            -4.306710434836491,
            42.88339935695168
          ]
        }
      ],
      "bbox": [
        -4.306710434836491,
        42.88339935695168,
        -4.306710434836491,
        42.88339935695168
      ]
    },
    "34028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.537247272312714,
              42.679367768148296
            ]
          },
          "bbox": [
            -4.537247272312714,
            42.679367768148296,
            -4.537247272312714,
            42.679367768148296
          ]
        }
      ],
      "bbox": [
        -4.537247272312714,
        42.679367768148296,
        -4.537247272312714,
        42.679367768148296
      ]
    },
    "34029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.634179073603581,
              42.11283162675606
            ]
          },
          "bbox": [
            -4.634179073603581,
            42.11283162675606,
            -4.634179073603581,
            42.11283162675606
          ]
        }
      ],
      "bbox": [
        -4.634179073603581,
        42.11283162675606,
        -4.634179073603581,
        42.11283162675606
      ]
    },
    "34031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.979084198655143,
              41.940650291449906
            ]
          },
          "bbox": [
            -4.979084198655143,
            41.940650291449906,
            -4.979084198655143,
            41.940650291449906
          ]
        }
      ],
      "bbox": [
        -4.979084198655143,
        41.940650291449906,
        -4.979084198655143,
        41.940650291449906
      ]
    },
    "34032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.017762705366565,
              42.79040956011338
            ]
          },
          "bbox": [
            -4.017762705366565,
            42.79040956011338,
            -4.017762705366565,
            42.79040956011338
          ]
        }
      ],
      "bbox": [
        -4.017762705366565,
        42.79040956011338,
        -4.017762705366565,
        42.79040956011338
      ]
    },
    "34033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.879371072941184,
              41.985666597770994
            ]
          },
          "bbox": [
            -4.879371072941184,
            41.985666597770994,
            -4.879371072941184,
            41.985666597770994
          ]
        }
      ],
      "bbox": [
        -4.879371072941184,
        41.985666597770994,
        -4.879371072941184,
        41.985666597770994
      ]
    },
    "34034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.336253821649749,
              42.26676342911143
            ]
          },
          "bbox": [
            -4.336253821649749,
            42.26676342911143,
            -4.336253821649749,
            42.26676342911143
          ]
        }
      ],
      "bbox": [
        -4.336253821649749,
        42.26676342911143,
        -4.336253821649749,
        42.26676342911143
      ]
    },
    "34035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.968095919496672,
              42.17737040651127
            ]
          },
          "bbox": [
            -4.968095919496672,
            42.17737040651127,
            -4.968095919496672,
            42.17737040651127
          ]
        }
      ],
      "bbox": [
        -4.968095919496672,
        42.17737040651127,
        -4.968095919496672,
        42.17737040651127
      ]
    },
    "34036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.295333123239094,
              42.93912015900111
            ]
          },
          "bbox": [
            -4.295333123239094,
            42.93912015900111,
            -4.295333123239094,
            42.93912015900111
          ]
        }
      ],
      "bbox": [
        -4.295333123239094,
        42.93912015900111,
        -4.295333123239094,
        42.93912015900111
      ]
    },
    "34037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6050796455374154,
              42.6322977373565
            ]
          },
          "bbox": [
            -4.6050796455374154,
            42.6322977373565,
            -4.6050796455374154,
            42.6322977373565
          ]
        }
      ],
      "bbox": [
        -4.6050796455374154,
        42.6322977373565,
        -4.6050796455374154,
        42.6322977373565
      ]
    },
    "34038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.745166010077757,
              42.443779372432054
            ]
          },
          "bbox": [
            -4.745166010077757,
            42.443779372432054,
            -4.745166010077757,
            42.443779372432054
          ]
        }
      ],
      "bbox": [
        -4.745166010077757,
        42.443779372432054,
        -4.745166010077757,
        42.443779372432054
      ]
    },
    "34039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.731997990514242,
              42.34860846072124
            ]
          },
          "bbox": [
            -4.731997990514242,
            42.34860846072124,
            -4.731997990514242,
            42.34860846072124
          ]
        }
      ],
      "bbox": [
        -4.731997990514242,
        42.34860846072124,
        -4.731997990514242,
        42.34860846072124
      ]
    },
    "34041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.379940621165331,
              42.56654780384977
            ]
          },
          "bbox": [
            -4.379940621165331,
            42.56654780384977,
            -4.379940621165331,
            42.56654780384977
          ]
        }
      ],
      "bbox": [
        -4.379940621165331,
        42.56654780384977,
        -4.379940621165331,
        42.56654780384977
      ]
    },
    "34042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6628036558393315,
              42.31597397438285
            ]
          },
          "bbox": [
            -4.6628036558393315,
            42.31597397438285,
            -4.6628036558393315,
            42.31597397438285
          ]
        }
      ],
      "bbox": [
        -4.6628036558393315,
        42.31597397438285,
        -4.6628036558393315,
        42.31597397438285
      ]
    },
    "34045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.897430877475116,
              42.010279076923474
            ]
          },
          "bbox": [
            -4.897430877475116,
            42.010279076923474,
            -4.897430877475116,
            42.010279076923474
          ]
        }
      ],
      "bbox": [
        -4.897430877475116,
        42.010279076923474,
        -4.897430877475116,
        42.010279076923474
      ]
    },
    "34046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.69947089010432,
              42.22908069407451
            ]
          },
          "bbox": [
            -4.69947089010432,
            42.22908069407451,
            -4.69947089010432,
            42.22908069407451
          ]
        }
      ],
      "bbox": [
        -4.69947089010432,
        42.22908069407451,
        -4.69947089010432,
        42.22908069407451
      ]
    },
    "34047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.615912595785887,
              42.327190298741534
            ]
          },
          "bbox": [
            -4.615912595785887,
            42.327190298741534,
            -4.615912595785887,
            42.327190298741534
          ]
        }
      ],
      "bbox": [
        -4.615912595785887,
        42.327190298741534,
        -4.615912595785887,
        42.327190298741534
      ]
    },
    "34048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9509413814601215,
              41.98244125493511
            ]
          },
          "bbox": [
            -4.9509413814601215,
            41.98244125493511,
            -4.9509413814601215,
            41.98244125493511
          ]
        }
      ],
      "bbox": [
        -4.9509413814601215,
        41.98244125493511,
        -4.9509413814601215,
        41.98244125493511
      ]
    },
    "34049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.600500697124874,
              42.796688293028765
            ]
          },
          "bbox": [
            -4.600500697124874,
            42.796688293028765,
            -4.600500697124874,
            42.796688293028765
          ]
        }
      ],
      "bbox": [
        -4.600500697124874,
        42.796688293028765,
        -4.600500697124874,
        42.796688293028765
      ]
    },
    "34050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.089671789813191,
              41.82479402242167
            ]
          },
          "bbox": [
            -4.089671789813191,
            41.82479402242167,
            -4.089671789813191,
            41.82479402242167
          ]
        }
      ],
      "bbox": [
        -4.089671789813191,
        41.82479402242167,
        -4.089671789813191,
        41.82479402242167
      ]
    },
    "34051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.289237470236848,
              41.86258509748818
            ]
          },
          "bbox": [
            -4.289237470236848,
            41.86258509748818,
            -4.289237470236848,
            41.86258509748818
          ]
        }
      ],
      "bbox": [
        -4.289237470236848,
        41.86258509748818,
        -4.289237470236848,
        41.86258509748818
      ]
    },
    "34052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.477926563247602,
              42.45447067328185
            ]
          },
          "bbox": [
            -4.477926563247602,
            42.45447067328185,
            -4.477926563247602,
            42.45447067328185
          ]
        }
      ],
      "bbox": [
        -4.477926563247602,
        42.45447067328185,
        -4.477926563247602,
        42.45447067328185
      ]
    },
    "34053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.824382029662819,
              42.018459821140254
            ]
          },
          "bbox": [
            -4.824382029662819,
            42.018459821140254,
            -4.824382029662819,
            42.018459821140254
          ]
        }
      ],
      "bbox": [
        -4.824382029662819,
        42.018459821140254,
        -4.824382029662819,
        42.018459821140254
      ]
    },
    "34055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.779878531143017,
              42.311383502856906
            ]
          },
          "bbox": [
            -4.779878531143017,
            42.311383502856906,
            -4.779878531143017,
            42.311383502856906
          ]
        }
      ],
      "bbox": [
        -4.779878531143017,
        42.311383502856906,
        -4.779878531143017,
        42.311383502856906
      ]
    },
    "34056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.528389446519895,
              42.903844468159235
            ]
          },
          "bbox": [
            -4.528389446519895,
            42.903844468159235,
            -4.528389446519895,
            42.903844468159235
          ]
        }
      ],
      "bbox": [
        -4.528389446519895,
        42.903844468159235,
        -4.528389446519895,
        42.903844468159235
      ]
    },
    "34057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.423550399183602,
              41.851377284191
            ]
          },
          "bbox": [
            -4.423550399183602,
            41.851377284191,
            -4.423550399183602,
            41.851377284191
          ]
        }
      ],
      "bbox": [
        -4.423550399183602,
        41.851377284191,
        -4.423550399183602,
        41.851377284191
      ]
    },
    "34058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.151171880164257,
              41.859274697627036
            ]
          },
          "bbox": [
            -4.151171880164257,
            41.859274697627036,
            -4.151171880164257,
            41.859274697627036
          ]
        }
      ],
      "bbox": [
        -4.151171880164257,
        41.859274697627036,
        -4.151171880164257,
        41.859274697627036
      ]
    },
    "34059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.870240158813469,
              42.20988948645622
            ]
          },
          "bbox": [
            -4.870240158813469,
            42.20988948645622,
            -4.870240158813469,
            42.20988948645622
          ]
        }
      ],
      "bbox": [
        -4.870240158813469,
        42.20988948645622,
        -4.870240158813469,
        42.20988948645622
      ]
    },
    "34060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.029261318268578,
              42.01414806989035
            ]
          },
          "bbox": [
            -4.029261318268578,
            42.01414806989035,
            -4.029261318268578,
            42.01414806989035
          ]
        }
      ],
      "bbox": [
        -4.029261318268578,
        42.01414806989035,
        -4.029261318268578,
        42.01414806989035
      ]
    },
    "34061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.49305693074376,
              42.63670432057141
            ]
          },
          "bbox": [
            -4.49305693074376,
            42.63670432057141,
            -4.49305693074376,
            42.63670432057141
          ]
        }
      ],
      "bbox": [
        -4.49305693074376,
        42.63670432057141,
        -4.49305693074376,
        42.63670432057141
      ]
    },
    "34062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.666469361803728,
              42.70674094575713
            ]
          },
          "bbox": [
            -4.666469361803728,
            42.70674094575713,
            -4.666469361803728,
            42.70674094575713
          ]
        }
      ],
      "bbox": [
        -4.666469361803728,
        42.70674094575713,
        -4.666469361803728,
        42.70674094575713
      ]
    },
    "34063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.238923590130177,
              42.097923203725905
            ]
          },
          "bbox": [
            -4.238923590130177,
            42.097923203725905,
            -4.238923590130177,
            42.097923203725905
          ]
        }
      ],
      "bbox": [
        -4.238923590130177,
        42.097923203725905,
        -4.238923590130177,
        42.097923203725905
      ]
    },
    "34066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.470136056615126,
              41.7905407075853
            ]
          },
          "bbox": [
            -4.470136056615126,
            41.7905407075853,
            -4.470136056615126,
            41.7905407075853
          ]
        }
      ],
      "bbox": [
        -4.470136056615126,
        41.7905407075853,
        -4.470136056615126,
        41.7905407075853
      ]
    },
    "34067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.514963275929096,
              42.82010566982066
            ]
          },
          "bbox": [
            -4.514963275929096,
            42.82010566982066,
            -4.514963275929096,
            42.82010566982066
          ]
        }
      ],
      "bbox": [
        -4.514963275929096,
        42.82010566982066,
        -4.514963275929096,
        42.82010566982066
      ]
    },
    "34068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4423478548513335,
              42.63814121665074
            ]
          },
          "bbox": [
            -4.4423478548513335,
            42.63814121665074,
            -4.4423478548513335,
            42.63814121665074
          ]
        }
      ],
      "bbox": [
        -4.4423478548513335,
        42.63814121665074,
        -4.4423478548513335,
        42.63814121665074
      ]
    },
    "34069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.561739227293841,
              41.87578870324266
            ]
          },
          "bbox": [
            -4.561739227293841,
            41.87578870324266,
            -4.561739227293841,
            41.87578870324266
          ]
        }
      ],
      "bbox": [
        -4.561739227293841,
        41.87578870324266,
        -4.561739227293841,
        41.87578870324266
      ]
    },
    "34070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.957345005333156,
              41.95650472140733
            ]
          },
          "bbox": [
            -3.957345005333156,
            41.95650472140733,
            -3.957345005333156,
            41.95650472140733
          ]
        }
      ],
      "bbox": [
        -3.957345005333156,
        41.95650472140733,
        -3.957345005333156,
        41.95650472140733
      ]
    },
    "34071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.374533565663662,
              42.47250857371976
            ]
          },
          "bbox": [
            -4.374533565663662,
            42.47250857371976,
            -4.374533565663662,
            42.47250857371976
          ]
        }
      ],
      "bbox": [
        -4.374533565663662,
        42.47250857371976,
        -4.374533565663662,
        42.47250857371976
      ]
    },
    "34072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.838524924259961,
              42.134208447610625
            ]
          },
          "bbox": [
            -4.838524924259961,
            42.134208447610625,
            -4.838524924259961,
            42.134208447610625
          ]
        }
      ],
      "bbox": [
        -4.838524924259961,
        42.134208447610625,
        -4.838524924259961,
        42.134208447610625
      ]
    },
    "34073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.838129596502357,
              42.68345319372248
            ]
          },
          "bbox": [
            -4.838129596502357,
            42.68345319372248,
            -4.838129596502357,
            42.68345319372248
          ]
        }
      ],
      "bbox": [
        -4.838129596502357,
        42.68345319372248,
        -4.838129596502357,
        42.68345319372248
      ]
    },
    "34074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.405260603904396,
              42.266757713792
            ]
          },
          "bbox": [
            -4.405260603904396,
            42.266757713792,
            -4.405260603904396,
            42.266757713792
          ]
        }
      ],
      "bbox": [
        -4.405260603904396,
        42.266757713792,
        -4.405260603904396,
        42.266757713792
      ]
    },
    "34076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.767476640632287,
              42.09013867397223
            ]
          },
          "bbox": [
            -4.767476640632287,
            42.09013867397223,
            -4.767476640632287,
            42.09013867397223
          ]
        }
      ],
      "bbox": [
        -4.767476640632287,
        42.09013867397223,
        -4.767476640632287,
        42.09013867397223
      ]
    },
    "34077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.485105366092034,
              42.06272935216308
            ]
          },
          "bbox": [
            -4.485105366092034,
            42.06272935216308,
            -4.485105366092034,
            42.06272935216308
          ]
        }
      ],
      "bbox": [
        -4.485105366092034,
        42.06272935216308,
        -4.485105366092034,
        42.06272935216308
      ]
    },
    "34079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.588365256078954,
              42.05023560811689
            ]
          },
          "bbox": [
            -4.588365256078954,
            42.05023560811689,
            -4.588365256078954,
            42.05023560811689
          ]
        }
      ],
      "bbox": [
        -4.588365256078954,
        42.05023560811689,
        -4.588365256078954,
        42.05023560811689
      ]
    },
    "34080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8522253204261805,
              42.78611595804542
            ]
          },
          "bbox": [
            -4.8522253204261805,
            42.78611595804542,
            -4.8522253204261805,
            42.78611595804542
          ]
        }
      ],
      "bbox": [
        -4.8522253204261805,
        42.78611595804542,
        -4.8522253204261805,
        42.78611595804542
      ]
    },
    "34081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.903576726034671,
              42.128210951927066
            ]
          },
          "bbox": [
            -4.903576726034671,
            42.128210951927066,
            -4.903576726034671,
            42.128210951927066
          ]
        }
      ],
      "bbox": [
        -4.903576726034671,
        42.128210951927066,
        -4.903576726034671,
        42.128210951927066
      ]
    },
    "34082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.172530631675557,
              41.81793405411988
            ]
          },
          "bbox": [
            -4.172530631675557,
            41.81793405411988,
            -4.172530631675557,
            41.81793405411988
          ]
        }
      ],
      "bbox": [
        -4.172530631675557,
        41.81793405411988,
        -4.172530631675557,
        41.81793405411988
      ]
    },
    "34083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.310841772090711,
              42.548529171117345
            ]
          },
          "bbox": [
            -4.310841772090711,
            42.548529171117345,
            -4.310841772090711,
            42.548529171117345
          ]
        }
      ],
      "bbox": [
        -4.310841772090711,
        42.548529171117345,
        -4.310841772090711,
        42.548529171117345
      ]
    },
    "34084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.204554959559062,
              42.04220306428443
            ]
          },
          "bbox": [
            -4.204554959559062,
            42.04220306428443,
            -4.204554959559062,
            42.04220306428443
          ]
        }
      ],
      "bbox": [
        -4.204554959559062,
        42.04220306428443,
        -4.204554959559062,
        42.04220306428443
      ]
    },
    "34086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.416890361141103,
              41.91205141255902
            ]
          },
          "bbox": [
            -4.416890361141103,
            41.91205141255902,
            -4.416890361141103,
            41.91205141255902
          ]
        }
      ],
      "bbox": [
        -4.416890361141103,
        41.91205141255902,
        -4.416890361141103,
        41.91205141255902
      ]
    },
    "34087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.258207215371231,
              41.99438221650213
            ]
          },
          "bbox": [
            -4.258207215371231,
            41.99438221650213,
            -4.258207215371231,
            41.99438221650213
          ]
        }
      ],
      "bbox": [
        -4.258207215371231,
        41.99438221650213,
        -4.258207215371231,
        41.99438221650213
      ]
    },
    "34088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.538033238156924,
              42.09222644871399
            ]
          },
          "bbox": [
            -4.538033238156924,
            42.09222644871399,
            -4.538033238156924,
            42.09222644871399
          ]
        }
      ],
      "bbox": [
        -4.538033238156924,
        42.09222644871399,
        -4.538033238156924,
        42.09222644871399
      ]
    },
    "34089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.278971205507846,
              42.2900826827759
            ]
          },
          "bbox": [
            -4.278971205507846,
            42.2900826827759,
            -4.278971205507846,
            42.2900826827759
          ]
        }
      ],
      "bbox": [
        -4.278971205507846,
        42.2900826827759,
        -4.278971205507846,
        42.2900826827759
      ]
    },
    "34091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.886301205502598,
              42.40606583118681
            ]
          },
          "bbox": [
            -4.886301205502598,
            42.40606583118681,
            -4.886301205502598,
            42.40606583118681
          ]
        }
      ],
      "bbox": [
        -4.886301205502598,
        42.40606583118681,
        -4.886301205502598,
        42.40606583118681
      ]
    },
    "34092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3021302654445535,
              42.3319439924098
            ]
          },
          "bbox": [
            -4.3021302654445535,
            42.3319439924098,
            -4.3021302654445535,
            42.3319439924098
          ]
        }
      ],
      "bbox": [
        -4.3021302654445535,
        42.3319439924098,
        -4.3021302654445535,
        42.3319439924098
      ]
    },
    "34093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.394343828856647,
              42.65633409511074
            ]
          },
          "bbox": [
            -4.394343828856647,
            42.65633409511074,
            -4.394343828856647,
            42.65633409511074
          ]
        }
      ],
      "bbox": [
        -4.394343828856647,
        42.65633409511074,
        -4.394343828856647,
        42.65633409511074
      ]
    },
    "34094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.844671064184609,
              42.36801627210932
            ]
          },
          "bbox": [
            -4.844671064184609,
            42.36801627210932,
            -4.844671064184609,
            42.36801627210932
          ]
        }
      ],
      "bbox": [
        -4.844671064184609,
        42.36801627210932,
        -4.844671064184609,
        42.36801627210932
      ]
    },
    "34096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5543141170675225,
              42.27250946887233
            ]
          },
          "bbox": [
            -4.5543141170675225,
            42.27250946887233,
            -4.5543141170675225,
            42.27250946887233
          ]
        }
      ],
      "bbox": [
        -4.5543141170675225,
        42.27250946887233,
        -4.5543141170675225,
        42.27250946887233
      ]
    },
    "34098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.447093994371671,
              41.987687439889505
            ]
          },
          "bbox": [
            -4.447093994371671,
            41.987687439889505,
            -4.447093994371671,
            41.987687439889505
          ]
        }
      ],
      "bbox": [
        -4.447093994371671,
        41.987687439889505,
        -4.447093994371671,
        41.987687439889505
      ]
    },
    "34099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.564574889894654,
              42.21320685576484
            ]
          },
          "bbox": [
            -4.564574889894654,
            42.21320685576484,
            -4.564574889894654,
            42.21320685576484
          ]
        }
      ],
      "bbox": [
        -4.564574889894654,
        42.21320685576484,
        -4.564574889894654,
        42.21320685576484
      ]
    },
    "34100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.857237396924412,
              42.75638142232529
            ]
          },
          "bbox": [
            -4.857237396924412,
            42.75638142232529,
            -4.857237396924412,
            42.75638142232529
          ]
        }
      ],
      "bbox": [
        -4.857237396924412,
        42.75638142232529,
        -4.857237396924412,
        42.75638142232529
      ]
    },
    "34101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.399013327350767,
              42.3236522644664
            ]
          },
          "bbox": [
            -4.399013327350767,
            42.3236522644664,
            -4.399013327350767,
            42.3236522644664
          ]
        }
      ],
      "bbox": [
        -4.399013327350767,
        42.3236522644664,
        -4.399013327350767,
        42.3236522644664
      ]
    },
    "34102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.709466477290006,
              42.04165306931738
            ]
          },
          "bbox": [
            -4.709466477290006,
            42.04165306931738,
            -4.709466477290006,
            42.04165306931738
          ]
        }
      ],
      "bbox": [
        -4.709466477290006,
        42.04165306931738,
        -4.709466477290006,
        42.04165306931738
      ]
    },
    "34103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.840035997887464,
              42.1686346169436
            ]
          },
          "bbox": [
            -4.840035997887464,
            42.1686346169436,
            -4.840035997887464,
            42.1686346169436
          ]
        }
      ],
      "bbox": [
        -4.840035997887464,
        42.1686346169436,
        -4.840035997887464,
        42.1686346169436
      ]
    },
    "34104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.266035720425304,
              42.24916867489166
            ]
          },
          "bbox": [
            -4.266035720425304,
            42.24916867489166,
            -4.266035720425304,
            42.24916867489166
          ]
        }
      ],
      "bbox": [
        -4.266035720425304,
        42.24916867489166,
        -4.266035720425304,
        42.24916867489166
      ]
    },
    "34106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.915359062267081,
              41.949575397978094
            ]
          },
          "bbox": [
            -4.915359062267081,
            41.949575397978094,
            -4.915359062267081,
            41.949575397978094
          ]
        }
      ],
      "bbox": [
        -4.915359062267081,
        41.949575397978094,
        -4.915359062267081,
        41.949575397978094
      ]
    },
    "34107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.468291369514008,
              42.6848287359676
            ]
          },
          "bbox": [
            -4.468291369514008,
            42.6848287359676,
            -4.468291369514008,
            42.6848287359676
          ]
        }
      ],
      "bbox": [
        -4.468291369514008,
        42.6848287359676,
        -4.468291369514008,
        42.6848287359676
      ]
    },
    "34108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.468476729622246,
              42.10436125396399
            ]
          },
          "bbox": [
            -4.468476729622246,
            42.10436125396399,
            -4.468476729622246,
            42.10436125396399
          ]
        }
      ],
      "bbox": [
        -4.468476729622246,
        42.10436125396399,
        -4.468476729622246,
        42.10436125396399
      ]
    },
    "34109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.935710737287279,
              42.35437752338896
            ]
          },
          "bbox": [
            -4.935710737287279,
            42.35437752338896,
            -4.935710737287279,
            42.35437752338896
          ]
        }
      ],
      "bbox": [
        -4.935710737287279,
        42.35437752338896,
        -4.935710737287279,
        42.35437752338896
      ]
    },
    "34110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.399196863323073,
              42.87807759856539
            ]
          },
          "bbox": [
            -4.399196863323073,
            42.87807759856539,
            -4.399196863323073,
            42.87807759856539
          ]
        }
      ],
      "bbox": [
        -4.399196863323073,
        42.87807759856539,
        -4.399196863323073,
        42.87807759856539
      ]
    },
    "34112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.633850134247064,
              42.38921074342219
            ]
          },
          "bbox": [
            -4.633850134247064,
            42.38921074342219,
            -4.633850134247064,
            42.38921074342219
          ]
        }
      ],
      "bbox": [
        -4.633850134247064,
        42.38921074342219,
        -4.633850134247064,
        42.38921074342219
      ]
    },
    "34113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.46282526873808,
              42.608365607239726
            ]
          },
          "bbox": [
            -4.46282526873808,
            42.608365607239726,
            -4.46282526873808,
            42.608365607239726
          ]
        }
      ],
      "bbox": [
        -4.46282526873808,
        42.608365607239726,
        -4.46282526873808,
        42.608365607239726
      ]
    },
    "34114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.466392138011743,
              42.73348171847777
            ]
          },
          "bbox": [
            -4.466392138011743,
            42.73348171847777,
            -4.466392138011743,
            42.73348171847777
          ]
        }
      ],
      "bbox": [
        -4.466392138011743,
        42.73348171847777,
        -4.466392138011743,
        42.73348171847777
      ]
    },
    "34116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.307584140114949,
              42.36744754661815
            ]
          },
          "bbox": [
            -4.307584140114949,
            42.36744754661815,
            -4.307584140114949,
            42.36744754661815
          ]
        }
      ],
      "bbox": [
        -4.307584140114949,
        42.36744754661815,
        -4.307584140114949,
        42.36744754661815
      ]
    },
    "34120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.565118758920234,
              41.98331830816808
            ]
          },
          "bbox": [
            -4.565118758920234,
            41.98331830816808,
            -4.565118758920234,
            41.98331830816808
          ]
        }
      ],
      "bbox": [
        -4.565118758920234,
        41.98331830816808,
        -4.565118758920234,
        41.98331830816808
      ]
    },
    "34121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.090409305736485,
              42.02807631781021
            ]
          },
          "bbox": [
            -4.090409305736485,
            42.02807631781021,
            -4.090409305736485,
            42.02807631781021
          ]
        }
      ],
      "bbox": [
        -4.090409305736485,
        42.02807631781021,
        -4.090409305736485,
        42.02807631781021
      ]
    },
    "34122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.390996124501571,
              42.590247008662516
            ]
          },
          "bbox": [
            -4.390996124501571,
            42.590247008662516,
            -4.390996124501571,
            42.590247008662516
          ]
        }
      ],
      "bbox": [
        -4.390996124501571,
        42.590247008662516,
        -4.390996124501571,
        42.590247008662516
      ]
    },
    "34123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6912815751939645,
              42.16820900766724
            ]
          },
          "bbox": [
            -4.6912815751939645,
            42.16820900766724,
            -4.6912815751939645,
            42.16820900766724
          ]
        }
      ],
      "bbox": [
        -4.6912815751939645,
        42.16820900766724,
        -4.6912815751939645,
        42.16820900766724
      ]
    },
    "34124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.496988273945159,
              42.72843436260963
            ]
          },
          "bbox": [
            -4.496988273945159,
            42.72843436260963,
            -4.496988273945159,
            42.72843436260963
          ]
        }
      ],
      "bbox": [
        -4.496988273945159,
        42.72843436260963,
        -4.496988273945159,
        42.72843436260963
      ]
    },
    "34125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.716988961454032,
              41.97152337920426
            ]
          },
          "bbox": [
            -4.716988961454032,
            41.97152337920426,
            -4.716988961454032,
            41.97152337920426
          ]
        }
      ],
      "bbox": [
        -4.716988961454032,
        41.97152337920426,
        -4.716988961454032,
        41.97152337920426
      ]
    },
    "34126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7479037640670585,
              42.47890699812851
            ]
          },
          "bbox": [
            -4.7479037640670585,
            42.47890699812851,
            -4.7479037640670585,
            42.47890699812851
          ]
        }
      ],
      "bbox": [
        -4.7479037640670585,
        42.47890699812851,
        -4.7479037640670585,
        42.47890699812851
      ]
    },
    "34127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.591108088039715,
              42.178537693729886
            ]
          },
          "bbox": [
            -4.591108088039715,
            42.178537693729886,
            -4.591108088039715,
            42.178537693729886
          ]
        }
      ],
      "bbox": [
        -4.591108088039715,
        42.178537693729886,
        -4.591108088039715,
        42.178537693729886
      ]
    },
    "34129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8011561958435545,
              42.641510435320136
            ]
          },
          "bbox": [
            -4.8011561958435545,
            42.641510435320136,
            -4.8011561958435545,
            42.641510435320136
          ]
        }
      ],
      "bbox": [
        -4.8011561958435545,
        42.641510435320136,
        -4.8011561958435545,
        42.641510435320136
      ]
    },
    "34130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.439940145220464,
              42.21575963534435
            ]
          },
          "bbox": [
            -4.439940145220464,
            42.21575963534435,
            -4.439940145220464,
            42.21575963534435
          ]
        }
      ],
      "bbox": [
        -4.439940145220464,
        42.21575963534435,
        -4.439940145220464,
        42.21575963534435
      ]
    },
    "34131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.876901498763375,
              42.32583372852131
            ]
          },
          "bbox": [
            -4.876901498763375,
            42.32583372852131,
            -4.876901498763375,
            42.32583372852131
          ]
        }
      ],
      "bbox": [
        -4.876901498763375,
        42.32583372852131,
        -4.876901498763375,
        42.32583372852131
      ]
    },
    "34132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.457141520455217,
              42.264960168361824
            ]
          },
          "bbox": [
            -4.457141520455217,
            42.264960168361824,
            -4.457141520455217,
            42.264960168361824
          ]
        }
      ],
      "bbox": [
        -4.457141520455217,
        42.264960168361824,
        -4.457141520455217,
        42.264960168361824
      ]
    },
    "34133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4317982632957715,
              41.791187707577464
            ]
          },
          "bbox": [
            -4.4317982632957715,
            41.791187707577464,
            -4.4317982632957715,
            41.791187707577464
          ]
        }
      ],
      "bbox": [
        -4.4317982632957715,
        41.791187707577464,
        -4.4317982632957715,
        41.791187707577464
      ]
    },
    "34134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.547795112140098,
              42.94188803686903
            ]
          },
          "bbox": [
            -4.547795112140098,
            42.94188803686903,
            -4.547795112140098,
            42.94188803686903
          ]
        }
      ],
      "bbox": [
        -4.547795112140098,
        42.94188803686903,
        -4.547795112140098,
        42.94188803686903
      ]
    },
    "34135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.169603467438019,
              42.77730794660127
            ]
          },
          "bbox": [
            -4.169603467438019,
            42.77730794660127,
            -4.169603467438019,
            42.77730794660127
          ]
        }
      ],
      "bbox": [
        -4.169603467438019,
        42.77730794660127,
        -4.169603467438019,
        42.77730794660127
      ]
    },
    "34136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.821530917349223,
              42.57905205110084
            ]
          },
          "bbox": [
            -4.821530917349223,
            42.57905205110084,
            -4.821530917349223,
            42.57905205110084
          ]
        }
      ],
      "bbox": [
        -4.821530917349223,
        42.57905205110084,
        -4.821530917349223,
        42.57905205110084
      ]
    },
    "34137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.89980173564015,
              42.25787245822575
            ]
          },
          "bbox": [
            -4.89980173564015,
            42.25787245822575,
            -4.89980173564015,
            42.25787245822575
          ]
        }
      ],
      "bbox": [
        -4.89980173564015,
        42.25787245822575,
        -4.89980173564015,
        42.25787245822575
      ]
    },
    "34139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.352989564361767,
              42.67218760857262
            ]
          },
          "bbox": [
            -4.352989564361767,
            42.67218760857262,
            -4.352989564361767,
            42.67218760857262
          ]
        }
      ],
      "bbox": [
        -4.352989564361767,
        42.67218760857262,
        -4.352989564361767,
        42.67218760857262
      ]
    },
    "34140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.593779178199619,
              42.688847414855296
            ]
          },
          "bbox": [
            -4.593779178199619,
            42.688847414855296,
            -4.593779178199619,
            42.688847414855296
          ]
        }
      ],
      "bbox": [
        -4.593779178199619,
        42.688847414855296,
        -4.593779178199619,
        42.688847414855296
      ]
    },
    "34141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.20329285537342,
              42.091602898066526
            ]
          },
          "bbox": [
            -4.20329285537342,
            42.091602898066526,
            -4.20329285537342,
            42.091602898066526
          ]
        }
      ],
      "bbox": [
        -4.20329285537342,
        42.091602898066526,
        -4.20329285537342,
        42.091602898066526
      ]
    },
    "34143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.66403187668984,
              42.47482682740073
            ]
          },
          "bbox": [
            -4.66403187668984,
            42.47482682740073,
            -4.66403187668984,
            42.47482682740073
          ]
        }
      ],
      "bbox": [
        -4.66403187668984,
        42.47482682740073,
        -4.66403187668984,
        42.47482682740073
      ]
    },
    "34146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.385927822193479,
              41.96033808486875
            ]
          },
          "bbox": [
            -4.385927822193479,
            41.96033808486875,
            -4.385927822193479,
            41.96033808486875
          ]
        }
      ],
      "bbox": [
        -4.385927822193479,
        41.96033808486875,
        -4.385927822193479,
        41.96033808486875
      ]
    },
    "34147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.706776916639377,
              42.43720666783418
            ]
          },
          "bbox": [
            -4.706776916639377,
            42.43720666783418,
            -4.706776916639377,
            42.43720666783418
          ]
        }
      ],
      "bbox": [
        -4.706776916639377,
        42.43720666783418,
        -4.706776916639377,
        42.43720666783418
      ]
    },
    "34149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.340772529772636,
              42.30667664742344
            ]
          },
          "bbox": [
            -4.340772529772636,
            42.30667664742344,
            -4.340772529772636,
            42.30667664742344
          ]
        }
      ],
      "bbox": [
        -4.340772529772636,
        42.30667664742344,
        -4.340772529772636,
        42.30667664742344
      ]
    },
    "34151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7031424324735065,
              42.74047152480609
            ]
          },
          "bbox": [
            -4.7031424324735065,
            42.74047152480609,
            -4.7031424324735065,
            42.74047152480609
          ]
        }
      ],
      "bbox": [
        -4.7031424324735065,
        42.74047152480609,
        -4.7031424324735065,
        42.74047152480609
      ]
    },
    "34152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.503606748656783,
              42.26309028763504
            ]
          },
          "bbox": [
            -4.503606748656783,
            42.26309028763504,
            -4.503606748656783,
            42.26309028763504
          ]
        }
      ],
      "bbox": [
        -4.503606748656783,
        42.26309028763504,
        -4.503606748656783,
        42.26309028763504
      ]
    },
    "34154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.522402860116549,
              42.63552046817316
            ]
          },
          "bbox": [
            -4.522402860116549,
            42.63552046817316,
            -4.522402860116549,
            42.63552046817316
          ]
        }
      ],
      "bbox": [
        -4.522402860116549,
        42.63552046817316,
        -4.522402860116549,
        42.63552046817316
      ]
    },
    "34155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.520769031216992,
              42.150470648894036
            ]
          },
          "bbox": [
            -4.520769031216992,
            42.150470648894036,
            -4.520769031216992,
            42.150470648894036
          ]
        }
      ],
      "bbox": [
        -4.520769031216992,
        42.150470648894036,
        -4.520769031216992,
        42.150470648894036
      ]
    },
    "34156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.713038438277578,
              42.282984754738486
            ]
          },
          "bbox": [
            -4.713038438277578,
            42.282984754738486,
            -4.713038438277578,
            42.282984754738486
          ]
        }
      ],
      "bbox": [
        -4.713038438277578,
        42.282984754738486,
        -4.713038438277578,
        42.282984754738486
      ]
    },
    "34157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.703539366953397,
              42.559002302839616
            ]
          },
          "bbox": [
            -4.703539366953397,
            42.559002302839616,
            -4.703539366953397,
            42.559002302839616
          ]
        }
      ],
      "bbox": [
        -4.703539366953397,
        42.559002302839616,
        -4.703539366953397,
        42.559002302839616
      ]
    },
    "34158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.369419801949658,
              42.85183719722913
            ]
          },
          "bbox": [
            -4.369419801949658,
            42.85183719722913,
            -4.369419801949658,
            42.85183719722913
          ]
        }
      ],
      "bbox": [
        -4.369419801949658,
        42.85183719722913,
        -4.369419801949658,
        42.85183719722913
      ]
    },
    "34159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.526287280694792,
              42.19959028821466
            ]
          },
          "bbox": [
            -4.526287280694792,
            42.19959028821466,
            -4.526287280694792,
            42.19959028821466
          ]
        }
      ],
      "bbox": [
        -4.526287280694792,
        42.19959028821466,
        -4.526287280694792,
        42.19959028821466
      ]
    },
    "34160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.385397801506848,
              42.90864031057308
            ]
          },
          "bbox": [
            -4.385397801506848,
            42.90864031057308,
            -4.385397801506848,
            42.90864031057308
          ]
        }
      ],
      "bbox": [
        -4.385397801506848,
        42.90864031057308,
        -4.385397801506848,
        42.90864031057308
      ]
    },
    "34161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.350442819488714,
              42.54067258944769
            ]
          },
          "bbox": [
            -4.350442819488714,
            42.54067258944769,
            -4.350442819488714,
            42.54067258944769
          ]
        }
      ],
      "bbox": [
        -4.350442819488714,
        42.54067258944769,
        -4.350442819488714,
        42.54067258944769
      ]
    },
    "34163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.545404163780763,
              42.357492606293484
            ]
          },
          "bbox": [
            -4.545404163780763,
            42.357492606293484,
            -4.545404163780763,
            42.357492606293484
          ]
        }
      ],
      "bbox": [
        -4.545404163780763,
        42.357492606293484,
        -4.545404163780763,
        42.357492606293484
      ]
    },
    "34165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.854295830526242,
              42.2650350670409
            ]
          },
          "bbox": [
            -4.854295830526242,
            42.2650350670409,
            -4.854295830526242,
            42.2650350670409
          ]
        }
      ],
      "bbox": [
        -4.854295830526242,
        42.2650350670409,
        -4.854295830526242,
        42.2650350670409
      ]
    },
    "34167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.663356402074891,
              41.92401669195723
            ]
          },
          "bbox": [
            -4.663356402074891,
            41.92401669195723,
            -4.663356402074891,
            41.92401669195723
          ]
        }
      ],
      "bbox": [
        -4.663356402074891,
        41.92401669195723,
        -4.663356402074891,
        41.92401669195723
      ]
    },
    "34168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.348551458603918,
              42.515374857936635
            ]
          },
          "bbox": [
            -4.348551458603918,
            42.515374857936635,
            -4.348551458603918,
            42.515374857936635
          ]
        }
      ],
      "bbox": [
        -4.348551458603918,
        42.515374857936635,
        -4.348551458603918,
        42.515374857936635
      ]
    },
    "34169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.835947680799667,
              42.49734944829941
            ]
          },
          "bbox": [
            -4.835947680799667,
            42.49734944829941,
            -4.835947680799667,
            42.49734944829941
          ]
        }
      ],
      "bbox": [
        -4.835947680799667,
        42.49734944829941,
        -4.835947680799667,
        42.49734944829941
      ]
    },
    "34170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.367074484106131,
              42.719146525088135
            ]
          },
          "bbox": [
            -4.367074484106131,
            42.719146525088135,
            -4.367074484106131,
            42.719146525088135
          ]
        }
      ],
      "bbox": [
        -4.367074484106131,
        42.719146525088135,
        -4.367074484106131,
        42.719146525088135
      ]
    },
    "34171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.724809728932041,
              42.80595375415688
            ]
          },
          "bbox": [
            -4.724809728932041,
            42.80595375415688,
            -4.724809728932041,
            42.80595375415688
          ]
        }
      ],
      "bbox": [
        -4.724809728932041,
        42.80595375415688,
        -4.724809728932041,
        42.80595375415688
      ]
    },
    "34174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.347065284978119,
              42.220568483567966
            ]
          },
          "bbox": [
            -4.347065284978119,
            42.220568483567966,
            -4.347065284978119,
            42.220568483567966
          ]
        }
      ],
      "bbox": [
        -4.347065284978119,
        42.220568483567966,
        -4.347065284978119,
        42.220568483567966
      ]
    },
    "34175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.658066597383911,
              42.41741371619454
            ]
          },
          "bbox": [
            -4.658066597383911,
            42.41741371619454,
            -4.658066597383911,
            42.41741371619454
          ]
        }
      ],
      "bbox": [
        -4.658066597383911,
        42.41741371619454,
        -4.658066597383911,
        42.41741371619454
      ]
    },
    "34176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.446195440529826,
              42.59521123596548
            ]
          },
          "bbox": [
            -4.446195440529826,
            42.59521123596548,
            -4.446195440529826,
            42.59521123596548
          ]
        }
      ],
      "bbox": [
        -4.446195440529826,
        42.59521123596548,
        -4.446195440529826,
        42.59521123596548
      ]
    },
    "34177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.426405186242398,
              41.94534875122893
            ]
          },
          "bbox": [
            -4.426405186242398,
            41.94534875122893,
            -4.426405186242398,
            41.94534875122893
          ]
        }
      ],
      "bbox": [
        -4.426405186242398,
        41.94534875122893,
        -4.426405186242398,
        41.94534875122893
      ]
    },
    "34178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.106695866495957,
              42.01590328854355
            ]
          },
          "bbox": [
            -4.106695866495957,
            42.01590328854355,
            -4.106695866495957,
            42.01590328854355
          ]
        }
      ],
      "bbox": [
        -4.106695866495957,
        42.01590328854355,
        -4.106695866495957,
        42.01590328854355
      ]
    },
    "34179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.708297794370318,
              42.65459266632897
            ]
          },
          "bbox": [
            -4.708297794370318,
            42.65459266632897,
            -4.708297794370318,
            42.65459266632897
          ]
        }
      ],
      "bbox": [
        -4.708297794370318,
        42.65459266632897,
        -4.708297794370318,
        42.65459266632897
      ]
    },
    "34180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.404051636575401,
              42.19960880560401
            ]
          },
          "bbox": [
            -4.404051636575401,
            42.19960880560401,
            -4.404051636575401,
            42.19960880560401
          ]
        }
      ],
      "bbox": [
        -4.404051636575401,
        42.19960880560401,
        -4.404051636575401,
        42.19960880560401
      ]
    },
    "34181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.474001496409258,
              41.888534321588395
            ]
          },
          "bbox": [
            -4.474001496409258,
            41.888534321588395,
            -4.474001496409258,
            41.888534321588395
          ]
        }
      ],
      "bbox": [
        -4.474001496409258,
        41.888534321588395,
        -4.474001496409258,
        41.888534321588395
      ]
    },
    "34182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.300014022243881,
              42.039455224229805
            ]
          },
          "bbox": [
            -4.300014022243881,
            42.039455224229805,
            -4.300014022243881,
            42.039455224229805
          ]
        }
      ],
      "bbox": [
        -4.300014022243881,
        42.039455224229805,
        -4.300014022243881,
        42.039455224229805
      ]
    },
    "34184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.788070922765108,
              41.9648678577357
            ]
          },
          "bbox": [
            -4.788070922765108,
            41.9648678577357,
            -4.788070922765108,
            41.9648678577357
          ]
        }
      ],
      "bbox": [
        -4.788070922765108,
        41.9648678577357,
        -4.788070922765108,
        41.9648678577357
      ]
    },
    "34185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.679333973224502,
              42.931900858547756
            ]
          },
          "bbox": [
            -4.679333973224502,
            42.931900858547756,
            -4.679333973224502,
            42.931900858547756
          ]
        }
      ],
      "bbox": [
        -4.679333973224502,
        42.931900858547756,
        -4.679333973224502,
        42.931900858547756
      ]
    },
    "34186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.223021915287923,
              42.15271306048236
            ]
          },
          "bbox": [
            -4.223021915287923,
            42.15271306048236,
            -4.223021915287923,
            42.15271306048236
          ]
        }
      ],
      "bbox": [
        -4.223021915287923,
        42.15271306048236,
        -4.223021915287923,
        42.15271306048236
      ]
    },
    "34189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.416353402269028,
              42.04639494561832
            ]
          },
          "bbox": [
            -4.416353402269028,
            42.04639494561832,
            -4.416353402269028,
            42.04639494561832
          ]
        }
      ],
      "bbox": [
        -4.416353402269028,
        42.04639494561832,
        -4.416353402269028,
        42.04639494561832
      ]
    },
    "34190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.652561133909535,
              42.60068989003502
            ]
          },
          "bbox": [
            -4.652561133909535,
            42.60068989003502,
            -4.652561133909535,
            42.60068989003502
          ]
        }
      ],
      "bbox": [
        -4.652561133909535,
        42.60068989003502,
        -4.652561133909535,
        42.60068989003502
      ]
    },
    "34192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.575163789386693,
              42.398680026405415
            ]
          },
          "bbox": [
            -4.575163789386693,
            42.398680026405415,
            -4.575163789386693,
            42.398680026405415
          ]
        }
      ],
      "bbox": [
        -4.575163789386693,
        42.398680026405415,
        -4.575163789386693,
        42.398680026405415
      ]
    },
    "34196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.353318906544326,
              41.893896132254234
            ]
          },
          "bbox": [
            -4.353318906544326,
            41.893896132254234,
            -4.353318906544326,
            41.893896132254234
          ]
        }
      ],
      "bbox": [
        -4.353318906544326,
        41.893896132254234,
        -4.353318906544326,
        41.893896132254234
      ]
    },
    "34199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.782686767288327,
              42.899582547492074
            ]
          },
          "bbox": [
            -4.782686767288327,
            42.899582547492074,
            -4.782686767288327,
            42.899582547492074
          ]
        }
      ],
      "bbox": [
        -4.782686767288327,
        42.899582547492074,
        -4.782686767288327,
        42.899582547492074
      ]
    },
    "34201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.306726763822147,
              41.81810227780431
            ]
          },
          "bbox": [
            -4.306726763822147,
            41.81810227780431,
            -4.306726763822147,
            41.81810227780431
          ]
        }
      ],
      "bbox": [
        -4.306726763822147,
        41.81810227780431,
        -4.306726763822147,
        41.81810227780431
      ]
    },
    "34202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.615349287886491,
              42.55584967839297
            ]
          },
          "bbox": [
            -4.615349287886491,
            42.55584967839297,
            -4.615349287886491,
            42.55584967839297
          ]
        }
      ],
      "bbox": [
        -4.615349287886491,
        42.55584967839297,
        -4.615349287886491,
        42.55584967839297
      ]
    },
    "34204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9782193880796335,
              42.216773957376056
            ]
          },
          "bbox": [
            -4.9782193880796335,
            42.216773957376056,
            -4.9782193880796335,
            42.216773957376056
          ]
        }
      ],
      "bbox": [
        -4.9782193880796335,
        42.216773957376056,
        -4.9782193880796335,
        42.216773957376056
      ]
    },
    "34205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2134469455971075,
              41.868443497424835
            ]
          },
          "bbox": [
            -4.2134469455971075,
            41.868443497424835,
            -4.2134469455971075,
            41.868443497424835
          ]
        }
      ],
      "bbox": [
        -4.2134469455971075,
        41.868443497424835,
        -4.2134469455971075,
        41.868443497424835
      ]
    },
    "34206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.956537449348776,
              42.268575292746476
            ]
          },
          "bbox": [
            -4.956537449348776,
            42.268575292746476,
            -4.956537449348776,
            42.268575292746476
          ]
        }
      ],
      "bbox": [
        -4.956537449348776,
        42.268575292746476,
        -4.956537449348776,
        42.268575292746476
      ]
    },
    "34208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.558147378245784,
              42.57604772830542
            ]
          },
          "bbox": [
            -4.558147378245784,
            42.57604772830542,
            -4.558147378245784,
            42.57604772830542
          ]
        }
      ],
      "bbox": [
        -4.558147378245784,
        42.57604772830542,
        -4.558147378245784,
        42.57604772830542
      ]
    },
    "34210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1489339158115115,
              42.05451744921304
            ]
          },
          "bbox": [
            -4.1489339158115115,
            42.05451744921304,
            -4.1489339158115115,
            42.05451744921304
          ]
        }
      ],
      "bbox": [
        -4.1489339158115115,
        42.05451744921304,
        -4.1489339158115115,
        42.05451744921304
      ]
    },
    "34211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.468678230225512,
              42.38772795061197
            ]
          },
          "bbox": [
            -4.468678230225512,
            42.38772795061197,
            -4.468678230225512,
            42.38772795061197
          ]
        }
      ],
      "bbox": [
        -4.468678230225512,
        42.38772795061197,
        -4.468678230225512,
        42.38772795061197
      ]
    },
    "34213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.269881800324468,
              42.14227399387569
            ]
          },
          "bbox": [
            -4.269881800324468,
            42.14227399387569,
            -4.269881800324468,
            42.14227399387569
          ]
        }
      ],
      "bbox": [
        -4.269881800324468,
        42.14227399387569,
        -4.269881800324468,
        42.14227399387569
      ]
    },
    "34214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.841117874419211,
              42.72306133826057
            ]
          },
          "bbox": [
            -4.841117874419211,
            42.72306133826057,
            -4.841117874419211,
            42.72306133826057
          ]
        }
      ],
      "bbox": [
        -4.841117874419211,
        42.72306133826057,
        -4.841117874419211,
        42.72306133826057
      ]
    },
    "34215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.54056350260597,
              42.31690654466072
            ]
          },
          "bbox": [
            -4.54056350260597,
            42.31690654466072,
            -4.54056350260597,
            42.31690654466072
          ]
        }
      ],
      "bbox": [
        -4.54056350260597,
        42.31690654466072,
        -4.54056350260597,
        42.31690654466072
      ]
    },
    "34216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.859776260641942,
              42.295254653613604
            ]
          },
          "bbox": [
            -4.859776260641942,
            42.295254653613604,
            -4.859776260641942,
            42.295254653613604
          ]
        }
      ],
      "bbox": [
        -4.859776260641942,
        42.295254653613604,
        -4.859776260641942,
        42.295254653613604
      ]
    },
    "34217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.481736890043791,
              42.025509531028945
            ]
          },
          "bbox": [
            -4.481736890043791,
            42.025509531028945,
            -4.481736890043791,
            42.025509531028945
          ]
        }
      ],
      "bbox": [
        -4.481736890043791,
        42.025509531028945,
        -4.481736890043791,
        42.025509531028945
      ]
    },
    "34218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.776575357618558,
              42.52568692010888
            ]
          },
          "bbox": [
            -4.776575357618558,
            42.52568692010888,
            -4.776575357618558,
            42.52568692010888
          ]
        }
      ],
      "bbox": [
        -4.776575357618558,
        42.52568692010888,
        -4.776575357618558,
        42.52568692010888
      ]
    },
    "34220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6793803303560315,
              42.00996075756438
            ]
          },
          "bbox": [
            -4.6793803303560315,
            42.00996075756438,
            -4.6793803303560315,
            42.00996075756438
          ]
        }
      ],
      "bbox": [
        -4.6793803303560315,
        42.00996075756438,
        -4.6793803303560315,
        42.00996075756438
      ]
    },
    "34221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.37625417184911,
              42.040637375872045
            ]
          },
          "bbox": [
            -4.37625417184911,
            42.040637375872045,
            -4.37625417184911,
            42.040637375872045
          ]
        }
      ],
      "bbox": [
        -4.37625417184911,
        42.040637375872045,
        -4.37625417184911,
        42.040637375872045
      ]
    },
    "34222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.464615673217144,
              42.5358493576951
            ]
          },
          "bbox": [
            -4.464615673217144,
            42.5358493576951,
            -4.464615673217144,
            42.5358493576951
          ]
        }
      ],
      "bbox": [
        -4.464615673217144,
        42.5358493576951,
        -4.464615673217144,
        42.5358493576951
      ]
    },
    "34223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.704940202973841,
              42.39933879886379
            ]
          },
          "bbox": [
            -4.704940202973841,
            42.39933879886379,
            -4.704940202973841,
            42.39933879886379
          ]
        }
      ],
      "bbox": [
        -4.704940202973841,
        42.39933879886379,
        -4.704940202973841,
        42.39933879886379
      ]
    },
    "34224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.67150447324223,
              42.25278407383189
            ]
          },
          "bbox": [
            -4.67150447324223,
            42.25278407383189,
            -4.67150447324223,
            42.25278407383189
          ]
        }
      ],
      "bbox": [
        -4.67150447324223,
        42.25278407383189,
        -4.67150447324223,
        42.25278407383189
      ]
    },
    "34225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.508138364815309,
              41.95191620692487
            ]
          },
          "bbox": [
            -4.508138364815309,
            41.95191620692487,
            -4.508138364815309,
            41.95191620692487
          ]
        }
      ],
      "bbox": [
        -4.508138364815309,
        41.95191620692487,
        -4.508138364815309,
        41.95191620692487
      ]
    },
    "34227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.736182790366402,
              42.24042244014843
            ]
          },
          "bbox": [
            -4.736182790366402,
            42.24042244014843,
            -4.736182790366402,
            42.24042244014843
          ]
        }
      ],
      "bbox": [
        -4.736182790366402,
        42.24042244014843,
        -4.736182790366402,
        42.24042244014843
      ]
    },
    "34228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.523853182240609,
              42.515157432795455
            ]
          },
          "bbox": [
            -4.523853182240609,
            42.515157432795455,
            -4.523853182240609,
            42.515157432795455
          ]
        }
      ],
      "bbox": [
        -4.523853182240609,
        42.515157432795455,
        -4.523853182240609,
        42.515157432795455
      ]
    },
    "34229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.407821108757091,
              42.51625163085198
            ]
          },
          "bbox": [
            -4.407821108757091,
            42.51625163085198,
            -4.407821108757091,
            42.51625163085198
          ]
        }
      ],
      "bbox": [
        -4.407821108757091,
        42.51625163085198,
        -4.407821108757091,
        42.51625163085198
      ]
    },
    "34230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.515761560843555,
              42.291263652117465
            ]
          },
          "bbox": [
            -4.515761560843555,
            42.291263652117465,
            -4.515761560843555,
            42.291263652117465
          ]
        }
      ],
      "bbox": [
        -4.515761560843555,
        42.291263652117465,
        -4.515761560843555,
        42.291263652117465
      ]
    },
    "34231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8022741940000735,
              42.41634101028726
            ]
          },
          "bbox": [
            -4.8022741940000735,
            42.41634101028726,
            -4.8022741940000735,
            42.41634101028726
          ]
        }
      ],
      "bbox": [
        -4.8022741940000735,
        42.41634101028726,
        -4.8022741940000735,
        42.41634101028726
      ]
    },
    "34232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9119377602646574,
              42.04784448116833
            ]
          },
          "bbox": [
            -4.9119377602646574,
            42.04784448116833,
            -4.9119377602646574,
            42.04784448116833
          ]
        }
      ],
      "bbox": [
        -4.9119377602646574,
        42.04784448116833,
        -4.9119377602646574,
        42.04784448116833
      ]
    },
    "34233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.509915680001376,
              42.40740224812593
            ]
          },
          "bbox": [
            -4.509915680001376,
            42.40740224812593,
            -4.509915680001376,
            42.40740224812593
          ]
        }
      ],
      "bbox": [
        -4.509915680001376,
        42.40740224812593,
        -4.509915680001376,
        42.40740224812593
      ]
    },
    "34234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.550737965935801,
              42.54466760626017
            ]
          },
          "bbox": [
            -4.550737965935801,
            42.54466760626017,
            -4.550737965935801,
            42.54466760626017
          ]
        }
      ],
      "bbox": [
        -4.550737965935801,
        42.54466760626017,
        -4.550737965935801,
        42.54466760626017
      ]
    },
    "34236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6865471954092035,
              42.371181743896564
            ]
          },
          "bbox": [
            -4.6865471954092035,
            42.371181743896564,
            -4.6865471954092035,
            42.371181743896564
          ]
        }
      ],
      "bbox": [
        -4.6865471954092035,
        42.371181743896564,
        -4.6865471954092035,
        42.371181743896564
      ]
    },
    "34237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.614329656990103,
              42.07499220127265
            ]
          },
          "bbox": [
            -4.614329656990103,
            42.07499220127265,
            -4.614329656990103,
            42.07499220127265
          ]
        }
      ],
      "bbox": [
        -4.614329656990103,
        42.07499220127265,
        -4.614329656990103,
        42.07499220127265
      ]
    },
    "34238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.328985278240529,
              41.95561396864887
            ]
          },
          "bbox": [
            -4.328985278240529,
            41.95561396864887,
            -4.328985278240529,
            41.95561396864887
          ]
        }
      ],
      "bbox": [
        -4.328985278240529,
        41.95561396864887,
        -4.328985278240529,
        41.95561396864887
      ]
    },
    "34240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.857209867355935,
              41.94865337539629
            ]
          },
          "bbox": [
            -4.857209867355935,
            41.94865337539629,
            -4.857209867355935,
            41.94865337539629
          ]
        }
      ],
      "bbox": [
        -4.857209867355935,
        41.94865337539629,
        -4.857209867355935,
        41.94865337539629
      ]
    },
    "34241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.249952171312121,
              42.21391249534556
            ]
          },
          "bbox": [
            -4.249952171312121,
            42.21391249534556,
            -4.249952171312121,
            42.21391249534556
          ]
        }
      ],
      "bbox": [
        -4.249952171312121,
        42.21391249534556,
        -4.249952171312121,
        42.21391249534556
      ]
    },
    "34242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.08628563026919,
              42.14989679171591
            ]
          },
          "bbox": [
            -4.08628563026919,
            42.14989679171591,
            -4.08628563026919,
            42.14989679171591
          ]
        }
      ],
      "bbox": [
        -4.08628563026919,
        42.14989679171591,
        -4.08628563026919,
        42.14989679171591
      ]
    },
    "34243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5982636568324535,
              42.25575789798529
            ]
          },
          "bbox": [
            -4.5982636568324535,
            42.25575789798529,
            -4.5982636568324535,
            42.25575789798529
          ]
        }
      ],
      "bbox": [
        -4.5982636568324535,
        42.25575789798529,
        -4.5982636568324535,
        42.25575789798529
      ]
    },
    "34245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8632251147686985,
              42.58600149217576
            ]
          },
          "bbox": [
            -4.8632251147686985,
            42.58600149217576,
            -4.8632251147686985,
            42.58600149217576
          ]
        }
      ],
      "bbox": [
        -4.8632251147686985,
        42.58600149217576,
        -4.8632251147686985,
        42.58600149217576
      ]
    },
    "34246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4642385977558785,
              42.31041162915524
            ]
          },
          "bbox": [
            -4.4642385977558785,
            42.31041162915524,
            -4.4642385977558785,
            42.31041162915524
          ]
        }
      ],
      "bbox": [
        -4.4642385977558785,
        42.31041162915524,
        -4.4642385977558785,
        42.31041162915524
      ]
    },
    "34901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.376007790312517,
              42.38899459357899
            ]
          },
          "bbox": [
            -4.376007790312517,
            42.38899459357899,
            -4.376007790312517,
            42.38899459357899
          ]
        }
      ],
      "bbox": [
        -4.376007790312517,
        42.38899459357899,
        -4.376007790312517,
        42.38899459357899
      ]
    },
    "34902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7837667935540535,
              42.21706508164375
            ]
          },
          "bbox": [
            -4.7837667935540535,
            42.21706508164375,
            -4.7837667935540535,
            42.21706508164375
          ]
        }
      ],
      "bbox": [
        -4.7837667935540535,
        42.21706508164375,
        -4.7837667935540535,
        42.21706508164375
      ]
    },
    "34903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.580622788576089,
              42.46413532392217
            ]
          },
          "bbox": [
            -4.580622788576089,
            42.46413532392217,
            -4.580622788576089,
            42.46413532392217
          ]
        }
      ],
      "bbox": [
        -4.580622788576089,
        42.46413532392217,
        -4.580622788576089,
        42.46413532392217
      ]
    },
    "34904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.495051660182286,
              43.00082955006588
            ]
          },
          "bbox": [
            -4.495051660182286,
            43.00082955006588,
            -4.495051660182286,
            43.00082955006588
          ]
        }
      ],
      "bbox": [
        -4.495051660182286,
        43.00082955006588,
        -4.495051660182286,
        43.00082955006588
      ]
    },
    "35001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.698297803292595,
              28.06931323245381
            ]
          },
          "bbox": [
            -15.698297803292595,
            28.06931323245381,
            -15.698297803292595,
            28.06931323245381
          ]
        }
      ],
      "bbox": [
        -15.698297803292595,
        28.06931323245381,
        -15.698297803292595,
        28.06931323245381
      ]
    },
    "35002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.457233120804403,
              27.895045691721787
            ]
          },
          "bbox": [
            -15.457233120804403,
            27.895045691721787,
            -15.457233120804403,
            27.895045691721787
          ]
        }
      ],
      "bbox": [
        -15.457233120804403,
        27.895045691721787,
        -15.457233120804403,
        27.895045691721787
      ]
    },
    "35003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.95024940093029,
              28.377652746368952
            ]
          },
          "bbox": [
            -13.95024940093029,
            28.377652746368952,
            -13.95024940093029,
            28.377652746368952
          ]
        }
      ],
      "bbox": [
        -13.95024940093029,
        28.377652746368952,
        -13.95024940093029,
        28.377652746368952
      ]
    },
    "35004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.56230117441221,
              28.978233334587514
            ]
          },
          "bbox": [
            -13.56230117441221,
            28.978233334587514,
            -13.56230117441221,
            28.978233334587514
          ]
        }
      ],
      "bbox": [
        -13.56230117441221,
        28.978233334587514,
        -13.56230117441221,
        28.978233334587514
      ]
    },
    "35005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.697130270924843,
              28.018933598218123
            ]
          },
          "bbox": [
            -15.697130270924843,
            28.018933598218123,
            -15.697130270924843,
            28.018933598218123
          ]
        }
      ],
      "bbox": [
        -15.697130270924843,
        28.018933598218123,
        -15.697130270924843,
        28.018933598218123
      ]
    },
    "35006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.522563866849417,
              28.120287441739638
            ]
          },
          "bbox": [
            -15.522563866849417,
            28.120287441739638,
            -15.522563866849417,
            28.120287441739638
          ]
        }
      ],
      "bbox": [
        -15.522563866849417,
        28.120287441739638,
        -15.522563866849417,
        28.120287441739638
      ]
    },
    "35007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -14.087109838236039,
              28.43440041209544
            ]
          },
          "bbox": [
            -14.087109838236039,
            28.43440041209544,
            -14.087109838236039,
            28.43440041209544
          ]
        }
      ],
      "bbox": [
        -14.087109838236039,
        28.43440041209544,
        -14.087109838236039,
        28.43440041209544
      ]
    },
    "35008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.558334257225113,
              28.107682328731208
            ]
          },
          "bbox": [
            -15.558334257225113,
            28.107682328731208,
            -15.558334257225113,
            28.107682328731208
          ]
        }
      ],
      "bbox": [
        -15.558334257225113,
        28.107682328731208,
        -15.558334257225113,
        28.107682328731208
      ]
    },
    "35009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.661103974600517,
              28.109255251762576
            ]
          },
          "bbox": [
            -15.661103974600517,
            28.109255251762576,
            -15.661103974600517,
            28.109255251762576
          ]
        }
      ],
      "bbox": [
        -15.661103974600517,
        28.109255251762576,
        -15.661103974600517,
        28.109255251762576
      ]
    },
    "35010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.475318123538617,
              29.16198180346445
            ]
          },
          "bbox": [
            -13.475318123538617,
            29.16198180346445,
            -13.475318123538617,
            29.16198180346445
          ]
        }
      ],
      "bbox": [
        -13.475318123538617,
        29.16198180346445,
        -13.475318123538617,
        29.16198180346445
      ]
    },
    "35011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.44352588257041,
              27.930752892738568
            ]
          },
          "bbox": [
            -15.44352588257041,
            27.930752892738568,
            -15.44352588257041,
            27.930752892738568
          ]
        }
      ],
      "bbox": [
        -15.44352588257041,
        27.930752892738568,
        -15.44352588257041,
        27.930752892738568
      ]
    },
    "35012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.716948039655097,
              27.857638279267896
            ]
          },
          "bbox": [
            -15.716948039655097,
            27.857638279267896,
            -15.716948039655097,
            27.857638279267896
          ]
        }
      ],
      "bbox": [
        -15.716948039655097,
        27.857638279267896,
        -15.716948039655097,
        27.857638279267896
      ]
    },
    "35013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.592594072149902,
              28.087676724917678
            ]
          },
          "bbox": [
            -15.592594072149902,
            28.087676724917678,
            -15.592594072149902,
            28.087676724917678
          ]
        }
      ],
      "bbox": [
        -15.592594072149902,
        28.087676724917678,
        -15.592594072149902,
        28.087676724917678
      ]
    },
    "35014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.924962093071027,
              28.652154900752993
            ]
          },
          "bbox": [
            -13.924962093071027,
            28.652154900752993,
            -13.924962093071027,
            28.652154900752993
          ]
        }
      ],
      "bbox": [
        -13.924962093071027,
        28.652154900752993,
        -13.924962093071027,
        28.652154900752993
      ]
    },
    "35015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -14.241064859756003,
              28.205120344419516
            ]
          },
          "bbox": [
            -14.241064859756003,
            28.205120344419516,
            -14.241064859756003,
            28.205120344419516
          ]
        }
      ],
      "bbox": [
        -14.241064859756003,
        28.205120344419516,
        -14.241064859756003,
        28.205120344419516
      ]
    },
    "35016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.454774726986095,
              28.091594020452977
            ]
          },
          "bbox": [
            -15.454774726986095,
            28.091594020452977,
            -15.454774726986095,
            28.091594020452977
          ]
        }
      ],
      "bbox": [
        -15.454774726986095,
        28.091594020452977,
        -15.454774726986095,
        28.091594020452977
      ]
    },
    "35017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.950523054038724,
              28.51070725631435
            ]
          },
          "bbox": [
            -13.950523054038724,
            28.51070725631435,
            -13.950523054038724,
            28.51070725631435
          ]
        }
      ],
      "bbox": [
        -13.950523054038724,
        28.51070725631435,
        -13.950523054038724,
        28.51070725631435
      ]
    },
    "35018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.615620094756018,
              28.990080006107913
            ]
          },
          "bbox": [
            -13.615620094756018,
            28.990080006107913,
            -13.615620094756018,
            28.990080006107913
          ]
        }
      ],
      "bbox": [
        -13.615620094756018,
        28.990080006107913,
        -13.615620094756018,
        28.990080006107913
      ]
    },
    "35019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.582867144787816,
              27.840877114659616
            ]
          },
          "bbox": [
            -15.582867144787816,
            27.840877114659616,
            -15.582867144787816,
            27.840877114659616
          ]
        }
      ],
      "bbox": [
        -15.582867144787816,
        27.840877114659616,
        -15.582867144787816,
        27.840877114659616
      ]
    },
    "35020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.787457482450083,
              27.949633462632004
            ]
          },
          "bbox": [
            -15.787457482450083,
            27.949633462632004,
            -15.787457482450083,
            27.949633462632004
          ]
        }
      ],
      "bbox": [
        -15.787457482450083,
        27.949633462632004,
        -15.787457482450083,
        27.949633462632004
      ]
    },
    "35021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.495217814828893,
              28.033469575446276
            ]
          },
          "bbox": [
            -15.495217814828893,
            28.033469575446276,
            -15.495217814828893,
            28.033469575446276
          ]
        }
      ],
      "bbox": [
        -15.495217814828893,
        28.033469575446276,
        -15.495217814828893,
        28.033469575446276
      ]
    },
    "35022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.488655402842255,
              27.86978079692709
            ]
          },
          "bbox": [
            -15.488655402842255,
            27.86978079692709,
            -15.488655402842255,
            27.86978079692709
          ]
        }
      ],
      "bbox": [
        -15.488655402842255,
        27.86978079692709,
        -15.488655402842255,
        27.86978079692709
      ]
    },
    "35023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.618351170852044,
              28.11606270796639
            ]
          },
          "bbox": [
            -15.618351170852044,
            28.11606270796639,
            -15.618351170852044,
            28.11606270796639
          ]
        }
      ],
      "bbox": [
        -15.618351170852044,
        28.11606270796639,
        -15.618351170852044,
        28.11606270796639
      ]
    },
    "35024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.547408488578032,
              29.094828203879718
            ]
          },
          "bbox": [
            -13.547408488578032,
            29.094828203879718,
            -13.547408488578032,
            29.094828203879718
          ]
        }
      ],
      "bbox": [
        -13.547408488578032,
        29.094828203879718,
        -13.547408488578032,
        29.094828203879718
      ]
    },
    "35025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.659793783596772,
              27.968039317765438
            ]
          },
          "bbox": [
            -15.659793783596772,
            27.968039317765438,
            -15.659793783596772,
            27.968039317765438
          ]
        }
      ],
      "bbox": [
        -15.659793783596772,
        27.968039317765438,
        -15.659793783596772,
        27.968039317765438
      ]
    },
    "35026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.429344940040028,
              27.983162997172517
            ]
          },
          "bbox": [
            -15.429344940040028,
            27.983162997172517,
            -15.429344940040028,
            27.983162997172517
          ]
        }
      ],
      "bbox": [
        -15.429344940040028,
        27.983162997172517,
        -15.429344940040028,
        27.983162997172517
      ]
    },
    "35027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.540299673188866,
              28.059975398046895
            ]
          },
          "bbox": [
            -15.540299673188866,
            28.059975398046895,
            -15.540299673188866,
            28.059975398046895
          ]
        }
      ],
      "bbox": [
        -15.540299673188866,
        28.059975398046895,
        -15.540299673188866,
        28.059975398046895
      ]
    },
    "35028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.662104192461706,
              28.95607791246065
            ]
          },
          "bbox": [
            -13.662104192461706,
            28.95607791246065,
            -13.662104192461706,
            28.95607791246065
          ]
        }
      ],
      "bbox": [
        -13.662104192461706,
        28.95607791246065,
        -13.662104192461706,
        28.95607791246065
      ]
    },
    "35029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.708258919505248,
              29.04547332464321
            ]
          },
          "bbox": [
            -13.708258919505248,
            29.04547332464321,
            -13.708258919505248,
            29.04547332464321
          ]
        }
      ],
      "bbox": [
        -13.708258919505248,
        29.04547332464321,
        -13.708258919505248,
        29.04547332464321
      ]
    },
    "35030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -14.044311370601346,
              28.272075563153827
            ]
          },
          "bbox": [
            -14.044311370601346,
            28.272075563153827,
            -14.044311370601346,
            28.272075563153827
          ]
        }
      ],
      "bbox": [
        -14.044311370601346,
        28.272075563153827,
        -14.044311370601346,
        28.272075563153827
      ]
    },
    "35031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.508976079991927,
              27.98434214945877
            ]
          },
          "bbox": [
            -15.508976079991927,
            27.98434214945877,
            -15.508976079991927,
            27.98434214945877
          ]
        }
      ],
      "bbox": [
        -15.508976079991927,
        27.98434214945877,
        -15.508976079991927,
        27.98434214945877
      ]
    },
    "35032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.583143867426793,
              28.044117813740648
            ]
          },
          "bbox": [
            -15.583143867426793,
            28.044117813740648,
            -15.583143867426793,
            28.044117813740648
          ]
        }
      ],
      "bbox": [
        -15.583143867426793,
        28.044117813740648,
        -15.583143867426793,
        28.044117813740648
      ]
    },
    "35033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -15.55936204270175,
              28.00015908416547
            ]
          },
          "bbox": [
            -15.55936204270175,
            28.00015908416547,
            -15.55936204270175,
            28.00015908416547
          ]
        }
      ],
      "bbox": [
        -15.55936204270175,
        28.00015908416547,
        -15.55936204270175,
        28.00015908416547
      ]
    },
    "35034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -13.785126419759026,
              28.93509387242423
            ]
          },
          "bbox": [
            -13.785126419759026,
            28.93509387242423,
            -13.785126419759026,
            28.93509387242423
          ]
        }
      ],
      "bbox": [
        -13.785126419759026,
        28.93509387242423,
        -13.785126419759026,
        28.93509387242423
      ]
    },
    "36001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.319012889490779,
              42.12332590214065
            ]
          },
          "bbox": [
            -8.319012889490779,
            42.12332590214065,
            -8.319012889490779,
            42.12332590214065
          ]
        }
      ],
      "bbox": [
        -8.319012889490779,
        42.12332590214065,
        -8.319012889490779,
        42.12332590214065
      ]
    },
    "36002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.645152847668879,
              42.52524856354738
            ]
          },
          "bbox": [
            -8.645152847668879,
            42.52524856354738,
            -8.645152847668879,
            42.52524856354738
          ]
        }
      ],
      "bbox": [
        -8.645152847668879,
        42.52524856354738,
        -8.645152847668879,
        42.52524856354738
      ]
    },
    "36003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.844290982548356,
              42.09196420545695
            ]
          },
          "bbox": [
            -8.844290982548356,
            42.09196420545695,
            -8.844290982548356,
            42.09196420545695
          ]
        }
      ],
      "bbox": [
        -8.844290982548356,
        42.09196420545695,
        -8.844290982548356,
        42.09196420545695
      ]
    },
    "36004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.803093905281484,
              42.3272351569447
            ]
          },
          "bbox": [
            -8.803093905281484,
            42.3272351569447,
            -8.803093905281484,
            42.3272351569447
          ]
        }
      ],
      "bbox": [
        -8.803093905281484,
        42.3272351569447,
        -8.803093905281484,
        42.3272351569447
      ]
    },
    "36005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.657118590122213,
              42.61544373428644
            ]
          },
          "bbox": [
            -8.657118590122213,
            42.61544373428644,
            -8.657118590122213,
            42.61544373428644
          ]
        }
      ],
      "bbox": [
        -8.657118590122213,
        42.61544373428644,
        -8.657118590122213,
        42.61544373428644
      ]
    },
    "36006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.801312611173175,
              42.505437844961854
            ]
          },
          "bbox": [
            -8.801312611173175,
            42.505437844961854,
            -8.801312611173175,
            42.505437844961854
          ]
        }
      ],
      "bbox": [
        -8.801312611173175,
        42.505437844961854,
        -8.801312611173175,
        42.505437844961854
      ]
    },
    "36007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.524818894376097,
              42.54390898395008
            ]
          },
          "bbox": [
            -8.524818894376097,
            42.54390898395008,
            -8.524818894376097,
            42.54390898395008
          ]
        }
      ],
      "bbox": [
        -8.524818894376097,
        42.54390898395008,
        -8.524818894376097,
        42.54390898395008
      ]
    },
    "36008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.812495643372959,
              42.27649541538558
            ]
          },
          "bbox": [
            -8.812495643372959,
            42.27649541538558,
            -8.812495643372959,
            42.27649541538558
          ]
        }
      ],
      "bbox": [
        -8.812495643372959,
        42.27649541538558,
        -8.812495643372959,
        42.27649541538558
      ]
    },
    "36009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.289899792610113,
              42.202347729601065
            ]
          },
          "bbox": [
            -8.289899792610113,
            42.202347729601065,
            -8.289899792610113,
            42.202347729601065
          ]
        }
      ],
      "bbox": [
        -8.289899792610113,
        42.202347729601065,
        -8.289899792610113,
        42.202347729601065
      ]
    },
    "36010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.70655640922997,
              42.65856528394316
            ]
          },
          "bbox": [
            -8.70655640922997,
            42.65856528394316,
            -8.70655640922997,
            42.65856528394316
          ]
        }
      ],
      "bbox": [
        -8.70655640922997,
        42.65856528394316,
        -8.70655640922997,
        42.65856528394316
      ]
    },
    "36013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.346359825851103,
              42.27119211741717
            ]
          },
          "bbox": [
            -8.346359825851103,
            42.27119211741717,
            -8.346359825851103,
            42.27119211741717
          ]
        }
      ],
      "bbox": [
        -8.346359825851103,
        42.27119211741717,
        -8.346359825851103,
        42.27119211741717
      ]
    },
    "36014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.210200375133233,
              42.18592264334529
            ]
          },
          "bbox": [
            -8.210200375133233,
            42.18592264334529,
            -8.210200375133233,
            42.18592264334529
          ]
        }
      ],
      "bbox": [
        -8.210200375133233,
        42.18592264334529,
        -8.210200375133233,
        42.18592264334529
      ]
    },
    "36015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.556824622099153,
              42.64043969163155
            ]
          },
          "bbox": [
            -8.556824622099153,
            42.64043969163155,
            -8.556824622099153,
            42.64043969163155
          ]
        }
      ],
      "bbox": [
        -8.556824622099153,
        42.64043969163155,
        -8.556824622099153,
        42.64043969163155
      ]
    },
    "36016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.046488919991814,
              42.59001811983576
            ]
          },
          "bbox": [
            -8.046488919991814,
            42.59001811983576,
            -8.046488919991814,
            42.59001811983576
          ]
        }
      ],
      "bbox": [
        -8.046488919991814,
        42.59001811983576,
        -8.046488919991814,
        42.59001811983576
      ]
    },
    "36017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.45736468905952,
              42.68694606588071
            ]
          },
          "bbox": [
            -8.45736468905952,
            42.68694606588071,
            -8.45736468905952,
            42.68694606588071
          ]
        }
      ],
      "bbox": [
        -8.45736468905952,
        42.68694606588071,
        -8.45736468905952,
        42.68694606588071
      ]
    },
    "36018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.322614431306397,
              42.579306884332226
            ]
          },
          "bbox": [
            -8.322614431306397,
            42.579306884332226,
            -8.322614431306397,
            42.579306884332226
          ]
        }
      ],
      "bbox": [
        -8.322614431306397,
        42.579306884332226,
        -8.322614431306397,
        42.579306884332226
      ]
    },
    "36019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.424363355977173,
              42.33130453910987
            ]
          },
          "bbox": [
            -8.424363355977173,
            42.33130453910987,
            -8.424363355977173,
            42.33130453910987
          ]
        }
      ],
      "bbox": [
        -8.424363355977173,
        42.33130453910987,
        -8.424363355977173,
        42.33130453910987
      ]
    },
    "36020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.033357397839744,
              42.788268487572616
            ]
          },
          "bbox": [
            -8.033357397839744,
            42.788268487572616,
            -8.033357397839744,
            42.788268487572616
          ]
        }
      ],
      "bbox": [
        -8.033357397839744,
        42.788268487572616,
        -8.033357397839744,
        42.788268487572616
      ]
    },
    "36021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.746512440258664,
              42.10587859019287
            ]
          },
          "bbox": [
            -8.746512440258664,
            42.10587859019287,
            -8.746512440258664,
            42.10587859019287
          ]
        }
      ],
      "bbox": [
        -8.746512440258664,
        42.10587859019287,
        -8.746512440258664,
        42.10587859019287
      ]
    },
    "36022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.890309928986849,
              42.47351345646878
            ]
          },
          "bbox": [
            -8.890309928986849,
            42.47351345646878,
            -8.890309928986849,
            42.47351345646878
          ]
        }
      ],
      "bbox": [
        -8.890309928986849,
        42.47351345646878,
        -8.890309928986849,
        42.47351345646878
      ]
    },
    "36023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.861434284044634,
              41.90232298493049
            ]
          },
          "bbox": [
            -8.861434284044634,
            41.90232298493049,
            -8.861434284044634,
            41.90232298493049
          ]
        }
      ],
      "bbox": [
        -8.861434284044634,
        41.90232298493049,
        -8.861434284044634,
        41.90232298493049
      ]
    },
    "36024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.130961582913143,
              42.66170456352455
            ]
          },
          "bbox": [
            -8.130961582913143,
            42.66170456352455,
            -8.130961582913143,
            42.66170456352455
          ]
        }
      ],
      "bbox": [
        -8.130961582913143,
        42.66170456352455,
        -8.130961582913143,
        42.66170456352455
      ]
    },
    "36025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.387465621462795,
              42.41192269483512
            ]
          },
          "bbox": [
            -8.387465621462795,
            42.41192269483512,
            -8.387465621462795,
            42.41192269483512
          ]
        }
      ],
      "bbox": [
        -8.387465621462795,
        42.41192269483512,
        -8.387465621462795,
        42.41192269483512
      ]
    },
    "36026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.70969988782122,
              42.36073270065104
            ]
          },
          "bbox": [
            -8.70969988782122,
            42.36073270065104,
            -8.70969988782122,
            42.36073270065104
          ]
        }
      ],
      "bbox": [
        -8.70969988782122,
        42.36073270065104,
        -8.70969988782122,
        42.36073270065104
      ]
    },
    "36027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.784566824870435,
              42.4498184121455
            ]
          },
          "bbox": [
            -8.784566824870435,
            42.4498184121455,
            -8.784566824870435,
            42.4498184121455
          ]
        }
      ],
      "bbox": [
        -8.784566824870435,
        42.4498184121455,
        -8.784566824870435,
        42.4498184121455
      ]
    },
    "36028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.72237334538594,
              42.496545020930135
            ]
          },
          "bbox": [
            -8.72237334538594,
            42.496545020930135,
            -8.72237334538594,
            42.496545020930135
          ]
        }
      ],
      "bbox": [
        -8.72237334538594,
        42.496545020930135,
        -8.72237334538594,
        42.496545020930135
      ]
    },
    "36029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.716358343693388,
              42.30023793303665
            ]
          },
          "bbox": [
            -8.716358343693388,
            42.30023793303665,
            -8.716358343693388,
            42.30023793303665
          ]
        }
      ],
      "bbox": [
        -8.716358343693388,
        42.30023793303665,
        -8.716358343693388,
        42.30023793303665
      ]
    },
    "36030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.444819111068306,
              42.2431836344538
            ]
          },
          "bbox": [
            -8.444819111068306,
            42.2431836344538,
            -8.444819111068306,
            42.2431836344538
          ]
        }
      ],
      "bbox": [
        -8.444819111068306,
        42.2431836344538,
        -8.444819111068306,
        42.2431836344538
      ]
    },
    "36031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.470667522213297,
              42.22183206297134
            ]
          },
          "bbox": [
            -8.470667522213297,
            42.22183206297134,
            -8.470667522213297,
            42.22183206297134
          ]
        }
      ],
      "bbox": [
        -8.470667522213297,
        42.22183206297134,
        -8.470667522213297,
        42.22183206297134
      ]
    },
    "36032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.584457541649343,
              42.566659663324685
            ]
          },
          "bbox": [
            -8.584457541649343,
            42.566659663324685,
            -8.584457541649343,
            42.566659663324685
          ]
        }
      ],
      "bbox": [
        -8.584457541649343,
        42.566659663324685,
        -8.584457541649343,
        42.566659663324685
      ]
    },
    "36033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.623355251937056,
              42.197135766792414
            ]
          },
          "bbox": [
            -8.623355251937056,
            42.197135766792414,
            -8.623355251937056,
            42.197135766792414
          ]
        }
      ],
      "bbox": [
        -8.623355251937056,
        42.197135766792414,
        -8.623355251937056,
        42.197135766792414
      ]
    },
    "36034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.401026483202179,
              42.12208184785604
            ]
          },
          "bbox": [
            -8.401026483202179,
            42.12208184785604,
            -8.401026483202179,
            42.12208184785604
          ]
        }
      ],
      "bbox": [
        -8.401026483202179,
        42.12208184785604,
        -8.401026483202179,
        42.12208184785604
      ]
    },
    "36035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.790060621677432,
              42.14441563177653
            ]
          },
          "bbox": [
            -8.790060621677432,
            42.14441563177653,
            -8.790060621677432,
            42.14441563177653
          ]
        }
      ],
      "bbox": [
        -8.790060621677432,
        42.14441563177653,
        -8.790060621677432,
        42.14441563177653
      ]
    },
    "36036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.846016096703877,
              42.020739185509534
            ]
          },
          "bbox": [
            -8.846016096703877,
            42.020739185509534,
            -8.846016096703877,
            42.020739185509534
          ]
        }
      ],
      "bbox": [
        -8.846016096703877,
        42.020739185509534,
        -8.846016096703877,
        42.020739185509534
      ]
    },
    "36037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.535506729870932,
              42.28634860168133
            ]
          },
          "bbox": [
            -8.535506729870932,
            42.28634860168133,
            -8.535506729870932,
            42.28634860168133
          ]
        }
      ],
      "bbox": [
        -8.535506729870932,
        42.28634860168133,
        -8.535506729870932,
        42.28634860168133
      ]
    },
    "36038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.619808349640435,
              42.437156299516545
            ]
          },
          "bbox": [
            -8.619808349640435,
            42.437156299516545,
            -8.619808349640435,
            42.437156299516545
          ]
        }
      ],
      "bbox": [
        -8.619808349640435,
        42.437156299516545,
        -8.619808349640435,
        42.437156299516545
      ]
    },
    "36039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.628681653183548,
              42.13320563588036
            ]
          },
          "bbox": [
            -8.628681653183548,
            42.13320563588036,
            -8.628681653183548,
            42.13320563588036
          ]
        }
      ],
      "bbox": [
        -8.628681653183548,
        42.13320563588036,
        -8.628681653183548,
        42.13320563588036
      ]
    },
    "36040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.66960194573375,
              42.5652988219611
            ]
          },
          "bbox": [
            -8.66960194573375,
            42.5652988219611,
            -8.66960194573375,
            42.5652988219611
          ]
        }
      ],
      "bbox": [
        -8.66960194573375,
        42.5652988219611,
        -8.66960194573375,
        42.5652988219611
      ]
    },
    "36041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.704764904095484,
              42.441005717245766
            ]
          },
          "bbox": [
            -8.704764904095484,
            42.441005717245766,
            -8.704764904095484,
            42.441005717245766
          ]
        }
      ],
      "bbox": [
        -8.704764904095484,
        42.441005717245766,
        -8.704764904095484,
        42.441005717245766
      ]
    },
    "36042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.515257771432715,
              42.1864693906214
            ]
          },
          "bbox": [
            -8.515257771432715,
            42.1864693906214,
            -8.515257771432715,
            42.1864693906214
          ]
        }
      ],
      "bbox": [
        -8.515257771432715,
        42.1864693906214,
        -8.515257771432715,
        42.1864693906214
      ]
    },
    "36043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.516721829780053,
              42.385077482289006
            ]
          },
          "bbox": [
            -8.516721829780053,
            42.385077482289006,
            -8.516721829780053,
            42.385077482289006
          ]
        }
      ],
      "bbox": [
        -8.516721829780053,
        42.385077482289006,
        -8.516721829780053,
        42.385077482289006
      ]
    },
    "36044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.636378135952489,
              42.71445805162487
            ]
          },
          "bbox": [
            -8.636378135952489,
            42.71445805162487,
            -8.636378135952489,
            42.71445805162487
          ]
        }
      ],
      "bbox": [
        -8.636378135952489,
        42.71445805162487,
        -8.636378135952489,
        42.71445805162487
      ]
    },
    "36045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.614578405696156,
              42.27799321203182
            ]
          },
          "bbox": [
            -8.614578405696156,
            42.27799321203182,
            -8.614578405696156,
            42.27799321203182
          ]
        }
      ],
      "bbox": [
        -8.614578405696156,
        42.27799321203182,
        -8.614578405696156,
        42.27799321203182
      ]
    },
    "36046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.757514106156677,
              42.51336172435978
            ]
          },
          "bbox": [
            -8.757514106156677,
            42.51336172435978,
            -8.757514106156677,
            42.51336172435978
          ]
        }
      ],
      "bbox": [
        -8.757514106156677,
        42.51336172435978,
        -8.757514106156677,
        42.51336172435978
      ]
    },
    "36047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.945468527707127,
              42.659181635396486
            ]
          },
          "bbox": [
            -7.945468527707127,
            42.659181635396486,
            -7.945468527707127,
            42.659181635396486
          ]
        }
      ],
      "bbox": [
        -7.945468527707127,
        42.659181635396486,
        -7.945468527707127,
        42.659181635396486
      ]
    },
    "36048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.833087181612848,
              41.93998894268835
            ]
          },
          "bbox": [
            -8.833087181612848,
            41.93998894268835,
            -8.833087181612848,
            41.93998894268835
          ]
        }
      ],
      "bbox": [
        -8.833087181612848,
        41.93998894268835,
        -8.833087181612848,
        41.93998894268835
      ]
    },
    "36049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.574308664612323,
              42.10025535491966
            ]
          },
          "bbox": [
            -8.574308664612323,
            42.10025535491966,
            -8.574308664612323,
            42.10025535491966
          ]
        }
      ],
      "bbox": [
        -8.574308664612323,
        42.10025535491966,
        -8.574308664612323,
        42.10025535491966
      ]
    },
    "36050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.485048106813142,
              42.11307020372713
            ]
          },
          "bbox": [
            -8.485048106813142,
            42.11307020372713,
            -8.485048106813142,
            42.11307020372713
          ]
        }
      ],
      "bbox": [
        -8.485048106813142,
        42.11307020372713,
        -8.485048106813142,
        42.11307020372713
      ]
    },
    "36051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.824728479389547,
              42.419673923595866
            ]
          },
          "bbox": [
            -8.824728479389547,
            42.419673923595866,
            -8.824728479389547,
            42.419673923595866
          ]
        }
      ],
      "bbox": [
        -8.824728479389547,
        42.419673923595866,
        -8.824728479389547,
        42.419673923595866
      ]
    },
    "36052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.276156393777345,
              42.69411876936045
            ]
          },
          "bbox": [
            -8.276156393777345,
            42.69411876936045,
            -8.276156393777345,
            42.69411876936045
          ]
        }
      ],
      "bbox": [
        -8.276156393777345,
        42.69411876936045,
        -8.276156393777345,
        42.69411876936045
      ]
    },
    "36053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.567501853255536,
              42.33509000685928
            ]
          },
          "bbox": [
            -8.567501853255536,
            42.33509000685928,
            -8.567501853255536,
            42.33509000685928
          ]
        }
      ],
      "bbox": [
        -8.567501853255536,
        42.33509000685928,
        -8.567501853255536,
        42.33509000685928
      ]
    },
    "36054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.749010750939643,
              42.00543100674921
            ]
          },
          "bbox": [
            -8.749010750939643,
            42.00543100674921,
            -8.749010750939643,
            42.00543100674921
          ]
        }
      ],
      "bbox": [
        -8.749010750939643,
        42.00543100674921,
        -8.749010750939643,
        42.00543100674921
      ]
    },
    "36055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.659363005986581,
              42.06494768303215
            ]
          },
          "bbox": [
            -8.659363005986581,
            42.06494768303215,
            -8.659363005986581,
            42.06494768303215
          ]
        }
      ],
      "bbox": [
        -8.659363005986581,
        42.06494768303215,
        -8.659363005986581,
        42.06494768303215
      ]
    },
    "36056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.654010383942962,
              42.690160593954126
            ]
          },
          "bbox": [
            -8.654010383942962,
            42.690160593954126,
            -8.654010383942962,
            42.690160593954126
          ]
        }
      ],
      "bbox": [
        -8.654010383942962,
        42.690160593954126,
        -8.654010383942962,
        42.690160593954126
      ]
    },
    "36057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.723011306874103,
              42.20364276463024
            ]
          },
          "bbox": [
            -8.723011306874103,
            42.20364276463024,
            -8.723011306874103,
            42.20364276463024
          ]
        }
      ],
      "bbox": [
        -8.723011306874103,
        42.20364276463024,
        -8.723011306874103,
        42.20364276463024
      ]
    },
    "36058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.652395750807285,
              42.35121265103806
            ]
          },
          "bbox": [
            -8.652395750807285,
            42.35121265103806,
            -8.652395750807285,
            42.35121265103806
          ]
        }
      ],
      "bbox": [
        -8.652395750807285,
        42.35121265103806,
        -8.652395750807285,
        42.35121265103806
      ]
    },
    "36059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.220282307511333,
              42.7985116996261
            ]
          },
          "bbox": [
            -8.220282307511333,
            42.7985116996261,
            -8.220282307511333,
            42.7985116996261
          ]
        }
      ],
      "bbox": [
        -8.220282307511333,
        42.7985116996261,
        -8.220282307511333,
        42.7985116996261
      ]
    },
    "36060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.751650370078478,
              42.597096009998715
            ]
          },
          "bbox": [
            -8.751650370078478,
            42.597096009998715,
            -8.751650370078478,
            42.597096009998715
          ]
        }
      ],
      "bbox": [
        -8.751650370078478,
        42.597096009998715,
        -8.751650370078478,
        42.597096009998715
      ]
    },
    "36061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.776606091489848,
              42.55377859274478
            ]
          },
          "bbox": [
            -8.776606091489848,
            42.55377859274478,
            -8.776606091489848,
            42.55377859274478
          ]
        }
      ],
      "bbox": [
        -8.776606091489848,
        42.55377859274478,
        -8.776606091489848,
        42.55377859274478
      ]
    },
    "36901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.873905768564512,
              42.55193674131489
            ]
          },
          "bbox": [
            -8.873905768564512,
            42.55193674131489,
            -8.873905768564512,
            42.55193674131489
          ]
        }
      ],
      "bbox": [
        -8.873905768564512,
        42.55193674131489,
        -8.873905768564512,
        42.55193674131489
      ]
    },
    "36902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -8.457423935874926,
              42.49927775966394
            ]
          },
          "bbox": [
            -8.457423935874926,
            42.49927775966394,
            -8.457423935874926,
            42.49927775966394
          ]
        }
      ],
      "bbox": [
        -8.457423935874926,
        42.49927775966394,
        -8.457423935874926,
        42.49927775966394
      ]
    },
    "37001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.13524110672647,
              40.70543025729891
            ]
          },
          "bbox": [
            -6.13524110672647,
            40.70543025729891,
            -6.13524110672647,
            40.70543025729891
          ]
        }
      ],
      "bbox": [
        -6.13524110672647,
        40.70543025729891,
        -6.13524110672647,
        40.70543025729891
      ]
    },
    "37002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.421734068047646,
              40.42292588485001
            ]
          },
          "bbox": [
            -6.421734068047646,
            40.42292588485001,
            -6.421734068047646,
            40.42292588485001
          ]
        }
      ],
      "bbox": [
        -6.421734068047646,
        40.42292588485001,
        -6.421734068047646,
        40.42292588485001
      ]
    },
    "37003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7593890036047375,
              40.870858688319935
            ]
          },
          "bbox": [
            -6.7593890036047375,
            40.870858688319935,
            -6.7593890036047375,
            40.870858688319935
          ]
        }
      ],
      "bbox": [
        -6.7593890036047375,
        40.870858688319935,
        -6.7593890036047375,
        40.870858688319935
      ]
    },
    "37004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.3781227110591425,
              41.16195688721733
            ]
          },
          "bbox": [
            -6.3781227110591425,
            41.16195688721733,
            -6.3781227110591425,
            41.16195688721733
          ]
        }
      ],
      "bbox": [
        -6.3781227110591425,
        41.16195688721733,
        -6.3781227110591425,
        41.16195688721733
      ]
    },
    "37005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.770220529068657,
              40.63707543150805
            ]
          },
          "bbox": [
            -6.770220529068657,
            40.63707543150805,
            -6.770220529068657,
            40.63707543150805
          ]
        }
      ],
      "bbox": [
        -6.770220529068657,
        40.63707543150805,
        -6.770220529068657,
        40.63707543150805
      ]
    },
    "37006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.816496395834077,
              40.45856401335291
            ]
          },
          "bbox": [
            -6.816496395834077,
            40.45856401335291,
            -6.816496395834077,
            40.45856401335291
          ]
        }
      ],
      "bbox": [
        -6.816496395834077,
        40.45856401335291,
        -6.816496395834077,
        40.45856401335291
      ]
    },
    "37007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3151037323862935,
              40.727749626701055
            ]
          },
          "bbox": [
            -5.3151037323862935,
            40.727749626701055,
            -5.3151037323862935,
            40.727749626701055
          ]
        }
      ],
      "bbox": [
        -5.3151037323862935,
        40.727749626701055,
        -5.3151037323862935,
        40.727749626701055
      ]
    },
    "37008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.50921072082897,
              40.8281552882352
            ]
          },
          "bbox": [
            -5.50921072082897,
            40.8281552882352,
            -5.50921072082897,
            40.8281552882352
          ]
        }
      ],
      "bbox": [
        -5.50921072082897,
        40.8281552882352,
        -5.50921072082897,
        40.8281552882352
      ]
    },
    "37009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.32923522948873,
              40.67105289263143
            ]
          },
          "bbox": [
            -6.32923522948873,
            40.67105289263143,
            -6.32923522948873,
            40.67105289263143
          ]
        }
      ],
      "bbox": [
        -6.32923522948873,
        40.67105289263143,
        -6.32923522948873,
        40.67105289263143
      ]
    },
    "37010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.130437666329521,
              40.47831759927039
            ]
          },
          "bbox": [
            -6.130437666329521,
            40.47831759927039,
            -6.130437666329521,
            40.47831759927039
          ]
        }
      ],
      "bbox": [
        -6.130437666329521,
        40.47831759927039,
        -6.130437666329521,
        40.47831759927039
      ]
    },
    "37011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.806365021973944,
              40.41792247653687
            ]
          },
          "bbox": [
            -6.806365021973944,
            40.41792247653687,
            -6.806365021973944,
            40.41792247653687
          ]
        }
      ],
      "bbox": [
        -6.806365021973944,
        40.41792247653687,
        -6.806365021973944,
        40.41792247653687
      ]
    },
    "37012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.373350431423697,
              40.911386430513716
            ]
          },
          "bbox": [
            -5.373350431423697,
            40.911386430513716,
            -5.373350431423697,
            40.911386430513716
          ]
        }
      ],
      "bbox": [
        -5.373350431423697,
        40.911386430513716,
        -5.373350431423697,
        40.911386430513716
      ]
    },
    "37013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.894251850622118,
              40.381307226517116
            ]
          },
          "bbox": [
            -5.894251850622118,
            40.381307226517116,
            -5.894251850622118,
            40.381307226517116
          ]
        }
      ],
      "bbox": [
        -5.894251850622118,
        40.381307226517116,
        -5.894251850622118,
        40.381307226517116
      ]
    },
    "37014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.636433665545753,
              41.21512279952802
            ]
          },
          "bbox": [
            -6.636433665545753,
            41.21512279952802,
            -6.636433665545753,
            41.21512279952802
          ]
        }
      ],
      "bbox": [
        -6.636433665545753,
        41.21512279952802,
        -6.636433665545753,
        41.21512279952802
      ]
    },
    "37015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.791237559891702,
              40.71189090840042
            ]
          },
          "bbox": [
            -6.791237559891702,
            40.71189090840042,
            -6.791237559891702,
            40.71189090840042
          ]
        }
      ],
      "bbox": [
        -6.791237559891702,
        40.71189090840042,
        -6.791237559891702,
        40.71189090840042
      ]
    },
    "37016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.548357604310549,
              40.98848758121633
            ]
          },
          "bbox": [
            -5.548357604310549,
            40.98848758121633,
            -5.548357604310549,
            40.98848758121633
          ]
        }
      ],
      "bbox": [
        -5.548357604310549,
        40.98848758121633,
        -5.548357604310549,
        40.98848758121633
      ]
    },
    "37017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.535109377648753,
              41.16900059570832
            ]
          },
          "bbox": [
            -5.535109377648753,
            41.16900059570832,
            -5.535109377648753,
            41.16900059570832
          ]
        }
      ],
      "bbox": [
        -5.535109377648753,
        41.16900059570832,
        -5.535109377648753,
        41.16900059570832
      ]
    },
    "37018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.097163811862161,
              40.61255854794079
            ]
          },
          "bbox": [
            -6.097163811862161,
            40.61255854794079,
            -6.097163811862161,
            40.61255854794079
          ]
        }
      ],
      "bbox": [
        -6.097163811862161,
        40.61255854794079,
        -6.097163811862161,
        40.61255854794079
      ]
    },
    "37019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.806044491899659,
              41.11531806386935
            ]
          },
          "bbox": [
            -5.806044491899659,
            41.11531806386935,
            -5.806044491899659,
            41.11531806386935
          ]
        }
      ],
      "bbox": [
        -5.806044491899659,
        41.11531806386935,
        -5.806044491899659,
        41.11531806386935
      ]
    },
    "37020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.492708888719048,
              41.004050246546576
            ]
          },
          "bbox": [
            -5.492708888719048,
            41.004050246546576,
            -5.492708888719048,
            41.004050246546576
          ]
        }
      ],
      "bbox": [
        -5.492708888719048,
        41.004050246546576,
        -5.492708888719048,
        41.004050246546576
      ]
    },
    "37021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.452949276639754,
              40.817279687429306
            ]
          },
          "bbox": [
            -5.452949276639754,
            40.817279687429306,
            -5.452949276639754,
            40.817279687429306
          ]
        }
      ],
      "bbox": [
        -5.452949276639754,
        40.817279687429306,
        -5.452949276639754,
        40.817279687429306
      ]
    },
    "37022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.218068890198951,
              40.94023258429607
            ]
          },
          "bbox": [
            -5.218068890198951,
            40.94023258429607,
            -5.218068890198951,
            40.94023258429607
          ]
        }
      ],
      "bbox": [
        -5.218068890198951,
        40.94023258429607,
        -5.218068890198951,
        40.94023258429607
      ]
    },
    "37023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.719363317436351,
              40.906615308776836
            ]
          },
          "bbox": [
            -5.719363317436351,
            40.906615308776836,
            -5.719363317436351,
            40.906615308776836
          ]
        }
      ],
      "bbox": [
        -5.719363317436351,
        40.906615308776836,
        -5.719363317436351,
        40.906615308776836
      ]
    },
    "37024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.628416024612683,
              40.57657237596999
            ]
          },
          "bbox": [
            -5.628416024612683,
            40.57657237596999,
            -5.628416024612683,
            40.57657237596999
          ]
        }
      ],
      "bbox": [
        -5.628416024612683,
        40.57657237596999,
        -5.628416024612683,
        40.57657237596999
      ]
    },
    "37025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.06641024995771,
              40.842705832787274
            ]
          },
          "bbox": [
            -6.06641024995771,
            40.842705832787274,
            -6.06641024995771,
            40.842705832787274
          ]
        }
      ],
      "bbox": [
        -6.06641024995771,
        40.842705832787274,
        -6.06641024995771,
        40.842705832787274
      ]
    },
    "37026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.246464876933991,
              40.66054264079737
            ]
          },
          "bbox": [
            -6.246464876933991,
            40.66054264079737,
            -6.246464876933991,
            40.66054264079737
          ]
        }
      ],
      "bbox": [
        -6.246464876933991,
        40.66054264079737,
        -6.246464876933991,
        40.66054264079737
      ]
    },
    "37027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.817706208612642,
              41.071592948108766
            ]
          },
          "bbox": [
            -5.817706208612642,
            41.071592948108766,
            -5.817706208612642,
            41.071592948108766
          ]
        }
      ],
      "bbox": [
        -5.817706208612642,
        41.071592948108766,
        -5.817706208612642,
        41.071592948108766
      ]
    },
    "37028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.330246611212662,
              41.23501802500265
            ]
          },
          "bbox": [
            -6.330246611212662,
            41.23501802500265,
            -6.330246611212662,
            41.23501802500265
          ]
        }
      ],
      "bbox": [
        -6.330246611212662,
        41.23501802500265,
        -6.330246611212662,
        41.23501802500265
      ]
    },
    "37029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.491930477598482,
              40.726269025525866
            ]
          },
          "bbox": [
            -5.491930477598482,
            40.726269025525866,
            -5.491930477598482,
            40.726269025525866
          ]
        }
      ],
      "bbox": [
        -5.491930477598482,
        40.726269025525866,
        -5.491930477598482,
        40.726269025525866
      ]
    },
    "37030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.918591611256285,
              41.13455652324009
            ]
          },
          "bbox": [
            -5.918591611256285,
            41.13455652324009,
            -5.918591611256285,
            41.13455652324009
          ]
        }
      ],
      "bbox": [
        -5.918591611256285,
        41.13455652324009,
        -5.918591611256285,
        41.13455652324009
      ]
    },
    "37031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.393270071519884,
              41.04723602799727
            ]
          },
          "bbox": [
            -5.393270071519884,
            41.04723602799727,
            -5.393270071519884,
            41.04723602799727
          ]
        }
      ],
      "bbox": [
        -5.393270071519884,
        41.04723602799727,
        -5.393270071519884,
        41.04723602799727
      ]
    },
    "37032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.647898817474203,
              40.89518047720097
            ]
          },
          "bbox": [
            -5.647898817474203,
            40.89518047720097,
            -5.647898817474203,
            40.89518047720097
          ]
        }
      ],
      "bbox": [
        -5.647898817474203,
        40.89518047720097,
        -5.647898817474203,
        40.89518047720097
      ]
    },
    "37033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.556068672197723,
              41.10602690792505
            ]
          },
          "bbox": [
            -5.556068672197723,
            41.10602690792505,
            -5.556068672197723,
            41.10602690792505
          ]
        }
      ],
      "bbox": [
        -5.556068672197723,
        41.10602690792505,
        -5.556068672197723,
        41.10602690792505
      ]
    },
    "37034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.826905902646713,
              41.10846306643979
            ]
          },
          "bbox": [
            -5.826905902646713,
            41.10846306643979,
            -5.826905902646713,
            41.10846306643979
          ]
        }
      ],
      "bbox": [
        -5.826905902646713,
        41.10846306643979,
        -5.826905902646713,
        41.10846306643979
      ]
    },
    "37035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.473605040172991,
              40.60895268789802
            ]
          },
          "bbox": [
            -5.473605040172991,
            40.60895268789802,
            -5.473605040172991,
            40.60895268789802
          ]
        }
      ],
      "bbox": [
        -5.473605040172991,
        40.60895268789802,
        -5.473605040172991,
        40.60895268789802
      ]
    },
    "37036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.045784411198684,
              40.5429786319195
            ]
          },
          "bbox": [
            -6.045784411198684,
            40.5429786319195,
            -6.045784411198684,
            40.5429786319195
          ]
        }
      ],
      "bbox": [
        -6.045784411198684,
        40.5429786319195,
        -6.045784411198684,
        40.5429786319195
      ]
    },
    "37037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.406158420894713,
              40.50534443347788
            ]
          },
          "bbox": [
            -6.406158420894713,
            40.50534443347788,
            -6.406158420894713,
            40.50534443347788
          ]
        }
      ],
      "bbox": [
        -6.406158420894713,
        40.50534443347788,
        -6.406158420894713,
        40.50534443347788
      ]
    },
    "37038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.432546355517246,
              40.98641289469124
            ]
          },
          "bbox": [
            -5.432546355517246,
            40.98641289469124,
            -5.432546355517246,
            40.98641289469124
          ]
        }
      ],
      "bbox": [
        -5.432546355517246,
        40.98641289469124,
        -5.432546355517246,
        40.98641289469124
      ]
    },
    "37039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6210487401020535,
              40.81917842732409
            ]
          },
          "bbox": [
            -6.6210487401020535,
            40.81917842732409,
            -6.6210487401020535,
            40.81917842732409
          ]
        }
      ],
      "bbox": [
        -6.6210487401020535,
        40.81917842732409,
        -6.6210487401020535,
        40.81917842732409
      ]
    },
    "37040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.834242757632017,
              40.90334935502406
            ]
          },
          "bbox": [
            -5.834242757632017,
            40.90334935502406,
            -5.834242757632017,
            40.90334935502406
          ]
        }
      ],
      "bbox": [
        -5.834242757632017,
        40.90334935502406,
        -5.834242757632017,
        40.90334935502406
      ]
    },
    "37041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.94139069010085,
              40.659653171918634
            ]
          },
          "bbox": [
            -5.94139069010085,
            40.659653171918634,
            -5.94139069010085,
            40.659653171918634
          ]
        }
      ],
      "bbox": [
        -5.94139069010085,
        40.659653171918634,
        -5.94139069010085,
        40.659653171918634
      ]
    },
    "37042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4547372650167025,
              41.06619283973308
            ]
          },
          "bbox": [
            -6.4547372650167025,
            41.06619283973308,
            -6.4547372650167025,
            41.06619283973308
          ]
        }
      ],
      "bbox": [
        -6.4547372650167025,
        41.06619283973308,
        -6.4547372650167025,
        41.06619283973308
      ]
    },
    "37044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.662703853454571,
              41.06832115313337
            ]
          },
          "bbox": [
            -6.662703853454571,
            41.06832115313337,
            -6.662703853454571,
            41.06832115313337
          ]
        }
      ],
      "bbox": [
        -6.662703853454571,
        41.06832115313337,
        -6.662703853454571,
        41.06832115313337
      ]
    },
    "37045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.046183059281174,
              40.585212092487936
            ]
          },
          "bbox": [
            -6.046183059281174,
            40.585212092487936,
            -6.046183059281174,
            40.585212092487936
          ]
        }
      ],
      "bbox": [
        -6.046183059281174,
        40.585212092487936,
        -6.046183059281174,
        40.585212092487936
      ]
    },
    "37046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.769619309719596,
              40.38984312396443
            ]
          },
          "bbox": [
            -5.769619309719596,
            40.38984312396443,
            -5.769619309719596,
            40.38984312396443
          ]
        }
      ],
      "bbox": [
        -5.769619309719596,
        40.38984312396443,
        -5.769619309719596,
        40.38984312396443
      ]
    },
    "37047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.646059127614197,
              40.741541803288065
            ]
          },
          "bbox": [
            -5.646059127614197,
            40.741541803288065,
            -5.646059127614197,
            40.741541803288065
          ]
        }
      ],
      "bbox": [
        -5.646059127614197,
        40.741541803288065,
        -5.646059127614197,
        40.741541803288065
      ]
    },
    "37049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6657810898300776,
              40.99693057886561
            ]
          },
          "bbox": [
            -6.6657810898300776,
            40.99693057886561,
            -6.6657810898300776,
            40.99693057886561
          ]
        }
      ],
      "bbox": [
        -6.6657810898300776,
        40.99693057886561,
        -6.6657810898300776,
        40.99693057886561
      ]
    },
    "37050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.005657375051549,
              40.70833526678838
            ]
          },
          "bbox": [
            -6.005657375051549,
            40.70833526678838,
            -6.005657375051549,
            40.70833526678838
          ]
        }
      ],
      "bbox": [
        -6.005657375051549,
        40.70833526678838,
        -6.005657375051549,
        40.70833526678838
      ]
    },
    "37051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.701225090994356,
              40.638168250794386
            ]
          },
          "bbox": [
            -5.701225090994356,
            40.638168250794386,
            -5.701225090994356,
            40.638168250794386
          ]
        }
      ],
      "bbox": [
        -5.701225090994356,
        40.638168250794386,
        -5.701225090994356,
        40.638168250794386
      ]
    },
    "37052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.331505232592144,
              40.82566526672568
            ]
          },
          "bbox": [
            -6.331505232592144,
            40.82566526672568,
            -6.331505232592144,
            40.82566526672568
          ]
        }
      ],
      "bbox": [
        -6.331505232592144,
        40.82566526672568,
        -6.331505232592144,
        40.82566526672568
      ]
    },
    "37054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.586601075485831,
              40.49760280309511
            ]
          },
          "bbox": [
            -6.586601075485831,
            40.49760280309511,
            -6.586601075485831,
            40.49760280309511
          ]
        }
      ],
      "bbox": [
        -6.586601075485831,
        40.49760280309511,
        -6.586601075485831,
        40.49760280309511
      ]
    },
    "37055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.52956933519734,
              40.902019171734615
            ]
          },
          "bbox": [
            -6.52956933519734,
            40.902019171734615,
            -6.52956933519734,
            40.902019171734615
          ]
        }
      ],
      "bbox": [
        -6.52956933519734,
        40.902019171734615,
        -6.52956933519734,
        40.902019171734615
      ]
    },
    "37056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.80495797404856,
              40.822053897466525
            ]
          },
          "bbox": [
            -6.80495797404856,
            40.822053897466525,
            -6.80495797404856,
            40.822053897466525
          ]
        }
      ],
      "bbox": [
        -6.80495797404856,
        40.822053897466525,
        -6.80495797404856,
        40.822053897466525
      ]
    },
    "37057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2201746854036095,
              40.86357820638201
            ]
          },
          "bbox": [
            -5.2201746854036095,
            40.86357820638201,
            -5.2201746854036095,
            40.86357820638201
          ]
        }
      ],
      "bbox": [
        -5.2201746854036095,
        40.86357820638201,
        -5.2201746854036095,
        40.86357820638201
      ]
    },
    "37058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.348063267484625,
              41.11191875234435
            ]
          },
          "bbox": [
            -6.348063267484625,
            41.11191875234435,
            -6.348063267484625,
            41.11191875234435
          ]
        }
      ],
      "bbox": [
        -6.348063267484625,
        41.11191875234435,
        -6.348063267484625,
        41.11191875234435
      ]
    },
    "37059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.225506338788501,
              40.85839983890178
            ]
          },
          "bbox": [
            -6.225506338788501,
            40.85839983890178,
            -6.225506338788501,
            40.85839983890178
          ]
        }
      ],
      "bbox": [
        -6.225506338788501,
        40.85839983890178,
        -6.225506338788501,
        40.85839983890178
      ]
    },
    "37060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.633527354347883,
              40.786840105018044
            ]
          },
          "bbox": [
            -5.633527354347883,
            40.786840105018044,
            -5.633527354347883,
            40.786840105018044
          ]
        }
      ],
      "bbox": [
        -5.633527354347883,
        40.786840105018044,
        -5.633527354347883,
        40.786840105018044
      ]
    },
    "37061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.14387099673985,
              40.570752071578084
            ]
          },
          "bbox": [
            -6.14387099673985,
            40.570752071578084,
            -6.14387099673985,
            40.570752071578084
          ]
        }
      ],
      "bbox": [
        -6.14387099673985,
        40.570752071578084,
        -6.14387099673985,
        40.570752071578084
      ]
    },
    "37062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.483078478542869,
              41.04068521197546
            ]
          },
          "bbox": [
            -5.483078478542869,
            41.04068521197546,
            -5.483078478542869,
            41.04068521197546
          ]
        }
      ],
      "bbox": [
        -5.483078478542869,
        41.04068521197546,
        -5.483078478542869,
        41.04068521197546
      ]
    },
    "37063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.656661594673692,
              40.49869119133855
            ]
          },
          "bbox": [
            -5.656661594673692,
            40.49869119133855,
            -5.656661594673692,
            40.49869119133855
          ]
        }
      ],
      "bbox": [
        -5.656661594673692,
        40.49869119133855,
        -5.656661594673692,
        40.49869119133855
      ]
    },
    "37065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.559956664570946,
              41.13534587485107
            ]
          },
          "bbox": [
            -6.559956664570946,
            41.13534587485107,
            -6.559956664570946,
            41.13534587485107
          ]
        }
      ],
      "bbox": [
        -6.559956664570946,
        41.13534587485107,
        -6.559956664570946,
        41.13534587485107
      ]
    },
    "37067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.601033351759765,
              40.98077375836579
            ]
          },
          "bbox": [
            -5.601033351759765,
            40.98077375836579,
            -5.601033351759765,
            40.98077375836579
          ]
        }
      ],
      "bbox": [
        -5.601033351759765,
        40.98077375836579,
        -5.601033351759765,
        40.98077375836579
      ]
    },
    "37068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.180926723162533,
              40.742181892126275
            ]
          },
          "bbox": [
            -6.180926723162533,
            40.742181892126275,
            -6.180926723162533,
            40.742181892126275
          ]
        }
      ],
      "bbox": [
        -6.180926723162533,
        40.742181892126275,
        -6.180926723162533,
        40.742181892126275
      ]
    },
    "37069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5345501209976975,
              40.95317271145817
            ]
          },
          "bbox": [
            -5.5345501209976975,
            40.95317271145817,
            -5.5345501209976975,
            40.95317271145817
          ]
        }
      ],
      "bbox": [
        -5.5345501209976975,
        40.95317271145817,
        -5.5345501209976975,
        40.95317271145817
      ]
    },
    "37070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.571563289748654,
              40.898828977917915
            ]
          },
          "bbox": [
            -5.571563289748654,
            40.898828977917915,
            -5.571563289748654,
            40.898828977917915
          ]
        }
      ],
      "bbox": [
        -5.571563289748654,
        40.898828977917915,
        -5.571563289748654,
        40.898828977917915
      ]
    },
    "37071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.820899832584262,
              40.41509447808954
            ]
          },
          "bbox": [
            -5.820899832584262,
            40.41509447808954,
            -5.820899832584262,
            40.41509447808954
          ]
        }
      ],
      "bbox": [
        -5.820899832584262,
        40.41509447808954,
        -5.820899832584262,
        40.41509447808954
      ]
    },
    "37072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.892596623460738,
              40.88521472381464
            ]
          },
          "bbox": [
            -5.892596623460738,
            40.88521472381464,
            -5.892596623460738,
            40.88521472381464
          ]
        }
      ],
      "bbox": [
        -5.892596623460738,
        40.88521472381464,
        -5.892596623460738,
        40.88521472381464
      ]
    },
    "37073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.70811700941003,
              41.08786882655371
            ]
          },
          "bbox": [
            -5.70811700941003,
            41.08786882655371,
            -5.70811700941003,
            41.08786882655371
          ]
        }
      ],
      "bbox": [
        -5.70811700941003,
        41.08786882655371,
        -5.70811700941003,
        41.08786882655371
      ]
    },
    "37074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.667684023975311,
              40.525752758516475
            ]
          },
          "bbox": [
            -6.667684023975311,
            40.525752758516475,
            -6.667684023975311,
            40.525752758516475
          ]
        }
      ],
      "bbox": [
        -6.667684023975311,
        40.525752758516475,
        -6.667684023975311,
        40.525752758516475
      ]
    },
    "37077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.268445864450395,
              40.99464087239125
            ]
          },
          "bbox": [
            -5.268445864450395,
            40.99464087239125,
            -5.268445864450395,
            40.99464087239125
          ]
        }
      ],
      "bbox": [
        -5.268445864450395,
        40.99464087239125,
        -5.268445864450395,
        40.99464087239125
      ]
    },
    "37078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.74870517982593,
              40.331447887101014
            ]
          },
          "bbox": [
            -5.74870517982593,
            40.331447887101014,
            -5.74870517982593,
            40.331447887101014
          ]
        }
      ],
      "bbox": [
        -5.74870517982593,
        40.331447887101014,
        -5.74870517982593,
        40.331447887101014
      ]
    },
    "37079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.953056584734236,
              40.91428926945922
            ]
          },
          "bbox": [
            -5.953056584734236,
            40.91428926945922,
            -5.953056584734236,
            40.91428926945922
          ]
        }
      ],
      "bbox": [
        -5.953056584734236,
        40.91428926945922,
        -5.953056584734236,
        40.91428926945922
      ]
    },
    "37080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.816181306909987,
              40.37047539004117
            ]
          },
          "bbox": [
            -5.816181306909987,
            40.37047539004117,
            -5.816181306909987,
            40.37047539004117
          ]
        }
      ],
      "bbox": [
        -5.816181306909987,
        40.37047539004117,
        -5.816181306909987,
        40.37047539004117
      ]
    },
    "37081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.192329492686232,
              41.12778764453487
            ]
          },
          "bbox": [
            -5.192329492686232,
            41.12778764453487,
            -5.192329492686232,
            41.12778764453487
          ]
        }
      ],
      "bbox": [
        -5.192329492686232,
        41.12778764453487,
        -5.192329492686232,
        41.12778764453487
      ]
    },
    "37082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.328966007481206,
              41.08164688281383
            ]
          },
          "bbox": [
            -5.328966007481206,
            41.08164688281383,
            -5.328966007481206,
            41.08164688281383
          ]
        }
      ],
      "bbox": [
        -5.328966007481206,
        41.08164688281383,
        -5.328966007481206,
        41.08164688281383
      ]
    },
    "37083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.152697269268399,
              40.910983524028666
            ]
          },
          "bbox": [
            -5.152697269268399,
            40.910983524028666,
            -5.152697269268399,
            40.910983524028666
          ]
        }
      ],
      "bbox": [
        -5.152697269268399,
        40.910983524028666,
        -5.152697269268399,
        40.910983524028666
      ]
    },
    "37085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.634420777603667,
              40.92499891764946
            ]
          },
          "bbox": [
            -5.634420777603667,
            40.92499891764946,
            -5.634420777603667,
            40.92499891764946
          ]
        }
      ],
      "bbox": [
        -5.634420777603667,
        40.92499891764946,
        -5.634420777603667,
        40.92499891764946
      ]
    },
    "37086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.633803530628604,
              40.578779545204625
            ]
          },
          "bbox": [
            -6.633803530628604,
            40.578779545204625,
            -6.633803530628604,
            40.578779545204625
          ]
        }
      ],
      "bbox": [
        -6.633803530628604,
        40.578779545204625,
        -6.633803530628604,
        40.578779545204625
      ]
    },
    "37087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.803280216027785,
              40.943223563050715
            ]
          },
          "bbox": [
            -5.803280216027785,
            40.943223563050715,
            -5.803280216027785,
            40.943223563050715
          ]
        }
      ],
      "bbox": [
        -5.803280216027785,
        40.943223563050715,
        -5.803280216027785,
        40.943223563050715
      ]
    },
    "37088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.996010412165102,
              40.76774773455049
            ]
          },
          "bbox": [
            -5.996010412165102,
            40.76774773455049,
            -5.996010412165102,
            40.76774773455049
          ]
        }
      ],
      "bbox": [
        -5.996010412165102,
        40.76774773455049,
        -5.996010412165102,
        40.76774773455049
      ]
    },
    "37089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.768163268978731,
              40.61170785973531
            ]
          },
          "bbox": [
            -5.768163268978731,
            40.61170785973531,
            -5.768163268978731,
            40.61170785973531
          ]
        }
      ],
      "bbox": [
        -5.768163268978731,
        40.61170785973531,
        -5.768163268978731,
        40.61170785973531
      ]
    },
    "37090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.040678105426592,
              40.506110689695326
            ]
          },
          "bbox": [
            -6.040678105426592,
            40.506110689695326,
            -6.040678105426592,
            40.506110689695326
          ]
        }
      ],
      "bbox": [
        -6.040678105426592,
        40.506110689695326,
        -6.040678105426592,
        40.506110689695326
      ]
    },
    "37091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.759892702025046,
              40.36970233602048
            ]
          },
          "bbox": [
            -6.759892702025046,
            40.36970233602048,
            -6.759892702025046,
            40.36970233602048
          ]
        }
      ],
      "bbox": [
        -6.759892702025046,
        40.36970233602048,
        -6.759892702025046,
        40.36970233602048
      ]
    },
    "37092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.589753378768683,
              41.02785780082819
            ]
          },
          "bbox": [
            -5.589753378768683,
            41.02785780082819,
            -5.589753378768683,
            41.02785780082819
          ]
        }
      ],
      "bbox": [
        -5.589753378768683,
        41.02785780082819,
        -5.589753378768683,
        41.02785780082819
      ]
    },
    "37096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.573427255182306,
              40.73191491798759
            ]
          },
          "bbox": [
            -6.573427255182306,
            40.73191491798759,
            -6.573427255182306,
            40.73191491798759
          ]
        }
      ],
      "bbox": [
        -6.573427255182306,
        40.73191491798759,
        -6.573427255182306,
        40.73191491798759
      ]
    },
    "37097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.321539050447952,
              40.71378232048505
            ]
          },
          "bbox": [
            -6.321539050447952,
            40.71378232048505,
            -6.321539050447952,
            40.71378232048505
          ]
        }
      ],
      "bbox": [
        -6.321539050447952,
        40.71378232048505,
        -6.321539050447952,
        40.71378232048505
      ]
    },
    "37098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.033018420829934,
              40.464631448810096
            ]
          },
          "bbox": [
            -6.033018420829934,
            40.464631448810096,
            -6.033018420829934,
            40.464631448810096
          ]
        }
      ],
      "bbox": [
        -6.033018420829934,
        40.464631448810096,
        -6.033018420829934,
        40.464631448810096
      ]
    },
    "37099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.090861261678732,
              40.56686637293097
            ]
          },
          "bbox": [
            -6.090861261678732,
            40.56686637293097,
            -6.090861261678732,
            40.56686637293097
          ]
        }
      ],
      "bbox": [
        -6.090861261678732,
        40.56686637293097,
        -6.090861261678732,
        40.56686637293097
      ]
    },
    "37100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.652843967918857,
              41.12712792629125
            ]
          },
          "bbox": [
            -6.652843967918857,
            41.12712792629125,
            -6.652843967918857,
            41.12712792629125
          ]
        }
      ],
      "bbox": [
        -6.652843967918857,
        41.12712792629125,
        -6.652843967918857,
        41.12712792629125
      ]
    },
    "37101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.580998381735259,
              40.95362184846415
            ]
          },
          "bbox": [
            -6.580998381735259,
            40.95362184846415,
            -6.580998381735259,
            40.95362184846415
          ]
        }
      ],
      "bbox": [
        -6.580998381735259,
        40.95362184846415,
        -6.580998381735259,
        40.95362184846415
      ]
    },
    "37102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.922400151943627,
              40.30923247532339
            ]
          },
          "bbox": [
            -5.922400151943627,
            40.30923247532339,
            -5.922400151943627,
            40.30923247532339
          ]
        }
      ],
      "bbox": [
        -5.922400151943627,
        40.30923247532339,
        -5.922400151943627,
        40.30923247532339
      ]
    },
    "37103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5709098890132625,
              40.541406356603524
            ]
          },
          "bbox": [
            -5.5709098890132625,
            40.541406356603524,
            -5.5709098890132625,
            40.541406356603524
          ]
        }
      ],
      "bbox": [
        -5.5709098890132625,
        40.541406356603524,
        -5.5709098890132625,
        40.541406356603524
      ]
    },
    "37104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.034577695167525,
              40.55803742535114
            ]
          },
          "bbox": [
            -6.034577695167525,
            40.55803742535114,
            -6.034577695167525,
            40.55803742535114
          ]
        }
      ],
      "bbox": [
        -6.034577695167525,
        40.55803742535114,
        -6.034577695167525,
        40.55803742535114
      ]
    },
    "37106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.242247821425412,
              40.965340865053015
            ]
          },
          "bbox": [
            -6.242247821425412,
            40.965340865053015,
            -6.242247821425412,
            40.965340865053015
          ]
        }
      ],
      "bbox": [
        -6.242247821425412,
        40.965340865053015,
        -6.242247821425412,
        40.965340865053015
      ]
    },
    "37107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.492229834139399,
              40.6181375824657
            ]
          },
          "bbox": [
            -6.492229834139399,
            40.6181375824657,
            -6.492229834139399,
            40.6181375824657
          ]
        }
      ],
      "bbox": [
        -6.492229834139399,
        40.6181375824657,
        -6.492229834139399,
        40.6181375824657
      ]
    },
    "37108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3578525371971,
              40.87850577280751
            ]
          },
          "bbox": [
            -5.3578525371971,
            40.87850577280751,
            -5.3578525371971,
            40.87850577280751
          ]
        }
      ],
      "bbox": [
        -5.3578525371971,
        40.87850577280751,
        -5.3578525371971,
        40.87850577280751
      ]
    },
    "37109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.956922437489606,
              40.40863077517404
            ]
          },
          "bbox": [
            -5.956922437489606,
            40.40863077517404,
            -5.956922437489606,
            40.40863077517404
          ]
        }
      ],
      "bbox": [
        -5.956922437489606,
        40.40863077517404,
        -5.956922437489606,
        40.40863077517404
      ]
    },
    "37110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.410214128670528,
              40.93909981301848
            ]
          },
          "bbox": [
            -5.410214128670528,
            40.93909981301848,
            -5.410214128670528,
            40.93909981301848
          ]
        }
      ],
      "bbox": [
        -5.410214128670528,
        40.93909981301848,
        -5.410214128670528,
        40.93909981301848
      ]
    },
    "37112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.891554078020216,
              40.46941382353016
            ]
          },
          "bbox": [
            -5.891554078020216,
            40.46941382353016,
            -5.891554078020216,
            40.46941382353016
          ]
        }
      ],
      "bbox": [
        -5.891554078020216,
        40.46941382353016,
        -5.891554078020216,
        40.46941382353016
      ]
    },
    "37113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.306885202088254,
              40.88834920228621
            ]
          },
          "bbox": [
            -6.306885202088254,
            40.88834920228621,
            -6.306885202088254,
            40.88834920228621
          ]
        }
      ],
      "bbox": [
        -6.306885202088254,
        40.88834920228621,
        -6.306885202088254,
        40.88834920228621
      ]
    },
    "37114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3879952842118986,
              40.67006269254625
            ]
          },
          "bbox": [
            -5.3879952842118986,
            40.67006269254625,
            -5.3879952842118986,
            40.67006269254625
          ]
        }
      ],
      "bbox": [
        -5.3879952842118986,
        40.67006269254625,
        -5.3879952842118986,
        40.67006269254625
      ]
    },
    "37115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.315987744236555,
              40.64024352688403
            ]
          },
          "bbox": [
            -6.315987744236555,
            40.64024352688403,
            -6.315987744236555,
            40.64024352688403
          ]
        }
      ],
      "bbox": [
        -6.315987744236555,
        40.64024352688403,
        -6.315987744236555,
        40.64024352688403
      ]
    },
    "37116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.034473473554176,
              41.030063588928684
            ]
          },
          "bbox": [
            -6.034473473554176,
            41.030063588928684,
            -6.034473473554176,
            41.030063588928684
          ]
        }
      ],
      "bbox": [
        -6.034473473554176,
        41.030063588928684,
        -6.034473473554176,
        41.030063588928684
      ]
    },
    "37117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.746230777965992,
              40.96482501298126
            ]
          },
          "bbox": [
            -5.746230777965992,
            40.96482501298126,
            -5.746230777965992,
            40.96482501298126
          ]
        }
      ],
      "bbox": [
        -5.746230777965992,
        40.96482501298126,
        -5.746230777965992,
        40.96482501298126
      ]
    },
    "37118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.525361774341123,
              40.768794528501616
            ]
          },
          "bbox": [
            -5.525361774341123,
            40.768794528501616,
            -5.525361774341123,
            40.768794528501616
          ]
        }
      ],
      "bbox": [
        -5.525361774341123,
        40.768794528501616,
        -5.525361774341123,
        40.768794528501616
      ]
    },
    "37119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.536965471490556,
              40.51605124737041
            ]
          },
          "bbox": [
            -6.536965471490556,
            40.51605124737041,
            -6.536965471490556,
            40.51605124737041
          ]
        }
      ],
      "bbox": [
        -6.536965471490556,
        40.51605124737041,
        -6.536965471490556,
        40.51605124737041
      ]
    },
    "37120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.103113215773045,
              41.012444937538476
            ]
          },
          "bbox": [
            -6.103113215773045,
            41.012444937538476,
            -6.103113215773045,
            41.012444937538476
          ]
        }
      ],
      "bbox": [
        -6.103113215773045,
        41.012444937538476,
        -6.103113215773045,
        41.012444937538476
      ]
    },
    "37121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.457851398996679,
              40.917290228015666
            ]
          },
          "bbox": [
            -5.457851398996679,
            40.917290228015666,
            -5.457851398996679,
            40.917290228015666
          ]
        }
      ],
      "bbox": [
        -5.457851398996679,
        40.917290228015666,
        -5.457851398996679,
        40.917290228015666
      ]
    },
    "37122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.565454593622156,
              40.76897824028062
            ]
          },
          "bbox": [
            -5.565454593622156,
            40.76897824028062,
            -5.565454593622156,
            40.76897824028062
          ]
        }
      ],
      "bbox": [
        -5.565454593622156,
        40.76897824028062,
        -5.565454593622156,
        40.76897824028062
      ]
    },
    "37123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.543729776583124,
              41.02636812531314
            ]
          },
          "bbox": [
            -6.543729776583124,
            41.02636812531314,
            -6.543729776583124,
            41.02636812531314
          ]
        }
      ],
      "bbox": [
        -6.543729776583124,
        41.02636812531314,
        -6.543729776583124,
        41.02636812531314
      ]
    },
    "37124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.812551831611896,
              40.59487513970917
            ]
          },
          "bbox": [
            -5.812551831611896,
            40.59487513970917,
            -5.812551831611896,
            40.59487513970917
          ]
        }
      ],
      "bbox": [
        -5.812551831611896,
        40.59487513970917,
        -5.812551831611896,
        40.59487513970917
      ]
    },
    "37125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.957216900946169,
              40.612737295946836
            ]
          },
          "bbox": [
            -5.957216900946169,
            40.612737295946836,
            -5.957216900946169,
            40.612737295946836
          ]
        }
      ],
      "bbox": [
        -5.957216900946169,
        40.612737295946836,
        -5.957216900946169,
        40.612737295946836
      ]
    },
    "37126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.27325261708825,
              41.05162471530497
            ]
          },
          "bbox": [
            -6.27325261708825,
            41.05162471530497,
            -6.27325261708825,
            41.05162471530497
          ]
        }
      ],
      "bbox": [
        -6.27325261708825,
        41.05162471530497,
        -6.27325261708825,
        41.05162471530497
      ]
    },
    "37127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.745611357418079,
              40.51823553742309
            ]
          },
          "bbox": [
            -6.745611357418079,
            40.51823553742309,
            -6.745611357418079,
            40.51823553742309
          ]
        }
      ],
      "bbox": [
        -6.745611357418079,
        40.51823553742309,
        -6.745611357418079,
        40.51823553742309
      ]
    },
    "37128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.41487798906102,
              41.119708605074294
            ]
          },
          "bbox": [
            -5.41487798906102,
            41.119708605074294,
            -5.41487798906102,
            41.119708605074294
          ]
        }
      ],
      "bbox": [
        -5.41487798906102,
        41.119708605074294,
        -5.41487798906102,
        41.119708605074294
      ]
    },
    "37129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.763467256322845,
              41.01747081341571
            ]
          },
          "bbox": [
            -5.763467256322845,
            41.01747081341571,
            -5.763467256322845,
            41.01747081341571
          ]
        }
      ],
      "bbox": [
        -5.763467256322845,
        41.01747081341571,
        -5.763467256322845,
        41.01747081341571
      ]
    },
    "37130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.749105726394874,
              41.112950554596146
            ]
          },
          "bbox": [
            -5.749105726394874,
            41.112950554596146,
            -5.749105726394874,
            41.112950554596146
          ]
        }
      ],
      "bbox": [
        -5.749105726394874,
        41.112950554596146,
        -5.749105726394874,
        41.112950554596146
      ]
    },
    "37131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.774276164682701,
              40.65869834360865
            ]
          },
          "bbox": [
            -5.774276164682701,
            40.65869834360865,
            -5.774276164682701,
            40.65869834360865
          ]
        }
      ],
      "bbox": [
        -5.774276164682701,
        40.65869834360865,
        -5.774276164682701,
        40.65869834360865
      ]
    },
    "37132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.878589375845627,
              40.999486551184745
            ]
          },
          "bbox": [
            -6.878589375845627,
            40.999486551184745,
            -6.878589375845627,
            40.999486551184745
          ]
        }
      ],
      "bbox": [
        -6.878589375845627,
        40.999486551184745,
        -6.878589375845627,
        40.999486551184745
      ]
    },
    "37133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.714846946241082,
              40.43738489807864
            ]
          },
          "bbox": [
            -5.714846946241082,
            40.43738489807864,
            -5.714846946241082,
            40.43738489807864
          ]
        }
      ],
      "bbox": [
        -5.714846946241082,
        40.43738489807864,
        -5.714846946241082,
        40.43738489807864
      ]
    },
    "37134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.628358519737182,
              40.711670042532056
            ]
          },
          "bbox": [
            -5.628358519737182,
            40.711670042532056,
            -5.628358519737182,
            40.711670042532056
          ]
        }
      ],
      "bbox": [
        -5.628358519737182,
        40.711670042532056,
        -5.628358519737182,
        40.711670042532056
      ]
    },
    "37135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.209030546969681,
              40.79713109059277
            ]
          },
          "bbox": [
            -6.209030546969681,
            40.79713109059277,
            -6.209030546969681,
            40.79713109059277
          ]
        }
      ],
      "bbox": [
        -6.209030546969681,
        40.79713109059277,
        -6.209030546969681,
        40.79713109059277
      ]
    },
    "37136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.672743872141232,
              40.42214264881618
            ]
          },
          "bbox": [
            -6.672743872141232,
            40.42214264881618,
            -6.672743872141232,
            40.42214264881618
          ]
        }
      ],
      "bbox": [
        -6.672743872141232,
        40.42214264881618,
        -6.672743872141232,
        40.42214264881618
      ]
    },
    "37137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.569702068497855,
              40.84169155621993
            ]
          },
          "bbox": [
            -6.569702068497855,
            40.84169155621993,
            -6.569702068497855,
            40.84169155621993
          ]
        }
      ],
      "bbox": [
        -6.569702068497855,
        40.84169155621993,
        -6.569702068497855,
        40.84169155621993
      ]
    },
    "37138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.740254273444641,
              40.549615660417835
            ]
          },
          "bbox": [
            -5.740254273444641,
            40.549615660417835,
            -5.740254273444641,
            40.549615660417835
          ]
        }
      ],
      "bbox": [
        -5.740254273444641,
        40.549615660417835,
        -5.740254273444641,
        40.549615660417835
      ]
    },
    "37139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.698662411059971,
              40.50964233470994
            ]
          },
          "bbox": [
            -5.698662411059971,
            40.50964233470994,
            -5.698662411059971,
            40.50964233470994
          ]
        }
      ],
      "bbox": [
        -5.698662411059971,
        40.50964233470994,
        -5.698662411059971,
        40.50964233470994
      ]
    },
    "37140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.789914735746042,
              40.57570350187601
            ]
          },
          "bbox": [
            -6.789914735746042,
            40.57570350187601,
            -6.789914735746042,
            40.57570350187601
          ]
        }
      ],
      "bbox": [
        -6.789914735746042,
        40.57570350187601,
        -6.789914735746042,
        40.57570350187601
      ]
    },
    "37141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.363588616480131,
              40.792906183197516
            ]
          },
          "bbox": [
            -5.363588616480131,
            40.792906183197516,
            -5.363588616480131,
            40.792906183197516
          ]
        }
      ],
      "bbox": [
        -5.363588616480131,
        40.792906183197516,
        -5.363588616480131,
        40.792906183197516
      ]
    },
    "37142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.830967323822847,
              40.944851791679326
            ]
          },
          "bbox": [
            -5.830967323822847,
            40.944851791679326,
            -5.830967323822847,
            40.944851791679326
          ]
        }
      ],
      "bbox": [
        -5.830967323822847,
        40.944851791679326,
        -5.830967323822847,
        40.944851791679326
      ]
    },
    "37143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.526978464703799,
              40.662210540659444
            ]
          },
          "bbox": [
            -5.526978464703799,
            40.662210540659444,
            -5.526978464703799,
            40.662210540659444
          ]
        }
      ],
      "bbox": [
        -5.526978464703799,
        40.662210540659444,
        -5.526978464703799,
        40.662210540659444
      ]
    },
    "37144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.545739097497873,
              40.7216829328609
            ]
          },
          "bbox": [
            -5.545739097497873,
            40.7216829328609,
            -5.545739097497873,
            40.7216829328609
          ]
        }
      ],
      "bbox": [
        -5.545739097497873,
        40.7216829328609,
        -5.545739097497873,
        40.7216829328609
      ]
    },
    "37145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.696830806380716,
              40.62363695327962
            ]
          },
          "bbox": [
            -6.696830806380716,
            40.62363695327962,
            -6.696830806380716,
            40.62363695327962
          ]
        }
      ],
      "bbox": [
        -6.696830806380716,
        40.62363695327962,
        -6.696830806380716,
        40.62363695327962
      ]
    },
    "37146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.463047783937223,
              40.533869355965464
            ]
          },
          "bbox": [
            -5.463047783937223,
            40.533869355965464,
            -5.463047783937223,
            40.533869355965464
          ]
        }
      ],
      "bbox": [
        -5.463047783937223,
        40.533869355965464,
        -5.463047783937223,
        40.533869355965464
      ]
    },
    "37147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.971449272426605,
              40.50455633784378
            ]
          },
          "bbox": [
            -5.971449272426605,
            40.50455633784378,
            -5.971449272426605,
            40.50455633784378
          ]
        }
      ],
      "bbox": [
        -5.971449272426605,
        40.50455633784378,
        -5.971449272426605,
        40.50455633784378
      ]
    },
    "37148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.439562158696959,
              40.86924149251557
            ]
          },
          "bbox": [
            -5.439562158696959,
            40.86924149251557,
            -5.439562158696959,
            40.86924149251557
          ]
        }
      ],
      "bbox": [
        -5.439562158696959,
        40.86924149251557,
        -5.439562158696959,
        40.86924149251557
      ]
    },
    "37149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.13974645936083,
              40.89034874750203
            ]
          },
          "bbox": [
            -6.13974645936083,
            40.89034874750203,
            -6.13974645936083,
            40.89034874750203
          ]
        }
      ],
      "bbox": [
        -6.13974645936083,
        40.89034874750203,
        -6.13974645936083,
        40.89034874750203
      ]
    },
    "37150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.109925837521574,
              41.065010199828606
            ]
          },
          "bbox": [
            -6.109925837521574,
            41.065010199828606,
            -6.109925837521574,
            41.065010199828606
          ]
        }
      ],
      "bbox": [
        -6.109925837521574,
        41.065010199828606,
        -6.109925837521574,
        41.065010199828606
      ]
    },
    "37151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.910347105029856,
              40.99996571772343
            ]
          },
          "bbox": [
            -5.910347105029856,
            40.99996571772343,
            -5.910347105029856,
            40.99996571772343
          ]
        }
      ],
      "bbox": [
        -5.910347105029856,
        40.99996571772343,
        -5.910347105029856,
        40.99996571772343
      ]
    },
    "37152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.532373683782907,
              41.032893338727476
            ]
          },
          "bbox": [
            -5.532373683782907,
            41.032893338727476,
            -5.532373683782907,
            41.032893338727476
          ]
        }
      ],
      "bbox": [
        -5.532373683782907,
        41.032893338727476,
        -5.532373683782907,
        41.032893338727476
      ]
    },
    "37154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.49488606461826,
              41.01470226677555
            ]
          },
          "bbox": [
            -6.49488606461826,
            41.01470226677555,
            -6.49488606461826,
            41.01470226677555
          ]
        }
      ],
      "bbox": [
        -6.49488606461826,
        41.01470226677555,
        -6.49488606461826,
        41.01470226677555
      ]
    },
    "37155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.634014271099439,
              40.52092623378834
            ]
          },
          "bbox": [
            -5.634014271099439,
            40.52092623378834,
            -5.634014271099439,
            40.52092623378834
          ]
        }
      ],
      "bbox": [
        -5.634014271099439,
        40.52092623378834,
        -5.634014271099439,
        40.52092623378834
      ]
    },
    "37156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.689988105098779,
              40.573557355181926
            ]
          },
          "bbox": [
            -5.689988105098779,
            40.573557355181926,
            -5.689988105098779,
            40.573557355181926
          ]
        }
      ],
      "bbox": [
        -5.689988105098779,
        40.573557355181926,
        -5.689988105098779,
        40.573557355181926
      ]
    },
    "37157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.515297997096374,
              40.43808661489236
            ]
          },
          "bbox": [
            -6.515297997096374,
            40.43808661489236,
            -6.515297997096374,
            40.43808661489236
          ]
        }
      ],
      "bbox": [
        -6.515297997096374,
        40.43808661489236,
        -6.515297997096374,
        40.43808661489236
      ]
    },
    "37158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.081572363862924,
              40.431253361799286
            ]
          },
          "bbox": [
            -6.081572363862924,
            40.431253361799286,
            -6.081572363862924,
            40.431253361799286
          ]
        }
      ],
      "bbox": [
        -6.081572363862924,
        40.431253361799286,
        -6.081572363862924,
        40.431253361799286
      ]
    },
    "37159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.881520000943105,
              40.61501531784803
            ]
          },
          "bbox": [
            -5.881520000943105,
            40.61501531784803,
            -5.881520000943105,
            40.61501531784803
          ]
        }
      ],
      "bbox": [
        -5.881520000943105,
        40.61501531784803,
        -5.881520000943105,
        40.61501531784803
      ]
    },
    "37160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.778660214391635,
              40.99257431287896
            ]
          },
          "bbox": [
            -6.778660214391635,
            40.99257431287896,
            -6.778660214391635,
            40.99257431287896
          ]
        }
      ],
      "bbox": [
        -6.778660214391635,
        40.99257431287896,
        -6.778660214391635,
        40.99257431287896
      ]
    },
    "37161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.890684621511646,
              40.431040199194335
            ]
          },
          "bbox": [
            -5.890684621511646,
            40.431040199194335,
            -5.890684621511646,
            40.431040199194335
          ]
        }
      ],
      "bbox": [
        -5.890684621511646,
        40.431040199194335,
        -5.890684621511646,
        40.431040199194335
      ]
    },
    "37162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.443857323984261,
              40.64923999237768
            ]
          },
          "bbox": [
            -5.443857323984261,
            40.64923999237768,
            -5.443857323984261,
            40.64923999237768
          ]
        }
      ],
      "bbox": [
        -5.443857323984261,
        40.64923999237768,
        -5.443857323984261,
        40.64923999237768
      ]
    },
    "37163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.692380878295966,
              40.39240564281087
            ]
          },
          "bbox": [
            -5.692380878295966,
            40.39240564281087,
            -5.692380878295966,
            40.39240564281087
          ]
        }
      ],
      "bbox": [
        -5.692380878295966,
        40.39240564281087,
        -5.692380878295966,
        40.39240564281087
      ]
    },
    "37164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.462166640025666,
              40.96396671103241
            ]
          },
          "bbox": [
            -5.462166640025666,
            40.96396671103241,
            -5.462166640025666,
            40.96396671103241
          ]
        }
      ],
      "bbox": [
        -5.462166640025666,
        40.96396671103241,
        -5.462166640025666,
        40.96396671103241
      ]
    },
    "37165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.332512607735542,
              41.14547613792752
            ]
          },
          "bbox": [
            -6.332512607735542,
            41.14547613792752,
            -6.332512607735542,
            41.14547613792752
          ]
        }
      ],
      "bbox": [
        -6.332512607735542,
        41.14547613792752,
        -6.332512607735542,
        41.14547613792752
      ]
    },
    "37166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6746490064642465,
              40.488950273322075
            ]
          },
          "bbox": [
            -6.6746490064642465,
            40.488950273322075,
            -6.6746490064642465,
            40.488950273322075
          ]
        }
      ],
      "bbox": [
        -6.6746490064642465,
        40.488950273322075,
        -6.6746490064642465,
        40.488950273322075
      ]
    },
    "37167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.892353790144076,
              41.085908399808865
            ]
          },
          "bbox": [
            -5.892353790144076,
            41.085908399808865,
            -5.892353790144076,
            41.085908399808865
          ]
        }
      ],
      "bbox": [
        -5.892353790144076,
        41.085908399808865,
        -5.892353790144076,
        41.085908399808865
      ]
    },
    "37168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.972868409494182,
              40.3297720484713
            ]
          },
          "bbox": [
            -5.972868409494182,
            40.3297720484713,
            -5.972868409494182,
            40.3297720484713
          ]
        }
      ],
      "bbox": [
        -5.972868409494182,
        40.3297720484713,
        -5.972868409494182,
        40.3297720484713
      ]
    },
    "37169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.42781325296642,
              40.71095550472921
            ]
          },
          "bbox": [
            -5.42781325296642,
            40.71095550472921,
            -5.42781325296642,
            40.71095550472921
          ]
        }
      ],
      "bbox": [
        -5.42781325296642,
        40.71095550472921,
        -5.42781325296642,
        40.71095550472921
      ]
    },
    "37170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.019367861514829,
              41.13399742226577
            ]
          },
          "bbox": [
            -6.019367861514829,
            41.13399742226577,
            -6.019367861514829,
            41.13399742226577
          ]
        }
      ],
      "bbox": [
        -6.019367861514829,
        41.13399742226577,
        -6.019367861514829,
        41.13399742226577
      ]
    },
    "37171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.724168833426362,
              40.476288526634036
            ]
          },
          "bbox": [
            -5.724168833426362,
            40.476288526634036,
            -5.724168833426362,
            40.476288526634036
          ]
        }
      ],
      "bbox": [
        -5.724168833426362,
        40.476288526634036,
        -5.724168833426362,
        40.476288526634036
      ]
    },
    "37172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.918865945080269,
              40.58060331022971
            ]
          },
          "bbox": [
            -5.918865945080269,
            40.58060331022971,
            -5.918865945080269,
            40.58060331022971
          ]
        }
      ],
      "bbox": [
        -5.918865945080269,
        40.58060331022971,
        -5.918865945080269,
        40.58060331022971
      ]
    },
    "37173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.694472028029341,
              40.94367836008931
            ]
          },
          "bbox": [
            -6.694472028029341,
            40.94367836008931,
            -6.694472028029341,
            40.94367836008931
          ]
        }
      ],
      "bbox": [
        -6.694472028029341,
        40.94367836008931,
        -6.694472028029341,
        40.94367836008931
      ]
    },
    "37174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.296586399999067,
              40.83728976178859
            ]
          },
          "bbox": [
            -5.296586399999067,
            40.83728976178859,
            -5.296586399999067,
            40.83728976178859
          ]
        }
      ],
      "bbox": [
        -5.296586399999067,
        40.83728976178859,
        -5.296586399999067,
        40.83728976178859
      ]
    },
    "37175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.511323531871621,
              40.92174090219408
            ]
          },
          "bbox": [
            -5.511323531871621,
            40.92174090219408,
            -5.511323531871621,
            40.92174090219408
          ]
        }
      ],
      "bbox": [
        -5.511323531871621,
        40.92174090219408,
        -5.511323531871621,
        40.92174090219408
      ]
    },
    "37176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.063182364631405,
              40.463639809092264
            ]
          },
          "bbox": [
            -6.063182364631405,
            40.463639809092264,
            -6.063182364631405,
            40.463639809092264
          ]
        }
      ],
      "bbox": [
        -6.063182364631405,
        40.463639809092264,
        -6.063182364631405,
        40.463639809092264
      ]
    },
    "37177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.208452802928895,
              40.55756372978715
            ]
          },
          "bbox": [
            -6.208452802928895,
            40.55756372978715,
            -6.208452802928895,
            40.55756372978715
          ]
        }
      ],
      "bbox": [
        -6.208452802928895,
        40.55756372978715,
        -6.208452802928895,
        40.55756372978715
      ]
    },
    "37178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.238249641817136,
              40.76098505037795
            ]
          },
          "bbox": [
            -5.238249641817136,
            40.76098505037795,
            -5.238249641817136,
            40.76098505037795
          ]
        }
      ],
      "bbox": [
        -5.238249641817136,
        40.76098505037795,
        -5.238249641817136,
        40.76098505037795
      ]
    },
    "37179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.189442646366672,
              40.835945914292296
            ]
          },
          "bbox": [
            -5.189442646366672,
            40.835945914292296,
            -5.189442646366672,
            40.835945914292296
          ]
        }
      ],
      "bbox": [
        -5.189442646366672,
        40.835945914292296,
        -5.189442646366672,
        40.835945914292296
      ]
    },
    "37180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.304829924021473,
              41.175620105892236
            ]
          },
          "bbox": [
            -6.304829924021473,
            41.175620105892236,
            -6.304829924021473,
            41.175620105892236
          ]
        }
      ],
      "bbox": [
        -6.304829924021473,
        41.175620105892236,
        -6.304829924021473,
        41.175620105892236
      ]
    },
    "37181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4788735427040285,
              40.43833940297899
            ]
          },
          "bbox": [
            -6.4788735427040285,
            40.43833940297899,
            -6.4788735427040285,
            40.43833940297899
          ]
        }
      ],
      "bbox": [
        -6.4788735427040285,
        40.43833940297899,
        -6.4788735427040285,
        40.43833940297899
      ]
    },
    "37182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.590708279483555,
              40.7991900732279
            ]
          },
          "bbox": [
            -5.590708279483555,
            40.7991900732279,
            -5.590708279483555,
            40.7991900732279
          ]
        }
      ],
      "bbox": [
        -5.590708279483555,
        40.7991900732279,
        -5.590708279483555,
        40.7991900732279
      ]
    },
    "37183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2899944847507,
              40.75131193140386
            ]
          },
          "bbox": [
            -6.2899944847507,
            40.75131193140386,
            -6.2899944847507,
            40.75131193140386
          ]
        }
      ],
      "bbox": [
        -6.2899944847507,
        40.75131193140386,
        -6.2899944847507,
        40.75131193140386
      ]
    },
    "37184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.5882971303709645,
              41.197888945996915
            ]
          },
          "bbox": [
            -6.5882971303709645,
            41.197888945996915,
            -6.5882971303709645,
            41.197888945996915
          ]
        }
      ],
      "bbox": [
        -6.5882971303709645,
        41.197888945996915,
        -6.5882971303709645,
        41.197888945996915
      ]
    },
    "37185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.667004030423587,
              41.05656387249036
            ]
          },
          "bbox": [
            -5.667004030423587,
            41.05656387249036,
            -5.667004030423587,
            41.05656387249036
          ]
        }
      ],
      "bbox": [
        -5.667004030423587,
        41.05656387249036,
        -5.667004030423587,
        41.05656387249036
      ]
    },
    "37186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.980957595181238,
              40.98446392000127
            ]
          },
          "bbox": [
            -5.980957595181238,
            40.98446392000127,
            -5.980957595181238,
            40.98446392000127
          ]
        }
      ],
      "bbox": [
        -5.980957595181238,
        40.98446392000127,
        -5.980957595181238,
        40.98446392000127
      ]
    },
    "37187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.924842766577223,
              40.827378089586254
            ]
          },
          "bbox": [
            -5.924842766577223,
            40.827378089586254,
            -5.924842766577223,
            40.827378089586254
          ]
        }
      ],
      "bbox": [
        -5.924842766577223,
        40.827378089586254,
        -5.924842766577223,
        40.827378089586254
      ]
    },
    "37188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.616358867078296,
              40.69017135549377
            ]
          },
          "bbox": [
            -5.616358867078296,
            40.69017135549377,
            -5.616358867078296,
            40.69017135549377
          ]
        }
      ],
      "bbox": [
        -5.616358867078296,
        40.69017135549377,
        -5.616358867078296,
        40.69017135549377
      ]
    },
    "37189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.797449335343543,
              40.703305679859994
            ]
          },
          "bbox": [
            -5.797449335343543,
            40.703305679859994,
            -5.797449335343543,
            40.703305679859994
          ]
        }
      ],
      "bbox": [
        -5.797449335343543,
        40.703305679859994,
        -5.797449335343543,
        40.703305679859994
      ]
    },
    "37190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.696019790679125,
              41.15784204484814
            ]
          },
          "bbox": [
            -6.696019790679125,
            41.15784204484814,
            -6.696019790679125,
            41.15784204484814
          ]
        }
      ],
      "bbox": [
        -6.696019790679125,
        41.15784204484814,
        -6.696019790679125,
        41.15784204484814
      ]
    },
    "37191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.604221327413796,
              41.09742761327874
            ]
          },
          "bbox": [
            -6.604221327413796,
            41.09742761327874,
            -6.604221327413796,
            41.09742761327874
          ]
        }
      ],
      "bbox": [
        -6.604221327413796,
        41.09742761327874,
        -6.604221327413796,
        41.09742761327874
      ]
    },
    "37192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.694355360470429,
              40.876648982187334
            ]
          },
          "bbox": [
            -5.694355360470429,
            40.876648982187334,
            -5.694355360470429,
            40.876648982187334
          ]
        }
      ],
      "bbox": [
        -5.694355360470429,
        40.876648982187334,
        -5.694355360470429,
        40.876648982187334
      ]
    },
    "37193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9923318947088715,
              40.46937683651564
            ]
          },
          "bbox": [
            -5.9923318947088715,
            40.46937683651564,
            -5.9923318947088715,
            40.46937683651564
          ]
        }
      ],
      "bbox": [
        -5.9923318947088715,
        40.46937683651564,
        -5.9923318947088715,
        40.46937683651564
      ]
    },
    "37194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.05973592923278,
              40.500870764869454
            ]
          },
          "bbox": [
            -6.05973592923278,
            40.500870764869454,
            -6.05973592923278,
            40.500870764869454
          ]
        }
      ],
      "bbox": [
        -6.05973592923278,
        40.500870764869454,
        -6.05973592923278,
        40.500870764869454
      ]
    },
    "37195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9472060217535665,
              40.47313526344722
            ]
          },
          "bbox": [
            -5.9472060217535665,
            40.47313526344722,
            -5.9472060217535665,
            40.47313526344722
          ]
        }
      ],
      "bbox": [
        -5.9472060217535665,
        40.47313526344722,
        -5.9472060217535665,
        40.47313526344722
      ]
    },
    "37196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.061580318598385,
              40.47940974862317
            ]
          },
          "bbox": [
            -6.061580318598385,
            40.47940974862317,
            -6.061580318598385,
            40.47940974862317
          ]
        }
      ],
      "bbox": [
        -6.061580318598385,
        40.47940974862317,
        -6.061580318598385,
        40.47940974862317
      ]
    },
    "37197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.849018038302537,
              40.581781143075226
            ]
          },
          "bbox": [
            -5.849018038302537,
            40.581781143075226,
            -5.849018038302537,
            40.581781143075226
          ]
        }
      ],
      "bbox": [
        -5.849018038302537,
        40.581781143075226,
        -5.849018038302537,
        40.581781143075226
      ]
    },
    "37198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.22610356850175,
              41.190161782056144
            ]
          },
          "bbox": [
            -6.22610356850175,
            41.190161782056144,
            -6.22610356850175,
            41.190161782056144
          ]
        }
      ],
      "bbox": [
        -6.22610356850175,
        41.190161782056144,
        -6.22610356850175,
        41.190161782056144
      ]
    },
    "37199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.24168643707266,
              40.50262020190837
            ]
          },
          "bbox": [
            -6.24168643707266,
            40.50262020190837,
            -6.24168643707266,
            40.50262020190837
          ]
        }
      ],
      "bbox": [
        -6.24168643707266,
        40.50262020190837,
        -6.24168643707266,
        40.50262020190837
      ]
    },
    "37200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.623252464065647,
              40.64541467349803
            ]
          },
          "bbox": [
            -5.623252464065647,
            40.64541467349803,
            -5.623252464065647,
            40.64541467349803
          ]
        }
      ],
      "bbox": [
        -5.623252464065647,
        40.64541467349803,
        -5.623252464065647,
        40.64541467349803
      ]
    },
    "37201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.892348242557761,
              40.35213307796147
            ]
          },
          "bbox": [
            -5.892348242557761,
            40.35213307796147,
            -5.892348242557761,
            40.35213307796147
          ]
        }
      ],
      "bbox": [
        -5.892348242557761,
        40.35213307796147,
        -5.892348242557761,
        40.35213307796147
      ]
    },
    "37202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.651139560209109,
              41.03803034617865
            ]
          },
          "bbox": [
            -5.651139560209109,
            41.03803034617865,
            -5.651139560209109,
            41.03803034617865
          ]
        }
      ],
      "bbox": [
        -5.651139560209109,
        41.03803034617865,
        -5.651139560209109,
        41.03803034617865
      ]
    },
    "37203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.695865818003466,
              40.757182039644015
            ]
          },
          "bbox": [
            -5.695865818003466,
            40.757182039644015,
            -5.695865818003466,
            40.757182039644015
          ]
        }
      ],
      "bbox": [
        -5.695865818003466,
        40.757182039644015,
        -5.695865818003466,
        40.757182039644015
      ]
    },
    "37204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.284066557963496,
              40.59616062087672
            ]
          },
          "bbox": [
            -6.284066557963496,
            40.59616062087672,
            -6.284066557963496,
            40.59616062087672
          ]
        }
      ],
      "bbox": [
        -6.284066557963496,
        40.59616062087672,
        -6.284066557963496,
        40.59616062087672
      ]
    },
    "37205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.700548609969327,
              40.80664105238819
            ]
          },
          "bbox": [
            -5.700548609969327,
            40.80664105238819,
            -5.700548609969327,
            40.80664105238819
          ]
        }
      ],
      "bbox": [
        -5.700548609969327,
        40.80664105238819,
        -5.700548609969327,
        40.80664105238819
      ]
    },
    "37206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.395980659851244,
              40.96400941683353
            ]
          },
          "bbox": [
            -5.395980659851244,
            40.96400941683353,
            -5.395980659851244,
            40.96400941683353
          ]
        }
      ],
      "bbox": [
        -5.395980659851244,
        40.96400941683353,
        -5.395980659851244,
        40.96400941683353
      ]
    },
    "37207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.574989197293918,
              41.00341008362801
            ]
          },
          "bbox": [
            -5.574989197293918,
            41.00341008362801,
            -5.574989197293918,
            41.00341008362801
          ]
        }
      ],
      "bbox": [
        -5.574989197293918,
        41.00341008362801,
        -5.574989197293918,
        41.00341008362801
      ]
    },
    "37208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.422697773615446,
              40.96038027594649
            ]
          },
          "bbox": [
            -6.422697773615446,
            40.96038027594649,
            -6.422697773615446,
            40.96038027594649
          ]
        }
      ],
      "bbox": [
        -6.422697773615446,
        40.96038027594649,
        -6.422697773615446,
        40.96038027594649
      ]
    },
    "37209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.685895238982648,
              40.84478406856198
            ]
          },
          "bbox": [
            -5.685895238982648,
            40.84478406856198,
            -5.685895238982648,
            40.84478406856198
          ]
        }
      ],
      "bbox": [
        -5.685895238982648,
        40.84478406856198,
        -5.685895238982648,
        40.84478406856198
      ]
    },
    "37211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.901471122211743,
              40.70221363422047
            ]
          },
          "bbox": [
            -5.901471122211743,
            40.70221363422047,
            -5.901471122211743,
            40.70221363422047
          ]
        }
      ],
      "bbox": [
        -5.901471122211743,
        40.70221363422047,
        -5.901471122211743,
        40.70221363422047
      ]
    },
    "37212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.705769493413865,
              40.38404138872572
            ]
          },
          "bbox": [
            -5.705769493413865,
            40.38404138872572,
            -5.705769493413865,
            40.38404138872572
          ]
        }
      ],
      "bbox": [
        -5.705769493413865,
        40.38404138872572,
        -5.705769493413865,
        40.38404138872572
      ]
    },
    "37213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6800603700547905,
              40.47664970919972
            ]
          },
          "bbox": [
            -5.6800603700547905,
            40.47664970919972,
            -5.6800603700547905,
            40.47664970919972
          ]
        }
      ],
      "bbox": [
        -5.6800603700547905,
        40.47664970919972,
        -5.6800603700547905,
        40.47664970919972
      ]
    },
    "37214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.122709445242739,
              40.52984959382713
            ]
          },
          "bbox": [
            -6.122709445242739,
            40.52984959382713,
            -6.122709445242739,
            40.52984959382713
          ]
        }
      ],
      "bbox": [
        -6.122709445242739,
        40.52984959382713,
        -6.122709445242739,
        40.52984959382713
      ]
    },
    "37215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.285383538686038,
              40.89300237144596
            ]
          },
          "bbox": [
            -5.285383538686038,
            40.89300237144596,
            -5.285383538686038,
            40.89300237144596
          ]
        }
      ],
      "bbox": [
        -5.285383538686038,
        40.89300237144596,
        -5.285383538686038,
        40.89300237144596
      ]
    },
    "37216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.477056442909119,
              40.77996946518684
            ]
          },
          "bbox": [
            -5.477056442909119,
            40.77996946518684,
            -5.477056442909119,
            40.77996946518684
          ]
        }
      ],
      "bbox": [
        -5.477056442909119,
        40.77996946518684,
        -5.477056442909119,
        40.77996946518684
      ]
    },
    "37217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.790205450876482,
              40.43168561393283
            ]
          },
          "bbox": [
            -5.790205450876482,
            40.43168561393283,
            -5.790205450876482,
            40.43168561393283
          ]
        }
      ],
      "bbox": [
        -5.790205450876482,
        40.43168561393283,
        -5.790205450876482,
        40.43168561393283
      ]
    },
    "37218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4861310539740265,
              40.480575713897444
            ]
          },
          "bbox": [
            -5.4861310539740265,
            40.480575713897444,
            -5.4861310539740265,
            40.480575713897444
          ]
        }
      ],
      "bbox": [
        -5.4861310539740265,
        40.480575713897444,
        -5.4861310539740265,
        40.480575713897444
      ]
    },
    "37219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0051394676693715,
              40.6001732585647
            ]
          },
          "bbox": [
            -6.0051394676693715,
            40.6001732585647,
            -6.0051394676693715,
            40.6001732585647
          ]
        }
      ],
      "bbox": [
        -6.0051394676693715,
        40.6001732585647,
        -6.0051394676693715,
        40.6001732585647
      ]
    },
    "37221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.812551794565681,
              40.287276549909215
            ]
          },
          "bbox": [
            -6.812551794565681,
            40.287276549909215,
            -6.812551794565681,
            40.287276549909215
          ]
        }
      ],
      "bbox": [
        -6.812551794565681,
        40.287276549909215,
        -6.812551794565681,
        40.287276549909215
      ]
    },
    "37222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.605026546715971,
              41.11403654067894
            ]
          },
          "bbox": [
            -5.605026546715971,
            41.11403654067894,
            -5.605026546715971,
            41.11403654067894
          ]
        }
      ],
      "bbox": [
        -5.605026546715971,
        41.11403654067894,
        -5.605026546715971,
        41.11403654067894
      ]
    },
    "37223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.579231015957107,
              40.87224322956369
            ]
          },
          "bbox": [
            -6.579231015957107,
            40.87224322956369,
            -6.579231015957107,
            40.87224322956369
          ]
        }
      ],
      "bbox": [
        -6.579231015957107,
        40.87224322956369,
        -6.579231015957107,
        40.87224322956369
      ]
    },
    "37224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.467000294558086,
              41.10672726502572
            ]
          },
          "bbox": [
            -5.467000294558086,
            41.10672726502572,
            -5.467000294558086,
            41.10672726502572
          ]
        }
      ],
      "bbox": [
        -5.467000294558086,
        41.10672726502572,
        -5.467000294558086,
        41.10672726502572
      ]
    },
    "37225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.511241260465484,
              41.09942472455076
            ]
          },
          "bbox": [
            -5.511241260465484,
            41.09942472455076,
            -5.511241260465484,
            41.09942472455076
          ]
        }
      ],
      "bbox": [
        -5.511241260465484,
        41.09942472455076,
        -5.511241260465484,
        41.09942472455076
      ]
    },
    "37226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8832071679168365,
              41.17046880854404
            ]
          },
          "bbox": [
            -5.8832071679168365,
            41.17046880854404,
            -5.8832071679168365,
            41.17046880854404
          ]
        }
      ],
      "bbox": [
        -5.8832071679168365,
        41.17046880854404,
        -5.8832071679168365,
        41.17046880854404
      ]
    },
    "37228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1898890129502755,
              41.055383651579696
            ]
          },
          "bbox": [
            -5.1898890129502755,
            41.055383651579696,
            -5.1898890129502755,
            41.055383651579696
          ]
        }
      ],
      "bbox": [
        -5.1898890129502755,
        41.055383651579696,
        -5.1898890129502755,
        41.055383651579696
      ]
    },
    "37229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.621713251888718,
              41.10916610247056
            ]
          },
          "bbox": [
            -5.621713251888718,
            41.10916610247056,
            -5.621713251888718,
            41.10916610247056
          ]
        }
      ],
      "bbox": [
        -5.621713251888718,
        41.10916610247056,
        -5.621713251888718,
        41.10916610247056
      ]
    },
    "37230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.810197255310922,
              40.977446281160795
            ]
          },
          "bbox": [
            -5.810197255310922,
            40.977446281160795,
            -5.810197255310922,
            40.977446281160795
          ]
        }
      ],
      "bbox": [
        -5.810197255310922,
        40.977446281160795,
        -5.810197255310922,
        40.977446281160795
      ]
    },
    "37231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.436970634576115,
              41.160495739974145
            ]
          },
          "bbox": [
            -5.436970634576115,
            41.160495739974145,
            -5.436970634576115,
            41.160495739974145
          ]
        }
      ],
      "bbox": [
        -5.436970634576115,
        41.160495739974145,
        -5.436970634576115,
        41.160495739974145
      ]
    },
    "37232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.16609045043712,
              40.97047826562337
            ]
          },
          "bbox": [
            -5.16609045043712,
            40.97047826562337,
            -5.16609045043712,
            40.97047826562337
          ]
        }
      ],
      "bbox": [
        -5.16609045043712,
        40.97047826562337,
        -5.16609045043712,
        40.97047826562337
      ]
    },
    "37233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.498751870587688,
              40.52014966602424
            ]
          },
          "bbox": [
            -6.498751870587688,
            40.52014966602424,
            -6.498751870587688,
            40.52014966602424
          ]
        }
      ],
      "bbox": [
        -6.498751870587688,
        40.52014966602424,
        -6.498751870587688,
        40.52014966602424
      ]
    },
    "37234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.734914899094927,
              40.29397537898547
            ]
          },
          "bbox": [
            -6.734914899094927,
            40.29397537898547,
            -6.734914899094927,
            40.29397537898547
          ]
        }
      ],
      "bbox": [
        -6.734914899094927,
        40.29397537898547,
        -6.734914899094927,
        40.29397537898547
      ]
    },
    "37235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.36420963846162,
              40.740972917306756
            ]
          },
          "bbox": [
            -5.36420963846162,
            40.740972917306756,
            -5.36420963846162,
            40.740972917306756
          ]
        }
      ],
      "bbox": [
        -5.36420963846162,
        40.740972917306756,
        -5.36420963846162,
        40.740972917306756
      ]
    },
    "37236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.403162658504881,
              40.813845075979756
            ]
          },
          "bbox": [
            -5.403162658504881,
            40.813845075979756,
            -5.403162658504881,
            40.813845075979756
          ]
        }
      ],
      "bbox": [
        -5.403162658504881,
        40.813845075979756,
        -5.403162658504881,
        40.813845075979756
      ]
    },
    "37237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7007552128341645,
              40.692564168721375
            ]
          },
          "bbox": [
            -5.7007552128341645,
            40.692564168721375,
            -5.7007552128341645,
            40.692564168721375
          ]
        }
      ],
      "bbox": [
        -5.7007552128341645,
        40.692564168721375,
        -5.7007552128341645,
        40.692564168721375
      ]
    },
    "37238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5552583852056205,
              41.058883113315865
            ]
          },
          "bbox": [
            -5.5552583852056205,
            41.058883113315865,
            -5.5552583852056205,
            41.058883113315865
          ]
        }
      ],
      "bbox": [
        -5.5552583852056205,
        41.058883113315865,
        -5.5552583852056205,
        41.058883113315865
      ]
    },
    "37239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.39866028708957,
              41.08434774582791
            ]
          },
          "bbox": [
            -5.39866028708957,
            41.08434774582791,
            -5.39866028708957,
            41.08434774582791
          ]
        }
      ],
      "bbox": [
        -5.39866028708957,
        41.08434774582791,
        -5.39866028708957,
        41.08434774582791
      ]
    },
    "37240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.586560640795564,
              40.94663951962828
            ]
          },
          "bbox": [
            -5.586560640795564,
            40.94663951962828,
            -5.586560640795564,
            40.94663951962828
          ]
        }
      ],
      "bbox": [
        -5.586560640795564,
        40.94663951962828,
        -5.586560640795564,
        40.94663951962828
      ]
    },
    "37241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.213481282086174,
              40.86891691348303
            ]
          },
          "bbox": [
            -6.213481282086174,
            40.86891691348303,
            -6.213481282086174,
            40.86891691348303
          ]
        }
      ],
      "bbox": [
        -6.213481282086174,
        40.86891691348303,
        -6.213481282086174,
        40.86891691348303
      ]
    },
    "37242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.570085607600881,
              40.662806194336916
            ]
          },
          "bbox": [
            -5.570085607600881,
            40.662806194336916,
            -5.570085607600881,
            40.662806194336916
          ]
        }
      ],
      "bbox": [
        -5.570085607600881,
        40.662806194336916,
        -5.570085607600881,
        40.662806194336916
      ]
    },
    "37243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.522694477342628,
              41.17395486330427
            ]
          },
          "bbox": [
            -6.522694477342628,
            41.17395486330427,
            -6.522694477342628,
            41.17395486330427
          ]
        }
      ],
      "bbox": [
        -6.522694477342628,
        41.17395486330427,
        -6.522694477342628,
        41.17395486330427
      ]
    },
    "37244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8645781282997405,
              40.34167598737211
            ]
          },
          "bbox": [
            -5.8645781282997405,
            40.34167598737211,
            -5.8645781282997405,
            40.34167598737211
          ]
        }
      ],
      "bbox": [
        -5.8645781282997405,
        40.34167598737211,
        -5.8645781282997405,
        40.34167598737211
      ]
    },
    "37245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.663136220469699,
              40.30268087422667
            ]
          },
          "bbox": [
            -6.663136220469699,
            40.30268087422667,
            -6.663136220469699,
            40.30268087422667
          ]
        }
      ],
      "bbox": [
        -6.663136220469699,
        40.30268087422667,
        -6.663136220469699,
        40.30268087422667
      ]
    },
    "37246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.212166624848101,
              40.90138749809327
            ]
          },
          "bbox": [
            -5.212166624848101,
            40.90138749809327,
            -5.212166624848101,
            40.90138749809327
          ]
        }
      ],
      "bbox": [
        -5.212166624848101,
        40.90138749809327,
        -5.212166624848101,
        40.90138749809327
      ]
    },
    "37247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.393446216701737,
              40.879414798452345
            ]
          },
          "bbox": [
            -5.393446216701737,
            40.879414798452345,
            -5.393446216701737,
            40.879414798452345
          ]
        }
      ],
      "bbox": [
        -5.393446216701737,
        40.879414798452345,
        -5.393446216701737,
        40.879414798452345
      ]
    },
    "37248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.365458757978101,
              41.008286774077376
            ]
          },
          "bbox": [
            -6.365458757978101,
            41.008286774077376,
            -6.365458757978101,
            41.008286774077376
          ]
        }
      ],
      "bbox": [
        -6.365458757978101,
        41.008286774077376,
        -6.365458757978101,
        41.008286774077376
      ]
    },
    "37249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.312489050937818,
              41.00774842009035
            ]
          },
          "bbox": [
            -6.312489050937818,
            41.00774842009035,
            -6.312489050937818,
            41.00774842009035
          ]
        }
      ],
      "bbox": [
        -6.312489050937818,
        41.00774842009035,
        -6.312489050937818,
        41.00774842009035
      ]
    },
    "37250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.525176287474044,
              41.23036350439103
            ]
          },
          "bbox": [
            -6.525176287474044,
            41.23036350439103,
            -6.525176287474044,
            41.23036350439103
          ]
        }
      ],
      "bbox": [
        -6.525176287474044,
        41.23036350439103,
        -6.525176287474044,
        41.23036350439103
      ]
    },
    "37251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7710945285527435,
              40.46394145728994
            ]
          },
          "bbox": [
            -5.7710945285527435,
            40.46394145728994,
            -5.7710945285527435,
            40.46394145728994
          ]
        }
      ],
      "bbox": [
        -5.7710945285527435,
        40.46394145728994,
        -5.7710945285527435,
        40.46394145728994
      ]
    },
    "37252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.973724193905825,
              40.44275566788874
            ]
          },
          "bbox": [
            -5.973724193905825,
            40.44275566788874,
            -5.973724193905825,
            40.44275566788874
          ]
        }
      ],
      "bbox": [
        -5.973724193905825,
        40.44275566788874,
        -5.973724193905825,
        40.44275566788874
      ]
    },
    "37253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.810160754292295,
              41.026727286890434
            ]
          },
          "bbox": [
            -5.810160754292295,
            41.026727286890434,
            -5.810160754292295,
            41.026727286890434
          ]
        }
      ],
      "bbox": [
        -5.810160754292295,
        41.026727286890434,
        -5.810160754292295,
        41.026727286890434
      ]
    },
    "37254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.452469917814897,
              41.05499966731014
            ]
          },
          "bbox": [
            -5.452469917814897,
            41.05499966731014,
            -5.452469917814897,
            41.05499966731014
          ]
        }
      ],
      "bbox": [
        -5.452469917814897,
        41.05499966731014,
        -5.452469917814897,
        41.05499966731014
      ]
    },
    "37255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.647016746721304,
              40.61429500314889
            ]
          },
          "bbox": [
            -5.647016746721304,
            40.61429500314889,
            -5.647016746721304,
            40.61429500314889
          ]
        }
      ],
      "bbox": [
        -5.647016746721304,
        40.61429500314889,
        -5.647016746721304,
        40.61429500314889
      ]
    },
    "37256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.264653583515511,
              41.04126889183892
            ]
          },
          "bbox": [
            -5.264653583515511,
            41.04126889183892,
            -5.264653583515511,
            41.04126889183892
          ]
        }
      ],
      "bbox": [
        -5.264653583515511,
        41.04126889183892,
        -5.264653583515511,
        41.04126889183892
      ]
    },
    "37257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.377331716341269,
              40.93478681230026
            ]
          },
          "bbox": [
            -6.377331716341269,
            40.93478681230026,
            -6.377331716341269,
            40.93478681230026
          ]
        }
      ],
      "bbox": [
        -6.377331716341269,
        40.93478681230026,
        -6.377331716341269,
        40.93478681230026
      ]
    },
    "37258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7388685253966685,
              40.443653044658355
            ]
          },
          "bbox": [
            -6.7388685253966685,
            40.443653044658355,
            -6.7388685253966685,
            40.443653044658355
          ]
        }
      ],
      "bbox": [
        -6.7388685253966685,
        40.443653044658355,
        -6.7388685253966685,
        40.443653044658355
      ]
    },
    "37259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.745476350256543,
              40.50128080527557
            ]
          },
          "bbox": [
            -5.745476350256543,
            40.50128080527557,
            -5.745476350256543,
            40.50128080527557
          ]
        }
      ],
      "bbox": [
        -5.745476350256543,
        40.50128080527557,
        -5.745476350256543,
        40.50128080527557
      ]
    },
    "37260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.19590612483296,
              40.6184374775243
            ]
          },
          "bbox": [
            -6.19590612483296,
            40.6184374775243,
            -6.19590612483296,
            40.6184374775243
          ]
        }
      ],
      "bbox": [
        -6.19590612483296,
        40.6184374775243,
        -6.19590612483296,
        40.6184374775243
      ]
    },
    "37261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.52240695418757,
              40.503020758384224
            ]
          },
          "bbox": [
            -5.52240695418757,
            40.503020758384224,
            -5.52240695418757,
            40.503020758384224
          ]
        }
      ],
      "bbox": [
        -5.52240695418757,
        40.503020758384224,
        -5.52240695418757,
        40.503020758384224
      ]
    },
    "37262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.266379275001949,
              41.10686683695794
            ]
          },
          "bbox": [
            -6.266379275001949,
            41.10686683695794,
            -6.266379275001949,
            41.10686683695794
          ]
        }
      ],
      "bbox": [
        -6.266379275001949,
        41.10686683695794,
        -6.266379275001949,
        41.10686683695794
      ]
    },
    "37263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.833888199186684,
              40.35278337482676
            ]
          },
          "bbox": [
            -5.833888199186684,
            40.35278337482676,
            -5.833888199186684,
            40.35278337482676
          ]
        }
      ],
      "bbox": [
        -5.833888199186684,
        40.35278337482676,
        -5.833888199186684,
        40.35278337482676
      ]
    },
    "37264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.764222983371749,
              40.813178049403376
            ]
          },
          "bbox": [
            -6.764222983371749,
            40.813178049403376,
            -6.764222983371749,
            40.813178049403376
          ]
        }
      ],
      "bbox": [
        -6.764222983371749,
        40.813178049403376,
        -6.764222983371749,
        40.813178049403376
      ]
    },
    "37265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.122956932683828,
              40.99999984678401
            ]
          },
          "bbox": [
            -5.122956932683828,
            40.99999984678401,
            -5.122956932683828,
            40.99999984678401
          ]
        }
      ],
      "bbox": [
        -5.122956932683828,
        40.99999984678401,
        -5.122956932683828,
        40.99999984678401
      ]
    },
    "37266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.742317209039253,
              40.90917499125417
            ]
          },
          "bbox": [
            -6.742317209039253,
            40.90917499125417,
            -6.742317209039253,
            40.90917499125417
          ]
        }
      ],
      "bbox": [
        -6.742317209039253,
        40.90917499125417,
        -6.742317209039253,
        40.90917499125417
      ]
    },
    "37267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4006915273850025,
              40.799130651839285
            ]
          },
          "bbox": [
            -6.4006915273850025,
            40.799130651839285,
            -6.4006915273850025,
            40.799130651839285
          ]
        }
      ],
      "bbox": [
        -6.4006915273850025,
        40.799130651839285,
        -6.4006915273850025,
        40.799130651839285
      ]
    },
    "37268": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.040504675791679,
              40.61575892206169
            ]
          },
          "bbox": [
            -6.040504675791679,
            40.61575892206169,
            -6.040504675791679,
            40.61575892206169
          ]
        }
      ],
      "bbox": [
        -6.040504675791679,
        40.61575892206169,
        -6.040504675791679,
        40.61575892206169
      ]
    },
    "37269": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.60225665341574,
              40.37255955451311
            ]
          },
          "bbox": [
            -6.60225665341574,
            40.37255955451311,
            -6.60225665341574,
            40.37255955451311
          ]
        }
      ],
      "bbox": [
        -6.60225665341574,
        40.37255955451311,
        -6.60225665341574,
        40.37255955451311
      ]
    },
    "37270": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.974171777115138,
              40.85730705090691
            ]
          },
          "bbox": [
            -5.974171777115138,
            40.85730705090691,
            -5.974171777115138,
            40.85730705090691
          ]
        }
      ],
      "bbox": [
        -5.974171777115138,
        40.85730705090691,
        -5.974171777115138,
        40.85730705090691
      ]
    },
    "37271": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.922142259135908,
              40.96055294986163
            ]
          },
          "bbox": [
            -5.922142259135908,
            40.96055294986163,
            -5.922142259135908,
            40.96055294986163
          ]
        }
      ],
      "bbox": [
        -5.922142259135908,
        40.96055294986163,
        -5.922142259135908,
        40.96055294986163
      ]
    },
    "37272": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.620033773665638,
              40.65471837592631
            ]
          },
          "bbox": [
            -6.620033773665638,
            40.65471837592631,
            -6.620033773665638,
            40.65471837592631
          ]
        }
      ],
      "bbox": [
        -6.620033773665638,
        40.65471837592631,
        -6.620033773665638,
        40.65471837592631
      ]
    },
    "37273": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.070832114743516,
              40.73733542569799
            ]
          },
          "bbox": [
            -6.070832114743516,
            40.73733542569799,
            -6.070832114743516,
            40.73733542569799
          ]
        }
      ],
      "bbox": [
        -6.070832114743516,
        40.73733542569799,
        -6.070832114743516,
        40.73733542569799
      ]
    },
    "37274": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.679760088530759,
              40.96167909932782
            ]
          },
          "bbox": [
            -5.679760088530759,
            40.96167909932782,
            -5.679760088530759,
            40.96167909932782
          ]
        }
      ],
      "bbox": [
        -5.679760088530759,
        40.96167909932782,
        -5.679760088530759,
        40.96167909932782
      ]
    },
    "37275": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.629433258903758,
              41.019353340882354
            ]
          },
          "bbox": [
            -6.629433258903758,
            41.019353340882354,
            -6.629433258903758,
            41.019353340882354
          ]
        }
      ],
      "bbox": [
        -6.629433258903758,
        41.019353340882354,
        -6.629433258903758,
        41.019353340882354
      ]
    },
    "37276": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.21415079052409,
              40.80393487575228
            ]
          },
          "bbox": [
            -5.21415079052409,
            40.80393487575228,
            -5.21415079052409,
            40.80393487575228
          ]
        }
      ],
      "bbox": [
        -5.21415079052409,
        40.80393487575228,
        -5.21415079052409,
        40.80393487575228
      ]
    },
    "37277": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.580138721027735,
              40.59003721054228
            ]
          },
          "bbox": [
            -5.580138721027735,
            40.59003721054228,
            -5.580138721027735,
            40.59003721054228
          ]
        }
      ],
      "bbox": [
        -5.580138721027735,
        40.59003721054228,
        -5.580138721027735,
        40.59003721054228
      ]
    },
    "37278": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.618230187835115,
              41.03257674031925
            ]
          },
          "bbox": [
            -5.618230187835115,
            41.03257674031925,
            -5.618230187835115,
            41.03257674031925
          ]
        }
      ],
      "bbox": [
        -5.618230187835115,
        41.03257674031925,
        -5.618230187835115,
        41.03257674031925
      ]
    },
    "37279": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.429915980728169,
              40.73325148841164
            ]
          },
          "bbox": [
            -6.429915980728169,
            40.73325148841164,
            -6.429915980728169,
            40.73325148841164
          ]
        }
      ],
      "bbox": [
        -6.429915980728169,
        40.73325148841164,
        -6.429915980728169,
        40.73325148841164
      ]
    },
    "37280": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.395642431368563,
              41.1003187510522
            ]
          },
          "bbox": [
            -6.395642431368563,
            41.1003187510522,
            -6.395642431368563,
            41.1003187510522
          ]
        }
      ],
      "bbox": [
        -6.395642431368563,
        41.1003187510522,
        -6.395642431368563,
        41.1003187510522
      ]
    },
    "37281": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.025088840395446,
              40.75571300135205
            ]
          },
          "bbox": [
            -6.025088840395446,
            40.75571300135205,
            -6.025088840395446,
            40.75571300135205
          ]
        }
      ],
      "bbox": [
        -6.025088840395446,
        40.75571300135205,
        -6.025088840395446,
        40.75571300135205
      ]
    },
    "37282": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.750216042782118,
              40.4437097346392
            ]
          },
          "bbox": [
            -5.750216042782118,
            40.4437097346392,
            -5.750216042782118,
            40.4437097346392
          ]
        }
      ],
      "bbox": [
        -5.750216042782118,
        40.4437097346392,
        -5.750216042782118,
        40.4437097346392
      ]
    },
    "37283": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.101890295120082,
              40.95217604508937
            ]
          },
          "bbox": [
            -6.101890295120082,
            40.95217604508937,
            -6.101890295120082,
            40.95217604508937
          ]
        }
      ],
      "bbox": [
        -6.101890295120082,
        40.95217604508937,
        -6.101890295120082,
        40.95217604508937
      ]
    },
    "37284": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.876741565545251,
              40.51281566584124
            ]
          },
          "bbox": [
            -5.876741565545251,
            40.51281566584124,
            -5.876741565545251,
            40.51281566584124
          ]
        }
      ],
      "bbox": [
        -5.876741565545251,
        40.51281566584124,
        -5.876741565545251,
        40.51281566584124
      ]
    },
    "37285": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.694361433371724,
              40.834843980784775
            ]
          },
          "bbox": [
            -6.694361433371724,
            40.834843980784775,
            -6.694361433371724,
            40.834843980784775
          ]
        }
      ],
      "bbox": [
        -6.694361433371724,
        40.834843980784775,
        -6.694361433371724,
        40.834843980784775
      ]
    },
    "37286": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.077520317565899,
              40.52416614240334
            ]
          },
          "bbox": [
            -6.077520317565899,
            40.52416614240334,
            -6.077520317565899,
            40.52416614240334
          ]
        }
      ],
      "bbox": [
        -6.077520317565899,
        40.52416614240334,
        -6.077520317565899,
        40.52416614240334
      ]
    },
    "37287": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.944804783089315,
              40.55304602058609
            ]
          },
          "bbox": [
            -5.944804783089315,
            40.55304602058609,
            -5.944804783089315,
            40.55304602058609
          ]
        }
      ],
      "bbox": [
        -5.944804783089315,
        40.55304602058609,
        -5.944804783089315,
        40.55304602058609
      ]
    },
    "37288": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.503598050780431,
              40.9849211041107
            ]
          },
          "bbox": [
            -5.503598050780431,
            40.9849211041107,
            -5.503598050780431,
            40.9849211041107
          ]
        }
      ],
      "bbox": [
        -5.503598050780431,
        40.9849211041107,
        -5.503598050780431,
        40.9849211041107
      ]
    },
    "37289": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.127132194755015,
              40.7668471619539
            ]
          },
          "bbox": [
            -6.127132194755015,
            40.7668471619539,
            -6.127132194755015,
            40.7668471619539
          ]
        }
      ],
      "bbox": [
        -6.127132194755015,
        40.7668471619539,
        -6.127132194755015,
        40.7668471619539
      ]
    },
    "37290": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.863453760777448,
              41.047524583418
            ]
          },
          "bbox": [
            -5.863453760777448,
            41.047524583418,
            -5.863453760777448,
            41.047524583418
          ]
        }
      ],
      "bbox": [
        -5.863453760777448,
        41.047524583418,
        -5.863453760777448,
        41.047524583418
      ]
    },
    "37291": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.78227209864021,
              40.81082302560563
            ]
          },
          "bbox": [
            -5.78227209864021,
            40.81082302560563,
            -5.78227209864021,
            40.81082302560563
          ]
        }
      ],
      "bbox": [
        -5.78227209864021,
        40.81082302560563,
        -5.78227209864021,
        40.81082302560563
      ]
    },
    "37292": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.872081338608624,
              41.11702162997726
            ]
          },
          "bbox": [
            -5.872081338608624,
            41.11702162997726,
            -5.872081338608624,
            41.11702162997726
          ]
        }
      ],
      "bbox": [
        -5.872081338608624,
        41.11702162997726,
        -5.872081338608624,
        41.11702162997726
      ]
    },
    "37293": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.132815575193005,
              40.9820804130389
            ]
          },
          "bbox": [
            -6.132815575193005,
            40.9820804130389,
            -6.132815575193005,
            40.9820804130389
          ]
        }
      ],
      "bbox": [
        -6.132815575193005,
        40.9820804130389,
        -6.132815575193005,
        40.9820804130389
      ]
    },
    "37294": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.623174953873562,
              40.94721583814159
            ]
          },
          "bbox": [
            -5.623174953873562,
            40.94721583814159,
            -5.623174953873562,
            40.94721583814159
          ]
        }
      ],
      "bbox": [
        -5.623174953873562,
        40.94721583814159,
        -5.623174953873562,
        40.94721583814159
      ]
    },
    "37296": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.287261264816963,
              40.79126215817165
            ]
          },
          "bbox": [
            -5.287261264816963,
            40.79126215817165,
            -5.287261264816963,
            40.79126215817165
          ]
        }
      ],
      "bbox": [
        -5.287261264816963,
        40.79126215817165,
        -5.287261264816963,
        40.79126215817165
      ]
    },
    "37297": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.595434915453616,
              40.4913963372298
            ]
          },
          "bbox": [
            -5.595434915453616,
            40.4913963372298,
            -5.595434915453616,
            40.4913963372298
          ]
        }
      ],
      "bbox": [
        -5.595434915453616,
        40.4913963372298,
        -5.595434915453616,
        40.4913963372298
      ]
    },
    "37298": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9172355708081845,
              40.49004469155256
            ]
          },
          "bbox": [
            -5.9172355708081845,
            40.49004469155256,
            -5.9172355708081845,
            40.49004469155256
          ]
        }
      ],
      "bbox": [
        -5.9172355708081845,
        40.49004469155256,
        -5.9172355708081845,
        40.49004469155256
      ]
    },
    "37299": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.887157657902516,
              41.211824298586635
            ]
          },
          "bbox": [
            -5.887157657902516,
            41.211824298586635,
            -5.887157657902516,
            41.211824298586635
          ]
        }
      ],
      "bbox": [
        -5.887157657902516,
        41.211824298586635,
        -5.887157657902516,
        41.211824298586635
      ]
    },
    "37300": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.808075963740067,
              40.535588794447854
            ]
          },
          "bbox": [
            -5.808075963740067,
            40.535588794447854,
            -5.808075963740067,
            40.535588794447854
          ]
        }
      ],
      "bbox": [
        -5.808075963740067,
        40.535588794447854,
        -5.808075963740067,
        40.535588794447854
      ]
    },
    "37301": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.27053806626971,
              41.220237312277135
            ]
          },
          "bbox": [
            -6.27053806626971,
            41.220237312277135,
            -6.27053806626971,
            41.220237312277135
          ]
        }
      ],
      "bbox": [
        -6.27053806626971,
        41.220237312277135,
        -6.27053806626971,
        41.220237312277135
      ]
    },
    "37302": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.743173280247078,
              41.048769628440894
            ]
          },
          "bbox": [
            -6.743173280247078,
            41.048769628440894,
            -6.743173280247078,
            41.048769628440894
          ]
        }
      ],
      "bbox": [
        -6.743173280247078,
        41.048769628440894,
        -6.743173280247078,
        41.048769628440894
      ]
    },
    "37303": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.537392848986571,
              40.400996859272986
            ]
          },
          "bbox": [
            -6.537392848986571,
            40.400996859272986,
            -6.537392848986571,
            40.400996859272986
          ]
        }
      ],
      "bbox": [
        -6.537392848986571,
        40.400996859272986,
        -6.537392848986571,
        40.400996859272986
      ]
    },
    "37304": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.191153904016732,
              40.69437289141744
            ]
          },
          "bbox": [
            -6.191153904016732,
            40.69437289141744,
            -6.191153904016732,
            40.69437289141744
          ]
        }
      ],
      "bbox": [
        -6.191153904016732,
        40.69437289141744,
        -6.191153904016732,
        40.69437289141744
      ]
    },
    "37305": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.028186573238073,
              40.51096350909128
            ]
          },
          "bbox": [
            -6.028186573238073,
            40.51096350909128,
            -6.028186573238073,
            40.51096350909128
          ]
        }
      ],
      "bbox": [
        -6.028186573238073,
        40.51096350909128,
        -6.028186573238073,
        40.51096350909128
      ]
    },
    "37306": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.337406845352836,
              40.54476439437426
            ]
          },
          "bbox": [
            -6.337406845352836,
            40.54476439437426,
            -6.337406845352836,
            40.54476439437426
          ]
        }
      ],
      "bbox": [
        -6.337406845352836,
        40.54476439437426,
        -6.337406845352836,
        40.54476439437426
      ]
    },
    "37307": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.344698667061285,
              40.47589934248851
            ]
          },
          "bbox": [
            -6.344698667061285,
            40.47589934248851,
            -6.344698667061285,
            40.47589934248851
          ]
        }
      ],
      "bbox": [
        -6.344698667061285,
        40.47589934248851,
        -6.344698667061285,
        40.47589934248851
      ]
    },
    "37309": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.852384497582808,
              40.647317209778016
            ]
          },
          "bbox": [
            -5.852384497582808,
            40.647317209778016,
            -5.852384497582808,
            40.647317209778016
          ]
        }
      ],
      "bbox": [
        -5.852384497582808,
        40.647317209778016,
        -5.852384497582808,
        40.647317209778016
      ]
    },
    "37310": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.588783064014143,
              40.7389507428202
            ]
          },
          "bbox": [
            -5.588783064014143,
            40.7389507428202,
            -5.588783064014143,
            40.7389507428202
          ]
        }
      ],
      "bbox": [
        -5.588783064014143,
        40.7389507428202,
        -5.588783064014143,
        40.7389507428202
      ]
    },
    "37311": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.808489477522577,
              40.91809974763976
            ]
          },
          "bbox": [
            -6.808489477522577,
            40.91809974763976,
            -6.808489477522577,
            40.91809974763976
          ]
        }
      ],
      "bbox": [
        -6.808489477522577,
        40.91809974763976,
        -6.808489477522577,
        40.91809974763976
      ]
    },
    "37312": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.668134718813935,
              40.45080710363956
            ]
          },
          "bbox": [
            -5.668134718813935,
            40.45080710363956,
            -5.668134718813935,
            40.45080710363956
          ]
        }
      ],
      "bbox": [
        -5.668134718813935,
        40.45080710363956,
        -5.668134718813935,
        40.45080710363956
      ]
    },
    "37313": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.040720660339119,
              40.388723136447645
            ]
          },
          "bbox": [
            -6.040720660339119,
            40.388723136447645,
            -6.040720660339119,
            40.388723136447645
          ]
        }
      ],
      "bbox": [
        -6.040720660339119,
        40.388723136447645,
        -6.040720660339119,
        40.388723136447645
      ]
    },
    "37314": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.021350429433913,
              40.92644227951946
            ]
          },
          "bbox": [
            -6.021350429433913,
            40.92644227951946,
            -6.021350429433913,
            40.92644227951946
          ]
        }
      ],
      "bbox": [
        -6.021350429433913,
        40.92644227951946,
        -6.021350429433913,
        40.92644227951946
      ]
    },
    "37315": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.526732579373231,
              40.59447835866205
            ]
          },
          "bbox": [
            -5.526732579373231,
            40.59447835866205,
            -5.526732579373231,
            40.59447835866205
          ]
        }
      ],
      "bbox": [
        -5.526732579373231,
        40.59447835866205,
        -5.526732579373231,
        40.59447835866205
      ]
    },
    "37316": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1011043034705486,
              40.660730987878225
            ]
          },
          "bbox": [
            -6.1011043034705486,
            40.660730987878225,
            -6.1011043034705486,
            40.660730987878225
          ]
        }
      ],
      "bbox": [
        -6.1011043034705486,
        40.660730987878225,
        -6.1011043034705486,
        40.660730987878225
      ]
    },
    "37317": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2589143977082475,
              41.1722452931676
            ]
          },
          "bbox": [
            -5.2589143977082475,
            41.1722452931676,
            -5.2589143977082475,
            41.1722452931676
          ]
        }
      ],
      "bbox": [
        -5.2589143977082475,
        41.1722452931676,
        -5.2589143977082475,
        41.1722452931676
      ]
    },
    "37318": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.579252023943105,
              41.13928145335564
            ]
          },
          "bbox": [
            -5.579252023943105,
            41.13928145335564,
            -5.579252023943105,
            41.13928145335564
          ]
        }
      ],
      "bbox": [
        -5.579252023943105,
        41.13928145335564,
        -5.579252023943105,
        41.13928145335564
      ]
    },
    "37319": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.537593730557822,
              40.44713560099788
            ]
          },
          "bbox": [
            -5.537593730557822,
            40.44713560099788,
            -5.537593730557822,
            40.44713560099788
          ]
        }
      ],
      "bbox": [
        -5.537593730557822,
        40.44713560099788,
        -5.537593730557822,
        40.44713560099788
      ]
    },
    "37320": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.017401279540091,
              40.64579078289537
            ]
          },
          "bbox": [
            -6.017401279540091,
            40.64579078289537,
            -6.017401279540091,
            40.64579078289537
          ]
        }
      ],
      "bbox": [
        -6.017401279540091,
        40.64579078289537,
        -6.017401279540091,
        40.64579078289537
      ]
    },
    "37321": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.357512432589291,
              40.60574442447054
            ]
          },
          "bbox": [
            -6.357512432589291,
            40.60574442447054,
            -6.357512432589291,
            40.60574442447054
          ]
        }
      ],
      "bbox": [
        -6.357512432589291,
        40.60574442447054,
        -6.357512432589291,
        40.60574442447054
      ]
    },
    "37322": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5788499639495726,
              40.85620343525575
            ]
          },
          "bbox": [
            -5.5788499639495726,
            40.85620343525575,
            -5.5788499639495726,
            40.85620343525575
          ]
        }
      ],
      "bbox": [
        -5.5788499639495726,
        40.85620343525575,
        -5.5788499639495726,
        40.85620343525575
      ]
    },
    "37323": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.661350175785073,
              41.183155491346355
            ]
          },
          "bbox": [
            -5.661350175785073,
            41.183155491346355,
            -5.661350175785073,
            41.183155491346355
          ]
        }
      ],
      "bbox": [
        -5.661350175785073,
        41.183155491346355,
        -5.661350175785073,
        41.183155491346355
      ]
    },
    "37324": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.351432086025062,
              40.85168292676322
            ]
          },
          "bbox": [
            -5.351432086025062,
            40.85168292676322,
            -5.351432086025062,
            40.85168292676322
          ]
        }
      ],
      "bbox": [
        -5.351432086025062,
        40.85168292676322,
        -5.351432086025062,
        40.85168292676322
      ]
    },
    "37325": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.879961854711815,
              40.543437160236856
            ]
          },
          "bbox": [
            -5.879961854711815,
            40.543437160236856,
            -5.879961854711815,
            40.543437160236856
          ]
        }
      ],
      "bbox": [
        -5.879961854711815,
        40.543437160236856,
        -5.879961854711815,
        40.543437160236856
      ]
    },
    "37327": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.786276579702572,
              41.1057505401995
            ]
          },
          "bbox": [
            -5.786276579702572,
            41.1057505401995,
            -5.786276579702572,
            41.1057505401995
          ]
        }
      ],
      "bbox": [
        -5.786276579702572,
        41.1057505401995,
        -5.786276579702572,
        41.1057505401995
      ]
    },
    "37328": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.3758357126695815,
              41.2413514004813
            ]
          },
          "bbox": [
            -6.3758357126695815,
            41.2413514004813,
            -6.3758357126695815,
            41.2413514004813
          ]
        }
      ],
      "bbox": [
        -6.3758357126695815,
        41.2413514004813,
        -6.3758357126695815,
        41.2413514004813
      ]
    },
    "37329": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.178577665907067,
              41.09387309745369
            ]
          },
          "bbox": [
            -6.178577665907067,
            41.09387309745369,
            -6.178577665907067,
            41.09387309745369
          ]
        }
      ],
      "bbox": [
        -6.178577665907067,
        41.09387309745369,
        -6.178577665907067,
        41.09387309745369
      ]
    },
    "37330": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.422271477495617,
              40.768740097267994
            ]
          },
          "bbox": [
            -5.422271477495617,
            40.768740097267994,
            -5.422271477495617,
            40.768740097267994
          ]
        }
      ],
      "bbox": [
        -5.422271477495617,
        40.768740097267994,
        -5.422271477495617,
        40.768740097267994
      ]
    },
    "37331": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.825534333992627,
              40.46951263673627
            ]
          },
          "bbox": [
            -5.825534333992627,
            40.46951263673627,
            -5.825534333992627,
            40.46951263673627
          ]
        }
      ],
      "bbox": [
        -5.825534333992627,
        40.46951263673627,
        -5.825534333992627,
        40.46951263673627
      ]
    },
    "37332": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.847937334755284,
              40.41991346935543
            ]
          },
          "bbox": [
            -5.847937334755284,
            40.41991346935543,
            -5.847937334755284,
            40.41991346935543
          ]
        }
      ],
      "bbox": [
        -5.847937334755284,
        40.41991346935543,
        -5.847937334755284,
        40.41991346935543
      ]
    },
    "37333": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.764177798440364,
              40.511253546833636
            ]
          },
          "bbox": [
            -5.764177798440364,
            40.511253546833636,
            -5.764177798440364,
            40.511253546833636
          ]
        }
      ],
      "bbox": [
        -5.764177798440364,
        40.511253546833636,
        -5.764177798440364,
        40.511253546833636
      ]
    },
    "37334": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.994713250210098,
              40.37750286171357
            ]
          },
          "bbox": [
            -5.994713250210098,
            40.37750286171357,
            -5.994713250210098,
            40.37750286171357
          ]
        }
      ],
      "bbox": [
        -5.994713250210098,
        40.37750286171357,
        -5.994713250210098,
        40.37750286171357
      ]
    },
    "37335": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.774688400696901,
              41.18780441708503
            ]
          },
          "bbox": [
            -5.774688400696901,
            41.18780441708503,
            -5.774688400696901,
            41.18780441708503
          ]
        }
      ],
      "bbox": [
        -5.774688400696901,
        41.18780441708503,
        -5.774688400696901,
        41.18780441708503
      ]
    },
    "37336": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.589857067839435,
              40.83077354510181
            ]
          },
          "bbox": [
            -5.589857067839435,
            40.83077354510181,
            -5.589857067839435,
            40.83077354510181
          ]
        }
      ],
      "bbox": [
        -5.589857067839435,
        40.83077354510181,
        -5.589857067839435,
        40.83077354510181
      ]
    },
    "37337": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.517733989369349,
              41.07129047827029
            ]
          },
          "bbox": [
            -6.517733989369349,
            41.07129047827029,
            -6.517733989369349,
            41.07129047827029
          ]
        }
      ],
      "bbox": [
        -6.517733989369349,
        41.07129047827029,
        -6.517733989369349,
        41.07129047827029
      ]
    },
    "37338": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.679516005266259,
              41.111067501221356
            ]
          },
          "bbox": [
            -5.679516005266259,
            41.111067501221356,
            -5.679516005266259,
            41.111067501221356
          ]
        }
      ],
      "bbox": [
        -5.679516005266259,
        41.111067501221356,
        -5.679516005266259,
        41.111067501221356
      ]
    },
    "37339": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.960247782768149,
              40.53661632154165
            ]
          },
          "bbox": [
            -5.960247782768149,
            40.53661632154165,
            -5.960247782768149,
            40.53661632154165
          ]
        }
      ],
      "bbox": [
        -5.960247782768149,
        40.53661632154165,
        -5.960247782768149,
        40.53661632154165
      ]
    },
    "37340": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.492689921590369,
              41.104210245356285
            ]
          },
          "bbox": [
            -6.492689921590369,
            41.104210245356285,
            -6.492689921590369,
            41.104210245356285
          ]
        }
      ],
      "bbox": [
        -6.492689921590369,
        41.104210245356285,
        -6.492689921590369,
        41.104210245356285
      ]
    },
    "37341": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.783327022821889,
              40.487015077666385
            ]
          },
          "bbox": [
            -5.783327022821889,
            40.487015077666385,
            -5.783327022821889,
            40.487015077666385
          ]
        }
      ],
      "bbox": [
        -5.783327022821889,
        40.487015077666385,
        -5.783327022821889,
        40.487015077666385
      ]
    },
    "37342": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.753961481445907,
              41.052916498879306
            ]
          },
          "bbox": [
            -5.753961481445907,
            41.052916498879306,
            -5.753961481445907,
            41.052916498879306
          ]
        }
      ],
      "bbox": [
        -5.753961481445907,
        41.052916498879306,
        -5.753961481445907,
        41.052916498879306
      ]
    },
    "37343": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.722828579502769,
              40.41445801773542
            ]
          },
          "bbox": [
            -5.722828579502769,
            40.41445801773542,
            -5.722828579502769,
            40.41445801773542
          ]
        }
      ],
      "bbox": [
        -5.722828579502769,
        40.41445801773542,
        -5.722828579502769,
        40.41445801773542
      ]
    },
    "37344": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.879616272206943,
              40.777273912249605
            ]
          },
          "bbox": [
            -5.879616272206943,
            40.777273912249605,
            -5.879616272206943,
            40.777273912249605
          ]
        }
      ],
      "bbox": [
        -5.879616272206943,
        40.777273912249605,
        -5.879616272206943,
        40.777273912249605
      ]
    },
    "37345": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.899179558543458,
              41.03854805704584
            ]
          },
          "bbox": [
            -5.899179558543458,
            41.03854805704584,
            -5.899179558543458,
            41.03854805704584
          ]
        }
      ],
      "bbox": [
        -5.899179558543458,
        41.03854805704584,
        -5.899179558543458,
        41.03854805704584
      ]
    },
    "37346": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.832033795455186,
              40.73908776026488
            ]
          },
          "bbox": [
            -5.832033795455186,
            40.73908776026488,
            -5.832033795455186,
            40.73908776026488
          ]
        }
      ],
      "bbox": [
        -5.832033795455186,
        40.73908776026488,
        -5.832033795455186,
        40.73908776026488
      ]
    },
    "37347": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5684504784719815,
              41.07874385090498
            ]
          },
          "bbox": [
            -5.5684504784719815,
            41.07874385090498,
            -5.5684504784719815,
            41.07874385090498
          ]
        }
      ],
      "bbox": [
        -5.5684504784719815,
        41.07874385090498,
        -5.5684504784719815,
        41.07874385090498
      ]
    },
    "37348": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3453152960703525,
              40.93485297223232
            ]
          },
          "bbox": [
            -5.3453152960703525,
            40.93485297223232,
            -5.3453152960703525,
            40.93485297223232
          ]
        }
      ],
      "bbox": [
        -5.3453152960703525,
        40.93485297223232,
        -5.3453152960703525,
        40.93485297223232
      ]
    },
    "37349": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.481137272066906,
              41.152787155407246
            ]
          },
          "bbox": [
            -6.481137272066906,
            41.152787155407246,
            -6.481137272066906,
            41.152787155407246
          ]
        }
      ],
      "bbox": [
        -6.481137272066906,
        41.152787155407246,
        -6.481137272066906,
        41.152787155407246
      ]
    },
    "37350": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.72494712037056,
              41.10502529404766
            ]
          },
          "bbox": [
            -6.72494712037056,
            41.10502529404766,
            -6.72494712037056,
            41.10502529404766
          ]
        }
      ],
      "bbox": [
        -6.72494712037056,
        41.10502529404766,
        -6.72494712037056,
        41.10502529404766
      ]
    },
    "37351": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.245526366314061,
              41.09628188022414
            ]
          },
          "bbox": [
            -5.245526366314061,
            41.09628188022414,
            -5.245526366314061,
            41.09628188022414
          ]
        }
      ],
      "bbox": [
        -5.245526366314061,
        41.09628188022414,
        -5.245526366314061,
        41.09628188022414
      ]
    },
    "37352": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.510674143874353,
              40.895296908770526
            ]
          },
          "bbox": [
            -5.510674143874353,
            40.895296908770526,
            -5.510674143874353,
            40.895296908770526
          ]
        }
      ],
      "bbox": [
        -5.510674143874353,
        40.895296908770526,
        -5.510674143874353,
        40.895296908770526
      ]
    },
    "37353": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.98602113192467,
              40.79546613453653
            ]
          },
          "bbox": [
            -5.98602113192467,
            40.79546613453653,
            -5.98602113192467,
            40.79546613453653
          ]
        }
      ],
      "bbox": [
        -5.98602113192467,
        40.79546613453653,
        -5.98602113192467,
        40.79546613453653
      ]
    },
    "37354": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7077372410346525,
              41.005395314673756
            ]
          },
          "bbox": [
            -5.7077372410346525,
            41.005395314673756,
            -5.7077372410346525,
            41.005395314673756
          ]
        }
      ],
      "bbox": [
        -5.7077372410346525,
        41.005395314673756,
        -5.7077372410346525,
        41.005395314673756
      ]
    },
    "37355": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.015371219392478,
              40.50194724602978
            ]
          },
          "bbox": [
            -6.015371219392478,
            40.50194724602978,
            -6.015371219392478,
            40.50194724602978
          ]
        }
      ],
      "bbox": [
        -6.015371219392478,
        40.50194724602978,
        -6.015371219392478,
        40.50194724602978
      ]
    },
    "37356": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7043941197555075,
              40.67163100861477
            ]
          },
          "bbox": [
            -6.7043941197555075,
            40.67163100861477,
            -6.7043941197555075,
            40.67163100861477
          ]
        }
      ],
      "bbox": [
        -6.7043941197555075,
        40.67163100861477,
        -6.7043941197555075,
        40.67163100861477
      ]
    },
    "37357": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.760948623936652,
              40.76142848033796
            ]
          },
          "bbox": [
            -6.760948623936652,
            40.76142848033796,
            -6.760948623936652,
            40.76142848033796
          ]
        }
      ],
      "bbox": [
        -6.760948623936652,
        40.76142848033796,
        -6.760948623936652,
        40.76142848033796
      ]
    },
    "37358": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2972610611385536,
              40.957225928118625
            ]
          },
          "bbox": [
            -5.2972610611385536,
            40.957225928118625,
            -5.2972610611385536,
            40.957225928118625
          ]
        }
      ],
      "bbox": [
        -5.2972610611385536,
        40.957225928118625,
        -5.2972610611385536,
        40.957225928118625
      ]
    },
    "37359": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.704195645004998,
              40.723449647468364
            ]
          },
          "bbox": [
            -6.704195645004998,
            40.723449647468364,
            -6.704195645004998,
            40.723449647468364
          ]
        }
      ],
      "bbox": [
        -6.704195645004998,
        40.723449647468364,
        -6.704195645004998,
        40.723449647468364
      ]
    },
    "37360": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.210416094550889,
              41.03547041048568
            ]
          },
          "bbox": [
            -6.210416094550889,
            41.03547041048568,
            -6.210416094550889,
            41.03547041048568
          ]
        }
      ],
      "bbox": [
        -6.210416094550889,
        41.03547041048568,
        -6.210416094550889,
        41.03547041048568
      ]
    },
    "37361": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.43258603382502,
              41.12344775197399
            ]
          },
          "bbox": [
            -6.43258603382502,
            41.12344775197399,
            -6.43258603382502,
            41.12344775197399
          ]
        }
      ],
      "bbox": [
        -6.43258603382502,
        41.12344775197399,
        -6.43258603382502,
        41.12344775197399
      ]
    },
    "37362": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6543369910311965,
              41.006922500375545
            ]
          },
          "bbox": [
            -5.6543369910311965,
            41.006922500375545,
            -5.6543369910311965,
            41.006922500375545
          ]
        }
      ],
      "bbox": [
        -5.6543369910311965,
        41.006922500375545,
        -5.6543369910311965,
        41.006922500375545
      ]
    },
    "37363": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.413663636479916,
              40.87487989630091
            ]
          },
          "bbox": [
            -6.413663636479916,
            40.87487989630091,
            -6.413663636479916,
            40.87487989630091
          ]
        }
      ],
      "bbox": [
        -6.413663636479916,
        40.87487989630091,
        -6.413663636479916,
        40.87487989630091
      ]
    },
    "37364": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4386970110328186,
              41.22423245542727
            ]
          },
          "bbox": [
            -6.4386970110328186,
            41.22423245542727,
            -6.4386970110328186,
            41.22423245542727
          ]
        }
      ],
      "bbox": [
        -6.4386970110328186,
        41.22423245542727,
        -6.4386970110328186,
        41.22423245542727
      ]
    },
    "37365": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.956001207998588,
              41.03947034945023
            ]
          },
          "bbox": [
            -5.956001207998588,
            41.03947034945023,
            -5.956001207998588,
            41.03947034945023
          ]
        }
      ],
      "bbox": [
        -5.956001207998588,
        41.03947034945023,
        -5.956001207998588,
        41.03947034945023
      ]
    },
    "37366": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.350990461211504,
              41.06486472295588
            ]
          },
          "bbox": [
            -6.350990461211504,
            41.06486472295588,
            -6.350990461211504,
            41.06486472295588
          ]
        }
      ],
      "bbox": [
        -6.350990461211504,
        41.06486472295588,
        -6.350990461211504,
        41.06486472295588
      ]
    },
    "37367": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.592571570297235,
              41.045567153877066
            ]
          },
          "bbox": [
            -6.592571570297235,
            41.045567153877066,
            -6.592571570297235,
            41.045567153877066
          ]
        }
      ],
      "bbox": [
        -6.592571570297235,
        41.045567153877066,
        -6.592571570297235,
        41.045567153877066
      ]
    },
    "37368": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1684723959611,
              41.00722847934691
            ]
          },
          "bbox": [
            -6.1684723959611,
            41.00722847934691,
            -6.1684723959611,
            41.00722847934691
          ]
        }
      ],
      "bbox": [
        -6.1684723959611,
        41.00722847934691,
        -6.1684723959611,
        41.00722847934691
      ]
    },
    "37369": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.105473891176625,
              41.0381434748215
            ]
          },
          "bbox": [
            -6.105473891176625,
            41.0381434748215,
            -6.105473891176625,
            41.0381434748215
          ]
        }
      ],
      "bbox": [
        -6.105473891176625,
        41.0381434748215,
        -6.105473891176625,
        41.0381434748215
      ]
    },
    "37370": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.145622913272075,
              41.14278630395374
            ]
          },
          "bbox": [
            -6.145622913272075,
            41.14278630395374,
            -6.145622913272075,
            41.14278630395374
          ]
        }
      ],
      "bbox": [
        -6.145622913272075,
        41.14278630395374,
        -6.145622913272075,
        41.14278630395374
      ]
    },
    "37371": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.608817129727552,
              40.31693397414117
            ]
          },
          "bbox": [
            -6.608817129727552,
            40.31693397414117,
            -6.608817129727552,
            40.31693397414117
          ]
        }
      ],
      "bbox": [
        -6.608817129727552,
        40.31693397414117,
        -6.608817129727552,
        40.31693397414117
      ]
    },
    "37372": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.505225016914701,
              41.07109431793811
            ]
          },
          "bbox": [
            -5.505225016914701,
            41.07109431793811,
            -5.505225016914701,
            41.07109431793811
          ]
        }
      ],
      "bbox": [
        -5.505225016914701,
        41.07109431793811,
        -5.505225016914701,
        41.07109431793811
      ]
    },
    "37373": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.46384714324908,
              40.8605092851489
            ]
          },
          "bbox": [
            -6.46384714324908,
            40.8605092851489,
            -6.46384714324908,
            40.8605092851489
          ]
        }
      ],
      "bbox": [
        -6.46384714324908,
        40.8605092851489,
        -6.46384714324908,
        40.8605092851489
      ]
    },
    "37374": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.359138967077214,
              40.990221310420914
            ]
          },
          "bbox": [
            -5.359138967077214,
            40.990221310420914,
            -5.359138967077214,
            40.990221310420914
          ]
        }
      ],
      "bbox": [
        -5.359138967077214,
        40.990221310420914,
        -5.359138967077214,
        40.990221310420914
      ]
    },
    "37375": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.399562771945742,
              41.01041231896349
            ]
          },
          "bbox": [
            -5.399562771945742,
            41.01041231896349,
            -5.399562771945742,
            41.01041231896349
          ]
        }
      ],
      "bbox": [
        -5.399562771945742,
        41.01041231896349,
        -5.399562771945742,
        41.01041231896349
      ]
    },
    "37376": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4249626938133195,
              41.01906549748483
            ]
          },
          "bbox": [
            -6.4249626938133195,
            41.01906549748483,
            -6.4249626938133195,
            41.01906549748483
          ]
        }
      ],
      "bbox": [
        -6.4249626938133195,
        41.01906549748483,
        -6.4249626938133195,
        41.01906549748483
      ]
    },
    "37377": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.49425379646137,
              40.95167420896436
            ]
          },
          "bbox": [
            -6.49425379646137,
            40.95167420896436,
            -6.49425379646137,
            40.95167420896436
          ]
        }
      ],
      "bbox": [
        -6.49425379646137,
        40.95167420896436,
        -6.49425379646137,
        40.95167420896436
      ]
    },
    "37378": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.44194962580679,
              40.49924194530575
            ]
          },
          "bbox": [
            -6.44194962580679,
            40.49924194530575,
            -6.44194962580679,
            40.49924194530575
          ]
        }
      ],
      "bbox": [
        -6.44194962580679,
        40.49924194530575,
        -6.44194962580679,
        40.49924194530575
      ]
    },
    "37379": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.82675062512434,
              41.16085373351056
            ]
          },
          "bbox": [
            -5.82675062512434,
            41.16085373351056,
            -5.82675062512434,
            41.16085373351056
          ]
        }
      ],
      "bbox": [
        -5.82675062512434,
        41.16085373351056,
        -5.82675062512434,
        41.16085373351056
      ]
    },
    "37380": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.839824634206245,
              41.035571622677246
            ]
          },
          "bbox": [
            -5.839824634206245,
            41.035571622677246,
            -5.839824634206245,
            41.035571622677246
          ]
        }
      ],
      "bbox": [
        -5.839824634206245,
        41.035571622677246,
        -5.839824634206245,
        41.035571622677246
      ]
    },
    "37381": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.61855326411831,
              41.15260691024849
            ]
          },
          "bbox": [
            -6.61855326411831,
            41.15260691024849,
            -6.61855326411831,
            41.15260691024849
          ]
        }
      ],
      "bbox": [
        -6.61855326411831,
        41.15260691024849,
        -6.61855326411831,
        41.15260691024849
      ]
    },
    "37382": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.202281266576444,
              41.01404375405288
            ]
          },
          "bbox": [
            -5.202281266576444,
            41.01404375405288,
            -5.202281266576444,
            41.01404375405288
          ]
        }
      ],
      "bbox": [
        -5.202281266576444,
        41.01404375405288,
        -5.202281266576444,
        41.01404375405288
      ]
    },
    "38001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.72418654162319,
              28.139928252589385
            ]
          },
          "bbox": [
            -16.72418654162319,
            28.139928252589385,
            -16.72418654162319,
            28.139928252589385
          ]
        }
      ],
      "bbox": [
        -16.72418654162319,
        28.139928252589385,
        -16.72418654162319,
        28.139928252589385
      ]
    },
    "38002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.225045399106524,
              28.168767077014035
            ]
          },
          "bbox": [
            -17.225045399106524,
            28.168767077014035,
            -17.225045399106524,
            28.168767077014035
          ]
        }
      ],
      "bbox": [
        -17.225045399106524,
        28.168767077014035,
        -17.225045399106524,
        28.168767077014035
      ]
    },
    "38003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.245237569916373,
              28.05495999275349
            ]
          },
          "bbox": [
            -17.245237569916373,
            28.05495999275349,
            -17.245237569916373,
            28.05495999275349
          ]
        }
      ],
      "bbox": [
        -17.245237569916373,
        28.05495999275349,
        -17.245237569916373,
        28.05495999275349
      ]
    },
    "38004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.433189818371677,
              28.350690614782145
            ]
          },
          "bbox": [
            -16.433189818371677,
            28.350690614782145,
            -16.433189818371677,
            28.350690614782145
          ]
        }
      ],
      "bbox": [
        -16.433189818371677,
        28.350690614782145,
        -16.433189818371677,
        28.350690614782145
      ]
    },
    "38005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.504309236512764,
              28.19246211672525
            ]
          },
          "bbox": [
            -16.504309236512764,
            28.19246211672525,
            -16.504309236512764,
            28.19246211672525
          ]
        }
      ],
      "bbox": [
        -16.504309236512764,
        28.19246211672525,
        -16.504309236512764,
        28.19246211672525
      ]
    },
    "38006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.67597581369557,
              28.055222838100786
            ]
          },
          "bbox": [
            -16.67597581369557,
            28.055222838100786,
            -16.67597581369557,
            28.055222838100786
          ]
        }
      ],
      "bbox": [
        -16.67597581369557,
        28.055222838100786,
        -16.67597581369557,
        28.055222838100786
      ]
    },
    "38007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.821964286337945,
              28.80926467598653
            ]
          },
          "bbox": [
            -17.821964286337945,
            28.80926467598653,
            -17.821964286337945,
            28.80926467598653
          ]
        }
      ],
      "bbox": [
        -17.821964286337945,
        28.80926467598653,
        -17.821964286337945,
        28.80926467598653
      ]
    },
    "38008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.80325397194169,
              28.656582955080065
            ]
          },
          "bbox": [
            -17.80325397194169,
            28.656582955080065,
            -17.80325397194169,
            28.656582955080065
          ]
        }
      ],
      "bbox": [
        -17.80325397194169,
        28.656582955080065,
        -17.80325397194169,
        28.656582955080065
      ]
    },
    "38009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.790360194428064,
              28.631412597673343
            ]
          },
          "bbox": [
            -17.790360194428064,
            28.631412597673343,
            -17.790360194428064,
            28.631412597673343
          ]
        }
      ],
      "bbox": [
        -17.790360194428064,
        28.631412597673343,
        -17.790360194428064,
        28.631412597673343
      ]
    },
    "38010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.863085740971076,
              28.335099563935035
            ]
          },
          "bbox": [
            -16.863085740971076,
            28.335099563935035,
            -16.863085740971076,
            28.335099563935035
          ]
        }
      ],
      "bbox": [
        -16.863085740971076,
        28.335099563935035,
        -16.863085740971076,
        28.335099563935035
      ]
    },
    "38011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.39262734955592,
              28.381290482425907
            ]
          },
          "bbox": [
            -16.39262734955592,
            28.381290482425907,
            -16.39262734955592,
            28.381290482425907
          ]
        }
      ],
      "bbox": [
        -16.39262734955592,
        28.381290482425907,
        -16.39262734955592,
        28.381290482425907
      ]
    },
    "38012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.46680675509438,
              28.249550287981425
            ]
          },
          "bbox": [
            -16.46680675509438,
            28.249550287981425,
            -16.46680675509438,
            28.249550287981425
          ]
        }
      ],
      "bbox": [
        -16.46680675509438,
        28.249550287981425,
        -16.46680675509438,
        28.249550287981425
      ]
    },
    "38013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -18.066517764575163,
              27.748005669999976
            ]
          },
          "bbox": [
            -18.066517764575163,
            27.748005669999976,
            -18.066517764575163,
            27.748005669999976
          ]
        }
      ],
      "bbox": [
        -18.066517764575163,
        27.748005669999976,
        -18.066517764575163,
        27.748005669999976
      ]
    },
    "38014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.846412228042713,
              28.50861859193728
            ]
          },
          "bbox": [
            -17.846412228042713,
            28.50861859193728,
            -17.846412228042713,
            28.50861859193728
          ]
        }
      ],
      "bbox": [
        -17.846412228042713,
        28.50861859193728,
        -17.846412228042713,
        28.50861859193728
      ]
    },
    "38015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.75139071873687,
              28.332189762784065
            ]
          },
          "bbox": [
            -16.75139071873687,
            28.332189762784065,
            -16.75139071873687,
            28.332189762784065
          ]
        }
      ],
      "bbox": [
        -16.75139071873687,
        28.332189762784065,
        -16.75139071873687,
        28.332189762784065
      ]
    },
    "38016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.912414332614965,
              28.801733893762005
            ]
          },
          "bbox": [
            -17.912414332614965,
            28.801733893762005,
            -17.912414332614965,
            28.801733893762005
          ]
        }
      ],
      "bbox": [
        -17.912414332614965,
        28.801733893762005,
        -17.912414332614965,
        28.801733893762005
      ]
    },
    "38017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.56381603236341,
              28.11185005004726
            ]
          },
          "bbox": [
            -16.56381603236341,
            28.11185005004726,
            -16.56381603236341,
            28.11185005004726
          ]
        }
      ],
      "bbox": [
        -16.56381603236341,
        28.11185005004726,
        -16.56381603236341,
        28.11185005004726
      ]
    },
    "38018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.64640825217665,
              28.353153626326673
            ]
          },
          "bbox": [
            -16.64640825217665,
            28.353153626326673,
            -16.64640825217665,
            28.353153626326673
          ]
        }
      ],
      "bbox": [
        -16.64640825217665,
        28.353153626326673,
        -16.64640825217665,
        28.353153626326673
      ]
    },
    "38019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.7585651432986,
              28.220424317065344
            ]
          },
          "bbox": [
            -16.7585651432986,
            28.220424317065344,
            -16.7585651432986,
            28.220424317065344
          ]
        }
      ],
      "bbox": [
        -16.7585651432986,
        28.220424317065344,
        -16.7585651432986,
        28.220424317065344
      ]
    },
    "38020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.432452345763178,
              28.295298069753546
            ]
          },
          "bbox": [
            -16.432452345763178,
            28.295298069753546,
            -16.432452345763178,
            28.295298069753546
          ]
        }
      ],
      "bbox": [
        -16.432452345763178,
        28.295298069753546,
        -16.432452345763178,
        28.295298069753546
      ]
    },
    "38021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.19800576888164,
              28.147741062739243
            ]
          },
          "bbox": [
            -17.19800576888164,
            28.147741062739243,
            -17.19800576888164,
            28.147741062739243
          ]
        }
      ],
      "bbox": [
        -17.19800576888164,
        28.147741062739243,
        -17.19800576888164,
        28.147741062739243
      ]
    },
    "38022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.693465400491675,
              28.330734221617387
            ]
          },
          "bbox": [
            -16.693465400491675,
            28.330734221617387,
            -16.693465400491675,
            28.330734221617387
          ]
        }
      ],
      "bbox": [
        -16.693465400491675,
        28.330734221617387,
        -16.693465400491675,
        28.330734221617387
      ]
    },
    "38023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.32961721078579,
              28.513358030665554
            ]
          },
          "bbox": [
            -16.32961721078579,
            28.513358030665554,
            -16.32961721078579,
            28.513358030665554
          ]
        }
      ],
      "bbox": [
        -16.32961721078579,
        28.513358030665554,
        -16.32961721078579,
        28.513358030665554
      ]
    },
    "38024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.902505255957465,
              28.616855604360325
            ]
          },
          "bbox": [
            -17.902505255957465,
            28.616855604360325,
            -17.902505255957465,
            28.616855604360325
          ]
        }
      ],
      "bbox": [
        -17.902505255957465,
        28.616855604360325,
        -17.902505255957465,
        28.616855604360325
      ]
    },
    "38025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.442137522484618,
              28.4408606586024
            ]
          },
          "bbox": [
            -16.442137522484618,
            28.4408606586024,
            -16.442137522484618,
            28.4408606586024
          ]
        }
      ],
      "bbox": [
        -16.442137522484618,
        28.4408606586024,
        -16.442137522484618,
        28.4408606586024
      ]
    },
    "38026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.57694747947443,
              28.29893895257943
            ]
          },
          "bbox": [
            -16.57694747947443,
            28.29893895257943,
            -16.57694747947443,
            28.29893895257943
          ]
        }
      ],
      "bbox": [
        -16.57694747947443,
        28.29893895257943,
        -16.57694747947443,
        28.29893895257943
      ]
    },
    "38027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.86756100722944,
              28.664914352351527
            ]
          },
          "bbox": [
            -17.86756100722944,
            28.664914352351527,
            -17.86756100722944,
            28.664914352351527
          ]
        }
      ],
      "bbox": [
        -17.86756100722944,
        28.664914352351527,
        -17.86756100722944,
        28.664914352351527
      ]
    },
    "38028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.547200750305752,
              28.40756171631645
            ]
          },
          "bbox": [
            -16.547200750305752,
            28.40756171631645,
            -16.547200750305752,
            28.40756171631645
          ]
        }
      ],
      "bbox": [
        -16.547200750305752,
        28.40756171631645,
        -16.547200750305752,
        28.40756171631645
      ]
    },
    "38029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.965180048186294,
              28.76209144410647
            ]
          },
          "bbox": [
            -17.965180048186294,
            28.76209144410647,
            -17.965180048186294,
            28.76209144410647
          ]
        }
      ],
      "bbox": [
        -17.965180048186294,
        28.76209144410647,
        -17.965180048186294,
        28.76209144410647
      ]
    },
    "38030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.765686832519194,
              28.74231571240463
            ]
          },
          "bbox": [
            -17.765686832519194,
            28.74231571240463,
            -17.765686832519194,
            28.74231571240463
          ]
        }
      ],
      "bbox": [
        -17.765686832519194,
        28.74231571240463,
        -17.765686832519194,
        28.74231571240463
      ]
    },
    "38031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.585624012311314,
              28.354410920406195
            ]
          },
          "bbox": [
            -16.585624012311314,
            28.354410920406195,
            -16.585624012311314,
            28.354410920406195
          ]
        }
      ],
      "bbox": [
        -16.585624012311314,
        28.354410920406195,
        -16.585624012311314,
        28.354410920406195
      ]
    },
    "38032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.359024419037983,
              28.430394458418487
            ]
          },
          "bbox": [
            -16.359024419037983,
            28.430394458418487,
            -16.359024419037983,
            28.430394458418487
          ]
        }
      ],
      "bbox": [
        -16.359024419037983,
        28.430394458418487,
        -16.359024419037983,
        28.430394458418487
      ]
    },
    "38033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.799258912444873,
              28.776483667352405
            ]
          },
          "bbox": [
            -17.799258912444873,
            28.776483667352405,
            -17.799258912444873,
            28.776483667352405
          ]
        }
      ],
      "bbox": [
        -17.799258912444873,
        28.776483667352405,
        -17.799258912444873,
        28.776483667352405
      ]
    },
    "38034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.624978247707418,
              28.355373284952567
            ]
          },
          "bbox": [
            -16.624978247707418,
            28.355373284952567,
            -16.624978247707418,
            28.355373284952567
          ]
        }
      ],
      "bbox": [
        -16.624978247707418,
        28.355373284952567,
        -16.624978247707418,
        28.355373284952567
      ]
    },
    "38035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.621999463678414,
              28.07065784520956
            ]
          },
          "bbox": [
            -16.621999463678414,
            28.07065784520956,
            -16.621999463678414,
            28.07065784520956
          ]
        }
      ],
      "bbox": [
        -16.621999463678414,
        28.07065784520956,
        -16.621999463678414,
        28.07065784520956
      ]
    },
    "38036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.166112063999698,
              28.096840483227293
            ]
          },
          "bbox": [
            -17.166112063999698,
            28.096840483227293,
            -17.166112063999698,
            28.096840483227293
          ]
        }
      ],
      "bbox": [
        -17.166112063999698,
        28.096840483227293,
        -17.166112063999698,
        28.096840483227293
      ]
    },
    "38037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.79988599873302,
              28.7075591615191
            ]
          },
          "bbox": [
            -17.79988599873302,
            28.7075591615191,
            -17.79988599873302,
            28.7075591615191
          ]
        }
      ],
      "bbox": [
        -17.79988599873302,
        28.7075591615191,
        -17.79988599873302,
        28.7075591615191
      ]
    },
    "38038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.22541948007827,
              28.517866582941508
            ]
          },
          "bbox": [
            -16.22541948007827,
            28.517866582941508,
            -16.22541948007827,
            28.517866582941508
          ]
        }
      ],
      "bbox": [
        -16.22541948007827,
        28.517866582941508,
        -16.22541948007827,
        28.517866582941508
      ]
    },
    "38039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.477351508924386,
              28.406929606256416
            ]
          },
          "bbox": [
            -16.477351508924386,
            28.406929606256416,
            -16.477351508924386,
            28.406929606256416
          ]
        }
      ],
      "bbox": [
        -16.477351508924386,
        28.406929606256416,
        -16.477351508924386,
        28.406929606256416
      ]
    },
    "38040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.79676153746455,
              28.278472207047006
            ]
          },
          "bbox": [
            -16.79676153746455,
            28.278472207047006,
            -16.79676153746455,
            28.278472207047006
          ]
        }
      ],
      "bbox": [
        -16.79676153746455,
        28.278472207047006,
        -16.79676153746455,
        28.278472207047006
      ]
    },
    "38041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.423995496046174,
              28.456229490966013
            ]
          },
          "bbox": [
            -16.423995496046174,
            28.456229490966013,
            -16.423995496046174,
            28.456229490966013
          ]
        }
      ],
      "bbox": [
        -16.423995496046174,
        28.456229490966013,
        -16.423995496046174,
        28.456229490966013
      ]
    },
    "38042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.816497766517884,
              28.351887192679094
            ]
          },
          "bbox": [
            -16.816497766517884,
            28.351887192679094,
            -16.816497766517884,
            28.351887192679094
          ]
        }
      ],
      "bbox": [
        -16.816497766517884,
        28.351887192679094,
        -16.816497766517884,
        28.351887192679094
      ]
    },
    "38043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.400494632739502,
              28.48091405493962
            ]
          },
          "bbox": [
            -16.400494632739502,
            28.48091405493962,
            -16.400494632739502,
            28.48091405493962
          ]
        }
      ],
      "bbox": [
        -16.400494632739502,
        28.48091405493962,
        -16.400494632739502,
        28.48091405493962
      ]
    },
    "38044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.7805570923939,
              28.330876879066576
            ]
          },
          "bbox": [
            -16.7805570923939,
            28.330876879066576,
            -16.7805570923939,
            28.330876879066576
          ]
        }
      ],
      "bbox": [
        -16.7805570923939,
        28.330876879066576,
        -16.7805570923939,
        28.330876879066576
      ]
    },
    "38045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.92512525200051,
              28.624406622211023
            ]
          },
          "bbox": [
            -17.92512525200051,
            28.624406622211023,
            -17.92512525200051,
            28.624406622211023
          ]
        }
      ],
      "bbox": [
        -17.92512525200051,
        28.624406622211023,
        -17.92512525200051,
        28.624406622211023
      ]
    },
    "38046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.33577208659237,
              28.519976242421947
            ]
          },
          "bbox": [
            -16.33577208659237,
            28.519976242421947,
            -16.33577208659237,
            28.519976242421947
          ]
        }
      ],
      "bbox": [
        -16.33577208659237,
        28.519976242421947,
        -16.33577208659237,
        28.519976242421947
      ]
    },
    "38047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.945690704448577,
              28.71106362246999
            ]
          },
          "bbox": [
            -17.945690704448577,
            28.71106362246999,
            -17.945690704448577,
            28.71106362246999
          ]
        }
      ],
      "bbox": [
        -17.945690704448577,
        28.71106362246999,
        -17.945690704448577,
        28.71106362246999
      ]
    },
    "38048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.943767134886482,
              27.785343495005616
            ]
          },
          "bbox": [
            -17.943767134886482,
            27.785343495005616,
            -17.943767134886482,
            27.785343495005616
          ]
        }
      ],
      "bbox": [
        -17.943767134886482,
        27.785343495005616,
        -17.943767134886482,
        27.785343495005616
      ]
    },
    "38049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.318761102229395,
              28.123320613060983
            ]
          },
          "bbox": [
            -17.318761102229395,
            28.123320613060983,
            -17.318761102229395,
            28.123320613060983
          ]
        }
      ],
      "bbox": [
        -17.318761102229395,
        28.123320613060983,
        -17.318761102229395,
        28.123320613060983
      ]
    },
    "38050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.284448790150858,
              28.1425614769487
            ]
          },
          "bbox": [
            -17.284448790150858,
            28.1425614769487,
            -17.284448790150858,
            28.1425614769487
          ]
        }
      ],
      "bbox": [
        -17.284448790150858,
        28.1425614769487,
        -17.284448790150858,
        28.1425614769487
      ]
    },
    "38051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.44822316635456,
              28.418700318735976
            ]
          },
          "bbox": [
            -16.44822316635456,
            28.418700318735976,
            -16.44822316635456,
            28.418700318735976
          ]
        }
      ],
      "bbox": [
        -16.44822316635456,
        28.418700318735976,
        -16.44822316635456,
        28.418700318735976
      ]
    },
    "38052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -16.64749877119589,
              28.155588469731594
            ]
          },
          "bbox": [
            -16.64749877119589,
            28.155588469731594,
            -16.64749877119589,
            28.155588469731594
          ]
        }
      ],
      "bbox": [
        -16.64749877119589,
        28.155588469731594,
        -16.64749877119589,
        28.155588469731594
      ]
    },
    "38053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -17.797168598399054,
              28.57962022581511
            ]
          },
          "bbox": [
            -17.797168598399054,
            28.57962022581511,
            -17.797168598399054,
            28.57962022581511
          ]
        }
      ],
      "bbox": [
        -17.797168598399054,
        28.57962022581511,
        -17.797168598399054,
        28.57962022581511
      ]
    },
    "38901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -18.025379472306224,
              27.69597877904674
            ]
          },
          "bbox": [
            -18.025379472306224,
            27.69597877904674,
            -18.025379472306224,
            27.69597877904674
          ]
        }
      ],
      "bbox": [
        -18.025379472306224,
        27.69597877904674,
        -18.025379472306224,
        27.69597877904674
      ]
    },
    "39001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1828450122324465,
              43.37647742529682
            ]
          },
          "bbox": [
            -4.1828450122324465,
            43.37647742529682,
            -4.1828450122324465,
            43.37647742529682
          ]
        }
      ],
      "bbox": [
        -4.1828450122324465,
        43.37647742529682,
        -4.1828450122324465,
        43.37647742529682
      ]
    },
    "39002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4166139902483383,
              43.33310422442909
            ]
          },
          "bbox": [
            -3.4166139902483383,
            43.33310422442909,
            -3.4166139902483383,
            43.33310422442909
          ]
        }
      ],
      "bbox": [
        -3.4166139902483383,
        43.33310422442909,
        -3.4166139902483383,
        43.33310422442909
      ]
    },
    "39003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0034470348108435,
              43.20052558096398
            ]
          },
          "bbox": [
            -4.0034470348108435,
            43.20052558096398,
            -4.0034470348108435,
            43.20052558096398
          ]
        }
      ],
      "bbox": [
        -4.0034470348108435,
        43.20052558096398,
        -4.0034470348108435,
        43.20052558096398
      ]
    },
    "39004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.094617295605973,
              43.176925991720964
            ]
          },
          "bbox": [
            -4.094617295605973,
            43.176925991720964,
            -4.094617295605973,
            43.176925991720964
          ]
        }
      ],
      "bbox": [
        -4.094617295605973,
        43.176925991720964,
        -4.094617295605973,
        43.176925991720964
      ]
    },
    "39005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.493592900286852,
              43.45499850207527
            ]
          },
          "bbox": [
            -3.493592900286852,
            43.45499850207527,
            -3.493592900286852,
            43.45499850207527
          ]
        }
      ],
      "bbox": [
        -3.493592900286852,
        43.45499850207527,
        -3.493592900286852,
        43.45499850207527
      ]
    },
    "39006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.557489322181361,
              43.47746746489962
            ]
          },
          "bbox": [
            -3.557489322181361,
            43.47746746489962,
            -3.557489322181361,
            43.47746746489962
          ]
        }
      ],
      "bbox": [
        -3.557489322181361,
        43.47746746489962,
        -3.557489322181361,
        43.47746746489962
      ]
    },
    "39007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6334633804246455,
              43.26765627284974
            ]
          },
          "bbox": [
            -3.6334633804246455,
            43.26765627284974,
            -3.6334633804246455,
            43.26765627284974
          ]
        }
      ],
      "bbox": [
        -3.6334633804246455,
        43.26765627284974,
        -3.6334633804246455,
        43.26765627284974
      ]
    },
    "39008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.837108976611175,
              43.399297494676844
            ]
          },
          "bbox": [
            -3.837108976611175,
            43.399297494676844,
            -3.837108976611175,
            43.399297494676844
          ]
        }
      ],
      "bbox": [
        -3.837108976611175,
        43.399297494676844,
        -3.837108976611175,
        43.399297494676844
      ]
    },
    "39009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5077550800443507,
              43.40413998015126
            ]
          },
          "bbox": [
            -3.5077550800443507,
            43.40413998015126,
            -3.5077550800443507,
            43.40413998015126
          ]
        }
      ],
      "bbox": [
        -3.5077550800443507,
        43.40413998015126,
        -3.5077550800443507,
        43.40413998015126
      ]
    },
    "39010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0761317161367145,
              43.11418165067902
            ]
          },
          "bbox": [
            -4.0761317161367145,
            43.11418165067902,
            -4.0761317161367145,
            43.11418165067902
          ]
        }
      ],
      "bbox": [
        -4.0761317161367145,
        43.11418165067902,
        -4.0761317161367145,
        43.11418165067902
      ]
    },
    "39011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.618812300193925,
              43.47286720568433
            ]
          },
          "bbox": [
            -3.618812300193925,
            43.47286720568433,
            -3.618812300193925,
            43.47286720568433
          ]
        }
      ],
      "bbox": [
        -3.618812300193925,
        43.47286720568433,
        -3.618812300193925,
        43.47286720568433
      ]
    },
    "39012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2318166470580065,
              43.31124880881355
            ]
          },
          "bbox": [
            -4.2318166470580065,
            43.31124880881355,
            -4.2318166470580065,
            43.31124880881355
          ]
        }
      ],
      "bbox": [
        -4.2318166470580065,
        43.31124880881355,
        -4.2318166470580065,
        43.31124880881355
      ]
    },
    "39013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.540172338791048,
              43.12954032001831
            ]
          },
          "bbox": [
            -4.540172338791048,
            43.12954032001831,
            -4.540172338791048,
            43.12954032001831
          ]
        }
      ],
      "bbox": [
        -4.540172338791048,
        43.12954032001831,
        -4.540172338791048,
        43.12954032001831
      ]
    },
    "39014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.304383865325494,
              43.21147874218021
            ]
          },
          "bbox": [
            -4.304383865325494,
            43.21147874218021,
            -4.304383865325494,
            43.21147874218021
          ]
        }
      ],
      "bbox": [
        -4.304383865325494,
        43.21147874218021,
        -4.304383865325494,
        43.21147874218021
      ]
    },
    "39015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.747252467790232,
              43.14473684382374
            ]
          },
          "bbox": [
            -4.747252467790232,
            43.14473684382374,
            -4.747252467790232,
            43.14473684382374
          ]
        }
      ],
      "bbox": [
        -4.747252467790232,
        43.14473684382374,
        -4.747252467790232,
        43.14473684382374
      ]
    },
    "39016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8727914999796154,
              43.41230387716497
            ]
          },
          "bbox": [
            -3.8727914999796154,
            43.41230387716497,
            -3.8727914999796154,
            43.41230387716497
          ]
        }
      ],
      "bbox": [
        -3.8727914999796154,
        43.41230387716497,
        -3.8727914999796154,
        43.41230387716497
      ]
    },
    "39017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.985548035269485,
              43.02802650805132
            ]
          },
          "bbox": [
            -3.985548035269485,
            43.02802650805132,
            -3.985548035269485,
            43.02802650805132
          ]
        }
      ],
      "bbox": [
        -3.985548035269485,
        43.02802650805132,
        -3.985548035269485,
        43.02802650805132
      ]
    },
    "39018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.092616925168678,
              43.31546072921004
            ]
          },
          "bbox": [
            -4.092616925168678,
            43.31546072921004,
            -4.092616925168678,
            43.31546072921004
          ]
        }
      ],
      "bbox": [
        -4.092616925168678,
        43.31546072921004,
        -4.092616925168678,
        43.31546072921004
      ]
    },
    "39019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9284882265896814,
              43.31822252699532
            ]
          },
          "bbox": [
            -3.9284882265896814,
            43.31822252699532,
            -3.9284882265896814,
            43.31822252699532
          ]
        }
      ],
      "bbox": [
        -3.9284882265896814,
        43.31822252699532,
        -3.9284882265896814,
        43.31822252699532
      ]
    },
    "39020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2298672435222864,
              43.35151259721022
            ]
          },
          "bbox": [
            -3.2298672435222864,
            43.35151259721022,
            -3.2298672435222864,
            43.35151259721022
          ]
        }
      ],
      "bbox": [
        -3.2298672435222864,
        43.35151259721022,
        -3.2298672435222864,
        43.35151259721022
      ]
    },
    "39021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.130607375325202,
              43.2234099682894
            ]
          },
          "bbox": [
            -4.130607375325202,
            43.2234099682894,
            -4.130607375325202,
            43.2234099682894
          ]
        }
      ],
      "bbox": [
        -4.130607375325202,
        43.2234099682894,
        -4.130607375325202,
        43.2234099682894
      ]
    },
    "39022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.621253266300471,
              43.2065238755162
            ]
          },
          "bbox": [
            -4.621253266300471,
            43.2065238755162,
            -4.621253266300471,
            43.2065238755162
          ]
        }
      ],
      "bbox": [
        -4.621253266300471,
        43.2065238755162,
        -4.621253266300471,
        43.2065238755162
      ]
    },
    "39023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.441791429800403,
              43.388843832018686
            ]
          },
          "bbox": [
            -3.441791429800403,
            43.388843832018686,
            -3.441791429800403,
            43.388843832018686
          ]
        }
      ],
      "bbox": [
        -3.441791429800403,
        43.388843832018686,
        -3.441791429800403,
        43.388843832018686
      ]
    },
    "39024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.293991310845308,
              43.36598154409799
            ]
          },
          "bbox": [
            -4.293991310845308,
            43.36598154409799,
            -4.293991310845308,
            43.36598154409799
          ]
        }
      ],
      "bbox": [
        -4.293991310845308,
        43.36598154409799,
        -4.293991310845308,
        43.36598154409799
      ]
    },
    "39025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.090966767458467,
              43.261683123668845
            ]
          },
          "bbox": [
            -4.090966767458467,
            43.261683123668845,
            -4.090966767458467,
            43.261683123668845
          ]
        }
      ],
      "bbox": [
        -4.090966767458467,
        43.261683123668845,
        -4.090966767458467,
        43.261683123668845
      ]
    },
    "39026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.953697238545257,
              43.21083588473826
            ]
          },
          "bbox": [
            -3.953697238545257,
            43.21083588473826,
            -3.953697238545257,
            43.21083588473826
          ]
        }
      ],
      "bbox": [
        -3.953697238545257,
        43.21083588473826,
        -3.953697238545257,
        43.21083588473826
      ]
    },
    "39027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.123194645108488,
              42.99182045210859
            ]
          },
          "bbox": [
            -4.123194645108488,
            42.99182045210859,
            -4.123194645108488,
            42.99182045210859
          ]
        }
      ],
      "bbox": [
        -4.123194645108488,
        42.99182045210859,
        -4.123194645108488,
        42.99182045210859
      ]
    },
    "39028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.668130836413156,
              43.36408047384126
            ]
          },
          "bbox": [
            -3.668130836413156,
            43.36408047384126,
            -3.668130836413156,
            43.36408047384126
          ]
        }
      ],
      "bbox": [
        -3.668130836413156,
        43.36408047384126,
        -3.668130836413156,
        43.36408047384126
      ]
    },
    "39029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5195238627521728,
              43.436649845302036
            ]
          },
          "bbox": [
            -3.5195238627521728,
            43.436649845302036,
            -3.5195238627521728,
            43.436649845302036
          ]
        }
      ],
      "bbox": [
        -3.5195238627521728,
        43.436649845302036,
        -3.5195238627521728,
        43.436649845302036
      ]
    },
    "39030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3072277106071857,
              43.3315467425995
            ]
          },
          "bbox": [
            -3.3072277106071857,
            43.3315467425995,
            -3.3072277106071857,
            43.3315467425995
          ]
        }
      ],
      "bbox": [
        -3.3072277106071857,
        43.3315467425995,
        -3.3072277106071857,
        43.3315467425995
      ]
    },
    "39031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.585879553892432,
              43.40488943864658
            ]
          },
          "bbox": [
            -3.585879553892432,
            43.40488943864658,
            -3.585879553892432,
            43.40488943864658
          ]
        }
      ],
      "bbox": [
        -3.585879553892432,
        43.40488943864658,
        -3.585879553892432,
        43.40488943864658
      ]
    },
    "39032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.250728496045292,
              43.02431243027372
            ]
          },
          "bbox": [
            -4.250728496045292,
            43.02431243027372,
            -4.250728496045292,
            43.02431243027372
          ]
        }
      ],
      "bbox": [
        -4.250728496045292,
        43.02431243027372,
        -4.250728496045292,
        43.02431243027372
      ]
    },
    "39033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4775818637003875,
              43.310867320941064
            ]
          },
          "bbox": [
            -4.4775818637003875,
            43.310867320941064,
            -4.4775818637003875,
            43.310867320941064
          ]
        }
      ],
      "bbox": [
        -4.4775818637003875,
        43.310867320941064,
        -4.4775818637003875,
        43.310867320941064
      ]
    },
    "39034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4932372571257915,
              43.227706112708844
            ]
          },
          "bbox": [
            -4.4932372571257915,
            43.227706112708844,
            -4.4932372571257915,
            43.227706112708844
          ]
        }
      ],
      "bbox": [
        -4.4932372571257915,
        43.227706112708844,
        -4.4932372571257915,
        43.227706112708844
      ]
    },
    "39035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4239056938036496,
              43.40727555115207
            ]
          },
          "bbox": [
            -3.4239056938036496,
            43.40727555115207,
            -3.4239056938036496,
            43.40727555115207
          ]
        }
      ],
      "bbox": [
        -3.4239056938036496,
        43.40727555115207,
        -3.4239056938036496,
        43.40727555115207
      ]
    },
    "39036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.365392960577763,
              43.38958618200724
            ]
          },
          "bbox": [
            -3.365392960577763,
            43.38958618200724,
            -3.365392960577763,
            43.38958618200724
          ]
        }
      ],
      "bbox": [
        -3.365392960577763,
        43.38958618200724,
        -3.365392960577763,
        43.38958618200724
      ]
    },
    "39037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7628874420567207,
              43.336036118967556
            ]
          },
          "bbox": [
            -3.7628874420567207,
            43.336036118967556,
            -3.7628874420567207,
            43.336036118967556
          ]
        }
      ],
      "bbox": [
        -3.7628874420567207,
        43.336036118967556,
        -3.7628874420567207,
        43.336036118967556
      ]
    },
    "39038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.414606595856485,
              43.37438305567645
            ]
          },
          "bbox": [
            -3.414606595856485,
            43.37438305567645,
            -3.414606595856485,
            43.37438305567645
          ]
        }
      ],
      "bbox": [
        -3.414606595856485,
        43.37438305567645,
        -3.414606595856485,
        43.37438305567645
      ]
    },
    "39039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9011535943947915,
              43.10695178496867
            ]
          },
          "bbox": [
            -3.9011535943947915,
            43.10695178496867,
            -3.9011535943947915,
            43.10695178496867
          ]
        }
      ],
      "bbox": [
        -3.9011535943947915,
        43.10695178496867,
        -3.9011535943947915,
        43.10695178496867
      ]
    },
    "39040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.757868819860722,
              43.41756445292682
            ]
          },
          "bbox": [
            -3.757868819860722,
            43.41756445292682,
            -3.757868819860722,
            43.41756445292682
          ]
        }
      ],
      "bbox": [
        -3.757868819860722,
        43.41756445292682,
        -3.757868819860722,
        43.41756445292682
      ]
    },
    "39041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.175225164339384,
              43.289630256415755
            ]
          },
          "bbox": [
            -4.175225164339384,
            43.289630256415755,
            -4.175225164339384,
            43.289630256415755
          ]
        }
      ],
      "bbox": [
        -4.175225164339384,
        43.289630256415755,
        -4.175225164339384,
        43.289630256415755
      ]
    },
    "39042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7593923822048367,
              43.379856226314516
            ]
          },
          "bbox": [
            -3.7593923822048367,
            43.379856226314516,
            -3.7593923822048367,
            43.379856226314516
          ]
        }
      ],
      "bbox": [
        -3.7593923822048367,
        43.379856226314516,
        -3.7593923822048367,
        43.379856226314516
      ]
    },
    "39043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.585138358000587,
              43.444231445745295
            ]
          },
          "bbox": [
            -3.585138358000587,
            43.444231445745295,
            -3.585138358000587,
            43.444231445745295
          ]
        }
      ],
      "bbox": [
        -3.585138358000587,
        43.444231445745295,
        -3.585138358000587,
        43.444231445745295
      ]
    },
    "39044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.996341841468225,
              43.42415047314379
            ]
          },
          "bbox": [
            -3.996341841468225,
            43.42415047314379,
            -3.996341841468225,
            43.42415047314379
          ]
        }
      ],
      "bbox": [
        -3.996341841468225,
        43.42415047314379,
        -3.996341841468225,
        43.42415047314379
      ]
    },
    "39045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7178828667894606,
              43.285509900322204
            ]
          },
          "bbox": [
            -3.7178828667894606,
            43.285509900322204,
            -3.7178828667894606,
            43.285509900322204
          ]
        }
      ],
      "bbox": [
        -3.7178828667894606,
        43.285509900322204,
        -3.7178828667894606,
        43.285509900322204
      ]
    },
    "39046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.013214195325655,
              43.13544263660571
            ]
          },
          "bbox": [
            -4.013214195325655,
            43.13544263660571,
            -4.013214195325655,
            43.13544263660571
          ]
        }
      ],
      "bbox": [
        -4.013214195325655,
        43.13544263660571,
        -4.013214195325655,
        43.13544263660571
      ]
    },
    "39047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5202664925276443,
              43.47783746315839
            ]
          },
          "bbox": [
            -3.5202664925276443,
            43.47783746315839,
            -3.5202664925276443,
            43.47783746315839
          ]
        }
      ],
      "bbox": [
        -3.5202664925276443,
        43.47783746315839,
        -3.5202664925276443,
        43.47783746315839
      ]
    },
    "39048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8142897665680677,
              43.33289778541962
            ]
          },
          "bbox": [
            -3.8142897665680677,
            43.33289778541962,
            -3.8142897665680677,
            43.33289778541962
          ]
        }
      ],
      "bbox": [
        -3.8142897665680677,
        43.33289778541962,
        -3.8142897665680677,
        43.33289778541962
      ]
    },
    "39049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.577214914901655,
              43.254792262553224
            ]
          },
          "bbox": [
            -4.577214914901655,
            43.254792262553224,
            -4.577214914901655,
            43.254792262553224
          ]
        }
      ],
      "bbox": [
        -4.577214914901655,
        43.254792262553224,
        -4.577214914901655,
        43.254792262553224
      ]
    },
    "39050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.534857426679365,
              43.064756864644046
            ]
          },
          "bbox": [
            -4.534857426679365,
            43.064756864644046,
            -4.534857426679365,
            43.064756864644046
          ]
        }
      ],
      "bbox": [
        -4.534857426679365,
        43.064756864644046,
        -4.534857426679365,
        43.064756864644046
      ]
    },
    "39051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.067797862514497,
              43.08699814947953
            ]
          },
          "bbox": [
            -4.067797862514497,
            43.08699814947953,
            -4.067797862514497,
            43.08699814947953
          ]
        }
      ],
      "bbox": [
        -4.067797862514497,
        43.08699814947953,
        -4.067797862514497,
        43.08699814947953
      ]
    },
    "39052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9454628018575564,
              43.38729155800693
            ]
          },
          "bbox": [
            -3.9454628018575564,
            43.38729155800693,
            -3.9454628018575564,
            43.38729155800693
          ]
        }
      ],
      "bbox": [
        -3.9454628018575564,
        43.38729155800693,
        -3.9454628018575564,
        43.38729155800693
      ]
    },
    "39053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.414891498368354,
              43.091981026817734
            ]
          },
          "bbox": [
            -4.414891498368354,
            43.091981026817734,
            -4.414891498368354,
            43.091981026817734
          ]
        }
      ],
      "bbox": [
        -4.414891498368354,
        43.091981026817734,
        -4.414891498368354,
        43.091981026817734
      ]
    },
    "39054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0105811439803984,
              43.38062827293602
            ]
          },
          "bbox": [
            -4.0105811439803984,
            43.38062827293602,
            -4.0105811439803984,
            43.38062827293602
          ]
        }
      ],
      "bbox": [
        -4.0105811439803984,
        43.38062827293602,
        -4.0105811439803984,
        43.38062827293602
      ]
    },
    "39055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.623748567413476,
              43.15115254420053
            ]
          },
          "bbox": [
            -4.623748567413476,
            43.15115254420053,
            -4.623748567413476,
            43.15115254420053
          ]
        }
      ],
      "bbox": [
        -4.623748567413476,
        43.15115254420053,
        -4.623748567413476,
        43.15115254420053
      ]
    },
    "39056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9801368481989807,
              43.293862223960836
            ]
          },
          "bbox": [
            -3.9801368481989807,
            43.293862223960836,
            -3.9801368481989807,
            43.293862223960836
          ]
        }
      ],
      "bbox": [
        -3.9801368481989807,
        43.293862223960836,
        -3.9801368481989807,
        43.293862223960836
      ]
    },
    "39057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4424278226933267,
              43.263121118980834
            ]
          },
          "bbox": [
            -3.4424278226933267,
            43.263121118980834,
            -3.4424278226933267,
            43.263121118980834
          ]
        }
      ],
      "bbox": [
        -3.4424278226933267,
        43.263121118980834,
        -3.4424278226933267,
        43.263121118980834
      ]
    },
    "39058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.401756253251431,
              43.298011066446946
            ]
          },
          "bbox": [
            -3.401756253251431,
            43.298011066446946,
            -3.401756253251431,
            43.298011066446946
          ]
        }
      ],
      "bbox": [
        -3.401756253251431,
        43.298011066446946,
        -3.401756253251431,
        43.298011066446946
      ]
    },
    "39059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.136377730818711,
              43.00015513602597
            ]
          },
          "bbox": [
            -4.136377730818711,
            43.00015513602597,
            -4.136377730818711,
            43.00015513602597
          ]
        }
      ],
      "bbox": [
        -4.136377730818711,
        43.00015513602597,
        -4.136377730818711,
        43.00015513602597
      ]
    },
    "39060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.124021273148569,
              43.34918637327286
            ]
          },
          "bbox": [
            -4.124021273148569,
            43.34918637327286,
            -4.124021273148569,
            43.34918637327286
          ]
        }
      ],
      "bbox": [
        -4.124021273148569,
        43.34918637327286,
        -4.124021273148569,
        43.34918637327286
      ]
    },
    "39061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.693902458570339,
              43.457926094995464
            ]
          },
          "bbox": [
            -3.693902458570339,
            43.457926094995464,
            -3.693902458570339,
            43.457926094995464
          ]
        }
      ],
      "bbox": [
        -3.693902458570339,
        43.457926094995464,
        -3.693902458570339,
        43.457926094995464
      ]
    },
    "39062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6585714778158946,
              43.41073693015888
            ]
          },
          "bbox": [
            -3.6585714778158946,
            43.41073693015888,
            -3.6585714778158946,
            43.41073693015888
          ]
        }
      ],
      "bbox": [
        -3.6585714778158946,
        43.41073693015888,
        -3.6585714778158946,
        43.41073693015888
      ]
    },
    "39063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4206228918049035,
              43.2203544281877
            ]
          },
          "bbox": [
            -4.4206228918049035,
            43.2203544281877,
            -4.4206228918049035,
            43.2203544281877
          ]
        }
      ],
      "bbox": [
        -4.4206228918049035,
        43.2203544281877,
        -4.4206228918049035,
        43.2203544281877
      ]
    },
    "39064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.688285903228548,
              43.330161088145594
            ]
          },
          "bbox": [
            -3.688285903228548,
            43.330161088145594,
            -3.688285903228548,
            43.330161088145594
          ]
        }
      ],
      "bbox": [
        -3.688285903228548,
        43.330161088145594,
        -3.688285903228548,
        43.330161088145594
      ]
    },
    "39065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.02095619816845,
              42.96764117628424
            ]
          },
          "bbox": [
            -4.02095619816845,
            42.96764117628424,
            -4.02095619816845,
            42.96764117628424
          ]
        }
      ],
      "bbox": [
        -4.02095619816845,
        42.96764117628424,
        -4.02095619816845,
        42.96764117628424
      ]
    },
    "39066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2390038394631135,
              43.237520728490566
            ]
          },
          "bbox": [
            -4.2390038394631135,
            43.237520728490566,
            -4.2390038394631135,
            43.237520728490566
          ]
        }
      ],
      "bbox": [
        -4.2390038394631135,
        43.237520728490566,
        -4.2390038394631135,
        43.237520728490566
      ]
    },
    "39067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.570238430002277,
              43.285756970148604
            ]
          },
          "bbox": [
            -3.570238430002277,
            43.285756970148604,
            -3.570238430002277,
            43.285756970148604
          ]
        }
      ],
      "bbox": [
        -3.570238430002277,
        43.285756970148604,
        -3.570238430002277,
        43.285756970148604
      ]
    },
    "39068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.250875050190655,
              43.3807822204254
            ]
          },
          "bbox": [
            -4.250875050190655,
            43.3807822204254,
            -4.250875050190655,
            43.3807822204254
          ]
        }
      ],
      "bbox": [
        -4.250875050190655,
        43.3807822204254,
        -4.250875050190655,
        43.3807822204254
      ]
    },
    "39069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.031059061249062,
              43.27141591793776
            ]
          },
          "bbox": [
            -4.031059061249062,
            43.27141591793776,
            -4.031059061249062,
            43.27141591793776
          ]
        }
      ],
      "bbox": [
        -4.031059061249062,
        43.27141591793776,
        -4.031059061249062,
        43.27141591793776
      ]
    },
    "39070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.010394266882312,
              43.07174780937717
            ]
          },
          "bbox": [
            -4.010394266882312,
            43.07174780937717,
            -4.010394266882312,
            43.07174780937717
          ]
        }
      ],
      "bbox": [
        -4.010394266882312,
        43.07174780937717,
        -4.010394266882312,
        43.07174780937717
      ]
    },
    "39071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8288767292166543,
              43.117534496680804
            ]
          },
          "bbox": [
            -3.8288767292166543,
            43.117534496680804,
            -3.8288767292166543,
            43.117534496680804
          ]
        }
      ],
      "bbox": [
        -3.8288767292166543,
        43.117534496680804,
        -3.8288767292166543,
        43.117534496680804
      ]
    },
    "39072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7190052238032503,
              43.21910287060846
            ]
          },
          "bbox": [
            -3.7190052238032503,
            43.21910287060846,
            -3.7190052238032503,
            43.21910287060846
          ]
        }
      ],
      "bbox": [
        -3.7190052238032503,
        43.21910287060846,
        -3.7190052238032503,
        43.21910287060846
      ]
    },
    "39073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.902644798676787,
              43.44726094700391
            ]
          },
          "bbox": [
            -3.902644798676787,
            43.44726094700391,
            -3.902644798676787,
            43.44726094700391
          ]
        }
      ],
      "bbox": [
        -3.902644798676787,
        43.44726094700391,
        -3.902644798676787,
        43.44726094700391
      ]
    },
    "39074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8498516837104964,
              43.30057733545366
            ]
          },
          "bbox": [
            -3.8498516837104964,
            43.30057733545366,
            -3.8498516837104964,
            43.30057733545366
          ]
        }
      ],
      "bbox": [
        -3.8498516837104964,
        43.30057733545366,
        -3.8498516837104964,
        43.30057733545366
      ]
    },
    "39075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8327200021311723,
              43.46393375021062
            ]
          },
          "bbox": [
            -3.8327200021311723,
            43.46393375021062,
            -3.8327200021311723,
            43.46393375021062
          ]
        }
      ],
      "bbox": [
        -3.8327200021311723,
        43.46393375021062,
        -3.8327200021311723,
        43.46393375021062
      ]
    },
    "39076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.094642190837565,
              43.39031649665997
            ]
          },
          "bbox": [
            -4.094642190837565,
            43.39031649665997,
            -4.094642190837565,
            43.39031649665997
          ]
        }
      ],
      "bbox": [
        -4.094642190837565,
        43.39031649665997,
        -4.094642190837565,
        43.39031649665997
      ]
    },
    "39077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.088577287745851,
              43.064534390789426
            ]
          },
          "bbox": [
            -4.088577287745851,
            43.064534390789426,
            -4.088577287745851,
            43.064534390789426
          ]
        }
      ],
      "bbox": [
        -4.088577287745851,
        43.064534390789426,
        -4.088577287745851,
        43.064534390789426
      ]
    },
    "39078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9157881242598545,
              43.22864155106579
            ]
          },
          "bbox": [
            -3.9157881242598545,
            43.22864155106579,
            -3.9157881242598545,
            43.22864155106579
          ]
        }
      ],
      "bbox": [
        -3.9157881242598545,
        43.22864155106579,
        -3.9157881242598545,
        43.22864155106579
      ]
    },
    "39079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4527411130232264,
              43.45341792055989
            ]
          },
          "bbox": [
            -3.4527411130232264,
            43.45341792055989,
            -3.4527411130232264,
            43.45341792055989
          ]
        }
      ],
      "bbox": [
        -3.4527411130232264,
        43.45341792055989,
        -3.4527411130232264,
        43.45341792055989
      ]
    },
    "39080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.393785498033361,
              43.368197152267385
            ]
          },
          "bbox": [
            -4.393785498033361,
            43.368197152267385,
            -4.393785498033361,
            43.368197152267385
          ]
        }
      ],
      "bbox": [
        -4.393785498033361,
        43.368197152267385,
        -4.393785498033361,
        43.368197152267385
      ]
    },
    "39081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8024014332044214,
              43.2632846890092
            ]
          },
          "bbox": [
            -3.8024014332044214,
            43.2632846890092,
            -3.8024014332044214,
            43.2632846890092
          ]
        }
      ],
      "bbox": [
        -3.8024014332044214,
        43.2632846890092,
        -3.8024014332044214,
        43.2632846890092
      ]
    },
    "39082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.766542795709945,
              43.20209459195523
            ]
          },
          "bbox": [
            -3.766542795709945,
            43.20209459195523,
            -3.766542795709945,
            43.20209459195523
          ]
        }
      ],
      "bbox": [
        -3.766542795709945,
        43.20209459195523,
        -3.766542795709945,
        43.20209459195523
      ]
    },
    "39083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5485964904159966,
              43.19599652427326
            ]
          },
          "bbox": [
            -3.5485964904159966,
            43.19599652427326,
            -3.5485964904159966,
            43.19599652427326
          ]
        }
      ],
      "bbox": [
        -3.5485964904159966,
        43.19599652427326,
        -3.5485964904159966,
        43.19599652427326
      ]
    },
    "39084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5913288662192997,
              43.36350477785775
            ]
          },
          "bbox": [
            -3.5913288662192997,
            43.36350477785775,
            -3.5913288662192997,
            43.36350477785775
          ]
        }
      ],
      "bbox": [
        -3.5913288662192997,
        43.36350477785775,
        -3.5913288662192997,
        43.36350477785775
      ]
    },
    "39085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.056747528635216,
              43.4132639878918
            ]
          },
          "bbox": [
            -4.056747528635216,
            43.4132639878918,
            -4.056747528635216,
            43.4132639878918
          ]
        }
      ],
      "bbox": [
        -4.056747528635216,
        43.4132639878918,
        -4.056747528635216,
        43.4132639878918
      ]
    },
    "39086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2189731131440205,
              43.141247153316876
            ]
          },
          "bbox": [
            -4.2189731131440205,
            43.141247153316876,
            -4.2189731131440205,
            43.141247153316876
          ]
        }
      ],
      "bbox": [
        -4.2189731131440205,
        43.141247153316876,
        -4.2189731131440205,
        43.141247153316876
      ]
    },
    "39087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.041079418485885,
              43.3360132572341
            ]
          },
          "bbox": [
            -4.041079418485885,
            43.3360132572341,
            -4.041079418485885,
            43.3360132572341
          ]
        }
      ],
      "bbox": [
        -4.041079418485885,
        43.3360132572341,
        -4.041079418485885,
        43.3360132572341
      ]
    },
    "39088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.689612529642115,
              43.25315977633357
            ]
          },
          "bbox": [
            -4.689612529642115,
            43.25315977633357,
            -4.689612529642115,
            43.25315977633357
          ]
        }
      ],
      "bbox": [
        -4.689612529642115,
        43.25315977633357,
        -4.689612529642115,
        43.25315977633357
      ]
    },
    "39089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.361655279079345,
              43.15368113299905
            ]
          },
          "bbox": [
            -4.361655279079345,
            43.15368113299905,
            -4.361655279079345,
            43.15368113299905
          ]
        }
      ],
      "bbox": [
        -4.361655279079345,
        43.15368113299905,
        -4.361655279079345,
        43.15368113299905
      ]
    },
    "39090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.253103169691645,
              43.33747844436645
            ]
          },
          "bbox": [
            -4.253103169691645,
            43.33747844436645,
            -4.253103169691645,
            43.33747844436645
          ]
        }
      ],
      "bbox": [
        -4.253103169691645,
        43.33747844436645,
        -4.253103169691645,
        43.33747844436645
      ]
    },
    "39091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.343515327869816,
              43.318320027579674
            ]
          },
          "bbox": [
            -4.343515327869816,
            43.318320027579674,
            -4.343515327869816,
            43.318320027579674
          ]
        }
      ],
      "bbox": [
        -4.343515327869816,
        43.318320027579674,
        -4.343515327869816,
        43.318320027579674
      ]
    },
    "39092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.184857472891701,
              42.906058194341256
            ]
          },
          "bbox": [
            -4.184857472891701,
            42.906058194341256,
            -4.184857472891701,
            42.906058194341256
          ]
        }
      ],
      "bbox": [
        -4.184857472891701,
        42.906058194341256,
        -4.184857472891701,
        42.906058194341256
      ]
    },
    "39093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.064557760915326,
              42.89535375296188
            ]
          },
          "bbox": [
            -4.064557760915326,
            42.89535375296188,
            -4.064557760915326,
            42.89535375296188
          ]
        }
      ],
      "bbox": [
        -4.064557760915326,
        42.89535375296188,
        -4.064557760915326,
        42.89535375296188
      ]
    },
    "39094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9680985137177425,
              42.8387924105251
            ]
          },
          "bbox": [
            -3.9680985137177425,
            42.8387924105251,
            -3.9680985137177425,
            42.8387924105251
          ]
        }
      ],
      "bbox": [
        -3.9680985137177425,
        42.8387924105251,
        -3.9680985137177425,
        42.8387924105251
      ]
    },
    "39095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.477679574977423,
              43.36364295812286
            ]
          },
          "bbox": [
            -4.477679574977423,
            43.36364295812286,
            -4.477679574977423,
            43.36364295812286
          ]
        }
      ],
      "bbox": [
        -4.477679574977423,
        43.36364295812286,
        -4.477679574977423,
        43.36364295812286
      ]
    },
    "39096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6668411860716414,
              43.07439192028174
            ]
          },
          "bbox": [
            -4.6668411860716414,
            43.07439192028174,
            -4.6668411860716414,
            43.07439192028174
          ]
        }
      ],
      "bbox": [
        -4.6668411860716414,
        43.07439192028174,
        -4.6668411860716414,
        43.07439192028174
      ]
    },
    "39097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.764981983367588,
              43.14895943935831
            ]
          },
          "bbox": [
            -3.764981983367588,
            43.14895943935831,
            -3.764981983367588,
            43.14895943935831
          ]
        }
      ],
      "bbox": [
        -3.764981983367588,
        43.14895943935831,
        -3.764981983367588,
        43.14895943935831
      ]
    },
    "39098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.833763098814337,
              43.2222915864926
            ]
          },
          "bbox": [
            -3.833763098814337,
            43.2222915864926,
            -3.833763098814337,
            43.2222915864926
          ]
        }
      ],
      "bbox": [
        -3.833763098814337,
        43.2222915864926,
        -3.833763098814337,
        43.2222915864926
      ]
    },
    "39099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8580892657786414,
              43.36280257394839
            ]
          },
          "bbox": [
            -3.8580892657786414,
            43.36280257394839,
            -3.8580892657786414,
            43.36280257394839
          ]
        }
      ],
      "bbox": [
        -3.8580892657786414,
        43.36280257394839,
        -3.8580892657786414,
        43.36280257394839
      ]
    },
    "39100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.884114303008505,
              43.26357165469467
            ]
          },
          "bbox": [
            -3.884114303008505,
            43.26357165469467,
            -3.884114303008505,
            43.26357165469467
          ]
        }
      ],
      "bbox": [
        -3.884114303008505,
        43.26357165469467,
        -3.884114303008505,
        43.26357165469467
      ]
    },
    "39101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2783182046163986,
              43.236838665574794
            ]
          },
          "bbox": [
            -3.2783182046163986,
            43.236838665574794,
            -3.2783182046163986,
            43.236838665574794
          ]
        }
      ],
      "bbox": [
        -3.2783182046163986,
        43.236838665574794,
        -3.2783182046163986,
        43.236838665574794
      ]
    },
    "39102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.505454373695906,
              43.346269710455246
            ]
          },
          "bbox": [
            -3.505454373695906,
            43.346269710455246,
            -3.505454373695906,
            43.346269710455246
          ]
        }
      ],
      "bbox": [
        -3.505454373695906,
        43.346269710455246,
        -3.505454373695906,
        43.346269710455246
      ]
    },
    "40001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.276944165592686,
              40.914157755238385
            ]
          },
          "bbox": [
            -4.276944165592686,
            40.914157755238385,
            -4.276944165592686,
            40.914157755238385
          ]
        }
      ],
      "bbox": [
        -4.276944165592686,
        40.914157755238385,
        -4.276944165592686,
        40.914157755238385
      ]
    },
    "40002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.051958174652355,
              41.05950005715766
            ]
          },
          "bbox": [
            -4.051958174652355,
            41.05950005715766,
            -4.051958174652355,
            41.05950005715766
          ]
        }
      ],
      "bbox": [
        -4.051958174652355,
        41.05950005715766,
        -4.051958174652355,
        41.05950005715766
      ]
    },
    "40003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.112546850114441,
              41.36985273157104
            ]
          },
          "bbox": [
            -4.112546850114441,
            41.36985273157104,
            -4.112546850114441,
            41.36985273157104
          ]
        }
      ],
      "bbox": [
        -4.112546850114441,
        41.36985273157104,
        -4.112546850114441,
        41.36985273157104
      ]
    },
    "40004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.100506534804124,
              41.23573773517785
            ]
          },
          "bbox": [
            -4.100506534804124,
            41.23573773517785,
            -4.100506534804124,
            41.23573773517785
          ]
        }
      ],
      "bbox": [
        -4.100506534804124,
        41.23573773517785,
        -4.100506534804124,
        41.23573773517785
      ]
    },
    "40005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4957028216471815,
              41.45330169590397
            ]
          },
          "bbox": [
            -3.4957028216471815,
            41.45330169590397,
            -3.4957028216471815,
            41.45330169590397
          ]
        }
      ],
      "bbox": [
        -3.4957028216471815,
        41.45330169590397,
        -3.4957028216471815,
        41.45330169590397
      ]
    },
    "40006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7940303496185006,
              41.25023084823819
            ]
          },
          "bbox": [
            -3.7940303496185006,
            41.25023084823819,
            -3.7940303496185006,
            41.25023084823819
          ]
        }
      ],
      "bbox": [
        -3.7940303496185006,
        41.25023084823819,
        -3.7940303496185006,
        41.25023084823819
      ]
    },
    "40007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8016292481321137,
              41.03926460378544
            ]
          },
          "bbox": [
            -3.8016292481321137,
            41.03926460378544,
            -3.8016292481321137,
            41.03926460378544
          ]
        }
      ],
      "bbox": [
        -3.8016292481321137,
        41.03926460378544,
        -3.8016292481321137,
        41.03926460378544
      ]
    },
    "40008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4614758630494498,
              41.46920032416156
            ]
          },
          "bbox": [
            -3.4614758630494498,
            41.46920032416156,
            -3.4614758630494498,
            41.46920032416156
          ]
        }
      ],
      "bbox": [
        -3.4614758630494498,
        41.46920032416156,
        -3.4614758630494498,
        41.46920032416156
      ]
    },
    "40009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.784967432525052,
              41.462382880681844
            ]
          },
          "bbox": [
            -3.784967432525052,
            41.462382880681844,
            -3.784967432525052,
            41.462382880681844
          ]
        }
      ],
      "bbox": [
        -3.784967432525052,
        41.462382880681844,
        -3.784967432525052,
        41.462382880681844
      ]
    },
    "40010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.536192471885287,
              41.08483191262414
            ]
          },
          "bbox": [
            -4.536192471885287,
            41.08483191262414,
            -4.536192471885287,
            41.08483191262414
          ]
        }
      ],
      "bbox": [
        -4.536192471885287,
        41.08483191262414,
        -4.536192471885287,
        41.08483191262414
      ]
    },
    "40012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.167091528197022,
              41.186401757565164
            ]
          },
          "bbox": [
            -4.167091528197022,
            41.186401757565164,
            -4.167091528197022,
            41.186401757565164
          ]
        }
      ],
      "bbox": [
        -4.167091528197022,
        41.186401757565164,
        -4.167091528197022,
        41.186401757565164
      ]
    },
    "40013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.061309008082398,
              41.474240099272784
            ]
          },
          "bbox": [
            -4.061309008082398,
            41.474240099272784,
            -4.061309008082398,
            41.474240099272784
          ]
        }
      ],
      "bbox": [
        -4.061309008082398,
        41.474240099272784,
        -4.061309008082398,
        41.474240099272784
      ]
    },
    "40014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7833349085497496,
              41.50896058569794
            ]
          },
          "bbox": [
            -3.7833349085497496,
            41.50896058569794,
            -3.7833349085497496,
            41.50896058569794
          ]
        }
      ],
      "bbox": [
        -3.7833349085497496,
        41.50896058569794,
        -3.7833349085497496,
        41.50896058569794
      ]
    },
    "40015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.540199140425438,
              41.05219612091908
            ]
          },
          "bbox": [
            -4.540199140425438,
            41.05219612091908,
            -4.540199140425438,
            41.05219612091908
          ]
        }
      ],
      "bbox": [
        -4.540199140425438,
        41.05219612091908,
        -4.540199140425438,
        41.05219612091908
      ]
    },
    "40016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6960873080813785,
              41.33233539302735
            ]
          },
          "bbox": [
            -3.6960873080813785,
            41.33233539302735,
            -3.6960873080813785,
            41.33233539302735
          ]
        }
      ],
      "bbox": [
        -3.6960873080813785,
        41.33233539302735,
        -3.6960873080813785,
        41.33233539302735
      ]
    },
    "40017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.310537624963345,
              40.99220038745159
            ]
          },
          "bbox": [
            -4.310537624963345,
            40.99220038745159,
            -4.310537624963345,
            40.99220038745159
          ]
        }
      ],
      "bbox": [
        -4.310537624963345,
        40.99220038745159,
        -4.310537624963345,
        40.99220038745159
      ]
    },
    "40018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.289999320259282,
              41.03737590767044
            ]
          },
          "bbox": [
            -4.289999320259282,
            41.03737590767044,
            -4.289999320259282,
            41.03737590767044
          ]
        }
      ],
      "bbox": [
        -4.289999320259282,
        41.03737590767044,
        -4.289999320259282,
        41.03737590767044
      ]
    },
    "40019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8610425017366277,
              41.14882688112501
            ]
          },
          "bbox": [
            -3.8610425017366277,
            41.14882688112501,
            -3.8610425017366277,
            41.14882688112501
          ]
        }
      ],
      "bbox": [
        -3.8610425017366277,
        41.14882688112501,
        -3.8610425017366277,
        41.14882688112501
      ]
    },
    "40020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.722333032227626,
              41.11457093055271
            ]
          },
          "bbox": [
            -3.722333032227626,
            41.11457093055271,
            -3.722333032227626,
            41.11457093055271
          ]
        }
      ],
      "bbox": [
        -3.722333032227626,
        41.11457093055271,
        -3.722333032227626,
        41.11457093055271
      ]
    },
    "40021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.885951565345108,
              41.1695688414951
            ]
          },
          "bbox": [
            -3.885951565345108,
            41.1695688414951,
            -3.885951565345108,
            41.1695688414951
          ]
        }
      ],
      "bbox": [
        -3.885951565345108,
        41.1695688414951,
        -3.885951565345108,
        41.1695688414951
      ]
    },
    "40022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.278829669800018,
              41.05919093117404
            ]
          },
          "bbox": [
            -4.278829669800018,
            41.05919093117404,
            -4.278829669800018,
            41.05919093117404
          ]
        }
      ],
      "bbox": [
        -4.278829669800018,
        41.05919093117404,
        -4.278829669800018,
        41.05919093117404
      ]
    },
    "40024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3397257626790195,
              41.36783946697537
            ]
          },
          "bbox": [
            -3.3397257626790195,
            41.36783946697537,
            -3.3397257626790195,
            41.36783946697537
          ]
        }
      ],
      "bbox": [
        -3.3397257626790195,
        41.36783946697537,
        -3.3397257626790195,
        41.36783946697537
      ]
    },
    "40025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6627148801558675,
              41.314765441758
            ]
          },
          "bbox": [
            -3.6627148801558675,
            41.314765441758,
            -3.6627148801558675,
            41.314765441758
          ]
        }
      ],
      "bbox": [
        -3.6627148801558675,
        41.314765441758,
        -3.6627148801558675,
        41.314765441758
      ]
    },
    "40026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.984903015213286,
              41.00347938971739
            ]
          },
          "bbox": [
            -3.984903015213286,
            41.00347938971739,
            -3.984903015213286,
            41.00347938971739
          ]
        }
      ],
      "bbox": [
        -3.984903015213286,
        41.00347938971739,
        -3.984903015213286,
        41.00347938971739
      ]
    },
    "40028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.432512145205027,
              40.89536792268513
            ]
          },
          "bbox": [
            -4.432512145205027,
            40.89536792268513,
            -4.432512145205027,
            40.89536792268513
          ]
        }
      ],
      "bbox": [
        -4.432512145205027,
        40.89536792268513,
        -4.432512145205027,
        40.89536792268513
      ]
    },
    "40029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.565777922899209,
              41.39869155384909
            ]
          },
          "bbox": [
            -3.565777922899209,
            41.39869155384909,
            -3.565777922899209,
            41.39869155384909
          ]
        }
      ],
      "bbox": [
        -3.565777922899209,
        41.39869155384909,
        -3.565777922899209,
        41.39869155384909
      ]
    },
    "40030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.340938119488279,
              41.14148825224683
            ]
          },
          "bbox": [
            -4.340938119488279,
            41.14148825224683,
            -4.340938119488279,
            41.14148825224683
          ]
        }
      ],
      "bbox": [
        -4.340938119488279,
        41.14148825224683,
        -4.340938119488279,
        41.14148825224683
      ]
    },
    "40031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.115074767719426,
              40.999215319653594
            ]
          },
          "bbox": [
            -4.115074767719426,
            40.999215319653594,
            -4.115074767719426,
            40.999215319653594
          ]
        }
      ],
      "bbox": [
        -4.115074767719426,
        40.999215319653594,
        -4.115074767719426,
        40.999215319653594
      ]
    },
    "40032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6064132178064154,
              41.32734403143157
            ]
          },
          "bbox": [
            -3.6064132178064154,
            41.32734403143157,
            -3.6064132178064154,
            41.32734403143157
          ]
        }
      ],
      "bbox": [
        -3.6064132178064154,
        41.32734403143157,
        -3.6064132178064154,
        41.32734403143157
      ]
    },
    "40033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.049066808686212,
              41.03674378415222
            ]
          },
          "bbox": [
            -4.049066808686212,
            41.03674378415222,
            -4.049066808686212,
            41.03674378415222
          ]
        }
      ],
      "bbox": [
        -4.049066808686212,
        41.03674378415222,
        -4.049066808686212,
        41.03674378415222
      ]
    },
    "40034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.964078163718373,
              41.12261047571684
            ]
          },
          "bbox": [
            -3.964078163718373,
            41.12261047571684,
            -3.964078163718373,
            41.12261047571684
          ]
        }
      ],
      "bbox": [
        -3.964078163718373,
        41.12261047571684,
        -3.964078163718373,
        41.12261047571684
      ]
    },
    "40035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.105394903857425,
              41.05079409667497
            ]
          },
          "bbox": [
            -4.105394903857425,
            41.05079409667497,
            -4.105394903857425,
            41.05079409667497
          ]
        }
      ],
      "bbox": [
        -4.105394903857425,
        41.05079409667497,
        -4.105394903857425,
        41.05079409667497
      ]
    },
    "40036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9576924708647203,
              41.22813965587381
            ]
          },
          "bbox": [
            -3.9576924708647203,
            41.22813965587381,
            -3.9576924708647203,
            41.22813965587381
          ]
        }
      ],
      "bbox": [
        -3.9576924708647203,
        41.22813965587381,
        -3.9576924708647203,
        41.22813965587381
      ]
    },
    "40037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.012050310331651,
              41.451506540125436
            ]
          },
          "bbox": [
            -4.012050310331651,
            41.451506540125436,
            -4.012050310331651,
            41.451506540125436
          ]
        }
      ],
      "bbox": [
        -4.012050310331651,
        41.451506540125436,
        -4.012050310331651,
        41.451506540125436
      ]
    },
    "40039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5561232635876663,
              41.458019916038516
            ]
          },
          "bbox": [
            -3.5561232635876663,
            41.458019916038516,
            -3.5561232635876663,
            41.458019916038516
          ]
        }
      ],
      "bbox": [
        -3.5561232635876663,
        41.458019916038516,
        -3.5561232635876663,
        41.458019916038516
      ]
    },
    "40040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9342744867151187,
              41.26052220674765
            ]
          },
          "bbox": [
            -3.9342744867151187,
            41.26052220674765,
            -3.9342744867151187,
            41.26052220674765
          ]
        }
      ],
      "bbox": [
        -3.9342744867151187,
        41.26052220674765,
        -3.9342744867151187,
        41.26052220674765
      ]
    },
    "40041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.169742747582428,
              41.068174092323396
            ]
          },
          "bbox": [
            -4.169742747582428,
            41.068174092323396,
            -4.169742747582428,
            41.068174092323396
          ]
        }
      ],
      "bbox": [
        -4.169742747582428,
        41.068174092323396,
        -4.169742747582428,
        41.068174092323396
      ]
    },
    "40043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.279016168881902,
              41.14453848330622
            ]
          },
          "bbox": [
            -4.279016168881902,
            41.14453848330622,
            -4.279016168881902,
            41.14453848330622
          ]
        }
      ],
      "bbox": [
        -4.279016168881902,
        41.14453848330622,
        -4.279016168881902,
        41.14453848330622
      ]
    },
    "40044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8882363010975833,
              41.35268322326286
            ]
          },
          "bbox": [
            -3.8882363010975833,
            41.35268322326286,
            -3.8882363010975833,
            41.35268322326286
          ]
        }
      ],
      "bbox": [
        -3.8882363010975833,
        41.35268322326286,
        -3.8882363010975833,
        41.35268322326286
      ]
    },
    "40045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.65365621453125,
              41.162082425393905
            ]
          },
          "bbox": [
            -3.65365621453125,
            41.162082425393905,
            -3.65365621453125,
            41.162082425393905
          ]
        }
      ],
      "bbox": [
        -3.65365621453125,
        41.162082425393905,
        -3.65365621453125,
        41.162082425393905
      ]
    },
    "40046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.59401027611635,
              41.278386675382094
            ]
          },
          "bbox": [
            -3.59401027611635,
            41.278386675382094,
            -3.59401027611635,
            41.278386675382094
          ]
        }
      ],
      "bbox": [
        -3.59401027611635,
        41.278386675382094,
        -3.59401027611635,
        41.278386675382094
      ]
    },
    "40047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.869692186781651,
              41.42379379956457
            ]
          },
          "bbox": [
            -3.869692186781651,
            41.42379379956457,
            -3.869692186781651,
            41.42379379956457
          ]
        }
      ],
      "bbox": [
        -3.869692186781651,
        41.42379379956457,
        -3.869692186781651,
        41.42379379956457
      ]
    },
    "40048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.846056175963107,
              41.4000174885935
            ]
          },
          "bbox": [
            -3.846056175963107,
            41.4000174885935,
            -3.846056175963107,
            41.4000174885935
          ]
        }
      ],
      "bbox": [
        -3.846056175963107,
        41.4000174885935,
        -3.846056175963107,
        41.4000174885935
      ]
    },
    "40049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7356454988788217,
              41.20667428518602
            ]
          },
          "bbox": [
            -3.7356454988788217,
            41.20667428518602,
            -3.7356454988788217,
            41.20667428518602
          ]
        }
      ],
      "bbox": [
        -3.7356454988788217,
        41.20667428518602,
        -3.7356454988788217,
        41.20667428518602
      ]
    },
    "40051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7971938947361967,
              41.40265734020731
            ]
          },
          "bbox": [
            -3.7971938947361967,
            41.40265734020731,
            -3.7971938947361967,
            41.40265734020731
          ]
        }
      ],
      "bbox": [
        -3.7971938947361967,
        41.40265734020731,
        -3.7971938947361967,
        41.40265734020731
      ]
    },
    "40052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6222348491304133,
              41.42766059376879
            ]
          },
          "bbox": [
            -3.6222348491304133,
            41.42766059376879,
            -3.6222348491304133,
            41.42766059376879
          ]
        }
      ],
      "bbox": [
        -3.6222348491304133,
        41.42766059376879,
        -3.6222348491304133,
        41.42766059376879
      ]
    },
    "40053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5855927102862886,
              41.21941595087399
            ]
          },
          "bbox": [
            -3.5855927102862886,
            41.21941595087399,
            -3.5855927102862886,
            41.21941595087399
          ]
        }
      ],
      "bbox": [
        -3.5855927102862886,
        41.21941595087399,
        -3.5855927102862886,
        41.21941595087399
      ]
    },
    "40054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5252843025865914,
              41.21796280077152
            ]
          },
          "bbox": [
            -3.5252843025865914,
            41.21796280077152,
            -3.5252843025865914,
            41.21796280077152
          ]
        }
      ],
      "bbox": [
        -3.5252843025865914,
        41.21796280077152,
        -3.5252843025865914,
        41.21796280077152
      ]
    },
    "40055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.581188068669143,
              41.43729542059782
            ]
          },
          "bbox": [
            -3.581188068669143,
            41.43729542059782,
            -3.581188068669143,
            41.43729542059782
          ]
        }
      ],
      "bbox": [
        -3.581188068669143,
        41.43729542059782,
        -3.581188068669143,
        41.43729542059782
      ]
    },
    "40056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.920993997844864,
              41.38391565058106
            ]
          },
          "bbox": [
            -3.920993997844864,
            41.38391565058106,
            -3.920993997844864,
            41.38391565058106
          ]
        }
      ],
      "bbox": [
        -3.920993997844864,
        41.38391565058106,
        -3.920993997844864,
        41.38391565058106
      ]
    },
    "40057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.530138931569539,
              41.223612426704605
            ]
          },
          "bbox": [
            -4.530138931569539,
            41.223612426704605,
            -4.530138931569539,
            41.223612426704605
          ]
        }
      ],
      "bbox": [
        -4.530138931569539,
        41.223612426704605,
        -4.530138931569539,
        41.223612426704605
      ]
    },
    "40058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.60200436340082,
              41.05297377039632
            ]
          },
          "bbox": [
            -4.60200436340082,
            41.05297377039632,
            -4.60200436340082,
            41.05297377039632
          ]
        }
      ],
      "bbox": [
        -4.60200436340082,
        41.05297377039632,
        -4.60200436340082,
        41.05297377039632
      ]
    },
    "40059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9035739373607643,
              41.022093079884804
            ]
          },
          "bbox": [
            -3.9035739373607643,
            41.022093079884804,
            -3.9035739373607643,
            41.022093079884804
          ]
        }
      ],
      "bbox": [
        -3.9035739373607643,
        41.022093079884804,
        -3.9035739373607643,
        41.022093079884804
      ]
    },
    "40060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7492621844908496,
              41.24577264827506
            ]
          },
          "bbox": [
            -3.7492621844908496,
            41.24577264827506,
            -3.7492621844908496,
            41.24577264827506
          ]
        }
      ],
      "bbox": [
        -3.7492621844908496,
        41.24577264827506,
        -3.7492621844908496,
        41.24577264827506
      ]
    },
    "40061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4552408256390117,
              41.39413972182074
            ]
          },
          "bbox": [
            -3.4552408256390117,
            41.39413972182074,
            -3.4552408256390117,
            41.39413972182074
          ]
        }
      ],
      "bbox": [
        -3.4552408256390117,
        41.39413972182074,
        -3.4552408256390117,
        41.39413972182074
      ]
    },
    "40062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.917122950209486,
              41.11085028867935
            ]
          },
          "bbox": [
            -3.917122950209486,
            41.11085028867935,
            -3.917122950209486,
            41.11085028867935
          ]
        }
      ],
      "bbox": [
        -3.917122950209486,
        41.11085028867935,
        -3.917122950209486,
        41.11085028867935
      ]
    },
    "40063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.287060550829596,
              41.362136232278175
            ]
          },
          "bbox": [
            -4.287060550829596,
            41.362136232278175,
            -4.287060550829596,
            41.362136232278175
          ]
        }
      ],
      "bbox": [
        -4.287060550829596,
        41.362136232278175,
        -4.287060550829596,
        41.362136232278175
      ]
    },
    "40065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4318296925293374,
              41.34469971557694
            ]
          },
          "bbox": [
            -4.4318296925293374,
            41.34469971557694,
            -4.4318296925293374,
            41.34469971557694
          ]
        }
      ],
      "bbox": [
        -4.4318296925293374,
        41.34469971557694,
        -4.4318296925293374,
        41.34469971557694
      ]
    },
    "40068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.399953775545738,
              41.121495117479235
            ]
          },
          "bbox": [
            -4.399953775545738,
            41.121495117479235,
            -4.399953775545738,
            41.121495117479235
          ]
        }
      ],
      "bbox": [
        -4.399953775545738,
        41.121495117479235,
        -4.399953775545738,
        41.121495117479235
      ]
    },
    "40069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.695866332017928,
              41.113814688469965
            ]
          },
          "bbox": [
            -4.695866332017928,
            41.113814688469965,
            -4.695866332017928,
            41.113814688469965
          ]
        }
      ],
      "bbox": [
        -4.695866332017928,
        41.113814688469965,
        -4.695866332017928,
        41.113814688469965
      ]
    },
    "40070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.644839282803619,
              41.225456410495966
            ]
          },
          "bbox": [
            -3.644839282803619,
            41.225456410495966,
            -3.644839282803619,
            41.225456410495966
          ]
        }
      ],
      "bbox": [
        -3.644839282803619,
        41.225456410495966,
        -3.644839282803619,
        41.225456410495966
      ]
    },
    "40071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6792503323673507,
              41.398525419163356
            ]
          },
          "bbox": [
            -3.6792503323673507,
            41.398525419163356,
            -3.6792503323673507,
            41.398525419163356
          ]
        }
      ],
      "bbox": [
        -3.6792503323673507,
        41.398525419163356,
        -3.6792503323673507,
        41.398525419163356
      ]
    },
    "40072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.151768031676724,
              41.02560855828181
            ]
          },
          "bbox": [
            -4.151768031676724,
            41.02560855828181,
            -4.151768031676724,
            41.02560855828181
          ]
        }
      ],
      "bbox": [
        -4.151768031676724,
        41.02560855828181,
        -4.151768031676724,
        41.02560855828181
      ]
    },
    "40073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.11501585707325,
              41.16262095590729
            ]
          },
          "bbox": [
            -4.11501585707325,
            41.16262095590729,
            -4.11501585707325,
            41.16262095590729
          ]
        }
      ],
      "bbox": [
        -4.11501585707325,
        41.16262095590729,
        -4.11501585707325,
        41.16262095590729
      ]
    },
    "40074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.183067948154085,
              41.10585525151618
            ]
          },
          "bbox": [
            -4.183067948154085,
            41.10585525151618,
            -4.183067948154085,
            41.10585525151618
          ]
        }
      ],
      "bbox": [
        -4.183067948154085,
        41.10585525151618,
        -4.183067948154085,
        41.10585525151618
      ]
    },
    "40075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1114092301032645,
              41.10282292015152
            ]
          },
          "bbox": [
            -4.1114092301032645,
            41.10282292015152,
            -4.1114092301032645,
            41.10282292015152
          ]
        }
      ],
      "bbox": [
        -4.1114092301032645,
        41.10282292015152,
        -4.1114092301032645,
        41.10282292015152
      ]
    },
    "40076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.222183924868768,
              40.73088898472206
            ]
          },
          "bbox": [
            -4.222183924868768,
            40.73088898472206,
            -4.222183924868768,
            40.73088898472206
          ]
        }
      ],
      "bbox": [
        -4.222183924868768,
        40.73088898472206,
        -4.222183924868768,
        40.73088898472206
      ]
    },
    "40077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.075066346954809,
              41.00200129701387
            ]
          },
          "bbox": [
            -4.075066346954809,
            41.00200129701387,
            -4.075066346954809,
            41.00200129701387
          ]
        }
      ],
      "bbox": [
        -4.075066346954809,
        41.00200129701387,
        -4.075066346954809,
        41.00200129701387
      ]
    },
    "40078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.456041240329434,
              41.30766778234051
            ]
          },
          "bbox": [
            -4.456041240329434,
            41.30766778234051,
            -4.456041240329434,
            41.30766778234051
          ]
        }
      ],
      "bbox": [
        -4.456041240329434,
        41.30766778234051,
        -4.456041240329434,
        41.30766778234051
      ]
    },
    "40079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4974602906975862,
              41.36582474591775
            ]
          },
          "bbox": [
            -3.4974602906975862,
            41.36582474591775,
            -3.4974602906975862,
            41.36582474591775
          ]
        }
      ],
      "bbox": [
        -3.4974602906975862,
        41.36582474591775,
        -3.4974602906975862,
        41.36582474591775
      ]
    },
    "40080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.645614312270733,
              41.399160688439025
            ]
          },
          "bbox": [
            -3.645614312270733,
            41.399160688439025,
            -3.645614312270733,
            41.399160688439025
          ]
        }
      ],
      "bbox": [
        -3.645614312270733,
        41.399160688439025,
        -3.645614312270733,
        41.399160688439025
      ]
    },
    "40081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.194652547158775,
              41.3723777297572
            ]
          },
          "bbox": [
            -4.194652547158775,
            41.3723777297572,
            -4.194652547158775,
            41.3723777297572
          ]
        }
      ],
      "bbox": [
        -4.194652547158775,
        41.3723777297572,
        -4.194652547158775,
        41.3723777297572
      ]
    },
    "40082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.623837156520605,
              41.21293125860379
            ]
          },
          "bbox": [
            -4.623837156520605,
            41.21293125860379,
            -4.623837156520605,
            41.21293125860379
          ]
        }
      ],
      "bbox": [
        -4.623837156520605,
        41.21293125860379,
        -4.623837156520605,
        41.21293125860379
      ]
    },
    "40083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.99303057670303,
              41.39400396185563
            ]
          },
          "bbox": [
            -3.99303057670303,
            41.39400396185563,
            -3.99303057670303,
            41.39400396185563
          ]
        }
      ],
      "bbox": [
        -3.99303057670303,
        41.39400396185563,
        -3.99303057670303,
        41.39400396185563
      ]
    },
    "40084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.49244299551475,
              41.286250383800876
            ]
          },
          "bbox": [
            -4.49244299551475,
            41.286250383800876,
            -4.49244299551475,
            41.286250383800876
          ]
        }
      ],
      "bbox": [
        -4.49244299551475,
        41.286250383800876,
        -4.49244299551475,
        41.286250383800876
      ]
    },
    "40086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.183716811702181,
              41.22915864739191
            ]
          },
          "bbox": [
            -4.183716811702181,
            41.22915864739191,
            -4.183716811702181,
            41.22915864739191
          ]
        }
      ],
      "bbox": [
        -4.183716811702181,
        41.22915864739191,
        -4.183716811702181,
        41.22915864739191
      ]
    },
    "40087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.037294183356322,
              41.40283355193171
            ]
          },
          "bbox": [
            -4.037294183356322,
            41.40283355193171,
            -4.037294183356322,
            41.40283355193171
          ]
        }
      ],
      "bbox": [
        -4.037294183356322,
        41.40283355193171,
        -4.037294183356322,
        41.40283355193171
      ]
    },
    "40088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9479618421534086,
              41.30916331431037
            ]
          },
          "bbox": [
            -3.9479618421534086,
            41.30916331431037,
            -3.9479618421534086,
            41.30916331431037
          ]
        }
      ],
      "bbox": [
        -3.9479618421534086,
        41.30916331431037,
        -3.9479618421534086,
        41.30916331431037
      ]
    },
    "40089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.060898057679678,
              41.430634169496514
            ]
          },
          "bbox": [
            -4.060898057679678,
            41.430634169496514,
            -4.060898057679678,
            41.430634169496514
          ]
        }
      ],
      "bbox": [
        -4.060898057679678,
        41.430634169496514,
        -4.060898057679678,
        41.430634169496514
      ]
    },
    "40091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9081357395635146,
              41.44366429774368
            ]
          },
          "bbox": [
            -3.9081357395635146,
            41.44366429774368,
            -3.9081357395635146,
            41.44366429774368
          ]
        }
      ],
      "bbox": [
        -3.9081357395635146,
        41.44366429774368,
        -3.9081357395635146,
        41.44366429774368
      ]
    },
    "40092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9990170741830693,
              41.375380537545844
            ]
          },
          "bbox": [
            -3.9990170741830693,
            41.375380537545844,
            -3.9990170741830693,
            41.375380537545844
          ]
        }
      ],
      "bbox": [
        -3.9990170741830693,
        41.375380537545844,
        -3.9990170741830693,
        41.375380537545844
      ]
    },
    "40093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7714601652198025,
              41.061981810354936
            ]
          },
          "bbox": [
            -3.7714601652198025,
            41.061981810354936,
            -3.7714601652198025,
            41.061981810354936
          ]
        }
      ],
      "bbox": [
        -3.7714601652198025,
        41.061981810354936,
        -3.7714601652198025,
        41.061981810354936
      ]
    },
    "40094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2672450705374425,
              40.99143200295483
            ]
          },
          "bbox": [
            -4.2672450705374425,
            40.99143200295483,
            -4.2672450705374425,
            40.99143200295483
          ]
        }
      ],
      "bbox": [
        -4.2672450705374425,
        40.99143200295483,
        -4.2672450705374425,
        40.99143200295483
      ]
    },
    "40095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.299222380868544,
              41.28719121756814
            ]
          },
          "bbox": [
            -4.299222380868544,
            41.28719121756814,
            -4.299222380868544,
            41.28719121756814
          ]
        }
      ],
      "bbox": [
        -4.299222380868544,
        41.28719121756814,
        -4.299222380868544,
        41.28719121756814
      ]
    },
    "40097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.613278797210195,
              41.3721363773483
            ]
          },
          "bbox": [
            -3.613278797210195,
            41.3721363773483,
            -3.613278797210195,
            41.3721363773483
          ]
        }
      ],
      "bbox": [
        -3.613278797210195,
        41.3721363773483,
        -3.613278797210195,
        41.3721363773483
      ]
    },
    "40099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7039780854044553,
              41.50084881740893
            ]
          },
          "bbox": [
            -3.7039780854044553,
            41.50084881740893,
            -3.7039780854044553,
            41.50084881740893
          ]
        }
      ],
      "bbox": [
        -3.7039780854044553,
        41.50084881740893,
        -3.7039780854044553,
        41.50084881740893
      ]
    },
    "40100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.117767314644851,
              41.33827559365384
            ]
          },
          "bbox": [
            -4.117767314644851,
            41.33827559365384,
            -4.117767314644851,
            41.33827559365384
          ]
        }
      ],
      "bbox": [
        -4.117767314644851,
        41.33827559365384,
        -4.117767314644851,
        41.33827559365384
      ]
    },
    "40101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.199013950802195,
              40.98458804140982
            ]
          },
          "bbox": [
            -4.199013950802195,
            40.98458804140982,
            -4.199013950802195,
            40.98458804140982
          ]
        }
      ],
      "bbox": [
        -4.199013950802195,
        40.98458804140982,
        -4.199013950802195,
        40.98458804140982
      ]
    },
    "40103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.220868247552434,
              41.00770331164805
            ]
          },
          "bbox": [
            -4.220868247552434,
            41.00770331164805,
            -4.220868247552434,
            41.00770331164805
          ]
        }
      ],
      "bbox": [
        -4.220868247552434,
        41.00770331164805,
        -4.220868247552434,
        41.00770331164805
      ]
    },
    "40104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.376407700952843,
              40.800782376312135
            ]
          },
          "bbox": [
            -4.376407700952843,
            40.800782376312135,
            -4.376407700952843,
            40.800782376312135
          ]
        }
      ],
      "bbox": [
        -4.376407700952843,
        40.800782376312135,
        -4.376407700952843,
        40.800782376312135
      ]
    },
    "40105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.317674501820896,
              40.932621557176745
            ]
          },
          "bbox": [
            -4.317674501820896,
            40.932621557176745,
            -4.317674501820896,
            40.932621557176745
          ]
        }
      ],
      "bbox": [
        -4.317674501820896,
        40.932621557176745,
        -4.317674501820896,
        40.932621557176745
      ]
    },
    "40106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5160639812535655,
              41.034872870126904
            ]
          },
          "bbox": [
            -4.5160639812535655,
            41.034872870126904,
            -4.5160639812535655,
            41.034872870126904
          ]
        }
      ],
      "bbox": [
        -4.5160639812535655,
        41.034872870126904,
        -4.5160639812535655,
        41.034872870126904
      ]
    },
    "40107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.520267031185245,
              40.85200191835948
            ]
          },
          "bbox": [
            -4.520267031185245,
            40.85200191835948,
            -4.520267031185245,
            40.85200191835948
          ]
        }
      ],
      "bbox": [
        -4.520267031185245,
        40.85200191835948,
        -4.520267031185245,
        40.85200191835948
      ]
    },
    "40108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.024969070989912,
              41.49311218981068
            ]
          },
          "bbox": [
            -4.024969070989912,
            41.49311218981068,
            -4.024969070989912,
            41.49311218981068
          ]
        }
      ],
      "bbox": [
        -4.024969070989912,
        41.49311218981068,
        -4.024969070989912,
        41.49311218981068
      ]
    },
    "40109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4135439409396087,
              41.45042681289089
            ]
          },
          "bbox": [
            -3.4135439409396087,
            41.45042681289089,
            -3.4135439409396087,
            41.45042681289089
          ]
        }
      ],
      "bbox": [
        -3.4135439409396087,
        41.45042681289089,
        -3.4135439409396087,
        41.45042681289089
      ]
    },
    "40110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.090382718668317,
              41.28797014333549
            ]
          },
          "bbox": [
            -4.090382718668317,
            41.28797014333549,
            -4.090382718668317,
            41.28797014333549
          ]
        }
      ],
      "bbox": [
        -4.090382718668317,
        41.28797014333549,
        -4.090382718668317,
        41.28797014333549
      ]
    },
    "40111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.324950465020058,
              40.87650157832018
            ]
          },
          "bbox": [
            -4.324950465020058,
            40.87650157832018,
            -4.324950465020058,
            40.87650157832018
          ]
        }
      ],
      "bbox": [
        -4.324950465020058,
        40.87650157832018,
        -4.324950465020058,
        40.87650157832018
      ]
    },
    "40112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.091719042679453,
              40.969579038465504
            ]
          },
          "bbox": [
            -4.091719042679453,
            40.969579038465504,
            -4.091719042679453,
            40.969579038465504
          ]
        }
      ],
      "bbox": [
        -4.091719042679453,
        40.969579038465504,
        -4.091719042679453,
        40.969579038465504
      ]
    },
    "40113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.14305174042594,
              40.83608769915737
            ]
          },
          "bbox": [
            -4.14305174042594,
            40.83608769915737,
            -4.14305174042594,
            40.83608769915737
          ]
        }
      ],
      "bbox": [
        -4.14305174042594,
        40.83608769915737,
        -4.14305174042594,
        40.83608769915737
      ]
    },
    "40115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5181988811051452,
              41.51184120774716
            ]
          },
          "bbox": [
            -3.5181988811051452,
            41.51184120774716,
            -3.5181988811051452,
            41.51184120774716
          ]
        }
      ],
      "bbox": [
        -3.5181988811051452,
        41.51184120774716,
        -3.5181988811051452,
        41.51184120774716
      ]
    },
    "40118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3596371401686875,
              40.97889247214744
            ]
          },
          "bbox": [
            -4.3596371401686875,
            40.97889247214744,
            -4.3596371401686875,
            40.97889247214744
          ]
        }
      ],
      "bbox": [
        -4.3596371401686875,
        40.97889247214744,
        -4.3596371401686875,
        40.97889247214744
      ]
    },
    "40119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.274896399988434,
              40.953424816157884
            ]
          },
          "bbox": [
            -4.274896399988434,
            40.953424816157884,
            -4.274896399988434,
            40.953424816157884
          ]
        }
      ],
      "bbox": [
        -4.274896399988434,
        40.953424816157884,
        -4.274896399988434,
        40.953424816157884
      ]
    },
    "40120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.685264389733656,
              41.066432191178635
            ]
          },
          "bbox": [
            -4.685264389733656,
            41.066432191178635,
            -4.685264389733656,
            41.066432191178635
          ]
        }
      ],
      "bbox": [
        -4.685264389733656,
        41.066432191178635,
        -4.685264389733656,
        41.066432191178635
      ]
    },
    "40121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.560708805802501,
              40.988393804422856
            ]
          },
          "bbox": [
            -4.560708805802501,
            40.988393804422856,
            -4.560708805802501,
            40.988393804422856
          ]
        }
      ],
      "bbox": [
        -4.560708805802501,
        40.988393804422856,
        -4.560708805802501,
        40.988393804422856
      ]
    },
    "40122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.387532043115998,
              40.89559490524695
            ]
          },
          "bbox": [
            -4.387532043115998,
            40.89559490524695,
            -4.387532043115998,
            40.89559490524695
          ]
        }
      ],
      "bbox": [
        -4.387532043115998,
        40.89559490524695,
        -4.387532043115998,
        40.89559490524695
      ]
    },
    "40123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7498617538815058,
              41.08717270645501
            ]
          },
          "bbox": [
            -3.7498617538815058,
            41.08717270645501,
            -3.7498617538815058,
            41.08717270645501
          ]
        }
      ],
      "bbox": [
        -3.7498617538815058,
        41.08717270645501,
        -3.7498617538815058,
        41.08717270645501
      ]
    },
    "40124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.481740499585109,
              41.392653310353985
            ]
          },
          "bbox": [
            -4.481740499585109,
            41.392653310353985,
            -4.481740499585109,
            41.392653310353985
          ]
        }
      ],
      "bbox": [
        -4.481740499585109,
        41.392653310353985,
        -4.481740499585109,
        41.392653310353985
      ]
    },
    "40125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.803598462589645,
              41.18733450191433
            ]
          },
          "bbox": [
            -3.803598462589645,
            41.18733450191433,
            -3.803598462589645,
            41.18733450191433
          ]
        }
      ],
      "bbox": [
        -3.803598462589645,
        41.18733450191433,
        -3.803598462589645,
        41.18733450191433
      ]
    },
    "40126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.475344125253206,
              41.04591914684596
            ]
          },
          "bbox": [
            -4.475344125253206,
            41.04591914684596,
            -4.475344125253206,
            41.04591914684596
          ]
        }
      ],
      "bbox": [
        -4.475344125253206,
        41.04591914684596,
        -4.475344125253206,
        41.04591914684596
      ]
    },
    "40127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1020458397920425,
              41.458129012686115
            ]
          },
          "bbox": [
            -4.1020458397920425,
            41.458129012686115,
            -4.1020458397920425,
            41.458129012686115
          ]
        }
      ],
      "bbox": [
        -4.1020458397920425,
        41.458129012686115,
        -4.1020458397920425,
        41.458129012686115
      ]
    },
    "40128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.379947805139204,
              41.138806151936905
            ]
          },
          "bbox": [
            -4.379947805139204,
            41.138806151936905,
            -4.379947805139204,
            41.138806151936905
          ]
        }
      ],
      "bbox": [
        -4.379947805139204,
        41.138806151936905,
        -4.379947805139204,
        41.138806151936905
      ]
    },
    "40129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.673875278188646,
              41.15402859502098
            ]
          },
          "bbox": [
            -4.673875278188646,
            41.15402859502098,
            -4.673875278188646,
            41.15402859502098
          ]
        }
      ],
      "bbox": [
        -4.673875278188646,
        41.15402859502098,
        -4.673875278188646,
        41.15402859502098
      ]
    },
    "40130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6227259575076673,
              41.55429843844033
            ]
          },
          "bbox": [
            -3.6227259575076673,
            41.55429843844033,
            -3.6227259575076673,
            41.55429843844033
          ]
        }
      ],
      "bbox": [
        -3.6227259575076673,
        41.55429843844033,
        -3.6227259575076673,
        41.55429843844033
      ]
    },
    "40131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.370338269372746,
              40.842880216286076
            ]
          },
          "bbox": [
            -4.370338269372746,
            40.842880216286076,
            -4.370338269372746,
            40.842880216286076
          ]
        }
      ],
      "bbox": [
        -4.370338269372746,
        40.842880216286076,
        -4.370338269372746,
        40.842880216286076
      ]
    },
    "40132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6224555789598396,
              41.47140251422843
            ]
          },
          "bbox": [
            -3.6224555789598396,
            41.47140251422843,
            -3.6224555789598396,
            41.47140251422843
          ]
        }
      ],
      "bbox": [
        -3.6224555789598396,
        41.47140251422843,
        -3.6224555789598396,
        41.47140251422843
      ]
    },
    "40134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.188807130602527,
              41.14439888398237
            ]
          },
          "bbox": [
            -4.188807130602527,
            41.14439888398237,
            -4.188807130602527,
            41.14439888398237
          ]
        }
      ],
      "bbox": [
        -4.188807130602527,
        41.14439888398237,
        -4.188807130602527,
        41.14439888398237
      ]
    },
    "40135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.49409467658835,
              40.882066377269965
            ]
          },
          "bbox": [
            -4.49409467658835,
            40.882066377269965,
            -4.49409467658835,
            40.882066377269965
          ]
        }
      ],
      "bbox": [
        -4.49409467658835,
        40.882066377269965,
        -4.49409467658835,
        40.882066377269965
      ]
    },
    "40136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9509490235562645,
              41.17303557664591
            ]
          },
          "bbox": [
            -3.9509490235562645,
            41.17303557664591,
            -3.9509490235562645,
            41.17303557664591
          ]
        }
      ],
      "bbox": [
        -3.9509490235562645,
        41.17303557664591,
        -3.9509490235562645,
        41.17303557664591
      ]
    },
    "40138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.486371113423092,
              41.14077673077364
            ]
          },
          "bbox": [
            -4.486371113423092,
            41.14077673077364,
            -4.486371113423092,
            41.14077673077364
          ]
        }
      ],
      "bbox": [
        -4.486371113423092,
        41.14077673077364,
        -4.486371113423092,
        41.14077673077364
      ]
    },
    "40139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.833650427945183,
              41.03475109053994
            ]
          },
          "bbox": [
            -3.833650427945183,
            41.03475109053994,
            -3.833650427945183,
            41.03475109053994
          ]
        }
      ],
      "bbox": [
        -3.833650427945183,
        41.03475109053994,
        -3.833650427945183,
        41.03475109053994
      ]
    },
    "40140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9535525996140857,
              41.33829256586517
            ]
          },
          "bbox": [
            -3.9535525996140857,
            41.33829256586517,
            -3.9535525996140857,
            41.33829256586517
          ]
        }
      ],
      "bbox": [
        -3.9535525996140857,
        41.33829256586517,
        -3.9535525996140857,
        41.33829256586517
      ]
    },
    "40141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.254788503217471,
              41.21527287392907
            ]
          },
          "bbox": [
            -4.254788503217471,
            41.21527287392907,
            -4.254788503217471,
            41.21527287392907
          ]
        }
      ],
      "bbox": [
        -4.254788503217471,
        41.21527287392907,
        -4.254788503217471,
        41.21527287392907
      ]
    },
    "40142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6995373715260693,
              41.37272763402475
            ]
          },
          "bbox": [
            -3.6995373715260693,
            41.37272763402475,
            -3.6995373715260693,
            41.37272763402475
          ]
        }
      ],
      "bbox": [
        -3.6995373715260693,
        41.37272763402475,
        -3.6995373715260693,
        41.37272763402475
      ]
    },
    "40143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.73158985791514,
              41.4073595136196
            ]
          },
          "bbox": [
            -3.73158985791514,
            41.4073595136196,
            -3.73158985791514,
            41.4073595136196
          ]
        }
      ],
      "bbox": [
        -3.73158985791514,
        41.4073595136196,
        -3.73158985791514,
        41.4073595136196
      ]
    },
    "40144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7550411498551517,
              41.42037699392668
            ]
          },
          "bbox": [
            -3.7550411498551517,
            41.42037699392668,
            -3.7550411498551517,
            41.42037699392668
          ]
        }
      ],
      "bbox": [
        -3.7550411498551517,
        41.42037699392668,
        -3.7550411498551517,
        41.42037699392668
      ]
    },
    "40145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.422166203924211,
              41.20514581877775
            ]
          },
          "bbox": [
            -4.422166203924211,
            41.20514581877775,
            -4.422166203924211,
            41.20514581877775
          ]
        }
      ],
      "bbox": [
        -4.422166203924211,
        41.20514581877775,
        -4.422166203924211,
        41.20514581877775
      ]
    },
    "40146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.350438759947573,
              40.72257403873545
            ]
          },
          "bbox": [
            -4.350438759947573,
            40.72257403873545,
            -4.350438759947573,
            40.72257403873545
          ]
        }
      ],
      "bbox": [
        -4.350438759947573,
        40.72257403873545,
        -4.350438759947573,
        40.72257403873545
      ]
    },
    "40148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.447748275044811,
              41.09085931612854
            ]
          },
          "bbox": [
            -4.447748275044811,
            41.09085931612854,
            -4.447748275044811,
            41.09085931612854
          ]
        }
      ],
      "bbox": [
        -4.447748275044811,
        41.09085931612854,
        -4.447748275044811,
        41.09085931612854
      ]
    },
    "40149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.160767519087209,
              41.437350723836175
            ]
          },
          "bbox": [
            -4.160767519087209,
            41.437350723836175,
            -4.160767519087209,
            41.437350723836175
          ]
        }
      ],
      "bbox": [
        -4.160767519087209,
        41.437350723836175,
        -4.160767519087209,
        41.437350723836175
      ]
    },
    "40150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7705561785492843,
              41.15837423555013
            ]
          },
          "bbox": [
            -3.7705561785492843,
            41.15837423555013,
            -3.7705561785492843,
            41.15837423555013
          ]
        }
      ],
      "bbox": [
        -3.7705561785492843,
        41.15837423555013,
        -3.7705561785492843,
        41.15837423555013
      ]
    },
    "40151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3925665872498145,
              41.08995096915034
            ]
          },
          "bbox": [
            -4.3925665872498145,
            41.08995096915034,
            -4.3925665872498145,
            41.08995096915034
          ]
        }
      ],
      "bbox": [
        -4.3925665872498145,
        41.08995096915034,
        -4.3925665872498145,
        41.08995096915034
      ]
    },
    "40152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2078613838490195,
              40.81708807639442
            ]
          },
          "bbox": [
            -4.2078613838490195,
            40.81708807639442,
            -4.2078613838490195,
            40.81708807639442
          ]
        }
      ],
      "bbox": [
        -4.2078613838490195,
        40.81708807639442,
        -4.2078613838490195,
        40.81708807639442
      ]
    },
    "40154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5956057715774765,
              41.39202879065487
            ]
          },
          "bbox": [
            -3.5956057715774765,
            41.39202879065487,
            -3.5956057715774765,
            41.39202879065487
          ]
        }
      ],
      "bbox": [
        -3.5956057715774765,
        41.39202879065487,
        -3.5956057715774765,
        41.39202879065487
      ]
    },
    "40155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.013219847299919,
              40.924197621132166
            ]
          },
          "bbox": [
            -4.013219847299919,
            40.924197621132166,
            -4.013219847299919,
            40.924197621132166
          ]
        }
      ],
      "bbox": [
        -4.013219847299919,
        40.924197621132166,
        -4.013219847299919,
        40.924197621132166
      ]
    },
    "40156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8034214894814995,
              41.12757580015924
            ]
          },
          "bbox": [
            -3.8034214894814995,
            41.12757580015924,
            -3.8034214894814995,
            41.12757580015924
          ]
        }
      ],
      "bbox": [
        -3.8034214894814995,
        41.12757580015924,
        -3.8034214894814995,
        41.12757580015924
      ]
    },
    "40157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9576742378429732,
              41.05802864329289
            ]
          },
          "bbox": [
            -3.9576742378429732,
            41.05802864329289,
            -3.9576742378429732,
            41.05802864329289
          ]
        }
      ],
      "bbox": [
        -3.9576742378429732,
        41.05802864329289,
        -3.9576742378429732,
        41.05802864329289
      ]
    },
    "40158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.14340793598582,
              41.3915116396739
            ]
          },
          "bbox": [
            -4.14340793598582,
            41.3915116396739,
            -4.14340793598582,
            41.3915116396739
          ]
        }
      ],
      "bbox": [
        -4.14340793598582,
        41.3915116396739,
        -4.14340793598582,
        41.3915116396739
      ]
    },
    "40159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.290478126346618,
              41.25283192007376
            ]
          },
          "bbox": [
            -4.290478126346618,
            41.25283192007376,
            -4.290478126346618,
            41.25283192007376
          ]
        }
      ],
      "bbox": [
        -4.290478126346618,
        41.25283192007376,
        -4.290478126346618,
        41.25283192007376
      ]
    },
    "40160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.258046279716463,
              41.18874578743151
            ]
          },
          "bbox": [
            -4.258046279716463,
            41.18874578743151,
            -4.258046279716463,
            41.18874578743151
          ]
        }
      ],
      "bbox": [
        -4.258046279716463,
        41.18874578743151,
        -4.258046279716463,
        41.18874578743151
      ]
    },
    "40161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6945871501798107,
              41.45198541200772
            ]
          },
          "bbox": [
            -3.6945871501798107,
            41.45198541200772,
            -3.6945871501798107,
            41.45198541200772
          ]
        }
      ],
      "bbox": [
        -3.6945871501798107,
        41.45198541200772,
        -3.6945871501798107,
        41.45198541200772
      ]
    },
    "40162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6891704995990757,
              41.144101506252404
            ]
          },
          "bbox": [
            -3.6891704995990757,
            41.144101506252404,
            -3.6891704995990757,
            41.144101506252404
          ]
        }
      ],
      "bbox": [
        -3.6891704995990757,
        41.144101506252404,
        -3.6891704995990757,
        41.144101506252404
      ]
    },
    "40163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.910353517955402,
              41.20191833450311
            ]
          },
          "bbox": [
            -3.910353517955402,
            41.20191833450311,
            -3.910353517955402,
            41.20191833450311
          ]
        }
      ],
      "bbox": [
        -3.910353517955402,
        41.20191833450311,
        -3.910353517955402,
        41.20191833450311
      ]
    },
    "40164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.640623416885797,
              41.092126942570346
            ]
          },
          "bbox": [
            -4.640623416885797,
            41.092126942570346,
            -4.640623416885797,
            41.092126942570346
          ]
        }
      ],
      "bbox": [
        -4.640623416885797,
        41.092126942570346,
        -4.640623416885797,
        41.092126942570346
      ]
    },
    "40165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8589361176905106,
              41.19125801049513
            ]
          },
          "bbox": [
            -3.8589361176905106,
            41.19125801049513,
            -3.8589361176905106,
            41.19125801049513
          ]
        }
      ],
      "bbox": [
        -3.8589361176905106,
        41.19125801049513,
        -3.8589361176905106,
        41.19125801049513
      ]
    },
    "40166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.486663042597547,
              41.343888415436794
            ]
          },
          "bbox": [
            -4.486663042597547,
            41.343888415436794,
            -4.486663042597547,
            41.343888415436794
          ]
        }
      ],
      "bbox": [
        -4.486663042597547,
        41.343888415436794,
        -4.486663042597547,
        41.343888415436794
      ]
    },
    "40168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4840438128036517,
              41.425348559194646
            ]
          },
          "bbox": [
            -3.4840438128036517,
            41.425348559194646,
            -3.4840438128036517,
            41.425348559194646
          ]
        }
      ],
      "bbox": [
        -3.4840438128036517,
        41.425348559194646,
        -3.4840438128036517,
        41.425348559194646
      ]
    },
    "40170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3960593948581805,
              41.29905036809309
            ]
          },
          "bbox": [
            -3.3960593948581805,
            41.29905036809309,
            -3.3960593948581805,
            41.29905036809309
          ]
        }
      ],
      "bbox": [
        -3.3960593948581805,
        41.29905036809309,
        -3.3960593948581805,
        41.29905036809309
      ]
    },
    "40171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4197668386511197,
              41.35111860547655
            ]
          },
          "bbox": [
            -3.4197668386511197,
            41.35111860547655,
            -3.4197668386511197,
            41.35111860547655
          ]
        }
      ],
      "bbox": [
        -3.4197668386511197,
        41.35111860547655,
        -3.4197668386511197,
        41.35111860547655
      ]
    },
    "40172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.431352476489099,
              41.239689473447136
            ]
          },
          "bbox": [
            -3.431352476489099,
            41.239689473447136,
            -3.431352476489099,
            41.239689473447136
          ]
        }
      ],
      "bbox": [
        -3.431352476489099,
        41.239689473447136,
        -3.431352476489099,
        41.239689473447136
      ]
    },
    "40173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.179837292736621,
              41.03338099731634
            ]
          },
          "bbox": [
            -4.179837292736621,
            41.03338099731634,
            -4.179837292736621,
            41.03338099731634
          ]
        }
      ],
      "bbox": [
        -4.179837292736621,
        41.03338099731634,
        -4.179837292736621,
        41.03338099731634
      ]
    },
    "40174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9504541582558224,
              41.50577322299566
            ]
          },
          "bbox": [
            -3.9504541582558224,
            41.50577322299566,
            -3.9504541582558224,
            41.50577322299566
          ]
        }
      ],
      "bbox": [
        -3.9504541582558224,
        41.50577322299566,
        -3.9504541582558224,
        41.50577322299566
      ]
    },
    "40176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.422409097024567,
              41.27553206968479
            ]
          },
          "bbox": [
            -4.422409097024567,
            41.27553206968479,
            -4.422409097024567,
            41.27553206968479
          ]
        }
      ],
      "bbox": [
        -4.422409097024567,
        41.27553206968479,
        -4.422409097024567,
        41.27553206968479
      ]
    },
    "40177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.393915513558046,
              41.423914827866476
            ]
          },
          "bbox": [
            -4.393915513558046,
            41.423914827866476,
            -4.393915513558046,
            41.423914827866476
          ]
        }
      ],
      "bbox": [
        -4.393915513558046,
        41.423914827866476,
        -4.393915513558046,
        41.423914827866476
      ]
    },
    "40178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.625893441022299,
              41.11699353477192
            ]
          },
          "bbox": [
            -4.625893441022299,
            41.11699353477192,
            -4.625893441022299,
            41.11699353477192
          ]
        }
      ],
      "bbox": [
        -4.625893441022299,
        41.11699353477192,
        -4.625893441022299,
        41.11699353477192
      ]
    },
    "40179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.294783280278275,
              41.32335168802925
            ]
          },
          "bbox": [
            -4.294783280278275,
            41.32335168802925,
            -4.294783280278275,
            41.32335168802925
          ]
        }
      ],
      "bbox": [
        -4.294783280278275,
        41.32335168802925,
        -4.294783280278275,
        41.32335168802925
      ]
    },
    "40180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.431095901096965,
              40.94117618641212
            ]
          },
          "bbox": [
            -4.431095901096965,
            40.94117618641212,
            -4.431095901096965,
            40.94117618641212
          ]
        }
      ],
      "bbox": [
        -4.431095901096965,
        40.94117618641212,
        -4.431095901096965,
        40.94117618641212
      ]
    },
    "40181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.02380662060707,
              40.85158825908876
            ]
          },
          "bbox": [
            -4.02380662060707,
            40.85158825908876,
            -4.02380662060707,
            40.85158825908876
          ]
        }
      ],
      "bbox": [
        -4.02380662060707,
        40.85158825908876,
        -4.02380662060707,
        40.85158825908876
      ]
    },
    "40182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.343633167564787,
              41.22268272763322
            ]
          },
          "bbox": [
            -4.343633167564787,
            41.22268272763322,
            -4.343633167564787,
            41.22268272763322
          ]
        }
      ],
      "bbox": [
        -4.343633167564787,
        41.22268272763322,
        -4.343633167564787,
        41.22268272763322
      ]
    },
    "40183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9491860831805083,
              41.394109212865985
            ]
          },
          "bbox": [
            -3.9491860831805083,
            41.394109212865985,
            -3.9491860831805083,
            41.394109212865985
          ]
        }
      ],
      "bbox": [
        -3.9491860831805083,
        41.394109212865985,
        -3.9491860831805083,
        41.394109212865985
      ]
    },
    "40184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8185405716372918,
              41.224876372973384
            ]
          },
          "bbox": [
            -3.8185405716372918,
            41.224876372973384,
            -3.8185405716372918,
            41.224876372973384
          ]
        }
      ],
      "bbox": [
        -3.8185405716372918,
        41.224876372973384,
        -3.8185405716372918,
        41.224876372973384
      ]
    },
    "40185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.418742112818663,
              41.01364193347144
            ]
          },
          "bbox": [
            -4.418742112818663,
            41.01364193347144,
            -4.418742112818663,
            41.01364193347144
          ]
        }
      ],
      "bbox": [
        -4.418742112818663,
        41.01364193347144,
        -4.418742112818663,
        41.01364193347144
      ]
    },
    "40186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6861539800730845,
              41.2136131396674
            ]
          },
          "bbox": [
            -3.6861539800730845,
            41.2136131396674,
            -3.6861539800730845,
            41.2136131396674
          ]
        }
      ],
      "bbox": [
        -3.6861539800730845,
        41.2136131396674,
        -3.6861539800730845,
        41.2136131396674
      ]
    },
    "40188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.874206150202425,
              41.09641571069869
            ]
          },
          "bbox": [
            -3.874206150202425,
            41.09641571069869,
            -3.874206150202425,
            41.09641571069869
          ]
        }
      ],
      "bbox": [
        -3.874206150202425,
        41.09641571069869,
        -3.874206150202425,
        41.09641571069869
      ]
    },
    "40189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.574577115739373,
              41.159433996677905
            ]
          },
          "bbox": [
            -4.574577115739373,
            41.159433996677905,
            -4.574577115739373,
            41.159433996677905
          ]
        }
      ],
      "bbox": [
        -4.574577115739373,
        41.159433996677905,
        -4.574577115739373,
        41.159433996677905
      ]
    },
    "40190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9593925342395453,
              41.01083906703331
            ]
          },
          "bbox": [
            -3.9593925342395453,
            41.01083906703331,
            -3.9593925342395453,
            41.01083906703331
          ]
        }
      ],
      "bbox": [
        -3.9593925342395453,
        41.01083906703331,
        -3.9593925342395453,
        41.01083906703331
      ]
    },
    "40191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5894896243064593,
              41.18222612392524
            ]
          },
          "bbox": [
            -3.5894896243064593,
            41.18222612392524,
            -3.5894896243064593,
            41.18222612392524
          ]
        }
      ],
      "bbox": [
        -3.5894896243064593,
        41.18222612392524,
        -3.5894896243064593,
        41.18222612392524
      ]
    },
    "40192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.063049093618967,
              41.193194483121744
            ]
          },
          "bbox": [
            -4.063049093618967,
            41.193194483121744,
            -4.063049093618967,
            41.193194483121744
          ]
        }
      ],
      "bbox": [
        -4.063049093618967,
        41.193194483121744,
        -4.063049093618967,
        41.193194483121744
      ]
    },
    "40193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8744946504666924,
              41.283696540426625
            ]
          },
          "bbox": [
            -3.8744946504666924,
            41.283696540426625,
            -3.8744946504666924,
            41.283696540426625
          ]
        }
      ],
      "bbox": [
        -3.8744946504666924,
        41.283696540426625,
        -3.8744946504666924,
        41.283696540426625
      ]
    },
    "40194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.168844702623935,
              40.902410412892216
            ]
          },
          "bbox": [
            -4.168844702623935,
            40.902410412892216,
            -4.168844702623935,
            40.902410412892216
          ]
        }
      ],
      "bbox": [
        -4.168844702623935,
        40.902410412892216,
        -4.168844702623935,
        40.902410412892216
      ]
    },
    "40195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.771581265487549,
              41.294978585414626
            ]
          },
          "bbox": [
            -3.771581265487549,
            41.294978585414626,
            -3.771581265487549,
            41.294978585414626
          ]
        }
      ],
      "bbox": [
        -3.771581265487549,
        41.294978585414626,
        -3.771581265487549,
        41.294978585414626
      ]
    },
    "40196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.55246565375378,
              41.36181986710522
            ]
          },
          "bbox": [
            -3.55246565375378,
            41.36181986710522,
            -3.55246565375378,
            41.36181986710522
          ]
        }
      ],
      "bbox": [
        -3.55246565375378,
        41.36181986710522,
        -3.55246565375378,
        41.36181986710522
      ]
    },
    "40198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.646303108684249,
              41.26058894920935
            ]
          },
          "bbox": [
            -3.646303108684249,
            41.26058894920935,
            -3.646303108684249,
            41.26058894920935
          ]
        }
      ],
      "bbox": [
        -3.646303108684249,
        41.26058894920935,
        -3.646303108684249,
        41.26058894920935
      ]
    },
    "40199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9309604494773427,
              41.016868624162456
            ]
          },
          "bbox": [
            -3.9309604494773427,
            41.016868624162456,
            -3.9309604494773427,
            41.016868624162456
          ]
        }
      ],
      "bbox": [
        -3.9309604494773427,
        41.016868624162456,
        -3.9309604494773427,
        41.016868624162456
      ]
    },
    "40200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.243034698339986,
              41.097756207047496
            ]
          },
          "bbox": [
            -4.243034698339986,
            41.097756207047496,
            -4.243034698339986,
            41.097756207047496
          ]
        }
      ],
      "bbox": [
        -4.243034698339986,
        41.097756207047496,
        -4.243034698339986,
        41.097756207047496
      ]
    },
    "40201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.627869133038366,
              41.136363230453256
            ]
          },
          "bbox": [
            -4.627869133038366,
            41.136363230453256,
            -4.627869133038366,
            41.136363230453256
          ]
        }
      ],
      "bbox": [
        -4.627869133038366,
        41.136363230453256,
        -4.627869133038366,
        41.136363230453256
      ]
    },
    "40202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8360466428299556,
              41.45934089459744
            ]
          },
          "bbox": [
            -3.8360466428299556,
            41.45934089459744,
            -3.8360466428299556,
            41.45934089459744
          ]
        }
      ],
      "bbox": [
        -3.8360466428299556,
        41.45934089459744,
        -3.8360466428299556,
        41.45934089459744
      ]
    },
    "40203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.988026676230198,
              40.980220794411125
            ]
          },
          "bbox": [
            -3.988026676230198,
            40.980220794411125,
            -3.988026676230198,
            40.980220794411125
          ]
        }
      ],
      "bbox": [
        -3.988026676230198,
        40.980220794411125,
        -3.988026676230198,
        40.980220794411125
      ]
    },
    "40204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.04884891266708,
              41.365597515666714
            ]
          },
          "bbox": [
            -4.04884891266708,
            41.365597515666714,
            -4.04884891266708,
            41.365597515666714
          ]
        }
      ],
      "bbox": [
        -4.04884891266708,
        41.365597515666714,
        -4.04884891266708,
        41.365597515666714
      ]
    },
    "40205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.042741435189738,
              41.10828794587764
            ]
          },
          "bbox": [
            -4.042741435189738,
            41.10828794587764,
            -4.042741435189738,
            41.10828794587764
          ]
        }
      ],
      "bbox": [
        -4.042741435189738,
        41.10828794587764,
        -4.042741435189738,
        41.10828794587764
      ]
    },
    "40206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.868517503727221,
              41.041265637314055
            ]
          },
          "bbox": [
            -3.868517503727221,
            41.041265637314055,
            -3.868517503727221,
            41.041265637314055
          ]
        }
      ],
      "bbox": [
        -3.868517503727221,
        41.041265637314055,
        -3.868517503727221,
        41.041265637314055
      ]
    },
    "40207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.997902033902747,
              40.95043768961336
            ]
          },
          "bbox": [
            -3.997902033902747,
            40.95043768961336,
            -3.997902033902747,
            40.95043768961336
          ]
        }
      ],
      "bbox": [
        -3.997902033902747,
        40.95043768961336,
        -3.997902033902747,
        40.95043768961336
      ]
    },
    "40208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9964995167939734,
              41.132379946982546
            ]
          },
          "bbox": [
            -3.9964995167939734,
            41.132379946982546,
            -3.9964995167939734,
            41.132379946982546
          ]
        }
      ],
      "bbox": [
        -3.9964995167939734,
        41.132379946982546,
        -3.9964995167939734,
        41.132379946982546
      ]
    },
    "40210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7577975882836387,
              41.35768035464779
            ]
          },
          "bbox": [
            -3.7577975882836387,
            41.35768035464779,
            -3.7577975882836387,
            41.35768035464779
          ]
        }
      ],
      "bbox": [
        -3.7577975882836387,
        41.35768035464779,
        -3.7577975882836387,
        41.35768035464779
      ]
    },
    "40211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2721595246538415,
              40.82351415408915
            ]
          },
          "bbox": [
            -4.2721595246538415,
            40.82351415408915,
            -4.2721595246538415,
            40.82351415408915
          ]
        }
      ],
      "bbox": [
        -4.2721595246538415,
        40.82351415408915,
        -4.2721595246538415,
        40.82351415408915
      ]
    },
    "40212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6234060237961065,
              41.52324224667631
            ]
          },
          "bbox": [
            -3.6234060237961065,
            41.52324224667631,
            -3.6234060237961065,
            41.52324224667631
          ]
        }
      ],
      "bbox": [
        -3.6234060237961065,
        41.52324224667631,
        -3.6234060237961065,
        41.52324224667631
      ]
    },
    "40213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9215210624385195,
              41.14959439438006
            ]
          },
          "bbox": [
            -3.9215210624385195,
            41.14959439438006,
            -3.9215210624385195,
            41.14959439438006
          ]
        }
      ],
      "bbox": [
        -3.9215210624385195,
        41.14959439438006,
        -3.9215210624385195,
        41.14959439438006
      ]
    },
    "40214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.162838249874116,
              40.997937879713945
            ]
          },
          "bbox": [
            -4.162838249874116,
            40.997937879713945,
            -4.162838249874116,
            40.997937879713945
          ]
        }
      ],
      "bbox": [
        -4.162838249874116,
        40.997937879713945,
        -4.162838249874116,
        40.997937879713945
      ]
    },
    "40215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.901694177698357,
              41.49046975179119
            ]
          },
          "bbox": [
            -3.901694177698357,
            41.49046975179119,
            -3.901694177698357,
            41.49046975179119
          ]
        }
      ],
      "bbox": [
        -3.901694177698357,
        41.49046975179119,
        -3.901694177698357,
        41.49046975179119
      ]
    },
    "40216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2184610569407885,
              40.95039086943304
            ]
          },
          "bbox": [
            -4.2184610569407885,
            40.95039086943304,
            -4.2184610569407885,
            40.95039086943304
          ]
        }
      ],
      "bbox": [
        -4.2184610569407885,
        40.95039086943304,
        -4.2184610569407885,
        40.95039086943304
      ]
    },
    "40218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8324849033945907,
              41.36596212797252
            ]
          },
          "bbox": [
            -3.8324849033945907,
            41.36596212797252,
            -3.8324849033945907,
            41.36596212797252
          ]
        }
      ],
      "bbox": [
        -3.8324849033945907,
        41.36596212797252,
        -3.8324849033945907,
        41.36596212797252
      ]
    },
    "40219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.422614083133237,
              41.391810556715114
            ]
          },
          "bbox": [
            -4.422614083133237,
            41.391810556715114,
            -4.422614083133237,
            41.391810556715114
          ]
        }
      ],
      "bbox": [
        -4.422614083133237,
        41.391810556715114,
        -4.422614083133237,
        41.391810556715114
      ]
    },
    "40220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.819659572502021,
              41.1753920257802
            ]
          },
          "bbox": [
            -3.819659572502021,
            41.1753920257802,
            -3.819659572502021,
            41.1753920257802
          ]
        }
      ],
      "bbox": [
        -3.819659572502021,
        41.1753920257802,
        -3.819659572502021,
        41.1753920257802
      ]
    },
    "40221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.767752093115646,
              41.19350096801286
            ]
          },
          "bbox": [
            -3.767752093115646,
            41.19350096801286,
            -3.767752093115646,
            41.19350096801286
          ]
        }
      ],
      "bbox": [
        -3.767752093115646,
        41.19350096801286,
        -3.767752093115646,
        41.19350096801286
      ]
    },
    "40222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9985933963021765,
              41.20117943939187
            ]
          },
          "bbox": [
            -3.9985933963021765,
            41.20117943939187,
            -3.9985933963021765,
            41.20117943939187
          ]
        }
      ],
      "bbox": [
        -3.9985933963021765,
        41.20117943939187,
        -3.9985933963021765,
        41.20117943939187
      ]
    },
    "40223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.27210563579362,
              40.78906633513837
            ]
          },
          "bbox": [
            -4.27210563579362,
            40.78906633513837,
            -4.27210563579362,
            40.78906633513837
          ]
        }
      ],
      "bbox": [
        -4.27210563579362,
        40.78906633513837,
        -4.27210563579362,
        40.78906633513837
      ]
    },
    "40224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6899801973882167,
              41.183270724887436
            ]
          },
          "bbox": [
            -3.6899801973882167,
            41.183270724887436,
            -3.6899801973882167,
            41.183270724887436
          ]
        }
      ],
      "bbox": [
        -3.6899801973882167,
        41.183270724887436,
        -3.6899801973882167,
        41.183270724887436
      ]
    },
    "40225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.419069874243818,
              40.75785879501191
            ]
          },
          "bbox": [
            -4.419069874243818,
            40.75785879501191,
            -4.419069874243818,
            40.75785879501191
          ]
        }
      ],
      "bbox": [
        -4.419069874243818,
        40.75785879501191,
        -4.419069874243818,
        40.75785879501191
      ]
    },
    "40228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.550093900820485,
              41.29688247857124
            ]
          },
          "bbox": [
            -4.550093900820485,
            41.29688247857124,
            -4.550093900820485,
            41.29688247857124
          ]
        }
      ],
      "bbox": [
        -4.550093900820485,
        41.29688247857124,
        -4.550093900820485,
        41.29688247857124
      ]
    },
    "40229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6655039283060424,
              41.50990709046398
            ]
          },
          "bbox": [
            -3.6655039283060424,
            41.50990709046398,
            -3.6655039283060424,
            41.50990709046398
          ]
        }
      ],
      "bbox": [
        -3.6655039283060424,
        41.50990709046398,
        -3.6655039283060424,
        41.50990709046398
      ]
    },
    "40230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.579173472795173,
              41.251975545874586
            ]
          },
          "bbox": [
            -4.579173472795173,
            41.251975545874586,
            -4.579173472795173,
            41.251975545874586
          ]
        }
      ],
      "bbox": [
        -4.579173472795173,
        41.251975545874586,
        -4.579173472795173,
        41.251975545874586
      ]
    },
    "40231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.238596961942128,
              41.0689455195387
            ]
          },
          "bbox": [
            -4.238596961942128,
            41.0689455195387,
            -4.238596961942128,
            41.0689455195387
          ]
        }
      ],
      "bbox": [
        -4.238596961942128,
        41.0689455195387,
        -4.238596961942128,
        41.0689455195387
      ]
    },
    "40233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.334086557728604,
              40.80355169667361
            ]
          },
          "bbox": [
            -4.334086557728604,
            40.80355169667361,
            -4.334086557728604,
            40.80355169667361
          ]
        }
      ],
      "bbox": [
        -4.334086557728604,
        40.80355169667361,
        -4.334086557728604,
        40.80355169667361
      ]
    },
    "40234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.185965120532238,
              41.26317626372192
            ]
          },
          "bbox": [
            -4.185965120532238,
            41.26317626372192,
            -4.185965120532238,
            41.26317626372192
          ]
        }
      ],
      "bbox": [
        -4.185965120532238,
        41.26317626372192,
        -4.185965120532238,
        41.26317626372192
      ]
    },
    "40901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1620708848542645,
              40.82092848745021
            ]
          },
          "bbox": [
            -4.1620708848542645,
            40.82092848745021,
            -4.1620708848542645,
            40.82092848745021
          ]
        }
      ],
      "bbox": [
        -4.1620708848542645,
        40.82092848745021,
        -4.1620708848542645,
        40.82092848745021
      ]
    },
    "40902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.087936334394206,
              41.39370631810458
            ]
          },
          "bbox": [
            -4.087936334394206,
            41.39370631810458,
            -4.087936334394206,
            41.39370631810458
          ]
        }
      ],
      "bbox": [
        -4.087936334394206,
        41.39370631810458,
        -4.087936334394206,
        41.39370631810458
      ]
    },
    "40903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.350941954401605,
              40.940765572762395
            ]
          },
          "bbox": [
            -4.350941954401605,
            40.940765572762395,
            -4.350941954401605,
            40.940765572762395
          ]
        }
      ],
      "bbox": [
        -4.350941954401605,
        40.940765572762395,
        -4.350941954401605,
        40.940765572762395
      ]
    },
    "40904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.118353264872381,
              40.84749470389444
            ]
          },
          "bbox": [
            -4.118353264872381,
            40.84749470389444,
            -4.118353264872381,
            40.84749470389444
          ]
        }
      ],
      "bbox": [
        -4.118353264872381,
        40.84749470389444,
        -4.118353264872381,
        40.84749470389444
      ]
    },
    "40905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.950016810557744,
              41.54934480628906
            ]
          },
          "bbox": [
            -3.950016810557744,
            41.54934480628906,
            -3.950016810557744,
            41.54934480628906
          ]
        }
      ],
      "bbox": [
        -3.950016810557744,
        41.54934480628906,
        -3.950016810557744,
        41.54934480628906
      ]
    },
    "40906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.076726284673859,
              40.953138837307996
            ]
          },
          "bbox": [
            -4.076726284673859,
            40.953138837307996,
            -4.076726284673859,
            40.953138837307996
          ]
        }
      ],
      "bbox": [
        -4.076726284673859,
        40.953138837307996,
        -4.076726284673859,
        40.953138837307996
      ]
    },
    "41001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.978911874495467,
              37.249859288965695
            ]
          },
          "bbox": [
            -4.978911874495467,
            37.249859288965695,
            -4.978911874495467,
            37.249859288965695
          ]
        }
      ],
      "bbox": [
        -4.978911874495467,
        37.249859288965695,
        -4.978911874495467,
        37.249859288965695
      ]
    },
    "41002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.624144496867127,
              38.04390280175209
            ]
          },
          "bbox": [
            -5.624144496867127,
            38.04390280175209,
            -5.624144496867127,
            38.04390280175209
          ]
        }
      ],
      "bbox": [
        -5.624144496867127,
        38.04390280175209,
        -5.624144496867127,
        38.04390280175209
      ]
    },
    "41003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.178218640105807,
              37.44035570430754
            ]
          },
          "bbox": [
            -6.178218640105807,
            37.44035570430754,
            -6.178218640105807,
            37.44035570430754
          ]
        }
      ],
      "bbox": [
        -6.178218640105807,
        37.44035570430754,
        -6.178218640105807,
        37.44035570430754
      ]
    },
    "41004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.806170642314695,
              37.290353517184286
            ]
          },
          "bbox": [
            -5.806170642314695,
            37.290353517184286,
            -5.806170642314695,
            37.290353517184286
          ]
        }
      ],
      "bbox": [
        -5.806170642314695,
        37.290353517184286,
        -5.806170642314695,
        37.290353517184286
      ]
    },
    "41005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.971954097443441,
              37.54544319309738
            ]
          },
          "bbox": [
            -5.971954097443441,
            37.54544319309738,
            -5.971954097443441,
            37.54544319309738
          ]
        }
      ],
      "bbox": [
        -5.971954097443441,
        37.54544319309738,
        -5.971954097443441,
        37.54544319309738
      ]
    },
    "41006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.646240265088079,
              37.636603743561096
            ]
          },
          "bbox": [
            -5.646240265088079,
            37.636603743561096,
            -5.646240265088079,
            37.636603743561096
          ]
        }
      ],
      "bbox": [
        -5.646240265088079,
        37.636603743561096,
        -5.646240265088079,
        37.636603743561096
      ]
    },
    "41007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.015810198572961,
              37.47249135980093
            ]
          },
          "bbox": [
            -6.015810198572961,
            37.47249135980093,
            -6.015810198572961,
            37.47249135980093
          ]
        }
      ],
      "bbox": [
        -6.015810198572961,
        37.47249135980093,
        -6.015810198572961,
        37.47249135980093
      ]
    },
    "41008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.155927801790996,
              37.01779456652781
            ]
          },
          "bbox": [
            -5.155927801790996,
            37.01779456652781,
            -5.155927801790996,
            37.01779456652781
          ]
        }
      ],
      "bbox": [
        -5.155927801790996,
        37.01779456652781,
        -5.155927801790996,
        37.01779456652781
      ]
    },
    "41009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.039668619160215,
              37.848542571846835
            ]
          },
          "bbox": [
            -6.039668619160215,
            37.848542571846835,
            -6.039668619160215,
            37.848542571846835
          ]
        }
      ],
      "bbox": [
        -6.039668619160215,
        37.848542571846835,
        -6.039668619160215,
        37.848542571846835
      ]
    },
    "41010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.108382967591416,
              37.30632931641622
            ]
          },
          "bbox": [
            -6.108382967591416,
            37.30632931641622,
            -6.108382967591416,
            37.30632931641622
          ]
        }
      ],
      "bbox": [
        -6.108382967591416,
        37.30632931641622,
        -6.108382967591416,
        37.30632931641622
      ]
    },
    "41011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5731770682859105,
              37.200654713192165
            ]
          },
          "bbox": [
            -5.5731770682859105,
            37.200654713192165,
            -5.5731770682859105,
            37.200654713192165
          ]
        }
      ],
      "bbox": [
        -5.5731770682859105,
        37.200654713192165,
        -5.5731770682859105,
        37.200654713192165
      ]
    },
    "41012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.283500200808012,
              37.107524680903964
            ]
          },
          "bbox": [
            -6.283500200808012,
            37.107524680903964,
            -6.283500200808012,
            37.107524680903964
          ]
        }
      ],
      "bbox": [
        -6.283500200808012,
        37.107524680903964,
        -6.283500200808012,
        37.107524680903964
      ]
    },
    "41013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.309160381208514,
              37.54384748660588
            ]
          },
          "bbox": [
            -6.309160381208514,
            37.54384748660588,
            -6.309160381208514,
            37.54384748660588
          ]
        }
      ],
      "bbox": [
        -6.309160381208514,
        37.54384748660588,
        -6.309160381208514,
        37.54384748660588
      ]
    },
    "41014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6949799978182,
              37.298742736198086
            ]
          },
          "bbox": [
            -4.6949799978182,
            37.298742736198086,
            -4.6949799978182,
            37.298742736198086
          ]
        }
      ],
      "bbox": [
        -4.6949799978182,
        37.298742736198086,
        -4.6949799978182,
        37.298742736198086
      ]
    },
    "41015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2130580686963235,
              37.33750113384258
            ]
          },
          "bbox": [
            -6.2130580686963235,
            37.33750113384258,
            -6.2130580686963235,
            37.33750113384258
          ]
        }
      ],
      "bbox": [
        -6.2130580686963235,
        37.33750113384258,
        -6.2130580686963235,
        37.33750113384258
      ]
    },
    "41016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.15305730640445,
              37.31501532658357
            ]
          },
          "bbox": [
            -6.15305730640445,
            37.31501532658357,
            -6.15305730640445,
            37.31501532658357
          ]
        }
      ],
      "bbox": [
        -6.15305730640445,
        37.31501532658357,
        -6.15305730640445,
        37.31501532658357
      ]
    },
    "41017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.08348349008663,
              37.363793751916454
            ]
          },
          "bbox": [
            -6.08348349008663,
            37.363793751916454,
            -6.08348349008663,
            37.363793751916454
          ]
        }
      ],
      "bbox": [
        -6.08348349008663,
        37.363793751916454,
        -6.08348349008663,
        37.363793751916454
      ]
    },
    "41018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.877221411897905,
              37.55332106474985
            ]
          },
          "bbox": [
            -5.877221411897905,
            37.55332106474985,
            -5.877221411897905,
            37.55332106474985
          ]
        }
      ],
      "bbox": [
        -5.877221411897905,
        37.55332106474985,
        -5.877221411897905,
        37.55332106474985
      ]
    },
    "41019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.958955720057575,
              37.600273738919846
            ]
          },
          "bbox": [
            -5.958955720057575,
            37.600273738919846,
            -5.958955720057575,
            37.600273738919846
          ]
        }
      ],
      "bbox": [
        -5.958955720057575,
        37.600273738919846,
        -5.958955720057575,
        37.600273738919846
      ]
    },
    "41020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.928710745021915,
              36.98853021097527
            ]
          },
          "bbox": [
            -5.928710745021915,
            36.98853021097527,
            -5.928710745021915,
            36.98853021097527
          ]
        }
      ],
      "bbox": [
        -5.928710745021915,
        36.98853021097527,
        -5.928710745021915,
        36.98853021097527
      ]
    },
    "41021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.034616076608204,
              37.400497310633185
            ]
          },
          "bbox": [
            -6.034616076608204,
            37.400497310633185,
            -6.034616076608204,
            37.400497310633185
          ]
        }
      ],
      "bbox": [
        -6.034616076608204,
        37.400497310633185,
        -6.034616076608204,
        37.400497310633185
      ]
    },
    "41022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4077217607867185,
              37.55593719641158
            ]
          },
          "bbox": [
            -5.4077217607867185,
            37.55593719641158,
            -5.4077217607867185,
            37.55593719641158
          ]
        }
      ],
      "bbox": [
        -5.4077217607867185,
        37.55593719641158,
        -5.4077217607867185,
        37.55593719641158
      ]
    },
    "41023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.817650915100753,
              37.62354031504495
            ]
          },
          "bbox": [
            -5.817650915100753,
            37.62354031504495,
            -5.817650915100753,
            37.62354031504495
          ]
        }
      ],
      "bbox": [
        -5.817650915100753,
        37.62354031504495,
        -5.817650915100753,
        37.62354031504495
      ]
    },
    "41024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.631202227715647,
              37.456680423649296
            ]
          },
          "bbox": [
            -5.631202227715647,
            37.456680423649296,
            -5.631202227715647,
            37.456680423649296
          ]
        }
      ],
      "bbox": [
        -5.631202227715647,
        37.456680423649296,
        -5.631202227715647,
        37.456680423649296
      ]
    },
    "41025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.327904205063726,
              37.36413039934796
            ]
          },
          "bbox": [
            -6.327904205063726,
            37.36413039934796,
            -6.327904205063726,
            37.36413039934796
          ]
        }
      ],
      "bbox": [
        -6.327904205063726,
        37.36413039934796,
        -6.327904205063726,
        37.36413039934796
      ]
    },
    "41026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7555781088587175,
              37.295759496770394
            ]
          },
          "bbox": [
            -4.7555781088587175,
            37.295759496770394,
            -4.7555781088587175,
            37.295759496770394
          ]
        }
      ],
      "bbox": [
        -4.7555781088587175,
        37.295759496770394,
        -4.7555781088587175,
        37.295759496770394
      ]
    },
    "41027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.983449362563161,
              37.71507637022916
            ]
          },
          "bbox": [
            -5.983449362563161,
            37.71507637022916,
            -5.983449362563161,
            37.71507637022916
          ]
        }
      ],
      "bbox": [
        -5.983449362563161,
        37.71507637022916,
        -5.983449362563161,
        37.71507637022916
      ]
    },
    "41028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0532333118201045,
              37.40735198288571
            ]
          },
          "bbox": [
            -6.0532333118201045,
            37.40735198288571,
            -6.0532333118201045,
            37.40735198288571
          ]
        }
      ],
      "bbox": [
        -6.0532333118201045,
        37.40735198288571,
        -6.0532333118201045,
        37.40735198288571
      ]
    },
    "41029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.05594656340108,
              37.38589011362832
            ]
          },
          "bbox": [
            -6.05594656340108,
            37.38589011362832,
            -6.05594656340108,
            37.38589011362832
          ]
        }
      ],
      "bbox": [
        -6.05594656340108,
        37.38589011362832,
        -6.05594656340108,
        37.38589011362832
      ]
    },
    "41030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.337805395895366,
              37.39215385267307
            ]
          },
          "bbox": [
            -6.337805395895366,
            37.39215385267307,
            -6.337805395895366,
            37.39215385267307
          ]
        }
      ],
      "bbox": [
        -6.337805395895366,
        37.39215385267307,
        -6.337805395895366,
        37.39215385267307
      ]
    },
    "41031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.317054331538321,
              37.693429750042945
            ]
          },
          "bbox": [
            -6.317054331538321,
            37.693429750042945,
            -6.317054331538321,
            37.693429750042945
          ]
        }
      ],
      "bbox": [
        -6.317054331538321,
        37.693429750042945,
        -6.317054331538321,
        37.693429750042945
      ]
    },
    "41032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.842437729291304,
              37.93978671457853
            ]
          },
          "bbox": [
            -5.842437729291304,
            37.93978671457853,
            -5.842437729291304,
            37.93978671457853
          ]
        }
      ],
      "bbox": [
        -5.842437729291304,
        37.93978671457853,
        -5.842437729291304,
        37.93978671457853
      ]
    },
    "41033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.595033467522623,
              37.851577947729034
            ]
          },
          "bbox": [
            -5.595033467522623,
            37.851577947729034,
            -5.595033467522623,
            37.851577947729034
          ]
        }
      ],
      "bbox": [
        -5.595033467522623,
        37.851577947729034,
        -5.595033467522623,
        37.851577947729034
      ]
    },
    "41034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.035021470415589,
              37.24718140297215
            ]
          },
          "bbox": [
            -6.035021470415589,
            37.24718140297215,
            -6.035021470415589,
            37.24718140297215
          ]
        }
      ],
      "bbox": [
        -6.035021470415589,
        37.24718140297215,
        -6.035021470415589,
        37.24718140297215
      ]
    },
    "41035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.405590897779352,
              36.98708661439941
            ]
          },
          "bbox": [
            -5.405590897779352,
            36.98708661439941,
            -5.405590897779352,
            36.98708661439941
          ]
        }
      ],
      "bbox": [
        -5.405590897779352,
        36.98708661439941,
        -5.405590897779352,
        36.98708661439941
      ]
    },
    "41036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.619505934727213,
              37.02667162974392
            ]
          },
          "bbox": [
            -5.619505934727213,
            37.02667162974392,
            -5.619505934727213,
            37.02667162974392
          ]
        }
      ],
      "bbox": [
        -5.619505934727213,
        37.02667162974392,
        -5.619505934727213,
        37.02667162974392
      ]
    },
    "41037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.014520907164293,
              37.11022979809198
            ]
          },
          "bbox": [
            -5.014520907164293,
            37.11022979809198,
            -5.014520907164293,
            37.11022979809198
          ]
        }
      ],
      "bbox": [
        -5.014520907164293,
        37.11022979809198,
        -5.014520907164293,
        37.11022979809198
      ]
    },
    "41038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9625527424774685,
              37.25689315283021
            ]
          },
          "bbox": [
            -5.9625527424774685,
            37.25689315283021,
            -5.9625527424774685,
            37.25689315283021
          ]
        }
      ],
      "bbox": [
        -5.9625527424774685,
        37.25689315283021,
        -5.9625527424774685,
        37.25689315283021
      ]
    },
    "41039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.09143506742201,
              37.52335184330504
            ]
          },
          "bbox": [
            -5.09143506742201,
            37.52335184330504,
            -5.09143506742201,
            37.52335184330504
          ]
        }
      ],
      "bbox": [
        -5.09143506742201,
        37.52335184330504,
        -5.09143506742201,
        37.52335184330504
      ]
    },
    "41040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.124509032103619,
              37.38547917797212
            ]
          },
          "bbox": [
            -6.124509032103619,
            37.38547917797212,
            -6.124509032103619,
            37.38547917797212
          ]
        }
      ],
      "bbox": [
        -6.124509032103619,
        37.38547917797212,
        -6.124509032103619,
        37.38547917797212
      ]
    },
    "41041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.890879642772396,
              37.30744104552594
            ]
          },
          "bbox": [
            -4.890879642772396,
            37.30744104552594,
            -4.890879642772396,
            37.30744104552594
          ]
        }
      ],
      "bbox": [
        -4.890879642772396,
        37.30744104552594,
        -4.890879642772396,
        37.30744104552594
      ]
    },
    "41042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3477734703647695,
              37.499473055902335
            ]
          },
          "bbox": [
            -5.3477734703647695,
            37.499473055902335,
            -5.3477734703647695,
            37.499473055902335
          ]
        }
      ],
      "bbox": [
        -5.3477734703647695,
        37.499473055902335,
        -5.3477734703647695,
        37.499473055902335
      ]
    },
    "41043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.178688463246811,
              37.630817247216314
            ]
          },
          "bbox": [
            -6.178688463246811,
            37.630817247216314,
            -6.178688463246811,
            37.630817247216314
          ]
        }
      ],
      "bbox": [
        -6.178688463246811,
        37.630817247216314,
        -6.178688463246811,
        37.630817247216314
      ]
    },
    "41044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.04146615960285,
              37.32989624127888
            ]
          },
          "bbox": [
            -6.04146615960285,
            37.32989624127888,
            -6.04146615960285,
            37.32989624127888
          ]
        }
      ],
      "bbox": [
        -6.04146615960285,
        37.32989624127888,
        -6.04146615960285,
        37.32989624127888
      ]
    },
    "41045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1680608489662285,
              37.548664657399286
            ]
          },
          "bbox": [
            -6.1680608489662285,
            37.548664657399286,
            -6.1680608489662285,
            37.548664657399286
          ]
        }
      ],
      "bbox": [
        -6.1680608489662285,
        37.548664657399286,
        -6.1680608489662285,
        37.548664657399286
      ]
    },
    "41046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.934829602876317,
              37.23194275802572
            ]
          },
          "bbox": [
            -4.934829602876317,
            37.23194275802572,
            -4.934829602876317,
            37.23194275802572
          ]
        }
      ],
      "bbox": [
        -4.934829602876317,
        37.23194275802572,
        -4.934829602876317,
        37.23194275802572
      ]
    },
    "41047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0770090929469145,
              37.38641165302076
            ]
          },
          "bbox": [
            -6.0770090929469145,
            37.38641165302076,
            -6.0770090929469145,
            37.38641165302076
          ]
        }
      ],
      "bbox": [
        -6.0770090929469145,
        37.38641165302076,
        -6.0770090929469145,
        37.38641165302076
      ]
    },
    "41048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.810927684471647,
              38.10026972256449
            ]
          },
          "bbox": [
            -5.810927684471647,
            38.10026972256449,
            -5.810927684471647,
            38.10026972256449
          ]
        }
      ],
      "bbox": [
        -5.810927684471647,
        38.10026972256449,
        -5.810927684471647,
        38.10026972256449
      ]
    },
    "41049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.087355689602773,
              37.615195194847324
            ]
          },
          "bbox": [
            -6.087355689602773,
            37.615195194847324,
            -6.087355689602773,
            37.615195194847324
          ]
        }
      ],
      "bbox": [
        -6.087355689602773,
        37.615195194847324,
        -6.087355689602773,
        37.615195194847324
      ]
    },
    "41050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.864510506533698,
              37.3774781755495
            ]
          },
          "bbox": [
            -4.864510506533698,
            37.3774781755495,
            -4.864510506533698,
            37.3774781755495
          ]
        }
      ],
      "bbox": [
        -4.864510506533698,
        37.3774781755495,
        -4.864510506533698,
        37.3774781755495
      ]
    },
    "41051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.293177467835226,
              37.35900977207206
            ]
          },
          "bbox": [
            -6.293177467835226,
            37.35900977207206,
            -6.293177467835226,
            37.35900977207206
          ]
        }
      ],
      "bbox": [
        -6.293177467835226,
        37.35900977207206,
        -6.293177467835226,
        37.35900977207206
      ]
    },
    "41052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.220484007576361,
              37.361312321424684
            ]
          },
          "bbox": [
            -5.220484007576361,
            37.361312321424684,
            -5.220484007576361,
            37.361312321424684
          ]
        }
      ],
      "bbox": [
        -5.220484007576361,
        37.361312321424684,
        -5.220484007576361,
        37.361312321424684
      ]
    },
    "41053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.061381019056377,
              36.93951381689795
            ]
          },
          "bbox": [
            -6.061381019056377,
            36.93951381689795,
            -6.061381019056377,
            36.93951381689795
          ]
        }
      ],
      "bbox": [
        -6.061381019056377,
        36.93951381689795,
        -6.061381019056377,
        36.93951381689795
      ]
    },
    "41054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.819022214968444,
              37.270645183882635
            ]
          },
          "bbox": [
            -4.819022214968444,
            37.270645183882635,
            -4.819022214968444,
            37.270645183882635
          ]
        }
      ],
      "bbox": [
        -4.819022214968444,
        37.270645183882635,
        -4.819022214968444,
        37.270645183882635
      ]
    },
    "41055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.508481582453457,
              37.669880290665766
            ]
          },
          "bbox": [
            -5.508481582453457,
            37.669880290665766,
            -5.508481582453457,
            37.669880290665766
          ]
        }
      ],
      "bbox": [
        -5.508481582453457,
        37.669880290665766,
        -5.508481582453457,
        37.669880290665766
      ]
    },
    "41056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.218874244613761,
              37.55201460240119
            ]
          },
          "bbox": [
            -5.218874244613761,
            37.55201460240119,
            -5.218874244613761,
            37.55201460240119
          ]
        }
      ],
      "bbox": [
        -5.218874244613761,
        37.55201460240119,
        -5.218874244613761,
        37.55201460240119
      ]
    },
    "41057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.447762000179349,
              37.65020481351675
            ]
          },
          "bbox": [
            -6.447762000179349,
            37.65020481351675,
            -6.447762000179349,
            37.65020481351675
          ]
        }
      ],
      "bbox": [
        -6.447762000179349,
        37.65020481351675,
        -6.447762000179349,
        37.65020481351675
      ]
    },
    "41058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.74074890949057,
              37.34874839092541
            ]
          },
          "bbox": [
            -5.74074890949057,
            37.34874839092541,
            -5.74074890949057,
            37.34874839092541
          ]
        }
      ],
      "bbox": [
        -5.74074890949057,
        37.34874839092541,
        -5.74074890949057,
        37.34874839092541
      ]
    },
    "41059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0676791891301685,
              37.338686614691184
            ]
          },
          "bbox": [
            -6.0676791891301685,
            37.338686614691184,
            -6.0676791891301685,
            37.338686614691184
          ]
        }
      ],
      "bbox": [
        -6.0676791891301685,
        37.338686614691184,
        -6.0676791891301685,
        37.338686614691184
      ]
    },
    "41060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.364393631177426,
              37.33199063710384
            ]
          },
          "bbox": [
            -5.364393631177426,
            37.33199063710384,
            -5.364393631177426,
            37.33199063710384
          ]
        }
      ],
      "bbox": [
        -5.364393631177426,
        37.33199063710384,
        -5.364393631177426,
        37.33199063710384
      ]
    },
    "41061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.942268480762543,
              37.371734038546634
            ]
          },
          "bbox": [
            -4.942268480762543,
            37.371734038546634,
            -4.942268480762543,
            37.371734038546634
          ]
        }
      ],
      "bbox": [
        -4.942268480762543,
        37.371734038546634,
        -4.942268480762543,
        37.371734038546634
      ]
    },
    "41062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9543455070438345,
              37.13350004426152
            ]
          },
          "bbox": [
            -4.9543455070438345,
            37.13350004426152,
            -4.9543455070438345,
            37.13350004426152
          ]
        }
      ],
      "bbox": [
        -4.9543455070438345,
        37.13350004426152,
        -4.9543455070438345,
        37.13350004426152
      ]
    },
    "41063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6833991429252055,
              37.16095821729434
            ]
          },
          "bbox": [
            -5.6833991429252055,
            37.16095821729434,
            -5.6833991429252055,
            37.16095821729434
          ]
        }
      ],
      "bbox": [
        -5.6833991429252055,
        37.16095821729434,
        -5.6833991429252055,
        37.16095821729434
      ]
    },
    "41064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.547192359648848,
              37.00446707226764
            ]
          },
          "bbox": [
            -5.547192359648848,
            37.00446707226764,
            -5.547192359648848,
            37.00446707226764
          ]
        }
      ],
      "bbox": [
        -5.547192359648848,
        37.00446707226764,
        -5.547192359648848,
        37.00446707226764
      ]
    },
    "41065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4270099389819615,
              37.11425028140718
            ]
          },
          "bbox": [
            -5.4270099389819615,
            37.11425028140718,
            -5.4270099389819615,
            37.11425028140718
          ]
        }
      ],
      "bbox": [
        -5.4270099389819615,
        37.11425028140718,
        -5.4270099389819615,
        37.11425028140718
      ]
    },
    "41066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.461992473065377,
              37.93668268924332
            ]
          },
          "bbox": [
            -5.461992473065377,
            37.93668268924332,
            -5.461992473065377,
            37.93668268924332
          ]
        }
      ],
      "bbox": [
        -5.461992473065377,
        37.93668268924332,
        -5.461992473065377,
        37.93668268924332
      ]
    },
    "41067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.169082700527407,
              37.45864365140165
            ]
          },
          "bbox": [
            -6.169082700527407,
            37.45864365140165,
            -6.169082700527407,
            37.45864365140165
          ]
        }
      ],
      "bbox": [
        -6.169082700527407,
        37.45864365140165,
        -6.169082700527407,
        37.45864365140165
      ]
    },
    "41068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.124652439583915,
              37.24931056129507
            ]
          },
          "bbox": [
            -5.124652439583915,
            37.24931056129507,
            -5.124652439583915,
            37.24931056129507
          ]
        }
      ],
      "bbox": [
        -5.124652439583915,
        37.24931056129507,
        -5.124652439583915,
        37.24931056129507
      ]
    },
    "41069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.92111137036716,
              37.15018665489852
            ]
          },
          "bbox": [
            -5.92111137036716,
            37.15018665489852,
            -5.92111137036716,
            37.15018665489852
          ]
        }
      ],
      "bbox": [
        -5.92111137036716,
        37.15018665489852,
        -5.92111137036716,
        37.15018665489852
      ]
    },
    "41070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.057680217912196,
              37.31507225799452
            ]
          },
          "bbox": [
            -6.057680217912196,
            37.31507225799452,
            -6.057680217912196,
            37.31507225799452
          ]
        }
      ],
      "bbox": [
        -6.057680217912196,
        37.31507225799452,
        -6.057680217912196,
        37.31507225799452
      ]
    },
    "41071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4981851997994955,
              37.291481470134464
            ]
          },
          "bbox": [
            -5.4981851997994955,
            37.291481470134464,
            -5.4981851997994955,
            37.291481470134464
          ]
        }
      ],
      "bbox": [
        -5.4981851997994955,
        37.291481470134464,
        -5.4981851997994955,
        37.291481470134464
      ]
    },
    "41072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.894924639247439,
              37.202400172601536
            ]
          },
          "bbox": [
            -4.894924639247439,
            37.202400172601536,
            -4.894924639247439,
            37.202400172601536
          ]
        }
      ],
      "bbox": [
        -4.894924639247439,
        37.202400172601536,
        -4.894924639247439,
        37.202400172601536
      ]
    },
    "41073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.796694368877632,
              37.78933676117958
            ]
          },
          "bbox": [
            -5.796694368877632,
            37.78933676117958,
            -5.796694368877632,
            37.78933676117958
          ]
        }
      ],
      "bbox": [
        -5.796694368877632,
        37.78933676117958,
        -5.796694368877632,
        37.78933676117958
      ]
    },
    "41074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.365193413510945,
              37.731256812655324
            ]
          },
          "bbox": [
            -5.365193413510945,
            37.731256812655324,
            -5.365193413510945,
            37.731256812655324
          ]
        }
      ],
      "bbox": [
        -5.365193413510945,
        37.731256812655324,
        -5.365193413510945,
        37.731256812655324
      ]
    },
    "41075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.31290680177989,
              37.30534112131341
            ]
          },
          "bbox": [
            -6.31290680177989,
            37.30534112131341,
            -6.31290680177989,
            37.30534112131341
          ]
        }
      ],
      "bbox": [
        -6.31290680177989,
        37.30534112131341,
        -6.31290680177989,
        37.30534112131341
      ]
    },
    "41076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.231574637779474,
              37.00734270934194
            ]
          },
          "bbox": [
            -5.231574637779474,
            37.00734270934194,
            -5.231574637779474,
            37.00734270934194
          ]
        }
      ],
      "bbox": [
        -5.231574637779474,
        37.00734270934194,
        -5.231574637779474,
        37.00734270934194
      ]
    },
    "41077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.275092344343056,
              37.16213893482733
            ]
          },
          "bbox": [
            -5.275092344343056,
            37.16213893482733,
            -5.275092344343056,
            37.16213893482733
          ]
        }
      ],
      "bbox": [
        -5.275092344343056,
        37.16213893482733,
        -5.275092344343056,
        37.16213893482733
      ]
    },
    "41078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.428656776706612,
              37.806668865763335
            ]
          },
          "bbox": [
            -5.428656776706612,
            37.806668865763335,
            -5.428656776706612,
            37.806668865763335
          ]
        }
      ],
      "bbox": [
        -5.428656776706612,
        37.806668865763335,
        -5.428656776706612,
        37.806668865763335
      ]
    },
    "41079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.144395105029601,
              37.10603749150813
            ]
          },
          "bbox": [
            -6.144395105029601,
            37.10603749150813,
            -6.144395105029601,
            37.10603749150813
          ]
        }
      ],
      "bbox": [
        -6.144395105029601,
        37.10603749150813,
        -6.144395105029601,
        37.10603749150813
      ]
    },
    "41080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.050142783567691,
              37.95051092418404
            ]
          },
          "bbox": [
            -6.050142783567691,
            37.95051092418404,
            -6.050142783567691,
            37.95051092418404
          ]
        }
      ],
      "bbox": [
        -6.050142783567691,
        37.95051092418404,
        -6.050142783567691,
        37.95051092418404
      ]
    },
    "41081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.896330920388972,
              37.470097328796
            ]
          },
          "bbox": [
            -5.896330920388972,
            37.470097328796,
            -5.896330920388972,
            37.470097328796
          ]
        }
      ],
      "bbox": [
        -5.896330920388972,
        37.470097328796,
        -5.896330920388972,
        37.470097328796
      ]
    },
    "41082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.775323843222795,
              37.21021847466519
            ]
          },
          "bbox": [
            -4.775323843222795,
            37.21021847466519,
            -4.775323843222795,
            37.21021847466519
          ]
        }
      ],
      "bbox": [
        -4.775323843222795,
        37.21021847466519,
        -4.775323843222795,
        37.21021847466519
      ]
    },
    "41083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.178372437313729,
              37.74856898286038
            ]
          },
          "bbox": [
            -6.178372437313729,
            37.74856898286038,
            -6.178372437313729,
            37.74856898286038
          ]
        }
      ],
      "bbox": [
        -6.178372437313729,
        37.74856898286038,
        -6.178372437313729,
        37.74856898286038
      ]
    },
    "41084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0105225923563115,
              37.34558089908838
            ]
          },
          "bbox": [
            -5.0105225923563115,
            37.34558089908838,
            -5.0105225923563115,
            37.34558089908838
          ]
        }
      ],
      "bbox": [
        -5.0105225923563115,
        37.34558089908838,
        -5.0105225923563115,
        37.34558089908838
      ]
    },
    "41085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.088682011764571,
              37.45630130802008
            ]
          },
          "bbox": [
            -6.088682011764571,
            37.45630130802008,
            -6.088682011764571,
            37.45630130802008
          ]
        }
      ],
      "bbox": [
        -6.088682011764571,
        37.45630130802008,
        -6.088682011764571,
        37.45630130802008
      ]
    },
    "41086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.031145586917303,
              37.36274646745644
            ]
          },
          "bbox": [
            -6.031145586917303,
            37.36274646745644,
            -6.031145586917303,
            37.36274646745644
          ]
        }
      ],
      "bbox": [
        -6.031145586917303,
        37.36274646745644,
        -6.031145586917303,
        37.36274646745644
      ]
    },
    "41087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.241133144157179,
              37.45008659293657
            ]
          },
          "bbox": [
            -6.241133144157179,
            37.45008659293657,
            -6.241133144157179,
            37.45008659293657
          ]
        }
      ],
      "bbox": [
        -6.241133144157179,
        37.45008659293657,
        -6.241133144157179,
        37.45008659293657
      ]
    },
    "41088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.631961142943109,
              37.98033532346759
            ]
          },
          "bbox": [
            -5.631961142943109,
            37.98033532346759,
            -5.631961142943109,
            37.98033532346759
          ]
        }
      ],
      "bbox": [
        -5.631961142943109,
        37.98033532346759,
        -5.631961142943109,
        37.98033532346759
      ]
    },
    "41089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.030481158674964,
              37.43386129632194
            ]
          },
          "bbox": [
            -6.030481158674964,
            37.43386129632194,
            -6.030481158674964,
            37.43386129632194
          ]
        }
      ],
      "bbox": [
        -6.030481158674964,
        37.43386129632194,
        -6.030481158674964,
        37.43386129632194
      ]
    },
    "41090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.092263750748178,
              37.07277298145473
            ]
          },
          "bbox": [
            -5.092263750748178,
            37.07277298145473,
            -5.092263750748178,
            37.07277298145473
          ]
        }
      ],
      "bbox": [
        -5.092263750748178,
        37.07277298145473,
        -5.092263750748178,
        37.07277298145473
      ]
    },
    "41091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.956276853534238,
              37.3923023080687
            ]
          },
          "bbox": [
            -5.956276853534238,
            37.3923023080687,
            -5.956276853534238,
            37.3923023080687
          ]
        }
      ],
      "bbox": [
        -5.956276853534238,
        37.3923023080687,
        -5.956276853534238,
        37.3923023080687
      ]
    },
    "41092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.730092978094755,
              37.60550615565507
            ]
          },
          "bbox": [
            -5.730092978094755,
            37.60550615565507,
            -5.730092978094755,
            37.60550615565507
          ]
        }
      ],
      "bbox": [
        -5.730092978094755,
        37.60550615565507,
        -5.730092978094755,
        37.60550615565507
      ]
    },
    "41093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.047649618042081,
              37.371968090764256
            ]
          },
          "bbox": [
            -6.047649618042081,
            37.371968090764256,
            -6.047649618042081,
            37.371968090764256
          ]
        }
      ],
      "bbox": [
        -6.047649618042081,
        37.371968090764256,
        -6.047649618042081,
        37.371968090764256
      ]
    },
    "41094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.178123713277574,
              37.34475219201424
            ]
          },
          "bbox": [
            -6.178123713277574,
            37.34475219201424,
            -6.178123713277574,
            37.34475219201424
          ]
        }
      ],
      "bbox": [
        -6.178123713277574,
        37.34475219201424,
        -6.178123713277574,
        37.34475219201424
      ]
    },
    "41095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.794379787646433,
              37.08051256275217
            ]
          },
          "bbox": [
            -5.794379787646433,
            37.08051256275217,
            -5.794379787646433,
            37.08051256275217
          ]
        }
      ],
      "bbox": [
        -5.794379787646433,
        37.08051256275217,
        -5.794379787646433,
        37.08051256275217
      ]
    },
    "41096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.072792859665547,
              37.42437892055145
            ]
          },
          "bbox": [
            -6.072792859665547,
            37.42437892055145,
            -6.072792859665547,
            37.42437892055145
          ]
        }
      ],
      "bbox": [
        -6.072792859665547,
        37.42437892055145,
        -6.072792859665547,
        37.42437892055145
      ]
    },
    "41097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.318288174831495,
              37.22718265847988
            ]
          },
          "bbox": [
            -6.318288174831495,
            37.22718265847988,
            -6.318288174831495,
            37.22718265847988
          ]
        }
      ],
      "bbox": [
        -6.318288174831495,
        37.22718265847988,
        -6.318288174831495,
        37.22718265847988
      ]
    },
    "41098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1501051794207005,
              37.39950355504366
            ]
          },
          "bbox": [
            -6.1501051794207005,
            37.39950355504366,
            -6.1501051794207005,
            37.39950355504366
          ]
        }
      ],
      "bbox": [
        -6.1501051794207005,
        37.39950355504366,
        -6.1501051794207005,
        37.39950355504366
      ]
    },
    "41099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.717366070309285,
              37.66555685863671
            ]
          },
          "bbox": [
            -5.717366070309285,
            37.66555685863671,
            -5.717366070309285,
            37.66555685863671
          ]
        }
      ],
      "bbox": [
        -5.717366070309285,
        37.66555685863671,
        -5.717366070309285,
        37.66555685863671
      ]
    },
    "41100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1855861439647315,
              37.07166904131577
            ]
          },
          "bbox": [
            -5.1855861439647315,
            37.07166904131577,
            -5.1855861439647315,
            37.07166904131577
          ]
        }
      ],
      "bbox": [
        -5.1855861439647315,
        37.07166904131577,
        -5.1855861439647315,
        37.07166904131577
      ]
    },
    "41101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.893349056870513,
              37.6052345820404
            ]
          },
          "bbox": [
            -5.893349056870513,
            37.6052345820404,
            -5.893349056870513,
            37.6052345820404
          ]
        }
      ],
      "bbox": [
        -5.893349056870513,
        37.6052345820404,
        -5.893349056870513,
        37.6052345820404
      ]
    },
    "41102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.709349203156804,
              37.38586224151459
            ]
          },
          "bbox": [
            -5.709349203156804,
            37.38586224151459,
            -5.709349203156804,
            37.38586224151459
          ]
        }
      ],
      "bbox": [
        -5.709349203156804,
        37.38586224151459,
        -5.709349203156804,
        37.38586224151459
      ]
    },
    "41901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.223356556651742,
              37.59941886727561
            ]
          },
          "bbox": [
            -5.223356556651742,
            37.59941886727561,
            -5.223356556651742,
            37.59941886727561
          ]
        }
      ],
      "bbox": [
        -5.223356556651742,
        37.59941886727561,
        -5.223356556651742,
        37.59941886727561
      ]
    },
    "41902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.178248025700094,
              37.11175794738129
            ]
          },
          "bbox": [
            -6.178248025700094,
            37.11175794738129,
            -6.178248025700094,
            37.11175794738129
          ]
        }
      ],
      "bbox": [
        -6.178248025700094,
        37.11175794738129,
        -6.178248025700094,
        37.11175794738129
      ]
    },
    "41903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.013679770936468,
              36.86269517535376
            ]
          },
          "bbox": [
            -6.013679770936468,
            36.86269517535376,
            -6.013679770936468,
            36.86269517535376
          ]
        }
      ],
      "bbox": [
        -6.013679770936468,
        36.86269517535376,
        -6.013679770936468,
        36.86269517535376
      ]
    },
    "41904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7899864633009885,
              37.053122153149985
            ]
          },
          "bbox": [
            -5.7899864633009885,
            37.053122153149985,
            -5.7899864633009885,
            37.053122153149985
          ]
        }
      ],
      "bbox": [
        -5.7899864633009885,
        37.053122153149985,
        -5.7899864633009885,
        37.053122153149985
      ]
    },
    "42001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.785929533480755,
              41.80049049310858
            ]
          },
          "bbox": [
            -2.785929533480755,
            41.80049049310858,
            -2.785929533480755,
            41.80049049310858
          ]
        }
      ],
      "bbox": [
        -2.785929533480755,
        41.80049049310858,
        -2.785929533480755,
        41.80049049310858
      ]
    },
    "42003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4931365549816995,
              41.34565156728276
            ]
          },
          "bbox": [
            -2.4931365549816995,
            41.34565156728276,
            -2.4931365549816995,
            41.34565156728276
          ]
        }
      ],
      "bbox": [
        -2.4931365549816995,
        41.34565156728276,
        -2.4931365549816995,
        41.34565156728276
      ]
    },
    "42004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9015755998832087,
              41.853931644466215
            ]
          },
          "bbox": [
            -1.9015755998832087,
            41.853931644466215,
            -1.9015755998832087,
            41.853931644466215
          ]
        }
      ],
      "bbox": [
        -1.9015755998832087,
        41.853931644466215,
        -1.9015755998832087,
        41.853931644466215
      ]
    },
    "42006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.381430275281331,
              41.731292237313085
            ]
          },
          "bbox": [
            -2.381430275281331,
            41.731292237313085,
            -2.381430275281331,
            41.731292237313085
          ]
        }
      ],
      "bbox": [
        -2.381430275281331,
        41.731292237313085,
        -2.381430275281331,
        41.731292237313085
      ]
    },
    "42007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.295674528007418,
              41.71552109834925
            ]
          },
          "bbox": [
            -3.295674528007418,
            41.71552109834925,
            -3.295674528007418,
            41.71552109834925
          ]
        }
      ],
      "bbox": [
        -3.295674528007418,
        41.71552109834925,
        -3.295674528007418,
        41.71552109834925
      ]
    },
    "42008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5054262808897954,
              41.27329833718812
            ]
          },
          "bbox": [
            -2.5054262808897954,
            41.27329833718812,
            -2.5054262808897954,
            41.27329833718812
          ]
        }
      ],
      "bbox": [
        -2.5054262808897954,
        41.27329833718812,
        -2.5054262808897954,
        41.27329833718812
      ]
    },
    "42009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3328190749285476,
              41.65437316527147
            ]
          },
          "bbox": [
            -2.3328190749285476,
            41.65437316527147,
            -2.3328190749285476,
            41.65437316527147
          ]
        }
      ],
      "bbox": [
        -2.3328190749285476,
        41.65437316527147,
        -2.3328190749285476,
        41.65437316527147
      ]
    },
    "42010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3022516421497947,
              41.90664228944876
            ]
          },
          "bbox": [
            -2.3022516421497947,
            41.90664228944876,
            -2.3022516421497947,
            41.90664228944876
          ]
        }
      ],
      "bbox": [
        -2.3022516421497947,
        41.90664228944876,
        -2.3022516421497947,
        41.90664228944876
      ]
    },
    "42011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1965363768883384,
              41.78542856368026
            ]
          },
          "bbox": [
            -2.1965363768883384,
            41.78542856368026,
            -2.1965363768883384,
            41.78542856368026
          ]
        }
      ],
      "bbox": [
        -2.1965363768883384,
        41.78542856368026,
        -2.1965363768883384,
        41.78542856368026
      ]
    },
    "42012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.312141626912725,
              41.88447455386882
            ]
          },
          "bbox": [
            -2.312141626912725,
            41.88447455386882,
            -2.312141626912725,
            41.88447455386882
          ]
        }
      ],
      "bbox": [
        -2.312141626912725,
        41.88447455386882,
        -2.312141626912725,
        41.88447455386882
      ]
    },
    "42013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3180242685383528,
              41.80984597460105
            ]
          },
          "bbox": [
            -2.3180242685383528,
            41.80984597460105,
            -2.3180242685383528,
            41.80984597460105
          ]
        }
      ],
      "bbox": [
        -2.3180242685383528,
        41.80984597460105,
        -2.3180242685383528,
        41.80984597460105
      ]
    },
    "42014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3715301735254806,
              42.00137695629383
            ]
          },
          "bbox": [
            -2.3715301735254806,
            42.00137695629383,
            -2.3715301735254806,
            42.00137695629383
          ]
        }
      ],
      "bbox": [
        -2.3715301735254806,
        42.00137695629383,
        -2.3715301735254806,
        42.00137695629383
      ]
    },
    "42015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3354100980019314,
              41.40480643948374
            ]
          },
          "bbox": [
            -2.3354100980019314,
            41.40480643948374,
            -2.3354100980019314,
            41.40480643948374
          ]
        }
      ],
      "bbox": [
        -2.3354100980019314,
        41.40480643948374,
        -2.3354100980019314,
        41.40480643948374
      ]
    },
    "42016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.250065623988482,
              41.65423596847066
            ]
          },
          "bbox": [
            -2.250065623988482,
            41.65423596847066,
            -2.250065623988482,
            41.65423596847066
          ]
        }
      ],
      "bbox": [
        -2.250065623988482,
        41.65423596847066,
        -2.250065623988482,
        41.65423596847066
      ]
    },
    "42017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.336661038533378,
              41.84361226674914
            ]
          },
          "bbox": [
            -2.336661038533378,
            41.84361226674914,
            -2.336661038533378,
            41.84361226674914
          ]
        }
      ],
      "bbox": [
        -2.336661038533378,
        41.84361226674914,
        -2.336661038533378,
        41.84361226674914
      ]
    },
    "42018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3070292167520865,
              41.31779038856857
            ]
          },
          "bbox": [
            -2.3070292167520865,
            41.31779038856857,
            -2.3070292167520865,
            41.31779038856857
          ]
        }
      ],
      "bbox": [
        -2.3070292167520865,
        41.31779038856857,
        -2.3070292167520865,
        41.31779038856857
      ]
    },
    "42019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.461674261812422,
              41.93644968664269
            ]
          },
          "bbox": [
            -2.461674261812422,
            41.93644968664269,
            -2.461674261812422,
            41.93644968664269
          ]
        }
      ],
      "bbox": [
        -2.461674261812422,
        41.93644968664269,
        -2.461674261812422,
        41.93644968664269
      ]
    },
    "42020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5559881735430365,
              41.48296488354539
            ]
          },
          "bbox": [
            -2.5559881735430365,
            41.48296488354539,
            -2.5559881735430365,
            41.48296488354539
          ]
        }
      ],
      "bbox": [
        -2.5559881735430365,
        41.48296488354539,
        -2.5559881735430365,
        41.48296488354539
      ]
    },
    "42021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.141543805199515,
              41.555991457435574
            ]
          },
          "bbox": [
            -2.141543805199515,
            41.555991457435574,
            -2.141543805199515,
            41.555991457435574
          ]
        }
      ],
      "bbox": [
        -2.141543805199515,
        41.555991457435574,
        -2.141543805199515,
        41.555991457435574
      ]
    },
    "42022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1662026820948617,
              41.694737686481034
            ]
          },
          "bbox": [
            -2.1662026820948617,
            41.694737686481034,
            -2.1662026820948617,
            41.694737686481034
          ]
        }
      ],
      "bbox": [
        -2.1662026820948617,
        41.694737686481034,
        -2.1662026820948617,
        41.694737686481034
      ]
    },
    "42023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.698921939893774,
              41.277714812181046
            ]
          },
          "bbox": [
            -2.698921939893774,
            41.277714812181046,
            -2.698921939893774,
            41.277714812181046
          ]
        }
      ],
      "bbox": [
        -2.698921939893774,
        41.277714812181046,
        -2.698921939893774,
        41.277714812181046
      ]
    },
    "42024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.25436078730918,
              41.78489247283892
            ]
          },
          "bbox": [
            -2.25436078730918,
            41.78489247283892,
            -2.25436078730918,
            41.78489247283892
          ]
        }
      ],
      "bbox": [
        -2.25436078730918,
        41.78489247283892,
        -2.25436078730918,
        41.78489247283892
      ]
    },
    "42025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2530581853017924,
              41.17476671132659
            ]
          },
          "bbox": [
            -2.2530581853017924,
            41.17476671132659,
            -2.2530581853017924,
            41.17476671132659
          ]
        }
      ],
      "bbox": [
        -2.2530581853017924,
        41.17476671132659,
        -2.2530581853017924,
        41.17476671132659
      ]
    },
    "42026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8463778942316402,
              41.341943710783276
            ]
          },
          "bbox": [
            -2.8463778942316402,
            41.341943710783276,
            -2.8463778942316402,
            41.341943710783276
          ]
        }
      ],
      "bbox": [
        -2.8463778942316402,
        41.341943710783276,
        -2.8463778942316402,
        41.341943710783276
      ]
    },
    "42027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.387779560249385,
              41.95260828522881
            ]
          },
          "bbox": [
            -2.387779560249385,
            41.95260828522881,
            -2.387779560249385,
            41.95260828522881
          ]
        }
      ],
      "bbox": [
        -2.387779560249385,
        41.95260828522881,
        -2.387779560249385,
        41.95260828522881
      ]
    },
    "42028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.363235814837647,
              41.906882846672936
            ]
          },
          "bbox": [
            -2.363235814837647,
            41.906882846672936,
            -2.363235814837647,
            41.906882846672936
          ]
        }
      ],
      "bbox": [
        -2.363235814837647,
        41.906882846672936,
        -2.363235814837647,
        41.906882846672936
      ]
    },
    "42029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6195211833741876,
              41.29777259774476
            ]
          },
          "bbox": [
            -2.6195211833741876,
            41.29777259774476,
            -2.6195211833741876,
            41.29777259774476
          ]
        }
      ],
      "bbox": [
        -2.6195211833741876,
        41.29777259774476,
        -2.6195211833741876,
        41.29777259774476
      ]
    },
    "42030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6410031773029656,
              41.43909333796098
            ]
          },
          "bbox": [
            -2.6410031773029656,
            41.43909333796098,
            -2.6410031773029656,
            41.43909333796098
          ]
        }
      ],
      "bbox": [
        -2.6410031773029656,
        41.43909333796098,
        -2.6410031773029656,
        41.43909333796098
      ]
    },
    "42031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.811360443237709,
              41.289479309024216
            ]
          },
          "bbox": [
            -2.811360443237709,
            41.289479309024216,
            -2.811360443237709,
            41.289479309024216
          ]
        }
      ],
      "bbox": [
        -2.811360443237709,
        41.289479309024216,
        -2.811360443237709,
        41.289479309024216
      ]
    },
    "42032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9090262571763192,
              41.51058613059139
            ]
          },
          "bbox": [
            -2.9090262571763192,
            41.51058613059139,
            -2.9090262571763192,
            41.51058613059139
          ]
        }
      ],
      "bbox": [
        -2.9090262571763192,
        41.51058613059139,
        -2.9090262571763192,
        41.51058613059139
      ]
    },
    "42033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8877138532558004,
              41.56274395229013
            ]
          },
          "bbox": [
            -2.8877138532558004,
            41.56274395229013,
            -2.8877138532558004,
            41.56274395229013
          ]
        }
      ],
      "bbox": [
        -2.8877138532558004,
        41.56274395229013,
        -2.8877138532558004,
        41.56274395229013
      ]
    },
    "42034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8273560458977496,
              41.7226547908956
            ]
          },
          "bbox": [
            -1.8273560458977496,
            41.7226547908956,
            -1.8273560458977496,
            41.7226547908956
          ]
        }
      ],
      "bbox": [
        -1.8273560458977496,
        41.7226547908956,
        -1.8273560458977496,
        41.7226547908956
      ]
    },
    "42035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.873928313952751,
              41.43582235508194
            ]
          },
          "bbox": [
            -2.873928313952751,
            41.43582235508194,
            -2.873928313952751,
            41.43582235508194
          ]
        }
      ],
      "bbox": [
        -2.873928313952751,
        41.43582235508194,
        -2.873928313952751,
        41.43582235508194
      ]
    },
    "42036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8791464731417133,
              41.692159099366265
            ]
          },
          "bbox": [
            -2.8791464731417133,
            41.692159099366265,
            -2.8791464731417133,
            41.692159099366265
          ]
        }
      ],
      "bbox": [
        -2.8791464731417133,
        41.692159099366265,
        -2.8791464731417133,
        41.692159099366265
      ]
    },
    "42037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.270993312706895,
              41.52876735153626
            ]
          },
          "bbox": [
            -2.270993312706895,
            41.52876735153626,
            -2.270993312706895,
            41.52876735153626
          ]
        }
      ],
      "bbox": [
        -2.270993312706895,
        41.52876735153626,
        -2.270993312706895,
        41.52876735153626
      ]
    },
    "42038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4002340448019153,
              41.560414856238786
            ]
          },
          "bbox": [
            -2.4002340448019153,
            41.560414856238786,
            -2.4002340448019153,
            41.560414856238786
          ]
        }
      ],
      "bbox": [
        -2.4002340448019153,
        41.560414856238786,
        -2.4002340448019153,
        41.560414856238786
      ]
    },
    "42039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8823779551641076,
              41.675250626110085
            ]
          },
          "bbox": [
            -1.8823779551641076,
            41.675250626110085,
            -1.8823779551641076,
            41.675250626110085
          ]
        }
      ],
      "bbox": [
        -1.8823779551641076,
        41.675250626110085,
        -1.8823779551641076,
        41.675250626110085
      ]
    },
    "42041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.17547358495143,
              41.65000664246085
            ]
          },
          "bbox": [
            -2.17547358495143,
            41.65000664246085,
            -2.17547358495143,
            41.65000664246085
          ]
        }
      ],
      "bbox": [
        -2.17547358495143,
        41.65000664246085,
        -2.17547358495143,
        41.65000664246085
      ]
    },
    "42042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.405895582548779,
              41.844381206069166
            ]
          },
          "bbox": [
            -2.405895582548779,
            41.844381206069166,
            -2.405895582548779,
            41.844381206069166
          ]
        }
      ],
      "bbox": [
        -2.405895582548779,
        41.844381206069166,
        -2.405895582548779,
        41.844381206069166
      ]
    },
    "42043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0523408256935096,
              41.59839548430708
            ]
          },
          "bbox": [
            -3.0523408256935096,
            41.59839548430708,
            -3.0523408256935096,
            41.59839548430708
          ]
        }
      ],
      "bbox": [
        -3.0523408256935096,
        41.59839548430708,
        -3.0523408256935096,
        41.59839548430708
      ]
    },
    "42044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.274454054298848,
              41.69357319653734
            ]
          },
          "bbox": [
            -2.274454054298848,
            41.69357319653734,
            -2.274454054298848,
            41.69357319653734
          ]
        }
      ],
      "bbox": [
        -2.274454054298848,
        41.69357319653734,
        -2.274454054298848,
        41.69357319653734
      ]
    },
    "42045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8679010597281307,
              41.78167376922046
            ]
          },
          "bbox": [
            -2.8679010597281307,
            41.78167376922046,
            -2.8679010597281307,
            41.78167376922046
          ]
        }
      ],
      "bbox": [
        -2.8679010597281307,
        41.78167376922046,
        -2.8679010597281307,
        41.78167376922046
      ]
    },
    "42046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.802391534277141,
              41.71952395365641
            ]
          },
          "bbox": [
            -2.802391534277141,
            41.71952395365641,
            -2.802391534277141,
            41.71952395365641
          ]
        }
      ],
      "bbox": [
        -2.802391534277141,
        41.71952395365641,
        -2.802391534277141,
        41.71952395365641
      ]
    },
    "42048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7523598117219037,
              41.40214019805052
            ]
          },
          "bbox": [
            -2.7523598117219037,
            41.40214019805052,
            -2.7523598117219037,
            41.40214019805052
          ]
        }
      ],
      "bbox": [
        -2.7523598117219037,
        41.40214019805052,
        -2.7523598117219037,
        41.40214019805052
      ]
    },
    "42049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.301621443992878,
              41.72169461528221
            ]
          },
          "bbox": [
            -2.301621443992878,
            41.72169461528221,
            -2.301621443992878,
            41.72169461528221
          ]
        }
      ],
      "bbox": [
        -2.301621443992878,
        41.72169461528221,
        -2.301621443992878,
        41.72169461528221
      ]
    },
    "42050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.22786070884508,
              41.44956037199539
            ]
          },
          "bbox": [
            -2.22786070884508,
            41.44956037199539,
            -2.22786070884508,
            41.44956037199539
          ]
        }
      ],
      "bbox": [
        -2.22786070884508,
        41.44956037199539,
        -2.22786070884508,
        41.44956037199539
      ]
    },
    "42051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9946487275564786,
              41.55092779851434
            ]
          },
          "bbox": [
            -1.9946487275564786,
            41.55092779851434,
            -1.9946487275564786,
            41.55092779851434
          ]
        }
      ],
      "bbox": [
        -1.9946487275564786,
        41.55092779851434,
        -1.9946487275564786,
        41.55092779851434
      ]
    },
    "42052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.094887482969804,
              41.38799305111051
            ]
          },
          "bbox": [
            -3.094887482969804,
            41.38799305111051,
            -3.094887482969804,
            41.38799305111051
          ]
        }
      ],
      "bbox": [
        -3.094887482969804,
        41.38799305111051,
        -3.094887482969804,
        41.38799305111051
      ]
    },
    "42053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0790733022216026,
              41.422626451816036
            ]
          },
          "bbox": [
            -3.0790733022216026,
            41.422626451816036,
            -3.0790733022216026,
            41.422626451816036
          ]
        }
      ],
      "bbox": [
        -3.0790733022216026,
        41.422626451816036,
        -3.0790733022216026,
        41.422626451816036
      ]
    },
    "42054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.269238537035394,
              41.904548798489344
            ]
          },
          "bbox": [
            -2.269238537035394,
            41.904548798489344,
            -2.269238537035394,
            41.904548798489344
          ]
        }
      ],
      "bbox": [
        -2.269238537035394,
        41.904548798489344,
        -2.269238537035394,
        41.904548798489344
      ]
    },
    "42055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.046443408566827,
              41.80705622430337
            ]
          },
          "bbox": [
            -3.046443408566827,
            41.80705622430337,
            -3.046443408566827,
            41.80705622430337
          ]
        }
      ],
      "bbox": [
        -3.046443408566827,
        41.80705622430337,
        -3.046443408566827,
        41.80705622430337
      ]
    },
    "42056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.298124460956678,
              41.927851289467554
            ]
          },
          "bbox": [
            -2.298124460956678,
            41.927851289467554,
            -2.298124460956678,
            41.927851289467554
          ]
        }
      ],
      "bbox": [
        -2.298124460956678,
        41.927851289467554,
        -2.298124460956678,
        41.927851289467554
      ]
    },
    "42057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0228580968595935,
              41.88263505962626
            ]
          },
          "bbox": [
            -2.0228580968595935,
            41.88263505962626,
            -2.0228580968595935,
            41.88263505962626
          ]
        }
      ],
      "bbox": [
        -2.0228580968595935,
        41.88263505962626,
        -2.0228580968595935,
        41.88263505962626
      ]
    },
    "42058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4968621210255937,
              41.56540151694823
            ]
          },
          "bbox": [
            -3.4968621210255937,
            41.56540151694823,
            -3.4968621210255937,
            41.56540151694823
          ]
        }
      ],
      "bbox": [
        -3.4968621210255937,
        41.56540151694823,
        -3.4968621210255937,
        41.56540151694823
      ]
    },
    "42059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7230770535022093,
              41.51012894961851
            ]
          },
          "bbox": [
            -2.7230770535022093,
            41.51012894961851,
            -2.7230770535022093,
            41.51012894961851
          ]
        }
      ],
      "bbox": [
        -2.7230770535022093,
        41.51012894961851,
        -2.7230770535022093,
        41.51012894961851
      ]
    },
    "42060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.157594716297746,
              41.93484677362443
            ]
          },
          "bbox": [
            -2.157594716297746,
            41.93484677362443,
            -2.157594716297746,
            41.93484677362443
          ]
        }
      ],
      "bbox": [
        -2.157594716297746,
        41.93484677362443,
        -2.157594716297746,
        41.93484677362443
      ]
    },
    "42061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6736569708907862,
              41.82376698780745
            ]
          },
          "bbox": [
            -2.6736569708907862,
            41.82376698780745,
            -2.6736569708907862,
            41.82376698780745
          ]
        }
      ],
      "bbox": [
        -2.6736569708907862,
        41.82376698780745,
        -2.6736569708907862,
        41.82376698780745
      ]
    },
    "42062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0667597908913935,
              41.92633716509503
            ]
          },
          "bbox": [
            -2.0667597908913935,
            41.92633716509503,
            -2.0667597908913935,
            41.92633716509503
          ]
        }
      ],
      "bbox": [
        -2.0667597908913935,
        41.92633716509503,
        -2.0667597908913935,
        41.92633716509503
      ]
    },
    "42063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9982721424553869,
              41.4140536416323
            ]
          },
          "bbox": [
            -1.9982721424553869,
            41.4140536416323,
            -1.9982721424553869,
            41.4140536416323
          ]
        }
      ],
      "bbox": [
        -1.9982721424553869,
        41.4140536416323,
        -1.9982721424553869,
        41.4140536416323
      ]
    },
    "42064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9591547638381073,
              41.63035224993499
            ]
          },
          "bbox": [
            -1.9591547638381073,
            41.63035224993499,
            -1.9591547638381073,
            41.63035224993499
          ]
        }
      ],
      "bbox": [
        -1.9591547638381073,
        41.63035224993499,
        -1.9591547638381073,
        41.63035224993499
      ]
    },
    "42065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3247038145279073,
              41.86798907748831
            ]
          },
          "bbox": [
            -2.3247038145279073,
            41.86798907748831,
            -2.3247038145279073,
            41.86798907748831
          ]
        }
      ],
      "bbox": [
        -2.3247038145279073,
        41.86798907748831,
        -2.3247038145279073,
        41.86798907748831
      ]
    },
    "42068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4711933322662385,
              41.44265819316658
            ]
          },
          "bbox": [
            -2.4711933322662385,
            41.44265819316658,
            -2.4711933322662385,
            41.44265819316658
          ]
        }
      ],
      "bbox": [
        -2.4711933322662385,
        41.44265819316658,
        -2.4711933322662385,
        41.44265819316658
      ]
    },
    "42069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8649495658044186,
              41.9437495535273
            ]
          },
          "bbox": [
            -2.8649495658044186,
            41.9437495535273,
            -2.8649495658044186,
            41.9437495535273
          ]
        }
      ],
      "bbox": [
        -2.8649495658044186,
        41.9437495535273,
        -2.8649495658044186,
        41.9437495535273
      ]
    },
    "42070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.940730294010609,
              41.736980176767624
            ]
          },
          "bbox": [
            -2.940730294010609,
            41.736980176767624,
            -2.940730294010609,
            41.736980176767624
          ]
        }
      ],
      "bbox": [
        -2.940730294010609,
        41.736980176767624,
        -2.940730294010609,
        41.736980176767624
      ]
    },
    "42071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4567422429534775,
              41.61412378577666
            ]
          },
          "bbox": [
            -2.4567422429534775,
            41.61412378577666,
            -2.4567422429534775,
            41.61412378577666
          ]
        }
      ],
      "bbox": [
        -2.4567422429534775,
        41.61412378577666,
        -2.4567422429534775,
        41.61412378577666
      ]
    },
    "42073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8787516353547282,
              41.7571350164096
            ]
          },
          "bbox": [
            -1.8787516353547282,
            41.7571350164096,
            -1.8787516353547282,
            41.7571350164096
          ]
        }
      ],
      "bbox": [
        -1.8787516353547282,
        41.7571350164096,
        -1.8787516353547282,
        41.7571350164096
      ]
    },
    "42075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9509827869521583,
              41.90820041531857
            ]
          },
          "bbox": [
            -1.9509827869521583,
            41.90820041531857,
            -1.9509827869521583,
            41.90820041531857
          ]
        }
      ],
      "bbox": [
        -1.9509827869521583,
        41.90820041531857,
        -1.9509827869521583,
        41.90820041531857
      ]
    },
    "42076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0349468403739825,
              41.480815770110944
            ]
          },
          "bbox": [
            -2.0349468403739825,
            41.480815770110944,
            -2.0349468403739825,
            41.480815770110944
          ]
        }
      ],
      "bbox": [
        -2.0349468403739825,
        41.480815770110944,
        -2.0349468403739825,
        41.480815770110944
      ]
    },
    "42078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9309554967789073,
              41.97230787279511
            ]
          },
          "bbox": [
            -2.9309554967789073,
            41.97230787279511,
            -2.9309554967789073,
            41.97230787279511
          ]
        }
      ],
      "bbox": [
        -2.9309554967789073,
        41.97230787279511,
        -2.9309554967789073,
        41.97230787279511
      ]
    },
    "42079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3675812422959575,
              41.49097590453432
            ]
          },
          "bbox": [
            -2.3675812422959575,
            41.49097590453432,
            -2.3675812422959575,
            41.49097590453432
          ]
        }
      ],
      "bbox": [
        -2.3675812422959575,
        41.49097590453432,
        -2.3675812422959575,
        41.49097590453432
      ]
    },
    "42080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.233333149530306,
              41.786049166723714
            ]
          },
          "bbox": [
            -3.233333149530306,
            41.786049166723714,
            -3.233333149530306,
            41.786049166723714
          ]
        }
      ],
      "bbox": [
        -3.233333149530306,
        41.786049166723714,
        -3.233333149530306,
        41.786049166723714
      ]
    },
    "42081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.265116122534593,
              41.84549190137963
            ]
          },
          "bbox": [
            -3.265116122534593,
            41.84549190137963,
            -3.265116122534593,
            41.84549190137963
          ]
        }
      ],
      "bbox": [
        -3.265116122534593,
        41.84549190137963,
        -3.265116122534593,
        41.84549190137963
      ]
    },
    "42082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3252803628081486,
              41.93918810190331
            ]
          },
          "bbox": [
            -2.3252803628081486,
            41.93918810190331,
            -2.3252803628081486,
            41.93918810190331
          ]
        }
      ],
      "bbox": [
        -2.3252803628081486,
        41.93918810190331,
        -2.3252803628081486,
        41.93918810190331
      ]
    },
    "42083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5206507276600907,
              41.412851648415085
            ]
          },
          "bbox": [
            -2.5206507276600907,
            41.412851648415085,
            -2.5206507276600907,
            41.412851648415085
          ]
        }
      ],
      "bbox": [
        -2.5206507276600907,
        41.412851648415085,
        -2.5206507276600907,
        41.412851648415085
      ]
    },
    "42084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.104372002495654,
              41.46254830548801
            ]
          },
          "bbox": [
            -3.104372002495654,
            41.46254830548801,
            -3.104372002495654,
            41.46254830548801
          ]
        }
      ],
      "bbox": [
        -3.104372002495654,
        41.46254830548801,
        -3.104372002495654,
        41.46254830548801
      ]
    },
    "42085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1948347633340193,
              41.71691384432931
            ]
          },
          "bbox": [
            -3.1948347633340193,
            41.71691384432931,
            -3.1948347633340193,
            41.71691384432931
          ]
        }
      ],
      "bbox": [
        -3.1948347633340193,
        41.71691384432931,
        -3.1948347633340193,
        41.71691384432931
      ]
    },
    "42086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3381666892692565,
              41.4920505446273
            ]
          },
          "bbox": [
            -3.3381666892692565,
            41.4920505446273,
            -3.3381666892692565,
            41.4920505446273
          ]
        }
      ],
      "bbox": [
        -3.3381666892692565,
        41.4920505446273,
        -3.3381666892692565,
        41.4920505446273
      ]
    },
    "42087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4337468980456793,
              41.84349360878713
            ]
          },
          "bbox": [
            -2.4337468980456793,
            41.84349360878713,
            -2.4337468980456793,
            41.84349360878713
          ]
        }
      ],
      "bbox": [
        -2.4337468980456793,
        41.84349360878713,
        -2.4337468980456793,
        41.84349360878713
      ]
    },
    "42088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.176053076553332,
              41.41198338905277
            ]
          },
          "bbox": [
            -2.176053076553332,
            41.41198338905277,
            -2.176053076553332,
            41.41198338905277
          ]
        }
      ],
      "bbox": [
        -2.176053076553332,
        41.41198338905277,
        -2.176053076553332,
        41.41198338905277
      ]
    },
    "42089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.420201153767841,
              41.86832121342075
            ]
          },
          "bbox": [
            -2.420201153767841,
            41.86832121342075,
            -2.420201153767841,
            41.86832121342075
          ]
        }
      ],
      "bbox": [
        -2.420201153767841,
        41.86832121342075,
        -2.420201153767841,
        41.86832121342075
      ]
    },
    "42090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7443438114825662,
              41.55647506684797
            ]
          },
          "bbox": [
            -2.7443438114825662,
            41.55647506684797,
            -2.7443438114825662,
            41.55647506684797
          ]
        }
      ],
      "bbox": [
        -2.7443438114825662,
        41.55647506684797,
        -2.7443438114825662,
        41.55647506684797
      ]
    },
    "42092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.192531870010678,
              41.93267737283474
            ]
          },
          "bbox": [
            -2.192531870010678,
            41.93267737283474,
            -2.192531870010678,
            41.93267737283474
          ]
        }
      ],
      "bbox": [
        -2.192531870010678,
        41.93267737283474,
        -2.192531870010678,
        41.93267737283474
      ]
    },
    "42093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0890546490091215,
              41.86853386973357
            ]
          },
          "bbox": [
            -2.0890546490091215,
            41.86853386973357,
            -2.0890546490091215,
            41.86853386973357
          ]
        }
      ],
      "bbox": [
        -2.0890546490091215,
        41.86853386973357,
        -2.0890546490091215,
        41.86853386973357
      ]
    },
    "42094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5046698361890374,
              41.848065961078916
            ]
          },
          "bbox": [
            -2.5046698361890374,
            41.848065961078916,
            -2.5046698361890374,
            41.848065961078916
          ]
        }
      ],
      "bbox": [
        -2.5046698361890374,
        41.848065961078916,
        -2.5046698361890374,
        41.848065961078916
      ]
    },
    "42095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.656222870158568,
              41.717613118781884
            ]
          },
          "bbox": [
            -2.656222870158568,
            41.717613118781884,
            -2.656222870158568,
            41.717613118781884
          ]
        }
      ],
      "bbox": [
        -2.656222870158568,
        41.717613118781884,
        -2.656222870158568,
        41.717613118781884
      ]
    },
    "42096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2283797489995116,
              41.60180437575823
            ]
          },
          "bbox": [
            -2.2283797489995116,
            41.60180437575823,
            -2.2283797489995116,
            41.60180437575823
          ]
        }
      ],
      "bbox": [
        -2.2283797489995116,
        41.60180437575823,
        -2.2283797489995116,
        41.60180437575823
      ]
    },
    "42097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.008103662660423,
              41.508965543332
            ]
          },
          "bbox": [
            -3.008103662660423,
            41.508965543332,
            -3.008103662660423,
            41.508965543332
          ]
        }
      ],
      "bbox": [
        -3.008103662660423,
        41.508965543332,
        -3.008103662660423,
        41.508965543332
      ]
    },
    "42098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.027339528801182,
              41.76300080817684
            ]
          },
          "bbox": [
            -3.027339528801182,
            41.76300080817684,
            -3.027339528801182,
            41.76300080817684
          ]
        }
      ],
      "bbox": [
        -3.027339528801182,
        41.76300080817684,
        -3.027339528801182,
        41.76300080817684
      ]
    },
    "42100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.081403062871523,
              41.743265030158696
            ]
          },
          "bbox": [
            -2.081403062871523,
            41.743265030158696,
            -2.081403062871523,
            41.743265030158696
          ]
        }
      ],
      "bbox": [
        -2.081403062871523,
        41.743265030158696,
        -2.081403062871523,
        41.743265030158696
      ]
    },
    "42103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.376336555189619,
              41.611438944427626
            ]
          },
          "bbox": [
            -3.376336555189619,
            41.611438944427626,
            -3.376336555189619,
            41.611438944427626
          ]
        }
      ],
      "bbox": [
        -3.376336555189619,
        41.611438944427626,
        -3.376336555189619,
        41.611438944427626
      ]
    },
    "42105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2379271605485305,
              41.39184059276323
            ]
          },
          "bbox": [
            -3.2379271605485305,
            41.39184059276323,
            -3.2379271605485305,
            41.39184059276323
          ]
        }
      ],
      "bbox": [
        -3.2379271605485305,
        41.39184059276323,
        -3.2379271605485305,
        41.39184059276323
      ]
    },
    "42106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2702821112086804,
              41.87277748554202
            ]
          },
          "bbox": [
            -2.2702821112086804,
            41.87277748554202,
            -2.2702821112086804,
            41.87277748554202
          ]
        }
      ],
      "bbox": [
        -2.2702821112086804,
        41.87277748554202,
        -2.2702821112086804,
        41.87277748554202
      ]
    },
    "42107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.178504921158988,
              41.89404728335629
            ]
          },
          "bbox": [
            -2.178504921158988,
            41.89404728335629,
            -2.178504921158988,
            41.89404728335629
          ]
        }
      ],
      "bbox": [
        -2.178504921158988,
        41.89404728335629,
        -2.178504921158988,
        41.89404728335629
      ]
    },
    "42108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2974808727961284,
              41.466442227462764
            ]
          },
          "bbox": [
            -2.2974808727961284,
            41.466442227462764,
            -2.2974808727961284,
            41.466442227462764
          ]
        }
      ],
      "bbox": [
        -2.2974808727961284,
        41.466442227462764,
        -2.2974808727961284,
        41.466442227462764
      ]
    },
    "42110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.060721636262338,
              41.831982909808595
            ]
          },
          "bbox": [
            -2.060721636262338,
            41.831982909808595,
            -2.060721636262338,
            41.831982909808595
          ]
        }
      ],
      "bbox": [
        -2.060721636262338,
        41.831982909808595,
        -2.060721636262338,
        41.831982909808595
      ]
    },
    "42111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.640613923964619,
              41.50908042673628
            ]
          },
          "bbox": [
            -2.640613923964619,
            41.50908042673628,
            -2.640613923964619,
            41.50908042673628
          ]
        }
      ],
      "bbox": [
        -2.640613923964619,
        41.50908042673628,
        -2.640613923964619,
        41.50908042673628
      ]
    },
    "42113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4293796631899394,
              41.160907643100494
            ]
          },
          "bbox": [
            -2.4293796631899394,
            41.160907643100494,
            -2.4293796631899394,
            41.160907643100494
          ]
        }
      ],
      "bbox": [
        -2.4293796631899394,
        41.160907643100494,
        -2.4293796631899394,
        41.160907643100494
      ]
    },
    "42115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5427414022796127,
              41.176664995427096
            ]
          },
          "bbox": [
            -2.5427414022796127,
            41.176664995427096,
            -2.5427414022796127,
            41.176664995427096
          ]
        }
      ],
      "bbox": [
        -2.5427414022796127,
        41.176664995427096,
        -2.5427414022796127,
        41.176664995427096
      ]
    },
    "42116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3518251327229045,
              41.54942676530764
            ]
          },
          "bbox": [
            -3.3518251327229045,
            41.54942676530764,
            -3.3518251327229045,
            41.54942676530764
          ]
        }
      ],
      "bbox": [
        -3.3518251327229045,
        41.54942676530764,
        -3.3518251327229045,
        41.54942676530764
      ]
    },
    "42117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.825882714235607,
              41.881780643079026
            ]
          },
          "bbox": [
            -2.825882714235607,
            41.881780643079026,
            -2.825882714235607,
            41.881780643079026
          ]
        }
      ],
      "bbox": [
        -2.825882714235607,
        41.881780643079026,
        -2.825882714235607,
        41.881780643079026
      ]
    },
    "42118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3373246167303106,
              41.44513285151403
            ]
          },
          "bbox": [
            -2.3373246167303106,
            41.44513285151403,
            -2.3373246167303106,
            41.44513285151403
          ]
        }
      ],
      "bbox": [
        -2.3373246167303106,
        41.44513285151403,
        -2.3373246167303106,
        41.44513285151403
      ]
    },
    "42119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2046192130986713,
              41.36734289456558
            ]
          },
          "bbox": [
            -2.2046192130986713,
            41.36734289456558,
            -2.2046192130986713,
            41.36734289456558
          ]
        }
      ],
      "bbox": [
        -2.2046192130986713,
        41.36734289456558,
        -2.2046192130986713,
        41.36734289456558
      ]
    },
    "42120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.20466726286771,
              41.36988947313822
            ]
          },
          "bbox": [
            -3.20466726286771,
            41.36988947313822,
            -3.20466726286771,
            41.36988947313822
          ]
        }
      ],
      "bbox": [
        -3.20466726286771,
        41.36988947313822,
        -3.20466726286771,
        41.36988947313822
      ]
    },
    "42121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7573379727116896,
              42.08225536165417
            ]
          },
          "bbox": [
            -2.7573379727116896,
            42.08225536165417,
            -2.7573379727116896,
            42.08225536165417
          ]
        }
      ],
      "bbox": [
        -2.7573379727116896,
        42.08225536165417,
        -2.7573379727116896,
        42.08225536165417
      ]
    },
    "42123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4061884807528906,
              41.39977773330491
            ]
          },
          "bbox": [
            -2.4061884807528906,
            41.39977773330491,
            -2.4061884807528906,
            41.39977773330491
          ]
        }
      ],
      "bbox": [
        -2.4061884807528906,
        41.39977773330491,
        -2.4061884807528906,
        41.39977773330491
      ]
    },
    "42124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8685268333666545,
              41.72332784548163
            ]
          },
          "bbox": [
            -2.8685268333666545,
            41.72332784548163,
            -2.8685268333666545,
            41.72332784548163
          ]
        }
      ],
      "bbox": [
        -2.8685268333666545,
        41.72332784548163,
        -2.8685268333666545,
        41.72332784548163
      ]
    },
    "42125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9140863537995707,
              41.77952549795439
            ]
          },
          "bbox": [
            -2.9140863537995707,
            41.77952549795439,
            -2.9140863537995707,
            41.77952549795439
          ]
        }
      ],
      "bbox": [
        -2.9140863537995707,
        41.77952549795439,
        -2.9140863537995707,
        41.77952549795439
      ]
    },
    "42127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.106098530535035,
              41.716449060996766
            ]
          },
          "bbox": [
            -3.106098530535035,
            41.716449060996766,
            -3.106098530535035,
            41.716449060996766
          ]
        }
      ],
      "bbox": [
        -3.106098530535035,
        41.716449060996766,
        -3.106098530535035,
        41.716449060996766
      ]
    },
    "42128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2853224359148667,
              41.85005372048686
            ]
          },
          "bbox": [
            -2.2853224359148667,
            41.85005372048686,
            -2.2853224359148667,
            41.85005372048686
          ]
        }
      ],
      "bbox": [
        -2.2853224359148667,
        41.85005372048686,
        -2.2853224359148667,
        41.85005372048686
      ]
    },
    "42129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.007952177524168,
              41.85214184723725
            ]
          },
          "bbox": [
            -3.007952177524168,
            41.85214184723725,
            -3.007952177524168,
            41.85214184723725
          ]
        }
      ],
      "bbox": [
        -3.007952177524168,
        41.85214184723725,
        -3.007952177524168,
        41.85214184723725
      ]
    },
    "42130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.40392373795072,
              41.52084101858118
            ]
          },
          "bbox": [
            -2.40392373795072,
            41.52084101858118,
            -2.40392373795072,
            41.52084101858118
          ]
        }
      ],
      "bbox": [
        -2.40392373795072,
        41.52084101858118,
        -2.40392373795072,
        41.52084101858118
      ]
    },
    "42131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3394723801107515,
              41.52238450927877
            ]
          },
          "bbox": [
            -2.3394723801107515,
            41.52238450927877,
            -2.3394723801107515,
            41.52238450927877
          ]
        }
      ],
      "bbox": [
        -2.3394723801107515,
        41.52238450927877,
        -2.3394723801107515,
        41.52238450927877
      ]
    },
    "42132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0046048178207743,
              41.70172564940488
            ]
          },
          "bbox": [
            -2.0046048178207743,
            41.70172564940488,
            -2.0046048178207743,
            41.70172564940488
          ]
        }
      ],
      "bbox": [
        -2.0046048178207743,
        41.70172564940488,
        -2.0046048178207743,
        41.70172564940488
      ]
    },
    "42134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9789802306045943,
              41.782138353065235
            ]
          },
          "bbox": [
            -1.9789802306045943,
            41.782138353065235,
            -1.9789802306045943,
            41.782138353065235
          ]
        }
      ],
      "bbox": [
        -1.9789802306045943,
        41.782138353065235,
        -1.9789802306045943,
        41.782138353065235
      ]
    },
    "42135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2850296876321776,
              41.971925533401304
            ]
          },
          "bbox": [
            -2.2850296876321776,
            41.971925533401304,
            -2.2850296876321776,
            41.971925533401304
          ]
        }
      ],
      "bbox": [
        -2.2850296876321776,
        41.971925533401304,
        -2.2850296876321776,
        41.971925533401304
      ]
    },
    "42139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0953490871622424,
              41.70938266271457
            ]
          },
          "bbox": [
            -2.0953490871622424,
            41.70938266271457,
            -2.0953490871622424,
            41.70938266271457
          ]
        }
      ],
      "bbox": [
        -2.0953490871622424,
        41.70938266271457,
        -2.0953490871622424,
        41.70938266271457
      ]
    },
    "42140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1155094272422574,
              41.629257038086195
            ]
          },
          "bbox": [
            -2.1155094272422574,
            41.629257038086195,
            -2.1155094272422574,
            41.629257038086195
          ]
        }
      ],
      "bbox": [
        -2.1155094272422574,
        41.629257038086195,
        -2.1155094272422574,
        41.629257038086195
      ]
    },
    "42141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5224009725678496,
              42.01526210929125
            ]
          },
          "bbox": [
            -2.5224009725678496,
            42.01526210929125,
            -2.5224009725678496,
            42.01526210929125
          ]
        }
      ],
      "bbox": [
        -2.5224009725678496,
        42.01526210929125,
        -2.5224009725678496,
        42.01526210929125
      ]
    },
    "42142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.103894727280976,
              41.77422382400921
            ]
          },
          "bbox": [
            -2.103894727280976,
            41.77422382400921,
            -2.103894727280976,
            41.77422382400921
          ]
        }
      ],
      "bbox": [
        -2.103894727280976,
        41.77422382400921,
        -2.103894727280976,
        41.77422382400921
      ]
    },
    "42144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6601377840267313,
              41.6352842289717
            ]
          },
          "bbox": [
            -2.6601377840267313,
            41.6352842289717,
            -2.6601377840267313,
            41.6352842289717
          ]
        }
      ],
      "bbox": [
        -2.6601377840267313,
        41.6352842289717,
        -2.6601377840267313,
        41.6352842289717
      ]
    },
    "42145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9641938025902803,
              41.513095326544345
            ]
          },
          "bbox": [
            -2.9641938025902803,
            41.513095326544345,
            -2.9641938025902803,
            41.513095326544345
          ]
        }
      ],
      "bbox": [
        -2.9641938025902803,
        41.513095326544345,
        -2.9641938025902803,
        41.513095326544345
      ]
    },
    "42148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.054342566276802,
              41.55106196631365
            ]
          },
          "bbox": [
            -2.054342566276802,
            41.55106196631365,
            -2.054342566276802,
            41.55106196631365
          ]
        }
      ],
      "bbox": [
        -2.054342566276802,
        41.55106196631365,
        -2.054342566276802,
        41.55106196631365
      ]
    },
    "42149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4637640039353874,
              41.68155409990739
            ]
          },
          "bbox": [
            -2.4637640039353874,
            41.68155409990739,
            -2.4637640039353874,
            41.68155409990739
          ]
        }
      ],
      "bbox": [
        -2.4637640039353874,
        41.68155409990739,
        -2.4637640039353874,
        41.68155409990739
      ]
    },
    "42151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.513368560940442,
              41.90484760474832
            ]
          },
          "bbox": [
            -2.513368560940442,
            41.90484760474832,
            -2.513368560940442,
            41.90484760474832
          ]
        }
      ],
      "bbox": [
        -2.513368560940442,
        41.90484760474832,
        -2.513368560940442,
        41.90484760474832
      ]
    },
    "42152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0024459397726027,
              41.43554417307171
            ]
          },
          "bbox": [
            -3.0024459397726027,
            41.43554417307171,
            -3.0024459397726027,
            41.43554417307171
          ]
        }
      ],
      "bbox": [
        -3.0024459397726027,
        41.43554417307171,
        -3.0024459397726027,
        41.43554417307171
      ]
    },
    "42153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7462050480437776,
              41.33897345517902
            ]
          },
          "bbox": [
            -2.7462050480437776,
            41.33897345517902,
            -2.7462050480437776,
            41.33897345517902
          ]
        }
      ],
      "bbox": [
        -2.7462050480437776,
        41.33897345517902,
        -2.7462050480437776,
        41.33897345517902
      ]
    },
    "42154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3628204594892126,
              41.79887485146208
            ]
          },
          "bbox": [
            -2.3628204594892126,
            41.79887485146208,
            -2.3628204594892126,
            41.79887485146208
          ]
        }
      ],
      "bbox": [
        -2.3628204594892126,
        41.79887485146208,
        -2.3628204594892126,
        41.79887485146208
      ]
    },
    "42155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0290439247505985,
              41.3304515570503
            ]
          },
          "bbox": [
            -3.0290439247505985,
            41.3304515570503,
            -3.0290439247505985,
            41.3304515570503
          ]
        }
      ],
      "bbox": [
        -3.0290439247505985,
        41.3304515570503,
        -3.0290439247505985,
        41.3304515570503
      ]
    },
    "42156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0171419546853553,
              41.59546880028811
            ]
          },
          "bbox": [
            -2.0171419546853553,
            41.59546880028811,
            -2.0171419546853553,
            41.59546880028811
          ]
        }
      ],
      "bbox": [
        -2.0171419546853553,
        41.59546880028811,
        -2.0171419546853553,
        41.59546880028811
      ]
    },
    "42157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7979515231354526,
              41.35329711383478
            ]
          },
          "bbox": [
            -2.7979515231354526,
            41.35329711383478,
            -2.7979515231354526,
            41.35329711383478
          ]
        }
      ],
      "bbox": [
        -2.7979515231354526,
        41.35329711383478,
        -2.7979515231354526,
        41.35329711383478
      ]
    },
    "42158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.851092049883667,
              41.63657460488308
            ]
          },
          "bbox": [
            -2.851092049883667,
            41.63657460488308,
            -2.851092049883667,
            41.63657460488308
          ]
        }
      ],
      "bbox": [
        -2.851092049883667,
        41.63657460488308,
        -2.851092049883667,
        41.63657460488308
      ]
    },
    "42159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.531665774666612,
              41.93939959520154
            ]
          },
          "bbox": [
            -2.531665774666612,
            41.93939959520154,
            -2.531665774666612,
            41.93939959520154
          ]
        }
      ],
      "bbox": [
        -2.531665774666612,
        41.93939959520154,
        -2.531665774666612,
        41.93939959520154
      ]
    },
    "42160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.668527378057311,
              41.924552294370294
            ]
          },
          "bbox": [
            -2.668527378057311,
            41.924552294370294,
            -2.668527378057311,
            41.924552294370294
          ]
        }
      ],
      "bbox": [
        -2.668527378057311,
        41.924552294370294,
        -2.668527378057311,
        41.924552294370294
      ]
    },
    "42161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.808117462015027,
              41.90042632440273
            ]
          },
          "bbox": [
            -2.808117462015027,
            41.90042632440273,
            -2.808117462015027,
            41.90042632440273
          ]
        }
      ],
      "bbox": [
        -2.808117462015027,
        41.90042632440273,
        -2.808117462015027,
        41.90042632440273
      ]
    },
    "42162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2200903494695114,
              41.53552330639191
            ]
          },
          "bbox": [
            -3.2200903494695114,
            41.53552330639191,
            -3.2200903494695114,
            41.53552330639191
          ]
        }
      ],
      "bbox": [
        -3.2200903494695114,
        41.53552330639191,
        -3.2200903494695114,
        41.53552330639191
      ]
    },
    "42163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.012700582613284,
              41.91801684450936
            ]
          },
          "bbox": [
            -2.012700582613284,
            41.91801684450936,
            -2.012700582613284,
            41.91801684450936
          ]
        }
      ],
      "bbox": [
        -2.012700582613284,
        41.91801684450936,
        -2.012700582613284,
        41.91801684450936
      ]
    },
    "42164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.081337795399034,
              41.83525726563827
            ]
          },
          "bbox": [
            -3.081337795399034,
            41.83525726563827,
            -3.081337795399034,
            41.83525726563827
          ]
        }
      ],
      "bbox": [
        -3.081337795399034,
        41.83525726563827,
        -3.081337795399034,
        41.83525726563827
      ]
    },
    "42165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2079098499136016,
              42.036327644389544
            ]
          },
          "bbox": [
            -2.2079098499136016,
            42.036327644389544,
            -2.2079098499136016,
            42.036327644389544
          ]
        }
      ],
      "bbox": [
        -2.2079098499136016,
        42.036327644389544,
        -2.2079098499136016,
        42.036327644389544
      ]
    },
    "42166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.472759879509985,
              42.06478257809652
            ]
          },
          "bbox": [
            -2.472759879509985,
            42.06478257809652,
            -2.472759879509985,
            42.06478257809652
          ]
        }
      ],
      "bbox": [
        -2.472759879509985,
        42.06478257809652,
        -2.472759879509985,
        42.06478257809652
      ]
    },
    "42167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.161544759306509,
              41.224233649000325
            ]
          },
          "bbox": [
            -2.161544759306509,
            41.224233649000325,
            -2.161544759306509,
            41.224233649000325
          ]
        }
      ],
      "bbox": [
        -2.161544759306509,
        41.224233649000325,
        -2.161544759306509,
        41.224233649000325
      ]
    },
    "42168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.14003300718265,
              41.76996821273847
            ]
          },
          "bbox": [
            -3.14003300718265,
            41.76996821273847,
            -3.14003300718265,
            41.76996821273847
          ]
        }
      ],
      "bbox": [
        -3.14003300718265,
        41.76996821273847,
        -3.14003300718265,
        41.76996821273847
      ]
    },
    "42171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.194886081597819,
              41.502986588798436
            ]
          },
          "bbox": [
            -2.194886081597819,
            41.502986588798436,
            -2.194886081597819,
            41.502986588798436
          ]
        }
      ],
      "bbox": [
        -2.194886081597819,
        41.502986588798436,
        -2.194886081597819,
        41.502986588798436
      ]
    },
    "42172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.390393416728751,
              41.45817308676119
            ]
          },
          "bbox": [
            -2.390393416728751,
            41.45817308676119,
            -2.390393416728751,
            41.45817308676119
          ]
        }
      ],
      "bbox": [
        -2.390393416728751,
        41.45817308676119,
        -2.390393416728751,
        41.45817308676119
      ]
    },
    "42173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6803410061197117,
              41.82231840309631
            ]
          },
          "bbox": [
            -2.6803410061197117,
            41.82231840309631,
            -2.6803410061197117,
            41.82231840309631
          ]
        }
      ],
      "bbox": [
        -2.6803410061197117,
        41.82231840309631,
        -2.6803410061197117,
        41.82231840309631
      ]
    },
    "42174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6224733346811817,
              41.963871134690706
            ]
          },
          "bbox": [
            -2.6224733346811817,
            41.963871134690706,
            -2.6224733346811817,
            41.963871134690706
          ]
        }
      ],
      "bbox": [
        -2.6224733346811817,
        41.963871134690706,
        -2.6224733346811817,
        41.963871134690706
      ]
    },
    "42175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2056873942306106,
              41.84984951846876
            ]
          },
          "bbox": [
            -2.2056873942306106,
            41.84984951846876,
            -2.2056873942306106,
            41.84984951846876
          ]
        }
      ],
      "bbox": [
        -2.2056873942306106,
        41.84984951846876,
        -2.2056873942306106,
        41.84984951846876
      ]
    },
    "42176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1649434963442626,
              41.749985653136996
            ]
          },
          "bbox": [
            -2.1649434963442626,
            41.749985653136996,
            -2.1649434963442626,
            41.749985653136996
          ]
        }
      ],
      "bbox": [
        -2.1649434963442626,
        41.749985653136996,
        -2.1649434963442626,
        41.749985653136996
      ]
    },
    "42177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.845404261154495,
              41.53756532319723
            ]
          },
          "bbox": [
            -2.845404261154495,
            41.53756532319723,
            -2.845404261154495,
            41.53756532319723
          ]
        }
      ],
      "bbox": [
        -2.845404261154495,
        41.53756532319723,
        -2.845404261154495,
        41.53756532319723
      ]
    },
    "42178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9694825635860242,
              41.74111092041118
            ]
          },
          "bbox": [
            -2.9694825635860242,
            41.74111092041118,
            -2.9694825635860242,
            41.74111092041118
          ]
        }
      ],
      "bbox": [
        -2.9694825635860242,
        41.74111092041118,
        -2.9694825635860242,
        41.74111092041118
      ]
    },
    "42181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.643924599495906,
              41.58276371885714
            ]
          },
          "bbox": [
            -2.643924599495906,
            41.58276371885714,
            -2.643924599495906,
            41.58276371885714
          ]
        }
      ],
      "bbox": [
        -2.643924599495906,
        41.58276371885714,
        -2.643924599495906,
        41.58276371885714
      ]
    },
    "42182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4190045491285823,
              41.33320430574288
            ]
          },
          "bbox": [
            -2.4190045491285823,
            41.33320430574288,
            -2.4190045491285823,
            41.33320430574288
          ]
        }
      ],
      "bbox": [
        -2.4190045491285823,
        41.33320430574288,
        -2.4190045491285823,
        41.33320430574288
      ]
    },
    "42183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.301821603002833,
              41.57942589553344
            ]
          },
          "bbox": [
            -2.301821603002833,
            41.57942589553344,
            -2.301821603002833,
            41.57942589553344
          ]
        }
      ],
      "bbox": [
        -2.301821603002833,
        41.57942589553344,
        -2.301821603002833,
        41.57942589553344
      ]
    },
    "42184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.143305315674678,
              41.459743417073994
            ]
          },
          "bbox": [
            -2.143305315674678,
            41.459743417073994,
            -2.143305315674678,
            41.459743417073994
          ]
        }
      ],
      "bbox": [
        -2.143305315674678,
        41.459743417073994,
        -2.143305315674678,
        41.459743417073994
      ]
    },
    "42185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8987303922234737,
              41.67741652094905
            ]
          },
          "bbox": [
            -2.8987303922234737,
            41.67741652094905,
            -2.8987303922234737,
            41.67741652094905
          ]
        }
      ],
      "bbox": [
        -2.8987303922234737,
        41.67741652094905,
        -2.8987303922234737,
        41.67741652094905
      ]
    },
    "42187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.064677423253811,
              41.623824723927825
            ]
          },
          "bbox": [
            -2.064677423253811,
            41.623824723927825,
            -2.064677423253811,
            41.623824723927825
          ]
        }
      ],
      "bbox": [
        -2.064677423253811,
        41.623824723927825,
        -2.064677423253811,
        41.623824723927825
      ]
    },
    "42188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.117748397706611,
              41.85918291337693
            ]
          },
          "bbox": [
            -2.117748397706611,
            41.85918291337693,
            -2.117748397706611,
            41.85918291337693
          ]
        }
      ],
      "bbox": [
        -2.117748397706611,
        41.85918291337693,
        -2.117748397706611,
        41.85918291337693
      ]
    },
    "42189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0579823676583455,
              41.72116227544579
            ]
          },
          "bbox": [
            -3.0579823676583455,
            41.72116227544579,
            -3.0579823676583455,
            41.72116227544579
          ]
        }
      ],
      "bbox": [
        -3.0579823676583455,
        41.72116227544579,
        -3.0579823676583455,
        41.72116227544579
      ]
    },
    "42190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9986820508306327,
              41.806610423199366
            ]
          },
          "bbox": [
            -2.9986820508306327,
            41.806610423199366,
            -2.9986820508306327,
            41.806610423199366
          ]
        }
      ],
      "bbox": [
        -2.9986820508306327,
        41.806610423199366,
        -2.9986820508306327,
        41.806610423199366
      ]
    },
    "42191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5682701359434406,
              41.95113522065576
            ]
          },
          "bbox": [
            -2.5682701359434406,
            41.95113522065576,
            -2.5682701359434406,
            41.95113522065576
          ]
        }
      ],
      "bbox": [
        -2.5682701359434406,
        41.95113522065576,
        -2.5682701359434406,
        41.95113522065576
      ]
    },
    "42192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1647617088739404,
              41.823140043176366
            ]
          },
          "bbox": [
            -2.1647617088739404,
            41.823140043176366,
            -2.1647617088739404,
            41.823140043176366
          ]
        }
      ],
      "bbox": [
        -2.1647617088739404,
        41.823140043176366,
        -2.1647617088739404,
        41.823140043176366
      ]
    },
    "42193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.108729472714247,
              41.89052005372431
            ]
          },
          "bbox": [
            -2.108729472714247,
            41.89052005372431,
            -2.108729472714247,
            41.89052005372431
          ]
        }
      ],
      "bbox": [
        -2.108729472714247,
        41.89052005372431,
        -2.108729472714247,
        41.89052005372431
      ]
    },
    "42194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.032537030284061,
              41.68602775033081
            ]
          },
          "bbox": [
            -3.032537030284061,
            41.68602775033081,
            -3.032537030284061,
            41.68602775033081
          ]
        }
      ],
      "bbox": [
        -3.032537030284061,
        41.68602775033081,
        -3.032537030284061,
        41.68602775033081
      ]
    },
    "42195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.927756398898791,
              41.58477791333663
            ]
          },
          "bbox": [
            -2.927756398898791,
            41.58477791333663,
            -2.927756398898791,
            41.58477791333663
          ]
        }
      ],
      "bbox": [
        -2.927756398898791,
        41.58477791333663,
        -2.927756398898791,
        41.58477791333663
      ]
    },
    "42196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.134584185494817,
              41.95723118482406
            ]
          },
          "bbox": [
            -2.134584185494817,
            41.95723118482406,
            -2.134584185494817,
            41.95723118482406
          ]
        }
      ],
      "bbox": [
        -2.134584185494817,
        41.95723118482406,
        -2.134584185494817,
        41.95723118482406
      ]
    },
    "42197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8191301033057408,
              41.575134986448084
            ]
          },
          "bbox": [
            -2.8191301033057408,
            41.575134986448084,
            -2.8191301033057408,
            41.575134986448084
          ]
        }
      ],
      "bbox": [
        -2.8191301033057408,
        41.575134986448084,
        -2.8191301033057408,
        41.575134986448084
      ]
    },
    "42198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.235836333099974,
              41.93923047324356
            ]
          },
          "bbox": [
            -2.235836333099974,
            41.93923047324356,
            -2.235836333099974,
            41.93923047324356
          ]
        }
      ],
      "bbox": [
        -2.235836333099974,
        41.93923047324356,
        -2.235836333099974,
        41.93923047324356
      ]
    },
    "42200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.725806575504612,
              41.46114983411628
            ]
          },
          "bbox": [
            -2.725806575504612,
            41.46114983411628,
            -2.725806575504612,
            41.46114983411628
          ]
        }
      ],
      "bbox": [
        -2.725806575504612,
        41.46114983411628,
        -2.725806575504612,
        41.46114983411628
      ]
    },
    "42201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4093887736292414,
              41.79716549879053
            ]
          },
          "bbox": [
            -2.4093887736292414,
            41.79716549879053,
            -2.4093887736292414,
            41.79716549879053
          ]
        }
      ],
      "bbox": [
        -2.4093887736292414,
        41.79716549879053,
        -2.4093887736292414,
        41.79716549879053
      ]
    },
    "42202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.273531976654139,
              41.49569074321667
            ]
          },
          "bbox": [
            -2.273531976654139,
            41.49569074321667,
            -2.273531976654139,
            41.49569074321667
          ]
        }
      ],
      "bbox": [
        -2.273531976654139,
        41.49569074321667,
        -2.273531976654139,
        41.49569074321667
      ]
    },
    "42204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.459640553206809,
              41.52112377809894
            ]
          },
          "bbox": [
            -2.459640553206809,
            41.52112377809894,
            -2.459640553206809,
            41.52112377809894
          ]
        }
      ],
      "bbox": [
        -2.459640553206809,
        41.52112377809894,
        -2.459640553206809,
        41.52112377809894
      ]
    },
    "42205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6667890563524557,
              41.76927969811932
            ]
          },
          "bbox": [
            -2.6667890563524557,
            41.76927969811932,
            -2.6667890563524557,
            41.76927969811932
          ]
        }
      ],
      "bbox": [
        -2.6667890563524557,
        41.76927969811932,
        -2.6667890563524557,
        41.76927969811932
      ]
    },
    "42206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.055096601292021,
              41.4658806855319
            ]
          },
          "bbox": [
            -3.055096601292021,
            41.4658806855319,
            -3.055096601292021,
            41.4658806855319
          ]
        }
      ],
      "bbox": [
        -3.055096601292021,
        41.4658806855319,
        -3.055096601292021,
        41.4658806855319
      ]
    },
    "42207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5657374396452943,
              41.90854669690395
            ]
          },
          "bbox": [
            -2.5657374396452943,
            41.90854669690395,
            -2.5657374396452943,
            41.90854669690395
          ]
        }
      ],
      "bbox": [
        -2.5657374396452943,
        41.90854669690395,
        -2.5657374396452943,
        41.90854669690395
      ]
    },
    "42208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.137065103108983,
              41.804836277479524
            ]
          },
          "bbox": [
            -2.137065103108983,
            41.804836277479524,
            -2.137065103108983,
            41.804836277479524
          ]
        }
      ],
      "bbox": [
        -2.137065103108983,
        41.804836277479524,
        -2.137065103108983,
        41.804836277479524
      ]
    },
    "42209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.38204584982237,
              42.071082866038225
            ]
          },
          "bbox": [
            -2.38204584982237,
            42.071082866038225,
            -2.38204584982237,
            42.071082866038225
          ]
        }
      ],
      "bbox": [
        -2.38204584982237,
        42.071082866038225,
        -2.38204584982237,
        42.071082866038225
      ]
    },
    "42211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.365848884324258,
              41.874331205999475
            ]
          },
          "bbox": [
            -2.365848884324258,
            41.874331205999475,
            -2.365848884324258,
            41.874331205999475
          ]
        }
      ],
      "bbox": [
        -2.365848884324258,
        41.874331205999475,
        -2.365848884324258,
        41.874331205999475
      ]
    },
    "42212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6301322424919844,
              41.36039518859475
            ]
          },
          "bbox": [
            -2.6301322424919844,
            41.36039518859475,
            -2.6301322424919844,
            41.36039518859475
          ]
        }
      ],
      "bbox": [
        -2.6301322424919844,
        41.36039518859475,
        -2.6301322424919844,
        41.36039518859475
      ]
    },
    "42213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.151255835743223,
              41.61826649725984
            ]
          },
          "bbox": [
            -2.151255835743223,
            41.61826649725984,
            -2.151255835743223,
            41.61826649725984
          ]
        }
      ],
      "bbox": [
        -2.151255835743223,
        41.61826649725984,
        -2.151255835743223,
        41.61826649725984
      ]
    },
    "42215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7738272482277497,
              41.95289513208203
            ]
          },
          "bbox": [
            -2.7738272482277497,
            41.95289513208203,
            -2.7738272482277497,
            41.95289513208203
          ]
        }
      ],
      "bbox": [
        -2.7738272482277497,
        41.95289513208203,
        -2.7738272482277497,
        41.95289513208203
      ]
    },
    "42216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4230259216275587,
              42.02453651953278
            ]
          },
          "bbox": [
            -2.4230259216275587,
            42.02453651953278,
            -2.4230259216275587,
            42.02453651953278
          ]
        }
      ],
      "bbox": [
        -2.4230259216275587,
        42.02453651953278,
        -2.4230259216275587,
        42.02453651953278
      ]
    },
    "42217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8417155227094208,
              41.840184822338664
            ]
          },
          "bbox": [
            -1.8417155227094208,
            41.840184822338664,
            -1.8417155227094208,
            41.840184822338664
          ]
        }
      ],
      "bbox": [
        -1.8417155227094208,
        41.840184822338664,
        -1.8417155227094208,
        41.840184822338664
      ]
    },
    "42218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.336262711065595,
              42.11548323164777
            ]
          },
          "bbox": [
            -2.336262711065595,
            42.11548323164777,
            -2.336262711065595,
            42.11548323164777
          ]
        }
      ],
      "bbox": [
        -2.336262711065595,
        42.11548323164777,
        -2.336262711065595,
        42.11548323164777
      ]
    },
    "42219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5410639216165567,
              41.218853760110854
            ]
          },
          "bbox": [
            -2.5410639216165567,
            41.218853760110854,
            -2.5410639216165567,
            41.218853760110854
          ]
        }
      ],
      "bbox": [
        -2.5410639216165567,
        41.218853760110854,
        -2.5410639216165567,
        41.218853760110854
      ]
    },
    "43001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4239589487442155,
              41.36292065069239
            ]
          },
          "bbox": [
            1.4239589487442155,
            41.36292065069239,
            1.4239589487442155,
            41.36292065069239
          ]
        }
      ],
      "bbox": [
        1.4239589487442155,
        41.36292065069239,
        1.4239589487442155,
        41.36292065069239
      ]
    },
    "43002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4924952098401618,
              41.24209021445784
            ]
          },
          "bbox": [
            1.4924952098401618,
            41.24209021445784,
            1.4924952098401618,
            41.24209021445784
          ]
        }
      ],
      "bbox": [
        1.4924952098401618,
        41.24209021445784,
        1.4924952098401618,
        41.24209021445784
      ]
    },
    "43004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5203673532196851,
              40.57366075958505
            ]
          },
          "bbox": [
            0.5203673532196851,
            40.57366075958505,
            0.5203673532196851,
            40.57366075958505
          ]
        }
      ],
      "bbox": [
        0.5203673532196851,
        40.57366075958505,
        0.5203673532196851,
        40.57366075958505
      ]
    },
    "43005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1671797961904775,
              41.26558421447831
            ]
          },
          "bbox": [
            1.1671797961904775,
            41.26558421447831,
            1.1671797961904775,
            41.26558421447831
          ]
        }
      ],
      "bbox": [
        1.1671797961904775,
        41.26558421447831,
        1.1671797961904775,
        41.26558421447831
      ]
    },
    "43006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.47438797579769115,
              40.866656258930874
            ]
          },
          "bbox": [
            0.47438797579769115,
            40.866656258930874,
            0.47438797579769115,
            40.866656258930874
          ]
        }
      ],
      "bbox": [
        0.47438797579769115,
        40.866656258930874,
        0.47438797579769115,
        40.866656258930874
      ]
    },
    "43008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.368297440729407,
              40.85399967326904
            ]
          },
          "bbox": [
            0.368297440729407,
            40.85399967326904,
            0.368297440729407,
            40.85399967326904
          ]
        }
      ],
      "bbox": [
        0.368297440729407,
        40.85399967326904,
        0.368297440729407,
        40.85399967326904
      ]
    },
    "43009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9614136002902298,
              41.205225425327725
            ]
          },
          "bbox": [
            0.9614136002902298,
            41.205225425327725,
            0.9614136002902298,
            41.205225425327725
          ]
        }
      ],
      "bbox": [
        0.9614136002902298,
        41.205225425327725,
        0.9614136002902298,
        41.205225425327725
      ]
    },
    "43010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.306778239008229,
              41.29747171116988
            ]
          },
          "bbox": [
            1.306778239008229,
            41.29747171116988,
            1.306778239008229,
            41.29747171116988
          ]
        }
      ],
      "bbox": [
        1.306778239008229,
        41.29747171116988,
        1.306778239008229,
        41.29747171116988
      ]
    },
    "43011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1002728553644827,
              41.1998854661947
            ]
          },
          "bbox": [
            1.1002728553644827,
            41.1998854661947,
            1.1002728553644827,
            41.1998854661947
          ]
        }
      ],
      "bbox": [
        1.1002728553644827,
        41.1998854661947,
        1.1002728553644827,
        41.1998854661947
      ]
    },
    "43012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3802378305652894,
              41.15141873154569
            ]
          },
          "bbox": [
            1.3802378305652894,
            41.15141873154569,
            1.3802378305652894,
            41.15141873154569
          ]
        }
      ],
      "bbox": [
        1.3802378305652894,
        41.15141873154569,
        1.3802378305652894,
        41.15141873154569
      ]
    },
    "43014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6394900234487132,
              40.675903547129856
            ]
          },
          "bbox": [
            0.6394900234487132,
            40.675903547129856,
            0.6394900234487132,
            40.675903547129856
          ]
        }
      ],
      "bbox": [
        0.6394900234487132,
        40.675903547129856,
        0.6394900234487132,
        40.675903547129856
      ]
    },
    "43015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9562887151822891,
              41.238493849872604
            ]
          },
          "bbox": [
            0.9562887151822891,
            41.238493849872604,
            0.9562887151822891,
            41.238493849872604
          ]
        }
      ],
      "bbox": [
        0.9562887151822891,
        41.238493849872604,
        0.9562887151822891,
        41.238493849872604
      ]
    },
    "43018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.27564043562828316,
              40.876329349453044
            ]
          },
          "bbox": [
            0.27564043562828316,
            40.876329349453044,
            0.27564043562828316,
            40.876329349453044
          ]
        }
      ],
      "bbox": [
        0.27564043562828316,
        40.876329349453044,
        0.27564043562828316,
        40.876329349453044
      ]
    },
    "43019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5502531204450725,
              41.16439654412945
            ]
          },
          "bbox": [
            0.5502531204450725,
            41.16439654412945,
            0.5502531204450725,
            41.16439654412945
          ]
        }
      ],
      "bbox": [
        0.5502531204450725,
        41.16439654412945,
        0.5502531204450725,
        41.16439654412945
      ]
    },
    "43020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5594975122786203,
              41.27077282947113
            ]
          },
          "bbox": [
            1.5594975122786203,
            41.27077282947113,
            1.5594975122786203,
            41.27077282947113
          ]
        }
      ],
      "bbox": [
        1.5594975122786203,
        41.27077282947113,
        1.5594975122786203,
        41.27077282947113
      ]
    },
    "43021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.229346439402007,
              41.41102588490708
            ]
          },
          "bbox": [
            1.229346439402007,
            41.41102588490708,
            1.229346439402007,
            41.41102588490708
          ]
        }
      ],
      "bbox": [
        1.229346439402007,
        41.41102588490708,
        1.229346439402007,
        41.41102588490708
      ]
    },
    "43022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2954136372596835,
              41.10426947431897
            ]
          },
          "bbox": [
            0.2954136372596835,
            41.10426947431897,
            0.2954136372596835,
            41.10426947431897
          ]
        }
      ],
      "bbox": [
        0.2954136372596835,
        41.10426947431897,
        0.2954136372596835,
        41.10426947431897
      ]
    },
    "43023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7594950970077561,
              41.15885078182536
            ]
          },
          "bbox": [
            0.7594950970077561,
            41.15885078182536,
            0.7594950970077561,
            41.15885078182536
          ]
        }
      ],
      "bbox": [
        0.7594950970077561,
        41.15885078182536,
        0.7594950970077561,
        41.15885078182536
      ]
    },
    "43024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.567709038932009,
              41.2341875628341
            ]
          },
          "bbox": [
            1.567709038932009,
            41.2341875628341,
            1.567709038932009,
            41.2341875628341
          ]
        }
      ],
      "bbox": [
        1.567709038932009,
        41.2341875628341,
        1.567709038932009,
        41.2341875628341
      ]
    },
    "43025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5231926417262559,
              40.96762543506187
            ]
          },
          "bbox": [
            0.5231926417262559,
            40.96762543506187,
            0.5231926417262559,
            40.96762543506187
          ]
        }
      ],
      "bbox": [
        0.5231926417262559,
        40.96762543506187,
        0.5231926417262559,
        40.96762543506187
      ]
    },
    "43026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5831257820627367,
              41.06835313078592
            ]
          },
          "bbox": [
            0.5831257820627367,
            41.06835313078592,
            0.5831257820627367,
            41.06835313078592
          ]
        }
      ],
      "bbox": [
        0.5831257820627367,
        41.06835313078592,
        0.5831257820627367,
        41.06835313078592
      ]
    },
    "43027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7210650882498746,
              41.28267287185416
            ]
          },
          "bbox": [
            0.7210650882498746,
            41.28267287185416,
            0.7210650882498746,
            41.28267287185416
          ]
        }
      ],
      "bbox": [
        0.7210650882498746,
        41.28267287185416,
        0.7210650882498746,
        41.28267287185416
      ]
    },
    "43028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.481283607884034,
              41.28038194763743
            ]
          },
          "bbox": [
            1.481283607884034,
            41.28038194763743,
            1.481283607884034,
            41.28038194763743
          ]
        }
      ],
      "bbox": [
        1.481283607884034,
        41.28038194763743,
        1.481283607884034,
        41.28038194763743
      ]
    },
    "43029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1502223838847379,
              41.44948263400423
            ]
          },
          "bbox": [
            1.1502223838847379,
            41.44948263400423,
            1.1502223838847379,
            41.44948263400423
          ]
        }
      ],
      "bbox": [
        1.1502223838847379,
        41.44948263400423,
        1.1502223838847379,
        41.44948263400423
      ]
    },
    "43030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.436083377647637,
              41.22489138413941
            ]
          },
          "bbox": [
            1.436083377647637,
            41.22489138413941,
            1.436083377647637,
            41.22489138413941
          ]
        }
      ],
      "bbox": [
        1.436083377647637,
        41.22489138413941,
        1.436083377647637,
        41.22489138413941
      ]
    },
    "43031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0170798849613516,
              41.177942616725154
            ]
          },
          "bbox": [
            1.0170798849613516,
            41.177942616725154,
            1.0170798849613516,
            41.177942616725154
          ]
        }
      ],
      "bbox": [
        1.0170798849613516,
        41.177942616725154,
        1.0170798849613516,
        41.177942616725154
      ]
    },
    "43032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3724722065020532,
              41.01476717392156
            ]
          },
          "bbox": [
            0.3724722065020532,
            41.01476717392156,
            0.3724722065020532,
            41.01476717392156
          ]
        }
      ],
      "bbox": [
        0.3724722065020532,
        41.01476717392156,
        0.3724722065020532,
        41.01476717392156
      ]
    },
    "43033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0039608801698687,
              41.14630294422855
            ]
          },
          "bbox": [
            1.0039608801698687,
            41.14630294422855,
            1.0039608801698687,
            41.14630294422855
          ]
        }
      ],
      "bbox": [
        1.0039608801698687,
        41.14630294422855,
        1.0039608801698687,
        41.14630294422855
      ]
    },
    "43034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3415316280304717,
              41.27697290242232
            ]
          },
          "bbox": [
            1.3415316280304717,
            41.27697290242232,
            1.3415316280304717,
            41.27697290242232
          ]
        }
      ],
      "bbox": [
        1.3415316280304717,
        41.27697290242232,
        1.3415316280304717,
        41.27697290242232
      ]
    },
    "43035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7303716770342853,
              41.24571777463367
            ]
          },
          "bbox": [
            0.7303716770342853,
            41.24571777463367,
            0.7303716770342853,
            41.24571777463367
          ]
        }
      ],
      "bbox": [
        0.7303716770342853,
        41.24571777463367,
        0.7303716770342853,
        41.24571777463367
      ]
    },
    "43036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2999328544130404,
              41.39543966887984
            ]
          },
          "bbox": [
            1.2999328544130404,
            41.39543966887984,
            1.2999328544130404,
            41.39543966887984
          ]
        }
      ],
      "bbox": [
        1.2999328544130404,
        41.39543966887984,
        1.2999328544130404,
        41.39543966887984
      ]
    },
    "43037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.589721615118832,
              41.20883869683511
            ]
          },
          "bbox": [
            1.589721615118832,
            41.20883869683511,
            1.589721615118832,
            41.20883869683511
          ]
        }
      ],
      "bbox": [
        1.589721615118832,
        41.20883869683511,
        1.589721615118832,
        41.20883869683511
      ]
    },
    "43038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0421365159246057,
              41.082342928024254
            ]
          },
          "bbox": [
            1.0421365159246057,
            41.082342928024254,
            1.0421365159246057,
            41.082342928024254
          ]
        }
      ],
      "bbox": [
        1.0421365159246057,
        41.082342928024254,
        1.0421365159246057,
        41.082342928024254
      ]
    },
    "43039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0405116747576013,
              41.29287256412565
            ]
          },
          "bbox": [
            1.0405116747576013,
            41.29287256412565,
            1.0405116747576013,
            41.29287256412565
          ]
        }
      ],
      "bbox": [
        1.0405116747576013,
        41.29287256412565,
        1.0405116747576013,
        41.29287256412565
      ]
    },
    "43040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7996158555176645,
              41.09492714733564
            ]
          },
          "bbox": [
            0.7996158555176645,
            41.09492714733564,
            0.7996158555176645,
            41.09492714733564
          ]
        }
      ],
      "bbox": [
        0.7996158555176645,
        41.09492714733564,
        0.7996158555176645,
        41.09492714733564
      ]
    },
    "43041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.28182564091826334,
              41.04260366390734
            ]
          },
          "bbox": [
            0.28182564091826334,
            41.04260366390734,
            0.28182564091826334,
            41.04260366390734
          ]
        }
      ],
      "bbox": [
        0.28182564091826334,
        41.04260366390734,
        0.28182564091826334,
        41.04260366390734
      ]
    },
    "43042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.091324410803384,
              41.18869717379906
            ]
          },
          "bbox": [
            1.091324410803384,
            41.18869717379906,
            1.091324410803384,
            41.18869717379906
          ]
        }
      ],
      "bbox": [
        1.091324410803384,
        41.18869717379906,
        1.091324410803384,
        41.18869717379906
      ]
    },
    "43043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.306964257151263,
              41.178311213484356
            ]
          },
          "bbox": [
            1.306964257151263,
            41.178311213484356,
            1.306964257151263,
            41.178311213484356
          ]
        }
      ],
      "bbox": [
        1.306964257151263,
        41.178311213484356,
        1.306964257151263,
        41.178311213484356
      ]
    },
    "43044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2740585810666688,
              40.70210751613202
            ]
          },
          "bbox": [
            0.2740585810666688,
            40.70210751613202,
            0.2740585810666688,
            40.70210751613202
          ]
        }
      ],
      "bbox": [
        0.2740585810666688,
        40.70210751613202,
        0.2740585810666688,
        40.70210751613202
      ]
    },
    "43045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8734471287233534,
              41.100180886060535
            ]
          },
          "bbox": [
            0.8734471287233534,
            41.100180886060535,
            0.8734471287233534,
            41.100180886060535
          ]
        }
      ],
      "bbox": [
        0.8734471287233534,
        41.100180886060535,
        0.8734471287233534,
        41.100180886060535
      ]
    },
    "43046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2802683792982772,
              41.5146986258535
            ]
          },
          "bbox": [
            1.2802683792982772,
            41.5146986258535,
            1.2802683792982772,
            41.5146986258535
          ]
        }
      ],
      "bbox": [
        1.2802683792982772,
        41.5146986258535,
        1.2802683792982772,
        41.5146986258535
      ]
    },
    "43047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1917654144348542,
              41.161278439155346
            ]
          },
          "bbox": [
            1.1917654144348542,
            41.161278439155346,
            1.1917654144348542,
            41.161278439155346
          ]
        }
      ],
      "bbox": [
        1.1917654144348542,
        41.161278439155346,
        1.1917654144348542,
        41.161278439155346
      ]
    },
    "43049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9178451178916749,
              41.269084894985454
            ]
          },
          "bbox": [
            0.9178451178916749,
            41.269084894985454,
            0.9178451178916749,
            41.269084894985454
          ]
        }
      ],
      "bbox": [
        0.9178451178916749,
        41.269084894985454,
        0.9178451178916749,
        41.269084894985454
      ]
    },
    "43050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4321401666474303,
              41.181849402065865
            ]
          },
          "bbox": [
            1.4321401666474303,
            41.181849402065865,
            1.4321401666474303,
            41.181849402065865
          ]
        }
      ],
      "bbox": [
        1.4321401666474303,
        41.181849402065865,
        1.4321401666474303,
        41.181849402065865
      ]
    },
    "43051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6304342097544224,
              41.20940327471331
            ]
          },
          "bbox": [
            1.6304342097544224,
            41.20940327471331,
            1.6304342097544224,
            41.20940327471331
          ]
        }
      ],
      "bbox": [
        1.6304342097544224,
        41.20940327471331,
        1.6304342097544224,
        41.20940327471331
      ]
    },
    "43052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.46349541824245344,
              40.90266109616264
            ]
          },
          "bbox": [
            0.46349541824245344,
            40.90266109616264,
            0.46349541824245344,
            40.90266109616264
          ]
        }
      ],
      "bbox": [
        0.46349541824245344,
        40.90266109616264,
        0.46349541824245344,
        40.90266109616264
      ]
    },
    "43053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9250760233503986,
              41.15993264910608
            ]
          },
          "bbox": [
            0.9250760233503986,
            41.15993264910608,
            0.9250760233503986,
            41.15993264910608
          ]
        }
      ],
      "bbox": [
        0.9250760233503986,
        41.15993264910608,
        0.9250760233503986,
        41.15993264910608
      ]
    },
    "43055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8154422744811474,
              41.16021290354192
            ]
          },
          "bbox": [
            0.8154422744811474,
            41.16021290354192,
            0.8154422744811474,
            41.16021290354192
          ]
        }
      ],
      "bbox": [
        0.8154422744811474,
        41.16021290354192,
        0.8154422744811474,
        41.16021290354192
      ]
    },
    "43056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4689608921892915,
              41.17152102903643
            ]
          },
          "bbox": [
            0.4689608921892915,
            41.17152102903643,
            0.4689608921892915,
            41.17152102903643
          ]
        }
      ],
      "bbox": [
        0.4689608921892915,
        41.17152102903643,
        0.4689608921892915,
        41.17152102903643
      ]
    },
    "43057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9981383880325104,
              41.275168654110345
            ]
          },
          "bbox": [
            0.9981383880325104,
            41.275168654110345,
            0.9981383880325104,
            41.275168654110345
          ]
        }
      ],
      "bbox": [
        0.9981383880325104,
        41.275168654110345,
        0.9981383880325104,
        41.275168654110345
      ]
    },
    "43058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7120140030979277,
              41.20084317015277
            ]
          },
          "bbox": [
            0.7120140030979277,
            41.20084317015277,
            0.7120140030979277,
            41.20084317015277
          ]
        }
      ],
      "bbox": [
        0.7120140030979277,
        41.20084317015277,
        0.7120140030979277,
        41.20084317015277
      ]
    },
    "43059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2546492026331921,
              41.358405562972024
            ]
          },
          "bbox": [
            1.2546492026331921,
            41.358405562972024,
            1.2546492026331921,
            41.358405562972024
          ]
        }
      ],
      "bbox": [
        1.2546492026331921,
        41.358405562972024,
        1.2546492026331921,
        41.358405562972024
      ]
    },
    "43060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5671141440619578,
              41.260989994731574
            ]
          },
          "bbox": [
            0.5671141440619578,
            41.260989994731574,
            0.5671141440619578,
            41.260989994731574
          ]
        }
      ],
      "bbox": [
        0.5671141440619578,
        41.260989994731574,
        0.5671141440619578,
        41.260989994731574
      ]
    },
    "43061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2302023300448057,
              41.49208342426086
            ]
          },
          "bbox": [
            1.2302023300448057,
            41.49208342426086,
            1.2302023300448057,
            41.49208342426086
          ]
        }
      ],
      "bbox": [
        1.2302023300448057,
        41.49208342426086,
        1.2302023300448057,
        41.49208342426086
      ]
    },
    "43062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5184161044474309,
              40.65866052774164
            ]
          },
          "bbox": [
            0.5184161044474309,
            40.65866052774164,
            0.5184161044474309,
            40.65866052774164
          ]
        }
      ],
      "bbox": [
        0.5184161044474309,
        40.65866052774164,
        0.5184161044474309,
        40.65866052774164
      ]
    },
    "43063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.44005528804747945,
              40.694695267284075
            ]
          },
          "bbox": [
            0.44005528804747945,
            40.694695267284075,
            0.44005528804747945,
            40.694695267284075
          ]
        }
      ],
      "bbox": [
        0.44005528804747945,
        40.694695267284075,
        0.44005528804747945,
        40.694695267284075
      ]
    },
    "43064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4216194007804467,
              41.0540114250248
            ]
          },
          "bbox": [
            0.4216194007804467,
            41.0540114250248,
            0.4216194007804467,
            41.0540114250248
          ]
        }
      ],
      "bbox": [
        0.4216194007804467,
        41.0540114250248,
        0.4216194007804467,
        41.0540114250248
      ]
    },
    "43065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6521231181909309,
              41.142086922350096
            ]
          },
          "bbox": [
            0.6521231181909309,
            41.142086922350096,
            0.6521231181909309,
            41.142086922350096
          ]
        }
      ],
      "bbox": [
        0.6521231181909309,
        41.142086922350096,
        0.6521231181909309,
        41.142086922350096
      ]
    },
    "43066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2451970187604255,
              41.20970291744388
            ]
          },
          "bbox": [
            1.2451970187604255,
            41.20970291744388,
            1.2451970187604255,
            41.20970291744388
          ]
        }
      ],
      "bbox": [
        1.2451970187604255,
        41.20970291744388,
        1.2451970187604255,
        41.20970291744388
      ]
    },
    "43067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.637278460613721,
              41.02937037702371
            ]
          },
          "bbox": [
            0.637278460613721,
            41.02937037702371,
            0.637278460613721,
            41.02937037702371
          ]
        }
      ],
      "bbox": [
        0.637278460613721,
        41.02937037702371,
        0.637278460613721,
        41.02937037702371
      ]
    },
    "43068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4638101636330413,
              40.662207423566805
            ]
          },
          "bbox": [
            0.4638101636330413,
            40.662207423566805,
            0.4638101636330413,
            40.662207423566805
          ]
        }
      ],
      "bbox": [
        0.4638101636330413,
        40.662207423566805,
        0.4638101636330413,
        40.662207423566805
      ]
    },
    "43069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7722684022171393,
              41.19116199413413
            ]
          },
          "bbox": [
            0.7722684022171393,
            41.19116199413413,
            0.7722684022171393,
            41.19116199413413
          ]
        }
      ],
      "bbox": [
        0.7722684022171393,
        41.19116199413413,
        0.7722684022171393,
        41.19116199413413
      ]
    },
    "43070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7483747383066245,
              41.10569295726688
            ]
          },
          "bbox": [
            0.7483747383066245,
            41.10569295726688,
            0.7483747383066245,
            41.10569295726688
          ]
        }
      ],
      "bbox": [
        0.7483747383066245,
        41.10569295726688,
        0.7483747383066245,
        41.10569295726688
      ]
    },
    "43071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3230626140127218,
              40.93726665845505
            ]
          },
          "bbox": [
            0.3230626140127218,
            40.93726665845505,
            0.3230626140127218,
            40.93726665845505
          ]
        }
      ],
      "bbox": [
        0.3230626140127218,
        40.93726665845505,
        0.3230626140127218,
        40.93726665845505
      ]
    },
    "43072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.740920865255491,
              41.192670515751985
            ]
          },
          "bbox": [
            0.740920865255491,
            41.192670515751985,
            0.740920865255491,
            41.192670515751985
          ]
        }
      ],
      "bbox": [
        0.740920865255491,
        41.192670515751985,
        0.740920865255491,
        41.192670515751985
      ]
    },
    "43073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.330238837678788,
              41.556245080629786
            ]
          },
          "bbox": [
            1.330238837678788,
            41.556245080629786,
            1.330238837678788,
            41.556245080629786
          ]
        }
      ],
      "bbox": [
        1.330238837678788,
        41.556245080629786,
        1.330238837678788,
        41.556245080629786
      ]
    },
    "43074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5447319542845614,
              41.28520604734614
            ]
          },
          "bbox": [
            1.5447319542845614,
            41.28520604734614,
            1.5447319542845614,
            41.28520604734614
          ]
        }
      ],
      "bbox": [
        1.5447319542845614,
        41.28520604734614,
        1.5447319542845614,
        41.28520604734614
      ]
    },
    "43075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7743899482689146,
              41.29973666913681
            ]
          },
          "bbox": [
            0.7743899482689146,
            41.29973666913681,
            0.7743899482689146,
            41.29973666913681
          ]
        }
      ],
      "bbox": [
        0.7743899482689146,
        41.29973666913681,
        0.7743899482689146,
        41.29973666913681
      ]
    },
    "43076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.801358244345607,
              41.126748044888394
            ]
          },
          "bbox": [
            0.801358244345607,
            41.126748044888394,
            0.801358244345607,
            41.126748044888394
          ]
        }
      ],
      "bbox": [
        0.801358244345607,
        41.126748044888394,
        0.801358244345607,
        41.126748044888394
      ]
    },
    "43077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3550786091140897,
              40.72139172450641
            ]
          },
          "bbox": [
            0.3550786091140897,
            40.72139172450641,
            0.3550786091140897,
            40.72139172450641
          ]
        }
      ],
      "bbox": [
        0.3550786091140897,
        40.72139172450641,
        0.3550786091140897,
        40.72139172450641
      ]
    },
    "43078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5305631387557892,
              40.714003204197745
            ]
          },
          "bbox": [
            0.5305631387557892,
            40.714003204197745,
            0.5305631387557892,
            40.714003204197745
          ]
        }
      ],
      "bbox": [
        0.5305631387557892,
        40.714003204197745,
        0.5305631387557892,
        40.714003204197745
      ]
    },
    "43079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4299135413239594,
              41.263154639828734
            ]
          },
          "bbox": [
            1.4299135413239594,
            41.263154639828734,
            1.4299135413239594,
            41.263154639828734
          ]
        }
      ],
      "bbox": [
        1.4299135413239594,
        41.263154639828734,
        1.4299135413239594,
        41.263154639828734
      ]
    },
    "43080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2202580712842108,
              41.23791895004787
            ]
          },
          "bbox": [
            1.2202580712842108,
            41.23791895004787,
            1.2202580712842108,
            41.23791895004787
          ]
        }
      ],
      "bbox": [
        1.2202580712842108,
        41.23791895004787,
        1.2202580712842108,
        41.23791895004787
      ]
    },
    "43081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.034068717589369,
              41.187450791146084
            ]
          },
          "bbox": [
            1.034068717589369,
            41.187450791146084,
            1.034068717589369,
            41.187450791146084
          ]
        }
      ],
      "bbox": [
        1.034068717589369,
        41.187450791146084,
        1.034068717589369,
        41.187450791146084
      ]
    },
    "43082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7323567409710506,
              41.13190142616895
            ]
          },
          "bbox": [
            0.7323567409710506,
            41.13190142616895,
            0.7323567409710506,
            41.13190142616895
          ]
        }
      ],
      "bbox": [
        0.7323567409710506,
        41.13190142616895,
        0.7323567409710506,
        41.13190142616895
      ]
    },
    "43083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2062830840276428,
              41.25144585614223
            ]
          },
          "bbox": [
            1.2062830840276428,
            41.25144585614223,
            1.2062830840276428,
            41.25144585614223
          ]
        }
      ],
      "bbox": [
        1.2062830840276428,
        41.25144585614223,
        1.2062830840276428,
        41.25144585614223
      ]
    },
    "43084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5771654045743485,
              41.04141868396753
            ]
          },
          "bbox": [
            0.5771654045743485,
            41.04141868396753,
            0.5771654045743485,
            41.04141868396753
          ]
        }
      ],
      "bbox": [
        0.5771654045743485,
        41.04141868396753,
        0.5771654045743485,
        41.04141868396753
      ]
    },
    "43085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7069647394042649,
              41.1650945388549
            ]
          },
          "bbox": [
            0.7069647394042649,
            41.1650945388549,
            0.7069647394042649,
            41.1650945388549
          ]
        }
      ],
      "bbox": [
        0.7069647394042649,
        41.1650945388549,
        0.7069647394042649,
        41.1650945388549
      ]
    },
    "43086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1544471523634816,
              41.361412326577316
            ]
          },
          "bbox": [
            1.1544471523634816,
            41.361412326577316,
            1.1544471523634816,
            41.361412326577316
          ]
        }
      ],
      "bbox": [
        1.1544471523634816,
        41.361412326577316,
        1.1544471523634816,
        41.361412326577316
      ]
    },
    "43088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0013084537926695,
              41.117701027104516
            ]
          },
          "bbox": [
            1.0013084537926695,
            41.117701027104516,
            1.0013084537926695,
            41.117701027104516
          ]
        }
      ],
      "bbox": [
        1.0013084537926695,
        41.117701027104516,
        1.0013084537926695,
        41.117701027104516
      ]
    },
    "43089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3859280455580567,
              41.25998948066199
            ]
          },
          "bbox": [
            1.3859280455580567,
            41.25998948066199,
            1.3859280455580567,
            41.25998948066199
          ]
        }
      ],
      "bbox": [
        1.3859280455580567,
        41.25998948066199,
        1.3859280455580567,
        41.25998948066199
      ]
    },
    "43090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4870576969699396,
              41.331509683878615
            ]
          },
          "bbox": [
            1.4870576969699396,
            41.331509683878615,
            1.4870576969699396,
            41.331509683878615
          ]
        }
      ],
      "bbox": [
        1.4870576969699396,
        41.331509683878615,
        1.4870576969699396,
        41.331509683878615
      ]
    },
    "43091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.091807214949171,
              41.29279942973803
            ]
          },
          "bbox": [
            1.091807214949171,
            41.29279942973803,
            1.091807214949171,
            41.29279942973803
          ]
        }
      ],
      "bbox": [
        1.091807214949171,
        41.29279942973803,
        1.091807214949171,
        41.29279942973803
      ]
    },
    "43092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9559119405639757,
              41.05649970631882
            ]
          },
          "bbox": [
            0.9559119405639757,
            41.05649970631882,
            0.9559119405639757,
            41.05649970631882
          ]
        }
      ],
      "bbox": [
        0.9559119405639757,
        41.05649970631882,
        0.9559119405639757,
        41.05649970631882
      ]
    },
    "43094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.667456169572301,
              41.09472438378327
            ]
          },
          "bbox": [
            0.667456169572301,
            41.09472438378327,
            0.667456169572301,
            41.09472438378327
          ]
        }
      ],
      "bbox": [
        0.667456169572301,
        41.09472438378327,
        0.667456169572301,
        41.09472438378327
      ]
    },
    "43095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.21613029799658,
              41.20081022927412
            ]
          },
          "bbox": [
            1.21613029799658,
            41.20081022927412,
            1.21613029799658,
            41.20081022927412
          ]
        }
      ],
      "bbox": [
        1.21613029799658,
        41.20081022927412,
        1.21613029799658,
        41.20081022927412
      ]
    },
    "43096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8372691177009063,
              41.26893932301927
            ]
          },
          "bbox": [
            0.8372691177009063,
            41.26893932301927,
            0.8372691177009063,
            41.26893932301927
          ]
        }
      ],
      "bbox": [
        0.8372691177009063,
        41.26893932301927,
        0.8372691177009063,
        41.26893932301927
      ]
    },
    "43097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.379110763790073,
              41.18550884584482
            ]
          },
          "bbox": [
            1.379110763790073,
            41.18550884584482,
            1.379110763790073,
            41.18550884584482
          ]
        }
      ],
      "bbox": [
        1.379110763790073,
        41.18550884584482,
        1.379110763790073,
        41.18550884584482
      ]
    },
    "43098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2898319843943855,
              41.25268400057566
            ]
          },
          "bbox": [
            1.2898319843943855,
            41.25268400057566,
            1.2898319843943855,
            41.25268400057566
          ]
        }
      ],
      "bbox": [
        1.2898319843943855,
        41.25268400057566,
        1.2898319843943855,
        41.25268400057566
      ]
    },
    "43100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2600982247618542,
              41.168352433780974
            ]
          },
          "bbox": [
            1.2600982247618542,
            41.168352433780974,
            1.2600982247618542,
            41.168352433780974
          ]
        }
      ],
      "bbox": [
        1.2600982247618542,
        41.168352433780974,
        1.2600982247618542,
        41.168352433780974
      ]
    },
    "43101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.199558339095295,
              41.52044762373111
            ]
          },
          "bbox": [
            1.199558339095295,
            41.52044762373111,
            1.199558339095295,
            41.52044762373111
          ]
        }
      ],
      "bbox": [
        1.199558339095295,
        41.52044762373111,
        1.199558339095295,
        41.52044762373111
      ]
    },
    "43102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.40895008009407813,
              40.9274217289466
            ]
          },
          "bbox": [
            0.40895008009407813,
            40.9274217289466,
            0.40895008009407813,
            40.9274217289466
          ]
        }
      ],
      "bbox": [
        0.40895008009407813,
        40.9274217289466,
        0.40895008009407813,
        40.9274217289466
      ]
    },
    "43103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2411185377701892,
              41.18898284073312
            ]
          },
          "bbox": [
            1.2411185377701892,
            41.18898284073312,
            1.2411185377701892,
            41.18898284073312
          ]
        }
      ],
      "bbox": [
        1.2411185377701892,
        41.18898284073312,
        1.2411185377701892,
        41.18898284073312
      ]
    },
    "43104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6837385978782832,
              40.88752905467974
            ]
          },
          "bbox": [
            0.6837385978782832,
            40.88752905467974,
            0.6837385978782832,
            40.88752905467974
          ]
        }
      ],
      "bbox": [
        0.6837385978782832,
        40.88752905467974,
        0.6837385978782832,
        40.88752905467974
      ]
    },
    "43105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.349441400502704,
              41.50182572697963
            ]
          },
          "bbox": [
            1.349441400502704,
            41.50182572697963,
            1.349441400502704,
            41.50182572697963
          ]
        }
      ],
      "bbox": [
        1.349441400502704,
        41.50182572697963,
        1.349441400502704,
        41.50182572697963
      ]
    },
    "43106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5017362421579449,
              41.01857800419487
            ]
          },
          "bbox": [
            0.5017362421579449,
            41.01857800419487,
            0.5017362421579449,
            41.01857800419487
          ]
        }
      ],
      "bbox": [
        0.5017362421579449,
        41.01857800419487,
        0.5017362421579449,
        41.01857800419487
      ]
    },
    "43107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.192104864097131,
              41.42077348274307
            ]
          },
          "bbox": [
            1.192104864097131,
            41.42077348274307,
            1.192104864097131,
            41.42077348274307
          ]
        }
      ],
      "bbox": [
        1.192104864097131,
        41.42077348274307,
        1.192104864097131,
        41.42077348274307
      ]
    },
    "43108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3113096158224604,
              41.347025770640954
            ]
          },
          "bbox": [
            1.3113096158224604,
            41.347025770640954,
            1.3113096158224604,
            41.347025770640954
          ]
        }
      ],
      "bbox": [
        1.3113096158224604,
        41.347025770640954,
        1.3113096158224604,
        41.347025770640954
      ]
    },
    "43109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.21281014758118,
              41.1826829323607
            ]
          },
          "bbox": [
            1.21281014758118,
            41.1826829323607,
            1.21281014758118,
            41.1826829323607
          ]
        }
      ],
      "bbox": [
        1.21281014758118,
        41.1826829323607,
        1.21281014758118,
        41.1826829323607
      ]
    },
    "43110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3478578129540615,
              41.193445294724924
            ]
          },
          "bbox": [
            0.3478578129540615,
            41.193445294724924,
            0.3478578129540615,
            41.193445294724924
          ]
        }
      ],
      "bbox": [
        0.3478578129540615,
        41.193445294724924,
        0.3478578129540615,
        41.193445294724924
      ]
    },
    "43111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4048922262407808,
              41.18322597440558
            ]
          },
          "bbox": [
            1.4048922262407808,
            41.18322597440558,
            1.4048922262407808,
            41.18322597440558
          ]
        }
      ],
      "bbox": [
        1.4048922262407808,
        41.18322597440558,
        1.4048922262407808,
        41.18322597440558
      ]
    },
    "43112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.844995217293454,
              41.23181672348119
            ]
          },
          "bbox": [
            0.844995217293454,
            41.23181672348119,
            0.844995217293454,
            41.23181672348119
          ]
        }
      ],
      "bbox": [
        0.844995217293454,
        41.23181672348119,
        0.844995217293454,
        41.23181672348119
      ]
    },
    "43114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8548458349927408,
              41.19651894559771
            ]
          },
          "bbox": [
            0.8548458349927408,
            41.19651894559771,
            0.8548458349927408,
            41.19651894559771
          ]
        }
      ],
      "bbox": [
        0.8548458349927408,
        41.19651894559771,
        0.8548458349927408,
        41.19651894559771
      ]
    },
    "43115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8734524223042371,
              41.151929144872085
            ]
          },
          "bbox": [
            0.8734524223042371,
            41.151929144872085,
            0.8734524223042371,
            41.151929144872085
          ]
        }
      ],
      "bbox": [
        0.8734524223042371,
        41.151929144872085,
        0.8734524223042371,
        41.151929144872085
      ]
    },
    "43116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9914781495157692,
              41.314695014112864
            ]
          },
          "bbox": [
            0.9914781495157692,
            41.314695014112864,
            0.9914781495157692,
            41.314695014112864
          ]
        }
      ],
      "bbox": [
        0.9914781495157692,
        41.314695014112864,
        0.9914781495157692,
        41.314695014112864
      ]
    },
    "43117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.41120718626122615,
              40.97513463382617
            ]
          },
          "bbox": [
            0.41120718626122615,
            40.97513463382617,
            0.41120718626122615,
            40.97513463382617
          ]
        }
      ],
      "bbox": [
        0.41120718626122615,
        40.97513463382617,
        0.41120718626122615,
        40.97513463382617
      ]
    },
    "43118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8812680811647271,
              41.054276270128746
            ]
          },
          "bbox": [
            0.8812680811647271,
            41.054276270128746,
            0.8812680811647271,
            41.054276270128746
          ]
        }
      ],
      "bbox": [
        0.8812680811647271,
        41.054276270128746,
        0.8812680811647271,
        41.054276270128746
      ]
    },
    "43119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.299722384593576,
              41.27940518699235
            ]
          },
          "bbox": [
            1.299722384593576,
            41.27940518699235,
            1.299722384593576,
            41.27940518699235
          ]
        }
      ],
      "bbox": [
        1.299722384593576,
        41.27940518699235,
        1.299722384593576,
        41.27940518699235
      ]
    },
    "43120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.429739655859298,
              41.42044207710114
            ]
          },
          "bbox": [
            1.429739655859298,
            41.42044207710114,
            1.429739655859298,
            41.42044207710114
          ]
        }
      ],
      "bbox": [
        1.429739655859298,
        41.42044207710114,
        1.429739655859298,
        41.42044207710114
      ]
    },
    "43121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6149017231987198,
              40.97505302888342
            ]
          },
          "bbox": [
            0.6149017231987198,
            40.97505302888342,
            0.6149017231987198,
            40.97505302888342
          ]
        }
      ],
      "bbox": [
        0.6149017231987198,
        40.97505302888342,
        0.6149017231987198,
        40.97505302888342
      ]
    },
    "43122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3155087021231247,
              41.21793747334638
            ]
          },
          "bbox": [
            1.3155087021231247,
            41.21793747334638,
            1.3155087021231247,
            41.21793747334638
          ]
        }
      ],
      "bbox": [
        1.3155087021231247,
        41.21793747334638,
        1.3155087021231247,
        41.21793747334638
      ]
    },
    "43123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1200576943639156,
              41.14874377174347
            ]
          },
          "bbox": [
            1.1200576943639156,
            41.14874377174347,
            1.1200576943639156,
            41.14874377174347
          ]
        }
      ],
      "bbox": [
        1.1200576943639156,
        41.14874377174347,
        1.1200576943639156,
        41.14874377174347
      ]
    },
    "43124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.171396384258229,
              41.30679077361002
            ]
          },
          "bbox": [
            1.171396384258229,
            41.30679077361002,
            1.171396384258229,
            41.30679077361002
          ]
        }
      ],
      "bbox": [
        1.171396384258229,
        41.30679077361002,
        1.171396384258229,
        41.30679077361002
      ]
    },
    "43126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3558772852127419,
              41.163772818745294
            ]
          },
          "bbox": [
            1.3558772852127419,
            41.163772818745294,
            1.3558772852127419,
            41.163772818745294
          ]
        }
      ],
      "bbox": [
        1.3558772852127419,
        41.163772818745294,
        1.3558772852127419,
        41.163772818745294
      ]
    },
    "43127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9581740510574781,
              41.135484265207026
            ]
          },
          "bbox": [
            0.9581740510574781,
            41.135484265207026,
            0.9581740510574781,
            41.135484265207026
          ]
        }
      ],
      "bbox": [
        0.9581740510574781,
        41.135484265207026,
        0.9581740510574781,
        41.135484265207026
      ]
    },
    "43128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9647300238029023,
              41.17347055308572
            ]
          },
          "bbox": [
            0.9647300238029023,
            41.17347055308572,
            0.9647300238029023,
            41.17347055308572
          ]
        }
      ],
      "bbox": [
        0.9647300238029023,
        41.17347055308572,
        0.9647300238029023,
        41.17347055308572
      ]
    },
    "43129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0580303229979824,
              41.13616743119926
            ]
          },
          "bbox": [
            1.0580303229979824,
            41.13616743119926,
            1.0580303229979824,
            41.13616743119926
          ]
        }
      ],
      "bbox": [
        1.0580303229979824,
        41.13616743119926,
        1.0580303229979824,
        41.13616743119926
      ]
    },
    "43130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2698118624115964,
              41.48013538018741
            ]
          },
          "bbox": [
            1.2698118624115964,
            41.48013538018741,
            1.2698118624115964,
            41.48013538018741
          ]
        }
      ],
      "bbox": [
        1.2698118624115964,
        41.48013538018741,
        1.2698118624115964,
        41.48013538018741
      ]
    },
    "43131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4613966774640348,
              41.18611116000262
            ]
          },
          "bbox": [
            1.4613966774640348,
            41.18611116000262,
            1.4613966774640348,
            41.18611116000262
          ]
        }
      ],
      "bbox": [
        1.4613966774640348,
        41.18611116000262,
        1.4613966774640348,
        41.18611116000262
      ]
    },
    "43132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4070802659935604,
              41.27875849436561
            ]
          },
          "bbox": [
            1.4070802659935604,
            41.27875849436561,
            1.4070802659935604,
            41.27875849436561
          ]
        }
      ],
      "bbox": [
        1.4070802659935604,
        41.27875849436561,
        1.4070802659935604,
        41.27875849436561
      ]
    },
    "43133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4016757423752381,
              40.7880490650987
            ]
          },
          "bbox": [
            0.4016757423752381,
            40.7880490650987,
            0.4016757423752381,
            40.7880490650987
          ]
        }
      ],
      "bbox": [
        0.4016757423752381,
        40.7880490650987,
        0.4016757423752381,
        40.7880490650987
      ]
    },
    "43134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2199407200142658,
              41.222559034892605
            ]
          },
          "bbox": [
            1.2199407200142658,
            41.222559034892605,
            1.2199407200142658,
            41.222559034892605
          ]
        }
      ],
      "bbox": [
        1.2199407200142658,
        41.222559034892605,
        1.2199407200142658,
        41.222559034892605
      ]
    },
    "43135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3736200257531805,
              41.23426555952197
            ]
          },
          "bbox": [
            1.3736200257531805,
            41.23426555952197,
            1.3736200257531805,
            41.23426555952197
          ]
        }
      ],
      "bbox": [
        1.3736200257531805,
        41.23426555952197,
        1.3736200257531805,
        41.23426555952197
      ]
    },
    "43136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6246839891034334,
              40.60406418773705
            ]
          },
          "bbox": [
            0.6246839891034334,
            40.60406418773705,
            0.6246839891034334,
            40.60406418773705
          ]
        }
      ],
      "bbox": [
        0.6246839891034334,
        40.60406418773705,
        0.6246839891034334,
        40.60406418773705
      ]
    },
    "43137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5553715016381544,
              41.3078183141736
            ]
          },
          "bbox": [
            1.5553715016381544,
            41.3078183141736,
            1.5553715016381544,
            41.3078183141736
          ]
        }
      ],
      "bbox": [
        1.5553715016381544,
        41.3078183141736,
        1.5553715016381544,
        41.3078183141736
      ]
    },
    "43138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.48136335295370924,
              40.72314938662307
            ]
          },
          "bbox": [
            0.48136335295370924,
            40.72314938662307,
            0.48136335295370924,
            40.72314938662307
          ]
        }
      ],
      "bbox": [
        0.48136335295370924,
        40.72314938662307,
        0.48136335295370924,
        40.72314938662307
      ]
    },
    "43139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.395817738459757,
              41.54607087279084
            ]
          },
          "bbox": [
            1.395817738459757,
            41.54607087279084,
            1.395817738459757,
            41.54607087279084
          ]
        }
      ],
      "bbox": [
        1.395817738459757,
        41.54607087279084,
        1.395817738459757,
        41.54607087279084
      ]
    },
    "43140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5389844891036606,
              41.248958306055876
            ]
          },
          "bbox": [
            1.5389844891036606,
            41.248958306055876,
            1.5389844891036606,
            41.248958306055876
          ]
        }
      ],
      "bbox": [
        1.5389844891036606,
        41.248958306055876,
        1.5389844891036606,
        41.248958306055876
      ]
    },
    "43141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3921834018924062,
              41.46890477737111
            ]
          },
          "bbox": [
            1.3921834018924062,
            41.46890477737111,
            1.3921834018924062,
            41.46890477737111
          ]
        }
      ],
      "bbox": [
        1.3921834018924062,
        41.46890477737111,
        1.3921834018924062,
        41.46890477737111
      ]
    },
    "43142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.271918544469394,
              41.45313520253399
            ]
          },
          "bbox": [
            1.271918544469394,
            41.45313520253399,
            1.271918544469394,
            41.45313520253399
          ]
        }
      ],
      "bbox": [
        1.271918544469394,
        41.45313520253399,
        1.271918544469394,
        41.45313520253399
      ]
    },
    "43143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.287359824473056,
              41.54339926776454
            ]
          },
          "bbox": [
            1.287359824473056,
            41.54339926776454,
            1.287359824473056,
            41.54339926776454
          ]
        }
      ],
      "bbox": [
        1.287359824473056,
        41.54339926776454,
        1.287359824473056,
        41.54339926776454
      ]
    },
    "43144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2760518812093873,
              41.209434224662104
            ]
          },
          "bbox": [
            1.2760518812093873,
            41.209434224662104,
            1.2760518812093873,
            41.209434224662104
          ]
        }
      ],
      "bbox": [
        1.2760518812093873,
        41.209434224662104,
        1.2760518812093873,
        41.209434224662104
      ]
    },
    "43145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1344468227875075,
              41.21154306314466
            ]
          },
          "bbox": [
            1.1344468227875075,
            41.21154306314466,
            1.1344468227875075,
            41.21154306314466
          ]
        }
      ],
      "bbox": [
        1.1344468227875075,
        41.21154306314466,
        1.1344468227875075,
        41.21154306314466
      ]
    },
    "43146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0693503688842467,
              41.47673138866525
            ]
          },
          "bbox": [
            1.0693503688842467,
            41.47673138866525,
            1.0693503688842467,
            41.47673138866525
          ]
        }
      ],
      "bbox": [
        1.0693503688842467,
        41.47673138866525,
        1.0693503688842467,
        41.47673138866525
      ]
    },
    "43147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1833254553212822,
              41.46406767183006
            ]
          },
          "bbox": [
            1.1833254553212822,
            41.46406767183006,
            1.1833254553212822,
            41.46406767183006
          ]
        }
      ],
      "bbox": [
        1.1833254553212822,
        41.46406767183006,
        1.1833254553212822,
        41.46406767183006
      ]
    },
    "43148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.269137828815511,
              41.13198312861574
            ]
          },
          "bbox": [
            1.269137828815511,
            41.13198312861574,
            1.269137828815511,
            41.13198312861574
          ]
        }
      ],
      "bbox": [
        1.269137828815511,
        41.13198312861574,
        1.269137828815511,
        41.13198312861574
      ]
    },
    "43149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5523334085063484,
              40.91546056887903
            ]
          },
          "bbox": [
            0.5523334085063484,
            40.91546056887903,
            0.5523334085063484,
            40.91546056887903
          ]
        }
      ],
      "bbox": [
        0.5523334085063484,
        40.91546056887903,
        0.5523334085063484,
        40.91546056887903
      ]
    },
    "43150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7333834933144568,
              41.01869891706489
            ]
          },
          "bbox": [
            0.7333834933144568,
            41.01869891706489,
            0.7333834933144568,
            41.01869891706489
          ]
        }
      ],
      "bbox": [
        0.7333834933144568,
        41.01869891706489,
        0.7333834933144568,
        41.01869891706489
      ]
    },
    "43151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.864081714816648,
              41.12069252877706
            ]
          },
          "bbox": [
            0.864081714816648,
            41.12069252877706,
            0.864081714816648,
            41.12069252877706
          ]
        }
      ],
      "bbox": [
        0.864081714816648,
        41.12069252877706,
        0.864081714816648,
        41.12069252877706
      ]
    },
    "43153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4080342051399173,
              41.15099006272878
            ]
          },
          "bbox": [
            1.4080342051399173,
            41.15099006272878,
            1.4080342051399173,
            41.15099006272878
          ]
        }
      ],
      "bbox": [
        1.4080342051399173,
        41.15099006272878,
        1.4080342051399173,
        41.15099006272878
      ]
    },
    "43154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8077720692753039,
              41.2204334579516
            ]
          },
          "bbox": [
            0.8077720692753039,
            41.2204334579516,
            0.8077720692753039,
            41.2204334579516
          ]
        }
      ],
      "bbox": [
        0.8077720692753039,
        41.2204334579516,
        0.8077720692753039,
        41.2204334579516
      ]
    },
    "43155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.5326731546887468,
              40.819683206676785
            ]
          },
          "bbox": [
            0.5326731546887468,
            40.819683206676785,
            0.5326731546887468,
            40.819683206676785
          ]
        }
      ],
      "bbox": [
        0.5326731546887468,
        40.819683206676785,
        0.5326731546887468,
        40.819683206676785
      ]
    },
    "43156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.4370954898939961,
              40.61770703473714
            ]
          },
          "bbox": [
            0.4370954898939961,
            40.61770703473714,
            0.4370954898939961,
            40.61770703473714
          ]
        }
      ],
      "bbox": [
        0.4370954898939961,
        40.61770703473714,
        0.4370954898939961,
        40.61770703473714
      ]
    },
    "43157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.8556006319239147,
              41.32515104120653
            ]
          },
          "bbox": [
            0.8556006319239147,
            41.32515104120653,
            0.8556006319239147,
            41.32515104120653
          ]
        }
      ],
      "bbox": [
        0.8556006319239147,
        41.32515104120653,
        0.8556006319239147,
        41.32515104120653
      ]
    },
    "43158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9925576060050633,
              41.371240646309495
            ]
          },
          "bbox": [
            0.9925576060050633,
            41.371240646309495,
            0.9925576060050633,
            41.371240646309495
          ]
        }
      ],
      "bbox": [
        0.9925576060050633,
        41.371240646309495,
        0.9925576060050633,
        41.371240646309495
      ]
    },
    "43159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2346900137107124,
              41.55622131571263
            ]
          },
          "bbox": [
            1.2346900137107124,
            41.55622131571263,
            1.2346900137107124,
            41.55622131571263
          ]
        }
      ],
      "bbox": [
        1.2346900137107124,
        41.55622131571263,
        1.2346900137107124,
        41.55622131571263
      ]
    },
    "43160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2520694376924535,
              41.245847045567
            ]
          },
          "bbox": [
            1.2520694376924535,
            41.245847045567,
            1.2520694376924535,
            41.245847045567
          ]
        }
      ],
      "bbox": [
        1.2520694376924535,
        41.245847045567,
        1.2520694376924535,
        41.245847045567
      ]
    },
    "43161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.2390744179965278,
              41.30159048465843
            ]
          },
          "bbox": [
            1.2390744179965278,
            41.30159048465843,
            1.2390744179965278,
            41.30159048465843
          ]
        }
      ],
      "bbox": [
        1.2390744179965278,
        41.30159048465843,
        1.2390744179965278,
        41.30159048465843
      ]
    },
    "43163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5199335554774067,
              41.20498894851113
            ]
          },
          "bbox": [
            1.5199335554774067,
            41.20498894851113,
            1.5199335554774067,
            41.20498894851113
          ]
        }
      ],
      "bbox": [
        1.5199335554774067,
        41.20498894851113,
        1.5199335554774067,
        41.20498894851113
      ]
    },
    "43164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.361519576725069,
              41.20295961769091
            ]
          },
          "bbox": [
            1.361519576725069,
            41.20295961769091,
            1.361519576725069,
            41.20295961769091
          ]
        }
      ],
      "bbox": [
        1.361519576725069,
        41.20295961769091,
        1.361519576725069,
        41.20295961769091
      ]
    },
    "43165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3289455981239604,
              41.245128914117885
            ]
          },
          "bbox": [
            1.3289455981239604,
            41.245128914117885,
            1.3289455981239604,
            41.245128914117885
          ]
        }
      ],
      "bbox": [
        1.3289455981239604,
        41.245128914117885,
        1.3289455981239604,
        41.245128914117885
      ]
    },
    "43166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1956490259050028,
              41.211431594872835
            ]
          },
          "bbox": [
            1.1956490259050028,
            41.211431594872835,
            1.1956490259050028,
            41.211431594872835
          ]
        }
      ],
      "bbox": [
        1.1956490259050028,
        41.211431594872835,
        1.1956490259050028,
        41.211431594872835
      ]
    },
    "43168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.9489029438787614,
              41.33898243950197
            ]
          },
          "bbox": [
            0.9489029438787614,
            41.33898243950197,
            0.9489029438787614,
            41.33898243950197
          ]
        }
      ],
      "bbox": [
        0.9489029438787614,
        41.33898243950197,
        0.9489029438787614,
        41.33898243950197
      ]
    },
    "43169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.0355171234144707,
              41.2497038005369
            ]
          },
          "bbox": [
            1.0355171234144707,
            41.2497038005369,
            1.0355171234144707,
            41.2497038005369
          ]
        }
      ],
      "bbox": [
        1.0355171234144707,
        41.2497038005369,
        1.0355171234144707,
        41.2497038005369
      ]
    },
    "43170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3678335385846085,
              41.30844521092507
            ]
          },
          "bbox": [
            1.3678335385846085,
            41.30844521092507,
            1.3678335385846085,
            41.30844521092507
          ]
        }
      ],
      "bbox": [
        1.3678335385846085,
        41.30844521092507,
        1.3678335385846085,
        41.30844521092507
      ]
    },
    "43171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1448002461392515,
              41.1014144258523
            ]
          },
          "bbox": [
            1.1448002461392515,
            41.1014144258523,
            1.1448002461392515,
            41.1014144258523
          ]
        }
      ],
      "bbox": [
        1.1448002461392515,
        41.1014144258523,
        1.1448002461392515,
        41.1014144258523
      ]
    },
    "43172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1642080689234522,
              41.33442265052045
            ]
          },
          "bbox": [
            1.1642080689234522,
            41.33442265052045,
            1.1642080689234522,
            41.33442265052045
          ]
        }
      ],
      "bbox": [
        1.1642080689234522,
        41.33442265052045,
        1.1642080689234522,
        41.33442265052045
      ]
    },
    "43173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7808727929403191,
              41.23065289941716
            ]
          },
          "bbox": [
            0.7808727929403191,
            41.23065289941716,
            0.7808727929403191,
            41.23065289941716
          ]
        }
      ],
      "bbox": [
        0.7808727929403191,
        41.23065289941716,
        0.7808727929403191,
        41.23065289941716
      ]
    },
    "43174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7558479453618141,
              41.22051986247503
            ]
          },
          "bbox": [
            0.7558479453618141,
            41.22051986247503,
            0.7558479453618141,
            41.22051986247503
          ]
        }
      ],
      "bbox": [
        0.7558479453618141,
        41.22051986247503,
        0.7558479453618141,
        41.22051986247503
      ]
    },
    "43175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.40061873240694873,
              41.14791022209622
            ]
          },
          "bbox": [
            0.40061873240694873,
            41.14791022209622,
            0.40061873240694873,
            41.14791022209622
          ]
        }
      ],
      "bbox": [
        0.40061873240694873,
        41.14791022209622,
        0.40061873240694873,
        41.14791022209622
      ]
    },
    "43176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.046335387145415,
              41.375309317077814
            ]
          },
          "bbox": [
            1.046335387145415,
            41.375309317077814,
            1.046335387145415,
            41.375309317077814
          ]
        }
      ],
      "bbox": [
        1.046335387145415,
        41.375309317077814,
        1.046335387145415,
        41.375309317077814
      ]
    },
    "43177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.6228408190642767,
              41.216213513361765
            ]
          },
          "bbox": [
            0.6228408190642767,
            41.216213513361765,
            0.6228408190642767,
            41.216213513361765
          ]
        }
      ],
      "bbox": [
        0.6228408190642767,
        41.216213513361765,
        0.6228408190642767,
        41.216213513361765
      ]
    },
    "43178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.054516269015458,
              41.09891687030445
            ]
          },
          "bbox": [
            1.054516269015458,
            41.09891687030445,
            1.054516269015458,
            41.09891687030445
          ]
        }
      ],
      "bbox": [
        1.054516269015458,
        41.09891687030445,
        1.054516269015458,
        41.09891687030445
      ]
    },
    "43901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.7447767618415619,
              40.73596510016159
            ]
          },
          "bbox": [
            0.7447767618415619,
            40.73596510016159,
            0.7447767618415619,
            40.73596510016159
          ]
        }
      ],
      "bbox": [
        0.7447767618415619,
        40.73596510016159,
        0.7447767618415619,
        40.73596510016159
      ]
    },
    "43903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.651476190383832,
              40.77979549241544
            ]
          },
          "bbox": [
            0.651476190383832,
            40.77979549241544,
            0.651476190383832,
            40.77979549241544
          ]
        }
      ],
      "bbox": [
        0.651476190383832,
        40.77979549241544,
        0.651476190383832,
        40.77979549241544
      ]
    },
    "43905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1444777780029947,
              41.07948115352887
            ]
          },
          "bbox": [
            1.1444777780029947,
            41.07948115352887,
            1.1444777780029947,
            41.07948115352887
          ]
        }
      ],
      "bbox": [
        1.1444777780029947,
        41.07948115352887,
        1.1444777780029947,
        41.07948115352887
      ]
    },
    "43907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.1809584877541504,
              41.114833192235665
            ]
          },
          "bbox": [
            1.1809584877541504,
            41.114833192235665,
            1.1809584877541504,
            41.114833192235665
          ]
        }
      ],
      "bbox": [
        1.1809584877541504,
        41.114833192235665,
        1.1809584877541504,
        41.114833192235665
      ]
    },
    "44001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8335950990957459,
              40.54786844843004
            ]
          },
          "bbox": [
            -0.8335950990957459,
            40.54786844843004,
            -0.8335950990957459,
            40.54786844843004
          ]
        }
      ],
      "bbox": [
        -0.8335950990957459,
        40.54786844843004,
        -0.8335950990957459,
        40.54786844843004
      ]
    },
    "44002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8693524123569741,
              39.90953425028353
            ]
          },
          "bbox": [
            -0.8693524123569741,
            39.90953425028353,
            -0.8693524123569741,
            39.90953425028353
          ]
        }
      ],
      "bbox": [
        -0.8693524123569741,
        39.90953425028353,
        -0.8693524123569741,
        39.90953425028353
      ]
    },
    "44003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.224838109133389,
              40.658629241178886
            ]
          },
          "bbox": [
            -1.224838109133389,
            40.658629241178886,
            -1.224838109133389,
            40.658629241178886
          ]
        }
      ],
      "bbox": [
        -1.224838109133389,
        40.658629241178886,
        -1.224838109133389,
        40.658629241178886
      ]
    },
    "44004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2025447757451451,
              40.81713443341723
            ]
          },
          "bbox": [
            -0.2025447757451451,
            40.81713443341723,
            -0.2025447757451451,
            40.81713443341723
          ]
        }
      ],
      "bbox": [
        -0.2025447757451451,
        40.81713443341723,
        -0.2025447757451451,
        40.81713443341723
      ]
    },
    "44005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8149983790795963,
              40.58898178690457
            ]
          },
          "bbox": [
            -0.8149983790795963,
            40.58898178690457,
            -0.8149983790795963,
            40.58898178690457
          ]
        }
      ],
      "bbox": [
        -0.8149983790795963,
        40.58898178690457,
        -0.8149983790795963,
        40.58898178690457
      ]
    },
    "44006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6986611987083184,
              41.047267644936234
            ]
          },
          "bbox": [
            -0.6986611987083184,
            41.047267644936234,
            -0.6986611987083184,
            41.047267644936234
          ]
        }
      ],
      "bbox": [
        -0.6986611987083184,
        41.047267644936234,
        -0.6986611987083184,
        41.047267644936234
      ]
    },
    "44007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3661743916940554,
              40.61561634149239
            ]
          },
          "bbox": [
            -1.3661743916940554,
            40.61561634149239,
            -1.3661743916940554,
            40.61561634149239
          ]
        }
      ],
      "bbox": [
        -1.3661743916940554,
        40.61561634149239,
        -1.3661743916940554,
        40.61561634149239
      ]
    },
    "44008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5626376065048645,
              41.122190762070524
            ]
          },
          "bbox": [
            -0.5626376065048645,
            41.122190762070524,
            -0.5626376065048645,
            41.122190762070524
          ]
        }
      ],
      "bbox": [
        -0.5626376065048645,
        41.122190762070524,
        -0.5626376065048645,
        41.122190762070524
      ]
    },
    "44009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5455340438617649,
              40.37684551801121
            ]
          },
          "bbox": [
            -1.5455340438617649,
            40.37684551801121,
            -1.5455340438617649,
            40.37684551801121
          ]
        }
      ],
      "bbox": [
        -1.5455340438617649,
        40.37684551801121,
        -1.5455340438617649,
        40.37684551801121
      ]
    },
    "44010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7533704014126062,
              40.089492682166636
            ]
          },
          "bbox": [
            -0.7533704014126062,
            40.089492682166636,
            -0.7533704014126062,
            40.089492682166636
          ]
        }
      ],
      "bbox": [
        -0.7533704014126062,
        40.089492682166636,
        -0.7533704014126062,
        40.089492682166636
      ]
    },
    "44011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6965808813159652,
              40.95276556831606
            ]
          },
          "bbox": [
            -0.6965808813159652,
            40.95276556831606,
            -0.6965808813159652,
            40.95276556831606
          ]
        }
      ],
      "bbox": [
        -0.6965808813159652,
        40.95276556831606,
        -0.6965808813159652,
        40.95276556831606
      ]
    },
    "44012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7134004386032395,
              40.36416167028712
            ]
          },
          "bbox": [
            -0.7134004386032395,
            40.36416167028712,
            -0.7134004386032395,
            40.36416167028712
          ]
        }
      ],
      "bbox": [
        -0.7134004386032395,
        40.36416167028712,
        -0.7134004386032395,
        40.36416167028712
      ]
    },
    "44013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.16970539458792813,
              41.073561848190664
            ]
          },
          "bbox": [
            -0.16970539458792813,
            41.073561848190664,
            -0.16970539458792813,
            41.073561848190664
          ]
        }
      ],
      "bbox": [
        -0.16970539458792813,
        41.073561848190664,
        -0.16970539458792813,
        41.073561848190664
      ]
    },
    "44014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.36995165373641814,
              40.919696751589754
            ]
          },
          "bbox": [
            -0.36995165373641814,
            40.919696751589754,
            -0.36995165373641814,
            40.919696751589754
          ]
        }
      ],
      "bbox": [
        -0.36995165373641814,
        40.919696751589754,
        -0.36995165373641814,
        40.919696751589754
      ]
    },
    "44016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0605721064191482,
              40.55051291376056
            ]
          },
          "bbox": [
            -1.0605721064191482,
            40.55051291376056,
            -1.0605721064191482,
            40.55051291376056
          ]
        }
      ],
      "bbox": [
        -1.0605721064191482,
        40.55051291376056,
        -1.0605721064191482,
        40.55051291376056
      ]
    },
    "44017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6791290390854752,
              40.682101446862056
            ]
          },
          "bbox": [
            -0.6791290390854752,
            40.682101446862056,
            -0.6791290390854752,
            40.682101446862056
          ]
        }
      ],
      "bbox": [
        -0.6791290390854752,
        40.682101446862056,
        -0.6791290390854752,
        40.682101446862056
      ]
    },
    "44018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.435093554578042,
              40.60715003346781
            ]
          },
          "bbox": [
            -1.435093554578042,
            40.60715003346781,
            -1.435093554578042,
            40.60715003346781
          ]
        }
      ],
      "bbox": [
        -1.435093554578042,
        40.60715003346781,
        -1.435093554578042,
        40.60715003346781
      ]
    },
    "44019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4015709462516854,
              40.18292691870746
            ]
          },
          "bbox": [
            -1.4015709462516854,
            40.18292691870746,
            -1.4015709462516854,
            40.18292691870746
          ]
        }
      ],
      "bbox": [
        -1.4015709462516854,
        40.18292691870746,
        -1.4015709462516854,
        40.18292691870746
      ]
    },
    "44020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0739006551863022,
              40.80234539295011
            ]
          },
          "bbox": [
            -1.0739006551863022,
            40.80234539295011,
            -1.0739006551863022,
            40.80234539295011
          ]
        }
      ],
      "bbox": [
        -1.0739006551863022,
        40.80234539295011,
        -1.0739006551863022,
        40.80234539295011
      ]
    },
    "44021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7019925325591561,
              40.479215359785485
            ]
          },
          "bbox": [
            -0.7019925325591561,
            40.479215359785485,
            -0.7019925325591561,
            40.479215359785485
          ]
        }
      ],
      "bbox": [
        -0.7019925325591561,
        40.479215359785485,
        -0.7019925325591561,
        40.479215359785485
      ]
    },
    "44022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5276665377402694,
              40.96755619866956
            ]
          },
          "bbox": [
            -0.5276665377402694,
            40.96755619866956,
            -0.5276665377402694,
            40.96755619866956
          ]
        }
      ],
      "bbox": [
        -0.5276665377402694,
        40.96755619866956,
        -0.5276665377402694,
        40.96755619866956
      ]
    },
    "44023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.04440120088944,
              40.9781548164025
            ]
          },
          "bbox": [
            -1.04440120088944,
            40.9781548164025,
            -1.04440120088944,
            40.9781548164025
          ]
        }
      ],
      "bbox": [
        -1.04440120088944,
        40.9781548164025,
        -1.04440120088944,
        40.9781548164025
      ]
    },
    "44024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9662640664848768,
              40.986246183893435
            ]
          },
          "bbox": [
            -0.9662640664848768,
            40.986246183893435,
            -0.9662640664848768,
            40.986246183893435
          ]
        }
      ],
      "bbox": [
        -0.9662640664848768,
        40.986246183893435,
        -0.9662640664848768,
        40.986246183893435
      ]
    },
    "44025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.43081035667730744,
              41.01330242522674
            ]
          },
          "bbox": [
            -0.43081035667730744,
            41.01330242522674,
            -0.43081035667730744,
            41.01330242522674
          ]
        }
      ],
      "bbox": [
        -0.43081035667730744,
        41.01330242522674,
        -0.43081035667730744,
        41.01330242522674
      ]
    },
    "44026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0625567718656634,
              40.01468332176267
            ]
          },
          "bbox": [
            -1.0625567718656634,
            40.01468332176267,
            -1.0625567718656634,
            40.01468332176267
          ]
        }
      ],
      "bbox": [
        -1.0625567718656634,
        40.01468332176267,
        -1.0625567718656634,
        40.01468332176267
      ]
    },
    "44027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.24557996122801407,
              40.98999316633692
            ]
          },
          "bbox": [
            0.24557996122801407,
            40.98999316633692,
            0.24557996122801407,
            40.98999316633692
          ]
        }
      ],
      "bbox": [
        0.24557996122801407,
        40.98999316633692,
        0.24557996122801407,
        40.98999316633692
      ]
    },
    "44028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.179061441327627,
              40.69893147722255
            ]
          },
          "bbox": [
            -1.179061441327627,
            40.69893147722255,
            -1.179061441327627,
            40.69893147722255
          ]
        }
      ],
      "bbox": [
        -1.179061441327627,
        40.69893147722255,
        -1.179061441327627,
        40.69893147722255
      ]
    },
    "44029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5990400802889705,
              41.050177542217405
            ]
          },
          "bbox": [
            -0.5990400802889705,
            41.050177542217405,
            -0.5990400802889705,
            41.050177542217405
          ]
        }
      ],
      "bbox": [
        -0.5990400802889705,
        41.050177542217405,
        -0.5990400802889705,
        41.050177542217405
      ]
    },
    "44031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5096375059050592,
              41.306288650759555
            ]
          },
          "bbox": [
            -0.5096375059050592,
            41.306288650759555,
            -0.5096375059050592,
            41.306288650759555
          ]
        }
      ],
      "bbox": [
        -0.5096375059050592,
        41.306288650759555,
        -0.5096375059050592,
        41.306288650759555
      ]
    },
    "44032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1252458620165844,
              41.09957167668853
            ]
          },
          "bbox": [
            -1.1252458620165844,
            41.09957167668853,
            -1.1252458620165844,
            41.09957167668853
          ]
        }
      ],
      "bbox": [
        -1.1252458620165844,
        41.09957167668853,
        -1.1252458620165844,
        41.09957167668853
      ]
    },
    "44033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3585906363120728,
              41.03526791964556
            ]
          },
          "bbox": [
            -1.3585906363120728,
            41.03526791964556,
            -1.3585906363120728,
            41.03526791964556
          ]
        }
      ],
      "bbox": [
        -1.3585906363120728,
        41.03526791964556,
        -1.3585906363120728,
        41.03526791964556
      ]
    },
    "44034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2134795848106608,
              40.834244835482664
            ]
          },
          "bbox": [
            -1.2134795848106608,
            40.834244835482664,
            -1.2134795848106608,
            40.834244835482664
          ]
        }
      ],
      "bbox": [
        -1.2134795848106608,
        40.834244835482664,
        -1.2134795848106608,
        40.834244835482664
      ]
    },
    "44035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.146250045236504,
              40.890335870715404
            ]
          },
          "bbox": [
            -1.146250045236504,
            40.890335870715404,
            -1.146250045236504,
            40.890335870715404
          ]
        }
      ],
      "bbox": [
        -1.146250045236504,
        40.890335870715404,
        -1.146250045236504,
        40.890335870715404
      ]
    },
    "44036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1379603400952332,
              41.02578454646744
            ]
          },
          "bbox": [
            -1.1379603400952332,
            41.02578454646744,
            -1.1379603400952332,
            41.02578454646744
          ]
        }
      ],
      "bbox": [
        -1.1379603400952332,
        41.02578454646744,
        -1.1379603400952332,
        41.02578454646744
      ]
    },
    "44037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2002221052765659,
              40.81006422038759
            ]
          },
          "bbox": [
            0.2002221052765659,
            40.81006422038759,
            0.2002221052765659,
            40.81006422038759
          ]
        }
      ],
      "bbox": [
        0.2002221052765659,
        40.81006422038759,
        0.2002221052765659,
        40.81006422038759
      ]
    },
    "44038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.06128892154552975,
              40.882872133339774
            ]
          },
          "bbox": [
            -0.06128892154552975,
            40.882872133339774,
            -0.06128892154552975,
            40.882872133339774
          ]
        }
      ],
      "bbox": [
        -0.06128892154552975,
        40.882872133339774,
        -0.06128892154552975,
        40.882872133339774
      ]
    },
    "44039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5111004817512208,
              40.92255877720856
            ]
          },
          "bbox": [
            -1.5111004817512208,
            40.92255877720856,
            -1.5111004817512208,
            40.92255877720856
          ]
        }
      ],
      "bbox": [
        -1.5111004817512208,
        40.92255877720856,
        -1.5111004817512208,
        40.92255877720856
      ]
    },
    "44040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4063265931667905,
              40.852152065990516
            ]
          },
          "bbox": [
            -0.4063265931667905,
            40.852152065990516,
            -0.4063265931667905,
            40.852152065990516
          ]
        }
      ],
      "bbox": [
        -0.4063265931667905,
        40.852152065990516,
        -0.4063265931667905,
        40.852152065990516
      ]
    },
    "44041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3333856962423596,
              40.32077732071448
            ]
          },
          "bbox": [
            -1.3333856962423596,
            40.32077732071448,
            -1.3333856962423596,
            40.32077732071448
          ]
        }
      ],
      "bbox": [
        -1.3333856962423596,
        40.32077732071448,
        -1.3333856962423596,
        40.32077732071448
      ]
    },
    "44042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4815131093664071,
              40.82236961501617
            ]
          },
          "bbox": [
            -1.4815131093664071,
            40.82236961501617,
            -1.4815131093664071,
            40.82236961501617
          ]
        }
      ],
      "bbox": [
        -1.4815131093664071,
        40.82236961501617,
        -1.4815131093664071,
        40.82236961501617
      ]
    },
    "44043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8832118866212801,
              41.07280765039342
            ]
          },
          "bbox": [
            -0.8832118866212801,
            41.07280765039342,
            -0.8832118866212801,
            41.07280765039342
          ]
        }
      ],
      "bbox": [
        -0.8832118866212801,
        41.07280765039342,
        -0.8832118866212801,
        41.07280765039342
      ]
    },
    "44044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.343504278243898,
              40.68871165415641
            ]
          },
          "bbox": [
            -0.343504278243898,
            40.68871165415641,
            -0.343504278243898,
            40.68871165415641
          ]
        }
      ],
      "bbox": [
        -0.343504278243898,
        40.68871165415641,
        -0.343504278243898,
        40.68871165415641
      ]
    },
    "44045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5684000785991234,
              40.52338373848375
            ]
          },
          "bbox": [
            -1.5684000785991234,
            40.52338373848375,
            -1.5684000785991234,
            40.52338373848375
          ]
        }
      ],
      "bbox": [
        -1.5684000785991234,
        40.52338373848375,
        -1.5684000785991234,
        40.52338373848375
      ]
    },
    "44046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.271486453573205,
              40.712487899760276
            ]
          },
          "bbox": [
            -1.271486453573205,
            40.712487899760276,
            -1.271486453573205,
            40.712487899760276
          ]
        }
      ],
      "bbox": [
        -1.271486453573205,
        40.712487899760276,
        -1.271486453573205,
        40.712487899760276
      ]
    },
    "44047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3313173595121695,
              41.01158847064984
            ]
          },
          "bbox": [
            -1.3313173595121695,
            41.01158847064984,
            -1.3313173595121695,
            41.01158847064984
          ]
        }
      ],
      "bbox": [
        -1.3313173595121695,
        41.01158847064984,
        -1.3313173595121695,
        41.01158847064984
      ]
    },
    "44048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8100095673403652,
              40.31527696822462
            ]
          },
          "bbox": [
            -0.8100095673403652,
            40.31527696822462,
            -0.8100095673403652,
            40.31527696822462
          ]
        }
      ],
      "bbox": [
        -0.8100095673403652,
        40.31527696822462,
        -0.8100095673403652,
        40.31527696822462
      ]
    },
    "44049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.18585366803802628,
              41.01710473953786
            ]
          },
          "bbox": [
            0.18585366803802628,
            41.01710473953786,
            0.18585366803802628,
            41.01710473953786
          ]
        }
      ],
      "bbox": [
        0.18585366803802628,
        41.01710473953786,
        0.18585366803802628,
        41.01710473953786
      ]
    },
    "44050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2416072680994445,
              40.94251626476142
            ]
          },
          "bbox": [
            -1.2416072680994445,
            40.94251626476142,
            -1.2416072680994445,
            40.94251626476142
          ]
        }
      ],
      "bbox": [
        -1.2416072680994445,
        40.94251626476142,
        -1.2416072680994445,
        40.94251626476142
      ]
    },
    "44051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2329567101276793,
              40.952215550968305
            ]
          },
          "bbox": [
            -0.2329567101276793,
            40.952215550968305,
            -0.2329567101276793,
            40.952215550968305
          ]
        }
      ],
      "bbox": [
        -0.2329567101276793,
        40.952215550968305,
        -0.2329567101276793,
        40.952215550968305
      ]
    },
    "44052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5748415153336057,
              40.370628909441294
            ]
          },
          "bbox": [
            -1.5748415153336057,
            40.370628909441294,
            -1.5748415153336057,
            40.370628909441294
          ]
        }
      ],
      "bbox": [
        -1.5748415153336057,
        40.370628909441294,
        -1.5748415153336057,
        40.370628909441294
      ]
    },
    "44053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1439249417337984,
              40.61561925838205
            ]
          },
          "bbox": [
            -1.1439249417337984,
            40.61561925838205,
            -1.1439249417337984,
            40.61561925838205
          ]
        }
      ],
      "bbox": [
        -1.1439249417337984,
        40.61561925838205,
        -1.1439249417337984,
        40.61561925838205
      ]
    },
    "44054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0566367179635432,
              40.128793420641436
            ]
          },
          "bbox": [
            -1.0566367179635432,
            40.128793420641436,
            -1.0566367179635432,
            40.128793420641436
          ]
        }
      ],
      "bbox": [
        -1.0566367179635432,
        40.128793420641436,
        -1.0566367179635432,
        40.128793420641436
      ]
    },
    "44055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7680566306527956,
              40.62444025794984
            ]
          },
          "bbox": [
            -0.7680566306527956,
            40.62444025794984,
            -0.7680566306527956,
            40.62444025794984
          ]
        }
      ],
      "bbox": [
        -0.7680566306527956,
        40.62444025794984,
        -0.7680566306527956,
        40.62444025794984
      ]
    },
    "44056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.358418730961704,
              40.84787101339621
            ]
          },
          "bbox": [
            -1.358418730961704,
            40.84787101339621,
            -1.358418730961704,
            40.84787101339621
          ]
        }
      ],
      "bbox": [
        -1.358418730961704,
        40.84787101339621,
        -1.358418730961704,
        40.84787101339621
      ]
    },
    "44059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3961589262030806,
              40.52126696639097
            ]
          },
          "bbox": [
            -0.3961589262030806,
            40.52126696639097,
            -0.3961589262030806,
            40.52126696639097
          ]
        }
      ],
      "bbox": [
        -0.3961589262030806,
        40.52126696639097,
        -0.3961589262030806,
        40.52126696639097
      ]
    },
    "44060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.522492681941919,
              40.578322958710686
            ]
          },
          "bbox": [
            -0.522492681941919,
            40.578322958710686,
            -0.522492681941919,
            40.578322958710686
          ]
        }
      ],
      "bbox": [
        -0.522492681941919,
        40.578322958710686,
        -0.522492681941919,
        40.578322958710686
      ]
    },
    "44061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.10077458611838769,
              40.85623906338765
            ]
          },
          "bbox": [
            -0.10077458611838769,
            40.85623906338765,
            -0.10077458611838769,
            40.85623906338765
          ]
        }
      ],
      "bbox": [
        -0.10077458611838769,
        40.85623906338765,
        -0.10077458611838769,
        40.85623906338765
      ]
    },
    "44062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9213615908207085,
              40.70224847230461
            ]
          },
          "bbox": [
            -0.9213615908207085,
            40.70224847230461,
            -0.9213615908207085,
            40.70224847230461
          ]
        }
      ],
      "bbox": [
        -0.9213615908207085,
        40.70224847230461,
        -0.9213615908207085,
        40.70224847230461
      ]
    },
    "44063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.643991198709948,
              40.804897829680954
            ]
          },
          "bbox": [
            -0.643991198709948,
            40.804897829680954,
            -0.643991198709948,
            40.804897829680954
          ]
        }
      ],
      "bbox": [
        -0.643991198709948,
        40.804897829680954,
        -0.643991198709948,
        40.804897829680954
      ]
    },
    "44064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1327182412433605,
              40.19530111376653
            ]
          },
          "bbox": [
            -1.1327182412433605,
            40.19530111376653,
            -1.1327182412433605,
            40.19530111376653
          ]
        }
      ],
      "bbox": [
        -1.1327182412433605,
        40.19530111376653,
        -1.1327182412433605,
        40.19530111376653
      ]
    },
    "44065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4209940181086114,
              40.99879180087441
            ]
          },
          "bbox": [
            -1.4209940181086114,
            40.99879180087441,
            -1.4209940181086114,
            40.99879180087441
          ]
        }
      ],
      "bbox": [
        -1.4209940181086114,
        40.99879180087441,
        -1.4209940181086114,
        40.99879180087441
      ]
    },
    "44066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6997014801244965,
              40.79817532595325
            ]
          },
          "bbox": [
            -0.6997014801244965,
            40.79817532595325,
            -0.6997014801244965,
            40.79817532595325
          ]
        }
      ],
      "bbox": [
        -0.6997014801244965,
        40.79817532595325,
        -0.6997014801244965,
        40.79817532595325
      ]
    },
    "44067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.34120925273849056,
              41.23566765960884
            ]
          },
          "bbox": [
            -0.34120925273849056,
            41.23566765960884,
            -0.34120925273849056,
            41.23566765960884
          ]
        }
      ],
      "bbox": [
        -0.34120925273849056,
        41.23566765960884,
        -0.34120925273849056,
        41.23566765960884
      ]
    },
    "44068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.14616765995489042,
              40.9640070963627
            ]
          },
          "bbox": [
            -0.14616765995489042,
            40.9640070963627,
            -0.14616765995489042,
            40.9640070963627
          ]
        }
      ],
      "bbox": [
        -0.14616765995489042,
        40.9640070963627,
        -0.14616765995489042,
        40.9640070963627
      ]
    },
    "44070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8305656217881049,
              40.37499507573621
            ]
          },
          "bbox": [
            -0.8305656217881049,
            40.37499507573621,
            -0.8305656217881049,
            40.37499507573621
          ]
        }
      ],
      "bbox": [
        -0.8305656217881049,
        40.37499507573621,
        -0.8305656217881049,
        40.37499507573621
      ]
    },
    "44071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3578008399847546,
              40.7534489179584
            ]
          },
          "bbox": [
            -0.3578008399847546,
            40.7534489179584,
            -0.3578008399847546,
            40.7534489179584
          ]
        }
      ],
      "bbox": [
        -0.3578008399847546,
        40.7534489179584,
        -0.3578008399847546,
        40.7534489179584
      ]
    },
    "44074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8522236024866036,
              40.429082117529894
            ]
          },
          "bbox": [
            -0.8522236024866036,
            40.429082117529894,
            -0.8522236024866036,
            40.429082117529894
          ]
        }
      ],
      "bbox": [
        -0.8522236024866036,
        40.429082117529894,
        -0.8522236024866036,
        40.429082117529894
      ]
    },
    "44075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1577934533239465,
              40.4954004372124
            ]
          },
          "bbox": [
            -1.1577934533239465,
            40.4954004372124,
            -1.1577934533239465,
            40.4954004372124
          ]
        }
      ],
      "bbox": [
        -1.1577934533239465,
        40.4954004372124,
        -1.1577934533239465,
        40.4954004372124
      ]
    },
    "44076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2950388523913192,
              40.458456181353895
            ]
          },
          "bbox": [
            -1.2950388523913192,
            40.458456181353895,
            -1.2950388523913192,
            40.458456181353895
          ]
        }
      ],
      "bbox": [
        -1.2950388523913192,
        40.458456181353895,
        -1.2950388523913192,
        40.458456181353895
      ]
    },
    "44077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.08175197526719814,
              40.82450761801621
            ]
          },
          "bbox": [
            -0.08175197526719814,
            40.82450761801621,
            -0.08175197526719814,
            40.82450761801621
          ]
        }
      ],
      "bbox": [
        -0.08175197526719814,
        40.82450761801621,
        -0.08175197526719814,
        40.82450761801621
      ]
    },
    "44080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.058898425716728584,
              40.92840360791909
            ]
          },
          "bbox": [
            -0.058898425716728584,
            40.92840360791909,
            -0.058898425716728584,
            40.92840360791909
          ]
        }
      ],
      "bbox": [
        -0.058898425716728584,
        40.92840360791909,
        -0.058898425716728584,
        40.92840360791909
      ]
    },
    "44082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9611822660680237,
              40.40355714339341
            ]
          },
          "bbox": [
            -0.9611822660680237,
            40.40355714339341,
            -0.9611822660680237,
            40.40355714339341
          ]
        }
      ],
      "bbox": [
        -0.9611822660680237,
        40.40355714339341,
        -0.9611822660680237,
        40.40355714339341
      ]
    },
    "44084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8205774049825025,
              40.97740700726452
            ]
          },
          "bbox": [
            -0.8205774049825025,
            40.97740700726452,
            -0.8205774049825025,
            40.97740700726452
          ]
        }
      ],
      "bbox": [
        -0.8205774049825025,
        40.97740700726452,
        -0.8205774049825025,
        40.97740700726452
      ]
    },
    "44085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.126910190024096,
              40.80237337125293
            ]
          },
          "bbox": [
            -1.126910190024096,
            40.80237337125293,
            -1.126910190024096,
            40.80237337125293
          ]
        }
      ],
      "bbox": [
        -1.126910190024096,
        40.80237337125293,
        -1.126910190024096,
        40.80237337125293
      ]
    },
    "44086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.2052841819568679,
              40.922861936042125
            ]
          },
          "bbox": [
            0.2052841819568679,
            40.922861936042125,
            0.2052841819568679,
            40.922861936042125
          ]
        }
      ],
      "bbox": [
        0.2052841819568679,
        40.922861936042125,
        0.2052841819568679,
        40.922861936042125
      ]
    },
    "44087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5690829265339089,
              40.89697773416228
            ]
          },
          "bbox": [
            -0.5690829265339089,
            40.89697773416228,
            -0.5690829265339089,
            40.89697773416228
          ]
        }
      ],
      "bbox": [
        -0.5690829265339089,
        40.89697773416228,
        -0.5690829265339089,
        40.89697773416228
      ]
    },
    "44088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3039954687870173,
              40.59156994629966
            ]
          },
          "bbox": [
            -0.3039954687870173,
            40.59156994629966,
            -0.3039954687870173,
            40.59156994629966
          ]
        }
      ],
      "bbox": [
        -0.3039954687870173,
        40.59156994629966,
        -0.3039954687870173,
        40.59156994629966
      ]
    },
    "44089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0626179328479948,
              40.21710146430636
            ]
          },
          "bbox": [
            -1.0626179328479948,
            40.21710146430636,
            -1.0626179328479948,
            40.21710146430636
          ]
        }
      ],
      "bbox": [
        -1.0626179328479948,
        40.21710146430636,
        -1.0626179328479948,
        40.21710146430636
      ]
    },
    "44090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1914713440164595,
              41.07905565962922
            ]
          },
          "bbox": [
            -1.1914713440164595,
            41.07905565962922,
            -1.1914713440164595,
            41.07905565962922
          ]
        }
      ],
      "bbox": [
        -1.1914713440164595,
        41.07905565962922,
        -1.1914713440164595,
        41.07905565962922
      ]
    },
    "44092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3481831161436189,
              40.155788410309306
            ]
          },
          "bbox": [
            -1.3481831161436189,
            40.155788410309306,
            -1.3481831161436189,
            40.155788410309306
          ]
        }
      ],
      "bbox": [
        -1.3481831161436189,
        40.155788410309306,
        -1.3481831161436189,
        40.155788410309306
      ]
    },
    "44093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8195607835730354,
              40.72950814690985
            ]
          },
          "bbox": [
            -0.8195607835730354,
            40.72950814690985,
            -0.8195607835730354,
            40.72950814690985
          ]
        }
      ],
      "bbox": [
        -0.8195607835730354,
        40.72950814690985,
        -0.8195607835730354,
        40.72950814690985
      ]
    },
    "44094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0229404527014325,
              40.456242155875564
            ]
          },
          "bbox": [
            -1.0229404527014325,
            40.456242155875564,
            -1.0229404527014325,
            40.456242155875564
          ]
        }
      ],
      "bbox": [
        -1.0229404527014325,
        40.456242155875564,
        -1.0229404527014325,
        40.456242155875564
      ]
    },
    "44096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5447904608944427,
              40.754276780435475
            ]
          },
          "bbox": [
            -0.5447904608944427,
            40.754276780435475,
            -0.5447904608944427,
            40.754276780435475
          ]
        }
      ],
      "bbox": [
        -0.5447904608944427,
        40.754276780435475,
        -0.5447904608944427,
        40.754276780435475
      ]
    },
    "44097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9510277842385619,
              40.52872861360198
            ]
          },
          "bbox": [
            -0.9510277842385619,
            40.52872861360198,
            -0.9510277842385619,
            40.52872861360198
          ]
        }
      ],
      "bbox": [
        -0.9510277842385619,
        40.52872861360198,
        -0.9510277842385619,
        40.52872861360198
      ]
    },
    "44099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8503104584591639,
              40.77761889445115
            ]
          },
          "bbox": [
            -0.8503104584591639,
            40.77761889445115,
            -0.8503104584591639,
            40.77761889445115
          ]
        }
      ],
      "bbox": [
        -0.8503104584591639,
        40.77761889445115,
        -0.8503104584591639,
        40.77761889445115
      ]
    },
    "44100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6272601120965772,
              40.88371712033634
            ]
          },
          "bbox": [
            -0.6272601120965772,
            40.88371712033634,
            -0.6272601120965772,
            40.88371712033634
          ]
        }
      ],
      "bbox": [
        -0.6272601120965772,
        40.88371712033634,
        -0.6272601120965772,
        40.88371712033634
      ]
    },
    "44101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.246516635092035,
              41.053674070467835
            ]
          },
          "bbox": [
            -1.246516635092035,
            41.053674070467835,
            -1.246516635092035,
            41.053674070467835
          ]
        }
      ],
      "bbox": [
        -1.246516635092035,
        41.053674070467835,
        -1.246516635092035,
        41.053674070467835
      ]
    },
    "44102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0869578399740853,
              40.99316239258331
            ]
          },
          "bbox": [
            -1.0869578399740853,
            40.99316239258331,
            -1.0869578399740853,
            40.99316239258331
          ]
        }
      ],
      "bbox": [
        -1.0869578399740853,
        40.99316239258331,
        -1.0869578399740853,
        40.99316239258331
      ]
    },
    "44103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8860207121961186,
              40.32323660820495
            ]
          },
          "bbox": [
            -0.8860207121961186,
            40.32323660820495,
            -0.8860207121961186,
            40.32323660820495
          ]
        }
      ],
      "bbox": [
        -0.8860207121961186,
        40.32323660820495,
        -0.8860207121961186,
        40.32323660820495
      ]
    },
    "44105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.0009743848824236342,
              40.898838175525626
            ]
          },
          "bbox": [
            -0.0009743848824236342,
            40.898838175525626,
            -0.0009743848824236342,
            40.898838175525626
          ]
        }
      ],
      "bbox": [
        -0.0009743848824236342,
        40.898838175525626,
        -0.0009743848824236342,
        40.898838175525626
      ]
    },
    "44106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5403963310803053,
              40.50484341121555
            ]
          },
          "bbox": [
            -0.5403963310803053,
            40.50484341121555,
            -0.5403963310803053,
            40.50484341121555
          ]
        }
      ],
      "bbox": [
        -0.5403963310803053,
        40.50484341121555,
        -0.5403963310803053,
        40.50484341121555
      ]
    },
    "44107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.27273121065910344,
              40.91518383502505
            ]
          },
          "bbox": [
            -0.27273121065910344,
            40.91518383502505,
            -0.27273121065910344,
            40.91518383502505
          ]
        }
      ],
      "bbox": [
        -0.27273121065910344,
        40.91518383502505,
        -0.27273121065910344,
        40.91518383502505
      ]
    },
    "44108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.0671630142971391,
              40.92784104161656
            ]
          },
          "bbox": [
            0.0671630142971391,
            40.92784104161656,
            0.0671630142971391,
            40.92784104161656
          ]
        }
      ],
      "bbox": [
        0.0671630142971391,
        40.92784104161656,
        0.0671630142971391,
        40.92784104161656
      ]
    },
    "44109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6282659998636837,
              40.344585825263735
            ]
          },
          "bbox": [
            -1.6282659998636837,
            40.344585825263735,
            -1.6282659998636837,
            40.344585825263735
          ]
        }
      ],
      "bbox": [
        -1.6282659998636837,
        40.344585825263735,
        -1.6282659998636837,
        40.344585825263735
      ]
    },
    "44110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0111946747189737,
              40.86567235339809
            ]
          },
          "bbox": [
            -1.0111946747189737,
            40.86567235339809,
            -1.0111946747189737,
            40.86567235339809
          ]
        }
      ],
      "bbox": [
        -1.0111946747189737,
        40.86567235339809,
        -1.0111946747189737,
        40.86567235339809
      ]
    },
    "44111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9787781430618986,
              40.68798247021363
            ]
          },
          "bbox": [
            -0.9787781430618986,
            40.68798247021363,
            -0.9787781430618986,
            40.68798247021363
          ]
        }
      ],
      "bbox": [
        -0.9787781430618986,
        40.68798247021363,
        -0.9787781430618986,
        40.68798247021363
      ]
    },
    "44112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.323262986278232,
              40.863535674857246
            ]
          },
          "bbox": [
            -1.323262986278232,
            40.863535674857246,
            -1.323262986278232,
            40.863535674857246
          ]
        }
      ],
      "bbox": [
        -1.323262986278232,
        40.863535674857246,
        -1.323262986278232,
        40.863535674857246
      ]
    },
    "44113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6076762578222183,
              40.16707997543738
            ]
          },
          "bbox": [
            -0.6076762578222183,
            40.16707997543738,
            -0.6076762578222183,
            40.16707997543738
          ]
        }
      ],
      "bbox": [
        -0.6076762578222183,
        40.16707997543738,
        -0.6076762578222183,
        40.16707997543738
      ]
    },
    "44114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.07064205546407076,
              40.817681458155796
            ]
          },
          "bbox": [
            0.07064205546407076,
            40.817681458155796,
            0.07064205546407076,
            40.817681458155796
          ]
        }
      ],
      "bbox": [
        0.07064205546407076,
        40.817681458155796,
        0.07064205546407076,
        40.817681458155796
      ]
    },
    "44115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8706017478832035,
              40.64519047048423
            ]
          },
          "bbox": [
            -0.8706017478832035,
            40.64519047048423,
            -0.8706017478832035,
            40.64519047048423
          ]
        }
      ],
      "bbox": [
        -0.8706017478832035,
        40.64519047048423,
        -0.8706017478832035,
        40.64519047048423
      ]
    },
    "44116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5864120971275154,
              40.82868993657349
            ]
          },
          "bbox": [
            -0.5864120971275154,
            40.82868993657349,
            -0.5864120971275154,
            40.82868993657349
          ]
        }
      ],
      "bbox": [
        -0.5864120971275154,
        40.82868993657349,
        -0.5864120971275154,
        40.82868993657349
      ]
    },
    "44117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.337349916869743,
              40.39403787072638
            ]
          },
          "bbox": [
            -1.337349916869743,
            40.39403787072638,
            -1.337349916869743,
            40.39403787072638
          ]
        }
      ],
      "bbox": [
        -1.337349916869743,
        40.39403787072638,
        -1.337349916869743,
        40.39403787072638
      ]
    },
    "44118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.18140647454869507,
              40.8576769878513
            ]
          },
          "bbox": [
            -0.18140647454869507,
            40.8576769878513,
            -0.18140647454869507,
            40.8576769878513
          ]
        }
      ],
      "bbox": [
        -0.18140647454869507,
        40.8576769878513,
        -0.18140647454869507,
        40.8576769878513
      ]
    },
    "44119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.720369187662811,
              40.44263923769661
            ]
          },
          "bbox": [
            -1.720369187662811,
            40.44263923769661,
            -1.720369187662811,
            40.44263923769661
          ]
        }
      ],
      "bbox": [
        -1.720369187662811,
        40.44263923769661,
        -1.720369187662811,
        40.44263923769661
      ]
    },
    "44120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7355023535353087,
              40.39646418848495
            ]
          },
          "bbox": [
            -1.7355023535353087,
            40.39646418848495,
            -1.7355023535353087,
            40.39646418848495
          ]
        }
      ],
      "bbox": [
        -1.7355023535353087,
        40.39646418848495,
        -1.7355023535353087,
        40.39646418848495
      ]
    },
    "44121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6980566126448914,
              40.43343320035851
            ]
          },
          "bbox": [
            -0.6980566126448914,
            40.43343320035851,
            -0.6980566126448914,
            40.43343320035851
          ]
        }
      ],
      "bbox": [
        -0.6980566126448914,
        40.43343320035851,
        -0.6980566126448914,
        40.43343320035851
      ]
    },
    "44122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4530332255107245,
              41.149573611704106
            ]
          },
          "bbox": [
            -0.4530332255107245,
            41.149573611704106,
            -0.4530332255107245,
            41.149573611704106
          ]
        }
      ],
      "bbox": [
        -0.4530332255107245,
        41.149573611704106,
        -0.4530332255107245,
        41.149573611704106
      ]
    },
    "44123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7695851059041039,
              40.67709186430556
            ]
          },
          "bbox": [
            -0.7695851059041039,
            40.67709186430556,
            -0.7695851059041039,
            40.67709186430556
          ]
        }
      ],
      "bbox": [
        -0.7695851059041039,
        40.67709186430556,
        -0.7695851059041039,
        40.67709186430556
      ]
    },
    "44124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8406396762667833,
              40.92580425498473
            ]
          },
          "bbox": [
            -0.8406396762667833,
            40.92580425498473,
            -0.8406396762667833,
            40.92580425498473
          ]
        }
      ],
      "bbox": [
        -0.8406396762667833,
        40.92580425498473,
        -0.8406396762667833,
        40.92580425498473
      ]
    },
    "44125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.944464469678483,
              41.015937281429906
            ]
          },
          "bbox": [
            -0.944464469678483,
            41.015937281429906,
            -0.944464469678483,
            41.015937281429906
          ]
        }
      ],
      "bbox": [
        -0.944464469678483,
        41.015937281429906,
        -0.944464469678483,
        41.015937281429906
      ]
    },
    "44126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3252066629170272,
              40.480510542559024
            ]
          },
          "bbox": [
            -0.3252066629170272,
            40.480510542559024,
            -0.3252066629170272,
            40.480510542559024
          ]
        }
      ],
      "bbox": [
        -0.3252066629170272,
        40.480510542559024,
        -0.3252066629170272,
        40.480510542559024
      ]
    },
    "44127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.418597242573843,
              40.23500103180886
            ]
          },
          "bbox": [
            -1.418597242573843,
            40.23500103180886,
            -1.418597242573843,
            40.23500103180886
          ]
        }
      ],
      "bbox": [
        -1.418597242573843,
        40.23500103180886,
        -1.418597242573843,
        40.23500103180886
      ]
    },
    "44128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7996730414538289,
              40.70433545439441
            ]
          },
          "bbox": [
            -0.7996730414538289,
            40.70433545439441,
            -0.7996730414538289,
            40.70433545439441
          ]
        }
      ],
      "bbox": [
        -0.7996730414538289,
        40.70433545439441,
        -0.7996730414538289,
        40.70433545439441
      ]
    },
    "44129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4002216806197918,
              41.238100154886055
            ]
          },
          "bbox": [
            -0.4002216806197918,
            41.238100154886055,
            -0.4002216806197918,
            41.238100154886055
          ]
        }
      ],
      "bbox": [
        -0.4002216806197918,
        41.238100154886055,
        -0.4002216806197918,
        41.238100154886055
      ]
    },
    "44130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7499214269594946,
              40.5460499254146
            ]
          },
          "bbox": [
            -0.7499214269594946,
            40.5460499254146,
            -0.7499214269594946,
            40.5460499254146
          ]
        }
      ],
      "bbox": [
        -0.7499214269594946,
        40.5460499254146,
        -0.7499214269594946,
        40.5460499254146
      ]
    },
    "44131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7748502724267039,
              40.957481510448055
            ]
          },
          "bbox": [
            -0.7748502724267039,
            40.957481510448055,
            -0.7748502724267039,
            40.957481510448055
          ]
        }
      ],
      "bbox": [
        -0.7748502724267039,
        40.957481510448055,
        -0.7748502724267039,
        40.957481510448055
      ]
    },
    "44132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1981779599577913,
              41.03398817419873
            ]
          },
          "bbox": [
            -1.1981779599577913,
            41.03398817419873,
            -1.1981779599577913,
            41.03398817419873
          ]
        }
      ],
      "bbox": [
        -1.1981779599577913,
        41.03398817419873,
        -1.1981779599577913,
        41.03398817419873
      ]
    },
    "44133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.186881230399007,
              41.107518641831696
            ]
          },
          "bbox": [
            -1.186881230399007,
            41.107518641831696,
            -1.186881230399007,
            41.107518641831696
          ]
        }
      ],
      "bbox": [
        -1.186881230399007,
        41.107518641831696,
        -1.186881230399007,
        41.107518641831696
      ]
    },
    "44135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2110733219041099,
              40.14427488381798
            ]
          },
          "bbox": [
            -1.2110733219041099,
            40.14427488381798,
            -1.2110733219041099,
            40.14427488381798
          ]
        }
      ],
      "bbox": [
        -1.2110733219041099,
        40.14427488381798,
        -1.2110733219041099,
        40.14427488381798
      ]
    },
    "44136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1114919097989806,
              40.72956771278254
            ]
          },
          "bbox": [
            -1.1114919097989806,
            40.72956771278254,
            -1.1114919097989806,
            40.72956771278254
          ]
        }
      ],
      "bbox": [
        -1.1114919097989806,
        40.72956771278254,
        -1.1114919097989806,
        40.72956771278254
      ]
    },
    "44137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.558416518595763,
              40.31383093422601
            ]
          },
          "bbox": [
            -0.558416518595763,
            40.31383093422601,
            -0.558416518595763,
            40.31383093422601
          ]
        }
      ],
      "bbox": [
        -0.558416518595763,
        40.31383093422601,
        -0.558416518595763,
        40.31383093422601
      ]
    },
    "44138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0678951830692203,
              41.065330084974605
            ]
          },
          "bbox": [
            -1.0678951830692203,
            41.065330084974605,
            -1.0678951830692203,
            41.065330084974605
          ]
        }
      ],
      "bbox": [
        -1.0678951830692203,
        41.065330084974605,
        -1.0678951830692203,
        41.065330084974605
      ]
    },
    "44141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.258947758605601,
              40.948194841147796
            ]
          },
          "bbox": [
            0.258947758605601,
            40.948194841147796,
            0.258947758605601,
            40.948194841147796
          ]
        }
      ],
      "bbox": [
        0.258947758605601,
        40.948194841147796,
        0.258947758605601,
        40.948194841147796
      ]
    },
    "44142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8944022825633589,
              40.96888233300863
            ]
          },
          "bbox": [
            -0.8944022825633589,
            40.96888233300863,
            -0.8944022825633589,
            40.96888233300863
          ]
        }
      ],
      "bbox": [
        -0.8944022825633589,
        40.96888233300863,
        -0.8944022825633589,
        40.96888233300863
      ]
    },
    "44143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8583062458942442,
              40.035613004909536
            ]
          },
          "bbox": [
            -0.8583062458942442,
            40.035613004909536,
            -0.8583062458942442,
            40.035613004909536
          ]
        }
      ],
      "bbox": [
        -0.8583062458942442,
        40.035613004909536,
        -0.8583062458942442,
        40.035613004909536
      ]
    },
    "44144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9103476064237577,
              40.83694890670712
            ]
          },
          "bbox": [
            -0.9103476064237577,
            40.83694890670712,
            -0.9103476064237577,
            40.83694890670712
          ]
        }
      ],
      "bbox": [
        -0.9103476064237577,
        40.83694890670712,
        -0.9103476064237577,
        40.83694890670712
      ]
    },
    "44145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.26860372178685504,
              40.84976236475619
            ]
          },
          "bbox": [
            -0.26860372178685504,
            40.84976236475619,
            -0.26860372178685504,
            40.84976236475619
          ]
        }
      ],
      "bbox": [
        -0.26860372178685504,
        40.84976236475619,
        -0.26860372178685504,
        40.84976236475619
      ]
    },
    "44146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5263410212961296,
              40.87320273407102
            ]
          },
          "bbox": [
            -0.5263410212961296,
            40.87320273407102,
            -0.5263410212961296,
            40.87320273407102
          ]
        }
      ],
      "bbox": [
        -0.5263410212961296,
        40.87320273407102,
        -0.5263410212961296,
        40.87320273407102
      ]
    },
    "44147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.08931067388879704,
              41.03147819525181
            ]
          },
          "bbox": [
            0.08931067388879704,
            41.03147819525181,
            0.08931067388879704,
            41.03147819525181
          ]
        }
      ],
      "bbox": [
        0.08931067388879704,
        41.03147819525181,
        0.08931067388879704,
        41.03147819525181
      ]
    },
    "44148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8787715113937589,
              40.730423125723455
            ]
          },
          "bbox": [
            -0.8787715113937589,
            40.730423125723455,
            -0.8787715113937589,
            40.730423125723455
          ]
        }
      ],
      "bbox": [
        -0.8787715113937589,
        40.730423125723455,
        -0.8787715113937589,
        40.730423125723455
      ]
    },
    "44149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3535576793561817,
              40.576066830518776
            ]
          },
          "bbox": [
            -0.3535576793561817,
            40.576066830518776,
            -0.3535576793561817,
            40.576066830518776
          ]
        }
      ],
      "bbox": [
        -0.3535576793561817,
        40.576066830518776,
        -0.3535576793561817,
        40.576066830518776
      ]
    },
    "44150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7012619197403034,
              40.56538583971089
            ]
          },
          "bbox": [
            -0.7012619197403034,
            40.56538583971089,
            -0.7012619197403034,
            40.56538583971089
          ]
        }
      ],
      "bbox": [
        -0.7012619197403034,
        40.56538583971089,
        -0.7012619197403034,
        40.56538583971089
      ]
    },
    "44151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.47400550769620275,
              40.812455240795245
            ]
          },
          "bbox": [
            -0.47400550769620275,
            40.812455240795245,
            -0.47400550769620275,
            40.812455240795245
          ]
        }
      ],
      "bbox": [
        -0.47400550769620275,
        40.812455240795245,
        -0.47400550769620275,
        40.812455240795245
      ]
    },
    "44152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.001880976649725,
              41.0515014952523
            ]
          },
          "bbox": [
            -1.001880976649725,
            41.0515014952523,
            -1.001880976649725,
            41.0515014952523
          ]
        }
      ],
      "bbox": [
        -1.001880976649725,
        41.0515014952523,
        -1.001880976649725,
        41.0515014952523
      ]
    },
    "44153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3517675471050743,
              40.77366395119146
            ]
          },
          "bbox": [
            -1.3517675471050743,
            40.77366395119146,
            -1.3517675471050743,
            40.77366395119146
          ]
        }
      ],
      "bbox": [
        -1.3517675471050743,
        40.77366395119146,
        -1.3517675471050743,
        40.77366395119146
      ]
    },
    "44154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.025740819141030796,
              40.78410025895426
            ]
          },
          "bbox": [
            -0.025740819141030796,
            40.78410025895426,
            -0.025740819141030796,
            40.78410025895426
          ]
        }
      ],
      "bbox": [
        -0.025740819141030796,
        40.78410025895426,
        -0.025740819141030796,
        40.78410025895426
      ]
    },
    "44155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7970275073325362,
              40.84956177594147
            ]
          },
          "bbox": [
            -0.7970275073325362,
            40.84956177594147,
            -0.7970275073325362,
            40.84956177594147
          ]
        }
      ],
      "bbox": [
        -0.7970275073325362,
        40.84956177594147,
        -0.7970275073325362,
        40.84956177594147
      ]
    },
    "44156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.797156890581355,
              40.46010616754954
            ]
          },
          "bbox": [
            -0.797156890581355,
            40.46010616754954,
            -0.797156890581355,
            40.46010616754954
          ]
        }
      ],
      "bbox": [
        -0.797156890581355,
        40.46010616754954,
        -0.797156890581355,
        40.46010616754954
      ]
    },
    "44157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4854471712425257,
              40.495420732982076
            ]
          },
          "bbox": [
            -1.4854471712425257,
            40.495420732982076,
            -1.4854471712425257,
            40.495420732982076
          ]
        }
      ],
      "bbox": [
        -1.4854471712425257,
        40.495420732982076,
        -1.4854471712425257,
        40.495420732982076
      ]
    },
    "44158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7350267325111922,
              40.250340749384854
            ]
          },
          "bbox": [
            -0.7350267325111922,
            40.250340749384854,
            -0.7350267325111922,
            40.250340749384854
          ]
        }
      ],
      "bbox": [
        -0.7350267325111922,
        40.250340749384854,
        -0.7350267325111922,
        40.250340749384854
      ]
    },
    "44159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5518075563127425,
              40.31941293344239
            ]
          },
          "bbox": [
            -1.5518075563127425,
            40.31941293344239,
            -1.5518075563127425,
            40.31941293344239
          ]
        }
      ],
      "bbox": [
        -1.5518075563127425,
        40.31941293344239,
        -1.5518075563127425,
        40.31941293344239
      ]
    },
    "44160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.42470921306800224,
              40.39181889782837
            ]
          },
          "bbox": [
            -0.42470921306800224,
            40.39181889782837,
            -0.42470921306800224,
            40.39181889782837
          ]
        }
      ],
      "bbox": [
        -0.42470921306800224,
        40.39181889782837,
        -0.42470921306800224,
        40.39181889782837
      ]
    },
    "44161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7681262638112165,
              41.07509217343473
            ]
          },
          "bbox": [
            -0.7681262638112165,
            41.07509217343473,
            -0.7681262638112165,
            41.07509217343473
          ]
        }
      ],
      "bbox": [
        -0.7681262638112165,
        41.07509217343473,
        -0.7681262638112165,
        41.07509217343473
      ]
    },
    "44163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6219236792457743,
              40.457561932446694
            ]
          },
          "bbox": [
            -1.6219236792457743,
            40.457561932446694,
            -1.6219236792457743,
            40.457561932446694
          ]
        }
      ],
      "bbox": [
        -1.6219236792457743,
        40.457561932446694,
        -1.6219236792457743,
        40.457561932446694
      ]
    },
    "44164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0778909080090195,
              41.14279487062412
            ]
          },
          "bbox": [
            -1.0778909080090195,
            41.14279487062412,
            -1.0778909080090195,
            41.14279487062412
          ]
        }
      ],
      "bbox": [
        -1.0778909080090195,
        41.14279487062412,
        -1.0778909080090195,
        41.14279487062412
      ]
    },
    "44165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.616962092597553,
              40.254164266508184
            ]
          },
          "bbox": [
            -0.616962092597553,
            40.254164266508184,
            -0.616962092597553,
            40.254164266508184
          ]
        }
      ],
      "bbox": [
        -0.616962092597553,
        40.254164266508184,
        -0.616962092597553,
        40.254164266508184
      ]
    },
    "44167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7240461270612737,
              40.90205704362921
            ]
          },
          "bbox": [
            -0.7240461270612737,
            40.90205704362921,
            -0.7240461270612737,
            40.90205704362921
          ]
        }
      ],
      "bbox": [
        -0.7240461270612737,
        40.90205704362921,
        -0.7240461270612737,
        40.90205704362921
      ]
    },
    "44168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5688423172146346,
              40.882513779467395
            ]
          },
          "bbox": [
            -1.5688423172146346,
            40.882513779467395,
            -1.5688423172146346,
            40.882513779467395
          ]
        }
      ],
      "bbox": [
        -1.5688423172146346,
        40.882513779467395,
        -1.5688423172146346,
        40.882513779467395
      ]
    },
    "44169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4763703158540082,
              40.73287774110978
            ]
          },
          "bbox": [
            -1.4763703158540082,
            40.73287774110978,
            -1.4763703158540082,
            40.73287774110978
          ]
        }
      ],
      "bbox": [
        -1.4763703158540082,
        40.73287774110978,
        -1.4763703158540082,
        40.73287774110978
      ]
    },
    "44171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6417872068017305,
              40.12386819981842
            ]
          },
          "bbox": [
            -0.6417872068017305,
            40.12386819981842,
            -0.6417872068017305,
            40.12386819981842
          ]
        }
      ],
      "bbox": [
        -0.6417872068017305,
        40.12386819981842,
        -0.6417872068017305,
        40.12386819981842
      ]
    },
    "44172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.649184050618014,
              40.99109193784328
            ]
          },
          "bbox": [
            -0.649184050618014,
            40.99109193784328,
            -0.649184050618014,
            40.99109193784328
          ]
        }
      ],
      "bbox": [
        -0.649184050618014,
        40.99109193784328,
        -0.649184050618014,
        40.99109193784328
      ]
    },
    "44173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4743052738369878,
              40.90039320375679
            ]
          },
          "bbox": [
            -0.4743052738369878,
            40.90039320375679,
            -0.4743052738369878,
            40.90039320375679
          ]
        }
      ],
      "bbox": [
        -0.4743052738369878,
        40.90039320375679,
        -0.4743052738369878,
        40.90039320375679
      ]
    },
    "44174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.638483659902904,
              40.54884518146955
            ]
          },
          "bbox": [
            -1.638483659902904,
            40.54884518146955,
            -1.638483659902904,
            40.54884518146955
          ]
        }
      ],
      "bbox": [
        -1.638483659902904,
        40.54884518146955,
        -1.638483659902904,
        40.54884518146955
      ]
    },
    "44175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9602636968444704,
              40.5831879459674
            ]
          },
          "bbox": [
            -0.9602636968444704,
            40.5831879459674,
            -0.9602636968444704,
            40.5831879459674
          ]
        }
      ],
      "bbox": [
        -0.9602636968444704,
        40.5831879459674,
        -0.9602636968444704,
        40.5831879459674
      ]
    },
    "44176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7323279291073777,
              40.775467288035344
            ]
          },
          "bbox": [
            -0.7323279291073777,
            40.775467288035344,
            -0.7323279291073777,
            40.775467288035344
          ]
        }
      ],
      "bbox": [
        -0.7323279291073777,
        40.775467288035344,
        -0.7323279291073777,
        40.775467288035344
      ]
    },
    "44177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0130678476960022,
              40.78701490830917
            ]
          },
          "bbox": [
            -1.0130678476960022,
            40.78701490830917,
            -1.0130678476960022,
            40.78701490830917
          ]
        }
      ],
      "bbox": [
        -1.0130678476960022,
        40.78701490830917,
        -1.0130678476960022,
        40.78701490830917
      ]
    },
    "44178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24507002640915568,
              40.769009524255374
            ]
          },
          "bbox": [
            -0.24507002640915568,
            40.769009524255374,
            -0.24507002640915568,
            40.769009524255374
          ]
        }
      ],
      "bbox": [
        -0.24507002640915568,
        40.769009524255374,
        -0.24507002640915568,
        40.769009524255374
      ]
    },
    "44179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.06544599984646682,
              40.7467579440396
            ]
          },
          "bbox": [
            0.06544599984646682,
            40.7467579440396,
            0.06544599984646682,
            40.7467579440396
          ]
        }
      ],
      "bbox": [
        0.06544599984646682,
        40.7467579440396,
        0.06544599984646682,
        40.7467579440396
      ]
    },
    "44180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4569129275417263,
              40.64866860358944
            ]
          },
          "bbox": [
            -1.4569129275417263,
            40.64866860358944,
            -1.4569129275417263,
            40.64866860358944
          ]
        }
      ],
      "bbox": [
        -1.4569129275417263,
        40.64866860358944,
        -1.4569129275417263,
        40.64866860358944
      ]
    },
    "44181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9984191894711595,
              40.4793776176113
            ]
          },
          "bbox": [
            -0.9984191894711595,
            40.4793776176113,
            -0.9984191894711595,
            40.4793776176113
          ]
        }
      ],
      "bbox": [
        -0.9984191894711595,
        40.4793776176113,
        -0.9984191894711595,
        40.4793776176113
      ]
    },
    "44182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9776809473192134,
              40.63397001060795
            ]
          },
          "bbox": [
            -0.9776809473192134,
            40.63397001060795,
            -0.9776809473192134,
            40.63397001060795
          ]
        }
      ],
      "bbox": [
        -0.9776809473192134,
        40.63397001060795,
        -0.9776809473192134,
        40.63397001060795
      ]
    },
    "44183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6014546699983826,
              40.61934497437057
            ]
          },
          "bbox": [
            -0.6014546699983826,
            40.61934497437057,
            -0.6014546699983826,
            40.61934497437057
          ]
        }
      ],
      "bbox": [
        -0.6014546699983826,
        40.61934497437057,
        -0.6014546699983826,
        40.61934497437057
      ]
    },
    "44184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8488589347174819,
              41.00227479041485
            ]
          },
          "bbox": [
            -0.8488589347174819,
            41.00227479041485,
            -0.8488589347174819,
            41.00227479041485
          ]
        }
      ],
      "bbox": [
        -0.8488589347174819,
        41.00227479041485,
        -0.8488589347174819,
        41.00227479041485
      ]
    },
    "44185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8713937375193929,
              40.502981653073924
            ]
          },
          "bbox": [
            -0.8713937375193929,
            40.502981653073924,
            -0.8713937375193929,
            40.502981653073924
          ]
        }
      ],
      "bbox": [
        -0.8713937375193929,
        40.502981653073924,
        -0.8713937375193929,
        40.502981653073924
      ]
    },
    "44187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.06314079388279406,
              40.88399182801662
            ]
          },
          "bbox": [
            0.06314079388279406,
            40.88399182801662,
            0.06314079388279406,
            40.88399182801662
          ]
        }
      ],
      "bbox": [
        0.06314079388279406,
        40.88399182801662,
        0.06314079388279406,
        40.88399182801662
      ]
    },
    "44189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4574759924792282,
              40.55866891041648
            ]
          },
          "bbox": [
            -1.4574759924792282,
            40.55866891041648,
            -1.4574759924792282,
            40.55866891041648
          ]
        }
      ],
      "bbox": [
        -1.4574759924792282,
        40.55866891041648,
        -1.4574759924792282,
        40.55866891041648
      ]
    },
    "44190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4915312940904164,
              40.77702984712583
            ]
          },
          "bbox": [
            -1.4915312940904164,
            40.77702984712583,
            -1.4915312940904164,
            40.77702984712583
          ]
        }
      ],
      "bbox": [
        -1.4915312940904164,
        40.77702984712583,
        -1.4915312940904164,
        40.77702984712583
      ]
    },
    "44191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4467022627999364,
              41.24639918265054
            ]
          },
          "bbox": [
            -0.4467022627999364,
            41.24639918265054,
            -0.4467022627999364,
            41.24639918265054
          ]
        }
      ],
      "bbox": [
        -0.4467022627999364,
        41.24639918265054,
        -0.4467022627999364,
        41.24639918265054
      ]
    },
    "44192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.947537838758743,
              40.207354635205576
            ]
          },
          "bbox": [
            -0.947537838758743,
            40.207354635205576,
            -0.947537838758743,
            40.207354635205576
          ]
        }
      ],
      "bbox": [
        -0.947537838758743,
        40.207354635205576,
        -0.947537838758743,
        40.207354635205576
      ]
    },
    "44193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.45340572530049494,
              40.29072509547097
            ]
          },
          "bbox": [
            -0.45340572530049494,
            40.29072509547097,
            -0.45340572530049494,
            40.29072509547097
          ]
        }
      ],
      "bbox": [
        -0.45340572530049494,
        40.29072509547097,
        -0.45340572530049494,
        40.29072509547097
      ]
    },
    "44194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.014212079443843652,
              40.84852452065762
            ]
          },
          "bbox": [
            0.014212079443843652,
            40.84852452065762,
            0.014212079443843652,
            40.84852452065762
          ]
        }
      ],
      "bbox": [
        0.014212079443843652,
        40.84852452065762,
        0.014212079443843652,
        40.84852452065762
      ]
    },
    "44195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9748088081261767,
              40.7316903626388
            ]
          },
          "bbox": [
            -0.9748088081261767,
            40.7316903626388,
            -0.9748088081261767,
            40.7316903626388
          ]
        }
      ],
      "bbox": [
        -0.9748088081261767,
        40.7316903626388,
        -0.9748088081261767,
        40.7316903626388
      ]
    },
    "44196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1352000441017114,
              40.125602223053555
            ]
          },
          "bbox": [
            -1.1352000441017114,
            40.125602223053555,
            -1.1352000441017114,
            40.125602223053555
          ]
        }
      ],
      "bbox": [
        -1.1352000441017114,
        40.125602223053555,
        -1.1352000441017114,
        40.125602223053555
      ]
    },
    "44197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5189053438666171,
              40.6206825493597
            ]
          },
          "bbox": [
            -1.5189053438666171,
            40.6206825493597,
            -1.5189053438666171,
            40.6206825493597
          ]
        }
      ],
      "bbox": [
        -1.5189053438666171,
        40.6206825493597,
        -1.5189053438666171,
        40.6206825493597
      ]
    },
    "44198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5072261951968413,
              40.36109288882365
            ]
          },
          "bbox": [
            -1.5072261951968413,
            40.36109288882365,
            -1.5072261951968413,
            40.36109288882365
          ]
        }
      ],
      "bbox": [
        -1.5072261951968413,
        40.36109288882365,
        -1.5072261951968413,
        40.36109288882365
      ]
    },
    "44199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2760363698530048,
              40.272127418270244
            ]
          },
          "bbox": [
            -1.2760363698530048,
            40.272127418270244,
            -1.2760363698530048,
            40.272127418270244
          ]
        }
      ],
      "bbox": [
        -1.2760363698530048,
        40.272127418270244,
        -1.2760363698530048,
        40.272127418270244
      ]
    },
    "44200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2124965107223775,
              40.76740389602861
            ]
          },
          "bbox": [
            -1.2124965107223775,
            40.76740389602861,
            -1.2124965107223775,
            40.76740389602861
          ]
        }
      ],
      "bbox": [
        -1.2124965107223775,
        40.76740389602861,
        -1.2124965107223775,
        40.76740389602861
      ]
    },
    "44201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.669233449611163,
              40.18308493989905
            ]
          },
          "bbox": [
            -0.669233449611163,
            40.18308493989905,
            -0.669233449611163,
            40.18308493989905
          ]
        }
      ],
      "bbox": [
        -0.669233449611163,
        40.18308493989905,
        -0.669233449611163,
        40.18308493989905
      ]
    },
    "44203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0070256244548783,
              40.95288087189924
            ]
          },
          "bbox": [
            -1.0070256244548783,
            40.95288087189924,
            -1.0070256244548783,
            40.95288087189924
          ]
        }
      ],
      "bbox": [
        -1.0070256244548783,
        40.95288087189924,
        -1.0070256244548783,
        40.95288087189924
      ]
    },
    "44204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4284770716974,
              40.333238325800394
            ]
          },
          "bbox": [
            -1.4284770716974,
            40.333238325800394,
            -1.4284770716974,
            40.333238325800394
          ]
        }
      ],
      "bbox": [
        -1.4284770716974,
        40.333238325800394,
        -1.4284770716974,
        40.333238325800394
      ]
    },
    "44205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3207415000980587,
              41.170969886961394
            ]
          },
          "bbox": [
            -0.3207415000980587,
            41.170969886961394,
            -0.3207415000980587,
            41.170969886961394
          ]
        }
      ],
      "bbox": [
        -0.3207415000980587,
        41.170969886961394,
        -0.3207415000980587,
        41.170969886961394
      ]
    },
    "44206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6798164216804119,
              40.08829961936628
            ]
          },
          "bbox": [
            -0.6798164216804119,
            40.08829961936628,
            -0.6798164216804119,
            40.08829961936628
          ]
        }
      ],
      "bbox": [
        -0.6798164216804119,
        40.08829961936628,
        -0.6798164216804119,
        40.08829961936628
      ]
    },
    "44207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3872271226019774,
              41.05447454471329
            ]
          },
          "bbox": [
            -1.3872271226019774,
            41.05447454471329,
            -1.3872271226019774,
            41.05447454471329
          ]
        }
      ],
      "bbox": [
        -1.3872271226019774,
        41.05447454471329,
        -1.3872271226019774,
        41.05447454471329
      ]
    },
    "44208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0904106160115672,
              41.119175350094885
            ]
          },
          "bbox": [
            -1.0904106160115672,
            41.119175350094885,
            -1.0904106160115672,
            41.119175350094885
          ]
        }
      ],
      "bbox": [
        -1.0904106160115672,
        41.119175350094885,
        -1.0904106160115672,
        41.119175350094885
      ]
    },
    "44209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3130673676910973,
              40.552555695021624
            ]
          },
          "bbox": [
            -1.3130673676910973,
            40.552555695021624,
            -1.3130673676910973,
            40.552555695021624
          ]
        }
      ],
      "bbox": [
        -1.3130673676910973,
        40.552555695021624,
        -1.3130673676910973,
        40.552555695021624
      ]
    },
    "44210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8575627849879528,
              40.13647029461081
            ]
          },
          "bbox": [
            -0.8575627849879528,
            40.13647029461081,
            -0.8575627849879528,
            40.13647029461081
          ]
        }
      ],
      "bbox": [
        -0.8575627849879528,
        40.13647029461081,
        -0.8575627849879528,
        40.13647029461081
      ]
    },
    "44211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9523302969591165,
              40.93083553551673
            ]
          },
          "bbox": [
            -0.9523302969591165,
            40.93083553551673,
            -0.9523302969591165,
            40.93083553551673
          ]
        }
      ],
      "bbox": [
        -0.9523302969591165,
        40.93083553551673,
        -0.9523302969591165,
        40.93083553551673
      ]
    },
    "44212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3481997030655925,
              40.82773428646008
            ]
          },
          "bbox": [
            -0.3481997030655925,
            40.82773428646008,
            -0.3481997030655925,
            40.82773428646008
          ]
        }
      ],
      "bbox": [
        -0.3481997030655925,
        40.82773428646008,
        -0.3481997030655925,
        40.82773428646008
      ]
    },
    "44213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3010847434509463,
              40.66581900414063
            ]
          },
          "bbox": [
            -1.3010847434509463,
            40.66581900414063,
            -1.3010847434509463,
            40.66581900414063
          ]
        }
      ],
      "bbox": [
        -1.3010847434509463,
        40.66581900414063,
        -1.3010847434509463,
        40.66581900414063
      ]
    },
    "44215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5031695571994468,
              40.28907512796263
            ]
          },
          "bbox": [
            -1.5031695571994468,
            40.28907512796263,
            -1.5031695571994468,
            40.28907512796263
          ]
        }
      ],
      "bbox": [
        -1.5031695571994468,
        40.28907512796263,
        -1.5031695571994468,
        40.28907512796263
      ]
    },
    "44216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1197976114408574,
              40.35648908297968
            ]
          },
          "bbox": [
            -1.1197976114408574,
            40.35648908297968,
            -1.1197976114408574,
            40.35648908297968
          ]
        }
      ],
      "bbox": [
        -1.1197976114408574,
        40.35648908297968,
        -1.1197976114408574,
        40.35648908297968
      ]
    },
    "44217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4956327354335122,
              40.22762263539315
            ]
          },
          "bbox": [
            -1.4956327354335122,
            40.22762263539315,
            -1.4956327354335122,
            40.22762263539315
          ]
        }
      ],
      "bbox": [
        -1.4956327354335122,
        40.22762263539315,
        -1.4956327354335122,
        40.22762263539315
      ]
    },
    "44218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3389953377395338,
              40.20769849024266
            ]
          },
          "bbox": [
            -1.3389953377395338,
            40.20769849024266,
            -1.3389953377395338,
            40.20769849024266
          ]
        }
      ],
      "bbox": [
        -1.3389953377395338,
        40.20769849024266,
        -1.3389953377395338,
        40.20769849024266
      ]
    },
    "44219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.418817697115134,
              40.94763906493156
            ]
          },
          "bbox": [
            -1.418817697115134,
            40.94763906493156,
            -1.418817697115134,
            40.94763906493156
          ]
        }
      ],
      "bbox": [
        -1.418817697115134,
        40.94763906493156,
        -1.418817697115134,
        40.94763906493156
      ]
    },
    "44220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4435834049878715,
              40.890461641008656
            ]
          },
          "bbox": [
            -1.4435834049878715,
            40.890461641008656,
            -1.4435834049878715,
            40.890461641008656
          ]
        }
      ],
      "bbox": [
        -1.4435834049878715,
        40.890461641008656,
        -1.4435834049878715,
        40.890461641008656
      ]
    },
    "44221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.08035969632862709,
              40.966332024631235
            ]
          },
          "bbox": [
            -0.08035969632862709,
            40.966332024631235,
            -0.08035969632862709,
            40.966332024631235
          ]
        }
      ],
      "bbox": [
        -0.08035969632862709,
        40.966332024631235,
        -0.08035969632862709,
        40.966332024631235
      ]
    },
    "44222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0739148243757866,
              40.91692509012662
            ]
          },
          "bbox": [
            -1.0739148243757866,
            40.91692509012662,
            -1.0739148243757866,
            40.91692509012662
          ]
        }
      ],
      "bbox": [
        -1.0739148243757866,
        40.91692509012662,
        -1.0739148243757866,
        40.91692509012662
      ]
    },
    "44223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.09666956637281786,
              40.76128010699666
            ]
          },
          "bbox": [
            -0.09666956637281786,
            40.76128010699666,
            -0.09666956637281786,
            40.76128010699666
          ]
        }
      ],
      "bbox": [
        -0.09666956637281786,
        40.76128010699666,
        -0.09666956637281786,
        40.76128010699666
      ]
    },
    "44224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7052512589697582,
              40.84539754067748
            ]
          },
          "bbox": [
            -0.7052512589697582,
            40.84539754067748,
            -0.7052512589697582,
            40.84539754067748
          ]
        }
      ],
      "bbox": [
        -0.7052512589697582,
        40.84539754067748,
        -0.7052512589697582,
        40.84539754067748
      ]
    },
    "44225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.12571587696530287,
              40.94603613853191
            ]
          },
          "bbox": [
            0.12571587696530287,
            40.94603613853191,
            0.12571587696530287,
            40.94603613853191
          ]
        }
      ],
      "bbox": [
        0.12571587696530287,
        40.94603613853191,
        0.12571587696530287,
        40.94603613853191
      ]
    },
    "44226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2743611470287306,
              40.626645648052126
            ]
          },
          "bbox": [
            -1.2743611470287306,
            40.626645648052126,
            -1.2743611470287306,
            40.626645648052126
          ]
        }
      ],
      "bbox": [
        -1.2743611470287306,
        40.626645648052126,
        -1.2743611470287306,
        40.626645648052126
      ]
    },
    "44227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.088718320308919,
              40.85093751130227
            ]
          },
          "bbox": [
            -1.088718320308919,
            40.85093751130227,
            -1.088718320308919,
            40.85093751130227
          ]
        }
      ],
      "bbox": [
        -1.088718320308919,
        40.85093751130227,
        -1.088718320308919,
        40.85093751130227
      ]
    },
    "44228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.239507143147002,
              40.597807521318465
            ]
          },
          "bbox": [
            -1.239507143147002,
            40.597807521318465,
            -1.239507143147002,
            40.597807521318465
          ]
        }
      ],
      "bbox": [
        -1.239507143147002,
        40.597807521318465,
        -1.239507143147002,
        40.597807521318465
      ]
    },
    "44229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5291934629727766,
              40.43029551148001
            ]
          },
          "bbox": [
            -1.5291934629727766,
            40.43029551148001,
            -1.5291934629727766,
            40.43029551148001
          ]
        }
      ],
      "bbox": [
        -1.5291934629727766,
        40.43029551148001,
        -1.5291934629727766,
        40.43029551148001
      ]
    },
    "44230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1209576993572857,
              40.913839592170326
            ]
          },
          "bbox": [
            -0.1209576993572857,
            40.913839592170326,
            -0.1209576993572857,
            40.913839592170326
          ]
        }
      ],
      "bbox": [
        -0.1209576993572857,
        40.913839592170326,
        -0.1209576993572857,
        40.913839592170326
      ]
    },
    "44231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9566822550019326,
              40.0103968935636
            ]
          },
          "bbox": [
            -0.9566822550019326,
            40.0103968935636,
            -0.9566822550019326,
            40.0103968935636
          ]
        }
      ],
      "bbox": [
        -0.9566822550019326,
        40.0103968935636,
        -0.9566822550019326,
        40.0103968935636
      ]
    },
    "44232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.331722648895083,
              40.815246482105465
            ]
          },
          "bbox": [
            -1.331722648895083,
            40.815246482105465,
            -1.331722648895083,
            40.815246482105465
          ]
        }
      ],
      "bbox": [
        -1.331722648895083,
        40.815246482105465,
        -1.331722648895083,
        40.815246482105465
      ]
    },
    "44234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2648018754616341,
              40.19881649241034
            ]
          },
          "bbox": [
            -1.2648018754616341,
            40.19881649241034,
            -1.2648018754616341,
            40.19881649241034
          ]
        }
      ],
      "bbox": [
        -1.2648018754616341,
        40.19881649241034,
        -1.2648018754616341,
        40.19881649241034
      ]
    },
    "44235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5743289503863676,
              40.42821764663954
            ]
          },
          "bbox": [
            -1.5743289503863676,
            40.42821764663954,
            -1.5743289503863676,
            40.42821764663954
          ]
        }
      ],
      "bbox": [
        -1.5743289503863676,
        40.42821764663954,
        -1.5743289503863676,
        40.42821764663954
      ]
    },
    "44236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.414771556755377,
              40.6136294508864
            ]
          },
          "bbox": [
            -0.414771556755377,
            40.6136294508864,
            -0.414771556755377,
            40.6136294508864
          ]
        }
      ],
      "bbox": [
        -0.414771556755377,
        40.6136294508864,
        -0.414771556755377,
        40.6136294508864
      ]
    },
    "44237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.48634111338384733,
              41.16433328470814
            ]
          },
          "bbox": [
            -0.48634111338384733,
            41.16433328470814,
            -0.48634111338384733,
            41.16433328470814
          ]
        }
      ],
      "bbox": [
        -0.48634111338384733,
        41.16433328470814,
        -0.48634111338384733,
        41.16433328470814
      ]
    },
    "44238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8804748396153742,
              40.80087806276965
            ]
          },
          "bbox": [
            -0.8804748396153742,
            40.80087806276965,
            -0.8804748396153742,
            40.80087806276965
          ]
        }
      ],
      "bbox": [
        -0.8804748396153742,
        40.80087806276965,
        -0.8804748396153742,
        40.80087806276965
      ]
    },
    "44239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0856607539575167,
              40.181256306909944
            ]
          },
          "bbox": [
            -1.0856607539575167,
            40.181256306909944,
            -1.0856607539575167,
            40.181256306909944
          ]
        }
      ],
      "bbox": [
        -1.0856607539575167,
        40.181256306909944,
        -1.0856607539575167,
        40.181256306909944
      ]
    },
    "44240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8132571844811871,
              40.22991422693927
            ]
          },
          "bbox": [
            -0.8132571844811871,
            40.22991422693927,
            -0.8132571844811871,
            40.22991422693927
          ]
        }
      ],
      "bbox": [
        -0.8132571844811871,
        40.22991422693927,
        -0.8132571844811871,
        40.22991422693927
      ]
    },
    "44241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.020336338818252964,
              41.00283019822907
            ]
          },
          "bbox": [
            -0.020336338818252964,
            41.00283019822907,
            -0.020336338818252964,
            41.00283019822907
          ]
        }
      ],
      "bbox": [
        -0.020336338818252964,
        41.00283019822907,
        -0.020336338818252964,
        41.00283019822907
      ]
    },
    "44243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4055067503265923,
              40.294698936358
            ]
          },
          "bbox": [
            -1.4055067503265923,
            40.294698936358,
            -1.4055067503265923,
            40.294698936358
          ]
        }
      ],
      "bbox": [
        -1.4055067503265923,
        40.294698936358,
        -1.4055067503265923,
        40.294698936358
      ]
    },
    "44244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6046327698581818,
              40.40593353173572
            ]
          },
          "bbox": [
            -0.6046327698581818,
            40.40593353173572,
            -0.6046327698581818,
            40.40593353173572
          ]
        }
      ],
      "bbox": [
        -0.6046327698581818,
        40.40593353173572,
        -0.6046327698581818,
        40.40593353173572
      ]
    },
    "44245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.08782976800779693,
              40.975292234423385
            ]
          },
          "bbox": [
            0.08782976800779693,
            40.975292234423385,
            0.08782976800779693,
            40.975292234423385
          ]
        }
      ],
      "bbox": [
        0.08782976800779693,
        40.975292234423385,
        0.08782976800779693,
        40.975292234423385
      ]
    },
    "44246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.1453573453776859,
              40.85403817872366
            ]
          },
          "bbox": [
            0.1453573453776859,
            40.85403817872366,
            0.1453573453776859,
            40.85403817872366
          ]
        }
      ],
      "bbox": [
        0.1453573453776859,
        40.85403817872366,
        0.1453573453776859,
        40.85403817872366
      ]
    },
    "44247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.01613701967451002,
              40.964293670610736
            ]
          },
          "bbox": [
            0.01613701967451002,
            40.964293670610736,
            0.01613701967451002,
            40.964293670610736
          ]
        }
      ],
      "bbox": [
        0.01613701967451002,
        40.964293670610736,
        0.01613701967451002,
        40.964293670610736
      ]
    },
    "44249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.574892704858253,
              40.24370941043976
            ]
          },
          "bbox": [
            -1.574892704858253,
            40.24370941043976,
            -1.574892704858253,
            40.24370941043976
          ]
        }
      ],
      "bbox": [
        -1.574892704858253,
        40.24370941043976,
        -1.574892704858253,
        40.24370941043976
      ]
    },
    "44250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4118016655847194,
              40.151152922820316
            ]
          },
          "bbox": [
            -1.4118016655847194,
            40.151152922820316,
            -1.4118016655847194,
            40.151152922820316
          ]
        }
      ],
      "bbox": [
        -1.4118016655847194,
        40.151152922820316,
        -1.4118016655847194,
        40.151152922820316
      ]
    },
    "44251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.372842730409646,
              40.69670995336335
            ]
          },
          "bbox": [
            -1.372842730409646,
            40.69670995336335,
            -1.372842730409646,
            40.69670995336335
          ]
        }
      ],
      "bbox": [
        -1.372842730409646,
        40.69670995336335,
        -1.372842730409646,
        40.69670995336335
      ]
    },
    "44252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2309422711805156,
              41.11446840622692
            ]
          },
          "bbox": [
            -1.2309422711805156,
            41.11446840622692,
            -1.2309422711805156,
            41.11446840622692
          ]
        }
      ],
      "bbox": [
        -1.2309422711805156,
        41.11446840622692,
        -1.2309422711805156,
        41.11446840622692
      ]
    },
    "44256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0113596806636735,
              40.899339788087495
            ]
          },
          "bbox": [
            -1.0113596806636735,
            40.899339788087495,
            -1.0113596806636735,
            40.899339788087495
          ]
        }
      ],
      "bbox": [
        -1.0113596806636735,
        40.899339788087495,
        -1.0113596806636735,
        40.899339788087495
      ]
    },
    "44257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6750028385596416,
              40.39618503669926
            ]
          },
          "bbox": [
            -1.6750028385596416,
            40.39618503669926,
            -1.6750028385596416,
            40.39618503669926
          ]
        }
      ],
      "bbox": [
        -1.6750028385596416,
        40.39618503669926,
        -1.6750028385596416,
        40.39618503669926
      ]
    },
    "44258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4895761125745615,
              40.68326311294945
            ]
          },
          "bbox": [
            -1.4895761125745615,
            40.68326311294945,
            -1.4895761125745615,
            40.68326311294945
          ]
        }
      ],
      "bbox": [
        -1.4895761125745615,
        40.68326311294945,
        -1.4895761125745615,
        40.68326311294945
      ]
    },
    "44260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5051699419784197,
              40.65601671498283
            ]
          },
          "bbox": [
            -0.5051699419784197,
            40.65601671498283,
            -0.5051699419784197,
            40.65601671498283
          ]
        }
      ],
      "bbox": [
        -0.5051699419784197,
        40.65601671498283,
        -0.5051699419784197,
        40.65601671498283
      ]
    },
    "44261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2431484913994675,
              40.52467142360974
            ]
          },
          "bbox": [
            -1.2431484913994675,
            40.52467142360974,
            -1.2431484913994675,
            40.52467142360974
          ]
        }
      ],
      "bbox": [
        -1.2431484913994675,
        40.52467142360974,
        -1.2431484913994675,
        40.52467142360974
      ]
    },
    "44262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6476391401432012,
              40.5117923498389
            ]
          },
          "bbox": [
            -0.6476391401432012,
            40.5117923498389,
            -0.6476391401432012,
            40.5117923498389
          ]
        }
      ],
      "bbox": [
        -0.6476391401432012,
        40.5117923498389,
        -0.6476391401432012,
        40.5117923498389
      ]
    },
    "44263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1671008630268038,
              40.26845965120384
            ]
          },
          "bbox": [
            -1.1671008630268038,
            40.26845965120384,
            -1.1671008630268038,
            40.26845965120384
          ]
        }
      ],
      "bbox": [
        -1.1671008630268038,
        40.26845965120384,
        -1.1671008630268038,
        40.26845965120384
      ]
    },
    "44264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2005194292551191,
              40.21783553655309
            ]
          },
          "bbox": [
            -1.2005194292551191,
            40.21783553655309,
            -1.2005194292551191,
            40.21783553655309
          ]
        }
      ],
      "bbox": [
        -1.2005194292551191,
        40.21783553655309,
        -1.2005194292551191,
        40.21783553655309
      ]
    },
    "44265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5902673106514984,
              41.27157555444513
            ]
          },
          "bbox": [
            -0.5902673106514984,
            41.27157555444513,
            -0.5902673106514984,
            41.27157555444513
          ]
        }
      ],
      "bbox": [
        -0.5902673106514984,
        41.27157555444513,
        -0.5902673106514984,
        41.27157555444513
      ]
    },
    "44266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0904594025781675,
              40.674878662771015
            ]
          },
          "bbox": [
            -1.0904594025781675,
            40.674878662771015,
            -1.0904594025781675,
            40.674878662771015
          ]
        }
      ],
      "bbox": [
        -1.0904594025781675,
        40.674878662771015,
        -1.0904594025781675,
        40.674878662771015
      ]
    },
    "44267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9164769778372028,
              40.88481020642433
            ]
          },
          "bbox": [
            -0.9164769778372028,
            40.88481020642433,
            -0.9164769778372028,
            40.88481020642433
          ]
        }
      ],
      "bbox": [
        -0.9164769778372028,
        40.88481020642433,
        -0.9164769778372028,
        40.88481020642433
      ]
    },
    "44268": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6175488571453864,
              40.77640549375721
            ]
          },
          "bbox": [
            -0.6175488571453864,
            40.77640549375721,
            -0.6175488571453864,
            40.77640549375721
          ]
        }
      ],
      "bbox": [
        -0.6175488571453864,
        40.77640549375721,
        -0.6175488571453864,
        40.77640549375721
      ]
    },
    "45001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.989372921467586,
              39.72870537882882
            ]
          },
          "bbox": [
            -3.989372921467586,
            39.72870537882882,
            -3.989372921467586,
            39.72870537882882
          ]
        }
      ],
      "bbox": [
        -3.989372921467586,
        39.72870537882882,
        -3.989372921467586,
        39.72870537882882
      ]
    },
    "45002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.802660195499215,
              40.00561471146075
            ]
          },
          "bbox": [
            -3.802660195499215,
            40.00561471146075,
            -3.802660195499215,
            40.00561471146075
          ]
        }
      ],
      "bbox": [
        -3.802660195499215,
        40.00561471146075,
        -3.802660195499215,
        40.00561471146075
      ]
    },
    "45003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.222440284286514,
              39.88927856134355
            ]
          },
          "bbox": [
            -4.222440284286514,
            39.88927856134355,
            -4.222440284286514,
            39.88927856134355
          ]
        }
      ],
      "bbox": [
        -4.222440284286514,
        39.88927856134355,
        -4.222440284286514,
        39.88927856134355
      ]
    },
    "45004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.368944961482472,
              40.00001171598694
            ]
          },
          "bbox": [
            -4.368944961482472,
            40.00001171598694,
            -4.368944961482472,
            40.00001171598694
          ]
        }
      ],
      "bbox": [
        -4.368944961482472,
        40.00001171598694,
        -4.368944961482472,
        40.00001171598694
      ]
    },
    "45005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.110844893641185,
              39.905322600888496
            ]
          },
          "bbox": [
            -5.110844893641185,
            39.905322600888496,
            -5.110844893641185,
            39.905322600888496
          ]
        }
      ],
      "bbox": [
        -5.110844893641185,
        39.905322600888496,
        -5.110844893641185,
        39.905322600888496
      ]
    },
    "45006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.851631585098504,
              39.773305406004965
            ]
          },
          "bbox": [
            -4.851631585098504,
            39.773305406004965,
            -4.851631585098504,
            39.773305406004965
          ]
        }
      ],
      "bbox": [
        -4.851631585098504,
        39.773305406004965,
        -4.851631585098504,
        39.773305406004965
      ]
    },
    "45007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1170847957623495,
              39.808280296721286
            ]
          },
          "bbox": [
            -5.1170847957623495,
            39.808280296721286,
            -5.1170847957623495,
            39.808280296721286
          ]
        }
      ],
      "bbox": [
        -5.1170847957623495,
        39.808280296721286,
        -5.1170847957623495,
        39.808280296721286
      ]
    },
    "45008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.47222425022813,
              40.193209164284724
            ]
          },
          "bbox": [
            -4.47222425022813,
            40.193209164284724,
            -4.47222425022813,
            40.193209164284724
          ]
        }
      ],
      "bbox": [
        -4.47222425022813,
        40.193209164284724,
        -4.47222425022813,
        40.193209164284724
      ]
    },
    "45009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.013370945967075,
              39.73185324114195
            ]
          },
          "bbox": [
            -5.013370945967075,
            39.73185324114195,
            -5.013370945967075,
            39.73185324114195
          ]
        }
      ],
      "bbox": [
        -5.013370945967075,
        39.73185324114195,
        -5.013370945967075,
        39.73185324114195
      ]
    },
    "45010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.106162758351942,
              39.64140503740686
            ]
          },
          "bbox": [
            -5.106162758351942,
            39.64140503740686,
            -5.106162758351942,
            39.64140503740686
          ]
        }
      ],
      "bbox": [
        -5.106162758351942,
        39.64140503740686,
        -5.106162758351942,
        39.64140503740686
      ]
    },
    "45011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7483926886328875,
              40.186229158361215
            ]
          },
          "bbox": [
            -4.7483926886328875,
            40.186229158361215,
            -4.7483926886328875,
            40.186229158361215
          ]
        }
      ],
      "bbox": [
        -4.7483926886328875,
        40.186229158361215,
        -4.7483926886328875,
        40.186229158361215
      ]
    },
    "45012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.845097545886785,
              39.801989546779915
            ]
          },
          "bbox": [
            -3.845097545886785,
            39.801989546779915,
            -3.845097545886785,
            39.801989546779915
          ]
        }
      ],
      "bbox": [
        -3.845097545886785,
        39.801989546779915,
        -3.845097545886785,
        39.801989546779915
      ]
    },
    "45013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.38374073306544,
              40.24669248806199
            ]
          },
          "bbox": [
            -4.38374073306544,
            40.24669248806199,
            -4.38374073306544,
            40.24669248806199
          ]
        }
      ],
      "bbox": [
        -4.38374073306544,
        40.24669248806199,
        -4.38374073306544,
        40.24669248806199
      ]
    },
    "45014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.766957681943342,
              39.982345532596334
            ]
          },
          "bbox": [
            -3.766957681943342,
            39.982345532596334,
            -3.766957681943342,
            39.982345532596334
          ]
        }
      ],
      "bbox": [
        -3.766957681943342,
        39.982345532596334,
        -3.766957681943342,
        39.982345532596334
      ]
    },
    "45015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.100820476137023,
              40.0539971897015
            ]
          },
          "bbox": [
            -4.100820476137023,
            40.0539971897015,
            -4.100820476137023,
            40.0539971897015
          ]
        }
      ],
      "bbox": [
        -4.100820476137023,
        40.0539971897015,
        -4.100820476137023,
        40.0539971897015
      ]
    },
    "45016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.093838017810705,
              39.81808655638499
            ]
          },
          "bbox": [
            -4.093838017810705,
            39.81808655638499,
            -4.093838017810705,
            39.81808655638499
          ]
        }
      ],
      "bbox": [
        -4.093838017810705,
        39.81808655638499,
        -4.093838017810705,
        39.81808655638499
      ]
    },
    "45017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.135040825333272,
              39.774794415444504
            ]
          },
          "bbox": [
            -5.135040825333272,
            39.774794415444504,
            -5.135040825333272,
            39.774794415444504
          ]
        }
      ],
      "bbox": [
        -5.135040825333272,
        39.774794415444504,
        -5.135040825333272,
        39.774794415444504
      ]
    },
    "45018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.235150092489253,
              39.98098219908814
            ]
          },
          "bbox": [
            -4.235150092489253,
            39.98098219908814,
            -4.235150092489253,
            39.98098219908814
          ]
        }
      ],
      "bbox": [
        -4.235150092489253,
        39.98098219908814,
        -4.235150092489253,
        39.98098219908814
      ]
    },
    "45019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.074212361711534,
              39.94795444943974
            ]
          },
          "bbox": [
            -4.074212361711534,
            39.94795444943974,
            -4.074212361711534,
            39.94795444943974
          ]
        }
      ],
      "bbox": [
        -4.074212361711534,
        39.94795444943974,
        -4.074212361711534,
        39.94795444943974
      ]
    },
    "45020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.939138467575999,
              39.75104130560015
            ]
          },
          "bbox": [
            -4.939138467575999,
            39.75104130560015,
            -4.939138467575999,
            39.75104130560015
          ]
        }
      ],
      "bbox": [
        -4.939138467575999,
        39.75104130560015,
        -4.939138467575999,
        39.75104130560015
      ]
    },
    "45021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7221172887926586,
              40.051492472212416
            ]
          },
          "bbox": [
            -3.7221172887926586,
            40.051492472212416,
            -3.7221172887926586,
            40.051492472212416
          ]
        }
      ],
      "bbox": [
        -3.7221172887926586,
        40.051492472212416,
        -3.7221172887926586,
        40.051492472212416
      ]
    },
    "45022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.874452087249871,
              40.16957811399918
            ]
          },
          "bbox": [
            -4.874452087249871,
            40.16957811399918,
            -4.874452087249871,
            40.16957811399918
          ]
        }
      ],
      "bbox": [
        -4.874452087249871,
        40.16957811399918,
        -4.874452087249871,
        40.16957811399918
      ]
    },
    "45023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9918634188564335,
              39.78141122804488
            ]
          },
          "bbox": [
            -3.9918634188564335,
            39.78141122804488,
            -3.9918634188564335,
            39.78141122804488
          ]
        }
      ],
      "bbox": [
        -3.9918634188564335,
        39.78141122804488,
        -3.9918634188564335,
        39.78141122804488
      ]
    },
    "45024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.289585335915755,
              39.88666651249412
            ]
          },
          "bbox": [
            -4.289585335915755,
            39.88666651249412,
            -4.289585335915755,
            39.88666651249412
          ]
        }
      ],
      "bbox": [
        -4.289585335915755,
        39.88666651249412,
        -4.289585335915755,
        39.88666651249412
      ]
    },
    "45025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9508549276299423,
              40.001341392114206
            ]
          },
          "bbox": [
            -3.9508549276299423,
            40.001341392114206,
            -3.9508549276299423,
            40.001341392114206
          ]
        }
      ],
      "bbox": [
        -3.9508549276299423,
        40.001341392114206,
        -3.9508549276299423,
        40.001341392114206
      ]
    },
    "45026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5374230275482614,
              39.89298188850261
            ]
          },
          "bbox": [
            -3.5374230275482614,
            39.89298188850261,
            -3.5374230275482614,
            39.89298188850261
          ]
        }
      ],
      "bbox": [
        -3.5374230275482614,
        39.89298188850261,
        -3.5374230275482614,
        39.89298188850261
      ]
    },
    "45027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.091627474858135,
              39.82533658670045
            ]
          },
          "bbox": [
            -3.091627474858135,
            39.82533658670045,
            -3.091627474858135,
            39.82533658670045
          ]
        }
      ],
      "bbox": [
        -3.091627474858135,
        39.82533658670045,
        -3.091627474858135,
        39.82533658670045
      ]
    },
    "45028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.017712154766219,
              39.877657903848956
            ]
          },
          "bbox": [
            -5.017712154766219,
            39.877657903848956,
            -5.017712154766219,
            39.877657903848956
          ]
        }
      ],
      "bbox": [
        -5.017712154766219,
        39.877657903848956,
        -5.017712154766219,
        39.877657903848956
      ]
    },
    "45029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.252127036820248,
              39.869582228700125
            ]
          },
          "bbox": [
            -5.252127036820248,
            39.869582228700125,
            -5.252127036820248,
            39.869582228700125
          ]
        }
      ],
      "bbox": [
        -5.252127036820248,
        39.869582228700125,
        -5.252127036820248,
        39.869582228700125
      ]
    },
    "45030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3031253459063805,
              39.98053947757111
            ]
          },
          "bbox": [
            -5.3031253459063805,
            39.98053947757111,
            -5.3031253459063805,
            39.98053947757111
          ]
        }
      ],
      "bbox": [
        -5.3031253459063805,
        39.98053947757111,
        -5.3031253459063805,
        39.98053947757111
      ]
    },
    "45031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.115730196458969,
              40.10746980280033
            ]
          },
          "bbox": [
            -4.115730196458969,
            40.10746980280033,
            -4.115730196458969,
            40.10746980280033
          ]
        }
      ],
      "bbox": [
        -4.115730196458969,
        40.10746980280033,
        -4.115730196458969,
        40.10746980280033
      ]
    },
    "45032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.084017078902558,
              40.009328383096545
            ]
          },
          "bbox": [
            -4.084017078902558,
            40.009328383096545,
            -4.084017078902558,
            40.009328383096545
          ]
        }
      ],
      "bbox": [
        -4.084017078902558,
        40.009328383096545,
        -4.084017078902558,
        40.009328383096545
      ]
    },
    "45033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.050768356266016,
              39.575524741415514
            ]
          },
          "bbox": [
            -5.050768356266016,
            39.575524741415514,
            -5.050768356266016,
            39.575524741415514
          ]
        }
      ],
      "bbox": [
        -5.050768356266016,
        39.575524741415514,
        -5.050768356266016,
        39.575524741415514
      ]
    },
    "45034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4787214833534414,
              39.39990118862228
            ]
          },
          "bbox": [
            -3.4787214833534414,
            39.39990118862228,
            -3.4787214833534414,
            39.39990118862228
          ]
        }
      ],
      "bbox": [
        -3.4787214833534414,
        39.39990118862228,
        -3.4787214833534414,
        39.39990118862228
      ]
    },
    "45035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.641411417691217,
              40.067916040578886
            ]
          },
          "bbox": [
            -4.641411417691217,
            40.067916040578886,
            -4.641411417691217,
            40.067916040578886
          ]
        }
      ],
      "bbox": [
        -4.641411417691217,
        40.067916040578886,
        -4.641411417691217,
        40.067916040578886
      ]
    },
    "45036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.398988921432919,
              39.92885228447886
            ]
          },
          "bbox": [
            -4.398988921432919,
            39.92885228447886,
            -4.398988921432919,
            39.92885228447886
          ]
        }
      ],
      "bbox": [
        -4.398988921432919,
        39.92885228447886,
        -4.398988921432919,
        39.92885228447886
      ]
    },
    "45037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.491543742308388,
              39.845568843177816
            ]
          },
          "bbox": [
            -4.491543742308388,
            39.845568843177816,
            -4.491543742308388,
            39.845568843177816
          ]
        }
      ],
      "bbox": [
        -4.491543742308388,
        39.845568843177816,
        -4.491543742308388,
        39.845568843177816
      ]
    },
    "45038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.925515945778371,
              40.176802229025434
            ]
          },
          "bbox": [
            -3.925515945778371,
            40.176802229025434,
            -3.925515945778371,
            40.176802229025434
          ]
        }
      ],
      "bbox": [
        -3.925515945778371,
        40.176802229025434,
        -3.925515945778371,
        40.176802229025434
      ]
    },
    "45039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.457790568927143,
              39.96640880168514
            ]
          },
          "bbox": [
            -4.457790568927143,
            39.96640880168514,
            -4.457790568927143,
            39.96640880168514
          ]
        }
      ],
      "bbox": [
        -4.457790568927143,
        39.96640880168514,
        -4.457790568927143,
        39.96640880168514
      ]
    },
    "45040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.531184697753076,
              40.05170545706809
            ]
          },
          "bbox": [
            -4.531184697753076,
            40.05170545706809,
            -4.531184697753076,
            40.05170545706809
          ]
        }
      ],
      "bbox": [
        -4.531184697753076,
        40.05170545706809,
        -4.531184697753076,
        40.05170545706809
      ]
    },
    "45041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.051828283297271,
              40.200350286666705
            ]
          },
          "bbox": [
            -4.051828283297271,
            40.200350286666705,
            -4.051828283297271,
            40.200350286666705
          ]
        }
      ],
      "bbox": [
        -4.051828283297271,
        40.200350286666705,
        -4.051828283297271,
        40.200350286666705
      ]
    },
    "45042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1079527326441525,
              39.765981612943555
            ]
          },
          "bbox": [
            -4.1079527326441525,
            39.765981612943555,
            -4.1079527326441525,
            39.765981612943555
          ]
        }
      ],
      "bbox": [
        -4.1079527326441525,
        39.765981612943555,
        -4.1079527326441525,
        39.765981612943555
      ]
    },
    "45043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.686768810950726,
              40.079168321394434
            ]
          },
          "bbox": [
            -4.686768810950726,
            40.079168321394434,
            -4.686768810950726,
            40.079168321394434
          ]
        }
      ],
      "bbox": [
        -4.686768810950726,
        40.079168321394434,
        -4.686768810950726,
        40.079168321394434
      ]
    },
    "45045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.702797127890803,
              40.0003553059134
            ]
          },
          "bbox": [
            -4.702797127890803,
            40.0003553059134,
            -4.702797127890803,
            40.0003553059134
          ]
        }
      ],
      "bbox": [
        -4.702797127890803,
        40.0003553059134,
        -4.702797127890803,
        40.0003553059134
      ]
    },
    "45046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.563997640247851,
              39.9387693297906
            ]
          },
          "bbox": [
            -4.563997640247851,
            39.9387693297906,
            -4.563997640247851,
            39.9387693297906
          ]
        }
      ],
      "bbox": [
        -4.563997640247851,
        39.9387693297906,
        -4.563997640247851,
        39.9387693297906
      ]
    },
    "45047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9289876498727425,
              40.0911514220552
            ]
          },
          "bbox": [
            -3.9289876498727425,
            40.0911514220552,
            -3.9289876498727425,
            40.0911514220552
          ]
        }
      ],
      "bbox": [
        -3.9289876498727425,
        40.0911514220552,
        -3.9289876498727425,
        40.0911514220552
      ]
    },
    "45048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.583354388388569,
              40.021378470087924
            ]
          },
          "bbox": [
            -4.583354388388569,
            40.021378470087924,
            -4.583354388388569,
            40.021378470087924
          ]
        }
      ],
      "bbox": [
        -4.583354388388569,
        40.021378470087924,
        -4.583354388388569,
        40.021378470087924
      ]
    },
    "45049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.830551221611104,
              40.05577721672336
            ]
          },
          "bbox": [
            -4.830551221611104,
            40.05577721672336,
            -4.830551221611104,
            40.05577721672336
          ]
        }
      ],
      "bbox": [
        -4.830551221611104,
        40.05577721672336,
        -4.830551221611104,
        40.05577721672336
      ]
    },
    "45050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6309972195999083,
              39.944644912411626
            ]
          },
          "bbox": [
            -3.6309972195999083,
            39.944644912411626,
            -3.6309972195999083,
            39.944644912411626
          ]
        }
      ],
      "bbox": [
        -3.6309972195999083,
        39.944644912411626,
        -3.6309972195999083,
        39.944644912411626
      ]
    },
    "45051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8541757229054605,
              40.00848233272438
            ]
          },
          "bbox": [
            -3.8541757229054605,
            40.00848233272438,
            -3.8541757229054605,
            40.00848233272438
          ]
        }
      ],
      "bbox": [
        -3.8541757229054605,
        40.00848233272438,
        -3.8541757229054605,
        40.00848233272438
      ]
    },
    "45052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.025557231541973,
              39.796509314018834
            ]
          },
          "bbox": [
            -4.025557231541973,
            39.796509314018834,
            -4.025557231541973,
            39.796509314018834
          ]
        }
      ],
      "bbox": [
        -4.025557231541973,
        39.796509314018834,
        -4.025557231541973,
        39.796509314018834
      ]
    },
    "45053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6890100287170466,
              39.479819518705376
            ]
          },
          "bbox": [
            -3.6890100287170466,
            39.479819518705376,
            -3.6890100287170466,
            39.479819518705376
          ]
        }
      ],
      "bbox": [
        -3.6890100287170466,
        39.479819518705376,
        -3.6890100287170466,
        39.479819518705376
      ]
    },
    "45054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1897081296797984,
              39.759905807241104
            ]
          },
          "bbox": [
            -3.1897081296797984,
            39.759905807241104,
            -3.1897081296797984,
            39.759905807241104
          ]
        }
      ],
      "bbox": [
        -3.1897081296797984,
        39.759905807241104,
        -3.1897081296797984,
        39.759905807241104
      ]
    },
    "45055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2161163736820075,
              39.6615259909801
            ]
          },
          "bbox": [
            -4.2161163736820075,
            39.6615259909801,
            -4.2161163736820075,
            39.6615259909801
          ]
        }
      ],
      "bbox": [
        -4.2161163736820075,
        39.6615259909801,
        -4.2161163736820075,
        39.6615259909801
      ]
    },
    "45056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.048956958103722,
              40.11207069439159
            ]
          },
          "bbox": [
            -4.048956958103722,
            40.11207069439159,
            -4.048956958103722,
            40.11207069439159
          ]
        }
      ],
      "bbox": [
        -4.048956958103722,
        40.11207069439159,
        -4.048956958103722,
        40.11207069439159
      ]
    },
    "45057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.940929249314364,
              39.72743586758973
            ]
          },
          "bbox": [
            -3.940929249314364,
            39.72743586758973,
            -3.940929249314364,
            39.72743586758973
          ]
        }
      ],
      "bbox": [
        -3.940929249314364,
        39.72743586758973,
        -3.940929249314364,
        39.72743586758973
      ]
    },
    "45058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.507847976374935,
              39.979358966721804
            ]
          },
          "bbox": [
            -4.507847976374935,
            39.979358966721804,
            -4.507847976374935,
            39.979358966721804
          ]
        }
      ],
      "bbox": [
        -4.507847976374935,
        39.979358966721804,
        -4.507847976374935,
        39.979358966721804
      ]
    },
    "45059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.501502283340777,
              39.847493299555865
            ]
          },
          "bbox": [
            -3.501502283340777,
            39.847493299555865,
            -3.501502283340777,
            39.847493299555865
          ]
        }
      ],
      "bbox": [
        -3.501502283340777,
        39.847493299555865,
        -3.501502283340777,
        39.847493299555865
      ]
    },
    "45060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4982868725711125,
              39.95453573149376
            ]
          },
          "bbox": [
            -4.4982868725711125,
            39.95453573149376,
            -4.4982868725711125,
            39.95453573149376
          ]
        }
      ],
      "bbox": [
        -4.4982868725711125,
        39.95453573149376,
        -4.4982868725711125,
        39.95453573149376
      ]
    },
    "45061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.390359892874418,
              40.15002827957176
            ]
          },
          "bbox": [
            -4.390359892874418,
            40.15002827957176,
            -4.390359892874418,
            40.15002827957176
          ]
        }
      ],
      "bbox": [
        -4.390359892874418,
        40.15002827957176,
        -4.390359892874418,
        40.15002827957176
      ]
    },
    "45062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.345688219783422,
              39.920287171127605
            ]
          },
          "bbox": [
            -4.345688219783422,
            39.920287171127605,
            -4.345688219783422,
            39.920287171127605
          ]
        }
      ],
      "bbox": [
        -4.345688219783422,
        39.920287171127605,
        -4.345688219783422,
        39.920287171127605
      ]
    },
    "45063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.78899725507644,
              39.64100644584637
            ]
          },
          "bbox": [
            -4.78899725507644,
            39.64100644584637,
            -4.78899725507644,
            39.64100644584637
          ]
        }
      ],
      "bbox": [
        -4.78899725507644,
        39.64100644584637,
        -4.78899725507644,
        39.64100644584637
      ]
    },
    "45064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.754291458827301,
              40.10639379016251
            ]
          },
          "bbox": [
            -3.754291458827301,
            40.10639379016251,
            -3.754291458827301,
            40.10639379016251
          ]
        }
      ],
      "bbox": [
        -3.754291458827301,
        40.10639379016251,
        -3.754291458827301,
        40.10639379016251
      ]
    },
    "45065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.089087101197495,
              39.69428717234874
            ]
          },
          "bbox": [
            -5.089087101197495,
            39.69428717234874,
            -5.089087101197495,
            39.69428717234874
          ]
        }
      ],
      "bbox": [
        -5.089087101197495,
        39.69428717234874,
        -5.089087101197495,
        39.69428717234874
      ]
    },
    "45066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.179549593148952,
              40.066423019059364
            ]
          },
          "bbox": [
            -4.179549593148952,
            40.066423019059364,
            -4.179549593148952,
            40.066423019059364
          ]
        }
      ],
      "bbox": [
        -4.179549593148952,
        40.066423019059364,
        -4.179549593148952,
        40.066423019059364
      ]
    },
    "45067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.289262405606723,
              39.71920868160007
            ]
          },
          "bbox": [
            -4.289262405606723,
            39.71920868160007,
            -4.289262405606723,
            39.71920868160007
          ]
        }
      ],
      "bbox": [
        -4.289262405606723,
        39.71920868160007,
        -4.289262405606723,
        39.71920868160007
      ]
    },
    "45068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.637845605607792,
              40.115460256861
            ]
          },
          "bbox": [
            -4.637845605607792,
            40.115460256861,
            -4.637845605607792,
            40.115460256861
          ]
        }
      ],
      "bbox": [
        -4.637845605607792,
        40.115460256861,
        -4.637845605607792,
        40.115460256861
      ]
    },
    "45069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.265377652901374,
              39.94206517138304
            ]
          },
          "bbox": [
            -4.265377652901374,
            39.94206517138304,
            -4.265377652901374,
            39.94206517138304
          ]
        }
      ],
      "bbox": [
        -4.265377652901374,
        39.94206517138304,
        -4.265377652901374,
        39.94206517138304
      ]
    },
    "45070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.147618305540017,
              39.82408960552029
            ]
          },
          "bbox": [
            -4.147618305540017,
            39.82408960552029,
            -4.147618305540017,
            39.82408960552029
          ]
        }
      ],
      "bbox": [
        -4.147618305540017,
        39.82408960552029,
        -4.147618305540017,
        39.82408960552029
      ]
    },
    "45071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.530712719309755,
              39.793445515395504
            ]
          },
          "bbox": [
            -3.530712719309755,
            39.793445515395504,
            -3.530712719309755,
            39.793445515395504
          ]
        }
      ],
      "bbox": [
        -3.530712719309755,
        39.793445515395504,
        -3.530712719309755,
        39.793445515395504
      ]
    },
    "45072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.849580619976216,
              39.8713150653596
            ]
          },
          "bbox": [
            -4.849580619976216,
            39.8713150653596,
            -4.849580619976216,
            39.8713150653596
          ]
        }
      ],
      "bbox": [
        -4.849580619976216,
        39.8713150653596,
        -4.849580619976216,
        39.8713150653596
      ]
    },
    "45073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.241148757520689,
              39.896046370356785
            ]
          },
          "bbox": [
            -5.241148757520689,
            39.896046370356785,
            -5.241148757520689,
            39.896046370356785
          ]
        }
      ],
      "bbox": [
        -5.241148757520689,
        39.896046370356785,
        -5.241148757520689,
        39.896046370356785
      ]
    },
    "45074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.739246036269659,
              40.10775977037315
            ]
          },
          "bbox": [
            -4.739246036269659,
            40.10775977037315,
            -4.739246036269659,
            40.10775977037315
          ]
        }
      ],
      "bbox": [
        -4.739246036269659,
        40.10775977037315,
        -4.739246036269659,
        40.10775977037315
      ]
    },
    "45075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.530007709347718,
              39.56414480426682
            ]
          },
          "bbox": [
            -4.530007709347718,
            39.56414480426682,
            -4.530007709347718,
            39.56414480426682
          ]
        }
      ],
      "bbox": [
        -4.530007709347718,
        39.56414480426682,
        -4.530007709347718,
        39.56414480426682
      ]
    },
    "45076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.456089776218351,
              40.09767669895318
            ]
          },
          "bbox": [
            -4.456089776218351,
            40.09767669895318,
            -4.456089776218351,
            40.09767669895318
          ]
        }
      ],
      "bbox": [
        -4.456089776218351,
        40.09767669895318,
        -4.456089776218351,
        40.09767669895318
      ]
    },
    "45077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.197600960267917,
              40.0109315015719
            ]
          },
          "bbox": [
            -4.197600960267917,
            40.0109315015719,
            -4.197600960267917,
            40.0109315015719
          ]
        }
      ],
      "bbox": [
        -4.197600960267917,
        40.0109315015719,
        -4.197600960267917,
        40.0109315015719
      ]
    },
    "45078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6148032502407808,
              39.840286885849686
            ]
          },
          "bbox": [
            -3.6148032502407808,
            39.840286885849686,
            -3.6148032502407808,
            39.840286885849686
          ]
        }
      ],
      "bbox": [
        -3.6148032502407808,
        39.840286885849686,
        -3.6148032502407808,
        39.840286885849686
      ]
    },
    "45079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.743733992650359,
              40.23998254818301
            ]
          },
          "bbox": [
            -4.743733992650359,
            40.23998254818301,
            -4.743733992650359,
            40.23998254818301
          ]
        }
      ],
      "bbox": [
        -4.743733992650359,
        40.23998254818301,
        -4.743733992650359,
        40.23998254818301
      ]
    },
    "45080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.555574788429173,
              39.9701705884117
            ]
          },
          "bbox": [
            -4.555574788429173,
            39.9701705884117,
            -4.555574788429173,
            39.9701705884117
          ]
        }
      ],
      "bbox": [
        -4.555574788429173,
        39.9701705884117,
        -4.555574788429173,
        39.9701705884117
      ]
    },
    "45081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8445433189063745,
              40.13445820068682
            ]
          },
          "bbox": [
            -3.8445433189063745,
            40.13445820068682,
            -3.8445433189063745,
            40.13445820068682
          ]
        }
      ],
      "bbox": [
        -3.8445433189063745,
        40.13445820068682,
        -3.8445433189063745,
        40.13445820068682
      ]
    },
    "45082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.235029766369631,
              39.98242952611929
            ]
          },
          "bbox": [
            -5.235029766369631,
            39.98242952611929,
            -5.235029766369631,
            39.98242952611929
          ]
        }
      ],
      "bbox": [
        -5.235029766369631,
        39.98242952611929,
        -5.235029766369631,
        39.98242952611929
      ]
    },
    "45083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.056404553834482,
              39.77037763664888
            ]
          },
          "bbox": [
            -4.056404553834482,
            39.77037763664888,
            -4.056404553834482,
            39.77037763664888
          ]
        }
      ],
      "bbox": [
        -4.056404553834482,
        39.77037763664888,
        -4.056404553834482,
        39.77037763664888
      ]
    },
    "45084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3336375052798304,
              39.70345084267079
            ]
          },
          "bbox": [
            -3.3336375052798304,
            39.70345084267079,
            -3.3336375052798304,
            39.70345084267079
          ]
        }
      ],
      "bbox": [
        -3.3336375052798304,
        39.70345084267079,
        -3.3336375052798304,
        39.70345084267079
      ]
    },
    "45085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9869480084536484,
              40.085218839986865
            ]
          },
          "bbox": [
            -3.9869480084536484,
            40.085218839986865,
            -3.9869480084536484,
            40.085218839986865
          ]
        }
      ],
      "bbox": [
        -3.9869480084536484,
        40.085218839986865,
        -3.9869480084536484,
        40.085218839986865
      ]
    },
    "45086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.626002232193914,
              40.005245608481474
            ]
          },
          "bbox": [
            -4.626002232193914,
            40.005245608481474,
            -4.626002232193914,
            40.005245608481474
          ]
        }
      ],
      "bbox": [
        -4.626002232193914,
        40.005245608481474,
        -4.626002232193914,
        40.005245608481474
      ]
    },
    "45087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5377743448135304,
              39.46626082056771
            ]
          },
          "bbox": [
            -3.5377743448135304,
            39.46626082056771,
            -3.5377743448135304,
            39.46626082056771
          ]
        }
      ],
      "bbox": [
        -3.5377743448135304,
        39.46626082056771,
        -3.5377743448135304,
        39.46626082056771
      ]
    },
    "45088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.932585382033833,
              39.96581354051476
            ]
          },
          "bbox": [
            -3.932585382033833,
            39.96581354051476,
            -3.932585382033833,
            39.96581354051476
          ]
        }
      ],
      "bbox": [
        -3.932585382033833,
        39.96581354051476,
        -3.932585382033833,
        39.96581354051476
      ]
    },
    "45089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5811684772395855,
              39.88489483760825
            ]
          },
          "bbox": [
            -4.5811684772395855,
            39.88489483760825,
            -4.5811684772395855,
            39.88489483760825
          ]
        }
      ],
      "bbox": [
        -4.5811684772395855,
        39.88489483760825,
        -4.5811684772395855,
        39.88489483760825
      ]
    },
    "45090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.794426729124449,
              39.640849331729235
            ]
          },
          "bbox": [
            -3.794426729124449,
            39.640849331729235,
            -3.794426729124449,
            39.640849331729235
          ]
        }
      ],
      "bbox": [
        -3.794426729124449,
        39.640849331729235,
        -3.794426729124449,
        39.640849331729235
      ]
    },
    "45091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.365505769419163,
              40.07850593133137
            ]
          },
          "bbox": [
            -4.365505769419163,
            40.07850593133137,
            -4.365505769419163,
            40.07850593133137
          ]
        }
      ],
      "bbox": [
        -4.365505769419163,
        40.07850593133137,
        -4.365505769419163,
        40.07850593133137
      ]
    },
    "45092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.968004117532828,
              39.54882298330268
            ]
          },
          "bbox": [
            -3.968004117532828,
            39.54882298330268,
            -3.968004117532828,
            39.54882298330268
          ]
        }
      ],
      "bbox": [
        -3.968004117532828,
        39.54882298330268,
        -3.968004117532828,
        39.54882298330268
      ]
    },
    "45093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.803348862901506,
              40.09038169794516
            ]
          },
          "bbox": [
            -4.803348862901506,
            40.09038169794516,
            -4.803348862901506,
            40.09038169794516
          ]
        }
      ],
      "bbox": [
        -4.803348862901506,
        40.09038169794516,
        -4.803348862901506,
        40.09038169794516
      ]
    },
    "45094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8023035734368062,
              39.74516465788576
            ]
          },
          "bbox": [
            -3.8023035734368062,
            39.74516465788576,
            -3.8023035734368062,
            39.74516465788576
          ]
        }
      ],
      "bbox": [
        -3.8023035734368062,
        39.74516465788576,
        -3.8023035734368062,
        39.74516465788576
      ]
    },
    "45095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.450066053694019,
              39.92791629670935
            ]
          },
          "bbox": [
            -4.450066053694019,
            39.92791629670935,
            -4.450066053694019,
            39.92791629670935
          ]
        }
      ],
      "bbox": [
        -4.450066053694019,
        39.92791629670935,
        -4.450066053694019,
        39.92791629670935
      ]
    },
    "45096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0796210704979705,
              39.6463831076074
            ]
          },
          "bbox": [
            -4.0796210704979705,
            39.6463831076074,
            -4.0796210704979705,
            39.6463831076074
          ]
        }
      ],
      "bbox": [
        -4.0796210704979705,
        39.6463831076074,
        -4.0796210704979705,
        39.6463831076074
      ]
    },
    "45097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.917034629915215,
              40.01605687196059
            ]
          },
          "bbox": [
            -4.917034629915215,
            40.01605687196059,
            -4.917034629915215,
            40.01605687196059
          ]
        }
      ],
      "bbox": [
        -4.917034629915215,
        40.01605687196059,
        -4.917034629915215,
        40.01605687196059
      ]
    },
    "45098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.356865454430231,
              39.61621223448725
            ]
          },
          "bbox": [
            -4.356865454430231,
            39.61621223448725,
            -4.356865454430231,
            39.61621223448725
          ]
        }
      ],
      "bbox": [
        -4.356865454430231,
        39.61621223448725,
        -4.356865454430231,
        39.61621223448725
      ]
    },
    "45099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.183847899035709,
              40.23300311231667
            ]
          },
          "bbox": [
            -4.183847899035709,
            40.23300311231667,
            -4.183847899035709,
            40.23300311231667
          ]
        }
      ],
      "bbox": [
        -4.183847899035709,
        40.23300311231667,
        -4.183847899035709,
        40.23300311231667
      ]
    },
    "45100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5013483039480215,
              39.92118983849501
            ]
          },
          "bbox": [
            -4.5013483039480215,
            39.92118983849501,
            -4.5013483039480215,
            39.92118983849501
          ]
        }
      ],
      "bbox": [
        -4.5013483039480215,
        39.92118983849501,
        -4.5013483039480215,
        39.92118983849501
      ]
    },
    "45101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1033998495816064,
              39.514900695178234
            ]
          },
          "bbox": [
            -3.1033998495816064,
            39.514900695178234,
            -3.1033998495816064,
            39.514900695178234
          ]
        }
      ],
      "bbox": [
        -3.1033998495816064,
        39.514900695178234,
        -3.1033998495816064,
        39.514900695178234
      ]
    },
    "45102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9086689296212973,
              39.925853966505514
            ]
          },
          "bbox": [
            -3.9086689296212973,
            39.925853966505514,
            -3.9086689296212973,
            39.925853966505514
          ]
        }
      ],
      "bbox": [
        -3.9086689296212973,
        39.925853966505514,
        -3.9086689296212973,
        39.925853966505514
      ]
    },
    "45103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.146967195474463,
              39.59668219187676
            ]
          },
          "bbox": [
            -5.146967195474463,
            39.59668219187676,
            -5.146967195474463,
            39.59668219187676
          ]
        }
      ],
      "bbox": [
        -5.146967195474463,
        39.59668219187676,
        -5.146967195474463,
        39.59668219187676
      ]
    },
    "45104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6335642053938235,
              39.956112322959584
            ]
          },
          "bbox": [
            -4.6335642053938235,
            39.956112322959584,
            -4.6335642053938235,
            39.956112322959584
          ]
        }
      ],
      "bbox": [
        -4.6335642053938235,
        39.956112322959584,
        -4.6335642053938235,
        39.956112322959584
      ]
    },
    "45105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.929957832551231,
              40.091347952398216
            ]
          },
          "bbox": [
            -4.929957832551231,
            40.091347952398216,
            -4.929957832551231,
            40.091347952398216
          ]
        }
      ],
      "bbox": [
        -4.929957832551231,
        40.091347952398216,
        -4.929957832551231,
        40.091347952398216
      ]
    },
    "45106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7367491878715757,
              39.68834533020018
            ]
          },
          "bbox": [
            -3.7367491878715757,
            39.68834533020018,
            -3.7367491878715757,
            39.68834533020018
          ]
        }
      ],
      "bbox": [
        -3.7367491878715757,
        39.68834533020018,
        -3.7367491878715757,
        39.68834533020018
      ]
    },
    "45107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9182241955739543,
              39.79849627927634
            ]
          },
          "bbox": [
            -3.9182241955739543,
            39.79849627927634,
            -3.9182241955739543,
            39.79849627927634
          ]
        }
      ],
      "bbox": [
        -3.9182241955739543,
        39.79849627927634,
        -3.9182241955739543,
        39.79849627927634
      ]
    },
    "45108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9959771243416276,
              39.6472435315276
            ]
          },
          "bbox": [
            -4.9959771243416276,
            39.6472435315276,
            -4.9959771243416276,
            39.6472435315276
          ]
        }
      ],
      "bbox": [
        -4.9959771243416276,
        39.6472435315276,
        -4.9959771243416276,
        39.6472435315276
      ]
    },
    "45109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.477247857912113,
              39.654498279977275
            ]
          },
          "bbox": [
            -4.477247857912113,
            39.654498279977275,
            -4.477247857912113,
            39.654498279977275
          ]
        }
      ],
      "bbox": [
        -4.477247857912113,
        39.654498279977275,
        -4.477247857912113,
        39.654498279977275
      ]
    },
    "45110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.101419552901353,
              40.088307540304015
            ]
          },
          "bbox": [
            -5.101419552901353,
            40.088307540304015,
            -5.101419552901353,
            40.088307540304015
          ]
        }
      ],
      "bbox": [
        -5.101419552901353,
        40.088307540304015,
        -5.101419552901353,
        40.088307540304015
      ]
    },
    "45111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.12349231281827,
              39.744534074183825
            ]
          },
          "bbox": [
            -5.12349231281827,
            39.744534074183825,
            -5.12349231281827,
            39.744534074183825
          ]
        }
      ],
      "bbox": [
        -5.12349231281827,
        39.744534074183825,
        -5.12349231281827,
        39.744534074183825
      ]
    },
    "45112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.619315684583537,
              39.712593993437494
            ]
          },
          "bbox": [
            -4.619315684583537,
            39.712593993437494,
            -4.619315684583537,
            39.712593993437494
          ]
        }
      ],
      "bbox": [
        -4.619315684583537,
        39.712593993437494,
        -4.619315684583537,
        39.712593993437494
      ]
    },
    "45113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.676670126825852,
              39.57463682396575
            ]
          },
          "bbox": [
            -4.676670126825852,
            39.57463682396575,
            -4.676670126825852,
            39.57463682396575
          ]
        }
      ],
      "bbox": [
        -4.676670126825852,
        39.57463682396575,
        -4.676670126825852,
        39.57463682396575
      ]
    },
    "45114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.831848183359866,
              40.13776140048724
            ]
          },
          "bbox": [
            -4.831848183359866,
            40.13776140048724,
            -4.831848183359866,
            40.13776140048724
          ]
        }
      ],
      "bbox": [
        -4.831848183359866,
        40.13776140048724,
        -4.831848183359866,
        40.13776140048724
      ]
    },
    "45115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4321900570446373,
              39.99462063127196
            ]
          },
          "bbox": [
            -3.4321900570446373,
            39.99462063127196,
            -3.4321900570446373,
            39.99462063127196
          ]
        }
      ],
      "bbox": [
        -3.4321900570446373,
        39.99462063127196,
        -3.4321900570446373,
        39.99462063127196
      ]
    },
    "45116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.164630487834865,
              39.73598231034493
            ]
          },
          "bbox": [
            -4.164630487834865,
            39.73598231034493,
            -4.164630487834865,
            39.73598231034493
          ]
        }
      ],
      "bbox": [
        -4.164630487834865,
        39.73598231034493,
        -4.164630487834865,
        39.73598231034493
      ]
    },
    "45117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.524801909256819,
              40.131929659454805
            ]
          },
          "bbox": [
            -4.524801909256819,
            40.131929659454805,
            -4.524801909256819,
            40.131929659454805
          ]
        }
      ],
      "bbox": [
        -4.524801909256819,
        40.131929659454805,
        -4.524801909256819,
        40.131929659454805
      ]
    },
    "45118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.265345019933385,
              40.0360334700649
            ]
          },
          "bbox": [
            -4.265345019933385,
            40.0360334700649,
            -4.265345019933385,
            40.0360334700649
          ]
        }
      ],
      "bbox": [
        -4.265345019933385,
        40.0360334700649,
        -4.265345019933385,
        40.0360334700649
      ]
    },
    "45119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8281489628667265,
              40.07722896581187
            ]
          },
          "bbox": [
            -3.8281489628667265,
            40.07722896581187,
            -3.8281489628667265,
            40.07722896581187
          ]
        }
      ],
      "bbox": [
        -3.8281489628667265,
        40.07722896581187,
        -3.8281489628667265,
        40.07722896581187
      ]
    },
    "45120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.614893010229345,
              40.12821711205464
            ]
          },
          "bbox": [
            -4.614893010229345,
            40.12821711205464,
            -4.614893010229345,
            40.12821711205464
          ]
        }
      ],
      "bbox": [
        -4.614893010229345,
        40.12821711205464,
        -4.614893010229345,
        40.12821711205464
      ]
    },
    "45121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5124123452738854,
              39.94664761816935
            ]
          },
          "bbox": [
            -3.5124123452738854,
            39.94664761816935,
            -3.5124123452738854,
            39.94664761816935
          ]
        }
      ],
      "bbox": [
        -3.5124123452738854,
        39.94664761816935,
        -3.5124123452738854,
        39.94664761816935
      ]
    },
    "45122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9849298598224165,
              39.948128358768734
            ]
          },
          "bbox": [
            -3.9849298598224165,
            39.948128358768734,
            -3.9849298598224165,
            39.948128358768734
          ]
        }
      ],
      "bbox": [
        -3.9849298598224165,
        39.948128358768734,
        -3.9849298598224165,
        39.948128358768734
      ]
    },
    "45123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5352557995201117,
              40.00647053729409
            ]
          },
          "bbox": [
            -3.5352557995201117,
            40.00647053729409,
            -3.5352557995201117,
            40.00647053729409
          ]
        }
      ],
      "bbox": [
        -3.5352557995201117,
        40.00647053729409,
        -3.5352557995201117,
        40.00647053729409
      ]
    },
    "45124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8965569012383856,
              39.641902356584204
            ]
          },
          "bbox": [
            -3.8965569012383856,
            39.641902356584204,
            -3.8965569012383856,
            39.641902356584204
          ]
        }
      ],
      "bbox": [
        -3.8965569012383856,
        39.641902356584204,
        -3.8965569012383856,
        39.641902356584204
      ]
    },
    "45125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2148535246727805,
              39.985124069669816
            ]
          },
          "bbox": [
            -5.2148535246727805,
            39.985124069669816,
            -5.2148535246727805,
            39.985124069669816
          ]
        }
      ],
      "bbox": [
        -5.2148535246727805,
        39.985124069669816,
        -5.2148535246727805,
        39.985124069669816
      ]
    },
    "45126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.506907215335476,
              40.01939886808285
            ]
          },
          "bbox": [
            -4.506907215335476,
            40.01939886808285,
            -4.506907215335476,
            40.01939886808285
          ]
        }
      ],
      "bbox": [
        -4.506907215335476,
        40.01939886808285,
        -4.506907215335476,
        40.01939886808285
      ]
    },
    "45127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9782673779274274,
              40.117171535974585
            ]
          },
          "bbox": [
            -3.9782673779274274,
            40.117171535974585,
            -3.9782673779274274,
            40.117171535974585
          ]
        }
      ],
      "bbox": [
        -3.9782673779274274,
        40.117171535974585,
        -3.9782673779274274,
        40.117171535974585
      ]
    },
    "45128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.822990554625004,
              40.04510160985698
            ]
          },
          "bbox": [
            -3.822990554625004,
            40.04510160985698,
            -3.822990554625004,
            40.04510160985698
          ]
        }
      ],
      "bbox": [
        -3.822990554625004,
        40.04510160985698,
        -3.822990554625004,
        40.04510160985698
      ]
    },
    "45129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.439906943709911,
              40.2116904197177
            ]
          },
          "bbox": [
            -4.439906943709911,
            40.2116904197177,
            -4.439906943709911,
            40.2116904197177
          ]
        }
      ],
      "bbox": [
        -4.439906943709911,
        40.2116904197177,
        -4.439906943709911,
        40.2116904197177
      ]
    },
    "45130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.043156504272528,
              40.09363294888373
            ]
          },
          "bbox": [
            -5.043156504272528,
            40.09363294888373,
            -5.043156504272528,
            40.09363294888373
          ]
        }
      ],
      "bbox": [
        -5.043156504272528,
        40.09363294888373,
        -5.043156504272528,
        40.09363294888373
      ]
    },
    "45131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.589095057407059,
              40.17051329101165
            ]
          },
          "bbox": [
            -4.589095057407059,
            40.17051329101165,
            -4.589095057407059,
            40.17051329101165
          ]
        }
      ],
      "bbox": [
        -4.589095057407059,
        40.17051329101165,
        -4.589095057407059,
        40.17051329101165
      ]
    },
    "45132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.785302038217049,
              40.015934505491934
            ]
          },
          "bbox": [
            -4.785302038217049,
            40.015934505491934,
            -4.785302038217049,
            40.015934505491934
          ]
        }
      ],
      "bbox": [
        -4.785302038217049,
        40.015934505491934,
        -4.785302038217049,
        40.015934505491934
      ]
    },
    "45133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.2456409447352685,
              39.798769130711214
            ]
          },
          "bbox": [
            -4.2456409447352685,
            39.798769130711214,
            -4.2456409447352685,
            39.798769130711214
          ]
        }
      ],
      "bbox": [
        -4.2456409447352685,
        39.798769130711214,
        -4.2456409447352685,
        39.798769130711214
      ]
    },
    "45134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.24649612312377,
              40.07875194793735
            ]
          },
          "bbox": [
            -4.24649612312377,
            40.07875194793735,
            -4.24649612312377,
            40.07875194793735
          ]
        }
      ],
      "bbox": [
        -4.24649612312377,
        40.07875194793735,
        -4.24649612312377,
        40.07875194793735
      ]
    },
    "45135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.13536023457609,
              39.5925487052433
            ]
          },
          "bbox": [
            -3.13536023457609,
            39.5925487052433,
            -3.13536023457609,
            39.5925487052433
          ]
        }
      ],
      "bbox": [
        -3.13536023457609,
        39.5925487052433,
        -3.13536023457609,
        39.5925487052433
      ]
    },
    "45136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.379450572938243,
              39.816368794666076
            ]
          },
          "bbox": [
            -4.379450572938243,
            39.816368794666076,
            -4.379450572938243,
            39.816368794666076
          ]
        }
      ],
      "bbox": [
        -4.379450572938243,
        39.816368794666076,
        -4.379450572938243,
        39.816368794666076
      ]
    },
    "45137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.704729779826679,
              39.912486578332526
            ]
          },
          "bbox": [
            -4.704729779826679,
            39.912486578332526,
            -4.704729779826679,
            39.912486578332526
          ]
        }
      ],
      "bbox": [
        -4.704729779826679,
        39.912486578332526,
        -4.704729779826679,
        39.912486578332526
      ]
    },
    "45138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.174706349565792,
              39.802431718671485
            ]
          },
          "bbox": [
            -5.174706349565792,
            39.802431718671485,
            -5.174706349565792,
            39.802431718671485
          ]
        }
      ],
      "bbox": [
        -5.174706349565792,
        39.802431718671485,
        -5.174706349565792,
        39.802431718671485
      ]
    },
    "45139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.079557852606167,
              39.5170727123648
            ]
          },
          "bbox": [
            -5.079557852606167,
            39.5170727123648,
            -5.079557852606167,
            39.5170727123648
          ]
        }
      ],
      "bbox": [
        -5.079557852606167,
        39.5170727123648,
        -5.079557852606167,
        39.5170727123648
      ]
    },
    "45140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.156381194175361,
              39.675524525979
            ]
          },
          "bbox": [
            -4.156381194175361,
            39.675524525979,
            -4.156381194175361,
            39.675524525979
          ]
        }
      ],
      "bbox": [
        -4.156381194175361,
        39.675524525979,
        -4.156381194175361,
        39.675524525979
      ]
    },
    "45141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.260144946385421,
              39.5137084770341
            ]
          },
          "bbox": [
            -3.260144946385421,
            39.5137084770341,
            -3.260144946385421,
            39.5137084770341
          ]
        }
      ],
      "bbox": [
        -3.260144946385421,
        39.5137084770341,
        -3.260144946385421,
        39.5137084770341
      ]
    },
    "45142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.019033201220096,
              39.597423930689956
            ]
          },
          "bbox": [
            -3.019033201220096,
            39.597423930689956,
            -3.019033201220096,
            39.597423930689956
          ]
        }
      ],
      "bbox": [
        -3.019033201220096,
        39.597423930689956,
        -3.019033201220096,
        39.597423930689956
      ]
    },
    "45143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.319818151912557,
              40.108125702776164
            ]
          },
          "bbox": [
            -4.319818151912557,
            40.108125702776164,
            -4.319818151912557,
            40.108125702776164
          ]
        }
      ],
      "bbox": [
        -4.319818151912557,
        40.108125702776164,
        -4.319818151912557,
        40.108125702776164
      ]
    },
    "45144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.673719124736629,
              40.164987748770656
            ]
          },
          "bbox": [
            -4.673719124736629,
            40.164987748770656,
            -4.673719124736629,
            40.164987748770656
          ]
        }
      ],
      "bbox": [
        -4.673719124736629,
        40.164987748770656,
        -4.673719124736629,
        40.164987748770656
      ]
    },
    "45145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.014295830723793,
              40.04926922662708
            ]
          },
          "bbox": [
            -4.014295830723793,
            40.04926922662708,
            -4.014295830723793,
            40.04926922662708
          ]
        }
      ],
      "bbox": [
        -4.014295830723793,
        40.04926922662708,
        -4.014295830723793,
        40.04926922662708
      ]
    },
    "45146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.772237063322168,
              39.74150506728497
            ]
          },
          "bbox": [
            -4.772237063322168,
            39.74150506728497,
            -4.772237063322168,
            39.74150506728497
          ]
        }
      ],
      "bbox": [
        -4.772237063322168,
        39.74150506728497,
        -4.772237063322168,
        39.74150506728497
      ]
    },
    "45147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.187627714363919,
              39.944944249790105
            ]
          },
          "bbox": [
            -4.187627714363919,
            39.944944249790105,
            -4.187627714363919,
            39.944944249790105
          ]
        }
      ],
      "bbox": [
        -4.187627714363919,
        39.944944249790105,
        -4.187627714363919,
        39.944944249790105
      ]
    },
    "45148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.832641750989917,
              39.58092418899481
            ]
          },
          "bbox": [
            -4.832641750989917,
            39.58092418899481,
            -4.832641750989917,
            39.58092418899481
          ]
        }
      ],
      "bbox": [
        -4.832641750989917,
        39.58092418899481,
        -4.832641750989917,
        39.58092418899481
      ]
    },
    "45149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4377075202915344,
              39.72286421849432
            ]
          },
          "bbox": [
            -3.4377075202915344,
            39.72286421849432,
            -3.4377075202915344,
            39.72286421849432
          ]
        }
      ],
      "bbox": [
        -3.4377075202915344,
        39.72286421849432,
        -3.4377075202915344,
        39.72286421849432
      ]
    },
    "45150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.739664930190039,
              39.83672917038947
            ]
          },
          "bbox": [
            -4.739664930190039,
            39.83672917038947,
            -4.739664930190039,
            39.83672917038947
          ]
        }
      ],
      "bbox": [
        -4.739664930190039,
        39.83672917038947,
        -4.739664930190039,
        39.83672917038947
      ]
    },
    "45151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.427526283846645,
              39.72488788434757
            ]
          },
          "bbox": [
            -4.427526283846645,
            39.72488788434757,
            -4.427526283846645,
            39.72488788434757
          ]
        }
      ],
      "bbox": [
        -4.427526283846645,
        39.72488788434757,
        -4.427526283846645,
        39.72488788434757
      ]
    },
    "45152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.650884672242997,
              39.80673438479037
            ]
          },
          "bbox": [
            -4.650884672242997,
            39.80673438479037,
            -4.650884672242997,
            39.80673438479037
          ]
        }
      ],
      "bbox": [
        -4.650884672242997,
        39.80673438479037,
        -4.650884672242997,
        39.80673438479037
      ]
    },
    "45153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.323204377883262,
              39.523155569423864
            ]
          },
          "bbox": [
            -4.323204377883262,
            39.523155569423864,
            -4.323204377883262,
            39.523155569423864
          ]
        }
      ],
      "bbox": [
        -4.323204377883262,
        39.523155569423864,
        -4.323204377883262,
        39.523155569423864
      ]
    },
    "45154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.741857586971384,
              40.053005101511815
            ]
          },
          "bbox": [
            -4.741857586971384,
            40.053005101511815,
            -4.741857586971384,
            40.053005101511815
          ]
        }
      ],
      "bbox": [
        -4.741857586971384,
        40.053005101511815,
        -4.741857586971384,
        40.053005101511815
      ]
    },
    "45155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.711849041245407,
              39.76021062548959
            ]
          },
          "bbox": [
            -4.711849041245407,
            39.76021062548959,
            -4.711849041245407,
            39.76021062548959
          ]
        }
      ],
      "bbox": [
        -4.711849041245407,
        39.76021062548959,
        -4.711849041245407,
        39.76021062548959
      ]
    },
    "45156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1890613325145822,
              39.956842616000316
            ]
          },
          "bbox": [
            -3.1890613325145822,
            39.956842616000316,
            -3.1890613325145822,
            39.956842616000316
          ]
        }
      ],
      "bbox": [
        -3.1890613325145822,
        39.956842616000316,
        -3.1890613325145822,
        39.956842616000316
      ]
    },
    "45157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.284414812042325,
              40.17379510320029
            ]
          },
          "bbox": [
            -4.284414812042325,
            40.17379510320029,
            -4.284414812042325,
            40.17379510320029
          ]
        }
      ],
      "bbox": [
        -4.284414812042325,
        40.17379510320029,
        -4.284414812042325,
        40.17379510320029
      ]
    },
    "45158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.434608992160303,
              40.014747457504654
            ]
          },
          "bbox": [
            -4.434608992160303,
            40.014747457504654,
            -4.434608992160303,
            40.014747457504654
          ]
        }
      ],
      "bbox": [
        -4.434608992160303,
        40.014747457504654,
        -4.434608992160303,
        40.014747457504654
      ]
    },
    "45159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8031038910756765,
              40.21020195912592
            ]
          },
          "bbox": [
            -4.8031038910756765,
            40.21020195912592,
            -4.8031038910756765,
            40.21020195912592
          ]
        }
      ],
      "bbox": [
        -4.8031038910756765,
        40.21020195912592,
        -4.8031038910756765,
        40.21020195912592
      ]
    },
    "45160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.872910174141737,
              40.03677137945638
            ]
          },
          "bbox": [
            -4.872910174141737,
            40.03677137945638,
            -4.872910174141737,
            40.03677137945638
          ]
        }
      ],
      "bbox": [
        -4.872910174141737,
        40.03677137945638,
        -4.872910174141737,
        40.03677137945638
      ]
    },
    "45161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6659904890614583,
              40.09218042528308
            ]
          },
          "bbox": [
            -3.6659904890614583,
            40.09218042528308,
            -3.6659904890614583,
            40.09218042528308
          ]
        }
      ],
      "bbox": [
        -3.6659904890614583,
        40.09218042528308,
        -3.6659904890614583,
        40.09218042528308
      ]
    },
    "45162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.955532350541069,
              39.529413758147186
            ]
          },
          "bbox": [
            -4.955532350541069,
            39.529413758147186,
            -4.955532350541069,
            39.529413758147186
          ]
        }
      ],
      "bbox": [
        -4.955532350541069,
        39.529413758147186,
        -4.955532350541069,
        39.529413758147186
      ]
    },
    "45163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.994965406447829,
              39.641277828651766
            ]
          },
          "bbox": [
            -3.994965406447829,
            39.641277828651766,
            -3.994965406447829,
            39.641277828651766
          ]
        }
      ],
      "bbox": [
        -3.994965406447829,
        39.641277828651766,
        -3.994965406447829,
        39.641277828651766
      ]
    },
    "45164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.847963341356374,
              40.09304426390879
            ]
          },
          "bbox": [
            -4.847963341356374,
            40.09304426390879,
            -4.847963341356374,
            40.09304426390879
          ]
        }
      ],
      "bbox": [
        -4.847963341356374,
        40.09304426390879,
        -4.847963341356374,
        40.09304426390879
      ]
    },
    "45165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.849734945701133,
              39.953539745520935
            ]
          },
          "bbox": [
            -4.849734945701133,
            39.953539745520935,
            -4.849734945701133,
            39.953539745520935
          ]
        }
      ],
      "bbox": [
        -4.849734945701133,
        39.953539745520935,
        -4.849734945701133,
        39.953539745520935
      ]
    },
    "45166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.540008252156596,
              39.664371646457475
            ]
          },
          "bbox": [
            -3.540008252156596,
            39.664371646457475,
            -3.540008252156596,
            39.664371646457475
          ]
        }
      ],
      "bbox": [
        -3.540008252156596,
        39.664371646457475,
        -3.540008252156596,
        39.664371646457475
      ]
    },
    "45167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.983175553113827,
              39.51742191932115
            ]
          },
          "bbox": [
            -2.983175553113827,
            39.51742191932115,
            -2.983175553113827,
            39.51742191932115
          ]
        }
      ],
      "bbox": [
        -2.983175553113827,
        39.51742191932115,
        -2.983175553113827,
        39.51742191932115
      ]
    },
    "45168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.015012430657267,
              39.86997062445869
            ]
          },
          "bbox": [
            -4.015012430657267,
            39.86997062445869,
            -4.015012430657267,
            39.86997062445869
          ]
        }
      ],
      "bbox": [
        -4.015012430657267,
        39.86997062445869,
        -4.015012430657267,
        39.86997062445869
      ]
    },
    "45169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.129841423741838,
              39.95191648250256
            ]
          },
          "bbox": [
            -5.129841423741838,
            39.95191648250256,
            -5.129841423741838,
            39.95191648250256
          ]
        }
      ],
      "bbox": [
        -5.129841423741838,
        39.95191648250256,
        -5.129841423741838,
        39.95191648250256
      ]
    },
    "45170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.80014144070203,
              39.68053628548531
            ]
          },
          "bbox": [
            -4.80014144070203,
            39.68053628548531,
            -4.80014144070203,
            39.68053628548531
          ]
        }
      ],
      "bbox": [
        -4.80014144070203,
        39.68053628548531,
        -4.80014144070203,
        39.68053628548531
      ]
    },
    "45171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.217614889070359,
              40.16926119688846
            ]
          },
          "bbox": [
            -4.217614889070359,
            40.16926119688846,
            -4.217614889070359,
            40.16926119688846
          ]
        }
      ],
      "bbox": [
        -4.217614889070359,
        40.16926119688846,
        -4.217614889070359,
        40.16926119688846
      ]
    },
    "45172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.211835498196932,
              39.833123418099234
            ]
          },
          "bbox": [
            -5.211835498196932,
            39.833123418099234,
            -5.211835498196932,
            39.833123418099234
          ]
        }
      ],
      "bbox": [
        -5.211835498196932,
        39.833123418099234,
        -5.211835498196932,
        39.833123418099234
      ]
    },
    "45173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.284549217711854,
              39.983616958833586
            ]
          },
          "bbox": [
            -4.284549217711854,
            39.983616958833586,
            -4.284549217711854,
            39.983616958833586
          ]
        }
      ],
      "bbox": [
        -4.284549217711854,
        39.983616958833586,
        -4.284549217711854,
        39.983616958833586
      ]
    },
    "45174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.219112791500088,
              39.708743445985824
            ]
          },
          "bbox": [
            -4.219112791500088,
            39.708743445985824,
            -4.219112791500088,
            39.708743445985824
          ]
        }
      ],
      "bbox": [
        -4.219112791500088,
        39.708743445985824,
        -4.219112791500088,
        39.708743445985824
      ]
    },
    "45175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6377714849514637,
              39.59488130125725
            ]
          },
          "bbox": [
            -3.6377714849514637,
            39.59488130125725,
            -3.6377714849514637,
            39.59488130125725
          ]
        }
      ],
      "bbox": [
        -3.6377714849514637,
        39.59488130125725,
        -3.6377714849514637,
        39.59488130125725
      ]
    },
    "45176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.874251310062098,
              40.16550704711047
            ]
          },
          "bbox": [
            -3.874251310062098,
            40.16550704711047,
            -3.874251310062098,
            40.16550704711047
          ]
        }
      ],
      "bbox": [
        -3.874251310062098,
        40.16550704711047,
        -3.874251310062098,
        40.16550704711047
      ]
    },
    "45177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7299698113826074,
              39.36462272841924
            ]
          },
          "bbox": [
            -3.7299698113826074,
            39.36462272841924,
            -3.7299698113826074,
            39.36462272841924
          ]
        }
      ],
      "bbox": [
        -3.7299698113826074,
        39.36462272841924,
        -3.7299698113826074,
        39.36462272841924
      ]
    },
    "45179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.273311804233321,
              39.80322387005586
            ]
          },
          "bbox": [
            -5.273311804233321,
            39.80322387005586,
            -5.273311804233321,
            39.80322387005586
          ]
        }
      ],
      "bbox": [
        -5.273311804233321,
        39.80322387005586,
        -5.273311804233321,
        39.80322387005586
      ]
    },
    "45180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.104432023023483,
              40.21426603046668
            ]
          },
          "bbox": [
            -4.104432023023483,
            40.21426603046668,
            -4.104432023023483,
            40.21426603046668
          ]
        }
      ],
      "bbox": [
        -4.104432023023483,
        40.21426603046668,
        -4.104432023023483,
        40.21426603046668
      ]
    },
    "45181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0021259105417375,
              40.028937050113505
            ]
          },
          "bbox": [
            -5.0021259105417375,
            40.028937050113505,
            -5.0021259105417375,
            40.028937050113505
          ]
        }
      ],
      "bbox": [
        -5.0021259105417375,
        40.028937050113505,
        -5.0021259105417375,
        40.028937050113505
      ]
    },
    "45182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.230126213012749,
              39.56791425794042
            ]
          },
          "bbox": [
            -4.230126213012749,
            39.56791425794042,
            -4.230126213012749,
            39.56791425794042
          ]
        }
      ],
      "bbox": [
        -4.230126213012749,
        39.56791425794042,
        -4.230126213012749,
        39.56791425794042
      ]
    },
    "45183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.142318120899928,
              40.15431200245343
            ]
          },
          "bbox": [
            -4.142318120899928,
            40.15431200245343,
            -4.142318120899928,
            40.15431200245343
          ]
        }
      ],
      "bbox": [
        -4.142318120899928,
        40.15431200245343,
        -4.142318120899928,
        40.15431200245343
      ]
    },
    "45184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2876827238807245,
              39.99626671788128
            ]
          },
          "bbox": [
            -5.2876827238807245,
            39.99626671788128,
            -5.2876827238807245,
            39.99626671788128
          ]
        }
      ],
      "bbox": [
        -5.2876827238807245,
        39.99626671788128,
        -5.2876827238807245,
        39.99626671788128
      ]
    },
    "45185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.36464482873416,
              39.58237517908248
            ]
          },
          "bbox": [
            -3.36464482873416,
            39.58237517908248,
            -3.36464482873416,
            39.58237517908248
          ]
        }
      ],
      "bbox": [
        -3.36464482873416,
        39.58237517908248,
        -3.36464482873416,
        39.58237517908248
      ]
    },
    "45186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2050410744423314,
              39.62790196462513
            ]
          },
          "bbox": [
            -3.2050410744423314,
            39.62790196462513,
            -3.2050410744423314,
            39.62790196462513
          ]
        }
      ],
      "bbox": [
        -3.2050410744423314,
        39.62790196462513,
        -3.2050410744423314,
        39.62790196462513
      ]
    },
    "45187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.368648775020706,
              39.45557916087671
            ]
          },
          "bbox": [
            -3.368648775020706,
            39.45557916087671,
            -3.368648775020706,
            39.45557916087671
          ]
        }
      ],
      "bbox": [
        -3.368648775020706,
        39.45557916087671,
        -3.368648775020706,
        39.45557916087671
      ]
    },
    "45188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9118025204391813,
              40.023707502243354
            ]
          },
          "bbox": [
            -3.9118025204391813,
            40.023707502243354,
            -3.9118025204391813,
            40.023707502243354
          ]
        }
      ],
      "bbox": [
        -3.9118025204391813,
        40.023707502243354,
        -3.9118025204391813,
        40.023707502243354
      ]
    },
    "45189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.130452204082314,
              39.981671775977325
            ]
          },
          "bbox": [
            -4.130452204082314,
            39.981671775977325,
            -4.130452204082314,
            39.981671775977325
          ]
        }
      ],
      "bbox": [
        -4.130452204082314,
        39.981671775977325,
        -4.130452204082314,
        39.981671775977325
      ]
    },
    "45190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8795941295621867,
              39.714249318580066
            ]
          },
          "bbox": [
            -3.8795941295621867,
            39.714249318580066,
            -3.8795941295621867,
            39.714249318580066
          ]
        }
      ],
      "bbox": [
        -3.8795941295621867,
        39.714249318580066,
        -3.8795941295621867,
        39.714249318580066
      ]
    },
    "45191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.733020990078283,
              39.80649772502755
            ]
          },
          "bbox": [
            -3.733020990078283,
            39.80649772502755,
            -3.733020990078283,
            39.80649772502755
          ]
        }
      ],
      "bbox": [
        -3.733020990078283,
        39.80649772502755,
        -3.733020990078283,
        39.80649772502755
      ]
    },
    "45192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.024496286987823,
              39.68136776031223
            ]
          },
          "bbox": [
            -3.024496286987823,
            39.68136776031223,
            -3.024496286987823,
            39.68136776031223
          ]
        }
      ],
      "bbox": [
        -3.024496286987823,
        39.68136776031223,
        -3.024496286987823,
        39.68136776031223
      ]
    },
    "45193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.651348412034967,
              39.725034290977874
            ]
          },
          "bbox": [
            -3.651348412034967,
            39.725034290977874,
            -3.651348412034967,
            39.725034290977874
          ]
        }
      ],
      "bbox": [
        -3.651348412034967,
        39.725034290977874,
        -3.651348412034967,
        39.725034290977874
      ]
    },
    "45194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.549375154116025,
              39.77064202297921
            ]
          },
          "bbox": [
            -4.549375154116025,
            39.77064202297921,
            -4.549375154116025,
            39.77064202297921
          ]
        }
      ],
      "bbox": [
        -4.549375154116025,
        39.77064202297921,
        -4.549375154116025,
        39.77064202297921
      ]
    },
    "45195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.33035321473441,
              40.00187680014153
            ]
          },
          "bbox": [
            -3.33035321473441,
            40.00187680014153,
            -3.33035321473441,
            40.00187680014153
          ]
        }
      ],
      "bbox": [
        -3.33035321473441,
        40.00187680014153,
        -3.33035321473441,
        40.00187680014153
      ]
    },
    "45196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.864555126450376,
              39.966413316897174
            ]
          },
          "bbox": [
            -3.864555126450376,
            39.966413316897174,
            -3.864555126450376,
            39.966413316897174
          ]
        }
      ],
      "bbox": [
        -3.864555126450376,
        39.966413316897174,
        -3.864555126450376,
        39.966413316897174
      ]
    },
    "45197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7062388326399733,
              39.862889712986195
            ]
          },
          "bbox": [
            -3.7062388326399733,
            39.862889712986195,
            -3.7062388326399733,
            39.862889712986195
          ]
        }
      ],
      "bbox": [
        -3.7062388326399733,
        39.862889712986195,
        -3.7062388326399733,
        39.862889712986195
      ]
    },
    "45198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3223050231653692,
              39.87575489187349
            ]
          },
          "bbox": [
            -3.3223050231653692,
            39.87575489187349,
            -3.3223050231653692,
            39.87575489187349
          ]
        }
      ],
      "bbox": [
        -3.3223050231653692,
        39.87575489187349,
        -3.3223050231653692,
        39.87575489187349
      ]
    },
    "45199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9870707325447405,
              40.15165233214361
            ]
          },
          "bbox": [
            -3.9870707325447405,
            40.15165233214361,
            -3.9870707325447405,
            40.15165233214361
          ]
        }
      ],
      "bbox": [
        -3.9870707325447405,
        40.15165233214361,
        -3.9870707325447405,
        40.15165233214361
      ]
    },
    "45200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9289897104933917,
              39.4255459780908
            ]
          },
          "bbox": [
            -3.9289897104933917,
            39.4255459780908,
            -3.9289897104933917,
            39.4255459780908
          ]
        }
      ],
      "bbox": [
        -3.9289897104933917,
        39.4255459780908,
        -3.9289897104933917,
        39.4255459780908
      ]
    },
    "45201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7892591525280555,
              40.12059681690276
            ]
          },
          "bbox": [
            -3.7892591525280555,
            40.12059681690276,
            -3.7892591525280555,
            40.12059681690276
          ]
        }
      ],
      "bbox": [
        -3.7892591525280555,
        40.12059681690276,
        -3.7892591525280555,
        40.12059681690276
      ]
    },
    "45202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.686673123715974,
              39.91412591742741
            ]
          },
          "bbox": [
            -3.686673123715974,
            39.91412591742741,
            -3.686673123715974,
            39.91412591742741
          ]
        }
      ],
      "bbox": [
        -3.686673123715974,
        39.91412591742741,
        -3.686673123715974,
        39.91412591742741
      ]
    },
    "45203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.903480380923711,
              40.052605603365514
            ]
          },
          "bbox": [
            -3.903480380923711,
            40.052605603365514,
            -3.903480380923711,
            40.052605603365514
          ]
        }
      ],
      "bbox": [
        -3.903480380923711,
        40.052605603365514,
        -3.903480380923711,
        40.052605603365514
      ]
    },
    "45204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.998601500728021,
              40.0182119426469
            ]
          },
          "bbox": [
            -3.998601500728021,
            40.0182119426469,
            -3.998601500728021,
            40.0182119426469
          ]
        }
      ],
      "bbox": [
        -3.998601500728021,
        40.0182119426469,
        -3.998601500728021,
        40.0182119426469
      ]
    },
    "45205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8893862178770173,
              40.08786757763647
            ]
          },
          "bbox": [
            -3.8893862178770173,
            40.08786757763647,
            -3.8893862178770173,
            40.08786757763647
          ]
        }
      ],
      "bbox": [
        -3.8893862178770173,
        40.08786757763647,
        -3.8893862178770173,
        40.08786757763647
      ]
    },
    "45901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.337162389363034,
              40.01446712813744
            ]
          },
          "bbox": [
            -4.337162389363034,
            40.01446712813744,
            -4.337162389363034,
            40.01446712813744
          ]
        }
      ],
      "bbox": [
        -4.337162389363034,
        40.01446712813744,
        -4.337162389363034,
        40.01446712813744
      ]
    },
    "46001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2289796095518966,
              40.06950485858659
            ]
          },
          "bbox": [
            -1.2289796095518966,
            40.06950485858659,
            -1.2289796095518966,
            40.06950485858659
          ]
        }
      ],
      "bbox": [
        -1.2289796095518966,
        40.06950485858659,
        -1.2289796095518966,
        40.06950485858659
      ]
    },
    "46002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24131352838037232,
              38.92331269997155
            ]
          },
          "bbox": [
            -0.24131352838037232,
            38.92331269997155,
            -0.24131352838037232,
            38.92331269997155
          ]
        }
      ],
      "bbox": [
        -0.24131352838037232,
        38.92331269997155,
        -0.24131352838037232,
        38.92331269997155
      ]
    },
    "46004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5580233883476775,
              38.834896623744655
            ]
          },
          "bbox": [
            -0.5580233883476775,
            38.834896623744655,
            -0.5580233883476775,
            38.834896623744655
          ]
        }
      ],
      "bbox": [
        -0.5580233883476775,
        38.834896623744655,
        -0.5580233883476775,
        38.834896623744655
      ]
    },
    "46005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.47112614838831346,
              39.451533081429446
            ]
          },
          "bbox": [
            -0.47112614838831346,
            39.451533081429446,
            -0.47112614838831346,
            39.451533081429446
          ]
        }
      ],
      "bbox": [
        -0.47112614838831346,
        39.451533081429446,
        -0.47112614838831346,
        39.451533081429446
      ]
    },
    "46006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.524478913459031,
              38.84841030643404
            ]
          },
          "bbox": [
            -0.524478913459031,
            38.84841030643404,
            -0.524478913459031,
            38.84841030643404
          ]
        }
      ],
      "bbox": [
        -0.524478913459031,
        38.84841030643404,
        -0.524478913459031,
        38.84841030643404
      ]
    },
    "46007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4163198095947203,
              39.39229947727781
            ]
          },
          "bbox": [
            -0.4163198095947203,
            39.39229947727781,
            -0.4163198095947203,
            39.39229947727781
          ]
        }
      ],
      "bbox": [
        -0.4163198095947203,
        39.39229947727781,
        -0.4163198095947203,
        39.39229947727781
      ]
    },
    "46008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3740581569829481,
              39.22046491860515
            ]
          },
          "bbox": [
            -0.3740581569829481,
            39.22046491860515,
            -0.3740581569829481,
            39.22046491860515
          ]
        }
      ],
      "bbox": [
        -0.3740581569829481,
        39.22046491860515,
        -0.3740581569829481,
        39.22046491860515
      ]
    },
    "46009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.35119740285331263,
              39.55022542476485
            ]
          },
          "bbox": [
            -0.35119740285331263,
            39.55022542476485,
            -0.35119740285331263,
            39.55022542476485
          ]
        }
      ],
      "bbox": [
        -0.35119740285331263,
        39.55022542476485,
        -0.35119740285331263,
        39.55022542476485
      ]
    },
    "46010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3573444693683538,
              39.677203619219775
            ]
          },
          "bbox": [
            -0.3573444693683538,
            39.677203619219775,
            -0.3573444693683538,
            39.677203619219775
          ]
        }
      ],
      "bbox": [
        -0.3573444693683538,
        39.677203619219775,
        -0.3573444693683538,
        39.677203619219775
      ]
    },
    "46011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5371986317122522,
              39.118318647532085
            ]
          },
          "bbox": [
            -0.5371986317122522,
            39.118318647532085,
            -0.5371986317122522,
            39.118318647532085
          ]
        }
      ],
      "bbox": [
        -0.5371986317122522,
        39.118318647532085,
        -0.5371986317122522,
        39.118318647532085
      ]
    },
    "46012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7554819719393614,
              39.3775601135659
            ]
          },
          "bbox": [
            -0.7554819719393614,
            39.3775601135659,
            -0.7554819719393614,
            39.3775601135659
          ]
        }
      ],
      "bbox": [
        -0.7554819719393614,
        39.3775601135659,
        -0.7554819719393614,
        39.3775601135659
      ]
    },
    "46013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3370945569063819,
              39.50046013134554
            ]
          },
          "bbox": [
            -0.3370945569063819,
            39.50046013134554,
            -0.3370945569063819,
            39.50046013134554
          ]
        }
      ],
      "bbox": [
        -0.3370945569063819,
        39.50046013134554,
        -0.3370945569063819,
        39.50046013134554
      ]
    },
    "46014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3176971936844696,
              39.54280449542028
            ]
          },
          "bbox": [
            -0.3176971936844696,
            39.54280449542028,
            -0.3176971936844696,
            39.54280449542028
          ]
        }
      ],
      "bbox": [
        -0.3176971936844696,
        39.54280449542028,
        -0.3176971936844696,
        39.54280449542028
      ]
    },
    "46015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.44733212349648477,
              39.38129466344034
            ]
          },
          "bbox": [
            -0.44733212349648477,
            39.38129466344034,
            -0.44733212349648477,
            39.38129466344034
          ]
        }
      ],
      "bbox": [
        -0.44733212349648477,
        39.38129466344034,
        -0.44733212349648477,
        39.38129466344034
      ]
    },
    "46016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.558066247240822,
              39.06570432293193
            ]
          },
          "bbox": [
            -0.558066247240822,
            39.06570432293193,
            -0.558066247240822,
            39.06570432293193
          ]
        }
      ],
      "bbox": [
        -0.558066247240822,
        39.06570432293193,
        -0.558066247240822,
        39.06570432293193
      ]
    },
    "46017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4509523542592828,
              39.137013542665
            ]
          },
          "bbox": [
            -0.4509523542592828,
            39.137013542665,
            -0.4509523542592828,
            39.137013542665
          ]
        }
      ],
      "bbox": [
        -0.4509523542592828,
        39.137013542665,
        -0.4509523542592828,
        39.137013542665
      ]
    },
    "46018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6786301115977368,
              39.803882628989044
            ]
          },
          "bbox": [
            -0.6786301115977368,
            39.803882628989044,
            -0.6786301115977368,
            39.803882628989044
          ]
        }
      ],
      "bbox": [
        -0.6786301115977368,
        39.803882628989044,
        -0.6786301115977368,
        39.803882628989044
      ]
    },
    "46021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.48836577805425574,
              39.45981384147067
            ]
          },
          "bbox": [
            -0.48836577805425574,
            39.45981384147067,
            -0.48836577805425574,
            39.45981384147067
          ]
        }
      ],
      "bbox": [
        -0.48836577805425574,
        39.45981384147067,
        -0.48836577805425574,
        39.45981384147067
      ]
    },
    "46022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.36167678321349717,
              39.402964926339706
            ]
          },
          "bbox": [
            -0.36167678321349717,
            39.402964926339706,
            -0.36167678321349717,
            39.402964926339706
          ]
        }
      ],
      "bbox": [
        -0.36167678321349717,
        39.402964926339706,
        -0.36167678321349717,
        39.402964926339706
      ]
    },
    "46023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24632318501830724,
              38.930015020220345
            ]
          },
          "bbox": [
            -0.24632318501830724,
            38.930015020220345,
            -0.24632318501830724,
            38.930015020220345
          ]
        }
      ],
      "bbox": [
        -0.24632318501830724,
        38.930015020220345,
        -0.24632318501830724,
        38.930015020220345
      ]
    },
    "46024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.346959259572339,
              39.771194764786244
            ]
          },
          "bbox": [
            -0.346959259572339,
            39.771194764786244,
            -0.346959259572339,
            39.771194764786244
          ]
        }
      ],
      "bbox": [
        -0.346959259572339,
        39.771194764786244,
        -0.346959259572339,
        39.771194764786244
      ]
    },
    "46025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3810502511285614,
              39.53911199018693
            ]
          },
          "bbox": [
            -0.3810502511285614,
            39.53911199018693,
            -0.3810502511285614,
            39.53911199018693
          ]
        }
      ],
      "bbox": [
        -0.3810502511285614,
        39.53911199018693,
        -0.3810502511285614,
        39.53911199018693
      ]
    },
    "46026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.523221712474372,
              39.282635078838666
            ]
          },
          "bbox": [
            -0.523221712474372,
            39.282635078838666,
            -0.523221712474372,
            39.282635078838666
          ]
        }
      ],
      "bbox": [
        -0.523221712474372,
        39.282635078838666,
        -0.523221712474372,
        39.282635078838666
      ]
    },
    "46027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5023707415492464,
              38.90660545861725
            ]
          },
          "bbox": [
            -0.5023707415492464,
            38.90660545861725,
            -0.5023707415492464,
            38.90660545861725
          ]
        }
      ],
      "bbox": [
        -0.5023707415492464,
        38.90660545861725,
        -0.5023707415492464,
        38.90660545861725
      ]
    },
    "46028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3640027680230219,
              39.785832950069214
            ]
          },
          "bbox": [
            -0.3640027680230219,
            39.785832950069214,
            -0.3640027680230219,
            39.785832950069214
          ]
        }
      ],
      "bbox": [
        -0.3640027680230219,
        39.785832950069214,
        -0.3640027680230219,
        39.785832950069214
      ]
    },
    "46029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4292705883714149,
              39.21381107732384
            ]
          },
          "bbox": [
            -0.4292705883714149,
            39.21381107732384,
            -0.4292705883714149,
            39.21381107732384
          ]
        }
      ],
      "bbox": [
        -0.4292705883714149,
        39.21381107732384,
        -0.4292705883714149,
        39.21381107732384
      ]
    },
    "46031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.46404834383609944,
              39.259954000418425
            ]
          },
          "bbox": [
            -0.46404834383609944,
            39.259954000418425,
            -0.46404834383609944,
            39.259954000418425
          ]
        }
      ],
      "bbox": [
        -0.46404834383609944,
        39.259954000418425,
        -0.46404834383609944,
        39.259954000418425
      ]
    },
    "46032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.350387335116302,
              39.51422027877294
            ]
          },
          "bbox": [
            -0.350387335116302,
            39.51422027877294,
            -0.350387335116302,
            39.51422027877294
          ]
        }
      ],
      "bbox": [
        -0.350387335116302,
        39.51422027877294,
        -0.350387335116302,
        39.51422027877294
      ]
    },
    "46033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.28665358086929305,
              38.92578764124075
            ]
          },
          "bbox": [
            -0.28665358086929305,
            38.92578764124075,
            -0.28665358086929305,
            38.92578764124075
          ]
        }
      ],
      "bbox": [
        -0.28665358086929305,
        38.92578764124075,
        -0.28665358086929305,
        38.92578764124075
      ]
    },
    "46034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.17964620700667433,
              38.945198781115785
            ]
          },
          "bbox": [
            -0.17964620700667433,
            38.945198781115785,
            -0.17964620700667433,
            38.945198781115785
          ]
        }
      ],
      "bbox": [
        -0.17964620700667433,
        38.945198781115785,
        -0.17964620700667433,
        38.945198781115785
      ]
    },
    "46035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4114822773937485,
              39.29681943693585
            ]
          },
          "bbox": [
            -0.4114822773937485,
            39.29681943693585,
            -0.4114822773937485,
            39.29681943693585
          ]
        }
      ],
      "bbox": [
        -0.4114822773937485,
        39.29681943693585,
        -0.4114822773937485,
        39.29681943693585
      ]
    },
    "46036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.027690883819428,
              39.907524453989865
            ]
          },
          "bbox": [
            -1.027690883819428,
            39.907524453989865,
            -1.027690883819428,
            39.907524453989865
          ]
        }
      ],
      "bbox": [
        -1.027690883819428,
        39.907524453989865,
        -1.027690883819428,
        39.907524453989865
      ]
    },
    "46038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8019678865330588,
              39.81237816643921
            ]
          },
          "bbox": [
            -0.8019678865330588,
            39.81237816643921,
            -0.8019678865330588,
            39.81237816643921
          ]
        }
      ],
      "bbox": [
        -0.8019678865330588,
        39.81237816643921,
        -0.8019678865330588,
        39.81237816643921
      ]
    },
    "46039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6551972027580135,
              39.01501630898757
            ]
          },
          "bbox": [
            -0.6551972027580135,
            39.01501630898757,
            -0.6551972027580135,
            39.01501630898757
          ]
        }
      ],
      "bbox": [
        -0.6551972027580135,
        39.01501630898757,
        -0.6551972027580135,
        39.01501630898757
      ]
    },
    "46040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6012631088215397,
              39.09697622011624
            ]
          },
          "bbox": [
            -0.6012631088215397,
            39.09697622011624,
            -0.6012631088215397,
            39.09697622011624
          ]
        }
      ],
      "bbox": [
        -0.6012631088215397,
        39.09697622011624,
        -0.6012631088215397,
        39.09697622011624
      ]
    },
    "46041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1483552241459996,
              39.92866032244574
            ]
          },
          "bbox": [
            -1.1483552241459996,
            39.92866032244574,
            -1.1483552241459996,
            39.92866032244574
          ]
        }
      ],
      "bbox": [
        -1.1483552241459996,
        39.92866032244574,
        -1.1483552241459996,
        39.92866032244574
      ]
    },
    "46042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6069462703603083,
              38.884013006262066
            ]
          },
          "bbox": [
            -0.6069462703603083,
            38.884013006262066,
            -0.6069462703603083,
            38.884013006262066
          ]
        }
      ],
      "bbox": [
        -0.6069462703603083,
        38.884013006262066,
        -0.6069462703603083,
        38.884013006262066
      ]
    },
    "46043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.34272543893414453,
              38.878684631258544
            ]
          },
          "bbox": [
            -0.34272543893414453,
            38.878684631258544,
            -0.34272543893414453,
            38.878684631258544
          ]
        }
      ],
      "bbox": [
        -0.34272543893414453,
        38.878684631258544,
        -0.34272543893414453,
        38.878684631258544
      ]
    },
    "46044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0742399932148305,
              39.01883184580339
            ]
          },
          "bbox": [
            -1.0742399932148305,
            39.01883184580339,
            -1.0742399932148305,
            39.01883184580339
          ]
        }
      ],
      "bbox": [
        -1.0742399932148305,
        39.01883184580339,
        -1.0742399932148305,
        39.01883184580339
      ]
    },
    "46045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4221634665879432,
              39.013057402428544
            ]
          },
          "bbox": [
            -0.4221634665879432,
            39.013057402428544,
            -0.4221634665879432,
            39.013057402428544
          ]
        }
      ],
      "bbox": [
        -0.4221634665879432,
        39.013057402428544,
        -0.4221634665879432,
        39.013057402428544
      ]
    },
    "46046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3135485483185452,
              39.011401945171166
            ]
          },
          "bbox": [
            -0.3135485483185452,
            39.011401945171166,
            -0.3135485483185452,
            39.011401945171166
          ]
        }
      ],
      "bbox": [
        -0.3135485483185452,
        39.011401945171166,
        -0.3135485483185452,
        39.011401945171166
      ]
    },
    "46047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4634089339954971,
              38.863908672075745
            ]
          },
          "bbox": [
            -0.4634089339954971,
            38.863908672075745,
            -0.4634089339954971,
            38.863908672075745
          ]
        }
      ],
      "bbox": [
        -0.4634089339954971,
        38.863908672075745,
        -0.4634089339954971,
        38.863908672075745
      ]
    },
    "46048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.15271829194640332,
              38.952838133727596
            ]
          },
          "bbox": [
            -0.15271829194640332,
            38.952838133727596,
            -0.15271829194640332,
            38.952838133727596
          ]
        }
      ],
      "bbox": [
        -0.15271829194640332,
        38.952838133727596,
        -0.15271829194640332,
        38.952838133727596
      ]
    },
    "46049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4977169641484523,
              38.94589691522567
            ]
          },
          "bbox": [
            -0.4977169641484523,
            38.94589691522567,
            -0.4977169641484523,
            38.94589691522567
          ]
        }
      ],
      "bbox": [
        -0.4977169641484523,
        38.94589691522567,
        -0.4977169641484523,
        38.94589691522567
      ]
    },
    "46050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1246035912234003,
              39.72278529258399
            ]
          },
          "bbox": [
            -1.1246035912234003,
            39.72278529258399,
            -1.1246035912234003,
            39.72278529258399
          ]
        }
      ],
      "bbox": [
        -1.1246035912234003,
        39.72278529258399,
        -1.1246035912234003,
        39.72278529258399
      ]
    },
    "46051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6166970444385995,
              39.59403098408876
            ]
          },
          "bbox": [
            -0.6166970444385995,
            39.59403098408876,
            -0.6166970444385995,
            39.59403098408876
          ]
        }
      ],
      "bbox": [
        -0.6166970444385995,
        39.59403098408876,
        -0.6166970444385995,
        39.59403098408876
      ]
    },
    "46052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.23662526120252897,
              39.73427204525602
            ]
          },
          "bbox": [
            -0.23662526120252897,
            39.73427204525602,
            -0.23662526120252897,
            39.73427204525602
          ]
        }
      ],
      "bbox": [
        -0.23662526120252897,
        39.73427204525602,
        -0.23662526120252897,
        39.73427204525602
      ]
    },
    "46053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5487728064441132,
              39.07041992324134
            ]
          },
          "bbox": [
            -0.5487728064441132,
            39.07041992324134,
            -0.5487728064441132,
            39.07041992324134
          ]
        }
      ],
      "bbox": [
        -0.5487728064441132,
        39.07041992324134,
        -0.5487728064441132,
        39.07041992324134
      ]
    },
    "46054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.39776496233119657,
              39.42215047421272
            ]
          },
          "bbox": [
            -0.39776496233119657,
            39.42215047421272,
            -0.39776496233119657,
            39.42215047421272
          ]
        }
      ],
      "bbox": [
        -0.39776496233119657,
        39.42215047421272,
        -0.39776496233119657,
        39.42215047421272
      ]
    },
    "46055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.19178209224555956,
              38.9342249688066
            ]
          },
          "bbox": [
            -0.19178209224555956,
            38.9342249688066,
            -0.19178209224555956,
            38.9342249688066
          ]
        }
      ],
      "bbox": [
        -0.19178209224555956,
        38.9342249688066,
        -0.19178209224555956,
        38.9342249688066
      ]
    },
    "46056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.422952771926864,
              38.851155330844456
            ]
          },
          "bbox": [
            -0.422952771926864,
            38.851155330844456,
            -0.422952771926864,
            38.851155330844456
          ]
        }
      ],
      "bbox": [
        -0.422952771926864,
        38.851155330844456,
        -0.422952771926864,
        38.851155330844456
      ]
    },
    "46057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.331209658161554,
              38.91921484029755
            ]
          },
          "bbox": [
            -0.331209658161554,
            38.91921484029755,
            -0.331209658161554,
            38.91921484029755
          ]
        }
      ],
      "bbox": [
        -0.331209658161554,
        38.91921484029755,
        -0.331209658161554,
        38.91921484029755
      ]
    },
    "46058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2755917134213989,
              39.724375881006054
            ]
          },
          "bbox": [
            -0.2755917134213989,
            39.724375881006054,
            -0.2755917134213989,
            39.724375881006054
          ]
        }
      ],
      "bbox": [
        -0.2755917134213989,
        39.724375881006054,
        -0.2755917134213989,
        39.724375881006054
      ]
    },
    "46059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3019847602577025,
              39.062582599086625
            ]
          },
          "bbox": [
            -0.3019847602577025,
            39.062582599086625,
            -0.3019847602577025,
            39.062582599086625
          ]
        }
      ],
      "bbox": [
        -0.3019847602577025,
        39.062582599086625,
        -0.3019847602577025,
        39.062582599086625
      ]
    },
    "46060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.44483558155816133,
              39.28477997797387
            ]
          },
          "bbox": [
            -0.44483558155816133,
            39.28477997797387,
            -0.44483558155816133,
            39.28477997797387
          ]
        }
      ],
      "bbox": [
        -0.44483558155816133,
        39.28477997797387,
        -0.44483558155816133,
        39.28477997797387
      ]
    },
    "46061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.19059963877841768,
              38.92648762114664
            ]
          },
          "bbox": [
            -0.19059963877841768,
            38.92648762114664,
            -0.19059963877841768,
            38.92648762114664
          ]
        }
      ],
      "bbox": [
        -0.19059963877841768,
        38.92648762114664,
        -0.19059963877841768,
        38.92648762114664
      ]
    },
    "46062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4455160097956166,
              38.95482625387413
            ]
          },
          "bbox": [
            -0.4455160097956166,
            38.95482625387413,
            -0.4455160097956166,
            38.95482625387413
          ]
        }
      ],
      "bbox": [
        -0.4455160097956166,
        38.95482625387413,
        -0.4455160097956166,
        38.95482625387413
      ]
    },
    "46063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5503099951417814,
              39.1959318882663
            ]
          },
          "bbox": [
            -0.5503099951417814,
            39.1959318882663,
            -0.5503099951417814,
            39.1959318882663
          ]
        }
      ],
      "bbox": [
        -0.5503099951417814,
        39.1959318882663,
        -0.5503099951417814,
        39.1959318882663
      ]
    },
    "46064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.494295313859976,
              39.13844503625251
            ]
          },
          "bbox": [
            -0.494295313859976,
            39.13844503625251,
            -0.494295313859976,
            39.13844503625251
          ]
        }
      ],
      "bbox": [
        -0.494295313859976,
        39.13844503625251,
        -0.494295313859976,
        39.13844503625251
      ]
    },
    "46065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4147021527372802,
              39.382925558761535
            ]
          },
          "bbox": [
            -0.4147021527372802,
            39.382925558761535,
            -0.4147021527372802,
            39.382925558761535
          ]
        }
      ],
      "bbox": [
        -0.4147021527372802,
        39.382925558761535,
        -0.4147021527372802,
        39.382925558761535
      ]
    },
    "46066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.19523079784339115,
              38.96132360993472
            ]
          },
          "bbox": [
            -0.19523079784339115,
            38.96132360993472,
            -0.19523079784339115,
            38.96132360993472
          ]
        }
      ],
      "bbox": [
        -0.19523079784339115,
        38.96132360993472,
        -0.19523079784339115,
        38.96132360993472
      ]
    },
    "46067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5723677228425603,
              39.61574544813137
            ]
          },
          "bbox": [
            -0.5723677228425603,
            39.61574544813137,
            -0.5723677228425603,
            39.61574544813137
          ]
        }
      ],
      "bbox": [
        -0.5723677228425603,
        39.61574544813137,
        -0.5723677228425603,
        39.61574544813137
      ]
    },
    "46068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5319750574324082,
              38.826312188361044
            ]
          },
          "bbox": [
            -0.5319750574324082,
            38.826312188361044,
            -0.5319750574324082,
            38.826312188361044
          ]
        }
      ],
      "bbox": [
        -0.5319750574324082,
        38.826312188361044,
        -0.5319750574324082,
        38.826312188361044
      ]
    },
    "46069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4812922085204881,
              38.90860332094829
            ]
          },
          "bbox": [
            -0.4812922085204881,
            38.90860332094829,
            -0.4812922085204881,
            38.90860332094829
          ]
        }
      ],
      "bbox": [
        -0.4812922085204881,
        38.90860332094829,
        -0.4812922085204881,
        38.90860332094829
      ]
    },
    "46070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4629669231409679,
              39.59974469143727
            ]
          },
          "bbox": [
            -0.4629669231409679,
            39.59974469143727,
            -0.4629669231409679,
            39.59974469143727
          ]
        }
      ],
      "bbox": [
        -0.4629669231409679,
        39.59974469143727,
        -0.4629669231409679,
        39.59974469143727
      ]
    },
    "46071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.848505238721252,
              39.11317670866658
            ]
          },
          "bbox": [
            -0.848505238721252,
            39.11317670866658,
            -0.848505238721252,
            39.11317670866658
          ]
        }
      ],
      "bbox": [
        -0.848505238721252,
        39.11317670866658,
        -0.848505238721252,
        39.11317670866658
      ]
    },
    "46072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6086446203191994,
              38.74209902938236
            ]
          },
          "bbox": [
            -0.6086446203191994,
            38.74209902938236,
            -0.6086446203191994,
            38.74209902938236
          ]
        }
      ],
      "bbox": [
        -0.6086446203191994,
        38.74209902938236,
        -0.6086446203191994,
        38.74209902938236
      ]
    },
    "46073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7459124615109438,
              39.05023381437554
            ]
          },
          "bbox": [
            -0.7459124615109438,
            39.05023381437554,
            -0.7459124615109438,
            39.05023381437554
          ]
        }
      ],
      "bbox": [
        -0.7459124615109438,
        39.05023381437554,
        -0.7459124615109438,
        39.05023381437554
      ]
    },
    "46074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3673955102039892,
              39.51942529253091
            ]
          },
          "bbox": [
            -0.3673955102039892,
            39.51942529253091,
            -0.3673955102039892,
            39.51942529253091
          ]
        }
      ],
      "bbox": [
        -0.3673955102039892,
        39.51942529253091,
        -0.3673955102039892,
        39.51942529253091
      ]
    },
    "46075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5039858174337646,
              38.86724554916746
            ]
          },
          "bbox": [
            -0.5039858174337646,
            38.86724554916746,
            -0.5039858174337646,
            38.86724554916746
          ]
        }
      ],
      "bbox": [
        -0.5039858174337646,
        38.86724554916746,
        -0.5039858174337646,
        38.86724554916746
      ]
    },
    "46076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7708532461097599,
              39.616628422323856
            ]
          },
          "bbox": [
            -0.7708532461097599,
            39.616628422323856,
            -0.7708532461097599,
            39.616628422323856
          ]
        }
      ],
      "bbox": [
        -0.7708532461097599,
        39.616628422323856,
        -0.7708532461097599,
        39.616628422323856
      ]
    },
    "46077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.848332630530942,
              39.42910812343129
            ]
          },
          "bbox": [
            -0.848332630530942,
            39.42910812343129,
            -0.848332630530942,
            39.42910812343129
          ]
        }
      ],
      "bbox": [
        -0.848332630530942,
        39.42910812343129,
        -0.848332630530942,
        39.42910812343129
      ]
    },
    "46078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.41117317712125917,
              39.50819505962108
            ]
          },
          "bbox": [
            -0.41117317712125917,
            39.50819505962108,
            -0.41117317712125917,
            39.50819505962108
          ]
        }
      ],
      "bbox": [
        -0.41117317712125917,
        39.50819505962108,
        -0.41117317712125917,
        39.50819505962108
      ]
    },
    "46079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9501411923678502,
              39.74077715642901
            ]
          },
          "bbox": [
            -0.9501411923678502,
            39.74077715642901,
            -0.9501411923678502,
            39.74077715642901
          ]
        }
      ],
      "bbox": [
        -0.9501411923678502,
        39.74077715642901,
        -0.9501411923678502,
        39.74077715642901
      ]
    },
    "46080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.377944780710082,
              39.63674580282729
            ]
          },
          "bbox": [
            -1.377944780710082,
            39.63674580282729,
            -1.377944780710082,
            39.63674580282729
          ]
        }
      ],
      "bbox": [
        -1.377944780710082,
        39.63674580282729,
        -1.377944780710082,
        39.63674580282729
      ]
    },
    "46081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5858817595705615,
              38.950364953909116
            ]
          },
          "bbox": [
            -0.5858817595705615,
            38.950364953909116,
            -0.5858817595705615,
            38.950364953909116
          ]
        }
      ],
      "bbox": [
        -0.5858817595705615,
        38.950364953909116,
        -0.5858817595705615,
        38.950364953909116
      ]
    },
    "46083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4277631948168576,
              39.098340133195606
            ]
          },
          "bbox": [
            -0.4277631948168576,
            39.098340133195606,
            -0.4277631948168576,
            39.098340133195606
          ]
        }
      ],
      "bbox": [
        -0.4277631948168576,
        39.098340133195606,
        -0.4277631948168576,
        39.098340133195606
      ]
    },
    "46084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5723795087543592,
              39.05982913752443
            ]
          },
          "bbox": [
            -0.5723795087543592,
            39.05982913752443,
            -0.5723795087543592,
            39.05982913752443
          ]
        }
      ],
      "bbox": [
        -0.5723795087543592,
        39.05982913752443,
        -0.5723795087543592,
        39.05982913752443
      ]
    },
    "46085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5363302364545864,
              39.23469677287634
            ]
          },
          "bbox": [
            -0.5363302364545864,
            39.23469677287634,
            -0.5363302364545864,
            39.23469677287634
          ]
        }
      ],
      "bbox": [
        -0.5363302364545864,
        39.23469677287634,
        -0.5363302364545864,
        39.23469677287634
      ]
    },
    "46086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4732875837432933,
              38.84377807402655
            ]
          },
          "bbox": [
            -0.4732875837432933,
            38.84377807402655,
            -0.4732875837432933,
            38.84377807402655
          ]
        }
      ],
      "bbox": [
        -0.4732875837432933,
        38.84377807402655,
        -0.4732875837432933,
        38.84377807402655
      ]
    },
    "46087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2497159661635828,
              40.033403241909056
            ]
          },
          "bbox": [
            -1.2497159661635828,
            40.033403241909056,
            -1.2497159661635828,
            40.033403241909056
          ]
        }
      ],
      "bbox": [
        -1.2497159661635828,
        40.033403241909056,
        -1.2497159661635828,
        40.033403241909056
      ]
    },
    "46088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2609299071661684,
              40.0144671133634
            ]
          },
          "bbox": [
            -1.2609299071661684,
            40.0144671133634,
            -1.2609299071661684,
            40.0144671133634
          ]
        }
      ],
      "bbox": [
        -1.2609299071661684,
        40.0144671133634,
        -1.2609299071661684,
        40.0144671133634
      ]
    },
    "46089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7435839256063159,
              39.70337336378477
            ]
          },
          "bbox": [
            -0.7435839256063159,
            39.70337336378477,
            -0.7435839256063159,
            39.70337336378477
          ]
        }
      ],
      "bbox": [
        -0.7435839256063159,
        39.70337336378477,
        -0.7435839256063159,
        39.70337336378477
      ]
    },
    "46090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.38616357520244377,
              38.87969588379959
            ]
          },
          "bbox": [
            -0.38616357520244377,
            38.87969588379959,
            -0.38616357520244377,
            38.87969588379959
          ]
        }
      ],
      "bbox": [
        -0.38616357520244377,
        38.87969588379959,
        -0.38616357520244377,
        38.87969588379959
      ]
    },
    "46091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2639075502461974,
              38.906629058988116
            ]
          },
          "bbox": [
            -0.2639075502461974,
            38.906629058988116,
            -0.2639075502461974,
            38.906629058988116
          ]
        }
      ],
      "bbox": [
        -0.2639075502461974,
        38.906629058988116,
        -0.2639075502461974,
        38.906629058988116
      ]
    },
    "46092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3357474233122686,
              40.12855075517774
            ]
          },
          "bbox": [
            -1.3357474233122686,
            40.12855075517774,
            -1.3357474233122686,
            40.12855075517774
          ]
        }
      ],
      "bbox": [
        -1.3357474233122686,
        40.12855075517774,
        -1.3357474233122686,
        40.12855075517774
      ]
    },
    "46093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6088091983554254,
              39.255471918077106
            ]
          },
          "bbox": [
            -0.6088091983554254,
            39.255471918077106,
            -0.6088091983554254,
            39.255471918077106
          ]
        }
      ],
      "bbox": [
        -0.6088091983554254,
        39.255471918077106,
        -0.6088091983554254,
        39.255471918077106
      ]
    },
    "46094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3947244500143033,
              39.396267033298436
            ]
          },
          "bbox": [
            -0.3947244500143033,
            39.396267033298436,
            -0.3947244500143033,
            39.396267033298436
          ]
        }
      ],
      "bbox": [
        -0.3947244500143033,
        39.396267033298436,
        -0.3947244500143033,
        39.396267033298436
      ]
    },
    "46095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2914038139541912,
              39.545432674902656
            ]
          },
          "bbox": [
            -1.2914038139541912,
            39.545432674902656,
            -1.2914038139541912,
            39.545432674902656
          ]
        }
      ],
      "bbox": [
        -1.2914038139541912,
        39.545432674902656,
        -1.2914038139541912,
        39.545432674902656
      ]
    },
    "46096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5962420947581027,
              38.985649722260575
            ]
          },
          "bbox": [
            -0.5962420947581027,
            38.985649722260575,
            -0.5962420947581027,
            38.985649722260575
          ]
        }
      ],
      "bbox": [
        -0.5962420947581027,
        38.985649722260575,
        -0.5962420947581027,
        38.985649722260575
      ]
    },
    "46097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.08066432946076,
              39.25479781377419
            ]
          },
          "bbox": [
            -1.08066432946076,
            39.25479781377419,
            -1.08066432946076,
            39.25479781377419
          ]
        }
      ],
      "bbox": [
        -1.08066432946076,
        39.25479781377419,
        -1.08066432946076,
        39.25479781377419
      ]
    },
    "46098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.35254703682718636,
              39.158934065646505
            ]
          },
          "bbox": [
            -0.35254703682718636,
            39.158934065646505,
            -0.35254703682718636,
            39.158934065646505
          ]
        }
      ],
      "bbox": [
        -0.35254703682718636,
        39.158934065646505,
        -0.35254703682718636,
        39.158934065646505
      ]
    },
    "46099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9388062525976228,
              39.24929462615372
            ]
          },
          "bbox": [
            -0.9388062525976228,
            39.24929462615372,
            -0.9388062525976228,
            39.24929462615372
          ]
        }
      ],
      "bbox": [
        -0.9388062525976228,
        39.24929462615372,
        -0.9388062525976228,
        39.24929462615372
      ]
    },
    "46100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6011639708276776,
              39.05848160607725
            ]
          },
          "bbox": [
            -0.6011639708276776,
            39.05848160607725,
            -0.6011639708276776,
            39.05848160607725
          ]
        }
      ],
      "bbox": [
        -0.6011639708276776,
        39.05848160607725,
        -0.6011639708276776,
        39.05848160607725
      ]
    },
    "46101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2890566759048107,
              39.74462040103733
            ]
          },
          "bbox": [
            -0.2890566759048107,
            39.74462040103733,
            -0.2890566759048107,
            39.74462040103733
          ]
        }
      ],
      "bbox": [
        -0.2890566759048107,
        39.74462040103733,
        -0.2890566759048107,
        39.74462040103733
      ]
    },
    "46102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.49562578723999473,
              39.47230628842445
            ]
          },
          "bbox": [
            -0.49562578723999473,
            39.47230628842445,
            -0.49562578723999473,
            39.47230628842445
          ]
        }
      ],
      "bbox": [
        -0.49562578723999473,
        39.47230628842445,
        -0.49562578723999473,
        39.47230628842445
      ]
    },
    "46103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2393632231790908,
              39.72886154200082
            ]
          },
          "bbox": [
            -0.2393632231790908,
            39.72886154200082,
            -0.2393632231790908,
            39.72886154200082
          ]
        }
      ],
      "bbox": [
        -0.2393632231790908,
        39.72886154200082,
        -0.2393632231790908,
        39.72886154200082
      ]
    },
    "46104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3852321847703754,
              38.96914833441977
            ]
          },
          "bbox": [
            -0.3852321847703754,
            38.96914833441977,
            -0.3852321847703754,
            38.96914833441977
          ]
        }
      ],
      "bbox": [
        -0.3852321847703754,
        38.96914833441977,
        -0.3852321847703754,
        38.96914833441977
      ]
    },
    "46105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2594465035008206,
              39.16292528836945
            ]
          },
          "bbox": [
            -0.2594465035008206,
            39.16292528836945,
            -0.2594465035008206,
            39.16292528836945
          ]
        }
      ],
      "bbox": [
        -0.2594465035008206,
        39.16292528836945,
        -0.2594465035008206,
        39.16292528836945
      ]
    },
    "46106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.016993742016039,
              39.74273654261839
            ]
          },
          "bbox": [
            -1.016993742016039,
            39.74273654261839,
            -1.016993742016039,
            39.74273654261839
          ]
        }
      ],
      "bbox": [
        -1.016993742016039,
        39.74273654261839,
        -1.016993742016039,
        39.74273654261839
      ]
    },
    "46107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.700012009388545,
              39.03909165822527
            ]
          },
          "bbox": [
            -0.700012009388545,
            39.03909165822527,
            -0.700012009388545,
            39.03909165822527
          ]
        }
      ],
      "bbox": [
        -0.700012009388545,
        39.03909165822527,
        -0.700012009388545,
        39.03909165822527
      ]
    },
    "46108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9631947221379018,
              39.58427485551147
            ]
          },
          "bbox": [
            -0.9631947221379018,
            39.58427485551147,
            -0.9631947221379018,
            39.58427485551147
          ]
        }
      ],
      "bbox": [
        -0.9631947221379018,
        39.58427485551147,
        -0.9631947221379018,
        39.58427485551147
      ]
    },
    "46109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6777086399038185,
              39.5086075785119
            ]
          },
          "bbox": [
            -0.6777086399038185,
            39.5086075785119,
            -0.6777086399038185,
            39.5086075785119
          ]
        }
      ],
      "bbox": [
        -0.6777086399038185,
        39.5086075785119,
        -0.6777086399038185,
        39.5086075785119
      ]
    },
    "46110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4302053654210003,
              39.45909917967318
            ]
          },
          "bbox": [
            -0.4302053654210003,
            39.45909917967318,
            -0.4302053654210003,
            39.45909917967318
          ]
        }
      ],
      "bbox": [
        -0.4302053654210003,
        39.45909917967318,
        -0.4302053654210003,
        39.45909917967318
      ]
    },
    "46111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.741992853862098,
              39.48762615451366
            ]
          },
          "bbox": [
            -0.741992853862098,
            39.48762615451366,
            -0.741992853862098,
            39.48762615451366
          ]
        }
      ],
      "bbox": [
        -0.741992853862098,
        39.48762615451366,
        -0.741992853862098,
        39.48762615451366
      ]
    },
    "46112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8588506311925759,
              39.65170863640385
            ]
          },
          "bbox": [
            -0.8588506311925759,
            39.65170863640385,
            -0.8588506311925759,
            39.65170863640385
          ]
        }
      ],
      "bbox": [
        -0.8588506311925759,
        39.65170863640385,
        -0.8588506311925759,
        39.65170863640385
      ]
    },
    "46113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.15027420394039226,
              38.97210357500832
            ]
          },
          "bbox": [
            -0.15027420394039226,
            38.97210357500832,
            -0.15027420394039226,
            38.97210357500832
          ]
        }
      ],
      "bbox": [
        -0.15027420394039226,
        38.97210357500832,
        -0.15027420394039226,
        38.97210357500832
      ]
    },
    "46114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.924310980600242,
              39.7008081455389
            ]
          },
          "bbox": [
            -0.924310980600242,
            39.7008081455389,
            -0.924310980600242,
            39.7008081455389
          ]
        }
      ],
      "bbox": [
        -0.924310980600242,
        39.7008081455389,
        -0.924310980600242,
        39.7008081455389
      ]
    },
    "46115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7742871860607443,
              39.28710283243583
            ]
          },
          "bbox": [
            -0.7742871860607443,
            39.28710283243583,
            -0.7742871860607443,
            39.28710283243583
          ]
        }
      ],
      "bbox": [
        -0.7742871860607443,
        39.28710283243583,
        -0.7742871860607443,
        39.28710283243583
      ]
    },
    "46117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.33925346643364607,
              39.553734076837706
            ]
          },
          "bbox": [
            -0.33925346643364607,
            39.553734076837706,
            -0.33925346643364607,
            39.553734076837706
          ]
        }
      ],
      "bbox": [
        -0.33925346643364607,
        39.553734076837706,
        -0.33925346643364607,
        39.553734076837706
      ]
    },
    "46118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.810455426568162,
              38.96388704845267
            ]
          },
          "bbox": [
            -0.810455426568162,
            38.96388704845267,
            -0.810455426568162,
            38.96388704845267
          ]
        }
      ],
      "bbox": [
        -0.810455426568162,
        38.96388704845267,
        -0.810455426568162,
        38.96388704845267
      ]
    },
    "46120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.36889666248495967,
              39.71456477615856
            ]
          },
          "bbox": [
            -0.36889666248495967,
            39.71456477615856,
            -0.36889666248495967,
            39.71456477615856
          ]
        }
      ],
      "bbox": [
        -0.36889666248495967,
        39.71456477615856,
        -0.36889666248495967,
        39.71456477615856
      ]
    },
    "46121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.616755727132451,
              39.01724972878761
            ]
          },
          "bbox": [
            -0.616755727132451,
            39.01724972878761,
            -0.616755727132451,
            39.01724972878761
          ]
        }
      ],
      "bbox": [
        -0.616755727132451,
        39.01724972878761,
        -0.616755727132451,
        39.01724972878761
      ]
    },
    "46122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2582112488909939,
              39.727894652287816
            ]
          },
          "bbox": [
            -0.2582112488909939,
            39.727894652287816,
            -0.2582112488909939,
            39.727894652287816
          ]
        }
      ],
      "bbox": [
        -0.2582112488909939,
        39.727894652287816,
        -0.2582112488909939,
        39.727894652287816
      ]
    },
    "46123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2996639290375494,
              39.118397438509945
            ]
          },
          "bbox": [
            -0.2996639290375494,
            39.118397438509945,
            -0.2996639290375494,
            39.118397438509945
          ]
        }
      ],
      "bbox": [
        -0.2996639290375494,
        39.118397438509945,
        -0.2996639290375494,
        39.118397438509945
      ]
    },
    "46124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7853359886640326,
              38.779910104382395
            ]
          },
          "bbox": [
            -0.7853359886640326,
            38.779910104382395,
            -0.7853359886640326,
            38.779910104382395
          ]
        }
      ],
      "bbox": [
        -0.7853359886640326,
        38.779910104382395,
        -0.7853359886640326,
        38.779910104382395
      ]
    },
    "46125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3166090110683253,
              39.1773770048619
            ]
          },
          "bbox": [
            -0.3166090110683253,
            39.1773770048619,
            -0.3166090110683253,
            39.1773770048619
          ]
        }
      ],
      "bbox": [
        -0.3166090110683253,
        39.1773770048619,
        -0.3166090110683253,
        39.1773770048619
      ]
    },
    "46126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.36022804843891626,
              39.545183743724465
            ]
          },
          "bbox": [
            -0.36022804843891626,
            39.545183743724465,
            -0.36022804843891626,
            39.545183743724465
          ]
        }
      ],
      "bbox": [
        -0.36022804843891626,
        39.545183743724465,
        -0.36022804843891626,
        39.545183743724465
      ]
    },
    "46128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8924641262896064,
              38.82594647269254
            ]
          },
          "bbox": [
            -0.8924641262896064,
            38.82594647269254,
            -0.8924641262896064,
            38.82594647269254
          ]
        }
      ],
      "bbox": [
        -0.8924641262896064,
        38.82594647269254,
        -0.8924641262896064,
        38.82594647269254
      ]
    },
    "46129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3680687910432625,
              39.57828408733556
            ]
          },
          "bbox": [
            -1.3680687910432625,
            39.57828408733556,
            -1.3680687910432625,
            39.57828408733556
          ]
        }
      ],
      "bbox": [
        -1.3680687910432625,
        39.57828408733556,
        -1.3680687910432625,
        39.57828408733556
      ]
    },
    "46130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5602097087868488,
              39.09309758696638
            ]
          },
          "bbox": [
            -0.5602097087868488,
            39.09309758696638,
            -0.5602097087868488,
            39.09309758696638
          ]
        }
      ],
      "bbox": [
        -0.5602097087868488,
        39.09309758696638,
        -0.5602097087868488,
        39.09309758696638
      ]
    },
    "46131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.21558187890363764,
              38.98414766493959
            ]
          },
          "bbox": [
            -0.21558187890363764,
            38.98414766493959,
            -0.21558187890363764,
            38.98414766493959
          ]
        }
      ],
      "bbox": [
        -0.21558187890363764,
        38.98414766493959,
        -0.21558187890363764,
        38.98414766493959
      ]
    },
    "46132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.46500671037025426,
              38.98597151566318
            ]
          },
          "bbox": [
            -0.46500671037025426,
            38.98597151566318,
            -0.46500671037025426,
            38.98597151566318
          ]
        }
      ],
      "bbox": [
        -0.46500671037025426,
        38.98597151566318,
        -0.46500671037025426,
        38.98597151566318
      ]
    },
    "46133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8423757658185304,
              39.58164049145851
            ]
          },
          "bbox": [
            -0.8423757658185304,
            39.58164049145851,
            -0.8423757658185304,
            39.58164049145851
          ]
        }
      ],
      "bbox": [
        -0.8423757658185304,
        39.58164049145851,
        -0.8423757658185304,
        39.58164049145851
      ]
    },
    "46134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3364882894758083,
              39.67001577284916
            ]
          },
          "bbox": [
            -0.3364882894758083,
            39.67001577284916,
            -0.3364882894758083,
            39.67001577284916
          ]
        }
      ],
      "bbox": [
        -0.3364882894758083,
        39.67001577284916,
        -0.3364882894758083,
        39.67001577284916
      ]
    },
    "46135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.42667831682595103,
              39.53574619450442
            ]
          },
          "bbox": [
            -0.42667831682595103,
            39.53574619450442,
            -0.42667831682595103,
            39.53574619450442
          ]
        }
      ],
      "bbox": [
        -0.42667831682595103,
        39.53574619450442,
        -0.42667831682595103,
        39.53574619450442
      ]
    },
    "46136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6662804119086495,
              39.42611523622518
            ]
          },
          "bbox": [
            -0.6662804119086495,
            39.42611523622518,
            -0.6662804119086495,
            39.42611523622518
          ]
        }
      ],
      "bbox": [
        -0.6662804119086495,
        39.42611523622518,
        -0.6662804119086495,
        39.42611523622518
      ]
    },
    "46137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5587286830765649,
              38.99100655417385
            ]
          },
          "bbox": [
            -0.5587286830765649,
            38.99100655417385,
            -0.5587286830765649,
            38.99100655417385
          ]
        }
      ],
      "bbox": [
        -0.5587286830765649,
        38.99100655417385,
        -0.5587286830765649,
        38.99100655417385
      ]
    },
    "46138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.49400589227889863,
              38.92708554087008
            ]
          },
          "bbox": [
            -0.49400589227889863,
            38.92708554087008,
            -0.49400589227889863,
            38.92708554087008
          ]
        }
      ],
      "bbox": [
        -0.49400589227889863,
        38.92708554087008,
        -0.49400589227889863,
        38.92708554087008
      ]
    },
    "46139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5305150787909136,
              39.18201833044701
            ]
          },
          "bbox": [
            -0.5305150787909136,
            39.18201833044701,
            -0.5305150787909136,
            39.18201833044701
          ]
        }
      ],
      "bbox": [
        -0.5305150787909136,
        39.18201833044701,
        -0.5305150787909136,
        39.18201833044701
      ]
    },
    "46140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.14625903345069557,
              38.96308137521594
            ]
          },
          "bbox": [
            -0.14625903345069557,
            38.96308137521594,
            -0.14625903345069557,
            38.96308137521594
          ]
        }
      ],
      "bbox": [
        -0.14625903345069557,
        38.96308137521594,
        -0.14625903345069557,
        38.96308137521594
      ]
    },
    "46141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8632540556744605,
              39.77257877756257
            ]
          },
          "bbox": [
            -0.8632540556744605,
            39.77257877756257,
            -0.8632540556744605,
            39.77257877756257
          ]
        }
      ],
      "bbox": [
        -0.8632540556744605,
        39.77257877756257,
        -0.8632540556744605,
        39.77257877756257
      ]
    },
    "46142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0988594130548668,
              39.1962783070959
            ]
          },
          "bbox": [
            -1.0988594130548668,
            39.1962783070959,
            -1.0988594130548668,
            39.1962783070959
          ]
        }
      ],
      "bbox": [
        -1.0988594130548668,
        39.1962783070959,
        -1.0988594130548668,
        39.1962783070959
      ]
    },
    "46143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2284282163320361,
              39.03686439121696
            ]
          },
          "bbox": [
            -0.2284282163320361,
            39.03686439121696,
            -0.2284282163320361,
            39.03686439121696
          ]
        }
      ],
      "bbox": [
        -0.2284282163320361,
        39.03686439121696,
        -0.2284282163320361,
        39.03686439121696
      ]
    },
    "46144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1098540660950555,
              39.14894869421069
            ]
          },
          "bbox": [
            -1.1098540660950555,
            39.14894869421069,
            -1.1098540660950555,
            39.14894869421069
          ]
        }
      ],
      "bbox": [
        -1.1098540660950555,
        39.14894869421069,
        -1.1098540660950555,
        39.14894869421069
      ]
    },
    "46145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5118405287192332,
              39.00273151752513
            ]
          },
          "bbox": [
            -0.5118405287192332,
            39.00273151752513,
            -0.5118405287192332,
            39.00273151752513
          ]
        }
      ],
      "bbox": [
        -0.5118405287192332,
        39.00273151752513,
        -0.5118405287192332,
        39.00273151752513
      ]
    },
    "46146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.22536073945518587,
              39.01321963188319
            ]
          },
          "bbox": [
            -0.22536073945518587,
            39.01321963188319,
            -0.22536073945518587,
            39.01321963188319
          ]
        }
      ],
      "bbox": [
        -0.22536073945518587,
        39.01321963188319,
        -0.22536073945518587,
        39.01321963188319
      ]
    },
    "46147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6590205907370144,
              39.69049145415829
            ]
          },
          "bbox": [
            -0.6590205907370144,
            39.69049145415829,
            -0.6590205907370144,
            39.69049145415829
          ]
        }
      ],
      "bbox": [
        -0.6590205907370144,
        39.69049145415829,
        -0.6590205907370144,
        39.69049145415829
      ]
    },
    "46148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9494575133530465,
              39.63208638305755
            ]
          },
          "bbox": [
            -0.9494575133530465,
            39.63208638305755,
            -0.9494575133530465,
            39.63208638305755
          ]
        }
      ],
      "bbox": [
        -0.9494575133530465,
        39.63208638305755,
        -0.9494575133530465,
        39.63208638305755
      ]
    },
    "46149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8646298208978137,
              39.69841975584132
            ]
          },
          "bbox": [
            -0.8646298208978137,
            39.69841975584132,
            -0.8646298208978137,
            39.69841975584132
          ]
        }
      ],
      "bbox": [
        -0.8646298208978137,
        39.69841975584132,
        -0.8646298208978137,
        39.69841975584132
      ]
    },
    "46150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.34154785107614677,
              38.9458939415981
            ]
          },
          "bbox": [
            -0.34154785107614677,
            38.9458939415981,
            -0.34154785107614677,
            38.9458939415981
          ]
        }
      ],
      "bbox": [
        -0.34154785107614677,
        38.9458939415981,
        -0.34154785107614677,
        38.9458939415981
      ]
    },
    "46152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.38198409475556705,
              39.42049318977509
            ]
          },
          "bbox": [
            -0.38198409475556705,
            39.42049318977509,
            -0.38198409475556705,
            39.42049318977509
          ]
        }
      ],
      "bbox": [
        -0.38198409475556705,
        39.42049318977509,
        -0.38198409475556705,
        39.42049318977509
      ]
    },
    "46153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2897746014669852,
              38.902591964490675
            ]
          },
          "bbox": [
            -0.2897746014669852,
            38.902591964490675,
            -0.2897746014669852,
            38.902591964490675
          ]
        }
      ],
      "bbox": [
        -0.2897746014669852,
        38.902591964490675,
        -0.2897746014669852,
        38.902591964490675
      ]
    },
    "46154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5927681953887333,
              38.9998703005583
            ]
          },
          "bbox": [
            -0.5927681953887333,
            38.9998703005583,
            -0.5927681953887333,
            38.9998703005583
          ]
        }
      ],
      "bbox": [
        -0.5927681953887333,
        38.9998703005583,
        -0.5927681953887333,
        38.9998703005583
      ]
    },
    "46155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3235106276183511,
              39.1380552007514
            ]
          },
          "bbox": [
            -0.3235106276183511,
            39.1380552007514,
            -0.3235106276183511,
            39.1380552007514
          ]
        }
      ],
      "bbox": [
        -0.3235106276183511,
        39.1380552007514,
        -0.3235106276183511,
        39.1380552007514
      ]
    },
    "46156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5923990098733428,
              39.295263778108335
            ]
          },
          "bbox": [
            -0.5923990098733428,
            39.295263778108335,
            -0.5923990098733428,
            39.295263778108335
          ]
        }
      ],
      "bbox": [
        -0.5923990098733428,
        39.295263778108335,
        -0.5923990098733428,
        39.295263778108335
      ]
    },
    "46157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.537323844650784,
              39.0280492596174
            ]
          },
          "bbox": [
            -0.537323844650784,
            39.0280492596174,
            -0.537323844650784,
            39.0280492596174
          ]
        }
      ],
      "bbox": [
        -0.537323844650784,
        39.0280492596174,
        -0.537323844650784,
        39.0280492596174
      ]
    },
    "46158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7961904516931808,
              39.347974361031625
            ]
          },
          "bbox": [
            -0.7961904516931808,
            39.347974361031625,
            -0.7961904516931808,
            39.347974361031625
          ]
        }
      ],
      "bbox": [
        -0.7961904516931808,
        39.347974361031625,
        -0.7961904516931808,
        39.347974361031625
      ]
    },
    "46159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.49548399649862457,
              39.49801783240828
            ]
          },
          "bbox": [
            -0.49548399649862457,
            39.49801783240828,
            -0.49548399649862457,
            39.49801783240828
          ]
        }
      ],
      "bbox": [
        -0.49548399649862457,
        39.49801783240828,
        -0.49548399649862457,
        39.49801783240828
      ]
    },
    "46160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4919291391725624,
              39.0545443305137
            ]
          },
          "bbox": [
            -0.4919291391725624,
            39.0545443305137,
            -0.4919291391725624,
            39.0545443305137
          ]
        }
      ],
      "bbox": [
        -0.4919291391725624,
        39.0545443305137,
        -0.4919291391725624,
        39.0545443305137
      ]
    },
    "46161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5495102633349144,
              39.723895709061125
            ]
          },
          "bbox": [
            -0.5495102633349144,
            39.723895709061125,
            -0.5495102633349144,
            39.723895709061125
          ]
        }
      ],
      "bbox": [
        -0.5495102633349144,
        39.723895709061125,
        -0.5495102633349144,
        39.723895709061125
      ]
    },
    "46162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.517970947117936,
              39.15376507514773
            ]
          },
          "bbox": [
            -0.517970947117936,
            39.15376507514773,
            -0.517970947117936,
            39.15376507514773
          ]
        }
      ],
      "bbox": [
        -0.517970947117936,
        39.15376507514773,
        -0.517970947117936,
        39.15376507514773
      ]
    },
    "46163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3155918766387468,
              39.554554274586856
            ]
          },
          "bbox": [
            -0.3155918766387468,
            39.554554274586856,
            -0.3155918766387468,
            39.554554274586856
          ]
        }
      ],
      "bbox": [
        -0.3155918766387468,
        39.554554274586856,
        -0.3155918766387468,
        39.554554274586856
      ]
    },
    "46164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3309174867859259,
              39.574035523961754
            ]
          },
          "bbox": [
            -0.3309174867859259,
            39.574035523961754,
            -0.3309174867859259,
            39.574035523961754
          ]
        }
      ],
      "bbox": [
        -0.3309174867859259,
        39.574035523961754,
        -0.3309174867859259,
        39.574035523961754
      ]
    },
    "46165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.37306573027132633,
              39.39753668615602
            ]
          },
          "bbox": [
            -0.37306573027132633,
            39.39753668615602,
            -0.37306573027132633,
            39.39753668615602
          ]
        }
      ],
      "bbox": [
        -0.37306573027132633,
        39.39753668615602,
        -0.37306573027132633,
        39.39753668615602
      ]
    },
    "46166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3347933744958355,
              39.52491601446046
            ]
          },
          "bbox": [
            -0.3347933744958355,
            39.52491601446046,
            -0.3347933744958355,
            39.52491601446046
          ]
        }
      ],
      "bbox": [
        -0.3347933744958355,
        39.52491601446046,
        -0.3347933744958355,
        39.52491601446046
      ]
    },
    "46167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7931600637522424,
              39.211107916493035
            ]
          },
          "bbox": [
            -0.7931600637522424,
            39.211107916493035,
            -0.7931600637522424,
            39.211107916493035
          ]
        }
      ],
      "bbox": [
        -0.7931600637522424,
        39.211107916493035,
        -0.7931600637522424,
        39.211107916493035
      ]
    },
    "46168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.13559852577264347,
              38.9549953444278
            ]
          },
          "bbox": [
            -0.13559852577264347,
            38.9549953444278,
            -0.13559852577264347,
            38.9549953444278
          ]
        }
      ],
      "bbox": [
        -0.13559852577264347,
        38.9549953444278,
        -0.13559852577264347,
        38.9549953444278
      ]
    },
    "46169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.42119902319344366,
              39.476136466898495
            ]
          },
          "bbox": [
            -0.42119902319344366,
            39.476136466898495,
            -0.42119902319344366,
            39.476136466898495
          ]
        }
      ],
      "bbox": [
        -0.42119902319344366,
        39.476136466898495,
        -0.42119902319344366,
        39.476136466898495
      ]
    },
    "46170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7976511122392963,
              38.86447875523483
            ]
          },
          "bbox": [
            -0.7976511122392963,
            38.86447875523483,
            -0.7976511122392963,
            38.86447875523483
          ]
        }
      ],
      "bbox": [
        -0.7976511122392963,
        38.86447875523483,
        -0.7976511122392963,
        38.86447875523483
      ]
    },
    "46171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3964853053286469,
              39.56277460066161
            ]
          },
          "bbox": [
            -0.3964853053286469,
            39.56277460066161,
            -0.3964853053286469,
            39.56277460066161
          ]
        }
      ],
      "bbox": [
        -0.3964853053286469,
        39.56277460066161,
        -0.3964853053286469,
        39.56277460066161
      ]
    },
    "46172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5842486887191157,
              39.363207370177726
            ]
          },
          "bbox": [
            -0.5842486887191157,
            39.363207370177726,
            -0.5842486887191157,
            39.363207370177726
          ]
        }
      ],
      "bbox": [
        -0.5842486887191157,
        39.363207370177726,
        -0.5842486887191157,
        39.363207370177726
      ]
    },
    "46173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.49082314288503326,
              38.88568465488409
            ]
          },
          "bbox": [
            -0.49082314288503326,
            38.88568465488409,
            -0.49082314288503326,
            38.88568465488409
          ]
        }
      ],
      "bbox": [
        -0.49082314288503326,
        38.88568465488409,
        -0.49082314288503326,
        38.88568465488409
      ]
    },
    "46174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6412952353799732,
              38.94127783226948
            ]
          },
          "bbox": [
            -0.6412952353799732,
            38.94127783226948,
            -0.6412952353799732,
            38.94127783226948
          ]
        }
      ],
      "bbox": [
        -0.6412952353799732,
        38.94127783226948,
        -0.6412952353799732,
        38.94127783226948
      ]
    },
    "46175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3355941117728004,
              38.89260191548258
            ]
          },
          "bbox": [
            -0.3355941117728004,
            38.89260191548258,
            -0.3355941117728004,
            38.89260191548258
          ]
        }
      ],
      "bbox": [
        -0.3355941117728004,
        38.89260191548258,
        -0.3355941117728004,
        38.89260191548258
      ]
    },
    "46176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6523465230498197,
              39.33245261642122
            ]
          },
          "bbox": [
            -0.6523465230498197,
            39.33245261642122,
            -0.6523465230498197,
            39.33245261642122
          ]
        }
      ],
      "bbox": [
        -0.6523465230498197,
        39.33245261642122,
        -0.6523465230498197,
        39.33245261642122
      ]
    },
    "46177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3593995535195885,
              39.5744792308233
            ]
          },
          "bbox": [
            -0.3593995535195885,
            39.5744792308233,
            -0.3593995535195885,
            39.5744792308233
          ]
        }
      ],
      "bbox": [
        -0.3593995535195885,
        39.5744792308233,
        -0.3593995535195885,
        39.5744792308233
      ]
    },
    "46178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.41380284548497254,
              39.63717053384098
            ]
          },
          "bbox": [
            -0.41380284548497254,
            39.63717053384098,
            -0.41380284548497254,
            39.63717053384098
          ]
        }
      ],
      "bbox": [
        -0.41380284548497254,
        39.63717053384098,
        -0.41380284548497254,
        39.63717053384098
      ]
    },
    "46179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7163397138378194,
              39.0924072611633
            ]
          },
          "bbox": [
            -0.7163397138378194,
            39.0924072611633,
            -0.7163397138378194,
            39.0924072611633
          ]
        }
      ],
      "bbox": [
        -0.7163397138378194,
        39.0924072611633,
        -0.7163397138378194,
        39.0924072611633
      ]
    },
    "46180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.550598927041755,
              38.97835383524699
            ]
          },
          "bbox": [
            -0.550598927041755,
            38.97835383524699,
            -0.550598927041755,
            38.97835383524699
          ]
        }
      ],
      "bbox": [
        -0.550598927041755,
        38.97835383524699,
        -0.550598927041755,
        38.97835383524699
      ]
    },
    "46181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.10396665373473915,
              38.89692504323298
            ]
          },
          "bbox": [
            -0.10396665373473915,
            38.89692504323298,
            -0.10396665373473915,
            38.89692504323298
          ]
        }
      ],
      "bbox": [
        -0.10396665373473915,
        38.89692504323298,
        -0.10396665373473915,
        38.89692504323298
      ]
    },
    "46182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.527608937475488,
              39.68817380302817
            ]
          },
          "bbox": [
            -0.527608937475488,
            39.68817380302817,
            -0.527608937475488,
            39.68817380302817
          ]
        }
      ],
      "bbox": [
        -0.527608937475488,
        39.68817380302817,
        -0.527608937475488,
        39.68817380302817
      ]
    },
    "46184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6551326079100646,
              38.81270862988204
            ]
          },
          "bbox": [
            -0.6551326079100646,
            38.81270862988204,
            -0.6551326079100646,
            38.81270862988204
          ]
        }
      ],
      "bbox": [
        -0.6551326079100646,
        38.81270862988204,
        -0.6551326079100646,
        38.81270862988204
      ]
    },
    "46185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.44785146347126453,
              38.869107019892766
            ]
          },
          "bbox": [
            -0.44785146347126453,
            38.869107019892766,
            -0.44785146347126453,
            38.869107019892766
          ]
        }
      ],
      "bbox": [
        -0.44785146347126453,
        38.869107019892766,
        -0.44785146347126453,
        38.869107019892766
      ]
    },
    "46186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4122992374570323,
              39.425842943360806
            ]
          },
          "bbox": [
            -0.4122992374570323,
            39.425842943360806,
            -0.4122992374570323,
            39.425842943360806
          ]
        }
      ],
      "bbox": [
        -0.4122992374570323,
        39.425842943360806,
        -0.4122992374570323,
        39.425842943360806
      ]
    },
    "46187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.23778230181466184,
              38.945595115785544
            ]
          },
          "bbox": [
            -0.23778230181466184,
            38.945595115785544,
            -0.23778230181466184,
            38.945595115785544
          ]
        }
      ],
      "bbox": [
        -0.23778230181466184,
        38.945595115785544,
        -0.23778230181466184,
        38.945595115785544
      ]
    },
    "46188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.15151517913245915,
              38.9425016856543
            ]
          },
          "bbox": [
            -0.15151517913245915,
            38.9425016856543,
            -0.15151517913245915,
            38.9425016856543
          ]
        }
      ],
      "bbox": [
        -0.15151517913245915,
        38.9425016856543,
        -0.15151517913245915,
        38.9425016856543
      ]
    },
    "46189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4907333199346527,
              38.853299096565244
            ]
          },
          "bbox": [
            -0.4907333199346527,
            38.853299096565244,
            -0.4907333199346527,
            38.853299096565244
          ]
        }
      ],
      "bbox": [
        -0.4907333199346527,
        38.853299096565244,
        -0.4907333199346527,
        38.853299096565244
      ]
    },
    "46190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.46751529476141107,
              39.523920819767966
            ]
          },
          "bbox": [
            -0.46751529476141107,
            39.523920819767966,
            -0.46751529476141107,
            39.523920819767966
          ]
        }
      ],
      "bbox": [
        -0.46751529476141107,
        39.523920819767966,
        -0.46751529476141107,
        39.523920819767966
      ]
    },
    "46191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7273313423055088,
              39.596245618935924
            ]
          },
          "bbox": [
            -0.7273313423055088,
            39.596245618935924,
            -0.7273313423055088,
            39.596245618935924
          ]
        }
      ],
      "bbox": [
        -0.7273313423055088,
        39.596245618935924,
        -0.7273313423055088,
        39.596245618935924
      ]
    },
    "46192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3114720673262437,
              39.684052864103045
            ]
          },
          "bbox": [
            -0.3114720673262437,
            39.684052864103045,
            -0.3114720673262437,
            39.684052864103045
          ]
        }
      ],
      "bbox": [
        -0.3114720673262437,
        39.684052864103045,
        -0.3114720673262437,
        39.684052864103045
      ]
    },
    "46193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.43603330018478903,
              39.43366102806822
            ]
          },
          "bbox": [
            -0.43603330018478903,
            39.43366102806822,
            -0.43603330018478903,
            39.43366102806822
          ]
        }
      ],
      "bbox": [
        -0.43603330018478903,
        39.43366102806822,
        -0.43603330018478903,
        39.43366102806822
      ]
    },
    "46194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4861583291152906,
              39.34733324305222
            ]
          },
          "bbox": [
            -0.4861583291152906,
            39.34733324305222,
            -0.4861583291152906,
            39.34733324305222
          ]
        }
      ],
      "bbox": [
        -0.4861583291152906,
        39.34733324305222,
        -0.4861583291152906,
        39.34733324305222
      ]
    },
    "46195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12824316230674035,
              38.944551546504265
            ]
          },
          "bbox": [
            -0.12824316230674035,
            38.944551546504265,
            -0.12824316230674035,
            38.944551546504265
          ]
        }
      ],
      "bbox": [
        -0.12824316230674035,
        38.944551546504265,
        -0.12824316230674035,
        38.944551546504265
      ]
    },
    "46196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3267815430237465,
              38.984167973895254
            ]
          },
          "bbox": [
            -0.3267815430237465,
            38.984167973895254,
            -0.3267815430237465,
            38.984167973895254
          ]
        }
      ],
      "bbox": [
        -0.3267815430237465,
        38.984167973895254,
        -0.3267815430237465,
        38.984167973895254
      ]
    },
    "46197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.37241971249661765,
              39.19059992522078
            ]
          },
          "bbox": [
            -0.37241971249661765,
            39.19059992522078,
            -0.37241971249661765,
            39.19059992522078
          ]
        }
      ],
      "bbox": [
        -0.37241971249661765,
        39.19059992522078,
        -0.37241971249661765,
        39.19059992522078
      ]
    },
    "46198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1903398128408793,
              38.91454851963388
            ]
          },
          "bbox": [
            -0.1903398128408793,
            38.91454851963388,
            -0.1903398128408793,
            38.91454851963388
          ]
        }
      ],
      "bbox": [
        -0.1903398128408793,
        38.91454851963388,
        -0.1903398128408793,
        38.91454851963388
      ]
    },
    "46199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3099360711002769,
              39.57268228414768
            ]
          },
          "bbox": [
            -0.3099360711002769,
            39.57268228414768,
            -0.3099360711002769,
            39.57268228414768
          ]
        }
      ],
      "bbox": [
        -0.3099360711002769,
        39.57268228414768,
        -0.3099360711002769,
        39.57268228414768
      ]
    },
    "46200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.42385675247721216,
              38.90513049284712
            ]
          },
          "bbox": [
            -0.42385675247721216,
            38.90513049284712,
            -0.42385675247721216,
            38.90513049284712
          ]
        }
      ],
      "bbox": [
        -0.42385675247721216,
        38.90513049284712,
        -0.42385675247721216,
        38.90513049284712
      ]
    },
    "46201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1322981980737648,
              40.05592846930778
            ]
          },
          "bbox": [
            -1.1322981980737648,
            40.05592846930778,
            -1.1322981980737648,
            40.05592846930778
          ]
        }
      ],
      "bbox": [
        -1.1322981980737648,
        40.05592846930778,
        -1.1322981980737648,
        40.05592846930778
      ]
    },
    "46202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.542462754867619,
              39.60205482358644
            ]
          },
          "bbox": [
            -0.542462754867619,
            39.60205482358644,
            -0.542462754867619,
            39.60205482358644
          ]
        }
      ],
      "bbox": [
        -0.542462754867619,
        39.60205482358644,
        -0.542462754867619,
        39.60205482358644
      ]
    },
    "46203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4722914452629948,
              39.08167515679165
            ]
          },
          "bbox": [
            -0.4722914452629948,
            39.08167515679165,
            -0.4722914452629948,
            39.08167515679165
          ]
        }
      ],
      "bbox": [
        -0.4722914452629948,
        39.08167515679165,
        -0.4722914452629948,
        39.08167515679165
      ]
    },
    "46204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.32039560744904727,
              39.59883691631733
            ]
          },
          "bbox": [
            -0.32039560744904727,
            39.59883691631733,
            -0.32039560744904727,
            39.59883691631733
          ]
        }
      ],
      "bbox": [
        -0.32039560744904727,
        39.59883691631733,
        -0.32039560744904727,
        39.59883691631733
      ]
    },
    "46205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.30549323506622095,
              39.61791356185092
            ]
          },
          "bbox": [
            -0.30549323506622095,
            39.61791356185092,
            -0.30549323506622095,
            39.61791356185092
          ]
        }
      ],
      "bbox": [
        -0.30549323506622095,
        39.61791356185092,
        -0.30549323506622095,
        39.61791356185092
      ]
    },
    "46206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7782091524638759,
              39.10467661087628
            ]
          },
          "bbox": [
            -0.7782091524638759,
            39.10467661087628,
            -0.7782091524638759,
            39.10467661087628
          ]
        }
      ],
      "bbox": [
        -0.7782091524638759,
        39.10467661087628,
        -0.7782091524638759,
        39.10467661087628
      ]
    },
    "46207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3480739016379993,
              39.59520207442565
            ]
          },
          "bbox": [
            -0.3480739016379993,
            39.59520207442565,
            -0.3480739016379993,
            39.59520207442565
          ]
        }
      ],
      "bbox": [
        -0.3480739016379993,
        39.59520207442565,
        -0.3480739016379993,
        39.59520207442565
      ]
    },
    "46208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1691483646369061,
              38.93410346569421
            ]
          },
          "bbox": [
            -0.1691483646369061,
            38.93410346569421,
            -0.1691483646369061,
            38.93410346569421
          ]
        }
      ],
      "bbox": [
        -0.1691483646369061,
        38.93410346569421,
        -0.1691483646369061,
        38.93410346569421
      ]
    },
    "46209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4385511951454642,
              39.049713532354176
            ]
          },
          "bbox": [
            -0.4385511951454642,
            39.049713532354176,
            -0.4385511951454642,
            39.049713532354176
          ]
        }
      ],
      "bbox": [
        -0.4385511951454642,
        39.049713532354176,
        -0.4385511951454642,
        39.049713532354176
      ]
    },
    "46210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4086713139980243,
              38.86467731188334
            ]
          },
          "bbox": [
            -0.4086713139980243,
            38.86467731188334,
            -0.4086713139980243,
            38.86467731188334
          ]
        }
      ],
      "bbox": [
        -0.4086713139980243,
        38.86467731188334,
        -0.4086713139980243,
        38.86467731188334
      ]
    },
    "46211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.20365019111286253,
              38.947984389486734
            ]
          },
          "bbox": [
            -0.20365019111286253,
            38.947984389486734,
            -0.20365019111286253,
            38.947984389486734
          ]
        }
      ],
      "bbox": [
        -0.20365019111286253,
        38.947984389486734,
        -0.20365019111286253,
        38.947984389486734
      ]
    },
    "46212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6221622316527456,
              39.313529487723294
            ]
          },
          "bbox": [
            -0.6221622316527456,
            39.313529487723294,
            -0.6221622316527456,
            39.313529487723294
          ]
        }
      ],
      "bbox": [
        -0.6221622316527456,
        39.313529487723294,
        -0.6221622316527456,
        39.313529487723294
      ]
    },
    "46213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.136049889200283,
              39.436708568009855
            ]
          },
          "bbox": [
            -1.136049889200283,
            39.436708568009855,
            -1.136049889200283,
            39.436708568009855
          ]
        }
      ],
      "bbox": [
        -1.136049889200283,
        39.436708568009855,
        -1.136049889200283,
        39.436708568009855
      ]
    },
    "46214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5603208590139044,
              39.5202198520707
            ]
          },
          "bbox": [
            -0.5603208590139044,
            39.5202198520707,
            -0.5603208590139044,
            39.5202198520707
          ]
        }
      ],
      "bbox": [
        -0.5603208590139044,
        39.5202198520707,
        -0.5603208590139044,
        39.5202198520707
      ]
    },
    "46215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.33759553886297006,
              39.18963883764577
            ]
          },
          "bbox": [
            -0.33759553886297006,
            39.18963883764577,
            -0.33759553886297006,
            39.18963883764577
          ]
        }
      ],
      "bbox": [
        -0.33759553886297006,
        39.18963883764577,
        -0.33759553886297006,
        39.18963883764577
      ]
    },
    "46216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4136109606817289,
              39.53646158643497
            ]
          },
          "bbox": [
            -0.4136109606817289,
            39.53646158643497,
            -0.4136109606817289,
            39.53646158643497
          ]
        }
      ],
      "bbox": [
        -0.4136109606817289,
        39.53646158643497,
        -0.4136109606817289,
        39.53646158643497
      ]
    },
    "46217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5632597051090809,
              39.01142689597842
            ]
          },
          "bbox": [
            -0.5632597051090809,
            39.01142689597842,
            -0.5632597051090809,
            39.01142689597842
          ]
        }
      ],
      "bbox": [
        -0.5632597051090809,
        39.01142689597842,
        -0.5632597051090809,
        39.01142689597842
      ]
    },
    "46218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.26862707893627025,
              38.939404887952215
            ]
          },
          "bbox": [
            -0.26862707893627025,
            38.939404887952215,
            -0.26862707893627025,
            38.939404887952215
          ]
        }
      ],
      "bbox": [
        -0.26862707893627025,
        38.939404887952215,
        -0.26862707893627025,
        38.939404887952215
      ]
    },
    "46219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.36134710365266565,
              38.881324749470444
            ]
          },
          "bbox": [
            -0.36134710365266565,
            38.881324749470444,
            -0.36134710365266565,
            38.881324749470444
          ]
        }
      ],
      "bbox": [
        -0.36134710365266565,
        38.881324749470444,
        -0.36134710365266565,
        38.881324749470444
      ]
    },
    "46220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.27812184470297213,
              39.69009710418631
            ]
          },
          "bbox": [
            -0.27812184470297213,
            39.69009710418631,
            -0.27812184470297213,
            39.69009710418631
          ]
        }
      ],
      "bbox": [
        -0.27812184470297213,
        39.69009710418631,
        -0.27812184470297213,
        39.69009710418631
      ]
    },
    "46221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.382423094661362,
              38.849025410632585
            ]
          },
          "bbox": [
            -0.382423094661362,
            38.849025410632585,
            -0.382423094661362,
            38.849025410632585
          ]
        }
      ],
      "bbox": [
        -0.382423094661362,
        38.849025410632585,
        -0.382423094661362,
        38.849025410632585
      ]
    },
    "46222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4888355780017851,
              39.07205843796413
            ]
          },
          "bbox": [
            -0.4888355780017851,
            39.07205843796413,
            -0.4888355780017851,
            39.07205843796413
          ]
        }
      ],
      "bbox": [
        -0.4888355780017851,
        39.07205843796413,
        -0.4888355780017851,
        39.07205843796413
      ]
    },
    "46223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3758162709209898,
              39.423754997277904
            ]
          },
          "bbox": [
            -0.3758162709209898,
            39.423754997277904,
            -0.3758162709209898,
            39.423754997277904
          ]
        }
      ],
      "bbox": [
        -0.3758162709209898,
        39.423754997277904,
        -0.3758162709209898,
        39.423754997277904
      ]
    },
    "46224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3818800396525082,
              39.67832288312718
            ]
          },
          "bbox": [
            -0.3818800396525082,
            39.67832288312718,
            -0.3818800396525082,
            39.67832288312718
          ]
        }
      ],
      "bbox": [
        -0.3818800396525082,
        39.67832288312718,
        -0.3818800396525082,
        39.67832288312718
      ]
    },
    "46225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5922687479318798,
              39.0333069913509
            ]
          },
          "bbox": [
            -0.5922687479318798,
            39.0333069913509,
            -0.5922687479318798,
            39.0333069913509
          ]
        }
      ],
      "bbox": [
        -0.5922687479318798,
        39.0333069913509,
        -0.5922687479318798,
        39.0333069913509
      ]
    },
    "46226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.47159243190575095,
              38.918573761526225
            ]
          },
          "bbox": [
            -0.47159243190575095,
            38.918573761526225,
            -0.47159243190575095,
            38.918573761526225
          ]
        }
      ],
      "bbox": [
        -0.47159243190575095,
        38.918573761526225,
        -0.47159243190575095,
        38.918573761526225
      ]
    },
    "46227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5060800730054377,
              39.064666265936765
            ]
          },
          "bbox": [
            -0.5060800730054377,
            39.064666265936765,
            -0.5060800730054377,
            39.064666265936765
          ]
        }
      ],
      "bbox": [
        -0.5060800730054377,
        39.064666265936765,
        -0.5060800730054377,
        39.064666265936765
      ]
    },
    "46228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4556318577285451,
              39.68454289449605
            ]
          },
          "bbox": [
            -0.4556318577285451,
            39.68454289449605,
            -0.4556318577285451,
            39.68454289449605
          ]
        }
      ],
      "bbox": [
        -0.4556318577285451,
        39.68454289449605,
        -0.4556318577285451,
        39.68454289449605
      ]
    },
    "46229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9246742061949046,
              39.484411170403654
            ]
          },
          "bbox": [
            -0.9246742061949046,
            39.484411170403654,
            -0.9246742061949046,
            39.484411170403654
          ]
        }
      ],
      "bbox": [
        -0.9246742061949046,
        39.484411170403654,
        -0.9246742061949046,
        39.484411170403654
      ]
    },
    "46230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4028181284445442,
              39.35645563399472
            ]
          },
          "bbox": [
            -0.4028181284445442,
            39.35645563399472,
            -0.4028181284445442,
            39.35645563399472
          ]
        }
      ],
      "bbox": [
        -0.4028181284445442,
        39.35645563399472,
        -0.4028181284445442,
        39.35645563399472
      ]
    },
    "46231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3400840854125754,
              39.04191817487945
            ]
          },
          "bbox": [
            -0.3400840854125754,
            39.04191817487945,
            -0.3400840854125754,
            39.04191817487945
          ]
        }
      ],
      "bbox": [
        -0.3400840854125754,
        39.04191817487945,
        -0.3400840854125754,
        39.04191817487945
      ]
    },
    "46232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2102056752058379,
              39.74566220300669
            ]
          },
          "bbox": [
            -1.2102056752058379,
            39.74566220300669,
            -1.2102056752058379,
            39.74566220300669
          ]
        }
      ],
      "bbox": [
        -1.2102056752058379,
        39.74566220300669,
        -1.2102056752058379,
        39.74566220300669
      ]
    },
    "46233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3771234150681606,
              39.28143624492197
            ]
          },
          "bbox": [
            -0.3771234150681606,
            39.28143624492197,
            -0.3771234150681606,
            39.28143624492197
          ]
        }
      ],
      "bbox": [
        -0.3771234150681606,
        39.28143624492197,
        -0.3771234150681606,
        39.28143624492197
      ]
    },
    "46234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9137181755298195,
              39.60991634786551
            ]
          },
          "bbox": [
            -0.9137181755298195,
            39.60991634786551,
            -0.9137181755298195,
            39.60991634786551
          ]
        }
      ],
      "bbox": [
        -0.9137181755298195,
        39.60991634786551,
        -0.9137181755298195,
        39.60991634786551
      ]
    },
    "46235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.30830636624630003,
              39.240286199317225
            ]
          },
          "bbox": [
            -0.30830636624630003,
            39.240286199317225,
            -0.30830636624630003,
            39.240286199317225
          ]
        }
      ],
      "bbox": [
        -0.30830636624630003,
        39.240286199317225,
        -0.30830636624630003,
        39.240286199317225
      ]
    },
    "46236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.633142206277139,
              39.08614471481031
            ]
          },
          "bbox": [
            -0.633142206277139,
            39.08614471481031,
            -0.633142206277139,
            39.08614471481031
          ]
        }
      ],
      "bbox": [
        -0.633142206277139,
        39.08614471481031,
        -0.633142206277139,
        39.08614471481031
      ]
    },
    "46237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3643331896271483,
              39.50734268623839
            ]
          },
          "bbox": [
            -0.3643331896271483,
            39.50734268623839,
            -0.3643331896271483,
            39.50734268623839
          ]
        }
      ],
      "bbox": [
        -0.3643331896271483,
        39.50734268623839,
        -0.3643331896271483,
        39.50734268623839
      ]
    },
    "46238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2545783948433094,
              39.080400432928656
            ]
          },
          "bbox": [
            -0.2545783948433094,
            39.080400432928656,
            -0.2545783948433094,
            39.080400432928656
          ]
        }
      ],
      "bbox": [
        -0.2545783948433094,
        39.080400432928656,
        -0.2545783948433094,
        39.080400432928656
      ]
    },
    "46239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9824903418443994,
              39.11156060908985
            ]
          },
          "bbox": [
            -0.9824903418443994,
            39.11156060908985,
            -0.9824903418443994,
            39.11156060908985
          ]
        }
      ],
      "bbox": [
        -0.9824903418443994,
        39.11156060908985,
        -0.9824903418443994,
        39.11156060908985
      ]
    },
    "46240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.31329449357942635,
              38.89795750513629
            ]
          },
          "bbox": [
            -0.31329449357942635,
            38.89795750513629,
            -0.31329449357942635,
            38.89795750513629
          ]
        }
      ],
      "bbox": [
        -0.31329449357942635,
        38.89795750513629,
        -0.31329449357942635,
        38.89795750513629
      ]
    },
    "46241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.112106556163166,
              39.870792111392106
            ]
          },
          "bbox": [
            -1.112106556163166,
            39.870792111392106,
            -1.112106556163166,
            39.870792111392106
          ]
        }
      ],
      "bbox": [
        -1.112106556163166,
        39.870792111392106,
        -1.112106556163166,
        39.870792111392106
      ]
    },
    "46242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.259950228433949,
              40.101633765925754
            ]
          },
          "bbox": [
            -1.259950228433949,
            40.101633765925754,
            -1.259950228433949,
            40.101633765925754
          ]
        }
      ],
      "bbox": [
        -1.259950228433949,
        40.101633765925754,
        -1.259950228433949,
        40.101633765925754
      ]
    },
    "46243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5739428683179206,
              38.987508595503265
            ]
          },
          "bbox": [
            -0.5739428683179206,
            38.987508595503265,
            -0.5739428683179206,
            38.987508595503265
          ]
        }
      ],
      "bbox": [
        -0.5739428683179206,
        38.987508595503265,
        -0.5739428683179206,
        38.987508595503265
      ]
    },
    "46244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5223996926412021,
              39.417605095788616
            ]
          },
          "bbox": [
            -0.5223996926412021,
            39.417605095788616,
            -0.5223996926412021,
            39.417605095788616
          ]
        }
      ],
      "bbox": [
        -0.5223996926412021,
        39.417605095788616,
        -0.5223996926412021,
        39.417605095788616
      ]
    },
    "46245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.38353314854312376,
              39.731709239086534
            ]
          },
          "bbox": [
            -0.38353314854312376,
            39.731709239086534,
            -0.38353314854312376,
            39.731709239086534
          ]
        }
      ],
      "bbox": [
        -0.38353314854312376,
        39.731709239086534,
        -0.38353314854312376,
        39.731709239086534
      ]
    },
    "46246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6613822998178884,
              39.18790836359256
            ]
          },
          "bbox": [
            -0.6613822998178884,
            39.18790836359256,
            -0.6613822998178884,
            39.18790836359256
          ]
        }
      ],
      "bbox": [
        -0.6613822998178884,
        39.18790836359256,
        -0.6613822998178884,
        39.18790836359256
      ]
    },
    "46247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1134055057319177,
              39.80432219039463
            ]
          },
          "bbox": [
            -1.1134055057319177,
            39.80432219039463,
            -1.1134055057319177,
            39.80432219039463
          ]
        }
      ],
      "bbox": [
        -1.1134055057319177,
        39.80432219039463,
        -1.1134055057319177,
        39.80432219039463
      ]
    },
    "46248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6867900166829327,
              39.37355109074073
            ]
          },
          "bbox": [
            -0.6867900166829327,
            39.37355109074073,
            -0.6867900166829327,
            39.37355109074073
          ]
        }
      ],
      "bbox": [
        -0.6867900166829327,
        39.37355109074073,
        -0.6867900166829327,
        39.37355109074073
      ]
    },
    "46249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2226119244666052,
              39.61693084283334
            ]
          },
          "bbox": [
            -1.2226119244666052,
            39.61693084283334,
            -1.2226119244666052,
            39.61693084283334
          ]
        }
      ],
      "bbox": [
        -1.2226119244666052,
        39.61693084283334,
        -1.2226119244666052,
        39.61693084283334
      ]
    },
    "46250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3583121020432131,
              39.42053769489571
            ]
          },
          "bbox": [
            -0.3583121020432131,
            39.42053769489571,
            -0.3583121020432131,
            39.42053769489571
          ]
        }
      ],
      "bbox": [
        -0.3583121020432131,
        39.42053769489571,
        -0.3583121020432131,
        39.42053769489571
      ]
    },
    "46251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6887580234873132,
              38.899788004751514
            ]
          },
          "bbox": [
            -0.6887580234873132,
            38.899788004751514,
            -0.6887580234873132,
            38.899788004751514
          ]
        }
      ],
      "bbox": [
        -0.6887580234873132,
        38.899788004751514,
        -0.6887580234873132,
        38.899788004751514
      ]
    },
    "46252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3548764874087835,
              40.054539427612895
            ]
          },
          "bbox": [
            -1.3548764874087835,
            40.054539427612895,
            -1.3548764874087835,
            40.054539427612895
          ]
        }
      ],
      "bbox": [
        -1.3548764874087835,
        40.054539427612895,
        -1.3548764874087835,
        40.054539427612895
      ]
    },
    "46253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5567480690061279,
              38.98821523135266
            ]
          },
          "bbox": [
            -0.5567480690061279,
            38.98821523135266,
            -0.5567480690061279,
            38.98821523135266
          ]
        }
      ],
      "bbox": [
        -0.5567480690061279,
        38.98821523135266,
        -0.5567480690061279,
        38.98821523135266
      ]
    },
    "46254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3914762507695637,
              39.4554669699406
            ]
          },
          "bbox": [
            -1.3914762507695637,
            39.4554669699406,
            -1.3914762507695637,
            39.4554669699406
          ]
        }
      ],
      "bbox": [
        -1.3914762507695637,
        39.4554669699406,
        -1.3914762507695637,
        39.4554669699406
      ]
    },
    "46255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2270333158352834,
              38.88110136660399
            ]
          },
          "bbox": [
            -0.2270333158352834,
            38.88110136660399,
            -0.2270333158352834,
            38.88110136660399
          ]
        }
      ],
      "bbox": [
        -0.2270333158352834,
        38.88110136660399,
        -0.2270333158352834,
        38.88110136660399
      ]
    },
    "46256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.655215459290634,
              39.56022237414592
            ]
          },
          "bbox": [
            -0.655215459290634,
            39.56022237414592,
            -0.655215459290634,
            39.56022237414592
          ]
        }
      ],
      "bbox": [
        -0.655215459290634,
        39.56022237414592,
        -0.655215459290634,
        39.56022237414592
      ]
    },
    "46257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5201990512841702,
              39.073362580141975
            ]
          },
          "bbox": [
            -0.5201990512841702,
            39.073362580141975,
            -0.5201990512841702,
            39.073362580141975
          ]
        }
      ],
      "bbox": [
        -0.5201990512841702,
        39.073362580141975,
        -0.5201990512841702,
        39.073362580141975
      ]
    },
    "46258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8170274906789773,
              39.70760017043915
            ]
          },
          "bbox": [
            -0.8170274906789773,
            39.70760017043915,
            -0.8170274906789773,
            39.70760017043915
          ]
        }
      ],
      "bbox": [
        -0.8170274906789773,
        39.70760017043915,
        -0.8170274906789773,
        39.70760017043915
      ]
    },
    "46259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4516366150595919,
              39.531918805262904
            ]
          },
          "bbox": [
            -1.4516366150595919,
            39.531918805262904,
            -1.4516366150595919,
            39.531918805262904
          ]
        }
      ],
      "bbox": [
        -1.4516366150595919,
        39.531918805262904,
        -1.4516366150595919,
        39.531918805262904
      ]
    },
    "46260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.36911605064925074,
              39.53432830136478
            ]
          },
          "bbox": [
            -0.36911605064925074,
            39.53432830136478,
            -0.36911605064925074,
            39.53432830136478
          ]
        }
      ],
      "bbox": [
        -0.36911605064925074,
        39.53432830136478,
        -0.36911605064925074,
        39.53432830136478
      ]
    },
    "46261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8918996942563351,
              39.36099427345318
            ]
          },
          "bbox": [
            -0.8918996942563351,
            39.36099427345318,
            -0.8918996942563351,
            39.36099427345318
          ]
        }
      ],
      "bbox": [
        -0.8918996942563351,
        39.36099427345318,
        -0.8918996942563351,
        39.36099427345318
      ]
    },
    "46262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9448203401241586,
              39.902273213660095
            ]
          },
          "bbox": [
            -0.9448203401241586,
            39.902273213660095,
            -0.9448203401241586,
            39.902273213660095
          ]
        }
      ],
      "bbox": [
        -0.9448203401241586,
        39.902273213660095,
        -0.9448203401241586,
        39.902273213660095
      ]
    },
    "46263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1178860107908588,
              39.10024109644661
            ]
          },
          "bbox": [
            -1.1178860107908588,
            39.10024109644661,
            -1.1178860107908588,
            39.10024109644661
          ]
        }
      ],
      "bbox": [
        -1.1178860107908588,
        39.10024109644661,
        -1.1178860107908588,
        39.10024109644661
      ]
    },
    "46902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.530279438636225,
              39.76172410581305
            ]
          },
          "bbox": [
            -0.530279438636225,
            39.76172410581305,
            -0.530279438636225,
            39.76172410581305
          ]
        }
      ],
      "bbox": [
        -0.530279438636225,
        39.76172410581305,
        -0.530279438636225,
        39.76172410581305
      ]
    },
    "46903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.49596245613873696,
              39.562082914275216
            ]
          },
          "bbox": [
            -0.49596245613873696,
            39.562082914275216,
            -0.49596245613873696,
            39.562082914275216
          ]
        }
      ],
      "bbox": [
        -0.49596245613873696,
        39.562082914275216,
        -0.49596245613873696,
        39.562082914275216
      ]
    },
    "46904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.38784016212673356,
              39.18485811931945
            ]
          },
          "bbox": [
            -0.38784016212673356,
            39.18485811931945,
            -0.38784016212673356,
            39.18485811931945
          ]
        }
      ],
      "bbox": [
        -0.38784016212673356,
        39.18485811931945,
        -0.38784016212673356,
        39.18485811931945
      ]
    },
    "47001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1249654155206965,
              41.64558594408273
            ]
          },
          "bbox": [
            -5.1249654155206965,
            41.64558594408273,
            -5.1249654155206965,
            41.64558594408273
          ]
        }
      ],
      "bbox": [
        -5.1249654155206965,
        41.64558594408273,
        -5.1249654155206965,
        41.64558594408273
      ]
    },
    "47002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.63710329766751,
              41.29428082076208
            ]
          },
          "bbox": [
            -4.63710329766751,
            41.29428082076208,
            -4.63710329766751,
            41.29428082076208
          ]
        }
      ],
      "bbox": [
        -4.63710329766751,
        41.29428082076208,
        -4.63710329766751,
        41.29428082076208
      ]
    },
    "47003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.191336891178889,
              41.980013361127874
            ]
          },
          "bbox": [
            -5.191336891178889,
            41.980013361127874,
            -5.191336891178889,
            41.980013361127874
          ]
        }
      ],
      "bbox": [
        -5.191336891178889,
        41.980013361127874,
        -5.191336891178889,
        41.980013361127874
      ]
    },
    "47004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.229152737848661,
              41.29815674523142
            ]
          },
          "bbox": [
            -5.229152737848661,
            41.29815674523142,
            -5.229152737848661,
            41.29815674523142
          ]
        }
      ],
      "bbox": [
        -5.229152737848661,
        41.29815674523142,
        -5.229152737848661,
        41.29815674523142
      ]
    },
    "47005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.671477030108354,
              41.38154803779697
            ]
          },
          "bbox": [
            -4.671477030108354,
            41.38154803779697,
            -4.671477030108354,
            41.38154803779697
          ]
        }
      ],
      "bbox": [
        -4.671477030108354,
        41.38154803779697,
        -4.671477030108354,
        41.38154803779697
      ]
    },
    "47006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.61975799553148,
              41.4461688475415
            ]
          },
          "bbox": [
            -4.61975799553148,
            41.4461688475415,
            -4.61975799553148,
            41.4461688475415
          ]
        }
      ],
      "bbox": [
        -4.61975799553148,
        41.4461688475415,
        -4.61975799553148,
        41.4461688475415
      ]
    },
    "47007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.635780975634675,
              41.52772185747286
            ]
          },
          "bbox": [
            -4.635780975634675,
            41.52772185747286,
            -4.635780975634675,
            41.52772185747286
          ]
        }
      ],
      "bbox": [
        -4.635780975634675,
        41.52772185747286,
        -4.635780975634675,
        41.52772185747286
      ]
    },
    "47008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.679255157165081,
              41.20697685240624
            ]
          },
          "bbox": [
            -4.679255157165081,
            41.20697685240624,
            -4.679255157165081,
            41.20697685240624
          ]
        }
      ],
      "bbox": [
        -4.679255157165081,
        41.20697685240624,
        -4.679255157165081,
        41.20697685240624
      ]
    },
    "47009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.295810981817588,
              41.75115573489431
            ]
          },
          "bbox": [
            -4.295810981817588,
            41.75115573489431,
            -4.295810981817588,
            41.75115573489431
          ]
        }
      ],
      "bbox": [
        -4.295810981817588,
        41.75115573489431,
        -4.295810981817588,
        41.75115573489431
      ]
    },
    "47010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.797107943725774,
              41.62221774464218
            ]
          },
          "bbox": [
            -4.797107943725774,
            41.62221774464218,
            -4.797107943725774,
            41.62221774464218
          ]
        }
      ],
      "bbox": [
        -4.797107943725774,
        41.62221774464218,
        -4.797107943725774,
        41.62221774464218
      ]
    },
    "47011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.77387081086165,
              41.186373491095196
            ]
          },
          "bbox": [
            -4.77387081086165,
            41.186373491095196,
            -4.77387081086165,
            41.186373491095196
          ]
        }
      ],
      "bbox": [
        -4.77387081086165,
        41.186373491095196,
        -4.77387081086165,
        41.186373491095196
      ]
    },
    "47012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.271128127172871,
              41.48061091937467
            ]
          },
          "bbox": [
            -4.271128127172871,
            41.48061091937467,
            -4.271128127172871,
            41.48061091937467
          ]
        }
      ],
      "bbox": [
        -4.271128127172871,
        41.48061091937467,
        -4.271128127172871,
        41.48061091937467
      ]
    },
    "47013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2814938541147525,
              41.94896327195832
            ]
          },
          "bbox": [
            -5.2814938541147525,
            41.94896327195832,
            -5.2814938541147525,
            41.94896327195832
          ]
        }
      ],
      "bbox": [
        -5.2814938541147525,
        41.94896327195832,
        -5.2814938541147525,
        41.94896327195832
      ]
    },
    "47014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.084703680683152,
              41.67572808633038
            ]
          },
          "bbox": [
            -5.084703680683152,
            41.67572808633038,
            -5.084703680683152,
            41.67572808633038
          ]
        }
      ],
      "bbox": [
        -5.084703680683152,
        41.67572808633038,
        -5.084703680683152,
        41.67572808633038
      ]
    },
    "47015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.210488643364667,
              42.093515280246756
            ]
          },
          "bbox": [
            -5.210488643364667,
            42.093515280246756,
            -5.210488643364667,
            42.093515280246756
          ]
        }
      ],
      "bbox": [
        -5.210488643364667,
        42.093515280246756,
        -5.210488643364667,
        42.093515280246756
      ]
    },
    "47016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.290932658406408,
              41.621105457632204
            ]
          },
          "bbox": [
            -5.290932658406408,
            41.621105457632204,
            -5.290932658406408,
            41.621105457632204
          ]
        }
      ],
      "bbox": [
        -5.290932658406408,
        41.621105457632204,
        -5.290932658406408,
        41.621105457632204
      ]
    },
    "47017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.077306433314229,
              41.55598800118231
            ]
          },
          "bbox": [
            -5.077306433314229,
            41.55598800118231,
            -5.077306433314229,
            41.55598800118231
          ]
        }
      ],
      "bbox": [
        -5.077306433314229,
        41.55598800118231,
        -5.077306433314229,
        41.55598800118231
      ]
    },
    "47018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.015700153185022,
              41.590238800284645
            ]
          },
          "bbox": [
            -5.015700153185022,
            41.590238800284645,
            -5.015700153185022,
            41.590238800284645
          ]
        }
      ],
      "bbox": [
        -5.015700153185022,
        41.590238800284645,
        -5.015700153185022,
        41.590238800284645
      ]
    },
    "47019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.095937675291396,
              41.94764916366716
            ]
          },
          "bbox": [
            -5.095937675291396,
            41.94764916366716,
            -5.095937675291396,
            41.94764916366716
          ]
        }
      ],
      "bbox": [
        -5.095937675291396,
        41.94764916366716,
        -5.095937675291396,
        41.94764916366716
      ]
    },
    "47020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.02973952345943,
              41.18289879366
            ]
          },
          "bbox": [
            -5.02973952345943,
            41.18289879366,
            -5.02973952345943,
            41.18289879366
          ]
        }
      ],
      "bbox": [
        -5.02973952345943,
        41.18289879366,
        -5.02973952345943,
        41.18289879366
      ]
    },
    "47021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.685595539695489,
              41.24193513581081
            ]
          },
          "bbox": [
            -4.685595539695489,
            41.24193513581081,
            -4.685595539695489,
            41.24193513581081
          ]
        }
      ],
      "bbox": [
        -4.685595539695489,
        41.24193513581081,
        -4.685595539695489,
        41.24193513581081
      ]
    },
    "47022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.058044361009,
              41.61887667926149
            ]
          },
          "bbox": [
            -4.058044361009,
            41.61887667926149,
            -4.058044361009,
            41.61887667926149
          ]
        }
      ],
      "bbox": [
        -4.058044361009,
        41.61887667926149,
        -4.058044361009,
        41.61887667926149
      ]
    },
    "47023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.720520382329347,
              41.53919211449379
            ]
          },
          "bbox": [
            -4.720520382329347,
            41.53919211449379,
            -4.720520382329347,
            41.53919211449379
          ]
        }
      ],
      "bbox": [
        -4.720520382329347,
        41.53919211449379,
        -4.720520382329347,
        41.53919211449379
      ]
    },
    "47024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2779389948631295,
              42.00893293850597
            ]
          },
          "bbox": [
            -5.2779389948631295,
            42.00893293850597,
            -5.2779389948631295,
            42.00893293850597
          ]
        }
      ],
      "bbox": [
        -5.2779389948631295,
        42.00893293850597,
        -5.2779389948631295,
        42.00893293850597
      ]
    },
    "47025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.047198353115122,
              41.22312916753284
            ]
          },
          "bbox": [
            -5.047198353115122,
            41.22312916753284,
            -5.047198353115122,
            41.22312916753284
          ]
        }
      ],
      "bbox": [
        -5.047198353115122,
        41.22312916753284,
        -5.047198353115122,
        41.22312916753284
      ]
    },
    "47026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.113542853127813,
              42.12344417430004
            ]
          },
          "bbox": [
            -5.113542853127813,
            42.12344417430004,
            -5.113542853127813,
            42.12344417430004
          ]
        }
      ],
      "bbox": [
        -5.113542853127813,
        42.12344417430004,
        -5.113542853127813,
        42.12344417430004
      ]
    },
    "47027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.620638461355368,
              41.727302694416636
            ]
          },
          "bbox": [
            -4.620638461355368,
            41.727302694416636,
            -4.620638461355368,
            41.727302694416636
          ]
        }
      ],
      "bbox": [
        -4.620638461355368,
        41.727302694416636,
        -4.620638461355368,
        41.727302694416636
      ]
    },
    "47028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1633579236895875,
              42.17022463926508
            ]
          },
          "bbox": [
            -5.1633579236895875,
            42.17022463926508,
            -5.1633579236895875,
            42.17022463926508
          ]
        }
      ],
      "bbox": [
        -5.1633579236895875,
        42.17022463926508,
        -5.1633579236895875,
        42.17022463926508
      ]
    },
    "47029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.281602203160792,
              41.848735141427774
            ]
          },
          "bbox": [
            -5.281602203160792,
            41.848735141427774,
            -5.281602203160792,
            41.848735141427774
          ]
        }
      ],
      "bbox": [
        -5.281602203160792,
        41.848735141427774,
        -5.281602203160792,
        41.848735141427774
      ]
    },
    "47030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.220435348932781,
              41.4978203807132
            ]
          },
          "bbox": [
            -4.220435348932781,
            41.4978203807132,
            -4.220435348932781,
            41.4978203807132
          ]
        }
      ],
      "bbox": [
        -4.220435348932781,
        41.4978203807132,
        -4.220435348932781,
        41.4978203807132
      ]
    },
    "47031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.986339990101386,
              41.261553011100716
            ]
          },
          "bbox": [
            -4.986339990101386,
            41.261553011100716,
            -4.986339990101386,
            41.261553011100716
          ]
        }
      ],
      "bbox": [
        -4.986339990101386,
        41.261553011100716,
        -4.986339990101386,
        41.261553011100716
      ]
    },
    "47032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.509099164895211,
              41.476354041114014
            ]
          },
          "bbox": [
            -4.509099164895211,
            41.476354041114014,
            -4.509099164895211,
            41.476354041114014
          ]
        }
      ],
      "bbox": [
        -4.509099164895211,
        41.476354041114014,
        -4.509099164895211,
        41.476354041114014
      ]
    },
    "47033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.115105106876652,
              41.50802962788332
            ]
          },
          "bbox": [
            -4.115105106876652,
            41.50802962788332,
            -4.115105106876652,
            41.50802962788332
          ]
        }
      ],
      "bbox": [
        -4.115105106876652,
        41.50802962788332,
        -4.115105106876652,
        41.50802962788332
      ]
    },
    "47034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.136161990101112,
              41.76158336775988
            ]
          },
          "bbox": [
            -4.136161990101112,
            41.76158336775988,
            -4.136161990101112,
            41.76158336775988
          ]
        }
      ],
      "bbox": [
        -4.136161990101112,
        41.76158336775988,
        -4.136161990101112,
        41.76158336775988
      ]
    },
    "47035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.103059790197095,
              41.2031951540161
            ]
          },
          "bbox": [
            -5.103059790197095,
            41.2031951540161,
            -5.103059790197095,
            41.2031951540161
          ]
        }
      ],
      "bbox": [
        -5.103059790197095,
        41.2031951540161,
        -5.103059790197095,
        41.2031951540161
      ]
    },
    "47036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.251074729392946,
              41.57853459260539
            ]
          },
          "bbox": [
            -5.251074729392946,
            41.57853459260539,
            -5.251074729392946,
            41.57853459260539
          ]
        }
      ],
      "bbox": [
        -5.251074729392946,
        41.57853459260539,
        -5.251074729392946,
        41.57853459260539
      ]
    },
    "47037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.178768735285886,
              41.250863119558296
            ]
          },
          "bbox": [
            -5.178768735285886,
            41.250863119558296,
            -5.178768735285886,
            41.250863119558296
          ]
        }
      ],
      "bbox": [
        -5.178768735285886,
        41.250863119558296,
        -5.178768735285886,
        41.250863119558296
      ]
    },
    "47038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.008801988553717,
              41.58400867312215
            ]
          },
          "bbox": [
            -4.008801988553717,
            41.58400867312215,
            -4.008801988553717,
            41.58400867312215
          ]
        }
      ],
      "bbox": [
        -4.008801988553717,
        41.58400867312215,
        -4.008801988553717,
        41.58400867312215
      ]
    },
    "47039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.372419114750061,
              41.69160349839497
            ]
          },
          "bbox": [
            -4.372419114750061,
            41.69160349839497,
            -4.372419114750061,
            41.69160349839497
          ]
        }
      ],
      "bbox": [
        -4.372419114750061,
        41.69160349839497,
        -4.372419114750061,
        41.69160349839497
      ]
    },
    "47040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.314459070546097,
              42.13669269508458
            ]
          },
          "bbox": [
            -5.314459070546097,
            42.13669269508458,
            -5.314459070546097,
            42.13669269508458
          ]
        }
      ],
      "bbox": [
        -5.314459070546097,
        42.13669269508458,
        -5.314459070546097,
        42.13669269508458
      ]
    },
    "47041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.953503090840595,
              41.64093121708158
            ]
          },
          "bbox": [
            -4.953503090840595,
            41.64093121708158,
            -4.953503090840595,
            41.64093121708158
          ]
        }
      ],
      "bbox": [
        -4.953503090840595,
        41.64093121708158,
        -4.953503090840595,
        41.64093121708158
      ]
    },
    "47042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.313348175464374,
              41.68004440476985
            ]
          },
          "bbox": [
            -5.313348175464374,
            41.68004440476985,
            -5.313348175464374,
            41.68004440476985
          ]
        }
      ],
      "bbox": [
        -5.313348175464374,
        41.68004440476985,
        -5.313348175464374,
        41.68004440476985
      ]
    },
    "47043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.053820871928648,
              41.75652304056328
            ]
          },
          "bbox": [
            -5.053820871928648,
            41.75652304056328,
            -5.053820871928648,
            41.75652304056328
          ]
        }
      ],
      "bbox": [
        -5.053820871928648,
        41.75652304056328,
        -5.053820871928648,
        41.75652304056328
      ]
    },
    "47044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.590117281001123,
              41.6789236984861
            ]
          },
          "bbox": [
            -4.590117281001123,
            41.6789236984861,
            -4.590117281001123,
            41.6789236984861
          ]
        }
      ],
      "bbox": [
        -4.590117281001123,
        41.6789236984861,
        -4.590117281001123,
        41.6789236984861
      ]
    },
    "47045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.261715879528306,
              41.40053380104612
            ]
          },
          "bbox": [
            -5.261715879528306,
            41.40053380104612,
            -5.261715879528306,
            41.40053380104612
          ]
        }
      ],
      "bbox": [
        -5.261715879528306,
        41.40053380104612,
        -5.261715879528306,
        41.40053380104612
      ]
    },
    "47046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.173784035300685,
              42.1182546468186
            ]
          },
          "bbox": [
            -5.173784035300685,
            42.1182546468186,
            -5.173784035300685,
            42.1182546468186
          ]
        }
      ],
      "bbox": [
        -5.173784035300685,
        42.1182546468186,
        -5.173784035300685,
        42.1182546468186
      ]
    },
    "47047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.23878997779975,
              41.748283095670914
            ]
          },
          "bbox": [
            -4.23878997779975,
            41.748283095670914,
            -4.23878997779975,
            41.748283095670914
          ]
        }
      ],
      "bbox": [
        -4.23878997779975,
        41.748283095670914,
        -4.23878997779975,
        41.748283095670914
      ]
    },
    "47048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.141933827607532,
              42.030208814986814
            ]
          },
          "bbox": [
            -5.141933827607532,
            42.030208814986814,
            -5.141933827607532,
            42.030208814986814
          ]
        }
      ],
      "bbox": [
        -5.141933827607532,
        42.030208814986814,
        -5.141933827607532,
        42.030208814986814
      ]
    },
    "47049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.973634291326859,
              41.18294077369029
            ]
          },
          "bbox": [
            -4.973634291326859,
            41.18294077369029,
            -4.973634291326859,
            41.18294077369029
          ]
        }
      ],
      "bbox": [
        -4.973634291326859,
        41.18294077369029,
        -4.973634291326859,
        41.18294077369029
      ]
    },
    "47050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.714875745225065,
              41.77385374335972
            ]
          },
          "bbox": [
            -4.714875745225065,
            41.77385374335972,
            -4.714875745225065,
            41.77385374335972
          ]
        }
      ],
      "bbox": [
        -4.714875745225065,
        41.77385374335972,
        -4.714875745225065,
        41.77385374335972
      ]
    },
    "47051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.861322333815335,
              41.64467806276871
            ]
          },
          "bbox": [
            -4.861322333815335,
            41.64467806276871,
            -4.861322333815335,
            41.64467806276871
          ]
        }
      ],
      "bbox": [
        -4.861322333815335,
        41.64467806276871,
        -4.861322333815335,
        41.64467806276871
      ]
    },
    "47052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.653133227637387,
              41.59472756778053
            ]
          },
          "bbox": [
            -4.653133227637387,
            41.59472756778053,
            -4.653133227637387,
            41.59472756778053
          ]
        }
      ],
      "bbox": [
        -4.653133227637387,
        41.59472756778053,
        -4.653133227637387,
        41.59472756778053
      ]
    },
    "47053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.539325252478872,
              41.41053317698322
            ]
          },
          "bbox": [
            -4.539325252478872,
            41.41053317698322,
            -4.539325252478872,
            41.41053317698322
          ]
        }
      ],
      "bbox": [
        -4.539325252478872,
        41.41053317698322,
        -4.539325252478872,
        41.41053317698322
      ]
    },
    "47054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.330304381415225,
              41.53752687758825
            ]
          },
          "bbox": [
            -4.330304381415225,
            41.53752687758825,
            -4.330304381415225,
            41.53752687758825
          ]
        }
      ],
      "bbox": [
        -4.330304381415225,
        41.53752687758825,
        -4.330304381415225,
        41.53752687758825
      ]
    },
    "47055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.680174919503945,
              41.80259993031739
            ]
          },
          "bbox": [
            -4.680174919503945,
            41.80259993031739,
            -4.680174919503945,
            41.80259993031739
          ]
        }
      ],
      "bbox": [
        -4.680174919503945,
        41.80259993031739,
        -4.680174919503945,
        41.80259993031739
      ]
    },
    "47056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.055924869647718,
              41.67484867513773
            ]
          },
          "bbox": [
            -4.055924869647718,
            41.67484867513773,
            -4.055924869647718,
            41.67484867513773
          ]
        }
      ],
      "bbox": [
        -4.055924869647718,
        41.67484867513773,
        -4.055924869647718,
        41.67484867513773
      ]
    },
    "47057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.603832651423044,
              41.817665027053465
            ]
          },
          "bbox": [
            -4.603832651423044,
            41.817665027053465,
            -4.603832651423044,
            41.817665027053465
          ]
        }
      ],
      "bbox": [
        -4.603832651423044,
        41.817665027053465,
        -4.603832651423044,
        41.817665027053465
      ]
    },
    "47058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.060269660294459,
              42.046753644114524
            ]
          },
          "bbox": [
            -5.060269660294459,
            42.046753644114524,
            -5.060269660294459,
            42.046753644114524
          ]
        }
      ],
      "bbox": [
        -5.060269660294459,
        42.046753644114524,
        -5.060269660294459,
        42.046753644114524
      ]
    },
    "47059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.105056912110115,
              41.64127485534662
            ]
          },
          "bbox": [
            -4.105056912110115,
            41.64127485534662,
            -4.105056912110115,
            41.64127485534662
          ]
        }
      ],
      "bbox": [
        -4.105056912110115,
        41.64127485534662,
        -4.105056912110115,
        41.64127485534662
      ]
    },
    "47060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.091256877873832,
              41.75380654824723
            ]
          },
          "bbox": [
            -4.091256877873832,
            41.75380654824723,
            -4.091256877873832,
            41.75380654824723
          ]
        }
      ],
      "bbox": [
        -4.091256877873832,
        41.75380654824723,
        -4.091256877873832,
        41.75380654824723
      ]
    },
    "47061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.381833613728362,
              41.75307564246433
            ]
          },
          "bbox": [
            -4.381833613728362,
            41.75307564246433,
            -4.381833613728362,
            41.75307564246433
          ]
        }
      ],
      "bbox": [
        -4.381833613728362,
        41.75307564246433,
        -4.381833613728362,
        41.75307564246433
      ]
    },
    "47062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.183249033936649,
              41.754245893431865
            ]
          },
          "bbox": [
            -4.183249033936649,
            41.754245893431865,
            -4.183249033936649,
            41.754245893431865
          ]
        }
      ],
      "bbox": [
        -4.183249033936649,
        41.754245893431865,
        -4.183249033936649,
        41.754245893431865
      ]
    },
    "47063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.153492552292587,
              41.51847705596575
            ]
          },
          "bbox": [
            -4.153492552292587,
            41.51847705596575,
            -4.153492552292587,
            41.51847705596575
          ]
        }
      ],
      "bbox": [
        -4.153492552292587,
        41.51847705596575,
        -4.153492552292587,
        41.51847705596575
      ]
    },
    "47064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.055985073426271,
              42.16340652166647
            ]
          },
          "bbox": [
            -5.055985073426271,
            42.16340652166647,
            -5.055985073426271,
            42.16340652166647
          ]
        }
      ],
      "bbox": [
        -5.055985073426271,
        42.16340652166647,
        -5.055985073426271,
        42.16340652166647
      ]
    },
    "47065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.160962836833702,
              41.18356197990564
            ]
          },
          "bbox": [
            -5.160962836833702,
            41.18356197990564,
            -5.160962836833702,
            41.18356197990564
          ]
        }
      ],
      "bbox": [
        -5.160962836833702,
        41.18356197990564,
        -5.160962836833702,
        41.18356197990564
      ]
    },
    "47066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.758573809066489,
              41.70933160413048
            ]
          },
          "bbox": [
            -4.758573809066489,
            41.70933160413048,
            -4.758573809066489,
            41.70933160413048
          ]
        }
      ],
      "bbox": [
        -4.758573809066489,
        41.70933160413048,
        -4.758573809066489,
        41.70933160413048
      ]
    },
    "47067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9357618136255725,
              41.168516099669745
            ]
          },
          "bbox": [
            -4.9357618136255725,
            41.168516099669745,
            -4.9357618136255725,
            41.168516099669745
          ]
        }
      ],
      "bbox": [
        -4.9357618136255725,
        41.168516099669745,
        -4.9357618136255725,
        41.168516099669745
      ]
    },
    "47068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.641326846362151,
              41.247184149872105
            ]
          },
          "bbox": [
            -4.641326846362151,
            41.247184149872105,
            -4.641326846362151,
            41.247184149872105
          ]
        }
      ],
      "bbox": [
        -4.641326846362151,
        41.247184149872105,
        -4.641326846362151,
        41.247184149872105
      ]
    },
    "47069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.091595466238095,
              41.60858830186705
            ]
          },
          "bbox": [
            -5.091595466238095,
            41.60858830186705,
            -5.091595466238095,
            41.60858830186705
          ]
        }
      ],
      "bbox": [
        -5.091595466238095,
        41.60858830186705,
        -5.091595466238095,
        41.60858830186705
      ]
    },
    "47070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.972883894890014,
              42.04186211094479
            ]
          },
          "bbox": [
            -4.972883894890014,
            42.04186211094479,
            -4.972883894890014,
            42.04186211094479
          ]
        }
      ],
      "bbox": [
        -4.972883894890014,
        42.04186211094479,
        -4.972883894890014,
        42.04186211094479
      ]
    },
    "47071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.884808291501224,
              41.57558814151867
            ]
          },
          "bbox": [
            -4.884808291501224,
            41.57558814151867,
            -4.884808291501224,
            41.57558814151867
          ]
        }
      ],
      "bbox": [
        -4.884808291501224,
        41.57558814151867,
        -4.884808291501224,
        41.57558814151867
      ]
    },
    "47073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.951723251520069,
              42.12070789449048
            ]
          },
          "bbox": [
            -4.951723251520069,
            42.12070789449048,
            -4.951723251520069,
            42.12070789449048
          ]
        }
      ],
      "bbox": [
        -4.951723251520069,
        42.12070789449048,
        -4.951723251520069,
        42.12070789449048
      ]
    },
    "47074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.727782169905919,
              41.3575341251522
            ]
          },
          "bbox": [
            -4.727782169905919,
            41.3575341251522,
            -4.727782169905919,
            41.3575341251522
          ]
        }
      ],
      "bbox": [
        -4.727782169905919,
        41.3575341251522,
        -4.727782169905919,
        41.3575341251522
      ]
    },
    "47075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5279310671516155,
              41.34443482984174
            ]
          },
          "bbox": [
            -4.5279310671516155,
            41.34443482984174,
            -4.5279310671516155,
            41.34443482984174
          ]
        }
      ],
      "bbox": [
        -4.5279310671516155,
        41.34443482984174,
        -4.5279310671516155,
        41.34443482984174
      ]
    },
    "47076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.716964493537025,
              41.573340072237784
            ]
          },
          "bbox": [
            -4.716964493537025,
            41.573340072237784,
            -4.716964493537025,
            41.573340072237784
          ]
        }
      ],
      "bbox": [
        -4.716964493537025,
        41.573340072237784,
        -4.716964493537025,
        41.573340072237784
      ]
    },
    "47077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.233148443259452,
              41.55716998539769
            ]
          },
          "bbox": [
            -4.233148443259452,
            41.55716998539769,
            -4.233148443259452,
            41.55716998539769
          ]
        }
      ],
      "bbox": [
        -4.233148443259452,
        41.55716998539769,
        -4.233148443259452,
        41.55716998539769
      ]
    },
    "47078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.898023170922527,
              41.15687941417136
            ]
          },
          "bbox": [
            -4.898023170922527,
            41.15687941417136,
            -4.898023170922527,
            41.15687941417136
          ]
        }
      ],
      "bbox": [
        -4.898023170922527,
        41.15687941417136,
        -4.898023170922527,
        41.15687941417136
      ]
    },
    "47079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6054866474611496,
              41.273459279782564
            ]
          },
          "bbox": [
            -4.6054866474611496,
            41.273459279782564,
            -4.6054866474611496,
            41.273459279782564
          ]
        }
      ],
      "bbox": [
        -4.6054866474611496,
        41.273459279782564,
        -4.6054866474611496,
        41.273459279782564
      ]
    },
    "47080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.202000234635545,
              41.58597386836445
            ]
          },
          "bbox": [
            -4.202000234635545,
            41.58597386836445,
            -4.202000234635545,
            41.58597386836445
          ]
        }
      ],
      "bbox": [
        -4.202000234635545,
        41.58597386836445,
        -4.202000234635545,
        41.58597386836445
      ]
    },
    "47081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1426373774073,
              41.57991200702483
            ]
          },
          "bbox": [
            -5.1426373774073,
            41.57991200702483,
            -5.1426373774073,
            41.57991200702483
          ]
        }
      ],
      "bbox": [
        -5.1426373774073,
        41.57991200702483,
        -5.1426373774073,
        41.57991200702483
      ]
    },
    "47082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7734285053184005,
              41.41043071345093
            ]
          },
          "bbox": [
            -4.7734285053184005,
            41.41043071345093,
            -4.7734285053184005,
            41.41043071345093
          ]
        }
      ],
      "bbox": [
        -4.7734285053184005,
        41.41043071345093,
        -4.7734285053184005,
        41.41043071345093
      ]
    },
    "47083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.95434423535684,
              41.54323584276172
            ]
          },
          "bbox": [
            -4.95434423535684,
            41.54323584276172,
            -4.95434423535684,
            41.54323584276172
          ]
        }
      ],
      "bbox": [
        -4.95434423535684,
        41.54323584276172,
        -4.95434423535684,
        41.54323584276172
      ]
    },
    "47084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.301073589143276,
              42.17261510907275
            ]
          },
          "bbox": [
            -5.301073589143276,
            42.17261510907275,
            -5.301073589143276,
            42.17261510907275
          ]
        }
      ],
      "bbox": [
        -5.301073589143276,
        42.17261510907275,
        -5.301073589143276,
        42.17261510907275
      ]
    },
    "47085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.90470070923529,
              41.319322468255834
            ]
          },
          "bbox": [
            -4.90470070923529,
            41.319322468255834,
            -4.90470070923529,
            41.319322468255834
          ]
        }
      ],
      "bbox": [
        -4.90470070923529,
        41.319322468255834,
        -4.90470070923529,
        41.319322468255834
      ]
    },
    "47086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.014810042553007,
              41.8715041328167
            ]
          },
          "bbox": [
            -5.014810042553007,
            41.8715041328167,
            -5.014810042553007,
            41.8715041328167
          ]
        }
      ],
      "bbox": [
        -5.014810042553007,
        41.8715041328167,
        -5.014810042553007,
        41.8715041328167
      ]
    },
    "47087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.584835938240141,
              41.40464679841174
            ]
          },
          "bbox": [
            -4.584835938240141,
            41.40464679841174,
            -4.584835938240141,
            41.40464679841174
          ]
        }
      ],
      "bbox": [
        -4.584835938240141,
        41.40464679841174,
        -4.584835938240141,
        41.40464679841174
      ]
    },
    "47088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.147305194607442,
              42.24808284412177
            ]
          },
          "bbox": [
            -5.147305194607442,
            42.24808284412177,
            -5.147305194607442,
            42.24808284412177
          ]
        }
      ],
      "bbox": [
        -5.147305194607442,
        42.24808284412177,
        -5.147305194607442,
        42.24808284412177
      ]
    },
    "47089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.101078328828813,
              42.27415958799862
            ]
          },
          "bbox": [
            -5.101078328828813,
            42.27415958799862,
            -5.101078328828813,
            42.27415958799862
          ]
        }
      ],
      "bbox": [
        -5.101078328828813,
        42.27415958799862,
        -5.101078328828813,
        42.27415958799862
      ]
    },
    "47090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.680664815982535,
              41.42938319630684
            ]
          },
          "bbox": [
            -4.680664815982535,
            41.42938319630684,
            -4.680664815982535,
            41.42938319630684
          ]
        }
      ],
      "bbox": [
        -4.680664815982535,
        41.42938319630684,
        -4.680664815982535,
        41.42938319630684
      ]
    },
    "47091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.204150124874026,
              42.244773721028224
            ]
          },
          "bbox": [
            -5.204150124874026,
            42.244773721028224,
            -5.204150124874026,
            42.244773721028224
          ]
        }
      ],
      "bbox": [
        -5.204150124874026,
        42.244773721028224,
        -5.204150124874026,
        42.244773721028224
      ]
    },
    "47092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.907168750540867,
              41.89188198549821
            ]
          },
          "bbox": [
            -4.907168750540867,
            41.89188198549821,
            -4.907168750540867,
            41.89188198549821
          ]
        }
      ],
      "bbox": [
        -4.907168750540867,
        41.89188198549821,
        -4.907168750540867,
        41.89188198549821
      ]
    },
    "47093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.44758791083328,
              41.51247480207218
            ]
          },
          "bbox": [
            -4.44758791083328,
            41.51247480207218,
            -4.44758791083328,
            41.51247480207218
          ]
        }
      ],
      "bbox": [
        -4.44758791083328,
        41.51247480207218,
        -4.44758791083328,
        41.51247480207218
      ]
    },
    "47094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.081865680314734,
              41.98384131281502
            ]
          },
          "bbox": [
            -5.081865680314734,
            41.98384131281502,
            -5.081865680314734,
            41.98384131281502
          ]
        }
      ],
      "bbox": [
        -5.081865680314734,
        41.98384131281502,
        -5.081865680314734,
        41.98384131281502
      ]
    },
    "47095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8204675534524055,
              41.277816973886814
            ]
          },
          "bbox": [
            -4.8204675534524055,
            41.277816973886814,
            -4.8204675534524055,
            41.277816973886814
          ]
        }
      ],
      "bbox": [
        -4.8204675534524055,
        41.277816973886814,
        -4.8204675534524055,
        41.277816973886814
      ]
    },
    "47096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.177617179152138,
              41.856514285824865
            ]
          },
          "bbox": [
            -5.177617179152138,
            41.856514285824865,
            -5.177617179152138,
            41.856514285824865
          ]
        }
      ],
      "bbox": [
        -5.177617179152138,
        41.856514285824865,
        -5.177617179152138,
        41.856514285824865
      ]
    },
    "47097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.179720354528202,
              41.63062180176851
            ]
          },
          "bbox": [
            -5.179720354528202,
            41.63062180176851,
            -5.179720354528202,
            41.63062180176851
          ]
        }
      ],
      "bbox": [
        -5.179720354528202,
        41.63062180176851,
        -5.179720354528202,
        41.63062180176851
      ]
    },
    "47098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.778576226900651,
              41.76078400227836
            ]
          },
          "bbox": [
            -4.778576226900651,
            41.76078400227836,
            -4.778576226900651,
            41.76078400227836
          ]
        }
      ],
      "bbox": [
        -4.778576226900651,
        41.76078400227836,
        -4.778576226900651,
        41.76078400227836
      ]
    },
    "47099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.94512093405436,
              41.78347906725872
            ]
          },
          "bbox": [
            -4.94512093405436,
            41.78347906725872,
            -4.94512093405436,
            41.78347906725872
          ]
        }
      ],
      "bbox": [
        -4.94512093405436,
        41.78347906725872,
        -4.94512093405436,
        41.78347906725872
      ]
    },
    "47100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.845361763057546,
              41.12580555455262
            ]
          },
          "bbox": [
            -4.845361763057546,
            41.12580555455262,
            -4.845361763057546,
            41.12580555455262
          ]
        }
      ],
      "bbox": [
        -4.845361763057546,
        41.12580555455262,
        -4.845361763057546,
        41.12580555455262
      ]
    },
    "47101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.093334550057406,
              41.366294067474975
            ]
          },
          "bbox": [
            -5.093334550057406,
            41.366294067474975,
            -5.093334550057406,
            41.366294067474975
          ]
        }
      ],
      "bbox": [
        -5.093334550057406,
        41.366294067474975,
        -5.093334550057406,
        41.366294067474975
      ]
    },
    "47102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.084049296302945,
              41.26652708784255
            ]
          },
          "bbox": [
            -5.084049296302945,
            41.26652708784255,
            -5.084049296302945,
            41.26652708784255
          ]
        }
      ],
      "bbox": [
        -5.084049296302945,
        41.26652708784255,
        -5.084049296302945,
        41.26652708784255
      ]
    },
    "47103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.363375539664286,
              41.64800830669725
            ]
          },
          "bbox": [
            -4.363375539664286,
            41.64800830669725,
            -4.363375539664286,
            41.64800830669725
          ]
        }
      ],
      "bbox": [
        -4.363375539664286,
        41.64800830669725,
        -4.363375539664286,
        41.64800830669725
      ]
    },
    "47104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.717385364111539,
              41.29221618353792
            ]
          },
          "bbox": [
            -4.717385364111539,
            41.29221618353792,
            -4.717385364111539,
            41.29221618353792
          ]
        }
      ],
      "bbox": [
        -4.717385364111539,
        41.29221618353792,
        -4.717385364111539,
        41.29221618353792
      ]
    },
    "47105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.516346823218876,
              41.68638174433653
            ]
          },
          "bbox": [
            -4.516346823218876,
            41.68638174433653,
            -4.516346823218876,
            41.68638174433653
          ]
        }
      ],
      "bbox": [
        -4.516346823218876,
        41.68638174433653,
        -4.516346823218876,
        41.68638174433653
      ]
    },
    "47106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0366644500573985,
              41.56521557765046
            ]
          },
          "bbox": [
            -4.0366644500573985,
            41.56521557765046,
            -4.0366644500573985,
            41.56521557765046
          ]
        }
      ],
      "bbox": [
        -4.0366644500573985,
        41.56521557765046,
        -4.0366644500573985,
        41.56521557765046
      ]
    },
    "47109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.139290359107948,
              41.92831574572138
            ]
          },
          "bbox": [
            -5.139290359107948,
            41.92831574572138,
            -5.139290359107948,
            41.92831574572138
          ]
        }
      ],
      "bbox": [
        -5.139290359107948,
        41.92831574572138,
        -5.139290359107948,
        41.92831574572138
      ]
    },
    "47110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.529929924034955,
              41.53946801130448
            ]
          },
          "bbox": [
            -4.529929924034955,
            41.53946801130448,
            -4.529929924034955,
            41.53946801130448
          ]
        }
      ],
      "bbox": [
        -4.529929924034955,
        41.53946801130448,
        -4.529929924034955,
        41.53946801130448
      ]
    },
    "47111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.69609101655703,
              41.480402743097116
            ]
          },
          "bbox": [
            -4.69609101655703,
            41.480402743097116,
            -4.69609101655703,
            41.480402743097116
          ]
        }
      ],
      "bbox": [
        -4.69609101655703,
        41.480402743097116,
        -4.69609101655703,
        41.480402743097116
      ]
    },
    "47112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5917262696475625,
              41.355552081078336
            ]
          },
          "bbox": [
            -4.5917262696475625,
            41.355552081078336,
            -4.5917262696475625,
            41.355552081078336
          ]
        }
      ],
      "bbox": [
        -4.5917262696475625,
        41.355552081078336,
        -4.5917262696475625,
        41.355552081078336
      ]
    },
    "47113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2092070121756455,
              41.52969517353132
            ]
          },
          "bbox": [
            -5.2092070121756455,
            41.52969517353132,
            -5.2092070121756455,
            41.52969517353132
          ]
        }
      ],
      "bbox": [
        -5.2092070121756455,
        41.52969517353132,
        -5.2092070121756455,
        41.52969517353132
      ]
    },
    "47114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1230229704758585,
              41.596509806766065
            ]
          },
          "bbox": [
            -4.1230229704758585,
            41.596509806766065,
            -4.1230229704758585,
            41.596509806766065
          ]
        }
      ],
      "bbox": [
        -4.1230229704758585,
        41.596509806766065,
        -4.1230229704758585,
        41.596509806766065
      ]
    },
    "47115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.976138775136636,
              41.724435144792
            ]
          },
          "bbox": [
            -4.976138775136636,
            41.724435144792,
            -4.976138775136636,
            41.724435144792
          ]
        }
      ],
      "bbox": [
        -4.976138775136636,
        41.724435144792,
        -4.976138775136636,
        41.724435144792
      ]
    },
    "47116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.207524515639393,
              41.6617249520013
            ]
          },
          "bbox": [
            -4.207524515639393,
            41.6617249520013,
            -4.207524515639393,
            41.6617249520013
          ]
        }
      ],
      "bbox": [
        -4.207524515639393,
        41.6617249520013,
        -4.207524515639393,
        41.6617249520013
      ]
    },
    "47117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.437471828570174,
              41.72548419109875
            ]
          },
          "bbox": [
            -4.437471828570174,
            41.72548419109875,
            -4.437471828570174,
            41.72548419109875
          ]
        }
      ],
      "bbox": [
        -4.437471828570174,
        41.72548419109875,
        -4.437471828570174,
        41.72548419109875
      ]
    },
    "47118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.167020046255265,
              41.687859118506886
            ]
          },
          "bbox": [
            -4.167020046255265,
            41.687859118506886,
            -4.167020046255265,
            41.687859118506886
          ]
        }
      ],
      "bbox": [
        -4.167020046255265,
        41.687859118506886,
        -4.167020046255265,
        41.687859118506886
      ]
    },
    "47119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.144457448912407,
              41.71040238843702
            ]
          },
          "bbox": [
            -4.144457448912407,
            41.71040238843702,
            -4.144457448912407,
            41.71040238843702
          ]
        }
      ],
      "bbox": [
        -4.144457448912407,
        41.71040238843702,
        -4.144457448912407,
        41.71040238843702
      ]
    },
    "47121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.129412698478753,
              41.43887930055988
            ]
          },
          "bbox": [
            -5.129412698478753,
            41.43887930055988,
            -5.129412698478753,
            41.43887930055988
          ]
        }
      ],
      "bbox": [
        -5.129412698478753,
        41.43887930055988,
        -5.129412698478753,
        41.43887930055988
      ]
    },
    "47122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.56893752387949,
              41.47875436668739
            ]
          },
          "bbox": [
            -4.56893752387949,
            41.47875436668739,
            -4.56893752387949,
            41.47875436668739
          ]
        }
      ],
      "bbox": [
        -4.56893752387949,
        41.47875436668739,
        -4.56893752387949,
        41.47875436668739
      ]
    },
    "47123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.830414098232643,
              41.32427520884588
            ]
          },
          "bbox": [
            -4.830414098232643,
            41.32427520884588,
            -4.830414098232643,
            41.32427520884588
          ]
        }
      ],
      "bbox": [
        -4.830414098232643,
        41.32427520884588,
        -4.830414098232643,
        41.32427520884588
      ]
    },
    "47124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.832762242755567,
              41.374348070692214
            ]
          },
          "bbox": [
            -4.832762242755567,
            41.374348070692214,
            -4.832762242755567,
            41.374348070692214
          ]
        }
      ],
      "bbox": [
        -4.832762242755567,
        41.374348070692214,
        -4.832762242755567,
        41.374348070692214
      ]
    },
    "47125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.261090588278109,
              41.81105239755922
            ]
          },
          "bbox": [
            -5.261090588278109,
            41.81105239755922,
            -5.261090588278109,
            41.81105239755922
          ]
        }
      ],
      "bbox": [
        -5.261090588278109,
        41.81105239755922,
        -5.261090588278109,
        41.81105239755922
      ]
    },
    "47126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.657765216987482,
              41.18389421558303
            ]
          },
          "bbox": [
            -4.657765216987482,
            41.18389421558303,
            -4.657765216987482,
            41.18389421558303
          ]
        }
      ],
      "bbox": [
        -4.657765216987482,
        41.18389421558303,
        -4.657765216987482,
        41.18389421558303
      ]
    },
    "47127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.257395193179921,
              41.60727573992033
            ]
          },
          "bbox": [
            -4.257395193179921,
            41.60727573992033,
            -4.257395193179921,
            41.60727573992033
          ]
        }
      ],
      "bbox": [
        -4.257395193179921,
        41.60727573992033,
        -4.257395193179921,
        41.60727573992033
      ]
    },
    "47128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.455776272331435,
              41.99180719849344
            ]
          },
          "bbox": [
            -5.455776272331435,
            41.99180719849344,
            -5.455776272331435,
            41.99180719849344
          ]
        }
      ],
      "bbox": [
        -5.455776272331435,
        41.99180719849344,
        -5.455776272331435,
        41.99180719849344
      ]
    },
    "47129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.34982965628501,
              41.60216991813488
            ]
          },
          "bbox": [
            -4.34982965628501,
            41.60216991813488,
            -4.34982965628501,
            41.60216991813488
          ]
        }
      ],
      "bbox": [
        -4.34982965628501,
        41.60216991813488,
        -4.34982965628501,
        41.60216991813488
      ]
    },
    "47130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.66600149953412,
              41.86943786434816
            ]
          },
          "bbox": [
            -4.66600149953412,
            41.86943786434816,
            -4.66600149953412,
            41.86943786434816
          ]
        }
      ],
      "bbox": [
        -4.66600149953412,
        41.86943786434816,
        -4.66600149953412,
        41.86943786434816
      ]
    },
    "47131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.045125024694575,
              41.53285943598176
            ]
          },
          "bbox": [
            -4.045125024694575,
            41.53285943598176,
            -4.045125024694575,
            41.53285943598176
          ]
        }
      ],
      "bbox": [
        -4.045125024694575,
        41.53285943598176,
        -4.045125024694575,
        41.53285943598176
      ]
    },
    "47132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.789137192061688,
              41.23133998425618
            ]
          },
          "bbox": [
            -4.789137192061688,
            41.23133998425618,
            -4.789137192061688,
            41.23133998425618
          ]
        }
      ],
      "bbox": [
        -4.789137192061688,
        41.23133998425618,
        -4.789137192061688,
        41.23133998425618
      ]
    },
    "47133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.627702965720321,
              41.65057940067091
            ]
          },
          "bbox": [
            -4.627702965720321,
            41.65057940067091,
            -4.627702965720321,
            41.65057940067091
          ]
        }
      ],
      "bbox": [
        -4.627702965720321,
        41.65057940067091,
        -4.627702965720321,
        41.65057940067091
      ]
    },
    "47134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.474133019735825,
              42.041461537033136
            ]
          },
          "bbox": [
            -5.474133019735825,
            42.041461537033136,
            -5.474133019735825,
            42.041461537033136
          ]
        }
      ],
      "bbox": [
        -5.474133019735825,
        42.041461537033136,
        -5.474133019735825,
        42.041461537033136
      ]
    },
    "47135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.907230320584465,
              41.61082622048025
            ]
          },
          "bbox": [
            -4.907230320584465,
            41.61082622048025,
            -4.907230320584465,
            41.61082622048025
          ]
        }
      ],
      "bbox": [
        -4.907230320584465,
        41.61082622048025,
        -4.907230320584465,
        41.61082622048025
      ]
    },
    "47137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.11453048343677,
              41.66957987616256
            ]
          },
          "bbox": [
            -4.11453048343677,
            41.66957987616256,
            -4.11453048343677,
            41.66957987616256
          ]
        }
      ],
      "bbox": [
        -4.11453048343677,
        41.66957987616256,
        -4.11453048343677,
        41.66957987616256
      ]
    },
    "47138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9226238504732445,
              41.21525002502237
            ]
          },
          "bbox": [
            -4.9226238504732445,
            41.21525002502237,
            -4.9226238504732445,
            41.21525002502237
          ]
        }
      ],
      "bbox": [
        -4.9226238504732445,
        41.21525002502237,
        -4.9226238504732445,
        41.21525002502237
      ]
    },
    "47139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.994830836808967,
              41.408716661545476
            ]
          },
          "bbox": [
            -4.994830836808967,
            41.408716661545476,
            -4.994830836808967,
            41.408716661545476
          ]
        }
      ],
      "bbox": [
        -4.994830836808967,
        41.408716661545476,
        -4.994830836808967,
        41.408716661545476
      ]
    },
    "47140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.205728893214869,
              42.21254753396352
            ]
          },
          "bbox": [
            -5.205728893214869,
            42.21254753396352,
            -5.205728893214869,
            42.21254753396352
          ]
        }
      ],
      "bbox": [
        -5.205728893214869,
        42.21254753396352,
        -5.205728893214869,
        42.21254753396352
      ]
    },
    "47141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.863795922572281,
              41.14215634544145
            ]
          },
          "bbox": [
            -4.863795922572281,
            41.14215634544145,
            -4.863795922572281,
            41.14215634544145
          ]
        }
      ],
      "bbox": [
        -4.863795922572281,
        41.14215634544145,
        -4.863795922572281,
        41.14215634544145
      ]
    },
    "47142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.148384037569421,
              41.680671720730835
            ]
          },
          "bbox": [
            -5.148384037569421,
            41.680671720730835,
            -5.148384037569421,
            41.680671720730835
          ]
        }
      ],
      "bbox": [
        -5.148384037569421,
        41.680671720730835,
        -5.148384037569421,
        41.680671720730835
      ]
    },
    "47143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.074933329663428,
              41.69991063354746
            ]
          },
          "bbox": [
            -4.074933329663428,
            41.69991063354746,
            -4.074933329663428,
            41.69991063354746
          ]
        }
      ],
      "bbox": [
        -4.074933329663428,
        41.69991063354746,
        -4.074933329663428,
        41.69991063354746
      ]
    },
    "47144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.534129367604076,
              41.75164966619087
            ]
          },
          "bbox": [
            -4.534129367604076,
            41.75164966619087,
            -4.534129367604076,
            41.75164966619087
          ]
        }
      ],
      "bbox": [
        -4.534129367604076,
        41.75164966619087,
        -4.534129367604076,
        41.75164966619087
      ]
    },
    "47145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.474866151500538,
              41.44293570215993
            ]
          },
          "bbox": [
            -4.474866151500538,
            41.44293570215993,
            -4.474866151500538,
            41.44293570215993
          ]
        }
      ],
      "bbox": [
        -4.474866151500538,
        41.44293570215993,
        -4.474866151500538,
        41.44293570215993
      ]
    },
    "47146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.911030527895666,
              41.51499387646011
            ]
          },
          "bbox": [
            -4.911030527895666,
            41.51499387646011,
            -4.911030527895666,
            41.51499387646011
          ]
        }
      ],
      "bbox": [
        -4.911030527895666,
        41.51499387646011,
        -4.911030527895666,
        41.51499387646011
      ]
    },
    "47147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.779701351562693,
              41.156628636716
            ]
          },
          "bbox": [
            -4.779701351562693,
            41.156628636716,
            -4.779701351562693,
            41.156628636716
          ]
        }
      ],
      "bbox": [
        -4.779701351562693,
        41.156628636716,
        -4.779701351562693,
        41.156628636716
      ]
    },
    "47148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.343089210506576,
              41.74153674814278
            ]
          },
          "bbox": [
            -5.343089210506576,
            41.74153674814278,
            -5.343089210506576,
            41.74153674814278
          ]
        }
      ],
      "bbox": [
        -5.343089210506576,
        41.74153674814278,
        -5.343089210506576,
        41.74153674814278
      ]
    },
    "47149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.027727095809974,
              41.68958226145623
            ]
          },
          "bbox": [
            -5.027727095809974,
            41.68958226145623,
            -5.027727095809974,
            41.68958226145623
          ]
        }
      ],
      "bbox": [
        -5.027727095809974,
        41.68958226145623,
        -5.027727095809974,
        41.68958226145623
      ]
    },
    "47150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.286514431380679,
              41.473132137692446
            ]
          },
          "bbox": [
            -5.286514431380679,
            41.473132137692446,
            -5.286514431380679,
            41.473132137692446
          ]
        }
      ],
      "bbox": [
        -5.286514431380679,
        41.473132137692446,
        -5.286514431380679,
        41.473132137692446
      ]
    },
    "47151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.078474763013272,
              41.62010076668248
            ]
          },
          "bbox": [
            -5.078474763013272,
            41.62010076668248,
            -5.078474763013272,
            41.62010076668248
          ]
        }
      ],
      "bbox": [
        -5.078474763013272,
        41.62010076668248,
        -5.078474763013272,
        41.62010076668248
      ]
    },
    "47152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.27776452401458,
              41.89481250815239
            ]
          },
          "bbox": [
            -5.27776452401458,
            41.89481250815239,
            -5.27776452401458,
            41.89481250815239
          ]
        }
      ],
      "bbox": [
        -5.27776452401458,
        41.89481250815239,
        -5.27776452401458,
        41.89481250815239
      ]
    },
    "47153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.060848276613209,
              42.23775588821522
            ]
          },
          "bbox": [
            -5.060848276613209,
            42.23775588821522,
            -5.060848276613209,
            42.23775588821522
          ]
        }
      ],
      "bbox": [
        -5.060848276613209,
        42.23775588821522,
        -5.060848276613209,
        42.23775588821522
      ]
    },
    "47154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.419558401900395,
              41.56323303575719
            ]
          },
          "bbox": [
            -4.419558401900395,
            41.56323303575719,
            -4.419558401900395,
            41.56323303575719
          ]
        }
      ],
      "bbox": [
        -4.419558401900395,
        41.56323303575719,
        -4.419558401900395,
        41.56323303575719
      ]
    },
    "47155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.667897945893379,
              41.69888070681498
            ]
          },
          "bbox": [
            -4.667897945893379,
            41.69888070681498,
            -4.667897945893379,
            41.69888070681498
          ]
        }
      ],
      "bbox": [
        -4.667897945893379,
        41.69888070681498,
        -4.667897945893379,
        41.69888070681498
      ]
    },
    "47156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.870610461654159,
              41.220221744245265
            ]
          },
          "bbox": [
            -4.870610461654159,
            41.220221744245265,
            -4.870610461654159,
            41.220221744245265
          ]
        }
      ],
      "bbox": [
        -4.870610461654159,
        41.220221744245265,
        -4.870610461654159,
        41.220221744245265
      ]
    },
    "47157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4201029634069045,
              41.60858977843974
            ]
          },
          "bbox": [
            -4.4201029634069045,
            41.60858977843974,
            -4.4201029634069045,
            41.60858977843974
          ]
        }
      ],
      "bbox": [
        -4.4201029634069045,
        41.60858977843974,
        -4.4201029634069045,
        41.60858977843974
      ]
    },
    "47158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.914047750863719,
              41.43792511581673
            ]
          },
          "bbox": [
            -4.914047750863719,
            41.43792511581673,
            -4.914047750863719,
            41.43792511581673
          ]
        }
      ],
      "bbox": [
        -4.914047750863719,
        41.43792511581673,
        -4.914047750863719,
        41.43792511581673
      ]
    },
    "47159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.848986355363512,
              41.46226333306427
            ]
          },
          "bbox": [
            -4.848986355363512,
            41.46226333306427,
            -4.848986355363512,
            41.46226333306427
          ]
        }
      ],
      "bbox": [
        -4.848986355363512,
        41.46226333306427,
        -4.848986355363512,
        41.46226333306427
      ]
    },
    "47160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1827608116672215,
              41.370006084837954
            ]
          },
          "bbox": [
            -5.1827608116672215,
            41.370006084837954,
            -5.1827608116672215,
            41.370006084837954
          ]
        }
      ],
      "bbox": [
        -5.1827608116672215,
        41.370006084837954,
        -5.1827608116672215,
        41.370006084837954
      ]
    },
    "47161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.833384931358154,
              41.591722871272715
            ]
          },
          "bbox": [
            -4.833384931358154,
            41.591722871272715,
            -4.833384931358154,
            41.591722871272715
          ]
        }
      ],
      "bbox": [
        -4.833384931358154,
        41.591722871272715,
        -4.833384931358154,
        41.591722871272715
      ]
    },
    "47162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.019414275692186,
              41.98037944127084
            ]
          },
          "bbox": [
            -5.019414275692186,
            41.98037944127084,
            -5.019414275692186,
            41.98037944127084
          ]
        }
      ],
      "bbox": [
        -5.019414275692186,
        41.98037944127084,
        -5.019414275692186,
        41.98037944127084
      ]
    },
    "47163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.251522299082581,
              41.65447308758262
            ]
          },
          "bbox": [
            -5.251522299082581,
            41.65447308758262,
            -5.251522299082581,
            41.65447308758262
          ]
        }
      ],
      "bbox": [
        -5.251522299082581,
        41.65447308758262,
        -5.251522299082581,
        41.65447308758262
      ]
    },
    "47164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1611261728865605,
              41.805652365298094
            ]
          },
          "bbox": [
            -5.1611261728865605,
            41.805652365298094,
            -5.1611261728865605,
            41.805652365298094
          ]
        }
      ],
      "bbox": [
        -5.1611261728865605,
        41.805652365298094,
        -5.1611261728865605,
        41.805652365298094
      ]
    },
    "47165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.005967298950248,
              41.50589989962595
            ]
          },
          "bbox": [
            -5.005967298950248,
            41.50589989962595,
            -5.005967298950248,
            41.50589989962595
          ]
        }
      ],
      "bbox": [
        -5.005967298950248,
        41.50589989962595,
        -5.005967298950248,
        41.50589989962595
      ]
    },
    "47166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.135038504249256,
              41.478557826945995
            ]
          },
          "bbox": [
            -5.135038504249256,
            41.478557826945995,
            -5.135038504249256,
            41.478557826945995
          ]
        }
      ],
      "bbox": [
        -5.135038504249256,
        41.478557826945995,
        -5.135038504249256,
        41.478557826945995
      ]
    },
    "47167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.267032474438971,
              41.21905147941328
            ]
          },
          "bbox": [
            -5.267032474438971,
            41.21905147941328,
            -5.267032474438971,
            41.21905147941328
          ]
        }
      ],
      "bbox": [
        -5.267032474438971,
        41.21905147941328,
        -5.267032474438971,
        41.21905147941328
      ]
    },
    "47168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.056409788450854,
              41.6751173524122
            ]
          },
          "bbox": [
            -5.056409788450854,
            41.6751173524122,
            -5.056409788450854,
            41.6751173524122
          ]
        }
      ],
      "bbox": [
        -5.056409788450854,
        41.6751173524122,
        -5.056409788450854,
        41.6751173524122
      ]
    },
    "47169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.225507194135211,
              41.782946605882785
            ]
          },
          "bbox": [
            -4.225507194135211,
            41.782946605882785,
            -4.225507194135211,
            41.782946605882785
          ]
        }
      ],
      "bbox": [
        -4.225507194135211,
        41.782946605882785,
        -4.225507194135211,
        41.782946605882785
      ]
    },
    "47170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.138840658311653,
              41.5383097059733
            ]
          },
          "bbox": [
            -4.138840658311653,
            41.5383097059733,
            -4.138840658311653,
            41.5383097059733
          ]
        }
      ],
      "bbox": [
        -4.138840658311653,
        41.5383097059733,
        -4.138840658311653,
        41.5383097059733
      ]
    },
    "47171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.012163364827654,
              41.656746518966884
            ]
          },
          "bbox": [
            -5.012163364827654,
            41.656746518966884,
            -5.012163364827654,
            41.656746518966884
          ]
        }
      ],
      "bbox": [
        -5.012163364827654,
        41.656746518966884,
        -5.012163364827654,
        41.656746518966884
      ]
    },
    "47172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.353324954697741,
              41.480188387602944
            ]
          },
          "bbox": [
            -4.353324954697741,
            41.480188387602944,
            -4.353324954697741,
            41.480188387602944
          ]
        }
      ],
      "bbox": [
        -4.353324954697741,
        41.480188387602944,
        -4.353324954697741,
        41.480188387602944
      ]
    },
    "47173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4794883517920425,
              41.584813720060744
            ]
          },
          "bbox": [
            -4.4794883517920425,
            41.584813720060744,
            -4.4794883517920425,
            41.584813720060744
          ]
        }
      ],
      "bbox": [
        -4.4794883517920425,
        41.584813720060744,
        -4.4794883517920425,
        41.584813720060744
      ]
    },
    "47174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.664019592291094,
              41.82324130180448
            ]
          },
          "bbox": [
            -4.664019592291094,
            41.82324130180448,
            -4.664019592291094,
            41.82324130180448
          ]
        }
      ],
      "bbox": [
        -4.664019592291094,
        41.82324130180448,
        -4.664019592291094,
        41.82324130180448
      ]
    },
    "47175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.58692288905651,
              41.58880201239506
            ]
          },
          "bbox": [
            -4.58692288905651,
            41.58880201239506,
            -4.58692288905651,
            41.58880201239506
          ]
        }
      ],
      "bbox": [
        -4.58692288905651,
        41.58880201239506,
        -4.58692288905651,
        41.58880201239506
      ]
    },
    "47176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.341687374702555,
              42.08609811863802
            ]
          },
          "bbox": [
            -5.341687374702555,
            42.08609811863802,
            -5.341687374702555,
            42.08609811863802
          ]
        }
      ],
      "bbox": [
        -5.341687374702555,
        42.08609811863802,
        -5.341687374702555,
        42.08609811863802
      ]
    },
    "47177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.284036247117999,
              42.10655250677617
            ]
          },
          "bbox": [
            -5.284036247117999,
            42.10655250677617,
            -5.284036247117999,
            42.10655250677617
          ]
        }
      ],
      "bbox": [
        -5.284036247117999,
        42.10655250677617,
        -5.284036247117999,
        42.10655250677617
      ]
    },
    "47178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.185466789424674,
              41.729384423982914
            ]
          },
          "bbox": [
            -5.185466789424674,
            41.729384423982914,
            -5.185466789424674,
            41.729384423982914
          ]
        }
      ],
      "bbox": [
        -5.185466789424674,
        41.729384423982914,
        -5.185466789424674,
        41.729384423982914
      ]
    },
    "47179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.283288015317385,
              41.66238995362759
            ]
          },
          "bbox": [
            -4.283288015317385,
            41.66238995362759,
            -4.283288015317385,
            41.66238995362759
          ]
        }
      ],
      "bbox": [
        -4.283288015317385,
        41.66238995362759,
        -4.283288015317385,
        41.66238995362759
      ]
    },
    "47180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.056432953046443,
              41.63910051759397
            ]
          },
          "bbox": [
            -4.056432953046443,
            41.63910051759397,
            -4.056432953046443,
            41.63910051759397
          ]
        }
      ],
      "bbox": [
        -4.056432953046443,
        41.63910051759397,
        -4.056432953046443,
        41.63910051759397
      ]
    },
    "47181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.967594736775572,
              41.845906680756016
            ]
          },
          "bbox": [
            -4.967594736775572,
            41.845906680756016,
            -4.967594736775572,
            41.845906680756016
          ]
        }
      ],
      "bbox": [
        -4.967594736775572,
        41.845906680756016,
        -4.967594736775572,
        41.845906680756016
      ]
    },
    "47182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.789571127938742,
              41.47931848119499
            ]
          },
          "bbox": [
            -4.789571127938742,
            41.47931848119499,
            -4.789571127938742,
            41.47931848119499
          ]
        }
      ],
      "bbox": [
        -4.789571127938742,
        41.47931848119499,
        -4.789571127938742,
        41.47931848119499
      ]
    },
    "47183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.326526985283875,
              42.043440925983546
            ]
          },
          "bbox": [
            -5.326526985283875,
            42.043440925983546,
            -5.326526985283875,
            42.043440925983546
          ]
        }
      ],
      "bbox": [
        -5.326526985283875,
        42.043440925983546,
        -5.326526985283875,
        42.043440925983546
      ]
    },
    "47184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.528725243186945,
              41.80226969286198
            ]
          },
          "bbox": [
            -4.528725243186945,
            41.80226969286198,
            -4.528725243186945,
            41.80226969286198
          ]
        }
      ],
      "bbox": [
        -4.528725243186945,
        41.80226969286198,
        -4.528725243186945,
        41.80226969286198
      ]
    },
    "47185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.034367023195897,
              41.824605353875135
            ]
          },
          "bbox": [
            -5.034367023195897,
            41.824605353875135,
            -5.034367023195897,
            41.824605353875135
          ]
        }
      ],
      "bbox": [
        -5.034367023195897,
        41.824605353875135,
        -5.034367023195897,
        41.824605353875135
      ]
    },
    "47186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7773931090813475,
              41.66169026843691
            ]
          },
          "bbox": [
            -4.7773931090813475,
            41.66169026843691,
            -4.7773931090813475,
            41.66169026843691
          ]
        }
      ],
      "bbox": [
        -4.7773931090813475,
        41.66169026843691,
        -4.7773931090813475,
        41.66169026843691
      ]
    },
    "47187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.125633272000375,
              42.19025542261192
            ]
          },
          "bbox": [
            -5.125633272000375,
            42.19025542261192,
            -5.125633272000375,
            42.19025542261192
          ]
        }
      ],
      "bbox": [
        -5.125633272000375,
        42.19025542261192,
        -5.125633272000375,
        42.19025542261192
      ]
    },
    "47188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.113199162861565,
              41.59454199865696
            ]
          },
          "bbox": [
            -5.113199162861565,
            41.59454199865696,
            -5.113199162861565,
            41.59454199865696
          ]
        }
      ],
      "bbox": [
        -5.113199162861565,
        41.59454199865696,
        -5.113199162861565,
        41.59454199865696
      ]
    },
    "47189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9663931693140215,
              41.23076718698089
            ]
          },
          "bbox": [
            -4.9663931693140215,
            41.23076718698089,
            -4.9663931693140215,
            41.23076718698089
          ]
        }
      ],
      "bbox": [
        -4.9663931693140215,
        41.23076718698089,
        -4.9663931693140215,
        41.23076718698089
      ]
    },
    "47190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.995555636133784,
              41.56583890589116
            ]
          },
          "bbox": [
            -4.995555636133784,
            41.56583890589116,
            -4.995555636133784,
            41.56583890589116
          ]
        }
      ],
      "bbox": [
        -4.995555636133784,
        41.56583890589116,
        -4.995555636133784,
        41.56583890589116
      ]
    },
    "47191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.949802820796927,
              41.58298079670097
            ]
          },
          "bbox": [
            -4.949802820796927,
            41.58298079670097,
            -4.949802820796927,
            41.58298079670097
          ]
        }
      ],
      "bbox": [
        -4.949802820796927,
        41.58298079670097,
        -4.949802820796927,
        41.58298079670097
      ]
    },
    "47192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.829845333483822,
              41.418051628080306
            ]
          },
          "bbox": [
            -4.829845333483822,
            41.418051628080306,
            -4.829845333483822,
            41.418051628080306
          ]
        }
      ],
      "bbox": [
        -4.829845333483822,
        41.418051628080306,
        -4.829845333483822,
        41.418051628080306
      ]
    },
    "47193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.761763047561327,
              41.51584659563539
            ]
          },
          "bbox": [
            -4.761763047561327,
            41.51584659563539,
            -4.761763047561327,
            41.51584659563539
          ]
        }
      ],
      "bbox": [
        -4.761763047561327,
        41.51584659563539,
        -4.761763047561327,
        41.51584659563539
      ]
    },
    "47194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.3864092462083635,
              41.454585673126495
            ]
          },
          "bbox": [
            -4.3864092462083635,
            41.454585673126495,
            -4.3864092462083635,
            41.454585673126495
          ]
        }
      ],
      "bbox": [
        -4.3864092462083635,
        41.454585673126495,
        -4.3864092462083635,
        41.454585673126495
      ]
    },
    "47195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.517387582529741,
              41.62775697728271
            ]
          },
          "bbox": [
            -4.517387582529741,
            41.62775697728271,
            -4.517387582529741,
            41.62775697728271
          ]
        }
      ],
      "bbox": [
        -4.517387582529741,
        41.62775697728271,
        -4.517387582529741,
        41.62775697728271
      ]
    },
    "47196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.992400642248436,
              42.013913763607036
            ]
          },
          "bbox": [
            -4.992400642248436,
            42.013913763607036,
            -4.992400642248436,
            42.013913763607036
          ]
        }
      ],
      "bbox": [
        -4.992400642248436,
        42.013913763607036,
        -4.992400642248436,
        42.013913763607036
      ]
    },
    "47197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.105304592991805,
              41.83294851984185
            ]
          },
          "bbox": [
            -5.105304592991805,
            41.83294851984185,
            -5.105304592991805,
            41.83294851984185
          ]
        }
      ],
      "bbox": [
        -5.105304592991805,
        41.83294851984185,
        -5.105304592991805,
        41.83294851984185
      ]
    },
    "47198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.041639864770712,
              42.1956257381176
            ]
          },
          "bbox": [
            -5.041639864770712,
            42.1956257381176,
            -5.041639864770712,
            42.1956257381176
          ]
        }
      ],
      "bbox": [
        -5.041639864770712,
        42.1956257381176,
        -5.041639864770712,
        42.1956257381176
      ]
    },
    "47199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.12348637131718,
              42.08031237922573
            ]
          },
          "bbox": [
            -5.12348637131718,
            42.08031237922573,
            -5.12348637131718,
            42.08031237922573
          ]
        }
      ],
      "bbox": [
        -5.12348637131718,
        42.08031237922573,
        -5.12348637131718,
        42.08031237922573
      ]
    },
    "47200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.267811286833293,
              41.73506576972994
            ]
          },
          "bbox": [
            -4.267811286833293,
            41.73506576972994,
            -4.267811286833293,
            41.73506576972994
          ]
        }
      ],
      "bbox": [
        -4.267811286833293,
        41.73506576972994,
        -4.267811286833293,
        41.73506576972994
      ]
    },
    "47203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.956918261147316,
              42.07665833197646
            ]
          },
          "bbox": [
            -4.956918261147316,
            42.07665833197646,
            -4.956918261147316,
            42.07665833197646
          ]
        }
      ],
      "bbox": [
        -4.956918261147316,
        42.07665833197646,
        -4.956918261147316,
        42.07665833197646
      ]
    },
    "47204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.315086189011366,
              41.43424433776529
            ]
          },
          "bbox": [
            -5.315086189011366,
            41.43424433776529,
            -5.315086189011366,
            41.43424433776529
          ]
        }
      ],
      "bbox": [
        -5.315086189011366,
        41.43424433776529,
        -5.315086189011366,
        41.43424433776529
      ]
    },
    "47205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.212444811861449,
              41.896288538414154
            ]
          },
          "bbox": [
            -5.212444811861449,
            41.896288538414154,
            -5.212444811861449,
            41.896288538414154
          ]
        }
      ],
      "bbox": [
        -5.212444811861449,
        41.896288538414154,
        -5.212444811861449,
        41.896288538414154
      ]
    },
    "47206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.319129869767056,
              41.7247470608819
            ]
          },
          "bbox": [
            -4.319129869767056,
            41.7247470608819,
            -4.319129869767056,
            41.7247470608819
          ]
        }
      ],
      "bbox": [
        -4.319129869767056,
        41.7247470608819,
        -4.319129869767056,
        41.7247470608819
      ]
    },
    "47207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1910817228277875,
              41.77719117842283
            ]
          },
          "bbox": [
            -5.1910817228277875,
            41.77719117842283,
            -5.1910817228277875,
            41.77719117842283
          ]
        }
      ],
      "bbox": [
        -5.1910817228277875,
        41.77719117842283,
        -5.1910817228277875,
        41.77719117842283
      ]
    },
    "47208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.150015966705483,
              42.15047097481586
            ]
          },
          "bbox": [
            -5.150015966705483,
            42.15047097481586,
            -5.150015966705483,
            42.15047097481586
          ]
        }
      ],
      "bbox": [
        -5.150015966705483,
        42.15047097481586,
        -5.150015966705483,
        42.15047097481586
      ]
    },
    "47209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.210928620577066,
              42.016295039116784
            ]
          },
          "bbox": [
            -5.210928620577066,
            42.016295039116784,
            -5.210928620577066,
            42.016295039116784
          ]
        }
      ],
      "bbox": [
        -5.210928620577066,
        42.016295039116784,
        -5.210928620577066,
        42.016295039116784
      ]
    },
    "47210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.15156624833828,
              41.53143985000851
            ]
          },
          "bbox": [
            -5.15156624833828,
            41.53143985000851,
            -5.15156624833828,
            41.53143985000851
          ]
        }
      ],
      "bbox": [
        -5.15156624833828,
        41.53143985000851,
        -5.15156624833828,
        41.53143985000851
      ]
    },
    "47211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.195223853819994,
              42.1757959273895
            ]
          },
          "bbox": [
            -5.195223853819994,
            42.1757959273895,
            -5.195223853819994,
            42.1757959273895
          ]
        }
      ],
      "bbox": [
        -5.195223853819994,
        42.1757959273895,
        -5.195223853819994,
        42.1757959273895
      ]
    },
    "47212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.863702502504388,
              41.84142843128277
            ]
          },
          "bbox": [
            -4.863702502504388,
            41.84142843128277,
            -4.863702502504388,
            41.84142843128277
          ]
        }
      ],
      "bbox": [
        -4.863702502504388,
        41.84142843128277,
        -4.863702502504388,
        41.84142843128277
      ]
    },
    "47213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.207793117696454,
              41.60556858808594
            ]
          },
          "bbox": [
            -5.207793117696454,
            41.60556858808594,
            -5.207793117696454,
            41.60556858808594
          ]
        }
      ],
      "bbox": [
        -5.207793117696454,
        41.60556858808594,
        -5.207793117696454,
        41.60556858808594
      ]
    },
    "47214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0309287345805105,
              42.108544444830876
            ]
          },
          "bbox": [
            -5.0309287345805105,
            42.108544444830876,
            -5.0309287345805105,
            42.108544444830876
          ]
        }
      ],
      "bbox": [
        -5.0309287345805105,
        42.108544444830876,
        -5.0309287345805105,
        42.108544444830876
      ]
    },
    "47215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.201926355738221,
              41.94304565636673
            ]
          },
          "bbox": [
            -5.201926355738221,
            41.94304565636673,
            -5.201926355738221,
            41.94304565636673
          ]
        }
      ],
      "bbox": [
        -5.201926355738221,
        41.94304565636673,
        -5.201926355738221,
        41.94304565636673
      ]
    },
    "47216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.927008494844434,
              41.59757005281339
            ]
          },
          "bbox": [
            -4.927008494844434,
            41.59757005281339,
            -4.927008494844434,
            41.59757005281339
          ]
        }
      ],
      "bbox": [
        -4.927008494844434,
        41.59757005281339,
        -4.927008494844434,
        41.59757005281339
      ]
    },
    "47217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.84364819503609,
              41.70203706403802
            ]
          },
          "bbox": [
            -4.84364819503609,
            41.70203706403802,
            -4.84364819503609,
            41.70203706403802
          ]
        }
      ],
      "bbox": [
        -4.84364819503609,
        41.70203706403802,
        -4.84364819503609,
        41.70203706403802
      ]
    },
    "47218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.854337078813018,
              41.50768319717492
            ]
          },
          "bbox": [
            -4.854337078813018,
            41.50768319717492,
            -4.854337078813018,
            41.50768319717492
          ]
        }
      ],
      "bbox": [
        -4.854337078813018,
        41.50768319717492,
        -4.854337078813018,
        41.50768319717492
      ]
    },
    "47219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.09689720799443,
              42.150008461994
            ]
          },
          "bbox": [
            -5.09689720799443,
            42.150008461994,
            -5.09689720799443,
            42.150008461994
          ]
        }
      ],
      "bbox": [
        -5.09689720799443,
        42.150008461994,
        -5.09689720799443,
        42.150008461994
      ]
    },
    "47220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.283913243461706,
              41.77427476773865
            ]
          },
          "bbox": [
            -5.283913243461706,
            41.77427476773865,
            -5.283913243461706,
            41.77427476773865
          ]
        }
      ],
      "bbox": [
        -5.283913243461706,
        41.77427476773865,
        -5.283913243461706,
        41.77427476773865
      ]
    },
    "47221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.481669439172879,
              41.70489839991258
            ]
          },
          "bbox": [
            -4.481669439172879,
            41.70489839991258,
            -4.481669439172879,
            41.70489839991258
          ]
        }
      ],
      "bbox": [
        -4.481669439172879,
        41.70489839991258,
        -4.481669439172879,
        41.70489839991258
      ]
    },
    "47222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0150031694523705,
              41.92662044560156
            ]
          },
          "bbox": [
            -5.0150031694523705,
            41.92662044560156,
            -5.0150031694523705,
            41.92662044560156
          ]
        }
      ],
      "bbox": [
        -5.0150031694523705,
        41.92662044560156,
        -5.0150031694523705,
        41.92662044560156
      ]
    },
    "47223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.251566161208103,
              41.723936630682054
            ]
          },
          "bbox": [
            -5.251566161208103,
            41.723936630682054,
            -5.251566161208103,
            41.723936630682054
          ]
        }
      ],
      "bbox": [
        -5.251566161208103,
        41.723936630682054,
        -5.251566161208103,
        41.723936630682054
      ]
    },
    "47224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.55203809493367,
              41.688412753763515
            ]
          },
          "bbox": [
            -4.55203809493367,
            41.688412753763515,
            -4.55203809493367,
            41.688412753763515
          ]
        }
      ],
      "bbox": [
        -4.55203809493367,
        41.688412753763515,
        -4.55203809493367,
        41.688412753763515
      ]
    },
    "47225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.064114850855993,
              41.63271083227877
            ]
          },
          "bbox": [
            -5.064114850855993,
            41.63271083227877,
            -5.064114850855993,
            41.63271083227877
          ]
        }
      ],
      "bbox": [
        -5.064114850855993,
        41.63271083227877,
        -5.064114850855993,
        41.63271083227877
      ]
    },
    "47226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.443624355815131,
              41.66024809874378
            ]
          },
          "bbox": [
            -4.443624355815131,
            41.66024809874378,
            -4.443624355815131,
            41.66024809874378
          ]
        }
      ],
      "bbox": [
        -4.443624355815131,
        41.66024809874378,
        -4.443624355815131,
        41.66024809874378
      ]
    },
    "47227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.2769575145206975,
              41.697506100137346
            ]
          },
          "bbox": [
            -5.2769575145206975,
            41.697506100137346,
            -5.2769575145206975,
            41.697506100137346
          ]
        }
      ],
      "bbox": [
        -5.2769575145206975,
        41.697506100137346,
        -5.2769575145206975,
        41.697506100137346
      ]
    },
    "47228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.022509085140639,
              41.317885453111636
            ]
          },
          "bbox": [
            -5.022509085140639,
            41.317885453111636,
            -5.022509085140639,
            41.317885453111636
          ]
        }
      ],
      "bbox": [
        -5.022509085140639,
        41.317885453111636,
        -5.022509085140639,
        41.317885453111636
      ]
    },
    "47229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.22834809960898,
              42.05276819249072
            ]
          },
          "bbox": [
            -5.22834809960898,
            42.05276819249072,
            -5.22834809960898,
            42.05276819249072
          ]
        }
      ],
      "bbox": [
        -5.22834809960898,
        42.05276819249072,
        -5.22834809960898,
        42.05276819249072
      ]
    },
    "47230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.913208695730424,
              41.67962374327691
            ]
          },
          "bbox": [
            -4.913208695730424,
            41.67962374327691,
            -4.913208695730424,
            41.67962374327691
          ]
        }
      ],
      "bbox": [
        -4.913208695730424,
        41.67962374327691,
        -4.913208695730424,
        41.67962374327691
      ]
    },
    "47231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7978531796493575,
              41.654527607880645
            ]
          },
          "bbox": [
            -4.7978531796493575,
            41.654527607880645,
            -4.7978531796493575,
            41.654527607880645
          ]
        }
      ],
      "bbox": [
        -4.7978531796493575,
        41.654527607880645,
        -4.7978531796493575,
        41.654527607880645
      ]
    },
    "47232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7702206037224775,
              41.26737477279357
            ]
          },
          "bbox": [
            -4.7702206037224775,
            41.26737477279357,
            -4.7702206037224775,
            41.26737477279357
          ]
        }
      ],
      "bbox": [
        -4.7702206037224775,
        41.26737477279357,
        -4.7702206037224775,
        41.26737477279357
      ]
    },
    "48001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.622342823443638,
              43.12579591055503
            ]
          },
          "bbox": [
            -2.622342823443638,
            43.12579591055503,
            -2.622342823443638,
            43.12579591055503
          ]
        }
      ],
      "bbox": [
        -2.622342823443638,
        43.12579591055503,
        -2.622342823443638,
        43.12579591055503
      ]
    },
    "48002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0887368285822348,
              43.30880676818311
            ]
          },
          "bbox": [
            -3.0887368285822348,
            43.30880676818311,
            -3.0887368285822348,
            43.30880676818311
          ]
        }
      ],
      "bbox": [
        -3.0887368285822348,
        43.30880676818311,
        -3.0887368285822348,
        43.30880676818311
      ]
    },
    "48003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7202037054493053,
              43.21535431062057
            ]
          },
          "bbox": [
            -2.7202037054493053,
            43.21535431062057,
            -2.7202037054493053,
            43.21535431062057
          ]
        }
      ],
      "bbox": [
        -2.7202037054493053,
        43.21535431062057,
        -2.7202037054493053,
        43.21535431062057
      ]
    },
    "48004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.511092818178666,
              43.32165702941824
            ]
          },
          "bbox": [
            -2.511092818178666,
            43.32165702941824,
            -2.511092818178666,
            43.32165702941824
          ]
        }
      ],
      "bbox": [
        -2.511092818178666,
        43.32165702941824,
        -2.511092818178666,
        43.32165702941824
      ]
    },
    "48005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9279056623068067,
              43.14917722220913
            ]
          },
          "bbox": [
            -2.9279056623068067,
            43.14917722220913,
            -2.9279056623068067,
            43.14917722220913
          ]
        }
      ],
      "bbox": [
        -2.9279056623068067,
        43.14917722220913,
        -2.9279056623068067,
        43.14917722220913
      ]
    },
    "48006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.792809892932471,
              43.15158989588818
            ]
          },
          "bbox": [
            -2.792809892932471,
            43.15158989588818,
            -2.792809892932471,
            43.15158989588818
          ]
        }
      ],
      "bbox": [
        -2.792809892932471,
        43.15158989588818,
        -2.792809892932471,
        43.15158989588818
      ]
    },
    "48007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5977514642820583,
              43.25864104187629
            ]
          },
          "bbox": [
            -2.5977514642820583,
            43.25864104187629,
            -2.5977514642820583,
            43.25864104187629
          ]
        }
      ],
      "bbox": [
        -2.5977514642820583,
        43.25864104187629,
        -2.5977514642820583,
        43.25864104187629
      ]
    },
    "48008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.233650479037342,
              43.23775708193264
            ]
          },
          "bbox": [
            -3.233650479037342,
            43.23775708193264,
            -3.233650479037342,
            43.23775708193264
          ]
        }
      ],
      "bbox": [
        -3.233650479037342,
        43.23775708193264,
        -3.233650479037342,
        43.23775708193264
      ]
    },
    "48009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9395509917581992,
              43.178681979379625
            ]
          },
          "bbox": [
            -2.9395509917581992,
            43.178681979379625,
            -2.9395509917581992,
            43.178681979379625
          ]
        }
      ],
      "bbox": [
        -2.9395509917581992,
        43.178681979379625,
        -2.9395509917581992,
        43.178681979379625
      ]
    },
    "48010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.763727779356793,
              43.34633351863055
            ]
          },
          "bbox": [
            -2.763727779356793,
            43.34633351863055,
            -2.763727779356793,
            43.34633351863055
          ]
        }
      ],
      "bbox": [
        -2.763727779356793,
        43.34633351863055,
        -2.763727779356793,
        43.34633351863055
      ]
    },
    "48011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.912854849541219,
              43.208589999691874
            ]
          },
          "bbox": [
            -2.912854849541219,
            43.208589999691874,
            -2.912854849541219,
            43.208589999691874
          ]
        }
      ],
      "bbox": [
        -2.912854849541219,
        43.208589999691874,
        -2.912854849541219,
        43.208589999691874
      ]
    },
    "48012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.813185861925285,
              43.42011774649909
            ]
          },
          "bbox": [
            -2.813185861925285,
            43.42011774649909,
            -2.813185861925285,
            43.42011774649909
          ]
        }
      ],
      "bbox": [
        -2.813185861925285,
        43.42011774649909,
        -2.813185861925285,
        43.42011774649909
      ]
    },
    "48013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0032701122639827,
              43.27266597908117
            ]
          },
          "bbox": [
            -3.0032701122639827,
            43.27266597908117,
            -3.0032701122639827,
            43.27266597908117
          ]
        }
      ],
      "bbox": [
        -3.0032701122639827,
        43.27266597908117,
        -3.0032701122639827,
        43.27266597908117
      ]
    },
    "48014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.962110989352386,
              43.39794325435228
            ]
          },
          "bbox": [
            -2.962110989352386,
            43.39794325435228,
            -2.962110989352386,
            43.39794325435228
          ]
        }
      ],
      "bbox": [
        -2.962110989352386,
        43.39794325435228,
        -2.962110989352386,
        43.39794325435228
      ]
    },
    "48015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8880230562762548,
              43.23132755727736
            ]
          },
          "bbox": [
            -2.8880230562762548,
            43.23132755727736,
            -2.8880230562762548,
            43.23132755727736
          ]
        }
      ],
      "bbox": [
        -2.8880230562762548,
        43.23132755727736,
        -2.8880230562762548,
        43.23132755727736
      ]
    },
    "48016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9812998008928115,
              43.35784241457004
            ]
          },
          "bbox": [
            -2.9812998008928115,
            43.35784241457004,
            -2.9812998008928115,
            43.35784241457004
          ]
        }
      ],
      "bbox": [
        -2.9812998008928115,
        43.35784241457004,
        -2.9812998008928115,
        43.35784241457004
      ]
    },
    "48017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.754922001142155,
              43.413474770593325
            ]
          },
          "bbox": [
            -2.754922001142155,
            43.413474770593325,
            -2.754922001142155,
            43.413474770593325
          ]
        }
      ],
      "bbox": [
        -2.754922001142155,
        43.413474770593325,
        -2.754922001142155,
        43.413474770593325
      ]
    },
    "48018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4618994473755893,
              43.31510529035213
            ]
          },
          "bbox": [
            -2.4618994473755893,
            43.31510529035213,
            -2.4618994473755893,
            43.31510529035213
          ]
        }
      ],
      "bbox": [
        -2.4618994473755893,
        43.31510529035213,
        -2.4618994473755893,
        43.31510529035213
      ]
    },
    "48019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5869970480201046,
              43.192564951443835
            ]
          },
          "bbox": [
            -2.5869970480201046,
            43.192564951443835,
            -2.5869970480201046,
            43.192564951443835
          ]
        }
      ],
      "bbox": [
        -2.5869970480201046,
        43.192564951443835,
        -2.5869970480201046,
        43.192564951443835
      ]
    },
    "48020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9373646672707108,
              43.254038855643294
            ]
          },
          "bbox": [
            -2.9373646672707108,
            43.254038855643294,
            -2.9373646672707108,
            43.254038855643294
          ]
        }
      ],
      "bbox": [
        -2.9373646672707108,
        43.254038855643294,
        -2.9373646672707108,
        43.254038855643294
      ]
    },
    "48021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7182282041251575,
              43.372231514180704
            ]
          },
          "bbox": [
            -2.7182282041251575,
            43.372231514180704,
            -2.7182282041251575,
            43.372231514180704
          ]
        }
      ],
      "bbox": [
        -2.7182282041251575,
        43.372231514180704,
        -2.7182282041251575,
        43.372231514180704
      ]
    },
    "48022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3650264655044873,
              43.20905662674052
            ]
          },
          "bbox": [
            -3.3650264655044873,
            43.20905662674052,
            -3.3650264655044873,
            43.20905662674052
          ]
        }
      ],
      "bbox": [
        -3.3650264655044873,
        43.20905662674052,
        -3.3650264655044873,
        43.20905662674052
      ]
    },
    "48023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8004839185462904,
              43.12594593654363
            ]
          },
          "bbox": [
            -2.8004839185462904,
            43.12594593654363,
            -2.8004839185462904,
            43.12594593654363
          ]
        }
      ],
      "bbox": [
        -2.8004839185462904,
        43.12594593654363,
        -2.8004839185462904,
        43.12594593654363
      ]
    },
    "48024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7470582975841236,
              43.06776193700362
            ]
          },
          "bbox": [
            -2.7470582975841236,
            43.06776193700362,
            -2.7470582975841236,
            43.06776193700362
          ]
        }
      ],
      "bbox": [
        -2.7470582975841236,
        43.06776193700362,
        -2.7470582975841236,
        43.06776193700362
      ]
    },
    "48025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8551617405200185,
              43.15087445028938
            ]
          },
          "bbox": [
            -2.8551617405200185,
            43.15087445028938,
            -2.8551617405200185,
            43.15087445028938
          ]
        }
      ],
      "bbox": [
        -2.8551617405200185,
        43.15087445028938,
        -2.8551617405200185,
        43.15087445028938
      ]
    },
    "48026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.715811028823566,
              43.12079720408818
            ]
          },
          "bbox": [
            -2.715811028823566,
            43.12079720408818,
            -2.715811028823566,
            43.12079720408818
          ]
        }
      ],
      "bbox": [
        -2.715811028823566,
        43.12079720408818,
        -2.715811028823566,
        43.12079720408818
      ]
    },
    "48027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.65357027861128,
              43.1606054942951
            ]
          },
          "bbox": [
            -2.65357027861128,
            43.1606054942951,
            -2.65357027861128,
            43.1606054942951
          ]
        }
      ],
      "bbox": [
        -2.65357027861128,
        43.1606054942951,
        -2.65357027861128,
        43.1606054942951
      ]
    },
    "48028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5894159729351163,
              43.377936235835314
            ]
          },
          "bbox": [
            -2.5894159729351163,
            43.377936235835314,
            -2.5894159729351163,
            43.377936235835314
          ]
        }
      ],
      "bbox": [
        -2.5894159729351163,
        43.377936235835314,
        -2.5894159729351163,
        43.377936235835314
      ]
    },
    "48029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.885732869544225,
              43.25178868634175
            ]
          },
          "bbox": [
            -2.885732869544225,
            43.25178868634175,
            -2.885732869544225,
            43.25178868634175
          ]
        }
      ],
      "bbox": [
        -2.885732869544225,
        43.25178868634175,
        -2.885732869544225,
        43.25178868634175
      ]
    },
    "48030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4702930474685427,
              43.25013737878299
            ]
          },
          "bbox": [
            -2.4702930474685427,
            43.25013737878299,
            -2.4702930474685427,
            43.25013737878299
          ]
        }
      ],
      "bbox": [
        -2.4702930474685427,
        43.25013737878299,
        -2.4702930474685427,
        43.25013737878299
      ]
    },
    "48031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6430183767859825,
              43.40755605250498
            ]
          },
          "bbox": [
            -2.6430183767859825,
            43.40755605250498,
            -2.6430183767859825,
            43.40755605250498
          ]
        }
      ],
      "bbox": [
        -2.6430183767859825,
        43.40755605250498,
        -2.6430183767859825,
        43.40755605250498
      ]
    },
    "48032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5350973226635807,
              43.12553717379579
            ]
          },
          "bbox": [
            -2.5350973226635807,
            43.12553717379579,
            -2.5350973226635807,
            43.12553717379579
          ]
        }
      ],
      "bbox": [
        -2.5350973226635807,
        43.12553717379579,
        -2.5350973226635807,
        43.12553717379579
      ]
    },
    "48033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.610857828523347,
              43.351169433509426
            ]
          },
          "bbox": [
            -2.610857828523347,
            43.351169433509426,
            -2.610857828523347,
            43.351169433509426
          ]
        }
      ],
      "bbox": [
        -2.610857828523347,
        43.351169433509426,
        -2.610857828523347,
        43.351169433509426
      ]
    },
    "48034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.503771835818563,
              43.18882410250276
            ]
          },
          "bbox": [
            -2.503771835818563,
            43.18882410250276,
            -2.503771835818563,
            43.18882410250276
          ]
        }
      ],
      "bbox": [
        -2.503771835818563,
        43.18882410250276,
        -2.503771835818563,
        43.18882410250276
      ]
    },
    "48035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.791732597483128,
              43.32577520470921
            ]
          },
          "bbox": [
            -2.791732597483128,
            43.32577520470921,
            -2.791732597483128,
            43.32577520470921
          ]
        }
      ],
      "bbox": [
        -2.791732597483128,
        43.32577520470921,
        -2.791732597483128,
        43.32577520470921
      ]
    },
    "48036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8347312419260957,
              43.23068671546061
            ]
          },
          "bbox": [
            -2.8347312419260957,
            43.23068671546061,
            -2.8347312419260957,
            43.23068671546061
          ]
        }
      ],
      "bbox": [
        -2.8347312419260957,
        43.23068671546061,
        -2.8347312419260957,
        43.23068671546061
      ]
    },
    "48037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0988509746288093,
              43.26250085257885
            ]
          },
          "bbox": [
            -3.0988509746288093,
            43.26250085257885,
            -3.0988509746288093,
            43.26250085257885
          ]
        }
      ],
      "bbox": [
        -3.0988509746288093,
        43.26250085257885,
        -3.0988509746288093,
        43.26250085257885
      ]
    },
    "48038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.81226950542273,
              43.31325031004237
            ]
          },
          "bbox": [
            -2.81226950542273,
            43.31325031004237,
            -2.81226950542273,
            43.31325031004237
          ]
        }
      ],
      "bbox": [
        -2.81226950542273,
        43.31325031004237,
        -2.81226950542273,
        43.31325031004237
      ]
    },
    "48039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6210438893585084,
              43.196658836026536
            ]
          },
          "bbox": [
            -2.6210438893585084,
            43.196658836026536,
            -2.6210438893585084,
            43.196658836026536
          ]
        }
      ],
      "bbox": [
        -2.6210438893585084,
        43.196658836026536,
        -2.6210438893585084,
        43.196658836026536
      ]
    },
    "48040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8935147166063793,
              43.365041507155794
            ]
          },
          "bbox": [
            -2.8935147166063793,
            43.365041507155794,
            -2.8935147166063793,
            43.365041507155794
          ]
        }
      ],
      "bbox": [
        -2.8935147166063793,
        43.365041507155794,
        -2.8935147166063793,
        43.365041507155794
      ]
    },
    "48041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6584402213122686,
              43.36663951395447
            ]
          },
          "bbox": [
            -2.6584402213122686,
            43.36663951395447,
            -2.6584402213122686,
            43.36663951395447
          ]
        }
      ],
      "bbox": [
        -2.6584402213122686,
        43.36663951395447,
        -2.6584402213122686,
        43.36663951395447
      ]
    },
    "48042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0801393122826375,
              43.17285149932541
            ]
          },
          "bbox": [
            -3.0801393122826375,
            43.17285149932541,
            -3.0801393122826375,
            43.17285149932541
          ]
        }
      ],
      "bbox": [
        -3.0801393122826375,
        43.17285149932541,
        -3.0801393122826375,
        43.17285149932541
      ]
    },
    "48043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.932766792857696,
              43.41765549008289
            ]
          },
          "bbox": [
            -2.932766792857696,
            43.41765549008289,
            -2.932766792857696,
            43.41765549008289
          ]
        }
      ],
      "bbox": [
        -2.932766792857696,
        43.41765549008289,
        -2.932766792857696,
        43.41765549008289
      ]
    },
    "48044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.010061858630937,
              43.35593830699433
            ]
          },
          "bbox": [
            -3.010061858630937,
            43.35593830699433,
            -3.010061858630937,
            43.35593830699433
          ]
        }
      ],
      "bbox": [
        -3.010061858630937,
        43.35593830699433,
        -3.010061858630937,
        43.35593830699433
      ]
    },
    "48045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0597754525495353,
              43.21744265137823
            ]
          },
          "bbox": [
            -3.0597754525495353,
            43.21744265137823,
            -3.0597754525495353,
            43.21744265137823
          ]
        }
      ],
      "bbox": [
        -3.0597754525495353,
        43.21744265137823,
        -3.0597754525495353,
        43.21744265137823
      ]
    },
    "48046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.691965045485207,
              43.312585804428174
            ]
          },
          "bbox": [
            -2.691965045485207,
            43.312585804428174,
            -2.691965045485207,
            43.312585804428174
          ]
        }
      ],
      "bbox": [
        -2.691965045485207,
        43.312585804428174,
        -2.691965045485207,
        43.312585804428174
      ]
    },
    "48047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5405172756610424,
              43.330706083026996
            ]
          },
          "bbox": [
            -2.5405172756610424,
            43.330706083026996,
            -2.5405172756610424,
            43.330706083026996
          ]
        }
      ],
      "bbox": [
        -2.5405172756610424,
        43.330706083026996,
        -2.5405172756610424,
        43.330706083026996
      ]
    },
    "48048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.653019810907804,
              43.39260709214659
            ]
          },
          "bbox": [
            -2.653019810907804,
            43.39260709214659,
            -2.653019810907804,
            43.39260709214659
          ]
        }
      ],
      "bbox": [
        -2.653019810907804,
        43.39260709214659,
        -2.653019810907804,
        43.39260709214659
      ]
    },
    "48049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5484269754852242,
              43.35625112043064
            ]
          },
          "bbox": [
            -2.5484269754852242,
            43.35625112043064,
            -2.5484269754852242,
            43.35625112043064
          ]
        }
      ],
      "bbox": [
        -2.5484269754852242,
        43.35625112043064,
        -2.5484269754852242,
        43.35625112043064
      ]
    },
    "48050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.647073975611905,
              43.14878752901901
            ]
          },
          "bbox": [
            -2.647073975611905,
            43.14878752901901,
            -2.647073975611905,
            43.14878752901901
          ]
        }
      ],
      "bbox": [
        -2.647073975611905,
        43.14878752901901,
        -2.647073975611905,
        43.14878752901901
      ]
    },
    "48051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4403120654454846,
              43.21982341627645
            ]
          },
          "bbox": [
            -3.4403120654454846,
            43.21982341627645,
            -3.4403120654454846,
            43.21982341627645
          ]
        }
      ],
      "bbox": [
        -3.4403120654454846,
        43.21982341627645,
        -3.4403120654454846,
        43.21982341627645
      ]
    },
    "48052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7838079286117896,
              43.26728464707631
            ]
          },
          "bbox": [
            -2.7838079286117896,
            43.26728464707631,
            -2.7838079286117896,
            43.26728464707631
          ]
        }
      ],
      "bbox": [
        -2.7838079286117896,
        43.26728464707631,
        -2.7838079286117896,
        43.26728464707631
      ]
    },
    "48053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.91602117605037,
              43.34878090841912
            ]
          },
          "bbox": [
            -2.91602117605037,
            43.34878090841912,
            -2.91602117605037,
            43.34878090841912
          ]
        }
      ],
      "bbox": [
        -2.91602117605037,
        43.34878090841912,
        -2.91602117605037,
        43.34878090841912
      ]
    },
    "48054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9858325894560283,
              43.32906121018819
            ]
          },
          "bbox": [
            -2.9858325894560283,
            43.32906121018819,
            -2.9858325894560283,
            43.32906121018819
          ]
        }
      ],
      "bbox": [
        -2.9858325894560283,
        43.32906121018819,
        -2.9858325894560283,
        43.32906121018819
      ]
    },
    "48055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.770447561269212,
              43.20444246734728
            ]
          },
          "bbox": [
            -2.770447561269212,
            43.20444246734728,
            -2.770447561269212,
            43.20444246734728
          ]
        }
      ],
      "bbox": [
        -2.770447561269212,
        43.20444246734728,
        -2.770447561269212,
        43.20444246734728
      ]
    },
    "48056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8902462570777354,
              43.41737665435804
            ]
          },
          "bbox": [
            -2.8902462570777354,
            43.41737665435804,
            -2.8902462570777354,
            43.41737665435804
          ]
        }
      ],
      "bbox": [
        -2.8902462570777354,
        43.41737665435804,
        -2.8902462570777354,
        43.41737665435804
      ]
    },
    "48057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.507253167206251,
              43.36641298943596
            ]
          },
          "bbox": [
            -2.507253167206251,
            43.36641298943596,
            -2.507253167206251,
            43.36641298943596
          ]
        }
      ],
      "bbox": [
        -2.507253167206251,
        43.36641298943596,
        -2.507253167206251,
        43.36641298943596
      ]
    },
    "48058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.539357343311586,
              43.20590575123173
            ]
          },
          "bbox": [
            -2.539357343311586,
            43.20590575123173,
            -2.539357343311586,
            43.20590575123173
          ]
        }
      ],
      "bbox": [
        -2.539357343311586,
        43.20590575123173,
        -2.539357343311586,
        43.20590575123173
      ]
    },
    "48059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.666918151725867,
              43.12647488453184
            ]
          },
          "bbox": [
            -2.666918151725867,
            43.12647488453184,
            -2.666918151725867,
            43.12647488453184
          ]
        }
      ],
      "bbox": [
        -2.666918151725867,
        43.12647488453184,
        -2.666918151725867,
        43.12647488453184
      ]
    },
    "48060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.48754491940894,
              43.26495336332153
            ]
          },
          "bbox": [
            -2.48754491940894,
            43.26495336332153,
            -2.48754491940894,
            43.26495336332153
          ]
        }
      ],
      "bbox": [
        -2.48754491940894,
        43.26495336332153,
        -2.48754491940894,
        43.26495336332153
      ]
    },
    "48061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8685150732048657,
              43.39548937270586
            ]
          },
          "bbox": [
            -2.8685150732048657,
            43.39548937270586,
            -2.8685150732048657,
            43.39548937270586
          ]
        }
      ],
      "bbox": [
        -2.8685150732048657,
        43.39548937270586,
        -2.8685150732048657,
        43.39548937270586
      ]
    },
    "48062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6354723223713425,
              43.278116941961066
            ]
          },
          "bbox": [
            -2.6354723223713425,
            43.278116941961066,
            -2.6354723223713425,
            43.278116941961066
          ]
        }
      ],
      "bbox": [
        -2.6354723223713425,
        43.278116941961066,
        -2.6354723223713425,
        43.278116941961066
      ]
    },
    "48063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.487307173038782,
              43.3472269026093
            ]
          },
          "bbox": [
            -2.487307173038782,
            43.3472269026093,
            -2.487307173038782,
            43.3472269026093
          ]
        }
      ],
      "bbox": [
        -2.487307173038782,
        43.3472269026093,
        -2.487307173038782,
        43.3472269026093
      ]
    },
    "48064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7894938991099325,
              43.36710395465439
            ]
          },
          "bbox": [
            -2.7894938991099325,
            43.36710395465439,
            -2.7894938991099325,
            43.36710395465439
          ]
        }
      ],
      "bbox": [
        -2.7894938991099325,
        43.36710395465439,
        -2.7894938991099325,
        43.36710395465439
      ]
    },
    "48065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9040650729667403,
              43.18246863090097
            ]
          },
          "bbox": [
            -2.9040650729667403,
            43.18246863090097,
            -2.9040650729667403,
            43.18246863090097
          ]
        }
      ],
      "bbox": [
        -2.9040650729667403,
        43.18246863090097,
        -2.9040650729667403,
        43.18246863090097
      ]
    },
    "48066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7599827983758054,
              43.30082965431673
            ]
          },
          "bbox": [
            -2.7599827983758054,
            43.30082965431673,
            -2.7599827983758054,
            43.30082965431673
          ]
        }
      ],
      "bbox": [
        -2.7599827983758054,
        43.30082965431673,
        -2.7599827983758054,
        43.30082965431673
      ]
    },
    "48067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6860902975560674,
              43.25887067028732
            ]
          },
          "bbox": [
            -2.6860902975560674,
            43.25887067028732,
            -2.6860902975560674,
            43.25887067028732
          ]
        }
      ],
      "bbox": [
        -2.6860902975560674,
        43.25887067028732,
        -2.6860902975560674,
        43.25887067028732
      ]
    },
    "48068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.708972251588746,
              43.40395674129746
            ]
          },
          "bbox": [
            -2.708972251588746,
            43.40395674129746,
            -2.708972251588746,
            43.40395674129746
          ]
        }
      ],
      "bbox": [
        -2.708972251588746,
        43.40395674129746,
        -2.708972251588746,
        43.40395674129746
      ]
    },
    "48069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8358892168435657,
              43.36082640049764
            ]
          },
          "bbox": [
            -2.8358892168435657,
            43.36082640049764,
            -2.8358892168435657,
            43.36082640049764
          ]
        }
      ],
      "bbox": [
        -2.8358892168435657,
        43.36082640049764,
        -2.8358892168435657,
        43.36082640049764
      ]
    },
    "48070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.55677657546244,
              43.29474813753667
            ]
          },
          "bbox": [
            -2.55677657546244,
            43.29474813753667,
            -2.55677657546244,
            43.29474813753667
          ]
        }
      ],
      "bbox": [
        -2.55677657546244,
        43.29474813753667,
        -2.55677657546244,
        43.29474813753667
      ]
    },
    "48071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.129594024403731,
              43.325347955069546
            ]
          },
          "bbox": [
            -3.129594024403731,
            43.325347955069546,
            -3.129594024403731,
            43.325347955069546
          ]
        }
      ],
      "bbox": [
        -3.129594024403731,
        43.325347955069546,
        -3.129594024403731,
        43.325347955069546
      ]
    },
    "48072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6548049091116264,
              43.04188514345455
            ]
          },
          "bbox": [
            -2.6548049091116264,
            43.04188514345455,
            -2.6548049091116264,
            43.04188514345455
          ]
        }
      ],
      "bbox": [
        -2.6548049091116264,
        43.04188514345455,
        -2.6548049091116264,
        43.04188514345455
      ]
    },
    "48073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.433516587223913,
              43.31977581847793
            ]
          },
          "bbox": [
            -2.433516587223913,
            43.31977581847793,
            -2.433516587223913,
            43.31977581847793
          ]
        }
      ],
      "bbox": [
        -2.433516587223913,
        43.31977581847793,
        -2.433516587223913,
        43.31977581847793
      ]
    },
    "48074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0314122035628026,
              43.00930714445458
            ]
          },
          "bbox": [
            -3.0314122035628026,
            43.00930714445458,
            -3.0314122035628026,
            43.00930714445458
          ]
        }
      ],
      "bbox": [
        -3.0314122035628026,
        43.00930714445458,
        -3.0314122035628026,
        43.00930714445458
      ]
    },
    "48075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.878157412235134,
              43.08799014202221
            ]
          },
          "bbox": [
            -2.878157412235134,
            43.08799014202221,
            -2.878157412235134,
            43.08799014202221
          ]
        }
      ],
      "bbox": [
        -2.878157412235134,
        43.08799014202221,
        -2.878157412235134,
        43.08799014202221
      ]
    },
    "48076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.700365839744282,
              43.393984400242665
            ]
          },
          "bbox": [
            -2.700365839744282,
            43.393984400242665,
            -2.700365839744282,
            43.393984400242665
          ]
        }
      ],
      "bbox": [
        -2.700365839744282,
        43.393984400242665,
        -2.700365839744282,
        43.393984400242665
      ]
    },
    "48077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9331731379136494,
              43.39232442468608
            ]
          },
          "bbox": [
            -2.9331731379136494,
            43.39232442468608,
            -2.9331731379136494,
            43.39232442468608
          ]
        }
      ],
      "bbox": [
        -2.9331731379136494,
        43.39232442468608,
        -2.9331731379136494,
        43.39232442468608
      ]
    },
    "48078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0220502890002887,
              43.316353560479875
            ]
          },
          "bbox": [
            -3.0220502890002887,
            43.316353560479875,
            -3.0220502890002887,
            43.316353560479875
          ]
        }
      ],
      "bbox": [
        -3.0220502890002887,
        43.316353560479875,
        -3.0220502890002887,
        43.316353560479875
      ]
    },
    "48079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.729701291385712,
              43.328194644465405
            ]
          },
          "bbox": [
            -2.729701291385712,
            43.328194644465405,
            -2.729701291385712,
            43.328194644465405
          ]
        }
      ],
      "bbox": [
        -2.729701291385712,
        43.328194644465405,
        -2.729701291385712,
        43.328194644465405
      ]
    },
    "48080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.03682514656979,
              43.28949303040226
            ]
          },
          "bbox": [
            -3.03682514656979,
            43.28949303040226,
            -3.03682514656979,
            43.28949303040226
          ]
        }
      ],
      "bbox": [
        -3.03682514656979,
        43.28949303040226,
        -3.03682514656979,
        43.28949303040226
      ]
    },
    "48081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8294772637274828,
              43.275893240210806
            ]
          },
          "bbox": [
            -2.8294772637274828,
            43.275893240210806,
            -2.8294772637274828,
            43.275893240210806
          ]
        }
      ],
      "bbox": [
        -2.8294772637274828,
        43.275893240210806,
        -2.8294772637274828,
        43.275893240210806
      ]
    },
    "48082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0483062579990103,
              43.33341472410271
            ]
          },
          "bbox": [
            -3.0483062579990103,
            43.33341472410271,
            -3.0483062579990103,
            43.33341472410271
          ]
        }
      ],
      "bbox": [
        -3.0483062579990103,
        43.33341472410271,
        -3.0483062579990103,
        43.33341472410271
      ]
    },
    "48083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0570824525515445,
              43.30260046747173
            ]
          },
          "bbox": [
            -3.0570824525515445,
            43.30260046747173,
            -3.0570824525515445,
            43.30260046747173
          ]
        }
      ],
      "bbox": [
        -3.0570824525515445,
        43.30260046747173,
        -3.0570824525515445,
        43.30260046747173
      ]
    },
    "48084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.999300750953664,
              43.30821296127393
            ]
          },
          "bbox": [
            -2.999300750953664,
            43.30821296127393,
            -2.999300750953664,
            43.30821296127393
          ]
        }
      ],
      "bbox": [
        -2.999300750953664,
        43.30821296127393,
        -2.999300750953664,
        43.30821296127393
      ]
    },
    "48085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9794578135609617,
              43.37797257526593
            ]
          },
          "bbox": [
            -2.9794578135609617,
            43.37797257526593,
            -2.9794578135609617,
            43.37797257526593
          ]
        }
      ],
      "bbox": [
        -2.9794578135609617,
        43.37797257526593,
        -2.9794578135609617,
        43.37797257526593
      ]
    },
    "48086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1732845437036943,
              43.26029570865248
            ]
          },
          "bbox": [
            -3.1732845437036943,
            43.26029570865248,
            -3.1732845437036943,
            43.26029570865248
          ]
        }
      ],
      "bbox": [
        -3.1732845437036943,
        43.26029570865248,
        -3.1732845437036943,
        43.26029570865248
      ]
    },
    "48087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.28504660489964,
              43.28043209036601
            ]
          },
          "bbox": [
            -3.28504660489964,
            43.28043209036601,
            -3.28504660489964,
            43.28043209036601
          ]
        }
      ],
      "bbox": [
        -3.28504660489964,
        43.28043209036601,
        -3.28504660489964,
        43.28043209036601
      ]
    },
    "48088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6907476313898884,
              43.03014830898104
            ]
          },
          "bbox": [
            -2.6907476313898884,
            43.03014830898104,
            -2.6907476313898884,
            43.03014830898104
          ]
        }
      ],
      "bbox": [
        -2.6907476313898884,
        43.03014830898104,
        -2.6907476313898884,
        43.03014830898104
      ]
    },
    "48089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.944871878657019,
              43.36678356294478
            ]
          },
          "bbox": [
            -2.944871878657019,
            43.36678356294478,
            -2.944871878657019,
            43.36678356294478
          ]
        }
      ],
      "bbox": [
        -2.944871878657019,
        43.36678356294478,
        -2.944871878657019,
        43.36678356294478
      ]
    },
    "48090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2108570365457534,
              43.19447058724743
            ]
          },
          "bbox": [
            -3.2108570365457534,
            43.19447058724743,
            -3.2108570365457534,
            43.19447058724743
          ]
        }
      ],
      "bbox": [
        -3.2108570365457534,
        43.19447058724743,
        -3.2108570365457534,
        43.19447058724743
      ]
    },
    "48091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.586666002286554,
              43.105459111797025
            ]
          },
          "bbox": [
            -2.586666002286554,
            43.105459111797025,
            -2.586666002286554,
            43.105459111797025
          ]
        }
      ],
      "bbox": [
        -2.586666002286554,
        43.105459111797025,
        -2.586666002286554,
        43.105459111797025
      ]
    },
    "48092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.817505172004503,
              43.19865744476977
            ]
          },
          "bbox": [
            -2.817505172004503,
            43.19865744476977,
            -2.817505172004503,
            43.19865744476977
          ]
        }
      ],
      "bbox": [
        -2.817505172004503,
        43.19865744476977,
        -2.817505172004503,
        43.19865744476977
      ]
    },
    "48093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7902194685984028,
              43.10238454400779
            ]
          },
          "bbox": [
            -2.7902194685984028,
            43.10238454400779,
            -2.7902194685984028,
            43.10238454400779
          ]
        }
      ],
      "bbox": [
        -2.7902194685984028,
        43.10238454400779,
        -2.7902194685984028,
        43.10238454400779
      ]
    },
    "48094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7794435966675244,
              43.17356707282224
            ]
          },
          "bbox": [
            -2.7794435966675244,
            43.17356707282224,
            -2.7794435966675244,
            43.17356707282224
          ]
        }
      ],
      "bbox": [
        -2.7794435966675244,
        43.17356707282224,
        -2.7794435966675244,
        43.17356707282224
      ]
    },
    "48095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.525523302950119,
              43.167257077021574
            ]
          },
          "bbox": [
            -2.525523302950119,
            43.167257077021574,
            -2.525523302950119,
            43.167257077021574
          ]
        }
      ],
      "bbox": [
        -2.525523302950119,
        43.167257077021574,
        -2.525523302950119,
        43.167257077021574
      ]
    },
    "48096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1462863757459187,
              43.204558612714486
            ]
          },
          "bbox": [
            -3.1462863757459187,
            43.204558612714486,
            -3.1462863757459187,
            43.204558612714486
          ]
        }
      ],
      "bbox": [
        -3.1462863757459187,
        43.204558612714486,
        -3.1462863757459187,
        43.204558612714486
      ]
    },
    "48097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8678113391853004,
              43.2071630292488
            ]
          },
          "bbox": [
            -2.8678113391853004,
            43.2071630292488,
            -2.8678113391853004,
            43.2071630292488
          ]
        }
      ],
      "bbox": [
        -2.8678113391853004,
        43.2071630292488,
        -2.8678113391853004,
        43.2071630292488
      ]
    },
    "48901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.882192368278346,
              43.304916760658934
            ]
          },
          "bbox": [
            -2.882192368278346,
            43.304916760658934,
            -2.882192368278346,
            43.304916760658934
          ]
        }
      ],
      "bbox": [
        -2.882192368278346,
        43.304916760658934,
        -2.882192368278346,
        43.304916760658934
      ]
    },
    "48902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9557584961573893,
              43.31863350294989
            ]
          },
          "bbox": [
            -2.9557584961573893,
            43.31863350294989,
            -2.9557584961573893,
            43.31863350294989
          ]
        }
      ],
      "bbox": [
        -2.9557584961573893,
        43.31863350294989,
        -2.9557584961573893,
        43.31863350294989
      ]
    },
    "48903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9148194026534635,
              43.31653873098139
            ]
          },
          "bbox": [
            -2.9148194026534635,
            43.31653873098139,
            -2.9148194026534635,
            43.31653873098139
          ]
        }
      ],
      "bbox": [
        -2.9148194026534635,
        43.31653873098139,
        -2.9148194026534635,
        43.31653873098139
      ]
    },
    "48904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9295774897355873,
              43.29028028718627
            ]
          },
          "bbox": [
            -2.9295774897355873,
            43.29028028718627,
            -2.9295774897355873,
            43.29028028718627
          ]
        }
      ],
      "bbox": [
        -2.9295774897355873,
        43.29028028718627,
        -2.9295774897355873,
        43.29028028718627
      ]
    },
    "48905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.866581987871709,
              43.287619291203654
            ]
          },
          "bbox": [
            -2.866581987871709,
            43.287619291203654,
            -2.866581987871709,
            43.287619291203654
          ]
        }
      ],
      "bbox": [
        -2.866581987871709,
        43.287619291203654,
        -2.866581987871709,
        43.287619291203654
      ]
    },
    "48906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.687137451531556,
              43.33609033236036
            ]
          },
          "bbox": [
            -2.687137451531556,
            43.33609033236036,
            -2.687137451531556,
            43.33609033236036
          ]
        }
      ],
      "bbox": [
        -2.687137451531556,
        43.33609033236036,
        -2.687137451531556,
        43.33609033236036
      ]
    },
    "48907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.638162114078648,
              43.33282626453511
            ]
          },
          "bbox": [
            -2.638162114078648,
            43.33282626453511,
            -2.638162114078648,
            43.33282626453511
          ]
        }
      ],
      "bbox": [
        -2.638162114078648,
        43.33282626453511,
        -2.638162114078648,
        43.33282626453511
      ]
    },
    "48908": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6849387638233755,
              43.35586948463958
            ]
          },
          "bbox": [
            -2.6849387638233755,
            43.35586948463958,
            -2.6849387638233755,
            43.35586948463958
          ]
        }
      ],
      "bbox": [
        -2.6849387638233755,
        43.35586948463958,
        -2.6849387638233755,
        43.35586948463958
      ]
    },
    "48909": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.584286548692972,
              43.322963663460285
            ]
          },
          "bbox": [
            -2.584286548692972,
            43.322963663460285,
            -2.584286548692972,
            43.322963663460285
          ]
        }
      ],
      "bbox": [
        -2.584286548692972,
        43.322963663460285,
        -2.584286548692972,
        43.322963663460285
      ]
    },
    "48910": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6516012962306714,
              43.19001907931633
            ]
          },
          "bbox": [
            -2.6516012962306714,
            43.19001907931633,
            -2.6516012962306714,
            43.19001907931633
          ]
        }
      ],
      "bbox": [
        -2.6516012962306714,
        43.19001907931633,
        -2.6516012962306714,
        43.19001907931633
      ]
    },
    "48911": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.667059484004258,
              43.300092197353024
            ]
          },
          "bbox": [
            -2.667059484004258,
            43.300092197353024,
            -2.667059484004258,
            43.300092197353024
          ]
        }
      ],
      "bbox": [
        -2.667059484004258,
        43.300092197353024,
        -2.667059484004258,
        43.300092197353024
      ]
    },
    "48912": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.986810535757501,
              43.227944224095616
            ]
          },
          "bbox": [
            -2.986810535757501,
            43.227944224095616,
            -2.986810535757501,
            43.227944224095616
          ]
        }
      ],
      "bbox": [
        -2.986810535757501,
        43.227944224095616,
        -2.986810535757501,
        43.227944224095616
      ]
    },
    "48913": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.088620514659983,
              43.346880706115
            ]
          },
          "bbox": [
            -3.088620514659983,
            43.346880706115,
            -3.088620514659983,
            43.346880706115
          ]
        }
      ],
      "bbox": [
        -3.088620514659983,
        43.346880706115,
        -3.088620514659983,
        43.346880706115
      ]
    },
    "48914": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.633812578640214,
              43.31235566259358
            ]
          },
          "bbox": [
            -2.633812578640214,
            43.31235566259358,
            -2.633812578640214,
            43.31235566259358
          ]
        }
      ],
      "bbox": [
        -2.633812578640214,
        43.31235566259358,
        -2.633812578640214,
        43.31235566259358
      ]
    },
    "48915": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5545185236465424,
              43.24538836387289
            ]
          },
          "bbox": [
            -2.5545185236465424,
            43.24538836387289,
            -2.5545185236465424,
            43.24538836387289
          ]
        }
      ],
      "bbox": [
        -2.5545185236465424,
        43.24538836387289,
        -2.5545185236465424,
        43.24538836387289
      ]
    },
    "49002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.42628334372245,
              41.63604866995301
            ]
          },
          "bbox": [
            -5.42628334372245,
            41.63604866995301,
            -5.42628334372245,
            41.63604866995301
          ]
        }
      ],
      "bbox": [
        -5.42628334372245,
        41.63604866995301,
        -5.42628334372245,
        41.63604866995301
      ]
    },
    "49003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.366063334707347,
              41.696964121976684
            ]
          },
          "bbox": [
            -6.366063334707347,
            41.696964121976684,
            -6.366063334707347,
            41.696964121976684
          ]
        }
      ],
      "bbox": [
        -6.366063334707347,
        41.696964121976684,
        -6.366063334707347,
        41.696964121976684
      ]
    },
    "49004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9194680027641144,
              42.1325886680182
            ]
          },
          "bbox": [
            -5.9194680027641144,
            42.1325886680182,
            -5.9194680027641144,
            42.1325886680182
          ]
        }
      ],
      "bbox": [
        -5.9194680027641144,
        42.1325886680182,
        -5.9194680027641144,
        42.1325886680182
      ]
    },
    "49005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.965863458609242,
              41.223675454664715
            ]
          },
          "bbox": [
            -5.965863458609242,
            41.223675454664715,
            -5.965863458609242,
            41.223675454664715
          ]
        }
      ],
      "bbox": [
        -5.965863458609242,
        41.223675454664715,
        -5.965863458609242,
        41.223675454664715
      ]
    },
    "49006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.600169002997196,
              41.57439365154542
            ]
          },
          "bbox": [
            -5.600169002997196,
            41.57439365154542,
            -5.600169002997196,
            41.57439365154542
          ]
        }
      ],
      "bbox": [
        -5.600169002997196,
        41.57439365154542,
        -5.600169002997196,
        41.57439365154542
      ]
    },
    "49007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.909785792439891,
              41.48360120776443
            ]
          },
          "bbox": [
            -5.909785792439891,
            41.48360120776443,
            -5.909785792439891,
            41.48360120776443
          ]
        }
      ],
      "bbox": [
        -5.909785792439891,
        41.48360120776443,
        -5.909785792439891,
        41.48360120776443
      ]
    },
    "49008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.057963921616263,
              41.25955909451317
            ]
          },
          "bbox": [
            -6.057963921616263,
            41.25955909451317,
            -6.057963921616263,
            41.25955909451317
          ]
        }
      ],
      "bbox": [
        -6.057963921616263,
        41.25955909451317,
        -6.057963921616263,
        41.25955909451317
      ]
    },
    "49009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.846367769482661,
              41.60266277206632
            ]
          },
          "bbox": [
            -5.846367769482661,
            41.60266277206632,
            -5.846367769482661,
            41.60266277206632
          ]
        }
      ],
      "bbox": [
        -5.846367769482661,
        41.60266277206632,
        -5.846367769482661,
        41.60266277206632
      ]
    },
    "49010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.693124853206977,
              41.462092309301035
            ]
          },
          "bbox": [
            -5.693124853206977,
            41.462092309301035,
            -5.693124853206977,
            41.462092309301035
          ]
        }
      ],
      "bbox": [
        -5.693124853206977,
        41.462092309301035,
        -5.693124853206977,
        41.462092309301035
      ]
    },
    "49011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.711857862081697,
              41.94378582724538
            ]
          },
          "bbox": [
            -5.711857862081697,
            41.94378582724538,
            -5.711857862081697,
            41.94378582724538
          ]
        }
      ],
      "bbox": [
        -5.711857862081697,
        41.94378582724538,
        -5.711857862081697,
        41.94378582724538
      ]
    },
    "49012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.21330212422993,
              41.44078835944211
            ]
          },
          "bbox": [
            -6.21330212422993,
            41.44078835944211,
            -6.21330212422993,
            41.44078835944211
          ]
        }
      ],
      "bbox": [
        -6.21330212422993,
        41.44078835944211,
        -6.21330212422993,
        41.44078835944211
      ]
    },
    "49013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.582762390311289,
              41.302308840972486
            ]
          },
          "bbox": [
            -5.582762390311289,
            41.302308840972486,
            -5.582762390311289,
            41.302308840972486
          ]
        }
      ],
      "bbox": [
        -5.582762390311289,
        41.302308840972486,
        -5.582762390311289,
        41.302308840972486
      ]
    },
    "49014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.632226310365758,
              41.71206235621257
            ]
          },
          "bbox": [
            -5.632226310365758,
            41.71206235621257,
            -5.632226310365758,
            41.71206235621257
          ]
        }
      ],
      "bbox": [
        -5.632226310365758,
        41.71206235621257,
        -5.632226310365758,
        41.71206235621257
      ]
    },
    "49015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.891738451843761,
              42.11308794849819
            ]
          },
          "bbox": [
            -5.891738451843761,
            42.11308794849819,
            -5.891738451843761,
            42.11308794849819
          ]
        }
      ],
      "bbox": [
        -5.891738451843761,
        42.11308794849819,
        -5.891738451843761,
        42.11308794849819
      ]
    },
    "49016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.592017772211986,
              41.670508627532335
            ]
          },
          "bbox": [
            -5.592017772211986,
            41.670508627532335,
            -5.592017772211986,
            41.670508627532335
          ]
        }
      ],
      "bbox": [
        -5.592017772211986,
        41.670508627532335,
        -5.592017772211986,
        41.670508627532335
      ]
    },
    "49017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.471561390374688,
              42.05509952612135
            ]
          },
          "bbox": [
            -6.471561390374688,
            42.05509952612135,
            -6.471561390374688,
            42.05509952612135
          ]
        }
      ],
      "bbox": [
        -6.471561390374688,
        42.05509952612135,
        -6.471561390374688,
        42.05509952612135
      ]
    },
    "49018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.079452331507338,
              42.13002528917135
            ]
          },
          "bbox": [
            -6.079452331507338,
            42.13002528917135,
            -6.079452331507338,
            42.13002528917135
          ]
        }
      ],
      "bbox": [
        -6.079452331507338,
        42.13002528917135,
        -6.079452331507338,
        42.13002528917135
      ]
    },
    "49019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.644314886482437,
              41.93524768362038
            ]
          },
          "bbox": [
            -5.644314886482437,
            41.93524768362038,
            -5.644314886482437,
            41.93524768362038
          ]
        }
      ],
      "bbox": [
        -5.644314886482437,
        41.93524768362038,
        -5.644314886482437,
        41.93524768362038
      ]
    },
    "49020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4356626741516045,
              41.718957723881566
            ]
          },
          "bbox": [
            -5.4356626741516045,
            41.718957723881566,
            -5.4356626741516045,
            41.718957723881566
          ]
        }
      ],
      "bbox": [
        -5.4356626741516045,
        41.718957723881566,
        -5.4356626741516045,
        41.718957723881566
      ]
    },
    "49021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.679945880269184,
              42.00463480749816
            ]
          },
          "bbox": [
            -5.679945880269184,
            42.00463480749816,
            -5.679945880269184,
            42.00463480749816
          ]
        }
      ],
      "bbox": [
        -5.679945880269184,
        42.00463480749816,
        -5.679945880269184,
        42.00463480749816
      ]
    },
    "49022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.622224339416023,
              41.627461267315844
            ]
          },
          "bbox": [
            -5.622224339416023,
            41.627461267315844,
            -5.622224339416023,
            41.627461267315844
          ]
        }
      ],
      "bbox": [
        -5.622224339416023,
        41.627461267315844,
        -5.622224339416023,
        41.627461267315844
      ]
    },
    "49023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.076476041190522,
              41.36413629485587
            ]
          },
          "bbox": [
            -6.076476041190522,
            41.36413629485587,
            -6.076476041190522,
            41.36413629485587
          ]
        }
      ],
      "bbox": [
        -6.076476041190522,
        41.36413629485587,
        -6.076476041190522,
        41.36413629485587
      ]
    },
    "49024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.391871954801853,
              41.333992529164455
            ]
          },
          "bbox": [
            -5.391871954801853,
            41.333992529164455,
            -5.391871954801853,
            41.333992529164455
          ]
        }
      ],
      "bbox": [
        -5.391871954801853,
        41.333992529164455,
        -5.391871954801853,
        41.333992529164455
      ]
    },
    "49025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.74680350562827,
              41.86263663194262
            ]
          },
          "bbox": [
            -5.74680350562827,
            41.86263663194262,
            -5.74680350562827,
            41.86263663194262
          ]
        }
      ],
      "bbox": [
        -5.74680350562827,
        41.86263663194262,
        -5.74680350562827,
        41.86263663194262
      ]
    },
    "49026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.777821793270165,
              41.881826995634626
            ]
          },
          "bbox": [
            -5.777821793270165,
            41.881826995634626,
            -5.777821793270165,
            41.881826995634626
          ]
        }
      ],
      "bbox": [
        -5.777821793270165,
        41.881826995634626,
        -5.777821793270165,
        41.881826995634626
      ]
    },
    "49027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.047966112148853,
              42.05853938988314
            ]
          },
          "bbox": [
            -6.047966112148853,
            42.05853938988314,
            -6.047966112148853,
            42.05853938988314
          ]
        }
      ],
      "bbox": [
        -6.047966112148853,
        42.05853938988314,
        -6.047966112148853,
        42.05853938988314
      ]
    },
    "49028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.875043109553375,
              42.04037854554918
            ]
          },
          "bbox": [
            -5.875043109553375,
            42.04037854554918,
            -5.875043109553375,
            42.04037854554918
          ]
        }
      ],
      "bbox": [
        -5.875043109553375,
        42.04037854554918,
        -5.875043109553375,
        42.04037854554918
      ]
    },
    "49029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.794183707944361,
              41.906648438735374
            ]
          },
          "bbox": [
            -5.794183707944361,
            41.906648438735374,
            -5.794183707944361,
            41.906648438735374
          ]
        }
      ],
      "bbox": [
        -5.794183707944361,
        41.906648438735374,
        -5.794183707944361,
        41.906648438735374
      ]
    },
    "49030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.453220771269888,
              41.67496113620603
            ]
          },
          "bbox": [
            -5.453220771269888,
            41.67496113620603,
            -5.453220771269888,
            41.67496113620603
          ]
        }
      ],
      "bbox": [
        -5.453220771269888,
        41.67496113620603,
        -5.453220771269888,
        41.67496113620603
      ]
    },
    "49031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.81282558760979,
              41.31478838740494
            ]
          },
          "bbox": [
            -5.81282558760979,
            41.31478838740494,
            -5.81282558760979,
            41.31478838740494
          ]
        }
      ],
      "bbox": [
        -5.81282558760979,
        41.31478838740494,
        -5.81282558760979,
        41.31478838740494
      ]
    },
    "49032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.102536641836902,
              41.96910525681345
            ]
          },
          "bbox": [
            -6.102536641836902,
            41.96910525681345,
            -6.102536641836902,
            41.96910525681345
          ]
        }
      ],
      "bbox": [
        -6.102536641836902,
        41.96910525681345,
        -6.102536641836902,
        41.96910525681345
      ]
    },
    "49033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.01633550872833,
              42.00981912049867
            ]
          },
          "bbox": [
            -6.01633550872833,
            42.00981912049867,
            -6.01633550872833,
            42.00981912049867
          ]
        }
      ],
      "bbox": [
        -6.01633550872833,
        42.00981912049867,
        -6.01633550872833,
        42.00981912049867
      ]
    },
    "49034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.37011977889774,
              41.17210590386536
            ]
          },
          "bbox": [
            -5.37011977889774,
            41.17210590386536,
            -5.37011977889774,
            41.17210590386536
          ]
        }
      ],
      "bbox": [
        -5.37011977889774,
        41.17210590386536,
        -5.37011977889774,
        41.17210590386536
      ]
    },
    "49035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.509797706731761,
              41.76493081237989
            ]
          },
          "bbox": [
            -5.509797706731761,
            41.76493081237989,
            -5.509797706731761,
            41.76493081237989
          ]
        }
      ],
      "bbox": [
        -5.509797706731761,
        41.76493081237989,
        -5.509797706731761,
        41.76493081237989
      ]
    },
    "49036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.99202779343434,
              41.65028874163836
            ]
          },
          "bbox": [
            -5.99202779343434,
            41.65028874163836,
            -5.99202779343434,
            41.65028874163836
          ]
        }
      ],
      "bbox": [
        -5.99202779343434,
        41.65028874163836,
        -5.99202779343434,
        41.65028874163836
      ]
    },
    "49037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.140101496716544,
              41.22707813889327
            ]
          },
          "bbox": [
            -6.140101496716544,
            41.22707813889327,
            -6.140101496716544,
            41.22707813889327
          ]
        }
      ],
      "bbox": [
        -6.140101496716544,
        41.22707813889327,
        -6.140101496716544,
        41.22707813889327
      ]
    },
    "49038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7541264634006595,
              41.37503215628162
            ]
          },
          "bbox": [
            -5.7541264634006595,
            41.37503215628162,
            -5.7541264634006595,
            41.37503215628162
          ]
        }
      ],
      "bbox": [
        -5.7541264634006595,
        41.37503215628162,
        -5.7541264634006595,
        41.37503215628162
      ]
    },
    "49039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.672251625709771,
              41.430071416726555
            ]
          },
          "bbox": [
            -5.672251625709771,
            41.430071416726555,
            -5.672251625709771,
            41.430071416726555
          ]
        }
      ],
      "bbox": [
        -5.672251625709771,
        41.430071416726555,
        -5.672251625709771,
        41.430071416726555
      ]
    },
    "49040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.324418666535928,
              41.23270465919732
            ]
          },
          "bbox": [
            -5.324418666535928,
            41.23270465919732,
            -5.324418666535928,
            41.23270465919732
          ]
        }
      ],
      "bbox": [
        -5.324418666535928,
        41.23270465919732,
        -5.324418666535928,
        41.23270465919732
      ]
    },
    "49041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.596026117342615,
              41.9796057790411
            ]
          },
          "bbox": [
            -5.596026117342615,
            41.9796057790411,
            -5.596026117342615,
            41.9796057790411
          ]
        }
      ],
      "bbox": [
        -5.596026117342615,
        41.9796057790411,
        -5.596026117342615,
        41.9796057790411
      ]
    },
    "49042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.556415124985978,
              41.73103046277661
            ]
          },
          "bbox": [
            -5.556415124985978,
            41.73103046277661,
            -5.556415124985978,
            41.73103046277661
          ]
        }
      ],
      "bbox": [
        -5.556415124985978,
        41.73103046277661,
        -5.556415124985978,
        41.73103046277661
      ]
    },
    "49043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.347145078075001,
              41.985499583065696
            ]
          },
          "bbox": [
            -5.347145078075001,
            41.985499583065696,
            -5.347145078075001,
            41.985499583065696
          ]
        }
      ],
      "bbox": [
        -5.347145078075001,
        41.985499583065696,
        -5.347145078075001,
        41.985499583065696
      ]
    },
    "49044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.702312822532113,
              41.41073502401471
            ]
          },
          "bbox": [
            -5.702312822532113,
            41.41073502401471,
            -5.702312822532113,
            41.41073502401471
          ]
        }
      ],
      "bbox": [
        -5.702312822532113,
        41.41073502401471,
        -5.702312822532113,
        41.41073502401471
      ]
    },
    "49046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.488962382862148,
              41.89830383687757
            ]
          },
          "bbox": [
            -5.488962382862148,
            41.89830383687757,
            -5.488962382862148,
            41.89830383687757
          ]
        }
      ],
      "bbox": [
        -5.488962382862148,
        41.89830383687757,
        -5.488962382862148,
        41.89830383687757
      ]
    },
    "49047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.655345467317365,
              41.68108328881657
            ]
          },
          "bbox": [
            -5.655345467317365,
            41.68108328881657,
            -5.655345467317365,
            41.68108328881657
          ]
        }
      ],
      "bbox": [
        -5.655345467317365,
        41.68108328881657,
        -5.655345467317365,
        41.68108328881657
      ]
    },
    "49048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.409303095888381,
              42.01851768057761
            ]
          },
          "bbox": [
            -6.409303095888381,
            42.01851768057761,
            -6.409303095888381,
            42.01851768057761
          ]
        }
      ],
      "bbox": [
        -6.409303095888381,
        42.01851768057761,
        -6.409303095888381,
        42.01851768057761
      ]
    },
    "49050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.719247177568849,
              42.07349184759228
            ]
          },
          "bbox": [
            -6.719247177568849,
            42.07349184759228,
            -6.719247177568849,
            42.07349184759228
          ]
        }
      ],
      "bbox": [
        -6.719247177568849,
        42.07349184759228,
        -6.719247177568849,
        42.07349184759228
      ]
    },
    "49052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.819667354989489,
              42.110163350403276
            ]
          },
          "bbox": [
            -5.819667354989489,
            42.110163350403276,
            -5.819667354989489,
            42.110163350403276
          ]
        }
      ],
      "bbox": [
        -5.819667354989489,
        42.110163350403276,
        -5.819667354989489,
        42.110163350403276
      ]
    },
    "49053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6179773412507,
              41.53457980584682
            ]
          },
          "bbox": [
            -5.6179773412507,
            41.53457980584682,
            -5.6179773412507,
            41.53457980584682
          ]
        }
      ],
      "bbox": [
        -5.6179773412507,
        41.53457980584682,
        -5.6179773412507,
        41.53457980584682
      ]
    },
    "49054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.725299680244783,
              41.32015088431855
            ]
          },
          "bbox": [
            -5.725299680244783,
            41.32015088431855,
            -5.725299680244783,
            41.32015088431855
          ]
        }
      ],
      "bbox": [
        -5.725299680244783,
        41.32015088431855,
        -5.725299680244783,
        41.32015088431855
      ]
    },
    "49055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.311913635425104,
              41.81216185257756
            ]
          },
          "bbox": [
            -5.311913635425104,
            41.81216185257756,
            -5.311913635425104,
            41.81216185257756
          ]
        }
      ],
      "bbox": [
        -5.311913635425104,
        41.81216185257756,
        -5.311913635425104,
        41.81216185257756
      ]
    },
    "49056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.757850039103316,
              41.58783331528607
            ]
          },
          "bbox": [
            -5.757850039103316,
            41.58783331528607,
            -5.757850039103316,
            41.58783331528607
          ]
        }
      ],
      "bbox": [
        -5.757850039103316,
        41.58783331528607,
        -5.757850039103316,
        41.58783331528607
      ]
    },
    "49057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.17190459669657,
              42.132196709463365
            ]
          },
          "bbox": [
            -6.17190459669657,
            42.132196709463365,
            -6.17190459669657,
            42.132196709463365
          ]
        }
      ],
      "bbox": [
        -6.17190459669657,
        42.132196709463365,
        -6.17190459669657,
        42.132196709463365
      ]
    },
    "49058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.711449600613913,
              41.25233932071249
            ]
          },
          "bbox": [
            -5.711449600613913,
            41.25233932071249,
            -5.711449600613913,
            41.25233932071249
          ]
        }
      ],
      "bbox": [
        -5.711449600613913,
        41.25233932071249,
        -5.711449600613913,
        41.25233932071249
      ]
    },
    "49059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6632440722034385,
              41.299806230564464
            ]
          },
          "bbox": [
            -5.6632440722034385,
            41.299806230564464,
            -5.6632440722034385,
            41.299806230564464
          ]
        }
      ],
      "bbox": [
        -5.6632440722034385,
        41.299806230564464,
        -5.6632440722034385,
        41.299806230564464
      ]
    },
    "49061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.763945946611497,
              41.438003969951225
            ]
          },
          "bbox": [
            -5.763945946611497,
            41.438003969951225,
            -5.763945946611497,
            41.438003969951225
          ]
        }
      ],
      "bbox": [
        -5.763945946611497,
        41.438003969951225,
        -5.763945946611497,
        41.438003969951225
      ]
    },
    "49062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.410353555578975,
              42.142253435365504
            ]
          },
          "bbox": [
            -6.410353555578975,
            42.142253435365504,
            -6.410353555578975,
            42.142253435365504
          ]
        }
      ],
      "bbox": [
        -6.410353555578975,
        42.142253435365504,
        -6.410353555578975,
        42.142253435365504
      ]
    },
    "49063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.869239037250635,
              41.851994947675486
            ]
          },
          "bbox": [
            -5.869239037250635,
            41.851994947675486,
            -5.869239037250635,
            41.851994947675486
          ]
        }
      ],
      "bbox": [
        -5.869239037250635,
        41.851994947675486,
        -5.869239037250635,
        41.851994947675486
      ]
    },
    "49064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.26372759995463,
              41.41681061731327
            ]
          },
          "bbox": [
            -6.26372759995463,
            41.41681061731327,
            -6.26372759995463,
            41.41681061731327
          ]
        }
      ],
      "bbox": [
        -6.26372759995463,
        41.41681061731327,
        -6.26372759995463,
        41.41681061731327
      ]
    },
    "49065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.386187859950408,
              41.31055586687561
            ]
          },
          "bbox": [
            -6.386187859950408,
            41.31055586687561,
            -6.386187859950408,
            41.31055586687561
          ]
        }
      ],
      "bbox": [
        -6.386187859950408,
        41.31055586687561,
        -6.386187859950408,
        41.31055586687561
      ]
    },
    "49066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.06424494219672,
              41.89804629759736
            ]
          },
          "bbox": [
            -6.06424494219672,
            41.89804629759736,
            -6.06424494219672,
            41.89804629759736
          ]
        }
      ],
      "bbox": [
        -6.06424494219672,
        41.89804629759736,
        -6.06424494219672,
        41.89804629759736
      ]
    },
    "49067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2036565450217696,
              41.90993813580177
            ]
          },
          "bbox": [
            -6.2036565450217696,
            41.90993813580177,
            -6.2036565450217696,
            41.90993813580177
          ]
        }
      ],
      "bbox": [
        -6.2036565450217696,
        41.90993813580177,
        -6.2036565450217696,
        41.90993813580177
      ]
    },
    "49068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.049753073052333,
              41.787546649098125
            ]
          },
          "bbox": [
            -6.049753073052333,
            41.787546649098125,
            -6.049753073052333,
            41.787546649098125
          ]
        }
      ],
      "bbox": [
        -6.049753073052333,
        41.787546649098125,
        -6.049753073052333,
        41.787546649098125
      ]
    },
    "49069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.465380440748327,
              41.867298542879674
            ]
          },
          "bbox": [
            -6.465380440748327,
            41.867298542879674,
            -6.465380440748327,
            41.867298542879674
          ]
        }
      ],
      "bbox": [
        -6.465380440748327,
        41.867298542879674,
        -6.465380440748327,
        41.867298542879674
      ]
    },
    "49071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.191779332712964,
              41.64008178555382
            ]
          },
          "bbox": [
            -6.191779332712964,
            41.64008178555382,
            -6.191779332712964,
            41.64008178555382
          ]
        }
      ],
      "bbox": [
        -6.191779332712964,
        41.64008178555382,
        -6.191779332712964,
        41.64008178555382
      ]
    },
    "49075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.771909643961335,
              42.08459307844298
            ]
          },
          "bbox": [
            -5.771909643961335,
            42.08459307844298,
            -5.771909643961335,
            42.08459307844298
          ]
        }
      ],
      "bbox": [
        -5.771909643961335,
        42.08459307844298,
        -5.771909643961335,
        42.08459307844298
      ]
    },
    "49076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.566254165969684,
              41.54108080076934
            ]
          },
          "bbox": [
            -5.566254165969684,
            41.54108080076934,
            -5.566254165969684,
            41.54108080076934
          ]
        }
      ],
      "bbox": [
        -5.566254165969684,
        41.54108080076934,
        -5.566254165969684,
        41.54108080076934
      ]
    },
    "49077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9689349270410945,
              41.322657328906
            ]
          },
          "bbox": [
            -5.9689349270410945,
            41.322657328906,
            -5.9689349270410945,
            41.322657328906
          ]
        }
      ],
      "bbox": [
        -5.9689349270410945,
        41.322657328906,
        -5.9689349270410945,
        41.322657328906
      ]
    },
    "49078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.855313386012472,
              41.90197523197102
            ]
          },
          "bbox": [
            -5.855313386012472,
            41.90197523197102,
            -5.855313386012472,
            41.90197523197102
          ]
        }
      ],
      "bbox": [
        -5.855313386012472,
        41.90197523197102,
        -5.855313386012472,
        41.90197523197102
      ]
    },
    "49079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.000984343706506,
              42.12385776162571
            ]
          },
          "bbox": [
            -6.000984343706506,
            42.12385776162571,
            -6.000984343706506,
            42.12385776162571
          ]
        }
      ],
      "bbox": [
        -6.000984343706506,
        42.12385776162571,
        -6.000984343706506,
        42.12385776162571
      ]
    },
    "49080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.390269114282275,
              41.24424651455284
            ]
          },
          "bbox": [
            -5.390269114282275,
            41.24424651455284,
            -5.390269114282275,
            41.24424651455284
          ]
        }
      ],
      "bbox": [
        -5.390269114282275,
        41.24424651455284,
        -5.390269114282275,
        41.24424651455284
      ]
    },
    "49081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.497840503863197,
              41.24214943880402
            ]
          },
          "bbox": [
            -5.497840503863197,
            41.24214943880402,
            -5.497840503863197,
            41.24214943880402
          ]
        }
      ],
      "bbox": [
        -5.497840503863197,
        41.24214943880402,
        -5.497840503863197,
        41.24214943880402
      ]
    },
    "49082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.563876552054186,
              42.012286631430385
            ]
          },
          "bbox": [
            -5.563876552054186,
            42.012286631430385,
            -5.563876552054186,
            42.012286631430385
          ]
        }
      ],
      "bbox": [
        -5.563876552054186,
        42.012286631430385,
        -5.563876552054186,
        42.012286631430385
      ]
    },
    "49083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.481364428662761,
              41.62552927688712
            ]
          },
          "bbox": [
            -5.481364428662761,
            41.62552927688712,
            -5.481364428662761,
            41.62552927688712
          ]
        }
      ],
      "bbox": [
        -5.481364428662761,
        41.62552927688712,
        -5.481364428662761,
        41.62552927688712
      ]
    },
    "49084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.628802358748798,
              41.329439167117
            ]
          },
          "bbox": [
            -5.628802358748798,
            41.329439167117,
            -5.628802358748798,
            41.329439167117
          ]
        }
      ],
      "bbox": [
        -5.628802358748798,
        41.329439167117,
        -5.628802358748798,
        41.329439167117
      ]
    },
    "49085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.73076635502219,
              42.13814728324989
            ]
          },
          "bbox": [
            -6.73076635502219,
            42.13814728324989,
            -6.73076635502219,
            42.13814728324989
          ]
        }
      ],
      "bbox": [
        -6.73076635502219,
        42.13814728324989,
        -6.73076635502219,
        42.13814728324989
      ]
    },
    "49086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.586777004587921,
              41.59895319657799
            ]
          },
          "bbox": [
            -5.586777004587921,
            41.59895319657799,
            -5.586777004587921,
            41.59895319657799
          ]
        }
      ],
      "bbox": [
        -5.586777004587921,
        41.59895319657799,
        -5.586777004587921,
        41.59895319657799
      ]
    },
    "49087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.163794578440403,
              41.74115136304743
            ]
          },
          "bbox": [
            -6.163794578440403,
            41.74115136304743,
            -6.163794578440403,
            41.74115136304743
          ]
        }
      ],
      "bbox": [
        -6.163794578440403,
        41.74115136304743,
        -6.163794578440403,
        41.74115136304743
      ]
    },
    "49088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.174367698753385,
              41.464561589648724
            ]
          },
          "bbox": [
            -6.174367698753385,
            41.464561589648724,
            -6.174367698753385,
            41.464561589648724
          ]
        }
      ],
      "bbox": [
        -6.174367698753385,
        41.464561589648724,
        -6.174367698753385,
        41.464561589648724
      ]
    },
    "49090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.62818190831948,
              41.40495458455336
            ]
          },
          "bbox": [
            -5.62818190831948,
            41.40495458455336,
            -5.62818190831948,
            41.40495458455336
          ]
        }
      ],
      "bbox": [
        -5.62818190831948,
        41.40495458455336,
        -5.62818190831948,
        41.40495458455336
      ]
    },
    "49091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7517501315974195,
              41.811701800472235
            ]
          },
          "bbox": [
            -5.7517501315974195,
            41.811701800472235,
            -5.7517501315974195,
            41.811701800472235
          ]
        }
      ],
      "bbox": [
        -5.7517501315974195,
        41.811701800472235,
        -5.7517501315974195,
        41.811701800472235
      ]
    },
    "49092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9179925334734405,
              42.044598677984055
            ]
          },
          "bbox": [
            -5.9179925334734405,
            42.044598677984055,
            -5.9179925334734405,
            42.044598677984055
          ]
        }
      ],
      "bbox": [
        -5.9179925334734405,
        42.044598677984055,
        -5.9179925334734405,
        42.044598677984055
      ]
    },
    "49093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.471580968031043,
              41.299711482935805
            ]
          },
          "bbox": [
            -5.471580968031043,
            41.299711482935805,
            -5.471580968031043,
            41.299711482935805
          ]
        }
      ],
      "bbox": [
        -5.471580968031043,
        41.299711482935805,
        -5.471580968031043,
        41.299711482935805
      ]
    },
    "49094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.890938642537823,
              41.98278940345822
            ]
          },
          "bbox": [
            -6.890938642537823,
            41.98278940345822,
            -6.890938642537823,
            41.98278940345822
          ]
        }
      ],
      "bbox": [
        -6.890938642537823,
        41.98278940345822,
        -6.890938642537823,
        41.98278940345822
      ]
    },
    "49095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.818682427711856,
              41.57139964393613
            ]
          },
          "bbox": [
            -5.818682427711856,
            41.57139964393613,
            -5.818682427711856,
            41.57139964393613
          ]
        }
      ],
      "bbox": [
        -5.818682427711856,
        41.57139964393613,
        -5.818682427711856,
        41.57139964393613
      ]
    },
    "49096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.648703570230988,
              41.380713189884474
            ]
          },
          "bbox": [
            -5.648703570230988,
            41.380713189884474,
            -5.648703570230988,
            41.380713189884474
          ]
        }
      ],
      "bbox": [
        -5.648703570230988,
        41.380713189884474,
        -5.648703570230988,
        41.380713189884474
      ]
    },
    "49097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.268701385118982,
              42.147634524778525
            ]
          },
          "bbox": [
            -6.268701385118982,
            42.147634524778525,
            -6.268701385118982,
            42.147634524778525
          ]
        }
      ],
      "bbox": [
        -6.268701385118982,
        42.147634524778525,
        -6.268701385118982,
        42.147634524778525
      ]
    },
    "49098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.068399599383261,
              41.67427244981878
            ]
          },
          "bbox": [
            -6.068399599383261,
            41.67427244981878,
            -6.068399599383261,
            41.67427244981878
          ]
        }
      ],
      "bbox": [
        -6.068399599383261,
        41.67427244981878,
        -6.068399599383261,
        41.67427244981878
      ]
    },
    "49099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0412982263438515,
              41.7176450418281
            ]
          },
          "bbox": [
            -6.0412982263438515,
            41.7176450418281,
            -6.0412982263438515,
            41.7176450418281
          ]
        }
      ],
      "bbox": [
        -6.0412982263438515,
        41.7176450418281,
        -6.0412982263438515,
        41.7176450418281
      ]
    },
    "49100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.8918311447422935,
              42.04999020721482
            ]
          },
          "bbox": [
            -6.8918311447422935,
            42.04999020721482,
            -6.8918311447422935,
            42.04999020721482
          ]
        }
      ],
      "bbox": [
        -6.8918311447422935,
        42.04999020721482,
        -6.8918311447422935,
        42.04999020721482
      ]
    },
    "49101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.138080444737146,
              41.43373427026272
            ]
          },
          "bbox": [
            -6.138080444737146,
            41.43373427026272,
            -6.138080444737146,
            41.43373427026272
          ]
        }
      ],
      "bbox": [
        -6.138080444737146,
        41.43373427026272,
        -6.138080444737146,
        41.43373427026272
      ]
    },
    "49102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.632637536409093,
              41.26841110063136
            ]
          },
          "bbox": [
            -5.632637536409093,
            41.26841110063136,
            -5.632637536409093,
            41.26841110063136
          ]
        }
      ],
      "bbox": [
        -5.632637536409093,
        41.26841110063136,
        -5.632637536409093,
        41.26841110063136
      ]
    },
    "49103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.598169216227433,
              41.462900920655116
            ]
          },
          "bbox": [
            -5.598169216227433,
            41.462900920655116,
            -5.598169216227433,
            41.462900920655116
          ]
        }
      ],
      "bbox": [
        -5.598169216227433,
        41.462900920655116,
        -5.598169216227433,
        41.462900920655116
      ]
    },
    "49104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.343419459774683,
              41.87737501057144
            ]
          },
          "bbox": [
            -6.343419459774683,
            41.87737501057144,
            -6.343419459774683,
            41.87737501057144
          ]
        }
      ],
      "bbox": [
        -6.343419459774683,
        41.87737501057144,
        -6.343419459774683,
        41.87737501057144
      ]
    },
    "49105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.774928196768301,
              42.11713175458057
            ]
          },
          "bbox": [
            -5.774928196768301,
            42.11713175458057,
            -5.774928196768301,
            42.11713175458057
          ]
        }
      ],
      "bbox": [
        -5.774928196768301,
        42.11713175458057,
        -5.774928196768301,
        42.11713175458057
      ]
    },
    "49107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.500060355219926,
              41.65743603092811
            ]
          },
          "bbox": [
            -5.500060355219926,
            41.65743603092811,
            -5.500060355219926,
            41.65743603092811
          ]
        }
      ],
      "bbox": [
        -5.500060355219926,
        41.65743603092811,
        -5.500060355219926,
        41.65743603092811
      ]
    },
    "49108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.721835407736114,
              41.762217619836385
            ]
          },
          "bbox": [
            -5.721835407736114,
            41.762217619836385,
            -5.721835407736114,
            41.762217619836385
          ]
        }
      ],
      "bbox": [
        -5.721835407736114,
        41.762217619836385,
        -5.721835407736114,
        41.762217619836385
      ]
    },
    "49109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.757200311238419,
              42.03380466699579
            ]
          },
          "bbox": [
            -5.757200311238419,
            42.03380466699579,
            -5.757200311238419,
            42.03380466699579
          ]
        }
      ],
      "bbox": [
        -5.757200311238419,
        42.03380466699579,
        -5.757200311238419,
        42.03380466699579
      ]
    },
    "49110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.461749287645579,
              41.96473426845851
            ]
          },
          "bbox": [
            -6.461749287645579,
            41.96473426845851,
            -6.461749287645579,
            41.96473426845851
          ]
        }
      ],
      "bbox": [
        -6.461749287645579,
        41.96473426845851,
        -6.461749287645579,
        41.96473426845851
      ]
    },
    "49111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.938657422424305,
              41.63212139132622
            ]
          },
          "bbox": [
            -5.938657422424305,
            41.63212139132622,
            -5.938657422424305,
            41.63212139132622
          ]
        }
      ],
      "bbox": [
        -5.938657422424305,
        41.63212139132622,
        -5.938657422424305,
        41.63212139132622
      ]
    },
    "49112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.352655300755452,
              42.064710015011244
            ]
          },
          "bbox": [
            -6.352655300755452,
            42.064710015011244,
            -6.352655300755452,
            42.064710015011244
          ]
        }
      ],
      "bbox": [
        -6.352655300755452,
        42.064710015011244,
        -6.352655300755452,
        42.064710015011244
      ]
    },
    "49113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.614911719256898,
              42.1000428234954
            ]
          },
          "bbox": [
            -5.614911719256898,
            42.1000428234954,
            -5.614911719256898,
            42.1000428234954
          ]
        }
      ],
      "bbox": [
        -5.614911719256898,
        42.1000428234954,
        -5.614911719256898,
        42.1000428234954
      ]
    },
    "49114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4999007219685385,
              41.58329589875134
            ]
          },
          "bbox": [
            -5.4999007219685385,
            41.58329589875134,
            -5.4999007219685385,
            41.58329589875134
          ]
        }
      ],
      "bbox": [
        -5.4999007219685385,
        41.58329589875134,
        -5.4999007219685385,
        41.58329589875134
      ]
    },
    "49115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.797965147673592,
              41.24557531516248
            ]
          },
          "bbox": [
            -5.797965147673592,
            41.24557531516248,
            -5.797965147673592,
            41.24557531516248
          ]
        }
      ],
      "bbox": [
        -5.797965147673592,
        41.24557531516248,
        -5.797965147673592,
        41.24557531516248
      ]
    },
    "49116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.039892134540927,
              41.95297903693342
            ]
          },
          "bbox": [
            -6.039892134540927,
            41.95297903693342,
            -6.039892134540927,
            41.95297903693342
          ]
        }
      ],
      "bbox": [
        -6.039892134540927,
        41.95297903693342,
        -6.039892134540927,
        41.95297903693342
      ]
    },
    "49117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.871019319070007,
              41.98043676660339
            ]
          },
          "bbox": [
            -5.871019319070007,
            41.98043676660339,
            -5.871019319070007,
            41.98043676660339
          ]
        }
      ],
      "bbox": [
        -5.871019319070007,
        41.98043676660339,
        -5.871019319070007,
        41.98043676660339
      ]
    },
    "49118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.738447318477147,
              41.9224470528633
            ]
          },
          "bbox": [
            -5.738447318477147,
            41.9224470528633,
            -5.738447318477147,
            41.9224470528633
          ]
        }
      ],
      "bbox": [
        -5.738447318477147,
        41.9224470528633,
        -5.738447318477147,
        41.9224470528633
      ]
    },
    "49119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.663910124088779,
              41.587096277430724
            ]
          },
          "bbox": [
            -5.663910124088779,
            41.587096277430724,
            -5.663910124088779,
            41.587096277430724
          ]
        }
      ],
      "bbox": [
        -5.663910124088779,
        41.587096277430724,
        -5.663910124088779,
        41.587096277430724
      ]
    },
    "49120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.198611666095331,
              42.076089576130194
            ]
          },
          "bbox": [
            -6.198611666095331,
            42.076089576130194,
            -6.198611666095331,
            42.076089576130194
          ]
        }
      ],
      "bbox": [
        -6.198611666095331,
        42.076089576130194,
        -6.198611666095331,
        42.076089576130194
      ]
    },
    "49121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.327026535289954,
              42.006739771557626
            ]
          },
          "bbox": [
            -6.327026535289954,
            42.006739771557626,
            -6.327026535289954,
            42.006739771557626
          ]
        }
      ],
      "bbox": [
        -6.327026535289954,
        42.006739771557626,
        -6.327026535289954,
        42.006739771557626
      ]
    },
    "49122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.702258591086765,
              41.560425215318986
            ]
          },
          "bbox": [
            -5.702258591086765,
            41.560425215318986,
            -5.702258591086765,
            41.560425215318986
          ]
        }
      ],
      "bbox": [
        -5.702258591086765,
        41.560425215318986,
        -5.702258591086765,
        41.560425215318986
      ]
    },
    "49123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8151996601167015,
              41.650945370368014
            ]
          },
          "bbox": [
            -5.8151996601167015,
            41.650945370368014,
            -5.8151996601167015,
            41.650945370368014
          ]
        }
      ],
      "bbox": [
        -5.8151996601167015,
        41.650945370368014,
        -5.8151996601167015,
        41.650945370368014
      ]
    },
    "49124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0544646347534545,
              41.45749039362784
            ]
          },
          "bbox": [
            -6.0544646347534545,
            41.45749039362784,
            -6.0544646347534545,
            41.45749039362784
          ]
        }
      ],
      "bbox": [
        -6.0544646347534545,
        41.45749039362784,
        -6.0544646347534545,
        41.45749039362784
      ]
    },
    "49125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.641766733535939,
              41.45087598225232
            ]
          },
          "bbox": [
            -5.641766733535939,
            41.45087598225232,
            -5.641766733535939,
            41.45087598225232
          ]
        }
      ],
      "bbox": [
        -5.641766733535939,
        41.45087598225232,
        -5.641766733535939,
        41.45087598225232
      ]
    },
    "49126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.981859488379716,
              41.17666293386104
            ]
          },
          "bbox": [
            -5.981859488379716,
            41.17666293386104,
            -5.981859488379716,
            41.17666293386104
          ]
        }
      ],
      "bbox": [
        -5.981859488379716,
        41.17666293386104,
        -5.981859488379716,
        41.17666293386104
      ]
    },
    "49127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.725977292232942,
              41.442145777021246
            ]
          },
          "bbox": [
            -5.725977292232942,
            41.442145777021246,
            -5.725977292232942,
            41.442145777021246
          ]
        }
      ],
      "bbox": [
        -5.725977292232942,
        41.442145777021246,
        -5.725977292232942,
        41.442145777021246
      ]
    },
    "49128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.785042889870591,
              42.05946624288371
            ]
          },
          "bbox": [
            -5.785042889870591,
            42.05946624288371,
            -5.785042889870591,
            42.05946624288371
          ]
        }
      ],
      "bbox": [
        -5.785042889870591,
        42.05946624288371,
        -5.785042889870591,
        42.05946624288371
      ]
    },
    "49129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.292970061825733,
              41.53266061589927
            ]
          },
          "bbox": [
            -5.292970061825733,
            41.53266061589927,
            -5.292970061825733,
            41.53266061589927
          ]
        }
      ],
      "bbox": [
        -5.292970061825733,
        41.53266061589927,
        -5.292970061825733,
        41.53266061589927
      ]
    },
    "49130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.888331689887771,
              41.943652082390074
            ]
          },
          "bbox": [
            -5.888331689887771,
            41.943652082390074,
            -5.888331689887771,
            41.943652082390074
          ]
        }
      ],
      "bbox": [
        -5.888331689887771,
        41.943652082390074,
        -5.888331689887771,
        41.943652082390074
      ]
    },
    "49131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1403363805945315,
              41.49532502512326
            ]
          },
          "bbox": [
            -6.1403363805945315,
            41.49532502512326,
            -6.1403363805945315,
            41.49532502512326
          ]
        }
      ],
      "bbox": [
        -6.1403363805945315,
        41.49532502512326,
        -6.1403363805945315,
        41.49532502512326
      ]
    },
    "49132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7294130388807005,
              41.62734219962386
            ]
          },
          "bbox": [
            -5.7294130388807005,
            41.62734219962386,
            -5.7294130388807005,
            41.62734219962386
          ]
        }
      ],
      "bbox": [
        -5.7294130388807005,
        41.62734219962386,
        -5.7294130388807005,
        41.62734219962386
      ]
    },
    "49133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.839463302516975,
              41.80330973287717
            ]
          },
          "bbox": [
            -5.839463302516975,
            41.80330973287717,
            -5.839463302516975,
            41.80330973287717
          ]
        }
      ],
      "bbox": [
        -5.839463302516975,
        41.80330973287717,
        -5.839463302516975,
        41.80330973287717
      ]
    },
    "49134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.327815809476213,
              42.14583265263728
            ]
          },
          "bbox": [
            -6.327815809476213,
            42.14583265263728,
            -6.327815809476213,
            42.14583265263728
          ]
        }
      ],
      "bbox": [
        -6.327815809476213,
        42.14583265263728,
        -6.327815809476213,
        42.14583265263728
      ]
    },
    "49135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.994935639421117,
              41.547435834961874
            ]
          },
          "bbox": [
            -5.994935639421117,
            41.547435834961874,
            -5.994935639421117,
            41.547435834961874
          ]
        }
      ],
      "bbox": [
        -5.994935639421117,
        41.547435834961874,
        -5.994935639421117,
        41.547435834961874
      ]
    },
    "49136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.215376201568673,
              41.36729795868334
            ]
          },
          "bbox": [
            -6.215376201568673,
            41.36729795868334,
            -6.215376201568673,
            41.36729795868334
          ]
        }
      ],
      "bbox": [
        -6.215376201568673,
        41.36729795868334,
        -6.215376201568673,
        41.36729795868334
      ]
    },
    "49137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.81558165863375,
              41.951797293641135
            ]
          },
          "bbox": [
            -5.81558165863375,
            41.951797293641135,
            -5.81558165863375,
            41.951797293641135
          ]
        }
      ],
      "bbox": [
        -5.81558165863375,
        41.951797293641135,
        -5.81558165863375,
        41.951797293641135
      ]
    },
    "49138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.969980452977134,
              41.73665485305212
            ]
          },
          "bbox": [
            -5.969980452977134,
            41.73665485305212,
            -5.969980452977134,
            41.73665485305212
          ]
        }
      ],
      "bbox": [
        -5.969980452977134,
        41.73665485305212,
        -5.969980452977134,
        41.73665485305212
      ]
    },
    "49139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.171305744191651,
              41.94857852218017
            ]
          },
          "bbox": [
            -6.171305744191651,
            41.94857852218017,
            -6.171305744191651,
            41.94857852218017
          ]
        }
      ],
      "bbox": [
        -6.171305744191651,
        41.94857852218017,
        -6.171305744191651,
        41.94857852218017
      ]
    },
    "49141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.695383485182929,
              41.70942223591862
            ]
          },
          "bbox": [
            -5.695383485182929,
            41.70942223591862,
            -5.695383485182929,
            41.70942223591862
          ]
        }
      ],
      "bbox": [
        -5.695383485182929,
        41.70942223591862,
        -5.695383485182929,
        41.70942223591862
      ]
    },
    "49142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.889331069368229,
              41.61208029421618
            ]
          },
          "bbox": [
            -5.889331069368229,
            41.61208029421618,
            -5.889331069368229,
            41.61208029421618
          ]
        }
      ],
      "bbox": [
        -5.889331069368229,
        41.61208029421618,
        -5.889331069368229,
        41.61208029421618
      ]
    },
    "49143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.5450679711926725,
              42.046123292844584
            ]
          },
          "bbox": [
            -6.5450679711926725,
            42.046123292844584,
            -6.5450679711926725,
            42.046123292844584
          ]
        }
      ],
      "bbox": [
        -6.5450679711926725,
        42.046123292844584,
        -6.5450679711926725,
        42.046123292844584
      ]
    },
    "49145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.688108142955202,
              41.98168897597496
            ]
          },
          "bbox": [
            -6.688108142955202,
            41.98168897597496,
            -6.688108142955202,
            41.98168897597496
          ]
        }
      ],
      "bbox": [
        -6.688108142955202,
        41.98168897597496,
        -6.688108142955202,
        41.98168897597496
      ]
    },
    "49146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.473368102798646,
              41.342381693039464
            ]
          },
          "bbox": [
            -5.473368102798646,
            41.342381693039464,
            -5.473368102798646,
            41.342381693039464
          ]
        }
      ],
      "bbox": [
        -5.473368102798646,
        41.342381693039464,
        -5.473368102798646,
        41.342381693039464
      ]
    },
    "49147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.470004545325646,
              41.488617112490594
            ]
          },
          "bbox": [
            -5.470004545325646,
            41.488617112490594,
            -5.470004545325646,
            41.488617112490594
          ]
        }
      ],
      "bbox": [
        -5.470004545325646,
        41.488617112490594,
        -5.470004545325646,
        41.488617112490594
      ]
    },
    "49148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.69000966557032,
              41.389267357177275
            ]
          },
          "bbox": [
            -5.69000966557032,
            41.389267357177275,
            -5.69000966557032,
            41.389267357177275
          ]
        }
      ],
      "bbox": [
        -5.69000966557032,
        41.389267357177275,
        -5.69000966557032,
        41.389267357177275
      ]
    },
    "49149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.892071430160618,
              41.28729854958969
            ]
          },
          "bbox": [
            -5.892071430160618,
            41.28729854958969,
            -5.892071430160618,
            41.28729854958969
          ]
        }
      ],
      "bbox": [
        -5.892071430160618,
        41.28729854958969,
        -5.892071430160618,
        41.28729854958969
      ]
    },
    "49150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2666591870633885,
              42.0775747046372
            ]
          },
          "bbox": [
            -6.2666591870633885,
            42.0775747046372,
            -6.2666591870633885,
            42.0775747046372
          ]
        }
      ],
      "bbox": [
        -6.2666591870633885,
        42.0775747046372,
        -6.2666591870633885,
        42.0775747046372
      ]
    },
    "49151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.787075774694117,
              41.41566994601327
            ]
          },
          "bbox": [
            -5.787075774694117,
            41.41566994601327,
            -5.787075774694117,
            41.41566994601327
          ]
        }
      ],
      "bbox": [
        -5.787075774694117,
        41.41566994601327,
        -5.787075774694117,
        41.41566994601327
      ]
    },
    "49152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.889750521052813,
              41.409443246634446
            ]
          },
          "bbox": [
            -5.889750521052813,
            41.409443246634446,
            -5.889750521052813,
            41.409443246634446
          ]
        }
      ],
      "bbox": [
        -5.889750521052813,
        41.409443246634446,
        -5.889750521052813,
        41.409443246634446
      ]
    },
    "49153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.852322441996995,
              41.728786813598326
            ]
          },
          "bbox": [
            -5.852322441996995,
            41.728786813598326,
            -5.852322441996995,
            41.728786813598326
          ]
        }
      ],
      "bbox": [
        -5.852322441996995,
        41.728786813598326,
        -5.852322441996995,
        41.728786813598326
      ]
    },
    "49154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.972697000305504,
              42.0956821677046
            ]
          },
          "bbox": [
            -6.972697000305504,
            42.0956821677046,
            -6.972697000305504,
            42.0956821677046
          ]
        }
      ],
      "bbox": [
        -6.972697000305504,
        42.0956821677046,
        -6.972697000305504,
        42.0956821677046
      ]
    },
    "49155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.734277116488009,
              41.6733578016274
            ]
          },
          "bbox": [
            -5.734277116488009,
            41.6733578016274,
            -5.734277116488009,
            41.6733578016274
          ]
        }
      ],
      "bbox": [
        -5.734277116488009,
        41.6733578016274,
        -5.734277116488009,
        41.6733578016274
      ]
    },
    "49156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3597651910218325,
              41.62282178836303
            ]
          },
          "bbox": [
            -5.3597651910218325,
            41.62282178836303,
            -5.3597651910218325,
            41.62282178836303
          ]
        }
      ],
      "bbox": [
        -5.3597651910218325,
        41.62282178836303,
        -5.3597651910218325,
        41.62282178836303
      ]
    },
    "49157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.119847726881118,
              41.58498008590604
            ]
          },
          "bbox": [
            -6.119847726881118,
            41.58498008590604,
            -6.119847726881118,
            41.58498008590604
          ]
        }
      ],
      "bbox": [
        -6.119847726881118,
        41.58498008590604,
        -6.119847726881118,
        41.58498008590604
      ]
    },
    "49158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.593797113174664,
              41.36649466370081
            ]
          },
          "bbox": [
            -5.593797113174664,
            41.36649466370081,
            -5.593797113174664,
            41.36649466370081
          ]
        }
      ],
      "bbox": [
        -5.593797113174664,
        41.36649466370081,
        -5.593797113174664,
        41.36649466370081
      ]
    },
    "49159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7253623088652565,
              42.105458775851226
            ]
          },
          "bbox": [
            -5.7253623088652565,
            42.105458775851226,
            -5.7253623088652565,
            42.105458775851226
          ]
        }
      ],
      "bbox": [
        -5.7253623088652565,
        42.105458775851226,
        -5.7253623088652565,
        42.105458775851226
      ]
    },
    "49160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.528877267270472,
              41.691711629757854
            ]
          },
          "bbox": [
            -5.528877267270472,
            41.691711629757854,
            -5.528877267270472,
            41.691711629757854
          ]
        }
      ],
      "bbox": [
        -5.528877267270472,
        41.691711629757854,
        -5.528877267270472,
        41.691711629757854
      ]
    },
    "49162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.84579976288528,
              42.1570102436483
            ]
          },
          "bbox": [
            -6.84579976288528,
            42.1570102436483,
            -6.84579976288528,
            42.1570102436483
          ]
        }
      ],
      "bbox": [
        -6.84579976288528,
        42.1570102436483,
        -6.84579976288528,
        42.1570102436483
      ]
    },
    "49163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4442512192209405,
              41.58938117970718
            ]
          },
          "bbox": [
            -5.4442512192209405,
            41.58938117970718,
            -5.4442512192209405,
            41.58938117970718
          ]
        }
      ],
      "bbox": [
        -5.4442512192209405,
        41.58938117970718,
        -5.4442512192209405,
        41.58938117970718
      ]
    },
    "49164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.90722831342292,
              41.77968455091013
            ]
          },
          "bbox": [
            -5.90722831342292,
            41.77968455091013,
            -5.90722831342292,
            41.77968455091013
          ]
        }
      ],
      "bbox": [
        -5.90722831342292,
        41.77968455091013,
        -5.90722831342292,
        41.77968455091013
      ]
    },
    "49165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.425982496932758,
              41.928736416529546
            ]
          },
          "bbox": [
            -5.425982496932758,
            41.928736416529546,
            -5.425982496932758,
            41.928736416529546
          ]
        }
      ],
      "bbox": [
        -5.425982496932758,
        41.928736416529546,
        -5.425982496932758,
        41.928736416529546
      ]
    },
    "49166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.598833950967049,
              42.00906631386293
            ]
          },
          "bbox": [
            -6.598833950967049,
            42.00906631386293,
            -6.598833950967049,
            42.00906631386293
          ]
        }
      ],
      "bbox": [
        -6.598833950967049,
        42.00906631386293,
        -6.598833950967049,
        42.00906631386293
      ]
    },
    "49167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9230580149641066,
              41.912949848856904
            ]
          },
          "bbox": [
            -5.9230580149641066,
            41.912949848856904,
            -5.9230580149641066,
            41.912949848856904
          ]
        }
      ],
      "bbox": [
        -5.9230580149641066,
        41.912949848856904,
        -5.9230580149641066,
        41.912949848856904
      ]
    },
    "49168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.336135251279254,
              41.86129078917689
            ]
          },
          "bbox": [
            -5.336135251279254,
            41.86129078917689,
            -5.336135251279254,
            41.86129078917689
          ]
        }
      ],
      "bbox": [
        -5.336135251279254,
        41.86129078917689,
        -5.336135251279254,
        41.86129078917689
      ]
    },
    "49169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.413009258658209,
              41.90679735163431
            ]
          },
          "bbox": [
            -5.413009258658209,
            41.90679735163431,
            -5.413009258658209,
            41.90679735163431
          ]
        }
      ],
      "bbox": [
        -5.413009258658209,
        41.90679735163431,
        -5.413009258658209,
        41.90679735163431
      ]
    },
    "49170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.844871184456865,
              42.03912249672205
            ]
          },
          "bbox": [
            -5.844871184456865,
            42.03912249672205,
            -5.844871184456865,
            42.03912249672205
          ]
        }
      ],
      "bbox": [
        -5.844871184456865,
        42.03912249672205,
        -5.844871184456865,
        42.03912249672205
      ]
    },
    "49171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.821491237346434,
              42.014190844533644
            ]
          },
          "bbox": [
            -5.821491237346434,
            42.014190844533644,
            -5.821491237346434,
            42.014190844533644
          ]
        }
      ],
      "bbox": [
        -5.821491237346434,
        42.014190844533644,
        -5.821491237346434,
        42.014190844533644
      ]
    },
    "49172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.274221053065717,
              41.7375431511553
            ]
          },
          "bbox": [
            -6.274221053065717,
            41.7375431511553,
            -6.274221053065717,
            41.7375431511553
          ]
        }
      ],
      "bbox": [
        -6.274221053065717,
        41.7375431511553,
        -6.274221053065717,
        41.7375431511553
      ]
    },
    "49173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.434500772729291,
              41.72706975234316
            ]
          },
          "bbox": [
            -6.434500772729291,
            41.72706975234316,
            -6.434500772729291,
            41.72706975234316
          ]
        }
      ],
      "bbox": [
        -6.434500772729291,
        41.72706975234316,
        -6.434500772729291,
        41.72706975234316
      ]
    },
    "49174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.77407222312993,
              42.02671622382381
            ]
          },
          "bbox": [
            -6.77407222312993,
            42.02671622382381,
            -6.77407222312993,
            42.02671622382381
          ]
        }
      ],
      "bbox": [
        -6.77407222312993,
        42.02671622382381,
        -6.77407222312993,
        42.02671622382381
      ]
    },
    "49175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.553449735468307,
              41.87673273942104
            ]
          },
          "bbox": [
            -5.553449735468307,
            41.87673273942104,
            -5.553449735468307,
            41.87673273942104
          ]
        }
      ],
      "bbox": [
        -5.553449735468307,
        41.87673273942104,
        -5.553449735468307,
        41.87673273942104
      ]
    },
    "49176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.182958036266246,
              41.83686088985163
            ]
          },
          "bbox": [
            -6.182958036266246,
            41.83686088985163,
            -6.182958036266246,
            41.83686088985163
          ]
        }
      ],
      "bbox": [
        -6.182958036266246,
        41.83686088985163,
        -6.182958036266246,
        41.83686088985163
      ]
    },
    "49177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.234224232714204,
              42.01318793736996
            ]
          },
          "bbox": [
            -6.234224232714204,
            42.01318793736996,
            -6.234224232714204,
            42.01318793736996
          ]
        }
      ],
      "bbox": [
        -6.234224232714204,
        42.01318793736996,
        -6.234224232714204,
        42.01318793736996
      ]
    },
    "49178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.780996227517319,
              41.54722488468553
            ]
          },
          "bbox": [
            -5.780996227517319,
            41.54722488468553,
            -5.780996227517319,
            41.54722488468553
          ]
        }
      ],
      "bbox": [
        -5.780996227517319,
        41.54722488468553,
        -5.780996227517319,
        41.54722488468553
      ]
    },
    "49179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.598424689257935,
              42.083185640009525
            ]
          },
          "bbox": [
            -6.598424689257935,
            42.083185640009525,
            -6.598424689257935,
            42.083185640009525
          ]
        }
      ],
      "bbox": [
        -6.598424689257935,
        42.083185640009525,
        -6.598424689257935,
        42.083185640009525
      ]
    },
    "49180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.174447154164639,
              41.270661086144685
            ]
          },
          "bbox": [
            -6.174447154164639,
            41.270661086144685,
            -6.174447154164639,
            41.270661086144685
          ]
        }
      ],
      "bbox": [
        -6.174447154164639,
        41.270661086144685,
        -6.174447154164639,
        41.270661086144685
      ]
    },
    "49181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.51900809255816,
              42.138132548784775
            ]
          },
          "bbox": [
            -6.51900809255816,
            42.138132548784775,
            -6.51900809255816,
            42.138132548784775
          ]
        }
      ],
      "bbox": [
        -6.51900809255816,
        42.138132548784775,
        -6.51900809255816,
        42.138132548784775
      ]
    },
    "49183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.228470594107356,
              41.27410837017578
            ]
          },
          "bbox": [
            -6.228470594107356,
            41.27410837017578,
            -6.228470594107356,
            41.27410837017578
          ]
        }
      ],
      "bbox": [
        -6.228470594107356,
        41.27410837017578,
        -6.228470594107356,
        41.27410837017578
      ]
    },
    "49184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.160743021758803,
              41.67601707882714
            ]
          },
          "bbox": [
            -6.160743021758803,
            41.67601707882714,
            -6.160743021758803,
            41.67601707882714
          ]
        }
      ],
      "bbox": [
        -6.160743021758803,
        41.67601707882714,
        -6.160743021758803,
        41.67601707882714
      ]
    },
    "49185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.609471473835009,
              41.89722564859266
            ]
          },
          "bbox": [
            -5.609471473835009,
            41.89722564859266,
            -5.609471473835009,
            41.89722564859266
          ]
        }
      ],
      "bbox": [
        -5.609471473835009,
        41.89722564859266,
        -5.609471473835009,
        41.89722564859266
      ]
    },
    "49186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.7871577613045675,
              41.719454812582
            ]
          },
          "bbox": [
            -5.7871577613045675,
            41.719454812582,
            -5.7871577613045675,
            41.719454812582
          ]
        }
      ],
      "bbox": [
        -5.7871577613045675,
        41.719454812582,
        -5.7871577613045675,
        41.719454812582
      ]
    },
    "49187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6359853076343285,
              42.05279782887995
            ]
          },
          "bbox": [
            -5.6359853076343285,
            42.05279782887995,
            -5.6359853076343285,
            42.05279782887995
          ]
        }
      ],
      "bbox": [
        -5.6359853076343285,
        42.05279782887995,
        -5.6359853076343285,
        42.05279782887995
      ]
    },
    "49188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.557189172571298,
              41.938451920673124
            ]
          },
          "bbox": [
            -5.557189172571298,
            41.938451920673124,
            -5.557189172571298,
            41.938451920673124
          ]
        }
      ],
      "bbox": [
        -5.557189172571298,
        41.938451920673124,
        -5.557189172571298,
        41.938451920673124
      ]
    },
    "49189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6433535120319505,
              42.17246001119549
            ]
          },
          "bbox": [
            -6.6433535120319505,
            42.17246001119549,
            -6.6433535120319505,
            42.17246001119549
          ]
        }
      ],
      "bbox": [
        -6.6433535120319505,
        42.17246001119549,
        -6.6433535120319505,
        42.17246001119549
      ]
    },
    "49190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.493824708245921,
              41.79955456795984
            ]
          },
          "bbox": [
            -5.493824708245921,
            41.79955456795984,
            -5.493824708245921,
            41.79955456795984
          ]
        }
      ],
      "bbox": [
        -5.493824708245921,
        41.79955456795984,
        -5.493824708245921,
        41.79955456795984
      ]
    },
    "49191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.555928651124519,
              41.332572835592025
            ]
          },
          "bbox": [
            -5.555928651124519,
            41.332572835592025,
            -5.555928651124519,
            41.332572835592025
          ]
        }
      ],
      "bbox": [
        -5.555928651124519,
        41.332572835592025,
        -5.555928651124519,
        41.332572835592025
      ]
    },
    "49192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.501278505959727,
              42.034699484089046
            ]
          },
          "bbox": [
            -5.501278505959727,
            42.034699484089046,
            -5.501278505959727,
            42.034699484089046
          ]
        }
      ],
      "bbox": [
        -5.501278505959727,
        42.034699484089046,
        -5.501278505959727,
        42.034699484089046
      ]
    },
    "49193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1155452619086725,
              42.04581221198039
            ]
          },
          "bbox": [
            -6.1155452619086725,
            42.04581221198039,
            -6.1155452619086725,
            42.04581221198039
          ]
        }
      ],
      "bbox": [
        -6.1155452619086725,
        42.04581221198039,
        -6.1155452619086725,
        42.04581221198039
      ]
    },
    "49194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.936029532303976,
              41.58551014357913
            ]
          },
          "bbox": [
            -5.936029532303976,
            41.58551014357913,
            -5.936029532303976,
            41.58551014357913
          ]
        }
      ],
      "bbox": [
        -5.936029532303976,
        41.58551014357913,
        -5.936029532303976,
        41.58551014357913
      ]
    },
    "49197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.670286901796498,
              41.34402095727018
            ]
          },
          "bbox": [
            -5.670286901796498,
            41.34402095727018,
            -5.670286901796498,
            41.34402095727018
          ]
        }
      ],
      "bbox": [
        -5.670286901796498,
        41.34402095727018,
        -5.670286901796498,
        41.34402095727018
      ]
    },
    "49199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6848977736174895,
              41.95786375037337
            ]
          },
          "bbox": [
            -5.6848977736174895,
            41.95786375037337,
            -5.6848977736174895,
            41.95786375037337
          ]
        }
      ],
      "bbox": [
        -5.6848977736174895,
        41.95786375037337,
        -5.6848977736174895,
        41.95786375037337
      ]
    },
    "49200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.741361306513967,
              41.990275269243476
            ]
          },
          "bbox": [
            -5.741361306513967,
            41.990275269243476,
            -5.741361306513967,
            41.990275269243476
          ]
        }
      ],
      "bbox": [
        -5.741361306513967,
        41.990275269243476,
        -5.741361306513967,
        41.990275269243476
      ]
    },
    "49201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.968235628771062,
              41.9698414696173
            ]
          },
          "bbox": [
            -5.968235628771062,
            41.9698414696173,
            -5.968235628771062,
            41.9698414696173
          ]
        }
      ],
      "bbox": [
        -5.968235628771062,
        41.9698414696173,
        -5.968235628771062,
        41.9698414696173
      ]
    },
    "49202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.904281415176639,
              41.688512736347626
            ]
          },
          "bbox": [
            -5.904281415176639,
            41.688512736347626,
            -5.904281415176639,
            41.688512736347626
          ]
        }
      ],
      "bbox": [
        -5.904281415176639,
        41.688512736347626,
        -5.904281415176639,
        41.688512736347626
      ]
    },
    "49203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.81650763733597,
              42.079007241442596
            ]
          },
          "bbox": [
            -5.81650763733597,
            42.079007241442596,
            -5.81650763733597,
            42.079007241442596
          ]
        }
      ],
      "bbox": [
        -5.81650763733597,
        42.079007241442596,
        -5.81650763733597,
        42.079007241442596
      ]
    },
    "49204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.931677293043065,
              41.9363165534165
            ]
          },
          "bbox": [
            -5.931677293043065,
            41.9363165534165,
            -5.931677293043065,
            41.9363165534165
          ]
        }
      ],
      "bbox": [
        -5.931677293043065,
        41.9363165534165,
        -5.931677293043065,
        41.9363165534165
      ]
    },
    "49205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.918481649799867,
              41.99370451760303
            ]
          },
          "bbox": [
            -5.918481649799867,
            41.99370451760303,
            -5.918481649799867,
            41.99370451760303
          ]
        }
      ],
      "bbox": [
        -5.918481649799867,
        41.99370451760303,
        -5.918481649799867,
        41.99370451760303
      ]
    },
    "49206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.984593652419923,
              42.07166894225651
            ]
          },
          "bbox": [
            -5.984593652419923,
            42.07166894225651,
            -5.984593652419923,
            42.07166894225651
          ]
        }
      ],
      "bbox": [
        -5.984593652419923,
        42.07166894225651,
        -5.984593652419923,
        42.07166894225651
      ]
    },
    "49207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.697104776482769,
              41.86250226482492
            ]
          },
          "bbox": [
            -5.697104776482769,
            41.86250226482492,
            -5.697104776482769,
            41.86250226482492
          ]
        }
      ],
      "bbox": [
        -5.697104776482769,
        41.86250226482492,
        -5.697104776482769,
        41.86250226482492
      ]
    },
    "49208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.248883361841449,
              41.80367971777958
            ]
          },
          "bbox": [
            -6.248883361841449,
            41.80367971777958,
            -6.248883361841449,
            41.80367971777958
          ]
        }
      ],
      "bbox": [
        -6.248883361841449,
        41.80367971777958,
        -6.248883361841449,
        41.80367971777958
      ]
    },
    "49209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.358791894170228,
              41.78231350820031
            ]
          },
          "bbox": [
            -6.358791894170228,
            41.78231350820031,
            -6.358791894170228,
            41.78231350820031
          ]
        }
      ],
      "bbox": [
        -6.358791894170228,
        41.78231350820031,
        -6.358791894170228,
        41.78231350820031
      ]
    },
    "49210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.572237405466854,
              41.426291361686864
            ]
          },
          "bbox": [
            -5.572237405466854,
            41.426291361686864,
            -5.572237405466854,
            41.426291361686864
          ]
        }
      ],
      "bbox": [
        -5.572237405466854,
        41.426291361686864,
        -5.572237405466854,
        41.426291361686864
      ]
    },
    "49214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.993641985273229,
              41.84563832246709
            ]
          },
          "bbox": [
            -5.993641985273229,
            41.84563832246709,
            -5.993641985273229,
            41.84563832246709
          ]
        }
      ],
      "bbox": [
        -5.993641985273229,
        41.84563832246709,
        -5.993641985273229,
        41.84563832246709
      ]
    },
    "49216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.501742532546476,
              41.854531976238675
            ]
          },
          "bbox": [
            -5.501742532546476,
            41.854531976238675,
            -5.501742532546476,
            41.854531976238675
          ]
        }
      ],
      "bbox": [
        -5.501742532546476,
        41.854531976238675,
        -5.501742532546476,
        41.854531976238675
      ]
    },
    "49219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.436218269946573,
              41.47361111207857
            ]
          },
          "bbox": [
            -5.436218269946573,
            41.47361111207857,
            -5.436218269946573,
            41.47361111207857
          ]
        }
      ],
      "bbox": [
        -5.436218269946573,
        41.47361111207857,
        -5.436218269946573,
        41.47361111207857
      ]
    },
    "49220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.705835579634312,
              42.09334565893818
            ]
          },
          "bbox": [
            -5.705835579634312,
            42.09334565893818,
            -5.705835579634312,
            42.09334565893818
          ]
        }
      ],
      "bbox": [
        -5.705835579634312,
        42.09334565893818,
        -5.705835579634312,
        42.09334565893818
      ]
    },
    "49221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.214774905144051,
              41.490417451496214
            ]
          },
          "bbox": [
            -6.214774905144051,
            41.490417451496214,
            -6.214774905144051,
            41.490417451496214
          ]
        }
      ],
      "bbox": [
        -6.214774905144051,
        41.490417451496214,
        -6.214774905144051,
        41.490417451496214
      ]
    },
    "49222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.680511978780972,
              41.63318471304834
            ]
          },
          "bbox": [
            -5.680511978780972,
            41.63318471304834,
            -5.680511978780972,
            41.63318471304834
          ]
        }
      ],
      "bbox": [
        -5.680511978780972,
        41.63318471304834,
        -5.680511978780972,
        41.63318471304834
      ]
    },
    "49223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.513494122877961,
              41.73441478479479
            ]
          },
          "bbox": [
            -6.513494122877961,
            41.73441478479479,
            -6.513494122877961,
            41.73441478479479
          ]
        }
      ],
      "bbox": [
        -6.513494122877961,
        41.73441478479479,
        -6.513494122877961,
        41.73441478479479
      ]
    },
    "49224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.675538051489446,
              42.1498135603905
            ]
          },
          "bbox": [
            -6.675538051489446,
            42.1498135603905,
            -6.675538051489446,
            42.1498135603905
          ]
        }
      ],
      "bbox": [
        -6.675538051489446,
        42.1498135603905,
        -6.675538051489446,
        42.1498135603905
      ]
    },
    "49225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.137715381989873,
              42.088456148071984
            ]
          },
          "bbox": [
            -6.137715381989873,
            42.088456148071984,
            -6.137715381989873,
            42.088456148071984
          ]
        }
      ],
      "bbox": [
        -6.137715381989873,
        42.088456148071984,
        -6.137715381989873,
        42.088456148071984
      ]
    },
    "49226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.339255691557559,
              41.29047922341304
            ]
          },
          "bbox": [
            -5.339255691557559,
            41.29047922341304,
            -5.339255691557559,
            41.29047922341304
          ]
        }
      ],
      "bbox": [
        -5.339255691557559,
        41.29047922341304,
        -5.339255691557559,
        41.29047922341304
      ]
    },
    "49227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.747975265727703,
              41.545106854367155
            ]
          },
          "bbox": [
            -5.747975265727703,
            41.545106854367155,
            -5.747975265727703,
            41.545106854367155
          ]
        }
      ],
      "bbox": [
        -5.747975265727703,
        41.545106854367155,
        -5.747975265727703,
        41.545106854367155
      ]
    },
    "49228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.46082550962251,
              41.43078984892463
            ]
          },
          "bbox": [
            -5.46082550962251,
            41.43078984892463,
            -5.46082550962251,
            41.43078984892463
          ]
        }
      ],
      "bbox": [
        -5.46082550962251,
        41.43078984892463,
        -5.46082550962251,
        41.43078984892463
      ]
    },
    "49229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.526068870227547,
              42.033967283962575
            ]
          },
          "bbox": [
            -5.526068870227547,
            42.033967283962575,
            -5.526068870227547,
            42.033967283962575
          ]
        }
      ],
      "bbox": [
        -5.526068870227547,
        42.033967283962575,
        -5.526068870227547,
        42.033967283962575
      ]
    },
    "49230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.319442034815128,
              41.1501417013207
            ]
          },
          "bbox": [
            -5.319442034815128,
            41.1501417013207,
            -5.319442034815128,
            41.1501417013207
          ]
        }
      ],
      "bbox": [
        -5.319442034815128,
        41.1501417013207,
        -5.319442034815128,
        41.1501417013207
      ]
    },
    "49231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.138562291369002,
              42.00522440001083
            ]
          },
          "bbox": [
            -6.138562291369002,
            42.00522440001083,
            -6.138562291369002,
            42.00522440001083
          ]
        }
      ],
      "bbox": [
        -6.138562291369002,
        42.00522440001083,
        -6.138562291369002,
        42.00522440001083
      ]
    },
    "49232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.472769338331216,
              41.974109406941764
            ]
          },
          "bbox": [
            -5.472769338331216,
            41.974109406941764,
            -5.472769338331216,
            41.974109406941764
          ]
        }
      ],
      "bbox": [
        -5.472769338331216,
        41.974109406941764,
        -5.472769338331216,
        41.974109406941764
      ]
    },
    "49233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.10269352262956,
              41.710537369001806
            ]
          },
          "bbox": [
            -6.10269352262956,
            41.710537369001806,
            -6.10269352262956,
            41.710537369001806
          ]
        }
      ],
      "bbox": [
        -6.10269352262956,
        41.710537369001806,
        -6.10269352262956,
        41.710537369001806
      ]
    },
    "49234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.535482672012252,
              41.379381563554446
            ]
          },
          "bbox": [
            -5.535482672012252,
            41.379381563554446,
            -5.535482672012252,
            41.379381563554446
          ]
        }
      ],
      "bbox": [
        -5.535482672012252,
        41.379381563554446,
        -5.535482672012252,
        41.379381563554446
      ]
    },
    "49235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3689437110717515,
              41.673120291749726
            ]
          },
          "bbox": [
            -5.3689437110717515,
            41.673120291749726,
            -5.3689437110717515,
            41.673120291749726
          ]
        }
      ],
      "bbox": [
        -5.3689437110717515,
        41.673120291749726,
        -5.3689437110717515,
        41.673120291749726
      ]
    },
    "49236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.585915447415746,
              41.91843058565009
            ]
          },
          "bbox": [
            -5.585915447415746,
            41.91843058565009,
            -5.585915447415746,
            41.91843058565009
          ]
        }
      ],
      "bbox": [
        -5.585915447415746,
        41.91843058565009,
        -5.585915447415746,
        41.91843058565009
      ]
    },
    "49237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.025754475592528,
              41.61097831540388
            ]
          },
          "bbox": [
            -6.025754475592528,
            41.61097831540388,
            -6.025754475592528,
            41.61097831540388
          ]
        }
      ],
      "bbox": [
        -6.025754475592528,
        41.61097831540388,
        -6.025754475592528,
        41.61097831540388
      ]
    },
    "49238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.709027844365496,
              42.06270770249286
            ]
          },
          "bbox": [
            -5.709027844365496,
            42.06270770249286,
            -5.709027844365496,
            42.06270770249286
          ]
        }
      ],
      "bbox": [
        -5.709027844365496,
        42.06270770249286,
        -5.709027844365496,
        42.06270770249286
      ]
    },
    "49239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.391912738435515,
              41.37834954926128
            ]
          },
          "bbox": [
            -5.391912738435515,
            41.37834954926128,
            -5.391912738435515,
            41.37834954926128
          ]
        }
      ],
      "bbox": [
        -5.391912738435515,
        41.37834954926128,
        -5.391912738435515,
        41.37834954926128
      ]
    },
    "49240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.143514482454794,
              41.54526769359901
            ]
          },
          "bbox": [
            -6.143514482454794,
            41.54526769359901,
            -6.143514482454794,
            41.54526769359901
          ]
        }
      ],
      "bbox": [
        -6.143514482454794,
        41.54526769359901,
        -6.143514482454794,
        41.54526769359901
      ]
    },
    "49241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.455132591713848,
              41.20046261218645
            ]
          },
          "bbox": [
            -5.455132591713848,
            41.20046261218645,
            -5.455132591713848,
            41.20046261218645
          ]
        }
      ],
      "bbox": [
        -5.455132591713848,
        41.20046261218645,
        -5.455132591713848,
        41.20046261218645
      ]
    },
    "49242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.600167813106341,
              41.83745644849087
            ]
          },
          "bbox": [
            -5.600167813106341,
            41.83745644849087,
            -5.600167813106341,
            41.83745644849087
          ]
        }
      ],
      "bbox": [
        -5.600167813106341,
        41.83745644849087,
        -5.600167813106341,
        41.83745644849087
      ]
    },
    "49243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.871677860856662,
              42.09027446928413
            ]
          },
          "bbox": [
            -5.871677860856662,
            42.09027446928413,
            -5.871677860856662,
            42.09027446928413
          ]
        }
      ],
      "bbox": [
        -5.871677860856662,
        42.09027446928413,
        -5.871677860856662,
        42.09027446928413
      ]
    },
    "49244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.953917006755951,
              42.11523658651432
            ]
          },
          "bbox": [
            -5.953917006755951,
            42.11523658651432,
            -5.953917006755951,
            42.11523658651432
          ]
        }
      ],
      "bbox": [
        -5.953917006755951,
        42.11523658651432,
        -5.953917006755951,
        42.11523658651432
      ]
    },
    "49245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.579767732238789,
              41.49875656918527
            ]
          },
          "bbox": [
            -5.579767732238789,
            41.49875656918527,
            -5.579767732238789,
            41.49875656918527
          ]
        }
      ],
      "bbox": [
        -5.579767732238789,
        41.49875656918527,
        -5.579767732238789,
        41.49875656918527
      ]
    },
    "49246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6117528708315625,
              41.75747113800181
            ]
          },
          "bbox": [
            -5.6117528708315625,
            41.75747113800181,
            -5.6117528708315625,
            41.75747113800181
          ]
        }
      ],
      "bbox": [
        -5.6117528708315625,
        41.75747113800181,
        -5.6117528708315625,
        41.75747113800181
      ]
    },
    "49247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.05572666582627,
              41.526487229840285
            ]
          },
          "bbox": [
            -6.05572666582627,
            41.526487229840285,
            -6.05572666582627,
            41.526487229840285
          ]
        }
      ],
      "bbox": [
        -6.05572666582627,
        41.526487229840285,
        -6.05572666582627,
        41.526487229840285
      ]
    },
    "49248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.491082856009194,
              41.94541291024739
            ]
          },
          "bbox": [
            -5.491082856009194,
            41.94541291024739,
            -5.491082856009194,
            41.94541291024739
          ]
        }
      ],
      "bbox": [
        -5.491082856009194,
        41.94541291024739,
        -5.491082856009194,
        41.94541291024739
      ]
    },
    "49249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.300288289639963,
              41.59417545131309
            ]
          },
          "bbox": [
            -5.300288289639963,
            41.59417545131309,
            -5.300288289639963,
            41.59417545131309
          ]
        }
      ],
      "bbox": [
        -5.300288289639963,
        41.59417545131309,
        -5.300288289639963,
        41.59417545131309
      ]
    },
    "49250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.406380604993803,
              41.825128797624224
            ]
          },
          "bbox": [
            -5.406380604993803,
            41.825128797624224,
            -5.406380604993803,
            41.825128797624224
          ]
        }
      ],
      "bbox": [
        -5.406380604993803,
        41.825128797624224,
        -5.406380604993803,
        41.825128797624224
      ]
    },
    "49251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5434460339366485,
              41.61723188655842
            ]
          },
          "bbox": [
            -5.5434460339366485,
            41.61723188655842,
            -5.5434460339366485,
            41.61723188655842
          ]
        }
      ],
      "bbox": [
        -5.5434460339366485,
        41.61723188655842,
        -5.5434460339366485,
        41.61723188655842
      ]
    },
    "49252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3582746084393875,
              41.903725398402415
            ]
          },
          "bbox": [
            -5.3582746084393875,
            41.903725398402415,
            -5.3582746084393875,
            41.903725398402415
          ]
        }
      ],
      "bbox": [
        -5.3582746084393875,
        41.903725398402415,
        -5.3582746084393875,
        41.903725398402415
      ]
    },
    "49255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.5712686692446995,
              41.252319228510224
            ]
          },
          "bbox": [
            -5.5712686692446995,
            41.252319228510224,
            -5.5712686692446995,
            41.252319228510224
          ]
        }
      ],
      "bbox": [
        -5.5712686692446995,
        41.252319228510224,
        -5.5712686692446995,
        41.252319228510224
      ]
    },
    "49256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.785542967480403,
              41.97036071978953
            ]
          },
          "bbox": [
            -5.785542967480403,
            41.97036071978953,
            -5.785542967480403,
            41.97036071978953
          ]
        }
      ],
      "bbox": [
        -5.785542967480403,
        41.97036071978953,
        -5.785542967480403,
        41.97036071978953
      ]
    },
    "49257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.638446829170485,
              41.96568428344205
            ]
          },
          "bbox": [
            -5.638446829170485,
            41.96568428344205,
            -5.638446829170485,
            41.96568428344205
          ]
        }
      ],
      "bbox": [
        -5.638446829170485,
        41.96568428344205,
        -5.638446829170485,
        41.96568428344205
      ]
    },
    "49258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.77803356819569,
              41.35123010212649
            ]
          },
          "bbox": [
            -5.77803356819569,
            41.35123010212649,
            -5.77803356819569,
            41.35123010212649
          ]
        }
      ],
      "bbox": [
        -5.77803356819569,
        41.35123010212649,
        -5.77803356819569,
        41.35123010212649
      ]
    },
    "49259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.98071037285273,
              41.92461967138357
            ]
          },
          "bbox": [
            -5.98071037285273,
            41.92461967138357,
            -5.98071037285273,
            41.92461967138357
          ]
        }
      ],
      "bbox": [
        -5.98071037285273,
        41.92461967138357,
        -5.98071037285273,
        41.92461967138357
      ]
    },
    "49260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.40757719247548,
              41.98526394641183
            ]
          },
          "bbox": [
            -5.40757719247548,
            41.98526394641183,
            -5.40757719247548,
            41.98526394641183
          ]
        }
      ],
      "bbox": [
        -5.40757719247548,
        41.98526394641183,
        -5.40757719247548,
        41.98526394641183
      ]
    },
    "49261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6695323419494095,
              41.49612184270863
            ]
          },
          "bbox": [
            -5.6695323419494095,
            41.49612184270863,
            -5.6695323419494095,
            41.49612184270863
          ]
        }
      ],
      "bbox": [
        -5.6695323419494095,
        41.49612184270863,
        -5.6695323419494095,
        41.49612184270863
      ]
    },
    "49262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.291755246299142,
              41.94376074542392
            ]
          },
          "bbox": [
            -6.291755246299142,
            41.94376074542392,
            -6.291755246299142,
            41.94376074542392
          ]
        }
      ],
      "bbox": [
        -6.291755246299142,
        41.94376074542392,
        -6.291755246299142,
        41.94376074542392
      ]
    },
    "49263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.340163919712661,
              41.925062821264575
            ]
          },
          "bbox": [
            -5.340163919712661,
            41.925062821264575,
            -5.340163919712661,
            41.925062821264575
          ]
        }
      ],
      "bbox": [
        -5.340163919712661,
        41.925062821264575,
        -5.340163919712661,
        41.925062821264575
      ]
    },
    "49264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.274767427839068,
              41.327799837417416
            ]
          },
          "bbox": [
            -6.274767427839068,
            41.327799837417416,
            -6.274767427839068,
            41.327799837417416
          ]
        }
      ],
      "bbox": [
        -6.274767427839068,
        41.327799837417416,
        -6.274767427839068,
        41.327799837417416
      ]
    },
    "49265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.195749288381974,
              41.536019570792384
            ]
          },
          "bbox": [
            -6.195749288381974,
            41.536019570792384,
            -6.195749288381974,
            41.536019570792384
          ]
        }
      ],
      "bbox": [
        -6.195749288381974,
        41.536019570792384,
        -6.195749288381974,
        41.536019570792384
      ]
    },
    "49266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.48252139926691,
              41.82192944449336
            ]
          },
          "bbox": [
            -5.48252139926691,
            41.82192944449336,
            -5.48252139926691,
            41.82192944449336
          ]
        }
      ],
      "bbox": [
        -5.48252139926691,
        41.82192944449336,
        -5.48252139926691,
        41.82192944449336
      ]
    },
    "49267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.395031615097056,
              41.57689122337594
            ]
          },
          "bbox": [
            -5.395031615097056,
            41.57689122337594,
            -5.395031615097056,
            41.57689122337594
          ]
        }
      ],
      "bbox": [
        -5.395031615097056,
        41.57689122337594,
        -5.395031615097056,
        41.57689122337594
      ]
    },
    "49268": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.656921091023307,
              41.79942714603893
            ]
          },
          "bbox": [
            -5.656921091023307,
            41.79942714603893,
            -5.656921091023307,
            41.79942714603893
          ]
        }
      ],
      "bbox": [
        -5.656921091023307,
        41.79942714603893,
        -5.656921091023307,
        41.79942714603893
      ]
    },
    "49269": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.991319953579403,
              41.47471791322689
            ]
          },
          "bbox": [
            -5.991319953579403,
            41.47471791322689,
            -5.991319953579403,
            41.47471791322689
          ]
        }
      ],
      "bbox": [
        -5.991319953579403,
        41.47471791322689,
        -5.991319953579403,
        41.47471791322689
      ]
    },
    "49270": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.339192670704962,
              41.57881964241141
            ]
          },
          "bbox": [
            -5.339192670704962,
            41.57881964241141,
            -5.339192670704962,
            41.57881964241141
          ]
        }
      ],
      "bbox": [
        -5.339192670704962,
        41.57881964241141,
        -5.339192670704962,
        41.57881964241141
      ]
    },
    "49271": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.674711859675779,
              41.906100900456494
            ]
          },
          "bbox": [
            -5.674711859675779,
            41.906100900456494,
            -5.674711859675779,
            41.906100900456494
          ]
        }
      ],
      "bbox": [
        -5.674711859675779,
        41.906100900456494,
        -5.674711859675779,
        41.906100900456494
      ]
    },
    "49272": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.850856064494651,
              41.93872598668338
            ]
          },
          "bbox": [
            -5.850856064494651,
            41.93872598668338,
            -5.850856064494651,
            41.93872598668338
          ]
        }
      ],
      "bbox": [
        -5.850856064494651,
        41.93872598668338,
        -5.850856064494651,
        41.93872598668338
      ]
    },
    "49273": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.456200321948141,
              41.78600304042912
            ]
          },
          "bbox": [
            -6.456200321948141,
            41.78600304042912,
            -6.456200321948141,
            41.78600304042912
          ]
        }
      ],
      "bbox": [
        -6.456200321948141,
        41.78600304042912,
        -6.456200321948141,
        41.78600304042912
      ]
    },
    "49275": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.805175596432104,
              41.516497354456575
            ]
          },
          "bbox": [
            -5.805175596432104,
            41.516497354456575,
            -5.805175596432104,
            41.516497354456575
          ]
        }
      ],
      "bbox": [
        -5.805175596432104,
        41.516497354456575,
        -5.805175596432104,
        41.516497354456575
      ]
    },
    "50001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6908730280278181,
              41.134737512338184
            ]
          },
          "bbox": [
            -1.6908730280278181,
            41.134737512338184,
            -1.6908730280278181,
            41.134737512338184
          ]
        }
      ],
      "bbox": [
        -1.6908730280278181,
        41.134737512338184,
        -1.6908730280278181,
        41.134737512338184
      ]
    },
    "50002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6024007453464089,
              41.15967942753154
            ]
          },
          "bbox": [
            -1.6024007453464089,
            41.15967942753154,
            -1.6024007453464089,
            41.15967942753154
          ]
        }
      ],
      "bbox": [
        -1.6024007453464089,
        41.15967942753154,
        -1.6024007453464089,
        41.15967942753154
      ]
    },
    "50003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4352578205673752,
              41.84668332646723
            ]
          },
          "bbox": [
            -1.4352578205673752,
            41.84668332646723,
            -1.4352578205673752,
            41.84668332646723
          ]
        }
      ],
      "bbox": [
        -1.4352578205673752,
        41.84668332646723,
        -1.4352578205673752,
        41.84668332646723
      ]
    },
    "50004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.289100422878705,
              41.33599313431361
            ]
          },
          "bbox": [
            -1.289100422878705,
            41.33599313431361,
            -1.289100422878705,
            41.33599313431361
          ]
        }
      ],
      "bbox": [
        -1.289100422878705,
        41.33599313431361,
        -1.289100422878705,
        41.33599313431361
      ]
    },
    "50005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0165298814484622,
              41.290016466877105
            ]
          },
          "bbox": [
            -1.0165298814484622,
            41.290016466877105,
            -1.0165298814484622,
            41.290016466877105
          ]
        }
      ],
      "bbox": [
        -1.0165298814484622,
        41.290016466877105,
        -1.0165298814484622,
        41.290016466877105
      ]
    },
    "50006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5303858846067304,
              41.77213021032354
            ]
          },
          "bbox": [
            -1.5303858846067304,
            41.77213021032354,
            -1.5303858846067304,
            41.77213021032354
          ]
        }
      ],
      "bbox": [
        -1.5303858846067304,
        41.77213021032354,
        -1.5303858846067304,
        41.77213021032354
      ]
    },
    "50007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1563614415059966,
              41.24786738504378
            ]
          },
          "bbox": [
            -1.1563614415059966,
            41.24786738504378,
            -1.1563614415059966,
            41.24786738504378
          ]
        }
      ],
      "bbox": [
        -1.1563614415059966,
        41.24786738504378,
        -1.1563614415059966,
        41.24786738504378
      ]
    },
    "50008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1231466678425792,
              41.77200110940644
            ]
          },
          "bbox": [
            -1.1231466678425792,
            41.77200110940644,
            -1.1231466678425792,
            41.77200110940644
          ]
        }
      ],
      "bbox": [
        -1.1231466678425792,
        41.77200110940644,
        -1.1231466678425792,
        41.77200110940644
      ]
    },
    "50009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.614593571965268,
              41.20682934289557
            ]
          },
          "bbox": [
            -1.614593571965268,
            41.20682934289557,
            -1.614593571965268,
            41.20682934289557
          ]
        }
      ],
      "bbox": [
        -1.614593571965268,
        41.20682934289557,
        -1.614593571965268,
        41.20682934289557
      ]
    },
    "50010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4617974532314053,
              41.80226380775202
            ]
          },
          "bbox": [
            -1.4617974532314053,
            41.80226380775202,
            -1.4617974532314053,
            41.80226380775202
          ]
        }
      ],
      "bbox": [
        -1.4617974532314053,
        41.80226380775202,
        -1.4617974532314053,
        41.80226380775202
      ]
    },
    "50011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5015410548457513,
              41.826489580021644
            ]
          },
          "bbox": [
            -1.5015410548457513,
            41.826489580021644,
            -1.5015410548457513,
            41.826489580021644
          ]
        }
      ],
      "bbox": [
        -1.5015410548457513,
        41.826489580021644,
        -1.5015410548457513,
        41.826489580021644
      ]
    },
    "50012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3552994238096882,
              41.33925153551419
            ]
          },
          "bbox": [
            -0.3552994238096882,
            41.33925153551419,
            -0.3552994238096882,
            41.33925153551419
          ]
        }
      ],
      "bbox": [
        -0.3552994238096882,
        41.33925153551419,
        -0.3552994238096882,
        41.33925153551419
      ]
    },
    "50013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1820256112379146,
              41.817063705700804
            ]
          },
          "bbox": [
            -1.1820256112379146,
            41.817063705700804,
            -1.1820256112379146,
            41.817063705700804
          ]
        }
      ],
      "bbox": [
        -1.1820256112379146,
        41.817063705700804,
        -1.1820256112379146,
        41.817063705700804
      ]
    },
    "50014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6874781593143315,
              41.79797926895581
            ]
          },
          "bbox": [
            -1.6874781593143315,
            41.79797926895581,
            -1.6874781593143315,
            41.79797926895581
          ]
        }
      ],
      "bbox": [
        -1.6874781593143315,
        41.79797926895581,
        -1.6874781593143315,
        41.79797926895581
      ]
    },
    "50015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.10612715599482,
              41.20246756955482
            ]
          },
          "bbox": [
            -2.10612715599482,
            41.20246756955482,
            -2.10612715599482,
            41.20246756955482
          ]
        }
      ],
      "bbox": [
        -2.10612715599482,
        41.20246756955482,
        -2.10612715599482,
        41.20246756955482
      ]
    },
    "50016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7103961559648067,
              41.05967721556797
            ]
          },
          "bbox": [
            -1.7103961559648067,
            41.05967721556797,
            -1.7103961559648067,
            41.05967721556797
          ]
        }
      ],
      "bbox": [
        -1.7103961559648067,
        41.05967721556797,
        -1.7103961559648067,
        41.05967721556797
      ]
    },
    "50017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.648736789123569,
              41.63218254954605
            ]
          },
          "bbox": [
            -0.648736789123569,
            41.63218254954605,
            -0.648736789123569,
            41.63218254954605
          ]
        }
      ],
      "bbox": [
        -0.648736789123569,
        41.63218254954605,
        -0.648736789123569,
        41.63218254954605
      ]
    },
    "50018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2324291289546103,
              41.46076115268067
            ]
          },
          "bbox": [
            -1.2324291289546103,
            41.46076115268067,
            -1.2324291289546103,
            41.46076115268067
          ]
        }
      ],
      "bbox": [
        -1.2324291289546103,
        41.46076115268067,
        -1.2324291289546103,
        41.46076115268067
      ]
    },
    "50019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.38825236630106247,
              41.34724529001817
            ]
          },
          "bbox": [
            -0.38825236630106247,
            41.34724529001817,
            -0.38825236630106247,
            41.34724529001817
          ]
        }
      ],
      "bbox": [
        -0.38825236630106247,
        41.34724529001817,
        -0.38825236630106247,
        41.34724529001817
      ]
    },
    "50020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9017147938635943,
              41.29800734337522
            ]
          },
          "bbox": [
            -1.9017147938635943,
            41.29800734337522,
            -1.9017147938635943,
            41.29800734337522
          ]
        }
      ],
      "bbox": [
        -1.9017147938635943,
        41.29800734337522,
        -1.9017147938635943,
        41.29800734337522
      ]
    },
    "50021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5519560201589732,
              41.284326107364876
            ]
          },
          "bbox": [
            -0.5519560201589732,
            41.284326107364876,
            -0.5519560201589732,
            41.284326107364876
          ]
        }
      ],
      "bbox": [
        -0.5519560201589732,
        41.284326107364876,
        -0.5519560201589732,
        41.284326107364876
      ]
    },
    "50022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24270718756828505,
              41.541314458368646
            ]
          },
          "bbox": [
            -0.24270718756828505,
            41.541314458368646,
            -0.24270718756828505,
            41.541314458368646
          ]
        }
      ],
      "bbox": [
        -0.24270718756828505,
        41.541314458368646,
        -0.24270718756828505,
        41.541314458368646
      ]
    },
    "50023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8120743507924664,
              41.28386875448558
            ]
          },
          "bbox": [
            -0.8120743507924664,
            41.28386875448558,
            -0.8120743507924664,
            41.28386875448558
          ]
        }
      ],
      "bbox": [
        -0.8120743507924664,
        41.28386875448558,
        -0.8120743507924664,
        41.28386875448558
      ]
    },
    "50024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3206728862924302,
              41.41423352541755
            ]
          },
          "bbox": [
            -1.3206728862924302,
            41.41423352541755,
            -1.3206728862924302,
            41.41423352541755
          ]
        }
      ],
      "bbox": [
        -1.3206728862924302,
        41.41423352541755,
        -1.3206728862924302,
        41.41423352541755
      ]
    },
    "50025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.359812611523162,
              41.47165439576894
            ]
          },
          "bbox": [
            -1.359812611523162,
            41.47165439576894,
            -1.359812611523162,
            41.47165439576894
          ]
        }
      ],
      "bbox": [
        -1.359812611523162,
        41.47165439576894,
        -1.359812611523162,
        41.47165439576894
      ]
    },
    "50026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3806243918377132,
              41.404814727334085
            ]
          },
          "bbox": [
            -1.3806243918377132,
            41.404814727334085,
            -1.3806243918377132,
            41.404814727334085
          ]
        }
      ],
      "bbox": [
        -1.3806243918377132,
        41.404814727334085,
        -1.3806243918377132,
        41.404814727334085
      ]
    },
    "50027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6215310345653522,
              41.7693519531084
            ]
          },
          "bbox": [
            -1.6215310345653522,
            41.7693519531084,
            -1.6215310345653522,
            41.7693519531084
          ]
        }
      ],
      "bbox": [
        -1.6215310345653522,
        41.7693519531084,
        -1.6215310345653522,
        41.7693519531084
      ]
    },
    "50028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3236429812494073,
              41.07047204208543
            ]
          },
          "bbox": [
            -1.3236429812494073,
            41.07047204208543,
            -1.3236429812494073,
            41.07047204208543
          ]
        }
      ],
      "bbox": [
        -1.3236429812494073,
        41.07047204208543,
        -1.3236429812494073,
        41.07047204208543
      ]
    },
    "50029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7067745557838645,
              41.477334795135
            ]
          },
          "bbox": [
            -1.7067745557838645,
            41.477334795135,
            -1.7067745557838645,
            41.477334795135
          ]
        }
      ],
      "bbox": [
        -1.7067745557838645,
        41.477334795135,
        -1.7067745557838645,
        41.477334795135
      ]
    },
    "50030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7529985018909888,
              41.75872111675126
            ]
          },
          "bbox": [
            -1.7529985018909888,
            41.75872111675126,
            -1.7529985018909888,
            41.75872111675126
          ]
        }
      ],
      "bbox": [
        -1.7529985018909888,
        41.75872111675126,
        -1.7529985018909888,
        41.75872111675126
      ]
    },
    "50031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7860266759195134,
              41.59011982887987
            ]
          },
          "bbox": [
            -1.7860266759195134,
            41.59011982887987,
            -1.7860266759195134,
            41.59011982887987
          ]
        }
      ],
      "bbox": [
        -1.7860266759195134,
        41.59011982887987,
        -1.7860266759195134,
        41.59011982887987
      ]
    },
    "50032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5019290753802275,
              41.518768686604766
            ]
          },
          "bbox": [
            -1.5019290753802275,
            41.518768686604766,
            -1.5019290753802275,
            41.518768686604766
          ]
        }
      ],
      "bbox": [
        -1.5019290753802275,
        41.518768686604766,
        -1.5019290753802275,
        41.518768686604766
      ]
    },
    "50033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7829488265656438,
              42.194515969861804
            ]
          },
          "bbox": [
            -0.7829488265656438,
            42.194515969861804,
            -0.7829488265656438,
            42.194515969861804
          ]
        }
      ],
      "bbox": [
        -0.7829488265656438,
        42.194515969861804,
        -0.7829488265656438,
        42.194515969861804
      ]
    },
    "50034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.045085992379688,
              41.32344018721444
            ]
          },
          "bbox": [
            -2.045085992379688,
            41.32344018721444,
            -2.045085992379688,
            41.32344018721444
          ]
        }
      ],
      "bbox": [
        -2.045085992379688,
        41.32344018721444,
        -2.045085992379688,
        41.32344018721444
      ]
    },
    "50035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9923506207837126,
              42.590070394300064
            ]
          },
          "bbox": [
            -0.9923506207837126,
            42.590070394300064,
            -0.9923506207837126,
            42.590070394300064
          ]
        }
      ],
      "bbox": [
        -0.9923506207837126,
        42.590070394300064,
        -0.9923506207837126,
        42.590070394300064
      ]
    },
    "50036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.055720383199125,
              42.270136252493586
            ]
          },
          "bbox": [
            -1.055720383199125,
            42.270136252493586,
            -1.055720383199125,
            42.270136252493586
          ]
        }
      ],
      "bbox": [
        -1.055720383199125,
        42.270136252493586,
        -1.055720383199125,
        42.270136252493586
      ]
    },
    "50037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5562165184540384,
              41.14382475842161
            ]
          },
          "bbox": [
            -1.5562165184540384,
            41.14382475842161,
            -1.5562165184540384,
            41.14382475842161
          ]
        }
      ],
      "bbox": [
        -1.5562165184540384,
        41.14382475842161,
        -1.5562165184540384,
        41.14382475842161
      ]
    },
    "50038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8103559630128534,
              41.346852022351854
            ]
          },
          "bbox": [
            -1.8103559630128534,
            41.346852022351854,
            -1.8103559630128534,
            41.346852022351854
          ]
        }
      ],
      "bbox": [
        -1.8103559630128534,
        41.346852022351854,
        -1.8103559630128534,
        41.346852022351854
      ]
    },
    "50039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9061855740275887,
              41.239064995003964
            ]
          },
          "bbox": [
            -0.9061855740275887,
            41.239064995003964,
            -0.9061855740275887,
            41.239064995003964
          ]
        }
      ],
      "bbox": [
        -0.9061855740275887,
        41.239064995003964,
        -0.9061855740275887,
        41.239064995003964
      ]
    },
    "50040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2388324704157323,
              41.15309728075709
            ]
          },
          "bbox": [
            -1.2388324704157323,
            41.15309728075709,
            -1.2388324704157323,
            41.15309728075709
          ]
        }
      ],
      "bbox": [
        -1.2388324704157323,
        41.15309728075709,
        -1.2388324704157323,
        41.15309728075709
      ]
    },
    "50041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9406412471105808,
              42.539621048242964
            ]
          },
          "bbox": [
            -0.9406412471105808,
            42.539621048242964,
            -0.9406412471105808,
            42.539621048242964
          ]
        }
      ],
      "bbox": [
        -0.9406412471105808,
        42.539621048242964,
        -0.9406412471105808,
        42.539621048242964
      ]
    },
    "50042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4822646770481374,
              41.07649407581715
            ]
          },
          "bbox": [
            -1.4822646770481374,
            41.07649407581715,
            -1.4822646770481374,
            41.07649407581715
          ]
        }
      ],
      "bbox": [
        -1.4822646770481374,
        41.07649407581715,
        -1.4822646770481374,
        41.07649407581715
      ]
    },
    "50043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.170957584058606,
              41.7154582627397
            ]
          },
          "bbox": [
            -1.170957584058606,
            41.7154582627397,
            -1.170957584058606,
            41.7154582627397
          ]
        }
      ],
      "bbox": [
        -1.170957584058606,
        41.7154582627397,
        -1.170957584058606,
        41.7154582627397
      ]
    },
    "50044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1900710173881,
              41.67686228214888
            ]
          },
          "bbox": [
            -1.1900710173881,
            41.67686228214888,
            -1.1900710173881,
            41.67686228214888
          ]
        }
      ],
      "bbox": [
        -1.1900710173881,
        41.67686228214888,
        -1.1900710173881,
        41.67686228214888
      ]
    },
    "50045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7045229512626392,
              41.33284987803235
            ]
          },
          "bbox": [
            -0.7045229512626392,
            41.33284987803235,
            -0.7045229512626392,
            41.33284987803235
          ]
        }
      ],
      "bbox": [
        -0.7045229512626392,
        41.33284987803235,
        -0.7045229512626392,
        41.33284987803235
      ]
    },
    "50046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4993208100084114,
              41.32840798745129
            ]
          },
          "bbox": [
            -1.4993208100084114,
            41.32840798745129,
            -1.4993208100084114,
            41.32840798745129
          ]
        }
      ],
      "bbox": [
        -1.4993208100084114,
        41.32840798745129,
        -1.4993208100084114,
        41.32840798745129
      ]
    },
    "50047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9578303407058855,
              41.56779967676233
            ]
          },
          "bbox": [
            -1.9578303407058855,
            41.56779967676233,
            -1.9578303407058855,
            41.56779967676233
          ]
        }
      ],
      "bbox": [
        -1.9578303407058855,
        41.56779967676233,
        -1.9578303407058855,
        41.56779967676233
      ]
    },
    "50048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4744371075765004,
              40.98076052255566
            ]
          },
          "bbox": [
            -1.4744371075765004,
            40.98076052255566,
            -1.4744371075765004,
            40.98076052255566
          ]
        }
      ],
      "bbox": [
        -1.4744371075765004,
        40.98076052255566,
        -1.4744371075765004,
        40.98076052255566
      ]
    },
    "50050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9230119186154677,
              41.52657633425541
            ]
          },
          "bbox": [
            -1.9230119186154677,
            41.52657633425541,
            -1.9230119186154677,
            41.52657633425541
          ]
        }
      ],
      "bbox": [
        -1.9230119186154677,
        41.52657633425541,
        -1.9230119186154677,
        41.52657633425541
      ]
    },
    "50051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1817101807341488,
              42.248069375291294
            ]
          },
          "bbox": [
            -1.1817101807341488,
            42.248069375291294,
            -1.1817101807341488,
            42.248069375291294
          ]
        }
      ],
      "bbox": [
        -1.1817101807341488,
        42.248069375291294,
        -1.1817101807341488,
        42.248069375291294
      ]
    },
    "50052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4133844934455428,
              41.855598281960425
            ]
          },
          "bbox": [
            -1.4133844934455428,
            41.855598281960425,
            -1.4133844934455428,
            41.855598281960425
          ]
        }
      ],
      "bbox": [
        -1.4133844934455428,
        41.855598281960425,
        -1.4133844934455428,
        41.855598281960425
      ]
    },
    "50053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2753543947586248,
              41.83843612641117
            ]
          },
          "bbox": [
            -1.2753543947586248,
            41.83843612641117,
            -1.2753543947586248,
            41.83843612641117
          ]
        }
      ],
      "bbox": [
        -1.2753543947586248,
        41.83843612641117,
        -1.2753543947586248,
        41.83843612641117
      ]
    },
    "50054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0777158295473828,
              41.41036089595183
            ]
          },
          "bbox": [
            -2.0777158295473828,
            41.41036089595183,
            -2.0777158295473828,
            41.41036089595183
          ]
        }
      ],
      "bbox": [
        -2.0777158295473828,
        41.41036089595183,
        -2.0777158295473828,
        41.41036089595183
      ]
    },
    "50055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5510705046489777,
              41.8497242939447
            ]
          },
          "bbox": [
            -1.5510705046489777,
            41.8497242939447,
            -1.5510705046489777,
            41.8497242939447
          ]
        }
      ],
      "bbox": [
        -1.5510705046489777,
        41.8497242939447,
        -1.5510705046489777,
        41.8497242939447
      ]
    },
    "50056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0311440834332735,
              41.489751591735775
            ]
          },
          "bbox": [
            -1.0311440834332735,
            41.489751591735775,
            -1.0311440834332735,
            41.489751591735775
          ]
        }
      ],
      "bbox": [
        -1.0311440834332735,
        41.489751591735775,
        -1.0311440834332735,
        41.489751591735775
      ]
    },
    "50057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5935568513574752,
              41.52491910111394
            ]
          },
          "bbox": [
            -1.5935568513574752,
            41.52491910111394,
            -1.5935568513574752,
            41.52491910111394
          ]
        }
      ],
      "bbox": [
        -1.5935568513574752,
        41.52491910111394,
        -1.5935568513574752,
        41.52491910111394
      ]
    },
    "50058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8722534560927424,
              41.32537264795439
            ]
          },
          "bbox": [
            -1.8722534560927424,
            41.32537264795439,
            -1.8722534560927424,
            41.32537264795439
          ]
        }
      ],
      "bbox": [
        -1.8722534560927424,
        41.32537264795439,
        -1.8722534560927424,
        41.32537264795439
      ]
    },
    "50059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1405921145539263,
              41.45031889269133
            ]
          },
          "bbox": [
            -0.1405921145539263,
            41.45031889269133,
            -0.1405921145539263,
            41.45031889269133
          ]
        }
      ],
      "bbox": [
        -0.1405921145539263,
        41.45031889269133,
        -0.1405921145539263,
        41.45031889269133
      ]
    },
    "50060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6182654679047432,
              41.827714955472445
            ]
          },
          "bbox": [
            -1.6182654679047432,
            41.827714955472445,
            -1.6182654679047432,
            41.827714955472445
          ]
        }
      ],
      "bbox": [
        -1.6182654679047432,
        41.827714955472445,
        -1.6182654679047432,
        41.827714955472445
      ]
    },
    "50061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4906040736910497,
              41.798135754477954
            ]
          },
          "bbox": [
            -1.4906040736910497,
            41.798135754477954,
            -1.4906040736910497,
            41.798135754477954
          ]
        }
      ],
      "bbox": [
        -1.4906040736910497,
        41.798135754477954,
        -1.4906040736910497,
        41.798135754477954
      ]
    },
    "50062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7231053624836754,
              41.565915092937516
            ]
          },
          "bbox": [
            -0.7231053624836754,
            41.565915092937516,
            -0.7231053624836754,
            41.565915092937516
          ]
        }
      ],
      "bbox": [
        -0.7231053624836754,
        41.565915092937516,
        -0.7231053624836754,
        41.565915092937516
      ]
    },
    "50063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5996119202978696,
              41.89364732666029
            ]
          },
          "bbox": [
            -1.5996119202978696,
            41.89364732666029,
            -1.5996119202978696,
            41.89364732666029
          ]
        }
      ],
      "bbox": [
        -1.5996119202978696,
        41.89364732666029,
        -1.5996119202978696,
        41.89364732666029
      ]
    },
    "50064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1672287116453284,
              41.79596445581042
            ]
          },
          "bbox": [
            -1.1672287116453284,
            41.79596445581042,
            -1.1672287116453284,
            41.79596445581042
          ]
        }
      ],
      "bbox": [
        -1.1672287116453284,
        41.79596445581042,
        -1.1672287116453284,
        41.79596445581042
      ]
    },
    "50065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.041940512462368,
              41.21729532023899
            ]
          },
          "bbox": [
            -2.041940512462368,
            41.21729532023899,
            -2.041940512462368,
            41.21729532023899
          ]
        }
      ],
      "bbox": [
        -2.041940512462368,
        41.21729532023899,
        -2.041940512462368,
        41.21729532023899
      ]
    },
    "50066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9564914250271731,
              41.549673308631746
            ]
          },
          "bbox": [
            -0.9564914250271731,
            41.549673308631746,
            -0.9564914250271731,
            41.549673308631746
          ]
        }
      ],
      "bbox": [
        -0.9564914250271731,
        41.549673308631746,
        -0.9564914250271731,
        41.549673308631746
      ]
    },
    "50067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6248954209409225,
              41.37614714340208
            ]
          },
          "bbox": [
            -1.6248954209409225,
            41.37614714340208,
            -1.6248954209409225,
            41.37614714340208
          ]
        }
      ],
      "bbox": [
        -1.6248954209409225,
        41.37614714340208,
        -1.6248954209409225,
        41.37614714340208
      ]
    },
    "50068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3001294241428523,
              41.52189828813787
            ]
          },
          "bbox": [
            -1.3001294241428523,
            41.52189828813787,
            -1.3001294241428523,
            41.52189828813787
          ]
        }
      ],
      "bbox": [
        -1.3001294241428523,
        41.52189828813787,
        -1.3001294241428523,
        41.52189828813787
      ]
    },
    "50069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.720572722500906,
              41.663901894933026
            ]
          },
          "bbox": [
            -1.720572722500906,
            41.663901894933026,
            -1.720572722500906,
            41.663901894933026
          ]
        }
      ],
      "bbox": [
        -1.720572722500906,
        41.663901894933026,
        -1.720572722500906,
        41.663901894933026
      ]
    },
    "50070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9267266117468227,
              41.166353825700725
            ]
          },
          "bbox": [
            -1.9267266117468227,
            41.166353825700725,
            -1.9267266117468227,
            41.166353825700725
          ]
        }
      ],
      "bbox": [
        -1.9267266117468227,
        41.166353825700725,
        -1.9267266117468227,
        41.166353825700725
      ]
    },
    "50071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8448089590309757,
              41.13016242707025
            ]
          },
          "bbox": [
            -1.8448089590309757,
            41.13016242707025,
            -1.8448089590309757,
            41.13016242707025
          ]
        }
      ],
      "bbox": [
        -1.8448089590309757,
        41.13016242707025,
        -1.8448089590309757,
        41.13016242707025
      ]
    },
    "50072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7947482407493307,
              41.261906871939594
            ]
          },
          "bbox": [
            -1.7947482407493307,
            41.261906871939594,
            -1.7947482407493307,
            41.261906871939594
          ]
        }
      ],
      "bbox": [
        -1.7947482407493307,
        41.261906871939594,
        -1.7947482407493307,
        41.261906871939594
      ]
    },
    "50073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2085156513166102,
              41.350514039339224
            ]
          },
          "bbox": [
            -1.2085156513166102,
            41.350514039339224,
            -1.2085156513166102,
            41.350514039339224
          ]
        }
      ],
      "bbox": [
        -1.2085156513166102,
        41.350514039339224,
        -1.2085156513166102,
        41.350514039339224
      ]
    },
    "50074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.03265259620947728,
              41.25528706279741
            ]
          },
          "bbox": [
            -0.03265259620947728,
            41.25528706279741,
            -0.03265259620947728,
            41.25528706279741
          ]
        }
      ],
      "bbox": [
        -0.03265259620947728,
        41.25528706279741,
        -0.03265259620947728,
        41.25528706279741
      ]
    },
    "50075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.652329942637125,
              41.18391993518428
            ]
          },
          "bbox": [
            -1.652329942637125,
            41.18391993518428,
            -1.652329942637125,
            41.18391993518428
          ]
        }
      ],
      "bbox": [
        -1.652329942637125,
        41.18391993518428,
        -1.652329942637125,
        41.18391993518428
      ]
    },
    "50076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.81914734013198,
              41.29723626831877
            ]
          },
          "bbox": [
            -1.81914734013198,
            41.29723626831877,
            -1.81914734013198,
            41.29723626831877
          ]
        }
      ],
      "bbox": [
        -1.81914734013198,
        41.29723626831877,
        -1.81914734013198,
        41.29723626831877
      ]
    },
    "50077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0058419491750907,
              41.99788413995137
            ]
          },
          "bbox": [
            -1.0058419491750907,
            41.99788413995137,
            -1.0058419491750907,
            41.99788413995137
          ]
        }
      ],
      "bbox": [
        -1.0058419491750907,
        41.99788413995137,
        -1.0058419491750907,
        41.99788413995137
      ]
    },
    "50078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2873056602560917,
              42.35850003453839
            ]
          },
          "bbox": [
            -1.2873056602560917,
            42.35850003453839,
            -1.2873056602560917,
            42.35850003453839
          ]
        }
      ],
      "bbox": [
        -1.2873056602560917,
        42.35850003453839,
        -1.2873056602560917,
        42.35850003453839
      ]
    },
    "50079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7475301034659427,
              41.41695557443695
            ]
          },
          "bbox": [
            -1.7475301034659427,
            41.41695557443695,
            -1.7475301034659427,
            41.41695557443695
          ]
        }
      ],
      "bbox": [
        -1.7475301034659427,
        41.41695557443695,
        -1.7475301034659427,
        41.41695557443695
      ]
    },
    "50080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2149345793022117,
              41.21941384720832
            ]
          },
          "bbox": [
            -1.2149345793022117,
            41.21941384720832,
            -1.2149345793022117,
            41.21941384720832
          ]
        }
      ],
      "bbox": [
        -1.2149345793022117,
        41.21941384720832,
        -1.2149345793022117,
        41.21941384720832
      ]
    },
    "50081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9691369924087219,
              41.27623448548221
            ]
          },
          "bbox": [
            -1.9691369924087219,
            41.27623448548221,
            -1.9691369924087219,
            41.27623448548221
          ]
        }
      ],
      "bbox": [
        -1.9691369924087219,
        41.27623448548221,
        -1.9691369924087219,
        41.27623448548221
      ]
    },
    "50082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7705898239417375,
              41.0934843110674
            ]
          },
          "bbox": [
            -1.7705898239417375,
            41.0934843110674,
            -1.7705898239417375,
            41.0934843110674
          ]
        }
      ],
      "bbox": [
        -1.7705898239417375,
        41.0934843110674,
        -1.7705898239417375,
        41.0934843110674
      ]
    },
    "50083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3686749584430616,
              41.34081011641909
            ]
          },
          "bbox": [
            -0.3686749584430616,
            41.34081011641909,
            -0.3686749584430616,
            41.34081011641909
          ]
        }
      ],
      "bbox": [
        -0.3686749584430616,
        41.34081011641909,
        -0.3686749584430616,
        41.34081011641909
      ]
    },
    "50084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8358029950554393,
              41.535656791949336
            ]
          },
          "bbox": [
            -1.8358029950554393,
            41.535656791949336,
            -1.8358029950554393,
            41.535656791949336
          ]
        }
      ],
      "bbox": [
        -1.8358029950554393,
        41.535656791949336,
        -1.8358029950554393,
        41.535656791949336
      ]
    },
    "50085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6811397170507214,
              41.34041077396862
            ]
          },
          "bbox": [
            -0.6811397170507214,
            41.34041077396862,
            -0.6811397170507214,
            41.34041077396862
          ]
        }
      ],
      "bbox": [
        -0.6811397170507214,
        41.34041077396862,
        -0.6811397170507214,
        41.34041077396862
      ]
    },
    "50086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3638569766508186,
              41.28706449556018
            ]
          },
          "bbox": [
            -1.3638569766508186,
            41.28706449556018,
            -1.3638569766508186,
            41.28706449556018
          ]
        }
      ],
      "bbox": [
        -1.3638569766508186,
        41.28706449556018,
        -1.3638569766508186,
        41.28706449556018
      ]
    },
    "50087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9275463206873984,
              41.28042954336422
            ]
          },
          "bbox": [
            -1.9275463206873984,
            41.28042954336422,
            -1.9275463206873984,
            41.28042954336422
          ]
        }
      ],
      "bbox": [
        -1.9275463206873984,
        41.28042954336422,
        -1.9275463206873984,
        41.28042954336422
      ]
    },
    "50088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3047930206679175,
              41.36708214652359
            ]
          },
          "bbox": [
            -1.3047930206679175,
            41.36708214652359,
            -1.3047930206679175,
            41.36708214652359
          ]
        }
      ],
      "bbox": [
        -1.3047930206679175,
        41.36708214652359,
        -1.3047930206679175,
        41.36708214652359
      ]
    },
    "50089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9287625078500794,
              41.58599200763636
            ]
          },
          "bbox": [
            -0.9287625078500794,
            41.58599200763636,
            -0.9287625078500794,
            41.58599200763636
          ]
        }
      ],
      "bbox": [
        -0.9287625078500794,
        41.58599200763636,
        -0.9287625078500794,
        41.58599200763636
      ]
    },
    "50090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.630200595255434,
              41.09371279138699
            ]
          },
          "bbox": [
            -1.630200595255434,
            41.09371279138699,
            -1.630200595255434,
            41.09371279138699
          ]
        }
      ],
      "bbox": [
        -1.630200595255434,
        41.09371279138699,
        -1.630200595255434,
        41.09371279138699
      ]
    },
    "50091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5644330011679384,
              40.961726080251125
            ]
          },
          "bbox": [
            -1.5644330011679384,
            40.961726080251125,
            -1.5644330011679384,
            40.961726080251125
          ]
        }
      ],
      "bbox": [
        -1.5644330011679384,
        40.961726080251125,
        -1.5644330011679384,
        40.961726080251125
      ]
    },
    "50092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1717761069404606,
              41.25672658287544
            ]
          },
          "bbox": [
            -0.1717761069404606,
            41.25672658287544,
            -0.1717761069404606,
            41.25672658287544
          ]
        }
      ],
      "bbox": [
        -0.1717761069404606,
        41.25672658287544,
        -0.1717761069404606,
        41.25672658287544
      ]
    },
    "50093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4812132207051365,
              41.48277735272282
            ]
          },
          "bbox": [
            -1.4812132207051365,
            41.48277735272282,
            -1.4812132207051365,
            41.48277735272282
          ]
        }
      ],
      "bbox": [
        -1.4812132207051365,
        41.48277735272282,
        -1.4812132207051365,
        41.48277735272282
      ]
    },
    "50094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4176143951624705,
              41.11408690889828
            ]
          },
          "bbox": [
            -1.4176143951624705,
            41.11408690889828,
            -1.4176143951624705,
            41.11408690889828
          ]
        }
      ],
      "bbox": [
        -1.4176143951624705,
        41.11408690889828,
        -1.4176143951624705,
        41.11408690889828
      ]
    },
    "50095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1948645810970997,
              42.127480577503
            ]
          },
          "bbox": [
            -1.1948645810970997,
            42.127480577503,
            -1.1948645810970997,
            42.127480577503
          ]
        }
      ],
      "bbox": [
        -1.1948645810970997,
        42.127480577503,
        -1.1948645810970997,
        42.127480577503
      ]
    },
    "50096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9700628383670191,
              41.37353741407294
            ]
          },
          "bbox": [
            -1.9700628383670191,
            41.37353741407294,
            -1.9700628383670191,
            41.37353741407294
          ]
        }
      ],
      "bbox": [
        -1.9700628383670191,
        41.37353741407294,
        -1.9700628383670191,
        41.37353741407294
      ]
    },
    "50098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2768348593603702,
              41.2860069540013
            ]
          },
          "bbox": [
            -1.2768348593603702,
            41.2860069540013,
            -1.2768348593603702,
            41.2860069540013
          ]
        }
      ],
      "bbox": [
        -1.2768348593603702,
        41.2860069540013,
        -1.2768348593603702,
        41.2860069540013
      ]
    },
    "50099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3123659770293292,
              41.58823337934848
            ]
          },
          "bbox": [
            -1.3123659770293292,
            41.58823337934848,
            -1.3123659770293292,
            41.58823337934848
          ]
        }
      ],
      "bbox": [
        -1.3123659770293292,
        41.58823337934848,
        -1.3123659770293292,
        41.58823337934848
      ]
    },
    "50100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9780884649651008,
              42.11073419810148
            ]
          },
          "bbox": [
            -0.9780884649651008,
            42.11073419810148,
            -0.9780884649651008,
            42.11073419810148
          ]
        }
      ],
      "bbox": [
        -0.9780884649651008,
        42.11073419810148,
        -0.9780884649651008,
        42.11073419810148
      ]
    },
    "50101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2729036377320954,
              41.25731370828668
            ]
          },
          "bbox": [
            -0.2729036377320954,
            41.25731370828668,
            -0.2729036377320954,
            41.25731370828668
          ]
        }
      ],
      "bbox": [
        -0.2729036377320954,
        41.25731370828668,
        -0.2729036377320954,
        41.25731370828668
      ]
    },
    "50102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.15704068046039607,
              41.19743642138303
            ]
          },
          "bbox": [
            0.15704068046039607,
            41.19743642138303,
            0.15704068046039607,
            41.19743642138303
          ]
        }
      ],
      "bbox": [
        0.15704068046039607,
        41.19743642138303,
        0.15704068046039607,
        41.19743642138303
      ]
    },
    "50104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5374080291677372,
              41.681230995216424
            ]
          },
          "bbox": [
            -0.5374080291677372,
            41.681230995216424,
            -0.5374080291677372,
            41.681230995216424
          ]
        }
      ],
      "bbox": [
        -0.5374080291677372,
        41.681230995216424,
        -0.5374080291677372,
        41.681230995216424
      ]
    },
    "50105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.3191545542338207,
              41.26451091215364
            ]
          },
          "bbox": [
            0.3191545542338207,
            41.26451091215364,
            0.3191545542338207,
            41.26451091215364
          ]
        }
      ],
      "bbox": [
        0.3191545542338207,
        41.26451091215364,
        0.3191545542338207,
        41.26451091215364
      ]
    },
    "50106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7908743273466903,
              41.876296540058775
            ]
          },
          "bbox": [
            -1.7908743273466903,
            41.876296540058775,
            -1.7908743273466903,
            41.876296540058775
          ]
        }
      ],
      "bbox": [
        -1.7908743273466903,
        41.876296540058775,
        -1.7908743273466903,
        41.876296540058775
      ]
    },
    "50107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1786342413347506,
              41.76765318267094
            ]
          },
          "bbox": [
            -1.1786342413347506,
            41.76765318267094,
            -1.1786342413347506,
            41.76765318267094
          ]
        }
      ],
      "bbox": [
        -1.1786342413347506,
        41.76765318267094,
        -1.1786342413347506,
        41.76765318267094
      ]
    },
    "50108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1914986888767514,
              41.15551088102881
            ]
          },
          "bbox": [
            -1.1914986888767514,
            41.15551088102881,
            -1.1914986888767514,
            41.15551088102881
          ]
        }
      ],
      "bbox": [
        -1.1914986888767514,
        41.15551088102881,
        -1.1914986888767514,
        41.15551088102881
      ]
    },
    "50109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9329759097142803,
              42.28256963108943
            ]
          },
          "bbox": [
            -0.9329759097142803,
            42.28256963108943,
            -0.9329759097142803,
            42.28256963108943
          ]
        }
      ],
      "bbox": [
        -0.9329759097142803,
        42.28256963108943,
        -0.9329759097142803,
        42.28256963108943
      ]
    },
    "50110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4921023104285558,
              41.40449793697275
            ]
          },
          "bbox": [
            -1.4921023104285558,
            41.40449793697275,
            -1.4921023104285558,
            41.40449793697275
          ]
        }
      ],
      "bbox": [
        -1.4921023104285558,
        41.40449793697275,
        -1.4921023104285558,
        41.40449793697275
      ]
    },
    "50111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4597064997061624,
              41.884125846401226
            ]
          },
          "bbox": [
            -1.4597064997061624,
            41.884125846401226,
            -1.4597064997061624,
            41.884125846401226
          ]
        }
      ],
      "bbox": [
        -1.4597064997061624,
        41.884125846401226,
        -1.4597064997061624,
        41.884125846401226
      ]
    },
    "50113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4600252368602218,
              41.732989271639326
            ]
          },
          "bbox": [
            -1.4600252368602218,
            41.732989271639326,
            -1.4600252368602218,
            41.732989271639326
          ]
        }
      ],
      "bbox": [
        -1.4600252368602218,
        41.732989271639326,
        -1.4600252368602218,
        41.732989271639326
      ]
    },
    "50114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9259160376701113,
              41.354852597885
            ]
          },
          "bbox": [
            -0.9259160376701113,
            41.354852597885,
            -0.9259160376701113,
            41.354852597885
          ]
        }
      ],
      "bbox": [
        -0.9259160376701113,
        41.354852597885,
        -0.9259160376701113,
        41.354852597885
      ]
    },
    "50115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6283204984014118,
              41.47179580437578
            ]
          },
          "bbox": [
            -0.6283204984014118,
            41.47179580437578,
            -0.6283204984014118,
            41.47179580437578
          ]
        }
      ],
      "bbox": [
        -0.6283204984014118,
        41.47179580437578,
        -0.6283204984014118,
        41.47179580437578
      ]
    },
    "50116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5482665858284896,
              41.226202481318786
            ]
          },
          "bbox": [
            -1.5482665858284896,
            41.226202481318786,
            -1.5482665858284896,
            41.226202481318786
          ]
        }
      ],
      "bbox": [
        -1.5482665858284896,
        41.226202481318786,
        -1.5482665858284896,
        41.226202481318786
      ]
    },
    "50117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5211244173849687,
              40.99607512846701
            ]
          },
          "bbox": [
            -1.5211244173849687,
            40.99607512846701,
            -1.5211244173849687,
            40.99607512846701
          ]
        }
      ],
      "bbox": [
        -1.5211244173849687,
        40.99607512846701,
        -1.5211244173849687,
        40.99607512846701
      ]
    },
    "50118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3225581202690961,
              41.863146642054986
            ]
          },
          "bbox": [
            -1.3225581202690961,
            41.863146642054986,
            -1.3225581202690961,
            41.863146642054986
          ]
        }
      ],
      "bbox": [
        -1.3225581202690961,
        41.863146642054986,
        -1.3225581202690961,
        41.863146642054986
      ]
    },
    "50119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.39827040654101764,
              41.4306872331334
            ]
          },
          "bbox": [
            -0.39827040654101764,
            41.4306872331334,
            -0.39827040654101764,
            41.4306872331334
          ]
        }
      ],
      "bbox": [
        -0.39827040654101764,
        41.4306872331334,
        -0.39827040654101764,
        41.4306872331334
      ]
    },
    "50120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8707840250295498,
              41.26234225423263
            ]
          },
          "bbox": [
            -1.8707840250295498,
            41.26234225423263,
            -1.8707840250295498,
            41.26234225423263
          ]
        }
      ],
      "bbox": [
        -1.8707840250295498,
        41.26234225423263,
        -1.8707840250295498,
        41.26234225423263
      ]
    },
    "50121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6549965455213738,
              41.54939481977991
            ]
          },
          "bbox": [
            -1.6549965455213738,
            41.54939481977991,
            -1.6549965455213738,
            41.54939481977991
          ]
        }
      ],
      "bbox": [
        -1.6549965455213738,
        41.54939481977991,
        -1.6549965455213738,
        41.54939481977991
      ]
    },
    "50122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7239025820981788,
              41.866357538084074
            ]
          },
          "bbox": [
            -1.7239025820981788,
            41.866357538084074,
            -1.7239025820981788,
            41.866357538084074
          ]
        }
      ],
      "bbox": [
        -1.7239025820981788,
        41.866357538084074,
        -1.7239025820981788,
        41.866357538084074
      ]
    },
    "50123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1602509547993582,
              41.74598819609991
            ]
          },
          "bbox": [
            -1.1602509547993582,
            41.74598819609991,
            -1.1602509547993582,
            41.74598819609991
          ]
        }
      ],
      "bbox": [
        -1.1602509547993582,
        41.74598819609991,
        -1.1602509547993582,
        41.74598819609991
      ]
    },
    "50124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0598851298897551,
              41.218015312840684
            ]
          },
          "bbox": [
            -1.0598851298897551,
            41.218015312840684,
            -1.0598851298897551,
            41.218015312840684
          ]
        }
      ],
      "bbox": [
        -1.0598851298897551,
        41.218015312840684,
        -1.0598851298897551,
        41.218015312840684
      ]
    },
    "50125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8498961406657453,
              41.215876708036554
            ]
          },
          "bbox": [
            -1.8498961406657453,
            41.215876708036554,
            -1.8498961406657453,
            41.215876708036554
          ]
        }
      ],
      "bbox": [
        -1.8498961406657453,
        41.215876708036554,
        -1.8498961406657453,
        41.215876708036554
      ]
    },
    "50126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.629302329274918,
              41.5365958263701
            ]
          },
          "bbox": [
            -1.629302329274918,
            41.5365958263701,
            -1.629302329274918,
            41.5365958263701
          ]
        }
      ],
      "bbox": [
        -1.629302329274918,
        41.5365958263701,
        -1.629302329274918,
        41.5365958263701
      ]
    },
    "50128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.057922861640301,
              42.48387265842007
            ]
          },
          "bbox": [
            -1.057922861640301,
            42.48387265842007,
            -1.057922861640301,
            42.48387265842007
          ]
        }
      ],
      "bbox": [
        -1.057922861640301,
        42.48387265842007,
        -1.057922861640301,
        42.48387265842007
      ]
    },
    "50129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8976792299829095,
              41.19317101632993
            ]
          },
          "bbox": [
            -1.8976792299829095,
            41.19317101632993,
            -1.8976792299829095,
            41.19317101632993
          ]
        }
      ],
      "bbox": [
        -1.8976792299829095,
        41.19317101632993,
        -1.8976792299829095,
        41.19317101632993
      ]
    },
    "50130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6932694534236479,
              41.56418660220274
            ]
          },
          "bbox": [
            -1.6932694534236479,
            41.56418660220274,
            -1.6932694534236479,
            41.56418660220274
          ]
        }
      ],
      "bbox": [
        -1.6932694534236479,
        41.56418660220274,
        -1.6932694534236479,
        41.56418660220274
      ]
    },
    "50131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9761231124390529,
              41.4264909877805
            ]
          },
          "bbox": [
            -0.9761231124390529,
            41.4264909877805,
            -0.9761231124390529,
            41.4264909877805
          ]
        }
      ],
      "bbox": [
        -0.9761231124390529,
        41.4264909877805,
        -0.9761231124390529,
        41.4264909877805
      ]
    },
    "50132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0750964121547593,
              41.74794449105449
            ]
          },
          "bbox": [
            -1.0750964121547593,
            41.74794449105449,
            -1.0750964121547593,
            41.74794449105449
          ]
        }
      ],
      "bbox": [
        -1.0750964121547593,
        41.74794449105449,
        -1.0750964121547593,
        41.74794449105449
      ]
    },
    "50133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8023555407835297,
              41.20809627332367
            ]
          },
          "bbox": [
            -0.8023555407835297,
            41.20809627332367,
            -0.8023555407835297,
            41.20809627332367
          ]
        }
      ],
      "bbox": [
        -0.8023555407835297,
        41.20809627332367,
        -0.8023555407835297,
        41.20809627332367
      ]
    },
    "50134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3976328903098656,
              41.21858706894603
            ]
          },
          "bbox": [
            -1.3976328903098656,
            41.21858706894603,
            -1.3976328903098656,
            41.21858706894603
          ]
        }
      ],
      "bbox": [
        -1.3976328903098656,
        41.21858706894603,
        -1.3976328903098656,
        41.21858706894603
      ]
    },
    "50135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2465447951617419,
              42.29998775276084
            ]
          },
          "bbox": [
            -1.2465447951617419,
            42.29998775276084,
            -1.2465447951617419,
            42.29998775276084
          ]
        }
      ],
      "bbox": [
        -1.2465447951617419,
        42.29998775276084,
        -1.2465447951617419,
        42.29998775276084
      ]
    },
    "50136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6941492336895075,
              41.18606106079841
            ]
          },
          "bbox": [
            -0.6941492336895075,
            41.18606106079841,
            -0.6941492336895075,
            41.18606106079841
          ]
        }
      ],
      "bbox": [
        -0.6941492336895075,
        41.18606106079841,
        -0.6941492336895075,
        41.18606106079841
      ]
    },
    "50137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.615767769022788,
              41.837713564938255
            ]
          },
          "bbox": [
            -0.615767769022788,
            41.837713564938255,
            -0.615767769022788,
            41.837713564938255
          ]
        }
      ],
      "bbox": [
        -0.615767769022788,
        41.837713564938255,
        -0.615767769022788,
        41.837713564938255
      ]
    },
    "50138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2809162304777213,
              41.081288882563214
            ]
          },
          "bbox": [
            -1.2809162304777213,
            41.081288882563214,
            -1.2809162304777213,
            41.081288882563214
          ]
        }
      ],
      "bbox": [
        -1.2809162304777213,
        41.081288882563214,
        -1.2809162304777213,
        41.081288882563214
      ]
    },
    "50139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7886945024303176,
              41.23468278997542
            ]
          },
          "bbox": [
            -0.7886945024303176,
            41.23468278997542,
            -0.7886945024303176,
            41.23468278997542
          ]
        }
      ],
      "bbox": [
        -0.7886945024303176,
        41.23468278997542,
        -0.7886945024303176,
        41.23468278997542
      ]
    },
    "50140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7617155268939213,
              41.806576126297024
            ]
          },
          "bbox": [
            -1.7617155268939213,
            41.806576126297024,
            -1.7617155268939213,
            41.806576126297024
          ]
        }
      ],
      "bbox": [
        -1.7617155268939213,
        41.806576126297024,
        -1.7617155268939213,
        41.806576126297024
      ]
    },
    "50141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7706484583330766,
              41.831123758440235
            ]
          },
          "bbox": [
            -1.7706484583330766,
            41.831123758440235,
            -1.7706484583330766,
            41.831123758440235
          ]
        }
      ],
      "bbox": [
        -1.7706484583330766,
        41.831123758440235,
        -1.7706484583330766,
        41.831123758440235
      ]
    },
    "50142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0104177597387076,
              42.47515913719703
            ]
          },
          "bbox": [
            -1.0104177597387076,
            42.47515913719703,
            -1.0104177597387076,
            42.47515913719703
          ]
        }
      ],
      "bbox": [
        -1.0104177597387076,
        42.47515913719703,
        -1.0104177597387076,
        42.47515913719703
      ]
    },
    "50143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1542165945653207,
              41.39071628792347
            ]
          },
          "bbox": [
            -1.1542165945653207,
            41.39071628792347,
            -1.1542165945653207,
            41.39071628792347
          ]
        }
      ],
      "bbox": [
        -1.1542165945653207,
        41.39071628792347,
        -1.1542165945653207,
        41.39071628792347
      ]
    },
    "50144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9323968283589205,
              42.47612812232977
            ]
          },
          "bbox": [
            -0.9323968283589205,
            42.47612812232977,
            -0.9323968283589205,
            42.47612812232977
          ]
        }
      ],
      "bbox": [
        -0.9323968283589205,
        42.47612812232977,
        -0.9323968283589205,
        42.47612812232977
      ]
    },
    "50146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2983348684069949,
              41.55343780686488
            ]
          },
          "bbox": [
            -1.2983348684069949,
            41.55343780686488,
            -1.2983348684069949,
            41.55343780686488
          ]
        }
      ],
      "bbox": [
        -1.2983348684069949,
        41.55343780686488,
        -1.2983348684069949,
        41.55343780686488
      ]
    },
    "50147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2597209894667032,
              41.819032646132
            ]
          },
          "bbox": [
            -1.2597209894667032,
            41.819032646132,
            -1.2597209894667032,
            41.819032646132
          ]
        }
      ],
      "bbox": [
        -1.2597209894667032,
        41.819032646132,
        -1.2597209894667032,
        41.819032646132
      ]
    },
    "50148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0170403605611873,
              42.36742377004222
            ]
          },
          "bbox": [
            -1.0170403605611873,
            42.36742377004222,
            -1.0170403605611873,
            42.36742377004222
          ]
        }
      ],
      "bbox": [
        -1.0170403605611873,
        42.36742377004222,
        -1.0170403605611873,
        42.36742377004222
      ]
    },
    "50149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1444848216446446,
              41.164100552749616
            ]
          },
          "bbox": [
            -1.1444848216446446,
            41.164100552749616,
            -1.1444848216446446,
            41.164100552749616
          ]
        }
      ],
      "bbox": [
        -1.1444848216446446,
        41.164100552749616,
        -1.1444848216446446,
        41.164100552749616
      ]
    },
    "50150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3537695010097028,
              41.64228549867056
            ]
          },
          "bbox": [
            -1.3537695010097028,
            41.64228549867056,
            -1.3537695010097028,
            41.64228549867056
          ]
        }
      ],
      "bbox": [
        -1.3537695010097028,
        41.64228549867056,
        -1.3537695010097028,
        41.64228549867056
      ]
    },
    "50151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9258113415621797,
              42.1632250874712
            ]
          },
          "bbox": [
            -0.9258113415621797,
            42.1632250874712,
            -0.9258113415621797,
            42.1632250874712
          ]
        }
      ],
      "bbox": [
        -0.9258113415621797,
        42.1632250874712,
        -0.9258113415621797,
        42.1632250874712
      ]
    },
    "50152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.1016004710151632,
              41.116044559336885
            ]
          },
          "bbox": [
            0.1016004710151632,
            41.116044559336885,
            0.1016004710151632,
            41.116044559336885
          ]
        }
      ],
      "bbox": [
        0.1016004710151632,
        41.116044559336885,
        0.1016004710151632,
        41.116044559336885
      ]
    },
    "50153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3956645676790584,
              41.81225210910232
            ]
          },
          "bbox": [
            -1.3956645676790584,
            41.81225210910232,
            -1.3956645676790584,
            41.81225210910232
          ]
        }
      ],
      "bbox": [
        -1.3956645676790584,
        41.81225210910232,
        -1.3956645676790584,
        41.81225210910232
      ]
    },
    "50154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3062065949364503,
              41.20829529488063
            ]
          },
          "bbox": [
            -1.3062065949364503,
            41.20829529488063,
            -1.3062065949364503,
            41.20829529488063
          ]
        }
      ],
      "bbox": [
        -1.3062065949364503,
        41.20829529488063,
        -1.3062065949364503,
        41.20829529488063
      ]
    },
    "50155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8793057643002646,
              41.58241222028844
            ]
          },
          "bbox": [
            -1.8793057643002646,
            41.58241222028844,
            -1.8793057643002646,
            41.58241222028844
          ]
        }
      ],
      "bbox": [
        -1.8793057643002646,
        41.58241222028844,
        -1.8793057643002646,
        41.58241222028844
      ]
    },
    "50156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5487809721491628,
              41.82779549173152
            ]
          },
          "bbox": [
            -1.5487809721491628,
            41.82779549173152,
            -1.5487809721491628,
            41.82779549173152
          ]
        }
      ],
      "bbox": [
        -1.5487809721491628,
        41.82779549173152,
        -1.5487809721491628,
        41.82779549173152
      ]
    },
    "50157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6688898916516257,
              41.9519628844904
            ]
          },
          "bbox": [
            -1.6688898916516257,
            41.9519628844904,
            -1.6688898916516257,
            41.9519628844904
          ]
        }
      ],
      "bbox": [
        -1.6688898916516257,
        41.9519628844904,
        -1.6688898916516257,
        41.9519628844904
      ]
    },
    "50159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6297186475658627,
              41.274006372011726
            ]
          },
          "bbox": [
            -1.6297186475658627,
            41.274006372011726,
            -1.6297186475658627,
            41.274006372011726
          ]
        }
      ],
      "bbox": [
        -1.6297186475658627,
        41.274006372011726,
        -1.6297186475658627,
        41.274006372011726
      ]
    },
    "50160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4288106978672124,
              41.89540673652121
            ]
          },
          "bbox": [
            -1.4288106978672124,
            41.89540673652121,
            -1.4288106978672124,
            41.89540673652121
          ]
        }
      ],
      "bbox": [
        -1.4288106978672124,
        41.89540673652121,
        -1.4288106978672124,
        41.89540673652121
      ]
    },
    "50161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4543984682038498,
              41.15472751049948
            ]
          },
          "bbox": [
            -1.4543984682038498,
            41.15472751049948,
            -1.4543984682038498,
            41.15472751049948
          ]
        }
      ],
      "bbox": [
        -1.4543984682038498,
        41.15472751049948,
        -1.4543984682038498,
        41.15472751049948
      ]
    },
    "50162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5243634914401896,
              41.2833997260487
            ]
          },
          "bbox": [
            -1.5243634914401896,
            41.2833997260487,
            -1.5243634914401896,
            41.2833997260487
          ]
        }
      ],
      "bbox": [
        -1.5243634914401896,
        41.2833997260487,
        -1.5243634914401896,
        41.2833997260487
      ]
    },
    "50163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9914447649896939,
              41.510806807768496
            ]
          },
          "bbox": [
            -0.9914447649896939,
            41.510806807768496,
            -0.9914447649896939,
            41.510806807768496
          ]
        }
      ],
      "bbox": [
        -0.9914447649896939,
        41.510806807768496,
        -0.9914447649896939,
        41.510806807768496
      ]
    },
    "50164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7294135672178458,
              41.449485628703506
            ]
          },
          "bbox": [
            -0.7294135672178458,
            41.449485628703506,
            -0.7294135672178458,
            41.449485628703506
          ]
        }
      ],
      "bbox": [
        -0.7294135672178458,
        41.449485628703506,
        -0.7294135672178458,
        41.449485628703506
      ]
    },
    "50165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.23014259470925247,
              41.33890910756219
            ]
          },
          "bbox": [
            0.23014259470925247,
            41.33890910756219,
            0.23014259470925247,
            41.33890910756219
          ]
        }
      ],
      "bbox": [
        0.23014259470925247,
        41.33890910756219,
        0.23014259470925247,
        41.33890910756219
      ]
    },
    "50166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5413945122985206,
              41.57749748713036
            ]
          },
          "bbox": [
            -1.5413945122985206,
            41.57749748713036,
            -1.5413945122985206,
            41.57749748713036
          ]
        }
      ],
      "bbox": [
        -1.5413945122985206,
        41.57749748713036,
        -1.5413945122985206,
        41.57749748713036
      ]
    },
    "50167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0703202537830028,
              41.412146490063506
            ]
          },
          "bbox": [
            -1.0703202537830028,
            41.412146490063506,
            -1.0703202537830028,
            41.412146490063506
          ]
        }
      ],
      "bbox": [
        -1.0703202537830028,
        41.412146490063506,
        -1.0703202537830028,
        41.412146490063506
      ]
    },
    "50168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9422539727555077,
              42.58880746716461
            ]
          },
          "bbox": [
            -0.9422539727555077,
            42.58880746716461,
            -0.9422539727555077,
            42.58880746716461
          ]
        }
      ],
      "bbox": [
        -0.9422539727555077,
        42.58880746716461,
        -0.9422539727555077,
        42.58880746716461
      ]
    },
    "50169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4598220988180595,
              41.24508532116755
            ]
          },
          "bbox": [
            -1.4598220988180595,
            41.24508532116755,
            -1.4598220988180595,
            41.24508532116755
          ]
        }
      ],
      "bbox": [
        -1.4598220988180595,
        41.24508532116755,
        -1.4598220988180595,
        41.24508532116755
      ]
    },
    "50170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4206197891407108,
              41.626802901642336
            ]
          },
          "bbox": [
            -0.4206197891407108,
            41.626802901642336,
            -0.4206197891407108,
            41.626802901642336
          ]
        }
      ],
      "bbox": [
        -0.4206197891407108,
        41.626802901642336,
        -0.4206197891407108,
        41.626802901642336
      ]
    },
    "50171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8215375248965855,
              41.139833563219106
            ]
          },
          "bbox": [
            -0.8215375248965855,
            41.139833563219106,
            -0.8215375248965855,
            41.139833563219106
          ]
        }
      ],
      "bbox": [
        -0.8215375248965855,
        41.139833563219106,
        -0.8215375248965855,
        41.139833563219106
      ]
    },
    "50172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.116130428410528,
              41.28752448841767
            ]
          },
          "bbox": [
            -2.116130428410528,
            41.28752448841767,
            -2.116130428410528,
            41.28752448841767
          ]
        }
      ],
      "bbox": [
        -2.116130428410528,
        41.28752448841767,
        -2.116130428410528,
        41.28752448841767
      ]
    },
    "50173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7550465420493546,
              41.160513303827464
            ]
          },
          "bbox": [
            -1.7550465420493546,
            41.160513303827464,
            -1.7550465420493546,
            41.160513303827464
          ]
        }
      ],
      "bbox": [
        -1.7550465420493546,
        41.160513303827464,
        -1.7550465420493546,
        41.160513303827464
      ]
    },
    "50174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5174913574444788,
              41.20757415569792
            ]
          },
          "bbox": [
            -1.5174913574444788,
            41.20757415569792,
            -1.5174913574444788,
            41.20757415569792
          ]
        }
      ],
      "bbox": [
        -1.5174913574444788,
        41.20757415569792,
        -1.5174913574444788,
        41.20757415569792
      ]
    },
    "50175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4607816340174706,
              41.44327832366135
            ]
          },
          "bbox": [
            -1.4607816340174706,
            41.44327832366135,
            -1.4607816340174706,
            41.44327832366135
          ]
        }
      ],
      "bbox": [
        -1.4607816340174706,
        41.44327832366135,
        -1.4607816340174706,
        41.44327832366135
      ]
    },
    "50176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5821727934804184,
              41.24767483411228
            ]
          },
          "bbox": [
            -1.5821727934804184,
            41.24767483411228,
            -1.5821727934804184,
            41.24767483411228
          ]
        }
      ],
      "bbox": [
        -1.5821727934804184,
        41.24767483411228,
        -1.5821727934804184,
        41.24767483411228
      ]
    },
    "50177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5664827236177747,
              41.47537842167253
            ]
          },
          "bbox": [
            -1.5664827236177747,
            41.47537842167253,
            -1.5664827236177747,
            41.47537842167253
          ]
        }
      ],
      "bbox": [
        -1.5664827236177747,
        41.47537842167253,
        -1.5664827236177747,
        41.47537842167253
      ]
    },
    "50178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8375255616144004,
              41.3963799935194
            ]
          },
          "bbox": [
            -1.8375255616144004,
            41.3963799935194,
            -1.8375255616144004,
            41.3963799935194
          ]
        }
      ],
      "bbox": [
        -1.8375255616144004,
        41.3963799935194,
        -1.8375255616144004,
        41.3963799935194
      ]
    },
    "50179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9237396339056083,
              41.15250463701573
            ]
          },
          "bbox": [
            -0.9237396339056083,
            41.15250463701573,
            -0.9237396339056083,
            41.15250463701573
          ]
        }
      ],
      "bbox": [
        -0.9237396339056083,
        41.15250463701573,
        -0.9237396339056083,
        41.15250463701573
      ]
    },
    "50180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0533444953775692,
              41.478888089539815
            ]
          },
          "bbox": [
            -1.0533444953775692,
            41.478888089539815,
            -1.0533444953775692,
            41.478888089539815
          ]
        }
      ],
      "bbox": [
        -1.0533444953775692,
        41.478888089539815,
        -1.0533444953775692,
        41.478888089539815
      ]
    },
    "50181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1267970188560148,
              41.47293197337401
            ]
          },
          "bbox": [
            -1.1267970188560148,
            41.47293197337401,
            -1.1267970188560148,
            41.47293197337401
          ]
        }
      ],
      "bbox": [
        -1.1267970188560148,
        41.47293197337401,
        -1.1267970188560148,
        41.47293197337401
      ]
    },
    "50182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.116971310105757,
              41.57778764233807
            ]
          },
          "bbox": [
            -1.116971310105757,
            41.57778764233807,
            -1.116971310105757,
            41.57778764233807
          ]
        }
      ],
      "bbox": [
        -1.116971310105757,
        41.57778764233807,
        -1.116971310105757,
        41.57778764233807
      ]
    },
    "50183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7193804866733484,
              41.24322076239935
            ]
          },
          "bbox": [
            -1.7193804866733484,
            41.24322076239935,
            -1.7193804866733484,
            41.24322076239935
          ]
        }
      ],
      "bbox": [
        -1.7193804866733484,
        41.24322076239935,
        -1.7193804866733484,
        41.24322076239935
      ]
    },
    "50184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.495318540367648,
              41.158184242706206
            ]
          },
          "bbox": [
            -1.495318540367648,
            41.158184242706206,
            -1.495318540367648,
            41.158184242706206
          ]
        }
      ],
      "bbox": [
        -1.495318540367648,
        41.158184242706206,
        -1.495318540367648,
        41.158184242706206
      ]
    },
    "50185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7760890509055055,
              42.28642993561759
            ]
          },
          "bbox": [
            -0.7760890509055055,
            42.28642993561759,
            -0.7760890509055055,
            42.28642993561759
          ]
        }
      ],
      "bbox": [
        -0.7760890509055055,
        42.28642993561759,
        -0.7760890509055055,
        42.28642993561759
      ]
    },
    "50186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1292369981558212,
              42.5043417228139
            ]
          },
          "bbox": [
            -1.1292369981558212,
            42.5043417228139,
            -1.1292369981558212,
            42.5043417228139
          ]
        }
      ],
      "bbox": [
        -1.1292369981558212,
        42.5043417228139,
        -1.1292369981558212,
        42.5043417228139
      ]
    },
    "50187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5060203361175202,
              41.55821253939643
            ]
          },
          "bbox": [
            -1.5060203361175202,
            41.55821253939643,
            -1.5060203361175202,
            41.55821253939643
          ]
        }
      ],
      "bbox": [
        -1.5060203361175202,
        41.55821253939643,
        -1.5060203361175202,
        41.55821253939643
      ]
    },
    "50188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3445308808791006,
              41.10876278918824
            ]
          },
          "bbox": [
            -1.3445308808791006,
            41.10876278918824,
            -1.3445308808791006,
            41.10876278918824
          ]
        }
      ],
      "bbox": [
        -1.3445308808791006,
        41.10876278918824,
        -1.3445308808791006,
        41.10876278918824
      ]
    },
    "50189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.24285081045416712,
              41.212407120592374
            ]
          },
          "bbox": [
            0.24285081045416712,
            41.212407120592374,
            0.24285081045416712,
            41.212407120592374
          ]
        }
      ],
      "bbox": [
        0.24285081045416712,
        41.212407120592374,
        0.24285081045416712,
        41.212407120592374
      ]
    },
    "50190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7123883365862982,
              41.946346953271366
            ]
          },
          "bbox": [
            -1.7123883365862982,
            41.946346953271366,
            -1.7123883365862982,
            41.946346953271366
          ]
        }
      ],
      "bbox": [
        -1.7123883365862982,
        41.946346953271366,
        -1.7123883365862982,
        41.946346953271366
      ]
    },
    "50191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.37580533121437,
              41.91562595307752
            ]
          },
          "bbox": [
            -1.37580533121437,
            41.91562595307752,
            -1.37580533121437,
            41.91562595307752
          ]
        }
      ],
      "bbox": [
        -1.37580533121437,
        41.91562595307752,
        -1.37580533121437,
        41.91562595307752
      ]
    },
    "50192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7761612389070518,
              41.202318406896474
            ]
          },
          "bbox": [
            -1.7761612389070518,
            41.202318406896474,
            -1.7761612389070518,
            41.202318406896474
          ]
        }
      ],
      "bbox": [
        -1.7761612389070518,
        41.202318406896474,
        -1.7761612389070518,
        41.202318406896474
      ]
    },
    "50193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6851462056724714,
              41.58211498293807
            ]
          },
          "bbox": [
            -0.6851462056724714,
            41.58211498293807,
            -0.6851462056724714,
            41.58211498293807
          ]
        }
      ],
      "bbox": [
        -0.6851462056724714,
        41.58211498293807,
        -0.6851462056724714,
        41.58211498293807
      ]
    },
    "50194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6631989580160835,
              41.225137414483726
            ]
          },
          "bbox": [
            -1.6631989580160835,
            41.225137414483726,
            -1.6631989580160835,
            41.225137414483726
          ]
        }
      ],
      "bbox": [
        -1.6631989580160835,
        41.225137414483726,
        -1.6631989580160835,
        41.225137414483726
      ]
    },
    "50195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.506099396243059,
              41.10466761719555
            ]
          },
          "bbox": [
            -1.506099396243059,
            41.10466761719555,
            -1.506099396243059,
            41.10466761719555
          ]
        }
      ],
      "bbox": [
        -1.506099396243059,
        41.10466761719555,
        -1.506099396243059,
        41.10466761719555
      ]
    },
    "50196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.462826570899871,
              41.30683728252404
            ]
          },
          "bbox": [
            -1.462826570899871,
            41.30683728252404,
            -1.462826570899871,
            41.30683728252404
          ]
        }
      ],
      "bbox": [
        -1.462826570899871,
        41.30683728252404,
        -1.462826570899871,
        41.30683728252404
      ]
    },
    "50197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0087513130829684,
              42.26299518660659
            ]
          },
          "bbox": [
            -1.0087513130829684,
            42.26299518660659,
            -1.0087513130829684,
            42.26299518660659
          ]
        }
      ],
      "bbox": [
        -1.0087513130829684,
        42.26299518660659,
        -1.0087513130829684,
        42.26299518660659
      ]
    },
    "50198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.69920788009627,
              41.60663013952664
            ]
          },
          "bbox": [
            -1.69920788009627,
            41.60663013952664,
            -1.69920788009627,
            41.60663013952664
          ]
        }
      ],
      "bbox": [
        -1.69920788009627,
        41.60663013952664,
        -1.69920788009627,
        41.60663013952664
      ]
    },
    "50199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5663763011876471,
              41.53592698054314
            ]
          },
          "bbox": [
            -0.5663763011876471,
            41.53592698054314,
            -0.5663763011876471,
            41.53592698054314
          ]
        }
      ],
      "bbox": [
        -0.5663763011876471,
        41.53592698054314,
        -0.5663763011876471,
        41.53592698054314
      ]
    },
    "50200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2031220750825342,
              41.27390743037126
            ]
          },
          "bbox": [
            -1.2031220750825342,
            41.27390743037126,
            -1.2031220750825342,
            41.27390743037126
          ]
        }
      ],
      "bbox": [
        -1.2031220750825342,
        41.27390743037126,
        -1.2031220750825342,
        41.27390743037126
      ]
    },
    "50201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6524855328167471,
              41.30210064360994
            ]
          },
          "bbox": [
            -1.6524855328167471,
            41.30210064360994,
            -1.6524855328167471,
            41.30210064360994
          ]
        }
      ],
      "bbox": [
        -1.6524855328167471,
        41.30210064360994,
        -1.6524855328167471,
        41.30210064360994
      ]
    },
    "50202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5515348454254834,
              41.42019784750285
            ]
          },
          "bbox": [
            -1.5515348454254834,
            41.42019784750285,
            -1.5515348454254834,
            41.42019784750285
          ]
        }
      ],
      "bbox": [
        -1.5515348454254834,
        41.42019784750285,
        -1.5515348454254834,
        41.42019784750285
      ]
    },
    "50203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7665828191083509,
              41.60477340436847
            ]
          },
          "bbox": [
            -0.7665828191083509,
            41.60477340436847,
            -0.7665828191083509,
            41.60477340436847
          ]
        }
      ],
      "bbox": [
        -0.7665828191083509,
        41.60477340436847,
        -0.7665828191083509,
        41.60477340436847
      ]
    },
    "50204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2614745630018451,
              41.76009390463841
            ]
          },
          "bbox": [
            -1.2614745630018451,
            41.76009390463841,
            -1.2614745630018451,
            41.76009390463841
          ]
        }
      ],
      "bbox": [
        -1.2614745630018451,
        41.76009390463841,
        -1.2614745630018451,
        41.76009390463841
      ]
    },
    "50205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8781805247650456,
              42.029241306661866
            ]
          },
          "bbox": [
            -0.8781805247650456,
            42.029241306661866,
            -0.8781805247650456,
            42.029241306661866
          ]
        }
      ],
      "bbox": [
        -0.8781805247650456,
        42.029241306661866,
        -0.8781805247650456,
        42.029241306661866
      ]
    },
    "50206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6117611252586137,
              41.746088195489875
            ]
          },
          "bbox": [
            -0.6117611252586137,
            41.746088195489875,
            -0.6117611252586137,
            41.746088195489875
          ]
        }
      ],
      "bbox": [
        -0.6117611252586137,
        41.746088195489875,
        -0.6117611252586137,
        41.746088195489875
      ]
    },
    "50207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7903913275126864,
              42.12518889324616
            ]
          },
          "bbox": [
            -0.7903913275126864,
            42.12518889324616,
            -0.7903913275126864,
            42.12518889324616
          ]
        }
      ],
      "bbox": [
        -0.7903913275126864,
        42.12518889324616,
        -0.7903913275126864,
        42.12518889324616
      ]
    },
    "50208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3954125653753935,
              41.50907742455811
            ]
          },
          "bbox": [
            -0.3954125653753935,
            41.50907742455811,
            -0.3954125653753935,
            41.50907742455811
          ]
        }
      ],
      "bbox": [
        -0.3954125653753935,
        41.50907742455811,
        -0.3954125653753935,
        41.50907742455811
      ]
    },
    "50209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0867332965626786,
              41.728469288337344
            ]
          },
          "bbox": [
            -1.0867332965626786,
            41.728469288337344,
            -1.0867332965626786,
            41.728469288337344
          ]
        }
      ],
      "bbox": [
        -1.0867332965626786,
        41.728469288337344,
        -1.0867332965626786,
        41.728469288337344
      ]
    },
    "50210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.02949498994594,
              42.54938702390665
            ]
          },
          "bbox": [
            -1.02949498994594,
            42.54938702390665,
            -1.02949498994594,
            42.54938702390665
          ]
        }
      ],
      "bbox": [
        -1.02949498994594,
        42.54938702390665,
        -1.02949498994594,
        42.54938702390665
      ]
    },
    "50211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.264145044687033,
              41.69218023355392
            ]
          },
          "bbox": [
            -1.264145044687033,
            41.69218023355392,
            -1.264145044687033,
            41.69218023355392
          ]
        }
      ],
      "bbox": [
        -1.264145044687033,
        41.69218023355392,
        -1.264145044687033,
        41.69218023355392
      ]
    },
    "50212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1999898596380727,
              41.713455449849974
            ]
          },
          "bbox": [
            -1.1999898596380727,
            41.713455449849974,
            -1.1999898596380727,
            41.713455449849974
          ]
        }
      ],
      "bbox": [
        -1.1999898596380727,
        41.713455449849974,
        -1.1999898596380727,
        41.713455449849974
      ]
    },
    "50213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9592811832665225,
              41.11113669695058
            ]
          },
          "bbox": [
            -0.9592811832665225,
            41.11113669695058,
            -0.9592811832665225,
            41.11113669695058
          ]
        }
      ],
      "bbox": [
        -0.9592811832665225,
        41.11113669695058,
        -0.9592811832665225,
        41.11113669695058
      ]
    },
    "50214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.84180638037764,
              41.63605658572954
            ]
          },
          "bbox": [
            -1.84180638037764,
            41.63605658572954,
            -1.84180638037764,
            41.63605658572954
          ]
        }
      ],
      "bbox": [
        -1.84180638037764,
        41.63605658572954,
        -1.84180638037764,
        41.63605658572954
      ]
    },
    "50215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1283662206814546,
              41.352318626940026
            ]
          },
          "bbox": [
            -2.1283662206814546,
            41.352318626940026,
            -2.1283662206814546,
            41.352318626940026
          ]
        }
      ],
      "bbox": [
        -2.1283662206814546,
        41.352318626940026,
        -2.1283662206814546,
        41.352318626940026
      ]
    },
    "50216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3809567024817058,
              41.75296670532677
            ]
          },
          "bbox": [
            -1.3809567024817058,
            41.75296670532677,
            -1.3809567024817058,
            41.75296670532677
          ]
        }
      ],
      "bbox": [
        -1.3809567024817058,
        41.75296670532677,
        -1.3809567024817058,
        41.75296670532677
      ]
    },
    "50217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1860274440833778,
              41.87357031710185
            ]
          },
          "bbox": [
            -1.1860274440833778,
            41.87357031710185,
            -1.1860274440833778,
            41.87357031710185
          ]
        }
      ],
      "bbox": [
        -1.1860274440833778,
        41.87357031710185,
        -1.1860274440833778,
        41.87357031710185
      ]
    },
    "50218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8416834485361598,
              41.39520193368592
            ]
          },
          "bbox": [
            -0.8416834485361598,
            41.39520193368592,
            -0.8416834485361598,
            41.39520193368592
          ]
        }
      ],
      "bbox": [
        -0.8416834485361598,
        41.39520193368592,
        -0.8416834485361598,
        41.39520193368592
      ]
    },
    "50219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.747700010965829,
              41.63517294106156
            ]
          },
          "bbox": [
            -0.747700010965829,
            41.63517294106156,
            -0.747700010965829,
            41.63517294106156
          ]
        }
      ],
      "bbox": [
        -0.747700010965829,
        41.63517294106156,
        -0.747700010965829,
        41.63517294106156
      ]
    },
    "50220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7734856158471143,
              42.15191526242525
            ]
          },
          "bbox": [
            -0.7734856158471143,
            42.15191526242525,
            -0.7734856158471143,
            42.15191526242525
          ]
        }
      ],
      "bbox": [
        -0.7734856158471143,
        42.15191526242525,
        -0.7734856158471143,
        42.15191526242525
      ]
    },
    "50221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7671878902617755,
              41.691069406602196
            ]
          },
          "bbox": [
            -1.7671878902617755,
            41.691069406602196,
            -1.7671878902617755,
            41.691069406602196
          ]
        }
      ],
      "bbox": [
        -1.7671878902617755,
        41.691069406602196,
        -1.7671878902617755,
        41.691069406602196
      ]
    },
    "50222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5306218322979754,
              41.40625565538873
            ]
          },
          "bbox": [
            -0.5306218322979754,
            41.40625565538873,
            -0.5306218322979754,
            41.40625565538873
          ]
        }
      ],
      "bbox": [
        -0.5306218322979754,
        41.40625565538873,
        -0.5306218322979754,
        41.40625565538873
      ]
    },
    "50223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1750369831003775,
              41.846910689741506
            ]
          },
          "bbox": [
            -1.1750369831003775,
            41.846910689741506,
            -1.1750369831003775,
            41.846910689741506
          ]
        }
      ],
      "bbox": [
        -1.1750369831003775,
        41.846910689741506,
        -1.1750369831003775,
        41.846910689741506
      ]
    },
    "50224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3783396458947024,
              41.159641567222714
            ]
          },
          "bbox": [
            -1.3783396458947024,
            41.159641567222714,
            -1.3783396458947024,
            41.159641567222714
          ]
        }
      ],
      "bbox": [
        -1.3783396458947024,
        41.159641567222714,
        -1.3783396458947024,
        41.159641567222714
      ]
    },
    "50225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4107829152389995,
              41.53083727137488
            ]
          },
          "bbox": [
            -1.4107829152389995,
            41.53083727137488,
            -1.4107829152389995,
            41.53083727137488
          ]
        }
      ],
      "bbox": [
        -1.4107829152389995,
        41.53083727137488,
        -1.4107829152389995,
        41.53083727137488
      ]
    },
    "50227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.287269884628876,
              41.12154124530066
            ]
          },
          "bbox": [
            -1.287269884628876,
            41.12154124530066,
            -1.287269884628876,
            41.12154124530066
          ]
        }
      ],
      "bbox": [
        -1.287269884628876,
        41.12154124530066,
        -1.287269884628876,
        41.12154124530066
      ]
    },
    "50228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.348885951691133,
              41.67704026524058
            ]
          },
          "bbox": [
            -1.348885951691133,
            41.67704026524058,
            -1.348885951691133,
            41.67704026524058
          ]
        }
      ],
      "bbox": [
        -1.348885951691133,
        41.67704026524058,
        -1.348885951691133,
        41.67704026524058
      ]
    },
    "50229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4559209952976908,
              41.28125996645791
            ]
          },
          "bbox": [
            -1.4559209952976908,
            41.28125996645791,
            -1.4559209952976908,
            41.28125996645791
          ]
        }
      ],
      "bbox": [
        -1.4559209952976908,
        41.28125996645791,
        -1.4559209952976908,
        41.28125996645791
      ]
    },
    "50230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3322971054309272,
              42.27138876414042
            ]
          },
          "bbox": [
            -1.3322971054309272,
            42.27138876414042,
            -1.3322971054309272,
            42.27138876414042
          ]
        }
      ],
      "bbox": [
        -1.3322971054309272,
        42.27138876414042,
        -1.3322971054309272,
        42.27138876414042
      ]
    },
    "50231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3242844378414242,
              41.561623869490454
            ]
          },
          "bbox": [
            -1.3242844378414242,
            41.561623869490454,
            -1.3242844378414242,
            41.561623869490454
          ]
        }
      ],
      "bbox": [
        -1.3242844378414242,
        41.561623869490454,
        -1.3242844378414242,
        41.561623869490454
      ]
    },
    "50232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9583982869651593,
              42.68425310770924
            ]
          },
          "bbox": [
            -0.9583982869651593,
            42.68425310770924,
            -0.9583982869651593,
            42.68425310770924
          ]
        }
      ],
      "bbox": [
        -0.9583982869651593,
        42.68425310770924,
        -0.9583982869651593,
        42.68425310770924
      ]
    },
    "50233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.831524975658517,
              41.21334804203495
            ]
          },
          "bbox": [
            -0.831524975658517,
            41.21334804203495,
            -0.831524975658517,
            41.21334804203495
          ]
        }
      ],
      "bbox": [
        -0.831524975658517,
        41.21334804203495,
        -0.831524975658517,
        41.21334804203495
      ]
    },
    "50234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7919508435651825,
              41.83508370718959
            ]
          },
          "bbox": [
            -1.7919508435651825,
            41.83508370718959,
            -1.7919508435651825,
            41.83508370718959
          ]
        }
      ],
      "bbox": [
        -1.7919508435651825,
        41.83508370718959,
        -1.7919508435651825,
        41.83508370718959
      ]
    },
    "50235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7313645339018776,
              41.8359927096629
            ]
          },
          "bbox": [
            -0.7313645339018776,
            41.8359927096629,
            -0.7313645339018776,
            41.8359927096629
          ]
        }
      ],
      "bbox": [
        -0.7313645339018776,
        41.8359927096629,
        -0.7313645339018776,
        41.8359927096629
      ]
    },
    "50236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4246804445237249,
              41.37491685281806
            ]
          },
          "bbox": [
            -1.4246804445237249,
            41.37491685281806,
            -1.4246804445237249,
            41.37491685281806
          ]
        }
      ],
      "bbox": [
        -1.4246804445237249,
        41.37491685281806,
        -1.4246804445237249,
        41.37491685281806
      ]
    },
    "50237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7572464931753655,
              41.881652022945815
            ]
          },
          "bbox": [
            -1.7572464931753655,
            41.881652022945815,
            -1.7572464931753655,
            41.881652022945815
          ]
        }
      ],
      "bbox": [
        -1.7572464931753655,
        41.881652022945815,
        -1.7572464931753655,
        41.881652022945815
      ]
    },
    "50238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.782247257506607,
              42.26777834679549
            ]
          },
          "bbox": [
            -0.782247257506607,
            42.26777834679549,
            -0.782247257506607,
            42.26777834679549
          ]
        }
      ],
      "bbox": [
        -0.782247257506607,
        42.26777834679549,
        -0.782247257506607,
        42.26777834679549
      ]
    },
    "50239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5098991506304096,
              41.03467751267055
            ]
          },
          "bbox": [
            -1.5098991506304096,
            41.03467751267055,
            -1.5098991506304096,
            41.03467751267055
          ]
        }
      ],
      "bbox": [
        -1.5098991506304096,
        41.03467751267055,
        -1.5098991506304096,
        41.03467751267055
      ]
    },
    "50240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2592069307881574,
              41.35030075457882
            ]
          },
          "bbox": [
            -0.2592069307881574,
            41.35030075457882,
            -0.2592069307881574,
            41.35030075457882
          ]
        }
      ],
      "bbox": [
        -0.2592069307881574,
        41.35030075457882,
        -0.2592069307881574,
        41.35030075457882
      ]
    },
    "50241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.558514342390331,
              41.4491134345266
            ]
          },
          "bbox": [
            -1.558514342390331,
            41.4491134345266,
            -1.558514342390331,
            41.4491134345266
          ]
        }
      ],
      "bbox": [
        -1.558514342390331,
        41.4491134345266,
        -1.558514342390331,
        41.4491134345266
      ]
    },
    "50242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5158812586666033,
              41.35604922259927
            ]
          },
          "bbox": [
            -1.5158812586666033,
            41.35604922259927,
            -1.5158812586666033,
            41.35604922259927
          ]
        }
      ],
      "bbox": [
        -1.5158812586666033,
        41.35604922259927,
        -1.5158812586666033,
        41.35604922259927
      ]
    },
    "50243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6311307702841868,
              41.48493954364034
            ]
          },
          "bbox": [
            -1.6311307702841868,
            41.48493954364034,
            -1.6311307702841868,
            41.48493954364034
          ]
        }
      ],
      "bbox": [
        -1.6311307702841868,
        41.48493954364034,
        -1.6311307702841868,
        41.48493954364034
      ]
    },
    "50244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9327862701442303,
              42.044343277670166
            ]
          },
          "bbox": [
            -0.9327862701442303,
            42.044343277670166,
            -0.9327862701442303,
            42.044343277670166
          ]
        }
      ],
      "bbox": [
        -0.9327862701442303,
        42.044343277670166,
        -0.9327862701442303,
        42.044343277670166
      ]
    },
    "50245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.028293733082596,
              42.624109385104475
            ]
          },
          "bbox": [
            -1.028293733082596,
            42.624109385104475,
            -1.028293733082596,
            42.624109385104475
          ]
        }
      ],
      "bbox": [
        -1.028293733082596,
        42.624109385104475,
        -1.028293733082596,
        42.624109385104475
      ]
    },
    "50246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0090146196353094,
              41.180644422683095
            ]
          },
          "bbox": [
            -2.0090146196353094,
            41.180644422683095,
            -2.0090146196353094,
            41.180644422683095
          ]
        }
      ],
      "bbox": [
        -2.0090146196353094,
        41.180644422683095,
        -2.0090146196353094,
        41.180644422683095
      ]
    },
    "50247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0295839748996491,
              41.742663470155726
            ]
          },
          "bbox": [
            -1.0295839748996491,
            41.742663470155726,
            -1.0295839748996491,
            41.742663470155726
          ]
        }
      ],
      "bbox": [
        -1.0295839748996491,
        41.742663470155726,
        -1.0295839748996491,
        41.742663470155726
      ]
    },
    "50248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2345123906080075,
              42.46376390310012
            ]
          },
          "bbox": [
            -1.2345123906080075,
            42.46376390310012,
            -1.2345123906080075,
            42.46376390310012
          ]
        }
      ],
      "bbox": [
        -1.2345123906080075,
        42.46376390310012,
        -1.2345123906080075,
        42.46376390310012
      ]
    },
    "50249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5237309725268957,
              41.68293132116886
            ]
          },
          "bbox": [
            -1.5237309725268957,
            41.68293132116886,
            -1.5237309725268957,
            41.68293132116886
          ]
        }
      ],
      "bbox": [
        -1.5237309725268957,
        41.68293132116886,
        -1.5237309725268957,
        41.68293132116886
      ]
    },
    "50250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6642304360843727,
              41.724730446608746
            ]
          },
          "bbox": [
            -1.6642304360843727,
            41.724730446608746,
            -1.6642304360843727,
            41.724730446608746
          ]
        }
      ],
      "bbox": [
        -1.6642304360843727,
        41.724730446608746,
        -1.6642304360843727,
        41.724730446608746
      ]
    },
    "50251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.747982505039896,
              41.91081426738401
            ]
          },
          "bbox": [
            -1.747982505039896,
            41.91081426738401,
            -1.747982505039896,
            41.91081426738401
          ]
        }
      ],
      "bbox": [
        -1.747982505039896,
        41.91081426738401,
        -1.747982505039896,
        41.91081426738401
      ]
    },
    "50252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2019277296057518,
              41.94843087371716
            ]
          },
          "bbox": [
            -1.2019277296057518,
            41.94843087371716,
            -1.2019277296057518,
            41.94843087371716
          ]
        }
      ],
      "bbox": [
        -1.2019277296057518,
        41.94843087371716,
        -1.2019277296057518,
        41.94843087371716
      ]
    },
    "50253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7272072666714635,
              41.32831033188626
            ]
          },
          "bbox": [
            -1.7272072666714635,
            41.32831033188626,
            -1.7272072666714635,
            41.32831033188626
          ]
        }
      ],
      "bbox": [
        -1.7272072666714635,
        41.32831033188626,
        -1.7272072666714635,
        41.32831033188626
      ]
    },
    "50254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5878800898590122,
              41.61207059890561
            ]
          },
          "bbox": [
            -1.5878800898590122,
            41.61207059890561,
            -1.5878800898590122,
            41.61207059890561
          ]
        }
      ],
      "bbox": [
        -1.5878800898590122,
        41.61207059890561,
        -1.5878800898590122,
        41.61207059890561
      ]
    },
    "50255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3913031184271103,
              41.34526917713849
            ]
          },
          "bbox": [
            -1.3913031184271103,
            41.34526917713849,
            -1.3913031184271103,
            41.34526917713849
          ]
        }
      ],
      "bbox": [
        -1.3913031184271103,
        41.34526917713849,
        -1.3913031184271103,
        41.34526917713849
      ]
    },
    "50256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6594636084638958,
              41.01768769024597
            ]
          },
          "bbox": [
            -1.6594636084638958,
            41.01768769024597,
            -1.6594636084638958,
            41.01768769024597
          ]
        }
      ],
      "bbox": [
        -1.6594636084638958,
        41.01768769024597,
        -1.6594636084638958,
        41.01768769024597
      ]
    },
    "50257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6756520177043852,
              41.422650253648
            ]
          },
          "bbox": [
            -1.6756520177043852,
            41.422650253648,
            -1.6756520177043852,
            41.422650253648
          ]
        }
      ],
      "bbox": [
        -1.6756520177043852,
        41.422650253648,
        -1.6756520177043852,
        41.422650253648
      ]
    },
    "50258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3410537435028538,
              41.22040665862453
            ]
          },
          "bbox": [
            -1.3410537435028538,
            41.22040665862453,
            -1.3410537435028538,
            41.22040665862453
          ]
        }
      ],
      "bbox": [
        -1.3410537435028538,
        41.22040665862453,
        -1.3410537435028538,
        41.22040665862453
      ]
    },
    "50259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.126583680332629,
              41.2420453667507
            ]
          },
          "bbox": [
            -2.126583680332629,
            41.2420453667507,
            -2.126583680332629,
            41.2420453667507
          ]
        }
      ],
      "bbox": [
        -2.126583680332629,
        41.2420453667507,
        -2.126583680332629,
        41.2420453667507
      ]
    },
    "50260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9479032511886158,
              41.590420753930964
            ]
          },
          "bbox": [
            -1.9479032511886158,
            41.590420753930964,
            -1.9479032511886158,
            41.590420753930964
          ]
        }
      ],
      "bbox": [
        -1.9479032511886158,
        41.590420753930964,
        -1.9479032511886158,
        41.590420753930964
      ]
    },
    "50261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7687426165751727,
              41.89619143958763
            ]
          },
          "bbox": [
            -1.7687426165751727,
            41.89619143958763,
            -1.7687426165751727,
            41.89619143958763
          ]
        }
      ],
      "bbox": [
        -1.7687426165751727,
        41.89619143958763,
        -1.7687426165751727,
        41.89619143958763
      ]
    },
    "50262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1041858672432991,
              41.81715419009859
            ]
          },
          "bbox": [
            -1.1041858672432991,
            41.81715419009859,
            -1.1041858672432991,
            41.81715419009859
          ]
        }
      ],
      "bbox": [
        -1.1041858672432991,
        41.81715419009859,
        -1.1041858672432991,
        41.81715419009859
      ]
    },
    "50263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.899342572275713,
              41.468964872979136
            ]
          },
          "bbox": [
            -1.899342572275713,
            41.468964872979136,
            -1.899342572275713,
            41.468964872979136
          ]
        }
      ],
      "bbox": [
        -1.899342572275713,
        41.468964872979136,
        -1.899342572275713,
        41.468964872979136
      ]
    },
    "50264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1085609136114054,
              41.306627267934566
            ]
          },
          "bbox": [
            -1.1085609136114054,
            41.306627267934566,
            -1.1085609136114054,
            41.306627267934566
          ]
        }
      ],
      "bbox": [
        -1.1085609136114054,
        41.306627267934566,
        -1.1085609136114054,
        41.306627267934566
      ]
    },
    "50265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7339303551463106,
              41.82372528870217
            ]
          },
          "bbox": [
            -1.7339303551463106,
            41.82372528870217,
            -1.7339303551463106,
            41.82372528870217
          ]
        }
      ],
      "bbox": [
        -1.7339303551463106,
        41.82372528870217,
        -1.7339303551463106,
        41.82372528870217
      ]
    },
    "50266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6261555799268965,
              41.65688807747222
            ]
          },
          "bbox": [
            -1.6261555799268965,
            41.65688807747222,
            -1.6261555799268965,
            41.65688807747222
          ]
        }
      ],
      "bbox": [
        -1.6261555799268965,
        41.65688807747222,
        -1.6261555799268965,
        41.65688807747222
      ]
    },
    "50267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1538814451873183,
              42.36511988452455
            ]
          },
          "bbox": [
            -1.1538814451873183,
            42.36511988452455,
            -1.1538814451873183,
            42.36511988452455
          ]
        }
      ],
      "bbox": [
        -1.1538814451873183,
        42.36511988452455,
        -1.1538814451873183,
        42.36511988452455
      ]
    },
    "50268": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1531327020040951,
              42.57788333521012
            ]
          },
          "bbox": [
            -1.1531327020040951,
            42.57788333521012,
            -1.1531327020040951,
            42.57788333521012
          ]
        }
      ],
      "bbox": [
        -1.1531327020040951,
        42.57788333521012,
        -1.1531327020040951,
        42.57788333521012
      ]
    },
    "50269": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2271274822227487,
              41.64883053647128
            ]
          },
          "bbox": [
            -1.2271274822227487,
            41.64883053647128,
            -1.2271274822227487,
            41.64883053647128
          ]
        }
      ],
      "bbox": [
        -1.2271274822227487,
        41.64883053647128,
        -1.2271274822227487,
        41.64883053647128
      ]
    },
    "50270": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1060854054959184,
              42.547496117870125
            ]
          },
          "bbox": [
            -1.1060854054959184,
            42.547496117870125,
            -1.1060854054959184,
            42.547496117870125
          ]
        }
      ],
      "bbox": [
        -1.1060854054959184,
        42.547496117870125,
        -1.1060854054959184,
        42.547496117870125
      ]
    },
    "50271": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5806482866470821,
              41.029927026488544
            ]
          },
          "bbox": [
            -1.5806482866470821,
            41.029927026488544,
            -1.5806482866470821,
            41.029927026488544
          ]
        }
      ],
      "bbox": [
        -1.5806482866470821,
        41.029927026488544,
        -1.5806482866470821,
        41.029927026488544
      ]
    },
    "50272": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0001107668823068,
              41.714118416648816
            ]
          },
          "bbox": [
            -1.0001107668823068,
            41.714118416648816,
            -1.0001107668823068,
            41.714118416648816
          ]
        }
      ],
      "bbox": [
        -1.0001107668823068,
        41.714118416648816,
        -1.0001107668823068,
        41.714118416648816
      ]
    },
    "50273": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4206227699503353,
              41.06299703996636
            ]
          },
          "bbox": [
            -1.4206227699503353,
            41.06299703996636,
            -1.4206227699503353,
            41.06299703996636
          ]
        }
      ],
      "bbox": [
        -1.4206227699503353,
        41.06299703996636,
        -1.4206227699503353,
        41.06299703996636
      ]
    },
    "50274": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4533793156397736,
              41.04360273957174
            ]
          },
          "bbox": [
            -1.4533793156397736,
            41.04360273957174,
            -1.4533793156397736,
            41.04360273957174
          ]
        }
      ],
      "bbox": [
        -1.4533793156397736,
        41.04360273957174,
        -1.4533793156397736,
        41.04360273957174
      ]
    },
    "50275": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8850930914728751,
              41.4491329852921
            ]
          },
          "bbox": [
            -0.8850930914728751,
            41.4491329852921,
            -0.8850930914728751,
            41.4491329852921
          ]
        }
      ],
      "bbox": [
        -0.8850930914728751,
        41.4491329852921,
        -0.8850930914728751,
        41.4491329852921
      ]
    },
    "50276": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8510641120584072,
              42.156672847903906
            ]
          },
          "bbox": [
            -0.8510641120584072,
            42.156672847903906,
            -0.8510641120584072,
            42.156672847903906
          ]
        }
      ],
      "bbox": [
        -0.8510641120584072,
        42.156672847903906,
        -0.8510641120584072,
        42.156672847903906
      ]
    },
    "50277": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.745272268038116,
              41.298739051778995
            ]
          },
          "bbox": [
            -1.745272268038116,
            41.298739051778995,
            -1.745272268038116,
            41.298739051778995
          ]
        }
      ],
      "bbox": [
        -1.745272268038116,
        41.298739051778995,
        -1.745272268038116,
        41.298739051778995
      ]
    },
    "50278": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3859230991334318,
              41.38886745208269
            ]
          },
          "bbox": [
            -0.3859230991334318,
            41.38886745208269,
            -0.3859230991334318,
            41.38886745208269
          ]
        }
      ],
      "bbox": [
        -0.3859230991334318,
        41.38886745208269,
        -0.3859230991334318,
        41.38886745208269
      ]
    },
    "50279": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5922951783880026,
              41.271794963568134
            ]
          },
          "bbox": [
            -1.5922951783880026,
            41.271794963568134,
            -1.5922951783880026,
            41.271794963568134
          ]
        }
      ],
      "bbox": [
        -1.5922951783880026,
        41.271794963568134,
        -1.5922951783880026,
        41.271794963568134
      ]
    },
    "50280": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.680520242960645,
              41.83129556283603
            ]
          },
          "bbox": [
            -1.680520242960645,
            41.83129556283603,
            -1.680520242960645,
            41.83129556283603
          ]
        }
      ],
      "bbox": [
        -1.680520242960645,
        41.83129556283603,
        -1.680520242960645,
        41.83129556283603
      ]
    },
    "50281": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6839650367347967,
              41.93045145771905
            ]
          },
          "bbox": [
            -1.6839650367347967,
            41.93045145771905,
            -1.6839650367347967,
            41.93045145771905
          ]
        }
      ],
      "bbox": [
        -1.6839650367347967,
        41.93045145771905,
        -1.6839650367347967,
        41.93045145771905
      ]
    },
    "50282": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7234571261401106,
              41.28222804418518
            ]
          },
          "bbox": [
            -1.7234571261401106,
            41.28222804418518,
            -1.7234571261401106,
            41.28222804418518
          ]
        }
      ],
      "bbox": [
        -1.7234571261401106,
        41.28222804418518,
        -1.7234571261401106,
        41.28222804418518
      ]
    },
    "50283": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2758683902670418,
              41.16558473477955
            ]
          },
          "bbox": [
            -1.2758683902670418,
            41.16558473477955,
            -1.2758683902670418,
            41.16558473477955
          ]
        }
      ],
      "bbox": [
        -1.2758683902670418,
        41.16558473477955,
        -1.2758683902670418,
        41.16558473477955
      ]
    },
    "50284": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4996886718962381,
              41.18506902122409
            ]
          },
          "bbox": [
            -1.4996886718962381,
            41.18506902122409,
            -1.4996886718962381,
            41.18506902122409
          ]
        }
      ],
      "bbox": [
        -1.4996886718962381,
        41.18506902122409,
        -1.4996886718962381,
        41.18506902122409
      ]
    },
    "50285": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5780317942175032,
              41.57455398972982
            ]
          },
          "bbox": [
            -0.5780317942175032,
            41.57455398972982,
            -0.5780317942175032,
            41.57455398972982
          ]
        }
      ],
      "bbox": [
        -0.5780317942175032,
        41.57455398972982,
        -0.5780317942175032,
        41.57455398972982
      ]
    },
    "50286": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5566983011158875,
              41.33215184858954
            ]
          },
          "bbox": [
            -1.5566983011158875,
            41.33215184858954,
            -1.5566983011158875,
            41.33215184858954
          ]
        }
      ],
      "bbox": [
        -1.5566983011158875,
        41.33215184858954,
        -1.5566983011158875,
        41.33215184858954
      ]
    },
    "50287": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.877320873733376,
              41.42291314802518
            ]
          },
          "bbox": [
            -1.877320873733376,
            41.42291314802518,
            -1.877320873733376,
            41.42291314802518
          ]
        }
      ],
      "bbox": [
        -1.877320873733376,
        41.42291314802518,
        -1.877320873733376,
        41.42291314802518
      ]
    },
    "50288": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8614281252937841,
              41.78882470503431
            ]
          },
          "bbox": [
            -0.8614281252937841,
            41.78882470503431,
            -0.8614281252937841,
            41.78882470503431
          ]
        }
      ],
      "bbox": [
        -0.8614281252937841,
        41.78882470503431,
        -0.8614281252937841,
        41.78882470503431
      ]
    },
    "50289": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3840378124944883,
              41.08166563031886
            ]
          },
          "bbox": [
            -1.3840378124944883,
            41.08166563031886,
            -1.3840378124944883,
            41.08166563031886
          ]
        }
      ],
      "bbox": [
        -1.3840378124944883,
        41.08166563031886,
        -1.3840378124944883,
        41.08166563031886
      ]
    },
    "50290": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0258674949887585,
              41.359476118700314
            ]
          },
          "bbox": [
            -1.0258674949887585,
            41.359476118700314,
            -1.0258674949887585,
            41.359476118700314
          ]
        }
      ],
      "bbox": [
        -1.0258674949887585,
        41.359476118700314,
        -1.0258674949887585,
        41.359476118700314
      ]
    },
    "50291": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0128019291325872,
              41.16766160233616
            ]
          },
          "bbox": [
            -1.0128019291325872,
            41.16766160233616,
            -1.0128019291325872,
            41.16766160233616
          ]
        }
      ],
      "bbox": [
        -1.0128019291325872,
        41.16766160233616,
        -1.0128019291325872,
        41.16766160233616
      ]
    },
    "50292": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.262439064388899,
              41.20316534543509
            ]
          },
          "bbox": [
            -1.262439064388899,
            41.20316534543509,
            -1.262439064388899,
            41.20316534543509
          ]
        }
      ],
      "bbox": [
        -1.262439064388899,
        41.20316534543509,
        -1.262439064388899,
        41.20316534543509
      ]
    },
    "50293": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7837296423740345,
              41.49122081192092
            ]
          },
          "bbox": [
            -1.7837296423740345,
            41.49122081192092,
            -1.7837296423740345,
            41.49122081192092
          ]
        }
      ],
      "bbox": [
        -1.7837296423740345,
        41.49122081192092,
        -1.7837296423740345,
        41.49122081192092
      ]
    },
    "50294": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.326764649345515,
              41.14416371343766
            ]
          },
          "bbox": [
            -1.326764649345515,
            41.14416371343766,
            -1.326764649345515,
            41.14416371343766
          ]
        }
      ],
      "bbox": [
        -1.326764649345515,
        41.14416371343766,
        -1.326764649345515,
        41.14416371343766
      ]
    },
    "50295": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1601507576725105,
              41.21074669666872
            ]
          },
          "bbox": [
            -1.1601507576725105,
            41.21074669666872,
            -1.1601507576725105,
            41.21074669666872
          ]
        }
      ],
      "bbox": [
        -1.1601507576725105,
        41.21074669666872,
        -1.1601507576725105,
        41.21074669666872
      ]
    },
    "50296": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.44535163809708833,
              41.3311186499322
            ]
          },
          "bbox": [
            -0.44535163809708833,
            41.3311186499322,
            -0.44535163809708833,
            41.3311186499322
          ]
        }
      ],
      "bbox": [
        -0.44535163809708833,
        41.3311186499322,
        -0.44535163809708833,
        41.3311186499322
      ]
    },
    "50297": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9274724213603869,
              41.68071433948564
            ]
          },
          "bbox": [
            -0.9274724213603869,
            41.68071433948564,
            -0.9274724213603869,
            41.68071433948564
          ]
        }
      ],
      "bbox": [
        -0.9274724213603869,
        41.68071433948564,
        -0.9274724213603869,
        41.68071433948564
      ]
    },
    "50298": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.818027825239409,
              41.90802031208964
            ]
          },
          "bbox": [
            -0.818027825239409,
            41.90802031208964,
            -0.818027825239409,
            41.90802031208964
          ]
        }
      ],
      "bbox": [
        -0.818027825239409,
        41.90802031208964,
        -0.818027825239409,
        41.90802031208964
      ]
    },
    "50901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9127477362692302,
              42.369096014452744
            ]
          },
          "bbox": [
            -0.9127477362692302,
            42.369096014452744,
            -0.9127477362692302,
            42.369096014452744
          ]
        }
      ],
      "bbox": [
        -0.9127477362692302,
        42.369096014452744,
        -0.9127477362692302,
        42.369096014452744
      ]
    },
    "50902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7824039852617038,
              42.081178221298835
            ]
          },
          "bbox": [
            -0.7824039852617038,
            42.081178221298835,
            -0.7824039852617038,
            42.081178221298835
          ]
        }
      ],
      "bbox": [
        -0.7824039852617038,
        42.081178221298835,
        -0.7824039852617038,
        42.081178221298835
      ]
    },
    "50903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.739789809528397,
              41.69301402904772
            ]
          },
          "bbox": [
            -0.739789809528397,
            41.69301402904772,
            -0.739789809528397,
            41.69301402904772
          ]
        }
      ],
      "bbox": [
        -0.739789809528397,
        41.69301402904772,
        -0.739789809528397,
        41.69301402904772
      ]
    },
    "51001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.342845175340998,
              35.893669227658755
            ]
          },
          "bbox": [
            -5.342845175340998,
            35.893669227658755,
            -5.342845175340998,
            35.893669227658755
          ]
        }
      ],
      "bbox": [
        -5.342845175340998,
        35.893669227658755,
        -5.342845175340998,
        35.893669227658755
      ]
    },
    "52001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.950436506926661,
              35.29104609268949
            ]
          },
          "bbox": [
            -2.950436506926661,
            35.29104609268949,
            -2.950436506926661,
            35.29104609268949
          ]
        }
      ],
      "bbox": [
        -2.950436506926661,
        35.29104609268949,
        -2.950436506926661,
        35.29104609268949
      ]
    },
    "53000": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2842355458549193,
              42.93189061811366
            ]
          },
          "bbox": [
            -2.2842355458549193,
            42.93189061811366,
            -2.2842355458549193,
            42.93189061811366
          ]
        }
      ],
      "bbox": [
        -2.2842355458549193,
        42.93189061811366,
        -2.2842355458549193,
        42.93189061811366
      ]
    },
    "53001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8606944897194695,
              42.88041911663588
            ]
          },
          "bbox": [
            -2.8606944897194695,
            42.88041911663588,
            -2.8606944897194695,
            42.88041911663588
          ]
        }
      ],
      "bbox": [
        -2.8606944897194695,
        42.88041911663588,
        -2.8606944897194695,
        42.88041911663588
      ]
    },
    "53002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.304495071220448,
              42.809857829706
            ]
          },
          "bbox": [
            -2.304495071220448,
            42.809857829706,
            -2.304495071220448,
            42.809857829706
          ]
        }
      ],
      "bbox": [
        -2.304495071220448,
        42.809857829706,
        -2.304495071220448,
        42.809857829706
      ]
    },
    "53003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5535590300388282,
              38.41745844212868
            ]
          },
          "bbox": [
            -2.5535590300388282,
            38.41745844212868,
            -2.5535590300388282,
            38.41745844212868
          ]
        }
      ],
      "bbox": [
        -2.5535590300388282,
        38.41745844212868,
        -2.5535590300388282,
        38.41745844212868
      ]
    },
    "53004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3365938903383983,
              41.872432317284456
            ]
          },
          "bbox": [
            -3.3365938903383983,
            41.872432317284456,
            -3.3365938903383983,
            41.872432317284456
          ]
        }
      ],
      "bbox": [
        -3.3365938903383983,
        41.872432317284456,
        -3.3365938903383983,
        41.872432317284456
      ]
    },
    "53005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5726966102544977,
              42.17804452988592
            ]
          },
          "bbox": [
            -3.5726966102544977,
            42.17804452988592,
            -3.5726966102544977,
            42.17804452988592
          ]
        }
      ],
      "bbox": [
        -3.5726966102544977,
        42.17804452988592,
        -3.5726966102544977,
        42.17804452988592
      ]
    },
    "53006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.194933318407545,
              42.13022154743099
            ]
          },
          "bbox": [
            -3.194933318407545,
            42.13022154743099,
            -3.194933318407545,
            42.13022154743099
          ]
        }
      ],
      "bbox": [
        -3.194933318407545,
        42.13022154743099,
        -3.194933318407545,
        42.13022154743099
      ]
    },
    "53007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.307726892422846,
              41.99557204692384
            ]
          },
          "bbox": [
            -3.307726892422846,
            41.99557204692384,
            -3.307726892422846,
            41.99557204692384
          ]
        }
      ],
      "bbox": [
        -3.307726892422846,
        41.99557204692384,
        -3.307726892422846,
        41.99557204692384
      ]
    },
    "53008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3070990556555437,
              41.97426758132274
            ]
          },
          "bbox": [
            -3.3070990556555437,
            41.97426758132274,
            -3.3070990556555437,
            41.97426758132274
          ]
        }
      ],
      "bbox": [
        -3.3070990556555437,
        41.97426758132274,
        -3.3070990556555437,
        41.97426758132274
      ]
    },
    "53009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3717889461988078,
              42.017133612832446
            ]
          },
          "bbox": [
            -3.3717889461988078,
            42.017133612832446,
            -3.3717889461988078,
            42.017133612832446
          ]
        }
      ],
      "bbox": [
        -3.3717889461988078,
        42.017133612832446,
        -3.3717889461988078,
        42.017133612832446
      ]
    },
    "53010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.38387890204568,
              42.053559277964055
            ]
          },
          "bbox": [
            -3.38387890204568,
            42.053559277964055,
            -3.38387890204568,
            42.053559277964055
          ]
        }
      ],
      "bbox": [
        -3.38387890204568,
        42.053559277964055,
        -3.38387890204568,
        42.053559277964055
      ]
    },
    "53011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3270624601469545,
              42.05994335843085
            ]
          },
          "bbox": [
            -3.3270624601469545,
            42.05994335843085,
            -3.3270624601469545,
            42.05994335843085
          ]
        }
      ],
      "bbox": [
        -3.3270624601469545,
        42.05994335843085,
        -3.3270624601469545,
        42.05994335843085
      ]
    },
    "53012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3164120651094375,
              42.00791411650394
            ]
          },
          "bbox": [
            -3.3164120651094375,
            42.00791411650394,
            -3.3164120651094375,
            42.00791411650394
          ]
        }
      ],
      "bbox": [
        -3.3164120651094375,
        42.00791411650394,
        -3.3164120651094375,
        42.00791411650394
      ]
    },
    "53013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2645182475813677,
              42.15002439892491
            ]
          },
          "bbox": [
            -3.2645182475813677,
            42.15002439892491,
            -3.2645182475813677,
            42.15002439892491
          ]
        }
      ],
      "bbox": [
        -3.2645182475813677,
        42.15002439892491,
        -3.2645182475813677,
        42.15002439892491
      ]
    },
    "53014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.228557860032084,
              42.14895659811797
            ]
          },
          "bbox": [
            -3.228557860032084,
            42.14895659811797,
            -3.228557860032084,
            42.14895659811797
          ]
        }
      ],
      "bbox": [
        -3.228557860032084,
        42.14895659811797,
        -3.228557860032084,
        42.14895659811797
      ]
    },
    "53015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0978483266336525,
              42.4238762956916
            ]
          },
          "bbox": [
            -3.0978483266336525,
            42.4238762956916,
            -3.0978483266336525,
            42.4238762956916
          ]
        }
      ],
      "bbox": [
        -3.0978483266336525,
        42.4238762956916,
        -3.0978483266336525,
        42.4238762956916
      ]
    },
    "53016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0116221612142846,
              41.96253311917886
            ]
          },
          "bbox": [
            -3.0116221612142846,
            41.96253311917886,
            -3.0116221612142846,
            41.96253311917886
          ]
        }
      ],
      "bbox": [
        -3.0116221612142846,
        41.96253311917886,
        -3.0116221612142846,
        41.96253311917886
      ]
    },
    "53017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.05294469050161,
              41.956414470035874
            ]
          },
          "bbox": [
            -3.05294469050161,
            41.956414470035874,
            -3.05294469050161,
            41.956414470035874
          ]
        }
      ],
      "bbox": [
        -3.05294469050161,
        41.956414470035874,
        -3.05294469050161,
        41.956414470035874
      ]
    },
    "53018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0402589072742874,
              41.888296233002585
            ]
          },
          "bbox": [
            -3.0402589072742874,
            41.888296233002585,
            -3.0402589072742874,
            41.888296233002585
          ]
        }
      ],
      "bbox": [
        -3.0402589072742874,
        41.888296233002585,
        -3.0402589072742874,
        41.888296233002585
      ]
    },
    "53019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3524739067572473,
              41.984421031322164
            ]
          },
          "bbox": [
            -3.3524739067572473,
            41.984421031322164,
            -3.3524739067572473,
            41.984421031322164
          ]
        }
      ],
      "bbox": [
        -3.3524739067572473,
        41.984421031322164,
        -3.3524739067572473,
        41.984421031322164
      ]
    },
    "53020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.331194381669359,
              41.97245497818918
            ]
          },
          "bbox": [
            -3.331194381669359,
            41.97245497818918,
            -3.331194381669359,
            41.97245497818918
          ]
        }
      ],
      "bbox": [
        -3.331194381669359,
        41.97245497818918,
        -3.331194381669359,
        41.97245497818918
      ]
    },
    "53021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1672316062278822,
              42.04465836560083
            ]
          },
          "bbox": [
            -3.1672316062278822,
            42.04465836560083,
            -3.1672316062278822,
            42.04465836560083
          ]
        }
      ],
      "bbox": [
        -3.1672316062278822,
        42.04465836560083,
        -3.1672316062278822,
        42.04465836560083
      ]
    },
    "53022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2333213882811203,
              41.95710300733921
            ]
          },
          "bbox": [
            -3.2333213882811203,
            41.95710300733921,
            -3.2333213882811203,
            41.95710300733921
          ]
        }
      ],
      "bbox": [
        -3.2333213882811203,
        41.95710300733921,
        -3.2333213882811203,
        41.95710300733921
      ]
    },
    "53023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.675633927435365,
              41.89751893683159
            ]
          },
          "bbox": [
            -3.675633927435365,
            41.89751893683159,
            -3.675633927435365,
            41.89751893683159
          ]
        }
      ],
      "bbox": [
        -3.675633927435365,
        41.89751893683159,
        -3.675633927435365,
        41.89751893683159
      ]
    },
    "53024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5157866929041153,
              42.013244163341554
            ]
          },
          "bbox": [
            -3.5157866929041153,
            42.013244163341554,
            -3.5157866929041153,
            42.013244163341554
          ]
        }
      ],
      "bbox": [
        -3.5157866929041153,
        42.013244163341554,
        -3.5157866929041153,
        42.013244163341554
      ]
    },
    "53025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.611141814242665,
              42.1739086109387
            ]
          },
          "bbox": [
            -3.611141814242665,
            42.1739086109387,
            -3.611141814242665,
            42.1739086109387
          ]
        }
      ],
      "bbox": [
        -3.611141814242665,
        42.1739086109387,
        -3.611141814242665,
        42.1739086109387
      ]
    },
    "53026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.293790506365494,
              41.95431432992147
            ]
          },
          "bbox": [
            -3.293790506365494,
            41.95431432992147,
            -3.293790506365494,
            41.95431432992147
          ]
        }
      ],
      "bbox": [
        -3.293790506365494,
        41.95431432992147,
        -3.293790506365494,
        41.95431432992147
      ]
    },
    "53027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.280766868194227,
              41.96678797642312
            ]
          },
          "bbox": [
            -3.280766868194227,
            41.96678797642312,
            -3.280766868194227,
            41.96678797642312
          ]
        }
      ],
      "bbox": [
        -3.280766868194227,
        41.96678797642312,
        -3.280766868194227,
        41.96678797642312
      ]
    },
    "53028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.307606108272458,
              42.00725097079042
            ]
          },
          "bbox": [
            -3.307606108272458,
            42.00725097079042,
            -3.307606108272458,
            42.00725097079042
          ]
        }
      ],
      "bbox": [
        -3.307606108272458,
        42.00725097079042,
        -3.307606108272458,
        42.00725097079042
      ]
    },
    "53029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.440007245562976,
              42.080611643666465
            ]
          },
          "bbox": [
            -3.440007245562976,
            42.080611643666465,
            -3.440007245562976,
            42.080611643666465
          ]
        }
      ],
      "bbox": [
        -3.440007245562976,
        42.080611643666465,
        -3.440007245562976,
        42.080611643666465
      ]
    },
    "53031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1113611101755363,
              41.88952174447894
            ]
          },
          "bbox": [
            -3.1113611101755363,
            41.88952174447894,
            -3.1113611101755363,
            41.88952174447894
          ]
        }
      ],
      "bbox": [
        -3.1113611101755363,
        41.88952174447894,
        -3.1113611101755363,
        41.88952174447894
      ]
    },
    "53032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.086422729937013,
              41.88100022749619
            ]
          },
          "bbox": [
            -3.086422729937013,
            41.88100022749619,
            -3.086422729937013,
            41.88100022749619
          ]
        }
      ],
      "bbox": [
        -3.086422729937013,
        41.88100022749619,
        -3.086422729937013,
        41.88100022749619
      ]
    },
    "53033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.703043471325906,
              42.443500962777435
            ]
          },
          "bbox": [
            -3.703043471325906,
            42.443500962777435,
            -3.703043471325906,
            42.443500962777435
          ]
        }
      ],
      "bbox": [
        -3.703043471325906,
        42.443500962777435,
        -3.703043471325906,
        42.443500962777435
      ]
    },
    "53034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.064229084401522,
              41.98719739782439
            ]
          },
          "bbox": [
            -3.064229084401522,
            41.98719739782439,
            -3.064229084401522,
            41.98719739782439
          ]
        }
      ],
      "bbox": [
        -3.064229084401522,
        41.98719739782439,
        -3.064229084401522,
        41.98719739782439
      ]
    },
    "53035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.48934894783351,
              42.200657766691265
            ]
          },
          "bbox": [
            -3.48934894783351,
            42.200657766691265,
            -3.48934894783351,
            42.200657766691265
          ]
        }
      ],
      "bbox": [
        -3.48934894783351,
        42.200657766691265,
        -3.48934894783351,
        42.200657766691265
      ]
    },
    "53036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.206169881127783,
              42.02084369410106
            ]
          },
          "bbox": [
            -3.206169881127783,
            42.02084369410106,
            -3.206169881127783,
            42.02084369410106
          ]
        }
      ],
      "bbox": [
        -3.206169881127783,
        42.02084369410106,
        -3.206169881127783,
        42.02084369410106
      ]
    },
    "53037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7548663279890264,
              41.87866369448739
            ]
          },
          "bbox": [
            -3.7548663279890264,
            41.87866369448739,
            -3.7548663279890264,
            41.87866369448739
          ]
        }
      ],
      "bbox": [
        -3.7548663279890264,
        41.87866369448739,
        -3.7548663279890264,
        41.87866369448739
      ]
    },
    "53038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1590686413219426,
              42.37127148972471
            ]
          },
          "bbox": [
            -3.1590686413219426,
            42.37127148972471,
            -3.1590686413219426,
            42.37127148972471
          ]
        }
      ],
      "bbox": [
        -3.1590686413219426,
        42.37127148972471,
        -3.1590686413219426,
        42.37127148972471
      ]
    },
    "53039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.854696797868201,
              42.3509069439018
            ]
          },
          "bbox": [
            -3.854696797868201,
            42.3509069439018,
            -3.854696797868201,
            42.3509069439018
          ]
        }
      ],
      "bbox": [
        -3.854696797868201,
        42.3509069439018,
        -3.854696797868201,
        42.3509069439018
      ]
    },
    "53040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8279336474154797,
              42.33040507483618
            ]
          },
          "bbox": [
            -3.8279336474154797,
            42.33040507483618,
            -3.8279336474154797,
            42.33040507483618
          ]
        }
      ],
      "bbox": [
        -3.8279336474154797,
        42.33040507483618,
        -3.8279336474154797,
        42.33040507483618
      ]
    },
    "53041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3646649544271923,
              42.16169700056344
            ]
          },
          "bbox": [
            -3.3646649544271923,
            42.16169700056344,
            -3.3646649544271923,
            42.16169700056344
          ]
        }
      ],
      "bbox": [
        -3.3646649544271923,
        42.16169700056344,
        -3.3646649544271923,
        42.16169700056344
      ]
    },
    "53042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0923416717063237,
              41.89412746673252
            ]
          },
          "bbox": [
            -3.0923416717063237,
            41.89412746673252,
            -3.0923416717063237,
            41.89412746673252
          ]
        }
      ],
      "bbox": [
        -3.0923416717063237,
        41.89412746673252,
        -3.0923416717063237,
        41.89412746673252
      ]
    },
    "53043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.482435329092604,
              42.204210506698765
            ]
          },
          "bbox": [
            -3.482435329092604,
            42.204210506698765,
            -3.482435329092604,
            42.204210506698765
          ]
        }
      ],
      "bbox": [
        -3.482435329092604,
        42.204210506698765,
        -3.482435329092604,
        42.204210506698765
      ]
    },
    "53044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2901450662639085,
              42.11408441878563
            ]
          },
          "bbox": [
            -3.2901450662639085,
            42.11408441878563,
            -3.2901450662639085,
            42.11408441878563
          ]
        }
      ],
      "bbox": [
        -3.2901450662639085,
        42.11408441878563,
        -3.2901450662639085,
        42.11408441878563
      ]
    },
    "53045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.613447922329714,
              42.04835570511089
            ]
          },
          "bbox": [
            -3.613447922329714,
            42.04835570511089,
            -3.613447922329714,
            42.04835570511089
          ]
        }
      ],
      "bbox": [
        -3.613447922329714,
        42.04835570511089,
        -3.613447922329714,
        42.04835570511089
      ]
    },
    "53046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6971078778551614,
              42.42716134232368
            ]
          },
          "bbox": [
            -3.6971078778551614,
            42.42716134232368,
            -3.6971078778551614,
            42.42716134232368
          ]
        }
      ],
      "bbox": [
        -3.6971078778551614,
        42.42716134232368,
        -3.6971078778551614,
        42.42716134232368
      ]
    },
    "53047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8996406393828424,
              40.235776945467805
            ]
          },
          "bbox": [
            -2.8996406393828424,
            40.235776945467805,
            -2.8996406393828424,
            40.235776945467805
          ]
        }
      ],
      "bbox": [
        -2.8996406393828424,
        40.235776945467805,
        -2.8996406393828424,
        40.235776945467805
      ]
    },
    "53048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0746426597285113,
              42.99828226106661
            ]
          },
          "bbox": [
            -2.0746426597285113,
            42.99828226106661,
            -2.0746426597285113,
            42.99828226106661
          ]
        }
      ],
      "bbox": [
        -2.0746426597285113,
        42.99828226106661,
        -2.0746426597285113,
        42.99828226106661
      ]
    },
    "53049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.667603342466539,
              42.84567366753011
            ]
          },
          "bbox": [
            -0.667603342466539,
            42.84567366753011,
            -0.667603342466539,
            42.84567366753011
          ]
        }
      ],
      "bbox": [
        -0.667603342466539,
        42.84567366753011,
        -0.667603342466539,
        42.84567366753011
      ]
    },
    "53050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.755892725617031,
              38.51738254104258
            ]
          },
          "bbox": [
            -2.755892725617031,
            38.51738254104258,
            -2.755892725617031,
            38.51738254104258
          ]
        }
      ],
      "bbox": [
        -2.755892725617031,
        38.51738254104258,
        -2.755892725617031,
        38.51738254104258
      ]
    },
    "53051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.01517048298663,
              42.31927215747544
            ]
          },
          "bbox": [
            -6.01517048298663,
            42.31927215747544,
            -6.01517048298663,
            42.31927215747544
          ]
        }
      ],
      "bbox": [
        -6.01517048298663,
        42.31927215747544,
        -6.01517048298663,
        42.31927215747544
      ]
    },
    "53052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.107307662479055,
              42.548070615555
            ]
          },
          "bbox": [
            -5.107307662479055,
            42.548070615555,
            -5.107307662479055,
            42.548070615555
          ]
        }
      ],
      "bbox": [
        -5.107307662479055,
        42.548070615555,
        -5.107307662479055,
        42.548070615555
      ]
    },
    "53053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8717121957957845,
              42.35414286550495
            ]
          },
          "bbox": [
            -5.8717121957957845,
            42.35414286550495,
            -5.8717121957957845,
            42.35414286550495
          ]
        }
      ],
      "bbox": [
        -5.8717121957957845,
        42.35414286550495,
        -5.8717121957957845,
        42.35414286550495
      ]
    },
    "53054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.091669263255767,
              42.60991762225927
            ]
          },
          "bbox": [
            -6.091669263255767,
            42.60991762225927,
            -6.091669263255767,
            42.60991762225927
          ]
        }
      ],
      "bbox": [
        -6.091669263255767,
        42.60991762225927,
        -6.091669263255767,
        42.60991762225927
      ]
    },
    "53055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.642900455676985,
              42.347327026204596
            ]
          },
          "bbox": [
            1.642900455676985,
            42.347327026204596,
            1.642900455676985,
            42.347327026204596
          ]
        }
      ],
      "bbox": [
        1.642900455676985,
        42.347327026204596,
        1.642900455676985,
        42.347327026204596
      ]
    },
    "53056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.584050417758069,
              42.31777319547427
            ]
          },
          "bbox": [
            -2.584050417758069,
            42.31777319547427,
            -2.584050417758069,
            42.31777319547427
          ]
        }
      ],
      "bbox": [
        -2.584050417758069,
        42.31777319547427,
        -2.584050417758069,
        42.31777319547427
      ]
    },
    "53057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.013884475213411,
              40.766580670066524
            ]
          },
          "bbox": [
            -4.013884475213411,
            40.766580670066524,
            -4.013884475213411,
            40.766580670066524
          ]
        }
      ],
      "bbox": [
        -4.013884475213411,
        40.766580670066524,
        -4.013884475213411,
        40.766580670066524
      ]
    },
    "53058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9170531365751815,
              40.68197604640391
            ]
          },
          "bbox": [
            -3.9170531365751815,
            40.68197604640391,
            -3.9170531365751815,
            40.68197604640391
          ]
        }
      ],
      "bbox": [
        -3.9170531365751815,
        40.68197604640391,
        -3.9170531365751815,
        40.68197604640391
      ]
    },
    "53059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.631115911628451,
              42.981495298689474
            ]
          },
          "bbox": [
            -1.631115911628451,
            42.981495298689474,
            -1.631115911628451,
            42.981495298689474
          ]
        }
      ],
      "bbox": [
        -1.631115911628451,
        42.981495298689474,
        -1.631115911628451,
        42.981495298689474
      ]
    },
    "53060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0723384514242196,
              42.61378642874533
            ]
          },
          "bbox": [
            -2.0723384514242196,
            42.61378642874533,
            -2.0723384514242196,
            42.61378642874533
          ]
        }
      ],
      "bbox": [
        -2.0723384514242196,
        42.61378642874533,
        -2.0723384514242196,
        42.61378642874533
      ]
    },
    "53061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7307776417097338,
              42.981132139748084
            ]
          },
          "bbox": [
            -1.7307776417097338,
            42.981132139748084,
            -1.7307776417097338,
            42.981132139748084
          ]
        }
      ],
      "bbox": [
        -1.7307776417097338,
        42.981132139748084,
        -1.7307776417097338,
        42.981132139748084
      ]
    },
    "53062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.146882619490601,
              42.57571565495042
            ]
          },
          "bbox": [
            -2.146882619490601,
            42.57571565495042,
            -2.146882619490601,
            42.57571565495042
          ]
        }
      ],
      "bbox": [
        -2.146882619490601,
        42.57571565495042,
        -2.146882619490601,
        42.57571565495042
      ]
    },
    "53063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1863617682588528,
              42.79811517308731
            ]
          },
          "bbox": [
            -2.1863617682588528,
            42.79811517308731,
            -2.1863617682588528,
            42.79811517308731
          ]
        }
      ],
      "bbox": [
        -2.1863617682588528,
        42.79811517308731,
        -2.1863617682588528,
        42.79811517308731
      ]
    },
    "53064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6705133562986032,
              42.86279912122185
            ]
          },
          "bbox": [
            -1.6705133562986032,
            42.86279912122185,
            -1.6705133562986032,
            42.86279912122185
          ]
        }
      ],
      "bbox": [
        -1.6705133562986032,
        42.86279912122185,
        -1.6705133562986032,
        42.86279912122185
      ]
    },
    "53065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9360637005551036,
              42.939882064462886
            ]
          },
          "bbox": [
            -1.9360637005551036,
            42.939882064462886,
            -1.9360637005551036,
            42.939882064462886
          ]
        }
      ],
      "bbox": [
        -1.9360637005551036,
        42.939882064462886,
        -1.9360637005551036,
        42.939882064462886
      ]
    },
    "53066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0713128751288044,
              42.62579380743824
            ]
          },
          "bbox": [
            -2.0713128751288044,
            42.62579380743824,
            -2.0713128751288044,
            42.62579380743824
          ]
        }
      ],
      "bbox": [
        -2.0713128751288044,
        42.62579380743824,
        -2.0713128751288044,
        42.62579380743824
      ]
    },
    "53067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.1846019854352872,
              42.86564359055686
            ]
          },
          "bbox": [
            -1.1846019854352872,
            42.86564359055686,
            -1.1846019854352872,
            42.86564359055686
          ]
        }
      ],
      "bbox": [
        -1.1846019854352872,
        42.86564359055686,
        -1.1846019854352872,
        42.86564359055686
      ]
    },
    "53068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7356102962690088,
              42.88481390344052
            ]
          },
          "bbox": [
            -1.7356102962690088,
            42.88481390344052,
            -1.7356102962690088,
            42.88481390344052
          ]
        }
      ],
      "bbox": [
        -1.7356102962690088,
        42.88481390344052,
        -1.7356102962690088,
        42.88481390344052
      ]
    },
    "53069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4742452122721486,
              42.53747060900345
            ]
          },
          "bbox": [
            -1.4742452122721486,
            42.53747060900345,
            -1.4742452122721486,
            42.53747060900345
          ]
        }
      ],
      "bbox": [
        -1.4742452122721486,
        42.53747060900345,
        -1.4742452122721486,
        42.53747060900345
      ]
    },
    "53070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0927112231069316,
              42.619682499016385
            ]
          },
          "bbox": [
            -2.0927112231069316,
            42.619682499016385,
            -2.0927112231069316,
            42.619682499016385
          ]
        }
      ],
      "bbox": [
        -2.0927112231069316,
        42.619682499016385,
        -2.0927112231069316,
        42.619682499016385
      ]
    },
    "53071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.534533317401317,
              41.058142565057004
            ]
          },
          "bbox": [
            -5.534533317401317,
            41.058142565057004,
            -5.534533317401317,
            41.058142565057004
          ]
        }
      ],
      "bbox": [
        -5.534533317401317,
        41.058142565057004,
        -5.534533317401317,
        41.058142565057004
      ]
    },
    "53072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.295536897696404,
              43.093489975204385
            ]
          },
          "bbox": [
            -4.295536897696404,
            43.093489975204385,
            -4.295536897696404,
            43.093489975204385
          ]
        }
      ],
      "bbox": [
        -4.295536897696404,
        43.093489975204385,
        -4.295536897696404,
        43.093489975204385
      ]
    },
    "53073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8191644339423867,
              41.51186982373489
            ]
          },
          "bbox": [
            -3.8191644339423867,
            41.51186982373489,
            -3.8191644339423867,
            41.51186982373489
          ]
        }
      ],
      "bbox": [
        -3.8191644339423867,
        41.51186982373489,
        -3.8191644339423867,
        41.51186982373489
      ]
    },
    "53074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5026070218428393,
              41.27946428175944
            ]
          },
          "bbox": [
            -3.5026070218428393,
            41.27946428175944,
            -3.5026070218428393,
            41.27946428175944
          ]
        }
      ],
      "bbox": [
        -3.5026070218428393,
        41.27946428175944,
        -3.5026070218428393,
        41.27946428175944
      ]
    },
    "53075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.055625356394731,
              41.749074344491525
            ]
          },
          "bbox": [
            -3.055625356394731,
            41.749074344491525,
            -3.055625356394731,
            41.749074344491525
          ]
        }
      ],
      "bbox": [
        -3.055625356394731,
        41.749074344491525,
        -3.055625356394731,
        41.749074344491525
      ]
    },
    "53076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0197193941885851,
              41.12536651418052
            ]
          },
          "bbox": [
            -1.0197193941885851,
            41.12536651418052,
            -1.0197193941885851,
            41.12536651418052
          ]
        }
      ],
      "bbox": [
        -1.0197193941885851,
        41.12536651418052,
        -1.0197193941885851,
        41.12536651418052
      ]
    },
    "53077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9258959015409584,
              40.89999590892056
            ]
          },
          "bbox": [
            -0.9258959015409584,
            40.89999590892056,
            -0.9258959015409584,
            40.89999590892056
          ]
        }
      ],
      "bbox": [
        -0.9258959015409584,
        40.89999590892056,
        -0.9258959015409584,
        40.89999590892056
      ]
    },
    "53078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4644726303004458,
              42.178555105662106
            ]
          },
          "bbox": [
            -1.4644726303004458,
            42.178555105662106,
            -1.4644726303004458,
            42.178555105662106
          ]
        }
      ],
      "bbox": [
        -1.4644726303004458,
        42.178555105662106,
        -1.4644726303004458,
        42.178555105662106
      ]
    },
    "53080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0048839451101372,
              42.9744221525218
            ]
          },
          "bbox": [
            -2.0048839451101372,
            42.9744221525218,
            -2.0048839451101372,
            42.9744221525218
          ]
        }
      ],
      "bbox": [
        -2.0048839451101372,
        42.9744221525218,
        -2.0048839451101372,
        42.9744221525218
      ]
    },
    "53081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.178453530182705,
              42.72583591509478
            ]
          },
          "bbox": [
            -2.178453530182705,
            42.72583591509478,
            -2.178453530182705,
            42.72583591509478
          ]
        }
      ],
      "bbox": [
        -2.178453530182705,
        42.72583591509478,
        -2.178453530182705,
        42.72583591509478
      ]
    },
    "53083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.125802558460852,
              42.60674765902709
            ]
          },
          "bbox": [
            -2.125802558460852,
            42.60674765902709,
            -2.125802558460852,
            42.60674765902709
          ]
        }
      ],
      "bbox": [
        -2.125802558460852,
        42.60674765902709,
        -2.125802558460852,
        42.60674765902709
      ]
    },
    "54001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.418463909283893,
              35.913794821618616
            ]
          },
          "bbox": [
            -5.418463909283893,
            35.913794821618616,
            -5.418463909283893,
            35.913794821618616
          ]
        }
      ],
      "bbox": [
        -5.418463909283893,
        35.913794821618616,
        -5.418463909283893,
        35.913794821618616
      ]
    },
    "54002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.298956223693084,
              35.171843267826965
            ]
          },
          "bbox": [
            -4.298956223693084,
            35.171843267826965,
            -4.298956223693084,
            35.171843267826965
          ]
        }
      ],
      "bbox": [
        -4.298956223693084,
        35.171843267826965,
        -4.298956223693084,
        35.171843267826965
      ]
    },
    "54003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.433875196413381,
              35.18033774904926
            ]
          },
          "bbox": [
            -2.433875196413381,
            35.18033774904926,
            -2.433875196413381,
            35.18033774904926
          ]
        }
      ],
      "bbox": [
        -2.433875196413381,
        35.18033774904926,
        -2.433875196413381,
        35.18033774904926
      ]
    },
    "54004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9018097418602817,
              35.21634652508945
            ]
          },
          "bbox": [
            -3.9018097418602817,
            35.21634652508945,
            -3.9018097418602817,
            35.21634652508945
          ]
        }
      ],
      "bbox": [
        -3.9018097418602817,
        35.21634652508945,
        -3.9018097418602817,
        35.21634652508945
      ]
    },
    "54005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8892051760944297,
              35.21325229266041
            ]
          },
          "bbox": [
            -3.8892051760944297,
            35.21325229266041,
            -3.8892051760944297,
            35.21325229266041
          ]
        }
      ],
      "bbox": [
        -3.8892051760944297,
        35.21325229266041,
        -3.8892051760944297,
        35.21325229266041
      ]
    },
    "54006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.34878109061264,
              36.1353286305458
            ]
          },
          "bbox": [
            -5.34878109061264,
            36.1353286305458,
            -5.34878109061264,
            36.1353286305458
          ]
        }
      ],
      "bbox": [
        -5.34878109061264,
        36.1353286305458,
        -5.34878109061264,
        36.1353286305458
      ]
    },
    "01001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5110455294964256,
              42.82911821793369
            ]
          },
          "bbox": [
            -2.5110455294964256,
            42.82911821793369,
            -2.5110455294964256,
            42.82911821793369
          ]
        }
      ],
      "bbox": [
        -2.5110455294964256,
        42.82911821793369,
        -2.5110455294964256,
        42.82911821793369
      ]
    },
    "01002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.97211068280673,
              43.02554344523224
            ]
          },
          "bbox": [
            -2.97211068280673,
            43.02554344523224,
            -2.97211068280673,
            43.02554344523224
          ]
        }
      ],
      "bbox": [
        -2.97211068280673,
        43.02554344523224,
        -2.97211068280673,
        43.02554344523224
      ]
    },
    "01003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.593669319157799,
              43.04601212704505
            ]
          },
          "bbox": [
            -2.593669319157799,
            43.04601212704505,
            -2.593669319157799,
            43.04601212704505
          ]
        }
      ],
      "bbox": [
        -2.593669319157799,
        43.04601212704505,
        -2.593669319157799,
        43.04601212704505
      ]
    },
    "01004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1413397243929824,
              43.12973950345351
            ]
          },
          "bbox": [
            -3.1413397243929824,
            43.12973950345351,
            -3.1413397243929824,
            43.12973950345351
          ]
        }
      ],
      "bbox": [
        -3.1413397243929824,
        43.12973950345351,
        -3.1413397243929824,
        43.12973950345351
      ]
    },
    "01006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8734062136785066,
              42.713954780485395
            ]
          },
          "bbox": [
            -2.8734062136785066,
            42.713954780485395,
            -2.8734062136785066,
            42.713954780485395
          ]
        }
      ],
      "bbox": [
        -2.8734062136785066,
        42.713954780485395,
        -2.8734062136785066,
        42.713954780485395
      ]
    },
    "01008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6142106460929604,
              42.92415200645097
            ]
          },
          "bbox": [
            -2.6142106460929604,
            42.92415200645097,
            -2.6142106460929604,
            42.92415200645097
          ]
        }
      ],
      "bbox": [
        -2.6142106460929604,
        42.92415200645097,
        -2.6142106460929604,
        42.92415200645097
      ]
    },
    "01009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.316209613504652,
              42.89413313167997
            ]
          },
          "bbox": [
            -2.316209613504652,
            42.89413313167997,
            -2.316209613504652,
            42.89413313167997
          ]
        }
      ],
      "bbox": [
        -2.316209613504652,
        42.89413313167997,
        -2.316209613504652,
        42.89413313167997
      ]
    },
    "01010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.078270080330568,
              43.075435044953565
            ]
          },
          "bbox": [
            -3.078270080330568,
            43.075435044953565,
            -3.078270080330568,
            43.075435044953565
          ]
        }
      ],
      "bbox": [
        -3.078270080330568,
        43.075435044953565,
        -3.078270080330568,
        43.075435044953565
      ]
    },
    "01011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6747961258838107,
              42.522947036369835
            ]
          },
          "bbox": [
            -2.6747961258838107,
            42.522947036369835,
            -2.6747961258838107,
            42.522947036369835
          ]
        }
      ],
      "bbox": [
        -2.6747961258838107,
        42.522947036369835,
        -2.6747961258838107,
        42.522947036369835
      ]
    },
    "01013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4928193141813364,
              42.91166121696889
            ]
          },
          "bbox": [
            -2.4928193141813364,
            42.91166121696889,
            -2.4928193141813364,
            42.91166121696889
          ]
        }
      ],
      "bbox": [
        -2.4928193141813364,
        42.91166121696889,
        -2.4928193141813364,
        42.91166121696889
      ]
    },
    "01014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.833189283478987,
              42.68692583997022
            ]
          },
          "bbox": [
            -2.833189283478987,
            42.68692583997022,
            -2.833189283478987,
            42.68692583997022
          ]
        }
      ],
      "bbox": [
        -2.833189283478987,
        42.68692583997022,
        -2.833189283478987,
        42.68692583997022
      ]
    },
    "01016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5236580631522294,
              42.69411068876675
            ]
          },
          "bbox": [
            -2.5236580631522294,
            42.69411068876675,
            -2.5236580631522294,
            42.69411068876675
          ]
        }
      ],
      "bbox": [
        -2.5236580631522294,
        42.69411068876675,
        -2.5236580631522294,
        42.69411068876675
      ]
    },
    "01017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3608660505654537,
              42.68672685989698
            ]
          },
          "bbox": [
            -2.3608660505654537,
            42.68672685989698,
            -2.3608660505654537,
            42.68672685989698
          ]
        }
      ],
      "bbox": [
        -2.3608660505654537,
        42.68672685989698,
        -2.3608660505654537,
        42.68672685989698
      ]
    },
    "01018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7307216073288973,
              42.96875961358331
            ]
          },
          "bbox": [
            -2.7307216073288973,
            42.96875961358331,
            -2.7307216073288973,
            42.96875961358331
          ]
        }
      ],
      "bbox": [
        -2.7307216073288973,
        42.96875961358331,
        -2.7307216073288973,
        42.96875961358331
      ]
    },
    "01019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5143898815665255,
              42.59516569484547
            ]
          },
          "bbox": [
            -2.5143898815665255,
            42.59516569484547,
            -2.5143898815665255,
            42.59516569484547
          ]
        }
      ],
      "bbox": [
        -2.5143898815665255,
        42.59516569484547,
        -2.5143898815665255,
        42.59516569484547
      ]
    },
    "01020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9357026525100776,
              42.89573606537058
            ]
          },
          "bbox": [
            -2.9357026525100776,
            42.89573606537058,
            -2.9357026525100776,
            42.89573606537058
          ]
        }
      ],
      "bbox": [
        -2.9357026525100776,
        42.89573606537058,
        -2.9357026525100776,
        42.89573606537058
      ]
    },
    "01021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5471720747988704,
              42.865122294022235
            ]
          },
          "bbox": [
            -2.5471720747988704,
            42.865122294022235,
            -2.5471720747988704,
            42.865122294022235
          ]
        }
      ],
      "bbox": [
        -2.5471720747988704,
        42.865122294022235,
        -2.5471720747988704,
        42.865122294022235
      ]
    },
    "01022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6247883973606623,
              42.50739439068037
            ]
          },
          "bbox": [
            -2.6247883973606623,
            42.50739439068037,
            -2.6247883973606623,
            42.50739439068037
          ]
        }
      ],
      "bbox": [
        -2.6247883973606623,
        42.50739439068037,
        -2.6247883973606623,
        42.50739439068037
      ]
    },
    "01023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5405530866400916,
              42.56209366333617
            ]
          },
          "bbox": [
            -2.5405530866400916,
            42.56209366333617,
            -2.5405530866400916,
            42.56209366333617
          ]
        }
      ],
      "bbox": [
        -2.5405530866400916,
        42.56209366333617,
        -2.5405530866400916,
        42.56209366333617
      ]
    },
    "01027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4762842766205693,
              42.8229730812936
            ]
          },
          "bbox": [
            -2.4762842766205693,
            42.8229730812936,
            -2.4762842766205693,
            42.8229730812936
          ]
        }
      ],
      "bbox": [
        -2.4762842766205693,
        42.8229730812936,
        -2.4762842766205693,
        42.8229730812936
      ]
    },
    "01028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8087927621508384,
              42.60564308045031
            ]
          },
          "bbox": [
            -2.8087927621508384,
            42.60564308045031,
            -2.8087927621508384,
            42.60564308045031
          ]
        }
      ],
      "bbox": [
        -2.8087927621508384,
        42.60564308045031,
        -2.8087927621508384,
        42.60564308045031
      ]
    },
    "01030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6083489676266116,
              42.62126182457295
            ]
          },
          "bbox": [
            -2.6083489676266116,
            42.62126182457295,
            -2.6083489676266116,
            42.62126182457295
          ]
        }
      ],
      "bbox": [
        -2.6083489676266116,
        42.62126182457295,
        -2.6083489676266116,
        42.62126182457295
      ]
    },
    "01031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.573906783799066,
              42.54882945882492
            ]
          },
          "bbox": [
            -2.573906783799066,
            42.54882945882492,
            -2.573906783799066,
            42.54882945882492
          ]
        }
      ],
      "bbox": [
        -2.573906783799066,
        42.54882945882492,
        -2.573906783799066,
        42.54882945882492
      ]
    },
    "01032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.499794808294262,
              42.54406308760154
            ]
          },
          "bbox": [
            -2.499794808294262,
            42.54406308760154,
            -2.499794808294262,
            42.54406308760154
          ]
        }
      ],
      "bbox": [
        -2.499794808294262,
        42.54406308760154,
        -2.499794808294262,
        42.54406308760154
      ]
    },
    "01033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5701765788191473,
              42.49453621228098
            ]
          },
          "bbox": [
            -2.5701765788191473,
            42.49453621228098,
            -2.5701765788191473,
            42.49453621228098
          ]
        }
      ],
      "bbox": [
        -2.5701765788191473,
        42.49453621228098,
        -2.5701765788191473,
        42.49453621228098
      ]
    },
    "01034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6374900314813585,
              42.57122394264313
            ]
          },
          "bbox": [
            -2.6374900314813585,
            42.57122394264313,
            -2.6374900314813585,
            42.57122394264313
          ]
        }
      ],
      "bbox": [
        -2.6374900314813585,
        42.57122394264313,
        -2.6374900314813585,
        42.57122394264313
      ]
    },
    "01036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.977644533004451,
              43.13847439131359
            ]
          },
          "bbox": [
            -2.977644533004451,
            43.13847439131359,
            -2.977644533004451,
            43.13847439131359
          ]
        }
      ],
      "bbox": [
        -2.977644533004451,
        43.13847439131359,
        -2.977644533004451,
        43.13847439131359
      ]
    },
    "01037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.445250050829085,
              42.74913571991017
            ]
          },
          "bbox": [
            -2.445250050829085,
            42.74913571991017,
            -2.445250050829085,
            42.74913571991017
          ]
        }
      ],
      "bbox": [
        -2.445250050829085,
        42.74913571991017,
        -2.445250050829085,
        42.74913571991017
      ]
    },
    "01039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.415017483944554,
              42.52749094232421
            ]
          },
          "bbox": [
            -2.415017483944554,
            42.52749094232421,
            -2.415017483944554,
            42.52749094232421
          ]
        }
      ],
      "bbox": [
        -2.415017483944554,
        42.52749094232421,
        -2.415017483944554,
        42.52749094232421
      ]
    },
    "01041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6314869610499847,
              42.53974145652634
            ]
          },
          "bbox": [
            -2.6314869610499847,
            42.53974145652634,
            -2.6314869610499847,
            42.53974145652634
          ]
        }
      ],
      "bbox": [
        -2.6314869610499847,
        42.53974145652634,
        -2.6314869610499847,
        42.53974145652634
      ]
    },
    "01042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0180318807336968,
              43.1683689624977
            ]
          },
          "bbox": [
            -3.0180318807336968,
            43.1683689624977,
            -3.0180318807336968,
            43.1683689624977
          ]
        }
      ],
      "bbox": [
        -3.0180318807336968,
        43.1683689624977,
        -3.0180318807336968,
        43.1683689624977
      ]
    },
    "01043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4327689486984854,
              42.54453580809522
            ]
          },
          "bbox": [
            -2.4327689486984854,
            42.54453580809522,
            -2.4327689486984854,
            42.54453580809522
          ]
        }
      ],
      "bbox": [
        -2.4327689486984854,
        42.54453580809522,
        -2.4327689486984854,
        42.54453580809522
      ]
    },
    "01044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7005209574308684,
              42.63835790753795
            ]
          },
          "bbox": [
            -2.7005209574308684,
            42.63835790753795,
            -2.7005209574308684,
            42.63835790753795
          ]
        }
      ],
      "bbox": [
        -2.7005209574308684,
        42.63835790753795,
        -2.7005209574308684,
        42.63835790753795
      ]
    },
    "01046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.925170591525422,
              42.80254689960632
            ]
          },
          "bbox": [
            -2.925170591525422,
            42.80254689960632,
            -2.925170591525422,
            42.80254689960632
          ]
        }
      ],
      "bbox": [
        -2.925170591525422,
        42.80254689960632,
        -2.925170591525422,
        42.80254689960632
      ]
    },
    "01047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.902816995026436,
              42.724716758646174
            ]
          },
          "bbox": [
            -2.902816995026436,
            42.724716758646174,
            -2.902816995026436,
            42.724716758646174
          ]
        }
      ],
      "bbox": [
        -2.902816995026436,
        42.724716758646174,
        -2.902816995026436,
        42.724716758646174
      ]
    },
    "01049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.991659381832774,
              42.813690309497154
            ]
          },
          "bbox": [
            -2.991659381832774,
            42.813690309497154,
            -2.991659381832774,
            42.813690309497154
          ]
        }
      ],
      "bbox": [
        -2.991659381832774,
        42.813690309497154,
        -2.991659381832774,
        42.813690309497154
      ]
    },
    "01051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.387046826358489,
              42.83854492231566
            ]
          },
          "bbox": [
            -2.387046826358489,
            42.83854492231566,
            -2.387046826358489,
            42.83854492231566
          ]
        }
      ],
      "bbox": [
        -2.387046826358489,
        42.83854492231566,
        -2.387046826358489,
        42.83854492231566
      ]
    },
    "01052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6733523419079797,
              42.57053715105272
            ]
          },
          "bbox": [
            -2.6733523419079797,
            42.57053715105272,
            -2.6733523419079797,
            42.57053715105272
          ]
        }
      ],
      "bbox": [
        -2.6733523419079797,
        42.57053715105272,
        -2.6733523419079797,
        42.57053715105272
      ]
    },
    "01053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.393004507784214,
              42.87892933559668
            ]
          },
          "bbox": [
            -2.393004507784214,
            42.87892933559668,
            -2.393004507784214,
            42.87892933559668
          ]
        }
      ],
      "bbox": [
        -2.393004507784214,
        42.87892933559668,
        -2.393004507784214,
        42.87892933559668
      ]
    },
    "01054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9209564104911045,
              42.9663106428757
            ]
          },
          "bbox": [
            -2.9209564104911045,
            42.9663106428757,
            -2.9209564104911045,
            42.9663106428757
          ]
        }
      ],
      "bbox": [
        -2.9209564104911045,
        42.9663106428757,
        -2.9209564104911045,
        42.9663106428757
      ]
    },
    "01055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.128381273532797,
              42.86405729861835
            ]
          },
          "bbox": [
            -3.128381273532797,
            42.86405729861835,
            -3.128381273532797,
            42.86405729861835
          ]
        }
      ],
      "bbox": [
        -3.128381273532797,
        42.86405729861835,
        -3.128381273532797,
        42.86405729861835
      ]
    },
    "01056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.318543103669499,
              42.7572420696049
            ]
          },
          "bbox": [
            -2.318543103669499,
            42.7572420696049,
            -2.318543103669499,
            42.7572420696049
          ]
        }
      ],
      "bbox": [
        -2.318543103669499,
        42.7572420696049,
        -2.318543103669499,
        42.7572420696049
      ]
    },
    "01057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.661374100409237,
              42.541400314057114
            ]
          },
          "bbox": [
            -2.661374100409237,
            42.541400314057114,
            -2.661374100409237,
            42.541400314057114
          ]
        }
      ],
      "bbox": [
        -2.661374100409237,
        42.541400314057114,
        -2.661374100409237,
        42.541400314057114
      ]
    },
    "01058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6477933270015193,
              42.98280389789377
            ]
          },
          "bbox": [
            -2.6477933270015193,
            42.98280389789377,
            -2.6477933270015193,
            42.98280389789377
          ]
        }
      ],
      "bbox": [
        -2.6477933270015193,
        42.98280389789377,
        -2.6477933270015193,
        42.98280389789377
      ]
    },
    "01059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6879814210681188,
              42.85030769790425
            ]
          },
          "bbox": [
            -2.6879814210681188,
            42.85030769790425,
            -2.6879814210681188,
            42.85030769790425
          ]
        }
      ],
      "bbox": [
        -2.6879814210681188,
        42.85030769790425,
        -2.6879814210681188,
        42.85030769790425
      ]
    },
    "01060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.468524994581271,
              42.555303856856575
            ]
          },
          "bbox": [
            -2.468524994581271,
            42.555303856856575,
            -2.468524994581271,
            42.555303856856575
          ]
        }
      ],
      "bbox": [
        -2.468524994581271,
        42.555303856856575,
        -2.468524994581271,
        42.555303856856575
      ]
    },
    "01061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3438106913862664,
              42.900373092883775
            ]
          },
          "bbox": [
            -2.3438106913862664,
            42.900373092883775,
            -2.3438106913862664,
            42.900373092883775
          ]
        }
      ],
      "bbox": [
        -2.3438106913862664,
        42.900373092883775,
        -2.3438106913862664,
        42.900373092883775
      ]
    },
    "01062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8225250637185337,
              42.65743808851441
            ]
          },
          "bbox": [
            -2.8225250637185337,
            42.65743808851441,
            -2.8225250637185337,
            42.65743808851441
          ]
        }
      ],
      "bbox": [
        -2.8225250637185337,
        42.65743808851441,
        -2.8225250637185337,
        42.65743808851441
      ]
    },
    "01063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.835882158317819,
              42.984358602608665
            ]
          },
          "bbox": [
            -2.835882158317819,
            42.984358602608665,
            -2.835882158317819,
            42.984358602608665
          ]
        }
      ],
      "bbox": [
        -2.835882158317819,
        42.984358602608665,
        -2.835882158317819,
        42.984358602608665
      ]
    },
    "01901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.819572160797181,
              42.826318662491865
            ]
          },
          "bbox": [
            -2.819572160797181,
            42.826318662491865,
            -2.819572160797181,
            42.826318662491865
          ]
        }
      ],
      "bbox": [
        -2.819572160797181,
        42.826318662491865,
        -2.819572160797181,
        42.826318662491865
      ]
    },
    "01902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.013809822424181,
              42.7554392255756
            ]
          },
          "bbox": [
            -3.013809822424181,
            42.7554392255756,
            -3.013809822424181,
            42.7554392255756
          ]
        }
      ],
      "bbox": [
        -3.013809822424181,
        42.7554392255756,
        -3.013809822424181,
        42.7554392255756
      ]
    },
    "02001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.53903717034121,
              39.214402394407
            ]
          },
          "bbox": [
            -1.53903717034121,
            39.214402394407,
            -1.53903717034121,
            39.214402394407
          ]
        }
      ],
      "bbox": [
        -1.53903717034121,
        39.214402394407,
        -1.53903717034121,
        39.214402394407
      ]
    },
    "02002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3704498835802006,
              39.079876410380066
            ]
          },
          "bbox": [
            -1.3704498835802006,
            39.079876410380066,
            -1.3704498835802006,
            39.079876410380066
          ]
        }
      ],
      "bbox": [
        -1.3704498835802006,
        39.079876410380066,
        -1.3704498835802006,
        39.079876410380066
      ]
    },
    "02003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8917769737158703,
              38.9560503344524
            ]
          },
          "bbox": [
            -1.8917769737158703,
            38.9560503344524,
            -1.8917769737158703,
            38.9560503344524
          ]
        }
      ],
      "bbox": [
        -1.8917769737158703,
        38.9560503344524,
        -1.8917769737158703,
        38.9560503344524
      ]
    },
    "02004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5157772949454,
              38.576307295427235
            ]
          },
          "bbox": [
            -1.5157772949454,
            38.576307295427235,
            -1.5157772949454,
            38.576307295427235
          ]
        }
      ],
      "bbox": [
        -1.5157772949454,
        38.576307295427235,
        -1.5157772949454,
        38.576307295427235
      ]
    },
    "02005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3859488894195524,
              39.29167927451015
            ]
          },
          "bbox": [
            -1.3859488894195524,
            39.29167927451015,
            -1.3859488894195524,
            39.29167927451015
          ]
        }
      ],
      "bbox": [
        -1.3859488894195524,
        39.29167927451015,
        -1.3859488894195524,
        39.29167927451015
      ]
    },
    "02006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.05659224735004,
              38.662473225702655
            ]
          },
          "bbox": [
            -2.05659224735004,
            38.662473225702655,
            -2.05659224735004,
            38.662473225702655
          ]
        }
      ],
      "bbox": [
        -2.05659224735004,
        38.662473225702655,
        -2.05659224735004,
        38.662473225702655
      ]
    },
    "02007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4029530484394555,
              39.18574939593284
            ]
          },
          "bbox": [
            -1.4029530484394555,
            39.18574939593284,
            -1.4029530484394555,
            39.18574939593284
          ]
        }
      ],
      "bbox": [
        -1.4029530484394555,
        39.18574939593284,
        -1.4029530484394555,
        39.18574939593284
      ]
    },
    "02008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5304479733962437,
              38.71908366327485
            ]
          },
          "bbox": [
            -2.5304479733962437,
            38.71908366327485,
            -2.5304479733962437,
            38.71908366327485
          ]
        }
      ],
      "bbox": [
        -2.5304479733962437,
        38.71908366327485,
        -2.5304479733962437,
        38.71908366327485
      ]
    },
    "02009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.090205541047518,
              38.84094344396653
            ]
          },
          "bbox": [
            -1.090205541047518,
            38.84094344396653,
            -1.090205541047518,
            38.84094344396653
          ]
        }
      ],
      "bbox": [
        -1.090205541047518,
        38.84094344396653,
        -1.090205541047518,
        38.84094344396653
      ]
    },
    "02010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2736032979888383,
              38.98872776560717
            ]
          },
          "bbox": [
            -1.2736032979888383,
            38.98872776560717,
            -1.2736032979888383,
            38.98872776560717
          ]
        }
      ],
      "bbox": [
        -1.2736032979888383,
        38.98872776560717,
        -1.2736032979888383,
        38.98872776560717
      ]
    },
    "02011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.104915653261331,
              38.565073198845624
            ]
          },
          "bbox": [
            -2.104915653261331,
            38.565073198845624,
            -2.104915653261331,
            38.565073198845624
          ]
        }
      ],
      "bbox": [
        -2.104915653261331,
        38.565073198845624,
        -2.104915653261331,
        38.565073198845624
      ]
    },
    "02012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1375184840111308,
              38.88696547833963
            ]
          },
          "bbox": [
            -2.1375184840111308,
            38.88696547833963,
            -2.1375184840111308,
            38.88696547833963
          ]
        }
      ],
      "bbox": [
        -2.1375184840111308,
        38.88696547833963,
        -2.1375184840111308,
        38.88696547833963
      ]
    },
    "02013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2100321058884704,
              39.262355240145595
            ]
          },
          "bbox": [
            -1.2100321058884704,
            39.262355240145595,
            -1.2100321058884704,
            39.262355240145595
          ]
        }
      ],
      "bbox": [
        -1.2100321058884704,
        39.262355240145595,
        -1.2100321058884704,
        39.262355240145595
      ]
    },
    "02014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4758727725957224,
              38.84432682849756
            ]
          },
          "bbox": [
            -2.4758727725957224,
            38.84432682849756,
            -2.4758727725957224,
            38.84432682849756
          ]
        }
      ],
      "bbox": [
        -2.4758727725957224,
        38.84432682849756,
        -2.4758727725957224,
        38.84432682849756
      ]
    },
    "02015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2049460489260047,
              39.03310796399009
            ]
          },
          "bbox": [
            -2.2049460489260047,
            39.03310796399009,
            -2.2049460489260047,
            39.03310796399009
          ]
        }
      ],
      "bbox": [
        -2.2049460489260047,
        39.03310796399009,
        -2.2049460489260047,
        39.03310796399009
      ]
    },
    "02016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.648301158328426,
              38.530159853239006
            ]
          },
          "bbox": [
            -2.648301158328426,
            38.530159853239006,
            -2.648301158328426,
            38.530159853239006
          ]
        }
      ],
      "bbox": [
        -2.648301158328426,
        38.530159853239006,
        -2.648301158328426,
        38.530159853239006
      ]
    },
    "02017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2345522716447856,
              38.58343008681061
            ]
          },
          "bbox": [
            -2.2345522716447856,
            38.58343008681061,
            -2.2345522716447856,
            38.58343008681061
          ]
        }
      ],
      "bbox": [
        -2.2345522716447856,
        38.58343008681061,
        -2.2345522716447856,
        38.58343008681061
      ]
    },
    "02018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3083816882351904,
              38.88810575258752
            ]
          },
          "bbox": [
            -1.3083816882351904,
            38.88810575258752,
            -1.3083816882351904,
            38.88810575258752
          ]
        }
      ],
      "bbox": [
        -1.3083816882351904,
        38.88810575258752,
        -1.3083816882351904,
        38.88810575258752
      ]
    },
    "02019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.583444969884647,
              38.94029593219744
            ]
          },
          "bbox": [
            -2.583444969884647,
            38.94029593219744,
            -2.583444969884647,
            38.94029593219744
          ]
        }
      ],
      "bbox": [
        -2.583444969884647,
        38.94029593219744,
        -2.583444969884647,
        38.94029593219744
      ]
    },
    "02020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.288333139599874,
              39.106087049188794
            ]
          },
          "bbox": [
            -1.288333139599874,
            39.106087049188794,
            -1.288333139599874,
            39.106087049188794
          ]
        }
      ],
      "bbox": [
        -1.288333139599874,
        39.106087049188794,
        -1.288333139599874,
        39.106087049188794
      ]
    },
    "02021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5749454904374864,
              39.09732404972847
            ]
          },
          "bbox": [
            -1.5749454904374864,
            39.09732404972847,
            -1.5749454904374864,
            39.09732404972847
          ]
        }
      ],
      "bbox": [
        -1.5749454904374864,
        39.09732404972847,
        -1.5749454904374864,
        39.09732404972847
      ]
    },
    "02022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2291209702966674,
              38.755835136323675
            ]
          },
          "bbox": [
            -2.2291209702966674,
            38.755835136323675,
            -2.2291209702966674,
            38.755835136323675
          ]
        }
      ],
      "bbox": [
        -2.2291209702966674,
        38.755835136323675,
        -2.2291209702966674,
        38.755835136323675
      ]
    },
    "02023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3009343190429916,
              39.24927194304081
            ]
          },
          "bbox": [
            -1.3009343190429916,
            39.24927194304081,
            -1.3009343190429916,
            39.24927194304081
          ]
        }
      ],
      "bbox": [
        -1.3009343190429916,
        39.24927194304081,
        -1.3009343190429916,
        39.24927194304081
      ]
    },
    "02024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.458794943477415,
              39.312025157577
            ]
          },
          "bbox": [
            -1.458794943477415,
            39.312025157577,
            -1.458794943477415,
            39.312025157577
          ]
        }
      ],
      "bbox": [
        -1.458794943477415,
        39.312025157577,
        -1.458794943477415,
        39.312025157577
      ]
    },
    "02025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0100491352617302,
              38.70806481995141
            ]
          },
          "bbox": [
            -1.0100491352617302,
            38.70806481995141,
            -1.0100491352617302,
            38.70806481995141
          ]
        }
      ],
      "bbox": [
        -1.0100491352617302,
        38.70806481995141,
        -1.0100491352617302,
        38.70806481995141
      ]
    },
    "02026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6604922454215183,
              39.29814149981677
            ]
          },
          "bbox": [
            -1.6604922454215183,
            39.29814149981677,
            -1.6604922454215183,
            39.29814149981677
          ]
        }
      ],
      "bbox": [
        -1.6604922454215183,
        39.29814149981677,
        -1.6604922454215183,
        39.29814149981677
      ]
    },
    "02027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4405500639438038,
              38.81118579558974
            ]
          },
          "bbox": [
            -1.4405500639438038,
            38.81118579558974,
            -1.4405500639438038,
            38.81118579558974
          ]
        }
      ],
      "bbox": [
        -1.4405500639438038,
        38.81118579558974,
        -1.4405500639438038,
        38.81118579558974
      ]
    },
    "02028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5014165698151944,
              38.42295933596287
            ]
          },
          "bbox": [
            -2.5014165698151944,
            38.42295933596287,
            -2.5014165698151944,
            38.42295933596287
          ]
        }
      ],
      "bbox": [
        -2.5014165698151944,
        38.42295933596287,
        -2.5014165698151944,
        38.42295933596287
      ]
    },
    "02029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6361028953777093,
              38.87584683211011
            ]
          },
          "bbox": [
            -1.6361028953777093,
            38.87584683211011,
            -1.6361028953777093,
            38.87584683211011
          ]
        }
      ],
      "bbox": [
        -1.6361028953777093,
        38.87584683211011,
        -1.6361028953777093,
        38.87584683211011
      ]
    },
    "02030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.095784158072023,
              38.45460624050934
            ]
          },
          "bbox": [
            -2.095784158072023,
            38.45460624050934,
            -2.095784158072023,
            38.45460624050934
          ]
        }
      ],
      "bbox": [
        -2.095784158072023,
        38.45460624050934,
        -2.095784158072023,
        38.45460624050934
      ]
    },
    "02031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.946152396898516,
              38.386621215989386
            ]
          },
          "bbox": [
            -1.946152396898516,
            38.386621215989386,
            -1.946152396898516,
            38.386621215989386
          ]
        }
      ],
      "bbox": [
        -1.946152396898516,
        38.386621215989386,
        -1.946152396898516,
        38.386621215989386
      ]
    },
    "02032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.06850221212871,
              39.240512191454506
            ]
          },
          "bbox": [
            -2.06850221212871,
            39.240512191454506,
            -2.06850221212871,
            39.240512191454506
          ]
        }
      ],
      "bbox": [
        -2.06850221212871,
        39.240512191454506,
        -2.06850221212871,
        39.240512191454506
      ]
    },
    "02033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4580997293799938,
              38.7084657235254
            ]
          },
          "bbox": [
            -1.4580997293799938,
            38.7084657235254,
            -1.4580997293799938,
            38.7084657235254
          ]
        }
      ],
      "bbox": [
        -1.4580997293799938,
        38.7084657235254,
        -1.4580997293799938,
        38.7084657235254
      ]
    },
    "02034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5680111911262138,
              39.24972972724442
            ]
          },
          "bbox": [
            -1.5680111911262138,
            39.24972972724442,
            -1.5680111911262138,
            39.24972972724442
          ]
        }
      ],
      "bbox": [
        -1.5680111911262138,
        39.24972972724442,
        -1.5680111911262138,
        39.24972972724442
      ]
    },
    "02035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.997801128432156,
              39.111257337668
            ]
          },
          "bbox": [
            -1.997801128432156,
            39.111257337668,
            -1.997801128432156,
            39.111257337668
          ]
        }
      ],
      "bbox": [
        -1.997801128432156,
        39.111257337668,
        -1.997801128432156,
        39.111257337668
      ]
    },
    "02036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.636058047802283,
              39.23641157292499
            ]
          },
          "bbox": [
            -1.636058047802283,
            39.23641157292499,
            -1.636058047802283,
            39.23641157292499
          ]
        }
      ],
      "bbox": [
        -1.636058047802283,
        39.23641157292499,
        -1.636058047802283,
        39.23641157292499
      ]
    },
    "02037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.672994862898312,
              38.4619651511237
            ]
          },
          "bbox": [
            -1.672994862898312,
            38.4619651511237,
            -1.672994862898312,
            38.4619651511237
          ]
        }
      ],
      "bbox": [
        -1.672994862898312,
        38.4619651511237,
        -1.672994862898312,
        38.4619651511237
      ]
    },
    "02038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.103068519763122,
              38.944747488810584
            ]
          },
          "bbox": [
            -2.103068519763122,
            38.944747488810584,
            -2.103068519763122,
            38.944747488810584
          ]
        }
      ],
      "bbox": [
        -2.103068519763122,
        38.944747488810584,
        -2.103068519763122,
        38.944747488810584
      ]
    },
    "02039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4291059006655922,
              38.96647050265553
            ]
          },
          "bbox": [
            -1.4291059006655922,
            38.96647050265553,
            -1.4291059006655922,
            38.96647050265553
          ]
        }
      ],
      "bbox": [
        -1.4291059006655922,
        38.96647050265553,
        -1.4291059006655922,
        38.96647050265553
      ]
    },
    "02040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.562590898025883,
              38.960894150260835
            ]
          },
          "bbox": [
            -1.562590898025883,
            38.960894150260835,
            -1.562590898025883,
            38.960894150260835
          ]
        }
      ],
      "bbox": [
        -1.562590898025883,
        38.960894150260835,
        -1.562590898025883,
        38.960894150260835
      ]
    },
    "02041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5456455733347918,
              39.160258384800024
            ]
          },
          "bbox": [
            -1.5456455733347918,
            39.160258384800024,
            -1.5456455733347918,
            39.160258384800024
          ]
        }
      ],
      "bbox": [
        -1.5456455733347918,
        39.160258384800024,
        -1.5456455733347918,
        39.160258384800024
      ]
    },
    "02042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.130858297466183,
              38.31924585875835
            ]
          },
          "bbox": [
            -2.130858297466183,
            38.31924585875835,
            -2.130858297466183,
            38.31924585875835
          ]
        }
      ],
      "bbox": [
        -2.130858297466183,
        38.31924585875835,
        -2.130858297466183,
        38.31924585875835
      ]
    },
    "02043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3080625484524013,
              38.952332062915886
            ]
          },
          "bbox": [
            -2.3080625484524013,
            38.952332062915886,
            -2.3080625484524013,
            38.952332062915886
          ]
        }
      ],
      "bbox": [
        -2.3080625484524013,
        38.952332062915886,
        -2.3080625484524013,
        38.952332062915886
      ]
    },
    "02044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.936281451658062,
              38.54463948377742
            ]
          },
          "bbox": [
            -1.936281451658062,
            38.54463948377742,
            -1.936281451658062,
            38.54463948377742
          ]
        }
      ],
      "bbox": [
        -1.936281451658062,
        38.54463948377742,
        -1.936281451658062,
        38.54463948377742
      ]
    },
    "02045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.814416782012765,
              39.2337077463829
            ]
          },
          "bbox": [
            -1.814416782012765,
            39.2337077463829,
            -1.814416782012765,
            39.2337077463829
          ]
        }
      ],
      "bbox": [
        -1.814416782012765,
        39.2337077463829,
        -1.814416782012765,
        39.2337077463829
      ]
    },
    "02046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.716652311798352,
              39.204994912220855
            ]
          },
          "bbox": [
            -1.716652311798352,
            39.204994912220855,
            -1.716652311798352,
            39.204994912220855
          ]
        }
      ],
      "bbox": [
        -1.716652311798352,
        39.204994912220855,
        -1.716652311798352,
        39.204994912220855
      ]
    },
    "02047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.313765374654525,
              38.722606455465886
            ]
          },
          "bbox": [
            -2.313765374654525,
            38.722606455465886,
            -2.313765374654525,
            38.722606455465886
          ]
        }
      ],
      "bbox": [
        -2.313765374654525,
        38.722606455465886,
        -2.313765374654525,
        38.722606455465886
      ]
    },
    "02048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3153621443520325,
              39.221240722061665
            ]
          },
          "bbox": [
            -2.3153621443520325,
            39.221240722061665,
            -2.3153621443520325,
            39.221240722061665
          ]
        }
      ],
      "bbox": [
        -2.3153621443520325,
        39.221240722061665,
        -2.3153621443520325,
        39.221240722061665
      ]
    },
    "02049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2918001372757217,
              38.49192630307152
            ]
          },
          "bbox": [
            -2.2918001372757217,
            38.49192630307152,
            -2.2918001372757217,
            38.49192630307152
          ]
        }
      ],
      "bbox": [
        -2.2918001372757217,
        38.49192630307152,
        -2.2918001372757217,
        38.49192630307152
      ]
    },
    "02050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.031855882647159,
              39.170798751675235
            ]
          },
          "bbox": [
            -2.031855882647159,
            39.170798751675235,
            -2.031855882647159,
            39.170798751675235
          ]
        }
      ],
      "bbox": [
        -2.031855882647159,
        39.170798751675235,
        -2.031855882647159,
        39.170798751675235
      ]
    },
    "02051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3073683697647884,
              38.764193980837334
            ]
          },
          "bbox": [
            -1.3073683697647884,
            38.764193980837334,
            -1.3073683697647884,
            38.764193980837334
          ]
        }
      ],
      "bbox": [
        -1.3073683697647884,
        38.764193980837334,
        -1.3073683697647884,
        38.764193980837334
      ]
    },
    "02052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7767012390421766,
              39.17022917481714
            ]
          },
          "bbox": [
            -1.7767012390421766,
            39.17022917481714,
            -1.7767012390421766,
            39.17022917481714
          ]
        }
      ],
      "bbox": [
        -1.7767012390421766,
        39.17022917481714,
        -1.7767012390421766,
        39.17022917481714
      ]
    },
    "02053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.450720460816435,
              39.085980103369636
            ]
          },
          "bbox": [
            -2.450720460816435,
            39.085980103369636,
            -2.450720460816435,
            39.085980103369636
          ]
        }
      ],
      "bbox": [
        -2.450720460816435,
        39.085980103369636,
        -2.450720460816435,
        39.085980103369636
      ]
    },
    "02054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7263803744146173,
              39.28386153368451
            ]
          },
          "bbox": [
            -1.7263803744146173,
            39.28386153368451,
            -1.7263803744146173,
            39.28386153368451
          ]
        }
      ],
      "bbox": [
        -1.7263803744146173,
        39.28386153368451,
        -1.7263803744146173,
        39.28386153368451
      ]
    },
    "02055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.360531643881197,
              38.1432337928838
            ]
          },
          "bbox": [
            -2.360531643881197,
            38.1432337928838,
            -2.360531643881197,
            38.1432337928838
          ]
        }
      ],
      "bbox": [
        -2.360531643881197,
        38.1432337928838,
        -2.360531643881197,
        38.1432337928838
      ]
    },
    "02056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.490136936882656,
              38.622108729098535
            ]
          },
          "bbox": [
            -1.490136936882656,
            38.622108729098535,
            -1.490136936882656,
            38.622108729098535
          ]
        }
      ],
      "bbox": [
        -1.490136936882656,
        38.622108729098535,
        -1.490136936882656,
        38.622108729098535
      ]
    },
    "02057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7653411182341805,
              38.96882823899516
            ]
          },
          "bbox": [
            -2.7653411182341805,
            38.96882823899516,
            -2.7653411182341805,
            38.96882823899516
          ]
        }
      ],
      "bbox": [
        -2.7653411182341805,
        38.96882823899516,
        -2.7653411182341805,
        38.96882823899516
      ]
    },
    "02058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3435759864158,
              38.587886797139205
            ]
          },
          "bbox": [
            -2.3435759864158,
            38.587886797139205,
            -2.3435759864158,
            38.587886797139205
          ]
        }
      ],
      "bbox": [
        -2.3435759864158,
        38.587886797139205,
        -2.3435759864158,
        38.587886797139205
      ]
    },
    "02059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3286684595718965,
              38.66937933907434
            ]
          },
          "bbox": [
            -2.3286684595718965,
            38.66937933907434,
            -2.3286684595718965,
            38.66937933907434
          ]
        }
      ],
      "bbox": [
        -2.3286684595718965,
        38.66937933907434,
        -2.3286684595718965,
        38.66937933907434
      ]
    },
    "02060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0280331634991104,
              38.73725675600049
            ]
          },
          "bbox": [
            -2.0280331634991104,
            38.73725675600049,
            -2.0280331634991104,
            38.73725675600049
          ]
        }
      ],
      "bbox": [
        -2.0280331634991104,
        38.73725675600049,
        -2.0280331634991104,
        38.73725675600049
      ]
    },
    "02061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.5254404451293921,
              38.79393550261084
            ]
          },
          "bbox": [
            -1.5254404451293921,
            38.79393550261084,
            -1.5254404451293921,
            38.79393550261084
          ]
        }
      ],
      "bbox": [
        -1.5254404451293921,
        38.79393550261084,
        -1.5254404451293921,
        38.79393550261084
      ]
    },
    "02062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6190502124252877,
              38.705342445453944
            ]
          },
          "bbox": [
            -2.6190502124252877,
            38.705342445453944,
            -2.6190502124252877,
            38.705342445453944
          ]
        }
      ],
      "bbox": [
        -2.6190502124252877,
        38.705342445453944,
        -2.6190502124252877,
        38.705342445453944
      ]
    },
    "02063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.913382337749694,
              38.712129051160055
            ]
          },
          "bbox": [
            -1.913382337749694,
            38.712129051160055,
            -1.913382337749694,
            38.712129051160055
          ]
        }
      ],
      "bbox": [
        -1.913382337749694,
        38.712129051160055,
        -1.913382337749694,
        38.712129051160055
      ]
    },
    "02064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4821341849844278,
              39.059182204606714
            ]
          },
          "bbox": [
            -1.4821341849844278,
            39.059182204606714,
            -1.4821341849844278,
            39.059182204606714
          ]
        }
      ],
      "bbox": [
        -1.4821341849844278,
        39.059182204606714,
        -1.4821341849844278,
        39.059182204606714
      ]
    },
    "02065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0872186680540965,
              38.814206547114374
            ]
          },
          "bbox": [
            -2.0872186680540965,
            38.814206547114374,
            -2.0872186680540965,
            38.814206547114374
          ]
        }
      ],
      "bbox": [
        -2.0872186680540965,
        38.814206547114374,
        -2.0872186680540965,
        38.814206547114374
      ]
    },
    "02066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.456985160469652,
              39.14847111836131
            ]
          },
          "bbox": [
            -1.456985160469652,
            39.14847111836131,
            -1.456985160469652,
            39.14847111836131
          ]
        }
      ],
      "bbox": [
        -1.456985160469652,
        39.14847111836131,
        -1.456985160469652,
        39.14847111836131
      ]
    },
    "02067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4235937945661155,
              38.48170075167356
            ]
          },
          "bbox": [
            -2.4235937945661155,
            38.48170075167356,
            -2.4235937945661155,
            38.48170075167356
          ]
        }
      ],
      "bbox": [
        -2.4235937945661155,
        38.48170075167356,
        -2.4235937945661155,
        38.48170075167356
      ]
    },
    "02068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.429963216126004,
              38.776368528119974
            ]
          },
          "bbox": [
            -2.429963216126004,
            38.776368528119974,
            -2.429963216126004,
            38.776368528119974
          ]
        }
      ],
      "bbox": [
        -2.429963216126004,
        38.776368528119974,
        -2.429963216126004,
        38.776368528119974
      ]
    },
    "02069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1979593057003717,
              39.17268252014708
            ]
          },
          "bbox": [
            -2.1979593057003717,
            39.17268252014708,
            -2.1979593057003717,
            39.17268252014708
          ]
        }
      ],
      "bbox": [
        -2.1979593057003717,
        39.17268252014708,
        -2.1979593057003717,
        39.17268252014708
      ]
    },
    "02070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.547539258652256,
              38.575596588387334
            ]
          },
          "bbox": [
            -2.547539258652256,
            38.575596588387334,
            -2.547539258652256,
            38.575596588387334
          ]
        }
      ],
      "bbox": [
        -2.547539258652256,
        38.575596588387334,
        -2.547539258652256,
        38.575596588387334
      ]
    },
    "02071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1849931900138624,
              38.789811528609484
            ]
          },
          "bbox": [
            -2.1849931900138624,
            38.789811528609484,
            -2.1849931900138624,
            38.789811528609484
          ]
        }
      ],
      "bbox": [
        -2.1849931900138624,
        38.789811528609484,
        -2.1849931900138624,
        38.789811528609484
      ]
    },
    "02072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9087272986105854,
              38.33918199701785
            ]
          },
          "bbox": [
            -1.9087272986105854,
            38.33918199701785,
            -1.9087272986105854,
            38.33918199701785
          ]
        }
      ],
      "bbox": [
        -1.9087272986105854,
        38.33918199701785,
        -1.9087272986105854,
        38.33918199701785
      ]
    },
    "02073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9366525980993985,
              39.239021071571734
            ]
          },
          "bbox": [
            -1.9366525980993985,
            39.239021071571734,
            -1.9366525980993985,
            39.239021071571734
          ]
        }
      ],
      "bbox": [
        -1.9366525980993985,
        39.239021071571734,
        -1.9366525980993985,
        39.239021071571734
      ]
    },
    "02074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6642220143640845,
              38.62034852328149
            ]
          },
          "bbox": [
            -1.6642220143640845,
            38.62034852328149,
            -1.6642220143640845,
            38.62034852328149
          ]
        }
      ],
      "bbox": [
        -1.6642220143640845,
        38.62034852328149,
        -1.6642220143640845,
        38.62034852328149
      ]
    },
    "02075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.6674966253835497,
              39.12126137025844
            ]
          },
          "bbox": [
            -1.6674966253835497,
            39.12126137025844,
            -1.6674966253835497,
            39.12126137025844
          ]
        }
      ],
      "bbox": [
        -1.6674966253835497,
        39.12126137025844,
        -1.6674966253835497,
        39.12126137025844
      ]
    },
    "02076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5065040199468145,
              38.58107595865542
            ]
          },
          "bbox": [
            -2.5065040199468145,
            38.58107595865542,
            -2.5065040199468145,
            38.58107595865542
          ]
        }
      ],
      "bbox": [
        -2.5065040199468145,
        38.58107595865542,
        -2.5065040199468145,
        38.58107595865542
      ]
    },
    "02077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.2483158839076547,
              39.194301785589616
            ]
          },
          "bbox": [
            -1.2483158839076547,
            39.194301785589616,
            -1.2483158839076547,
            39.194301785589616
          ]
        }
      ],
      "bbox": [
        -1.2483158839076547,
        39.194301785589616,
        -1.2483158839076547,
        39.194301785589616
      ]
    },
    "02078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.044666370666001,
              39.319484430963485
            ]
          },
          "bbox": [
            -2.044666370666001,
            39.319484430963485,
            -2.044666370666001,
            39.319484430963485
          ]
        }
      ],
      "bbox": [
        -2.044666370666001,
        39.319484430963485,
        -2.044666370666001,
        39.319484430963485
      ]
    },
    "02079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.550307317265237,
              39.358897161977104
            ]
          },
          "bbox": [
            -1.550307317265237,
            39.358897161977104,
            -1.550307317265237,
            39.358897161977104
          ]
        }
      ],
      "bbox": [
        -1.550307317265237,
        39.358897161977104,
        -1.550307317265237,
        39.358897161977104
      ]
    },
    "02080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6406788071302976,
              38.57947612820755
            ]
          },
          "bbox": [
            -2.6406788071302976,
            38.57947612820755,
            -2.6406788071302976,
            38.57947612820755
          ]
        }
      ],
      "bbox": [
        -2.6406788071302976,
        38.57947612820755,
        -2.6406788071302976,
        38.57947612820755
      ]
    },
    "02081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6099640997457283,
              39.19364396185924
            ]
          },
          "bbox": [
            -2.6099640997457283,
            39.19364396185924,
            -2.6099640997457283,
            39.19364396185924
          ]
        }
      ],
      "bbox": [
        -2.6099640997457283,
        39.19364396185924,
        -2.6099640997457283,
        39.19364396185924
      ]
    },
    "02082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.3321328693576697,
              39.32313240823023
            ]
          },
          "bbox": [
            -1.3321328693576697,
            39.32313240823023,
            -1.3321328693576697,
            39.32313240823023
          ]
        }
      ],
      "bbox": [
        -1.3321328693576697,
        39.32313240823023,
        -1.3321328693576697,
        39.32313240823023
      ]
    },
    "02083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.4682494602775407,
              39.117754871493524
            ]
          },
          "bbox": [
            -1.4682494602775407,
            39.117754871493524,
            -1.4682494602775407,
            39.117754871493524
          ]
        }
      ],
      "bbox": [
        -1.4682494602775407,
        39.117754871493524,
        -1.4682494602775407,
        39.117754871493524
      ]
    },
    "02084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5176844859295,
              38.46245713200798
            ]
          },
          "bbox": [
            -2.5176844859295,
            38.46245713200798,
            -2.5176844859295,
            38.46245713200798
          ]
        }
      ],
      "bbox": [
        -2.5176844859295,
        38.46245713200798,
        -2.5176844859295,
        38.46245713200798
      ]
    },
    "02085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.574620899905221,
              38.80023342653584
            ]
          },
          "bbox": [
            -2.574620899905221,
            38.80023342653584,
            -2.574620899905221,
            38.80023342653584
          ]
        }
      ],
      "bbox": [
        -2.574620899905221,
        38.80023342653584,
        -2.574620899905221,
        38.80023342653584
      ]
    },
    "02086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.34806059421445,
              38.33831789494842
            ]
          },
          "bbox": [
            -2.34806059421445,
            38.33831789494842,
            -2.34806059421445,
            38.33831789494842
          ]
        }
      ],
      "bbox": [
        -2.34806059421445,
        38.33831789494842,
        -2.34806059421445,
        38.33831789494842
      ]
    },
    "02901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.7597026456167777,
              38.78279596933186
            ]
          },
          "bbox": [
            -1.7597026456167777,
            38.78279596933186,
            -1.7597026456167777,
            38.78279596933186
          ]
        }
      ],
      "bbox": [
        -1.7597026456167777,
        38.78279596933186,
        -1.7597026456167777,
        38.78279596933186
      ]
    },
    "03002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6415319762946592,
              38.44232160862567
            ]
          },
          "bbox": [
            -0.6415319762946592,
            38.44232160862567,
            -0.6415319762946592,
            38.44232160862567
          ]
        }
      ],
      "bbox": [
        -0.6415319762946592,
        38.44232160862567,
        -0.6415319762946592,
        38.44232160862567
      ]
    },
    "03003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5121760594056654,
              38.78712767294613
            ]
          },
          "bbox": [
            -0.5121760594056654,
            38.78712767294613,
            -0.5121760594056654,
            38.78712767294613
          ]
        }
      ],
      "bbox": [
        -0.5121760594056654,
        38.78712767294613,
        -0.5121760594056654,
        38.78712767294613
      ]
    },
    "03004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.363439214591136,
              38.51007973442802
            ]
          },
          "bbox": [
            -0.363439214591136,
            38.51007973442802,
            -0.363439214591136,
            38.51007973442802
          ]
        }
      ],
      "bbox": [
        -0.363439214591136,
        38.51007973442802,
        -0.363439214591136,
        38.51007973442802
      ]
    },
    "03005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.891918064188705,
              38.21603138654957
            ]
          },
          "bbox": [
            -0.891918064188705,
            38.21603138654957,
            -0.891918064188705,
            38.21603138654957
          ]
        }
      ],
      "bbox": [
        -0.891918064188705,
        38.21603138654957,
        -0.891918064188705,
        38.21603138654957
      ]
    },
    "03006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.031112076248596642,
              38.75587517582939
            ]
          },
          "bbox": [
            -0.031112076248596642,
            38.75587517582939,
            -0.031112076248596642,
            38.75587517582939
          ]
        }
      ],
      "bbox": [
        -0.031112076248596642,
        38.75587517582939,
        -0.031112076248596642,
        38.75587517582939
      ]
    },
    "03007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4004188703671752,
              38.79526798697304
            ]
          },
          "bbox": [
            -0.4004188703671752,
            38.79526798697304,
            -0.4004188703671752,
            38.79526798697304
          ]
        }
      ],
      "bbox": [
        -0.4004188703671752,
        38.79526798697304,
        -0.4004188703671752,
        38.79526798697304
      ]
    },
    "03008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.32748337614189077,
              38.66504896568666
            ]
          },
          "bbox": [
            -0.32748337614189077,
            38.66504896568666,
            -0.32748337614189077,
            38.66504896568666
          ]
        }
      ],
      "bbox": [
        -0.32748337614189077,
        38.66504896568666,
        -0.32748337614189077,
        38.66504896568666
      ]
    },
    "03009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5164785300253097,
              38.682290628101406
            ]
          },
          "bbox": [
            -0.5164785300253097,
            38.682290628101406,
            -0.5164785300253097,
            38.682290628101406
          ]
        }
      ],
      "bbox": [
        -0.5164785300253097,
        38.682290628101406,
        -0.5164785300253097,
        38.682290628101406
      ]
    },
    "03010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5637757652683929,
              38.78075721275011
            ]
          },
          "bbox": [
            -0.5637757652683929,
            38.78075721275011,
            -0.5637757652683929,
            38.78075721275011
          ]
        }
      ],
      "bbox": [
        -0.5637757652683929,
        38.78075721275011,
        -0.5637757652683929,
        38.78075721275011
      ]
    },
    "03012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.786673670114587,
              38.064097923716226
            ]
          },
          "bbox": [
            -0.786673670114587,
            38.064097923716226,
            -0.786673670114587,
            38.064097923716226
          ]
        }
      ],
      "bbox": [
        -0.786673670114587,
        38.064097923716226,
        -0.786673670114587,
        38.064097923716226
      ]
    },
    "03013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0039003426439668,
              38.3434917116986
            ]
          },
          "bbox": [
            -1.0039003426439668,
            38.3434917116986,
            -1.0039003426439668,
            38.3434917116986
          ]
        }
      ],
      "bbox": [
        -1.0039003426439668,
        38.3434917116986,
        -1.0039003426439668,
        38.3434917116986
      ]
    },
    "03014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5372137371614577,
              38.38231980300836
            ]
          },
          "bbox": [
            -0.5372137371614577,
            38.38231980300836,
            -0.5372137371614577,
            38.38231980300836
          ]
        }
      ],
      "bbox": [
        -0.5372137371614577,
        38.38231980300836,
        -0.5372137371614577,
        38.38231980300836
      ]
    },
    "03015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.791538786777941,
              38.07209223404616
            ]
          },
          "bbox": [
            -0.791538786777941,
            38.07209223404616,
            -0.791538786777941,
            38.07209223404616
          ]
        }
      ],
      "bbox": [
        -0.791538786777941,
        38.07209223404616,
        -0.791538786777941,
        38.07209223404616
      ]
    },
    "03016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3577594894431728,
              38.76419661950344
            ]
          },
          "bbox": [
            -0.3577594894431728,
            38.76419661950344,
            -0.3577594894431728,
            38.76419661950344
          ]
        }
      ],
      "bbox": [
        -0.3577594894431728,
        38.76419661950344,
        -0.3577594894431728,
        38.76419661950344
      ]
    },
    "03018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.047420036547011914,
              38.625162297241644
            ]
          },
          "bbox": [
            -0.047420036547011914,
            38.625162297241644,
            -0.047420036547011914,
            38.625162297241644
          ]
        }
      ],
      "bbox": [
        -0.047420036547011914,
        38.625162297241644,
        -0.047420036547011914,
        38.625162297241644
      ]
    },
    "03019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7853289390111853,
              38.32954402705902
            ]
          },
          "bbox": [
            -0.7853289390111853,
            38.32954402705902,
            -0.7853289390111853,
            38.32954402705902
          ]
        }
      ],
      "bbox": [
        -0.7853289390111853,
        38.32954402705902,
        -0.7853289390111853,
        38.32954402705902
      ]
    },
    "03020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.33313197875663403,
              38.74407875505803
            ]
          },
          "bbox": [
            -0.33313197875663403,
            38.74407875505803,
            -0.33313197875663403,
            38.74407875505803
          ]
        }
      ],
      "bbox": [
        -0.33313197875663403,
        38.74407875505803,
        -0.33313197875663403,
        38.74407875505803
      ]
    },
    "03021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6777690910532354,
              38.705576062653684
            ]
          },
          "bbox": [
            -0.6777690910532354,
            38.705576062653684,
            -0.6777690910532354,
            38.705576062653684
          ]
        }
      ],
      "bbox": [
        -0.6777690910532354,
        38.705576062653684,
        -0.6777690910532354,
        38.705576062653684
      ]
    },
    "03022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.33786493091057423,
              38.695884744643166
            ]
          },
          "bbox": [
            -0.33786493091057423,
            38.695884744643166,
            -0.33786493091057423,
            38.695884744643166
          ]
        }
      ],
      "bbox": [
        -0.33786493091057423,
        38.695884744643166,
        -0.33786493091057423,
        38.695884744643166
      ]
    },
    "03023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7605031914130189,
              38.722208383201824
            ]
          },
          "bbox": [
            -0.7605031914130189,
            38.722208383201824,
            -0.7605031914130189,
            38.722208383201824
          ]
        }
      ],
      "bbox": [
        -0.7605031914130189,
        38.722208383201824,
        -0.7605031914130189,
        38.722208383201824
      ]
    },
    "03024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8318735103789053,
              38.0736470440478
            ]
          },
          "bbox": [
            -0.8318735103789053,
            38.0736470440478,
            -0.8318735103789053,
            38.0736470440478
          ]
        }
      ],
      "bbox": [
        -0.8318735103789053,
        38.0736470440478,
        -0.8318735103789053,
        38.0736470440478
      ]
    },
    "03025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9830273544082798,
              38.1518502016217
            ]
          },
          "bbox": [
            -0.9830273544082798,
            38.1518502016217,
            -0.9830273544082798,
            38.1518502016217
          ]
        }
      ],
      "bbox": [
        -0.9830273544082798,
        38.1518502016217,
        -0.9830273544082798,
        38.1518502016217
      ]
    },
    "03026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.011652407515518713,
              38.81759948961862
            ]
          },
          "bbox": [
            -0.011652407515518713,
            38.81759948961862,
            -0.011652407515518713,
            38.81759948961862
          ]
        }
      ],
      "bbox": [
        -0.011652407515518713,
        38.81759948961862,
        -0.011652407515518713,
        38.81759948961862
      ]
    },
    "03027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2144122351381332,
              38.696161109583564
            ]
          },
          "bbox": [
            -0.2144122351381332,
            38.696161109583564,
            -0.2144122351381332,
            38.696161109583564
          ]
        }
      ],
      "bbox": [
        -0.2144122351381332,
        38.696161109583564,
        -0.2144122351381332,
        38.696161109583564
      ]
    },
    "03028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3625729847679592,
              38.82571563466051
            ]
          },
          "bbox": [
            -0.3625729847679592,
            38.82571563466051,
            -0.3625729847679592,
            38.82571563466051
          ]
        }
      ],
      "bbox": [
        -0.3625729847679592,
        38.82571563466051,
        -0.3625729847679592,
        38.82571563466051
      ]
    },
    "03029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1158240610508401,
              38.746969473329
            ]
          },
          "bbox": [
            -0.1158240610508401,
            38.746969473329,
            -0.1158240610508401,
            38.746969473329
          ]
        }
      ],
      "bbox": [
        -0.1158240610508401,
        38.746969473329,
        -0.1158240610508401,
        38.746969473329
      ]
    },
    "03030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.027011562079303827,
              38.792366865366965
            ]
          },
          "bbox": [
            -0.027011562079303827,
            38.792366865366965,
            -0.027011562079303827,
            38.792366865366965
          ]
        }
      ],
      "bbox": [
        -0.027011562079303827,
        38.792366865366965,
        -0.027011562079303827,
        38.792366865366965
      ]
    },
    "03031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1289964491021828,
              38.55483937045463
            ]
          },
          "bbox": [
            -0.1289964491021828,
            38.55483937045463,
            -0.1289964491021828,
            38.55483937045463
          ]
        }
      ],
      "bbox": [
        -0.1289964491021828,
        38.55483937045463,
        -0.1289964491021828,
        38.55483937045463
      ]
    },
    "03032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4117434355907616,
              38.65509741129884
            ]
          },
          "bbox": [
            -0.4117434355907616,
            38.65509741129884,
            -0.4117434355907616,
            38.65509741129884
          ]
        }
      ],
      "bbox": [
        -0.4117434355907616,
        38.65509741129884,
        -0.4117434355907616,
        38.65509741129884
      ]
    },
    "03033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.241205392850626,
              38.66027493080158
            ]
          },
          "bbox": [
            -0.241205392850626,
            38.66027493080158,
            -0.241205392850626,
            38.66027493080158
          ]
        }
      ],
      "bbox": [
        -0.241205392850626,
        38.66027493080158,
        -0.241205392850626,
        38.66027493080158
      ]
    },
    "03034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7407721905611306,
              38.0749200562189
            ]
          },
          "bbox": [
            -0.7407721905611306,
            38.0749200562189,
            -0.7407721905611306,
            38.0749200562189
          ]
        }
      ],
      "bbox": [
        -0.7407721905611306,
        38.0749200562189,
        -0.7407721905611306,
        38.0749200562189
      ]
    },
    "03035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3957410239841142,
              38.705813216273306
            ]
          },
          "bbox": [
            -0.3957410239841142,
            38.705813216273306,
            -0.3957410239841142,
            38.705813216273306
          ]
        }
      ],
      "bbox": [
        -0.3957410239841142,
        38.705813216273306,
        -0.3957410239841142,
        38.705813216273306
      ]
    },
    "03036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.37991146662087305,
              38.756291221537694
            ]
          },
          "bbox": [
            -0.37991146662087305,
            38.756291221537694,
            -0.37991146662087305,
            38.756291221537694
          ]
        }
      ],
      "bbox": [
        -0.37991146662087305,
        38.756291221537694,
        -0.37991146662087305,
        38.756291221537694
      ]
    },
    "03037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.20247439713719503,
              38.645821016545156
            ]
          },
          "bbox": [
            -0.20247439713719503,
            38.645821016545156,
            -0.20247439713719503,
            38.645821016545156
          ]
        }
      ],
      "bbox": [
        -0.20247439713719503,
        38.645821016545156,
        -0.20247439713719503,
        38.645821016545156
      ]
    },
    "03038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.39154409600333273,
              38.775198207112474
            ]
          },
          "bbox": [
            -0.39154409600333273,
            38.775198207112474,
            -0.39154409600333273,
            38.775198207112474
          ]
        }
      ],
      "bbox": [
        -0.39154409600333273,
        38.775198207112474,
        -0.39154409600333273,
        38.775198207112474
      ]
    },
    "03039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2986928024390241,
              38.75388103950172
            ]
          },
          "bbox": [
            -0.2986928024390241,
            38.75388103950172,
            -0.2986928024390241,
            38.75388103950172
          ]
        }
      ],
      "bbox": [
        -0.2986928024390241,
        38.75388103950172,
        -0.2986928024390241,
        38.75388103950172
      ]
    },
    "03040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.035783492773896704,
              38.828929800319756
            ]
          },
          "bbox": [
            -0.035783492773896704,
            38.828929800319756,
            -0.035783492773896704,
            38.828929800319756
          ]
        }
      ],
      "bbox": [
        -0.035783492773896704,
        38.828929800319756,
        -0.035783492773896704,
        38.828929800319756
      ]
    },
    "03041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.037696396024055646,
              38.69224390330301
            ]
          },
          "bbox": [
            0.037696396024055646,
            38.69224390330301,
            0.037696396024055646,
            38.69224390330301
          ]
        }
      ],
      "bbox": [
        0.037696396024055646,
        38.69224390330301,
        0.037696396024055646,
        38.69224390330301
      ]
    },
    "03042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.14889066941755885,
              38.72452262763982
            ]
          },
          "bbox": [
            0.14889066941755885,
            38.72452262763982,
            0.14889066941755885,
            38.72452262763982
          ]
        }
      ],
      "bbox": [
        0.14889066941755885,
        38.72452262763982,
        0.14889066941755885,
        38.72452262763982
      ]
    },
    "03043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7478951444962162,
              38.644764124911426
            ]
          },
          "bbox": [
            -0.7478951444962162,
            38.644764124911426,
            -0.7478951444962162,
            38.644764124911426
          ]
        }
      ],
      "bbox": [
        -0.7478951444962162,
        38.644764124911426,
        -0.7478951444962162,
        38.644764124911426
      ]
    },
    "03044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8957981838797338,
              38.06057037801818
            ]
          },
          "bbox": [
            -0.8957981838797338,
            38.06057037801818,
            -0.8957981838797338,
            38.06057037801818
          ]
        }
      ],
      "bbox": [
        -0.8957981838797338,
        38.06057037801818,
        -0.8957981838797338,
        38.06057037801818
      ]
    },
    "03045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12137496675595265,
              38.68497117224402
            ]
          },
          "bbox": [
            -0.12137496675595265,
            38.68497117224402,
            -0.12137496675595265,
            38.68497117224402
          ]
        }
      ],
      "bbox": [
        -0.12137496675595265,
        38.68497117224402,
        -0.12137496675595265,
        38.68497117224402
      ]
    },
    "03046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.41830871967895306,
              38.5010610459841
            ]
          },
          "bbox": [
            -0.41830871967895306,
            38.5010610459841,
            -0.41830871967895306,
            38.5010610459841
          ]
        }
      ],
      "bbox": [
        -0.41830871967895306,
        38.5010610459841,
        -0.41830871967895306,
        38.5010610459841
      ]
    },
    "03047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.0441400410848725,
              38.652596181785015
            ]
          },
          "bbox": [
            0.0441400410848725,
            38.652596181785015,
            0.0441400410848725,
            38.652596181785015
          ]
        }
      ],
      "bbox": [
        0.0441400410848725,
        38.652596181785015,
        0.0441400410848725,
        38.652596181785015
      ]
    },
    "03049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8715334678453196,
              38.13418620534829
            ]
          },
          "bbox": [
            -0.8715334678453196,
            38.13418620534829,
            -0.8715334678453196,
            38.13418620534829
          ]
        }
      ],
      "bbox": [
        -0.8715334678453196,
        38.13418620534829,
        -0.8715334678453196,
        38.13418620534829
      ]
    },
    "03050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3730648824711828,
              38.46185204486367
            ]
          },
          "bbox": [
            -0.3730648824711828,
            38.46185204486367,
            -0.3730648824711828,
            38.46185204486367
          ]
        }
      ],
      "bbox": [
        -0.3730648824711828,
        38.46185204486367,
        -0.3730648824711828,
        38.46185204486367
      ]
    },
    "03051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8019471860040849,
              38.70369027503984
            ]
          },
          "bbox": [
            -0.8019471860040849,
            38.70369027503984,
            -0.8019471860040849,
            38.70369027503984
          ]
        }
      ],
      "bbox": [
        -0.8019471860040849,
        38.70369027503984,
        -0.8019471860040849,
        38.70369027503984
      ]
    },
    "03052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8216304646208706,
              38.68570695329297
            ]
          },
          "bbox": [
            -0.8216304646208706,
            38.68570695329297,
            -0.8216304646208706,
            38.68570695329297
          ]
        }
      ],
      "bbox": [
        -0.8216304646208706,
        38.68570695329297,
        -0.8216304646208706,
        38.68570695329297
      ]
    },
    "03053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6705829924872267,
              38.56791906948887
            ]
          },
          "bbox": [
            -0.6705829924872267,
            38.56791906948887,
            -0.6705829924872267,
            38.56791906948887
          ]
        }
      ],
      "bbox": [
        -0.6705829924872267,
        38.56791906948887,
        -0.6705829924872267,
        38.56791906948887
      ]
    },
    "03054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.18704713670419884,
              38.73504922683043
            ]
          },
          "bbox": [
            -0.18704713670419884,
            38.73504922683043,
            -0.18704713670419884,
            38.73504922683043
          ]
        }
      ],
      "bbox": [
        -0.18704713670419884,
        38.73504922683043,
        -0.18704713670419884,
        38.73504922683043
      ]
    },
    "03055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.797683715894883,
              38.15228746637656
            ]
          },
          "bbox": [
            -0.797683715894883,
            38.15228746637656,
            -0.797683715894883,
            38.15228746637656
          ]
        }
      ],
      "bbox": [
        -0.797683715894883,
        38.15228746637656,
        -0.797683715894883,
        38.15228746637656
      ]
    },
    "03056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.44701440147537863,
              38.73755103491206
            ]
          },
          "bbox": [
            -0.44701440147537863,
            38.73755103491206,
            -0.44701440147537863,
            38.73755103491206
          ]
        }
      ],
      "bbox": [
        -0.44701440147537863,
        38.73755103491206,
        -0.44701440147537863,
        38.73755103491206
      ]
    },
    "03057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.276671515257284,
              38.679162231492704
            ]
          },
          "bbox": [
            -0.276671515257284,
            38.679162231492704,
            -0.276671515257284,
            38.679162231492704
          ]
        }
      ],
      "bbox": [
        -0.276671515257284,
        38.679162231492704,
        -0.276671515257284,
        38.679162231492704
      ]
    },
    "03058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8994888048522636,
              38.14385946601612
            ]
          },
          "bbox": [
            -0.8994888048522636,
            38.14385946601612,
            -0.8994888048522636,
            38.14385946601612
          ]
        }
      ],
      "bbox": [
        -0.8994888048522636,
        38.14385946601612,
        -0.8994888048522636,
        38.14385946601612
      ]
    },
    "03059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8142365475690562,
              38.234564593754705
            ]
          },
          "bbox": [
            -0.8142365475690562,
            38.234564593754705,
            -0.8142365475690562,
            38.234564593754705
          ]
        }
      ],
      "bbox": [
        -0.8142365475690562,
        38.234564593754705,
        -0.8142365475690562,
        38.234564593754705
      ]
    },
    "03060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3144259255797371,
              38.71667930443582
            ]
          },
          "bbox": [
            -0.3144259255797371,
            38.71667930443582,
            -0.3144259255797371,
            38.71667930443582
          ]
        }
      ],
      "bbox": [
        -0.3144259255797371,
        38.71667930443582,
        -0.3144259255797371,
        38.71667930443582
      ]
    },
    "03061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7630912056238734,
              38.10979540047168
            ]
          },
          "bbox": [
            -0.7630912056238734,
            38.10979540047168,
            -0.7630912056238734,
            38.10979540047168
          ]
        }
      ],
      "bbox": [
        -0.7630912056238734,
        38.10979540047168,
        -0.7630912056238734,
        38.10979540047168
      ]
    },
    "03062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7353407587788983,
              38.10588743732101
            ]
          },
          "bbox": [
            -0.7353407587788983,
            38.10588743732101,
            -0.7353407587788983,
            38.10588743732101
          ]
        }
      ],
      "bbox": [
        -0.7353407587788983,
        38.10588743732101,
        -0.7353407587788983,
        38.10588743732101
      ]
    },
    "03063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.0787800443298679,
              38.82475285370792
            ]
          },
          "bbox": [
            0.0787800443298679,
            38.82475285370792,
            0.0787800443298679,
            38.82475285370792
          ]
        }
      ],
      "bbox": [
        0.0787800443298679,
        38.82475285370792,
        0.0787800443298679,
        38.82475285370792
      ]
    },
    "03064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7548118100442118,
              38.13875767942836
            ]
          },
          "bbox": [
            -0.7548118100442118,
            38.13875767942836,
            -0.7548118100442118,
            38.13875767942836
          ]
        }
      ],
      "bbox": [
        -0.7548118100442118,
        38.13875767942836,
        -0.7548118100442118,
        38.13875767942836
      ]
    },
    "03065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6648804599295006,
              38.247140289494055
            ]
          },
          "bbox": [
            -0.6648804599295006,
            38.247140289494055,
            -0.6648804599295006,
            38.247140289494055
          ]
        }
      ],
      "bbox": [
        -0.6648804599295006,
        38.247140289494055,
        -0.6648804599295006,
        38.247140289494055
      ]
    },
    "03066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8209347964355738,
              38.47496470499499
            ]
          },
          "bbox": [
            -0.8209347964355738,
            38.47496470499499,
            -0.8209347964355738,
            38.47496470499499
          ]
        }
      ],
      "bbox": [
        -0.8209347964355738,
        38.47496470499499,
        -0.8209347964355738,
        38.47496470499499
      ]
    },
    "03067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2744941007569634,
              38.731519750967585
            ]
          },
          "bbox": [
            -0.2744941007569634,
            38.731519750967585,
            -0.2744941007569634,
            38.731519750967585
          ]
        }
      ],
      "bbox": [
        -0.2744941007569634,
        38.731519750967585,
        -0.2744941007569634,
        38.731519750967585
      ]
    },
    "03068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2406226742167078,
              38.73052415738971
            ]
          },
          "bbox": [
            -0.2406226742167078,
            38.73052415738971,
            -0.2406226742167078,
            38.73052415738971
          ]
        }
      ],
      "bbox": [
        -0.2406226742167078,
        38.73052415738971,
        -0.2406226742167078,
        38.73052415738971
      ]
    },
    "03069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.20322259247658808,
              38.57409272753559
            ]
          },
          "bbox": [
            -0.20322259247658808,
            38.57409272753559,
            -0.20322259247658808,
            38.57409272753559
          ]
        }
      ],
      "bbox": [
        -0.20322259247658808,
        38.57409272753559,
        -0.20322259247658808,
        38.57409272753559
      ]
    },
    "03070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7365181289881381,
              38.094192347981576
            ]
          },
          "bbox": [
            -0.7365181289881381,
            38.094192347981576,
            -0.7365181289881381,
            38.094192347981576
          ]
        }
      ],
      "bbox": [
        -0.7365181289881381,
        38.094192347981576,
        -0.7365181289881381,
        38.094192347981576
      ]
    },
    "03071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.06986738177810088,
              38.76806090563939
            ]
          },
          "bbox": [
            0.06986738177810088,
            38.76806090563939,
            0.06986738177810088,
            38.76806090563939
          ]
        }
      ],
      "bbox": [
        0.06986738177810088,
        38.76806090563939,
        0.06986738177810088,
        38.76806090563939
      ]
    },
    "03072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4107485781373599,
              38.815924208814145
            ]
          },
          "bbox": [
            -0.4107485781373599,
            38.815924208814145,
            -0.4107485781373599,
            38.815924208814145
          ]
        }
      ],
      "bbox": [
        -0.4107485781373599,
        38.815924208814145,
        -0.4107485781373599,
        38.815924208814145
      ]
    },
    "03073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3737808110730555,
              38.72215932320775
            ]
          },
          "bbox": [
            -0.3737808110730555,
            38.72215932320775,
            -0.3737808110730555,
            38.72215932320775
          ]
        }
      ],
      "bbox": [
        -0.3737808110730555,
        38.72215932320775,
        -0.3737808110730555,
        38.72215932320775
      ]
    },
    "03074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8941365790375645,
              38.154763748175085
            ]
          },
          "bbox": [
            -0.8941365790375645,
            38.154763748175085,
            -0.8941365790375645,
            38.154763748175085
          ]
        }
      ],
      "bbox": [
        -0.8941365790375645,
        38.154763748175085,
        -0.8941365790375645,
        38.154763748175085
      ]
    },
    "03075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.1684770057278746,
              38.66992628283871
            ]
          },
          "bbox": [
            -0.1684770057278746,
            38.66992628283871,
            -0.1684770057278746,
            38.66992628283871
          ]
        }
      ],
      "bbox": [
        -0.1684770057278746,
        38.66992628283871,
        -0.1684770057278746,
        38.66992628283871
      ]
    },
    "03076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6683047659515768,
              38.08058236849839
            ]
          },
          "bbox": [
            -0.6683047659515768,
            38.08058236849839,
            -0.6683047659515768,
            38.08058236849839
          ]
        }
      ],
      "bbox": [
        -0.6683047659515768,
        38.08058236849839,
        -0.6683047659515768,
        38.08058236849839
      ]
    },
    "03077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8949337802921858,
              38.30780231742904
            ]
          },
          "bbox": [
            -0.8949337802921858,
            38.30780231742904,
            -0.8949337802921858,
            38.30780231742904
          ]
        }
      ],
      "bbox": [
        -0.8949337802921858,
        38.30780231742904,
        -0.8949337802921858,
        38.30780231742904
      ]
    },
    "03078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9323021272873047,
              38.26902689288683
            ]
          },
          "bbox": [
            -0.9323021272873047,
            38.26902689288683,
            -0.9323021272873047,
            38.26902689288683
          ]
        }
      ],
      "bbox": [
        -0.9323021272873047,
        38.26902689288683,
        -0.9323021272873047,
        38.26902689288683
      ]
    },
    "03079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.562982180707244,
              38.62486837073521
            ]
          },
          "bbox": [
            -0.562982180707244,
            38.62486837073521,
            -0.562982180707244,
            38.62486837073521
          ]
        }
      ],
      "bbox": [
        -0.562982180707244,
        38.62486837073521,
        -0.562982180707244,
        38.62486837073521
      ]
    },
    "03080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.851729737930104,
              38.04790509701097
            ]
          },
          "bbox": [
            -0.851729737930104,
            38.04790509701097,
            -0.851729737930104,
            38.04790509701097
          ]
        }
      ],
      "bbox": [
        -0.851729737930104,
        38.04790509701097,
        -0.851729737930104,
        38.04790509701097
      ]
    },
    "03081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.031208784485083216,
              38.712731787067604
            ]
          },
          "bbox": [
            -0.031208784485083216,
            38.712731787067604,
            -0.031208784485083216,
            38.712731787067604
          ]
        }
      ],
      "bbox": [
        -0.031208784485083216,
        38.712731787067604,
        -0.031208784485083216,
        38.712731787067604
      ]
    },
    "03082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.16286135649367733,
              38.767502903704845
            ]
          },
          "bbox": [
            0.16286135649367733,
            38.767502903704845,
            0.16286135649367733,
            38.767502903704845
          ]
        }
      ],
      "bbox": [
        0.16286135649367733,
        38.767502903704845,
        0.16286135649367733,
        38.767502903704845
      ]
    },
    "03083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.48956979136222917,
              38.55113643343285
            ]
          },
          "bbox": [
            -0.48956979136222917,
            38.55113643343285,
            -0.48956979136222917,
            38.55113643343285
          ]
        }
      ],
      "bbox": [
        -0.48956979136222917,
        38.55113643343285,
        -0.48956979136222917,
        38.55113643343285
      ]
    },
    "03085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.007798793469039467,
              38.72926989625228
            ]
          },
          "bbox": [
            0.007798793469039467,
            38.72926989625228,
            0.007798793469039467,
            38.72926989625228
          ]
        }
      ],
      "bbox": [
        0.007798793469039467,
        38.72926989625228,
        0.007798793469039467,
        38.72926989625228
      ]
    },
    "03086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3785243500570734,
              38.74036045490652
            ]
          },
          "bbox": [
            -0.3785243500570734,
            38.74036045490652,
            -0.3785243500570734,
            38.74036045490652
          ]
        }
      ],
      "bbox": [
        -0.3785243500570734,
        38.74036045490652,
        -0.3785243500570734,
        38.74036045490652
      ]
    },
    "03088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6949820600750103,
              38.38494299254174
            ]
          },
          "bbox": [
            -0.6949820600750103,
            38.38494299254174,
            -0.6949820600750103,
            38.38494299254174
          ]
        }
      ],
      "bbox": [
        -0.6949820600750103,
        38.38494299254174,
        -0.6949820600750103,
        38.38494299254174
      ]
    },
    "03089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9124177477810888,
              38.42853657631069
            ]
          },
          "bbox": [
            -0.9124177477810888,
            38.42853657631069,
            -0.9124177477810888,
            38.42853657631069
          ]
        }
      ],
      "bbox": [
        -0.9124177477810888,
        38.42853657631069,
        -0.9124177477810888,
        38.42853657631069
      ]
    },
    "03090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.4748828489638678,
              38.434413623587524
            ]
          },
          "bbox": [
            -0.4748828489638678,
            38.434413623587524,
            -0.4748828489638678,
            38.434413623587524
          ]
        }
      ],
      "bbox": [
        -0.4748828489638678,
        38.434413623587524,
        -0.4748828489638678,
        38.434413623587524
      ]
    },
    "03091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.07701391602154778,
              38.76587939579689
            ]
          },
          "bbox": [
            -0.07701391602154778,
            38.76587939579689,
            -0.07701391602154778,
            38.76587939579689
          ]
        }
      ],
      "bbox": [
        -0.07701391602154778,
        38.76587939579689,
        -0.07701391602154778,
        38.76587939579689
      ]
    },
    "03092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.44613851364302703,
              38.78868705428742
            ]
          },
          "bbox": [
            -0.44613851364302703,
            38.78868705428742,
            -0.44613851364302703,
            38.78868705428742
          ]
        }
      ],
      "bbox": [
        -0.44613851364302703,
        38.78868705428742,
        -0.44613851364302703,
        38.78868705428742
      ]
    },
    "03093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7862498527104976,
              38.394128624902535
            ]
          },
          "bbox": [
            -0.7862498527104976,
            38.394128624902535,
            -0.7862498527104976,
            38.394128624902535
          ]
        }
      ],
      "bbox": [
        -0.7862498527104976,
        38.394128624902535,
        -0.7862498527104976,
        38.394128624902535
      ]
    },
    "03094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.12027579858193599,
              38.60107807288785
            ]
          },
          "bbox": [
            -0.12027579858193599,
            38.60107807288785,
            -0.12027579858193599,
            38.60107807288785
          ]
        }
      ],
      "bbox": [
        -0.12027579858193599,
        38.60107807288785,
        -0.12027579858193599,
        38.60107807288785
      ]
    },
    "03095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.015031518329368353,
              38.82689982738968
            ]
          },
          "bbox": [
            0.015031518329368353,
            38.82689982738968,
            0.015031518329368353,
            38.82689982738968
          ]
        }
      ],
      "bbox": [
        0.015031518329368353,
        38.82689982738968,
        0.015031518329368353,
        38.82689982738968
      ]
    },
    "03096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6611441629772966,
              38.63328960903297
            ]
          },
          "bbox": [
            -0.6611441629772966,
            38.63328960903297,
            -0.6611441629772966,
            38.63328960903297
          ]
        }
      ],
      "bbox": [
        -0.6611441629772966,
        38.63328960903297,
        -0.6611441629772966,
        38.63328960903297
      ]
    },
    "03097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.0664096737803189,
              38.78533821278552
            ]
          },
          "bbox": [
            -0.0664096737803189,
            38.78533821278552,
            -0.0664096737803189,
            38.78533821278552
          ]
        }
      ],
      "bbox": [
        -0.0664096737803189,
        38.78533821278552,
        -0.0664096737803189,
        38.78533821278552
      ]
    },
    "03098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.2796455970598755,
              38.55356264172554
            ]
          },
          "bbox": [
            -0.2796455970598755,
            38.55356264172554,
            -0.2796455970598755,
            38.55356264172554
          ]
        }
      ],
      "bbox": [
        -0.2796455970598755,
        38.55356264172554,
        -0.2796455970598755,
        38.55356264172554
      ]
    },
    "03099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9086431169427943,
              38.06741161559958
            ]
          },
          "bbox": [
            -0.9086431169427943,
            38.06741161559958,
            -0.9086431169427943,
            38.06741161559958
          ]
        }
      ],
      "bbox": [
        -0.9086431169427943,
        38.06741161559958,
        -0.9086431169427943,
        38.06741161559958
      ]
    },
    "03100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.0737422908317056,
              38.7392266856257
            ]
          },
          "bbox": [
            -0.0737422908317056,
            38.7392266856257,
            -0.0737422908317056,
            38.7392266856257
          ]
        }
      ],
      "bbox": [
        -0.0737422908317056,
        38.7392266856257,
        -0.0737422908317056,
        38.7392266856257
      ]
    },
    "03101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.03142525135561959,
              38.793450622555156
            ]
          },
          "bbox": [
            0.03142525135561959,
            38.793450622555156,
            0.03142525135561959,
            38.793450622555156
          ]
        }
      ],
      "bbox": [
        0.03142525135561959,
        38.793450622555156,
        0.03142525135561959,
        38.793450622555156
      ]
    },
    "03102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.10004356211610282,
              38.84374070229565
            ]
          },
          "bbox": [
            -0.10004356211610282,
            38.84374070229565,
            -0.10004356211610282,
            38.84374070229565
          ]
        }
      ],
      "bbox": [
        -0.10004356211610282,
        38.84374070229565,
        -0.10004356211610282,
        38.84374070229565
      ]
    },
    "03103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3735515049860963,
              38.65836280579415
            ]
          },
          "bbox": [
            -0.3735515049860963,
            38.65836280579415,
            -0.3735515049860963,
            38.65836280579415
          ]
        }
      ],
      "bbox": [
        -0.3735515049860963,
        38.65836280579415,
        -0.3735515049860963,
        38.65836280579415
      ]
    },
    "03104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.731692109585527,
              38.492091985622245
            ]
          },
          "bbox": [
            -0.731692109585527,
            38.492091985622245,
            -0.731692109585527,
            38.492091985622245
          ]
        }
      ],
      "bbox": [
        -0.731692109585527,
        38.492091985622245,
        -0.731692109585527,
        38.492091985622245
      ]
    },
    "03105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.0290198003443862,
              38.41229297874032
            ]
          },
          "bbox": [
            -1.0290198003443862,
            38.41229297874032,
            -1.0290198003443862,
            38.41229297874032
          ]
        }
      ],
      "bbox": [
        -1.0290198003443862,
        38.41229297874032,
        -1.0290198003443862,
        38.41229297874032
      ]
    },
    "03106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3313711862031839,
              38.792231175688244
            ]
          },
          "bbox": [
            -0.3313711862031839,
            38.792231175688244,
            -0.3313711862031839,
            38.792231175688244
          ]
        }
      ],
      "bbox": [
        -0.3313711862031839,
        38.792231175688244,
        -0.3313711862031839,
        38.792231175688244
      ]
    },
    "03107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.15237687938531616,
              38.62061398167607
            ]
          },
          "bbox": [
            -0.15237687938531616,
            38.62061398167607,
            -0.15237687938531616,
            38.62061398167607
          ]
        }
      ],
      "bbox": [
        -0.15237687938531616,
        38.62061398167607,
        -0.15237687938531616,
        38.62061398167607
      ]
    },
    "03109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8501625356726273,
              38.10607556945277
            ]
          },
          "bbox": [
            -0.8501625356726273,
            38.10607556945277,
            -0.8501625356726273,
            38.10607556945277
          ]
        }
      ],
      "bbox": [
        -0.8501625356726273,
        38.10607556945277,
        -0.8501625356726273,
        38.10607556945277
      ]
    },
    "03111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9212200459410793,
              38.12224671511296
            ]
          },
          "bbox": [
            -0.9212200459410793,
            38.12224671511296,
            -0.9212200459410793,
            38.12224671511296
          ]
        }
      ],
      "bbox": [
        -0.9212200459410793,
        38.12224671511296,
        -0.9212200459410793,
        38.12224671511296
      ]
    },
    "03112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.3395453644305088,
              38.57136354572136
            ]
          },
          "bbox": [
            -0.3395453644305088,
            38.57136354572136,
            -0.3395453644305088,
            38.57136354572136
          ]
        }
      ],
      "bbox": [
        -0.3395453644305088,
        38.57136354572136,
        -0.3395453644305088,
        38.57136354572136
      ]
    },
    "03113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7184568716964511,
              38.07405227536373
            ]
          },
          "bbox": [
            -0.7184568716964511,
            38.07405227536373,
            -0.7184568716964511,
            38.07405227536373
          ]
        }
      ],
      "bbox": [
        -0.7184568716964511,
        38.07405227536373,
        -0.7184568716964511,
        38.07405227536373
      ]
    },
    "03114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9077450154187479,
              38.35515451365426
            ]
          },
          "bbox": [
            -0.9077450154187479,
            38.35515451365426,
            -0.9077450154187479,
            38.35515451365426
          ]
        }
      ],
      "bbox": [
        -0.9077450154187479,
        38.35515451365426,
        -0.9077450154187479,
        38.35515451365426
      ]
    },
    "03115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.06672772003589442,
              38.818107233456594
            ]
          },
          "bbox": [
            -0.06672772003589442,
            38.818107233456594,
            -0.06672772003589442,
            38.818107233456594
          ]
        }
      ],
      "bbox": [
        -0.06672772003589442,
        38.818107233456594,
        -0.06672772003589442,
        38.818107233456594
      ]
    },
    "03116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9105633040108105,
              38.50778170252728
            ]
          },
          "bbox": [
            -0.9105633040108105,
            38.50778170252728,
            -0.9105633040108105,
            38.50778170252728
          ]
        }
      ],
      "bbox": [
        -0.9105633040108105,
        38.50778170252728,
        -0.9105633040108105,
        38.50778170252728
      ]
    },
    "03117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.028697217251138267,
              38.815549655422174
            ]
          },
          "bbox": [
            -0.028697217251138267,
            38.815549655422174,
            -0.028697217251138267,
            38.815549655422174
          ]
        }
      ],
      "bbox": [
        -0.028697217251138267,
        38.815549655422174,
        -0.028697217251138267,
        38.815549655422174
      ]
    },
    "03118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.6926924887403145,
              38.12505978694505
            ]
          },
          "bbox": [
            -0.6926924887403145,
            38.12505978694505,
            -0.6926924887403145,
            38.12505978694505
          ]
        }
      ],
      "bbox": [
        -0.6926924887403145,
        38.12505978694505,
        -0.6926924887403145,
        38.12505978694505
      ]
    },
    "03120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8031796068152554,
              37.97487778463334
            ]
          },
          "bbox": [
            -0.8031796068152554,
            37.97487778463334,
            -0.8031796068152554,
            37.97487778463334
          ]
        }
      ],
      "bbox": [
        -0.8031796068152554,
        37.97487778463334,
        -0.8031796068152554,
        37.97487778463334
      ]
    },
    "03121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.5773855029998769,
              38.20343734086127
            ]
          },
          "bbox": [
            -0.5773855029998769,
            38.20343734086127,
            -0.5773855029998769,
            38.20343734086127
          ]
        }
      ],
      "bbox": [
        -0.5773855029998769,
        38.20343734086127,
        -0.5773855029998769,
        38.20343734086127
      ]
    },
    "03122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.534546612239652,
              38.42726988039713
            ]
          },
          "bbox": [
            -0.534546612239652,
            38.42726988039713,
            -0.534546612239652,
            38.42726988039713
          ]
        }
      ],
      "bbox": [
        -0.534546612239652,
        38.42726988039713,
        -0.534546612239652,
        38.42726988039713
      ]
    },
    "03123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8134334206936301,
              38.548468737932495
            ]
          },
          "bbox": [
            -0.8134334206936301,
            38.548468737932495,
            -0.8134334206936301,
            38.548468737932495
          ]
        }
      ],
      "bbox": [
        -0.8134334206936301,
        38.548468737932495,
        -0.8134334206936301,
        38.548468737932495
      ]
    },
    "03124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.27879133057988065,
              38.617896790294836
            ]
          },
          "bbox": [
            -0.27879133057988065,
            38.617896790294836,
            -0.27879133057988065,
            38.617896790294836
          ]
        }
      ],
      "bbox": [
        -0.27879133057988065,
        38.617896790294836,
        -0.27879133057988065,
        38.617896790294836
      ]
    },
    "03125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.04429968436122076,
              38.73443909078269
            ]
          },
          "bbox": [
            0.04429968436122076,
            38.73443909078269,
            0.04429968436122076,
            38.73443909078269
          ]
        }
      ],
      "bbox": [
        0.04429968436122076,
        38.73443909078269,
        0.04429968436122076,
        38.73443909078269
      ]
    },
    "03127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.09659173501019205,
              38.70718282302431
            ]
          },
          "bbox": [
            -0.09659173501019205,
            38.70718282302431,
            -0.09659173501019205,
            38.70718282302431
          ]
        }
      ],
      "bbox": [
        -0.09659173501019205,
        38.70718282302431,
        -0.09659173501019205,
        38.70718282302431
      ]
    },
    "03128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.11468538499449224,
              38.71175408787983
            ]
          },
          "bbox": [
            0.11468538499449224,
            38.71175408787983,
            0.11468538499449224,
            38.71175408787983
          ]
        }
      ],
      "bbox": [
        0.11468538499449224,
        38.71175408787983,
        0.11468538499449224,
        38.71175408787983
      ]
    },
    "03129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.594371048675938,
              38.52150218805251
            ]
          },
          "bbox": [
            -0.594371048675938,
            38.52150218805251,
            -0.594371048675938,
            38.52150218805251
          ]
        }
      ],
      "bbox": [
        -0.594371048675938,
        38.52150218805251,
        -0.594371048675938,
        38.52150218805251
      ]
    },
    "03130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.24761751571466362,
              38.76262276885521
            ]
          },
          "bbox": [
            -0.24761751571466362,
            38.76262276885521,
            -0.24761751571466362,
            38.76262276885521
          ]
        }
      ],
      "bbox": [
        -0.24761751571466362,
        38.76262276885521,
        -0.24761751571466362,
        38.76262276885521
      ]
    },
    "03131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.07264613514551067,
              38.80387209072334
            ]
          },
          "bbox": [
            -0.07264613514551067,
            38.80387209072334,
            -0.07264613514551067,
            38.80387209072334
          ]
        }
      ],
      "bbox": [
        -0.07264613514551067,
        38.80387209072334,
        -0.07264613514551067,
        38.80387209072334
      ]
    },
    "03132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.411386576315123,
              38.60488593853864
            ]
          },
          "bbox": [
            -0.411386576315123,
            38.60488593853864,
            -0.411386576315123,
            38.60488593853864
          ]
        }
      ],
      "bbox": [
        -0.411386576315123,
        38.60488593853864,
        -0.411386576315123,
        38.60488593853864
      ]
    },
    "03133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7045590012949334,
              38.00055054954118
            ]
          },
          "bbox": [
            -0.7045590012949334,
            38.00055054954118,
            -0.7045590012949334,
            38.00055054954118
          ]
        }
      ],
      "bbox": [
        -0.7045590012949334,
        38.00055054954118,
        -0.7045590012949334,
        38.00055054954118
      ]
    },
    "03136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.23211776219792482,
              38.829605634170285
            ]
          },
          "bbox": [
            -0.23211776219792482,
            38.829605634170285,
            -0.23211776219792482,
            38.829605634170285
          ]
        }
      ],
      "bbox": [
        -0.23211776219792482,
        38.829605634170285,
        -0.23211776219792482,
        38.829605634170285
      ]
    },
    "03137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.13092067693071505,
              38.77501576779684
            ]
          },
          "bbox": [
            -0.13092067693071505,
            38.77501576779684,
            -0.13092067693071505,
            38.77501576779684
          ]
        }
      ],
      "bbox": [
        -0.13092067693071505,
        38.77501576779684,
        -0.13092067693071505,
        38.77501576779684
      ]
    },
    "03138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.004361489793827245,
              38.85023795425276
            ]
          },
          "bbox": [
            -0.004361489793827245,
            38.85023795425276,
            -0.004361489793827245,
            38.85023795425276
          ]
        }
      ],
      "bbox": [
        -0.004361489793827245,
        38.85023795425276,
        -0.004361489793827245,
        38.85023795425276
      ]
    },
    "03139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.25150341224382855,
              38.52119833862106
            ]
          },
          "bbox": [
            -0.25150341224382855,
            38.52119833862106,
            -0.25150341224382855,
            38.52119833862106
          ]
        }
      ],
      "bbox": [
        -0.25150341224382855,
        38.52119833862106,
        -0.25150341224382855,
        38.52119833862106
      ]
    },
    "03140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.9138510753071687,
              38.63406620998291
            ]
          },
          "bbox": [
            -0.9138510753071687,
            38.63406620998291,
            -0.9138510753071687,
            38.63406620998291
          ]
        }
      ],
      "bbox": [
        -0.9138510753071687,
        38.63406620998291,
        -0.9138510753071687,
        38.63406620998291
      ]
    },
    "03901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              0.017896159924982086,
              38.8547176962589
            ]
          },
          "bbox": [
            0.017896159924982086,
            38.8547176962589,
            0.017896159924982086,
            38.8547176962589
          ]
        }
      ],
      "bbox": [
        0.017896159924982086,
        38.8547176962589,
        0.017896159924982086,
        38.8547176962589
      ]
    },
    "03902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8293627345777471,
              37.89980604514396
            ]
          },
          "bbox": [
            -0.8293627345777471,
            37.89980604514396,
            -0.8293627345777471,
            37.89980604514396
          ]
        }
      ],
      "bbox": [
        -0.8293627345777471,
        37.89980604514396,
        -0.8293627345777471,
        37.89980604514396
      ]
    },
    "03903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.7551642920452216,
              38.024614319861705
            ]
          },
          "bbox": [
            -0.7551642920452216,
            38.024614319861705,
            -0.7551642920452216,
            38.024614319861705
          ]
        }
      ],
      "bbox": [
        -0.7551642920452216,
        38.024614319861705,
        -0.7551642920452216,
        38.024614319861705
      ]
    },
    "03904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -0.8421671025210822,
              38.174836690937134
            ]
          },
          "bbox": [
            -0.8421671025210822,
            38.174836690937134,
            -0.8421671025210822,
            38.174836690937134
          ]
        }
      ],
      "bbox": [
        -0.8421671025210822,
        38.174836690937134,
        -0.8421671025210822,
        38.174836690937134
      ]
    },
    "04001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7667042039128313,
              37.16258505532374
            ]
          },
          "bbox": [
            -2.7667042039128313,
            37.16258505532374,
            -2.7667042039128313,
            37.16258505532374
          ]
        }
      ],
      "bbox": [
        -2.7667042039128313,
        37.16258505532374,
        -2.7667042039128313,
        37.16258505532374
      ]
    },
    "04002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8260717914418785,
              37.13061001038681
            ]
          },
          "bbox": [
            -2.8260717914418785,
            37.13061001038681,
            -2.8260717914418785,
            37.13061001038681
          ]
        }
      ],
      "bbox": [
        -2.8260717914418785,
        37.13061001038681,
        -2.8260717914418785,
        37.13061001038681
      ]
    },
    "04003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.05023283001955,
              36.772137726191104
            ]
          },
          "bbox": [
            -3.05023283001955,
            36.772137726191104,
            -3.05023283001955,
            36.772137726191104
          ]
        }
      ],
      "bbox": [
        -3.05023283001955,
        36.772137726191104,
        -3.05023283001955,
        36.772137726191104
      ]
    },
    "04004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1608538955355314,
              37.28489006642597
            ]
          },
          "bbox": [
            -2.1608538955355314,
            37.28489006642597,
            -2.1608538955355314,
            37.28489006642597
          ]
        }
      ],
      "bbox": [
        -2.1608538955355314,
        37.28489006642597,
        -2.1608538955355314,
        37.28489006642597
      ]
    },
    "04005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6285536838932666,
              37.06256183096431
            ]
          },
          "bbox": [
            -2.6285536838932666,
            37.06256183096431,
            -2.6285536838932666,
            37.06256183096431
          ]
        }
      ],
      "bbox": [
        -2.6285536838932666,
        37.06256183096431,
        -2.6285536838932666,
        37.06256183096431
      ]
    },
    "04006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1361385218512887,
              37.449998132690936
            ]
          },
          "bbox": [
            -2.1361385218512887,
            37.449998132690936,
            -2.1361385218512887,
            37.449998132690936
          ]
        }
      ],
      "bbox": [
        -2.1361385218512887,
        37.449998132690936,
        -2.1361385218512887,
        37.449998132690936
      ]
    },
    "04007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9902057048823,
              36.93870450830073
            ]
          },
          "bbox": [
            -2.9902057048823,
            36.93870450830073,
            -2.9902057048823,
            36.93870450830073
          ]
        }
      ],
      "bbox": [
        -2.9902057048823,
        36.93870450830073,
        -2.9902057048823,
        36.93870450830073
      ]
    },
    "04008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6233858410504616,
              37.31375914385036
            ]
          },
          "bbox": [
            -2.6233858410504616,
            37.31375914385036,
            -2.6233858410504616,
            37.31375914385036
          ]
        }
      ],
      "bbox": [
        -2.6233858410504616,
        37.31375914385036,
        -2.6233858410504616,
        37.31375914385036
      ]
    },
    "04009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.255340691935153,
              37.23359684137171
            ]
          },
          "bbox": [
            -2.255340691935153,
            37.23359684137171,
            -2.255340691935153,
            37.23359684137171
          ]
        }
      ],
      "bbox": [
        -2.255340691935153,
        37.23359684137171,
        -2.255340691935153,
        37.23359684137171
      ]
    },
    "04010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5604752587289754,
              36.99389907375852
            ]
          },
          "bbox": [
            -2.5604752587289754,
            36.99389907375852,
            -2.5604752587289754,
            36.99389907375852
          ]
        }
      ],
      "bbox": [
        -2.5604752587289754,
        36.99389907375852,
        -2.5604752587289754,
        36.99389907375852
      ]
    },
    "04011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.575192232462943,
              36.94523086936395
            ]
          },
          "bbox": [
            -2.575192232462943,
            36.94523086936395,
            -2.575192232462943,
            36.94523086936395
          ]
        }
      ],
      "bbox": [
        -2.575192232462943,
        36.94523086936395,
        -2.575192232462943,
        36.94523086936395
      ]
    },
    "04012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.603714872912078,
              36.94987556959569
            ]
          },
          "bbox": [
            -2.603714872912078,
            36.94987556959569,
            -2.603714872912078,
            36.94987556959569
          ]
        }
      ],
      "bbox": [
        -2.603714872912078,
        36.94987556959569,
        -2.603714872912078,
        36.94987556959569
      ]
    },
    "04013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3422674439394835,
              36.87559682613375
            ]
          },
          "bbox": [
            -2.3422674439394835,
            36.87559682613375,
            -2.3422674439394835,
            36.87559682613375
          ]
        }
      ],
      "bbox": [
        -2.3422674439394835,
        36.87559682613375,
        -2.3422674439394835,
        36.87559682613375
      ]
    },
    "04014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.801965013686726,
              36.96434381176997
            ]
          },
          "bbox": [
            -2.801965013686726,
            36.96434381176997,
            -2.801965013686726,
            36.96434381176997
          ]
        }
      ],
      "bbox": [
        -2.801965013686726,
        36.96434381176997,
        -2.801965013686726,
        36.96434381176997
      ]
    },
    "04015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5898027235412733,
              37.02495035360468
            ]
          },
          "bbox": [
            -2.5898027235412733,
            37.02495035360468,
            -2.5898027235412733,
            37.02495035360468
          ]
        }
      ],
      "bbox": [
        -2.5898027235412733,
        37.02495035360468,
        -2.5898027235412733,
        37.02495035360468
      ]
    },
    "04016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9531140657525956,
              37.25313997937269
            ]
          },
          "bbox": [
            -1.9531140657525956,
            37.25313997937269,
            -1.9531140657525956,
            37.25313997937269
          ]
        }
      ],
      "bbox": [
        -1.9531140657525956,
        37.25313997937269,
        -1.9531140657525956,
        37.25313997937269
      ]
    },
    "04017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0840549093624876,
              37.353020960288035
            ]
          },
          "bbox": [
            -2.0840549093624876,
            37.353020960288035,
            -2.0840549093624876,
            37.353020960288035
          ]
        }
      ],
      "bbox": [
        -2.0840549093624876,
        37.353020960288035,
        -2.0840549093624876,
        37.353020960288035
      ]
    },
    "04018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4166205554633344,
              37.356907968062664
            ]
          },
          "bbox": [
            -2.4166205554633344,
            37.356907968062664,
            -2.4166205554633344,
            37.356907968062664
          ]
        }
      ],
      "bbox": [
        -2.4166205554633344,
        37.356907968062664,
        -2.4166205554633344,
        37.356907968062664
      ]
    },
    "04019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4769753599943796,
              37.25194870247312
            ]
          },
          "bbox": [
            -2.4769753599943796,
            37.25194870247312,
            -2.4769753599943796,
            37.25194870247312
          ]
        }
      ],
      "bbox": [
        -2.4769753599943796,
        37.25194870247312,
        -2.4769753599943796,
        37.25194870247312
      ]
    },
    "04020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.994672390479683,
              37.055314727009176
            ]
          },
          "bbox": [
            -2.994672390479683,
            37.055314727009176,
            -2.994672390479683,
            37.055314727009176
          ]
        }
      ],
      "bbox": [
        -2.994672390479683,
        37.055314727009176,
        -2.994672390479683,
        37.055314727009176
      ]
    },
    "04021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4481366755754115,
              37.3146152578775
            ]
          },
          "bbox": [
            -2.4481366755754115,
            37.3146152578775,
            -2.4481366755754115,
            37.3146152578775
          ]
        }
      ],
      "bbox": [
        -2.4481366755754115,
        37.3146152578775,
        -2.4481366755754115,
        37.3146152578775
      ]
    },
    "04022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9928528567802342,
              37.17723991652277
            ]
          },
          "bbox": [
            -1.9928528567802342,
            37.17723991652277,
            -1.9928528567802342,
            37.17723991652277
          ]
        }
      ],
      "bbox": [
        -1.9928528567802342,
        37.17723991652277,
        -1.9928528567802342,
        37.17723991652277
      ]
    },
    "04023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8086274077055933,
              37.042777639827705
            ]
          },
          "bbox": [
            -2.8086274077055933,
            37.042777639827705,
            -2.8086274077055933,
            37.042777639827705
          ]
        }
      ],
      "bbox": [
        -2.8086274077055933,
        37.042777639827705,
        -2.8086274077055933,
        37.042777639827705
      ]
    },
    "04024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4739390335976,
              36.916052844731745
            ]
          },
          "bbox": [
            -2.4739390335976,
            36.916052844731745,
            -2.4739390335976,
            36.916052844731745
          ]
        }
      ],
      "bbox": [
        -2.4739390335976,
        36.916052844731745,
        -2.4739390335976,
        36.916052844731745
      ]
    },
    "04026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.226228739348646,
              37.235109356269064
            ]
          },
          "bbox": [
            -2.226228739348646,
            37.235109356269064,
            -2.226228739348646,
            37.235109356269064
          ]
        }
      ],
      "bbox": [
        -2.226228739348646,
        37.235109356269064,
        -2.226228739348646,
        37.235109356269064
      ]
    },
    "04027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.217061923941912,
              37.202934885729576
            ]
          },
          "bbox": [
            -2.217061923941912,
            37.202934885729576,
            -2.217061923941912,
            37.202934885729576
          ]
        }
      ],
      "bbox": [
        -2.217061923941912,
        37.202934885729576,
        -2.217061923941912,
        37.202934885729576
      ]
    },
    "04028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.650021780848693,
              36.96351412159141
            ]
          },
          "bbox": [
            -2.650021780848693,
            36.96351412159141,
            -2.650021780848693,
            36.96351412159141
          ]
        }
      ],
      "bbox": [
        -2.650021780848693,
        36.96351412159141,
        -2.650021780848693,
        36.96351412159141
      ]
    },
    "04029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9641529045874173,
              36.85495372057238
            ]
          },
          "bbox": [
            -2.9641529045874173,
            36.85495372057238,
            -2.9641529045874173,
            36.85495372057238
          ]
        }
      ],
      "bbox": [
        -2.9641529045874173,
        36.85495372057238,
        -2.9641529045874173,
        36.85495372057238
      ]
    },
    "04030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7350267178157543,
              36.991284095124726
            ]
          },
          "bbox": [
            -2.7350267178157543,
            36.991284095124726,
            -2.7350267178157543,
            36.991284095124726
          ]
        }
      ],
      "bbox": [
        -2.7350267178157543,
        36.991284095124726,
        -2.7350267178157543,
        36.991284095124726
      ]
    },
    "04031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.180324985106883,
              37.34162633677799
            ]
          },
          "bbox": [
            -2.180324985106883,
            37.34162633677799,
            -2.180324985106883,
            37.34162633677799
          ]
        }
      ],
      "bbox": [
        -2.180324985106883,
        37.34162633677799,
        -2.180324985106883,
        37.34162633677799
      ]
    },
    "04032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9397111418331658,
              37.00387684659788
            ]
          },
          "bbox": [
            -1.9397111418331658,
            37.00387684659788,
            -1.9397111418331658,
            37.00387684659788
          ]
        }
      ],
      "bbox": [
        -1.9397111418331658,
        37.00387684659788,
        -1.9397111418331658,
        37.00387684659788
      ]
    },
    "04033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.451442795889411,
              37.17961488822492
            ]
          },
          "bbox": [
            -2.451442795889411,
            37.17961488822492,
            -2.451442795889411,
            37.17961488822492
          ]
        }
      ],
      "bbox": [
        -2.451442795889411,
        37.17961488822492,
        -2.451442795889411,
        37.17961488822492
      ]
    },
    "04034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1854778075113375,
              37.24894740957115
            ]
          },
          "bbox": [
            -2.1854778075113375,
            37.24894740957115,
            -2.1854778075113375,
            37.24894740957115
          ]
        }
      ],
      "bbox": [
        -2.1854778075113375,
        37.24894740957115,
        -2.1854778075113375,
        37.24894740957115
      ]
    },
    "04035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.815225319654237,
              37.323136235524295
            ]
          },
          "bbox": [
            -1.815225319654237,
            37.323136235524295,
            -1.815225319654237,
            37.323136235524295
          ]
        }
      ],
      "bbox": [
        -1.815225319654237,
        37.323136235524295,
        -1.815225319654237,
        37.323136235524295
      ]
    },
    "04036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.271961269959997,
              37.263871466109684
            ]
          },
          "bbox": [
            -2.271961269959997,
            37.263871466109684,
            -2.271961269959997,
            37.263871466109684
          ]
        }
      ],
      "bbox": [
        -2.271961269959997,
        37.263871466109684,
        -2.271961269959997,
        37.263871466109684
      ]
    },
    "04037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.26834524340139,
              37.60277086062842
            ]
          },
          "bbox": [
            -2.26834524340139,
            37.60277086062842,
            -2.26834524340139,
            37.60277086062842
          ]
        }
      ],
      "bbox": [
        -2.26834524340139,
        37.60277086062842,
        -2.26834524340139,
        37.60277086062842
      ]
    },
    "04038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.823456846394492,
              36.848612801569544
            ]
          },
          "bbox": [
            -2.823456846394492,
            36.848612801569544,
            -2.823456846394492,
            36.848612801569544
          ]
        }
      ],
      "bbox": [
        -2.823456846394492,
        36.848612801569544,
        -2.823456846394492,
        36.848612801569544
      ]
    },
    "04041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5963780559707024,
              36.87551589806815
            ]
          },
          "bbox": [
            -2.5963780559707024,
            36.87551589806815,
            -2.5963780559707024,
            36.87551589806815
          ]
        }
      ],
      "bbox": [
        -2.5963780559707024,
        36.87551589806815,
        -2.5963780559707024,
        36.87551589806815
      ]
    },
    "04043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6987615012736184,
              36.87633274096377
            ]
          },
          "bbox": [
            -2.6987615012736184,
            36.87633274096377,
            -2.6987615012736184,
            36.87633274096377
          ]
        }
      ],
      "bbox": [
        -2.6987615012736184,
        36.87633274096377,
        -2.6987615012736184,
        36.87633274096377
      ]
    },
    "04044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2674829515835637,
              37.36539881158338
            ]
          },
          "bbox": [
            -2.2674829515835637,
            37.36539881158338,
            -2.2674829515835637,
            37.36539881158338
          ]
        }
      ],
      "bbox": [
        -2.2674829515835637,
        37.36539881158338,
        -2.2674829515835637,
        37.36539881158338
      ]
    },
    "04045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.856626016947232,
              37.187895012845345
            ]
          },
          "bbox": [
            -2.856626016947232,
            37.187895012845345,
            -2.856626016947232,
            37.187895012845345
          ]
        }
      ],
      "bbox": [
        -2.856626016947232,
        37.187895012845345,
        -2.856626016947232,
        37.187895012845345
      ]
    },
    "04046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.8552472202986925,
              36.974976990462494
            ]
          },
          "bbox": [
            -2.8552472202986925,
            36.974976990462494,
            -2.8552472202986925,
            36.974976990462494
          ]
        }
      ],
      "bbox": [
        -2.8552472202986925,
        36.974976990462494,
        -2.8552472202986925,
        36.974976990462494
      ]
    },
    "04047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5073833361003826,
              36.95586893575802
            ]
          },
          "bbox": [
            -2.5073833361003826,
            36.95586893575802,
            -2.5073833361003826,
            36.95586893575802
          ]
        }
      ],
      "bbox": [
        -2.5073833361003826,
        36.95586893575802,
        -2.5073833361003826,
        36.95586893575802
      ]
    },
    "04048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.951267392529779,
              37.15990204954429
            ]
          },
          "bbox": [
            -1.951267392529779,
            37.15990204954429,
            -1.951267392529779,
            37.15990204954429
          ]
        }
      ],
      "bbox": [
        -1.951267392529779,
        37.15990204954429,
        -1.951267392529779,
        37.15990204954429
      ]
    },
    "04049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8314057986925711,
              37.187307030297035
            ]
          },
          "bbox": [
            -1.8314057986925711,
            37.187307030297035,
            -1.8314057986925711,
            37.187307030297035
          ]
        }
      ],
      "bbox": [
        -1.8314057986925711,
        37.187307030297035,
        -1.8314057986925711,
        37.187307030297035
      ]
    },
    "04050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.556784798994301,
              37.149851828477246
            ]
          },
          "bbox": [
            -2.556784798994301,
            37.149851828477246,
            -2.556784798994301,
            37.149851828477246
          ]
        }
      ],
      "bbox": [
        -2.556784798994301,
        37.149851828477246,
        -2.556784798994301,
        37.149851828477246
      ]
    },
    "04051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.628213792525087,
              36.9476406188185
            ]
          },
          "bbox": [
            -2.628213792525087,
            36.9476406188185,
            -2.628213792525087,
            36.9476406188185
          ]
        }
      ],
      "bbox": [
        -2.628213792525087,
        36.9476406188185,
        -2.628213792525087,
        36.9476406188185
      ]
    },
    "04052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4657806710328374,
              36.88728831569393
            ]
          },
          "bbox": [
            -2.4657806710328374,
            36.88728831569393,
            -2.4657806710328374,
            36.88728831569393
          ]
        }
      ],
      "bbox": [
        -2.4657806710328374,
        36.88728831569393,
        -2.4657806710328374,
        36.88728831569393
      ]
    },
    "04053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9395949843695155,
              37.42837458460795
            ]
          },
          "bbox": [
            -1.9395949843695155,
            37.42837458460795,
            -1.9395949843695155,
            37.42837458460795
          ]
        }
      ],
      "bbox": [
        -1.9395949843695155,
        37.42837458460795,
        -1.9395949843695155,
        37.42837458460795
      ]
    },
    "04054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6461654260035696,
              36.981952204142786
            ]
          },
          "bbox": [
            -2.6461654260035696,
            36.981952204142786,
            -2.6461654260035696,
            36.981952204142786
          ]
        }
      ],
      "bbox": [
        -2.6461654260035696,
        36.981952204142786,
        -2.6461654260035696,
        36.981952204142786
      ]
    },
    "04055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.686168222686333,
              36.96651603546139
            ]
          },
          "bbox": [
            -2.686168222686333,
            36.96651603546139,
            -2.686168222686333,
            36.96651603546139
          ]
        }
      ],
      "bbox": [
        -2.686168222686333,
        36.96651603546139,
        -2.686168222686333,
        36.96651603546139
      ]
    },
    "04056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3439469738313803,
              37.28765201073577
            ]
          },
          "bbox": [
            -2.3439469738313803,
            37.28765201073577,
            -2.3439469738313803,
            37.28765201073577
          ]
        }
      ],
      "bbox": [
        -2.3439469738313803,
        37.28765201073577,
        -2.3439469738313803,
        37.28765201073577
      ]
    },
    "04057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9029268822392598,
              37.0113710295669
            ]
          },
          "bbox": [
            -2.9029268822392598,
            37.0113710295669,
            -2.9029268822392598,
            37.0113710295669
          ]
        }
      ],
      "bbox": [
        -2.9029268822392598,
        37.0113710295669,
        -2.9029268822392598,
        37.0113710295669
      ]
    },
    "04058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2304629745495705,
              37.29296817202036
            ]
          },
          "bbox": [
            -2.2304629745495705,
            37.29296817202036,
            -2.2304629745495705,
            37.29296817202036
          ]
        }
      ],
      "bbox": [
        -2.2304629745495705,
        37.29296817202036,
        -2.2304629745495705,
        37.29296817202036
      ]
    },
    "04059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.06728717414633,
              37.22835474133618
            ]
          },
          "bbox": [
            -2.06728717414633,
            37.22835474133618,
            -2.06728717414633,
            37.22835474133618
          ]
        }
      ],
      "bbox": [
        -2.06728717414633,
        37.22835474133618,
        -2.06728717414633,
        37.22835474133618
      ]
    },
    "04060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1539924385093774,
              37.04187048098269
            ]
          },
          "bbox": [
            -2.1539924385093774,
            37.04187048098269,
            -2.1539924385093774,
            37.04187048098269
          ]
        }
      ],
      "bbox": [
        -2.1539924385093774,
        37.04187048098269,
        -2.1539924385093774,
        37.04187048098269
      ]
    },
    "04061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4419701860974765,
              37.43820893239894
            ]
          },
          "bbox": [
            -2.4419701860974765,
            37.43820893239894,
            -2.4419701860974765,
            37.43820893239894
          ]
        }
      ],
      "bbox": [
        -2.4419701860974765,
        37.43820893239894,
        -2.4419701860974765,
        37.43820893239894
      ]
    },
    "04062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2963127890110284,
              37.309030672773325
            ]
          },
          "bbox": [
            -2.2963127890110284,
            37.309030672773325,
            -2.2963127890110284,
            37.309030672773325
          ]
        }
      ],
      "bbox": [
        -2.2963127890110284,
        37.309030672773325,
        -2.2963127890110284,
        37.309030672773325
      ]
    },
    "04063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2396350706627355,
              37.7499447241827
            ]
          },
          "bbox": [
            -2.2396350706627355,
            37.7499447241827,
            -2.2396350706627355,
            37.7499447241827
          ]
        }
      ],
      "bbox": [
        -2.2396350706627355,
        37.7499447241827,
        -2.2396350706627355,
        37.7499447241827
      ]
    },
    "04064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8662547864979444,
              37.114007280989874
            ]
          },
          "bbox": [
            -1.8662547864979444,
            37.114007280989874,
            -1.8662547864979444,
            37.114007280989874
          ]
        }
      ],
      "bbox": [
        -1.8662547864979444,
        37.114007280989874,
        -1.8662547864979444,
        37.114007280989874
      ]
    },
    "04065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6585560402632393,
              37.13473551100109
            ]
          },
          "bbox": [
            -2.6585560402632393,
            37.13473551100109,
            -2.6585560402632393,
            37.13473551100109
          ]
        }
      ],
      "bbox": [
        -2.6585560402632393,
        37.13473551100109,
        -2.6585560402632393,
        37.13473551100109
      ]
    },
    "04066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1261036339392936,
              36.89020392734509
            ]
          },
          "bbox": [
            -2.1261036339392936,
            36.89020392734509,
            -2.1261036339392936,
            36.89020392734509
          ]
        }
      ],
      "bbox": [
        -2.1261036339392936,
        36.89020392734509,
        -2.1261036339392936,
        36.89020392734509
      ]
    },
    "04067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.761284835810311,
              37.06150669083467
            ]
          },
          "bbox": [
            -2.761284835810311,
            37.06150669083467,
            -2.761284835810311,
            37.06150669083467
          ]
        }
      ],
      "bbox": [
        -2.761284835810311,
        37.06150669083467,
        -2.761284835810311,
        37.06150669083467
      ]
    },
    "04068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4879648619553736,
              37.172745289879174
            ]
          },
          "bbox": [
            -2.4879648619553736,
            37.172745289879174,
            -2.4879648619553736,
            37.172745289879174
          ]
        }
      ],
      "bbox": [
        -2.4879648619553736,
        37.172745289879174,
        -2.4879648619553736,
        37.172745289879174
      ]
    },
    "04069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3031834551218013,
              37.378949039619584
            ]
          },
          "bbox": [
            -2.3031834551218013,
            37.378949039619584,
            -2.3031834551218013,
            37.378949039619584
          ]
        }
      ],
      "bbox": [
        -2.3031834551218013,
        37.378949039619584,
        -2.3031834551218013,
        37.378949039619584
      ]
    },
    "04070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2894939535604477,
              37.494106329677535
            ]
          },
          "bbox": [
            -2.2894939535604477,
            37.494106329677535,
            -2.2894939535604477,
            37.494106329677535
          ]
        }
      ],
      "bbox": [
        -2.2894939535604477,
        37.494106329677535,
        -2.2894939535604477,
        37.494106329677535
      ]
    },
    "04071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.770890866169629,
              36.96492509619069
            ]
          },
          "bbox": [
            -2.770890866169629,
            36.96492509619069,
            -2.770890866169629,
            36.96492509619069
          ]
        }
      ],
      "bbox": [
        -2.770890866169629,
        36.96492509619069,
        -2.770890866169629,
        36.96492509619069
      ]
    },
    "04072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2300787258605688,
              37.4109151911884
            ]
          },
          "bbox": [
            -2.2300787258605688,
            37.4109151911884,
            -2.2300787258605688,
            37.4109151911884
          ]
        }
      ],
      "bbox": [
        -2.2300787258605688,
        37.4109151911884,
        -2.2300787258605688,
        37.4109151911884
      ]
    },
    "04073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.949251288464059,
              37.04851889376758
            ]
          },
          "bbox": [
            -2.949251288464059,
            37.04851889376758,
            -2.949251288464059,
            37.04851889376758
          ]
        }
      ],
      "bbox": [
        -2.949251288464059,
        37.04851889376758,
        -2.949251288464059,
        37.04851889376758
      ]
    },
    "04074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4008646659028825,
              36.94282272702999
            ]
          },
          "bbox": [
            -2.4008646659028825,
            36.94282272702999,
            -2.4008646659028825,
            36.94282272702999
          ]
        }
      ],
      "bbox": [
        -2.4008646659028825,
        36.94282272702999,
        -2.4008646659028825,
        36.94282272702999
      ]
    },
    "04075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.728360404457146,
              37.3998721186636
            ]
          },
          "bbox": [
            -1.728360404457146,
            37.3998721186636,
            -1.728360404457146,
            37.3998721186636
          ]
        }
      ],
      "bbox": [
        -1.728360404457146,
        37.3998721186636,
        -1.728360404457146,
        37.3998721186636
      ]
    },
    "04076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.361369560739666,
              37.37903953465537
            ]
          },
          "bbox": [
            -2.361369560739666,
            37.37903953465537,
            -2.361369560739666,
            37.37903953465537
          ]
        }
      ],
      "bbox": [
        -2.361369560739666,
        37.37903953465537,
        -2.361369560739666,
        37.37903953465537
      ]
    },
    "04077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6937394918678623,
              36.9998752315471
            ]
          },
          "bbox": [
            -2.6937394918678623,
            36.9998752315471,
            -2.6937394918678623,
            36.9998752315471
          ]
        }
      ],
      "bbox": [
        -2.6937394918678623,
        36.9998752315471,
        -2.6937394918678623,
        36.9998752315471
      ]
    },
    "04078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.439993911291749,
              36.971102168116126
            ]
          },
          "bbox": [
            -2.439993911291749,
            36.971102168116126,
            -2.439993911291749,
            36.971102168116126
          ]
        }
      ],
      "bbox": [
        -2.439993911291749,
        36.971102168116126,
        -2.439993911291749,
        36.971102168116126
      ]
    },
    "04079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.617050385355351,
              36.775946666486426
            ]
          },
          "bbox": [
            -2.617050385355351,
            36.775946666486426,
            -2.617050385355351,
            36.775946666486426
          ]
        }
      ],
      "bbox": [
        -2.617050385355351,
        36.775946666486426,
        -2.617050385355351,
        36.775946666486426
      ]
    },
    "04080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.5802480155013265,
              37.03750924816753
            ]
          },
          "bbox": [
            -2.5802480155013265,
            37.03750924816753,
            -2.5802480155013265,
            37.03750924816753
          ]
        }
      ],
      "bbox": [
        -2.5802480155013265,
        37.03750924816753,
        -2.5802480155013265,
        37.03750924816753
      ]
    },
    "04081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.522134097795132,
              36.99151952901253
            ]
          },
          "bbox": [
            -2.522134097795132,
            36.99151952901253,
            -2.522134097795132,
            36.99151952901253
          ]
        }
      ],
      "bbox": [
        -2.522134097795132,
        36.99151952901253,
        -2.522134097795132,
        36.99151952901253
      ]
    },
    "04082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3346141963295817,
              37.18875443608216
            ]
          },
          "bbox": [
            -2.3346141963295817,
            37.18875443608216,
            -2.3346141963295817,
            37.18875443608216
          ]
        }
      ],
      "bbox": [
        -2.3346141963295817,
        37.18875443608216,
        -2.3346141963295817,
        37.18875443608216
      ]
    },
    "04083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.546545219127105,
              37.341752082687385
            ]
          },
          "bbox": [
            -2.546545219127105,
            37.341752082687385,
            -2.546545219127105,
            37.341752082687385
          ]
        }
      ],
      "bbox": [
        -2.546545219127105,
        37.341752082687385,
        -2.546545219127105,
        37.341752082687385
      ]
    },
    "04084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3940127818562464,
              37.293934145149514
            ]
          },
          "bbox": [
            -2.3940127818562464,
            37.293934145149514,
            -2.3940127818562464,
            37.293934145149514
          ]
        }
      ],
      "bbox": [
        -2.3940127818562464,
        37.293934145149514,
        -2.3940127818562464,
        37.293934145149514
      ]
    },
    "04085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.390335669969133,
              37.41780616707532
            ]
          },
          "bbox": [
            -2.390335669969133,
            37.41780616707532,
            -2.390335669969133,
            37.41780616707532
          ]
        }
      ],
      "bbox": [
        -2.390335669969133,
        37.41780616707532,
        -2.390335669969133,
        37.41780616707532
      ]
    },
    "04086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.089633652367822,
              37.10024895303367
            ]
          },
          "bbox": [
            -2.089633652367822,
            37.10024895303367,
            -2.089633652367822,
            37.10024895303367
          ]
        }
      ],
      "bbox": [
        -2.089633652367822,
        37.10024895303367,
        -2.089633652367822,
        37.10024895303367
      ]
    },
    "04087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.380625462617024,
              37.31722415729671
            ]
          },
          "bbox": [
            -2.380625462617024,
            37.31722415729671,
            -2.380625462617024,
            37.31722415729671
          ]
        }
      ],
      "bbox": [
        -2.380625462617024,
        37.31722415729671,
        -2.380625462617024,
        37.31722415729671
      ]
    },
    "04088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.363028445236163,
              37.070994500225375
            ]
          },
          "bbox": [
            -2.363028445236163,
            37.070994500225375,
            -2.363028445236163,
            37.070994500225375
          ]
        }
      ],
      "bbox": [
        -2.363028445236163,
        37.070994500225375,
        -2.363028445236163,
        37.070994500225375
      ]
    },
    "04089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.062597715837246,
              37.48028392951114
            ]
          },
          "bbox": [
            -2.062597715837246,
            37.48028392951114,
            -2.062597715837246,
            37.48028392951114
          ]
        }
      ],
      "bbox": [
        -2.062597715837246,
        37.48028392951114,
        -2.062597715837246,
        37.48028392951114
      ]
    },
    "04090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.283278269646225,
              37.18891001651238
            ]
          },
          "bbox": [
            -2.283278269646225,
            37.18891001651238,
            -2.283278269646225,
            37.18891001651238
          ]
        }
      ],
      "bbox": [
        -2.283278269646225,
        37.18891001651238,
        -2.283278269646225,
        37.18891001651238
      ]
    },
    "04091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6267056365938934,
              36.948157317387604
            ]
          },
          "bbox": [
            -2.6267056365938934,
            36.948157317387604,
            -2.6267056365938934,
            36.948157317387604
          ]
        }
      ],
      "bbox": [
        -2.6267056365938934,
        36.948157317387604,
        -2.6267056365938934,
        36.948157317387604
      ]
    },
    "04092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.4907949704070944,
              37.40115080490524
            ]
          },
          "bbox": [
            -2.4907949704070944,
            37.40115080490524,
            -2.4907949704070944,
            37.40115080490524
          ]
        }
      ],
      "bbox": [
        -2.4907949704070944,
        37.40115080490524,
        -2.4907949704070944,
        37.40115080490524
      ]
    },
    "04093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.9392913233464146,
              37.106086400150566
            ]
          },
          "bbox": [
            -1.9392913233464146,
            37.106086400150566,
            -1.9392913233464146,
            37.106086400150566
          ]
        }
      ],
      "bbox": [
        -1.9392913233464146,
        37.106086400150566,
        -1.9392913233464146,
        37.106086400150566
      ]
    },
    "04094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.2621501710529217,
              37.028943792556525
            ]
          },
          "bbox": [
            -2.2621501710529217,
            37.028943792556525,
            -2.2621501710529217,
            37.028943792556525
          ]
        }
      ],
      "bbox": [
        -2.2621501710529217,
        37.028943792556525,
        -2.2621501710529217,
        37.028943792556525
      ]
    },
    "04095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.186719565432335,
              37.1765793453077
            ]
          },
          "bbox": [
            -2.186719565432335,
            37.1765793453077,
            -2.186719565432335,
            37.1765793453077
          ]
        }
      ],
      "bbox": [
        -2.186719565432335,
        37.1765793453077,
        -2.186719565432335,
        37.1765793453077
      ]
    },
    "04096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3487810065401695,
              37.392760097272614
            ]
          },
          "bbox": [
            -2.3487810065401695,
            37.392760097272614,
            -2.3487810065401695,
            37.392760097272614
          ]
        }
      ],
      "bbox": [
        -2.3487810065401695,
        37.392760097272614,
        -2.3487810065401695,
        37.392760097272614
      ]
    },
    "04097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.3994469145579638,
              37.19678668071343
            ]
          },
          "bbox": [
            -2.3994469145579638,
            37.19678668071343,
            -2.3994469145579638,
            37.19678668071343
          ]
        }
      ],
      "bbox": [
        -2.3994469145579638,
        37.19678668071343,
        -2.3994469145579638,
        37.19678668071343
      ]
    },
    "04098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.1059409304704477,
              37.78857076114048
            ]
          },
          "bbox": [
            -2.1059409304704477,
            37.78857076114048,
            -2.1059409304704477,
            37.78857076114048
          ]
        }
      ],
      "bbox": [
        -2.1059409304704477,
        37.78857076114048,
        -2.1059409304704477,
        37.78857076114048
      ]
    },
    "04099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0644807699885313,
              37.59036388565099
            ]
          },
          "bbox": [
            -2.0644807699885313,
            37.59036388565099,
            -2.0644807699885313,
            37.59036388565099
          ]
        }
      ],
      "bbox": [
        -2.0644807699885313,
        37.59036388565099,
        -2.0644807699885313,
        37.59036388565099
      ]
    },
    "04100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -1.8561093399534088,
              37.22498355978373
            ]
          },
          "bbox": [
            -1.8561093399534088,
            37.22498355978373,
            -1.8561093399534088,
            37.22498355978373
          ]
        }
      ],
      "bbox": [
        -1.8561093399534088,
        37.22498355978373,
        -1.8561093399534088,
        37.22498355978373
      ]
    },
    "04101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.399075606216042,
              36.89434366024838
            ]
          },
          "bbox": [
            -2.399075606216042,
            36.89434366024838,
            -2.399075606216042,
            36.89434366024838
          ]
        }
      ],
      "bbox": [
        -2.399075606216042,
        36.89434366024838,
        -2.399075606216042,
        36.89434366024838
      ]
    },
    "04102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6608421567199785,
              36.81269137227602
            ]
          },
          "bbox": [
            -2.6608421567199785,
            36.81269137227602,
            -2.6608421567199785,
            36.81269137227602
          ]
        }
      ],
      "bbox": [
        -2.6608421567199785,
        36.81269137227602,
        -2.6608421567199785,
        36.81269137227602
      ]
    },
    "04103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.0323616286556656,
              37.35939417559735
            ]
          },
          "bbox": [
            -2.0323616286556656,
            37.35939417559735,
            -2.0323616286556656,
            37.35939417559735
          ]
        }
      ],
      "bbox": [
        -2.0323616286556656,
        37.35939417559735,
        -2.0323616286556656,
        37.35939417559735
      ]
    },
    "04901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.72081001667013,
              37.160061021615874
            ]
          },
          "bbox": [
            -2.72081001667013,
            37.160061021615874,
            -2.72081001667013,
            37.160061021615874
          ]
        }
      ],
      "bbox": [
        -2.72081001667013,
        37.160061021615874,
        -2.72081001667013,
        37.160061021615874
      ]
    },
    "04902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.7815774850023054,
              36.748200036227324
            ]
          },
          "bbox": [
            -2.7815774850023054,
            36.748200036227324,
            -2.7815774850023054,
            36.748200036227324
          ]
        }
      ],
      "bbox": [
        -2.7815774850023054,
        36.748200036227324,
        -2.7815774850023054,
        36.748200036227324
      ]
    },
    "04903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.6921071311967095,
              36.774656562353094
            ]
          },
          "bbox": [
            -2.6921071311967095,
            36.774656562353094,
            -2.6921071311967095,
            36.774656562353094
          ]
        }
      ],
      "bbox": [
        -2.6921071311967095,
        36.774656562353094,
        -2.6921071311967095,
        36.774656562353094
      ]
    },
    "04904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9309470352230544,
              36.7769371430002
            ]
          },
          "bbox": [
            -2.9309470352230544,
            36.7769371430002,
            -2.9309470352230544,
            36.7769371430002
          ]
        }
      ],
      "bbox": [
        -2.9309470352230544,
        36.7769371430002,
        -2.9309470352230544,
        36.7769371430002
      ]
    },
    "05001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.601033636000738,
              40.94329440169668
            ]
          },
          "bbox": [
            -4.601033636000738,
            40.94329440169668,
            -4.601033636000738,
            40.94329440169668
          ]
        }
      ],
      "bbox": [
        -4.601033636000738,
        40.94329440169668,
        -4.601033636000738,
        40.94329440169668
      ]
    },
    "05002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.653897559086372,
              40.308167678100475
            ]
          },
          "bbox": [
            -4.653897559086372,
            40.308167678100475,
            -4.653897559086372,
            40.308167678100475
          ]
        }
      ],
      "bbox": [
        -4.653897559086372,
        40.308167678100475,
        -4.653897559086372,
        40.308167678100475
      ]
    },
    "05005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.884942348112535,
              40.834446952649934
            ]
          },
          "bbox": [
            -4.884942348112535,
            40.834446952649934,
            -4.884942348112535,
            40.834446952649934
          ]
        }
      ],
      "bbox": [
        -4.884942348112535,
        40.834446952649934,
        -4.884942348112535,
        40.834446952649934
      ]
    },
    "05007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.427612173549168,
              40.37494669910539
            ]
          },
          "bbox": [
            -5.427612173549168,
            40.37494669910539,
            -5.427612173549168,
            40.37494669910539
          ]
        }
      ],
      "bbox": [
        -5.427612173549168,
        40.37494669910539,
        -5.427612173549168,
        40.37494669910539
      ]
    },
    "05008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.803007745145123,
              41.04271878245508
            ]
          },
          "bbox": [
            -4.803007745145123,
            41.04271878245508,
            -4.803007745145123,
            41.04271878245508
          ]
        }
      ],
      "bbox": [
        -4.803007745145123,
        41.04271878245508,
        -4.803007745145123,
        41.04271878245508
      ]
    },
    "05010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.402752502078806,
              40.41660573587613
            ]
          },
          "bbox": [
            -5.402752502078806,
            40.41660573587613,
            -5.402752502078806,
            40.41660573587613
          ]
        }
      ],
      "bbox": [
        -5.402752502078806,
        40.41660573587613,
        -5.402752502078806,
        40.41660573587613
      ]
    },
    "05012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.062127734681289,
              40.5782203591098
            ]
          },
          "bbox": [
            -5.062127734681289,
            40.5782203591098,
            -5.062127734681289,
            40.5782203591098
          ]
        }
      ],
      "bbox": [
        -5.062127734681289,
        40.5782203591098,
        -5.062127734681289,
        40.5782203591098
      ]
    },
    "05013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.083102056753725,
              40.27658019829557
            ]
          },
          "bbox": [
            -5.083102056753725,
            40.27658019829557,
            -5.083102056753725,
            40.27658019829557
          ]
        }
      ],
      "bbox": [
        -5.083102056753725,
        40.27658019829557,
        -5.083102056753725,
        40.27658019829557
      ]
    },
    "05014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.111953846760922,
              40.17301670913377
            ]
          },
          "bbox": [
            -5.111953846760922,
            40.17301670913377,
            -5.111953846760922,
            40.17301670913377
          ]
        }
      ],
      "bbox": [
        -5.111953846760922,
        40.17301670913377,
        -5.111953846760922,
        40.17301670913377
      ]
    },
    "05015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.371495157473329,
              40.586623429544915
            ]
          },
          "bbox": [
            -5.371495157473329,
            40.586623429544915,
            -5.371495157473329,
            40.586623429544915
          ]
        }
      ],
      "bbox": [
        -5.371495157473329,
        40.586623429544915,
        -5.371495157473329,
        40.586623429544915
      ]
    },
    "05016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.719862727439998,
              41.044908631621304
            ]
          },
          "bbox": [
            -4.719862727439998,
            41.044908631621304,
            -4.719862727439998,
            41.044908631621304
          ]
        }
      ],
      "bbox": [
        -4.719862727439998,
        41.044908631621304,
        -4.719862727439998,
        41.044908631621304
      ]
    },
    "05017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.832997398066493,
              40.784361628570345
            ]
          },
          "bbox": [
            -4.832997398066493,
            40.784361628570345,
            -4.832997398066493,
            40.784361628570345
          ]
        }
      ],
      "bbox": [
        -4.832997398066493,
        40.784361628570345,
        -4.832997398066493,
        40.784361628570345
      ]
    },
    "05018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.386758712842025,
              40.38783294949199
            ]
          },
          "bbox": [
            -5.386758712842025,
            40.38783294949199,
            -5.386758712842025,
            40.38783294949199
          ]
        }
      ],
      "bbox": [
        -5.386758712842025,
        40.38783294949199,
        -5.386758712842025,
        40.38783294949199
      ]
    },
    "05019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.645953265442108,
              40.66231084194563
            ]
          },
          "bbox": [
            -4.645953265442108,
            40.66231084194563,
            -4.645953265442108,
            40.66231084194563
          ]
        }
      ],
      "bbox": [
        -4.645953265442108,
        40.66231084194563,
        -4.645953265442108,
        40.66231084194563
      ]
    },
    "05021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.520702370564582,
              40.35569758383796
            ]
          },
          "bbox": [
            -5.520702370564582,
            40.35569758383796,
            -5.520702370564582,
            40.35569758383796
          ]
        }
      ],
      "bbox": [
        -5.520702370564582,
        40.35569758383796,
        -5.520702370564582,
        40.35569758383796
      ]
    },
    "05022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.595181748152059,
              40.442732232589464
            ]
          },
          "bbox": [
            -4.595181748152059,
            40.442732232589464,
            -4.595181748152059,
            40.442732232589464
          ]
        }
      ],
      "bbox": [
        -4.595181748152059,
        40.442732232589464,
        -4.595181748152059,
        40.442732232589464
      ]
    },
    "05023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.930359497414343,
              41.056188629705986
            ]
          },
          "bbox": [
            -4.930359497414343,
            41.056188629705986,
            -4.930359497414343,
            41.056188629705986
          ]
        }
      ],
      "bbox": [
        -4.930359497414343,
        41.056188629705986,
        -4.930359497414343,
        41.056188629705986
      ]
    },
    "05024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6325874605145385,
              40.385986204529424
            ]
          },
          "bbox": [
            -5.6325874605145385,
            40.385986204529424,
            -5.6325874605145385,
            40.385986204529424
          ]
        }
      ],
      "bbox": [
        -5.6325874605145385,
        40.385986204529424,
        -5.6325874605145385,
        40.385986204529424
      ]
    },
    "05025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3186904514667805,
              40.54891155849077
            ]
          },
          "bbox": [
            -5.3186904514667805,
            40.54891155849077,
            -5.3186904514667805,
            40.54891155849077
          ]
        }
      ],
      "bbox": [
        -5.3186904514667805,
        40.54891155849077,
        -5.3186904514667805,
        40.54891155849077
      ]
    },
    "05026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.974785906809302,
              41.04129393876951
            ]
          },
          "bbox": [
            -4.974785906809302,
            41.04129393876951,
            -4.974785906809302,
            41.04129393876951
          ]
        }
      ],
      "bbox": [
        -4.974785906809302,
        41.04129393876951,
        -4.974785906809302,
        41.04129393876951
      ]
    },
    "05027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7663648700729535,
              40.8012272936805
            ]
          },
          "bbox": [
            -4.7663648700729535,
            40.8012272936805,
            -4.7663648700729535,
            40.8012272936805
          ]
        }
      ],
      "bbox": [
        -4.7663648700729535,
        40.8012272936805,
        -4.7663648700729535,
        40.8012272936805
      ]
    },
    "05029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.937348165438186,
              40.97210088489596
            ]
          },
          "bbox": [
            -4.937348165438186,
            40.97210088489596,
            -4.937348165438186,
            40.97210088489596
          ]
        }
      ],
      "bbox": [
        -4.937348165438186,
        40.97210088489596,
        -4.937348165438186,
        40.97210088489596
      ]
    },
    "05030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.593202431114733,
              40.69741089404373
            ]
          },
          "bbox": [
            -4.593202431114733,
            40.69741089404373,
            -4.593202431114733,
            40.69741089404373
          ]
        }
      ],
      "bbox": [
        -4.593202431114733,
        40.69741089404373,
        -4.593202431114733,
        40.69741089404373
      ]
    },
    "05033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.115054608162043,
              40.82015165480701
            ]
          },
          "bbox": [
            -5.115054608162043,
            40.82015165480701,
            -5.115054608162043,
            40.82015165480701
          ]
        }
      ],
      "bbox": [
        -5.115054608162043,
        40.82015165480701,
        -5.115054608162043,
        40.82015165480701
      ]
    },
    "05034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.986906375056862,
              41.14183122301497
            ]
          },
          "bbox": [
            -4.986906375056862,
            41.14183122301497,
            -4.986906375056862,
            41.14183122301497
          ]
        }
      ],
      "bbox": [
        -4.986906375056862,
        41.14183122301497,
        -4.986906375056862,
        41.14183122301497
      ]
    },
    "05035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6446996184580565,
              40.874719112318715
            ]
          },
          "bbox": [
            -4.6446996184580565,
            40.874719112318715,
            -4.6446996184580565,
            40.874719112318715
          ]
        }
      ],
      "bbox": [
        -4.6446996184580565,
        40.874719112318715,
        -4.6446996184580565,
        40.874719112318715
      ]
    },
    "05036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.738416843857803,
              40.91573807406916
            ]
          },
          "bbox": [
            -4.738416843857803,
            40.91573807406916,
            -4.738416843857803,
            40.91573807406916
          ]
        }
      ],
      "bbox": [
        -4.738416843857803,
        40.91573807406916,
        -4.738416843857803,
        40.91573807406916
      ]
    },
    "05037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.409524507261597,
              40.28916243812673
            ]
          },
          "bbox": [
            -5.409524507261597,
            40.28916243812673,
            -5.409524507261597,
            40.28916243812673
          ]
        }
      ],
      "bbox": [
        -5.409524507261597,
        40.28916243812673,
        -5.409524507261597,
        40.28916243812673
      ]
    },
    "05038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.250159361167097,
              40.54833164370775
            ]
          },
          "bbox": [
            -5.250159361167097,
            40.54833164370775,
            -5.250159361167097,
            40.54833164370775
          ]
        }
      ],
      "bbox": [
        -5.250159361167097,
        40.54833164370775,
        -5.250159361167097,
        40.54833164370775
      ]
    },
    "05039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.927443098638634,
              40.75800441997003
            ]
          },
          "bbox": [
            -4.927443098638634,
            40.75800441997003,
            -4.927443098638634,
            40.75800441997003
          ]
        }
      ],
      "bbox": [
        -4.927443098638634,
        40.75800441997003,
        -4.927443098638634,
        40.75800441997003
      ]
    },
    "05040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.83687921954868,
              40.73351650851777
            ]
          },
          "bbox": [
            -4.83687921954868,
            40.73351650851777,
            -4.83687921954868,
            40.73351650851777
          ]
        }
      ],
      "bbox": [
        -4.83687921954868,
        40.73351650851777,
        -4.83687921954868,
        40.73351650851777
      ]
    },
    "05041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.778035960724199,
              40.388162823038
            ]
          },
          "bbox": [
            -4.778035960724199,
            40.388162823038,
            -4.778035960724199,
            40.388162823038
          ]
        }
      ],
      "bbox": [
        -4.778035960724199,
        40.388162823038,
        -4.778035960724199,
        40.388162823038
      ]
    },
    "05042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.834867897601776,
              40.93496491533248
            ]
          },
          "bbox": [
            -4.834867897601776,
            40.93496491533248,
            -4.834867897601776,
            40.93496491533248
          ]
        }
      ],
      "bbox": [
        -4.834867897601776,
        40.93496491533248,
        -4.834867897601776,
        40.93496491533248
      ]
    },
    "05043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.965821129121263,
              41.001470644763074
            ]
          },
          "bbox": [
            -4.965821129121263,
            41.001470644763074,
            -4.965821129121263,
            41.001470644763074
          ]
        }
      ],
      "bbox": [
        -4.965821129121263,
        41.001470644763074,
        -4.965821129121263,
        41.001470644763074
      ]
    },
    "05044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.20368537771567,
              40.69359310424226
            ]
          },
          "bbox": [
            -5.20368537771567,
            40.69359310424226,
            -5.20368537771567,
            40.69359310424226
          ]
        }
      ],
      "bbox": [
        -5.20368537771567,
        40.69359310424226,
        -5.20368537771567,
        40.69359310424226
      ]
    },
    "05045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.807877803093252,
              40.90199139298618
            ]
          },
          "bbox": [
            -4.807877803093252,
            40.90199139298618,
            -4.807877803093252,
            40.90199139298618
          ]
        }
      ],
      "bbox": [
        -4.807877803093252,
        40.90199139298618,
        -4.807877803093252,
        40.90199139298618
      ]
    },
    "05046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.905703423233396,
              40.996700405310726
            ]
          },
          "bbox": [
            -4.905703423233396,
            40.996700405310726,
            -4.905703423233396,
            40.996700405310726
          ]
        }
      ],
      "bbox": [
        -4.905703423233396,
        40.996700405310726,
        -4.905703423233396,
        40.996700405310726
      ]
    },
    "05047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.278741416330995,
              40.175717155846016
            ]
          },
          "bbox": [
            -5.278741416330995,
            40.175717155846016,
            -5.278741416330995,
            40.175717155846016
          ]
        }
      ],
      "bbox": [
        -5.278741416330995,
        40.175717155846016,
        -5.278741416330995,
        40.175717155846016
      ]
    },
    "05048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.960189991694773,
              40.95584799246714
            ]
          },
          "bbox": [
            -4.960189991694773,
            40.95584799246714,
            -4.960189991694773,
            40.95584799246714
          ]
        }
      ],
      "bbox": [
        -4.960189991694773,
        40.95584799246714,
        -4.960189991694773,
        40.95584799246714
      ]
    },
    "05049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.731972728247793,
              40.73668289518956
            ]
          },
          "bbox": [
            -4.731972728247793,
            40.73668289518956,
            -4.731972728247793,
            40.73668289518956
          ]
        }
      ],
      "bbox": [
        -4.731972728247793,
        40.73668289518956,
        -4.731972728247793,
        40.73668289518956
      ]
    },
    "05051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.56060531845531,
              40.35405757300106
            ]
          },
          "bbox": [
            -5.56060531845531,
            40.35405757300106,
            -5.56060531845531,
            40.35405757300106
          ]
        }
      ],
      "bbox": [
        -5.56060531845531,
        40.35405757300106,
        -5.56060531845531,
        40.35405757300106
      ]
    },
    "05052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.179367635634566,
              40.5255343003309
            ]
          },
          "bbox": [
            -5.179367635634566,
            40.5255343003309,
            -5.179367635634566,
            40.5255343003309
          ]
        }
      ],
      "bbox": [
        -5.179367635634566,
        40.5255343003309,
        -5.179367635634566,
        40.5255343003309
      ]
    },
    "05053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.820232021365234,
              40.66449157544433
            ]
          },
          "bbox": [
            -4.820232021365234,
            40.66449157544433,
            -4.820232021365234,
            40.66449157544433
          ]
        }
      ],
      "bbox": [
        -4.820232021365234,
        40.66449157544433,
        -4.820232021365234,
        40.66449157544433
      ]
    },
    "05054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.773009704163577,
              40.299746687342314
            ]
          },
          "bbox": [
            -4.773009704163577,
            40.299746687342314,
            -4.773009704163577,
            40.299746687342314
          ]
        }
      ],
      "bbox": [
        -4.773009704163577,
        40.299746687342314,
        -4.773009704163577,
        40.299746687342314
      ]
    },
    "05055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.575374356782601,
              40.331546483711044
            ]
          },
          "bbox": [
            -4.575374356782601,
            40.331546483711044,
            -4.575374356782601,
            40.331546483711044
          ]
        }
      ],
      "bbox": [
        -4.575374356782601,
        40.331546483711044,
        -4.575374356782601,
        40.331546483711044
      ]
    },
    "05056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9074165745470175,
              41.079490461075174
            ]
          },
          "bbox": [
            -4.9074165745470175,
            41.079490461075174,
            -4.9074165745470175,
            41.079490461075174
          ]
        }
      ],
      "bbox": [
        -4.9074165745470175,
        41.079490461075174,
        -4.9074165745470175,
        41.079490461075174
      ]
    },
    "05057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.4332688413064965,
              40.453063547562564
            ]
          },
          "bbox": [
            -4.4332688413064965,
            40.453063547562564,
            -4.4332688413064965,
            40.453063547562564
          ]
        }
      ],
      "bbox": [
        -4.4332688413064965,
        40.453063547562564,
        -4.4332688413064965,
        40.453063547562564
      ]
    },
    "05058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.052752159044985,
              40.45607637361547
            ]
          },
          "bbox": [
            -5.052752159044985,
            40.45607637361547,
            -5.052752159044985,
            40.45607637361547
          ]
        }
      ],
      "bbox": [
        -5.052752159044985,
        40.45607637361547,
        -5.052752159044985,
        40.45607637361547
      ]
    },
    "05059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.988610181369797,
              40.699781288711016
            ]
          },
          "bbox": [
            -4.988610181369797,
            40.699781288711016,
            -4.988610181369797,
            40.699781288711016
          ]
        }
      ],
      "bbox": [
        -4.988610181369797,
        40.699781288711016,
        -4.988610181369797,
        40.699781288711016
      ]
    },
    "05060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.018843620719389,
              40.969171659783
            ]
          },
          "bbox": [
            -5.018843620719389,
            40.969171659783,
            -5.018843620719389,
            40.969171659783
          ]
        }
      ],
      "bbox": [
        -5.018843620719389,
        40.969171659783,
        -5.018843620719389,
        40.969171659783
      ]
    },
    "05061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7588350469619956,
              40.644636722623915
            ]
          },
          "bbox": [
            -4.7588350469619956,
            40.644636722623915,
            -4.7588350469619956,
            40.644636722623915
          ]
        }
      ],
      "bbox": [
        -4.7588350469619956,
        40.644636722623915,
        -4.7588350469619956,
        40.644636722623915
      ]
    },
    "05062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.929296307374223,
              40.88483333716101
            ]
          },
          "bbox": [
            -4.929296307374223,
            40.88483333716101,
            -4.929296307374223,
            40.88483333716101
          ]
        }
      ],
      "bbox": [
        -4.929296307374223,
        40.88483333716101,
        -4.929296307374223,
        40.88483333716101
      ]
    },
    "05063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3623864888576955,
              40.55097955637722
            ]
          },
          "bbox": [
            -5.3623864888576955,
            40.55097955637722,
            -5.3623864888576955,
            40.55097955637722
          ]
        }
      ],
      "bbox": [
        -5.3623864888576955,
        40.55097955637722,
        -5.3623864888576955,
        40.55097955637722
      ]
    },
    "05064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.881883984370703,
              40.92551122276281
            ]
          },
          "bbox": [
            -4.881883984370703,
            40.92551122276281,
            -4.881883984370703,
            40.92551122276281
          ]
        }
      ],
      "bbox": [
        -4.881883984370703,
        40.92551122276281,
        -4.881883984370703,
        40.92551122276281
      ]
    },
    "05065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.972456491727082,
              40.85920323229189
            ]
          },
          "bbox": [
            -4.972456491727082,
            40.85920323229189,
            -4.972456491727082,
            40.85920323229189
          ]
        }
      ],
      "bbox": [
        -4.972456491727082,
        40.85920323229189,
        -4.972456491727082,
        40.85920323229189
      ]
    },
    "05066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.029239036653339,
              40.29593672826085
            ]
          },
          "bbox": [
            -5.029239036653339,
            40.29593672826085,
            -5.029239036653339,
            40.29593672826085
          ]
        }
      ],
      "bbox": [
        -5.029239036653339,
        40.29593672826085,
        -5.029239036653339,
        40.29593672826085
      ]
    },
    "05067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.94932249646152,
              40.72081369475627
            ]
          },
          "bbox": [
            -4.94932249646152,
            40.72081369475627,
            -4.94932249646152,
            40.72081369475627
          ]
        }
      ],
      "bbox": [
        -4.94932249646152,
        40.72081369475627,
        -4.94932249646152,
        40.72081369475627
      ]
    },
    "05069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.85351793934993,
              40.96448404996228
            ]
          },
          "bbox": [
            -4.85351793934993,
            40.96448404996228,
            -4.85351793934993,
            40.96448404996228
          ]
        }
      ],
      "bbox": [
        -4.85351793934993,
        40.96448404996228,
        -4.85351793934993,
        40.96448404996228
      ]
    },
    "05070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.802053088089883,
              41.084783534594415
            ]
          },
          "bbox": [
            -4.802053088089883,
            41.084783534594415,
            -4.802053088089883,
            41.084783534594415
          ]
        }
      ],
      "bbox": [
        -4.802053088089883,
        41.084783534594415,
        -4.802053088089883,
        41.084783534594415
      ]
    },
    "05072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.666801804967929,
              41.01670910631947
            ]
          },
          "bbox": [
            -4.666801804967929,
            41.01670910631947,
            -4.666801804967929,
            41.01670910631947
          ]
        }
      ],
      "bbox": [
        -4.666801804967929,
        41.01670910631947,
        -4.666801804967929,
        41.01670910631947
      ]
    },
    "05073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.083647408881973,
              40.94193399371593
            ]
          },
          "bbox": [
            -5.083647408881973,
            40.94193399371593,
            -5.083647408881973,
            40.94193399371593
          ]
        }
      ],
      "bbox": [
        -5.083647408881973,
        40.94193399371593,
        -5.083647408881973,
        40.94193399371593
      ]
    },
    "05074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.958851968441354,
              40.925064626743975
            ]
          },
          "bbox": [
            -4.958851968441354,
            40.925064626743975,
            -4.958851968441354,
            40.925064626743975
          ]
        }
      ],
      "bbox": [
        -4.958851968441354,
        40.925064626743975,
        -4.958851968441354,
        40.925064626743975
      ]
    },
    "05075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.657241373087498,
              40.2359918115719
            ]
          },
          "bbox": [
            -4.657241373087498,
            40.2359918115719,
            -4.657241373087498,
            40.2359918115719
          ]
        }
      ],
      "bbox": [
        -4.657241373087498,
        40.2359918115719,
        -4.657241373087498,
        40.2359918115719
      ]
    },
    "05076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.751096986265614,
              40.620162574731204
            ]
          },
          "bbox": [
            -4.751096986265614,
            40.620162574731204,
            -4.751096986265614,
            40.620162574731204
          ]
        }
      ],
      "bbox": [
        -4.751096986265614,
        40.620162574731204,
        -4.751096986265614,
        40.620162574731204
      ]
    },
    "05077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.896413792785332,
              40.97353842477706
            ]
          },
          "bbox": [
            -4.896413792785332,
            40.97353842477706,
            -4.896413792785332,
            40.97353842477706
          ]
        }
      ],
      "bbox": [
        -4.896413792785332,
        40.97353842477706,
        -4.896413792785332,
        40.97353842477706
      ]
    },
    "05078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.911837266676858,
              41.024599264893986
            ]
          },
          "bbox": [
            -4.911837266676858,
            41.024599264893986,
            -4.911837266676858,
            41.024599264893986
          ]
        }
      ],
      "bbox": [
        -4.911837266676858,
        41.024599264893986,
        -4.911837266676858,
        41.024599264893986
      ]
    },
    "05079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.911860554267185,
              40.70201759007967
            ]
          },
          "bbox": [
            -4.911860554267185,
            40.70201759007967,
            -4.911860554267185,
            40.70201759007967
          ]
        }
      ],
      "bbox": [
        -4.911860554267185,
        40.70201759007967,
        -4.911860554267185,
        40.70201759007967
      ]
    },
    "05080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.144037459624467,
              40.704122512091494
            ]
          },
          "bbox": [
            -5.144037459624467,
            40.704122512091494,
            -5.144037459624467,
            40.704122512091494
          ]
        }
      ],
      "bbox": [
        -5.144037459624467,
        40.704122512091494,
        -5.144037459624467,
        40.704122512091494
      ]
    },
    "05081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.116265222798193,
              40.45358981876192
            ]
          },
          "bbox": [
            -5.116265222798193,
            40.45358981876192,
            -5.116265222798193,
            40.45358981876192
          ]
        }
      ],
      "bbox": [
        -5.116265222798193,
        40.45358981876192,
        -5.116265222798193,
        40.45358981876192
      ]
    },
    "05082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8695816729308214,
              40.27297235073361
            ]
          },
          "bbox": [
            -4.8695816729308214,
            40.27297235073361,
            -4.8695816729308214,
            40.27297235073361
          ]
        }
      ],
      "bbox": [
        -4.8695816729308214,
        40.27297235073361,
        -4.8695816729308214,
        40.27297235073361
      ]
    },
    "05083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.778049006781894,
              40.58602982533832
            ]
          },
          "bbox": [
            -4.778049006781894,
            40.58602982533832,
            -4.778049006781894,
            40.58602982533832
          ]
        }
      ],
      "bbox": [
        -4.778049006781894,
        40.58602982533832,
        -4.778049006781894,
        40.58602982533832
      ]
    },
    "05084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.59441506877681,
              40.415842517988104
            ]
          },
          "bbox": [
            -5.59441506877681,
            40.415842517988104,
            -5.59441506877681,
            40.415842517988104
          ]
        }
      ],
      "bbox": [
        -5.59441506877681,
        40.415842517988104,
        -5.59441506877681,
        40.415842517988104
      ]
    },
    "05085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.592875501357711,
              40.280005242243206
            ]
          },
          "bbox": [
            -5.592875501357711,
            40.280005242243206,
            -5.592875501357711,
            40.280005242243206
          ]
        }
      ],
      "bbox": [
        -5.592875501357711,
        40.280005242243206,
        -5.592875501357711,
        40.280005242243206
      ]
    },
    "05086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.127583601902792,
              40.87119915832002
            ]
          },
          "bbox": [
            -5.127583601902792,
            40.87119915832002,
            -5.127583601902792,
            40.87119915832002
          ]
        }
      ],
      "bbox": [
        -5.127583601902792,
        40.87119915832002,
        -5.127583601902792,
        40.87119915832002
      ]
    },
    "05087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.729300540328047,
              40.82767295896879
            ]
          },
          "bbox": [
            -4.729300540328047,
            40.82767295896879,
            -4.729300540328047,
            40.82767295896879
          ]
        }
      ],
      "bbox": [
        -4.729300540328047,
        40.82767295896879,
        -4.729300540328047,
        40.82767295896879
      ]
    },
    "05088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.968733265408225,
              40.76198624795317
            ]
          },
          "bbox": [
            -4.968733265408225,
            40.76198624795317,
            -4.968733265408225,
            40.76198624795317
          ]
        }
      ],
      "bbox": [
        -4.968733265408225,
        40.76198624795317,
        -4.968733265408225,
        40.76198624795317
      ]
    },
    "05089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.144282818449931,
              40.21916513415585
            ]
          },
          "bbox": [
            -5.144282818449931,
            40.21916513415585,
            -5.144282818449931,
            40.21916513415585
          ]
        }
      ],
      "bbox": [
        -5.144282818449931,
        40.21916513415585,
        -5.144282818449931,
        40.21916513415585
      ]
    },
    "05090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.652599666261155,
              40.96792604047479
            ]
          },
          "bbox": [
            -4.652599666261155,
            40.96792604047479,
            -4.652599666261155,
            40.96792604047479
          ]
        }
      ],
      "bbox": [
        -4.652599666261155,
        40.96792604047479,
        -4.652599666261155,
        40.96792604047479
      ]
    },
    "05092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.718586086615348,
              40.85490124263636
            ]
          },
          "bbox": [
            -4.718586086615348,
            40.85490124263636,
            -4.718586086615348,
            40.85490124263636
          ]
        }
      ],
      "bbox": [
        -4.718586086615348,
        40.85490124263636,
        -4.718586086615348,
        40.85490124263636
      ]
    },
    "05093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.540387416299176,
              40.58651270793732
            ]
          },
          "bbox": [
            -4.540387416299176,
            40.58651270793732,
            -4.540387416299176,
            40.58651270793732
          ]
        }
      ],
      "bbox": [
        -4.540387416299176,
        40.58651270793732,
        -4.540387416299176,
        40.58651270793732
      ]
    },
    "05094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.043839859934223,
              40.80364521228486
            ]
          },
          "bbox": [
            -5.043839859934223,
            40.80364521228486,
            -5.043839859934223,
            40.80364521228486
          ]
        }
      ],
      "bbox": [
        -5.043839859934223,
        40.80364521228486,
        -5.043839859934223,
        40.80364521228486
      ]
    },
    "05095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.60814469022874,
              40.228700958727636
            ]
          },
          "bbox": [
            -4.60814469022874,
            40.228700958727636,
            -4.60814469022874,
            40.228700958727636
          ]
        }
      ],
      "bbox": [
        -4.60814469022874,
        40.228700958727636,
        -4.60814469022874,
        40.228700958727636
      ]
    },
    "05096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.968381003636706,
              40.516945935157615
            ]
          },
          "bbox": [
            -4.968381003636706,
            40.516945935157615,
            -4.968381003636706,
            40.516945935157615
          ]
        }
      ],
      "bbox": [
        -4.968381003636706,
        40.516945935157615,
        -4.968381003636706,
        40.516945935157615
      ]
    },
    "05097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.470813224845664,
              40.433000711479266
            ]
          },
          "bbox": [
            -5.470813224845664,
            40.433000711479266,
            -5.470813224845664,
            40.433000711479266
          ]
        }
      ],
      "bbox": [
        -5.470813224845664,
        40.433000711479266,
        -5.470813224845664,
        40.433000711479266
      ]
    },
    "05099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.110148826537915,
              41.07013705007518
            ]
          },
          "bbox": [
            -5.110148826537915,
            41.07013705007518,
            -5.110148826537915,
            41.07013705007518
          ]
        }
      ],
      "bbox": [
        -5.110148826537915,
        41.07013705007518,
        -5.110148826537915,
        41.07013705007518
      ]
    },
    "05100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.127364713296269,
              40.26167619346639
            ]
          },
          "bbox": [
            -5.127364713296269,
            40.26167619346639,
            -5.127364713296269,
            40.26167619346639
          ]
        }
      ],
      "bbox": [
        -5.127364713296269,
        40.26167619346639,
        -5.127364713296269,
        40.26167619346639
      ]
    },
    "05101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.987279700782398,
              40.40431294592485
            ]
          },
          "bbox": [
            -4.987279700782398,
            40.40431294592485,
            -4.987279700782398,
            40.40431294592485
          ]
        }
      ],
      "bbox": [
        -4.987279700782398,
        40.40431294592485,
        -4.987279700782398,
        40.40431294592485
      ]
    },
    "05102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.397795958445288,
              40.508414493727415
            ]
          },
          "bbox": [
            -4.397795958445288,
            40.508414493727415,
            -4.397795958445288,
            40.508414493727415
          ]
        }
      ],
      "bbox": [
        -4.397795958445288,
        40.508414493727415,
        -4.397795958445288,
        40.508414493727415
      ]
    },
    "05103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.409636063056884,
              40.45972691504692
            ]
          },
          "bbox": [
            -5.409636063056884,
            40.45972691504692,
            -5.409636063056884,
            40.45972691504692
          ]
        }
      ],
      "bbox": [
        -5.409636063056884,
        40.45972691504692,
        -5.409636063056884,
        40.45972691504692
      ]
    },
    "05104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.203769146271995,
              40.36929937271149
            ]
          },
          "bbox": [
            -5.203769146271995,
            40.36929937271149,
            -5.203769146271995,
            40.36929937271149
          ]
        }
      ],
      "bbox": [
        -5.203769146271995,
        40.36929937271149,
        -5.203769146271995,
        40.36929937271149
      ]
    },
    "05105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.175214474916209,
              40.325571018564936
            ]
          },
          "bbox": [
            -5.175214474916209,
            40.325571018564936,
            -5.175214474916209,
            40.325571018564936
          ]
        }
      ],
      "bbox": [
        -5.175214474916209,
        40.325571018564936,
        -5.175214474916209,
        40.325571018564936
      ]
    },
    "05106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0668589675673115,
              40.39118283084712
            ]
          },
          "bbox": [
            -5.0668589675673115,
            40.39118283084712,
            -5.0668589675673115,
            40.39118283084712
          ]
        }
      ],
      "bbox": [
        -5.0668589675673115,
        40.39118283084712,
        -5.0668589675673115,
        40.39118283084712
      ]
    },
    "05107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1055304971191875,
              40.680457917845665
            ]
          },
          "bbox": [
            -5.1055304971191875,
            40.680457917845665,
            -5.1055304971191875,
            40.680457917845665
          ]
        }
      ],
      "bbox": [
        -5.1055304971191875,
        40.680457917845665,
        -5.1055304971191875,
        40.680457917845665
      ]
    },
    "05108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.565873411749865,
              40.41146173909238
            ]
          },
          "bbox": [
            -5.565873411749865,
            40.41146173909238,
            -5.565873411749865,
            40.41146173909238
          ]
        }
      ],
      "bbox": [
        -5.565873411749865,
        40.41146173909238,
        -5.565873411749865,
        40.41146173909238
      ]
    },
    "05109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.853604468124562,
              41.00308749455113
            ]
          },
          "bbox": [
            -4.853604468124562,
            41.00308749455113,
            -4.853604468124562,
            41.00308749455113
          ]
        }
      ],
      "bbox": [
        -4.853604468124562,
        41.00308749455113,
        -4.853604468124562,
        41.00308749455113
      ]
    },
    "05110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.938831834217961,
              40.191966561919955
            ]
          },
          "bbox": [
            -4.938831834217961,
            40.191966561919955,
            -4.938831834217961,
            40.191966561919955
          ]
        }
      ],
      "bbox": [
        -4.938831834217961,
        40.191966561919955,
        -4.938831834217961,
        40.191966561919955
      ]
    },
    "05112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.534870129463332,
              40.397222770214626
            ]
          },
          "bbox": [
            -5.534870129463332,
            40.397222770214626,
            -5.534870129463332,
            40.397222770214626
          ]
        }
      ],
      "bbox": [
        -5.534870129463332,
        40.397222770214626,
        -5.534870129463332,
        40.397222770214626
      ]
    },
    "05113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.474161483692927,
              40.33679422687522
            ]
          },
          "bbox": [
            -5.474161483692927,
            40.33679422687522,
            -5.474161483692927,
            40.33679422687522
          ]
        }
      ],
      "bbox": [
        -5.474161483692927,
        40.33679422687522,
        -5.474161483692927,
        40.33679422687522
      ]
    },
    "05114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.030819656982178,
              41.10127505518376
            ]
          },
          "bbox": [
            -5.030819656982178,
            41.10127505518376,
            -5.030819656982178,
            41.10127505518376
          ]
        }
      ],
      "bbox": [
        -5.030819656982178,
        41.10127505518376,
        -5.030819656982178,
        41.10127505518376
      ]
    },
    "05115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.5565132824109655,
              40.815821312636146
            ]
          },
          "bbox": [
            -4.5565132824109655,
            40.815821312636146,
            -4.5565132824109655,
            40.815821312636146
          ]
        }
      ],
      "bbox": [
        -4.5565132824109655,
        40.815821312636146,
        -4.5565132824109655,
        40.815821312636146
      ]
    },
    "05116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.351244427881378,
              40.517390261030734
            ]
          },
          "bbox": [
            -5.351244427881378,
            40.517390261030734,
            -5.351244427881378,
            40.517390261030734
          ]
        }
      ],
      "bbox": [
        -5.351244427881378,
        40.517390261030734,
        -5.351244427881378,
        40.517390261030734
      ]
    },
    "05117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.023068057302296,
              41.008745278521225
            ]
          },
          "bbox": [
            -5.023068057302296,
            41.008745278521225,
            -5.023068057302296,
            41.008745278521225
          ]
        }
      ],
      "bbox": [
        -5.023068057302296,
        41.008745278521225,
        -5.023068057302296,
        41.008745278521225
      ]
    },
    "05118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.149039415294192,
              40.795167032093715
            ]
          },
          "bbox": [
            -5.149039415294192,
            40.795167032093715,
            -5.149039415294192,
            40.795167032093715
          ]
        }
      ],
      "bbox": [
        -5.149039415294192,
        40.795167032093715,
        -5.149039415294192,
        40.795167032093715
      ]
    },
    "05119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.072677397439701,
              40.670784669055806
            ]
          },
          "bbox": [
            -5.072677397439701,
            40.670784669055806,
            -5.072677397439701,
            40.670784669055806
          ]
        }
      ],
      "bbox": [
        -5.072677397439701,
        40.670784669055806,
        -5.072677397439701,
        40.670784669055806
      ]
    },
    "05120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.826056473337951,
              40.711747058726225
            ]
          },
          "bbox": [
            -4.826056473337951,
            40.711747058726225,
            -4.826056473337951,
            40.711747058726225
          ]
        }
      ],
      "bbox": [
        -4.826056473337951,
        40.711747058726225,
        -4.826056473337951,
        40.711747058726225
      ]
    },
    "05121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.781284449559834,
              40.681203884299265
            ]
          },
          "bbox": [
            -4.781284449559834,
            40.681203884299265,
            -4.781284449559834,
            40.681203884299265
          ]
        }
      ],
      "bbox": [
        -4.781284449559834,
        40.681203884299265,
        -4.781284449559834,
        40.681203884299265
      ]
    },
    "05122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.357057539499176,
              40.628636435645795
            ]
          },
          "bbox": [
            -5.357057539499176,
            40.628636435645795,
            -5.357057539499176,
            40.628636435645795
          ]
        }
      ],
      "bbox": [
        -5.357057539499176,
        40.628636435645795,
        -5.357057539499176,
        40.628636435645795
      ]
    },
    "05123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.555905655686911,
              40.70726396580947
            ]
          },
          "bbox": [
            -4.555905655686911,
            40.70726396580947,
            -4.555905655686911,
            40.70726396580947
          ]
        }
      ],
      "bbox": [
        -4.555905655686911,
        40.70726396580947,
        -4.555905655686911,
        40.70726396580947
      ]
    },
    "05124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.603450840968513,
              40.45126077495418
            ]
          },
          "bbox": [
            -5.603450840968513,
            40.45126077495418,
            -5.603450840968513,
            40.45126077495418
          ]
        }
      ],
      "bbox": [
        -5.603450840968513,
        40.45126077495418,
        -5.603450840968513,
        40.45126077495418
      ]
    },
    "05125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.01383642481489,
              40.49941496388111
            ]
          },
          "bbox": [
            -5.01383642481489,
            40.49941496388111,
            -5.01383642481489,
            40.49941496388111
          ]
        }
      ],
      "bbox": [
        -5.01383642481489,
        40.49941496388111,
        -5.01383642481489,
        40.49941496388111
      ]
    },
    "05126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.308231255559826,
              40.51507392410502
            ]
          },
          "bbox": [
            -5.308231255559826,
            40.51507392410502,
            -5.308231255559826,
            40.51507392410502
          ]
        }
      ],
      "bbox": [
        -5.308231255559826,
        40.51507392410502,
        -5.308231255559826,
        40.51507392410502
      ]
    },
    "05127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.831938835033595,
              40.28498468889651
            ]
          },
          "bbox": [
            -4.831938835033595,
            40.28498468889651,
            -4.831938835033595,
            40.28498468889651
          ]
        }
      ],
      "bbox": [
        -4.831938835033595,
        40.28498468889651,
        -4.831938835033595,
        40.28498468889651
      ]
    },
    "05128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.678948039149961,
              40.76503686015668
            ]
          },
          "bbox": [
            -4.678948039149961,
            40.76503686015668,
            -4.678948039149961,
            40.76503686015668
          ]
        }
      ],
      "bbox": [
        -4.678948039149961,
        40.76503686015668,
        -4.678948039149961,
        40.76503686015668
      ]
    },
    "05129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.400765483300126,
              40.55642719772108
            ]
          },
          "bbox": [
            -5.400765483300126,
            40.55642719772108,
            -5.400765483300126,
            40.55642719772108
          ]
        }
      ],
      "bbox": [
        -5.400765483300126,
        40.55642719772108,
        -5.400765483300126,
        40.55642719772108
      ]
    },
    "05130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.822782809237211,
              40.55570511482369
            ]
          },
          "bbox": [
            -4.822782809237211,
            40.55570511482369,
            -4.822782809237211,
            40.55570511482369
          ]
        }
      ],
      "bbox": [
        -4.822782809237211,
        40.55570511482369,
        -4.822782809237211,
        40.55570511482369
      ]
    },
    "05131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.085586936462932,
              40.7362615194224
            ]
          },
          "bbox": [
            -5.085586936462932,
            40.7362615194224,
            -5.085586936462932,
            40.7362615194224
          ]
        }
      ],
      "bbox": [
        -5.085586936462932,
        40.7362615194224,
        -5.085586936462932,
        40.7362615194224
      ]
    },
    "05132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.007794274984497,
              40.21118919186601
            ]
          },
          "bbox": [
            -5.007794274984497,
            40.21118919186601,
            -5.007794274984497,
            40.21118919186601
          ]
        }
      ],
      "bbox": [
        -5.007794274984497,
        40.21118919186601,
        -5.007794274984497,
        40.21118919186601
      ]
    },
    "05133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7892361302033795,
              40.76960625988853
            ]
          },
          "bbox": [
            -4.7892361302033795,
            40.76960625988853,
            -4.7892361302033795,
            40.76960625988853
          ]
        }
      ],
      "bbox": [
        -4.7892361302033795,
        40.76960625988853,
        -4.7892361302033795,
        40.76960625988853
      ]
    },
    "05134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9440858389259965,
              41.115158573318645
            ]
          },
          "bbox": [
            -4.9440858389259965,
            41.115158573318645,
            -4.9440858389259965,
            41.115158573318645
          ]
        }
      ],
      "bbox": [
        -4.9440858389259965,
        41.115158573318645,
        -4.9440858389259965,
        41.115158573318645
      ]
    },
    "05135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.021391282370937,
              40.59728830312478
            ]
          },
          "bbox": [
            -5.021391282370937,
            40.59728830312478,
            -5.021391282370937,
            40.59728830312478
          ]
        }
      ],
      "bbox": [
        -5.021391282370937,
        40.59728830312478,
        -5.021391282370937,
        40.59728830312478
      ]
    },
    "05136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0359834382788495,
              40.70823325417075
            ]
          },
          "bbox": [
            -5.0359834382788495,
            40.70823325417075,
            -5.0359834382788495,
            40.70823325417075
          ]
        }
      ],
      "bbox": [
        -5.0359834382788495,
        40.70823325417075,
        -5.0359834382788495,
        40.70823325417075
      ]
    },
    "05138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.90470529610654,
              40.59746704145634
            ]
          },
          "bbox": [
            -4.90470529610654,
            40.59746704145634,
            -4.90470529610654,
            40.59746704145634
          ]
        }
      ],
      "bbox": [
        -4.90470529610654,
        40.59746704145634,
        -4.90470529610654,
        40.59746704145634
      ]
    },
    "05139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.940168335237642,
              40.815607678061724
            ]
          },
          "bbox": [
            -4.940168335237642,
            40.815607678061724,
            -4.940168335237642,
            40.815607678061724
          ]
        }
      ],
      "bbox": [
        -4.940168335237642,
        40.815607678061724,
        -4.940168335237642,
        40.815607678061724
      ]
    },
    "05140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.891407164505464,
              40.8680046430941
            ]
          },
          "bbox": [
            -4.891407164505464,
            40.8680046430941,
            -4.891407164505464,
            40.8680046430941
          ]
        }
      ],
      "bbox": [
        -4.891407164505464,
        40.8680046430941,
        -4.891407164505464,
        40.8680046430941
      ]
    },
    "05141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.816891938526332,
              40.636160094449785
            ]
          },
          "bbox": [
            -4.816891938526332,
            40.636160094449785,
            -4.816891938526332,
            40.636160094449785
          ]
        }
      ],
      "bbox": [
        -4.816891938526332,
        40.636160094449785,
        -4.816891938526332,
        40.636160094449785
      ]
    },
    "05142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.032155590590356,
              40.922550252773625
            ]
          },
          "bbox": [
            -5.032155590590356,
            40.922550252773625,
            -5.032155590590356,
            40.922550252773625
          ]
        }
      ],
      "bbox": [
        -5.032155590590356,
        40.922550252773625,
        -5.032155590590356,
        40.922550252773625
      ]
    },
    "05143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.032349105526221,
              40.54072690116312
            ]
          },
          "bbox": [
            -5.032349105526221,
            40.54072690116312,
            -5.032349105526221,
            40.54072690116312
          ]
        }
      ],
      "bbox": [
        -5.032349105526221,
        40.54072690116312,
        -5.032349105526221,
        40.54072690116312
      ]
    },
    "05144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.470175979218157,
              40.57381975575463
            ]
          },
          "bbox": [
            -5.470175979218157,
            40.57381975575463,
            -5.470175979218157,
            40.57381975575463
          ]
        }
      ],
      "bbox": [
        -5.470175979218157,
        40.57381975575463,
        -5.470175979218157,
        40.57381975575463
      ]
    },
    "05145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.958592564024001,
              40.6743902901881
            ]
          },
          "bbox": [
            -4.958592564024001,
            40.6743902901881,
            -4.958592564024001,
            40.6743902901881
          ]
        }
      ],
      "bbox": [
        -4.958592564024001,
        40.6743902901881,
        -4.958592564024001,
        40.6743902901881
      ]
    },
    "05147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.061650561610381,
              40.849751440930326
            ]
          },
          "bbox": [
            -5.061650561610381,
            40.849751440930326,
            -5.061650561610381,
            40.849751440930326
          ]
        }
      ],
      "bbox": [
        -5.061650561610381,
        40.849751440930326,
        -5.061650561610381,
        40.849751440930326
      ]
    },
    "05148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.995028763130057,
              40.53722997521788
            ]
          },
          "bbox": [
            -4.995028763130057,
            40.53722997521788,
            -4.995028763130057,
            40.53722997521788
          ]
        }
      ],
      "bbox": [
        -4.995028763130057,
        40.53722997521788,
        -4.995028763130057,
        40.53722997521788
      ]
    },
    "05149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.872218826260163,
              40.880693191964674
            ]
          },
          "bbox": [
            -4.872218826260163,
            40.880693191964674,
            -4.872218826260163,
            40.880693191964674
          ]
        }
      ],
      "bbox": [
        -4.872218826260163,
        40.880693191964674,
        -4.872218826260163,
        40.880693191964674
      ]
    },
    "05151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.162567416864166,
              40.48782057605561
            ]
          },
          "bbox": [
            -5.162567416864166,
            40.48782057605561,
            -5.162567416864166,
            40.48782057605561
          ]
        }
      ],
      "bbox": [
        -5.162567416864166,
        40.48782057605561,
        -5.162567416864166,
        40.48782057605561
      ]
    },
    "05152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.765272973043806,
              40.99670467265345
            ]
          },
          "bbox": [
            -4.765272973043806,
            40.99670467265345,
            -4.765272973043806,
            40.99670467265345
          ]
        }
      ],
      "bbox": [
        -4.765272973043806,
        40.99670467265345,
        -4.765272973043806,
        40.99670467265345
      ]
    },
    "05153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.557068456466385,
              40.26947803097983
            ]
          },
          "bbox": [
            -5.557068456466385,
            40.26947803097983,
            -5.557068456466385,
            40.26947803097983
          ]
        }
      ],
      "bbox": [
        -5.557068456466385,
        40.26947803097983,
        -5.557068456466385,
        40.26947803097983
      ]
    },
    "05154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.089170899209609,
              40.420781194566956
            ]
          },
          "bbox": [
            -5.089170899209609,
            40.420781194566956,
            -5.089170899209609,
            40.420781194566956
          ]
        }
      ],
      "bbox": [
        -5.089170899209609,
        40.420781194566956,
        -5.089170899209609,
        40.420781194566956
      ]
    },
    "05155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.260216477841124,
              40.461695411764055
            ]
          },
          "bbox": [
            -5.260216477841124,
            40.461695411764055,
            -5.260216477841124,
            40.461695411764055
          ]
        }
      ],
      "bbox": [
        -5.260216477841124,
        40.461695411764055,
        -5.260216477841124,
        40.461695411764055
      ]
    },
    "05156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.489235497079085,
              40.33917694085503
            ]
          },
          "bbox": [
            -4.489235497079085,
            40.33917694085503,
            -4.489235497079085,
            40.33917694085503
          ]
        }
      ],
      "bbox": [
        -4.489235497079085,
        40.33917694085503,
        -4.489235497079085,
        40.33917694085503
      ]
    },
    "05157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.946709719270327,
              40.4551417728592
            ]
          },
          "bbox": [
            -4.946709719270327,
            40.4551417728592,
            -4.946709719270327,
            40.4551417728592
          ]
        }
      ],
      "bbox": [
        -4.946709719270327,
        40.4551417728592,
        -4.946709719270327,
        40.4551417728592
      ]
    },
    "05158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7475072645812295,
              40.46256592763447
            ]
          },
          "bbox": [
            -4.7475072645812295,
            40.46256592763447,
            -4.7475072645812295,
            40.46256592763447
          ]
        }
      ],
      "bbox": [
        -4.7475072645812295,
        40.46256592763447,
        -4.7475072645812295,
        40.46256592763447
      ]
    },
    "05159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.504877922169623,
              40.243027878010295
            ]
          },
          "bbox": [
            -5.504877922169623,
            40.243027878010295,
            -5.504877922169623,
            40.243027878010295
          ]
        }
      ],
      "bbox": [
        -5.504877922169623,
        40.243027878010295,
        -5.504877922169623,
        40.243027878010295
      ]
    },
    "05160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.923573549135321,
              40.39049918457545
            ]
          },
          "bbox": [
            -4.923573549135321,
            40.39049918457545,
            -4.923573549135321,
            40.39049918457545
          ]
        }
      ],
      "bbox": [
        -4.923573549135321,
        40.39049918457545,
        -4.923573549135321,
        40.39049918457545
      ]
    },
    "05161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.443414051643389,
              40.595855672433196
            ]
          },
          "bbox": [
            -4.443414051643389,
            40.595855672433196,
            -4.443414051643389,
            40.595855672433196
          ]
        }
      ],
      "bbox": [
        -4.443414051643389,
        40.595855672433196,
        -4.443414051643389,
        40.595855672433196
      ]
    },
    "05162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.277328495761811,
              40.32498572196357
            ]
          },
          "bbox": [
            -5.277328495761811,
            40.32498572196357,
            -5.277328495761811,
            40.32498572196357
          ]
        }
      ],
      "bbox": [
        -5.277328495761811,
        40.32498572196357,
        -5.277328495761811,
        40.32498572196357
      ]
    },
    "05163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.692682947270707,
              40.39517449055804
            ]
          },
          "bbox": [
            -4.692682947270707,
            40.39517449055804,
            -4.692682947270707,
            40.39517449055804
          ]
        }
      ],
      "bbox": [
        -4.692682947270707,
        40.39517449055804,
        -4.692682947270707,
        40.39517449055804
      ]
    },
    "05164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.919392660038544,
              40.424077875189894
            ]
          },
          "bbox": [
            -4.919392660038544,
            40.424077875189894,
            -4.919392660038544,
            40.424077875189894
          ]
        }
      ],
      "bbox": [
        -4.919392660038544,
        40.424077875189894,
        -4.919392660038544,
        40.424077875189894
      ]
    },
    "05165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.116912227479488,
              40.34943479410622
            ]
          },
          "bbox": [
            -5.116912227479488,
            40.34943479410622,
            -5.116912227479488,
            40.34943479410622
          ]
        }
      ],
      "bbox": [
        -5.116912227479488,
        40.34943479410622,
        -5.116912227479488,
        40.34943479410622
      ]
    },
    "05166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.836191355403766,
              40.43998589262081
            ]
          },
          "bbox": [
            -4.836191355403766,
            40.43998589262081,
            -4.836191355403766,
            40.43998589262081
          ]
        }
      ],
      "bbox": [
        -4.836191355403766,
        40.43998589262081,
        -4.836191355403766,
        40.43998589262081
      ]
    },
    "05167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.873224423326168,
              40.358399745222485
            ]
          },
          "bbox": [
            -4.873224423326168,
            40.358399745222485,
            -4.873224423326168,
            40.358399745222485
          ]
        }
      ],
      "bbox": [
        -4.873224423326168,
        40.358399745222485,
        -4.873224423326168,
        40.358399745222485
      ]
    },
    "05168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.349211590226153,
              40.59322723426295
            ]
          },
          "bbox": [
            -4.349211590226153,
            40.59322723426295,
            -4.349211590226153,
            40.59322723426295
          ]
        }
      ],
      "bbox": [
        -4.349211590226153,
        40.59322723426295,
        -4.349211590226153,
        40.59322723426295
      ]
    },
    "05169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8558809851907005,
              40.4100198466537
            ]
          },
          "bbox": [
            -4.8558809851907005,
            40.4100198466537,
            -4.8558809851907005,
            40.4100198466537
          ]
        }
      ],
      "bbox": [
        -4.8558809851907005,
        40.4100198466537,
        -4.8558809851907005,
        40.4100198466537
      ]
    },
    "05170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.536808647018625,
              40.321856929242955
            ]
          },
          "bbox": [
            -5.536808647018625,
            40.321856929242955,
            -5.536808647018625,
            40.321856929242955
          ]
        }
      ],
      "bbox": [
        -5.536808647018625,
        40.321856929242955,
        -5.536808647018625,
        40.321856929242955
      ]
    },
    "05171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.652488587084462,
              40.42873739014554
            ]
          },
          "bbox": [
            -5.652488587084462,
            40.42873739014554,
            -5.652488587084462,
            40.42873739014554
          ]
        }
      ],
      "bbox": [
        -5.652488587084462,
        40.42873739014554,
        -5.652488587084462,
        40.42873739014554
      ]
    },
    "05172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.843798534414466,
              40.59222308465059
            ]
          },
          "bbox": [
            -4.843798534414466,
            40.59222308465059,
            -4.843798534414466,
            40.59222308465059
          ]
        }
      ],
      "bbox": [
        -4.843798534414466,
        40.59222308465059,
        -4.843798534414466,
        40.59222308465059
      ]
    },
    "05173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.512266861657388,
              40.72611356575542
            ]
          },
          "bbox": [
            -4.512266861657388,
            40.72611356575542,
            -4.512266861657388,
            40.72611356575542
          ]
        }
      ],
      "bbox": [
        -4.512266861657388,
        40.72611356575542,
        -4.512266861657388,
        40.72611356575542
      ]
    },
    "05174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.658500434619131,
              40.9928267825335
            ]
          },
          "bbox": [
            -4.658500434619131,
            40.9928267825335,
            -4.658500434619131,
            40.9928267825335
          ]
        }
      ],
      "bbox": [
        -4.658500434619131,
        40.9928267825335,
        -4.658500434619131,
        40.9928267825335
      ]
    },
    "05175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.772220644489476,
              40.85029729441675
            ]
          },
          "bbox": [
            -4.772220644489476,
            40.85029729441675,
            -4.772220644489476,
            40.85029729441675
          ]
        }
      ],
      "bbox": [
        -4.772220644489476,
        40.85029729441675,
        -4.772220644489476,
        40.85029729441675
      ]
    },
    "05176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.864922415394065,
              40.63084651692816
            ]
          },
          "bbox": [
            -4.864922415394065,
            40.63084651692816,
            -4.864922415394065,
            40.63084651692816
          ]
        }
      ],
      "bbox": [
        -4.864922415394065,
        40.63084651692816,
        -4.864922415394065,
        40.63084651692816
      ]
    },
    "05177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.645969132443234,
              40.919099311833676
            ]
          },
          "bbox": [
            -4.645969132443234,
            40.919099311833676,
            -4.645969132443234,
            40.919099311833676
          ]
        }
      ],
      "bbox": [
        -4.645969132443234,
        40.919099311833676,
        -4.645969132443234,
        40.919099311833676
      ]
    },
    "05178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.763480807143956,
              41.111357879652765
            ]
          },
          "bbox": [
            -4.763480807143956,
            41.111357879652765,
            -4.763480807143956,
            41.111357879652765
          ]
        }
      ],
      "bbox": [
        -4.763480807143956,
        41.111357879652765,
        -4.763480807143956,
        41.111357879652765
      ]
    },
    "05179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.841643728319324,
              40.8764624836464
            ]
          },
          "bbox": [
            -4.841643728319324,
            40.8764624836464,
            -4.841643728319324,
            40.8764624836464
          ]
        }
      ],
      "bbox": [
        -4.841643728319324,
        40.8764624836464,
        -4.841643728319324,
        40.8764624836464
      ]
    },
    "05180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.990461124209045,
              40.79318482075443
            ]
          },
          "bbox": [
            -4.990461124209045,
            40.79318482075443,
            -4.990461124209045,
            40.79318482075443
          ]
        }
      ],
      "bbox": [
        -4.990461124209045,
        40.79318482075443,
        -4.990461124209045,
        40.79318482075443
      ]
    },
    "05181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.267128738005441,
              40.6435587703453
            ]
          },
          "bbox": [
            -5.267128738005441,
            40.6435587703453,
            -5.267128738005441,
            40.6435587703453
          ]
        }
      ],
      "bbox": [
        -5.267128738005441,
        40.6435587703453,
        -5.267128738005441,
        40.6435587703453
      ]
    },
    "05182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.908955897538764,
              40.243316973957114
            ]
          },
          "bbox": [
            -4.908955897538764,
            40.243316973957114,
            -4.908955897538764,
            40.243316973957114
          ]
        }
      ],
      "bbox": [
        -4.908955897538764,
        40.243316973957114,
        -4.908955897538764,
        40.243316973957114
      ]
    },
    "05183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7843180355001165,
              40.937043747760605
            ]
          },
          "bbox": [
            -4.7843180355001165,
            40.937043747760605,
            -4.7843180355001165,
            40.937043747760605
          ]
        }
      ],
      "bbox": [
        -4.7843180355001165,
        40.937043747760605,
        -4.7843180355001165,
        40.937043747760605
      ]
    },
    "05184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.22671071970508,
              40.64869169609311
            ]
          },
          "bbox": [
            -4.22671071970508,
            40.64869169609311,
            -4.22671071970508,
            40.64869169609311
          ]
        }
      ],
      "bbox": [
        -4.22671071970508,
        40.64869169609311,
        -4.22671071970508,
        40.64869169609311
      ]
    },
    "05185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.722091451837564,
              40.795846066362984
            ]
          },
          "bbox": [
            -4.722091451837564,
            40.795846066362984,
            -4.722091451837564,
            40.795846066362984
          ]
        }
      ],
      "bbox": [
        -4.722091451837564,
        40.795846066362984,
        -4.722091451837564,
        40.795846066362984
      ]
    },
    "05186": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.338472782404515,
              40.469890650591594
            ]
          },
          "bbox": [
            -5.338472782404515,
            40.469890650591594,
            -5.338472782404515,
            40.469890650591594
          ]
        }
      ],
      "bbox": [
        -5.338472782404515,
        40.469890650591594,
        -5.338472782404515,
        40.469890650591594
      ]
    },
    "05187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.7113483771106095,
              40.32119258877781
            ]
          },
          "bbox": [
            -4.7113483771106095,
            40.32119258877781,
            -4.7113483771106095,
            40.32119258877781
          ]
        }
      ],
      "bbox": [
        -4.7113483771106095,
        40.32119258877781,
        -4.7113483771106095,
        40.32119258877781
      ]
    },
    "05188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.088228551199611,
              40.57483076813284
            ]
          },
          "bbox": [
            -5.088228551199611,
            40.57483076813284,
            -5.088228551199611,
            40.57483076813284
          ]
        }
      ],
      "bbox": [
        -5.088228551199611,
        40.57483076813284,
        -5.088228551199611,
        40.57483076813284
      ]
    },
    "05189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.162375304738249,
              40.17090491556664
            ]
          },
          "bbox": [
            -5.162375304738249,
            40.17090491556664,
            -5.162375304738249,
            40.17090491556664
          ]
        }
      ],
      "bbox": [
        -5.162375304738249,
        40.17090491556664,
        -5.162375304738249,
        40.17090491556664
      ]
    },
    "05190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.6676917041522215,
              40.802158673511016
            ]
          },
          "bbox": [
            -4.6676917041522215,
            40.802158673511016,
            -4.6676917041522215,
            40.802158673511016
          ]
        }
      ],
      "bbox": [
        -4.6676917041522215,
        40.802158673511016,
        -4.6676917041522215,
        40.802158673511016
      ]
    },
    "05191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.076481226398183,
              40.54130165168619
            ]
          },
          "bbox": [
            -5.076481226398183,
            40.54130165168619,
            -5.076481226398183,
            40.54130165168619
          ]
        }
      ],
      "bbox": [
        -5.076481226398183,
        40.54130165168619,
        -5.076481226398183,
        40.54130165168619
      ]
    },
    "05192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.632576798772995,
              40.272154796314744
            ]
          },
          "bbox": [
            -5.632576798772995,
            40.272154796314744,
            -5.632576798772995,
            40.272154796314744
          ]
        }
      ],
      "bbox": [
        -5.632576798772995,
        40.272154796314744,
        -5.632576798772995,
        40.272154796314744
      ]
    },
    "05193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0669617023640345,
              41.021924613796614
            ]
          },
          "bbox": [
            -5.0669617023640345,
            41.021924613796614,
            -5.0669617023640345,
            41.021924613796614
          ]
        }
      ],
      "bbox": [
        -5.0669617023640345,
        41.021924613796614,
        -5.0669617023640345,
        41.021924613796614
      ]
    },
    "05194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8014079094039595,
              40.8374521161172
            ]
          },
          "bbox": [
            -4.8014079094039595,
            40.8374521161172,
            -4.8014079094039595,
            40.8374521161172
          ]
        }
      ],
      "bbox": [
        -4.8014079094039595,
        40.8374521161172,
        -4.8014079094039595,
        40.8374521161172
      ]
    },
    "05195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.755115981871285,
              40.53968963338137
            ]
          },
          "bbox": [
            -4.755115981871285,
            40.53968963338137,
            -4.755115981871285,
            40.53968963338137
          ]
        }
      ],
      "bbox": [
        -4.755115981871285,
        40.53968963338137,
        -4.755115981871285,
        40.53968963338137
      ]
    },
    "05196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.012073824332388,
              40.88404245436444
            ]
          },
          "bbox": [
            -5.012073824332388,
            40.88404245436444,
            -5.012073824332388,
            40.88404245436444
          ]
        }
      ],
      "bbox": [
        -5.012073824332388,
        40.88404245436444,
        -5.012073824332388,
        40.88404245436444
      ]
    },
    "05197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.806405665521071,
              40.61115381863604
            ]
          },
          "bbox": [
            -4.806405665521071,
            40.61115381863604,
            -4.806405665521071,
            40.61115381863604
          ]
        }
      ],
      "bbox": [
        -4.806405665521071,
        40.61115381863604,
        -4.806405665521071,
        40.61115381863604
      ]
    },
    "05198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0800544704850665,
              40.887854883974995
            ]
          },
          "bbox": [
            -5.0800544704850665,
            40.887854883974995,
            -5.0800544704850665,
            40.887854883974995
          ]
        }
      ],
      "bbox": [
        -5.0800544704850665,
        40.887854883974995,
        -5.0800544704850665,
        40.887854883974995
      ]
    },
    "05199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6728194891454615,
              40.39170982317095
            ]
          },
          "bbox": [
            -5.6728194891454615,
            40.39170982317095,
            -5.6728194891454615,
            40.39170982317095
          ]
        }
      ],
      "bbox": [
        -5.6728194891454615,
        40.39170982317095,
        -5.6728194891454615,
        40.39170982317095
      ]
    },
    "05200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.376435639547566,
              40.489579576700535
            ]
          },
          "bbox": [
            -5.376435639547566,
            40.489579576700535,
            -5.376435639547566,
            40.489579576700535
          ]
        }
      ],
      "bbox": [
        -5.376435639547566,
        40.489579576700535,
        -5.376435639547566,
        40.489579576700535
      ]
    },
    "05201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.483359319618836,
              40.538422229477035
            ]
          },
          "bbox": [
            -4.483359319618836,
            40.538422229477035,
            -4.483359319618836,
            40.538422229477035
          ]
        }
      ],
      "bbox": [
        -4.483359319618836,
        40.538422229477035,
        -4.483359319618836,
        40.538422229477035
      ]
    },
    "05204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.585742206743756,
              40.89329662799521
            ]
          },
          "bbox": [
            -4.585742206743756,
            40.89329662799521,
            -4.585742206743756,
            40.89329662799521
          ]
        }
      ],
      "bbox": [
        -4.585742206743756,
        40.89329662799521,
        -4.585742206743756,
        40.89329662799521
      ]
    },
    "05205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.881172258684534,
              40.67878382448056
            ]
          },
          "bbox": [
            -4.881172258684534,
            40.67878382448056,
            -4.881172258684534,
            40.67878382448056
          ]
        }
      ],
      "bbox": [
        -4.881172258684534,
        40.67878382448056,
        -4.881172258684534,
        40.67878382448056
      ]
    },
    "05206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.631302826829219,
              40.74002520987428
            ]
          },
          "bbox": [
            -4.631302826829219,
            40.74002520987428,
            -4.631302826829219,
            40.74002520987428
          ]
        }
      ],
      "bbox": [
        -4.631302826829219,
        40.74002520987428,
        -4.631302826829219,
        40.74002520987428
      ]
    },
    "05207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.95949758327642,
              40.28614106224172
            ]
          },
          "bbox": [
            -4.95949758327642,
            40.28614106224172,
            -4.95949758327642,
            40.28614106224172
          ]
        }
      ],
      "bbox": [
        -4.95949758327642,
        40.28614106224172,
        -4.95949758327642,
        40.28614106224172
      ]
    },
    "05208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8953575821088435,
              41.0982135128908
            ]
          },
          "bbox": [
            -4.8953575821088435,
            41.0982135128908,
            -4.8953575821088435,
            41.0982135128908
          ]
        }
      ],
      "bbox": [
        -4.8953575821088435,
        41.0982135128908,
        -4.8953575821088435,
        41.0982135128908
      ]
    },
    "05209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.113584969426211,
              40.764044387629376
            ]
          },
          "bbox": [
            -5.113584969426211,
            40.764044387629376,
            -5.113584969426211,
            40.764044387629376
          ]
        }
      ],
      "bbox": [
        -5.113584969426211,
        40.764044387629376,
        -5.113584969426211,
        40.764044387629376
      ]
    },
    "05210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.83241922583535,
              40.82244685256163
            ]
          },
          "bbox": [
            -4.83241922583535,
            40.82244685256163,
            -4.83241922583535,
            40.82244685256163
          ]
        }
      ],
      "bbox": [
        -4.83241922583535,
        40.82244685256163,
        -4.83241922583535,
        40.82244685256163
      ]
    },
    "05211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.676378706974569,
              40.46772899162198
            ]
          },
          "bbox": [
            -4.676378706974569,
            40.46772899162198,
            -4.676378706974569,
            40.46772899162198
          ]
        }
      ],
      "bbox": [
        -4.676378706974569,
        40.46772899162198,
        -4.676378706974569,
        40.46772899162198
      ]
    },
    "05212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.845047627951273,
              40.468513712557574
            ]
          },
          "bbox": [
            -4.845047627951273,
            40.468513712557574,
            -4.845047627951273,
            40.468513712557574
          ]
        }
      ],
      "bbox": [
        -4.845047627951273,
        40.468513712557574,
        -4.845047627951273,
        40.468513712557574
      ]
    },
    "05213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.062822738226468,
              40.63844576597861
            ]
          },
          "bbox": [
            -5.062822738226468,
            40.63844576597861,
            -5.062822738226468,
            40.63844576597861
          ]
        }
      ],
      "bbox": [
        -5.062822738226468,
        40.63844576597861,
        -5.062822738226468,
        40.63844576597861
      ]
    },
    "05214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.496749339537177,
              40.37608715022241
            ]
          },
          "bbox": [
            -5.496749339537177,
            40.37608715022241,
            -5.496749339537177,
            40.37608715022241
          ]
        }
      ],
      "bbox": [
        -5.496749339537177,
        40.37608715022241,
        -5.496749339537177,
        40.37608715022241
      ]
    },
    "05215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.176778357156956,
              40.4270894571315
            ]
          },
          "bbox": [
            -5.176778357156956,
            40.4270894571315,
            -5.176778357156956,
            40.4270894571315
          ]
        }
      ],
      "bbox": [
        -5.176778357156956,
        40.4270894571315,
        -5.176778357156956,
        40.4270894571315
      ]
    },
    "05216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.043871012165928,
              40.36005646786422
            ]
          },
          "bbox": [
            -5.043871012165928,
            40.36005646786422,
            -5.043871012165928,
            40.36005646786422
          ]
        }
      ],
      "bbox": [
        -5.043871012165928,
        40.36005646786422,
        -5.043871012165928,
        40.36005646786422
      ]
    },
    "05217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.279258873972346,
              40.49144323616749
            ]
          },
          "bbox": [
            -5.279258873972346,
            40.49144323616749,
            -5.279258873972346,
            40.49144323616749
          ]
        }
      ],
      "bbox": [
        -5.279258873972346,
        40.49144323616749,
        -5.279258873972346,
        40.49144323616749
      ]
    },
    "05218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.272363963928301,
              40.69260749862584
            ]
          },
          "bbox": [
            -5.272363963928301,
            40.69260749862584,
            -5.272363963928301,
            40.69260749862584
          ]
        }
      ],
      "bbox": [
        -5.272363963928301,
        40.69260749862584,
        -5.272363963928301,
        40.69260749862584
      ]
    },
    "05219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.744023008526713,
              40.89237121007787
            ]
          },
          "bbox": [
            -4.744023008526713,
            40.89237121007787,
            -4.744023008526713,
            40.89237121007787
          ]
        }
      ],
      "bbox": [
        -4.744023008526713,
        40.89237121007787,
        -4.744023008526713,
        40.89237121007787
      ]
    },
    "05220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.869814060383106,
              40.79372567541853
            ]
          },
          "bbox": [
            -4.869814060383106,
            40.79372567541853,
            -4.869814060383106,
            40.79372567541853
          ]
        }
      ],
      "bbox": [
        -4.869814060383106,
        40.79372567541853,
        -4.869814060383106,
        40.79372567541853
      ]
    },
    "05221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9977803262872245,
              40.2142548541959
            ]
          },
          "bbox": [
            -4.9977803262872245,
            40.2142548541959,
            -4.9977803262872245,
            40.2142548541959
          ]
        }
      ],
      "bbox": [
        -4.9977803262872245,
        40.2142548541959,
        -4.9977803262872245,
        40.2142548541959
      ]
    },
    "05222": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.600292584501383,
              40.53130362629635
            ]
          },
          "bbox": [
            -4.600292584501383,
            40.53130362629635,
            -4.600292584501383,
            40.53130362629635
          ]
        }
      ],
      "bbox": [
        -4.600292584501383,
        40.53130362629635,
        -4.600292584501383,
        40.53130362629635
      ]
    },
    "05224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.938377605690469,
              40.60014592429378
            ]
          },
          "bbox": [
            -4.938377605690469,
            40.60014592429378,
            -4.938377605690469,
            40.60014592429378
          ]
        }
      ],
      "bbox": [
        -4.938377605690469,
        40.60014592429378,
        -4.938377605690469,
        40.60014592429378
      ]
    },
    "05225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4152829673574425,
              40.50701382720584
            ]
          },
          "bbox": [
            -5.4152829673574425,
            40.50701382720584,
            -5.4152829673574425,
            40.50701382720584
          ]
        }
      ],
      "bbox": [
        -5.4152829673574425,
        40.50701382720584,
        -5.4152829673574425,
        40.50701382720584
      ]
    },
    "05226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.454976176185664,
              40.38625918835294
            ]
          },
          "bbox": [
            -5.454976176185664,
            40.38625918835294,
            -5.454976176185664,
            40.38625918835294
          ]
        }
      ],
      "bbox": [
        -5.454976176185664,
        40.38625918835294,
        -5.454976176185664,
        40.38625918835294
      ]
    },
    "05227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.549725402779798,
              40.30498110900878
            ]
          },
          "bbox": [
            -4.549725402779798,
            40.30498110900878,
            -4.549725402779798,
            40.30498110900878
          ]
        }
      ],
      "bbox": [
        -4.549725402779798,
        40.30498110900878,
        -4.549725402779798,
        40.30498110900878
      ]
    },
    "05228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.338792456353125,
              40.427191733677766
            ]
          },
          "bbox": [
            -5.338792456353125,
            40.427191733677766,
            -5.338792456353125,
            40.427191733677766
          ]
        }
      ],
      "bbox": [
        -5.338792456353125,
        40.427191733677766,
        -5.338792456353125,
        40.427191733677766
      ]
    },
    "05229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.640272841674229,
              40.79689809413976
            ]
          },
          "bbox": [
            -4.640272841674229,
            40.79689809413976,
            -4.640272841674229,
            40.79689809413976
          ]
        }
      ],
      "bbox": [
        -4.640272841674229,
        40.79689809413976,
        -4.640272841674229,
        40.79689809413976
      ]
    },
    "05230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.901449999454805,
              40.78772103338596
            ]
          },
          "bbox": [
            -4.901449999454805,
            40.78772103338596,
            -4.901449999454805,
            40.78772103338596
          ]
        }
      ],
      "bbox": [
        -4.901449999454805,
        40.78772103338596,
        -4.901449999454805,
        40.78772103338596
      ]
    },
    "05231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.803618719341336,
              40.96433559997358
            ]
          },
          "bbox": [
            -4.803618719341336,
            40.96433559997358,
            -4.803618719341336,
            40.96433559997358
          ]
        }
      ],
      "bbox": [
        -4.803618719341336,
        40.96433559997358,
        -4.803618719341336,
        40.96433559997358
      ]
    },
    "05232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.786098427572485,
              40.630065640886485
            ]
          },
          "bbox": [
            -4.786098427572485,
            40.630065640886485,
            -4.786098427572485,
            40.630065640886485
          ]
        }
      ],
      "bbox": [
        -4.786098427572485,
        40.630065640886485,
        -4.786098427572485,
        40.630065640886485
      ]
    },
    "05233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.90610742679362,
              40.3281478964433
            ]
          },
          "bbox": [
            -4.90610742679362,
            40.3281478964433,
            -4.90610742679362,
            40.3281478964433
          ]
        }
      ],
      "bbox": [
        -4.90610742679362,
        40.3281478964433,
        -4.90610742679362,
        40.3281478964433
      ]
    },
    "05234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.937294314206355,
              40.793691583440776
            ]
          },
          "bbox": [
            -4.937294314206355,
            40.793691583440776,
            -4.937294314206355,
            40.793691583440776
          ]
        }
      ],
      "bbox": [
        -4.937294314206355,
        40.793691583440776,
        -4.937294314206355,
        40.793691583440776
      ]
    },
    "05235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.843097189860605,
              41.07727968489402
            ]
          },
          "bbox": [
            -4.843097189860605,
            41.07727968489402,
            -4.843097189860605,
            41.07727968489402
          ]
        }
      ],
      "bbox": [
        -4.843097189860605,
        41.07727968489402,
        -4.843097189860605,
        41.07727968489402
      ]
    },
    "05236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.646704873847002,
              40.33072590209653
            ]
          },
          "bbox": [
            -5.646704873847002,
            40.33072590209653,
            -5.646704873847002,
            40.33072590209653
          ]
        }
      ],
      "bbox": [
        -5.646704873847002,
        40.33072590209653,
        -5.646704873847002,
        40.33072590209653
      ]
    },
    "05237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0089186795794225,
              40.743238692928486
            ]
          },
          "bbox": [
            -5.0089186795794225,
            40.743238692928486,
            -5.0089186795794225,
            40.743238692928486
          ]
        }
      ],
      "bbox": [
        -5.0089186795794225,
        40.743238692928486,
        -5.0089186795794225,
        40.743238692928486
      ]
    },
    "05238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.914672946782817,
              40.540519123297784
            ]
          },
          "bbox": [
            -4.914672946782817,
            40.540519123297784,
            -4.914672946782817,
            40.540519123297784
          ]
        }
      ],
      "bbox": [
        -4.914672946782817,
        40.540519123297784,
        -4.914672946782817,
        40.540519123297784
      ]
    },
    "05239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.8648208445415095,
              40.5122432403268
            ]
          },
          "bbox": [
            -4.8648208445415095,
            40.5122432403268,
            -4.8648208445415095,
            40.5122432403268
          ]
        }
      ],
      "bbox": [
        -4.8648208445415095,
        40.5122432403268,
        -4.8648208445415095,
        40.5122432403268
      ]
    },
    "05240": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.596063813212293,
              40.29414134341553
            ]
          },
          "bbox": [
            -4.596063813212293,
            40.29414134341553,
            -4.596063813212293,
            40.29414134341553
          ]
        }
      ],
      "bbox": [
        -4.596063813212293,
        40.29414134341553,
        -4.596063813212293,
        40.29414134341553
      ]
    },
    "05241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.495115015214426,
              40.3888600583371
            ]
          },
          "bbox": [
            -4.495115015214426,
            40.3888600583371,
            -4.495115015214426,
            40.3888600583371
          ]
        }
      ],
      "bbox": [
        -4.495115015214426,
        40.3888600583371,
        -4.495115015214426,
        40.3888600583371
      ]
    },
    "05242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.71255662669,
              40.94928631470539
            ]
          },
          "bbox": [
            -4.71255662669,
            40.94928631470539,
            -4.71255662669,
            40.94928631470539
          ]
        }
      ],
      "bbox": [
        -4.71255662669,
        40.94928631470539,
        -4.71255662669,
        40.94928631470539
      ]
    },
    "05243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.584021843813025,
              40.75498810058286
            ]
          },
          "bbox": [
            -4.584021843813025,
            40.75498810058286,
            -4.584021843813025,
            40.75498810058286
          ]
        }
      ],
      "bbox": [
        -4.584021843813025,
        40.75498810058286,
        -4.584021843813025,
        40.75498810058286
      ]
    },
    "05244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.505191575655964,
              40.30704619593457
            ]
          },
          "bbox": [
            -5.505191575655964,
            40.30704619593457,
            -5.505191575655964,
            40.30704619593457
          ]
        }
      ],
      "bbox": [
        -5.505191575655964,
        40.30704619593457,
        -5.505191575655964,
        40.30704619593457
      ]
    },
    "05245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.609750276514047,
              40.60380103780936
            ]
          },
          "bbox": [
            -4.609750276514047,
            40.60380103780936,
            -4.609750276514047,
            40.60380103780936
          ]
        }
      ],
      "bbox": [
        -4.609750276514047,
        40.60380103780936,
        -4.609750276514047,
        40.60380103780936
      ]
    },
    "05246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.273329905932584,
              40.572062568790265
            ]
          },
          "bbox": [
            -5.273329905932584,
            40.572062568790265,
            -5.273329905932584,
            40.572062568790265
          ]
        }
      ],
      "bbox": [
        -5.273329905932584,
        40.572062568790265,
        -5.273329905932584,
        40.572062568790265
      ]
    },
    "05247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.965790706402946,
              40.60768056643188
            ]
          },
          "bbox": [
            -4.965790706402946,
            40.60768056643188,
            -4.965790706402946,
            40.60768056643188
          ]
        }
      ],
      "bbox": [
        -4.965790706402946,
        40.60768056643188,
        -4.965790706402946,
        40.60768056643188
      ]
    },
    "05249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.574660821513336,
              40.31425249316626
            ]
          },
          "bbox": [
            -5.574660821513336,
            40.31425249316626,
            -5.574660821513336,
            40.31425249316626
          ]
        }
      ],
      "bbox": [
        -5.574660821513336,
        40.31425249316626,
        -5.574660821513336,
        40.31425249316626
      ]
    },
    "05251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.130015545643468,
              40.61357376678274
            ]
          },
          "bbox": [
            -5.130015545643468,
            40.61357376678274,
            -5.130015545643468,
            40.61357376678274
          ]
        }
      ],
      "bbox": [
        -5.130015545643468,
        40.61357376678274,
        -5.130015545643468,
        40.61357376678274
      ]
    },
    "05252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.014482849437468,
              40.66784048396885
            ]
          },
          "bbox": [
            -5.014482849437468,
            40.66784048396885,
            -5.014482849437468,
            40.66784048396885
          ]
        }
      ],
      "bbox": [
        -5.014482849437468,
        40.66784048396885,
        -5.014482849437468,
        40.66784048396885
      ]
    },
    "05253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.657261500702028,
              40.8378991343377
            ]
          },
          "bbox": [
            -4.657261500702028,
            40.8378991343377,
            -4.657261500702028,
            40.8378991343377
          ]
        }
      ],
      "bbox": [
        -4.657261500702028,
        40.8378991343377,
        -4.657261500702028,
        40.8378991343377
      ]
    },
    "05254": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.603643164957622,
              40.84723722658627
            ]
          },
          "bbox": [
            -4.603643164957622,
            40.84723722658627,
            -4.603643164957622,
            40.84723722658627
          ]
        }
      ],
      "bbox": [
        -4.603643164957622,
        40.84723722658627,
        -4.603643164957622,
        40.84723722658627
      ]
    },
    "05256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.880945983297092,
              40.750374325458075
            ]
          },
          "bbox": [
            -4.880945983297092,
            40.750374325458075,
            -4.880945983297092,
            40.750374325458075
          ]
        }
      ],
      "bbox": [
        -4.880945983297092,
        40.750374325458075,
        -4.880945983297092,
        40.750374325458075
      ]
    },
    "05257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.166656948161849,
              40.48779834761031
            ]
          },
          "bbox": [
            -5.166656948161849,
            40.48779834761031,
            -5.166656948161849,
            40.48779834761031
          ]
        }
      ],
      "bbox": [
        -5.166656948161849,
        40.48779834761031,
        -5.166656948161849,
        40.48779834761031
      ]
    },
    "05258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.699121366033291,
              40.8870293393179
            ]
          },
          "bbox": [
            -4.699121366033291,
            40.8870293393179,
            -4.699121366033291,
            40.8870293393179
          ]
        }
      ],
      "bbox": [
        -4.699121366033291,
        40.8870293393179,
        -4.699121366033291,
        40.8870293393179
      ]
    },
    "05259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.865587451785741,
              41.04210995200957
            ]
          },
          "bbox": [
            -4.865587451785741,
            41.04210995200957,
            -4.865587451785741,
            41.04210995200957
          ]
        }
      ],
      "bbox": [
        -4.865587451785741,
        41.04210995200957,
        -4.865587451785741,
        41.04210995200957
      ]
    },
    "05260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.1922349225607665,
              40.58399998965892
            ]
          },
          "bbox": [
            -5.1922349225607665,
            40.58399998965892,
            -5.1922349225607665,
            40.58399998965892
          ]
        }
      ],
      "bbox": [
        -5.1922349225607665,
        40.58399998965892,
        -5.1922349225607665,
        40.58399998965892
      ]
    },
    "05261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.441817474790525,
              40.47730613652537
            ]
          },
          "bbox": [
            -5.441817474790525,
            40.47730613652537,
            -5.441817474790525,
            40.47730613652537
          ]
        }
      ],
      "bbox": [
        -5.441817474790525,
        40.47730613652537,
        -5.441817474790525,
        40.47730613652537
      ]
    },
    "05262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.9862180380980545,
              40.327549374108095
            ]
          },
          "bbox": [
            -4.9862180380980545,
            40.327549374108095,
            -4.9862180380980545,
            40.327549374108095
          ]
        }
      ],
      "bbox": [
        -4.9862180380980545,
        40.327549374108095,
        -4.9862180380980545,
        40.327549374108095
      ]
    },
    "05263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.0988487799777795,
              40.52974760862521
            ]
          },
          "bbox": [
            -5.0988487799777795,
            40.52974760862521,
            -5.0988487799777795,
            40.52974760862521
          ]
        }
      ],
      "bbox": [
        -5.0988487799777795,
        40.52974760862521,
        -5.0988487799777795,
        40.52974760862521
      ]
    },
    "05264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.92575987479061,
              40.85182310386557
            ]
          },
          "bbox": [
            -4.92575987479061,
            40.85182310386557,
            -4.92575987479061,
            40.85182310386557
          ]
        }
      ],
      "bbox": [
        -4.92575987479061,
        40.85182310386557,
        -4.92575987479061,
        40.85182310386557
      ]
    },
    "05265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.003225081834476,
              40.8205957709244
            ]
          },
          "bbox": [
            -5.003225081834476,
            40.8205957709244,
            -5.003225081834476,
            40.8205957709244
          ]
        }
      ],
      "bbox": [
        -5.003225081834476,
        40.8205957709244,
        -5.003225081834476,
        40.8205957709244
      ]
    },
    "05266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.317151638208637,
              40.604190266267146
            ]
          },
          "bbox": [
            -5.317151638208637,
            40.604190266267146,
            -5.317151638208637,
            40.604190266267146
          ]
        }
      ],
      "bbox": [
        -5.317151638208637,
        40.604190266267146,
        -5.317151638208637,
        40.604190266267146
      ]
    },
    "05267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.3145828021089025,
              40.30837521138595
            ]
          },
          "bbox": [
            -5.3145828021089025,
            40.30837521138595,
            -5.3145828021089025,
            40.30837521138595
          ]
        }
      ],
      "bbox": [
        -5.3145828021089025,
        40.30837521138595,
        -5.3145828021089025,
        40.30837521138595
      ]
    },
    "05901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.241706486952063,
              40.35634056844232
            ]
          },
          "bbox": [
            -5.241706486952063,
            40.35634056844232,
            -5.241706486952063,
            40.35634056844232
          ]
        }
      ],
      "bbox": [
        -5.241706486952063,
        40.35634056844232,
        -5.241706486952063,
        40.35634056844232
      ]
    },
    "05902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.461815450821744,
              40.717104243506114
            ]
          },
          "bbox": [
            -4.461815450821744,
            40.717104243506114,
            -4.461815450821744,
            40.717104243506114
          ]
        }
      ],
      "bbox": [
        -4.461815450821744,
        40.717104243506114,
        -4.461815450821744,
        40.717104243506114
      ]
    },
    "05903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.334657704014442,
              40.668330477895
            ]
          },
          "bbox": [
            -5.334657704014442,
            40.668330477895,
            -5.334657704014442,
            40.668330477895
          ]
        }
      ],
      "bbox": [
        -5.334657704014442,
        40.668330477895,
        -5.334657704014442,
        40.668330477895
      ]
    },
    "05904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.374002094397049,
              40.346033363486185
            ]
          },
          "bbox": [
            -5.374002094397049,
            40.346033363486185,
            -5.374002094397049,
            40.346033363486185
          ]
        }
      ],
      "bbox": [
        -5.374002094397049,
        40.346033363486185,
        -5.374002094397049,
        40.346033363486185
      ]
    },
    "05905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.822962705605622,
              40.37055975073716
            ]
          },
          "bbox": [
            -4.822962705605622,
            40.37055975073716,
            -4.822962705605622,
            40.37055975073716
          ]
        }
      ],
      "bbox": [
        -4.822962705605622,
        40.37055975073716,
        -4.822962705605622,
        40.37055975073716
      ]
    },
    "06001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.59434973597162,
              39.07122890709508
            ]
          },
          "bbox": [
            -5.59434973597162,
            39.07122890709508,
            -5.59434973597162,
            39.07122890709508
          ]
        }
      ],
      "bbox": [
        -5.59434973597162,
        39.07122890709508,
        -5.59434973597162,
        39.07122890709508
      ]
    },
    "06002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.490697354789031,
              38.663602430326854
            ]
          },
          "bbox": [
            -6.490697354789031,
            38.663602430326854,
            -6.490697354789031,
            38.663602430326854
          ]
        }
      ],
      "bbox": [
        -6.490697354789031,
        38.663602430326854,
        -6.490697354789031,
        38.663602430326854
      ]
    },
    "06003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.878334201587393,
              38.24759597367301
            ]
          },
          "bbox": [
            -5.878334201587393,
            38.24759597367301,
            -5.878334201587393,
            38.24759597367301
          ]
        }
      ],
      "bbox": [
        -5.878334201587393,
        38.24759597367301,
        -5.878334201587393,
        38.24759597367301
      ]
    },
    "06004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.265851957340251,
              38.72496888427073
            ]
          },
          "bbox": [
            -6.265851957340251,
            38.72496888427073,
            -6.265851957340251,
            38.72496888427073
          ]
        }
      ],
      "bbox": [
        -6.265851957340251,
        38.72496888427073,
        -6.265851957340251,
        38.72496888427073
      ]
    },
    "06005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.798107559416639,
              38.71165328187579
            ]
          },
          "bbox": [
            -6.798107559416639,
            38.71165328187579,
            -6.798107559416639,
            38.71165328187579
          ]
        }
      ],
      "bbox": [
        -6.798107559416639,
        38.71165328187579,
        -6.798107559416639,
        38.71165328187579
      ]
    },
    "06006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.951100129844582,
              39.21578340051094
            ]
          },
          "bbox": [
            -6.951100129844582,
            39.21578340051094,
            -6.951100129844582,
            39.21578340051094
          ]
        }
      ],
      "bbox": [
        -6.951100129844582,
        39.21578340051094,
        -6.951100129844582,
        39.21578340051094
      ]
    },
    "06007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.1452540047285,
              38.516154239417624
            ]
          },
          "bbox": [
            -7.1452540047285,
            38.516154239417624,
            -7.1452540047285,
            38.516154239417624
          ]
        }
      ],
      "bbox": [
        -7.1452540047285,
        38.516154239417624,
        -7.1452540047285,
        38.516154239417624
      ]
    },
    "06008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.476155660080851,
              38.399768784128995
            ]
          },
          "bbox": [
            -6.476155660080851,
            38.399768784128995,
            -6.476155660080851,
            38.399768784128995
          ]
        }
      ],
      "bbox": [
        -6.476155660080851,
        38.399768784128995,
        -6.476155660080851,
        38.399768784128995
      ]
    },
    "06009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.333518129740271,
              39.056618243022385
            ]
          },
          "bbox": [
            -6.333518129740271,
            39.056618243022385,
            -6.333518129740271,
            39.056618243022385
          ]
        }
      ],
      "bbox": [
        -6.333518129740271,
        39.056618243022385,
        -6.333518129740271,
        39.056618243022385
      ]
    },
    "06010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.838532967421877,
              38.610716813903004
            ]
          },
          "bbox": [
            -6.838532967421877,
            38.610716813903004,
            -6.838532967421877,
            38.610716813903004
          ]
        }
      ],
      "bbox": [
        -6.838532967421877,
        38.610716813903004,
        -6.838532967421877,
        38.610716813903004
      ]
    },
    "06011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.409413588058697,
              38.708331085454724
            ]
          },
          "bbox": [
            -6.409413588058697,
            38.708331085454724,
            -6.409413588058697,
            38.708331085454724
          ]
        }
      ],
      "bbox": [
        -6.409413588058697,
        38.708331085454724,
        -6.409413588058697,
        38.708331085454724
      ]
    },
    "06012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.453052250986229,
              38.83924944855456
            ]
          },
          "bbox": [
            -6.453052250986229,
            38.83924944855456,
            -6.453052250986229,
            38.83924944855456
          ]
        }
      ],
      "bbox": [
        -6.453052250986229,
        38.83924944855456,
        -6.453052250986229,
        38.83924944855456
      ]
    },
    "06013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.487975423990085,
              38.33914735773071
            ]
          },
          "bbox": [
            -6.487975423990085,
            38.33914735773071,
            -6.487975423990085,
            38.33914735773071
          ]
        }
      ],
      "bbox": [
        -6.487975423990085,
        38.33914735773071,
        -6.487975423990085,
        38.33914735773071
      ]
    },
    "06014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.679940955817458,
              38.280363106924945
            ]
          },
          "bbox": [
            -5.679940955817458,
            38.280363106924945,
            -5.679940955817458,
            38.280363106924945
          ]
        }
      ],
      "bbox": [
        -5.679940955817458,
        38.280363106924945,
        -5.679940955817458,
        38.280363106924945
      ]
    },
    "06015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.854576597915772,
              38.86908496770901
            ]
          },
          "bbox": [
            -6.854576597915772,
            38.86908496770901,
            -6.854576597915772,
            38.86908496770901
          ]
        }
      ],
      "bbox": [
        -6.854576597915772,
        38.86908496770901,
        -6.854576597915772,
        38.86908496770901
      ]
    },
    "06016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.885101230553769,
              38.507688395829256
            ]
          },
          "bbox": [
            -6.885101230553769,
            38.507688395829256,
            -6.885101230553769,
            38.507688395829256
          ]
        }
      ],
      "bbox": [
        -6.885101230553769,
        38.507688395829256,
        -6.885101230553769,
        38.507688395829256
      ]
    },
    "06017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.892909857217125,
              38.923545123502095
            ]
          },
          "bbox": [
            -4.892909857217125,
            38.923545123502095,
            -4.892909857217125,
            38.923545123502095
          ]
        }
      ],
      "bbox": [
        -4.892909857217125,
        38.923545123502095,
        -4.892909857217125,
        38.923545123502095
      ]
    },
    "06018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.450840755170919,
              38.67314101689685
            ]
          },
          "bbox": [
            -5.450840755170919,
            38.67314101689685,
            -5.450840755170919,
            38.67314101689685
          ]
        }
      ],
      "bbox": [
        -5.450840755170919,
        38.67314101689685,
        -5.450840755170919,
        38.67314101689685
      ]
    },
    "06019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.84264428875676,
              38.289576939445226
            ]
          },
          "bbox": [
            -5.84264428875676,
            38.289576939445226,
            -5.84264428875676,
            38.289576939445226
          ]
        }
      ],
      "bbox": [
        -5.84264428875676,
        38.289576939445226,
        -5.84264428875676,
        38.289576939445226
      ]
    },
    "06020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.210981470603403,
              38.313893617840094
            ]
          },
          "bbox": [
            -6.210981470603403,
            38.313893617840094,
            -6.210981470603403,
            38.313893617840094
          ]
        }
      ],
      "bbox": [
        -6.210981470603403,
        38.313893617840094,
        -6.210981470603403,
        38.313893617840094
      ]
    },
    "06021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.535605862725799,
              38.163972711672535
            ]
          },
          "bbox": [
            -6.535605862725799,
            38.163972711672535,
            -6.535605862725799,
            38.163972711672535
          ]
        }
      ],
      "bbox": [
        -6.535605862725799,
        38.163972711672535,
        -6.535605862725799,
        38.163972711672535
      ]
    },
    "06022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.589098380256945,
              38.38569785206598
            ]
          },
          "bbox": [
            -6.589098380256945,
            38.38569785206598,
            -6.589098380256945,
            38.38569785206598
          ]
        }
      ],
      "bbox": [
        -6.589098380256945,
        38.38569785206598,
        -6.589098380256945,
        38.38569785206598
      ]
    },
    "06023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.276706053716728,
              38.772231515017694
            ]
          },
          "bbox": [
            -5.276706053716728,
            38.772231515017694,
            -5.276706053716728,
            38.772231515017694
          ]
        }
      ],
      "bbox": [
        -5.276706053716728,
        38.772231515017694,
        -5.276706053716728,
        38.772231515017694
      ]
    },
    "06024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.397411680719481,
              38.094518604577246
            ]
          },
          "bbox": [
            -6.397411680719481,
            38.094518604577246,
            -6.397411680719481,
            38.094518604577246
          ]
        }
      ],
      "bbox": [
        -6.397411680719481,
        38.094518604577246,
        -6.397411680719481,
        38.094518604577246
      ]
    },
    "06025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.383000189938959,
              38.874140551753996
            ]
          },
          "bbox": [
            -6.383000189938959,
            38.874140551753996,
            -6.383000189938959,
            38.874140551753996
          ]
        }
      ],
      "bbox": [
        -6.383000189938959,
        38.874140551753996,
        -6.383000189938959,
        38.874140551753996
      ]
    },
    "06026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.338149712139862,
              38.07236227545614
            ]
          },
          "bbox": [
            -6.338149712139862,
            38.07236227545614,
            -6.338149712139862,
            38.07236227545614
          ]
        }
      ],
      "bbox": [
        -6.338149712139862,
        38.07236227545614,
        -6.338149712139862,
        38.07236227545614
      ]
    },
    "06027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.311822586639111,
              38.31629062853395
            ]
          },
          "bbox": [
            -6.311822586639111,
            38.31629062853395,
            -6.311822586639111,
            38.31629062853395
          ]
        }
      ],
      "bbox": [
        -6.311822586639111,
        38.31629062853395,
        -6.311822586639111,
        38.31629062853395
      ]
    },
    "06028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.599252620288443,
              38.88016876934883
            ]
          },
          "bbox": [
            -5.599252620288443,
            38.88016876934883,
            -5.599252620288443,
            38.88016876934883
          ]
        }
      ],
      "bbox": [
        -5.599252620288443,
        38.88016876934883,
        -5.599252620288443,
        38.88016876934883
      ]
    },
    "06029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.803148789494985,
              38.505177937668016
            ]
          },
          "bbox": [
            -5.803148789494985,
            38.505177937668016,
            -5.803148789494985,
            38.505177937668016
          ]
        }
      ],
      "bbox": [
        -5.803148789494985,
        38.505177937668016,
        -5.803148789494985,
        38.505177937668016
      ]
    },
    "06030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.037397183059993,
              38.795526828892996
            ]
          },
          "bbox": [
            -5.037397183059993,
            38.795526828892996,
            -5.037397183059993,
            38.795526828892996
          ]
        }
      ],
      "bbox": [
        -5.037397183059993,
        38.795526828892996,
        -5.037397183059993,
        38.795526828892996
      ]
    },
    "06031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.347614823239787,
              39.14893486345836
            ]
          },
          "bbox": [
            -6.347614823239787,
            39.14893486345836,
            -6.347614823239787,
            39.14893486345836
          ]
        }
      ],
      "bbox": [
        -6.347614823239787,
        39.14893486345836,
        -6.347614823239787,
        39.14893486345836
      ]
    },
    "06032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.336269462462032,
              39.02117574750487
            ]
          },
          "bbox": [
            -6.336269462462032,
            39.02117574750487,
            -6.336269462462032,
            39.02117574750487
          ]
        }
      ],
      "bbox": [
        -6.336269462462032,
        39.02117574750487,
        -6.336269462462032,
        39.02117574750487
      ]
    },
    "06033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.304999177519994,
              39.17703066937478
            ]
          },
          "bbox": [
            -5.304999177519994,
            39.17703066937478,
            -5.304999177519994,
            39.17703066937478
          ]
        }
      ],
      "bbox": [
        -5.304999177519994,
        39.17703066937478,
        -5.304999177519994,
        39.17703066937478
      ]
    },
    "06034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.961793409482325,
              38.19625990874302
            ]
          },
          "bbox": [
            -5.961793409482325,
            38.19625990874302,
            -5.961793409482325,
            38.19625990874302
          ]
        }
      ],
      "bbox": [
        -5.961793409482325,
        38.19625990874302,
        -5.961793409482325,
        38.19625990874302
      ]
    },
    "06035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.087549475280717,
              39.311629022842624
            ]
          },
          "bbox": [
            -5.087549475280717,
            39.311629022842624,
            -5.087549475280717,
            39.311629022842624
          ]
        }
      ],
      "bbox": [
        -5.087549475280717,
        39.311629022842624,
        -5.087549475280717,
        39.311629022842624
      ]
    },
    "06036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.484722748316224,
              38.790678357324296
            ]
          },
          "bbox": [
            -5.484722748316224,
            38.790678357324296,
            -5.484722748316224,
            38.790678357324296
          ]
        }
      ],
      "bbox": [
        -5.484722748316224,
        38.790678357324296,
        -5.484722748316224,
        38.790678357324296
      ]
    },
    "06037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.193350737837254,
              39.2247236944644
            ]
          },
          "bbox": [
            -7.193350737837254,
            39.2247236944644,
            -7.193350737837254,
            39.2247236944644
          ]
        }
      ],
      "bbox": [
        -7.193350737837254,
        39.2247236944644,
        -7.193350737837254,
        39.2247236944644
      ]
    },
    "06038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.438093240895598,
              39.141296891988716
            ]
          },
          "bbox": [
            -6.438093240895598,
            39.141296891988716,
            -6.438093240895598,
            39.141296891988716
          ]
        }
      ],
      "bbox": [
        -6.438093240895598,
        39.141296891988716,
        -6.438093240895598,
        39.141296891988716
      ]
    },
    "06039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.643733773065884,
              38.946253830746656
            ]
          },
          "bbox": [
            -5.643733773065884,
            38.946253830746656,
            -5.643733773065884,
            38.946253830746656
          ]
        }
      ],
      "bbox": [
        -5.643733773065884,
        38.946253830746656,
        -5.643733773065884,
        38.946253830746656
      ]
    },
    "06040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.654224603037466,
              38.72894093095017
            ]
          },
          "bbox": [
            -6.654224603037466,
            38.72894093095017,
            -6.654224603037466,
            38.72894093095017
          ]
        }
      ],
      "bbox": [
        -6.654224603037466,
        38.72894093095017,
        -6.654224603037466,
        38.72894093095017
      ]
    },
    "06041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0999135693190185,
              38.825425474473406
            ]
          },
          "bbox": [
            -6.0999135693190185,
            38.825425474473406,
            -6.0999135693190185,
            38.825425474473406
          ]
        }
      ],
      "bbox": [
        -6.0999135693190185,
        38.825425474473406,
        -6.0999135693190185,
        38.825425474473406
      ]
    },
    "06042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.2641964620419905,
              38.5436653886994
            ]
          },
          "bbox": [
            -7.2641964620419905,
            38.5436653886994,
            -7.2641964620419905,
            38.5436653886994
          ]
        }
      ],
      "bbox": [
        -7.2641964620419905,
        38.5436653886994,
        -7.2641964620419905,
        38.5436653886994
      ]
    },
    "06043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.278072073771828,
              38.860264679664766
            ]
          },
          "bbox": [
            -6.278072073771828,
            38.860264679664766,
            -6.278072073771828,
            38.860264679664766
          ]
        }
      ],
      "bbox": [
        -6.278072073771828,
        38.860264679664766,
        -6.278072073771828,
        38.860264679664766
      ]
    },
    "06044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.891974284853464,
              38.94301218452749
            ]
          },
          "bbox": [
            -5.891974284853464,
            38.94301218452749,
            -5.891974284853464,
            38.94301218452749
          ]
        }
      ],
      "bbox": [
        -5.891974284853464,
        38.94301218452749,
        -5.891974284853464,
        38.94301218452749
      ]
    },
    "06045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.703589641629731,
              38.712764697453245
            ]
          },
          "bbox": [
            -6.703589641629731,
            38.712764697453245,
            -6.703589641629731,
            38.712764697453245
          ]
        }
      ],
      "bbox": [
        -6.703589641629731,
        38.712764697453245,
        -6.703589641629731,
        38.712764697453245
      ]
    },
    "06046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.43541220483374,
              38.951069882273934
            ]
          },
          "bbox": [
            -6.43541220483374,
            38.951069882273934,
            -6.43541220483374,
            38.951069882273934
          ]
        }
      ],
      "bbox": [
        -6.43541220483374,
        38.951069882273934,
        -6.43541220483374,
        38.951069882273934
      ]
    },
    "06047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.600645606844856,
              38.65298957383131
            ]
          },
          "bbox": [
            -5.600645606844856,
            38.65298957383131,
            -5.600645606844856,
            38.65298957383131
          ]
        }
      ],
      "bbox": [
        -5.600645606844856,
        38.65298957383131,
        -5.600645606844856,
        38.65298957383131
      ]
    },
    "06048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.369387071416921,
              38.95827920188695
            ]
          },
          "bbox": [
            -5.369387071416921,
            38.95827920188695,
            -5.369387071416921,
            38.95827920188695
          ]
        }
      ],
      "bbox": [
        -5.369387071416921,
        38.95827920188695,
        -5.369387071416921,
        38.95827920188695
      ]
    },
    "06049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.54837752192499,
              38.49792698593994
            ]
          },
          "bbox": [
            -6.54837752192499,
            38.49792698593994,
            -6.54837752192499,
            38.49792698593994
          ]
        }
      ],
      "bbox": [
        -6.54837752192499,
        38.49792698593994,
        -6.54837752192499,
        38.49792698593994
      ]
    },
    "06050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.583657377822278,
              38.209112820351386
            ]
          },
          "bbox": [
            -6.583657377822278,
            38.209112820351386,
            -6.583657377822278,
            38.209112820351386
          ]
        }
      ],
      "bbox": [
        -6.583657377822278,
        38.209112820351386,
        -6.583657377822278,
        38.209112820351386
      ]
    },
    "06051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.90386923709817,
              39.13609061952374
            ]
          },
          "bbox": [
            -4.90386923709817,
            39.13609061952374,
            -4.90386923709817,
            39.13609061952374
          ]
        }
      ],
      "bbox": [
        -4.90386923709817,
        39.13609061952374,
        -4.90386923709817,
        39.13609061952374
      ]
    },
    "06052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.2917521778573935,
              38.231361803169
            ]
          },
          "bbox": [
            -6.2917521778573935,
            38.231361803169,
            -6.2917521778573935,
            38.231361803169
          ]
        }
      ],
      "bbox": [
        -6.2917521778573935,
        38.231361803169,
        -6.2917521778573935,
        38.231361803169
      ]
    },
    "06053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.941675633420625,
              38.106117804767436
            ]
          },
          "bbox": [
            -5.941675633420625,
            38.106117804767436,
            -5.941675633420625,
            38.106117804767436
          ]
        }
      ],
      "bbox": [
        -5.941675633420625,
        38.106117804767436,
        -5.941675633420625,
        38.106117804767436
      ]
    },
    "06054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.439673000238448,
              38.55024328464472
            ]
          },
          "bbox": [
            -6.439673000238448,
            38.55024328464472,
            -6.439673000238448,
            38.55024328464472
          ]
        }
      ],
      "bbox": [
        -6.439673000238448,
        38.55024328464472,
        -6.439673000238448,
        38.55024328464472
      ]
    },
    "06055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.53303430339182,
              38.06502664454663
            ]
          },
          "bbox": [
            -6.53303430339182,
            38.06502664454663,
            -6.53303430339182,
            38.06502664454663
          ]
        }
      ],
      "bbox": [
        -6.53303430339182,
        38.06502664454663,
        -6.53303430339182,
        38.06502664454663
      ]
    },
    "06056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.005021666197306,
              39.07567271664593
            ]
          },
          "bbox": [
            -5.005021666197306,
            39.07567271664593,
            -5.005021666197306,
            39.07567271664593
          ]
        }
      ],
      "bbox": [
        -5.005021666197306,
        39.07567271664593,
        -5.005021666197306,
        39.07567271664593
      ]
    },
    "06057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.001784421551726,
              38.88887981311966
            ]
          },
          "bbox": [
            -5.001784421551726,
            38.88887981311966,
            -5.001784421551726,
            38.88887981311966
          ]
        }
      ],
      "bbox": [
        -5.001784421551726,
        38.88887981311966,
        -5.001784421551726,
        38.88887981311966
      ]
    },
    "06058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.486607181575862,
              38.93266943972087
            ]
          },
          "bbox": [
            -6.486607181575862,
            38.93266943972087,
            -6.486607181575862,
            38.93266943972087
          ]
        }
      ],
      "bbox": [
        -6.486607181575862,
        38.93266943972087,
        -6.486607181575862,
        38.93266943972087
      ]
    },
    "06059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.600595077195486,
              38.3341572017572
            ]
          },
          "bbox": [
            -5.600595077195486,
            38.3341572017572,
            -5.600595077195486,
            38.3341572017572
          ]
        }
      ],
      "bbox": [
        -5.600595077195486,
        38.3341572017572,
        -5.600595077195486,
        38.3341572017572
      ]
    },
    "06060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.089270849770561,
              38.90172159019112
            ]
          },
          "bbox": [
            -6.089270849770561,
            38.90172159019112,
            -6.089270849770561,
            38.90172159019112
          ]
        }
      ],
      "bbox": [
        -6.089270849770561,
        38.90172159019112,
        -6.089270849770561,
        38.90172159019112
      ]
    },
    "06061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.799746088409037,
              38.869919450917244
            ]
          },
          "bbox": [
            -5.799746088409037,
            38.869919450917244,
            -5.799746088409037,
            38.869919450917244
          ]
        }
      ],
      "bbox": [
        -5.799746088409037,
        38.869919450917244,
        -5.799746088409037,
        38.869919450917244
      ]
    },
    "06062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.832989666447009,
              39.34441619446516
            ]
          },
          "bbox": [
            -4.832989666447009,
            39.34441619446516,
            -4.832989666447009,
            39.34441619446516
          ]
        }
      ],
      "bbox": [
        -4.832989666447009,
        39.34441619446516,
        -4.832989666447009,
        39.34441619446516
      ]
    },
    "06063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.013741075237306,
              39.23311439144013
            ]
          },
          "bbox": [
            -5.013741075237306,
            39.23311439144013,
            -5.013741075237306,
            39.23311439144013
          ]
        }
      ],
      "bbox": [
        -5.013741075237306,
        39.23311439144013,
        -5.013741075237306,
        39.23311439144013
      ]
    },
    "06064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.772685758477601,
              38.64179100616602
            ]
          },
          "bbox": [
            -5.772685758477601,
            38.64179100616602,
            -5.772685758477601,
            38.64179100616602
          ]
        }
      ],
      "bbox": [
        -5.772685758477601,
        38.64179100616602,
        -5.772685758477601,
        38.64179100616602
      ]
    },
    "06065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.972069149149393,
              38.33287146888921
            ]
          },
          "bbox": [
            -5.972069149149393,
            38.33287146888921,
            -5.972069149149393,
            38.33287146888921
          ]
        }
      ],
      "bbox": [
        -5.972069149149393,
        38.33287146888921,
        -5.972069149149393,
        38.33287146888921
      ]
    },
    "06066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.996159984178742,
              38.41996525350329
            ]
          },
          "bbox": [
            -6.996159984178742,
            38.41996525350329,
            -6.996159984178742,
            38.41996525350329
          ]
        }
      ],
      "bbox": [
        -6.996159984178742,
        38.41996525350329,
        -6.996159984178742,
        38.41996525350329
      ]
    },
    "06067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.72768246144957,
              38.14035331037658
            ]
          },
          "bbox": [
            -6.72768246144957,
            38.14035331037658,
            -6.72768246144957,
            38.14035331037658
          ]
        }
      ],
      "bbox": [
        -6.72768246144957,
        38.14035331037658,
        -6.72768246144957,
        38.14035331037658
      ]
    },
    "06068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1892832354198815,
              38.472758322731764
            ]
          },
          "bbox": [
            -6.1892832354198815,
            38.472758322731764,
            -6.1892832354198815,
            38.472758322731764
          ]
        }
      ],
      "bbox": [
        -6.1892832354198815,
        38.472758322731764,
        -6.1892832354198815,
        38.472758322731764
      ]
    },
    "06069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.057685685762418,
              38.56601689237156
            ]
          },
          "bbox": [
            -6.057685685762418,
            38.56601689237156,
            -6.057685685762418,
            38.56601689237156
          ]
        }
      ],
      "bbox": [
        -6.057685685762418,
        38.56601689237156,
        -6.057685685762418,
        38.56601689237156
      ]
    },
    "06070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7990873164502155,
              38.32965281731019
            ]
          },
          "bbox": [
            -6.7990873164502155,
            38.32965281731019,
            -6.7990873164502155,
            38.32965281731019
          ]
        }
      ],
      "bbox": [
        -6.7990873164502155,
        38.32965281731019,
        -6.7990873164502155,
        38.32965281731019
      ]
    },
    "06071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.522541958418911,
              38.45660241347797
            ]
          },
          "bbox": [
            -6.522541958418911,
            38.45660241347797,
            -6.522541958418911,
            38.45660241347797
          ]
        }
      ],
      "bbox": [
        -6.522541958418911,
        38.45660241347797,
        -6.522541958418911,
        38.45660241347797
      ]
    },
    "06072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.6106738710423105,
              38.838952427694494
            ]
          },
          "bbox": [
            -6.6106738710423105,
            38.838952427694494,
            -6.6106738710423105,
            38.838952427694494
          ]
        }
      ],
      "bbox": [
        -6.6106738710423105,
        38.838952427694494,
        -6.6106738710423105,
        38.838952427694494
      ]
    },
    "06073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.080219001744348,
              38.461626663981576
            ]
          },
          "bbox": [
            -6.080219001744348,
            38.461626663981576,
            -6.080219001744348,
            38.461626663981576
          ]
        }
      ],
      "bbox": [
        -6.080219001744348,
        38.461626663981576,
        -6.080219001744348,
        38.461626663981576
      ]
    },
    "06074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.047095440356391,
              38.23359401765424
            ]
          },
          "bbox": [
            -6.047095440356391,
            38.23359401765424,
            -6.047095440356391,
            38.23359401765424
          ]
        }
      ],
      "bbox": [
        -6.047095440356391,
        38.23359401765424,
        -6.047095440356391,
        38.23359401765424
      ]
    },
    "06075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.722841162597215,
              38.88050329348109
            ]
          },
          "bbox": [
            -5.722841162597215,
            38.88050329348109,
            -5.722841162597215,
            38.88050329348109
          ]
        }
      ],
      "bbox": [
        -5.722841162597215,
        38.88050329348109,
        -5.722841162597215,
        38.88050329348109
      ]
    },
    "06076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.8404810426620495,
              38.380126987397425
            ]
          },
          "bbox": [
            -5.8404810426620495,
            38.380126987397425,
            -5.8404810426620495,
            38.380126987397425
          ]
        }
      ],
      "bbox": [
        -5.8404810426620495,
        38.380126987397425,
        -5.8404810426620495,
        38.380126987397425
      ]
    },
    "06077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.710542381937558,
              38.12116423922438
            ]
          },
          "bbox": [
            -5.710542381937558,
            38.12116423922438,
            -5.710542381937558,
            38.12116423922438
          ]
        }
      ],
      "bbox": [
        -5.710542381937558,
        38.12116423922438,
        -5.710542381937558,
        38.12116423922438
      ]
    },
    "06078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.6240062558634305,
              38.694343868111595
            ]
          },
          "bbox": [
            -5.6240062558634305,
            38.694343868111595,
            -5.6240062558634305,
            38.694343868111595
          ]
        }
      ],
      "bbox": [
        -5.6240062558634305,
        38.694343868111595,
        -5.6240062558634305,
        38.694343868111595
      ]
    },
    "06079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.011635694008744,
              38.79375793098841
            ]
          },
          "bbox": [
            -6.011635694008744,
            38.79375793098841,
            -6.011635694008744,
            38.79375793098841
          ]
        }
      ],
      "bbox": [
        -6.011635694008744,
        38.79375793098841,
        -6.011635694008744,
        38.79375793098841
      ]
    },
    "06080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.989734324838877,
              38.968196985927214
            ]
          },
          "bbox": [
            -5.989734324838877,
            38.968196985927214,
            -5.989734324838877,
            38.968196985927214
          ]
        }
      ],
      "bbox": [
        -5.989734324838877,
        38.968196985927214,
        -5.989734324838877,
        38.968196985927214
      ]
    },
    "06081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.406537671671658,
              38.33094937619495
            ]
          },
          "bbox": [
            -6.406537671671658,
            38.33094937619495,
            -6.406537671671658,
            38.33094937619495
          ]
        }
      ],
      "bbox": [
        -6.406537671671658,
        38.33094937619495,
        -6.406537671671658,
        38.33094937619495
      ]
    },
    "06082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.942861378320339,
              38.91519770492465
            ]
          },
          "bbox": [
            -5.942861378320339,
            38.91519770492465,
            -5.942861378320339,
            38.91519770492465
          ]
        }
      ],
      "bbox": [
        -5.942861378320339,
        38.91519770492465,
        -5.942861378320339,
        38.91519770492465
      ]
    },
    "06083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.40506766592215,
              38.97461344691827
            ]
          },
          "bbox": [
            -6.40506766592215,
            38.97461344691827,
            -6.40506766592215,
            38.97461344691827
          ]
        }
      ],
      "bbox": [
        -6.40506766592215,
        38.97461344691827,
        -6.40506766592215,
        38.97461344691827
      ]
    },
    "06084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.280563225591973,
              39.016412569447844
            ]
          },
          "bbox": [
            -6.280563225591973,
            39.016412569447844,
            -6.280563225591973,
            39.016412569447844
          ]
        }
      ],
      "bbox": [
        -6.280563225591973,
        39.016412569447844,
        -6.280563225591973,
        39.016412569447844
      ]
    },
    "06085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.201820663216261,
              38.040935116028486
            ]
          },
          "bbox": [
            -6.201820663216261,
            38.040935116028486,
            -6.201820663216261,
            38.040935116028486
          ]
        }
      ],
      "bbox": [
        -6.201820663216261,
        38.040935116028486,
        -6.201820663216261,
        38.040935116028486
      ]
    },
    "06086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.166723523873257,
              38.12950238367976
            ]
          },
          "bbox": [
            -6.166723523873257,
            38.12950238367976,
            -6.166723523873257,
            38.12950238367976
          ]
        }
      ],
      "bbox": [
        -6.166723523873257,
        38.12950238367976,
        -6.166723523873257,
        38.12950238367976
      ]
    },
    "06087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.4455379477028885,
              38.608641761208325
            ]
          },
          "bbox": [
            -5.4455379477028885,
            38.608641761208325,
            -5.4455379477028885,
            38.608641761208325
          ]
        }
      ],
      "bbox": [
        -5.4455379477028885,
        38.608641761208325,
        -5.4455379477028885,
        38.608641761208325
      ]
    },
    "06088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.578382488841044,
              38.94951443197082
            ]
          },
          "bbox": [
            -6.578382488841044,
            38.94951443197082,
            -6.578382488841044,
            38.94951443197082
          ]
        }
      ],
      "bbox": [
        -6.578382488841044,
        38.94951443197082,
        -6.578382488841044,
        38.94951443197082
      ]
    },
    "06089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.672319486262635,
              38.56849160294789
            ]
          },
          "bbox": [
            -6.672319486262635,
            38.56849160294789,
            -6.672319486262635,
            38.56849160294789
          ]
        }
      ],
      "bbox": [
        -6.672319486262635,
        38.56849160294789,
        -6.672319486262635,
        38.56849160294789
      ]
    },
    "06090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.513612709352768,
              39.06981574210254
            ]
          },
          "bbox": [
            -6.513612709352768,
            39.06981574210254,
            -6.513612709352768,
            39.06981574210254
          ]
        }
      ],
      "bbox": [
        -6.513612709352768,
        39.06981574210254,
        -6.513612709352768,
        39.06981574210254
      ]
    },
    "06091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.487202233233516,
              39.11221464960005
            ]
          },
          "bbox": [
            -5.487202233233516,
            39.11221464960005,
            -5.487202233233516,
            39.11221464960005
          ]
        }
      ],
      "bbox": [
        -5.487202233233516,
        39.11221464960005,
        -5.487202233233516,
        39.11221464960005
      ]
    },
    "06092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.737735842084726,
              38.60576279994584
            ]
          },
          "bbox": [
            -6.737735842084726,
            38.60576279994584,
            -6.737735842084726,
            38.60576279994584
          ]
        }
      ],
      "bbox": [
        -6.737735842084726,
        38.60576279994584,
        -6.737735842084726,
        38.60576279994584
      ]
    },
    "06093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.988385940789785,
              38.25358483444637
            ]
          },
          "bbox": [
            -6.988385940789785,
            38.25358483444637,
            -6.988385940789785,
            38.25358483444637
          ]
        }
      ],
      "bbox": [
        -6.988385940789785,
        38.25358483444637,
        -6.988385940789785,
        38.25358483444637
      ]
    },
    "06094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.039835681545215,
              38.731160701272316
            ]
          },
          "bbox": [
            -6.039835681545215,
            38.731160701272316,
            -6.039835681545215,
            38.731160701272316
          ]
        }
      ],
      "bbox": [
        -6.039835681545215,
        38.731160701272316,
        -6.039835681545215,
        38.731160701272316
      ]
    },
    "06095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.1207833331580925,
              38.65395929898247
            ]
          },
          "bbox": [
            -7.1207833331580925,
            38.65395929898247,
            -7.1207833331580925,
            38.65395929898247
          ]
        }
      ],
      "bbox": [
        -7.1207833331580925,
        38.65395929898247,
        -7.1207833331580925,
        38.65395929898247
      ]
    },
    "06096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.507321706656786,
              39.01870272957727
            ]
          },
          "bbox": [
            -5.507321706656786,
            39.01870272957727,
            -5.507321706656786,
            39.01870272957727
          ]
        }
      ],
      "bbox": [
        -5.507321706656786,
        39.01870272957727,
        -5.507321706656786,
        39.01870272957727
      ]
    },
    "06097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.552298677276987,
              39.01713563001378
            ]
          },
          "bbox": [
            -5.552298677276987,
            39.01713563001378,
            -5.552298677276987,
            39.01713563001378
          ]
        }
      ],
      "bbox": [
        -5.552298677276987,
        39.01713563001378,
        -5.552298677276987,
        39.01713563001378
      ]
    },
    "06098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.154840822341616,
              38.704994998604306
            ]
          },
          "bbox": [
            -6.154840822341616,
            38.704994998604306,
            -6.154840822341616,
            38.704994998604306
          ]
        }
      ],
      "bbox": [
        -6.154840822341616,
        38.704994998604306,
        -6.154840822341616,
        38.704994998604306
      ]
    },
    "06099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.610316416362611,
              38.53478095786745
            ]
          },
          "bbox": [
            -6.610316416362611,
            38.53478095786745,
            -6.610316416362611,
            38.53478095786745
          ]
        }
      ],
      "bbox": [
        -6.610316416362611,
        38.53478095786745,
        -6.610316416362611,
        38.53478095786745
      ]
    },
    "06100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.138734222158145,
              38.83205322108659
            ]
          },
          "bbox": [
            -5.138734222158145,
            38.83205322108659,
            -5.138734222158145,
            38.83205322108659
          ]
        }
      ],
      "bbox": [
        -5.138734222158145,
        38.83205322108659,
        -5.138734222158145,
        38.83205322108659
      ]
    },
    "06101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.595967294604172,
              38.48636492295039
            ]
          },
          "bbox": [
            -5.595967294604172,
            38.48636492295039,
            -5.595967294604172,
            38.48636492295039
          ]
        }
      ],
      "bbox": [
        -5.595967294604172,
        38.48636492295039,
        -5.595967294604172,
        38.48636492295039
      ]
    },
    "06102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.240616676834305,
              39.07916892908769
            ]
          },
          "bbox": [
            -5.240616676834305,
            39.07916892908769,
            -5.240616676834305,
            39.07916892908769
          ]
        }
      ],
      "bbox": [
        -5.240616676834305,
        39.07916892908769,
        -5.240616676834305,
        39.07916892908769
      ]
    },
    "06103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.628892594401196,
              38.87870856861635
            ]
          },
          "bbox": [
            -6.628892594401196,
            38.87870856861635,
            -6.628892594401196,
            38.87870856861635
          ]
        }
      ],
      "bbox": [
        -6.628892594401196,
        38.87870856861635,
        -6.628892594401196,
        38.87870856861635
      ]
    },
    "06104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0678507217354225,
              38.660147685430395
            ]
          },
          "bbox": [
            -6.0678507217354225,
            38.660147685430395,
            -6.0678507217354225,
            38.660147685430395
          ]
        }
      ],
      "bbox": [
        -6.0678507217354225,
        38.660147685430395,
        -6.0678507217354225,
        38.660147685430395
      ]
    },
    "06105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0695489297861425,
              38.08157100161874
            ]
          },
          "bbox": [
            -6.0695489297861425,
            38.08157100161874,
            -6.0695489297861425,
            38.08157100161874
          ]
        }
      ],
      "bbox": [
        -6.0695489297861425,
        38.08157100161874,
        -6.0695489297861425,
        38.08157100161874
      ]
    },
    "06106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.203910072912192,
              38.591865482200994
            ]
          },
          "bbox": [
            -6.203910072912192,
            38.591865482200994,
            -6.203910072912192,
            38.591865482200994
          ]
        }
      ],
      "bbox": [
        -6.203910072912192,
        38.591865482200994,
        -6.203910072912192,
        38.591865482200994
      ]
    },
    "06107": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.625896467084431,
              39.177647307712675
            ]
          },
          "bbox": [
            -6.625896467084431,
            39.177647307712675,
            -6.625896467084431,
            39.177647307712675
          ]
        }
      ],
      "bbox": [
        -6.625896467084431,
        39.177647307712675,
        -6.625896467084431,
        39.177647307712675
      ]
    },
    "06108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.364499848211664,
              38.39452508846385
            ]
          },
          "bbox": [
            -6.364499848211664,
            38.39452508846385,
            -6.364499848211664,
            38.39452508846385
          ]
        }
      ],
      "bbox": [
        -6.364499848211664,
        38.39452508846385,
        -6.364499848211664,
        38.39452508846385
      ]
    },
    "06109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.719452026404717,
              38.762449215937515
            ]
          },
          "bbox": [
            -5.719452026404717,
            38.762449215937515,
            -5.719452026404717,
            38.762449215937515
          ]
        }
      ],
      "bbox": [
        -5.719452026404717,
        38.762449215937515,
        -5.719452026404717,
        38.762449215937515
      ]
    },
    "06110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.941539299821317,
              38.159065729598325
            ]
          },
          "bbox": [
            -5.941539299821317,
            38.159065729598325,
            -5.941539299821317,
            38.159065729598325
          ]
        }
      ],
      "bbox": [
        -5.941539299821317,
        38.159065729598325,
        -5.941539299821317,
        38.159065729598325
      ]
    },
    "06111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.801669705534367,
              39.05091338022739
            ]
          },
          "bbox": [
            -5.801669705534367,
            39.05091338022739,
            -5.801669705534367,
            39.05091338022739
          ]
        }
      ],
      "bbox": [
        -5.801669705534367,
        39.05091338022739,
        -5.801669705534367,
        39.05091338022739
      ]
    },
    "06112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.85843782062328,
              38.590302389309706
            ]
          },
          "bbox": [
            -5.85843782062328,
            38.590302389309706,
            -5.85843782062328,
            38.590302389309706
          ]
        }
      ],
      "bbox": [
        -5.85843782062328,
        38.590302389309706,
        -5.85843782062328,
        38.590302389309706
      ]
    },
    "06113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.245950001019228,
              38.57402201560734
            ]
          },
          "bbox": [
            -6.245950001019228,
            38.57402201560734,
            -6.245950001019228,
            38.57402201560734
          ]
        }
      ],
      "bbox": [
        -6.245950001019228,
        38.57402201560734,
        -6.245950001019228,
        38.57402201560734
      ]
    },
    "06114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.113887214717988,
              38.891903854642166
            ]
          },
          "bbox": [
            -5.113887214717988,
            38.891903854642166,
            -5.113887214717988,
            38.891903854642166
          ]
        }
      ],
      "bbox": [
        -5.113887214717988,
        38.891903854642166,
        -5.113887214717988,
        38.891903854642166
      ]
    },
    "06115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.665799029649493,
              39.09343566987262
            ]
          },
          "bbox": [
            -6.665799029649493,
            39.09343566987262,
            -6.665799029649493,
            39.09343566987262
          ]
        }
      ],
      "bbox": [
        -6.665799029649493,
        39.09343566987262,
        -6.665799029649493,
        39.09343566987262
      ]
    },
    "06116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.773734243238756,
              38.51293057888186
            ]
          },
          "bbox": [
            -6.773734243238756,
            38.51293057888186,
            -6.773734243238756,
            38.51293057888186
          ]
        }
      ],
      "bbox": [
        -6.773734243238756,
        38.51293057888186,
        -6.773734243238756,
        38.51293057888186
      ]
    },
    "06117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.679209269251274,
              38.48999291203496
            ]
          },
          "bbox": [
            -6.679209269251274,
            38.48999291203496,
            -6.679209269251274,
            38.48999291203496
          ]
        }
      ],
      "bbox": [
        -6.679209269251274,
        38.48999291203496,
        -6.679209269251274,
        38.48999291203496
      ]
    },
    "06118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.176345931318023,
              38.92016127161774
            ]
          },
          "bbox": [
            -5.176345931318023,
            38.92016127161774,
            -5.176345931318023,
            38.92016127161774
          ]
        }
      ],
      "bbox": [
        -5.176345931318023,
        38.92016127161774,
        -5.176345931318023,
        38.92016127161774
      ]
    },
    "06119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.183463763285097,
              38.95991004314793
            ]
          },
          "bbox": [
            -6.183463763285097,
            38.95991004314793,
            -6.183463763285097,
            38.95991004314793
          ]
        }
      ],
      "bbox": [
        -6.183463763285097,
        38.95991004314793,
        -6.183463763285097,
        38.95991004314793
      ]
    },
    "06120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.034739426928138,
              39.0243186233205
            ]
          },
          "bbox": [
            -6.034739426928138,
            39.0243186233205,
            -6.034739426928138,
            39.0243186233205
          ]
        }
      ],
      "bbox": [
        -6.034739426928138,
        39.0243186233205,
        -6.034739426928138,
        39.0243186233205
      ]
    },
    "06121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.64166810724345,
              38.64706552142273
            ]
          },
          "bbox": [
            -6.64166810724345,
            38.64706552142273,
            -6.64166810724345,
            38.64706552142273
          ]
        }
      ],
      "bbox": [
        -6.64166810724345,
        38.64706552142273,
        -6.64166810724345,
        38.64706552142273
      ]
    },
    "06122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.3339770940200335,
              38.46714114836696
            ]
          },
          "bbox": [
            -6.3339770940200335,
            38.46714114836696,
            -6.3339770940200335,
            38.46714114836696
          ]
        }
      ],
      "bbox": [
        -6.3339770940200335,
        38.46714114836696,
        -6.3339770940200335,
        38.46714114836696
      ]
    },
    "06123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.029622735460416,
              39.33821563963529
            ]
          },
          "bbox": [
            -7.029622735460416,
            39.33821563963529,
            -7.029622735460416,
            39.33821563963529
          ]
        }
      ],
      "bbox": [
        -7.029622735460416,
        39.33821563963529,
        -7.029622735460416,
        39.33821563963529
      ]
    },
    "06124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.4596405157201495,
              38.13457423970655
            ]
          },
          "bbox": [
            -6.4596405157201495,
            38.13457423970655,
            -6.4596405157201495,
            38.13457423970655
          ]
        }
      ],
      "bbox": [
        -6.4596405157201495,
        38.13457423970655,
        -6.4596405157201495,
        38.13457423970655
      ]
    },
    "06125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.076852916807179,
              38.979491591158954
            ]
          },
          "bbox": [
            -5.076852916807179,
            38.979491591158954,
            -5.076852916807179,
            38.979491591158954
          ]
        }
      ],
      "bbox": [
        -5.076852916807179,
        38.979491591158954,
        -5.076852916807179,
        38.979491591158954
      ]
    },
    "06126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.566448014499975,
              38.73407149940775
            ]
          },
          "bbox": [
            -6.566448014499975,
            38.73407149940775,
            -6.566448014499975,
            38.73407149940775
          ]
        }
      ],
      "bbox": [
        -6.566448014499975,
        38.73407149940775,
        -6.566448014499975,
        38.73407149940775
      ]
    },
    "06127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.218762799089662,
              39.13776207979328
            ]
          },
          "bbox": [
            -5.218762799089662,
            39.13776207979328,
            -5.218762799089662,
            39.13776207979328
          ]
        }
      ],
      "bbox": [
        -5.218762799089662,
        39.13776207979328,
        -5.218762799089662,
        39.13776207979328
      ]
    },
    "06128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.751304582998705,
              38.86161522519912
            ]
          },
          "bbox": [
            -6.751304582998705,
            38.86161522519912,
            -6.751304582998705,
            38.86161522519912
          ]
        }
      ],
      "bbox": [
        -6.751304582998705,
        38.86161522519912,
        -6.751304582998705,
        38.86161522519912
      ]
    },
    "06129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.0037865910467225,
              38.526174624800944
            ]
          },
          "bbox": [
            -7.0037865910467225,
            38.526174624800944,
            -7.0037865910467225,
            38.526174624800944
          ]
        }
      ],
      "bbox": [
        -7.0037865910467225,
        38.526174624800944,
        -7.0037865910467225,
        38.526174624800944
      ]
    },
    "06130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.954333000417053,
              38.989871082029836
            ]
          },
          "bbox": [
            -4.954333000417053,
            38.989871082029836,
            -4.954333000417053,
            38.989871082029836
          ]
        }
      ],
      "bbox": [
        -4.954333000417053,
        38.989871082029836,
        -4.954333000417053,
        38.989871082029836
      ]
    },
    "06131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7656728083525834,
              38.64306143730233
            ]
          },
          "bbox": [
            -6.7656728083525834,
            38.64306143730233,
            -6.7656728083525834,
            38.64306143730233
          ]
        }
      ],
      "bbox": [
        -6.7656728083525834,
        38.64306143730233,
        -6.7656728083525834,
        38.64306143730233
      ]
    },
    "06132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.549671853177909,
              38.90862293251919
            ]
          },
          "bbox": [
            -6.549671853177909,
            38.90862293251919,
            -6.549671853177909,
            38.90862293251919
          ]
        }
      ],
      "bbox": [
        -6.549671853177909,
        38.90862293251919,
        -6.549671853177909,
        38.90862293251919
      ]
    },
    "06133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.375819465167431,
              38.7909988585653
            ]
          },
          "bbox": [
            -6.375819465167431,
            38.7909988585653,
            -6.375819465167431,
            38.7909988585653
          ]
        }
      ],
      "bbox": [
        -6.375819465167431,
        38.7909988585653,
        -6.375819465167431,
        38.7909988585653
      ]
    },
    "06134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.0132047789566245,
              38.15576199602542
            ]
          },
          "bbox": [
            -6.0132047789566245,
            38.15576199602542,
            -6.0132047789566245,
            38.15576199602542
          ]
        }
      ],
      "bbox": [
        -6.0132047789566245,
        38.15576199602542,
        -6.0132047789566245,
        38.15576199602542
      ]
    },
    "06135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.249631130978287,
              38.95838076294947
            ]
          },
          "bbox": [
            -6.249631130978287,
            38.95838076294947,
            -6.249631130978287,
            38.95838076294947
          ]
        }
      ],
      "bbox": [
        -6.249631130978287,
        38.95838076294947,
        -6.249631130978287,
        38.95838076294947
      ]
    },
    "06136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.1851720150374225,
              38.39287040995601
            ]
          },
          "bbox": [
            -6.1851720150374225,
            38.39287040995601,
            -6.1851720150374225,
            38.39287040995601
          ]
        }
      ],
      "bbox": [
        -6.1851720150374225,
        38.39287040995601,
        -6.1851720150374225,
        38.39287040995601
      ]
    },
    "06137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.195707672696629,
              39.27010855862893
            ]
          },
          "bbox": [
            -5.195707672696629,
            39.27010855862893,
            -5.195707672696629,
            39.27010855862893
          ]
        }
      ],
      "bbox": [
        -5.195707672696629,
        39.27010855862893,
        -5.195707672696629,
        39.27010855862893
      ]
    },
    "06138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.063652722751146,
              38.91083034261114
            ]
          },
          "bbox": [
            -6.063652722751146,
            38.91083034261114,
            -6.063652722751146,
            38.91083034261114
          ]
        }
      ],
      "bbox": [
        -6.063652722751146,
        38.91083034261114,
        -6.063652722751146,
        38.91083034261114
      ]
    },
    "06139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.9599965883751596,
              38.44122218618584
            ]
          },
          "bbox": [
            -5.9599965883751596,
            38.44122218618584,
            -5.9599965883751596,
            38.44122218618584
          ]
        }
      ],
      "bbox": [
        -5.9599965883751596,
        38.44122218618584,
        -5.9599965883751596,
        38.44122218618584
      ]
    },
    "06140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.100663098145368,
              38.2404814492627
            ]
          },
          "bbox": [
            -7.100663098145368,
            38.2404814492627,
            -7.100663098145368,
            38.2404814492627
          ]
        }
      ],
      "bbox": [
        -7.100663098145368,
        38.2404814492627,
        -7.100663098145368,
        38.2404814492627
      ]
    },
    "06141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.471918331457348,
              38.26625195357967
            ]
          },
          "bbox": [
            -6.471918331457348,
            38.26625195357967,
            -6.471918331457348,
            38.26625195357967
          ]
        }
      ],
      "bbox": [
        -6.471918331457348,
        38.26625195357967,
        -6.471918331457348,
        38.26625195357967
      ]
    },
    "06142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.534963239863409,
              38.32986697159554
            ]
          },
          "bbox": [
            -6.534963239863409,
            38.32986697159554,
            -6.534963239863409,
            38.32986697159554
          ]
        }
      ],
      "bbox": [
        -6.534963239863409,
        38.32986697159554,
        -6.534963239863409,
        38.32986697159554
      ]
    },
    "06143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.976223316081893,
              38.66749170101286
            ]
          },
          "bbox": [
            -6.976223316081893,
            38.66749170101286,
            -6.976223316081893,
            38.66749170101286
          ]
        }
      ],
      "bbox": [
        -6.976223316081893,
        38.66749170101286,
        -6.976223316081893,
        38.66749170101286
      ]
    },
    "06144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.808565584606165,
              38.206408343822346
            ]
          },
          "bbox": [
            -5.808565584606165,
            38.206408343822346,
            -5.808565584606165,
            38.206408343822346
          ]
        }
      ],
      "bbox": [
        -5.808565584606165,
        38.206408343822346,
        -5.808565584606165,
        38.206408343822346
      ]
    },
    "06145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.230638978281899,
              38.905363981793585
            ]
          },
          "bbox": [
            -6.230638978281899,
            38.905363981793585,
            -6.230638978281899,
            38.905363981793585
          ]
        }
      ],
      "bbox": [
        -6.230638978281899,
        38.905363981793585,
        -6.230638978281899,
        38.905363981793585
      ]
    },
    "06146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.830231576535211,
              38.71155049866128
            ]
          },
          "bbox": [
            -5.830231576535211,
            38.71155049866128,
            -5.830231576535211,
            38.71155049866128
          ]
        }
      ],
      "bbox": [
        -5.830231576535211,
        38.71155049866128,
        -5.830231576535211,
        38.71155049866128
      ]
    },
    "06147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.7968448904360645,
              38.38386922045624
            ]
          },
          "bbox": [
            -6.7968448904360645,
            38.38386922045624,
            -6.7968448904360645,
            38.38386922045624
          ]
        }
      ],
      "bbox": [
        -6.7968448904360645,
        38.38386922045624,
        -6.7968448904360645,
        38.38386922045624
      ]
    },
    "06148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.785403294765999,
              38.36508749277862
            ]
          },
          "bbox": [
            -6.785403294765999,
            38.36508749277862,
            -6.785403294765999,
            38.36508749277862
          ]
        }
      ],
      "bbox": [
        -6.785403294765999,
        38.36508749277862,
        -6.785403294765999,
        38.36508749277862
      ]
    },
    "06149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.338920610188056,
              38.58689389653097
            ]
          },
          "bbox": [
            -6.338920610188056,
            38.58689389653097,
            -6.338920610188056,
            38.58689389653097
          ]
        }
      ],
      "bbox": [
        -6.338920610188056,
        38.58689389653097,
        -6.338920610188056,
        38.58689389653097
      ]
    },
    "06150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.077417783595047,
              38.30979759923976
            ]
          },
          "bbox": [
            -6.077417783595047,
            38.30979759923976,
            -6.077417783595047,
            38.30979759923976
          ]
        }
      ],
      "bbox": [
        -6.077417783595047,
        38.30979759923976,
        -6.077417783595047,
        38.30979759923976
      ]
    },
    "06151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.210203866137919,
              38.84662328481136
            ]
          },
          "bbox": [
            -6.210203866137919,
            38.84662328481136,
            -6.210203866137919,
            38.84662328481136
          ]
        }
      ],
      "bbox": [
        -6.210203866137919,
        38.84662328481136,
        -6.210203866137919,
        38.84662328481136
      ]
    },
    "06152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.548334122046557,
              38.621485322643814
            ]
          },
          "bbox": [
            -6.548334122046557,
            38.621485322643814,
            -6.548334122046557,
            38.621485322643814
          ]
        }
      ],
      "bbox": [
        -6.548334122046557,
        38.621485322643814,
        -6.548334122046557,
        38.621485322643814
      ]
    },
    "06153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.735061773256397,
              38.99485139244013
            ]
          },
          "bbox": [
            -5.735061773256397,
            38.99485139244013,
            -5.735061773256397,
            38.99485139244013
          ]
        }
      ],
      "bbox": [
        -5.735061773256397,
        38.99485139244013,
        -5.735061773256397,
        38.99485139244013
      ]
    },
    "06154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -7.150977442477289,
              38.385434224042584
            ]
          },
          "bbox": [
            -7.150977442477289,
            38.385434224042584,
            -7.150977442477289,
            38.385434224042584
          ]
        }
      ],
      "bbox": [
        -7.150977442477289,
        38.385434224042584,
        -7.150977442477289,
        38.385434224042584
      ]
    },
    "06155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.826590644242047,
              39.117276317211655
            ]
          },
          "bbox": [
            -6.826590644242047,
            39.117276317211655,
            -6.826590644242047,
            39.117276317211655
          ]
        }
      ],
      "bbox": [
        -6.826590644242047,
        39.117276317211655,
        -6.826590644242047,
        39.117276317211655
      ]
    },
    "06156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.802528421467401,
              39.09643952512464
            ]
          },
          "bbox": [
            -5.802528421467401,
            39.09643952512464,
            -5.802528421467401,
            39.09643952512464
          ]
        }
      ],
      "bbox": [
        -5.802528421467401,
        39.09643952512464,
        -5.802528421467401,
        39.09643952512464
      ]
    },
    "06157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.775267800572895,
              39.2276424064025
            ]
          },
          "bbox": [
            -4.775267800572895,
            39.2276424064025,
            -4.775267800572895,
            39.2276424064025
          ]
        }
      ],
      "bbox": [
        -4.775267800572895,
        39.2276424064025,
        -4.775267800572895,
        39.2276424064025
      ]
    },
    "06158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.450260262714043,
              38.436765610956904
            ]
          },
          "bbox": [
            -6.450260262714043,
            38.436765610956904,
            -6.450260262714043,
            38.436765610956904
          ]
        }
      ],
      "bbox": [
        -6.450260262714043,
        38.436765610956904,
        -6.450260262714043,
        38.436765610956904
      ]
    },
    "06159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.992345871267388,
              38.323778785154275
            ]
          },
          "bbox": [
            -6.992345871267388,
            38.323778785154275,
            -6.992345871267388,
            38.323778785154275
          ]
        }
      ],
      "bbox": [
        -6.992345871267388,
        38.323778785154275,
        -6.992345871267388,
        38.323778785154275
      ]
    },
    "06160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.673943652178165,
              38.62062322947149
            ]
          },
          "bbox": [
            -5.673943652178165,
            38.62062322947149,
            -5.673943652178165,
            38.62062322947149
          ]
        }
      ],
      "bbox": [
        -5.673943652178165,
        38.62062322947149,
        -5.673943652178165,
        38.62062322947149
      ]
    },
    "06161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -5.142868319277346,
              38.75987061174711
            ]
          },
          "bbox": [
            -5.142868319277346,
            38.75987061174711,
            -5.142868319277346,
            38.75987061174711
          ]
        }
      ],
      "bbox": [
        -5.142868319277346,
        38.75987061174711,
        -5.142868319277346,
        38.75987061174711
      ]
    },
    "06162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.263121992623654,
              38.80481820974445
            ]
          },
          "bbox": [
            -6.263121992623654,
            38.80481820974445,
            -6.263121992623654,
            38.80481820974445
          ]
        }
      ],
      "bbox": [
        -6.263121992623654,
        38.80481820974445,
        -6.263121992623654,
        38.80481820974445
      ]
    },
    "06901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.693258906385797,
              38.88767603902337
            ]
          },
          "bbox": [
            -6.693258906385797,
            38.88767603902337,
            -6.693258906385797,
            38.88767603902337
          ]
        }
      ],
      "bbox": [
        -6.693258906385797,
        38.88767603902337,
        -6.693258906385797,
        38.88767603902337
      ]
    },
    "06902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.757664070393226,
              38.92349452828399
            ]
          },
          "bbox": [
            -6.757664070393226,
            38.92349452828399,
            -6.757664070393226,
            38.92349452828399
          ]
        }
      ],
      "bbox": [
        -6.757664070393226,
        38.92349452828399,
        -6.757664070393226,
        38.92349452828399
      ]
    },
    "06903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -6.674185431824775,
              38.936750983113036
            ]
          },
          "bbox": [
            -6.674185431824775,
            38.936750983113036,
            -6.674185431824775,
            38.936750983113036
          ]
        }
      ],
      "bbox": [
        -6.674185431824775,
        38.936750983113036,
        -6.674185431824775,
        38.936750983113036
      ]
    },
    "07001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8024130107454073,
              39.723430126626845
            ]
          },
          "bbox": [
            2.8024130107454073,
            39.723430126626845,
            2.8024130107454073,
            39.723430126626845
          ]
        }
      ],
      "bbox": [
        2.8024130107454073,
        39.723430126626845,
        2.8024130107454073,
        39.723430126626845
      ]
    },
    "07002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              4.135260231502588,
              39.91856681694999
            ]
          },
          "bbox": [
            4.135260231502588,
            39.91856681694999,
            4.135260231502588,
            39.91856681694999
          ]
        }
      ],
      "bbox": [
        4.135260231502588,
        39.91856681694999,
        4.135260231502588,
        39.91856681694999
      ]
    },
    "07003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1117881510556042,
              39.84246890177706
            ]
          },
          "bbox": [
            3.1117881510556042,
            39.84246890177706,
            3.1117881510556042,
            39.84246890177706
          ]
        }
      ],
      "bbox": [
        3.1117881510556042,
        39.84246890177706,
        3.1117881510556042,
        39.84246890177706
      ]
    },
    "07004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.903877879904074,
              39.56492598109676
            ]
          },
          "bbox": [
            2.903877879904074,
            39.56492598109676,
            2.903877879904074,
            39.56492598109676
          ]
        }
      ],
      "bbox": [
        2.903877879904074,
        39.56492598109676,
        2.903877879904074,
        39.56492598109676
      ]
    },
    "07005": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.40247037137466,
              39.582073751859575
            ]
          },
          "bbox": [
            2.40247037137466,
            39.582073751859575,
            2.40247037137466,
            39.582073751859575
          ]
        }
      ],
      "bbox": [
        2.40247037137466,
        39.582073751859575,
        2.40247037137466,
        39.582073751859575
      ]
    },
    "07006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.328660993200185,
              39.714606110756634
            ]
          },
          "bbox": [
            3.328660993200185,
            39.714606110756634,
            3.328660993200185,
            39.714606110756634
          ]
        }
      ],
      "bbox": [
        3.328660993200185,
        39.714606110756634,
        3.328660993200185,
        39.714606110756634
      ]
    },
    "07007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.527234002001316,
              39.68121429051085
            ]
          },
          "bbox": [
            2.527234002001316,
            39.68121429051085,
            2.527234002001316,
            39.68121429051085
          ]
        }
      ],
      "bbox": [
        2.527234002001316,
        39.68121429051085,
        2.527234002001316,
        39.68121429051085
      ]
    },
    "07008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8567244954019126,
              39.684493014258486
            ]
          },
          "bbox": [
            2.8567244954019126,
            39.684493014258486,
            2.8567244954019126,
            39.684493014258486
          ]
        }
      ],
      "bbox": [
        2.8567244954019126,
        39.684493014258486,
        2.8567244954019126,
        39.684493014258486
      ]
    },
    "07009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9850661460003476,
              39.75782484462377
            ]
          },
          "bbox": [
            2.9850661460003476,
            39.75782484462377,
            2.9850661460003476,
            39.75782484462377
          ]
        }
      ],
      "bbox": [
        2.9850661460003476,
        39.75782484462377,
        2.9850661460003476,
        39.75782484462377
      ]
    },
    "07010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7125037326880688,
              39.70766996849571
            ]
          },
          "bbox": [
            2.7125037326880688,
            39.70766996849571,
            2.7125037326880688,
            39.70766996849571
          ]
        }
      ],
      "bbox": [
        2.7125037326880688,
        39.70766996849571,
        2.7125037326880688,
        39.70766996849571
      ]
    },
    "07011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.508144988236965,
              39.550927328792106
            ]
          },
          "bbox": [
            2.508144988236965,
            39.550927328792106,
            2.508144988236965,
            39.550927328792106
          ]
        }
      ],
      "bbox": [
        2.508144988236965,
        39.550927328792106,
        2.508144988236965,
        39.550927328792106
      ]
    },
    "07012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.966613692862766,
              39.79754213520659
            ]
          },
          "bbox": [
            2.966613692862766,
            39.79754213520659,
            2.966613692862766,
            39.79754213520659
          ]
        }
      ],
      "bbox": [
        2.966613692862766,
        39.79754213520659,
        2.966613692862766,
        39.79754213520659
      ]
    },
    "07013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0145835025067558,
              39.40683349637801
            ]
          },
          "bbox": [
            3.0145835025067558,
            39.40683349637801,
            3.0145835025067558,
            39.40683349637801
          ]
        }
      ],
      "bbox": [
        3.0145835025067558,
        39.40683349637801,
        3.0145835025067558,
        39.40683349637801
      ]
    },
    "07014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.427319947388996,
              39.69717108418758
            ]
          },
          "bbox": [
            3.427319947388996,
            39.69717108418758,
            3.427319947388996,
            39.69717108418758
          ]
        }
      ],
      "bbox": [
        3.427319947388996,
        39.69717108418758,
        3.427319947388996,
        39.69717108418758
      ]
    },
    "07015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.894008064348019,
              39.99482627852239
            ]
          },
          "bbox": [
            3.894008064348019,
            39.99482627852239,
            3.894008064348019,
            39.99482627852239
          ]
        }
      ],
      "bbox": [
        3.894008064348019,
        39.99482627852239,
        3.894008064348019,
        39.99482627852239
      ]
    },
    "07016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.817955016571696,
              39.66497872273829
            ]
          },
          "bbox": [
            2.817955016571696,
            39.66497872273829,
            2.817955016571696,
            39.66497872273829
          ]
        }
      ],
      "bbox": [
        2.817955016571696,
        39.66497872273829,
        2.817955016571696,
        39.66497872273829
      ]
    },
    "07017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9494004786069,
              39.65552460691933
            ]
          },
          "bbox": [
            2.9494004786069,
            39.65552460691933,
            2.9494004786069,
            39.65552460691933
          ]
        }
      ],
      "bbox": [
        2.9494004786069,
        39.65552460691933,
        2.9494004786069,
        39.65552460691933
      ]
    },
    "07018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.648561792744153,
              39.74871569341685
            ]
          },
          "bbox": [
            2.648561792744153,
            39.74871569341685,
            2.648561792744153,
            39.74871569341685
          ]
        }
      ],
      "bbox": [
        2.648561792744153,
        39.74871569341685,
        2.648561792744153,
        39.74871569341685
      ]
    },
    "07019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8479602911260793,
              39.82790546611544
            ]
          },
          "bbox": [
            2.8479602911260793,
            39.82790546611544,
            2.8479602911260793,
            39.82790546611544
          ]
        }
      ],
      "bbox": [
        2.8479602911260793,
        39.82790546611544,
        2.8479602911260793,
        39.82790546611544
      ]
    },
    "07020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.586651499076396,
              39.6629178722455
            ]
          },
          "bbox": [
            2.586651499076396,
            39.6629178722455,
            2.586651499076396,
            39.6629178722455
          ]
        }
      ],
      "bbox": [
        2.586651499076396,
        39.6629178722455,
        2.586651499076396,
        39.6629178722455
      ]
    },
    "07021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4763374972943906,
              39.648720138180806
            ]
          },
          "bbox": [
            2.4763374972943906,
            39.648720138180806,
            2.4763374972943906,
            39.648720138180806
          ]
        }
      ],
      "bbox": [
        2.4763374972943906,
        39.648720138180806,
        2.4763374972943906,
        39.648720138180806
      ]
    },
    "07022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.159993662895571,
              39.45152157007335
            ]
          },
          "bbox": [
            3.159993662895571,
            39.45152157007335,
            3.159993662895571,
            39.45152157007335
          ]
        }
      ],
      "bbox": [
        3.159993662895571,
        39.45152157007335,
        3.159993662895571,
        39.45152157007335
      ]
    },
    "07023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              4.003040818634542,
              39.990336185352454
            ]
          },
          "bbox": [
            4.003040818634542,
            39.990336185352454,
            4.003040818634542,
            39.990336185352454
          ]
        }
      ],
      "bbox": [
        4.003040818634542,
        39.990336185352454,
        4.003040818634542,
        39.990336185352454
      ]
    },
    "07024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4584181192431698,
              38.692098034903054
            ]
          },
          "bbox": [
            1.4584181192431698,
            38.692098034903054,
            1.4584181192431698,
            38.692098034903054
          ]
        }
      ],
      "bbox": [
        1.4584181192431698,
        38.692098034903054,
        1.4584181192431698,
        38.692098034903054
      ]
    },
    "07025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.75125435508486,
              39.796161992605455
            ]
          },
          "bbox": [
            2.75125435508486,
            39.796161992605455,
            2.75125435508486,
            39.796161992605455
          ]
        }
      ],
      "bbox": [
        2.75125435508486,
        39.796161992605455,
        2.75125435508486,
        39.796161992605455
      ]
    },
    "07026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4193810140090846,
              38.91371036938454
            ]
          },
          "bbox": [
            1.4193810140090846,
            38.91371036938454,
            1.4193810140090846,
            38.91371036938454
          ]
        }
      ],
      "bbox": [
        1.4193810140090846,
        38.91371036938454,
        1.4193810140090846,
        38.91371036938454
      ]
    },
    "07027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.942136308262844,
              39.7117411207735
            ]
          },
          "bbox": [
            2.942136308262844,
            39.7117411207735,
            2.942136308262844,
            39.7117411207735
          ]
        }
      ],
      "bbox": [
        2.942136308262844,
        39.7117411207735,
        2.942136308262844,
        39.7117411207735
      ]
    },
    "07028": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9762115528849367,
              39.61887731685692
            ]
          },
          "bbox": [
            2.9762115528849367,
            39.61887731685692,
            2.9762115528849367,
            39.61887731685692
          ]
        }
      ],
      "bbox": [
        2.9762115528849367,
        39.61887731685692,
        2.9762115528849367,
        39.61887731685692
      ]
    },
    "07029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.858408746951982,
              39.72016114955844
            ]
          },
          "bbox": [
            2.858408746951982,
            39.72016114955844,
            2.858408746951982,
            39.72016114955844
          ]
        }
      ],
      "bbox": [
        2.858408746951982,
        39.72016114955844,
        2.858408746951982,
        39.72016114955844
      ]
    },
    "07030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.023930603727582,
              39.69973599795065
            ]
          },
          "bbox": [
            3.023930603727582,
            39.69973599795065,
            3.023930603727582,
            39.69973599795065
          ]
        }
      ],
      "bbox": [
        3.023930603727582,
        39.69973599795065,
        3.023930603727582,
        39.69973599795065
      ]
    },
    "07031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8513266208825763,
              39.4501156528257
            ]
          },
          "bbox": [
            2.8513266208825763,
            39.4501156528257,
            2.8513266208825763,
            39.4501156528257
          ]
        }
      ],
      "bbox": [
        2.8513266208825763,
        39.4501156528257,
        2.8513266208825763,
        39.4501156528257
      ]
    },
    "07032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              4.2323247097010706,
              39.915453210027955
            ]
          },
          "bbox": [
            4.2323247097010706,
            39.915453210027955,
            4.2323247097010706,
            39.915453210027955
          ]
        }
      ],
      "bbox": [
        4.2323247097010706,
        39.915453210027955,
        4.2323247097010706,
        39.915453210027955
      ]
    },
    "07033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.235368670454324,
              39.55506442264741
            ]
          },
          "bbox": [
            3.235368670454324,
            39.55506442264741,
            3.235368670454324,
            39.55506442264741
          ]
        }
      ],
      "bbox": [
        3.235368670454324,
        39.55506442264741,
        3.235368670454324,
        39.55506442264741
      ]
    },
    "07034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.864208284707296,
              39.76088006865653
            ]
          },
          "bbox": [
            2.864208284707296,
            39.76088006865653,
            2.864208284707296,
            39.76088006865653
          ]
        }
      ],
      "bbox": [
        2.864208284707296,
        39.76088006865653,
        2.864208284707296,
        39.76088006865653
      ]
    },
    "07035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.09478990201418,
              39.67444475266069
            ]
          },
          "bbox": [
            3.09478990201418,
            39.67444475266069,
            3.09478990201418,
            39.67444475266069
          ]
        }
      ],
      "bbox": [
        3.09478990201418,
        39.67444475266069,
        3.09478990201418,
        39.67444475266069
      ]
    },
    "07036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7286014135341596,
              39.623767337248125
            ]
          },
          "bbox": [
            2.7286014135341596,
            39.623767337248125,
            2.7286014135341596,
            39.623767337248125
          ]
        }
      ],
      "bbox": [
        2.7286014135341596,
        39.623767337248125,
        2.7286014135341596,
        39.623767337248125
      ]
    },
    "07037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              4.112085301463017,
              40.01234218882027
            ]
          },
          "bbox": [
            4.112085301463017,
            40.01234218882027,
            4.112085301463017,
            40.01234218882027
          ]
        }
      ],
      "bbox": [
        4.112085301463017,
        40.01234218882027,
        4.112085301463017,
        40.01234218882027
      ]
    },
    "07038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.9911298589097726,
              39.56463353834867
            ]
          },
          "bbox": [
            2.9911298589097726,
            39.56463353834867,
            2.9911298589097726,
            39.56463353834867
          ]
        }
      ],
      "bbox": [
        2.9911298589097726,
        39.56463353834867,
        2.9911298589097726,
        39.56463353834867
      ]
    },
    "07039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0855739840404257,
              39.75788727983587
            ]
          },
          "bbox": [
            3.0855739840404257,
            39.75788727983587,
            3.0855739840404257,
            39.75788727983587
          ]
        }
      ],
      "bbox": [
        3.0855739840404257,
        39.75788727983587,
        3.0855739840404257,
        39.75788727983587
      ]
    },
    "07040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.715225517121205,
              39.55648021948689
            ]
          },
          "bbox": [
            2.715225517121205,
            39.55648021948689,
            2.715225517121205,
            39.55648021948689
          ]
        }
      ],
      "bbox": [
        2.715225517121205,
        39.55648021948689,
        2.715225517121205,
        39.55648021948689
      ]
    },
    "07041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1430480460487122,
              39.628642538163106
            ]
          },
          "bbox": [
            3.1430480460487122,
            39.628642538163106,
            3.1430480460487122,
            39.628642538163106
          ]
        }
      ],
      "bbox": [
        3.1430480460487122,
        39.628642538163106,
        3.1430480460487122,
        39.628642538163106
      ]
    },
    "07042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0294985848451135,
              39.88673421582177
            ]
          },
          "bbox": [
            3.0294985848451135,
            39.88673421582177,
            3.0294985848451135,
            39.88673421582177
          ]
        }
      ],
      "bbox": [
        3.0294985848451135,
        39.88673421582177,
        3.0294985848451135,
        39.88673421582177
      ]
    },
    "07043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.030836882022188,
              39.50736673931156
            ]
          },
          "bbox": [
            3.030836882022188,
            39.50736673931156,
            3.030836882022188,
            39.50736673931156
          ]
        }
      ],
      "bbox": [
        3.030836882022188,
        39.50736673931156,
        3.030836882022188,
        39.50736673931156
      ]
    },
    "07044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0289801444152538,
              39.78041504518454
            ]
          },
          "bbox": [
            3.0289801444152538,
            39.78041504518454,
            3.0289801444152538,
            39.78041504518454
          ]
        }
      ],
      "bbox": [
        3.0289801444152538,
        39.78041504518454,
        3.0289801444152538,
        39.78041504518454
      ]
    },
    "07045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.528933998974481,
              39.62677145514136
            ]
          },
          "bbox": [
            2.528933998974481,
            39.62677145514136,
            2.528933998974481,
            39.62677145514136
          ]
        }
      ],
      "bbox": [
        2.528933998974481,
        39.62677145514136,
        2.528933998974481,
        39.62677145514136
      ]
    },
    "07046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3586308483328338,
              38.99645485747174
            ]
          },
          "bbox": [
            1.3586308483328338,
            38.99645485747174,
            1.3586308483328338,
            38.99645485747174
          ]
        }
      ],
      "bbox": [
        1.3586308483328338,
        38.99645485747174,
        1.3586308483328338,
        38.99645485747174
      ]
    },
    "07047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.8979326321231955,
              39.64039124936155
            ]
          },
          "bbox": [
            2.8979326321231955,
            39.64039124936155,
            2.8979326321231955,
            39.64039124936155
          ]
        }
      ],
      "bbox": [
        2.8979326321231955,
        39.64039124936155,
        2.8979326321231955,
        39.64039124936155
      ]
    },
    "07048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3083983702425883,
              38.908234605942525
            ]
          },
          "bbox": [
            1.3083983702425883,
            38.908234605942525,
            1.3083983702425883,
            38.908234605942525
          ]
        }
      ],
      "bbox": [
        1.3083983702425883,
        38.908234605942525,
        1.3083983702425883,
        38.908234605942525
      ]
    },
    "07049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.045320172520131,
              39.58797648289199
            ]
          },
          "bbox": [
            3.045320172520131,
            39.58797648289199,
            3.045320172520131,
            39.58797648289199
          ]
        }
      ],
      "bbox": [
        3.045320172520131,
        39.58797648289199,
        3.045320172520131,
        39.58797648289199
      ]
    },
    "07050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4876613350446093,
              39.0621341608895
            ]
          },
          "bbox": [
            1.4876613350446093,
            39.0621341608895,
            1.4876613350446093,
            39.0621341608895
          ]
        }
      ],
      "bbox": [
        1.4876613350446093,
        39.0621341608895,
        1.4876613350446093,
        39.0621341608895
      ]
    },
    "07051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.30538086907361,
              39.617205597133136
            ]
          },
          "bbox": [
            3.30538086907361,
            39.617205597133136,
            3.30538086907361,
            39.617205597133136
          ]
        }
      ],
      "bbox": [
        3.30538086907361,
        39.617205597133136,
        3.30538086907361,
        39.617205597133136
      ]
    },
    "07052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              4.253939034212181,
              39.836650000528145
            ]
          },
          "bbox": [
            4.253939034212181,
            39.836650000528145,
            4.253939034212181,
            39.836650000528145
          ]
        }
      ],
      "bbox": [
        4.253939034212181,
        39.836650000528145,
        4.253939034212181,
        39.836650000528145
      ]
    },
    "07053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.834169935738858,
              39.61877571725532
            ]
          },
          "bbox": [
            2.834169935738858,
            39.61877571725532,
            2.834169935738858,
            39.61877571725532
          ]
        }
      ],
      "bbox": [
        2.834169935738858,
        39.61877571725532,
        2.834169935738858,
        39.61877571725532
      ]
    },
    "07054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4971380855179421,
              38.992990480168594
            ]
          },
          "bbox": [
            1.4971380855179421,
            38.992990480168594,
            1.4971380855179421,
            38.992990480168594
          ]
        }
      ],
      "bbox": [
        1.4971380855179421,
        38.992990480168594,
        1.4971380855179421,
        38.992990480168594
      ]
    },
    "07055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1547374206148318,
              39.72103065493649
            ]
          },
          "bbox": [
            3.1547374206148318,
            39.72103065493649,
            3.1547374206148318,
            39.72103065493649
          ]
        }
      ],
      "bbox": [
        3.1547374206148318,
        39.72103065493649,
        3.1547374206148318,
        39.72103065493649
      ]
    },
    "07056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7719924079202913,
              39.664157087662396
            ]
          },
          "bbox": [
            2.7719924079202913,
            39.664157087662396,
            2.7719924079202913,
            39.664157087662396
          ]
        }
      ],
      "bbox": [
        2.7719924079202913,
        39.664157087662396,
        2.7719924079202913,
        39.664157087662396
      ]
    },
    "07057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1320985677049533,
              39.34927144596659
            ]
          },
          "bbox": [
            3.1320985677049533,
            39.34927144596659,
            3.1320985677049533,
            39.34927144596659
          ]
        }
      ],
      "bbox": [
        3.1320985677049533,
        39.34927144596659,
        3.1320985677049533,
        39.34927144596659
      ]
    },
    "07058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.908954695791545,
              39.76874658132036
            ]
          },
          "bbox": [
            2.908954695791545,
            39.76874658132036,
            2.908954695791545,
            39.76874658132036
          ]
        }
      ],
      "bbox": [
        2.908954695791545,
        39.76874658132036,
        2.908954695791545,
        39.76874658132036
      ]
    },
    "07059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.04342503924031,
              39.330890779439976
            ]
          },
          "bbox": [
            3.04342503924031,
            39.330890779439976,
            3.04342503924031,
            39.330890779439976
          ]
        }
      ],
      "bbox": [
        3.04342503924031,
        39.330890779439976,
        3.04342503924031,
        39.330890779439976
      ]
    },
    "07060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.0212069343171115,
              39.646245924504875
            ]
          },
          "bbox": [
            3.0212069343171115,
            39.646245924504875,
            3.0212069343171115,
            39.646245924504875
          ]
        }
      ],
      "bbox": [
        3.0212069343171115,
        39.646245924504875,
        3.0212069343171115,
        39.646245924504875
      ]
    },
    "07061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7063559316161623,
              39.77498670235267
            ]
          },
          "bbox": [
            2.7063559316161623,
            39.77498670235267,
            2.7063559316161623,
            39.77498670235267
          ]
        }
      ],
      "bbox": [
        2.7063559316161623,
        39.77498670235267,
        2.7063559316161623,
        39.77498670235267
      ]
    },
    "07062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.3712097836926374,
              39.640196041182904
            ]
          },
          "bbox": [
            3.3712097836926374,
            39.640196041182904,
            3.3712097836926374,
            39.640196041182904
          ]
        }
      ],
      "bbox": [
        3.3712097836926374,
        39.640196041182904,
        3.3712097836926374,
        39.640196041182904
      ]
    },
    "07063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.620016984322991,
              39.70401345204942
            ]
          },
          "bbox": [
            2.620016984322991,
            39.70401345204942,
            2.620016984322991,
            39.70401345204942
          ]
        }
      ],
      "bbox": [
        2.620016984322991,
        39.70401345204942,
        2.620016984322991,
        39.70401345204942
      ]
    },
    "07064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              4.285930206513501,
              39.864413733123264
            ]
          },
          "bbox": [
            4.285930206513501,
            39.864413733123264,
            4.285930206513501,
            39.864413733123264
          ]
        }
      ],
      "bbox": [
        4.285930206513501,
        39.864413733123264,
        4.285930206513501,
        39.864413733123264
      ]
    },
    "07065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1174179115685807,
              39.55694089424124
            ]
          },
          "bbox": [
            3.1174179115685807,
            39.55694089424124,
            3.1174179115685807,
            39.55694089424124
          ]
        }
      ],
      "bbox": [
        3.1174179115685807,
        39.55694089424124,
        3.1174179115685807,
        39.55694089424124
      ]
    },
    "07901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              3.1327951418241846,
              39.6558831137863
            ]
          },
          "bbox": [
            3.1327951418241846,
            39.6558831137863,
            3.1327951418241846,
            39.6558831137863
          ]
        }
      ],
      "bbox": [
        3.1327951418241846,
        39.6558831137863,
        3.1327951418241846,
        39.6558831137863
      ]
    },
    "07902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              4.037761023837748,
              39.94323347899154
            ]
          },
          "bbox": [
            4.037761023837748,
            39.94323347899154,
            4.037761023837748,
            39.94323347899154
          ]
        }
      ],
      "bbox": [
        4.037761023837748,
        39.94323347899154,
        4.037761023837748,
        39.94323347899154
      ]
    },
    "08001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.909751029123221,
              41.51647555621521
            ]
          },
          "bbox": [
            1.909751029123221,
            41.51647555621521,
            1.909751029123221,
            41.51647555621521
          ]
        }
      ],
      "bbox": [
        1.909751029123221,
        41.51647555621521,
        1.909751029123221,
        41.51647555621521
      ]
    },
    "08002": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6179637546779935,
              41.729570401351154
            ]
          },
          "bbox": [
            1.6179637546779935,
            41.729570401351154,
            1.6179637546779935,
            41.729570401351154
          ]
        }
      ],
      "bbox": [
        1.6179637546779935,
        41.729570401351154,
        1.6179637546779935,
        41.729570401351154
      ]
    },
    "08003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2917162182108783,
              41.499056699757666
            ]
          },
          "bbox": [
            2.2917162182108783,
            41.499056699757666,
            2.2917162182108783,
            41.499056699757666
          ]
        }
      ],
      "bbox": [
        2.2917162182108783,
        41.499056699757666,
        2.2917162182108783,
        41.499056699757666
      ]
    },
    "08004": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.091968728619014,
              42.121174480997304
            ]
          },
          "bbox": [
            2.091968728619014,
            42.121174480997304,
            2.091968728619014,
            42.121174480997304
          ]
        }
      ],
      "bbox": [
        2.091968728619014,
        42.121174480997304,
        2.091968728619014,
        42.121174480997304
      ]
    },
    "08006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.547265301219887,
              41.58295640186512
            ]
          },
          "bbox": [
            2.547265301219887,
            41.58295640186512,
            2.547265301219887,
            41.58295640186512
          ]
        }
      ],
      "bbox": [
        2.547265301219887,
        41.58295640186512,
        2.547265301219887,
        41.58295640186512
      ]
    },
    "08007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.530730777072716,
              41.61007091767846
            ]
          },
          "bbox": [
            2.530730777072716,
            41.61007091767846,
            2.530730777072716,
            41.61007091767846
          ]
        }
      ],
      "bbox": [
        2.530730777072716,
        41.61007091767846,
        2.530730777072716,
        41.61007091767846
      ]
    },
    "08008": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.454649510362651,
              41.595403009705386
            ]
          },
          "bbox": [
            1.454649510362651,
            41.595403009705386,
            1.454649510362651,
            41.595403009705386
          ]
        }
      ],
      "bbox": [
        1.454649510362651,
        41.595403009705386,
        1.454649510362651,
        41.595403009705386
      ]
    },
    "08009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.391415808723335,
              41.56218314610717
            ]
          },
          "bbox": [
            2.391415808723335,
            41.56218314610717,
            2.391415808723335,
            41.56218314610717
          ]
        }
      ],
      "bbox": [
        2.391415808723335,
        41.56218314610717,
        2.391415808723335,
        41.56218314610717
      ]
    },
    "08010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9569087616693268,
              41.79624961443771
            ]
          },
          "bbox": [
            1.9569087616693268,
            41.79624961443771,
            1.9569087616693268,
            41.79624961443771
          ]
        }
      ],
      "bbox": [
        1.9569087616693268,
        41.79624961443771,
        1.9569087616693268,
        41.79624961443771
      ]
    },
    "08011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.830740308192018,
              42.065756724157865
            ]
          },
          "bbox": [
            1.830740308192018,
            42.065756724157865,
            1.830740308192018,
            42.065756724157865
          ]
        }
      ],
      "bbox": [
        1.830740308192018,
        42.065756724157865,
        1.830740308192018,
        42.065756724157865
      ]
    },
    "08012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9765444684699318,
              41.860399780239725
            ]
          },
          "bbox": [
            1.9765444684699318,
            41.860399780239725,
            1.9765444684699318,
            41.860399780239725
          ]
        }
      ],
      "bbox": [
        1.9765444684699318,
        41.860399780239725,
        1.9765444684699318,
        41.860399780239725
      ]
    },
    "08013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7830281348365855,
              41.35229341355319
            ]
          },
          "bbox": [
            1.7830281348365855,
            41.35229341355319,
            1.7830281348365855,
            41.35229341355319
          ]
        }
      ],
      "bbox": [
        1.7830281348365855,
        41.35229341355319,
        1.7830281348365855,
        41.35229341355319
      ]
    },
    "08014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2644508765407205,
              41.78147346174565
            ]
          },
          "bbox": [
            2.2644508765407205,
            41.78147346174565,
            2.2644508765407205,
            41.78147346174565
          ]
        }
      ],
      "bbox": [
        2.2644508765407205,
        41.78147346174565,
        2.2644508765407205,
        41.78147346174565
      ]
    },
    "08015": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2366867426258943,
              41.461896644581714
            ]
          },
          "bbox": [
            2.2366867426258943,
            41.461896644581714,
            2.2366867426258943,
            41.461896644581714
          ]
        }
      ],
      "bbox": [
        2.2366867426258943,
        41.461896644581714,
        2.2366867426258943,
        41.461896644581714
      ]
    },
    "08016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8587548862840624,
              42.27887229157597
            ]
          },
          "bbox": [
            1.8587548862840624,
            42.27887229157597,
            1.8587548862840624,
            42.27887229157597
          ]
        }
      ],
      "bbox": [
        1.8587548862840624,
        42.27887229157597,
        1.8587548862840624,
        42.27887229157597
      ]
    },
    "08017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.211693569423995,
              41.81799656015708
            ]
          },
          "bbox": [
            2.211693569423995,
            41.81799656015708,
            2.211693569423995,
            41.81799656015708
          ]
        }
      ],
      "bbox": [
        2.211693569423995,
        41.81799656015708,
        2.211693569423995,
        41.81799656015708
      ]
    },
    "08018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8652881928278184,
              41.86615221271697
            ]
          },
          "bbox": [
            1.8652881928278184,
            41.86615221271697,
            1.8652881928278184,
            41.86615221271697
          ]
        }
      ],
      "bbox": [
        1.8652881928278184,
        41.86615221271697,
        1.8652881928278184,
        41.86615221271697
      ]
    },
    "08019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1512459682215614,
              41.39976744890764
            ]
          },
          "bbox": [
            2.1512459682215614,
            41.39976744890764,
            2.1512459682215614,
            41.39976744890764
          ]
        }
      ],
      "bbox": [
        2.1512459682215614,
        41.39976744890764,
        2.1512459682215614,
        41.39976744890764
      ]
    },
    "08020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9011822738000614,
              41.31858129177076
            ]
          },
          "bbox": [
            1.9011822738000614,
            41.31858129177076,
            1.9011822738000614,
            41.31858129177076
          ]
        }
      ],
      "bbox": [
        1.9011822738000614,
        41.31858129177076,
        1.9011822738000614,
        41.31858129177076
      ]
    },
    "08021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4475739086723736,
              41.513370014193875
            ]
          },
          "bbox": [
            1.4475739086723736,
            41.513370014193875,
            1.4475739086723736,
            41.513370014193875
          ]
        }
      ],
      "bbox": [
        1.4475739086723736,
        41.513370014193875,
        1.4475739086723736,
        41.513370014193875
      ]
    },
    "08022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8475719078683512,
              42.10069978892186
            ]
          },
          "bbox": [
            1.8475719078683512,
            42.10069978892186,
            1.8475719078683512,
            42.10069978892186
          ]
        }
      ],
      "bbox": [
        1.8475719078683512,
        42.10069978892186,
        1.8475719078683512,
        42.10069978892186
      ]
    },
    "08023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2050323768353595,
              41.68170643494143
            ]
          },
          "bbox": [
            2.2050323768353595,
            41.68170643494143,
            2.2050323768353595,
            41.68170643494143
          ]
        }
      ],
      "bbox": [
        2.2050323768353595,
        41.68170643494143,
        2.2050323768353595,
        41.68170643494143
      ]
    },
    "08024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0124863504687447,
              42.128410124181585
            ]
          },
          "bbox": [
            2.0124863504687447,
            42.128410124181585,
            2.0124863504687447,
            42.128410124181585
          ]
        }
      ],
      "bbox": [
        2.0124863504687447,
        42.128410124181585,
        2.0124863504687447,
        42.128410124181585
      ]
    },
    "08025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7635032640736608,
              41.60992910772642
            ]
          },
          "bbox": [
            1.7635032640736608,
            41.60992910772642,
            1.7635032640736608,
            41.60992910772642
          ]
        }
      ],
      "bbox": [
        1.7635032640736608,
        41.60992910772642,
        1.7635032640736608,
        41.60992910772642
      ]
    },
    "08026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.316604319763093,
              41.804054004027826
            ]
          },
          "bbox": [
            2.316604319763093,
            41.804054004027826,
            2.316604319763093,
            41.804054004027826
          ]
        }
      ],
      "bbox": [
        2.316604319763093,
        41.804054004027826,
        2.316604319763093,
        41.804054004027826
      ]
    },
    "08027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6897887679846413,
              41.371557379623425
            ]
          },
          "bbox": [
            1.6897887679846413,
            41.371557379623425,
            1.6897887679846413,
            41.371557379623425
          ]
        }
      ],
      "bbox": [
        1.6897887679846413,
        41.371557379623425,
        1.6897887679846413,
        41.371557379623425
      ]
    },
    "08029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4009337034043634,
              41.52582199367143
            ]
          },
          "bbox": [
            2.4009337034043634,
            41.52582199367143,
            2.4009337034043634,
            41.52582199367143
          ]
        }
      ],
      "bbox": [
        2.4009337034043634,
        41.52582199367143,
        2.4009337034043634,
        41.52582199367143
      ]
    },
    "08030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3675440104348517,
              41.52959291327625
            ]
          },
          "bbox": [
            2.3675440104348517,
            41.52959291327625,
            2.3675440104348517,
            41.52959291327625
          ]
        }
      ],
      "bbox": [
        2.3675440104348517,
        41.52959291327625,
        2.3675440104348517,
        41.52959291327625
      ]
    },
    "08031": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5065404122718307,
              41.72169215064026
            ]
          },
          "bbox": [
            1.5065404122718307,
            41.72169215064026,
            1.5065404122718307,
            41.72169215064026
          ]
        }
      ],
      "bbox": [
        1.5065404122718307,
        41.72169215064026,
        1.5065404122718307,
        41.72169215064026
      ]
    },
    "08033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.15713958754565,
              41.64459493883472
            ]
          },
          "bbox": [
            2.15713958754565,
            41.64459493883472,
            2.15713958754565,
            41.64459493883472
          ]
        }
      ],
      "bbox": [
        2.15713958754565,
        41.64459493883472,
        2.15713958754565,
        41.64459493883472
      ]
    },
    "08034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9697215002186939,
              41.77257685486929
            ]
          },
          "bbox": [
            1.9697215002186939,
            41.77257685486929,
            1.9697215002186939,
            41.77257685486929
          ]
        }
      ],
      "bbox": [
        1.9697215002186939,
        41.77257685486929,
        1.9697215002186939,
        41.77257685486929
      ]
    },
    "08035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6462290643804005,
              41.623146712369
            ]
          },
          "bbox": [
            2.6462290643804005,
            41.623146712369,
            2.6462290643804005,
            41.623146712369
          ]
        }
      ],
      "bbox": [
        2.6462290643804005,
        41.623146712369,
        2.6462290643804005,
        41.623146712369
      ]
    },
    "08036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.485438304344736,
              41.750041733342044
            ]
          },
          "bbox": [
            1.485438304344736,
            41.750041733342044,
            1.485438304344736,
            41.750041733342044
          ]
        }
      ],
      "bbox": [
        1.485438304344736,
        41.750041733342044,
        1.485438304344736,
        41.750041733342044
      ]
    },
    "08037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2977676131531664,
              41.925830272678446
            ]
          },
          "bbox": [
            2.2977676131531664,
            41.925830272678446,
            2.2977676131531664,
            41.925830272678446
          ]
        }
      ],
      "bbox": [
        2.2977676131531664,
        41.925830272678446,
        2.2977676131531664,
        41.925830272678446
      ]
    },
    "08038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7846720341833224,
              41.80025499840961
            ]
          },
          "bbox": [
            1.7846720341833224,
            41.80025499840961,
            1.7846720341833224,
            41.80025499840961
          ]
        }
      ],
      "bbox": [
        1.7846720341833224,
        41.80025499840961,
        1.7846720341833224,
        41.80025499840961
      ]
    },
    "08039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4716377602871398,
              41.72555733573776
            ]
          },
          "bbox": [
            2.4716377602871398,
            41.72555733573776,
            2.4716377602871398,
            41.72555733573776
          ]
        }
      ],
      "bbox": [
        2.4716377602871398,
        41.72555733573776,
        2.4716377602871398,
        41.72555733573776
      ]
    },
    "08040": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5763919019317147,
              41.59863362641698
            ]
          },
          "bbox": [
            2.5763919019317147,
            41.59863362641698,
            2.5763919019317147,
            41.59863362641698
          ]
        }
      ],
      "bbox": [
        2.5763919019317147,
        41.59863362641698,
        2.5763919019317147,
        41.59863362641698
      ]
    },
    "08041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2695884160821445,
              41.62638125941909
            ]
          },
          "bbox": [
            2.2695884160821445,
            41.62638125941909,
            2.2695884160821445,
            41.62638125941909
          ]
        }
      ],
      "bbox": [
        2.2695884160821445,
        41.62638125941909,
        2.2695884160821445,
        41.62638125941909
      ]
    },
    "08042": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3376388919311437,
              41.70271706281189
            ]
          },
          "bbox": [
            2.3376388919311437,
            41.70271706281189,
            2.3376388919311437,
            41.70271706281189
          ]
        }
      ],
      "bbox": [
        2.3376388919311437,
        41.70271706281189,
        2.3376388919311437,
        41.70271706281189
      ]
    },
    "08043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.720794705858011,
              41.283304762556156
            ]
          },
          "bbox": [
            1.720794705858011,
            41.283304762556156,
            1.720794705858011,
            41.283304762556156
          ]
        }
      ],
      "bbox": [
        1.720794705858011,
        41.283304762556156,
        1.720794705858011,
        41.283304762556156
      ]
    },
    "08044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.687857722862059,
              41.52914775888891
            ]
          },
          "bbox": [
            1.687857722862059,
            41.52914775888891,
            1.687857722862059,
            41.52914775888891
          ]
        }
      ],
      "bbox": [
        1.687857722862059,
        41.52914775888891,
        1.687857722862059,
        41.52914775888891
      ]
    },
    "08045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7513925124500407,
              42.08718556483878
            ]
          },
          "bbox": [
            1.7513925124500407,
            42.08718556483878,
            1.7513925124500407,
            42.08718556483878
          ]
        }
      ],
      "bbox": [
        1.7513925124500407,
        42.08718556483878,
        1.7513925124500407,
        42.08718556483878
      ]
    },
    "08046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3552407818691066,
              41.64581077015806
            ]
          },
          "bbox": [
            2.3552407818691066,
            41.64581077015806,
            2.3552407818691066,
            41.64581077015806
          ]
        }
      ],
      "bbox": [
        2.3552407818691066,
        41.64581077015806,
        2.3552407818691066,
        41.64581077015806
      ]
    },
    "08047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.659899437946993,
              41.90700616400415
            ]
          },
          "bbox": [
            1.659899437946993,
            41.90700616400415,
            1.659899437946993,
            41.90700616400415
          ]
        }
      ],
      "bbox": [
        1.659899437946993,
        41.90700616400415,
        1.659899437946993,
        41.90700616400415
      ]
    },
    "08048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6248652416859228,
              41.533818053853466
            ]
          },
          "bbox": [
            1.6248652416859228,
            41.533818053853466,
            1.6248652416859228,
            41.533818053853466
          ]
        }
      ],
      "bbox": [
        1.6248652416859228,
        41.533818053853466,
        1.6248652416859228,
        41.533818053853466
      ]
    },
    "08049": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.840816768876885,
              42.01749847074879
            ]
          },
          "bbox": [
            1.840816768876885,
            42.01749847074879,
            1.840816768876885,
            42.01749847074879
          ]
        }
      ],
      "bbox": [
        1.840816768876885,
        42.01749847074879,
        1.840816768876885,
        42.01749847074879
      ]
    },
    "08050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.758828856825883,
              42.12371779866586
            ]
          },
          "bbox": [
            1.758828856825883,
            42.12371779866586,
            1.758828856825883,
            42.12371779866586
          ]
        }
      ],
      "bbox": [
        1.758828856825883,
        42.12371779866586,
        1.758828856825883,
        42.12371779866586
      ]
    },
    "08051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0743623552150985,
              41.62263572006626
            ]
          },
          "bbox": [
            2.0743623552150985,
            41.62263572006626,
            2.0743623552150985,
            41.62263572006626
          ]
        }
      ],
      "bbox": [
        2.0743623552150985,
        41.62263572006626,
        2.0743623552150985,
        41.62263572006626
      ]
    },
    "08053": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8533557749196214,
              41.6387488824011
            ]
          },
          "bbox": [
            1.8533557749196214,
            41.6387488824011,
            1.8533557749196214,
            41.6387488824011
          ]
        }
      ],
      "bbox": [
        1.8533557749196214,
        41.6387488824011,
        1.8533557749196214,
        41.6387488824011
      ]
    },
    "08054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9681564174819757,
              41.482841028490526
            ]
          },
          "bbox": [
            1.9681564174819757,
            41.482841028490526,
            1.9681564174819757,
            41.482841028490526
          ]
        }
      ],
      "bbox": [
        1.9681564174819757,
        41.482841028490526,
        1.9681564174819757,
        41.482841028490526
      ]
    },
    "08055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.132713490072045,
              41.772175594477595
            ]
          },
          "bbox": [
            2.132713490072045,
            41.772175594477595,
            2.132713490072045,
            41.772175594477595
          ]
        }
      ],
      "bbox": [
        2.132713490072045,
        41.772175594477595,
        2.132713490072045,
        41.772175594477595
      ]
    },
    "08056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9703555108754316,
              41.276520210167305
            ]
          },
          "bbox": [
            1.9703555108754316,
            41.276520210167305,
            1.9703555108754316,
            41.276520210167305
          ]
        }
      ],
      "bbox": [
        1.9703555108754316,
        41.276520210167305,
        1.9703555108754316,
        41.276520210167305
      ]
    },
    "08058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6338203476374418,
              41.267459889578056
            ]
          },
          "bbox": [
            1.6338203476374418,
            41.267459889578056,
            1.6338203476374418,
            41.267459889578056
          ]
        }
      ],
      "bbox": [
        1.6338203476374418,
        41.267459889578056,
        1.6338203476374418,
        41.267459889578056
      ]
    },
    "08059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6774240505078666,
              41.669557160636764
            ]
          },
          "bbox": [
            1.6774240505078666,
            41.669557160636764,
            1.6774240505078666,
            41.669557160636764
          ]
        }
      ],
      "bbox": [
        1.6774240505078666,
        41.669557160636764,
        1.6774240505078666,
        41.669557160636764
      ]
    },
    "08060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4359304905027184,
              41.77102167452227
            ]
          },
          "bbox": [
            1.4359304905027184,
            41.77102167452227,
            1.4359304905027184,
            41.77102167452227
          ]
        }
      ],
      "bbox": [
        1.4359304905027184,
        41.77102167452227,
        1.4359304905027184,
        41.77102167452227
      ]
    },
    "08061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8272473636546382,
              41.67733552371736
            ]
          },
          "bbox": [
            1.8272473636546382,
            41.67733552371736,
            1.8272473636546382,
            41.67733552371736
          ]
        }
      ],
      "bbox": [
        1.8272473636546382,
        41.67733552371736,
        1.8272473636546382,
        41.67733552371736
      ]
    },
    "08062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8183049943295633,
              41.832759582668594
            ]
          },
          "bbox": [
            1.8183049943295633,
            41.832759582668594,
            1.8183049943295633,
            41.832759582668594
          ]
        }
      ],
      "bbox": [
        1.8183049943295633,
        41.832759582668594,
        1.8183049943295633,
        41.832759582668594
      ]
    },
    "08063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7038325172733892,
              41.59630621867188
            ]
          },
          "bbox": [
            1.7038325172733892,
            41.59630621867188,
            1.7038325172733892,
            41.59630621867188
          ]
        }
      ],
      "bbox": [
        1.7038325172733892,
        41.59630621867188,
        1.7038325172733892,
        41.59630621867188
      ]
    },
    "08064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1026297440700117,
              41.75179236025084
            ]
          },
          "bbox": [
            2.1026297440700117,
            41.75179236025084,
            2.1026297440700117,
            41.75179236025084
          ]
        }
      ],
      "bbox": [
        2.1026297440700117,
        41.75179236025084,
        2.1026297440700117,
        41.75179236025084
      ]
    },
    "08065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6039142932526615,
              41.33422006987775
            ]
          },
          "bbox": [
            1.6039142932526615,
            41.33422006987775,
            1.6039142932526615,
            41.33422006987775
          ]
        }
      ],
      "bbox": [
        1.6039142932526615,
        41.33422006987775,
        1.6039142932526615,
        41.33422006987775
      ]
    },
    "08066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9173286857500902,
              41.44812591073326
            ]
          },
          "bbox": [
            1.9173286857500902,
            41.44812591073326,
            1.9173286857500902,
            41.44812591073326
          ]
        }
      ],
      "bbox": [
        1.9173286857500902,
        41.44812591073326,
        1.9173286857500902,
        41.44812591073326
      ]
    },
    "08067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.222025462953778,
              41.785492215105194
            ]
          },
          "bbox": [
            2.222025462953778,
            41.785492215105194,
            2.222025462953778,
            41.785492215105194
          ]
        }
      ],
      "bbox": [
        2.222025462953778,
        41.785492215105194,
        2.222025462953778,
        41.785492215105194
      ]
    },
    "08068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9424056564850014,
              41.39285212651321
            ]
          },
          "bbox": [
            1.9424056564850014,
            41.39285212651321,
            1.9424056564850014,
            41.39285212651321
          ]
        }
      ],
      "bbox": [
        1.9424056564850014,
        41.39285212651321,
        1.9424056564850014,
        41.39285212651321
      ]
    },
    "08069": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8274505244145247,
              41.573036262767715
            ]
          },
          "bbox": [
            1.8274505244145247,
            41.573036262767715,
            1.8274505244145247,
            41.573036262767715
          ]
        }
      ],
      "bbox": [
        1.8274505244145247,
        41.573036262767715,
        1.8274505244145247,
        41.573036262767715
      ]
    },
    "08070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.174379785481974,
              41.831174234325054
            ]
          },
          "bbox": [
            2.174379785481974,
            41.831174234325054,
            2.174379785481974,
            41.831174234325054
          ]
        }
      ],
      "bbox": [
        2.174379785481974,
        41.831174234325054,
        2.174379785481974,
        41.831174234325054
      ]
    },
    "08071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.505044110673909,
              41.64293998512018
            ]
          },
          "bbox": [
            1.505044110673909,
            41.64293998512018,
            1.505044110673909,
            41.64293998512018
          ]
        }
      ],
      "bbox": [
        1.505044110673909,
        41.64293998512018,
        1.505044110673909,
        41.64293998512018
      ]
    },
    "08072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9300782917351755,
              41.42432191371774
            ]
          },
          "bbox": [
            1.9300782917351755,
            41.42432191371774,
            1.9300782917351755,
            41.42432191371774
          ]
        }
      ],
      "bbox": [
        1.9300782917351755,
        41.42432191371774,
        1.9300782917351755,
        41.42432191371774
      ]
    },
    "08073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.076298313151615,
              41.35521503447518
            ]
          },
          "bbox": [
            2.076298313151615,
            41.35521503447518,
            2.076298313151615,
            41.35521503447518
          ]
        }
      ],
      "bbox": [
        2.076298313151615,
        41.35521503447518,
        2.076298313151615,
        41.35521503447518
      ]
    },
    "08074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6607430678095452,
              41.21741008554616
            ]
          },
          "bbox": [
            1.6607430678095452,
            41.21741008554616,
            1.6607430678095452,
            41.21741008554616
          ]
        }
      ],
      "bbox": [
        1.6607430678095452,
        41.21741008554616,
        1.6607430678095452,
        41.21741008554616
      ]
    },
    "08075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4396977185901987,
              41.60440181346304
            ]
          },
          "bbox": [
            2.4396977185901987,
            41.60440181346304,
            2.4396977185901987,
            41.60440181346304
          ]
        }
      ],
      "bbox": [
        2.4396977185901987,
        41.60440181346304,
        2.4396977185901987,
        41.60440181346304
      ]
    },
    "08076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8627944445763092,
              41.54974832564373
            ]
          },
          "bbox": [
            1.8627944445763092,
            41.54974832564373,
            1.8627944445763092,
            41.54974832564373
          ]
        }
      ],
      "bbox": [
        1.8627944445763092,
        41.54974832564373,
        1.8627944445763092,
        41.54974832564373
      ]
    },
    "08077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.090903414066441,
              41.37897001262159
            ]
          },
          "bbox": [
            2.090903414066441,
            41.37897001262159,
            2.090903414066441,
            41.37897001262159
          ]
        }
      ],
      "bbox": [
        2.090903414066441,
        41.37897001262159,
        2.090903414066441,
        41.37897001262159
      ]
    },
    "08080": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7853890799221348,
              42.16853513102256
            ]
          },
          "bbox": [
            1.7853890799221348,
            42.16853513102256,
            1.7853890799221348,
            42.16853513102256
          ]
        }
      ],
      "bbox": [
        1.7853890799221348,
        42.16853513102256,
        1.7853890799221348,
        42.16853513102256
      ]
    },
    "08081": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4391140885049625,
              41.754025117771874
            ]
          },
          "bbox": [
            2.4391140885049625,
            41.754025117771874,
            2.4391140885049625,
            41.754025117771874
          ]
        }
      ],
      "bbox": [
        2.4391140885049625,
        41.754025117771874,
        2.4391140885049625,
        41.754025117771874
      ]
    },
    "08082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6426333698609867,
              41.7202478752019
            ]
          },
          "bbox": [
            2.6426333698609867,
            41.7202478752019,
            2.6426333698609867,
            41.7202478752019
          ]
        }
      ],
      "bbox": [
        2.6426333698609867,
        41.7202478752019,
        2.6426333698609867,
        41.7202478752019
      ]
    },
    "08083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3147924676270266,
              41.940801018868775
            ]
          },
          "bbox": [
            2.3147924676270266,
            41.940801018868775,
            2.3147924676270266,
            41.940801018868775
          ]
        }
      ],
      "bbox": [
        2.3147924676270266,
        41.940801018868775,
        2.3147924676270266,
        41.940801018868775
      ]
    },
    "08084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.712432579650713,
              41.76485868224541
            ]
          },
          "bbox": [
            1.712432579650713,
            41.76485868224541,
            1.712432579650713,
            41.76485868224541
          ]
        }
      ],
      "bbox": [
        1.712432579650713,
        41.76485868224541,
        1.712432579650713,
        41.76485868224541
      ]
    },
    "08085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6440827458702036,
              41.42200850446784
            ]
          },
          "bbox": [
            1.6440827458702036,
            41.42200850446784,
            1.6440827458702036,
            41.42200850446784
          ]
        }
      ],
      "bbox": [
        1.6440827458702036,
        41.42200850446784,
        1.6440827458702036,
        41.42200850446784
      ]
    },
    "08086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3126997801963842,
              41.649074227246935
            ]
          },
          "bbox": [
            2.3126997801963842,
            41.649074227246935,
            2.3126997801963842,
            41.649074227246935
          ]
        }
      ],
      "bbox": [
        2.3126997801963842,
        41.649074227246935,
        2.3126997801963842,
        41.649074227246935
      ]
    },
    "08087": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1142035283830243,
              41.694319904107466
            ]
          },
          "bbox": [
            2.1142035283830243,
            41.694319904107466,
            2.1142035283830243,
            41.694319904107466
          ]
        }
      ],
      "bbox": [
        2.1142035283830243,
        41.694319904107466,
        2.1142035283830243,
        41.694319904107466
      ]
    },
    "08088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2817669563326275,
              41.69001046563034
            ]
          },
          "bbox": [
            2.2817669563326275,
            41.69001046563034,
            2.2817669563326275,
            41.69001046563034
          ]
        }
      ],
      "bbox": [
        2.2817669563326275,
        41.69001046563034,
        2.2817669563326275,
        41.69001046563034
      ]
    },
    "08089": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9830454945596094,
              41.29477619169612
            ]
          },
          "bbox": [
            1.9830454945596094,
            41.29477619169612,
            1.9830454945596094,
            41.29477619169612
          ]
        }
      ],
      "bbox": [
        1.9830454945596094,
        41.29477619169612,
        1.9830454945596094,
        41.29477619169612
      ]
    },
    "08090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9278988099719965,
              41.92834391391186
            ]
          },
          "bbox": [
            1.9278988099719965,
            41.92834391391186,
            1.9278988099719965,
            41.92834391391186
          ]
        }
      ],
      "bbox": [
        1.9278988099719965,
        41.92834391391186,
        1.9278988099719965,
        41.92834391391186
      ]
    },
    "08091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8590344542764852,
              41.43523071550962
            ]
          },
          "bbox": [
            1.8590344542764852,
            41.43523071550962,
            1.8590344542764852,
            41.43523071550962
          ]
        }
      ],
      "bbox": [
        1.8590344542764852,
        41.43523071550962,
        1.8590344542764852,
        41.43523071550962
      ]
    },
    "08092": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8845391870888655,
              42.02661859221025
            ]
          },
          "bbox": [
            1.8845391870888655,
            42.02661859221025,
            1.8845391870888655,
            42.02661859221025
          ]
        }
      ],
      "bbox": [
        1.8845391870888655,
        42.02661859221025,
        1.8845391870888655,
        42.02661859221025
      ]
    },
    "08093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7863751590750434,
              42.26541151606115
            ]
          },
          "bbox": [
            1.7863751590750434,
            42.26541151606115,
            1.7863751590750434,
            42.26541151606115
          ]
        }
      ],
      "bbox": [
        1.7863751590750434,
        42.26541151606115,
        1.7863751590750434,
        42.26541151606115
      ]
    },
    "08094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7220784337449777,
              41.37698863367857
            ]
          },
          "bbox": [
            1.7220784337449777,
            41.37698863367857,
            1.7220784337449777,
            41.37698863367857
          ]
        }
      ],
      "bbox": [
        1.7220784337449777,
        41.37698863367857,
        1.7220784337449777,
        41.37698863367857
      ]
    },
    "08095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0630468203418166,
              41.72700543524401
            ]
          },
          "bbox": [
            2.0630468203418166,
            41.72700543524401,
            2.0630468203418166,
            41.72700543524401
          ]
        }
      ],
      "bbox": [
        2.0630468203418166,
        41.72700543524401,
        2.0630468203418166,
        41.72700543524401
      ]
    },
    "08096": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2790419655491605,
              41.59337552098736
            ]
          },
          "bbox": [
            2.2790419655491605,
            41.59337552098736,
            2.2790419655491605,
            41.59337552098736
          ]
        }
      ],
      "bbox": [
        2.2790419655491605,
        41.59337552098736,
        2.2790419655491605,
        41.59337552098736
      ]
    },
    "08097": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5042689283259065,
              41.73724149873866
            ]
          },
          "bbox": [
            2.5042689283259065,
            41.73724149873866,
            2.5042689283259065,
            41.73724149873866
          ]
        }
      ],
      "bbox": [
        2.5042689283259065,
        41.73724149873866,
        2.5042689283259065,
        41.73724149873866
      ]
    },
    "08098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7620417678309568,
              41.67621962810489
            ]
          },
          "bbox": [
            1.7620417678309568,
            41.67621962810489,
            1.7620417678309568,
            41.67621962810489
          ]
        }
      ],
      "bbox": [
        1.7620417678309568,
        41.67621962810489,
        1.7620417678309568,
        41.67621962810489
      ]
    },
    "08099": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8827281821885602,
              42.26262914954237
            ]
          },
          "bbox": [
            1.8827281821885602,
            42.26262914954237,
            1.8827281821885602,
            42.26262914954237
          ]
        }
      ],
      "bbox": [
        1.8827281821885602,
        42.26262914954237,
        1.8827281821885602,
        42.26262914954237
      ]
    },
    "08100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2260132184334784,
              41.961916438068585
            ]
          },
          "bbox": [
            2.2260132184334784,
            41.961916438068585,
            2.2260132184334784,
            41.961916438068585
          ]
        }
      ],
      "bbox": [
        2.2260132184334784,
        41.961916438068585,
        2.2260132184334784,
        41.961916438068585
      ]
    },
    "08102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6147375045667163,
              41.58593595898321
            ]
          },
          "bbox": [
            1.6147375045667163,
            41.58593595898321,
            1.6147375045667163,
            41.58593595898321
          ]
        }
      ],
      "bbox": [
        1.6147375045667163,
        41.58593595898321,
        1.6147375045667163,
        41.58593595898321
      ]
    },
    "08103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5291406137536288,
              41.6047622675124
            ]
          },
          "bbox": [
            1.5291406137536288,
            41.6047622675124,
            1.5291406137536288,
            41.6047622675124
          ]
        }
      ],
      "bbox": [
        1.5291406137536288,
        41.6047622675124,
        1.5291406137536288,
        41.6047622675124
      ]
    },
    "08104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5387266881973585,
              41.469350300364944
            ]
          },
          "bbox": [
            1.5387266881973585,
            41.469350300364944,
            1.5387266881973585,
            41.469350300364944
          ]
        }
      ],
      "bbox": [
        1.5387266881973585,
        41.469350300364944,
        1.5387266881973585,
        41.469350300364944
      ]
    },
    "08105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.203225323938825,
              41.51421766321167
            ]
          },
          "bbox": [
            2.203225323938825,
            41.51421766321167,
            2.203225323938825,
            41.51421766321167
          ]
        }
      ],
      "bbox": [
        2.203225323938825,
        41.51421766321167,
        2.203225323938825,
        41.51421766321167
      ]
    },
    "08106": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4080556762952536,
              41.63562704801868
            ]
          },
          "bbox": [
            2.4080556762952536,
            41.63562704801868,
            2.4080556762952536,
            41.63562704801868
          ]
        }
      ],
      "bbox": [
        2.4080556762952536,
        41.63562704801868,
        2.4080556762952536,
        41.63562704801868
      ]
    },
    "08108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.231151157958127,
              41.58812877050477
            ]
          },
          "bbox": [
            2.231151157958127,
            41.58812877050477,
            2.231151157958127,
            41.58812877050477
          ]
        }
      ],
      "bbox": [
        2.231151157958127,
        41.58812877050477,
        2.231151157958127,
        41.58812877050477
      ]
    },
    "08109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0511338222444033,
              42.06945662110421
            ]
          },
          "bbox": [
            2.0511338222444033,
            42.06945662110421,
            2.0511338222444033,
            42.06945662110421
          ]
        }
      ],
      "bbox": [
        2.0511338222444033,
        42.06945662110421,
        2.0511338222444033,
        42.06945662110421
      ]
    },
    "08110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.746153146031974,
              41.6505674906363
            ]
          },
          "bbox": [
            2.746153146031974,
            41.6505674906363,
            2.746153146031974,
            41.6505674906363
          ]
        }
      ],
      "bbox": [
        2.746153146031974,
        41.6505674906363,
        2.746153146031974,
        41.6505674906363
      ]
    },
    "08111": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.241915171867737,
              41.88589999456337
            ]
          },
          "bbox": [
            2.241915171867737,
            41.88589999456337,
            2.241915171867737,
            41.88589999456337
          ]
        }
      ],
      "bbox": [
        2.241915171867737,
        41.88589999456337,
        2.241915171867737,
        41.88589999456337
      ]
    },
    "08112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2798135171160143,
              42.00729640580264
            ]
          },
          "bbox": [
            2.2798135171160143,
            42.00729640580264,
            2.2798135171160143,
            42.00729640580264
          ]
        }
      ],
      "bbox": [
        2.2798135171160143,
        42.00729640580264,
        2.2798135171160143,
        42.00729640580264
      ]
    },
    "08113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8253638085639783,
              41.720623479689216
            ]
          },
          "bbox": [
            1.8253638085639783,
            41.720623479689216,
            1.8253638085639783,
            41.720623479689216
          ]
        }
      ],
      "bbox": [
        1.8253638085639783,
        41.720623479689216,
        1.8253638085639783,
        41.720623479689216
      ]
    },
    "08114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.917538452154131,
              41.47910920630853
            ]
          },
          "bbox": [
            1.917538452154131,
            41.47910920630853,
            1.917538452154131,
            41.47910920630853
          ]
        }
      ],
      "bbox": [
        1.917538452154131,
        41.47910920630853,
        1.917538452154131,
        41.47910920630853
      ]
    },
    "08115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2372275488765454,
              41.531589850285265
            ]
          },
          "bbox": [
            2.2372275488765454,
            41.531589850285265,
            2.2372275488765454,
            41.531589850285265
          ]
        }
      ],
      "bbox": [
        2.2372275488765454,
        41.531589850285265,
        2.2372275488765454,
        41.531589850285265
      ]
    },
    "08116": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3253317587832463,
              41.98249680973603
            ]
          },
          "bbox": [
            2.3253317587832463,
            41.98249680973603,
            2.3253317587832463,
            41.98249680973603
          ]
        }
      ],
      "bbox": [
        2.3253317587832463,
        41.98249680973603,
        2.3253317587832463,
        41.98249680973603
      ]
    },
    "08117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2265379882630576,
              42.02351704518743
            ]
          },
          "bbox": [
            2.2265379882630576,
            42.02351704518743,
            2.2265379882630576,
            42.02351704518743
          ]
        }
      ],
      "bbox": [
        2.2265379882630576,
        42.02351704518743,
        2.2265379882630576,
        42.02351704518743
      ]
    },
    "08118": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3181585651066414,
              41.48224535393975
            ]
          },
          "bbox": [
            2.3181585651066414,
            41.48224535393975,
            2.3181585651066414,
            41.48224535393975
          ]
        }
      ],
      "bbox": [
        2.3181585651066414,
        41.48224535393975,
        2.3181585651066414,
        41.48224535393975
      ]
    },
    "08119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.807399254575324,
              41.500203964051124
            ]
          },
          "bbox": [
            1.807399254575324,
            41.500203964051124,
            1.807399254575324,
            41.500203964051124
          ]
        }
      ],
      "bbox": [
        1.807399254575324,
        41.500203964051124,
        1.807399254575324,
        41.500203964051124
      ]
    },
    "08120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.012309042833861,
              41.62837054207755
            ]
          },
          "bbox": [
            2.012309042833861,
            41.62837054207755,
            2.012309042833861,
            41.62837054207755
          ]
        }
      ],
      "bbox": [
        2.012309042833861,
        41.62837054207755,
        2.012309042833861,
        41.62837054207755
      ]
    },
    "08121": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4466803135797868,
              41.554202868606545
            ]
          },
          "bbox": [
            2.4466803135797868,
            41.554202868606545,
            2.4466803135797868,
            41.554202868606545
          ]
        }
      ],
      "bbox": [
        2.4466803135797868,
        41.554202868606545,
        2.4466803135797868,
        41.554202868606545
      ]
    },
    "08122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6322602244918143,
              41.48105380521392
            ]
          },
          "bbox": [
            1.6322602244918143,
            41.48105380521392,
            1.6322602244918143,
            41.48105380521392
          ]
        }
      ],
      "bbox": [
        1.6322602244918143,
        41.48105380521392,
        1.6322602244918143,
        41.48105380521392
      ]
    },
    "08123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0343472172125443,
              41.42076230014586
            ]
          },
          "bbox": [
            2.0343472172125443,
            41.42076230014586,
            2.0343472172125443,
            41.42076230014586
          ]
        }
      ],
      "bbox": [
        2.0343472172125443,
        41.42076230014586,
        2.0343472172125443,
        41.42076230014586
      ]
    },
    "08124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.209898930159511,
              41.54884712175672
            ]
          },
          "bbox": [
            2.209898930159511,
            41.54884712175672,
            2.209898930159511,
            41.54884712175672
          ]
        }
      ],
      "bbox": [
        2.209898930159511,
        41.54884712175672,
        2.209898930159511,
        41.54884712175672
      ]
    },
    "08125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.184258148483802,
              41.48964311700741
            ]
          },
          "bbox": [
            2.184258148483802,
            41.48964311700741,
            2.184258148483802,
            41.48964311700741
          ]
        }
      ],
      "bbox": [
        2.184258148483802,
        41.48964311700741,
        2.184258148483802,
        41.48964311700741
      ]
    },
    "08126": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2801318617874653,
              41.47081927251636
            ]
          },
          "bbox": [
            2.2801318617874653,
            41.47081927251636,
            2.2801318617874653,
            41.47081927251636
          ]
        }
      ],
      "bbox": [
        2.2801318617874653,
        41.47081927251636,
        2.2801318617874653,
        41.47081927251636
      ]
    },
    "08127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8421827753231337,
              41.60946863755754
            ]
          },
          "bbox": [
            1.8421827753231337,
            41.60946863755754,
            1.8421827753231337,
            41.60946863755754
          ]
        }
      ],
      "bbox": [
        1.8421827753231337,
        41.60946863755754,
        1.8421827753231337,
        41.60946863755754
      ]
    },
    "08128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0205539957962824,
              41.757546310869905
            ]
          },
          "bbox": [
            2.0205539957962824,
            41.757546310869905,
            2.0205539957962824,
            41.757546310869905
          ]
        }
      ],
      "bbox": [
        2.0205539957962824,
        41.757546310869905,
        2.0205539957962824,
        41.757546310869905
      ]
    },
    "08129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.157668498684077,
              41.89022920176038
            ]
          },
          "bbox": [
            2.157668498684077,
            41.89022920176038,
            2.157668498684077,
            41.89022920176038
          ]
        }
      ],
      "bbox": [
        2.157668498684077,
        41.89022920176038,
        2.157668498684077,
        41.89022920176038
      ]
    },
    "08130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.775019772410919,
              42.018720698992304
            ]
          },
          "bbox": [
            1.775019772410919,
            42.018720698992304,
            1.775019772410919,
            42.018720698992304
          ]
        }
      ],
      "bbox": [
        1.775019772410919,
        42.018720698992304,
        1.775019772410919,
        42.018720698992304
      ]
    },
    "08131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.212330668709764,
              42.12108169103254
            ]
          },
          "bbox": [
            2.212330668709764,
            42.12108169103254,
            2.212330668709764,
            42.12108169103254
          ]
        }
      ],
      "bbox": [
        2.212330668709764,
        42.12108169103254,
        2.212330668709764,
        42.12108169103254
      ]
    },
    "08132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7071796158756234,
              42.0072648755411
            ]
          },
          "bbox": [
            1.7071796158756234,
            42.0072648755411,
            1.7071796158756234,
            42.0072648755411
          ]
        }
      ],
      "bbox": [
        1.7071796158756234,
        42.0072648755411,
        1.7071796158756234,
        42.0072648755411
      ]
    },
    "08133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.3993596134424091,
              41.61906030744894
            ]
          },
          "bbox": [
            1.3993596134424091,
            41.61906030744894,
            1.3993596134424091,
            41.61906030744894
          ]
        }
      ],
      "bbox": [
        1.3993596134424091,
        41.61906030744894,
        1.3993596134424091,
        41.61906030744894
      ]
    },
    "08134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.27233033874452,
              41.71724543090304
            ]
          },
          "bbox": [
            2.27233033874452,
            41.71724543090304,
            2.27233033874452,
            41.71724543090304
          ]
        }
      ],
      "bbox": [
        2.27233033874452,
        41.71724543090304,
        2.27233033874452,
        41.71724543090304
      ]
    },
    "08135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.250876551328584,
              41.55643709406389
            ]
          },
          "bbox": [
            2.250876551328584,
            41.55643709406389,
            2.250876551328584,
            41.55643709406389
          ]
        }
      ],
      "bbox": [
        2.250876551328584,
        41.55643709406389,
        2.250876551328584,
        41.55643709406389
      ]
    },
    "08136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.265576723112276,
              41.544598785687036
            ]
          },
          "bbox": [
            2.265576723112276,
            41.544598785687036,
            2.265576723112276,
            41.544598785687036
          ]
        }
      ],
      "bbox": [
        2.265576723112276,
        41.544598785687036,
        2.265576723112276,
        41.544598785687036
      ]
    },
    "08137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.390457417220569,
              41.779672547722136
            ]
          },
          "bbox": [
            2.390457417220569,
            41.779672547722136,
            2.390457417220569,
            41.779672547722136
          ]
        }
      ],
      "bbox": [
        2.390457417220569,
        41.779672547722136,
        2.390457417220569,
        41.779672547722136
      ]
    },
    "08138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1018844794371465,
              41.82003413156484
            ]
          },
          "bbox": [
            2.1018844794371465,
            41.82003413156484,
            2.1018844794371465,
            41.82003413156484
          ]
        }
      ],
      "bbox": [
        2.1018844794371465,
        41.82003413156484,
        2.1018844794371465,
        41.82003413156484
      ]
    },
    "08139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9752027720260457,
              41.68359945604114
            ]
          },
          "bbox": [
            1.9752027720260457,
            41.68359945604114,
            1.9752027720260457,
            41.68359945604114
          ]
        }
      ],
      "bbox": [
        1.9752027720260457,
        41.68359945604114,
        1.9752027720260457,
        41.68359945604114
      ]
    },
    "08140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.910321385993554,
              41.75478761684117
            ]
          },
          "bbox": [
            1.910321385993554,
            41.75478761684117,
            1.910321385993554,
            41.75478761684117
          ]
        }
      ],
      "bbox": [
        1.910321385993554,
        41.75478761684117,
        1.910321385993554,
        41.75478761684117
      ]
    },
    "08141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7714775122314923,
              41.88872822799134
            ]
          },
          "bbox": [
            1.7714775122314923,
            41.88872822799134,
            1.7714775122314923,
            41.88872822799134
          ]
        }
      ],
      "bbox": [
        1.7714775122314923,
        41.88872822799134,
        1.7714775122314923,
        41.88872822799134
      ]
    },
    "08142": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8911212920665375,
              42.17731239111925
            ]
          },
          "bbox": [
            1.8911212920665375,
            42.17731239111925,
            1.8911212920665375,
            42.17731239111925
          ]
        }
      ],
      "bbox": [
        1.8911212920665375,
        42.17731239111925,
        1.8911212920665375,
        42.17731239111925
      ]
    },
    "08143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6315616467590386,
              41.618687765174926
            ]
          },
          "bbox": [
            1.6315616467590386,
            41.618687765174926,
            1.6315616467590386,
            41.618687765174926
          ]
        }
      ],
      "bbox": [
        1.6315616467590386,
        41.618687765174926,
        1.6315616467590386,
        41.618687765174926
      ]
    },
    "08144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9076999498109497,
              42.0627770358453
            ]
          },
          "bbox": [
            1.9076999498109497,
            42.0627770358453,
            1.9076999498109497,
            42.0627770358453
          ]
        }
      ],
      "bbox": [
        1.9076999498109497,
        42.0627770358453,
        1.9076999498109497,
        42.0627770358453
      ]
    },
    "08145": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7244524485072725,
              41.31924511306445
            ]
          },
          "bbox": [
            1.7244524485072725,
            41.31924511306445,
            1.7244524485072725,
            41.31924511306445
          ]
        }
      ],
      "bbox": [
        1.7244524485072725,
        41.31924511306445,
        1.7244524485072725,
        41.31924511306445
      ]
    },
    "08146": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8523279463049087,
              41.35343761329359
            ]
          },
          "bbox": [
            1.8523279463049087,
            41.35343761329359,
            1.8523279463049087,
            41.35343761329359
          ]
        }
      ],
      "bbox": [
        1.8523279463049087,
        41.35343761329359,
        1.8523279463049087,
        41.35343761329359
      ]
    },
    "08147": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9013948120792863,
              41.55375607071524
            ]
          },
          "bbox": [
            1.9013948120792863,
            41.55375607071524,
            1.9013948120792863,
            41.55375607071524
          ]
        }
      ],
      "bbox": [
        1.9013948120792863,
        41.55375607071524,
        1.9013948120792863,
        41.55375607071524
      ]
    },
    "08148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.811555131337664,
              41.299400971487614
            ]
          },
          "bbox": [
            1.811555131337664,
            41.299400971487614,
            1.811555131337664,
            41.299400971487614
          ]
        }
      ],
      "bbox": [
        1.811555131337664,
        41.299400971487614,
        1.811555131337664,
        41.299400971487614
      ]
    },
    "08149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.094883698933368,
              42.00097082427351
            ]
          },
          "bbox": [
            2.094883698933368,
            42.00097082427351,
            2.094883698933368,
            42.00097082427351
          ]
        }
      ],
      "bbox": [
        2.094883698933368,
        42.00097082427351,
        2.094883698933368,
        42.00097082427351
      ]
    },
    "08150": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2229234718193878,
              42.069664893916055
            ]
          },
          "bbox": [
            2.2229234718193878,
            42.069664893916055,
            2.2229234718193878,
            42.069664893916055
          ]
        }
      ],
      "bbox": [
        2.2229234718193878,
        42.069664893916055,
        2.2229234718193878,
        42.069664893916055
      ]
    },
    "08151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0703506655580695,
              41.94909739966759
            ]
          },
          "bbox": [
            2.0703506655580695,
            41.94909739966759,
            2.0703506655580695,
            41.94909739966759
          ]
        }
      ],
      "bbox": [
        2.0703506655580695,
        41.94909739966759,
        2.0703506655580695,
        41.94909739966759
      ]
    },
    "08152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5776762871785524,
              41.524328453364696
            ]
          },
          "bbox": [
            1.5776762871785524,
            41.524328453364696,
            1.5776762871785524,
            41.524328453364696
          ]
        }
      ],
      "bbox": [
        1.5776762871785524,
        41.524328453364696,
        1.5776762871785524,
        41.524328453364696
      ]
    },
    "08153": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.35115627700342,
              41.55516311035204
            ]
          },
          "bbox": [
            2.35115627700342,
            41.55516311035204,
            2.35115627700342,
            41.55516311035204
          ]
        }
      ],
      "bbox": [
        2.35115627700342,
        41.55516311035204,
        2.35115627700342,
        41.55516311035204
      ]
    },
    "08154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.666386344357139,
              41.360173053753506
            ]
          },
          "bbox": [
            1.666386344357139,
            41.360173053753506,
            1.666386344357139,
            41.360173053753506
          ]
        }
      ],
      "bbox": [
        1.666386344357139,
        41.360173053753506,
        1.666386344357139,
        41.360173053753506
      ]
    },
    "08155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.7301561925251088,
              41.671487489783736
            ]
          },
          "bbox": [
            2.7301561925251088,
            41.671487489783736,
            2.7301561925251088,
            41.671487489783736
          ]
        }
      ],
      "bbox": [
        2.7301561925251088,
        41.671487489783736,
        2.7301561925251088,
        41.671487489783736
      ]
    },
    "08156": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.175062939850438,
              41.58040315401192
            ]
          },
          "bbox": [
            2.175062939850438,
            41.58040315401192,
            2.175062939850438,
            41.58040315401192
          ]
        }
      ],
      "bbox": [
        2.175062939850438,
        41.58040315401192,
        2.175062939850438,
        41.58040315401192
      ]
    },
    "08157": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.985796786505112,
              41.421440545144044
            ]
          },
          "bbox": [
            1.985796786505112,
            41.421440545144044,
            1.985796786505112,
            41.421440545144044
          ]
        }
      ],
      "bbox": [
        1.985796786505112,
        41.421440545144044,
        1.985796786505112,
        41.421440545144044
      ]
    },
    "08158": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0118267595320627,
              41.4420838537149
            ]
          },
          "bbox": [
            2.0118267595320627,
            41.4420838537149,
            2.0118267595320627,
            41.4420838537149
          ]
        }
      ],
      "bbox": [
        2.0118267595320627,
        41.4420838537149,
        2.0118267595320627,
        41.4420838537149
      ]
    },
    "08159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.232223892398774,
              41.56745372737016
            ]
          },
          "bbox": [
            2.232223892398774,
            41.56745372737016,
            2.232223892398774,
            41.56745372737016
          ]
        }
      ],
      "bbox": [
        2.232223892398774,
        41.56745372737016,
        2.232223892398774,
        41.56745372737016
      ]
    },
    "08160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.10731573913959,
              42.04789250018601
            ]
          },
          "bbox": [
            2.10731573913959,
            42.04789250018601,
            2.10731573913959,
            42.04789250018601
          ]
        }
      ],
      "bbox": [
        2.10731573913959,
        42.04789250018601,
        2.10731573913959,
        42.04789250018601
      ]
    },
    "08161": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7500745933574648,
              41.5111487348393
            ]
          },
          "bbox": [
            1.7500745933574648,
            41.5111487348393,
            1.7500745933574648,
            41.5111487348393
          ]
        }
      ],
      "bbox": [
        1.7500745933574648,
        41.5111487348393,
        1.7500745933574648,
        41.5111487348393
      ]
    },
    "08162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7955378614771589,
              41.542533310226545
            ]
          },
          "bbox": [
            1.7955378614771589,
            41.542533310226545,
            1.7955378614771589,
            41.542533310226545
          ]
        }
      ],
      "bbox": [
        1.7955378614771589,
        41.542533310226545,
        1.7955378614771589,
        41.542533310226545
      ]
    },
    "08163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6723177402090195,
              41.632518036101025
            ]
          },
          "bbox": [
            2.6723177402090195,
            41.632518036101025,
            2.6723177402090195,
            41.632518036101025
          ]
        }
      ],
      "bbox": [
        2.6723177402090195,
        41.632518036101025,
        2.6723177402090195,
        41.632518036101025
      ]
    },
    "08164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7213963659840026,
              41.41491085015457
            ]
          },
          "bbox": [
            1.7213963659840026,
            41.41491085015457,
            1.7213963659840026,
            41.41491085015457
          ]
        }
      ],
      "bbox": [
        1.7213963659840026,
        41.41491085015457,
        1.7213963659840026,
        41.41491085015457
      ]
    },
    "08165": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6813570761287329,
              41.55830410060505
            ]
          },
          "bbox": [
            1.6813570761287329,
            41.55830410060505,
            1.6813570761287329,
            41.55830410060505
          ]
        }
      ],
      "bbox": [
        1.6813570761287329,
        41.55830410060505,
        1.6813570761287329,
        41.55830410060505
      ]
    },
    "08166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9752854140800644,
              42.22942703752428
            ]
          },
          "bbox": [
            1.9752854140800644,
            42.22942703752428,
            1.9752854140800644,
            42.22942703752428
          ]
        }
      ],
      "bbox": [
        1.9752854140800644,
        42.22942703752428,
        1.9752854140800644,
        42.22942703752428
      ]
    },
    "08167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1521301541442854,
              41.55803593539564
            ]
          },
          "bbox": [
            2.1521301541442854,
            41.55803593539564,
            2.1521301541442854,
            41.55803593539564
          ]
        }
      ],
      "bbox": [
        2.1521301541442854,
        41.55803593539564,
        2.1521301541442854,
        41.55803593539564
      ]
    },
    "08168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5121346665851616,
              41.41281337227889
            ]
          },
          "bbox": [
            1.5121346665851616,
            41.41281337227889,
            1.5121346665851616,
            41.41281337227889
          ]
        }
      ],
      "bbox": [
        1.5121346665851616,
        41.41281337227889,
        1.5121346665851616,
        41.41281337227889
      ]
    },
    "08169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0988293107739917,
              41.309890715329075
            ]
          },
          "bbox": [
            2.0988293107739917,
            41.309890715329075,
            2.0988293107739917,
            41.309890715329075
          ]
        }
      ],
      "bbox": [
        2.0988293107739917,
        41.309890715329075,
        2.0988293107739917,
        41.309890715329075
      ]
    },
    "08170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.551870790829679,
              41.70643552582832
            ]
          },
          "bbox": [
            1.551870790829679,
            41.70643552582832,
            1.551870790829679,
            41.70643552582832
          ]
        }
      ],
      "bbox": [
        1.551870790829679,
        41.70643552582832,
        1.551870790829679,
        41.70643552582832
      ]
    },
    "08171": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0232902340482153,
              41.99801766000133
            ]
          },
          "bbox": [
            2.0232902340482153,
            41.99801766000133,
            2.0232902340482153,
            41.99801766000133
          ]
        }
      ],
      "bbox": [
        2.0232902340482153,
        41.99801766000133,
        2.0232902340482153,
        41.99801766000133
      ]
    },
    "08172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.357970871880925,
              41.491978139326605
            ]
          },
          "bbox": [
            2.357970871880925,
            41.491978139326605,
            2.357970871880925,
            41.491978139326605
          ]
        }
      ],
      "bbox": [
        2.357970871880925,
        41.491978139326605,
        2.357970871880925,
        41.491978139326605
      ]
    },
    "08174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7019540928382606,
              41.40375405974472
            ]
          },
          "bbox": [
            1.7019540928382606,
            41.40375405974472,
            1.7019540928382606,
            41.40375405974472
          ]
        }
      ],
      "bbox": [
        1.7019540928382606,
        41.40375405974472,
        1.7019540928382606,
        41.40375405974472
      ]
    },
    "08175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8688269539433258,
              41.965407268266524
            ]
          },
          "bbox": [
            1.8688269539433258,
            41.965407268266524,
            1.8688269539433258,
            41.965407268266524
          ]
        }
      ],
      "bbox": [
        1.8688269539433258,
        41.965407268266524,
        1.8688269539433258,
        41.965407268266524
      ]
    },
    "08176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4349822273680324,
              41.70688235415957
            ]
          },
          "bbox": [
            1.4349822273680324,
            41.70688235415957,
            1.4349822273680324,
            41.70688235415957
          ]
        }
      ],
      "bbox": [
        1.4349822273680324,
        41.70688235415957,
        1.4349822273680324,
        41.70688235415957
      ]
    },
    "08177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.965334654226953,
              42.09705808152602
            ]
          },
          "bbox": [
            1.965334654226953,
            42.09705808152602,
            1.965334654226953,
            42.09705808152602
          ]
        }
      ],
      "bbox": [
        1.965334654226953,
        42.09705808152602,
        1.965334654226953,
        42.09705808152602
      ]
    },
    "08178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7128461045072514,
              41.72095647078914
            ]
          },
          "bbox": [
            1.7128461045072514,
            41.72095647078914,
            1.7128461045072514,
            41.72095647078914
          ]
        }
      ],
      "bbox": [
        1.7128461045072514,
        41.72095647078914,
        1.7128461045072514,
        41.72095647078914
      ]
    },
    "08179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.918639374536761,
              41.643437108170794
            ]
          },
          "bbox": [
            1.918639374536761,
            41.643437108170794,
            1.918639374536761,
            41.643437108170794
          ]
        }
      ],
      "bbox": [
        1.918639374536761,
        41.643437108170794,
        1.918639374536761,
        41.643437108170794
      ]
    },
    "08180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1538971582693587,
              41.49956752534801
            ]
          },
          "bbox": [
            2.1538971582693587,
            41.49956752534801,
            2.1538971582693587,
            41.49956752534801
          ]
        }
      ],
      "bbox": [
        2.1538971582693587,
        41.49956752534801,
        2.1538971582693587,
        41.49956752534801
      ]
    },
    "08181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3348120567910065,
              41.59619079629901
            ]
          },
          "bbox": [
            2.3348120567910065,
            41.59619079629901,
            2.3348120567910065,
            41.59619079629901
          ]
        }
      ],
      "bbox": [
        2.3348120567910065,
        41.59619079629901,
        2.3348120567910065,
        41.59619079629901
      ]
    },
    "08182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9070324409911197,
              41.70410992945968
            ]
          },
          "bbox": [
            1.9070324409911197,
            41.70410992945968,
            1.9070324409911197,
            41.70410992945968
          ]
        }
      ],
      "bbox": [
        1.9070324409911197,
        41.70410992945968,
        1.9070324409911197,
        41.70410992945968
      ]
    },
    "08183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3092345872290148,
              41.9805814475984
            ]
          },
          "bbox": [
            2.3092345872290148,
            41.9805814475984,
            2.3092345872290148,
            41.9805814475984
          ]
        }
      ],
      "bbox": [
        2.3092345872290148,
        41.9805814475984,
        2.3092345872290148,
        41.9805814475984
      ]
    },
    "08184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.014629333038678,
              41.50158043905777
            ]
          },
          "bbox": [
            2.014629333038678,
            41.50158043905777,
            2.014629333038678,
            41.50158043905777
          ]
        }
      ],
      "bbox": [
        2.014629333038678,
        41.50158043905777,
        2.014629333038678,
        41.50158043905777
      ]
    },
    "08185": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5748079614681496,
              41.65714090527476
            ]
          },
          "bbox": [
            1.5748079614681496,
            41.65714090527476,
            1.5748079614681496,
            41.65714090527476
          ]
        }
      ],
      "bbox": [
        1.5748079614681496,
        41.65714090527476,
        1.5748079614681496,
        41.65714090527476
      ]
    },
    "08187": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1015231666982848,
              41.55546104082503
            ]
          },
          "bbox": [
            2.1015231666982848,
            41.55546104082503,
            2.1015231666982848,
            41.55546104082503
          ]
        }
      ],
      "bbox": [
        2.1015231666982848,
        41.55546104082503,
        2.1015231666982848,
        41.55546104082503
      ]
    },
    "08188": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9441775967771815,
              42.02907697920185
            ]
          },
          "bbox": [
            1.9441775967771815,
            42.02907697920185,
            1.9441775967771815,
            42.02907697920185
          ]
        }
      ],
      "bbox": [
        1.9441775967771815,
        42.02907697920185,
        1.9441775967771815,
        42.02907697920185
      ]
    },
    "08189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.563118088281764,
              41.751341184260866
            ]
          },
          "bbox": [
            1.563118088281764,
            41.751341184260866,
            1.563118088281764,
            41.751341184260866
          ]
        }
      ],
      "bbox": [
        1.563118088281764,
        41.751341184260866,
        1.563118088281764,
        41.751341184260866
      ]
    },
    "08190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.735135308772971,
              42.22906409740672
            ]
          },
          "bbox": [
            1.735135308772971,
            42.22906409740672,
            1.735135308772971,
            42.22906409740672
          ]
        }
      ],
      "bbox": [
        1.735135308772971,
        42.22906409740672,
        1.735135308772971,
        42.22906409740672
      ]
    },
    "08191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.906077775504855,
              41.83221262723553
            ]
          },
          "bbox": [
            1.906077775504855,
            41.83221262723553,
            1.906077775504855,
            41.83221262723553
          ]
        }
      ],
      "bbox": [
        1.906077775504855,
        41.83221262723553,
        1.906077775504855,
        41.83221262723553
      ]
    },
    "08192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8359181731524346,
              41.78360718591703
            ]
          },
          "bbox": [
            1.8359181731524346,
            41.78360718591703,
            1.8359181731524346,
            41.78360718591703
          ]
        }
      ],
      "bbox": [
        1.8359181731524346,
        41.78360718591703,
        1.8359181731524346,
        41.78360718591703
      ]
    },
    "08193": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5679264781079003,
              41.63924356214443
            ]
          },
          "bbox": [
            2.5679264781079003,
            41.63924356214443,
            2.5679264781079003,
            41.63924356214443
          ]
        }
      ],
      "bbox": [
        2.5679264781079003,
        41.63924356214443,
        2.5679264781079003,
        41.63924356214443
      ]
    },
    "08194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2218437882755073,
              41.42405434037754
            ]
          },
          "bbox": [
            2.2218437882755073,
            41.42405434037754,
            2.2218437882755073,
            41.42405434037754
          ]
        }
      ],
      "bbox": [
        2.2218437882755073,
        41.42405434037754,
        2.2218437882755073,
        41.42405434037754
      ]
    },
    "08195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1312389930508706,
              42.081484862211795
            ]
          },
          "bbox": [
            2.1312389930508706,
            42.081484862211795,
            2.1312389930508706,
            42.081484862211795
          ]
        }
      ],
      "bbox": [
        2.1312389930508706,
        42.081484862211795,
        2.1312389930508706,
        42.081484862211795
      ]
    },
    "08196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9637634877368113,
              41.45127206275734
            ]
          },
          "bbox": [
            1.9637634877368113,
            41.45127206275734,
            1.9637634877368113,
            41.45127206275734
          ]
        }
      ],
      "bbox": [
        1.9637634877368113,
        41.45127206275734,
        1.9637634877368113,
        41.45127206275734
      ]
    },
    "08197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.483954500490368,
              41.5776759503528
            ]
          },
          "bbox": [
            2.483954500490368,
            41.5776759503528,
            2.483954500490368,
            41.5776759503528
          ]
        }
      ],
      "bbox": [
        2.483954500490368,
        41.5776759503528,
        2.483954500490368,
        41.5776759503528
      ]
    },
    "08198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.395025008783104,
              41.66083352746583
            ]
          },
          "bbox": [
            2.395025008783104,
            41.66083352746583,
            2.395025008783104,
            41.66083352746583
          ]
        }
      ],
      "bbox": [
        2.395025008783104,
        41.66083352746583,
        2.395025008783104,
        41.66083352746583
      ]
    },
    "08199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.154515226393025,
              41.969210394741246
            ]
          },
          "bbox": [
            2.154515226393025,
            41.969210394741246,
            2.154515226393025,
            41.969210394741246
          ]
        }
      ],
      "bbox": [
        2.154515226393025,
        41.969210394741246,
        2.154515226393025,
        41.969210394741246
      ]
    },
    "08200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.041912658115558,
              41.334994954927915
            ]
          },
          "bbox": [
            2.041912658115558,
            41.334994954927915,
            2.041912658115558,
            41.334994954927915
          ]
        }
      ],
      "bbox": [
        2.041912658115558,
        41.334994954927915,
        2.041912658115558,
        41.334994954927915
      ]
    },
    "08201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1589223366409596,
              42.05343324191278
            ]
          },
          "bbox": [
            2.1589223366409596,
            42.05343324191278,
            2.1589223366409596,
            42.05343324191278
          ]
        }
      ],
      "bbox": [
        2.1589223366409596,
        42.05343324191278,
        2.1589223366409596,
        42.05343324191278
      ]
    },
    "08202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5402562597752936,
              41.6889899026974
            ]
          },
          "bbox": [
            2.5402562597752936,
            41.6889899026974,
            2.5402562597752936,
            41.6889899026974
          ]
        }
      ],
      "bbox": [
        2.5402562597752936,
        41.6889899026974,
        2.5402562597752936,
        41.6889899026974
      ]
    },
    "08203": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6040342511992653,
              41.629623255824065
            ]
          },
          "bbox": [
            2.6040342511992653,
            41.629623255824065,
            2.6040342511992653,
            41.629623255824065
          ]
        }
      ],
      "bbox": [
        2.6040342511992653,
        41.629623255824065,
        2.6040342511992653,
        41.629623255824065
      ]
    },
    "08204": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9854936580054812,
              41.333158753054676
            ]
          },
          "bbox": [
            1.9854936580054812,
            41.333158753054676,
            1.9854936580054812,
            41.333158753054676
          ]
        }
      ],
      "bbox": [
        1.9854936580054812,
        41.333158753054676,
        1.9854936580054812,
        41.333158753054676
      ]
    },
    "08205": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0731125277793585,
              41.46205999866046
            ]
          },
          "bbox": [
            2.0731125277793585,
            41.46205999866046,
            2.0731125277793585,
            41.46205999866046
          ]
        }
      ],
      "bbox": [
        2.0731125277793585,
        41.46205999866046,
        2.0731125277793585,
        41.46205999866046
      ]
    },
    "08206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7534699790102373,
              41.355672068078135
            ]
          },
          "bbox": [
            1.7534699790102373,
            41.355672068078135,
            1.7534699790102373,
            41.355672068078135
          ]
        }
      ],
      "bbox": [
        1.7534699790102373,
        41.355672068078135,
        1.7534699790102373,
        41.355672068078135
      ]
    },
    "08207": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.421489198432445,
              41.714115346958366
            ]
          },
          "bbox": [
            2.421489198432445,
            41.714115346958366,
            2.421489198432445,
            41.714115346958366
          ]
        }
      ],
      "bbox": [
        2.421489198432445,
        41.714115346958366,
        2.421489198432445,
        41.714115346958366
      ]
    },
    "08208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8765938848811463,
              41.486498859488954
            ]
          },
          "bbox": [
            1.8765938848811463,
            41.486498859488954,
            1.8765938848811463,
            41.486498859488954
          ]
        }
      ],
      "bbox": [
        1.8765938848811463,
        41.486498859488954,
        1.8765938848811463,
        41.486498859488954
      ]
    },
    "08209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2334568667023404,
              41.50772934161997
            ]
          },
          "bbox": [
            2.2334568667023404,
            41.50772934161997,
            2.2334568667023404,
            41.50772934161997
          ]
        }
      ],
      "bbox": [
        2.2334568667023404,
        41.50772934161997,
        2.2334568667023404,
        41.50772934161997
      ]
    },
    "08210": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.16117063411649,
              41.69865864203588
            ]
          },
          "bbox": [
            2.16117063411649,
            41.69865864203588,
            2.16117063411649,
            41.69865864203588
          ]
        }
      ],
      "bbox": [
        2.16117063411649,
        41.69865864203588,
        2.16117063411649,
        41.69865864203588
      ]
    },
    "08211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0493133698648927,
              41.39525148397995
            ]
          },
          "bbox": [
            2.0493133698648927,
            41.39525148397995,
            2.0493133698648927,
            41.39525148397995
          ]
        }
      ],
      "bbox": [
        2.0493133698648927,
        41.39525148397995,
        2.0493133698648927,
        41.39525148397995
      ]
    },
    "08212": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0072970545617554,
              41.93843454629569
            ]
          },
          "bbox": [
            2.0072970545617554,
            41.93843454629569,
            2.0072970545617554,
            41.93843454629569
          ]
        }
      ],
      "bbox": [
        2.0072970545617554,
        41.93843454629569,
        2.0072970545617554,
        41.93843454629569
      ]
    },
    "08213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.871932812117672,
              41.75580169745591
            ]
          },
          "bbox": [
            1.871932812117672,
            41.75580169745591,
            1.871932812117672,
            41.75580169745591
          ]
        }
      ],
      "bbox": [
        1.871932812117672,
        41.75580169745591,
        1.871932812117672,
        41.75580169745591
      ]
    },
    "08214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.347645607331483,
              41.52420173899782
            ]
          },
          "bbox": [
            2.347645607331483,
            41.52420173899782,
            2.347645607331483,
            41.52420173899782
          ]
        }
      ],
      "bbox": [
        2.347645607331483,
        41.52420173899782,
        2.347645607331483,
        41.52420173899782
      ]
    },
    "08215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2378931921169043,
              42.015809989947996
            ]
          },
          "bbox": [
            2.2378931921169043,
            42.015809989947996,
            2.2378931921169043,
            42.015809989947996
          ]
        }
      ],
      "bbox": [
        2.2378931921169043,
        42.015809989947996,
        2.2378931921169043,
        42.015809989947996
      ]
    },
    "08216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.029831292392425,
              42.19055940343406
            ]
          },
          "bbox": [
            2.029831292392425,
            42.19055940343406,
            2.029831292392425,
            42.19055940343406
          ]
        }
      ],
      "bbox": [
        2.029831292392425,
        42.19055940343406,
        2.029831292392425,
        42.19055940343406
      ]
    },
    "08217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.056608471418171,
              41.367390445618454
            ]
          },
          "bbox": [
            2.056608471418171,
            41.367390445618454,
            2.056608471418171,
            41.367390445618454
          ]
        }
      ],
      "bbox": [
        2.056608471418171,
        41.367390445618454,
        2.056608471418171,
        41.367390445618454
      ]
    },
    "08218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7972483119314753,
              41.763286007407956
            ]
          },
          "bbox": [
            1.7972483119314753,
            41.763286007407956,
            1.7972483119314753,
            41.763286007407956
          ]
        }
      ],
      "bbox": [
        1.7972483119314753,
        41.763286007407956,
        1.7972483119314753,
        41.763286007407956
      ]
    },
    "08219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3838881952909743,
              41.505434092058074
            ]
          },
          "bbox": [
            2.3838881952909743,
            41.505434092058074,
            2.3838881952909743,
            41.505434092058074
          ]
        }
      ],
      "bbox": [
        2.3838881952909743,
        41.505434092058074,
        2.3838881952909743,
        41.505434092058074
      ]
    },
    "08220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3255245385232954,
              41.90719820755009
            ]
          },
          "bbox": [
            2.3255245385232954,
            41.90719820755009,
            2.3255245385232954,
            41.90719820755009
          ]
        }
      ],
      "bbox": [
        2.3255245385232954,
        41.90719820755009,
        2.3255245385232954,
        41.90719820755009
      ]
    },
    "08221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0791580796378963,
              41.39270038693882
            ]
          },
          "bbox": [
            2.0791580796378963,
            41.39270038693882,
            2.0791580796378963,
            41.39270038693882
          ]
        }
      ],
      "bbox": [
        2.0791580796378963,
        41.39270038693882,
        2.0791580796378963,
        41.39270038693882
      ]
    },
    "08223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.053963136299385,
              41.68300412430886
            ]
          },
          "bbox": [
            2.053963136299385,
            41.68300412430886,
            2.053963136299385,
            41.68300412430886
          ]
        }
      ],
      "bbox": [
        2.053963136299385,
        41.68300412430886,
        2.053963136299385,
        41.68300412430886
      ]
    },
    "08224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.218102006695256,
              41.75088155344168
            ]
          },
          "bbox": [
            2.218102006695256,
            41.75088155344168,
            2.218102006695256,
            41.75088155344168
          ]
        }
      ],
      "bbox": [
        2.218102006695256,
        41.75088155344168,
        2.218102006695256,
        41.75088155344168
      ]
    },
    "08226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5012278367750036,
              41.55240706661742
            ]
          },
          "bbox": [
            1.5012278367750036,
            41.55240706661742,
            1.5012278367750036,
            41.55240706661742
          ]
        }
      ],
      "bbox": [
        1.5012278367750036,
        41.55240706661742,
        1.5012278367750036,
        41.55240706661742
      ]
    },
    "08227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6201819196455363,
              41.37436355965899
            ]
          },
          "bbox": [
            1.6201819196455363,
            41.37436355965899,
            1.6201819196455363,
            41.37436355965899
          ]
        }
      ],
      "bbox": [
        1.6201819196455363,
        41.37436355965899,
        1.6201819196455363,
        41.37436355965899
      ]
    },
    "08228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5031171553351073,
              41.70336670683041
            ]
          },
          "bbox": [
            1.5031171553351073,
            41.70336670683041,
            1.5031171553351073,
            41.70336670683041
          ]
        }
      ],
      "bbox": [
        1.5031171553351073,
        41.70336670683041,
        1.5031171553351073,
        41.70336670683041
      ]
    },
    "08229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.67002076371749,
              41.815826590501345
            ]
          },
          "bbox": [
            1.67002076371749,
            41.815826590501345,
            1.67002076371749,
            41.815826590501345
          ]
        }
      ],
      "bbox": [
        1.67002076371749,
        41.815826590501345,
        1.67002076371749,
        41.815826590501345
      ]
    },
    "08230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3438189281765727,
              41.50584543226532
            ]
          },
          "bbox": [
            2.3438189281765727,
            41.50584543226532,
            2.3438189281765727,
            41.50584543226532
          ]
        }
      ],
      "bbox": [
        2.3438189281765727,
        41.50584543226532,
        2.3438189281765727,
        41.50584543226532
      ]
    },
    "08231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.776139063571565,
              41.261998046847026
            ]
          },
          "bbox": [
            1.776139063571565,
            41.261998046847026,
            1.776139063571565,
            41.261998046847026
          ]
        }
      ],
      "bbox": [
        1.776139063571565,
        41.261998046847026,
        1.776139063571565,
        41.261998046847026
      ]
    },
    "08232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.697352224993056,
              41.458383221505514
            ]
          },
          "bbox": [
            1.697352224993056,
            41.458383221505514,
            1.697352224993056,
            41.458383221505514
          ]
        }
      ],
      "bbox": [
        1.697352224993056,
        41.458383221505514,
        1.697352224993056,
        41.458383221505514
      ]
    },
    "08233": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3300233351954125,
              42.08644396675102
            ]
          },
          "bbox": [
            2.3300233351954125,
            42.08644396675102,
            2.3300233351954125,
            42.08644396675102
          ]
        }
      ],
      "bbox": [
        2.3300233351954125,
        42.08644396675102,
        2.3300233351954125,
        42.08644396675102
      ]
    },
    "08234": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3863443487826204,
              41.71149157549556
            ]
          },
          "bbox": [
            2.3863443487826204,
            41.71149157549556,
            2.3863443487826204,
            41.71149157549556
          ]
        }
      ],
      "bbox": [
        2.3863443487826204,
        41.71149157549556,
        2.3863443487826204,
        41.71149157549556
      ]
    },
    "08235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6161602924234977,
              41.60723221108916
            ]
          },
          "bbox": [
            2.6161602924234977,
            41.60723221108916,
            2.6161602924234977,
            41.60723221108916
          ]
        }
      ],
      "bbox": [
        2.6161602924234977,
        41.60723221108916,
        2.6161602924234977,
        41.60723221108916
      ]
    },
    "08236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.665324282327145,
              41.45269560454841
            ]
          },
          "bbox": [
            1.665324282327145,
            41.45269560454841,
            1.665324282327145,
            41.45269560454841
          ]
        }
      ],
      "bbox": [
        1.665324282327145,
        41.45269560454841,
        1.665324282327145,
        41.45269560454841
      ]
    },
    "08237": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.229708068603321,
              42.10288110820547
            ]
          },
          "bbox": [
            2.229708068603321,
            42.10288110820547,
            2.229708068603321,
            42.10288110820547
          ]
        }
      ],
      "bbox": [
        2.229708068603321,
        42.10288110820547,
        2.229708068603321,
        42.10288110820547
      ]
    },
    "08238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.070172245586475,
              41.52506244429311
            ]
          },
          "bbox": [
            2.070172245586475,
            41.52506244429311,
            2.070172245586475,
            41.52506244429311
          ]
        }
      ],
      "bbox": [
        2.070172245586475,
        41.52506244429311,
        2.070172245586475,
        41.52506244429311
      ]
    },
    "08239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.185317843351813,
              41.72584401082997
            ]
          },
          "bbox": [
            2.185317843351813,
            41.72584401082997,
            2.185317843351813,
            41.72584401082997
          ]
        }
      ],
      "bbox": [
        2.185317843351813,
        41.72584401082997,
        2.185317843351813,
        41.72584401082997
      ]
    },
    "08242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8026730826132829,
              41.62906312729044
            ]
          },
          "bbox": [
            1.8026730826132829,
            41.62906312729044,
            1.8026730826132829,
            41.62906312729044
          ]
        }
      ],
      "bbox": [
        1.8026730826132829,
        41.62906312729044,
        1.8026730826132829,
        41.62906312729044
      ]
    },
    "08243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2106133032199,
              41.9978825345883
            ]
          },
          "bbox": [
            2.2106133032199,
            41.9978825345883,
            2.2106133032199,
            41.9978825345883
          ]
        }
      ],
      "bbox": [
        2.2106133032199,
        41.9978825345883,
        2.2106133032199,
        41.9978825345883
      ]
    },
    "08244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.018758779565759,
              41.36786464455512
            ]
          },
          "bbox": [
            2.018758779565759,
            41.36786464455512,
            2.018758779565759,
            41.36786464455512
          ]
        }
      ],
      "bbox": [
        2.018758779565759,
        41.36786464455512,
        2.018758779565759,
        41.36786464455512
      ]
    },
    "08245": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.206063687640452,
              41.45754317396638
            ]
          },
          "bbox": [
            2.206063687640452,
            41.45754317396638,
            2.206063687640452,
            41.45754317396638
          ]
        }
      ],
      "bbox": [
        2.206063687640452,
        41.45754317396638,
        2.206063687640452,
        41.45754317396638
      ]
    },
    "08246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.280871637085092,
              41.908226830678686
            ]
          },
          "bbox": [
            2.280871637085092,
            41.908226830678686,
            2.280871637085092,
            41.908226830678686
          ]
        }
      ],
      "bbox": [
        2.280871637085092,
        41.908226830678686,
        2.280871637085092,
        41.908226830678686
      ]
    },
    "08247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1738522794694246,
              41.912503894189285
            ]
          },
          "bbox": [
            2.1738522794694246,
            41.912503894189285,
            2.1738522794694246,
            41.912503894189285
          ]
        }
      ],
      "bbox": [
        2.1738522794694246,
        41.912503894189285,
        2.1738522794694246,
        41.912503894189285
      ]
    },
    "08248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2218654196335166,
              41.64701727152249
            ]
          },
          "bbox": [
            2.2218654196335166,
            41.64701727152249,
            2.2218654196335166,
            41.64701727152249
          ]
        }
      ],
      "bbox": [
        2.2218654196335166,
        41.64701727152249,
        2.2218654196335166,
        41.64701727152249
      ]
    },
    "08249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7202994470837398,
              41.38861347511891
            ]
          },
          "bbox": [
            1.7202994470837398,
            41.38861347511891,
            1.7202994470837398,
            41.38861347511891
          ]
        }
      ],
      "bbox": [
        1.7202994470837398,
        41.38861347511891,
        1.7202994470837398,
        41.38861347511891
      ]
    },
    "08250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5765182467342067,
              41.56072332931305
            ]
          },
          "bbox": [
            1.5765182467342067,
            41.56072332931305,
            1.5765182467342067,
            41.56072332931305
          ]
        }
      ],
      "bbox": [
        1.5765182467342067,
        41.56072332931305,
        1.5765182467342067,
        41.56072332931305
      ]
    },
    "08251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6640986039774757,
              41.31144992347491
            ]
          },
          "bbox": [
            1.6640986039774757,
            41.31144992347491,
            1.6640986039774757,
            41.31144992347491
          ]
        }
      ],
      "bbox": [
        1.6640986039774757,
        41.31144992347491,
        1.6640986039774757,
        41.31144992347491
      ]
    },
    "08252": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.135143428016397,
              41.51828880566871
            ]
          },
          "bbox": [
            2.135143428016397,
            41.51828880566871,
            2.135143428016397,
            41.51828880566871
          ]
        }
      ],
      "bbox": [
        2.135143428016397,
        41.51828880566871,
        2.135143428016397,
        41.51828880566871
      ]
    },
    "08253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.259907179680281,
              42.12197597045021
            ]
          },
          "bbox": [
            2.259907179680281,
            42.12197597045021,
            2.259907179680281,
            42.12197597045021
          ]
        }
      ],
      "bbox": [
        2.259907179680281,
        42.12197597045021,
        2.259907179680281,
        42.12197597045021
      ]
    },
    "08255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9680595973236465,
              41.98877044180871
            ]
          },
          "bbox": [
            1.9680595973236465,
            41.98877044180871,
            1.9680595973236465,
            41.98877044180871
          ]
        }
      ],
      "bbox": [
        1.9680595973236465,
        41.98877044180871,
        1.9680595973236465,
        41.98877044180871
      ]
    },
    "08256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2614125033340593,
              41.51779599197581
            ]
          },
          "bbox": [
            2.2614125033340593,
            41.51779599197581,
            2.2614125033340593,
            41.51779599197581
          ]
        }
      ],
      "bbox": [
        2.2614125033340593,
        41.51779599197581,
        2.2614125033340593,
        41.51779599197581
      ]
    },
    "08257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.5158638864223397,
              41.50552529159394
            ]
          },
          "bbox": [
            1.5158638864223397,
            41.50552529159394,
            1.5158638864223397,
            41.50552529159394
          ]
        }
      ],
      "bbox": [
        1.5158638864223397,
        41.50552529159394,
        1.5158638864223397,
        41.50552529159394
      ]
    },
    "08259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.453474796549937,
              41.68428923131514
            ]
          },
          "bbox": [
            2.453474796549937,
            41.68428923131514,
            2.453474796549937,
            41.68428923131514
          ]
        }
      ],
      "bbox": [
        2.453474796549937,
        41.68428923131514,
        2.453474796549937,
        41.68428923131514
      ]
    },
    "08260": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1764884957897492,
              41.53312912947403
            ]
          },
          "bbox": [
            2.1764884957897492,
            41.53312912947403,
            2.1764884957897492,
            41.53312912947403
          ]
        }
      ],
      "bbox": [
        2.1764884957897492,
        41.53312912947403,
        2.1764884957897492,
        41.53312912947403
      ]
    },
    "08261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6960394346106655,
              41.64990761467492
            ]
          },
          "bbox": [
            2.6960394346106655,
            41.64990761467492,
            2.6960394346106655,
            41.64990761467492
          ]
        }
      ],
      "bbox": [
        2.6960394346106655,
        41.64990761467492,
        2.6960394346106655,
        41.64990761467492
      ]
    },
    "08262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8771880438509878,
              41.66917480893366
            ]
          },
          "bbox": [
            1.8771880438509878,
            41.66917480893366,
            1.8771880438509878,
            41.66917480893366
          ]
        }
      ],
      "bbox": [
        1.8771880438509878,
        41.66917480893366,
        1.8771880438509878,
        41.66917480893366
      ]
    },
    "08263": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.0027566157601955,
              41.390224290891084
            ]
          },
          "bbox": [
            2.0027566157601955,
            41.390224290891084,
            2.0027566157601955,
            41.390224290891084
          ]
        }
      ],
      "bbox": [
        2.0027566157601955,
        41.390224290891084,
        2.0027566157601955,
        41.390224290891084
      ]
    },
    "08264": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.5081581191711106,
              41.58116313889409
            ]
          },
          "bbox": [
            2.5081581191711106,
            41.58116313889409,
            2.5081581191711106,
            41.58116313889409
          ]
        }
      ],
      "bbox": [
        2.5081581191711106,
        41.58116313889409,
        2.5081581191711106,
        41.58116313889409
      ]
    },
    "08265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.265080443627077,
              42.064650910818415
            ]
          },
          "bbox": [
            2.265080443627077,
            42.064650910818415,
            2.265080443627077,
            42.064650910818415
          ]
        }
      ],
      "bbox": [
        2.265080443627077,
        42.064650910818415,
        2.265080443627077,
        42.064650910818415
      ]
    },
    "08266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.12271591144715,
              41.480649977149966
            ]
          },
          "bbox": [
            2.12271591144715,
            41.480649977149966,
            2.12271591144715,
            41.480649977149966
          ]
        }
      ],
      "bbox": [
        2.12271591144715,
        41.480649977149966,
        2.12271591144715,
        41.480649977149966
      ]
    },
    "08267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1305241795763608,
              41.61340679893159
            ]
          },
          "bbox": [
            2.1305241795763608,
            41.61340679893159,
            2.1305241795763608,
            41.61340679893159
          ]
        }
      ],
      "bbox": [
        2.1305241795763608,
        41.61340679893159,
        2.1305241795763608,
        41.61340679893159
      ]
    },
    "08268": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.850169815261653,
              42.13962083616594
            ]
          },
          "bbox": [
            1.850169815261653,
            42.13962083616594,
            1.850169815261653,
            42.13962083616594
          ]
        }
      ],
      "bbox": [
        1.850169815261653,
        42.13962083616594,
        1.850169815261653,
        42.13962083616594
      ]
    },
    "08269": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2817595056604376,
              41.8306750035946
            ]
          },
          "bbox": [
            2.2817595056604376,
            41.8306750035946,
            2.2817595056604376,
            41.8306750035946
          ]
        }
      ],
      "bbox": [
        2.2817595056604376,
        41.8306750035946,
        2.2817595056604376,
        41.8306750035946
      ]
    },
    "08270": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8602179637260556,
              41.25597169702629
            ]
          },
          "bbox": [
            1.8602179637260556,
            41.25597169702629,
            1.8602179637260556,
            41.25597169702629
          ]
        }
      ],
      "bbox": [
        1.8602179637260556,
        41.25597169702629,
        1.8602179637260556,
        41.25597169702629
      ]
    },
    "08271": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.1701040532277824,
              42.021774282756
            ]
          },
          "bbox": [
            2.1701040532277824,
            42.021774282756,
            2.1701040532277824,
            42.021774282756
          ]
        }
      ],
      "bbox": [
        2.1701040532277824,
        42.021774282756,
        2.1701040532277824,
        42.021774282756
      ]
    },
    "08272": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.170510675805226,
              42.105837743449015
            ]
          },
          "bbox": [
            2.170510675805226,
            42.105837743449015,
            2.170510675805226,
            42.105837743449015
          ]
        }
      ],
      "bbox": [
        2.170510675805226,
        42.105837743449015,
        2.170510675805226,
        42.105837743449015
      ]
    },
    "08273": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.804483446259864,
              41.39811101246584
            ]
          },
          "bbox": [
            1.804483446259864,
            41.39811101246584,
            1.804483446259864,
            41.39811101246584
          ]
        }
      ],
      "bbox": [
        1.804483446259864,
        41.39811101246584,
        1.804483446259864,
        41.39811101246584
      ]
    },
    "08274": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7639872856964884,
              41.83480547597437
            ]
          },
          "bbox": [
            1.7639872856964884,
            41.83480547597437,
            1.7639872856964884,
            41.83480547597437
          ]
        }
      ],
      "bbox": [
        1.7639872856964884,
        41.83480547597437,
        1.7639872856964884,
        41.83480547597437
      ]
    },
    "08275": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3444527588935338,
              41.956098543043346
            ]
          },
          "bbox": [
            2.3444527588935338,
            41.956098543043346,
            2.3444527588935338,
            41.956098543043346
          ]
        }
      ],
      "bbox": [
        2.3444527588935338,
        41.956098543043346,
        2.3444527588935338,
        41.956098543043346
      ]
    },
    "08276": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.301130590358041,
              41.75627673055104
            ]
          },
          "bbox": [
            2.301130590358041,
            41.75627673055104,
            2.301130590358041,
            41.75627673055104
          ]
        }
      ],
      "bbox": [
        2.301130590358041,
        41.75627673055104,
        2.301130590358041,
        41.75627673055104
      ]
    },
    "08277": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9520635274703215,
              41.731860237332164
            ]
          },
          "bbox": [
            1.9520635274703215,
            41.731860237332164,
            1.9520635274703215,
            41.731860237332164
          ]
        }
      ],
      "bbox": [
        1.9520635274703215,
        41.731860237332164,
        1.9520635274703215,
        41.731860237332164
      ]
    },
    "08278": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2863086265395665,
              41.87439038070232
            ]
          },
          "bbox": [
            2.2863086265395665,
            41.87439038070232,
            2.2863086265395665,
            41.87439038070232
          ]
        }
      ],
      "bbox": [
        2.2863086265395665,
        41.87439038070232,
        2.2863086265395665,
        41.87439038070232
      ]
    },
    "08279": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.008003657130879,
              41.575244889699135
            ]
          },
          "bbox": [
            2.008003657130879,
            41.575244889699135,
            2.008003657130879,
            41.575244889699135
          ]
        }
      ],
      "bbox": [
        2.008003657130879,
        41.575244889699135,
        2.008003657130879,
        41.575244889699135
      ]
    },
    "08280": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4031714041732823,
              42.00477005303692
            ]
          },
          "bbox": [
            2.4031714041732823,
            42.00477005303692,
            2.4031714041732823,
            42.00477005303692
          ]
        }
      ],
      "bbox": [
        2.4031714041732823,
        42.00477005303692,
        2.4031714041732823,
        42.00477005303692
      ]
    },
    "08281": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.3215211905448188,
              41.50050135954895
            ]
          },
          "bbox": [
            2.3215211905448188,
            41.50050135954895,
            2.3215211905448188,
            41.50050135954895
          ]
        }
      ],
      "bbox": [
        2.3215211905448188,
        41.50050135954895,
        2.3215211905448188,
        41.50050135954895
      ]
    },
    "08282": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.268101261024994,
              41.485789291210814
            ]
          },
          "bbox": [
            2.268101261024994,
            41.485789291210814,
            2.268101261024994,
            41.485789291210814
          ]
        }
      ],
      "bbox": [
        2.268101261024994,
        41.485789291210814,
        2.268101261024994,
        41.485789291210814
      ]
    },
    "08283": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2191581559998426,
              41.855615745529235
            ]
          },
          "bbox": [
            2.2191581559998426,
            41.855615745529235,
            2.2191581559998426,
            41.855615745529235
          ]
        }
      ],
      "bbox": [
        2.2191581559998426,
        41.855615745529235,
        2.2191581559998426,
        41.855615745529235
      ]
    },
    "08284": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.6861591140275736,
              41.69289554881384
            ]
          },
          "bbox": [
            2.6861591140275736,
            41.69289554881384,
            2.6861591140275736,
            41.69289554881384
          ]
        }
      ],
      "bbox": [
        2.6861591140275736,
        41.69289554881384,
        2.6861591140275736,
        41.69289554881384
      ]
    },
    "08285": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.26645355237782,
              42.03824007591262
            ]
          },
          "bbox": [
            2.26645355237782,
            42.03824007591262,
            2.26645355237782,
            42.03824007591262
          ]
        }
      ],
      "bbox": [
        2.26645355237782,
        42.03824007591262,
        2.26645355237782,
        42.03824007591262
      ]
    },
    "08286": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6592197764716807,
              41.523776931242836
            ]
          },
          "bbox": [
            1.6592197764716807,
            41.523776931242836,
            1.6592197764716807,
            41.523776931242836
          ]
        }
      ],
      "bbox": [
        1.6592197764716807,
        41.523776931242836,
        1.6592197764716807,
        41.523776931242836
      ]
    },
    "08287": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.723174607125717,
              41.440718650462934
            ]
          },
          "bbox": [
            1.723174607125717,
            41.440718650462934,
            1.723174607125717,
            41.440718650462934
          ]
        }
      ],
      "bbox": [
        1.723174607125717,
        41.440718650462934,
        1.723174607125717,
        41.440718650462934
      ]
    },
    "08288": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.570180057249709,
              41.4093042497447
            ]
          },
          "bbox": [
            1.570180057249709,
            41.4093042497447,
            1.570180057249709,
            41.4093042497447
          ]
        }
      ],
      "bbox": [
        1.570180057249709,
        41.4093042497447,
        1.570180057249709,
        41.4093042497447
      ]
    },
    "08289": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.971273849114371,
              41.354630998893974
            ]
          },
          "bbox": [
            1.971273849114371,
            41.354630998893974,
            1.971273849114371,
            41.354630998893974
          ]
        }
      ],
      "bbox": [
        1.971273849114371,
        41.354630998893974,
        1.971273849114371,
        41.354630998893974
      ]
    },
    "08290": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9633053242042462,
              41.52554805674476
            ]
          },
          "bbox": [
            1.9633053242042462,
            41.52554805674476,
            1.9633053242042462,
            41.52554805674476
          ]
        }
      ],
      "bbox": [
        1.9633053242042462,
        41.52554805674476,
        1.9633053242042462,
        41.52554805674476
      ]
    },
    "08291": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9173956171161668,
              41.60390713026104
            ]
          },
          "bbox": [
            1.9173956171161668,
            41.60390713026104,
            1.9173956171161668,
            41.60390713026104
          ]
        }
      ],
      "bbox": [
        1.9173956171161668,
        41.60390713026104,
        1.9173956171161668,
        41.60390713026104
      ]
    },
    "08293": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.8121711470284323,
              42.2061964409355
            ]
          },
          "bbox": [
            1.8121711470284323,
            42.2061964409355,
            1.8121711470284323,
            42.2061964409355
          ]
        }
      ],
      "bbox": [
        1.8121711470284323,
        42.2061964409355,
        1.8121711470284323,
        42.2061964409355
      ]
    },
    "08294": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4968839549378403,
              41.64675555918988
            ]
          },
          "bbox": [
            2.4968839549378403,
            41.64675555918988,
            2.4968839549378403,
            41.64675555918988
          ]
        }
      ],
      "bbox": [
        2.4968839549378403,
        41.64675555918988,
        2.4968839549378403,
        41.64675555918988
      ]
    },
    "08295": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9166797310286499,
              41.373840060702115
            ]
          },
          "bbox": [
            1.9166797310286499,
            41.373840060702115,
            1.9166797310286499,
            41.373840060702115
          ]
        }
      ],
      "bbox": [
        1.9166797310286499,
        41.373840060702115,
        1.9166797310286499,
        41.373840060702115
      ]
    },
    "08296": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.299005533447229,
              41.52680146569042
            ]
          },
          "bbox": [
            2.299005533447229,
            41.52680146569042,
            2.299005533447229,
            41.52680146569042
          ]
        }
      ],
      "bbox": [
        2.299005533447229,
        41.52680146569042,
        2.299005533447229,
        41.52680146569042
      ]
    },
    "08297": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.4806563538776696,
              41.66814463659661
            ]
          },
          "bbox": [
            1.4806563538776696,
            41.66814463659661,
            1.4806563538776696,
            41.66814463659661
          ]
        }
      ],
      "bbox": [
        1.4806563538776696,
        41.66814463659661,
        1.4806563538776696,
        41.66814463659661
      ]
    },
    "08298": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.2364212507880823,
              41.92235032748175
            ]
          },
          "bbox": [
            2.2364212507880823,
            41.92235032748175,
            2.2364212507880823,
            41.92235032748175
          ]
        }
      ],
      "bbox": [
        2.2364212507880823,
        41.92235032748175,
        2.2364212507880823,
        41.92235032748175
      ]
    },
    "08299": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9230510335249513,
              42.13643357158761
            ]
          },
          "bbox": [
            1.9230510335249513,
            42.13643357158761,
            1.9230510335249513,
            42.13643357158761
          ]
        }
      ],
      "bbox": [
        1.9230510335249513,
        42.13643357158761,
        1.9230510335249513,
        42.13643357158761
      ]
    },
    "08300": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9514326829265098,
              41.55777762693887
            ]
          },
          "bbox": [
            1.9514326829265098,
            41.55777762693887,
            1.9514326829265098,
            41.55777762693887
          ]
        }
      ],
      "bbox": [
        1.9514326829265098,
        41.55777762693887,
        1.9514326829265098,
        41.55777762693887
      ]
    },
    "08301": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.031990176615236,
              41.30276515046201
            ]
          },
          "bbox": [
            2.031990176615236,
            41.30276515046201,
            2.031990176615236,
            41.30276515046201
          ]
        }
      ],
      "bbox": [
        2.031990176615236,
        41.30276515046201,
        2.031990176615236,
        41.30276515046201
      ]
    },
    "08302": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.6341975800716266,
              41.56122246236074
            ]
          },
          "bbox": [
            1.6341975800716266,
            41.56122246236074,
            1.6341975800716266,
            41.56122246236074
          ]
        }
      ],
      "bbox": [
        1.6341975800716266,
        41.56122246236074,
        1.6341975800716266,
        41.56122246236074
      ]
    },
    "08303": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4220929198886085,
              41.942924096246095
            ]
          },
          "bbox": [
            2.4220929198886085,
            41.942924096246095,
            2.4220929198886085,
            41.942924096246095
          ]
        }
      ],
      "bbox": [
        2.4220929198886085,
        41.942924096246095,
        2.4220929198886085,
        41.942924096246095
      ]
    },
    "08304": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.666300220646973,
              41.38571186927271
            ]
          },
          "bbox": [
            1.666300220646973,
            41.38571186927271,
            1.666300220646973,
            41.38571186927271
          ]
        }
      ],
      "bbox": [
        1.666300220646973,
        41.38571186927271,
        1.666300220646973,
        41.38571186927271
      ]
    },
    "08305": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7006341605770192,
              41.347486133999716
            ]
          },
          "bbox": [
            1.7006341605770192,
            41.347486133999716,
            1.7006341605770192,
            41.347486133999716
          ]
        }
      ],
      "bbox": [
        1.7006341605770192,
        41.347486133999716,
        1.7006341605770192,
        41.347486133999716
      ]
    },
    "08306": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.463367528549671,
              41.64028568110654
            ]
          },
          "bbox": [
            2.463367528549671,
            41.64028568110654,
            2.463367528549671,
            41.64028568110654
          ]
        }
      ],
      "bbox": [
        2.463367528549671,
        41.64028568110654,
        2.463367528549671,
        41.64028568110654
      ]
    },
    "08307": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.704913173517615,
              41.236985865338326
            ]
          },
          "bbox": [
            1.704913173517615,
            41.236985865338326,
            1.704913173517615,
            41.236985865338326
          ]
        }
      ],
      "bbox": [
        1.704913173517615,
        41.236985865338326,
        1.704913173517615,
        41.236985865338326
      ]
    },
    "08308": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.7933970177003282,
              41.9478663794216
            ]
          },
          "bbox": [
            1.7933970177003282,
            41.9478663794216,
            1.7933970177003282,
            41.9478663794216
          ]
        }
      ],
      "bbox": [
        1.7933970177003282,
        41.9478663794216,
        1.7933970177003282,
        41.9478663794216
      ]
    },
    "08901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.4631623042693698,
              42.02908373382566
            ]
          },
          "bbox": [
            2.4631623042693698,
            42.02908373382566,
            2.4631623042693698,
            42.02908373382566
          ]
        }
      ],
      "bbox": [
        2.4631623042693698,
        42.02908373382566,
        2.4631623042693698,
        42.02908373382566
      ]
    },
    "08902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.306486184492049,
              41.555031347981014
            ]
          },
          "bbox": [
            2.306486184492049,
            41.555031347981014,
            2.306486184492049,
            41.555031347981014
          ]
        }
      ],
      "bbox": [
        2.306486184492049,
        41.555031347981014,
        2.306486184492049,
        41.555031347981014
      ]
    },
    "08903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.9089184892593456,
              42.21851662320872
            ]
          },
          "bbox": [
            1.9089184892593456,
            42.21851662320872,
            1.9089184892593456,
            42.21851662320872
          ]
        }
      ],
      "bbox": [
        1.9089184892593456,
        42.21851662320872,
        1.9089184892593456,
        42.21851662320872
      ]
    },
    "08904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              2.114885301343017,
              41.508086182468595
            ]
          },
          "bbox": [
            2.114885301343017,
            41.508086182468595,
            2.114885301343017,
            41.508086182468595
          ]
        }
      ],
      "bbox": [
        2.114885301343017,
        41.508086182468595,
        2.114885301343017,
        41.508086182468595
      ]
    },
    "08905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              1.963334154761632,
              41.41020078529606
            ]
          },
          "bbox": [
            1.963334154761632,
            41.41020078529606,
            1.963334154761632,
            41.41020078529606
          ]
        }
      ],
      "bbox": [
        1.963334154761632,
        41.41020078529606,
        1.963334154761632,
        41.41020078529606
      ]
    },
    "09001": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5858627532346277,
              42.619007394891796
            ]
          },
          "bbox": [
            -3.5858627532346277,
            42.619007394891796,
            -3.5858627532346277,
            42.619007394891796
          ]
        }
      ],
      "bbox": [
        -3.5858627532346277,
        42.619007394891796,
        -3.5858627532346277,
        42.619007394891796
      ]
    },
    "09003": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8180506921607154,
              41.59811256201211
            ]
          },
          "bbox": [
            -3.8180506921607154,
            41.59811256201211,
            -3.8180506921607154,
            41.59811256201211
          ]
        }
      ],
      "bbox": [
        -3.8180506921607154,
        41.59811256201211,
        -3.8180506921607154,
        41.59811256201211
      ]
    },
    "09006": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.497080995668976,
              42.73406274009091
            ]
          },
          "bbox": [
            -3.497080995668976,
            42.73406274009091,
            -3.497080995668976,
            42.73406274009091
          ]
        }
      ],
      "bbox": [
        -3.497080995668976,
        42.73406274009091,
        -3.497080995668976,
        42.73406274009091
      ]
    },
    "09007": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3327737164073783,
              42.591665424340576
            ]
          },
          "bbox": [
            -3.3327737164073783,
            42.591665424340576,
            -3.3327737164073783,
            42.591665424340576
          ]
        }
      ],
      "bbox": [
        -3.3327737164073783,
        42.591665424340576,
        -3.3327737164073783,
        42.591665424340576
      ]
    },
    "09009": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.788700025255338,
              42.284882825581356
            ]
          },
          "bbox": [
            -3.788700025255338,
            42.284882825581356,
            -3.788700025255338,
            42.284882825581356
          ]
        }
      ],
      "bbox": [
        -3.788700025255338,
        42.284882825581356,
        -3.788700025255338,
        42.284882825581356
      ]
    },
    "09010": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3565096165658046,
              42.47357189680809
            ]
          },
          "bbox": [
            -3.3565096165658046,
            42.47357189680809,
            -3.3565096165658046,
            42.47357189680809
          ]
        }
      ],
      "bbox": [
        -3.3565096165658046,
        42.47357189680809,
        -3.3565096165658046,
        42.47357189680809
      ]
    },
    "09011": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8571521989914443,
              42.89818113687745
            ]
          },
          "bbox": [
            -3.8571521989914443,
            42.89818113687745,
            -3.8571521989914443,
            42.89818113687745
          ]
        }
      ],
      "bbox": [
        -3.8571521989914443,
        42.89818113687745,
        -3.8571521989914443,
        42.89818113687745
      ]
    },
    "09012": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.950076197486684,
              42.943326082002734
            ]
          },
          "bbox": [
            -3.950076197486684,
            42.943326082002734,
            -3.950076197486684,
            42.943326082002734
          ]
        }
      ],
      "bbox": [
        -3.950076197486684,
        42.943326082002734,
        -3.950076197486684,
        42.943326082002734
      ]
    },
    "09013": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.078770033038724,
              42.6006356185675
            ]
          },
          "bbox": [
            -3.078770033038724,
            42.6006356185675,
            -3.078770033038724,
            42.6006356185675
          ]
        }
      ],
      "bbox": [
        -3.078770033038724,
        42.6006356185675,
        -3.078770033038724,
        42.6006356185675
      ]
    },
    "09014": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6465519569159413,
              42.784725461378414
            ]
          },
          "bbox": [
            -3.6465519569159413,
            42.784725461378414,
            -3.6465519569159413,
            42.784725461378414
          ]
        }
      ],
      "bbox": [
        -3.6465519569159413,
        42.784725461378414,
        -3.6465519569159413,
        42.784725461378414
      ]
    },
    "09016": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0588902576818975,
              42.65617351439514
            ]
          },
          "bbox": [
            -3.0588902576818975,
            42.65617351439514,
            -3.0588902576818975,
            42.65617351439514
          ]
        }
      ],
      "bbox": [
        -3.0588902576818975,
        42.65617351439514,
        -3.0588902576818975,
        42.65617351439514
      ]
    },
    "09017": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9270378992094668,
              41.75084279618117
            ]
          },
          "bbox": [
            -3.9270378992094668,
            41.75084279618117,
            -3.9270378992094668,
            41.75084279618117
          ]
        }
      ],
      "bbox": [
        -3.9270378992094668,
        41.75084279618117,
        -3.9270378992094668,
        41.75084279618117
      ]
    },
    "09018": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.685159676227783,
              41.68995747133356
            ]
          },
          "bbox": [
            -3.685159676227783,
            41.68995747133356,
            -3.685159676227783,
            41.68995747133356
          ]
        }
      ],
      "bbox": [
        -3.685159676227783,
        41.68995747133356,
        -3.685159676227783,
        41.68995747133356
      ]
    },
    "09019": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.42705986923101,
              41.73220062514337
            ]
          },
          "bbox": [
            -3.42705986923101,
            41.73220062514337,
            -3.42705986923101,
            41.73220062514337
          ]
        }
      ],
      "bbox": [
        -3.42705986923101,
        41.73220062514337,
        -3.42705986923101,
        41.73220062514337
      ]
    },
    "09020": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.391668802168271,
              41.87162993991496
            ]
          },
          "bbox": [
            -3.391668802168271,
            41.87162993991496,
            -3.391668802168271,
            41.87162993991496
          ]
        }
      ],
      "bbox": [
        -3.391668802168271,
        41.87162993991496,
        -3.391668802168271,
        41.87162993991496
      ]
    },
    "09021": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.409294495043224,
              41.82266915436514
            ]
          },
          "bbox": [
            -3.409294495043224,
            41.82266915436514,
            -3.409294495043224,
            41.82266915436514
          ]
        }
      ],
      "bbox": [
        -3.409294495043224,
        41.82266915436514,
        -3.409294495043224,
        41.82266915436514
      ]
    },
    "09022": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.433555687512624,
              41.79222805705648
            ]
          },
          "bbox": [
            -3.433555687512624,
            41.79222805705648,
            -3.433555687512624,
            41.79222805705648
          ]
        }
      ],
      "bbox": [
        -3.433555687512624,
        41.79222805705648,
        -3.433555687512624,
        41.79222805705648
      ]
    },
    "09023": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.758283233072603,
              42.24642074911447
            ]
          },
          "bbox": [
            -3.758283233072603,
            42.24642074911447,
            -3.758283233072603,
            42.24642074911447
          ]
        }
      ],
      "bbox": [
        -3.758283233072603,
        42.24642074911447,
        -3.758283233072603,
        42.24642074911447
      ]
    },
    "09024": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.223262616226488,
              42.34960173683744
            ]
          },
          "bbox": [
            -4.223262616226488,
            42.34960173683744,
            -4.223262616226488,
            42.34960173683744
          ]
        }
      ],
      "bbox": [
        -4.223262616226488,
        42.34960173683744,
        -4.223262616226488,
        42.34960173683744
      ]
    },
    "09025": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.950611784639804,
              42.98737399220425
            ]
          },
          "bbox": [
            -3.950611784639804,
            42.98737399220425,
            -3.950611784639804,
            42.98737399220425
          ]
        }
      ],
      "bbox": [
        -3.950611784639804,
        42.98737399220425,
        -3.950611784639804,
        42.98737399220425
      ]
    },
    "09026": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4351077838479886,
              42.33883398623132
            ]
          },
          "bbox": [
            -3.4351077838479886,
            42.33883398623132,
            -3.4351077838479886,
            42.33883398623132
          ]
        }
      ],
      "bbox": [
        -3.4351077838479886,
        42.33883398623132,
        -3.4351077838479886,
        42.33883398623132
      ]
    },
    "09027": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.389797897935364,
              42.40188690594644
            ]
          },
          "bbox": [
            -3.389797897935364,
            42.40188690594644,
            -3.389797897935364,
            42.40188690594644
          ]
        }
      ],
      "bbox": [
        -3.389797897935364,
        42.40188690594644,
        -3.389797897935364,
        42.40188690594644
      ]
    },
    "09029": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5064858829063166,
              42.38151170047109
            ]
          },
          "bbox": [
            -3.5064858829063166,
            42.38151170047109,
            -3.5064858829063166,
            42.38151170047109
          ]
        }
      ],
      "bbox": [
        -3.5064858829063166,
        42.38151170047109,
        -3.5064858829063166,
        42.38151170047109
      ]
    },
    "09030": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5813318535668386,
              42.20180872354902
            ]
          },
          "bbox": [
            -3.5813318535668386,
            42.20180872354902,
            -3.5813318535668386,
            42.20180872354902
          ]
        }
      ],
      "bbox": [
        -3.5813318535668386,
        42.20180872354902,
        -3.5813318535668386,
        42.20180872354902
      ]
    },
    "09032": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8563070897422147,
              41.99005580612452
            ]
          },
          "bbox": [
            -3.8563070897422147,
            41.99005580612452,
            -3.8563070897422147,
            41.99005580612452
          ]
        }
      ],
      "bbox": [
        -3.8563070897422147,
        41.99005580612452,
        -3.8563070897422147,
        41.99005580612452
      ]
    },
    "09033": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7249654561161702,
              41.865013968459344
            ]
          },
          "bbox": [
            -3.7249654561161702,
            41.865013968459344,
            -3.7249654561161702,
            41.865013968459344
          ]
        }
      ],
      "bbox": [
        -3.7249654561161702,
        41.865013968459344,
        -3.7249654561161702,
        41.865013968459344
      ]
    },
    "09034": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.070932452822106,
              42.21818365162758
            ]
          },
          "bbox": [
            -4.070932452822106,
            42.21818365162758,
            -4.070932452822106,
            42.21818365162758
          ]
        }
      ],
      "bbox": [
        -4.070932452822106,
        42.21818365162758,
        -4.070932452822106,
        42.21818365162758
      ]
    },
    "09035": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.556312076829769,
              41.77034397289387
            ]
          },
          "bbox": [
            -3.556312076829769,
            41.77034397289387,
            -3.556312076829769,
            41.77034397289387
          ]
        }
      ],
      "bbox": [
        -3.556312076829769,
        41.77034397289387,
        -3.556312076829769,
        41.77034397289387
      ]
    },
    "09036": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.272120351875524,
              42.5035280975942
            ]
          },
          "bbox": [
            -3.272120351875524,
            42.5035280975942,
            -3.272120351875524,
            42.5035280975942
          ]
        }
      ],
      "bbox": [
        -3.272120351875524,
        42.5035280975942,
        -3.272120351875524,
        42.5035280975942
      ]
    },
    "09037": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1586254822812925,
              42.19543396797962
            ]
          },
          "bbox": [
            -3.1586254822812925,
            42.19543396797962,
            -3.1586254822812925,
            42.19543396797962
          ]
        }
      ],
      "bbox": [
        -3.1586254822812925,
        42.19543396797962,
        -3.1586254822812925,
        42.19543396797962
      ]
    },
    "09038": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.364809135326435,
              42.042478327647395
            ]
          },
          "bbox": [
            -3.364809135326435,
            42.042478327647395,
            -3.364809135326435,
            42.042478327647395
          ]
        }
      ],
      "bbox": [
        -3.364809135326435,
        42.042478327647395,
        -3.364809135326435,
        42.042478327647395
      ]
    },
    "09039": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2325177659619437,
              42.121433780367816
            ]
          },
          "bbox": [
            -3.2325177659619437,
            42.121433780367816,
            -3.2325177659619437,
            42.121433780367816
          ]
        }
      ],
      "bbox": [
        -3.2325177659619437,
        42.121433780367816,
        -3.2325177659619437,
        42.121433780367816
      ]
    },
    "09041": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.999733307962516,
              42.17954710026833
            ]
          },
          "bbox": [
            -3.999733307962516,
            42.17954710026833,
            -3.999733307962516,
            42.17954710026833
          ]
        }
      ],
      "bbox": [
        -3.999733307962516,
        42.17954710026833,
        -3.999733307962516,
        42.17954710026833
      ]
    },
    "09043": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.366767377183842,
              42.64245544684654
            ]
          },
          "bbox": [
            -3.366767377183842,
            42.64245544684654,
            -3.366767377183842,
            42.64245544684654
          ]
        }
      ],
      "bbox": [
        -3.366767377183842,
        42.64245544684654,
        -3.366767377183842,
        42.64245544684654
      ]
    },
    "09044": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.44515862123593,
              42.387248148404176
            ]
          },
          "bbox": [
            -3.44515862123593,
            42.387248148404176,
            -3.44515862123593,
            42.387248148404176
          ]
        }
      ],
      "bbox": [
        -3.44515862123593,
        42.387248148404176,
        -3.44515862123593,
        42.387248148404176
      ]
    },
    "09045": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.95123486554548,
              42.681257200161404
            ]
          },
          "bbox": [
            -3.95123486554548,
            42.681257200161404,
            -3.95123486554548,
            42.681257200161404
          ]
        }
      ],
      "bbox": [
        -3.95123486554548,
        42.681257200161404,
        -3.95123486554548,
        42.681257200161404
      ]
    },
    "09046": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.098705559580271,
              42.40954260200546
            ]
          },
          "bbox": [
            -3.098705559580271,
            42.40954260200546,
            -3.098705559580271,
            42.40954260200546
          ]
        }
      ],
      "bbox": [
        -3.098705559580271,
        42.40954260200546,
        -3.098705559580271,
        42.40954260200546
      ]
    },
    "09047": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.014515878467739,
              42.15880745707853
            ]
          },
          "bbox": [
            -4.014515878467739,
            42.15880745707853,
            -4.014515878467739,
            42.15880745707853
          ]
        }
      ],
      "bbox": [
        -4.014515878467739,
        42.15880745707853,
        -4.014515878467739,
        42.15880745707853
      ]
    },
    "09048": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1946862041921222,
              42.4228430247971
            ]
          },
          "bbox": [
            -3.1946862041921222,
            42.4228430247971,
            -3.1946862041921222,
            42.4228430247971
          ]
        }
      ],
      "bbox": [
        -3.1946862041921222,
        42.4228430247971,
        -3.1946862041921222,
        42.4228430247971
      ]
    },
    "09050": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.056143137326015,
              42.91269736362261
            ]
          },
          "bbox": [
            -3.056143137326015,
            42.91269736362261,
            -3.056143137326015,
            42.91269736362261
          ]
        }
      ],
      "bbox": [
        -3.056143137326015,
        42.91269736362261,
        -3.056143137326015,
        42.91269736362261
      ]
    },
    "09051": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8590860297990086,
              41.68338610087439
            ]
          },
          "bbox": [
            -3.8590860297990086,
            41.68338610087439,
            -3.8590860297990086,
            41.68338610087439
          ]
        }
      ],
      "bbox": [
        -3.8590860297990086,
        41.68338610087439,
        -3.8590860297990086,
        41.68338610087439
      ]
    },
    "09052": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.269704823927002,
              42.62459064231521
            ]
          },
          "bbox": [
            -3.269704823927002,
            42.62459064231521,
            -3.269704823927002,
            42.62459064231521
          ]
        }
      ],
      "bbox": [
        -3.269704823927002,
        42.62459064231521,
        -3.269704823927002,
        42.62459064231521
      ]
    },
    "09054": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.096894436299829,
              42.73943370492445
            ]
          },
          "bbox": [
            -3.096894436299829,
            42.73943370492445,
            -3.096894436299829,
            42.73943370492445
          ]
        }
      ],
      "bbox": [
        -3.096894436299829,
        42.73943370492445,
        -3.096894436299829,
        42.73943370492445
      ]
    },
    "09055": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.392194214303711,
              41.698381569584384
            ]
          },
          "bbox": [
            -3.392194214303711,
            41.698381569584384,
            -3.392194214303711,
            41.698381569584384
          ]
        }
      ],
      "bbox": [
        -3.392194214303711,
        41.698381569584384,
        -3.392194214303711,
        41.698381569584384
      ]
    },
    "09056": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.314082075545779,
              42.541292110609604
            ]
          },
          "bbox": [
            -3.314082075545779,
            42.541292110609604,
            -3.314082075545779,
            42.541292110609604
          ]
        }
      ],
      "bbox": [
        -3.314082075545779,
        42.541292110609604,
        -3.314082075545779,
        42.541292110609604
      ]
    },
    "09057": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0225749353736084,
              42.65392780345759
            ]
          },
          "bbox": [
            -3.0225749353736084,
            42.65392780345759,
            -3.0225749353736084,
            42.65392780345759
          ]
        }
      ],
      "bbox": [
        -3.0225749353736084,
        42.65392780345759,
        -3.0225749353736084,
        42.65392780345759
      ]
    },
    "09058": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.805655036810357,
              42.31067495495531
            ]
          },
          "bbox": [
            -3.805655036810357,
            42.31067495495531,
            -3.805655036810357,
            42.31067495495531
          ]
        }
      ],
      "bbox": [
        -3.805655036810357,
        42.31067495495531,
        -3.805655036810357,
        42.31067495495531
      ]
    },
    "09059": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6822701063187337,
              42.351508737549935
            ]
          },
          "bbox": [
            -3.6822701063187337,
            42.351508737549935,
            -3.6822701063187337,
            42.351508737549935
          ]
        }
      ],
      "bbox": [
        -3.6822701063187337,
        42.351508737549935,
        -3.6822701063187337,
        42.351508737549935
      ]
    },
    "09060": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2598911773159447,
              42.65892611210219
            ]
          },
          "bbox": [
            -3.2598911773159447,
            42.65892611210219,
            -3.2598911773159447,
            42.65892611210219
          ]
        }
      ],
      "bbox": [
        -3.2598911773159447,
        42.65892611210219,
        -3.2598911773159447,
        42.65892611210219
      ]
    },
    "09061": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7946769021526143,
              41.83566438711905
            ]
          },
          "bbox": [
            -3.7946769021526143,
            41.83566438711905,
            -3.7946769021526143,
            41.83566438711905
          ]
        }
      ],
      "bbox": [
        -3.7946769021526143,
        41.83566438711905,
        -3.7946769021526143,
        41.83566438711905
      ]
    },
    "09062": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.241813438677033,
              41.93209212365947
            ]
          },
          "bbox": [
            -3.241813438677033,
            41.93209212365947,
            -3.241813438677033,
            41.93209212365947
          ]
        }
      ],
      "bbox": [
        -3.241813438677033,
        41.93209212365947,
        -3.241813438677033,
        41.93209212365947
      ]
    },
    "09063": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8445153000944674,
              42.282042211722334
            ]
          },
          "bbox": [
            -3.8445153000944674,
            42.282042211722334,
            -3.8445153000944674,
            42.282042211722334
          ]
        }
      ],
      "bbox": [
        -3.8445153000944674,
        42.282042211722334,
        -3.8445153000944674,
        42.282042211722334
      ]
    },
    "09064": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4749096953975482,
              41.82313144956905
            ]
          },
          "bbox": [
            -3.4749096953975482,
            41.82313144956905,
            -3.4749096953975482,
            41.82313144956905
          ]
        }
      ],
      "bbox": [
        -3.4749096953975482,
        41.82313144956905,
        -3.4749096953975482,
        41.82313144956905
      ]
    },
    "09065": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7320263534411304,
              41.60855376609097
            ]
          },
          "bbox": [
            -3.7320263534411304,
            41.60855376609097,
            -3.7320263534411304,
            41.60855376609097
          ]
        }
      ],
      "bbox": [
        -3.7320263534411304,
        41.60855376609097,
        -3.7320263534411304,
        41.60855376609097
      ]
    },
    "09066": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4200501536202927,
              42.119824372707576
            ]
          },
          "bbox": [
            -3.4200501536202927,
            42.119824372707576,
            -3.4200501536202927,
            42.119824372707576
          ]
        }
      ],
      "bbox": [
        -3.4200501536202927,
        42.119824372707576,
        -3.4200501536202927,
        42.119824372707576
      ]
    },
    "09067": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0333769702744786,
              41.92493816102201
            ]
          },
          "bbox": [
            -3.0333769702744786,
            41.92493816102201,
            -3.0333769702744786,
            41.92493816102201
          ]
        }
      ],
      "bbox": [
        -3.0333769702744786,
        41.92493816102201,
        -3.0333769702744786,
        41.92493816102201
      ]
    },
    "09068": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.468328844757548,
              42.73766140441792
            ]
          },
          "bbox": [
            -3.468328844757548,
            42.73766140441792,
            -3.468328844757548,
            42.73766140441792
          ]
        }
      ],
      "bbox": [
        -3.468328844757548,
        42.73766140441792,
        -3.468328844757548,
        42.73766140441792
      ]
    },
    "09070": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.353536839968774,
              41.961702512788854
            ]
          },
          "bbox": [
            -3.353536839968774,
            41.961702512788854,
            -3.353536839968774,
            41.961702512788854
          ]
        }
      ],
      "bbox": [
        -3.353536839968774,
        41.961702512788854,
        -3.353536839968774,
        41.961702512788854
      ]
    },
    "09071": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5158031517302764,
              42.58103987424688
            ]
          },
          "bbox": [
            -3.5158031517302764,
            42.58103987424688,
            -3.5158031517302764,
            42.58103987424688
          ]
        }
      ],
      "bbox": [
        -3.5158031517302764,
        42.58103987424688,
        -3.5158031517302764,
        42.58103987424688
      ]
    },
    "09072": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.62106405120838,
              42.27606328977838
            ]
          },
          "bbox": [
            -3.62106405120838,
            42.27606328977838,
            -3.62106405120838,
            42.27606328977838
          ]
        }
      ],
      "bbox": [
        -3.62106405120838,
        42.27606328977838,
        -3.62106405120838,
        42.27606328977838
      ]
    },
    "09073": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.66710674603058,
              42.29580757145897
            ]
          },
          "bbox": [
            -3.66710674603058,
            42.29580757145897,
            -3.66710674603058,
            42.29580757145897
          ]
        }
      ],
      "bbox": [
        -3.66710674603058,
        42.29580757145897,
        -3.66710674603058,
        42.29580757145897
      ]
    },
    "09074": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.612946032249823,
              42.32890250221543
            ]
          },
          "bbox": [
            -3.612946032249823,
            42.32890250221543,
            -3.612946032249823,
            42.32890250221543
          ]
        }
      ],
      "bbox": [
        -3.612946032249823,
        42.32890250221543,
        -3.612946032249823,
        42.32890250221543
      ]
    },
    "09075": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.546027540177937,
              42.365278942290935
            ]
          },
          "bbox": [
            -3.546027540177937,
            42.365278942290935,
            -3.546027540177937,
            42.365278942290935
          ]
        }
      ],
      "bbox": [
        -3.546027540177937,
        42.365278942290935,
        -3.546027540177937,
        42.365278942290935
      ]
    },
    "09076": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.294787180871762,
              42.478409927287736
            ]
          },
          "bbox": [
            -3.294787180871762,
            42.478409927287736,
            -3.294787180871762,
            42.478409927287736
          ]
        }
      ],
      "bbox": [
        -3.294787180871762,
        42.478409927287736,
        -3.294787180871762,
        42.478409927287736
      ]
    },
    "09077": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.234384977721347,
              42.67868804220743
            ]
          },
          "bbox": [
            -3.234384977721347,
            42.67868804220743,
            -3.234384977721347,
            42.67868804220743
          ]
        }
      ],
      "bbox": [
        -3.234384977721347,
        42.67868804220743,
        -3.234384977721347,
        42.67868804220743
      ]
    },
    "09078": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3933001765226454,
              42.065525919677604
            ]
          },
          "bbox": [
            -3.3933001765226454,
            42.065525919677604,
            -3.3933001765226454,
            42.065525919677604
          ]
        }
      ],
      "bbox": [
        -3.3933001765226454,
        42.065525919677604,
        -3.3933001765226454,
        42.065525919677604
      ]
    },
    "09079": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.027841839968146,
              42.3292589356232
            ]
          },
          "bbox": [
            -4.027841839968146,
            42.3292589356232,
            -4.027841839968146,
            42.3292589356232
          ]
        }
      ],
      "bbox": [
        -4.027841839968146,
        42.3292589356232,
        -4.027841839968146,
        42.3292589356232
      ]
    },
    "09082": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0893148082935,
              42.44221281569871
            ]
          },
          "bbox": [
            -3.0893148082935,
            42.44221281569871,
            -3.0893148082935,
            42.44221281569871
          ]
        }
      ],
      "bbox": [
        -3.0893148082935,
        42.44221281569871,
        -3.0893148082935,
        42.44221281569871
      ]
    },
    "09083": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3852298139907027,
              42.47027339929728
            ]
          },
          "bbox": [
            -3.3852298139907027,
            42.47027339929728,
            -3.3852298139907027,
            42.47027339929728
          ]
        }
      ],
      "bbox": [
        -3.3852298139907027,
        42.47027339929728,
        -3.3852298139907027,
        42.47027339929728
      ]
    },
    "09084": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2355279140625295,
              41.98011969879506
            ]
          },
          "bbox": [
            -3.2355279140625295,
            41.98011969879506,
            -3.2355279140625295,
            41.98011969879506
          ]
        }
      ],
      "bbox": [
        -3.2355279140625295,
        41.98011969879506,
        -3.2355279140625295,
        41.98011969879506
      ]
    },
    "09085": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.770756548862975,
              41.64879562849767
            ]
          },
          "bbox": [
            -3.770756548862975,
            41.64879562849767,
            -3.770756548862975,
            41.64879562849767
          ]
        }
      ],
      "bbox": [
        -3.770756548862975,
        41.64879562849767,
        -3.770756548862975,
        41.64879562849767
      ]
    },
    "09086": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.582956474829209,
              42.310851856786755
            ]
          },
          "bbox": [
            -3.582956474829209,
            42.310851856786755,
            -3.582956474829209,
            42.310851856786755
          ]
        }
      ],
      "bbox": [
        -3.582956474829209,
        42.310851856786755,
        -3.582956474829209,
        42.310851856786755
      ]
    },
    "09088": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.269802066725261,
              42.54647523071457
            ]
          },
          "bbox": [
            -4.269802066725261,
            42.54647523071457,
            -4.269802066725261,
            42.54647523071457
          ]
        }
      ],
      "bbox": [
        -4.269802066725261,
        42.54647523071457,
        -4.269802066725261,
        42.54647523071457
      ]
    },
    "09090": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.192385628437032,
              42.3028108376789
            ]
          },
          "bbox": [
            -4.192385628437032,
            42.3028108376789,
            -4.192385628437032,
            42.3028108376789
          ]
        }
      ],
      "bbox": [
        -4.192385628437032,
        42.3028108376789,
        -4.192385628437032,
        42.3028108376789
      ]
    },
    "09091": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.14243576030792,
              42.275597923432834
            ]
          },
          "bbox": [
            -4.14243576030792,
            42.275597923432834,
            -4.14243576030792,
            42.275597923432834
          ]
        }
      ],
      "bbox": [
        -4.14243576030792,
        42.275597923432834,
        -4.14243576030792,
        42.275597923432834
      ]
    },
    "09093": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8019197355746583,
              42.26251230301366
            ]
          },
          "bbox": [
            -3.8019197355746583,
            42.26251230301366,
            -3.8019197355746583,
            42.26251230301366
          ]
        }
      ],
      "bbox": [
        -3.8019197355746583,
        42.26251230301366,
        -3.8019197355746583,
        42.26251230301366
      ]
    },
    "09094": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.589873375175412,
              41.99285603039933
            ]
          },
          "bbox": [
            -3.589873375175412,
            41.99285603039933,
            -3.589873375175412,
            41.99285603039933
          ]
        }
      ],
      "bbox": [
        -3.589873375175412,
        41.99285603039933,
        -3.589873375175412,
        41.99285603039933
      ]
    },
    "09095": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9397892445612506,
              42.26793880711752
            ]
          },
          "bbox": [
            -3.9397892445612506,
            42.26793880711752,
            -3.9397892445612506,
            42.26793880711752
          ]
        }
      ],
      "bbox": [
        -3.9397892445612506,
        42.26793880711752,
        -3.9397892445612506,
        42.26793880711752
      ]
    },
    "09098": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1370301473168998,
              42.50310815563141
            ]
          },
          "bbox": [
            -3.1370301473168998,
            42.50310815563141,
            -3.1370301473168998,
            42.50310815563141
          ]
        }
      ],
      "bbox": [
        -3.1370301473168998,
        42.50310815563141,
        -3.1370301473168998,
        42.50310815563141
      ]
    },
    "09100": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.365524766364458,
              42.415053563378834
            ]
          },
          "bbox": [
            -3.365524766364458,
            42.415053563378834,
            -3.365524766364458,
            42.415053563378834
          ]
        }
      ],
      "bbox": [
        -3.365524766364458,
        42.415053563378834,
        -3.365524766364458,
        42.415053563378834
      ]
    },
    "09101": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9369401380332314,
              42.163664421261814
            ]
          },
          "bbox": [
            -3.9369401380332314,
            42.163664421261814,
            -3.9369401380332314,
            42.163664421261814
          ]
        }
      ],
      "bbox": [
        -3.9369401380332314,
        42.163664421261814,
        -3.9369401380332314,
        42.163664421261814
      ]
    },
    "09102": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3752205361352945,
              42.777392793965745
            ]
          },
          "bbox": [
            -3.3752205361352945,
            42.777392793965745,
            -3.3752205361352945,
            42.777392793965745
          ]
        }
      ],
      "bbox": [
        -3.3752205361352945,
        42.777392793965745,
        -3.3752205361352945,
        42.777392793965745
      ]
    },
    "09103": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.803812173879497,
              41.894866844909785
            ]
          },
          "bbox": [
            -3.803812173879497,
            41.894866844909785,
            -3.803812173879497,
            41.894866844909785
          ]
        }
      ],
      "bbox": [
        -3.803812173879497,
        41.894866844909785,
        -3.803812173879497,
        41.894866844909785
      ]
    },
    "09104": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6516551304139186,
              41.90926363215904
            ]
          },
          "bbox": [
            -3.6516551304139186,
            41.90926363215904,
            -3.6516551304139186,
            41.90926363215904
          ]
        }
      ],
      "bbox": [
        -3.6516551304139186,
        41.90926363215904,
        -3.6516551304139186,
        41.90926363215904
      ]
    },
    "09105": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.530889521538918,
              41.91392000376896
            ]
          },
          "bbox": [
            -3.530889521538918,
            41.91392000376896,
            -3.530889521538918,
            41.91392000376896
          ]
        }
      ],
      "bbox": [
        -3.530889521538918,
        41.91392000376896,
        -3.530889521538918,
        41.91392000376896
      ]
    },
    "09108": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7004843469458164,
              42.19957714764339
            ]
          },
          "bbox": [
            -3.7004843469458164,
            42.19957714764339,
            -3.7004843469458164,
            42.19957714764339
          ]
        }
      ],
      "bbox": [
        -3.7004843469458164,
        42.19957714764339,
        -3.7004843469458164,
        42.19957714764339
      ]
    },
    "09109": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.690337969174557,
              42.71931409037593
            ]
          },
          "bbox": [
            -2.690337969174557,
            42.71931409037593,
            -2.690337969174557,
            42.71931409037593
          ]
        }
      ],
      "bbox": [
        -2.690337969174557,
        42.71931409037593,
        -2.690337969174557,
        42.71931409037593
      ]
    },
    "09110": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4252367144063354,
              42.0250372871931
            ]
          },
          "bbox": [
            -3.4252367144063354,
            42.0250372871931,
            -3.4252367144063354,
            42.0250372871931
          ]
        }
      ],
      "bbox": [
        -3.4252367144063354,
        42.0250372871931,
        -3.4252367144063354,
        42.0250372871931
      ]
    },
    "09112": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.391877115392543,
              41.756671058330824
            ]
          },
          "bbox": [
            -3.391877115392543,
            41.756671058330824,
            -3.391877115392543,
            41.756671058330824
          ]
        }
      ],
      "bbox": [
        -3.391877115392543,
        41.756671058330824,
        -3.391877115392543,
        41.756671058330824
      ]
    },
    "09113": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5326151395886605,
              42.057453594665574
            ]
          },
          "bbox": [
            -3.5326151395886605,
            42.057453594665574,
            -3.5326151395886605,
            42.057453594665574
          ]
        }
      ],
      "bbox": [
        -3.5326151395886605,
        42.057453594665574,
        -3.5326151395886605,
        42.057453594665574
      ]
    },
    "09114": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.599288349186779,
              42.155837366012776
            ]
          },
          "bbox": [
            -3.599288349186779,
            42.155837366012776,
            -3.599288349186779,
            42.155837366012776
          ]
        }
      ],
      "bbox": [
        -3.599288349186779,
        42.155837366012776,
        -3.599288349186779,
        42.155837366012776
      ]
    },
    "09115": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2071309655759497,
              42.637311210748045
            ]
          },
          "bbox": [
            -3.2071309655759497,
            42.637311210748045,
            -3.2071309655759497,
            42.637311210748045
          ]
        }
      ],
      "bbox": [
        -3.2071309655759497,
        42.637311210748045,
        -3.2071309655759497,
        42.637311210748045
      ]
    },
    "09117": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9410053761552435,
              41.65517433344315
            ]
          },
          "bbox": [
            -3.9410053761552435,
            41.65517433344315,
            -3.9410053761552435,
            41.65517433344315
          ]
        }
      ],
      "bbox": [
        -3.9410053761552435,
        41.65517433344315,
        -3.9410053761552435,
        41.65517433344315
      ]
    },
    "09119": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.585211094426553,
              42.1275660393949
            ]
          },
          "bbox": [
            -3.585211094426553,
            42.1275660393949,
            -3.585211094426553,
            42.1275660393949
          ]
        }
      ],
      "bbox": [
        -3.585211094426553,
        42.1275660393949,
        -3.585211094426553,
        42.1275660393949
      ]
    },
    "09120": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1080106077901766,
              42.684140708818994
            ]
          },
          "bbox": [
            -3.1080106077901766,
            42.684140708818994,
            -3.1080106077901766,
            42.684140708818994
          ]
        }
      ],
      "bbox": [
        -3.1080106077901766,
        42.684140708818994,
        -3.1080106077901766,
        42.684140708818994
      ]
    },
    "09122": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4690609593741737,
              41.89221450721594
            ]
          },
          "bbox": [
            -3.4690609593741737,
            41.89221450721594,
            -3.4690609593741737,
            41.89221450721594
          ]
        }
      ],
      "bbox": [
        -3.4690609593741737,
        41.89221450721594,
        -3.4690609593741737,
        41.89221450721594
      ]
    },
    "09123": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2821976682055425,
              42.40431893574368
            ]
          },
          "bbox": [
            -3.2821976682055425,
            42.40431893574368,
            -3.2821976682055425,
            42.40431893574368
          ]
        }
      ],
      "bbox": [
        -3.2821976682055425,
        42.40431893574368,
        -3.2821976682055425,
        42.40431893574368
      ]
    },
    "09124": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.599487121936477,
              43.11291825760045
            ]
          },
          "bbox": [
            -3.599487121936477,
            43.11291825760045,
            -3.599487121936477,
            43.11291825760045
          ]
        }
      ],
      "bbox": [
        -3.599487121936477,
        43.11291825760045,
        -3.599487121936477,
        43.11291825760045
      ]
    },
    "09125": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.879420313651014,
              42.26609503788574
            ]
          },
          "bbox": [
            -3.879420313651014,
            42.26609503788574,
            -3.879420313651014,
            42.26609503788574
          ]
        }
      ],
      "bbox": [
        -3.879420313651014,
        42.26609503788574,
        -3.879420313651014,
        42.26609503788574
      ]
    },
    "09127": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.743378979533722,
              41.92757660279449
            ]
          },
          "bbox": [
            -3.743378979533722,
            41.92757660279449,
            -3.743378979533722,
            41.92757660279449
          ]
        }
      ],
      "bbox": [
        -3.743378979533722,
        41.92757660279449,
        -3.743378979533722,
        41.92757660279449
      ]
    },
    "09128": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.846412851526286,
              42.31035890000333
            ]
          },
          "bbox": [
            -3.846412851526286,
            42.31035890000333,
            -3.846412851526286,
            42.31035890000333
          ]
        }
      ],
      "bbox": [
        -3.846412851526286,
        42.31035890000333,
        -3.846412851526286,
        42.31035890000333
      ]
    },
    "09129": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1308600651663214,
              42.281489419152145
            ]
          },
          "bbox": [
            -3.1308600651663214,
            42.281489419152145,
            -3.1308600651663214,
            42.281489419152145
          ]
        }
      ],
      "bbox": [
        -3.1308600651663214,
        42.281489419152145,
        -3.1308600651663214,
        42.281489419152145
      ]
    },
    "09130": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.134677748734208,
              42.40533287253536
            ]
          },
          "bbox": [
            -3.134677748734208,
            42.40533287253536,
            -3.134677748734208,
            42.40533287253536
          ]
        }
      ],
      "bbox": [
        -3.134677748734208,
        42.40533287253536,
        -3.134677748734208,
        42.40533287253536
      ]
    },
    "09131": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6332673232659687,
              41.63888285168091
            ]
          },
          "bbox": [
            -3.6332673232659687,
            41.63888285168091,
            -3.6332673232659687,
            41.63888285168091
          ]
        }
      ],
      "bbox": [
        -3.6332673232659687,
        41.63888285168091,
        -3.6332673232659687,
        41.63888285168091
      ]
    },
    "09132": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.18558566979403,
              42.464108124203044
            ]
          },
          "bbox": [
            -3.18558566979403,
            42.464108124203044,
            -3.18558566979403,
            42.464108124203044
          ]
        }
      ],
      "bbox": [
        -3.18558566979403,
        42.464108124203044,
        -3.18558566979403,
        42.464108124203044
      ]
    },
    "09133": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4813927269906038,
              42.42141568903421
            ]
          },
          "bbox": [
            -3.4813927269906038,
            42.42141568903421,
            -3.4813927269906038,
            42.42141568903421
          ]
        }
      ],
      "bbox": [
        -3.4813927269906038,
        42.42141568903421,
        -3.4813927269906038,
        42.42141568903421
      ]
    },
    "09134": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.296495769860036,
              42.75402478729621
            ]
          },
          "bbox": [
            -3.296495769860036,
            42.75402478729621,
            -3.296495769860036,
            42.75402478729621
          ]
        }
      ],
      "bbox": [
        -3.296495769860036,
        42.75402478729621,
        -3.296495769860036,
        42.75402478729621
      ]
    },
    "09135": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2367793685191737,
              42.630785655977355
            ]
          },
          "bbox": [
            -3.2367793685191737,
            42.630785655977355,
            -3.2367793685191737,
            42.630785655977355
          ]
        }
      ],
      "bbox": [
        -3.2367793685191737,
        42.630785655977355,
        -3.2367793685191737,
        42.630785655977355
      ]
    },
    "09136": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.87243974343614,
              41.632954665754
            ]
          },
          "bbox": [
            -3.87243974343614,
            41.632954665754,
            -3.87243974343614,
            41.632954665754
          ]
        }
      ],
      "bbox": [
        -3.87243974343614,
        41.632954665754,
        -3.87243974343614,
        41.632954665754
      ]
    },
    "09137": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.639050106092042,
              41.59958934400562
            ]
          },
          "bbox": [
            -3.639050106092042,
            41.59958934400562,
            -3.639050106092042,
            41.59958934400562
          ]
        }
      ],
      "bbox": [
        -3.639050106092042,
        41.59958934400562,
        -3.639050106092042,
        41.59958934400562
      ]
    },
    "09138": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9032524604115424,
              41.61987449598308
            ]
          },
          "bbox": [
            -3.9032524604115424,
            41.61987449598308,
            -3.9032524604115424,
            41.61987449598308
          ]
        }
      ],
      "bbox": [
        -3.9032524604115424,
        41.61987449598308,
        -3.9032524604115424,
        41.61987449598308
      ]
    },
    "09139": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8574821454063284,
              41.60195863063693
            ]
          },
          "bbox": [
            -3.8574821454063284,
            41.60195863063693,
            -3.8574821454063284,
            41.60195863063693
          ]
        }
      ],
      "bbox": [
        -3.8574821454063284,
        41.60195863063693,
        -3.8574821454063284,
        41.60195863063693
      ]
    },
    "09140": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7479128151228416,
              41.50847113403158
            ]
          },
          "bbox": [
            -3.7479128151228416,
            41.50847113403158,
            -3.7479128151228416,
            41.50847113403158
          ]
        }
      ],
      "bbox": [
        -3.7479128151228416,
        41.50847113403158,
        -3.7479128151228416,
        41.50847113403158
      ]
    },
    "09141": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.682298772652578,
              41.627538777742764
            ]
          },
          "bbox": [
            -3.682298772652578,
            41.627538777742764,
            -3.682298772652578,
            41.627538777742764
          ]
        }
      ],
      "bbox": [
        -3.682298772652578,
        41.627538777742764,
        -3.682298772652578,
        41.627538777742764
      ]
    },
    "09143": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4511518386552855,
              42.52097039979109
            ]
          },
          "bbox": [
            -3.4511518386552855,
            42.52097039979109,
            -3.4511518386552855,
            42.52097039979109
          ]
        }
      ],
      "bbox": [
        -3.4511518386552855,
        42.52097039979109,
        -3.4511518386552855,
        42.52097039979109
      ]
    },
    "09144": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.259536985905315,
              41.895944712341
            ]
          },
          "bbox": [
            -3.259536985905315,
            41.895944712341,
            -3.259536985905315,
            41.895944712341
          ]
        }
      ],
      "bbox": [
        -3.259536985905315,
        41.895944712341,
        -3.259536985905315,
        41.895944712341
      ]
    },
    "09148": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.120320939941714,
              42.427405234398044
            ]
          },
          "bbox": [
            -4.120320939941714,
            42.427405234398044,
            -4.120320939941714,
            42.427405234398044
          ]
        }
      ],
      "bbox": [
        -4.120320939941714,
        42.427405234398044,
        -4.120320939941714,
        42.427405234398044
      ]
    },
    "09149": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.267483937399035,
              42.59304005917365
            ]
          },
          "bbox": [
            -3.267483937399035,
            42.59304005917365,
            -3.267483937399035,
            42.59304005917365
          ]
        }
      ],
      "bbox": [
        -3.267483937399035,
        42.59304005917365,
        -3.267483937399035,
        42.59304005917365
      ]
    },
    "09151": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.691303888250572,
              41.77076950883005
            ]
          },
          "bbox": [
            -3.691303888250572,
            41.77076950883005,
            -3.691303888250572,
            41.77076950883005
          ]
        }
      ],
      "bbox": [
        -3.691303888250572,
        41.77076950883005,
        -3.691303888250572,
        41.77076950883005
      ]
    },
    "09152": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.80500741170938,
              41.734073064783296
            ]
          },
          "bbox": [
            -3.80500741170938,
            41.734073064783296,
            -3.80500741170938,
            41.734073064783296
          ]
        }
      ],
      "bbox": [
        -3.80500741170938,
        41.734073064783296,
        -3.80500741170938,
        41.734073064783296
      ]
    },
    "09154": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2834952020967183,
              41.98185185519822
            ]
          },
          "bbox": [
            -3.2834952020967183,
            41.98185185519822,
            -3.2834952020967183,
            41.98185185519822
          ]
        }
      ],
      "bbox": [
        -3.2834952020967183,
        41.98185185519822,
        -3.2834952020967183,
        41.98185185519822
      ]
    },
    "09155": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8466355030858033,
              41.57970624673953
            ]
          },
          "bbox": [
            -3.8466355030858033,
            41.57970624673953,
            -3.8466355030858033,
            41.57970624673953
          ]
        }
      ],
      "bbox": [
        -3.8466355030858033,
        41.57970624673953,
        -3.8466355030858033,
        41.57970624673953
      ]
    },
    "09159": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.048332596100742,
              42.308875546854644
            ]
          },
          "bbox": [
            -4.048332596100742,
            42.308875546854644,
            -4.048332596100742,
            42.308875546854644
          ]
        }
      ],
      "bbox": [
        -4.048332596100742,
        42.308875546854644,
        -4.048332596100742,
        42.308875546854644
      ]
    },
    "09160": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7890213790580334,
              41.5890641164904
            ]
          },
          "bbox": [
            -3.7890213790580334,
            41.5890641164904,
            -3.7890213790580334,
            41.5890641164904
          ]
        }
      ],
      "bbox": [
        -3.7890213790580334,
        41.5890641164904,
        -3.7890213790580334,
        41.5890641164904
      ]
    },
    "09162": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6364837995253523,
              42.19531575009108
            ]
          },
          "bbox": [
            -3.6364837995253523,
            42.19531575009108,
            -3.6364837995253523,
            42.19531575009108
          ]
        }
      ],
      "bbox": [
        -3.6364837995253523,
        42.19531575009108,
        -3.6364837995253523,
        42.19531575009108
      ]
    },
    "09163": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1652815527071336,
              41.85184698229228
            ]
          },
          "bbox": [
            -3.1652815527071336,
            41.85184698229228,
            -3.1652815527071336,
            41.85184698229228
          ]
        }
      ],
      "bbox": [
        -3.1652815527071336,
        41.85184698229228,
        -3.1652815527071336,
        41.85184698229228
      ]
    },
    "09164": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5076945697484847,
              41.75170944130644
            ]
          },
          "bbox": [
            -3.5076945697484847,
            41.75170944130644,
            -3.5076945697484847,
            41.75170944130644
          ]
        }
      ],
      "bbox": [
        -3.5076945697484847,
        41.75170944130644,
        -3.5076945697484847,
        41.75170944130644
      ]
    },
    "09166": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.904439232051065,
              42.52393892966212
            ]
          },
          "bbox": [
            -3.904439232051065,
            42.52393892966212,
            -3.904439232051065,
            42.52393892966212
          ]
        }
      ],
      "bbox": [
        -3.904439232051065,
        42.52393892966212,
        -3.904439232051065,
        42.52393892966212
      ]
    },
    "09167": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.929137978010305,
              42.33915410850188
            ]
          },
          "bbox": [
            -3.929137978010305,
            42.33915410850188,
            -3.929137978010305,
            42.33915410850188
          ]
        }
      ],
      "bbox": [
        -3.929137978010305,
        42.33915410850188,
        -3.929137978010305,
        42.33915410850188
      ]
    },
    "09168": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8757453566947815,
              41.742777060456255
            ]
          },
          "bbox": [
            -3.8757453566947815,
            41.742777060456255,
            -3.8757453566947815,
            41.742777060456255
          ]
        }
      ],
      "bbox": [
        -3.8757453566947815,
        41.742777060456255,
        -3.8757453566947815,
        41.742777060456255
      ]
    },
    "09169": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4538215019842324,
              42.061080130776084
            ]
          },
          "bbox": [
            -3.4538215019842324,
            42.061080130776084,
            -3.4538215019842324,
            42.061080130776084
          ]
        }
      ],
      "bbox": [
        -3.4538215019842324,
        42.061080130776084,
        -3.4538215019842324,
        42.061080130776084
      ]
    },
    "09170": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8534093561605705,
              41.65921087545005
            ]
          },
          "bbox": [
            -3.8534093561605705,
            41.65921087545005,
            -3.8534093561605705,
            41.65921087545005
          ]
        }
      ],
      "bbox": [
        -3.8534093561605705,
        41.65921087545005,
        -3.8534093561605705,
        41.65921087545005
      ]
    },
    "09172": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.789562518817009,
              42.542597258690314
            ]
          },
          "bbox": [
            -3.789562518817009,
            42.542597258690314,
            -3.789562518817009,
            42.542597258690314
          ]
        }
      ],
      "bbox": [
        -3.789562518817009,
        42.542597258690314,
        -3.789562518817009,
        42.542597258690314
      ]
    },
    "09173": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0700389831527266,
              42.097059879443435
            ]
          },
          "bbox": [
            -3.0700389831527266,
            42.097059879443435,
            -3.0700389831527266,
            42.097059879443435
          ]
        }
      ],
      "bbox": [
        -3.0700389831527266,
        42.097059879443435,
        -3.0700389831527266,
        42.097059879443435
      ]
    },
    "09174": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3298964433237743,
              41.80938780224073
            ]
          },
          "bbox": [
            -3.3298964433237743,
            41.80938780224073,
            -3.3298964433237743,
            41.80938780224073
          ]
        }
      ],
      "bbox": [
        -3.3298964433237743,
        41.80938780224073,
        -3.3298964433237743,
        41.80938780224073
      ]
    },
    "09175": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.077712706488081,
              42.65485254871685
            ]
          },
          "bbox": [
            -4.077712706488081,
            42.65485254871685,
            -4.077712706488081,
            42.65485254871685
          ]
        }
      ],
      "bbox": [
        -4.077712706488081,
        42.65485254871685,
        -4.077712706488081,
        42.65485254871685
      ]
    },
    "09176": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.606843200963839,
              42.40937024608952
            ]
          },
          "bbox": [
            -3.606843200963839,
            42.40937024608952,
            -3.606843200963839,
            42.40937024608952
          ]
        }
      ],
      "bbox": [
        -3.606843200963839,
        42.40937024608952,
        -3.606843200963839,
        42.40937024608952
      ]
    },
    "09177": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4965595529380122,
              42.27070140817841
            ]
          },
          "bbox": [
            -3.4965595529380122,
            42.27070140817841,
            -3.4965595529380122,
            42.27070140817841
          ]
        }
      ],
      "bbox": [
        -3.4965595529380122,
        42.27070140817841,
        -3.4965595529380122,
        42.27070140817841
      ]
    },
    "09178": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0758581260844875,
              42.46174072395315
            ]
          },
          "bbox": [
            -3.0758581260844875,
            42.46174072395315,
            -3.0758581260844875,
            42.46174072395315
          ]
        }
      ],
      "bbox": [
        -3.0758581260844875,
        42.46174072395315,
        -3.0758581260844875,
        42.46174072395315
      ]
    },
    "09179": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8455707917531528,
              41.96204672727646
            ]
          },
          "bbox": [
            -3.8455707917531528,
            41.96204672727646,
            -3.8455707917531528,
            41.96204672727646
          ]
        }
      ],
      "bbox": [
        -3.8455707917531528,
        41.96204672727646,
        -3.8455707917531528,
        41.96204672727646
      ]
    },
    "09180": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9968636234850603,
              42.30634122896115
            ]
          },
          "bbox": [
            -3.9968636234850603,
            42.30634122896115,
            -3.9968636234850603,
            42.30634122896115
          ]
        }
      ],
      "bbox": [
        -3.9968636234850603,
        42.30634122896115,
        -3.9968636234850603,
        42.30634122896115
      ]
    },
    "09181": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.922939473812601,
              42.39460124255895
            ]
          },
          "bbox": [
            -3.922939473812601,
            42.39460124255895,
            -3.922939473812601,
            42.39460124255895
          ]
        }
      ],
      "bbox": [
        -3.922939473812601,
        42.39460124255895,
        -3.922939473812601,
        42.39460124255895
      ]
    },
    "09182": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.231298612793305,
              42.28699287589595
            ]
          },
          "bbox": [
            -4.231298612793305,
            42.28699287589595,
            -4.231298612793305,
            42.28699287589595
          ]
        }
      ],
      "bbox": [
        -4.231298612793305,
        42.28699287589595,
        -4.231298612793305,
        42.28699287589595
      ]
    },
    "09183": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.316021421663331,
              42.11979392263015
            ]
          },
          "bbox": [
            -3.316021421663331,
            42.11979392263015,
            -3.316021421663331,
            42.11979392263015
          ]
        }
      ],
      "bbox": [
        -3.316021421663331,
        42.11979392263015,
        -3.316021421663331,
        42.11979392263015
      ]
    },
    "09184": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3594643508250233,
              42.09220958801937
            ]
          },
          "bbox": [
            -3.3594643508250233,
            42.09220958801937,
            -3.3594643508250233,
            42.09220958801937
          ]
        }
      ],
      "bbox": [
        -3.3594643508250233,
        42.09220958801937,
        -3.3594643508250233,
        42.09220958801937
      ]
    },
    "09189": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.406601503137999,
              43.03874436216726
            ]
          },
          "bbox": [
            -3.406601503137999,
            43.03874436216726,
            -3.406601503137999,
            43.03874436216726
          ]
        }
      ],
      "bbox": [
        -3.406601503137999,
        43.03874436216726,
        -3.406601503137999,
        43.03874436216726
      ]
    },
    "09190": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.084317892018853,
              42.9599574663332
            ]
          },
          "bbox": [
            -3.084317892018853,
            42.9599574663332,
            -3.084317892018853,
            42.9599574663332
          ]
        }
      ],
      "bbox": [
        -3.084317892018853,
        42.9599574663332,
        -3.084317892018853,
        42.9599574663332
      ]
    },
    "09191": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.460688414257669,
              42.13869613431408
            ]
          },
          "bbox": [
            -3.460688414257669,
            42.13869613431408,
            -3.460688414257669,
            42.13869613431408
          ]
        }
      ],
      "bbox": [
        -3.460688414257669,
        42.13869613431408,
        -3.460688414257669,
        42.13869613431408
      ]
    },
    "09192": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1711574503659636,
              42.838914456938525
            ]
          },
          "bbox": [
            -3.1711574503659636,
            42.838914456938525,
            -3.1711574503659636,
            42.838914456938525
          ]
        }
      ],
      "bbox": [
        -3.1711574503659636,
        42.838914456938525,
        -3.1711574503659636,
        42.838914456938525
      ]
    },
    "09194": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.76441617405494,
              41.98682149138039
            ]
          },
          "bbox": [
            -3.76441617405494,
            41.98682149138039,
            -3.76441617405494,
            41.98682149138039
          ]
        }
      ],
      "bbox": [
        -3.76441617405494,
        41.98682149138039,
        -3.76441617405494,
        41.98682149138039
      ]
    },
    "09195": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4447848371865,
              42.62297979352487
            ]
          },
          "bbox": [
            -3.4447848371865,
            42.62297979352487,
            -3.4447848371865,
            42.62297979352487
          ]
        }
      ],
      "bbox": [
        -3.4447848371865,
        42.62297979352487,
        -3.4447848371865,
        42.62297979352487
      ]
    },
    "09196": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.656318665362537,
              42.140309201200004
            ]
          },
          "bbox": [
            -3.656318665362537,
            42.140309201200004,
            -3.656318665362537,
            42.140309201200004
          ]
        }
      ],
      "bbox": [
        -3.656318665362537,
        42.140309201200004,
        -3.656318665362537,
        42.140309201200004
      ]
    },
    "09197": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.731585836534107,
              42.144392932957466
            ]
          },
          "bbox": [
            -3.731585836534107,
            42.144392932957466,
            -3.731585836534107,
            42.144392932957466
          ]
        }
      ],
      "bbox": [
        -3.731585836534107,
        42.144392932957466,
        -3.731585836534107,
        42.144392932957466
      ]
    },
    "09198": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9122839152717512,
              42.13050435337193
            ]
          },
          "bbox": [
            -3.9122839152717512,
            42.13050435337193,
            -3.9122839152717512,
            42.13050435337193
          ]
        }
      ],
      "bbox": [
        -3.9122839152717512,
        42.13050435337193,
        -3.9122839152717512,
        42.13050435337193
      ]
    },
    "09199": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9916145007768327,
              41.66683740091127
            ]
          },
          "bbox": [
            -3.9916145007768327,
            41.66683740091127,
            -3.9916145007768327,
            41.66683740091127
          ]
        }
      ],
      "bbox": [
        -3.9916145007768327,
        41.66683740091127,
        -3.9916145007768327,
        41.66683740091127
      ]
    },
    "09200": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4910402395761495,
              42.11210813802041
            ]
          },
          "bbox": [
            -3.4910402395761495,
            42.11210813802041,
            -3.4910402395761495,
            42.11210813802041
          ]
        }
      ],
      "bbox": [
        -3.4910402395761495,
        42.11210813802041,
        -3.4910402395761495,
        42.11210813802041
      ]
    },
    "09201": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.35542236009318,
              41.9197673511591
            ]
          },
          "bbox": [
            -3.35542236009318,
            41.9197673511591,
            -3.35542236009318,
            41.9197673511591
          ]
        }
      ],
      "bbox": [
        -3.35542236009318,
        41.9197673511591,
        -3.35542236009318,
        41.9197673511591
      ]
    },
    "09202": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9446164013488167,
              42.454737417437364
            ]
          },
          "bbox": [
            -3.9446164013488167,
            42.454737417437364,
            -3.9446164013488167,
            42.454737417437364
          ]
        }
      ],
      "bbox": [
        -3.9446164013488167,
        42.454737417437364,
        -3.9446164013488167,
        42.454737417437364
      ]
    },
    "09206": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.91502844767328,
              42.20972566862546
            ]
          },
          "bbox": [
            -3.91502844767328,
            42.20972566862546,
            -3.91502844767328,
            42.20972566862546
          ]
        }
      ],
      "bbox": [
        -3.91502844767328,
        42.20972566862546,
        -3.91502844767328,
        42.20972566862546
      ]
    },
    "09208": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.593023770274795,
              42.09729527642332
            ]
          },
          "bbox": [
            -3.593023770274795,
            42.09729527642332,
            -3.593023770274795,
            42.09729527642332
          ]
        }
      ],
      "bbox": [
        -3.593023770274795,
        42.09729527642332,
        -3.593023770274795,
        42.09729527642332
      ]
    },
    "09209": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3998636128112865,
              42.9497122665552
            ]
          },
          "bbox": [
            -3.3998636128112865,
            42.9497122665552,
            -3.3998636128112865,
            42.9497122665552
          ]
        }
      ],
      "bbox": [
        -3.3998636128112865,
        42.9497122665552,
        -3.3998636128112865,
        42.9497122665552
      ]
    },
    "09211": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.25536833205633,
              42.431597900735646
            ]
          },
          "bbox": [
            -4.25536833205633,
            42.431597900735646,
            -4.25536833205633,
            42.431597900735646
          ]
        }
      ],
      "bbox": [
        -4.25536833205633,
        42.431597900735646,
        -4.25536833205633,
        42.431597900735646
      ]
    },
    "09213": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.403447081257167,
              42.86348143172027
            ]
          },
          "bbox": [
            -3.403447081257167,
            42.86348143172027,
            -3.403447081257167,
            42.86348143172027
          ]
        }
      ],
      "bbox": [
        -3.403447081257167,
        42.86348143172027,
        -3.403447081257167,
        42.86348143172027
      ]
    },
    "09214": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.485741311535527,
              43.06919770885436
            ]
          },
          "bbox": [
            -3.485741311535527,
            43.06919770885436,
            -3.485741311535527,
            43.06919770885436
          ]
        }
      ],
      "bbox": [
        -3.485741311535527,
        43.06919770885436,
        -3.485741311535527,
        43.06919770885436
      ]
    },
    "09215": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6524241955031482,
              43.041281664249794
            ]
          },
          "bbox": [
            -3.6524241955031482,
            43.041281664249794,
            -3.6524241955031482,
            43.041281664249794
          ]
        }
      ],
      "bbox": [
        -3.6524241955031482,
        43.041281664249794,
        -3.6524241955031482,
        43.041281664249794
      ]
    },
    "09216": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.759162903360933,
              43.02021210129428
            ]
          },
          "bbox": [
            -3.759162903360933,
            43.02021210129428,
            -3.759162903360933,
            43.02021210129428
          ]
        }
      ],
      "bbox": [
        -3.759162903360933,
        43.02021210129428,
        -3.759162903360933,
        43.02021210129428
      ]
    },
    "09217": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5498692179161844,
              42.8228550748793
            ]
          },
          "bbox": [
            -3.5498692179161844,
            42.8228550748793,
            -3.5498692179161844,
            42.8228550748793
          ]
        }
      ],
      "bbox": [
        -3.5498692179161844,
        42.8228550748793,
        -3.5498692179161844,
        42.8228550748793
      ]
    },
    "09218": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6829171175156565,
              41.58419546629087
            ]
          },
          "bbox": [
            -3.6829171175156565,
            41.58419546629087,
            -3.6829171175156565,
            41.58419546629087
          ]
        }
      ],
      "bbox": [
        -3.6829171175156565,
        41.58419546629087,
        -3.6829171175156565,
        41.58419546629087
      ]
    },
    "09219": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9636147855044714,
              42.673035698303856
            ]
          },
          "bbox": [
            -2.9636147855044714,
            42.673035698303856,
            -2.9636147855044714,
            42.673035698303856
          ]
        }
      ],
      "bbox": [
        -2.9636147855044714,
        42.673035698303856,
        -2.9636147855044714,
        42.673035698303856
      ]
    },
    "09220": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1907054020061576,
              42.67770043209312
            ]
          },
          "bbox": [
            -3.1907054020061576,
            42.67770043209312,
            -3.1907054020061576,
            42.67770043209312
          ]
        }
      ],
      "bbox": [
        -3.1907054020061576,
        42.67770043209312,
        -3.1907054020061576,
        42.67770043209312
      ]
    },
    "09221": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6583349717899423,
              42.26475868058004
            ]
          },
          "bbox": [
            -3.6583349717899423,
            42.26475868058004,
            -3.6583349717899423,
            42.26475868058004
          ]
        }
      ],
      "bbox": [
        -3.6583349717899423,
        42.26475868058004,
        -3.6583349717899423,
        42.26475868058004
      ]
    },
    "09223": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.18642646933583,
              42.052076471584435
            ]
          },
          "bbox": [
            -3.18642646933583,
            42.052076471584435,
            -3.18642646933583,
            42.052076471584435
          ]
        }
      ],
      "bbox": [
        -3.18642646933583,
        42.052076471584435,
        -3.18642646933583,
        42.052076471584435
      ]
    },
    "09224": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.491765326973234,
              42.46239387997298
            ]
          },
          "bbox": [
            -3.491765326973234,
            42.46239387997298,
            -3.491765326973234,
            42.46239387997298
          ]
        }
      ],
      "bbox": [
        -3.491765326973234,
        42.46239387997298,
        -3.491765326973234,
        42.46239387997298
      ]
    },
    "09225": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1934266932079933,
              41.950337964242856
            ]
          },
          "bbox": [
            -3.1934266932079933,
            41.950337964242856,
            -3.1934266932079933,
            41.950337964242856
          ]
        }
      ],
      "bbox": [
        -3.1934266932079933,
        41.950337964242856,
        -3.1934266932079933,
        41.950337964242856
      ]
    },
    "09226": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.109932964181743,
              42.1483167917452
            ]
          },
          "bbox": [
            -3.109932964181743,
            42.1483167917452,
            -3.109932964181743,
            42.1483167917452
          ]
        }
      ],
      "bbox": [
        -3.109932964181743,
        42.1483167917452,
        -3.109932964181743,
        42.1483167917452
      ]
    },
    "09227": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7661583492371897,
              42.58762013810295
            ]
          },
          "bbox": [
            -3.7661583492371897,
            42.58762013810295,
            -3.7661583492371897,
            42.58762013810295
          ]
        }
      ],
      "bbox": [
        -3.7661583492371897,
        42.58762013810295,
        -3.7661583492371897,
        42.58762013810295
      ]
    },
    "09228": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7886218365410813,
              41.54728454182279
            ]
          },
          "bbox": [
            -3.7886218365410813,
            41.54728454182279,
            -3.7886218365410813,
            41.54728454182279
          ]
        }
      ],
      "bbox": [
        -3.7886218365410813,
        41.54728454182279,
        -3.7886218365410813,
        41.54728454182279
      ]
    },
    "09229": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9642146379335723,
              41.61540176749059
            ]
          },
          "bbox": [
            -3.9642146379335723,
            41.61540176749059,
            -3.9642146379335723,
            41.61540176749059
          ]
        }
      ],
      "bbox": [
        -3.9642146379335723,
        41.61540176749059,
        -3.9642146379335723,
        41.61540176749059
      ]
    },
    "09230": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.333566731658478,
              42.68641958062785
            ]
          },
          "bbox": [
            -3.333566731658478,
            42.68641958062785,
            -3.333566731658478,
            42.68641958062785
          ]
        }
      ],
      "bbox": [
        -3.333566731658478,
        42.68641958062785,
        -3.333566731658478,
        42.68641958062785
      ]
    },
    "09231": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.620379583087313,
              41.95984378741773
            ]
          },
          "bbox": [
            -3.620379583087313,
            41.95984378741773,
            -3.620379583087313,
            41.95984378741773
          ]
        }
      ],
      "bbox": [
        -3.620379583087313,
        41.95984378741773,
        -3.620379583087313,
        41.95984378741773
      ]
    },
    "09232": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.9795410234098605,
              42.056583013022426
            ]
          },
          "bbox": [
            -2.9795410234098605,
            42.056583013022426,
            -2.9795410234098605,
            42.056583013022426
          ]
        }
      ],
      "bbox": [
        -2.9795410234098605,
        42.056583013022426,
        -2.9795410234098605,
        42.056583013022426
      ]
    },
    "09235": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9284958278030073,
              41.781785270567184
            ]
          },
          "bbox": [
            -3.9284958278030073,
            41.781785270567184,
            -3.9284958278030073,
            41.781785270567184
          ]
        }
      ],
      "bbox": [
        -3.9284958278030073,
        41.781785270567184,
        -3.9284958278030073,
        41.781785270567184
      ]
    },
    "09236": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9440964819996824,
              42.202598807382
            ]
          },
          "bbox": [
            -3.9440964819996824,
            42.202598807382,
            -3.9440964819996824,
            42.202598807382
          ]
        }
      ],
      "bbox": [
        -3.9440964819996824,
        42.202598807382,
        -3.9440964819996824,
        42.202598807382
      ]
    },
    "09238": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.385314368320773,
              42.716701759203154
            ]
          },
          "bbox": [
            -3.385314368320773,
            42.716701759203154,
            -3.385314368320773,
            42.716701759203154
          ]
        }
      ],
      "bbox": [
        -3.385314368320773,
        42.716701759203154,
        -3.385314368320773,
        42.716701759203154
      ]
    },
    "09239": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6976577308950755,
              41.82999494311384
            ]
          },
          "bbox": [
            -3.6976577308950755,
            41.82999494311384,
            -3.6976577308950755,
            41.82999494311384
          ]
        }
      ],
      "bbox": [
        -3.6976577308950755,
        41.82999494311384,
        -3.6976577308950755,
        41.82999494311384
      ]
    },
    "09241": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.579799006528933,
              42.35868403540902
            ]
          },
          "bbox": [
            -3.579799006528933,
            42.35868403540902,
            -3.579799006528933,
            42.35868403540902
          ]
        }
      ],
      "bbox": [
        -3.579799006528933,
        42.35868403540902,
        -3.579799006528933,
        42.35868403540902
      ]
    },
    "09242": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.178896542757394,
              42.40253675679322
            ]
          },
          "bbox": [
            -4.178896542757394,
            42.40253675679322,
            -4.178896542757394,
            42.40253675679322
          ]
        }
      ],
      "bbox": [
        -4.178896542757394,
        42.40253675679322,
        -4.178896542757394,
        42.40253675679322
      ]
    },
    "09243": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.187622522460134,
              42.450770211606915
            ]
          },
          "bbox": [
            -4.187622522460134,
            42.450770211606915,
            -4.187622522460134,
            42.450770211606915
          ]
        }
      ],
      "bbox": [
        -4.187622522460134,
        42.450770211606915,
        -4.187622522460134,
        42.450770211606915
      ]
    },
    "09244": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.556758806423398,
              42.70292843835912
            ]
          },
          "bbox": [
            -3.556758806423398,
            42.70292843835912,
            -3.556758806423398,
            42.70292843835912
          ]
        }
      ],
      "bbox": [
        -3.556758806423398,
        42.70292843835912,
        -3.556758806423398,
        42.70292843835912
      ]
    },
    "09246": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.12097386287296,
              41.959046741244904
            ]
          },
          "bbox": [
            -3.12097386287296,
            41.959046741244904,
            -3.12097386287296,
            41.959046741244904
          ]
        }
      ],
      "bbox": [
        -3.12097386287296,
        41.959046741244904,
        -3.12097386287296,
        41.959046741244904
      ]
    },
    "09247": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.256965192155244,
              42.337844677202604
            ]
          },
          "bbox": [
            -4.256965192155244,
            42.337844677202604,
            -4.256965192155244,
            42.337844677202604
          ]
        }
      ],
      "bbox": [
        -4.256965192155244,
        42.337844677202604,
        -4.256965192155244,
        42.337844677202604
      ]
    },
    "09248": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.457564284313242,
              42.212847614022564
            ]
          },
          "bbox": [
            -3.457564284313242,
            42.212847614022564,
            -3.457564284313242,
            42.212847614022564
          ]
        }
      ],
      "bbox": [
        -3.457564284313242,
        42.212847614022564,
        -3.457564284313242,
        42.212847614022564
      ]
    },
    "09249": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.978196458839342,
              42.18398919485053
            ]
          },
          "bbox": [
            -3.978196458839342,
            42.18398919485053,
            -3.978196458839342,
            42.18398919485053
          ]
        }
      ],
      "bbox": [
        -3.978196458839342,
        42.18398919485053,
        -3.978196458839342,
        42.18398919485053
      ]
    },
    "09250": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.958083992160131,
              42.223482177836786
            ]
          },
          "bbox": [
            -3.958083992160131,
            42.223482177836786,
            -3.958083992160131,
            42.223482177836786
          ]
        }
      ],
      "bbox": [
        -3.958083992160131,
        42.223482177836786,
        -3.958083992160131,
        42.223482177836786
      ]
    },
    "09251": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.126276157951647,
              42.650005177562065
            ]
          },
          "bbox": [
            -3.126276157951647,
            42.650005177562065,
            -3.126276157951647,
            42.650005177562065
          ]
        }
      ],
      "bbox": [
        -3.126276157951647,
        42.650005177562065,
        -3.126276157951647,
        42.650005177562065
      ]
    },
    "09253": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.708776611182359,
              41.55013114968634
            ]
          },
          "bbox": [
            -3.708776611182359,
            41.55013114968634,
            -3.708776611182359,
            41.55013114968634
          ]
        }
      ],
      "bbox": [
        -3.708776611182359,
        41.55013114968634,
        -3.708776611182359,
        41.55013114968634
      ]
    },
    "09255": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2295676983151957,
              42.72454957860797
            ]
          },
          "bbox": [
            -3.2295676983151957,
            42.72454957860797,
            -3.2295676983151957,
            42.72454957860797
          ]
        }
      ],
      "bbox": [
        -3.2295676983151957,
        42.72454957860797,
        -3.2295676983151957,
        42.72454957860797
      ]
    },
    "09256": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.99549092649106,
              41.736467895471776
            ]
          },
          "bbox": [
            -3.99549092649106,
            41.736467895471776,
            -3.99549092649106,
            41.736467895471776
          ]
        }
      ],
      "bbox": [
        -3.99549092649106,
        41.736467895471776,
        -3.99549092649106,
        41.736467895471776
      ]
    },
    "09257": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.971603050143422,
              42.44236946443499
            ]
          },
          "bbox": [
            -3.971603050143422,
            42.44236946443499,
            -3.971603050143422,
            42.44236946443499
          ]
        }
      ],
      "bbox": [
        -3.971603050143422,
        42.44236946443499,
        -3.971603050143422,
        42.44236946443499
      ]
    },
    "09258": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.202559736248688,
              42.23784490566001
            ]
          },
          "bbox": [
            -4.202559736248688,
            42.23784490566001,
            -4.202559736248688,
            42.23784490566001
          ]
        }
      ],
      "bbox": [
        -4.202559736248688,
        42.23784490566001,
        -4.202559736248688,
        42.23784490566001
      ]
    },
    "09259": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8400174768356328,
              42.41904463718132
            ]
          },
          "bbox": [
            -3.8400174768356328,
            42.41904463718132,
            -3.8400174768356328,
            42.41904463718132
          ]
        }
      ],
      "bbox": [
        -3.8400174768356328,
        42.41904463718132,
        -3.8400174768356328,
        42.41904463718132
      ]
    },
    "09261": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.46637921486987,
              41.68679766496177
            ]
          },
          "bbox": [
            -3.46637921486987,
            41.68679766496177,
            -3.46637921486987,
            41.68679766496177
          ]
        }
      ],
      "bbox": [
        -3.46637921486987,
        41.68679766496177,
        -3.46637921486987,
        41.68679766496177
      ]
    },
    "09262": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.0664099334084085,
              42.07027814489196
            ]
          },
          "bbox": [
            -4.0664099334084085,
            42.07027814489196,
            -4.0664099334084085,
            42.07027814489196
          ]
        }
      ],
      "bbox": [
        -4.0664099334084085,
        42.07027814489196,
        -4.0664099334084085,
        42.07027814489196
      ]
    },
    "09265": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4053453985651707,
              42.6013724609006
            ]
          },
          "bbox": [
            -3.4053453985651707,
            42.6013724609006,
            -3.4053453985651707,
            42.6013724609006
          ]
        }
      ],
      "bbox": [
        -3.4053453985651707,
        42.6013724609006,
        -3.4053453985651707,
        42.6013724609006
      ]
    },
    "09266": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.289402847025623,
              42.22113210720946
            ]
          },
          "bbox": [
            -3.289402847025623,
            42.22113210720946,
            -3.289402847025623,
            42.22113210720946
          ]
        }
      ],
      "bbox": [
        -3.289402847025623,
        42.22113210720946,
        -3.289402847025623,
        42.22113210720946
      ]
    },
    "09267": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6950934482432927,
              41.91019667638549
            ]
          },
          "bbox": [
            -3.6950934482432927,
            41.91019667638549,
            -3.6950934482432927,
            41.91019667638549
          ]
        }
      ],
      "bbox": [
        -3.6950934482432927,
        41.91019667638549,
        -3.6950934482432927,
        41.91019667638549
      ]
    },
    "09268": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.304173925243407,
              41.91637901664609
            ]
          },
          "bbox": [
            -3.304173925243407,
            41.91637901664609,
            -3.304173925243407,
            41.91637901664609
          ]
        }
      ],
      "bbox": [
        -3.304173925243407,
        41.91637901664609,
        -3.304173925243407,
        41.91637901664609
      ]
    },
    "09269": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3116636611179935,
              42.07669468241791
            ]
          },
          "bbox": [
            -3.3116636611179935,
            42.07669468241791,
            -3.3116636611179935,
            42.07669468241791
          ]
        }
      ],
      "bbox": [
        -3.3116636611179935,
        42.07669468241791,
        -3.3116636611179935,
        42.07669468241791
      ]
    },
    "09270": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.625151863449875,
              41.8791984268549
            ]
          },
          "bbox": [
            -3.625151863449875,
            41.8791984268549,
            -3.625151863449875,
            41.8791984268549
          ]
        }
      ],
      "bbox": [
        -3.625151863449875,
        41.8791984268549,
        -3.625151863449875,
        41.8791984268549
      ]
    },
    "09272": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.527094743666762,
              42.65777409218198
            ]
          },
          "bbox": [
            -3.527094743666762,
            42.65777409218198,
            -3.527094743666762,
            42.65777409218198
          ]
        }
      ],
      "bbox": [
        -3.527094743666762,
        42.65777409218198,
        -3.527094743666762,
        42.65777409218198
      ]
    },
    "09273": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3442720816439424,
              42.49870265781326
            ]
          },
          "bbox": [
            -3.3442720816439424,
            42.49870265781326,
            -3.3442720816439424,
            42.49870265781326
          ]
        }
      ],
      "bbox": [
        -3.3442720816439424,
        42.49870265781326,
        -3.3442720816439424,
        42.49870265781326
      ]
    },
    "09274": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2012286309758835,
              42.31510745840095
            ]
          },
          "bbox": [
            -3.2012286309758835,
            42.31510745840095,
            -3.2012286309758835,
            42.31510745840095
          ]
        }
      ],
      "bbox": [
        -3.2012286309758835,
        42.31510745840095,
        -3.2012286309758835,
        42.31510745840095
      ]
    },
    "09275": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8696079654336053,
              42.18584499280149
            ]
          },
          "bbox": [
            -3.8696079654336053,
            42.18584499280149,
            -3.8696079654336053,
            42.18584499280149
          ]
        }
      ],
      "bbox": [
        -3.8696079654336053,
        42.18584499280149,
        -3.8696079654336053,
        42.18584499280149
      ]
    },
    "09276": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.819447581807588,
              42.77252077424941
            ]
          },
          "bbox": [
            -2.819447581807588,
            42.77252077424941,
            -2.819447581807588,
            42.77252077424941
          ]
        }
      ],
      "bbox": [
        -2.819447581807588,
        42.77252077424941,
        -2.819447581807588,
        42.77252077424941
      ]
    },
    "09277": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5858300669839154,
              42.045294738359594
            ]
          },
          "bbox": [
            -3.5858300669839154,
            42.045294738359594,
            -3.5858300669839154,
            42.045294738359594
          ]
        }
      ],
      "bbox": [
        -3.5858300669839154,
        42.045294738359594,
        -3.5858300669839154,
        42.045294738359594
      ]
    },
    "09279": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.58028823523342,
              41.70698403644611
            ]
          },
          "bbox": [
            -3.58028823523342,
            41.70698403644611,
            -3.58028823523342,
            41.70698403644611
          ]
        }
      ],
      "bbox": [
        -3.58028823523342,
        41.70698403644611,
        -3.58028823523342,
        41.70698403644611
      ]
    },
    "09280": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3720363484516414,
              42.58816775285185
            ]
          },
          "bbox": [
            -3.3720363484516414,
            42.58816775285185,
            -3.3720363484516414,
            42.58816775285185
          ]
        }
      ],
      "bbox": [
        -3.3720363484516414,
        42.58816775285185,
        -3.3720363484516414,
        42.58816775285185
      ]
    },
    "09281": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7495835175222822,
              41.761947459352044
            ]
          },
          "bbox": [
            -3.7495835175222822,
            41.761947459352044,
            -3.7495835175222822,
            41.761947459352044
          ]
        }
      ],
      "bbox": [
        -3.7495835175222822,
        41.761947459352044,
        -3.7495835175222822,
        41.761947459352044
      ]
    },
    "09283": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2976847969909793,
              42.67469537425771
            ]
          },
          "bbox": [
            -3.2976847969909793,
            42.67469537425771,
            -3.2976847969909793,
            42.67469537425771
          ]
        }
      ],
      "bbox": [
        -3.2976847969909793,
        42.67469537425771,
        -3.2976847969909793,
        42.67469537425771
      ]
    },
    "09287": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6918594974453836,
              42.45409814938474
            ]
          },
          "bbox": [
            -3.6918594974453836,
            42.45409814938474,
            -3.6918594974453836,
            42.45409814938474
          ]
        }
      ],
      "bbox": [
        -3.6918594974453836,
        42.45409814938474,
        -3.6918594974453836,
        42.45409814938474
      ]
    },
    "09288": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5368666617280176,
              42.41457508290573
            ]
          },
          "bbox": [
            -3.5368666617280176,
            42.41457508290573,
            -3.5368666617280176,
            42.41457508290573
          ]
        }
      ],
      "bbox": [
        -3.5368666617280176,
        42.41457508290573,
        -3.5368666617280176,
        42.41457508290573
      ]
    },
    "09289": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0184791153535784,
              42.00380759447592
            ]
          },
          "bbox": [
            -3.0184791153535784,
            42.00380759447592,
            -3.0184791153535784,
            42.00380759447592
          ]
        }
      ],
      "bbox": [
        -3.0184791153535784,
        42.00380759447592,
        -3.0184791153535784,
        42.00380759447592
      ]
    },
    "09292": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4203672179268585,
              42.47848426068667
            ]
          },
          "bbox": [
            -3.4203672179268585,
            42.47848426068667,
            -3.4203672179268585,
            42.47848426068667
          ]
        }
      ],
      "bbox": [
        -3.4203672179268585,
        42.47848426068667,
        -3.4203672179268585,
        42.47848426068667
      ]
    },
    "09294": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7895031478275394,
              41.99439423686136
            ]
          },
          "bbox": [
            -3.7895031478275394,
            41.99439423686136,
            -3.7895031478275394,
            41.99439423686136
          ]
        }
      ],
      "bbox": [
        -3.7895031478275394,
        41.99439423686136,
        -3.7895031478275394,
        41.99439423686136
      ]
    },
    "09295": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.525732564125679,
              41.985285057436975
            ]
          },
          "bbox": [
            -3.525732564125679,
            41.985285057436975,
            -3.525732564125679,
            41.985285057436975
          ]
        }
      ],
      "bbox": [
        -3.525732564125679,
        41.985285057436975,
        -3.525732564125679,
        41.985285057436975
      ]
    },
    "09297": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.847761912657957,
              42.37216366012597
            ]
          },
          "bbox": [
            -3.847761912657957,
            42.37216366012597,
            -3.847761912657957,
            42.37216366012597
          ]
        }
      ],
      "bbox": [
        -3.847761912657957,
        42.37216366012597,
        -3.847761912657957,
        42.37216366012597
      ]
    },
    "09298": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1921645305232147,
              42.551530250027795
            ]
          },
          "bbox": [
            -3.1921645305232147,
            42.551530250027795,
            -3.1921645305232147,
            42.551530250027795
          ]
        }
      ],
      "bbox": [
        -3.1921645305232147,
        42.551530250027795,
        -3.1921645305232147,
        42.551530250027795
      ]
    },
    "09301": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6801450673593687,
              42.41315175493526
            ]
          },
          "bbox": [
            -3.6801450673593687,
            42.41315175493526,
            -3.6801450673593687,
            42.41315175493526
          ]
        }
      ],
      "bbox": [
        -3.6801450673593687,
        42.41315175493526,
        -3.6801450673593687,
        42.41315175493526
      ]
    },
    "09302": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1979356335857307,
              41.89604849456245
            ]
          },
          "bbox": [
            -3.1979356335857307,
            41.89604849456245,
            -3.1979356335857307,
            41.89604849456245
          ]
        }
      ],
      "bbox": [
        -3.1979356335857307,
        41.89604849456245,
        -3.1979356335857307,
        41.89604849456245
      ]
    },
    "09303": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2871470123435427,
              42.30987913174276
            ]
          },
          "bbox": [
            -3.2871470123435427,
            42.30987913174276,
            -3.2871470123435427,
            42.30987913174276
          ]
        }
      ],
      "bbox": [
        -3.2871470123435427,
        42.30987913174276,
        -3.2871470123435427,
        42.30987913174276
      ]
    },
    "09304": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8551964849371076,
              42.335841836772495
            ]
          },
          "bbox": [
            -3.8551964849371076,
            42.335841836772495,
            -3.8551964849371076,
            42.335841836772495
          ]
        }
      ],
      "bbox": [
        -3.8551964849371076,
        42.335841836772495,
        -3.8551964849371076,
        42.335841836772495
      ]
    },
    "09306": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.213192169825703,
              42.6881178023174
            ]
          },
          "bbox": [
            -4.213192169825703,
            42.6881178023174,
            -4.213192169825703,
            42.6881178023174
          ]
        }
      ],
      "bbox": [
        -4.213192169825703,
        42.6881178023174,
        -4.213192169825703,
        42.6881178023174
      ]
    },
    "09307": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.064530669106663,
              42.417607764127816
            ]
          },
          "bbox": [
            -3.064530669106663,
            42.417607764127816,
            -3.064530669106663,
            42.417607764127816
          ]
        }
      ],
      "bbox": [
        -3.064530669106663,
        42.417607764127816,
        -3.064530669106663,
        42.417607764127816
      ]
    },
    "09308": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1170198782158,
              42.456372860289335
            ]
          },
          "bbox": [
            -3.1170198782158,
            42.456372860289335,
            -3.1170198782158,
            42.456372860289335
          ]
        }
      ],
      "bbox": [
        -3.1170198782158,
        42.456372860289335,
        -3.1170198782158,
        42.456372860289335
      ]
    },
    "09309": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -2.973359832643675,
              41.97036990390412
            ]
          },
          "bbox": [
            -2.973359832643675,
            41.97036990390412,
            -2.973359832643675,
            41.97036990390412
          ]
        }
      ],
      "bbox": [
        -2.973359832643675,
        41.97036990390412,
        -2.973359832643675,
        41.97036990390412
      ]
    },
    "09310": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.388117895588714,
              42.50945323056927
            ]
          },
          "bbox": [
            -3.388117895588714,
            42.50945323056927,
            -3.388117895588714,
            42.50945323056927
          ]
        }
      ],
      "bbox": [
        -3.388117895588714,
        42.50945323056927,
        -3.388117895588714,
        42.50945323056927
      ]
    },
    "09311": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.499266104422729,
              42.03064218429704
            ]
          },
          "bbox": [
            -3.499266104422729,
            42.03064218429704,
            -3.499266104422729,
            42.03064218429704
          ]
        }
      ],
      "bbox": [
        -3.499266104422729,
        42.03064218429704,
        -3.499266104422729,
        42.03064218429704
      ]
    },
    "09312": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.347502749847249,
              42.008252229557534
            ]
          },
          "bbox": [
            -3.347502749847249,
            42.008252229557534,
            -3.347502749847249,
            42.008252229557534
          ]
        }
      ],
      "bbox": [
        -3.347502749847249,
        42.008252229557534,
        -3.347502749847249,
        42.008252229557534
      ]
    },
    "09314": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5347616180802777,
              42.200285892818655
            ]
          },
          "bbox": [
            -3.5347616180802777,
            42.200285892818655,
            -3.5347616180802777,
            42.200285892818655
          ]
        }
      ],
      "bbox": [
        -3.5347616180802777,
        42.200285892818655,
        -3.5347616180802777,
        42.200285892818655
      ]
    },
    "09315": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.663782826094737,
              42.22713289916335
            ]
          },
          "bbox": [
            -3.663782826094737,
            42.22713289916335,
            -3.663782826094737,
            42.22713289916335
          ]
        }
      ],
      "bbox": [
        -3.663782826094737,
        42.22713289916335,
        -3.663782826094737,
        42.22713289916335
      ]
    },
    "09316": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.13056529152746,
              42.15054972168463
            ]
          },
          "bbox": [
            -4.13056529152746,
            42.15054972168463,
            -4.13056529152746,
            42.15054972168463
          ]
        }
      ],
      "bbox": [
        -4.13056529152746,
        42.15054972168463,
        -4.13056529152746,
        42.15054972168463
      ]
    },
    "09317": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.231135091560993,
              42.51422321802528
            ]
          },
          "bbox": [
            -4.231135091560993,
            42.51422321802528,
            -4.231135091560993,
            42.51422321802528
          ]
        }
      ],
      "bbox": [
        -4.231135091560993,
        42.51422321802528,
        -4.231135091560993,
        42.51422321802528
      ]
    },
    "09318": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.221455060259361,
              42.179146316714004
            ]
          },
          "bbox": [
            -3.221455060259361,
            42.179146316714004,
            -3.221455060259361,
            42.179146316714004
          ]
        }
      ],
      "bbox": [
        -3.221455060259361,
        42.179146316714004,
        -3.221455060259361,
        42.179146316714004
      ]
    },
    "09321": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.920713335954746,
              41.695731723498426
            ]
          },
          "bbox": [
            -3.920713335954746,
            41.695731723498426,
            -3.920713335954746,
            41.695731723498426
          ]
        }
      ],
      "bbox": [
        -3.920713335954746,
        41.695731723498426,
        -3.920713335954746,
        41.695731723498426
      ]
    },
    "09323": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4471593345307134,
              42.58527396844718
            ]
          },
          "bbox": [
            -3.4471593345307134,
            42.58527396844718,
            -3.4471593345307134,
            42.58527396844718
          ]
        }
      ],
      "bbox": [
        -3.4471593345307134,
        42.58527396844718,
        -3.4471593345307134,
        42.58527396844718
      ]
    },
    "09325": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9648528197403334,
              41.9954052520147
            ]
          },
          "bbox": [
            -3.9648528197403334,
            41.9954052520147,
            -3.9648528197403334,
            41.9954052520147
          ]
        }
      ],
      "bbox": [
        -3.9648528197403334,
        41.9954052520147,
        -3.9648528197403334,
        41.9954052520147
      ]
    },
    "09326": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.576196850309312,
              42.390402643652486
            ]
          },
          "bbox": [
            -3.576196850309312,
            42.390402643652486,
            -3.576196850309312,
            42.390402643652486
          ]
        }
      ],
      "bbox": [
        -3.576196850309312,
        42.390402643652486,
        -3.576196850309312,
        42.390402643652486
      ]
    },
    "09327": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5046763408272144,
              42.53684130205888
            ]
          },
          "bbox": [
            -3.5046763408272144,
            42.53684130205888,
            -3.5046763408272144,
            42.53684130205888
          ]
        }
      ],
      "bbox": [
        -3.5046763408272144,
        42.53684130205888,
        -3.5046763408272144,
        42.53684130205888
      ]
    },
    "09328": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.533933608565526,
              42.756498818067094
            ]
          },
          "bbox": [
            -3.533933608565526,
            42.756498818067094,
            -3.533933608565526,
            42.756498818067094
          ]
        }
      ],
      "bbox": [
        -3.533933608565526,
        42.756498818067094,
        -3.533933608565526,
        42.756498818067094
      ]
    },
    "09329": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.472953681391045,
              42.69503202113693
            ]
          },
          "bbox": [
            -3.472953681391045,
            42.69503202113693,
            -3.472953681391045,
            42.69503202113693
          ]
        }
      ],
      "bbox": [
        -3.472953681391045,
        42.69503202113693,
        -3.472953681391045,
        42.69503202113693
      ]
    },
    "09330": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.274770150962495,
              42.04506520335438
            ]
          },
          "bbox": [
            -3.274770150962495,
            42.04506520335438,
            -3.274770150962495,
            42.04506520335438
          ]
        }
      ],
      "bbox": [
        -3.274770150962495,
        42.04506520335438,
        -3.274770150962495,
        42.04506520335438
      ]
    },
    "09332": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6850964533393955,
              42.268994357439006
            ]
          },
          "bbox": [
            -3.6850964533393955,
            42.268994357439006,
            -3.6850964533393955,
            42.268994357439006
          ]
        }
      ],
      "bbox": [
        -3.6850964533393955,
        42.268994357439006,
        -3.6850964533393955,
        42.268994357439006
      ]
    },
    "09334": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3819161623830545,
              42.5562301807201
            ]
          },
          "bbox": [
            -3.3819161623830545,
            42.5562301807201,
            -3.3819161623830545,
            42.5562301807201
          ]
        }
      ],
      "bbox": [
        -3.3819161623830545,
        42.5562301807201,
        -3.3819161623830545,
        42.5562301807201
      ]
    },
    "09335": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4651989790524858,
              42.27663017537559
            ]
          },
          "bbox": [
            -3.4651989790524858,
            42.27663017537559,
            -3.4651989790524858,
            42.27663017537559
          ]
        }
      ],
      "bbox": [
        -3.4651989790524858,
        42.27663017537559,
        -3.4651989790524858,
        42.27663017537559
      ]
    },
    "09337": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.517041947308497,
              41.684138968298065
            ]
          },
          "bbox": [
            -3.517041947308497,
            41.684138968298065,
            -3.517041947308497,
            41.684138968298065
          ]
        }
      ],
      "bbox": [
        -3.517041947308497,
        41.684138968298065,
        -3.517041947308497,
        41.684138968298065
      ]
    },
    "09338": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7983803174737143,
              42.331605438600576
            ]
          },
          "bbox": [
            -3.7983803174737143,
            42.331605438600576,
            -3.7983803174737143,
            42.331605438600576
          ]
        }
      ],
      "bbox": [
        -3.7983803174737143,
        42.331605438600576,
        -3.7983803174737143,
        42.331605438600576
      ]
    },
    "09339": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.000116369566233,
              41.636428714575054
            ]
          },
          "bbox": [
            -4.000116369566233,
            41.636428714575054,
            -4.000116369566233,
            41.636428714575054
          ]
        }
      ],
      "bbox": [
        -4.000116369566233,
        41.636428714575054,
        -4.000116369566233,
        41.636428714575054
      ]
    },
    "09340": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3268003413122775,
              42.152005344464015
            ]
          },
          "bbox": [
            -3.3268003413122775,
            42.152005344464015,
            -3.3268003413122775,
            42.152005344464015
          ]
        }
      ],
      "bbox": [
        -3.3268003413122775,
        42.152005344464015,
        -3.3268003413122775,
        42.152005344464015
      ]
    },
    "09343": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.801999946580374,
              42.05935306091734
            ]
          },
          "bbox": [
            -3.801999946580374,
            42.05935306091734,
            -3.801999946580374,
            42.05935306091734
          ]
        }
      ],
      "bbox": [
        -3.801999946580374,
        42.05935306091734,
        -3.801999946580374,
        42.05935306091734
      ]
    },
    "09345": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.583502566748123,
              41.59124410529755
            ]
          },
          "bbox": [
            -3.583502566748123,
            41.59124410529755,
            -3.583502566748123,
            41.59124410529755
          ]
        }
      ],
      "bbox": [
        -3.583502566748123,
        41.59124410529755,
        -3.583502566748123,
        41.59124410529755
      ]
    },
    "09346": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2094142392709224,
              42.27292419660758
            ]
          },
          "bbox": [
            -3.2094142392709224,
            42.27292419660758,
            -3.2094142392709224,
            42.27292419660758
          ]
        }
      ],
      "bbox": [
        -3.2094142392709224,
        42.27292419660758,
        -3.2094142392709224,
        42.27292419660758
      ]
    },
    "09347": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.0744818466930393,
              42.70827631812051
            ]
          },
          "bbox": [
            -3.0744818466930393,
            42.70827631812051,
            -3.0744818466930393,
            42.70827631812051
          ]
        }
      ],
      "bbox": [
        -3.0744818466930393,
        42.70827631812051,
        -3.0744818466930393,
        42.70827631812051
      ]
    },
    "09348": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.694011295381616,
              42.05414483984214
            ]
          },
          "bbox": [
            -3.694011295381616,
            42.05414483984214,
            -3.694011295381616,
            42.05414483984214
          ]
        }
      ],
      "bbox": [
        -3.694011295381616,
        42.05414483984214,
        -3.694011295381616,
        42.05414483984214
      ]
    },
    "09350": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.989238283263271,
              42.11361290071241
            ]
          },
          "bbox": [
            -3.989238283263271,
            42.11361290071241,
            -3.989238283263271,
            42.11361290071241
          ]
        }
      ],
      "bbox": [
        -3.989238283263271,
        42.11361290071241,
        -3.989238283263271,
        42.11361290071241
      ]
    },
    "09351": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4343610942540566,
              42.43622759980545
            ]
          },
          "bbox": [
            -3.4343610942540566,
            42.43622759980545,
            -3.4343610942540566,
            42.43622759980545
          ]
        }
      ],
      "bbox": [
        -3.4343610942540566,
        42.43622759980545,
        -3.4343610942540566,
        42.43622759980545
      ]
    },
    "09352": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.558168503458805,
              41.86492972242381
            ]
          },
          "bbox": [
            -3.558168503458805,
            41.86492972242381,
            -3.558168503458805,
            41.86492972242381
          ]
        }
      ],
      "bbox": [
        -3.558168503458805,
        41.86492972242381,
        -3.558168503458805,
        41.86492972242381
      ]
    },
    "09353": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.179798791969344,
              42.62708866507177
            ]
          },
          "bbox": [
            -3.179798791969344,
            42.62708866507177,
            -3.179798791969344,
            42.62708866507177
          ]
        }
      ],
      "bbox": [
        -3.179798791969344,
        42.62708866507177,
        -3.179798791969344,
        42.62708866507177
      ]
    },
    "09354": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4487299740815986,
              42.48134167066371
            ]
          },
          "bbox": [
            -3.4487299740815986,
            42.48134167066371,
            -3.4487299740815986,
            42.48134167066371
          ]
        }
      ],
      "bbox": [
        -3.4487299740815986,
        42.48134167066371,
        -3.4487299740815986,
        42.48134167066371
      ]
    },
    "09355": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7507730989842343,
              41.82638561554277
            ]
          },
          "bbox": [
            -3.7507730989842343,
            41.82638561554277,
            -3.7507730989842343,
            41.82638561554277
          ]
        }
      ],
      "bbox": [
        -3.7507730989842343,
        41.82638561554277,
        -3.7507730989842343,
        41.82638561554277
      ]
    },
    "09356": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.484195837318247,
              41.97510615091386
            ]
          },
          "bbox": [
            -3.484195837318247,
            41.97510615091386,
            -3.484195837318247,
            41.97510615091386
          ]
        }
      ],
      "bbox": [
        -3.484195837318247,
        41.97510615091386,
        -3.484195837318247,
        41.97510615091386
      ]
    },
    "09358": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.430610539320992,
              41.954219035679
            ]
          },
          "bbox": [
            -3.430610539320992,
            41.954219035679,
            -3.430610539320992,
            41.954219035679
          ]
        }
      ],
      "bbox": [
        -3.430610539320992,
        41.954219035679,
        -3.430610539320992,
        41.954219035679
      ]
    },
    "09360": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1676762891301333,
              42.3566333540664
            ]
          },
          "bbox": [
            -3.1676762891301333,
            42.3566333540664,
            -3.1676762891301333,
            42.3566333540664
          ]
        }
      ],
      "bbox": [
        -3.1676762891301333,
        42.3566333540664,
        -3.1676762891301333,
        42.3566333540664
      ]
    },
    "09361": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8936292194755864,
              42.74086948023017
            ]
          },
          "bbox": [
            -3.8936292194755864,
            42.74086948023017,
            -3.8936292194755864,
            42.74086948023017
          ]
        }
      ],
      "bbox": [
        -3.8936292194755864,
        42.74086948023017,
        -3.8936292194755864,
        42.74086948023017
      ]
    },
    "09362": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7032398756603317,
              42.24465269540728
            ]
          },
          "bbox": [
            -3.7032398756603317,
            42.24465269540728,
            -3.7032398756603317,
            42.24465269540728
          ]
        }
      ],
      "bbox": [
        -3.7032398756603317,
        42.24465269540728,
        -3.7032398756603317,
        42.24465269540728
      ]
    },
    "09363": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.031750844023991,
              42.39258940871151
            ]
          },
          "bbox": [
            -4.031750844023991,
            42.39258940871151,
            -4.031750844023991,
            42.39258940871151
          ]
        }
      ],
      "bbox": [
        -4.031750844023991,
        42.39258940871151,
        -4.031750844023991,
        42.39258940871151
      ]
    },
    "09365": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8147682585389906,
              41.56624505869087
            ]
          },
          "bbox": [
            -3.8147682585389906,
            41.56624505869087,
            -3.8147682585389906,
            41.56624505869087
          ]
        }
      ],
      "bbox": [
        -3.8147682585389906,
        41.56624505869087,
        -3.8147682585389906,
        41.56624505869087
      ]
    },
    "09366": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6595746061449796,
              41.96309484371447
            ]
          },
          "bbox": [
            -3.6595746061449796,
            41.96309484371447,
            -3.6595746061449796,
            41.96309484371447
          ]
        }
      ],
      "bbox": [
        -3.6595746061449796,
        41.96309484371447,
        -3.6595746061449796,
        41.96309484371447
      ]
    },
    "09368": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.093119038473346,
              42.459076105720705
            ]
          },
          "bbox": [
            -4.093119038473346,
            42.459076105720705,
            -4.093119038473346,
            42.459076105720705
          ]
        }
      ],
      "bbox": [
        -4.093119038473346,
        42.459076105720705,
        -4.093119038473346,
        42.459076105720705
      ]
    },
    "09369": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8267380967754767,
              41.803916229844546
            ]
          },
          "bbox": [
            -3.8267380967754767,
            41.803916229844546,
            -3.8267380967754767,
            41.803916229844546
          ]
        }
      ],
      "bbox": [
        -3.8267380967754767,
        41.803916229844546,
        -3.8267380967754767,
        41.803916229844546
      ]
    },
    "09372": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7187735145605796,
              42.41409948379819
            ]
          },
          "bbox": [
            -3.7187735145605796,
            42.41409948379819,
            -3.7187735145605796,
            42.41409948379819
          ]
        }
      ],
      "bbox": [
        -3.7187735145605796,
        42.41409948379819,
        -3.7187735145605796,
        42.41409948379819
      ]
    },
    "09373": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.201033107321189,
              42.59452717411908
            ]
          },
          "bbox": [
            -4.201033107321189,
            42.59452717411908,
            -4.201033107321189,
            42.59452717411908
          ]
        }
      ],
      "bbox": [
        -4.201033107321189,
        42.59452717411908,
        -4.201033107321189,
        42.59452717411908
      ]
    },
    "09374": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.911219643575651,
              42.475776244034144
            ]
          },
          "bbox": [
            -3.911219643575651,
            42.475776244034144,
            -3.911219643575651,
            42.475776244034144
          ]
        }
      ],
      "bbox": [
        -3.911219643575651,
        42.475776244034144,
        -3.911219643575651,
        42.475776244034144
      ]
    },
    "09375": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9945948757806216,
              42.27267487487124
            ]
          },
          "bbox": [
            -3.9945948757806216,
            42.27267487487124,
            -3.9945948757806216,
            42.27267487487124
          ]
        }
      ],
      "bbox": [
        -3.9945948757806216,
        42.27267487487124,
        -3.9945948757806216,
        42.27267487487124
      ]
    },
    "09377": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8144620086940355,
              42.35314008559655
            ]
          },
          "bbox": [
            -3.8144620086940355,
            42.35314008559655,
            -3.8144620086940355,
            42.35314008559655
          ]
        }
      ],
      "bbox": [
        -3.8144620086940355,
        42.35314008559655,
        -3.8144620086940355,
        42.35314008559655
      ]
    },
    "09378": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.549960101782032,
              41.9530151195929
            ]
          },
          "bbox": [
            -3.549960101782032,
            41.9530151195929,
            -3.549960101782032,
            41.9530151195929
          ]
        }
      ],
      "bbox": [
        -3.549960101782032,
        41.9530151195929,
        -3.549960101782032,
        41.9530151195929
      ]
    },
    "09380": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.851141356478088,
              41.80339343936904
            ]
          },
          "bbox": [
            -3.851141356478088,
            41.80339343936904,
            -3.851141356478088,
            41.80339343936904
          ]
        }
      ],
      "bbox": [
        -3.851141356478088,
        41.80339343936904,
        -3.851141356478088,
        41.80339343936904
      ]
    },
    "09381": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3647444976355416,
              42.17990617829327
            ]
          },
          "bbox": [
            -3.3647444976355416,
            42.17990617829327,
            -3.3647444976355416,
            42.17990617829327
          ]
        }
      ],
      "bbox": [
        -3.3647444976355416,
        42.17990617829327,
        -3.3647444976355416,
        42.17990617829327
      ]
    },
    "09382": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.938639106808393,
              42.48829644796518
            ]
          },
          "bbox": [
            -3.938639106808393,
            42.48829644796518,
            -3.938639106808393,
            42.48829644796518
          ]
        }
      ],
      "bbox": [
        -3.938639106808393,
        42.48829644796518,
        -3.938639106808393,
        42.48829644796518
      ]
    },
    "09384": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.859264589049795,
              42.049618597064196
            ]
          },
          "bbox": [
            -3.859264589049795,
            42.049618597064196,
            -3.859264589049795,
            42.049618597064196
          ]
        }
      ],
      "bbox": [
        -3.859264589049795,
        42.049618597064196,
        -3.859264589049795,
        42.049618597064196
      ]
    },
    "09386": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.683511100783447,
              42.101871761810784
            ]
          },
          "bbox": [
            -3.683511100783447,
            42.101871761810784,
            -3.683511100783447,
            42.101871761810784
          ]
        }
      ],
      "bbox": [
        -3.683511100783447,
        42.101871761810784,
        -3.683511100783447,
        42.101871761810784
      ]
    },
    "09387": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.750954112871652,
              41.57536270888598
            ]
          },
          "bbox": [
            -3.750954112871652,
            41.57536270888598,
            -3.750954112871652,
            41.57536270888598
          ]
        }
      ],
      "bbox": [
        -3.750954112871652,
        41.57536270888598,
        -3.750954112871652,
        41.57536270888598
      ]
    },
    "09388": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5084186901285905,
              42.16861751133932
            ]
          },
          "bbox": [
            -3.5084186901285905,
            42.16861751133932,
            -3.5084186901285905,
            42.16861751133932
          ]
        }
      ],
      "bbox": [
        -3.5084186901285905,
        42.16861751133932,
        -3.5084186901285905,
        42.16861751133932
      ]
    },
    "09389": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.977967086326901,
              42.049615814850746
            ]
          },
          "bbox": [
            -3.977967086326901,
            42.049615814850746,
            -3.977967086326901,
            42.049615814850746
          ]
        }
      ],
      "bbox": [
        -3.977967086326901,
        42.049615814850746,
        -3.977967086326901,
        42.049615814850746
      ]
    },
    "09390": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.932849109576698,
              41.857126240441296
            ]
          },
          "bbox": [
            -3.932849109576698,
            41.857126240441296,
            -3.932849109576698,
            41.857126240441296
          ]
        }
      ],
      "bbox": [
        -3.932849109576698,
        41.857126240441296,
        -3.932849109576698,
        41.857126240441296
      ]
    },
    "09391": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.021414308442217,
              41.82758222196182
            ]
          },
          "bbox": [
            -4.021414308442217,
            41.82758222196182,
            -4.021414308442217,
            41.82758222196182
          ]
        }
      ],
      "bbox": [
        -4.021414308442217,
        41.82758222196182,
        -4.021414308442217,
        41.82758222196182
      ]
    },
    "09392": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2413669034372656,
              42.41226110326263
            ]
          },
          "bbox": [
            -3.2413669034372656,
            42.41226110326263,
            -3.2413669034372656,
            42.41226110326263
          ]
        }
      ],
      "bbox": [
        -3.2413669034372656,
        42.41226110326263,
        -3.2413669034372656,
        42.41226110326263
      ]
    },
    "09394": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3809864967496237,
              42.813583088314296
            ]
          },
          "bbox": [
            -3.3809864967496237,
            42.813583088314296,
            -3.3809864967496237,
            42.813583088314296
          ]
        }
      ],
      "bbox": [
        -3.3809864967496237,
        42.813583088314296,
        -3.3809864967496237,
        42.813583088314296
      ]
    },
    "09395": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8200174038783703,
              42.715993225819126
            ]
          },
          "bbox": [
            -3.8200174038783703,
            42.715993225819126,
            -3.8200174038783703,
            42.715993225819126
          ]
        }
      ],
      "bbox": [
        -3.8200174038783703,
        42.715993225819126,
        -3.8200174038783703,
        42.715993225819126
      ]
    },
    "09396": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5897438761293774,
              41.79602336768057
            ]
          },
          "bbox": [
            -3.5897438761293774,
            41.79602336768057,
            -3.5897438761293774,
            41.79602336768057
          ]
        }
      ],
      "bbox": [
        -3.5897438761293774,
        41.79602336768057,
        -3.5897438761293774,
        41.79602336768057
      ]
    },
    "09398": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8351375165762724,
              42.608603761925316
            ]
          },
          "bbox": [
            -3.8351375165762724,
            42.608603761925316,
            -3.8351375165762724,
            42.608603761925316
          ]
        }
      ],
      "bbox": [
        -3.8351375165762724,
        42.608603761925316,
        -3.8351375165762724,
        42.608603761925316
      ]
    },
    "09400": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.572560175231474,
              41.62857485836942
            ]
          },
          "bbox": [
            -3.572560175231474,
            41.62857485836942,
            -3.572560175231474,
            41.62857485836942
          ]
        }
      ],
      "bbox": [
        -3.572560175231474,
        41.62857485836942,
        -3.572560175231474,
        41.62857485836942
      ]
    },
    "09403": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5288078396175737,
              41.835226021104845
            ]
          },
          "bbox": [
            -3.5288078396175737,
            41.835226021104845,
            -3.5288078396175737,
            41.835226021104845
          ]
        }
      ],
      "bbox": [
        -3.5288078396175737,
        41.835226021104845,
        -3.5288078396175737,
        41.835226021104845
      ]
    },
    "09405": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9322793430781604,
              41.592232999230816
            ]
          },
          "bbox": [
            -3.9322793430781604,
            41.592232999230816,
            -3.9322793430781604,
            41.592232999230816
          ]
        }
      ],
      "bbox": [
        -3.9322793430781604,
        41.592232999230816,
        -3.9322793430781604,
        41.592232999230816
      ]
    },
    "09406": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.709830311965645,
              42.17231123445844
            ]
          },
          "bbox": [
            -3.709830311965645,
            42.17231123445844,
            -3.709830311965645,
            42.17231123445844
          ]
        }
      ],
      "bbox": [
        -3.709830311965645,
        42.17231123445844,
        -3.709830311965645,
        42.17231123445844
      ]
    },
    "09407": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2531360456279166,
              42.292324021141255
            ]
          },
          "bbox": [
            -3.2531360456279166,
            42.292324021141255,
            -3.2531360456279166,
            42.292324021141255
          ]
        }
      ],
      "bbox": [
        -3.2531360456279166,
        42.292324021141255,
        -3.2531360456279166,
        42.292324021141255
      ]
    },
    "09408": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2095062847941085,
              42.58215060884926
            ]
          },
          "bbox": [
            -3.2095062847941085,
            42.58215060884926,
            -3.2095062847941085,
            42.58215060884926
          ]
        }
      ],
      "bbox": [
        -3.2095062847941085,
        42.58215060884926,
        -3.2095062847941085,
        42.58215060884926
      ]
    },
    "09409": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6950879059252375,
              42.8997906413189
            ]
          },
          "bbox": [
            -3.6950879059252375,
            42.8997906413189,
            -3.6950879059252375,
            42.8997906413189
          ]
        }
      ],
      "bbox": [
        -3.6950879059252375,
        42.8997906413189,
        -3.6950879059252375,
        42.8997906413189
      ]
    },
    "09410": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.271004470848748,
              43.11375781212739
            ]
          },
          "bbox": [
            -3.271004470848748,
            43.11375781212739,
            -3.271004470848748,
            43.11375781212739
          ]
        }
      ],
      "bbox": [
        -3.271004470848748,
        43.11375781212739,
        -3.271004470848748,
        43.11375781212739
      ]
    },
    "09411": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.324299105679136,
              42.44252024612935
            ]
          },
          "bbox": [
            -3.324299105679136,
            42.44252024612935,
            -3.324299105679136,
            42.44252024612935
          ]
        }
      ],
      "bbox": [
        -3.324299105679136,
        42.44252024612935,
        -3.324299105679136,
        42.44252024612935
      ]
    },
    "09412": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.241594495604646,
              42.79532806675365
            ]
          },
          "bbox": [
            -3.241594495604646,
            42.79532806675365,
            -3.241594495604646,
            42.79532806675365
          ]
        }
      ],
      "bbox": [
        -3.241594495604646,
        42.79532806675365,
        -3.241594495604646,
        42.79532806675365
      ]
    },
    "09413": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.823278102467256,
              42.96985461228955
            ]
          },
          "bbox": [
            -3.823278102467256,
            42.96985461228955,
            -3.823278102467256,
            42.96985461228955
          ]
        }
      ],
      "bbox": [
        -3.823278102467256,
        42.96985461228955,
        -3.823278102467256,
        42.96985461228955
      ]
    },
    "09414": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.142138169708252,
              42.09478884158222
            ]
          },
          "bbox": [
            -3.142138169708252,
            42.09478884158222,
            -3.142138169708252,
            42.09478884158222
          ]
        }
      ],
      "bbox": [
        -3.142138169708252,
        42.09478884158222,
        -3.142138169708252,
        42.09478884158222
      ]
    },
    "09415": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.081062171719355,
              42.72380363221386
            ]
          },
          "bbox": [
            -4.081062171719355,
            42.72380363221386,
            -4.081062171719355,
            42.72380363221386
          ]
        }
      ],
      "bbox": [
        -4.081062171719355,
        42.72380363221386,
        -4.081062171719355,
        42.72380363221386
      ]
    },
    "09416": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7495747633022014,
              42.85857577018493
            ]
          },
          "bbox": [
            -3.7495747633022014,
            42.85857577018493,
            -3.7495747633022014,
            42.85857577018493
          ]
        }
      ],
      "bbox": [
        -3.7495747633022014,
        42.85857577018493,
        -3.7495747633022014,
        42.85857577018493
      ]
    },
    "09417": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.155330168280325,
              42.185671010585445
            ]
          },
          "bbox": [
            -4.155330168280325,
            42.185671010585445,
            -4.155330168280325,
            42.185671010585445
          ]
        }
      ],
      "bbox": [
        -4.155330168280325,
        42.185671010585445,
        -4.155330168280325,
        42.185671010585445
      ]
    },
    "09418": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.073309681406776,
              42.120629852173856
            ]
          },
          "bbox": [
            -4.073309681406776,
            42.120629852173856,
            -4.073309681406776,
            42.120629852173856
          ]
        }
      ],
      "bbox": [
        -4.073309681406776,
        42.120629852173856,
        -4.073309681406776,
        42.120629852173856
      ]
    },
    "09419": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1227128381661537,
              42.57742723910613
            ]
          },
          "bbox": [
            -3.1227128381661537,
            42.57742723910613,
            -3.1227128381661537,
            42.57742723910613
          ]
        }
      ],
      "bbox": [
        -3.1227128381661537,
        42.57742723910613,
        -3.1227128381661537,
        42.57742723910613
      ]
    },
    "09421": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.488187166983632,
              41.62112989000602
            ]
          },
          "bbox": [
            -3.488187166983632,
            41.62112989000602,
            -3.488187166983632,
            41.62112989000602
          ]
        }
      ],
      "bbox": [
        -3.488187166983632,
        41.62112989000602,
        -3.488187166983632,
        41.62112989000602
      ]
    },
    "09422": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3016460474409004,
              42.632724458746445
            ]
          },
          "bbox": [
            -3.3016460474409004,
            42.632724458746445,
            -3.3016460474409004,
            42.632724458746445
          ]
        }
      ],
      "bbox": [
        -3.3016460474409004,
        42.632724458746445,
        -3.3016460474409004,
        42.632724458746445
      ]
    },
    "09423": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.323411445479877,
              42.616441035805316
            ]
          },
          "bbox": [
            -3.323411445479877,
            42.616441035805316,
            -3.323411445479877,
            42.616441035805316
          ]
        }
      ],
      "bbox": [
        -3.323411445479877,
        42.616441035805316,
        -3.323411445479877,
        42.616441035805316
      ]
    },
    "09424": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.104031459674546,
              42.42005118165225
            ]
          },
          "bbox": [
            -3.104031459674546,
            42.42005118165225,
            -3.104031459674546,
            42.42005118165225
          ]
        }
      ],
      "bbox": [
        -3.104031459674546,
        42.42005118165225,
        -3.104031459674546,
        42.42005118165225
      ]
    },
    "09425": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.075691644217616,
              41.93596495684355
            ]
          },
          "bbox": [
            -3.075691644217616,
            41.93596495684355,
            -3.075691644217616,
            41.93596495684355
          ]
        }
      ],
      "bbox": [
        -3.075691644217616,
        41.93596495684355,
        -3.075691644217616,
        41.93596495684355
      ]
    },
    "09427": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.015962593031353,
              42.55556085298628
            ]
          },
          "bbox": [
            -4.015962593031353,
            42.55556085298628,
            -4.015962593031353,
            42.55556085298628
          ]
        }
      ],
      "bbox": [
        -4.015962593031353,
        42.55556085298628,
        -4.015962593031353,
        42.55556085298628
      ]
    },
    "09428": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.032702653594995,
              41.725939884355334
            ]
          },
          "bbox": [
            -4.032702653594995,
            41.725939884355334,
            -4.032702653594995,
            41.725939884355334
          ]
        }
      ],
      "bbox": [
        -4.032702653594995,
        41.725939884355334,
        -4.032702653594995,
        41.725939884355334
      ]
    },
    "09429": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4103738304563476,
              42.40899562842774
            ]
          },
          "bbox": [
            -3.4103738304563476,
            42.40899562842774,
            -3.4103738304563476,
            42.40899562842774
          ]
        }
      ],
      "bbox": [
        -3.4103738304563476,
        42.40899562842774,
        -3.4103738304563476,
        42.40899562842774
      ]
    },
    "09430": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3923896231708084,
              42.114715967713224
            ]
          },
          "bbox": [
            -3.3923896231708084,
            42.114715967713224,
            -3.3923896231708084,
            42.114715967713224
          ]
        }
      ],
      "bbox": [
        -3.3923896231708084,
        42.114715967713224,
        -3.3923896231708084,
        42.114715967713224
      ]
    },
    "09431": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.312812636730349,
              42.374212554139135
            ]
          },
          "bbox": [
            -3.312812636730349,
            42.374212554139135,
            -3.312812636730349,
            42.374212554139135
          ]
        }
      ],
      "bbox": [
        -3.312812636730349,
        42.374212554139135,
        -3.312812636730349,
        42.374212554139135
      ]
    },
    "09432": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9271945546518063,
              41.92134040413608
            ]
          },
          "bbox": [
            -3.9271945546518063,
            41.92134040413608,
            -3.9271945546518063,
            41.92134040413608
          ]
        }
      ],
      "bbox": [
        -3.9271945546518063,
        41.92134040413608,
        -3.9271945546518063,
        41.92134040413608
      ]
    },
    "09433": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.195563582215319,
              42.348890651196406
            ]
          },
          "bbox": [
            -3.195563582215319,
            42.348890651196406,
            -3.195563582215319,
            42.348890651196406
          ]
        }
      ],
      "bbox": [
        -3.195563582215319,
        42.348890651196406,
        -3.195563582215319,
        42.348890651196406
      ]
    },
    "09434": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.731523311507468,
              42.29901692237898
            ]
          },
          "bbox": [
            -3.731523311507468,
            42.29901692237898,
            -3.731523311507468,
            42.29901692237898
          ]
        }
      ],
      "bbox": [
        -3.731523311507468,
        42.29901692237898,
        -3.731523311507468,
        42.29901692237898
      ]
    },
    "09437": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9118512362262865,
              42.07336623103484
            ]
          },
          "bbox": [
            -3.9118512362262865,
            42.07336623103484,
            -3.9118512362262865,
            42.07336623103484
          ]
        }
      ],
      "bbox": [
        -3.9118512362262865,
        42.07336623103484,
        -3.9118512362262865,
        42.07336623103484
      ]
    },
    "09438": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7564794722964208,
              41.68367499467139
            ]
          },
          "bbox": [
            -3.7564794722964208,
            41.68367499467139,
            -3.7564794722964208,
            41.68367499467139
          ]
        }
      ],
      "bbox": [
        -3.7564794722964208,
        41.68367499467139,
        -3.7564794722964208,
        41.68367499467139
      ]
    },
    "09439": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7679151637695467,
              42.32883301404866
            ]
          },
          "bbox": [
            -3.7679151637695467,
            42.32883301404866,
            -3.7679151637695467,
            42.32883301404866
          ]
        }
      ],
      "bbox": [
        -3.7679151637695467,
        42.32883301404866,
        -3.7679151637695467,
        42.32883301404866
      ]
    },
    "09440": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.626259815439987,
              41.81325177503416
            ]
          },
          "bbox": [
            -3.626259815439987,
            41.81325177503416,
            -3.626259815439987,
            41.81325177503416
          ]
        }
      ],
      "bbox": [
        -3.626259815439987,
        41.81325177503416,
        -3.626259815439987,
        41.81325177503416
      ]
    },
    "09441": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9857903710249545,
              42.24731680824865
            ]
          },
          "bbox": [
            -3.9857903710249545,
            42.24731680824865,
            -3.9857903710249545,
            42.24731680824865
          ]
        }
      ],
      "bbox": [
        -3.9857903710249545,
        42.24731680824865,
        -3.9857903710249545,
        42.24731680824865
      ]
    },
    "09442": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7323826607657535,
              42.06559599377725
            ]
          },
          "bbox": [
            -3.7323826607657535,
            42.06559599377725,
            -3.7323826607657535,
            42.06559599377725
          ]
        }
      ],
      "bbox": [
        -3.7323826607657535,
        42.06559599377725,
        -3.7323826607657535,
        42.06559599377725
      ]
    },
    "09443": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.763575235063478,
              42.108664972701526
            ]
          },
          "bbox": [
            -3.763575235063478,
            42.108664972701526,
            -3.763575235063478,
            42.108664972701526
          ]
        }
      ],
      "bbox": [
        -3.763575235063478,
        42.108664972701526,
        -3.763575235063478,
        42.108664972701526
      ]
    },
    "09444": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.144244324809387,
              42.468522143745716
            ]
          },
          "bbox": [
            -4.144244324809387,
            42.468522143745716,
            -4.144244324809387,
            42.468522143745716
          ]
        }
      ],
      "bbox": [
        -4.144244324809387,
        42.468522143745716,
        -4.144244324809387,
        42.468522143745716
      ]
    },
    "09445": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.262972869594542,
              42.41055780811938
            ]
          },
          "bbox": [
            -3.262972869594542,
            42.41055780811938,
            -3.262972869594542,
            42.41055780811938
          ]
        }
      ],
      "bbox": [
        -3.262972869594542,
        42.41055780811938,
        -3.262972869594542,
        42.41055780811938
      ]
    },
    "09446": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.1491951297864915,
              42.164192287678034
            ]
          },
          "bbox": [
            -4.1491951297864915,
            42.164192287678034,
            -4.1491951297864915,
            42.164192287678034
          ]
        }
      ],
      "bbox": [
        -4.1491951297864915,
        42.164192287678034,
        -4.1491951297864915,
        42.164192287678034
      ]
    },
    "09447": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4096683729002755,
              42.19784965631992
            ]
          },
          "bbox": [
            -3.4096683729002755,
            42.19784965631992,
            -3.4096683729002755,
            42.19784965631992
          ]
        }
      ],
      "bbox": [
        -3.4096683729002755,
        42.19784965631992,
        -3.4096683729002755,
        42.19784965631992
      ]
    },
    "09448": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7796436573479486,
              42.182240723436834
            ]
          },
          "bbox": [
            -3.7796436573479486,
            42.182240723436834,
            -3.7796436573479486,
            42.182240723436834
          ]
        }
      ],
      "bbox": [
        -3.7796436573479486,
        42.182240723436834,
        -3.7796436573479486,
        42.182240723436834
      ]
    },
    "09449": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.9393940048217515,
              42.38335698464094
            ]
          },
          "bbox": [
            -3.9393940048217515,
            42.38335698464094,
            -3.9393940048217515,
            42.38335698464094
          ]
        }
      ],
      "bbox": [
        -3.9393940048217515,
        42.38335698464094,
        -3.9393940048217515,
        42.38335698464094
      ]
    },
    "09450": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.324149200002014,
              41.98437710984378
            ]
          },
          "bbox": [
            -3.324149200002014,
            41.98437710984378,
            -3.324149200002014,
            41.98437710984378
          ]
        }
      ],
      "bbox": [
        -3.324149200002014,
        41.98437710984378,
        -3.324149200002014,
        41.98437710984378
      ]
    },
    "09451": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6297527039526942,
              41.741818450547036
            ]
          },
          "bbox": [
            -3.6297527039526942,
            41.741818450547036,
            -3.6297527039526942,
            41.741818450547036
          ]
        }
      ],
      "bbox": [
        -3.6297527039526942,
        41.741818450547036,
        -3.6297527039526942,
        41.741818450547036
      ]
    },
    "09454": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.1626023845619993,
              42.64759685976784
            ]
          },
          "bbox": [
            -3.1626023845619993,
            42.64759685976784,
            -3.1626023845619993,
            42.64759685976784
          ]
        }
      ],
      "bbox": [
        -3.1626023845619993,
        42.64759685976784,
        -3.1626023845619993,
        42.64759685976784
      ]
    },
    "09455": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.085492264778989,
              42.27977374582305
            ]
          },
          "bbox": [
            -4.085492264778989,
            42.27977374582305,
            -4.085492264778989,
            42.27977374582305
          ]
        }
      ],
      "bbox": [
        -4.085492264778989,
        42.27977374582305,
        -4.085492264778989,
        42.27977374582305
      ]
    },
    "09456": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.015628961935452,
              42.23249205844033
            ]
          },
          "bbox": [
            -4.015628961935452,
            42.23249205844033,
            -4.015628961935452,
            42.23249205844033
          ]
        }
      ],
      "bbox": [
        -4.015628961935452,
        42.23249205844033,
        -4.015628961935452,
        42.23249205844033
      ]
    },
    "09458": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7208966374075576,
              42.268793158911066
            ]
          },
          "bbox": [
            -3.7208966374075576,
            42.268793158911066,
            -3.7208966374075576,
            42.268793158911066
          ]
        }
      ],
      "bbox": [
        -3.7208966374075576,
        42.268793158911066,
        -3.7208966374075576,
        42.268793158911066
      ]
    },
    "09460": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.115292764089729,
              42.380846470016124
            ]
          },
          "bbox": [
            -4.115292764089729,
            42.380846470016124,
            -4.115292764089729,
            42.380846470016124
          ]
        }
      ],
      "bbox": [
        -4.115292764089729,
        42.380846470016124,
        -4.115292764089729,
        42.380846470016124
      ]
    },
    "09463": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.3569151028588124,
              42.28271664906784
            ]
          },
          "bbox": [
            -3.3569151028588124,
            42.28271664906784,
            -3.3569151028588124,
            42.28271664906784
          ]
        }
      ],
      "bbox": [
        -3.3569151028588124,
        42.28271664906784,
        -3.3569151028588124,
        42.28271664906784
      ]
    },
    "09464": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8808090866207623,
              41.808414716949464
            ]
          },
          "bbox": [
            -3.8808090866207623,
            41.808414716949464,
            -3.8808090866207623,
            41.808414716949464
          ]
        }
      ],
      "bbox": [
        -3.8808090866207623,
        41.808414716949464,
        -3.8808090866207623,
        41.808414716949464
      ]
    },
    "09466": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.840377510536425,
              42.140397733892364
            ]
          },
          "bbox": [
            -3.840377510536425,
            42.140397733892364,
            -3.840377510536425,
            42.140397733892364
          ]
        }
      ],
      "bbox": [
        -3.840377510536425,
        42.140397733892364,
        -3.840377510536425,
        42.140397733892364
      ]
    },
    "09467": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.049607959883907,
              42.150325737755836
            ]
          },
          "bbox": [
            -4.049607959883907,
            42.150325737755836,
            -4.049607959883907,
            42.150325737755836
          ]
        }
      ],
      "bbox": [
        -4.049607959883907,
        42.150325737755836,
        -4.049607959883907,
        42.150325737755836
      ]
    },
    "09471": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6403459884198766,
              42.3976656176042
            ]
          },
          "bbox": [
            -3.6403459884198766,
            42.3976656176042,
            -3.6403459884198766,
            42.3976656176042
          ]
        }
      ],
      "bbox": [
        -3.6403459884198766,
        42.3976656176042,
        -3.6403459884198766,
        42.3976656176042
      ]
    },
    "09472": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.022630690383822,
              42.193246590447515
            ]
          },
          "bbox": [
            -4.022630690383822,
            42.193246590447515,
            -4.022630690383822,
            42.193246590447515
          ]
        }
      ],
      "bbox": [
        -4.022630690383822,
        42.193246590447515,
        -4.022630690383822,
        42.193246590447515
      ]
    },
    "09473": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.023078547631323,
              42.466307255305125
            ]
          },
          "bbox": [
            -4.023078547631323,
            42.466307255305125,
            -4.023078547631323,
            42.466307255305125
          ]
        }
      ],
      "bbox": [
        -4.023078547631323,
        42.466307255305125,
        -4.023078547631323,
        42.466307255305125
      ]
    },
    "09476": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.4475021340364878,
              42.172330751571025
            ]
          },
          "bbox": [
            -3.4475021340364878,
            42.172330751571025,
            -3.4475021340364878,
            42.172330751571025
          ]
        }
      ],
      "bbox": [
        -3.4475021340364878,
        42.172330751571025,
        -3.4475021340364878,
        42.172330751571025
      ]
    },
    "09478": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.274255806991937,
              42.10592780099429
            ]
          },
          "bbox": [
            -3.274255806991937,
            42.10592780099429,
            -3.274255806991937,
            42.10592780099429
          ]
        }
      ],
      "bbox": [
        -3.274255806991937,
        42.10592780099429,
        -3.274255806991937,
        42.10592780099429
      ]
    },
    "09480": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8334025930905287,
              42.101454864915176
            ]
          },
          "bbox": [
            -3.8334025930905287,
            42.101454864915176,
            -3.8334025930905287,
            42.101454864915176
          ]
        }
      ],
      "bbox": [
        -3.8334025930905287,
        42.101454864915176,
        -3.8334025930905287,
        42.101454864915176
      ]
    },
    "09482": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -4.256944622004014,
              42.5428709834895
            ]
          },
          "bbox": [
            -4.256944622004014,
            42.5428709834895,
            -4.256944622004014,
            42.5428709834895
          ]
        }
      ],
      "bbox": [
        -4.256944622004014,
        42.5428709834895,
        -4.256944622004014,
        42.5428709834895
      ]
    },
    "09483": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.551493698187797,
              41.68147354665507
            ]
          },
          "bbox": [
            -3.551493698187797,
            41.68147354665507,
            -3.551493698187797,
            41.68147354665507
          ]
        }
      ],
      "bbox": [
        -3.551493698187797,
        41.68147354665507,
        -3.551493698187797,
        41.68147354665507
      ]
    },
    "09485": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2238320568186443,
              42.603542848990756
            ]
          },
          "bbox": [
            -3.2238320568186443,
            42.603542848990756,
            -3.2238320568186443,
            42.603542848990756
          ]
        }
      ],
      "bbox": [
        -3.2238320568186443,
        42.603542848990756,
        -3.2238320568186443,
        42.603542848990756
      ]
    },
    "09901": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6413254295808595,
              42.042254871236516
            ]
          },
          "bbox": [
            -3.6413254295808595,
            42.042254871236516,
            -3.6413254295808595,
            42.042254871236516
          ]
        }
      ],
      "bbox": [
        -3.6413254295808595,
        42.042254871236516,
        -3.6413254295808595,
        42.042254871236516
      ]
    },
    "09902": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.8180336593815967,
              42.480055456998016
            ]
          },
          "bbox": [
            -3.8180336593815967,
            42.480055456998016,
            -3.8180336593815967,
            42.480055456998016
          ]
        }
      ],
      "bbox": [
        -3.8180336593815967,
        42.480055456998016,
        -3.8180336593815967,
        42.480055456998016
      ]
    },
    "09903": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.574421804239425,
              42.938403382576446
            ]
          },
          "bbox": [
            -3.574421804239425,
            42.938403382576446,
            -3.574421804239425,
            42.938403382576446
          ]
        }
      ],
      "bbox": [
        -3.574421804239425,
        42.938403382576446,
        -3.574421804239425,
        42.938403382576446
      ]
    },
    "09904": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.5833702087153476,
              42.47277798963201
            ]
          },
          "bbox": [
            -3.5833702087153476,
            42.47277798963201,
            -3.5833702087153476,
            42.47277798963201
          ]
        }
      ],
      "bbox": [
        -3.5833702087153476,
        42.47277798963201,
        -3.5833702087153476,
        42.47277798963201
      ]
    },
    "09905": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7442289592481637,
              42.731414216652894
            ]
          },
          "bbox": [
            -3.7442289592481637,
            42.731414216652894,
            -3.7442289592481637,
            42.731414216652894
          ]
        }
      ],
      "bbox": [
        -3.7442289592481637,
        42.731414216652894,
        -3.7442289592481637,
        42.731414216652894
      ]
    },
    "09906": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.6716229155782556,
              42.5565195499314
            ]
          },
          "bbox": [
            -3.6716229155782556,
            42.5565195499314,
            -3.6716229155782556,
            42.5565195499314
          ]
        }
      ],
      "bbox": [
        -3.6716229155782556,
        42.5565195499314,
        -3.6716229155782556,
        42.5565195499314
      ]
    },
    "09907": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.7536903931594474,
              42.403048315587114
            ]
          },
          "bbox": [
            -3.7536903931594474,
            42.403048315587114,
            -3.7536903931594474,
            42.403048315587114
          ]
        }
      ],
      "bbox": [
        -3.7536903931594474,
        42.403048315587114,
        -3.7536903931594474,
        42.403048315587114
      ]
    },
    "09908": {
      "type": "FeatureCollection",
      "features": [
        {
          "id": "0",
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              -3.2219260895088206,
              42.98756899511753
            ]
          },
          "bbox": [
            -3.2219260895088206,
            42.98756899511753,
            -3.2219260895088206,
            42.98756899511753
          ]
        }
      ],
      "bbox": [
        -3.2219260895088206,
        42.98756899511753,
        -3.2219260895088206,
        42.98756899511753
      ]
    }
  }

export default regionsLimits