import { Overlay } from "ol";
import Icon from 'ol/style/Icon.js';
import Feature from 'ol/Feature.js';
import BingMaps from 'ol/source/BingMaps.js';
import WMTS from 'ol/source/WMTS.js';
//import WMS from "ol/layer/"
import TileWMS from 'ol/source/TileWMS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import Map from "ol/Map";
import View from "ol/View";
//import Attribution from "ol/at"
import { Control, ScaleLine, defaults } from "ol/control";
import { createEmpty, extend, getCenter, getWidth, getTopLeft } from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { get as getProjection, get as projget } from "ol/proj";
import { register } from "ol/proj/proj4";
import { Cluster, Vector as VectorSource } from "ol/source";
import { Fill, Stroke, Text, Style } from "ol/style";
import proj4 from "proj4";
import XYZ from "ol/source/XYZ";
import CircleStyle from "ol/style/Circle";
import OSM from 'ol/source/OSM';
import { Point } from 'ol/geom';
import { Polygon } from 'ol/geom';
import { isMobile } from 'react-device-detect';
import LayerGroup from 'ol/layer/Group.js';

var geolocation_first_time = 0;

const startTime = performance.now();
export function calculeCenterOfFeatures(combinedExtent, polygonList, pointList) {
  //console.log("polygonList", polygonList);
  //console.log("pointList", pointList);

  if (polygonList == null || polygonList == []) {
    polygonList = []

  }
  if (pointList == null) {
    pointList = []
  }
  let geometryList = [...polygonList, ...pointList];
  // for (let i = 0; i < pointList.length; i++) {
  //     polygonList.push(pointList[i]); // Agrega cada elemento de pointList a geometryList
  //   }
  combinedExtent = geometryList.reduce((extent, feature) => {
    if (feature == null) {
      return extent;
    }
    const geometry = feature.getGeometry();
    // Comprobar si la geometría existe y tiene el método getExtent
    if (geometry !== null) {
      try {
        return extend(extent, geometry.getExtent());
      } catch (e) {
        return extent;
      }
    }

    // Si la geometría es inválida, simplemente retorna el extent actual
    return extent;
  }, createEmpty());
  let combinedCenter = getCenter(combinedExtent);
  return { combinedExtent, combinedCenter };
}


export function getSourceBaseMapFromName(selectedValue) {
  let sources = [];
  // https://idee.es/servicios-teselas
  if (selectedValue === 'osm') {
    // Cambiar al mapa base OSM
    sources = [new OSM()];
  } else if (selectedValue === 'voyayer') {
    // Cambiar a tu mapa base personalizado (ajusta según tus necesidades)
    sources.push(new XYZ({
      url: "http://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
    }));
  } else if (selectedValue === 'light_all') {
    // Cambiar a tu mapa base personalizado (ajusta según tus necesidades)
    sources.push(new XYZ({
      url: "http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
    }));

  } else if (selectedValue === "arcgis") {
    sources.push(new XYZ({
      url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
    }))
  } else if (selectedValue === "PNOAmaximaActualidad") {
    sources.push(new XYZ({
      url: "https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg",
    }))


    //"https://www.ign.es/wms-inspire/pnoa-ma?request=GetCapabilities&service=WMS"
  }else if(selectedValue == "CALIDAD_AIRE"){
    var projection = projget('EPSG:4326');
    var projectionExtent = projection.getExtent();
    var size = getWidth(projectionExtent) / 512;
    var resolutions = new Array(18);
		var matrixIds = new Array(18);
		for (var z = 0; z < 18; ++z) {
		  // generate resolutions and matrixIds arrays for this WMTS
		  resolutions[z] = size / Math.pow(2, z);
		  matrixIds[z] = "EPSG:4326:" + z;
		  //matrixIds[z] = z;
		}
    const source = new TileWMS({
      //url :'https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx',
      url: 'https://wms.mapama.gob.es/sig/EvaluacionAmbiental/CalidadAire/RedSeguimientoCA/wms.aspx',
      params: {
        'LAYERS': 'EnvironmentalMonitoringFacility',  // Especifica las capas que deseas
        'FORMAT': 'image/png',  // Formato de la imagen
        'TILED': true,
        //'TRANSPARENT': 'FALSE',  // Transparencia de la capa
        'SRS': 'EPSG:3857',     // Sistema de referencia espacial, asegúrate de que corresponda
        'WIDTH': 256,           // Ancho del tile en píxeles
        'HEIGHT': 256           // Altura del tile en píxeles
      },
     
      projection:"EPSG:3857",
      serverType: 'geoserver',  // Ajusta según el tipo de servidor WMS
      crossOrigin: 'anonymous'
    });
    sources.push(source)
  //   sources.push(new WMTS({
  //     url: 'https://wms.mapama.gob.es/sig/EvaluacionAmbiental/CalidadAire/RedSeguimientoCA/wms.aspx',
  //     layer: 'EF.EnvironmentalMonitoringFacilities',
  //     //matrixSet: 'EPSG:25831',
  //     matrixSet: 'EPSG:4326',
  //     format: 'image/png',
  //     projection: projection,
  //     tileGrid: new WMTSTileGrid({
  //       origin: getTopLeft(projectionExtent),
  //       resolutions: resolutions,
  //       matrixIds: matrixIds
  //     }),
  //     //style: 'default'
  // }));
   
  }else if(selectedValue == "RED_BOSQUES"){
    var projection = projget('EPSG:4326');
    var projectionExtent = projection.getExtent();
    var size = getWidth(projectionExtent) / 512;
    var resolutions = new Array(18);
		var matrixIds = new Array(18);
		for (var z = 0; z < 18; ++z) {
		  // generate resolutions and matrixIds arrays for this WMTS
		  resolutions[z] = size / Math.pow(2, z);
		  matrixIds[z] = "EPSG:4326:" + z;
		  //matrixIds[z] = z;
		}
    sources.push(new WMTS({
      url: 'https://wmts.mapama.gob.es/sig/biodiversidad/gwc/service/wmts',
      layer: 'MFE',
      //matrixSet: 'EPSG:25831',
      matrixSet: 'EPSG:4326',
      format: 'image/png',
      projection: projection,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds
      }),
      //style: 'default'
  }));
   
  }else if(selectedValue == "AGUA_MINISTERIO"){
    var projection = projget('EPSG:4326');
    var projectionExtent = projection.getExtent();
    var size = getWidth(projectionExtent) / 512;
    var resolutions = new Array(18);
		var matrixIds = new Array(18);
		for (var z = 0; z < 18; ++z) {
		  // generate resolutions and matrixIds arrays for this WMTS
		  resolutions[z] = size / Math.pow(2, z);
		  matrixIds[z] = "EPSG:4326:" + z;
		  //matrixIds[z] = z;
		}
    sources.push(new WMTS({
        //  attributions: [attribution],
        url:'https://wmts.mapama.gob.es/sig/agua/wmts',
        layer:'rios_pfaf_cx',
        matrixSet: 'EPSG:4326',
        format: 'image/png',
        projection: projection,
        tileGrid: new WMTSTileGrid({
          origin: getTopLeft(projectionExtent),
          resolutions: resolutions,
          matrixIds: matrixIds
        }),
        style: 'default'
    }))
   
  }else if(selectedValue == "CATASTRO"){
    // https://github.com/anthonyjba/OpenLayer/blob/master/CATASTRO_3785.html
    const sourceCatastro = new TileWMS({
      //url :'https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx',
      url: 'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx',
      params: {
        'LAYERS': 'Catastro',  // Especifica las capas que deseas
        'FORMAT': 'image/png',  // Formato de la imagen
        'TILED': true,
        'TRANSPARENT': 'FALSE',  // Transparencia de la capa
        'SRS': 'EPSG:3857',     // Sistema de referencia espacial, asegúrate de que corresponda
        'WIDTH': 256,           // Ancho del tile en píxeles
        'HEIGHT': 256           // Altura del tile en píxeles
      },
     
      projection:"EPSG:3857",
      serverType: 'geoserver',  // Ajusta según el tipo de servidor WMS
      crossOrigin: 'anonymous'
    });
    const sourcePNOA =  new XYZ({
      url: "https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg",
    })
   // source = source2
    sources = [sourceCatastro];
  }else if(selectedValue == "PNOAWMTS"){
    var projection = projget('EPSG:4326');
    var projectionExtent = projection.getExtent();
    var size = getWidth(projectionExtent) / 512;
    var resolutions = new Array(18);
		var matrixIds = new Array(18);
		for (var z = 0; z < 18; ++z) {
		  // generate resolutions and matrixIds arrays for this WMTS
		  resolutions[z] = size / Math.pow(2, z);
		  matrixIds[z] = "EPSG:4326:" + z;
		  //matrixIds[z] = z;
		}
    // var attribution = new Attribution({
		// 	html: 'Teselas de PNOA cedido por © Instituto Geográfico Nacional de España'
		// });
    sources.push(new WMTS({
        //  attributions: [attribution],
        url: 'http://www.ign.es/wmts/pnoa-ma',
        layer: 'OI.OrthoimageCoverage',
        matrixSet: 'EPSG:4326',
        //matrixSet: 'EPSG:3857',
        format: 'image/png',
        projection: projection,
        tileGrid: new WMTSTileGrid({
          origin: getTopLeft(projectionExtent),
          resolutions: resolutions,

          matrixIds: matrixIds
        }),
    style: 'default',
    crossOrigin: 'anonymous'
    }))
  }else if(selectedValue == "PNOARaster"){
    sources.push(new XYZ({
      url: "https://tms-mapa-raster.ign.es/1.0.0/mapa-raster/{z}/{x}/{-y}.jpeg",
    }));
    

  }else if(selectedValue == "PNOABaseTodo"){
    sources.push(new XYZ({
      url: "https://tms-ign-base.idee.es/1.0.0/IGNBaseTodo/{z}/{x}/{-y}.jpeg",
    }));
    
 } else if (selectedValue == "bing_AerialWithLabelsOnDemand") {
    //https://openlayers.org/en/latest/examples/bing-maps.html
    sources.push(new BingMaps({
      key: 'Your Bing Maps Key from https://www.bingmapsportal.com/ here',
      imagerySet: "AerialWithLabelsOnDemand",
      //crossOrigin:"https://www.asbestos.ai",
      projection: "EPSG:4326",
      key: "ArEHaClBNxd5sNIvqtthamdnShR1GQkYSOxPMgNiQmn2d4fCXxWQ0Hdb2l8mUzI3",//"Ag97niDOM4O5tEEHeg9Myjw2L5UYWD1kS1ptgFdn7wJ2C5YFL5S7W5dosgx51LFU"
      placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
    }));
  } else if (selectedValue == "bing_OrdnanceSurvey") {
    //https://openlayers.org/en/latest/examples/bing-maps.html
    sources.push(new BingMaps({
      key: 'Your Bing Maps Key from https://www.bingmapsportal.com/ here',
      imagerySet: "OrdnanceSurvey",
      //crossOrigin:"https://www.asbestos.ai",
      projection: "EPSG:4326",
      key: "ArEHaClBNxd5sNIvqtthamdnShR1GQkYSOxPMgNiQmn2d4fCXxWQ0Hdb2l8mUzI3",//"Ag97niDOM4O5tEEHeg9Myjw2L5UYWD1kS1ptgFdn7wJ2C5YFL5S7W5dosgx51LFU"
      placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
    }));
  }
  return sources;
}
export function initProjections() {
  const epsg25830 = '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
  const epsg25829 = '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
  const epsg25831 = '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
  const epsg4326 = '+proj=longlat +datum=WGS84 +no_defs +type=crs';
  const epsg4083 = "+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
  const epsg31981 = "+proj=utm +zone=21 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
  proj4.defs('EPSG:25830', epsg25830);
  proj4.defs('EPSG:25829', epsg25829);
  proj4.defs('EPSG:25831', epsg25831);
  proj4.defs('EPSG:4326', epsg4326);
  proj4.defs('EPSG:4083', epsg4083);
  proj4.defs('EPSG:4083', epsg31981);

  register(proj4);
  getProjection('EPSG:25830').setExtent([-1300000.000000, 3500000.000000, 1200000.000000, 5500000.000000]);
}


export function getFeatureType(feature) {
  let type = "Polygon";

  
  if(feature && "values_" in feature && "features" in feature["values_"]){
    type = "Features"
  }
  // if(feature && "values_" in feature && "geometry" in feature["values_"] && "flatCoordinates" in feature["values_"]["geometry"] && feature["values_"]["geometry"]["flatCoordinates"].length == 2){
  //     type = "Point"
  // }
  if (feature && "values_" in feature && "geometry" in feature["values_"] && "geometry" in feature["values_"] && "type" in feature["values_"].geometry) {
    type = feature["values_"].geometry.type;
  }
  if(feature && "values_" in feature && "geometry" in feature["values_"] && feature["values_"]["geometry"] instanceof Point){
    type = "Point"
  }else if(feature && "values_" in feature && "geometry" in feature["values_"] && feature["values_"]["geometry"] instanceof Polygon){

      type = "Polygon"
  }
  if (feature && "values_" in feature && "features" in feature["values_"] && Array.isArray(feature["values_"]["features"]) && feature["values_"]["features"].length > 0 && "values_" in feature["values_"]["features"][0]
    && "geometry" in feature["values_"]["features"][0]["values_"] && feature["values_"]["features"][0].getGeometry().getType() == "Point") {
    type = "Point"
  }
  if (feature && "values_" in feature && "features" in feature["values_"] && Array.isArray(feature["values_"]["features"]) && feature["values_"]["features"].length > 0 && "values_" in feature["values_"]["features"][0]
    && "geometry" in feature["values_"]["features"][0]["values_"] && feature["values_"]["features"][0].getGeometry().getType() == "Polygon") {
    type = "Polygon"
  }
  //console.log("Featuretype", type  ,  feature)


  return type;
}


export function handleClickNoFeature(map, isGetCoordinatesOnClickEnabled, evt, pointMarker, assetsBasePath, drawEnabled) {
  console.log("handleClickNoFeature - ", isGetCoordinatesOnClickEnabled, drawEnabled)
  if (isGetCoordinatesOnClickEnabled ) {
    evt.stopPropagation();
    const pixel = map.getEventPixel(evt.originalEvent);
    var coordinates = map.getCoordinateFromPixel(pixel);
    console.log("Enviando mapclick event", coordinates);
    var evt = new CustomEvent("mapClick", { "detail": { "coordinates": coordinates, "location": "" } });
    document.dispatchEvent(evt);
    addMarkerToMap(map, coordinates, pointMarker, assetsBasePath);
    return
  }
  if(drawEnabled){
    evt.stopPropagation();
    const pixel = map.getEventPixel(evt.originalEvent);
    var coordinates = map.getCoordinateFromPixel(pixel);
    console.log("Enviando mapclick event", coordinates);
    var evt = new CustomEvent("mapClick", { "detail": { "coordinates": coordinates, "location": "" } });
    document.dispatchEvent(evt);
    return
  }
  const view = map.getView();
  const currentZoom = view.getZoom();
  const maxZoom = 20; // Puedes ajustar este valor según tus necesidades

  // Si el zoom actual es menor que el máximo permitido, hacemos un pequeño zoom in
  const newZoom = currentZoom < maxZoom ? currentZoom + 1 : currentZoom;

  // Anima la vista para centrarla en las coordenadas del clic y ajustar el zoom
  view.animate({
    center: evt.coordinate,
    zoom: newZoom,
    duration: 350 // Duración de la animación en milisegundos, ajusta según necesites
  });
}
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}
export function addMarkerToMap(map, coordinates, pointMarker, assetsBasePath) {
  if (pointMarker[0]) {
    map.removeLayer(pointMarker[0]);
  }
  let iconFeature, vectorSource;
  let basePath = assetsBasePath ? assetsBasePath : process.env.PUBLIC_URL + "/images/"
  let iconStyle = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      scale: 0.02,
      src: basePath + "marker.png"
    })
  });
  //     size: [36, 36Z],
  //     //scale: 0.5,
  //     opacity: 0.75,
  //     src: basePath+"marker.png"
  //   })
  // });
  iconFeature = new Feature({
    geometry: new Point(coordinates)
  });
  iconFeature.setStyle(iconStyle);

  vectorSource = new VectorSource({
    features: [iconFeature]
  });

  pointMarker[0] = new VectorLayer({
    source: vectorSource
  });
  map.addLayer(pointMarker[0]);
}
export async function handleMapClick(map, evt, overlay, setModalBody, setIsModalOpen, layer, mustShowOnClickFeature, isGetCoordinatesOnClickEnabled, pointMarker, assetsBasePath, drawEnabled) {
  // Recupera todas las features en la posición clickeada
  //console.log("Map Click")
  const zoom = map?.getView().getZoom();
  console.log("Click on map info zoom:", zoom, "layer.breakpoint_zoom:", layer.breakpoint_zoom, "drawIsEnabled:", drawEnabled)

  if (zoom < layer.breakpoint_zoom | drawEnabled) {
    //console.log("handleClickNoFeature")
    handleClickNoFeature(map, isGetCoordinatesOnClickEnabled, evt, pointMarker, assetsBasePath, drawEnabled);
    return
  }
  evt.stopPropagation();
  const pixel = map.getEventPixel(evt.originalEvent);
  var pointClicked = map.getCoordinateFromPixel(pixel);

  let feature = map.forEachFeatureAtPixel(pixel, function (feature) {
    return feature;
  });
  //console.log("There is feature?", feature)
  if (feature) {
    if ("values_" in feature && "features" in feature["values_"]) {
      if (feature["values_"]["features"].length == 1) {
        feature = feature["values_"]["features"][0];
      } else {
        //hay más de una feature
        let features = feature["values_"]["features"];
        const sameLocation = features.every((f) => {
          return (
            f.getGeometry().getCoordinates()[0] === features[0].getGeometry().getCoordinates()[0] &&
            f.getGeometry().getCoordinates()[1] === features[0].getGeometry().getCoordinates()[1]
          );
        });
        if (sameLocation) {
          feature = feature["values_"]["features"][0];
        } else {
          if (feature["values_"]["features"].length < 10) { // lógica para múltiples features en el click. Más cercana.
            let features = feature["values_"]["features"]
            features.sort((a, b) => {
              const pa = getCenter(a.getGeometry().getExtent());
              const pb = getCenter(b.getGeometry().getExtent());
              const da = getDistanceFromLatLonInKm(pa[0], pointClicked[0], pa[1], pointClicked[1]);
              const db = getDistanceFromLatLonInKm(pb[0], pointClicked[0], pb[1], pointClicked[1]);
              //console.log("Check order",a.getGeometry(),b.getGeometry(), pa, pb,pointClicked,  da, db,  da > db )
              return da - db;
            })
            //  console.log("features", features);
            feature = features[0];
          }
          // no son la misma feature

        }
      }
    }
   // console.log("Feature click", mustShowOnClickFeature, feature)
    if (mustShowOnClickFeature) {
      const Body = layer.getModalBody;
      const result =<Body feature={feature}/>;
      //Promise.resolve(r).then(result => {
        if (result && result != <></>) {
          //console.log("Pre modal body", result)
          setModalBody(result);
          setIsModalOpen(true);
        } else if (!isGetCoordinatesOnClickEnabled) {
          handleClickNoFeature(map, isGetCoordinatesOnClickEnabled, evt, pointMarker, assetsBasePath, drawEnabled);
        }
      //}

      //)

    } else {
      console.log("Enviando evento featureClick",{ "detail": { "feature": feature } });

      var evt = new CustomEvent("featureClick", { "detail": { "feature": feature } });
      document.dispatchEvent(evt);
    }





  } else {
    handleClickNoFeature(map, isGetCoordinatesOnClickEnabled, evt, pointMarker, assetsBasePath, drawEnabled);
  }
}

// export function getVectorLayerFromPolygonVectorSources(vectorLayers, vectorSources, allPolygons, strokeColor,  strokeWidth, fillColor, fillOpacity, getBgColorFromFeature) {

//   vectorLayers = vectorSources.map((source, index) => {
//     let bgColor = getBgColorFromFeatureGeneric(allPolygons, index, getBgColorFromFeature);
//     let vl =  new VectorLayer({
//       source: source,
//       projection: source.projection,
//       style: new Style({
//         stroke: new Stroke({
//           color: strokeColor || DEFAULT_STROKE_COLOR,
//           width: strokeWidth || DEFAULT_STROKE_WIDTH,
//         }),
//          fill: new Fill({
//            color: `rgba(${bgColor || fillColor || "0, 0, 0"}, ${fillOpacity || 1})`,
//          }),
//       }),
//     });
//     return vl;
//   });
//   return vectorLayers;
// }

// const obtenerVectorSources = (map) => {
//   const vectorSources = [];
//   map.getLayers().forEach(layer => {
//     if (layer instanceof VectorLayer) {
//       vectorSources.push(layer.getSource());
//     }
//   });
//   return vectorSources;
// };




export function tooltipInit(tooltipRef) {
  return new Overlay({
    element: tooltipRef.current,
    positioning: "bottom-center",
    stopEvent: false,
    offset: [0, -10],
  });
}
export function addGeolocation(map, geolocation, positionFeature, accuracyFeature, geoPositionPoint, setGeoPositionPoint, geoAccuraccyGeometry, setGeoAccuraccyGeometry) {
  // geolocation.setTracking(true);
  // let positionFeature = new Feature();
  // let accuracyFeature = new Feature();
  if (geoAccuraccyGeometry) {
    accuracyFeature.setGeometry(geoAccuraccyGeometry);
  }
  // try {
  //   geolocation.un("change:position")
  // } catch (e) {
  //   console.warn("Error en el unlisten de geoposicion")
  // }
 
  positionFeature.setStyle(
    new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC',
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2,
        }),
      }),
    })
  );
  geolocation.on('error', (error) => {
    console.log("geoPosition ERROR - Problema obteniendo la geolocalización", error)

  });
  if (geoPositionPoint) {
    positionFeature.setGeometry(geoPositionPoint ? new Point(geoPositionPoint) : null);
  }
  return new VectorLayer({
    map: map,
    source: new VectorSource({
      features: [accuracyFeature, positionFeature],
    }),
  });
}
export function overlayInit(popupRef) {
  return new Overlay({
    element: popupRef.current,
    autoPan: true,
    autoPanAnimation: {
      duration: 250
    }
  });
}
export function mapInit(mapRef, tooltip, overlay, baseLayer, polygonVectorLayers, pointVectorLayers, combinedCenter, projection, scaleLineControl, drawEnabled, drawSource, oldZoom = false, oldCoordinates = false) {
  //console.log("pointVectorLayers", pointVectorLayers, pointVectorLayers.iterable)
  // console.log("baseLayer", baseLayer, baseLayer.iterable)
  //console.log("polygonVectorLayers", polygonVectorLayers, polygonVectorLayers.iterable)
  let drawVector = [];
  if (drawEnabled) {
    drawVector = [new VectorLayer({
      source: drawSource,
    })];
  }
  let map = new Map({
    target: mapRef.current,
    overlays: [tooltip, overlay],
    //layers: [baseLayer, ...(polygonVectorLayers  ? polygonVectorLayers : []), ...(pointVectorLayers   ? pointVectorLayers : []), ...(drawEnabled ? drawVector : [])],
    layers: [baseLayer, ...(polygonVectorLayers ? polygonVectorLayers : []), ...(pointVectorLayers ? pointVectorLayers : []), ...(drawEnabled ? drawVector : [])],
    view: new View({
      center: oldCoordinates || combinedCenter,
      projection: projection,
      zoom: oldZoom || polygonVectorLayers || pointVectorLayers ? 10 : 5,
    }),
    controls: defaults({
      zoomOptions: {
        className: 'ol-zoom',
        target: undefined,
        position: 'bottom-right' // Esta opción no es estándar en OpenLayers pero te guiaré sobre cómo implementarla a continuación.
      }
    }),
  });
  map.addControl(scaleLineControl);


  map.addOverlay(overlay);
  return map;
}



export function readGeoJsons(geojsons, projection = "EPSG:4326") {
  const parsedGeojsons = JSON.parse(geojsons); // Parsear el string a lista de objetos
  const vectorSources = [];
  const markerSources = [];
  const allPolygons = [];
  const allPoints = [];
  let crs_readed = projection;
  // Recorrer y parsear cada geojson y añadirlo a vectorSources y allPolygons
  for (let readedFeature of parsedGeojsons) {
    let features = null;
    if ("features" in readedFeature) {

      // if("crs" in readedFeature && "properties" in readedFeature["crs"] && "name" in readedFeature["crs"]["properties"]){
      //   let splitted = readedFeature["crs"]["properties"]["name"].split("EPSG::");
      //   if(splitted.length>1){
      //     crs_readed = "EPSG:" + splitted[1]
      //   }

      // }
      for (let feature of readedFeature["features"]) {

        const { features, geometryType } = processFeature(feature, crs_readed)
        //console.log("Geometry type : ", geometryType)
        if (geometryType == "Polygon" || geometryType == "MultiPolygon") {
          vectorSources.push(
            new VectorSource({
              features: features,
            })
          );
          //console.log("Tooltip", g, features)
          allPolygons.push(...features); //.flat()?
        } else if (geometryType == "Point") {
          
          markerSources.push(new VectorSource({
            features: features,
          }))
          allPoints.push(...features);
        }
      }
    } else {
      const { features, geometryType } = processFeature(readedFeature)
      if (geometryType == "Polygon" || geometryType == "MultiPolygon") {
        vectorSources.push(
          new VectorSource({
            features: features,
          })
        );
        //console.log("Tooltip", g, features)
        allPolygons.push(...features); //.flat()?
      } else if (geometryType == "Point") {
        markerSources.push(new VectorSource({
          features: features,
        }))
        allPoints.push(...features);
      }
    }

  }
  return { allPolygons, allPoints, vectorSources, markerSources };
}
export function processFeature(feature, projection) {
  let features = null;
  let geometryType = "Polygon";
  if (feature && "geometry" in feature && "type" in feature.geometry) {
    geometryType = feature.geometry.type;
  }
  if(!Object.keys(feature).includes("type") ){
    feature["type"] = "Feature";
  }
  if ("properties" in feature) {
    //// console.log("Read geom with coords", feature);
    features = new GeoJSON().readFeatures(feature, {
      dataProjection: feature["properties"]["projection"],
      featureProjection: projection,
      tooltip: feature["properties"]["tooltip"]
    });
  } else {
    // if(Object.keys(feature).includes("features") ) {
    //   for(let f of feature["features"]){
    //     processFeature(f, projection)
    //   }
    // }else{
     
      //console.log("f", feature)
      features = new GeoJSON().readFeatures(feature);
   // }
    //// console.log("Read geom withouth coords", feature);
    
  }
  return { features, geometryType };
}

export function scaleLineInit() {

  return new ScaleLine({
    className: "ol-scale-line",
    units: "metric",
    title: "Escala",
    // bar: true,  // muestra una barra en vez de texto
    // steps: 2,  // número de subdivisiones en la escala (sólo aplica si bar=true)
    // text: true,  // muestra el texto junto con la barra
    minWidth: 64,
  });
}

export function baseLayerInit(selectedValue = "light_all") {
  const styles = [
    "light_all",
    "dark_all",
    "light_nolabels",
    "light_only_labels",
    "dark_nolabels",
    "dark_only_labels",
    "rastertiles/voyager",
    "rastertiles/voyager_nolabels",
    "rastertiles/voyager_only_labels",
    "rastertiles/voyager_labels_unde",
  ];
  // "http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
  // "http://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
  let sourcesBaseMaps = getSourceBaseMapFromName(selectedValue);
  const layers = []
  for(let source of sourcesBaseMaps){
    layers.push(new TileLayer({
      source: source,
      crossOrigin: 'anonymous'
    })

    )
  }
  return new LayerGroup({
    layers: layers,
  });

}

