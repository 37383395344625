
import { renderToString } from 'react-dom/server'
import { Paper, TextField } from '@mui/material';
import {getVectorLayerFromSources, updateLayerStyle, createGeometryStyle, handleMapPointerMove, BREAKPOINT_ZOOM} from "../Styles/DefaultStyles"
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput"
import { useState, useEffect } from "react";
import Button from '@mui/material/Button';

function interpolateColors(color1, color2, steps) {
  var stepFactor = 1 / (steps - 1),
    interpolatedColorArray = [];

  color1 = color1.match(/\d+/g).map(Number);
  color2 = color2.match(/\d+/g).map(Number);

  for (var i = 0; i < steps; i++) {
    interpolatedColorArray.push(
      interpolateColorFactor(color1, color2, stepFactor * i)
    );
  }

  return interpolatedColorArray;
}
function interpolateColorFactor(color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  var result = color1.slice();
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

const ImageWithCheck = ({ src }) => {
  const [imageExists, setImageExists] = useState(null);

  useEffect(() => {
   //("ImageWithCheck Checking image at src:", src);
    const image = new Image();
    image.onload = () => {
     // console.log("Image loaded successfully.");
      setImageExists(true);
    };
    image.onerror = () => {
     // console.log("Failed to load image.");
      setImageExists(false);
    };
    image.src = src;

    // Función de limpieza para cancelar la carga si el componente se desmonta
    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [src]);

  if (imageExists === null) {
    return <></>; // Puede optar por mostrar un spinner de carga aquí
  }

  return (
    <>
      {imageExists ? (
        <div style={{display: "flex", flex: "3 1 320px", maxHeight: "70vh"}}>
          <a href={src} target="blank">
            <img style={{width: "100%", borderRadius: "10px", height: "100%", objectFit: "cover"}} src={src}/>
          </a>
        </div>
      ) : (
        <></> // Puedes cambiar esto por otro elemento visual que indique que la imagen no está disponible
      )}
    </>
  );
};
const legendBoxes = (values) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {values.map((value) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "0.8rem",
                height: "0.8rem",
                backgroundColor: value.color,
                borderRadius: "50%",
              }}
            ></div>
            <div
              style={{
                width: "0.8rem",
              }}
            />
            <label>{value.text}</label>
          </div>
        );
      })}
    </div>
  );
};


const legendGradient = (
  topText,
  bottomText,
  gradientColors,
  centerText = ""
) => {
  const gradientStyle = {
    background: `linear-gradient(${gradientColors.join(", ")})`,
    //background: "rgb(0,0,255)",
    //background: "linear-gradient(0deg, rgba(0,0,255,1) 0%, rgba(9,9,121,1) 0%, rgba(0,0,255,1) 5%, rgba(0,255,0,1) 95%, rgba(0,255,0,1) 100%);",
    opacity: 0.9,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px", // Ajusta según la necesidad
    width: "40px", // Ajusta según la necesidad
  };
  return (
    <div className="legend">
      <div className="legend-text top-text">{topText}</div>
      <div className="legend-container">
        <div style={gradientStyle}>
          <div className="legend-text">{centerText}</div>
        </div>
      </div>
      <div className="legend-text bottom-text">{bottomText}</div>
    </div>
  );
};
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null;
}

const tooltipBeginning = (feature) => {
  if (
    feature &&
    "values_" in feature &&
    "features" in feature.values_ &&
    feature.values_.features.length >= 1 &&
    "beginning" in feature.values_.features[0].values_
  ) {
    return (
      "Fecha de construcción: " +
      feature.values_.features[0].values_["beginning"]
    );
  }
};
var InfoFieldComponent = ({
  _label,
  _data,
  fixed_decimals = false,
  is_date=false,
  backgroundColor = false
}
) => {
  //const [label, setLabel] = useState(_label)
  if (fixed_decimals && _data !== undefined && _data !== null && typeof _data === "number") {
    console.log("_data", _data)
    _data = _data.toFixed(fixed_decimals).toString();
  }
  if(is_date  && _data !== undefined && _data !== null){
    _data = new Date(_data).toISOString().slice(0, 10);
  }
  // const [data, setData] = useState(_data)
  // useEffect(()=>{
  //   setLabel(label)
    
  //   if (fixed_decimals && _data !== undefined && _data !== null && typeof _data === "number") {
  //     console.log("_data", _data)
  //     _data = _data.toFixed(fixed_decimals).toString();
  //   }
  //   if(is_date  && _data !== undefined && _data !== null){
  //     _data = new Date(_data).toISOString().slice(0, 10);
  //   }
  //   setData(_data)
  // }, [_label, _data])
 // console.log(_data);
  
  return (<div style={{
    width: "100%",
    paddingLeft:"5%",paddingBottom:"1rem"}}>
  <TextField
    style={{
      width: "90%",
     // marginTop: "0.8rem",
    }}
   // sx={{style:{width:"100%"}}}
   // size="small"
    label={_label}
    value={"" + _data }
    //readonly
  />
  </div>)
  // return (
  //   <TextField
  //     style={{
  //       width: "100%",
  //       marginTop: "0.8rem",
  //       backgroundColor: backgroundColor,
  //     }}
  //     size="small"
  //     label={label}
  //     value={_data}
  //     readonly
  //   />
  // );
};
var info_field = (
  label,
  _data,
  fixed_decimals = false,
  is_date=false,
  backgroundColor = false
) => {
 // console.log(_data);
  if (fixed_decimals && _data !== undefined && _data !== null) {
    _data = _data.toFixed(fixed_decimals).toString();
  }
  if(is_date  && _data !== undefined && _data !== null){
    _data = new Date(_data).toISOString().slice(0, 10);
  }
  return (<LayerPublicAmianticTextInput
    style={{
      width: "90%",
      paddingLeft:"5%",
     // marginTop: "0.8rem",
      backgroundColor: backgroundColor,
    }}
   // sx={{style:{width:"100%"}}}
   // size="small"
    label={label}
    value={"" + _data }
    //readonly
  />)
  return (
    <TextField
      style={{
        width: "100%",
        marginTop: "0.8rem",
        backgroundColor: backgroundColor,
      }}
      size="small"
      label={label}
      value={_data}
      readonly
    />
  );
};

const FullModalComponent=({ feature})=>{
    const [_feature, setFeature] = useState(feature)
    let d;
   // console.log("_feature", feature)
    if(feature && !"values_" in feature || feature.values_ == undefined){
      d = {}
    }else{
      d = JSON.parse(JSON.stringify(feature.values_))
    }
    const [data_processed, set_data_processed] = useState(d)

   // let data_processed = JSON.parse(JSON.stringify(_feature.values));
    // const replacements = {"Clave": "Clave",
    // "Calendario": "Calendario",
    // "Codigo": "Codigo",
    // "Link": "Link",
    // "Municipio": "Municipio",
    // "Provincia": "Provincia",
    // "a_total": "Area",
    // "antiguedad": "antiguedad",
    // "beginning": "beginning",
    // "cat_antigu": "cat_antigu",
    // "cat_area": "cat_area",
    // "centroid": "centroid",
    // "currentUse": "currentUse",
    // "end": "end",
    // "floorAbove": "floorAbove",
    // "info_sys": "info_sys",
    // "lic_new_ba": "lic_new_ba",
    // "lic_new_co": "lic_new_co",
    // "mano_cost": "mano_cost",
    // "new_basicC": "new_basicC",
    // "new_coverC": "new_coverC",
    // "priority_r": "priority_r",
    // "projection": "projection",
    // "ref_geo": "ref_geo",
    // "reference": "reference",
    // "reti_cost": "reti_cost",
    
    // "risk_norm": "risk_norm",
    // "sourceStat": "sourceStat",
    // "sum_factor": "sum_factor",
    // "val_antigu": "val_antigu",
    // "val_area": "val_area",
    // "val_use": "val_use",
    // "value_uom": "value_uom"}
    //let catastroImageUrl = ""
    const [catastroImageUrl, setCatastroImageUrl] = useState("")

    const replacements = {
      "Provincia":"Provincia",
      "Municipio":"Municipio",
      "reference":"Ref. Catastral",
      //"Antiguedad":"Antugüedad",
      "currentUse":"Uso actual del edificio",
      "beginning":"Fecha de construcción",
      "a_total": "Área",
      "Link_catastro":"Catastro online",
     // "Link":"Imagen de catastro",
      "risk_level":"Riesgo de contener amianto"
     //"Superficie de amianto":

    }
    const is_float = ["a_total"]
    const is_date = []
    useEffect(()=>{
      update_feature(_feature)
    }, [ _feature])
    useEffect(()=>{
     // console.log("FullModalComponent actualizado", feature)

      setFeature(feature);
    }, [ feature])

    const update_feature = (f)=>{
      if(f && "values_" in f){

      }else{
        return
      }
      const data = f.values_;
      let data_processed_new = JSON.parse(JSON.stringify(data));
      for(const key of Object.keys(data)){
        if(!Object.keys(replacements).includes(key)){
          delete data_processed_new[key];
        }
      }
      // if(location.getN_REF_CATASTRAL() != null && location.getN_REF_CATASTRAL().length() >= 14) {
      
      if ("reference" in data_processed_new){
        const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
  
        const rc1 = data_processed_new["reference"].substring(0, 7);
        const rc2 = data_processed_new["reference"].substring(7, 14);
        const catastroUrl = uri + rc1 + "&rc2=" + rc2;
        //   properties.put("urlIframe", catastroUrl);
        // }
        setCatastroImageUrl("https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed_new["reference"]);
        data_processed_new["Link_catastro"] = catastroUrl;
        //data_processed_new["Link_catastro"] = catastroUrl;
      }
      set_data_processed(data_processed_new)
      
    }
    useEffect(()=>{update_feature(feature)}, [])
    
    
    return  <div style={{width:"100%", display:"flex", flexWrap:"wrap", gap:"1.5rem", paddingRight:"1rem"}}>
      <div style={{display:"flex", flexDirection:"column", flex:"1 1 220px"}}>
        {data_processed && "button_text" in data_processed && "button_href" in data_processed &&
        <div>
        <Button href={data_processed["button_href"]} >{data_processed["button_text"]}</Button>
        </div>
        }
        {data_processed && Object.keys(replacements).map(key=>{
          
          return <InfoFieldComponent
            _label={replacements[key]}
            _data={data_processed[key]  }
            fixed_decimals={is_float.includes(key) }
            is_date={is_date.includes(key)}
          />
        })}
      </div>
      { <ImageWithCheck src={catastroImageUrl}/>}

      {/* <div  style={{display:"flex", flex:"3 1 320px", height:"500px"}}>
       <a href={catastroImageUrl} target='blank'> <img style={{width: "100%", borderRadius: "10px",height:"100%",   objectFit:"cover"}}  src={catastroImageUrl}/></a>
      </div> */}
    </div>
    //return r;
   // return r
    //return renderToString(<TableKeyValue dataObject={data} replacements={replacements} />)
  
}
export const FullModalInfo = ({feature})=>{
  //console.log("getModalBody feature", feature)
  if (
    feature &&
    "values_" in feature &&
    "beginning" in feature["values_"] && 
    "Provincia" in feature["values_"]
  ){
    return <FullModalComponent feature={feature} />
  }
  return <></>;
}

export default class LayerClass {
  constructor(label, legend, colorBgFeatureFunction, getTooltip = ()=>"", 
  getModalBody = FullModalInfo, 
  _updateLayerStyle = updateLayerStyle, 
  _getVectorLayerFromSources = getVectorLayerFromSources, 
  _createGeometryStyle = createGeometryStyle,
  _breakpoint_zoom = BREAKPOINT_ZOOM,
   _handleMapPointerMove = handleMapPointerMove) {
    this.label = label;
    this.legend = legend;
    this.colorBgFeatureFunction = colorBgFeatureFunction;
    this.getTooltip = getTooltip;
    this.getModalBody = getModalBody;
    this.updateLayerStyle = _updateLayerStyle
    this.getVectorLayerFromSources = _getVectorLayerFromSources
    this.createGeometryStyle = _createGeometryStyle
    this.breakpoint_zoom = _breakpoint_zoom // zoom entre cluster y polígonos
    this.handleMapPointerMove = _handleMapPointerMove
  }
}
export {interpolateColors,legendBoxes,  legendGradient, hexToRgb, tooltipBeginning, info_field, ImageWithCheck}