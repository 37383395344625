const data = {
  type: "FeatureCollection",
  features: [
    {
      id: "0",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 28.107971590961295,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 706861.468079,
        centroid_y: 5706798.20496,
        Fecha_ejec: "2024-05-29",
        a_amianto: 28.107971590961295,
        Reti_cost: 1193.2391477300002,
        CuBasic_C: 562.1594318192259,
        LiBasic_C: 70.21594318196904,
        CuNormal_C: 2529.71744319,
        LiNormal_C: 148.9182636368,
        m3_amianto: 0.168647829545768,
        ratio: 100.0,
        id: 98,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 9.932961874909848,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96416666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.90666666666668,
        E_d: 22.87,
        E_m: 695.65,
        E_y: 8347.76,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 25.9,
        SD_y: 310.85,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 15917.754171041528,
        ton_co2_avoid: 4.113147677797131,
        energy_price_saved: 2901.8065853808703,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.02125733365027055, 51.47448679636458],
            [-0.021222029974742096, 51.474506459835595],
            [-0.02118525254018566, 51.47447956684803],
            [-0.021137677716118696, 51.47445359822065],
            [-0.021187581822632508, 51.47442882303564],
            [-0.02121520209754471, 51.47445968134466],
            [-0.02125733365027055, 51.47448679636458],
          ],
        ],
      },
    },
    {
      id: "1",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 124.49962150047432,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 706858.903888,
        centroid_y: 5706826.00581,
        Fecha_ejec: "2024-05-29",
        a_amianto: 124.49962150047432,
        Reti_cost: 2839.9924300100006,
        CuBasic_C: 2489.9924300100006,
        LiBasic_C: 213.1993944008,
        CuNormal_C: 11204.965935,
        LiNormal_C: 561.7983346004,
        m3_amianto: 0.746997729002846,
        ratio: 100.0,
        id: 99,
        superficie_cubierta: 145.0,
        Potencia_instalada_kWp: 15.0,
        n_modulos: 24.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1026,
        "Coste \u20ac/kWp": 1.282,
        Lucernario: "false",
        superficie_fv_percent: 34.8,
        superficie_placas: 43.25070261665146,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96416666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.90583333333335,
        E_d: 34.31,
        E_m: 1043.47,
        E_y: 12521.64,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 38.86,
        SD_y: 466.28,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 69310.0467561121,
        ton_co2_avoid: 17.90971608177937,
        energy_price_saved: 12635.221523639237,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.02124941312458239, 51.47478507200593],
            [-0.021109786230310963, 51.47473834416705],
            [-0.021182376095917412, 51.474651777409434],
            [-0.021331819216804444, 51.47469944007806],
            [-0.02124941312458239, 51.47478507200593],
          ],
        ],
      },
    },
    {
      id: "2",
      type: "Feature",
      properties: {
        Fecha_cons: "1976-01-01",
        Fecha_fin: "1976-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 213.92110057423588,
        Decada: "1970",
        Antiguedad: 48,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 698450.4634959999,
        centroid_y: 5705881.99859,
        Fecha_ejec: "2024-05-29",
        a_amianto: 213.92110057423588,
        Reti_cost: 4628.422011480001,
        CuBasic_C: 4278.42201148,
        LiBasic_C: 356.2737609184,
        CuNormal_C: 19252.8990517,
        LiNormal_C: 955.2528425272,
        m3_amianto: 1.283526603445415,
        ratio: 100.0,
        id: 118,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 99.736373560053,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.70916666666668,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57000000000001,
        E_d: 68.56,
        E_m: 2085.35,
        E_y: 25024.19,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 77.13,
        SD_y: 925.54,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 159829.37377925843,
        ton_co2_avoid: 41.299910184560375,
        energy_price_saved: 29136.89483995881,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14281069417221, 51.46940968761523],
            [-0.14273883578389215, 51.46942615068736],
            [-0.1425835613020591, 51.4691774331914],
            [-0.1425643210166827, 51.46917696398805],
            [-0.14250532451965828, 51.469095995852896],
            [-0.14257674367166784, 51.46907822112146],
            [-0.14262741000261475, 51.469159148717075],
            [-0.14265850893820153, 51.46916153346121],
            [-0.14281069417221, 51.46940968761523],
          ],
        ],
      },
    },
    {
      id: "3",
      type: "Feature",
      properties: {
        Fecha_cons: "1982-01-01",
        Fecha_fin: "1982-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Residencial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 424.1086791616275,
        Decada: "1980",
        Antiguedad: 42,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 707353.206335,
        centroid_y: 5707281.75467,
        Fecha_ejec: "2024-05-29",
        a_amianto: 424.1086791616275,
        Reti_cost: 4591.08679162,
        CuBasic_C: 8482.17358323,
        LiBasic_C: 522.930414994,
        CuNormal_C: 38169.7811245,
        LiNormal_C: 1710.43471664,
        m3_amianto: 2.544652074969765,
        ratio: 100.0,
        id: 95,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 211.68532361207767,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.9725,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91500000000002,
        E_d: 114.35,
        E_m: 3478.21,
        E_y: 41738.56,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.18,
        SD_m: 129.52,
        SD_y: 1554.26,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 339229.62609830895,
        ton_co2_avoid: 87.65693538380303,
        energy_price_saved: 61841.560837721714,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.014083348601457532, 51.47869020148138],
            [-0.013967198942691864, 51.47875440753082],
            [-0.013890316606602325, 51.4786880237403],
            [-0.01385619172941452, 51.47870634434627],
            [-0.0137752843936862, 51.478634374964585],
            [-0.013659402804154798, 51.47869447534188],
            [-0.013570937322290878, 51.47863739125673],
            [-0.013849705575621533, 51.47850331043074],
            [-0.014083348601457532, 51.47869020148138],
          ],
        ],
      },
    },
    {
      id: "4",
      type: "Feature",
      properties: {
        Fecha_cons: "1989-01-01",
        Fecha_fin: "1989-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 457.3256449417191,
        Decada: "1980",
        Antiguedad: 35,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 707031.687959,
        centroid_y: 5707584.05794,
        Fecha_ejec: "2024-05-29",
        a_amianto: 457.3256449417191,
        Reti_cost: 4923.25644942,
        CuBasic_C: 9146.51289883,
        LiBasic_C: 562.79077393,
        CuNormal_C: 41159.3080448,
        LiNormal_C: 1843.30257977,
        m3_amianto: 2.743953869650315,
        ratio: 100.0,
        id: 76,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 228.26537696287483,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97583333333334,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91833333333335,
        E_d: 114.35,
        E_m: 3478.2,
        E_y: 41738.43,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 129.52,
        SD_y: 1554.26,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 365799.46666594304,
        ton_co2_avoid: 94.52258218647968,
        energy_price_saved: 66685.24277320142,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.018457820004510076, 51.48155404152367],
            [-0.018241209529748503, 51.481577062286775],
            [-0.018228080499333495, 51.4815391486239],
            [-0.018148579949823235, 51.48154478063983],
            [-0.01810412853433436, 51.481423255636486],
            [-0.018193029283424384, 51.48141020776587],
            [-0.018171678992910157, 51.481361646115765],
            [-0.018399143356893955, 51.48134307718208],
            [-0.018457820004510076, 51.48155404152367],
          ],
        ],
      },
    },
    {
      id: "5",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 417.6090816865592,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 707034.870632,
        centroid_y: 5707330.07841,
        Fecha_ejec: "2024-05-29",
        a_amianto: 417.6090816865592,
        Reti_cost: 4526.09081687,
        CuBasic_C: 8352.18163373,
        LiBasic_C: 515.1308980240001,
        CuNormal_C: 37584.8173518,
        LiNormal_C: 1684.43632675,
        m3_amianto: 2.505654490119355,
        ratio: 100.0,
        id: 77,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 208.4415993295549,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.9725,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91583333333335,
        E_d: 114.35,
        E_m: 3478.21,
        E_y: 41738.55,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 129.52,
        SD_y: 1554.26,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 334031.49825103936,
        ton_co2_avoid: 86.31373914806856,
        energy_price_saved: 60893.94213116447,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.01832536838546761, 51.47936548862537],
            [-0.018194117949197945, 51.479327352758474],
            [-0.018461026406365138, 51.47899453664751],
            [-0.018592094780111937, 51.47903545126344],
            [-0.01832536838546761, 51.47936548862537],
          ],
        ],
      },
    },
    {
      id: "6",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 269.1321715749997,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 707062.792107,
        centroid_y: 5707330.165259999,
        Fecha_ejec: "2024-05-29",
        a_amianto: 269.1321715749997,
        Reti_cost: 3041.32171575,
        CuBasic_C: 5382.6434315,
        LiBasic_C: 336.95860588999994,
        CuNormal_C: 24221.8954417,
        LiNormal_C: 1090.5286863,
        m3_amianto: 1.614793029449998,
        ratio: 100.0,
        id: 78,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 125.46630917576104,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.9725,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91583333333335,
        E_d: 68.61,
        E_m: 2086.93,
        E_y: 25043.13,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 77.71,
        SD_y: 932.56,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 201062.06903424588,
        ton_co2_avoid: 51.95443863844913,
        energy_price_saved: 36653.615184943024,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.018170058665732926, 51.47920077931584],
            [-0.018063547936991973, 51.47927879528583],
            [-0.017813713461734403, 51.47914508396903],
            [-0.017923924993303416, 51.47906159506453],
            [-0.018170058665732926, 51.47920077931584],
          ],
        ],
      },
    },
    {
      id: "7",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 196.81983066137013,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 707048.9624500001,
        centroid_y: 5707310.7933,
        Fecha_ejec: "2024-05-29",
        a_amianto: 196.81983066137013,
        Reti_cost: 4286.39661323,
        CuBasic_C: 3936.39661323,
        LiBasic_C: 328.9117290584,
        CuNormal_C: 17713.7847595,
        LiNormal_C: 880.0072549092,
        m3_amianto: 1.180918983968221,
        ratio: 100.0,
        id: 79,
        superficie_cubierta: 190.0,
        Potencia_instalada_kWp: 20.0,
        n_modulos: 32.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.062,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 69.55314398483014,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.018309170463267802, 51.47906722348145],
            [-0.018069224021012967, 51.47903815204945],
            [-0.018092760824354483, 51.47893366738818],
            [-0.018342176373118954, 51.47897133075209],
            [-0.018309170463267802, 51.47906722348145],
          ],
        ],
      },
    },
    {
      id: "8",
      type: "Feature",
      properties: {
        Fecha_cons: "1988-01-01",
        Fecha_fin: "1988-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 382.71229355202024,
        Decada: "1980",
        Antiguedad: 36,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 707060.920883,
        centroid_y: 5707289.05194,
        Fecha_ejec: "2024-05-29",
        a_amianto: 382.71229355202024,
        Reti_cost: 4177.12293552,
        CuBasic_C: 7654.24587104,
        LiBasic_C: 473.2547522624,
        CuNormal_C: 34444.1064197,
        LiNormal_C: 1544.8491742100002,
        m3_amianto: 2.296273761312122,
        ratio: 100.0,
        id: 80,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 191.0238252521738,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97083333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91583333333335,
        E_d: 114.35,
        E_m: 3478.21,
        E_y: 41738.57,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 129.52,
        SD_y: 1554.26,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 306119.1948049931,
        ton_co2_avoid: 79.10119993761022,
        energy_price_saved: 55805.52921295024,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.01807451016058127, 51.478991312098714],
            [-0.017945273297467017, 51.47898210778761],
            [-0.017994676107847555, 51.47866915800742],
            [-0.018019332066702847, 51.47863290182445],
            [-0.01803602150607428, 51.47863332656011],
            [-0.018040765118633995, 51.478611873816796],
            [-0.01813520253867454, 51.478616364895615],
            [-0.01813334678731647, 51.478678950308],
            [-0.01807451016058127, 51.478991312098714],
          ],
        ],
      },
    },
    {
      id: "9",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "2002-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 529.7756723393188,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 707076.8840070001,
        centroid_y: 5707270.83263,
        Fecha_ejec: "2024-05-29",
        a_amianto: 529.7756723393188,
        Reti_cost: 5647.75672339,
        CuBasic_C: 10595.5134468,
        LiBasic_C: 649.7308068076,
        CuNormal_C: 47679.81051049999,
        LiNormal_C: 2133.10268936,
        m3_amianto: 3.178654034035913,
        ratio: 100.0,
        id: 81,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 227.4080124822962,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97083333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91500000000002,
        E_d: 182.96,
        E_m: 5565.14,
        E_y: 66781.7,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.18,
        SD_m: 207.23,
        SD_y: 2486.82,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 364425.5243103093,
        ton_co2_avoid: 94.16755548178392,
        energy_price_saved: 66434.77308176938,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.0178043506801814, 51.47893850592438],
            [-0.0177075966634266, 51.478935347459064],
            [-0.017759369520230234, 51.47844952239486],
            [-0.017780645435913515, 51.47844797614629],
            [-0.017780596967845053, 51.47841457083557],
            [-0.01787846251450597, 51.47841775754021],
            [-0.017875309224458416, 51.47844899360845],
            [-0.01795741671940456, 51.47845456286468],
            [-0.018033280924295292, 51.47847041194907],
            [-0.018022412315314925, 51.47855155778268],
            [-0.01795293060728435, 51.47855744459291],
            [-0.017848661083352108, 51.47854991945703],
            [-0.0178043506801814, 51.47893850592438],
          ],
        ],
      },
    },
    {
      id: "10",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 43.53586649524752,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 707195.046128,
        centroid_y: 5707192.46435,
        Fecha_ejec: "2024-05-29",
        a_amianto: 43.53586649524752,
        Reti_cost: 1656.07599486,
        CuBasic_C: 870.7173299049504,
        LiBasic_C: 101.071732990598,
        CuNormal_C: 3918.22798457,
        LiNormal_C: 222.9721591772,
        m3_amianto: 0.261215198971485,
        ratio: 100.0,
        id: 82,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 15.384895650203031,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96916666666665,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91333333333334,
        E_d: 22.87,
        E_m: 695.64,
        E_y: 8347.71,
        "H(i)_d": 2.88,
        "H(i)_m": 87.51,
        "H(i)_y": 1050.18,
        SD_m: 25.9,
        SD_y: 310.85,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 24654.57836153032,
        ton_co2_avoid: 6.370743048619434,
        energy_price_saved: 4494.529635306977,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.016197333622349278, 51.47794766559346],
            [-0.016152602325709992, 51.47795000608811],
            [-0.016144171164701415, 51.47782313420699],
            [-0.016188766218792644, 51.47782287800289],
            [-0.016197333622349278, 51.47794766559346],
          ],
        ],
      },
    },
    {
      id: "11",
      type: "Feature",
      properties: {
        Fecha_cons: "1988-01-01",
        Fecha_fin: "1988-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 494.3907476326487,
        Decada: "1980",
        Antiguedad: 36,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 707174.491675,
        centroid_y: 5707101.38983,
        Fecha_ejec: "2024-05-29",
        a_amianto: 494.3907476326487,
        Reti_cost: 5293.90747633,
        CuBasic_C: 9887.81495265,
        LiBasic_C: 607.2688971592,
        CuNormal_C: 44495.16728690001,
        LiNormal_C: 1991.56299053,
        m3_amianto: 2.966344485795892,
        ratio: 100.0,
        id: 83,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 212.21850479304157,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96166666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.90833333333335,
        E_d: 182.96,
        E_m: 5565.14,
        E_y: 66781.66,
        "H(i)_d": 2.88,
        "H(i)_m": 87.51,
        "H(i)_y": 1050.16,
        SD_m: 207.24,
        SD_y: 2486.83,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 340084.0587513373,
        ton_co2_avoid: 87.87772078134556,
        energy_price_saved: 61997.32391036878,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.016737824397481803, 51.47714581154687],
            [-0.016481479033933356, 51.47721444373791],
            [-0.016416739125430235, 51.47713067686334],
            [-0.01633905670473844, 51.47704031711333],
            [-0.0163058130888163, 51.477003282821194],
            [-0.016543957559747562, 51.47693631521295],
            [-0.016737824397481803, 51.47714581154687],
          ],
        ],
      },
    },
    {
      id: "12",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 43.14375934413017,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 707316.570887,
        centroid_y: 5707176.554599999,
        Fecha_ejec: "2024-05-29",
        a_amianto: 43.14375934413017,
        Reti_cost: 1644.31278032,
        CuBasic_C: 862.8751868826033,
        LiBasic_C: 100.28751868810414,
        CuNormal_C: 3882.93834097,
        LiNormal_C: 221.0900448516,
        m3_amianto: 0.258862556064781,
        ratio: 100.0,
        id: 84,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 15.246315622647947,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96916666666665,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91166666666668,
        E_d: 22.87,
        E_m: 695.64,
        E_y: 8347.71,
        "H(i)_d": 2.88,
        "H(i)_m": 87.51,
        "H(i)_y": 1050.17,
        SD_m: 25.9,
        SD_y: 310.85,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 24432.50131750079,
        ton_co2_avoid: 6.313358340442204,
        energy_price_saved: 4454.044990180394,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.014498352629273162, 51.477717186271185],
            [-0.014445402308236795, 51.47774297793275],
            [-0.014364658038233426, 51.47768246334536],
            [-0.014424465540358644, 51.47765406276885],
            [-0.014498352629273162, 51.477717186271185],
          ],
        ],
      },
    },
    {
      id: "13",
      type: "Feature",
      properties: {
        Fecha_cons: "1979-01-01",
        Fecha_fin: "1979-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 964.1615523400318,
        Decada: "1970",
        Antiguedad: 45,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 706804.561621,
        centroid_y: 5707054.355400001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 964.1615523400318,
        Reti_cost: 9991.6155234,
        CuBasic_C: 19283.2310468,
        LiBasic_C: 1170.99386281,
        CuNormal_C: 86774.5397106,
        LiNormal_C: 3870.64620936,
        m3_amianto: 5.784969314040192,
        ratio: 100.0,
        id: 85,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 448.5200651920209,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96833333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.90916666666668,
        E_d: 343.06,
        E_m: 10434.67,
        E_y: 125216.02,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 388.57,
        SD_y: 4662.8,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 718761.657239413,
        ton_co2_avoid: 185.72801223066432,
        energy_price_saved: 131030.25011474498,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.022074017770644678, 51.47691967573019],
            [-0.021587659862480693, 51.47692679957133],
            [-0.021579447738834506, 51.47671085628353],
            [-0.021991104726613107, 51.47672131963114],
            [-0.02198558473835213, 51.476523886629614],
            [-0.022083763646364545, 51.476522206392936],
            [-0.02209789974042015, 51.476895229650445],
            [-0.022074017770644678, 51.47691967573019],
          ],
        ],
      },
    },
    {
      id: "14",
      type: "Feature",
      properties: {
        Fecha_cons: "1950-01-01",
        Fecha_fin: "1950-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 49.4459090074748,
        Decada: "1950",
        Antiguedad: 74,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 707109.125705,
        centroid_y: 5707251.0944,
        Fecha_ejec: "2024-05-29",
        a_amianto: 49.4459090074748,
        Reti_cost: 1833.37727022,
        CuBasic_C: 988.9181801494959,
        LiBasic_C: 112.89181801477984,
        CuNormal_C: 4450.13181067,
        LiNormal_C: 251.34036323560002,
        m3_amianto: 0.296675454044849,
        ratio: 100.0,
        id: 86,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 17.473426529743172,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97083333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91500000000002,
        E_d: 22.87,
        E_m: 695.64,
        E_y: 8347.71,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.18,
        SD_m: 25.9,
        SD_y: 310.85,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 28001.48752496158,
        ton_co2_avoid: 7.235584376450072,
        energy_price_saved: 5104.671175800496,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.017402198707043612, 51.478491929201205],
            [-0.01733384029397142, 51.478489145257846],
            [-0.017341632334975786, 51.47839539463135],
            [-0.017409922575904558, 51.478399220713676],
            [-0.017402198707043612, 51.478491929201205],
          ],
        ],
      },
    },
    {
      id: "15",
      type: "Feature",
      properties: {
        Fecha_cons: "1982-01-01",
        Fecha_fin: "1990-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 201.231784407872,
        Decada: "1980",
        Antiguedad: 42,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 707144.003378,
        centroid_y: 5707232.23369,
        Fecha_ejec: "2024-05-29",
        a_amianto: 201.231784407872,
        Reti_cost: 4374.63568816,
        CuBasic_C: 4024.63568816,
        LiBasic_C: 335.97085505280006,
        CuNormal_C: 18110.8605967,
        LiNormal_C: 899.4198513944001,
        m3_amianto: 1.207390706447232,
        ratio: 100.0,
        id: 87,
        superficie_cubierta: 190.0,
        Potencia_instalada_kWp: 20.0,
        n_modulos: 32.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.062,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 71.11206720119618,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.017167619964599756, 51.47827613843708],
            [-0.01666616041813612, 51.47830095140517],
            [-0.01667552235729536, 51.478234381599144],
            [-0.01702555299890066, 51.47822450341193],
            [-0.017022387165697223, 51.47824738812318],
            [-0.017172182505309812, 51.478257464788555],
            [-0.017167619964599756, 51.47827613843708],
          ],
        ],
      },
    },
    {
      id: "16",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 62.78643816004717,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 707067.4609949999,
        centroid_y: 5707503.34315,
        Fecha_ejec: "2024-05-29",
        a_amianto: 62.78643816004717,
        Reti_cost: 1919.660954,
        CuBasic_C: 1255.7287632,
        LiBasic_C: 127.015588688,
        CuNormal_C: 5650.7794344,
        LiNormal_C: 302.817615536,
        m3_amianto: 0.376718628960283,
        ratio: 100.0,
        id: 88,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 22.187764393338384,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97500000000001,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91750000000002,
        E_d: 22.87,
        E_m: 695.64,
        E_y: 8347.69,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 25.9,
        SD_y: 310.85,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 35556.300695189595,
        ton_co2_avoid: 9.187748099636991,
        energy_price_saved: 6481.913616733063,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.017895433911673583, 51.48075090713352],
            [-0.017815885605014826, 51.480778633094715],
            [-0.017751195129946103, 51.48069399842618],
            [-0.017830386173932258, 51.480671743771936],
            [-0.017895433911673583, 51.48075090713352],
          ],
        ],
      },
    },
    {
      id: "17",
      type: "Feature",
      properties: {
        Fecha_cons: "1979-01-01",
        Fecha_fin: "1979-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 40.721841922071945,
        Decada: "1970",
        Antiguedad: 45,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 707201.806064,
        centroid_y: 5707107.95712,
        Fecha_ejec: "2024-05-29",
        a_amianto: 40.721841922071945,
        Reti_cost: 1571.65525766,
        CuBasic_C: 814.4368384414388,
        LiBasic_C: 95.44368384405755,
        CuNormal_C: 3664.96577299,
        LiNormal_C: 209.464841226,
        m3_amianto: 0.244331051532432,
        ratio: 100.0,
        id: 96,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 14.39046759048703,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96833333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91166666666668,
        E_d: 22.87,
        E_m: 695.64,
        E_y: 8347.72,
        "H(i)_d": 2.88,
        "H(i)_m": 87.51,
        "H(i)_y": 1050.18,
        SD_m: 25.9,
        SD_y: 310.85,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 23060.98909835913,
        ton_co2_avoid: 5.958959583015998,
        energy_price_saved: 4204.018312630869,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.016181176245922965, 51.477147080093246],
            [-0.016108658482617683, 51.47716510900822],
            [-0.016066615178302313, 51.4771030406809],
            [-0.016133788310659092, 51.47708281958671],
            [-0.016181176245922965, 51.477147080093246],
          ],
        ],
      },
    },
    {
      id: "18",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 38.47774241552668,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 707213.024127,
        centroid_y: 5707113.94217,
        Fecha_ejec: "2024-05-29",
        a_amianto: 38.47774241552668,
        Reti_cost: 1504.33227247,
        CuBasic_C: 769.5548483105335,
        LiBasic_C: 90.95548483122133,
        CuNormal_C: 3462.9968174,
        LiNormal_C: 198.69316359479996,
        m3_amianto: 0.23086645449316,
        ratio: 100.0,
        id: 97,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 13.597436057945272,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96916666666665,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91166666666668,
        E_d: 22.87,
        E_m: 695.64,
        E_y: 8347.72,
        "H(i)_d": 2.88,
        "H(i)_m": 87.51,
        "H(i)_y": 1050.18,
        SD_m: 25.9,
        SD_y: 310.85,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 21790.14147568076,
        ton_co2_avoid: 5.630572557315908,
        energy_price_saved: 3972.3427910166024,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.01601583673922325, 51.477191530569655],
            [-0.015936699818904682, 51.47721007617668],
            [-0.01589832458781009, 51.47715906707276],
            [-0.01598202306565929, 51.47713789619519],
            [-0.01601583673922325, 51.477191530569655],
          ],
        ],
      },
    },
    {
      id: "19",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 4486.93121383,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 706941.071176,
        centroid_y: 5707275.69645,
        Fecha_ejec: "2024-05-29",
        a_amianto: 4486.93121383,
        Reti_cost: 45219.3121383,
        CuBasic_C: 89738.6242766,
        LiBasic_C: 5398.3174566,
        CuNormal_C: 403823.80924500007,
        LiNormal_C: 17961.7248553,
        m3_amianto: 26.92158728298,
        ratio: 100.0,
        id: 100,
        superficie_cubierta: 4998.0,
        Potencia_instalada_kWp: 700.0,
        n_modulos: 1127.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1398,
        "Coste \u20ac/kWp": 0.529,
        Lucernario: "false",
        superficie_fv_percent: 47.4,
        superficie_placas: 2123.11269134078,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97083333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91583333333335,
        E_d: 1600.93,
        E_m: 48695.0,
        E_y: 584340.04,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 1813.31,
        SD_y: 21759.72,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 3402327.1531471205,
        ton_co2_avoid: 879.1613363732159,
        energy_price_saved: 620244.24001872,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.01988279156146287, 51.479235528927354],
            [-0.019330466088478084, 51.47918447034765],
            [-0.0195445448144491, 51.47828780273393],
            [-0.020358676866958903, 51.47835708386265],
            [-0.020289130787872964, 51.47862826235105],
            [-0.020024216469285757, 51.4786007072765],
            [-0.020004242576593778, 51.478722360441765],
            [-0.02002975342618087, 51.47872879201856],
            [-0.019999649032405212, 51.478863777064035],
            [-0.019966365124643472, 51.47886293056768],
            [-0.01988279156146287, 51.479235528927354],
          ],
        ],
      },
    },
    {
      id: "20",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1125.67359937,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 706793.266107,
        centroid_y: 5706932.90412,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1125.67359937,
        Reti_cost: 11606.7359937,
        CuBasic_C: 22513.4719874,
        LiBasic_C: 1364.80831924,
        CuNormal_C: 101310.623943,
        LiNormal_C: 4516.69439747,
        m3_amianto: 6.75404159622,
        ratio: 100.0,
        id: 101,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 523.6539656259082,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96583333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.90833333333335,
        E_d: 343.06,
        E_m: 10434.68,
        E_y: 125216.2,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 388.57,
        SD_y: 4662.81,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 839165.1151484854,
        ton_co2_avoid: 216.84026575436863,
        energy_price_saved: 152979.8004915689,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.022546717412785924, 51.47578920396179],
            [-0.021649207169592885, 51.47577795915091],
            [-0.021656045881880148, 51.47561621524219],
            [-0.022544458534268178, 51.47562491573835],
            [-0.022546717412785924, 51.47578920396179],
          ],
        ],
      },
    },
    {
      id: "21",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 301.70572257797784,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 706841.351076,
        centroid_y: 5706951.12115,
        Fecha_ejec: "2024-05-29",
        a_amianto: 301.70572257797784,
        Reti_cost: 3367.05722578,
        CuBasic_C: 6034.11445156,
        LiBasic_C: 376.04686709360004,
        CuNormal_C: 27153.515032,
        LiNormal_C: 1220.82289031,
        m3_amianto: 1.810234335467867,
        ratio: 100.0,
        id: 102,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 150.59113610547527,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.96666666666665,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.90916666666668,
        E_d: 114.35,
        E_m: 3478.23,
        E_y: 41738.72,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 129.52,
        SD_y: 1554.27,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 241325.06648592846,
        ton_co2_avoid: 62.35839717996391,
        energy_price_saved: 43993.55962038475,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.021549650441962814, 51.475913058557545],
            [-0.02124958732714877, 51.475913526510425],
            [-0.02124899873875332, 51.47578050767932],
            [-0.021548684655754092, 51.475785812924315],
            [-0.021549650441962814, 51.475913058557545],
          ],
        ],
      },
    },
    {
      id: "22",
      type: "Feature",
      properties: {
        Fecha_cons: "1982-01-01",
        Fecha_fin: "1982-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 574.387909498939,
        Decada: "1980",
        Antiguedad: 42,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 707208.815355,
        centroid_y: 5707256.15735,
        Fecha_ejec: "2024-05-29",
        a_amianto: 574.387909498939,
        Reti_cost: 6093.87909499,
        CuBasic_C: 11487.75819,
        LiBasic_C: 703.2654913996,
        CuNormal_C: 51694.9118549,
        LiNormal_C: 2311.551638,
        m3_amianto: 3.446327456993634,
        ratio: 100.0,
        id: 119,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 246.55714500648045,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97083333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91500000000002,
        E_d: 182.96,
        E_m: 5565.14,
        E_y: 66781.72,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.18,
        SD_m: 207.23,
        SD_y: 2486.81,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 395112.36152435304,
        ton_co2_avoid: 102.09703421789283,
        energy_price_saved: 72028.98350588956,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.016227455965854034, 51.47828452230884],
            [-0.015826265555497817, 51.47865031340455],
            [-0.015670343894232396, 51.4785868473167],
            [-0.01601027392898228, 51.47828375119857],
            [-0.016227455965854034, 51.47828452230884],
          ],
        ],
      },
    },
    {
      id: "23",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1661.10463256,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 707139.147974,
        centroid_y: 5707268.01705,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1661.10463256,
        Reti_cost: 16961.0463256,
        CuBasic_C: 33222.0926512,
        LiBasic_C: 2007.32555907,
        CuNormal_C: 149499.41693,
        LiNormal_C: 6658.41853022,
        m3_amianto: 9.96662779536,
        ratio: 100.0,
        id: 120,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 834.0841234240837,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.016501864694508043, 51.47828437036633],
            [-0.017523289323523903, 51.47875063632243],
            [-0.01738857957336064, 51.47888761540016],
            [-0.016343548917401866, 51.47843264618216],
            [-0.016501864694508043, 51.47828437036633],
          ],
        ],
      },
    },
    {
      id: "24",
      type: "Feature",
      properties: {
        Fecha_cons: "1976-01-01",
        Fecha_fin: "1976-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 999.5959731024461,
        Decada: "1970",
        Antiguedad: 48,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 707175.38082,
        centroid_y: 5707296.97623,
        Fecha_ejec: "2024-05-29",
        a_amianto: 999.5959731024461,
        Reti_cost: 10345.959731,
        CuBasic_C: 19991.919462,
        LiBasic_C: 1213.51516772,
        CuNormal_C: 89963.6375792,
        LiNormal_C: 4012.38389241,
        m3_amianto: 5.997575838614678,
        ratio: 100.0,
        id: 121,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 465.00216306348443,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.9725,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91583333333335,
        E_d: 343.06,
        E_m: 10434.64,
        E_y: 125215.69,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 388.57,
        SD_y: 4662.79,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 745174.5223490341,
        ton_co2_avoid: 192.5530965749904,
        energy_price_saved: 135845.3154242289,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.016136781614938214, 51.47862252431066],
            [-0.016796350379876604, 51.47891061559026],
            [-0.016650533780070788, 51.479042551467465],
            [-0.015983664836975057, 51.47874951387298],
            [-0.016136781614938214, 51.47862252431066],
          ],
        ],
      },
    },
    {
      id: "25",
      type: "Feature",
      properties: {
        Fecha_cons: "1973-01-01",
        Fecha_fin: "1973-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 340.92250084768267,
        Decada: "1970",
        Antiguedad: 51,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 707138.589099,
        centroid_y: 5707320.20037,
        Fecha_ejec: "2024-05-29",
        a_amianto: 340.92250084768267,
        Reti_cost: 3759.22500848,
        CuBasic_C: 6818.45001695,
        LiBasic_C: 423.10700101719993,
        CuNormal_C: 30683.0250763,
        LiNormal_C: 1377.69000339,
        m3_amianto: 2.045535005086096,
        ratio: 100.0,
        id: 122,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 170.1649990700742,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.9725,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91583333333335,
        E_d: 114.35,
        E_m: 3478.21,
        E_y: 41738.55,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.19,
        SD_m: 129.52,
        SD_y: 1554.26,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 272692.5420457768,
        ton_co2_avoid: 70.46375286462872,
        energy_price_saved: 49711.850414945104,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.01687058525293926, 51.47894106313857],
            [-0.017097250423518978, 51.479030126522645],
            [-0.01693970949859219, 51.47916652387823],
            [-0.01672065365171079, 51.47907765393106],
            [-0.01687058525293926, 51.47894106313857],
          ],
        ],
      },
    },
    {
      id: "26",
      type: "Feature",
      properties: {
        Fecha_cons: "1982-01-01",
        Fecha_fin: "1982-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 310.7178415862291,
        Decada: "1980",
        Antiguedad: 42,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 707100.870172,
        centroid_y: 5707242.73259,
        Fecha_ejec: "2024-05-29",
        a_amianto: 310.7178415862291,
        Reti_cost: 3457.17841586,
        CuBasic_C: 6214.35683172,
        LiBasic_C: 386.86140990319996,
        CuNormal_C: 27964.6057428,
        LiNormal_C: 1256.87136635,
        m3_amianto: 1.864307049517375,
        ratio: 100.0,
        id: 123,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 155.08896585149432,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.53166666666668,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.97,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.91500000000002,
        E_d: 114.35,
        E_m: 3478.21,
        E_y: 41738.57,
        "H(i)_d": 2.88,
        "H(i)_m": 87.52,
        "H(i)_y": 1050.18,
        SD_m: 129.52,
        SD_y: 1554.26,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.65,
        l_total: -20.51,
        solar_energy_total: 248532.9214139913,
        ton_co2_avoid: 64.22090689337536,
        energy_price_saved: 45307.551573770616,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.017586452014435417, 51.4782501069037],
            [-0.017570632847789144, 51.47849244353141],
            [-0.017402198707036458, 51.47849192920123],
            [-0.01740992257590933, 51.47839922071367],
            [-0.017422536771114057, 51.478250694212214],
            [-0.017586452014435417, 51.4782501069037],
          ],
        ],
      },
    },
    {
      id: "27",
      type: "Feature",
      properties: {
        Fecha_cons: "1950-01-01",
        Fecha_fin: "2006-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 803.578521025237,
        Decada: "1950",
        Antiguedad: 74,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 698279.3405139999,
        centroid_y: 5706094.15195,
        Fecha_ejec: "2024-05-29",
        a_amianto: 803.578521025237,
        Reti_cost: 8385.78521025,
        CuBasic_C: 16071.5704205,
        LiBasic_C: 978.29422523,
        CuNormal_C: 72322.0668923,
        LiNormal_C: 3228.3140841,
        m3_amianto: 4.821471126151422,
        ratio: 100.0,
        id: 89,
        superficie_cubierta: 675.0,
        Potencia_instalada_kWp: 100.0,
        n_modulos: 160.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.147,
        "Coste \u20ac/kWp": 0.583,
        Lucernario: "false",
        superficie_fv_percent: 49.8,
        superficie_placas: 399.5224602327293,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71166666666667,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.5725,
        E_d: 228.53,
        E_m: 6951.15,
        E_y: 83413.77,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 257.09,
        SD_y: 3085.13,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 640242.093736217,
        ton_co2_avoid: 165.43855702143847,
        energy_price_saved: 116716.13368811236,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14532578524936954, 51.471159571590135],
            [-0.14486408462639827, 51.47140667579612],
            [-0.14468935273807526, 51.47127402427878],
            [-0.14515095525355387, 51.47102848414882],
            [-0.14532578524936954, 51.471159571590135],
          ],
        ],
      },
    },
    {
      id: "28",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2357.07880488,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 698486.832357,
        centroid_y: 5706325.56596,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2357.07880488,
        Reti_cost: 23920.7880488,
        CuBasic_C: 47141.5760976,
        LiBasic_C: 2842.49456586,
        CuNormal_C: 212137.092439,
        LiNormal_C: 9442.31521951,
        m3_amianto: 14.14247282928,
        ratio: 100.0,
        id: 90,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 1070.6984993032356,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71500000000002,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57750000000003,
        E_d: 685.59,
        E_m: 20853.39,
        E_y: 250240.69,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 771.28,
        SD_y: 9255.39,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 1715814.045985822,
        ton_co2_avoid: 443.36634948273644,
        energy_price_saved: 312792.90058321535,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14234784399641762, 51.47363763626826],
            [-0.14205717454681524, 51.47371509921243],
            [-0.1414426833135274, 51.47279665596681],
            [-0.14171312533547134, 51.47272183273013],
            [-0.14234784399641762, 51.47363763626826],
          ],
        ],
      },
    },
    {
      id: "29",
      type: "Feature",
      properties: {
        Fecha_cons: "1985-01-01",
        Fecha_fin: "1990-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 275.1490450600288,
        Decada: "1980",
        Antiguedad: 39,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698489.328184,
        centroid_y: 5706279.63467,
        Fecha_ejec: "2024-05-29",
        a_amianto: 275.1490450600288,
        Reti_cost: 3101.4904506,
        CuBasic_C: 5502.9809012,
        LiBasic_C: 344.178854072,
        CuNormal_C: 24763.4140554,
        LiNormal_C: 1114.59618024,
        m3_amianto: 1.650894270360173,
        ratio: 100.0,
        id: 91,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 128.28270449490557,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71500000000002,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57583333333334,
        E_d: 68.56,
        E_m: 2085.34,
        E_y: 25024.07,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.36,
        SD_m: 77.13,
        SD_y: 925.53,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 205575.3943548489,
        ton_co2_avoid: 53.120681901292954,
        energy_price_saved: 37476.39439088895,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.1421078575919034, 51.472864551991584],
            [-0.14190185754894216, 51.47291119820588],
            [-0.14185791104243148, 51.47285219247463],
            [-0.1418028123342517, 51.472851239944156],
            [-0.14175110644142677, 51.47277599567126],
            [-0.14171312533547134, 51.47272183273013],
            [-0.14170450522914443, 51.47269931011458],
            [-0.14174719441214667, 51.472688216650994],
            [-0.1421078575919034, 51.472864551991584],
          ],
        ],
      },
    },
    {
      id: "30",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 296.41532760979015,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698477.836638,
        centroid_y: 5706263.1082,
        Fecha_ejec: "2024-05-29",
        a_amianto: 296.41532760979015,
        Reti_cost: 3314.1532761,
        CuBasic_C: 5928.3065522,
        LiBasic_C: 369.698393132,
        CuNormal_C: 26677.3794849,
        LiNormal_C: 1199.66131044,
        m3_amianto: 1.778491965658741,
        ratio: 100.0,
        id: 92,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 147.96297289880437,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71500000000002,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57583333333334,
        E_d: 114.27,
        E_m: 3475.57,
        E_y: 41706.78,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.36,
        SD_m: 128.55,
        SD_y: 1542.56,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 237113.38658903533,
        ton_co2_avoid: 61.27009909460673,
        energy_price_saved: 43225.77037518114,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14228631834002536, 51.47271702397482],
            [-0.1422110618059215, 51.472777819707794],
            [-0.14183809742040454, 51.47262780286295],
            [-0.14192198864672073, 51.472549211526704],
            [-0.14228631834002536, 51.47271702397482],
          ],
        ],
      },
    },
    {
      id: "31",
      type: "Feature",
      properties: {
        Fecha_cons: "1984-01-01",
        Fecha_fin: "1984-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 475.30401970212745,
        Decada: "1980",
        Antiguedad: 40,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 698415.7108500001,
        centroid_y: 5706371.02741,
        Fecha_ejec: "2024-05-29",
        a_amianto: 475.30401970212745,
        Reti_cost: 5103.04019702,
        CuBasic_C: 9506.08039404,
        LiBasic_C: 584.3648236424,
        CuNormal_C: 42777.3617732,
        LiNormal_C: 1915.2160788099998,
        m3_amianto: 2.851824118212765,
        ratio: 100.0,
        id: 93,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 237.25949750428623,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.1428295356804459, 51.47393357125269],
            [-0.1427226698924097, 51.47391843923147],
            [-0.1429520496466548, 51.473377575774535],
            [-0.1430586210524949, 51.473397397754326],
            [-0.1428295356804459, 51.47393357125269],
          ],
        ],
      },
    },
    {
      id: "32",
      type: "Feature",
      properties: {
        Fecha_cons: "1978-01-01",
        Fecha_fin: "1978-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 404.90320116092823,
        Decada: "1970",
        Antiguedad: 46,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698689.001119,
        centroid_y: 5706395.29332,
        Fecha_ejec: "2024-05-29",
        a_amianto: 404.90320116092823,
        Reti_cost: 4399.03201161,
        CuBasic_C: 8098.064023220001,
        LiBasic_C: 499.8838413932,
        CuNormal_C: 36441.2881045,
        LiNormal_C: 1633.61280464,
        m3_amianto: 2.429419206965569,
        ratio: 100.0,
        id: 94,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 202.11690572689136,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71833333333335,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57750000000003,
        E_d: 114.27,
        E_m: 3475.56,
        E_y: 41706.75,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 128.55,
        SD_y: 1542.57,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 323896.06037840876,
        ton_co2_avoid: 83.69474200178082,
        energy_price_saved: 59046.25180698391,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.13916398790137421, 51.47379246464709],
            [-0.13902931533590787, 51.47390112977841],
            [-0.13872912686898406, 51.47377010347539],
            [-0.13887440067101908, 51.473652302863094],
            [-0.13916398790137421, 51.47379246464709],
          ],
        ],
      },
    },
    {
      id: "33",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 342.6833863742442,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698293.477731,
        centroid_y: 5706774.49543,
        Fecha_ejec: "2024-05-29",
        a_amianto: 342.6833863742442,
        Reti_cost: 3776.83386374,
        CuBasic_C: 6853.66772748,
        LiBasic_C: 425.2200636488,
        CuNormal_C: 30841.5047737,
        LiNormal_C: 1384.7335455,
        m3_amianto: 2.056100318245465,
        ratio: 100.0,
        id: 104,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 171.05883697066352,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.72250000000001,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.58250000000002,
        E_d: 114.26,
        E_m: 3475.55,
        E_y: 41706.57,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 128.55,
        SD_y: 1542.56,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 274124.9337280885,
        ton_co2_avoid: 70.83388287533806,
        energy_price_saved: 49972.97541863053,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14460173486034564, 51.477428967245395],
            [-0.14450087056309174, 51.47747595090356],
            [-0.14423430496948306, 51.477240462526815],
            [-0.14438223678125953, 51.477173808636806],
            [-0.14460173486034564, 51.477428967245395],
          ],
        ],
      },
    },
    {
      id: "34",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2411.72640581,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 698368.8652999998,
        centroid_y: 5706750.71528,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2411.72640581,
        Reti_cost: 24467.2640581,
        CuBasic_C: 48234.5281162,
        LiBasic_C: 2908.07168697,
        CuNormal_C: 217055.376523,
        LiNormal_C: 9660.90562324,
        m3_amianto: 14.47035843486,
        ratio: 100.0,
        id: 105,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 1095.5249620408076,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.72250000000001,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.58083333333336,
        E_d: 685.59,
        E_m: 20853.29,
        E_y: 250239.51,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 771.28,
        SD_y: 9255.34,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 1755598.9093296954,
        ton_co2_avoid: 453.6467581707933,
        energy_price_saved: 320045.68117080344,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14385322806383855, 51.47710366770165],
            [-0.143190324945336, 51.47740887816764],
            [-0.14295502221382556, 51.477206676309244],
            [-0.14304299894439818, 51.47716588522746],
            [-0.1429854292275783, 51.47712024445041],
            [-0.1431356052387213, 51.47705104465299],
            [-0.14303413522893094, 51.4769744084563],
            [-0.1431196858529918, 51.47693908781434],
            [-0.1430599547275303, 51.47689469546789],
            [-0.1431394467870615, 51.47685629964052],
            [-0.1430804467616462, 51.476800215290645],
            [-0.14322238127980882, 51.47672951336327],
            [-0.14328505484297344, 51.47679349375595],
            [-0.1433491092572151, 51.476835389196246],
            [-0.143429288379253, 51.476919312656555],
            [-0.14357257224332148, 51.47686035303679],
            [-0.14385322806383855, 51.47710366770165],
          ],
        ],
      },
    },
    {
      id: "35",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 243.8082641963631,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 698333.192833,
        centroid_y: 5706715.83315,
        Fecha_ejec: "2024-05-29",
        a_amianto: 243.8082641963631,
        Reti_cost: 5226.16528393,
        CuBasic_C: 4876.16528393,
        LiBasic_C: 404.09322271440004,
        CuNormal_C: 21942.7437777,
        LiNormal_C: 1086.75636247,
        m3_amianto: 1.462849585178179,
        ratio: 100.0,
        id: 106,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 113.67056901048896,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.72166666666668,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.58166666666669,
        E_d: 68.56,
        E_m: 2085.33,
        E_y: 25023.96,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 77.13,
        SD_y: 925.53,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 182159.17837777833,
        ton_co2_avoid: 47.06993169281792,
        energy_price_saved: 33207.61821826899,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14405225848009848, 51.47678617297692],
            [-0.14382405491299677, 51.476870386298195],
            [-0.143748086153825, 51.47681909306161],
            [-0.14371499344859107, 51.47678185582445],
            [-0.14395849017450926, 51.47668630562577],
            [-0.14398734106371225, 51.476724740500785],
            [-0.14405225848009848, 51.47678617297692],
          ],
        ],
      },
    },
    {
      id: "36",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 255.98716814317555,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698552.067246,
        centroid_y: 5706536.71526,
        Fecha_ejec: "2024-05-29",
        a_amianto: 255.98716814317555,
        Reti_cost: 2909.87168143,
        CuBasic_C: 5119.74336286,
        LiBasic_C: 321.1846017716,
        CuNormal_C: 23038.845132899998,
        LiNormal_C: 1037.94867257,
        m3_amianto: 1.535923008859053,
        ratio: 100.0,
        id: 107,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 119.34848827774984,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.72083333333335,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.58083333333336,
        E_d: 68.56,
        E_m: 2085.33,
        E_y: 25024.01,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 77.13,
        SD_y: 925.54,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 191258.1484772784,
        ton_co2_avoid: 49.42110556652874,
        energy_price_saved: 34866.360467407845,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.141004367209543, 51.475132321232536],
            [-0.14094033265182546, 51.475156780728724],
            [-0.1409196680698896, 51.47513741066547],
            [-0.14078719433065984, 51.47519012533779],
            [-0.14073136040055545, 51.47513346645443],
            [-0.14067228423280895, 51.475078680067924],
            [-0.14087635772094453, 51.47499713686652],
            [-0.14093386557022425, 51.475060341993334],
            [-0.141004367209543, 51.475132321232536],
          ],
        ],
      },
    },
    {
      id: "37",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "2006-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 438.4405417321636,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698522.149595,
        centroid_y: 5706480.65386,
        Fecha_ejec: "2024-05-29",
        a_amianto: 438.4405417321636,
        Reti_cost: 4734.40541732,
        CuBasic_C: 8768.81083464,
        LiBasic_C: 540.1286500784,
        CuNormal_C: 39459.6487559,
        LiNormal_C: 1767.76216693,
        m3_amianto: 2.630643250392982,
        ratio: 100.0,
        id: 108,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 218.85804422762496,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24833333333333,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.70750000000001,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.5791666666667,
        E_d: 114.26,
        E_m: 3475.56,
        E_y: 41706.72,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 128.55,
        SD_y: 1542.56,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 350724.0428627828,
        ton_co2_avoid: 90.62709267574307,
        energy_price_saved: 63936.993013885294,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14151840481613134, 51.47472525925325],
            [-0.14137777435368667, 51.47477517341362],
            [-0.1410773715080285, 51.47448160386248],
            [-0.14122416090048218, 51.474433141419745],
            [-0.14151840481613134, 51.47472525925325],
          ],
        ],
      },
    },
    {
      id: "38",
      type: "Feature",
      properties: {
        Fecha_cons: "1999-01-01",
        Fecha_fin: "1999-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 292.80638030570157,
        Decada: "1990",
        Antiguedad: 25,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698590.169374,
        centroid_y: 5706484.67615,
        Fecha_ejec: "2024-05-29",
        a_amianto: 292.80638030570157,
        Reti_cost: 3278.06380306,
        CuBasic_C: 5856.12760611,
        LiBasic_C: 365.3676563668,
        CuNormal_C: 26352.574227499998,
        LiNormal_C: 1185.22552122,
        m3_amianto: 1.756838281834209,
        ratio: 100.0,
        id: 109,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 146.16125839098265,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.72000000000001,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.58000000000003,
        E_d: 114.26,
        E_m: 3475.56,
        E_y: 41706.71,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 128.55,
        SD_y: 1542.56,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 234226.10593870407,
        ton_co2_avoid: 60.52402577456113,
        energy_price_saved: 42699.41911262575,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.1404896751330549, 51.47465071788244],
            [-0.14031637632664656, 51.47472325325129],
            [-0.14022682871874037, 51.47465601313035],
            [-0.14015249368553498, 51.47457873546617],
            [-0.14032587348066058, 51.4745049012236],
            [-0.14040661210111108, 51.47457973286184],
            [-0.1404896751330549, 51.47465071788244],
          ],
        ],
      },
    },
    {
      id: "39",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "2000-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1676.18492278,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 698562.974529,
        centroid_y: 5706442.90406,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1676.18492278,
        Reti_cost: 17111.8492278,
        CuBasic_C: 33523.6984556,
        LiBasic_C: 2025.42190734,
        CuNormal_C: 150856.64305,
        LiNormal_C: 6718.73969111,
        m3_amianto: 10.05710953668,
        ratio: 100.0,
        id: 110,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 841.7289877499824,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.14135525109336125, 51.47423597110138],
            [-0.14109247112450957, 51.474340153030354],
            [-0.14105681442313653, 51.47431065900116],
            [-0.14121938413694377, 51.474243065174186],
            [-0.1411328915994743, 51.47416028715335],
            [-0.14032587348066058, 51.4745049012236],
            [-0.14028845256720898, 51.47452025164882],
            [-0.1402104197776587, 51.47446044854584],
            [-0.1401319906080025, 51.47438201380434],
            [-0.14105799713776532, 51.473983706683015],
            [-0.14114609823680868, 51.47405741635293],
            [-0.14135525109336125, 51.47423597110138],
          ],
        ],
      },
    },
    {
      id: "40",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 453.0363930910125,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 698720.004753,
        centroid_y: 5706291.76233,
        Fecha_ejec: "2024-05-29",
        a_amianto: 453.0363930910125,
        Reti_cost: 4880.36393091,
        CuBasic_C: 9060.72786182,
        LiBasic_C: 557.6436717092,
        CuNormal_C: 40773.2753782,
        LiNormal_C: 1826.14557236,
        m3_amianto: 2.718218358546075,
        ratio: 100.0,
        id: 111,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 226.14365382764183,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71166666666667,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.5741666666667,
        E_d: 114.27,
        E_m: 3475.57,
        E_y: 41706.79,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.36,
        SD_m: 128.55,
        SD_y: 1542.56,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 362399.36630202644,
        ton_co2_avoid: 93.64399625244363,
        energy_price_saved: 66065.40447685942,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.13876271910100632, 51.47290843775514],
            [-0.13866675159573807, 51.472943826096284],
            [-0.13856429933144868, 51.47298295928647],
            [-0.13835297901611474, 51.472764419091035],
            [-0.13846766803607702, 51.47272948820533],
            [-0.1385555585195726, 51.472689999502684],
            [-0.13876271910100632, 51.47290843775514],
          ],
        ],
      },
    },
    {
      id: "41",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 123.12045304985143,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 698733.367731,
        centroid_y: 5706214.366150001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 123.12045304985143,
        Reti_cost: 2812.409061,
        CuBasic_C: 2462.409061,
        LiBasic_C: 210.99272488,
        CuNormal_C: 11080.8407745,
        LiNormal_C: 555.72999342,
        m3_amianto: 0.738722718299109,
        ratio: 100.0,
        id: 112,
        superficie_cubierta: 145.0,
        Potencia_instalada_kWp: 15.0,
        n_modulos: 24.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1026,
        "Coste \u20ac/kWp": 1.282,
        Lucernario: "false",
        superficie_fv_percent: 34.8,
        superficie_placas: 42.77507987074741,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71166666666667,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57166666666666,
        E_d: 34.28,
        E_m: 1042.67,
        E_y: 12512.05,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.36,
        SD_m: 38.56,
        SD_y: 462.77,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 68547.85255434235,
        ton_co2_avoid: 17.712765100042063,
        energy_price_saved: 12496.273520656609,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.13851339146105018, 51.47216560434887],
            [-0.13845052193755375, 51.47218813927547],
            [-0.13838181858905724, 51.472204026221966],
            [-0.13830446907472918, 51.47210845834724],
            [-0.13836156875815525, 51.47209489025355],
            [-0.13843932464608127, 51.47206751454031],
            [-0.13851339146105018, 51.47216560434887],
          ],
        ],
      },
    },
    {
      id: "42",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 357.5748168215342,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698712.308128,
        centroid_y: 5706200.40679,
        Fecha_ejec: "2024-05-29",
        a_amianto: 357.5748168215342,
        Reti_cost: 3925.7481682200005,
        CuBasic_C: 7151.49633643,
        LiBasic_C: 443.089780186,
        CuNormal_C: 32181.7335139,
        LiNormal_C: 1444.29926728,
        m3_amianto: 2.145448900929205,
        ratio: 100.0,
        id: 113,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 178.49185885147128,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71500000000002,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57583333333336,
        E_d: 114.27,
        E_m: 3475.57,
        E_y: 41706.84,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 128.55,
        SD_y: 1542.57,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 286036.48805968557,
        ton_co2_avoid: 73.91182851462275,
        energy_price_saved: 52144.45177328068,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.1389436004157861, 51.47203250118637],
            [-0.13857483862160774, 51.47211587429818],
            [-0.1385364077087129, 51.472064193102035],
            [-0.13850778493553148, 51.47200559539194],
            [-0.1388703054988632, 51.47192207010121],
            [-0.13890213024837095, 51.471979444808525],
            [-0.1389436004157861, 51.47203250118637],
          ],
        ],
      },
    },
    {
      id: "43",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 214.0855654472665,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 698825.529175,
        centroid_y: 5706211.47511,
        Fecha_ejec: "2024-05-29",
        a_amianto: 214.0855654472665,
        Reti_cost: 4631.71130895,
        CuBasic_C: 4281.71130895,
        LiBasic_C: 356.536904716,
        CuNormal_C: 19267.7008903,
        LiNormal_C: 955.97648797,
        m3_amianto: 1.284513392683599,
        ratio: 100.0,
        id: 114,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 99.81255727521541,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71500000000002,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57583333333336,
        E_d: 68.56,
        E_m: 2085.34,
        E_y: 25024.1,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 77.13,
        SD_y: 925.54,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 159951.45958458656,
        ton_co2_avoid: 41.331457156657166,
        energy_price_saved: 29159.151082270128,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.13725018150878834, 51.472043835116246],
            [-0.13703574214315223, 51.472174560316475],
            [-0.1369596894770884, 51.472141475789606],
            [-0.13692883061310457, 51.47211860316193],
            [-0.1370549626424347, 51.47203402372934],
            [-0.13714911123399037, 51.471977774746236],
            [-0.1371855595481293, 51.472011192640146],
            [-0.13725018150878834, 51.472043835116246],
          ],
        ],
      },
    },
    {
      id: "44",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 231.5456564631752,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 698741.9557679999,
        centroid_y: 5706105.43059,
        Fecha_ejec: "2024-05-29",
        a_amianto: 231.5456564631752,
        Reti_cost: 4980.91312926,
        CuBasic_C: 4630.91312926,
        LiBasic_C: 384.4730503408,
        CuNormal_C: 20839.1090817,
        LiNormal_C: 1032.80088844,
        m3_amianto: 1.389273938779051,
        ratio: 100.0,
        id: 115,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 107.95311122598414,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71333333333335,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57583333333336,
        E_d: 68.56,
        E_m: 2085.34,
        E_y: 25024.13,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 77.13,
        SD_y: 925.54,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 172996.84707688616,
        ton_co2_avoid: 44.70238528466738,
        energy_price_saved: 31537.325222116346,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.13849439471097755, 51.47120460703605],
            [-0.1383079107431809, 51.471271612999274],
            [-0.1382769704162798, 51.47125003983086],
            [-0.13824692491247303, 51.47121417646154],
            [-0.13834333642472812, 51.47117164330664],
            [-0.13825102116636262, 51.471082215344666],
            [-0.1382579935927957, 51.47107067577596],
            [-0.13822415405403235, 51.471028864808474],
            [-0.13825932993468248, 51.47101606241558],
            [-0.1382837857460999, 51.47104138050641],
            [-0.1383195136205333, 51.4710363981478],
            [-0.13842378089024066, 51.47113457506209],
            [-0.13849439471097755, 51.47120460703605],
          ],
        ],
      },
    },
    {
      id: "45",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 208.07720153869406,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 698701.359748,
        centroid_y: 5706088.19158,
        Fecha_ejec: "2024-05-29",
        a_amianto: 208.07720153869406,
        Reti_cost: 4511.54403077,
        CuBasic_C: 4161.54403077,
        LiBasic_C: 346.9235224616,
        CuNormal_C: 18726.948138500004,
        LiNormal_C: 929.5396867708,
        m3_amianto: 1.248463209232164,
        ratio: 100.0,
        id: 116,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 97.01146677149308,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71333333333335,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57500000000003,
        E_d: 68.56,
        E_m: 2085.34,
        E_y: 25024.14,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 77.13,
        SD_y: 925.54,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 155462.66051230623,
        ton_co2_avoid: 40.17155147637993,
        energy_price_saved: 28340.843011393426,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.13907971206056216, 51.471056262722726],
            [-0.13893968327005024, 51.47111334461321],
            [-0.13887314778310628, 51.47104471359084],
            [-0.13880557244051947, 51.470976057136674],
            [-0.1389425621337561, 51.47091760010664],
            [-0.13907971206056216, 51.471056262722726],
          ],
        ],
      },
    },
    {
      id: "46",
      type: "Feature",
      properties: {
        Fecha_cons: "1982-01-01",
        Fecha_fin: "1982-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 258.69707889762657,
        Decada: "1980",
        Antiguedad: 42,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 698672.214249,
        centroid_y: 5706127.15082,
        Fecha_ejec: "2024-05-29",
        a_amianto: 258.69707889762657,
        Reti_cost: 2936.97078898,
        CuBasic_C: 5173.94157795,
        LiBasic_C: 324.4364946772,
        CuNormal_C: 23282.7371008,
        LiNormal_C: 1048.78831559,
        m3_amianto: 1.552182473385759,
        ratio: 100.0,
        id: 117,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 120.61183665508428,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 85.24916666666667,
        temperature_year: 2016.0,
        temperature_mean: 10.958333333333334,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 74.71333333333335,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 100.57583333333336,
        E_d: 68.56,
        E_m: 2085.34,
        E_y: 25024.13,
        "H(i)_d": 2.87,
        "H(i)_m": 87.45,
        "H(i)_y": 1049.37,
        SD_m: 77.13,
        SD_y: 925.54,
        l_aoi: -4.67,
        l_spec: "1.70",
        l_tg: -4.67,
        l_total: -20.51,
        solar_energy_total: 193282.68749756698,
        ton_co2_avoid: 49.944246449371306,
        energy_price_saved: 35235.43393080646,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.13949956187764775, 51.47143797559975],
            [-0.1394277634784806, 51.47147005127619],
            [-0.139368056209402, 51.47149201333757],
            [-0.1391785050413835, 51.47130848503746],
            [-0.13930756435227296, 51.471260242715644],
            [-0.13949956187764775, 51.47143797559975],
          ],
        ],
      },
    },
    {
      id: "47",
      type: "Feature",
      properties: {
        Fecha_cons: "1985-01-01",
        Fecha_fin: "1985-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 17.12026623496816,
        Decada: "1980",
        Antiguedad: 39,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 709680.341598,
        centroid_y: 5708310.57141,
        Fecha_ejec: "2024-05-29",
        a_amianto: 17.12026623496816,
        Reti_cost: 863.6079870490448,
        CuBasic_C: 342.4053246993632,
        LiBasic_C: 48.24053246993632,
        CuNormal_C: 1540.82396115,
        LiNormal_C: 96.17727792796181,
        m3_amianto: 0.102721597409809,
        ratio: 100.0,
        id: 1,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 6.049870187529087,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.020216440124079443, 51.48702165444989],
            [0.020229954451610892, 51.48702374185312],
            [0.020278429923391992, 51.48702353628614],
            [0.020289654844040824, 51.48701628773209],
            [0.0202842159141465, 51.487001465577634],
            [0.020219765746872707, 51.487004518620594],
            [0.02021786685096398, 51.48700108794909],
            [0.020196866696617286, 51.48699536591141],
            [0.02018666779005396, 51.486992845090185],
            [0.020159279259253052, 51.48700851335213],
            [0.020183594827996844, 51.48701380198258],
            [0.020211322803230555, 51.48701169546368],
            [0.02021632527164184, 51.487019917602744],
            [0.020216440124079443, 51.48702165444989],
          ],
        ],
      },
    },
    {
      id: "48",
      type: "Feature",
      properties: {
        Fecha_cons: "1958-01-01",
        Fecha_fin: "1958-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 299.1987084615368,
        Decada: "1950",
        Antiguedad: 66,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 709824.1877630001,
        centroid_y: 5708319.14742,
        Fecha_ejec: "2024-05-29",
        a_amianto: 299.1987084615368,
        Reti_cost: 3341.98708462,
        CuBasic_C: 5983.97416923,
        LiBasic_C: 373.038450154,
        CuNormal_C: 26927.8837615,
        LiNormal_C: 1210.79483384,
        m3_amianto: 1.79519225076922,
        ratio: 100.0,
        id: 2,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 149.33480754106318,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.29916666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86166666666666,
        E_d: 114.81,
        E_m: 3492.15,
        E_y: 41905.85,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 127.02,
        SD_y: 1524.21,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 239552.62402261473,
        ton_co2_avoid: 61.900398047443645,
        energy_price_saved: 43670.443359322664,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.02219141459835586, 51.487135667400594],
            [0.02228599273224496, 51.48714993021222],
            [0.022361846714383692, 51.48715075743459],
            [0.022416312068654038, 51.48692944084527],
            [0.022252373618602703, 51.48691139972012],
            [0.02219141459835586, 51.487135667400594],
          ],
        ],
      },
    },
    {
      id: "49",
      type: "Feature",
      properties: {
        Fecha_cons: "1973-01-01",
        Fecha_fin: "1973-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 228.2123482222945,
        Decada: "1970",
        Antiguedad: 51,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 709759.694383,
        centroid_y: 5708381.81346,
        Fecha_ejec: "2024-05-29",
        a_amianto: 228.2123482222945,
        Reti_cost: 4914.24696445,
        CuBasic_C: 4564.24696445,
        LiBasic_C: 379.139757156,
        CuNormal_C: 20539.11134,
        LiNormal_C: 1018.13433218,
        m3_amianto: 1.369274089333767,
        ratio: 100.0,
        id: 3,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 106.38680696464836,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.3,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86166666666666,
        E_d: 68.89,
        E_m: 2095.29,
        E_y: 25143.49,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 76.21,
        SD_y: 914.52,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 170658.3963203697,
        ton_co2_avoid: 44.09812960918353,
        energy_price_saved: 31111.025649203395,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.02128302287622696, 51.48767616347084],
            [0.021543695974976185, 51.48767361763314],
            [0.02155874317223104, 51.48763147424183],
            [0.02154329465327666, 51.487566455823554],
            [0.0212746486872333, 51.487566423542845],
            [0.02128302287622696, 51.48767616347084],
          ],
        ],
      },
    },
    {
      id: "50",
      type: "Feature",
      properties: {
        Fecha_cons: "1958-01-01",
        Fecha_fin: "1958-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1024.75856969,
        Decada: "1950",
        Antiguedad: 66,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 709880.7059400001,
        centroid_y: 5708378.66174,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1024.75856969,
        Reti_cost: 10597.5856969,
        CuBasic_C: 20495.1713938,
        LiBasic_C: 1243.71028363,
        CuNormal_C: 92228.2712721,
        LiNormal_C: 4113.03427876,
        m3_amianto: 6.14855141814,
        ratio: 100.0,
        id: 4,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 476.69245691580863,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.3,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86166666666666,
        E_d: 344.43,
        E_m: 10476.46,
        E_y: 125717.48,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 381.05,
        SD_y: 4572.61,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 764677.2429433044,
        ton_co2_avoid: 197.59259957654984,
        energy_price_saved: 139400.6613885644,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.023014230221414158, 51.487795055905146],
            [0.023268962698250555, 51.487803794261794],
            [0.023290077648275866, 51.48768494251429],
            [0.02327893025161303, 51.48755022099408],
            [0.023291031372950556, 51.48729520082192],
            [0.02302990085196404, 51.48729080314038],
            [0.023014230221414158, 51.487795055905146],
          ],
        ],
      },
    },
    {
      id: "51",
      type: "Feature",
      properties: {
        Fecha_cons: "1958-01-01",
        Fecha_fin: "1958-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 3798.59040559,
        Decada: "1950",
        Antiguedad: 66,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 709915.030409,
        centroid_y: 5708423.873210001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 3798.59040559,
        Reti_cost: 38335.9040559,
        CuBasic_C: 75971.8081118,
        LiBasic_C: 4572.30848671,
        CuNormal_C: 341873.136503,
        LiNormal_C: 15208.3616224,
        m3_amianto: 22.79154243354,
        ratio: 100.0,
        id: 5,
        superficie_cubierta: 3654.0,
        Potencia_instalada_kWp: 500.0,
        n_modulos: 805.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1366,
        "Coste \u20ac/kWp": 0.536,
        Lucernario: "false",
        superficie_fv_percent: 46.3,
        superficie_placas: 1755.6445021532481,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.3,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86416666666668,
        E_d: 1148.1,
        E_m: 34921.51,
        E_y: 419058.07,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 1270.17,
        SD_y: 15242.04,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 2816284.1220124927,
        ton_co2_avoid: 727.727817128028,
        energy_price_saved: 513408.5954428774,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.023382181817508584, 51.488503755649724],
            [0.0238705429719649, 51.488505420679864],
            [0.023883750286122496, 51.48786309052765],
            [0.023878043157255505, 51.48760852982882],
            [0.023894153268521397, 51.48748145602593],
            [0.02389239143236529, 51.487219138345125],
            [0.023422147709106125, 51.48722152957429],
            [0.023422019588907543, 51.48728694968024],
            [0.023633451105061994, 51.48728149414365],
            [0.02363214780099058, 51.48746385971152],
            [0.02362734898647215, 51.487593425293305],
            [0.02362509773372705, 51.487727796600105],
            [0.02361575566956816, 51.48785608756191],
            [0.023406087207384327, 51.48785453831766],
            [0.023382181817508584, 51.488503755649724],
          ],
        ],
      },
    },
    {
      id: "52",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 713.3955600085967,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 709946.94111,
        centroid_y: 5708788.26431,
        Fecha_ejec: "2024-05-29",
        a_amianto: 713.3955600085967,
        Reti_cost: 7483.95560009,
        CuBasic_C: 14267.9112002,
        LiBasic_C: 870.0746720116001,
        CuNormal_C: 64205.600400799995,
        LiNormal_C: 2867.58224004,
        m3_amianto: 4.280373360051582,
        ratio: 100.0,
        id: 6,
        superficie_cubierta: 675.0,
        Potencia_instalada_kWp: 100.0,
        n_modulos: 160.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.147,
        "Coste \u20ac/kWp": 0.583,
        Lucernario: "false",
        superficie_fv_percent: 49.8,
        superficie_placas: 354.6424740175413,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30499999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.8675,
        E_d: 229.62,
        E_m: 6984.27,
        E_y: 83811.28,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 254.03,
        SD_y: 3048.39,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 568893.0574167273,
        ton_co2_avoid: 147.00196603648234,
        energy_price_saved: 103709.20436706938,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.024022875415291443, 51.49121002292262],
            [0.02410968102611792, 51.49120778276215],
            [0.024111889365686586, 51.49124113008334],
            [0.0241987870453263, 51.49124027932761],
            [0.0242005353673004, 51.49126667928794],
            [0.02429179264575748, 51.49126432410564],
            [0.024290469743756156, 51.4913116810243],
            [0.024615342893285198, 51.49130190422879],
            [0.024591755856523054, 51.491181422396444],
            [0.0245935556862587, 51.491107608077385],
            [0.024008359608099984, 51.49112549489573],
            [0.024022875415291443, 51.49121002292262],
          ],
        ],
      },
    },
    {
      id: "53",
      type: "Feature",
      properties: {
        Fecha_cons: "1982-01-01",
        Fecha_fin: "1982-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 7729.347859640001,
        Decada: "1980",
        Antiguedad: 42,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710072.977429,
        centroid_y: 5708741.90674,
        Fecha_ejec: "2024-05-29",
        a_amianto: 7729.347859640001,
        Reti_cost: 77643.4785964,
        CuBasic_C: 154586.95719299998,
        LiBasic_C: 9289.21743158,
        CuNormal_C: 695641.307368,
        LiNormal_C: 30931.3914386,
        m3_amianto: 46.37608715784,
        ratio: 100.0,
        id: 7,
        superficie_cubierta: 7308.0,
        Potencia_instalada_kWp: 1000.0,
        n_modulos: 1627.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.138,
        "Coste \u20ac/kWp": 0.517,
        Lucernario: "false",
        superficie_fv_percent: 46.8,
        superficie_placas: 3610.918357045908,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30333333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.8675,
        E_d: 2296.2,
        E_m: 69842.77,
        E_y: 838113.25,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.27,
        SD_m: 2540.33,
        SD_y: 30483.97,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 5792386.79719008,
        ton_co2_avoid: 1496.7527483939168,
        energy_price_saved: 1055952.1131277515,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.025186832222046294, 51.491110735383366],
            [0.02639848045308054, 51.49129379334701],
            [0.026859436086598878, 51.49010786441957],
            [0.026269850415944557, 51.49002566306942],
            [0.02603010377251534, 51.49064705084231],
            [0.025960104184362513, 51.490801961622665],
            [0.02534751694308598, 51.49070921475495],
            [0.025186832222046294, 51.491110735383366],
          ],
        ],
      },
    },
    {
      id: "54",
      type: "Feature",
      properties: {
        Fecha_cons: "1976-01-01",
        Fecha_fin: "1976-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2005.17011995,
        Decada: "1970",
        Antiguedad: 48,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710177.464999,
        centroid_y: 5708705.3155,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2005.17011995,
        Reti_cost: 20401.701199500003,
        CuBasic_C: 40103.402399000006,
        LiBasic_C: 2420.2041439399995,
        CuNormal_C: 180465.310796,
        LiNormal_C: 8034.68047982,
        m3_amianto: 12.0310207197,
        ratio: 100.0,
        id: 8,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 910.7353330590603,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.3025,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86666666666667,
        E_d: 688.86,
        E_m: 20952.84,
        E_y: 251434.09,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.27,
        SD_m: 762.1,
        SD_y: 9145.2,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1460938.9627024299,
        ton_co2_avoid: 377.50662796230785,
        energy_price_saved: 266329.1729006529,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.02713869646239371, 51.49045278796561],
            [0.027985248430159204, 51.49057705986714],
            [0.028095178038357958, 51.4902847149007],
            [0.027235645316890468, 51.49016634657289],
            [0.02713869646239371, 51.49045278796561],
          ],
        ],
      },
    },
    {
      id: "55",
      type: "Feature",
      properties: {
        Fecha_cons: "1985-01-01",
        Fecha_fin: "1985-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 126.03102059965168,
        Decada: "1980",
        Antiguedad: 39,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 710119.674644,
        centroid_y: 5708815.88866,
        Fecha_ejec: "2024-05-29",
        a_amianto: 126.03102059965168,
        Reti_cost: 2870.6204119899994,
        CuBasic_C: 2520.6204119899994,
        LiBasic_C: 215.6496329592,
        CuNormal_C: 11342.791854,
        LiNormal_C: 568.5364906396,
        m3_amianto: 0.75618612359791,
        ratio: 100.0,
        id: 9,
        superficie_cubierta: 145.0,
        Potencia_instalada_kWp: 15.0,
        n_modulos: 24.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1026,
        "Coste \u20ac/kWp": 1.282,
        Lucernario: "false",
        superficie_fv_percent: 34.8,
        superficie_placas: 43.78108389243024,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.02675036851050371, 51.49142249762101],
            [0.026903518142488304, 51.49144568275521],
            [0.026930480946044638, 51.491398359402176],
            [0.026942515583965782, 51.491344462419775],
            [0.026783939957641296, 51.491323505262656],
            [0.02677733146158848, 51.49137517430066],
            [0.02675036851050371, 51.49142249762101],
          ],
        ],
      },
    },
    {
      id: "56",
      type: "Feature",
      properties: {
        Fecha_cons: "1979-01-01",
        Fecha_fin: "1979-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2518.56409427,
        Decada: "1970",
        Antiguedad: 45,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710284.108324,
        centroid_y: 5708808.828170001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2518.56409427,
        Reti_cost: 25535.6409427,
        CuBasic_C: 50371.2818854,
        LiBasic_C: 3036.27691312,
        CuNormal_C: 226670.768484,
        LiNormal_C: 10088.2563771,
        m3_amianto: 15.111384565620002,
        ratio: 100.0,
        id: 10,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 1143.9164666711363,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30583333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86833333333334,
        E_d: 688.86,
        E_m: 20952.82,
        E_y: 251433.81,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.27,
        SD_m: 762.1,
        SD_y: 9145.19,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1834992.0943809298,
        ton_co2_avoid: 474.16195718803226,
        energy_price_saved: 334519.05880564346,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.028868493256919554, 51.49167467065849],
            [0.029215045018189233, 51.4917227783729],
            [0.029254952397385892, 51.49165215435196],
            [0.02927223304499395, 51.49157654785184],
            [0.029301103446508703, 51.49150760097687],
            [0.02932951289744498, 51.49143170677068],
            [0.02936274251820692, 51.49136125533688],
            [0.029379838547743856, 51.49128286989247],
            [0.02941392061333777, 51.491225270996345],
            [0.029441961015802817, 51.49114381890601],
            [0.029472803194545493, 51.4910709934226],
            [0.029492493417844868, 51.490998108280316],
            [0.029527671724189544, 51.490923430867156],
            [0.029556103518122108, 51.49084788397777],
            [0.02920047076642301, 51.49079722838143],
            [0.028868493256919554, 51.49167467065849],
          ],
        ],
      },
    },
    {
      id: "57",
      type: "Feature",
      properties: {
        Fecha_cons: "1979-01-01",
        Fecha_fin: "1979-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2566.42211898,
        Decada: "1970",
        Antiguedad: 45,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710276.88281,
        centroid_y: 5708723.04395,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2566.42211898,
        Reti_cost: 26014.2211898,
        CuBasic_C: 51328.4423796,
        LiBasic_C: 3093.70654278,
        CuNormal_C: 230977.990708,
        LiNormal_C: 10279.6884759,
        m3_amianto: 15.39853271388,
        ratio: 100.0,
        id: 11,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 1165.6504745848167,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30333333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86666666666667,
        E_d: 688.86,
        E_m: 20952.84,
        E_y: 251434.05,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.27,
        SD_m: 762.1,
        SD_y: 9145.19,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1869856.2945763113,
        ton_co2_avoid: 483.17086651851884,
        energy_price_saved: 340874.8025012615,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.028653438151178476, 51.49071811443382],
            [0.029184798319226567, 51.490796241674936],
            [0.029247488111555874, 51.49080018848835],
            [0.02928174498595851, 51.49071161649051],
            [0.02930524168660205, 51.49062889003979],
            [0.029346359900246168, 51.490542924338236],
            [0.029376257106793126, 51.490455856860024],
            [0.02941508018107514, 51.49036890657814],
            [0.02944813818733297, 51.49026227149008],
            [0.028858638552960853, 51.49018147274348],
            [0.028831795631504502, 51.490280987769616],
            [0.02876532200837111, 51.490455295061984],
            [0.028726520659220888, 51.4905425925154],
            [0.02869662215689528, 51.4906296598479],
            [0.028653438151178476, 51.49071811443382],
          ],
        ],
      },
    },
    {
      id: "58",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2205.58775817,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710351.327775,
        centroid_y: 5708530.12328,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2205.58775817,
        Reti_cost: 22405.8775817,
        CuBasic_C: 44111.7551634,
        LiBasic_C: 2660.7053098,
        CuNormal_C: 198502.898235,
        LiNormal_C: 8836.35103267,
        m3_amianto: 13.23352654902,
        ratio: 100.0,
        id: 12,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 1001.7641415205039,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30083333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86250000000001,
        E_d: 688.86,
        E_m: 20952.88,
        E_y: 251434.59,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 762.1,
        SD_y: 9145.22,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1606961.1144542557,
        ton_co2_avoid: 415.23875197497966,
        energy_price_saved: 292949.0111650108,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.029690105718898845, 51.4890701610175],
            [0.03006807431690175, 51.48912162929594],
            [0.030329731189437402, 51.488394581354335],
            [0.030064525007990523, 51.48836246773235],
            [0.03008094728672271, 51.48834116534724],
            [0.0299706928901404, 51.488325922295594],
            [0.029690105718898845, 51.4890701610175],
          ],
        ],
      },
    },
    {
      id: "59",
      type: "Feature",
      properties: {
        Fecha_cons: "1976-01-01",
        Fecha_fin: "1976-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1035.87824954,
        Decada: "1970",
        Antiguedad: 48,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710339.780432,
        centroid_y: 5708469.76591,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1035.87824954,
        Reti_cost: 10708.7824954,
        CuBasic_C: 20717.5649908,
        LiBasic_C: 1257.05389945,
        CuNormal_C: 93229.0424586,
        LiNormal_C: 4157.51299816,
        m3_amianto: 6.21526949724,
        ratio: 100.0,
        id: 13,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 481.8625585174172,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.3,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86250000000001,
        E_d: 344.43,
        E_m: 10476.45,
        E_y: 125717.38,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 381.05,
        SD_y: 4572.61,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 772970.7642296146,
        ton_co2_avoid: 199.7356454769324,
        energy_price_saved: 140912.57031905875,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.029227202114113372, 51.48820874377387],
            [0.029925857606376213, 51.4883222101555],
            [0.029929387056454648, 51.488308200408156],
            [0.030084614969537155, 51.48832923979312],
            [0.030123978260673208, 51.48821687401732],
            [0.030162037626142963, 51.48811846042976],
            [0.0296779061901479, 51.488046076019295],
            [0.029626399911324184, 51.488143445260775],
            [0.02958899809052725, 51.488218180238995],
            [0.02955955799270753, 51.488211286227425],
            [0.029577659832764808, 51.48816488721871],
            [0.02952721458578177, 51.488160624045506],
            [0.02950888227491129, 51.4882035493835],
            [0.029242749214848318, 51.48817424157455],
            [0.029227202114113372, 51.48820874377387],
          ],
        ],
      },
    },
    {
      id: "60",
      type: "Feature",
      properties: {
        Fecha_cons: "1976-01-01",
        Fecha_fin: "1976-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 322.2222825504783,
        Decada: "1970",
        Antiguedad: 48,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710248.449605,
        centroid_y: 5708487.93933,
        Fecha_ejec: "2024-05-29",
        a_amianto: 322.2222825504783,
        Reti_cost: 3572.2228254999995,
        CuBasic_C: 6444.44565101,
        LiBasic_C: 400.6667390604,
        CuNormal_C: 29000.0054295,
        LiNormal_C: 1302.8891302,
        m3_amianto: 1.93333369530287,
        ratio: 100.0,
        id: 14,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 160.82540936326444,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.3,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86416666666668,
        E_d: 114.81,
        E_m: 3492.15,
        E_y: 41905.78,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 127.02,
        SD_y: 1524.2,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 257985.05691238464,
        ton_co2_avoid: 66.66333870616019,
        energy_price_saved: 47030.675875127716,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.028372894128891057, 51.48863933309971],
            [0.028449487631233093, 51.48865127207281],
            [0.028641383308689095, 51.48814942265952],
            [0.028562380704030815, 51.48813476238748],
            [0.028372894128891057, 51.48863933309971],
          ],
        ],
      },
    },
    {
      id: "61",
      type: "Feature",
      properties: {
        Fecha_cons: "1982-01-01",
        Fecha_fin: "2017-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1017.311527,
        Decada: "1980",
        Antiguedad: 42,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 709983.6011310001,
        centroid_y: 5709065.77127,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1017.311527,
        Reti_cost: 10523.11527,
        CuBasic_C: 20346.23054,
        LiBasic_C: 1234.7738324,
        CuNormal_C: 91558.03743,
        LiNormal_C: 4083.246108,
        m3_amianto: 6.103869162,
        ratio: 100.0,
        id: 15,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 473.2276355420709,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.0247817301417069, 51.49379701741655],
            [0.024858515603355117, 51.4938117375425],
            [0.024920656986488838, 51.49380734978711],
            [0.02498427114358146, 51.49382519351864],
            [0.02502832909748633, 51.493817096970695],
            [0.025086570962625083, 51.49382116086739],
            [0.02513980869282022, 51.4938170028413],
            [0.02519359874586957, 51.49382118160943],
            [0.025251472397970612, 51.493819687545106],
            [0.02530938594253695, 51.493549566096334],
            [0.025253830588646334, 51.49355239218903],
            [0.02519503672745099, 51.49353999155132],
            [0.025137347532473476, 51.49354426452175],
            [0.025080779606960237, 51.493531806363826],
            [0.025034680139671306, 51.493542739327374],
            [0.024953351242761084, 51.49352674475717],
            [0.02495491604861772, 51.49355036574859],
            [0.02489991293961757, 51.493561528494475],
            [0.02480958842060907, 51.49354437418444],
            [0.0247817301417069, 51.49379701741655],
          ],
        ],
      },
    },
    {
      id: "62",
      type: "Feature",
      properties: {
        Fecha_cons: "1976-01-01",
        Fecha_fin: "1976-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 342.4322633362605,
        Decada: "1970",
        Antiguedad: 48,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710044.93546,
        centroid_y: 5709013.25122,
        Fecha_ejec: "2024-05-29",
        a_amianto: 342.4322633362605,
        Reti_cost: 3774.32263336,
        CuBasic_C: 6848.64526673,
        LiBasic_C: 424.9187160036,
        CuNormal_C: 30818.9037003,
        LiNormal_C: 1383.72905335,
        m3_amianto: 2.054593580017563,
        ratio: 100.0,
        id: 16,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 170.91278444579638,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87333333333333,
        E_d: 114.81,
        E_m: 3492.13,
        E_y: 41905.54,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 127.02,
        SD_y: 1524.19,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 274166.5300083767,
        ton_co2_avoid: 70.84463135416453,
        energy_price_saved: 49980.55842052707,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.025774635342549212, 51.493268927573816],
            [0.026056953939157575, 51.49325607015359],
            [0.026037629475825528, 51.49313269482954],
            [0.025740039106202255, 51.49308331344569],
            [0.025774635342549212, 51.493268927573816],
          ],
        ],
      },
    },
    {
      id: "63",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 60.59402415290657,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 710104.864607,
        centroid_y: 5709026.09289,
        Fecha_ejec: "2024-05-29",
        a_amianto: 60.59402415290657,
        Reti_cost: 1864.85060382,
        CuBasic_C: 1211.88048306,
        LiBasic_C: 123.0692434752,
        CuNormal_C: 5453.46217376,
        LiNormal_C: 292.7325111032,
        m3_amianto: 0.363564144917439,
        ratio: 100.0,
        id: 17,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 21.41226411349149,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87083333333334,
        E_d: 22.96,
        E_m: 698.43,
        E_y: 8381.11,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.27,
        SD_m: 25.4,
        SD_y: 304.84,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 34348.080927092036,
        ton_co2_avoid: 8.875544111560583,
        energy_price_saved: 6261.655153008878,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.02675467471436826, 51.49333756771538],
            [0.026800067350703904, 51.49334961779652],
            [0.026797941479019167, 51.49323345350352],
            [0.026705667384037606, 51.49322052655236],
            [0.02673359033909263, 51.49330540390738],
            [0.026754966466531912, 51.49330833139837],
            [0.02675467471436826, 51.49333756771538],
          ],
        ],
      },
    },
    {
      id: "64",
      type: "Feature",
      properties: {
        Fecha_cons: "1950-01-01",
        Fecha_fin: "1950-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1046.51303359,
        Decada: "1950",
        Antiguedad: 74,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710431.5558989999,
        centroid_y: 5708836.84865,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1046.51303359,
        Reti_cost: 10815.1303359,
        CuBasic_C: 20930.2606718,
        LiBasic_C: 1269.81564031,
        CuNormal_C: 94186.1730231,
        LiNormal_C: 4200.05213436,
        m3_amianto: 6.27907820154,
        ratio: 100.0,
        id: 18,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 486.80920792405874,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30666666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86916666666667,
        E_d: 344.43,
        E_m: 10476.38,
        E_y: 125716.58,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 381.05,
        SD_y: 4572.59,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 780905.8388782698,
        ton_co2_avoid: 201.7860687661449,
        energy_price_saved: 142359.13442750857,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03112204507451565, 51.49166581066179],
            [0.031269027410265174, 51.49169680480571],
            [0.03139913805026957, 51.4917087493487],
            [0.03157282543780134, 51.4912352031376],
            [0.031447167655935006, 51.491223143605225],
            [0.03130927428941917, 51.49119469827516],
            [0.03112204507451565, 51.49166581066179],
          ],
        ],
      },
    },
    {
      id: "65",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 24.110848302497686,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 710396.68942,
        centroid_y: 5708777.41591,
        Fecha_ejec: "2024-05-29",
        a_amianto: 24.110848302497686,
        Reti_cost: 1073.32544907,
        CuBasic_C: 482.2169660499538,
        LiBasic_C: 62.221696604798154,
        CuNormal_C: 2169.97634722,
        LiNormal_C: 129.7320718516,
        m3_amianto: 0.144665089814986,
        ratio: 100.0,
        id: 19,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 8.520093392880943,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.030777432565373096, 51.49095827031739],
            [0.030801379387390446, 51.490966350004705],
            [0.030831983576106985, 51.4909655584458],
            [0.03084494194173748, 51.49090885342601],
            [0.03079342678706107, 51.49090531436709],
            [0.030777432565373096, 51.49095827031739],
          ],
        ],
      },
    },
    {
      id: "66",
      type: "Feature",
      properties: {
        Fecha_cons: "1981-01-01",
        Fecha_fin: "1981-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 246.06968609726522,
        Decada: "1980",
        Antiguedad: 43,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 710469.487437,
        centroid_y: 5708749.890089999,
        Fecha_ejec: "2024-05-29",
        a_amianto: 246.06968609726522,
        Reti_cost: 5271.39372195,
        CuBasic_C: 4921.39372195,
        LiBasic_C: 407.711497756,
        CuNormal_C: 22146.2717488,
        LiNormal_C: 1096.70661883,
        m3_amianto: 1.476418116583591,
        ratio: 100.0,
        id: 20,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 114.71058212497138,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30333333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86916666666667,
        E_d: 68.89,
        E_m: 2095.28,
        E_y: 25143.34,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 76.21,
        SD_y: 914.52,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 184010.82375682888,
        ton_co2_avoid: 47.54839685876458,
        energy_price_saved: 33545.1731708699,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03176900079597627, 51.49072262496886],
            [0.0319414843081496, 51.49075156661438],
            [0.03200412286786119, 51.49063720613908],
            [0.031691430059911076, 51.49057848779434],
            [0.03165412803353764, 51.490637910506614],
            [0.03178970191341329, 51.490665023581336],
            [0.03176900079597627, 51.49072262496886],
          ],
        ],
      },
    },
    {
      id: "67",
      type: "Feature",
      properties: {
        Fecha_cons: "1950-01-01",
        Fecha_fin: "1950-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 936.011775864335,
        Decada: "1950",
        Antiguedad: 74,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710468.929212,
        centroid_y: 5708731.07091,
        Fecha_ejec: "2024-05-29",
        a_amianto: 936.011775864335,
        Reti_cost: 9710.11775864,
        CuBasic_C: 18720.2355173,
        LiBasic_C: 1137.21413104,
        CuNormal_C: 84241.0598278,
        LiNormal_C: 3758.0471034600005,
        m3_amianto: 5.61607065518601,
        ratio: 100.0,
        id: 21,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 435.406852234699,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30333333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86666666666667,
        E_d: 344.43,
        E_m: 10476.39,
        E_y: 125716.73,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 381.05,
        SD_y: 4572.6,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 698449.7163634703,
        ton_co2_avoid: 180.4794067083207,
        energy_price_saved: 127327.38329306063,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03138879995410354, 51.490520204230165],
            [0.03200412286786119, 51.49063720613908],
            [0.03206685337811668, 51.49052423509517],
            [0.03212116691817125, 51.49053004992542],
            [0.032194564362151235, 51.49040984354887],
            [0.03187862655680839, 51.4903526015323],
            [0.03183696573487025, 51.4904135289349],
            [0.03179632542585749, 51.49047303806887],
            [0.031443557325954816, 51.49040465609455],
            [0.03138879995410354, 51.490520204230165],
          ],
        ],
      },
    },
    {
      id: "68",
      type: "Feature",
      properties: {
        Fecha_cons: "1990-01-01",
        Fecha_fin: "1990-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1015.48025941,
        Decada: "1990",
        Antiguedad: 34,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710479.5082580001,
        centroid_y: 5708687.35779,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1015.48025941,
        Reti_cost: 10504.8025941,
        CuBasic_C: 20309.6051882,
        LiBasic_C: 1232.57631129,
        CuNormal_C: 91393.2233469,
        LiNormal_C: 4075.92103764,
        m3_amianto: 6.09288155646,
        ratio: 100.0,
        id: 22,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 472.37385800724724,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.3025,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86500000000001,
        E_d: 344.43,
        E_m: 10476.4,
        E_y: 125716.79,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 381.05,
        SD_y: 4572.6,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 757749.6436937951,
        ton_co2_avoid: 195.80250793047665,
        energy_price_saved: 138137.76004537885,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03153920115745932, 51.490109458594155],
            [0.03225920122491718, 51.490245846597865],
            [0.032297978310757564, 51.49020865509081],
            [0.03231649896479343, 51.49015180594668],
            [0.03233709543702931, 51.49012621968181],
            [0.03234749737971045, 51.49008141133948],
            [0.03161090061380842, 51.489942669581964],
            [0.031614965085741346, 51.4899871035871],
            [0.03158388536840695, 51.49002270387711],
            [0.0315704208249418, 51.49007176692363],
            [0.03153920115745932, 51.490109458594155],
          ],
        ],
      },
    },
    {
      id: "69",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2400.12678236,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710579.594608,
        centroid_y: 5708810.11496,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2400.12678236,
        Reti_cost: 24351.2678236,
        CuBasic_C: 48002.5356472,
        LiBasic_C: 2894.15213883,
        CuNormal_C: 216011.410412,
        LiNormal_C: 9614.50712942,
        m3_amianto: 14.40076069416,
        ratio: 100.0,
        id: 23,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 1090.1176361090893,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03290471263831531, 51.49122866252245],
            [0.033056607639209756, 51.49126648651508],
            [0.03319330245047898, 51.491276866786485],
            [0.03388037748887443, 51.49138295464213],
            [0.033927231557451726, 51.49131632464754],
            [0.03394636084881918, 51.49123510211967],
            [0.0339824551209764, 51.49117431807878],
            [0.034012251485314234, 51.491085860104796],
            [0.03330950809678924, 51.490978786797385],
            [0.033179214556452986, 51.49096406542842],
            [0.03303946437116187, 51.49094123759202],
            [0.03290471263831531, 51.49122866252245],
          ],
        ],
      },
    },
    {
      id: "70",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 62.222316444340805,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 710537.416015,
        centroid_y: 5708956.66589,
        Fecha_ejec: "2024-05-29",
        a_amianto: 62.222316444340805,
        Reti_cost: 1905.55791111,
        CuBasic_C: 1244.44632889,
        LiBasic_C: 126.0001696,
        CuNormal_C: 5600.00847999,
        LiNormal_C: 300.222655644,
        m3_amianto: 0.373333898666045,
        ratio: 100.0,
        id: 24,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 21.9875729863578,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87166666666668,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.09,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 25.4,
        SD_y: 304.84,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 35270.95183969373,
        ton_co2_avoid: 9.114013955376858,
        energy_price_saved: 6429.894520376166,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.032828339854594754, 51.49249582969463],
            [0.03305332231614625, 51.4925261947114],
            [0.03306242107689524, 51.492495338534845],
            [0.03284365482842752, 51.49245785340945],
            [0.032828339854594754, 51.49249582969463],
          ],
        ],
      },
    },
    {
      id: "71",
      type: "Feature",
      properties: {
        Fecha_cons: "1985-01-01",
        Fecha_fin: "1985-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 273.19990913870373,
        Decada: "1980",
        Antiguedad: 39,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710595.683036,
        centroid_y: 5709025.82529,
        Fecha_ejec: "2024-05-29",
        a_amianto: 273.19990913870373,
        Reti_cost: 3081.99909139,
        CuBasic_C: 5463.99818277,
        LiBasic_C: 341.8398909664,
        CuNormal_C: 24587.9918225,
        LiNormal_C: 1106.79963656,
        m3_amianto: 1.639199454832222,
        ratio: 100.0,
        id: 25,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 127.35764130330891,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87250000000002,
        E_d: 68.89,
        E_m: 2095.27,
        E_y: 25143.26,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 76.21,
        SD_y: 914.52,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 204298.3659730465,
        ton_co2_avoid: 52.79069776743521,
        energy_price_saved: 37243.59211688637,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.033638112419042854, 51.49310607238043],
            [0.033719443349245905, 51.49312206088561],
            [0.03371893045135323, 51.493131121180966],
            [0.033950127468338656, 51.4931710665614],
            [0.033986218076042456, 51.493126984829566],
            [0.03399662403232797, 51.493065474058085],
            [0.033763386290758306, 51.49302836530393],
            [0.03372893171619242, 51.49302995322309],
            [0.033663597316789064, 51.493019813923375],
            [0.033638112419042854, 51.49310607238043],
          ],
        ],
      },
    },
    {
      id: "72",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 78.5710894147729,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 710618.055448,
        centroid_y: 5709031.00414,
        Fecha_ejec: "2024-05-29",
        a_amianto: 78.5710894147729,
        Reti_cost: 2314.27723537,
        CuBasic_C: 1571.4217883,
        LiBasic_C: 155.42796094680003,
        CuNormal_C: 7071.39804733,
        LiNormal_C: 375.4270113080001,
        m3_amianto: 0.471426536488637,
        ratio: 100.0,
        id: 26,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 27.76473023940902,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87333333333335,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.09,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 25.4,
        SD_y: 304.84,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 44538.27003661947,
        ton_co2_avoid: 11.508688977462471,
        energy_price_saved: 8119.326627675729,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03401538503173305, 51.49312970928527],
            [0.034268933199567954, 51.4931704671852],
            [0.03427636433900126, 51.49313130299696],
            [0.034028427185141485, 51.493091095762445],
            [0.03401538503173305, 51.49312970928527],
          ],
        ],
      },
    },
    {
      id: "73",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 277.94709951235495,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710711.338765,
        centroid_y: 5709026.78452,
        Fecha_ejec: "2024-05-29",
        a_amianto: 277.94709951235495,
        Reti_cost: 3129.47099512,
        CuBasic_C: 5558.94199025,
        LiBasic_C: 347.5365194148,
        CuNormal_C: 25015.2389561,
        LiNormal_C: 1125.78839805,
        m3_amianto: 1.66768259707413,
        ratio: 100.0,
        id: 27,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 129.57048715057113,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03528441376883845, 51.49305091458217],
            [0.035630040373219325, 51.49315191769481],
            [0.035688863096219374, 51.49306409921235],
            [0.03533887770897097, 51.49296460103074],
            [0.03528441376883845, 51.49305091458217],
          ],
        ],
      },
    },
    {
      id: "74",
      type: "Feature",
      properties: {
        Fecha_cons: "1960-01-01",
        Fecha_fin: "1960-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 493.12113666739384,
        Decada: "1960",
        Antiguedad: 64,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710725.6707740001,
        centroid_y: 5708999.40636,
        Fecha_ejec: "2024-05-29",
        a_amianto: 493.12113666739384,
        Reti_cost: 5281.21136667,
        CuBasic_C: 9862.42273335,
        LiBasic_C: 605.7453640008,
        CuNormal_C: 44380.9023001,
        LiNormal_C: 1986.48454667,
        m3_amianto: 2.958726820004363,
        ratio: 100.0,
        id: 28,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 211.66499483923843,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87250000000002,
        E_d: 183.7,
        E_m: 5587.39,
        E_y: 67048.72,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 203.23,
        SD_y: 2438.71,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 339538.4221694613,
        ton_co2_avoid: 87.73672828858881,
        energy_price_saved: 61897.8543614928,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03543678213936559, 51.492827403585366],
            [0.03579158785163839, 51.492932343987825],
            [0.03585597019448966, 51.492861083576045],
            [0.03589995029117824, 51.49278478430086],
            [0.03555377173575835, 51.492675445203176],
            [0.03551034534708934, 51.49276008109764],
            [0.03543678213936559, 51.492827403585366],
          ],
        ],
      },
    },
    {
      id: "75",
      type: "Feature",
      properties: {
        Fecha_cons: "1979-01-01",
        Fecha_fin: "1979-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 493.73174629044195,
        Decada: "1970",
        Antiguedad: 45,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710742.2090319999,
        centroid_y: 5708968.25064,
        Fecha_ejec: "2024-05-29",
        a_amianto: 493.73174629044195,
        Reti_cost: 5287.3174629,
        CuBasic_C: 9874.63492581,
        LiBasic_C: 606.4780955484,
        CuNormal_C: 44435.8571661,
        LiNormal_C: 1988.92698516,
        m3_amianto: 2.962390477742651,
        ratio: 100.0,
        id: 29,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 211.92708021873685,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87083333333335,
        E_d: 183.7,
        E_m: 5587.4,
        E_y: 67048.75,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 203.23,
        SD_y: 2438.71,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 339958.8415037786,
        ton_co2_avoid: 87.84536464457638,
        energy_price_saved: 61974.496806138835,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.035656519151111954, 51.49254403746165],
            [0.03600650192819283, 51.49264353474212],
            [0.03607143783549741, 51.49258061095752],
            [0.036126176899526505, 51.49249846543578],
            [0.03576682986524088, 51.49239225186675],
            [0.035727671024562035, 51.49247399359529],
            [0.035656519151111954, 51.49254403746165],
          ],
        ],
      },
    },
    {
      id: "76",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 492.0914812582587,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710758.591214,
        centroid_y: 5708937.23139,
        Fecha_ejec: "2024-05-29",
        a_amianto: 492.0914812582587,
        Reti_cost: 5270.914812579999,
        CuBasic_C: 9841.82962517,
        LiBasic_C: 604.50977751,
        CuNormal_C: 44288.2333132,
        LiNormal_C: 1982.36592503,
        m3_amianto: 2.952548887549552,
        ratio: 100.0,
        id: 30,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 211.22298533180992,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30666666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87000000000002,
        E_d: 183.7,
        E_m: 5587.4,
        E_y: 67048.77,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 203.23,
        SD_y: 2438.71,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 338829.38092789863,
        ton_co2_avoid: 87.553512031769,
        energy_price_saved: 61768.59614315592,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03587613802291193, 51.49225893413826],
            [0.03623075544368627, 51.49236109436319],
            [0.03629281812256115, 51.49228850194564],
            [0.036339299946936125, 51.49221631321465],
            [0.035993032383263304, 51.492105585896276],
            [0.03594088883427579, 51.49219323151582],
            [0.03587613802291193, 51.49225893413826],
          ],
        ],
      },
    },
    {
      id: "77",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 579.5523282129915,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710709.3228409999,
        centroid_y: 5708925.19561,
        Fecha_ejec: "2024-05-29",
        a_amianto: 579.5523282129915,
        Reti_cost: 6145.52328213,
        CuBasic_C: 11591.0465643,
        LiBasic_C: 709.4627938572,
        CuNormal_C: 52159.7095392,
        LiNormal_C: 2332.20931285,
        m3_amianto: 3.477313969277949,
        ratio: 100.0,
        id: 31,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 248.76455945132156,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03508175441890069, 51.49211527361696],
            [0.03565967107519516, 51.49228959532043],
            [0.03572610275000956, 51.492182093791214],
            [0.03511424610888507, 51.49200030040953],
            [0.03508175441890069, 51.49211527361696],
          ],
        ],
      },
    },
    {
      id: "78",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1290.46953155,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710723.179346,
        centroid_y: 5708892.155980001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1290.46953155,
        Reti_cost: 13254.6953155,
        CuBasic_C: 25809.390631,
        LiBasic_C: 1562.56343786,
        CuNormal_C: 116142.25784,
        LiNormal_C: 5175.87812622,
        m3_amianto: 7.7428171893,
        ratio: 100.0,
        id: 32,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 647.9521422082313,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30666666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86916666666669,
        E_d: 459.24,
        E_m: 13968.5,
        E_y: 167622.0,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 508.07,
        SD_y: 6096.78,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1039400.2474230606,
        ton_co2_avoid: 268.58102393411883,
        energy_price_saved: 189482.66510522395,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.035184885632697345, 51.49185545823262],
            [0.03583958503377802, 51.49204449240543],
            [0.03593847912681728, 51.49192223123586],
            [0.03599136141145352, 51.49184570126932],
            [0.03528417830722606, 51.491638541537384],
            [0.035184885632697345, 51.49185545823262],
          ],
        ],
      },
    },
    {
      id: "79",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 243.89663425401815,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 710767.221197,
        centroid_y: 5708903.547409999,
        Fecha_ejec: "2024-05-29",
        a_amianto: 243.89663425401815,
        Reti_cost: 5227.93268508,
        CuBasic_C: 4877.93268508,
        LiBasic_C: 404.2346148064,
        CuNormal_C: 21950.6970829,
        LiNormal_C: 1087.14519072,
        m3_amianto: 1.463379805524109,
        ratio: 100.0,
        id: 33,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 113.69711004903813,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30666666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87000000000002,
        E_d: 68.89,
        E_m: 2095.27,
        E_y: 25143.3,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 76.21,
        SD_y: 914.52,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 182385.08157949557,
        ton_co2_avoid: 47.12830508014165,
        energy_price_saved: 33248.80037194204,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03606949570005235, 51.49202322551122],
            [0.03619413189878318, 51.4920534006273],
            [0.03636251201717769, 51.49183608474155],
            [0.0362424202766333, 51.49180718390869],
            [0.03606949570005235, 51.49202322551122],
          ],
        ],
      },
    },
    {
      id: "80",
      type: "Feature",
      properties: {
        Fecha_cons: "1940-01-01",
        Fecha_fin: "1940-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 226.96119716113597,
        Decada: "1940",
        Antiguedad: 84,
        Tp_Amianto: "Primeros amiantos",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 710794.950771,
        centroid_y: 5708901.00128,
        Fecha_ejec: "2024-05-29",
        a_amianto: 226.96119716113597,
        Reti_cost: 4889.22394322,
        CuBasic_C: 4539.22394322,
        LiBasic_C: 377.13791545760006,
        CuNormal_C: 20426.5077445,
        LiNormal_C: 1012.62926751,
        m3_amianto: 1.361767182966816,
        ratio: 100.0,
        id: 34,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 105.8023444684123,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.555,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.27583333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86166666666666,
        E_d: 68.89,
        E_m: 2095.27,
        E_y: 25143.28,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.25,
        SD_m: 76.21,
        SD_y: 914.52,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 169720.84179492758,
        ton_co2_avoid: 43.855865519809285,
        energy_price_saved: 30940.109459215295,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03639936348397401, 51.491887324220635],
            [0.03652121518276132, 51.491925922237954],
            [0.03653591799722597, 51.49191231867038],
            [0.03663866581965757, 51.49194862790624],
            [0.03665346100330982, 51.49193641377984],
            [0.03676511215370524, 51.49197249218405],
            [0.03683338477143497, 51.49190948148138],
            [0.03671046617097126, 51.49187160736015],
            [0.03670114310346239, 51.49188228783725],
            [0.03657947616890104, 51.49184646891847],
            [0.03658026152865468, 51.4918582792906],
            [0.03646077434189632, 51.49182170784002],
            [0.03639936348397401, 51.491887324220635],
          ],
        ],
      },
    },
    {
      id: "81",
      type: "Feature",
      properties: {
        Fecha_cons: "1950-01-01",
        Fecha_fin: "1950-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 259.3055473446431,
        Decada: "1950",
        Antiguedad: 74,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710744.252271,
        centroid_y: 5708862.95844,
        Fecha_ejec: "2024-05-29",
        a_amianto: 259.3055473446431,
        Reti_cost: 2943.0554734500006,
        CuBasic_C: 5186.11094689,
        LiBasic_C: 325.1666568136,
        CuNormal_C: 23337.499261,
        LiNormal_C: 1051.22218938,
        m3_amianto: 1.555833284067859,
        ratio: 100.0,
        id: 35,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 120.88033319589924,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03569117537637188, 51.491614776035554],
            [0.03601376621318589, 51.49163773519092],
            [0.036033870567558274, 51.491537697057204],
            [0.035715362512047114, 51.49150906479594],
            [0.03569117537637188, 51.491614776035554],
          ],
        ],
      },
    },
    {
      id: "82",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 87.2079228807811,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 710782.594303,
        centroid_y: 5708848.61394,
        Fecha_ejec: "2024-05-29",
        a_amianto: 87.2079228807811,
        Reti_cost: 2530.19807202,
        CuBasic_C: 1744.15845762,
        LiBasic_C: 170.9742611856,
        CuNormal_C: 7848.71305927,
        LiNormal_C: 415.15644525159996,
        m3_amianto: 0.523247537284687,
        ratio: 100.0,
        id: 36,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 30.816662695846155,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.555,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.27583333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.85916666666667,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.1,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.25,
        SD_m: 25.4,
        SD_y: 304.84,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 49433.97011028293,
        ton_co2_avoid: 12.773737876497108,
        energy_price_saved: 9011.812751104577,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03642199124577766, 51.49152346509015],
            [0.03648686627207855, 51.491509953293416],
            [0.03644134712754326, 51.49144571586836],
            [0.03638968317978085, 51.49133936021096],
            [0.03632796224081028, 51.49135000652956],
            [0.03642199124577766, 51.49152346509015],
          ],
        ],
      },
    },
    {
      id: "83",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 88.7898208046866,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 710823.292263,
        centroid_y: 5708840.43811,
        Fecha_ejec: "2024-05-29",
        a_amianto: 88.7898208046866,
        Reti_cost: 2569.74552012,
        CuBasic_C: 1775.79641609,
        LiBasic_C: 173.8216774484,
        CuNormal_C: 7991.083872420001,
        LiNormal_C: 422.43317570159996,
        m3_amianto: 0.53273892482812,
        ratio: 100.0,
        id: 37,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 31.375695263350664,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.555,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.27583333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86083333333333,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.1,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.25,
        SD_m: 25.4,
        SD_y: 304.84,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 50330.73169363301,
        ton_co2_avoid: 13.00546106963477,
        energy_price_saved: 9175.292387749298,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03700313058692955, 51.49144455392481],
            [0.03706142041821383, 51.491432604326384],
            [0.03696279154887828, 51.49124030151372],
            [0.036908071582104525, 51.491255638172404],
            [0.03697004133807911, 51.491366076049545],
            [0.03700313058692955, 51.49144455392481],
          ],
        ],
      },
    },
    {
      id: "84",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1394.8557136,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710814.1821110001,
        centroid_y: 5708878.15541,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1394.8557136,
        Reti_cost: 14298.557136,
        CuBasic_C: 27897.114272,
        LiBasic_C: 1687.82685632,
        CuNormal_C: 125537.014224,
        LiNormal_C: 5593.4228544,
        m3_amianto: 8.3691342816,
        ratio: 100.0,
        id: 38,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 700.3640868984079,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.555,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.27583333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86166666666666,
        E_d: 459.24,
        E_m: 13968.49,
        E_y: 167621.88,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.25,
        SD_m: 508.07,
        SD_y: 6096.8,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1123475.8831532472,
        ton_co2_avoid: 290.30616820679904,
        energy_price_saved: 204809.65349883694,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03643186756376025, 51.491571401973125],
            [0.03652094926762371, 51.491737507460265],
            [0.03729455891635532, 51.49187056185423],
            [0.03730810626160102, 51.49183959001099],
            [0.037329917568548296, 51.49176525661336],
            [0.037367024287576045, 51.491719755602105],
            [0.036924698675903166, 51.49162509139319],
            [0.036949786898686963, 51.49161678601721],
            [0.03692255882748001, 51.49155729416446],
            [0.03692239084005223, 51.491556863565],
            [0.036921307332782136, 51.49155523882383],
            [0.036881633223919734, 51.491478323431416],
            [0.036824918177727706, 51.491497191377455],
            [0.03674270318261793, 51.49150140977887],
            [0.03668292657869367, 51.491524532545014],
            [0.036608312791791206, 51.491542472369034],
            [0.03643186756376025, 51.491571401973125],
          ],
        ],
      },
    },
    {
      id: "85",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 705.4381793569999,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710759.907508,
        centroid_y: 5708757.44603,
        Fecha_ejec: "2024-05-29",
        a_amianto: 705.4381793569999,
        Reti_cost: 7404.38179357,
        CuBasic_C: 14108.7635871,
        LiBasic_C: 860.5258152268001,
        CuNormal_C: 63489.4361421,
        LiNormal_C: 2835.7527174299994,
        m3_amianto: 4.232629076141999,
        ratio: 100.0,
        id: 39,
        superficie_cubierta: 675.0,
        Potencia_instalada_kWp: 100.0,
        n_modulos: 160.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.147,
        "Coste \u20ac/kWp": 0.583,
        Lucernario: "false",
        superficie_fv_percent: 49.8,
        superficie_placas: 350.6835756733594,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.035669641573919046, 51.490605372955784],
            [0.03629626795193653, 51.49076224925159],
            [0.03637679800792168, 51.490632112389314],
            [0.03573814993482652, 51.49048302917091],
            [0.035669641573919046, 51.490605372955784],
          ],
        ],
      },
    },
    {
      id: "86",
      type: "Feature",
      properties: {
        Fecha_cons: "1950-01-01",
        Fecha_fin: "1950-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 661.781088910229,
        Decada: "1950",
        Antiguedad: 74,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710819.254315,
        centroid_y: 5708776.96065,
        Fecha_ejec: "2024-05-29",
        a_amianto: 661.781088910229,
        Reti_cost: 6967.8108891,
        CuBasic_C: 13235.6217782,
        LiBasic_C: 808.137306692,
        CuNormal_C: 59560.2980019,
        LiNormal_C: 2661.12435564,
        m3_amianto: 3.970686533461374,
        ratio: 100.0,
        id: 40,
        superficie_cubierta: 675.0,
        Potencia_instalada_kWp: 100.0,
        n_modulos: 160.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.147,
        "Coste \u20ac/kWp": 0.583,
        Lucernario: "false",
        superficie_fv_percent: 49.8,
        superficie_placas: 328.98067277685897,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55333333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.25249999999998,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86083333333335,
        E_d: 229.62,
        E_m: 6984.25,
        E_y: 83810.99,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.24,
        SD_m: 254.03,
        SD_y: 3048.38,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 527728.16139835,
        ton_co2_avoid: 136.36495690533366,
        energy_price_saved: 96204.84382291921,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03662445937218564, 51.49080245967849],
            [0.03701133619402114, 51.490920483689536],
            [0.03707042278153552, 51.49087023755097],
            [0.037146961360040864, 51.49074716284293],
            [0.03674849505943574, 51.49062248038194],
            [0.03667241756289004, 51.49075250212456],
            [0.03662445937218564, 51.49080245967849],
          ],
        ],
      },
    },
    {
      id: "87",
      type: "Feature",
      properties: {
        Fecha_cons: "1965-01-01",
        Fecha_fin: "1965-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 3199.89067696,
        Decada: "1960",
        Antiguedad: 59,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710848.059845,
        centroid_y: 5708725.443980001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 3199.89067696,
        Reti_cost: 32348.9067696,
        CuBasic_C: 63997.8135392,
        LiBasic_C: 3853.86881235,
        CuNormal_C: 287990.160926,
        LiNormal_C: 12813.5627078,
        m3_amianto: 19.199344061759994,
        ratio: 100.0,
        id: 41,
        superficie_cubierta: 3080.0,
        Potencia_instalada_kWp: 400.0,
        n_modulos: 662.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1333,
        "Coste \u20ac/kWp": 0.543,
        Lucernario: "false",
        superficie_fv_percent: 45.1,
        superficie_placas: 1440.5776088382952,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55166666666668,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.21916666666667,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86083333333335,
        E_d: 918.48,
        E_m: 27936.99,
        E_y: 335243.86,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.23,
        SD_m: 1016.12,
        SD_y: 12193.41,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 2310875.488358905,
        ton_co2_avoid: 597.130226191941,
        energy_price_saved: 421272.6015278284,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.036804027159505884, 51.49031761791593],
            [0.03692480827966738, 51.49035693915386],
            [0.03690027513528489, 51.49037358124765],
            [0.03696880230789194, 51.490398250394215],
            [0.03694245116344899, 51.49045460734402],
            [0.03685848247277305, 51.49043242614276],
            [0.03680666985858704, 51.49050823289754],
            [0.03687148900059488, 51.49052743079772],
            [0.036803430407564834, 51.4905768654684],
            [0.03682095086896367, 51.490605640265024],
            [0.03723907917207046, 51.49072424545441],
            [0.03780086742636001, 51.48998592068454],
            [0.03738953718330387, 51.48986888114377],
            [0.03710901221565912, 51.49024360072214],
            [0.03690676591022664, 51.4901862091784],
            [0.036804027159505884, 51.49031761791593],
          ],
        ],
      },
    },
    {
      id: "88",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 387.71315630527255,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710789.33663,
        centroid_y: 5708653.864240001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 387.71315630527255,
        Reti_cost: 4227.13156305,
        CuBasic_C: 7754.263126109999,
        LiBasic_C: 479.2557875664,
        CuNormal_C: 34894.1840675,
        LiNormal_C: 1564.85262522,
        m3_amianto: 2.326278937831636,
        ratio: 100.0,
        id: 42,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 193.51185854983962,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55333333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.25916666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86333333333334,
        E_d: 114.81,
        E_m: 3492.13,
        E_y: 41905.57,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.25,
        SD_m: 127.02,
        SD_y: 1524.19,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 310418.40986978443,
        ton_co2_avoid: 80.2121171103523,
        energy_price_saved: 56589.276119261696,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.036120538835456614, 51.48965332250743],
            [0.03658260768673465, 51.48979584475135],
            [0.036653107277577424, 51.48971607419608],
            [0.03620819784286743, 51.48956336467114],
            [0.036120538835456614, 51.48965332250743],
          ],
        ],
      },
    },
    {
      id: "89",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1391.32787169,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710910.570697,
        centroid_y: 5708752.38669,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1391.32787169,
        Reti_cost: 14263.2787169,
        CuBasic_C: 27826.5574338,
        LiBasic_C: 1683.5934460299998,
        CuNormal_C: 125219.508452,
        LiNormal_C: 5579.31148676,
        m3_amianto: 8.347967230139998,
        ratio: 100.0,
        id: 43,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 698.5921324184065,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03779754067290261, 51.490555969688],
            [0.03840660739537377, 51.490729470228914],
            [0.038468941803955874, 51.490661045094676],
            [0.03856375807001567, 51.490477645900185],
            [0.037950427623371044, 51.49030704043838],
            [0.03785959849300195, 51.490483376502326],
            [0.03779754067290261, 51.490555969688],
          ],
        ],
      },
    },
    {
      id: "90",
      type: "Feature",
      properties: {
        Fecha_cons: "1952-01-01",
        Fecha_fin: "1952-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2046.28450028,
        Decada: "1950",
        Antiguedad: 72,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710946.2398,
        centroid_y: 5708728.65026,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2046.28450028,
        Reti_cost: 20812.8450028,
        CuBasic_C: 40925.6900056,
        LiBasic_C: 2469.54140034,
        CuNormal_C: 184165.605025,
        LiNormal_C: 8199.13800111,
        m3_amianto: 12.27770700168,
        ratio: 100.0,
        id: 44,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 929.4021592469907,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.53916666666667,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 77.9825,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.83750000000002,
        E_d: 688.85,
        E_m: 20952.43,
        E_y: 251429.15,
        "H(i)_d": 2.89,
        "H(i)_m": 87.76,
        "H(i)_y": 1053.14,
        SD_m: 762.09,
        SD_y: 9145.09,
        l_aoi: -4.65,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.42,
        solar_energy_total: 1491070.3684707703,
        ton_co2_avoid: 385.29258321284703,
        energy_price_saved: 271822.1281722214,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03819172890973352, 51.490314702831256],
            [0.03899191507954414, 51.49054587818216],
            [0.03919033321483179, 51.49027767235458],
            [0.03837624199867017, 51.49003850783178],
            [0.03819172890973352, 51.490314702831256],
          ],
        ],
      },
    },
    {
      id: "91",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 368.9975228313944,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710966.273422,
        centroid_y: 5708780.91132,
        Fecha_ejec: "2024-05-29",
        a_amianto: 368.9975228313944,
        Reti_cost: 4039.97522831,
        CuBasic_C: 7379.95045663,
        LiBasic_C: 456.79702739760006,
        CuNormal_C: 33209.7770548,
        LiNormal_C: 1489.99009132,
        m3_amianto: 2.213985136988367,
        ratio: 100.0,
        id: 45,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 184.17026492981856,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.51916666666666,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 77.65499999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.71916666666668,
        E_d: 114.8,
        E_m: 3491.92,
        E_y: 41903.08,
        "H(i)_d": 2.88,
        "H(i)_m": 87.75,
        "H(i)_y": 1052.96,
        SD_m: 127.0,
        SD_y: 1524.04,
        l_aoi: -4.64,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.41,
        solar_energy_total: 295507.5255370075,
        ton_co2_avoid: 76.35914459876274,
        energy_price_saved: 53871.02190539646,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03881914354940496, 51.4907807100651],
            [0.03885197145147451, 51.49078820984322],
            [0.038994408555381596, 51.49083462254106],
            [0.038991482759729704, 51.490857663956135],
            [0.0390601042156573, 51.490883721299895],
            [0.039196646111813435, 51.49072429273463],
            [0.038907794910963474, 51.490638704034616],
            [0.03888334816615171, 51.49069014057574],
            [0.038867668935746544, 51.49072255975662],
            [0.03881914354940496, 51.4907807100651],
          ],
        ],
      },
    },
    {
      id: "92",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 545.7996848104732,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 711014.173467,
        centroid_y: 5708799.37478,
        Fecha_ejec: "2024-05-29",
        a_amianto: 545.7996848104732,
        Reti_cost: 5807.9968481,
        CuBasic_C: 10915.9936962,
        LiBasic_C: 668.959621772,
        CuNormal_C: 49121.9716329,
        LiNormal_C: 2197.19873924,
        m3_amianto: 3.274798108862839,
        ratio: 100.0,
        id: 46,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 234.2763817777753,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.48500000000001,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 77.27666666666667,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.64916666666666,
        E_d: 183.66,
        E_m: 5586.46,
        E_y: 67037.53,
        "H(i)_d": 2.88,
        "H(i)_m": 87.73,
        "H(i)_y": 1052.71,
        SD_m: 203.16,
        SD_y: 2437.95,
        l_aoi: -4.63,
        l_spec: "1.67",
        l_tg: -4.54,
        l_total: -20.4,
        solar_energy_total: 375951.74378853996,
        ton_co2_avoid: 97.14593059495873,
        energy_price_saved: 68536.00289265082,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03988207086379245, 51.49107344018906],
            [0.0399984716772901, 51.49099665237404],
            [0.03958065074027113, 51.490732078621654],
            [0.03952871292435834, 51.4907556954587],
            [0.03956631299148854, 51.490784644941066],
            [0.03941181699467597, 51.4908752950621],
            [0.039417936207136274, 51.490883487447284],
            [0.03944698483311786, 51.4908928249002],
            [0.039542591363837205, 51.49093070867425],
            [0.03961539337100131, 51.490919077283486],
            [0.03968614851455921, 51.490943687066284],
            [0.03988207086379245, 51.49107344018906],
          ],
        ],
      },
    },
    {
      id: "93",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 932.460736799529,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710984.0623,
        centroid_y: 5708827.634350001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 932.460736799529,
        Reti_cost: 9674.607368,
        CuBasic_C: 18649.214736,
        LiBasic_C: 1132.95288416,
        CuNormal_C: 83921.46631200002,
        LiNormal_C: 3743.8429472,
        m3_amianto: 5.594764420797174,
        ratio: 100.0,
        id: 47,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 433.75285843816175,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03902598915243037, 51.49129241831088],
            [0.03911037654667394, 51.49132085044848],
            [0.03933479568725923, 51.491376271301796],
            [0.03955270432733919, 51.491032399427425],
            [0.03940571896438424, 51.491001415729684],
            [0.03925984985035261, 51.49095370068838],
            [0.03902598915243037, 51.49129241831088],
          ],
        ],
      },
    },
    {
      id: "94",
      type: "Feature",
      properties: {
        Fecha_cons: "1995-01-01",
        Fecha_fin: "1995-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1105.71822021,
        Decada: "1990",
        Antiguedad: 29,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710988.702108,
        centroid_y: 5708871.59316,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1105.71822021,
        Reti_cost: 11407.1822021,
        CuBasic_C: 22114.3644042,
        LiBasic_C: 1340.86186425,
        CuNormal_C: 99514.6398189,
        LiNormal_C: 4436.87288084,
        m3_amianto: 6.63430932126,
        ratio: 100.0,
        id: 48,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 514.3470052868963,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.52416666666666,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 77.82333333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.67166666666667,
        E_d: 344.41,
        E_m: 10475.69,
        E_y: 125708.32,
        "H(i)_d": 2.88,
        "H(i)_m": 87.75,
        "H(i)_y": 1053.02,
        SD_m: 380.98,
        SD_y: 4571.81,
        l_aoi: -4.65,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.41,
        solar_energy_total: 825287.463519807,
        ton_co2_avoid: 213.25428057351812,
        energy_price_saved: 150449.9045996608,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03905485895417958, 51.49162571372973],
            [0.03923652741270996, 51.49167528424745],
            [0.039394736226519796, 51.49170736891668],
            [0.03945863019552653, 51.4917293731058],
            [0.03954441207930418, 51.49174524211238],
            [0.0397101852727607, 51.491489017037985],
            [0.039588144171022924, 51.49146434574489],
            [0.039526476283255584, 51.49144228388691],
            [0.03940721604084776, 51.49142589137935],
            [0.039229722512777856, 51.49137203729878],
            [0.03905485895417958, 51.49162571372973],
          ],
        ],
      },
    },
    {
      id: "95",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1547.60876171,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710908.499564,
        centroid_y: 5708827.65168,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1547.60876171,
        Reti_cost: 15826.0876171,
        CuBasic_C: 30952.1752342,
        LiBasic_C: 1871.13051405,
        CuNormal_C: 139284.788554,
        LiNormal_C: 6204.43504684,
        m3_amianto: 9.28565257026,
        ratio: 100.0,
        id: 49,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 777.0605787004979,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.53500000000001,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 77.89666666666666,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.77416666666666,
        E_d: 459.23,
        E_m: 13968.11,
        E_y: 167617.36,
        "H(i)_d": 2.89,
        "H(i)_m": 87.76,
        "H(i)_y": 1053.08,
        SD_m: 508.02,
        SD_y: 6096.28,
        l_aoi: -4.65,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.42,
        solar_energy_total: 1246663.7739961902,
        ton_co2_avoid: 322.13791920061556,
        energy_price_saved: 227266.80599950545,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.0378245528695003, 51.49127624789636],
            [0.03789271216064286, 51.49129535865266],
            [0.037919798365541185, 51.49126681943809],
            [0.0381858973341585, 51.491345519301014],
            [0.03811335358100432, 51.49144482966827],
            [0.03825435560632441, 51.4914864090059],
            [0.0383310270754107, 51.49138212004736],
            [0.03836501444575691, 51.49139028582674],
            [0.03856505645457367, 51.49109611583581],
            [0.0380486687879691, 51.490958489178645],
            [0.0378245528695003, 51.49127624789636],
          ],
        ],
      },
    },
    {
      id: "96",
      type: "Feature",
      properties: {
        Fecha_cons: "1988-01-01",
        Fecha_fin: "2014-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1048.30819591,
        Decada: "1980",
        Antiguedad: 36,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710936.88473,
        centroid_y: 5708841.17457,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1048.30819591,
        Reti_cost: 10833.0819591,
        CuBasic_C: 20966.1639182,
        LiBasic_C: 1271.96983509,
        CuNormal_C: 94347.7376319,
        LiNormal_C: 4207.23278364,
        m3_amianto: 6.28984917546,
        ratio: 100.0,
        id: 50,
        superficie_cubierta: 1112.0,
        Potencia_instalada_kWp: 150.0,
        n_modulos: 247.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1377,
        "Coste \u20ac/kWp": 0.574,
        Lucernario: "false",
        superficie_fv_percent: 46.6,
        superficie_placas: 487.64181802502833,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.54583333333333,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.0975,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.78583333333334,
        E_d: 344.42,
        E_m: 10476.24,
        E_y: 125714.85,
        "H(i)_d": 2.89,
        "H(i)_m": 87.76,
        "H(i)_y": 1053.17,
        SD_m: 381.04,
        SD_y: 4572.53,
        l_aoi: -4.65,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.42,
        solar_energy_total: 782339.7633091841,
        ton_co2_avoid: 202.15659483909317,
        energy_price_saved: 142620.53885126425,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.038322713594274124, 51.491500120943925],
            [0.038519264356021966, 51.49155557001443],
            [0.03858049021056843, 51.49147047137621],
            [0.0386462856391032, 51.49148755521974],
            [0.038731069982029305, 51.49138792709025],
            [0.03891977444509333, 51.49114119941562],
            [0.03863499932613517, 51.491066639123254],
            [0.038322713594274124, 51.491500120943925],
          ],
        ],
      },
    },
    {
      id: "97",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 533.1938888740907,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 710883.305994,
        centroid_y: 5708908.58963,
        Fecha_ejec: "2024-05-29",
        a_amianto: 533.1938888740907,
        Reti_cost: 5681.93888874,
        CuBasic_C: 10663.8777775,
        LiBasic_C: 653.8326666496,
        CuNormal_C: 47987.4499987,
        LiNormal_C: 2146.7755555,
        m3_amianto: 3.199163333244544,
        ratio: 100.0,
        id: 51,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 228.86537887056426,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.037600044475776466, 51.49193171395618],
            [0.037639223144779826, 51.491950880179424],
            [0.03767756998532534, 51.49195754130204],
            [0.03769365890051689, 51.49196477940616],
            [0.03786860740837204, 51.491997128117674],
            [0.037886371221297406, 51.49197926947158],
            [0.038158038832554295, 51.49205782506483],
            [0.038189345780874, 51.49202569673659],
            [0.03821995934386795, 51.49198314750007],
            [0.037949127665787424, 51.491900394830694],
            [0.03798641873969301, 51.491857672540014],
            [0.03788988161354467, 51.49183094641996],
            [0.03786353822668728, 51.4918538989643],
            [0.037785223413578456, 51.491832963679336],
            [0.03776950425710701, 51.491831283403194],
            [0.03769471306091826, 51.49181304041358],
            [0.037600044475776466, 51.49193171395618],
          ],
        ],
      },
    },
    {
      id: "98",
      type: "Feature",
      properties: {
        Fecha_cons: "2000-01-01",
        Fecha_fin: "2000-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 419.2448569268771,
        Decada: "2000",
        Antiguedad: 24,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710947.577268,
        centroid_y: 5708908.5636599995,
        Fecha_ejec: "2024-05-29",
        a_amianto: 419.2448569268771,
        Reti_cost: 4542.44856927,
        CuBasic_C: 8384.89713854,
        LiBasic_C: 517.0938283124,
        CuNormal_C: 37732.0371234,
        LiNormal_C: 1690.97942771,
        m3_amianto: 2.515469141561262,
        ratio: 100.0,
        id: 52,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 209.24919222228147,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.52416666666666,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 77.82416666666667,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.67166666666667,
        E_d: 114.8,
        E_m: 3491.9,
        E_y: 41902.76,
        "H(i)_d": 2.88,
        "H(i)_m": 87.75,
        "H(i)_y": 1053.02,
        SD_m: 126.99,
        SD_y: 1523.94,
        l_aoi: -4.65,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.41,
        solar_energy_total: 335747.52709286305,
        ton_co2_avoid: 86.7571610007958,
        energy_price_saved: 61206.77418902893,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03862482928961299, 51.49193576550611],
            [0.038697626394442255, 51.49195753924249],
            [0.038815216799226095, 51.491982326888774],
            [0.038802182330506904, 51.49200423858723],
            [0.03896636104901726, 51.49204225837367],
            [0.039026473779574335, 51.49195718837315],
            [0.038859873046741336, 51.49191627380085],
            [0.038920882402898205, 51.49181117275026],
            [0.03884349184765346, 51.491804132705084],
            [0.038719505294969384, 51.49176698430051],
            [0.03862482928961299, 51.49193576550611],
          ],
        ],
      },
    },
    {
      id: "99",
      type: "Feature",
      properties: {
        Fecha_cons: "2000-01-01",
        Fecha_fin: "2000-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1759.3518613100002,
        Decada: "2000",
        Antiguedad: 24,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 710823.797817,
        centroid_y: 5708972.69546,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1759.3518613100002,
        Reti_cost: 17943.5186131,
        CuBasic_C: 35187.0372262,
        LiBasic_C: 2125.22223357,
        CuNormal_C: 158341.667518,
        LiNormal_C: 7051.407445240001,
        m3_amianto: 10.55611116786,
        ratio: 100.0,
        id: 53,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 883.3785212204631,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.29833333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86666666666666,
        E_d: 459.24,
        E_m: 13968.48,
        E_y: 167621.81,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 508.06,
        SD_y: 6096.77,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1417055.047299607,
        ton_co2_avoid: 366.1670242222184,
        energy_price_saved: 258329.13512271835,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.036719735489190865, 51.49274231715428],
            [0.0371026243758102, 51.492833651230384],
            [0.037427180020181805, 51.492316169910374],
            [0.03703360482284132, 51.49223172517299],
            [0.036719735489190865, 51.49274231715428],
          ],
        ],
      },
    },
    {
      id: "100",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 4633.22789108,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710791.466893,
        centroid_y: 5709038.59657,
        Fecha_ejec: "2024-05-29",
        a_amianto: 4633.22789108,
        Reti_cost: 46682.2789108,
        CuBasic_C: 92664.5578216,
        LiBasic_C: 5573.8734693,
        CuNormal_C: 416990.510197,
        LiNormal_C: 18546.9115643,
        m3_amianto: 27.799367346479997,
        ratio: 100.0,
        id: 54,
        superficie_cubierta: 4998.0,
        Potencia_instalada_kWp: 700.0,
        n_modulos: 1127.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1398,
        "Coste \u20ac/kWp": 0.529,
        Lucernario: "false",
        superficie_fv_percent: 47.4,
        superficie_placas: 2192.242972162145,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.555,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.29583333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86583333333333,
        E_d: 1607.33,
        E_m: 48889.67,
        E_y: 586676.04,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 1778.22,
        SD_y: 21338.66,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 3516645.3496260284,
        ton_co2_avoid: 908.7011583433657,
        energy_price_saved: 641084.4472368249,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03596718020502159, 51.4932614907718],
            [0.03700037707693566, 51.493508912582804],
            [0.037054922756287426, 51.49345739233587],
            [0.037083320315734455, 51.49338149646307],
            [0.037134808139313295, 51.493317528780025],
            [0.03717693030135324, 51.493246844565974],
            [0.03719808765869015, 51.49319618960973],
            [0.03724336744513499, 51.493105937630574],
            [0.03727251920181351, 51.49307490931226],
            [0.03730332687309, 51.49300173460835],
            [0.03627906650236006, 51.49275443196138],
            [0.03625020600155979, 51.49282338038312],
            [0.03620660455630651, 51.4928718329931],
            [0.03616466597136759, 51.4929452957928],
            [0.036142953249604474, 51.49298761384619],
            [0.036110195018929, 51.4930650143131],
            [0.03596718020502159, 51.4932614907718],
          ],
        ],
      },
    },
    {
      id: "101",
      type: "Feature",
      properties: {
        Fecha_cons: "1960-01-01",
        Fecha_fin: "1960-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 429.0849505104401,
        Decada: "1960",
        Antiguedad: 64,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710876.299422,
        centroid_y: 5709076.96486,
        Fecha_ejec: "2024-05-29",
        a_amianto: 429.0849505104401,
        Reti_cost: 4640.8495051,
        CuBasic_C: 8581.69901021,
        LiBasic_C: 528.9019406124,
        CuNormal_C: 38617.6455459,
        LiNormal_C: 1730.33980204,
        m3_amianto: 2.57450970306264,
        ratio: 100.0,
        id: 55,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 214.16055089598706,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.037720507629976585, 51.49352469522024],
            [0.03776534485590435, 51.493545106574636],
            [0.037828223858609476, 51.493551827628245],
            [0.03788210330508329, 51.49357409230441],
            [0.03794507480491861, 51.49358220274776],
            [0.03807880872004263, 51.49338039702521],
            [0.03774680400010827, 51.493299925706275],
            [0.0377194817332786, 51.4933416935546],
            [0.03793407783444569, 51.493400155499664],
            [0.03791450363165773, 51.49342432440311],
            [0.03780131543867025, 51.49339872577952],
            [0.037720507629976585, 51.49352469522024],
          ],
        ],
      },
    },
    {
      id: "102",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 361.1666042409281,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2027-08-27",
        Prioridad: "Alta",
        centroid_x: 710884.728065,
        centroid_y: 5709053.15068,
        Fecha_ejec: "2024-05-29",
        a_amianto: 361.1666042409281,
        Reti_cost: 3961.66604241,
        CuBasic_C: 7223.33208482,
        LiBasic_C: 447.3999250892,
        CuNormal_C: 32504.9943817,
        LiNormal_C: 1458.66641696,
        m3_amianto: 2.166999625445569,
        ratio: 100.0,
        id: 56,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 180.26185325488524,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.555,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.29583333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86583333333333,
        E_d: 114.81,
        E_m: 3492.12,
        E_y: 41905.43,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 127.02,
        SD_y: 1524.19,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 289163.66297598294,
        ton_co2_avoid: 74.71989051299398,
        energy_price_saved: 52714.535760521685,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03778099235169901, 51.49324406135909],
            [0.038134465943417406, 51.493328847443436],
            [0.03821499139259174, 51.49321541167107],
            [0.037849368811257944, 51.493132332637515],
            [0.03778099235169901, 51.49324406135909],
          ],
        ],
      },
    },
    {
      id: "103",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 235.1715018594614,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 710898.356676,
        centroid_y: 5709027.47709,
        Fecha_ejec: "2024-05-29",
        a_amianto: 235.1715018594614,
        Reti_cost: 5053.43003719,
        CuBasic_C: 4703.43003719,
        LiBasic_C: 390.2744029752,
        CuNormal_C: 21165.4351674,
        LiNormal_C: 1048.75460818,
        m3_amianto: 1.411029011156768,
        ratio: 100.0,
        id: 57,
        superficie_cubierta: 216.0,
        Potencia_instalada_kWp: 30.0,
        n_modulos: 48.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1378,
        "Coste \u20ac/kWp": 0.851,
        Lucernario: "false",
        superficie_fv_percent: 46.7,
        superficie_placas: 109.6295744838887,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.555,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.29583333333333,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.86583333333333,
        E_d: 68.89,
        E_m: 2095.27,
        E_y: 25143.26,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 76.21,
        SD_y: 914.51,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 175860.22087232975,
        ton_co2_avoid: 45.44228107341001,
        energy_price_saved: 32059.31826502571,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.038029499382535165, 51.493024665878934],
            [0.038262829147512466, 51.493079857829535],
            [0.038325500906047034, 51.49296618894804],
            [0.03809087384842835, 51.492908247060086],
            [0.038029499382535165, 51.493024665878934],
          ],
        ],
      },
    },
    {
      id: "104",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 37.067595087067865,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 710929.0449630001,
        centroid_y: 5708997.98908,
        Fecha_ejec: "2024-05-29",
        a_amianto: 37.067595087067865,
        Reti_cost: 1462.02785261,
        CuBasic_C: 741.3519017413573,
        LiBasic_C: 88.1351901740543,
        CuNormal_C: 3336.08355784,
        LiNormal_C: 191.924456418,
        m3_amianto: 0.222405570522407,
        ratio: 100.0,
        id: 58,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 13.098567336441976,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55250000000001,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.235,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.835,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.06,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.23,
        SD_m: 25.4,
        SD_y: 304.83,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 21011.82053968747,
        ton_co2_avoid: 5.429454427455242,
        energy_price_saved: 3830.4548843850253,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.038513728353495626, 51.492712167213575],
            [0.03866901765757581, 51.492750591917705],
            [0.03868845248842106, 51.492724338718176],
            [0.038529824518744066, 51.49268600061198],
            [0.038513728353495626, 51.492712167213575],
          ],
        ],
      },
    },
    {
      id: "105",
      type: "Feature",
      properties: {
        Fecha_cons: "1960-01-01",
        Fecha_fin: "1960-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 58.26532672040923,
        Decada: "1960",
        Antiguedad: 64,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 710955.241994,
        centroid_y: 5708988.66381,
        Fecha_ejec: "2024-05-29",
        a_amianto: 58.26532672040923,
        Reti_cost: 1806.63316801,
        CuBasic_C: 1165.30653441,
        LiBasic_C: 118.8775880968,
        CuNormal_C: 5243.87940484,
        LiNormal_C: 282.020502914,
        m3_amianto: 0.349591960322455,
        ratio: 100.0,
        id: 59,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 20.58919629968149,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.038896065434841164, 51.492677895269],
            [0.0389538873995151, 51.49269240203042],
            [0.039029769902653354, 51.49259300457093],
            [0.03901150097243816, 51.492586519108904],
            [0.03903654632391196, 51.49256081626965],
            [0.0389903621891792, 51.49255366291309],
            [0.03892867011302754, 51.4926485167911],
            [0.038896065434841164, 51.492677895269],
          ],
        ],
      },
    },
    {
      id: "106",
      type: "Feature",
      properties: {
        Fecha_cons: "1960-01-01",
        Fecha_fin: "1960-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 19.710118999333712,
        Decada: "1960",
        Antiguedad: 640,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 710989.0573689999,
        centroid_y: 5708969.48883,
        Fecha_ejec: "2024-05-29",
        a_amianto: 19.710118999333712,
        Reti_cost: 941.3035699800114,
        CuBasic_C: 394.20237998667426,
        LiBasic_C: 53.420237998667425,
        CuNormal_C: 1773.91070994,
        LiNormal_C: 108.60857119680045,
        m3_amianto: 0.118260713996002,
        ratio: 100.0,
        id: 60,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 6.964955073753653,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55166666666668,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.2275,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.83166666666666,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.05,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.23,
        SD_m: 25.4,
        SD_y: 304.83,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.42,
        solar_energy_total: 11174.105875349404,
        ton_co2_avoid: 2.887388958190286,
        energy_price_saved: 2037.039501076196,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.039411219347358285, 51.49246167237518],
            [0.03945587281356791, 51.492470953043714],
            [0.03948180416572148, 51.49241669418811],
            [0.03943842550098723, 51.49240981620279],
            [0.039411219347358285, 51.49246167237518],
          ],
        ],
      },
    },
    {
      id: "107",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 17.49665916913652,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 710998.307496,
        centroid_y: 5708972.12589,
        Fecha_ejec: "2024-05-29",
        a_amianto: 17.49665916913652,
        Reti_cost: 874.8997750740956,
        CuBasic_C: 349.9331833827304,
        LiBasic_C: 48.99331833827304,
        CuNormal_C: 1574.69932522,
        LiNormal_C: 97.98396401176383,
        m3_amianto: 0.104979955014819,
        ratio: 100.0,
        id: 61,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 6.18278596429566,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55166666666668,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.2275,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.83166666666666,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.05,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.23,
        SD_m: 25.4,
        SD_y: 304.83,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.42,
        solar_energy_total: 9919.246317899157,
        ton_co2_avoid: 2.563133248545142,
        energy_price_saved: 1808.2786037530161,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.039541612915945656, 51.49246942501567],
            [0.03960335310403947, 51.49248417778512],
            [0.0396200296547951, 51.492449992830416],
            [0.03960431022777733, 51.49244831280519],
            [0.039597332198881445, 51.492443970295135],
            [0.03957187560483648, 51.49243836728732],
            [0.03956019008738664, 51.492438670396695],
            [0.039541612915945656, 51.49246942501567],
          ],
        ],
      },
    },
    {
      id: "108",
      type: "Feature",
      properties: {
        Fecha_cons: "1990-01-01",
        Fecha_fin: "1990-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2769.2941684,
        Decada: "1990",
        Antiguedad: 34,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710507.0138970001,
        centroid_y: 5709090.422,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2769.2941684,
        Reti_cost: 28042.941684,
        CuBasic_C: 55385.883368,
        LiBasic_C: 3337.15300208,
        CuNormal_C: 249236.475156,
        LiNormal_C: 11091.1766736,
        m3_amianto: 16.6157650104,
        ratio: 100.0,
        id: 62,
        superficie_cubierta: 3080.0,
        Potencia_instalada_kWp: 400.0,
        n_modulos: 662.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1333,
        "Coste \u20ac/kWp": 0.543,
        Lucernario: "false",
        superficie_fv_percent: 45.1,
        superficie_placas: 1246.7308429407015,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87333333333335,
        E_d: 918.47,
        E_m: 27936.94,
        E_y: 335243.28,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 1016.13,
        SD_y: 12193.54,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1999919.843163479,
        ton_co2_avoid: 516.7792874734429,
        energy_price_saved: 364585.38740870217,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.032205879512314836, 51.49397790234561],
            [0.0324863207642546, 51.494037454129575],
            [0.032728933345492206, 51.4940645799036],
            [0.03296786590705105, 51.49343206425861],
            [0.03244426531527584, 51.493337050884875],
            [0.032205879512314836, 51.49397790234561],
          ],
        ],
      },
    },
    {
      id: "109",
      type: "Feature",
      properties: {
        Fecha_cons: "1983-01-01",
        Fecha_fin: "1983-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 5392.45957983,
        Decada: "1980",
        Antiguedad: 41,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 710666.553823,
        centroid_y: 5709160.70215,
        Fecha_ejec: "2024-05-29",
        a_amianto: 5392.45957983,
        Reti_cost: 54274.5957983,
        CuBasic_C: 107849.191597,
        LiBasic_C: 6484.95149581,
        CuNormal_C: 485321.362185,
        LiNormal_C: 21583.8383193,
        m3_amianto: 32.35475747898,
        ratio: 100.0,
        id: 63,
        superficie_cubierta: 4998.0,
        Potencia_instalada_kWp: 700.0,
        n_modulos: 1127.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1398,
        "Coste \u20ac/kWp": 0.529,
        Lucernario: "false",
        superficie_fv_percent: 47.4,
        superficie_placas: 2551.481389341981,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03420365457996172, 51.4944467429945],
            [0.03449810307177474, 51.494515670263155],
            [0.03507283447925162, 51.49457455410302],
            [0.03509557415139392, 51.494514115638644],
            [0.03516735785530831, 51.494520607495836],
            [0.03518239439460394, 51.49447846256685],
            [0.03574813000241137, 51.49453618424527],
            [0.035753142742449444, 51.49451935219659],
            [0.03590292618797666, 51.49452521477704],
            [0.035948605366833926, 51.49434030725733],
            [0.03581793193892628, 51.49432003117807],
            [0.03574587134173101, 51.49430937134754],
            [0.035724267719730675, 51.494286269569734],
            [0.0350438132394075, 51.494213425024974],
            [0.03508432808743773, 51.49401786479119],
            [0.035259056534875266, 51.4940300413407],
            [0.03525823447978329, 51.49398413161397],
            [0.03434845856602801, 51.49388277441567],
            [0.03435038771270239, 51.49394535765949],
            [0.0343141797761024, 51.494071561613325],
            [0.03420365457996172, 51.4944467429945],
          ],
        ],
      },
    },
    {
      id: "110",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 180.6207169241286,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 710465.0305869998,
        centroid_y: 5708999.82317,
        Fecha_ejec: "2024-05-29",
        a_amianto: 180.6207169241286,
        Reti_cost: 3962.41433848,
        CuBasic_C: 3612.41433848,
        LiBasic_C: 302.9931470784,
        CuNormal_C: 16255.8645232,
        LiNormal_C: 808.7311544672,
        m3_amianto: 1.083724301544772,
        ratio: 100.0,
        id: 64,
        superficie_cubierta: 190.0,
        Potencia_instalada_kWp: 20.0,
        n_modulos: 32.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.062,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 63.82618927338409,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.30749999999999,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87250000000002,
        E_d: 45.92,
        E_m: 1396.85,
        E_y: 16762.18,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 50.81,
        SD_y: 609.68,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 102385.58159054074,
        ton_co2_avoid: 26.456434282995726,
        energy_price_saved: 18664.891523955575,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.03182456411390822, 51.492969979530216],
            [0.03190635550216495, 51.49299291661542],
            [0.03196547906223562, 51.49299347465211],
            [0.0320307788251023, 51.49283520214485],
            [0.031977173960843604, 51.4928338054287],
            [0.03189561346757356, 51.49281434204168],
            [0.03182456411390822, 51.492969979530216],
          ],
        ],
      },
    },
    {
      id: "111",
      type: "Feature",
      properties: {
        Fecha_cons: "1980-01-01",
        Fecha_fin: "1980-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 106.79981109737197,
        Decada: "1980",
        Antiguedad: 44,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 711144.110617,
        centroid_y: 5708977.77632,
        Fecha_ejec: "2024-05-29",
        a_amianto: 106.79981109737197,
        Reti_cost: 2485.99622195,
        CuBasic_C: 2135.99622195,
        LiBasic_C: 184.879697756,
        CuNormal_C: 9611.982998760002,
        LiNormal_C: 483.9191688284,
        m3_amianto: 0.640798866584232,
        ratio: 100.0,
        id: 65,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 37.739735935224864,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55166666666668,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.21999999999998,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.82833333333332,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.05,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.23,
        SD_m: 25.4,
        SD_y: 304.83,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 60539.48789345538,
        ton_co2_avoid: 15.64340367166887,
        energy_price_saved: 11036.348642976915,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.041587447902593486, 51.49248802119576],
            [0.04174802797044128, 51.49250542673674],
            [0.04178411492697327, 51.49242793818509],
            [0.04160883599908899, 51.49240743459783],
            [0.041587447902593486, 51.49248802119576],
          ],
        ],
      },
    },
    {
      id: "112",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 180.87214440843155,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-08-22",
        Prioridad: "Media",
        centroid_x: 711229.606136,
        centroid_y: 5709000.12865,
        Fecha_ejec: "2024-05-29",
        a_amianto: 180.87214440843155,
        Reti_cost: 3967.44288817,
        CuBasic_C: 3617.44288817,
        LiBasic_C: 303.3954310536,
        CuNormal_C: 16278.4929968,
        LiNormal_C: 809.8374353988,
        m3_amianto: 1.085232866450589,
        ratio: 100.0,
        id: 66,
        superficie_cubierta: 190.0,
        Potencia_instalada_kWp: 20.0,
        n_modulos: 32.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.062,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 63.914535495396635,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55000000000001,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.19083333333332,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.81416666666667,
        E_d: 45.92,
        E_m: 1396.84,
        E_y: 16762.05,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.21,
        SD_m: 50.8,
        SD_y: 609.66,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.42,
        solar_energy_total: 102540.18569202906,
        ton_co2_avoid: 26.49638398282031,
        energy_price_saved: 18693.075851656897,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.042795204673262324, 51.492661268395054],
            [0.043022234405702374, 51.492688777454944],
            [0.04305600267759489, 51.492593254635665],
            [0.04283296209103562, 51.49255868280244],
            [0.042795204673262324, 51.492661268395054],
          ],
        ],
      },
    },
    {
      id: "113",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 593.1294124577649,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 711295.851116,
        centroid_y: 5709021.59432,
        Fecha_ejec: "2024-05-29",
        a_amianto: 593.1294124577649,
        Reti_cost: 6281.294124579999,
        CuBasic_C: 11862.588249200002,
        LiBasic_C: 725.7552949512001,
        CuNormal_C: 53381.6471212,
        LiNormal_C: 2386.51764983,
        m3_amianto: 3.55877647474659,
        ratio: 100.0,
        id: 67,
        superficie_cubierta: 640.0,
        Potencia_instalada_kWp: 80.0,
        n_modulos: 131.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1269,
        "Coste \u20ac/kWp": 0.662,
        Lucernario: "false",
        superficie_fv_percent: 43.0,
        superficie_placas: 254.59051805021045,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.04364832170903521, 51.492849282468434],
            [0.04408262964239139, 51.492908985517595],
            [0.044126789420286555, 51.4927352487232],
            [0.04370602322266398, 51.492677977819866],
            [0.04364832170903521, 51.492849282468434],
          ],
        ],
      },
    },
    {
      id: "114",
      type: "Feature",
      properties: {
        Fecha_cons: "1988-01-01",
        Fecha_fin: "1988-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 457.2867335278771,
        Decada: "1980",
        Antiguedad: 36,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 711312.350502,
        centroid_y: 5708999.191850001,
        Fecha_ejec: "2024-05-29",
        a_amianto: 457.2867335278771,
        Reti_cost: 4922.86733528,
        CuBasic_C: 9145.73467056,
        LiBasic_C: 562.7440802336,
        CuNormal_C: 41155.8060175,
        LiNormal_C: 1843.14693411,
        m3_amianto: 2.743720401167263,
        ratio: 100.0,
        id: 68,
        superficie_cubierta: 340.0,
        Potencia_instalada_kWp: 50.0,
        n_modulos: 81.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1477,
        "Coste \u20ac/kWp": 0.778,
        Lucernario: "false",
        superficie_fv_percent: 50.0,
        superficie_placas: 228.2353955019694,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.53666666666668,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 77.97333333333331,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.685,
        E_d: 114.81,
        E_m: 3492.02,
        E_y: 41904.21,
        "H(i)_d": 2.89,
        "H(i)_m": 87.76,
        "H(i)_y": 1053.08,
        SD_m: 127.0,
        SD_y: 1523.99,
        l_aoi: -4.65,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.42,
        solar_energy_total: 366165.53112478193,
        ton_co2_avoid: 94.61717324264364,
        energy_price_saved: 66751.97632404775,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.04389081955921392, 51.49259036254694],
            [0.04420464807564922, 51.49271582795031],
            [0.04433982987128007, 51.492586353452126],
            [0.04402776458991736, 51.49245388335117],
            [0.04389081955921392, 51.49259036254694],
          ],
        ],
      },
    },
    {
      id: "115",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 21.541947826344362,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 711349.190312,
        centroid_y: 5709068.26084,
        Fecha_ejec: "2024-05-29",
        a_amianto: 21.541947826344362,
        Reti_cost: 996.258434790331,
        CuBasic_C: 430.8389565268874,
        LiBasic_C: 57.08389565268874,
        CuNormal_C: 1938.77530437,
        LiNormal_C: 117.40134956641323,
        m3_amianto: 0.129251686958066,
        ratio: 100.0,
        id: 69,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 7.612236714169747,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.31916666666665,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87166666666667,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.07,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 25.4,
        SD_y: 304.83,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 12211.026414985172,
        ton_co2_avoid: 3.155329225632168,
        energy_price_saved: 2226.0701154517965,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.0446641725059481, 51.493228965455316],
            [0.04469779173369873, 51.49323157157507],
            [0.04471180109041679, 51.49315743942102],
            [0.04466941753632315, 51.493157148802034],
            [0.0446641725059481, 51.493228965455316],
          ],
        ],
      },
    },
    {
      id: "116",
      type: "Feature",
      properties: {
        Fecha_cons: "1970-01-01",
        Fecha_fin: "1970-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 65.87100817408876,
        Decada: "1970",
        Antiguedad: 54,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Medio",
        Calendario: "2029-11-21",
        Prioridad: "Media",
        centroid_x: 711359.547422,
        centroid_y: 5709071.360679999,
        Fecha_ejec: "2024-05-29",
        a_amianto: 65.87100817408876,
        Reti_cost: 1996.77520435,
        CuBasic_C: 1317.42016348,
        LiBasic_C: 132.5678147132,
        CuNormal_C: 5928.390735670001,
        LiNormal_C: 317.00663760080005,
        m3_amianto: 0.395226049044533,
        ratio: 100.0,
        id: 70,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 23.27671349526877,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.31916666666665,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87166666666667,
        E_d: 22.96,
        E_m: 698.42,
        E_y: 8381.07,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 25.4,
        SD_y: 304.83,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 37338.90235122168,
        ton_co2_avoid: 9.648372367555682,
        energy_price_saved: 6806.881898627712,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.04475070120536215, 51.49323924387525],
            [0.04486064760174904, 51.49324960967474],
            [0.04490975617391439, 51.49323371918181],
            [0.04492367037398128, 51.493191602068244],
            [0.04481859270789793, 51.49318737315578],
            [0.04477389063964415, 51.493185750965786],
            [0.04475070120536215, 51.49323924387525],
          ],
        ],
      },
    },
    {
      id: "117",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 7.291397098328998,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Leve",
        Calendario: "2031-04-05",
        Prioridad: "Baja",
        centroid_x: 711351.377262,
        centroid_y: 5709062.28441,
        Fecha_ejec: "2024-05-29",
        a_amianto: 7.291397098328998,
        Reti_cost: 568.7419129498699,
        CuBasic_C: 145.82794196657994,
        LiBasic_C: 28.582794196657993,
        CuNormal_C: 656.2257388496099,
        LiNormal_C: 48.998706071979186,
        m3_amianto: 0.043748382589974,
        ratio: 100.0,
        id: 71,
        superficie_cubierta: 95.0,
        Potencia_instalada_kWp: 10.0,
        n_modulos: 16.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1044,
        "Coste \u20ac/kWp": 1.377,
        Lucernario: "false",
        superficie_fv_percent: 35.4,
        superficie_placas: 2.576547481235181,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.044687595984718526, 51.49314554166574],
            [0.04473800157487481, 51.49314910347201],
            [0.04473767845644586, 51.49312753816198],
            [0.044687643473984406, 51.49312953413432],
            [0.044687595984718526, 51.49314554166574],
          ],
        ],
      },
    },
    {
      id: "118",
      type: "Feature",
      properties: {
        Fecha_cons: "1988-01-01",
        Fecha_fin: "1988-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2039.89105846,
        Decada: "1980",
        Antiguedad: 36,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 711372.2091329999,
        centroid_y: 5709251.58443,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2039.89105846,
        Reti_cost: 20748.9105846,
        CuBasic_C: 40797.8211692,
        LiBasic_C: 2461.86927015,
        CuNormal_C: 183590.195261,
        LiNormal_C: 8173.56423382,
        m3_amianto: 12.23934635076,
        ratio: 100.0,
        id: 72,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 926.492815782444,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.31999999999998,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87416666666668,
        E_d: 688.85,
        E_m: 20952.63,
        E_y: 251431.59,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 762.08,
        SD_y: 9144.98,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1486216.0323724707,
        ton_co2_avoid: 384.03822276504644,
        energy_price_saved: 270937.1827015014,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.044797602979220245, 51.495029338507656],
            [0.04491928564076893, 51.49504844638226],
            [0.045025296255867875, 51.49503316507739],
            [0.045128986252409374, 51.49504995656973],
            [0.04523555280643415, 51.49504301173795],
            [0.045237595268338336, 51.495006770514166],
            [0.04535250758106865, 51.495024662027504],
            [0.045447666376165353, 51.495013837908324],
            [0.04546660642035876, 51.49462919455734],
            [0.04537599291631968, 51.49464129242866],
            [0.04524540754625042, 51.494622416391344],
            [0.045154701324602316, 51.494633124648516],
            [0.0450553714258436, 51.4946148280366],
            [0.044957894784642786, 51.49462432022078],
            [0.044827124306438565, 51.4946026648253],
            [0.044797602979220245, 51.495029338507656],
          ],
        ],
      },
    },
    {
      id: "119",
      type: "Feature",
      properties: {
        Fecha_cons: "1975-01-01",
        Fecha_fin: "1975-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 2612.70273551,
        Decada: "1970",
        Antiguedad: 49,
        Tp_Amianto: "Probable amianto marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-08-30",
        Prioridad: "Muy Alta",
        centroid_x: 711244.267481,
        centroid_y: 5709261.75408,
        Fecha_ejec: "2024-05-29",
        a_amianto: 2612.70273551,
        Reti_cost: 26477.0273551,
        CuBasic_C: 52254.0547102,
        LiBasic_C: 3149.24328261,
        CuNormal_C: 235143.246196,
        LiNormal_C: 10464.810942000002,
        m3_amianto: 15.676216413059999,
        ratio: 100.0,
        id: 73,
        superficie_cubierta: 2310.0,
        Potencia_instalada_kWp: 300.0,
        n_modulos: 500.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1342,
        "Coste \u20ac/kWp": 0.557,
        Lucernario: "false",
        superficie_fv_percent: 45.5,
        superficie_placas: 1186.6582737848587,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.31999999999998,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87416666666668,
        E_d: 688.85,
        E_m: 20952.63,
        E_y: 251431.55,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 762.08,
        SD_y: 9144.97,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 1903555.5607164337,
        ton_co2_avoid: 491.87875688912646,
        energy_price_saved: 347018.17871860584,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.04294060647663264, 51.49520284082865],
            [0.043123503994033356, 51.4952036587615],
            [0.04331215321527121, 51.495190408522994],
            [0.043496906137467616, 51.495185610281354],
            [0.04370085884458264, 51.49516778639864],
            [0.0436374607422137, 51.494718473227074],
            [0.043449554491009144, 51.49474283952466],
            [0.04325562185652358, 51.49474370055205],
            [0.04308737813275892, 51.49476198822395],
            [0.042886304644246774, 51.494756074819826],
            [0.04294060647663264, 51.49520284082865],
          ],
        ],
      },
    },
    {
      id: "120",
      type: "Feature",
      properties: {
        Fecha_cons: "1990-01-01",
        Fecha_fin: "2002-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 707.5528641149658,
        Decada: "1990",
        Antiguedad: 34,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 711057.336598,
        centroid_y: 5709196.94745,
        Fecha_ejec: "2024-05-29",
        a_amianto: 707.5528641149658,
        Reti_cost: 7425.52864115,
        CuBasic_C: 14151.0572823,
        LiBasic_C: 863.0634369380001,
        CuNormal_C: 63679.7577703,
        LiNormal_C: 2844.21145646,
        m3_amianto: 4.245317184689794,
        ratio: 100.0,
        id: 74,
        superficie_cubierta: 675.0,
        Potencia_instalada_kWp: 100.0,
        n_modulos: 160.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.147,
        "Coste \u20ac/kWp": 0.583,
        Lucernario: "false",
        superficie_fv_percent: 49.8,
        superficie_placas: 351.73358983381445,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.04036389845697349, 51.494647565086815],
            [0.040524164590144594, 51.49464340683281],
            [0.04050555127514379, 51.49446434106927],
            [0.04096121482122877, 51.49444277435456],
            [0.040941381830310014, 51.494345859480994],
            [0.0402838094749746, 51.494382407702616],
            [0.040298547156904854, 51.49443630750634],
            [0.040296778327731944, 51.49447671706136],
            [0.040343707485437905, 51.49447828318961],
            [0.04036389845697349, 51.494647565086815],
          ],
        ],
      },
    },
    {
      id: "121",
      type: "Feature",
      properties: {
        Fecha_cons: "1988-01-01",
        Fecha_fin: "1988-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 668.030857852274,
        Decada: "1980",
        Antiguedad: 36,
        Tp_Amianto: "Probable amianto blanco",
        Niv_Riesgo: "Alto",
        Calendario: "2028-01-10",
        Prioridad: "Alta",
        centroid_x: 711119.600976,
        centroid_y: 5709191.68637,
        Fecha_ejec: "2024-05-29",
        a_amianto: 668.030857852274,
        Reti_cost: 7030.30857852,
        CuBasic_C: 13360.617157000002,
        LiBasic_C: 815.6370294208001,
        CuNormal_C: 60122.7772067,
        LiNormal_C: 2686.12343141,
        m3_amianto: 4.008185147113644,
        ratio: 100.0,
        id: 75,
        superficie_cubierta: 675.0,
        Potencia_instalada_kWp: 100.0,
        n_modulos: 160.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.147,
        "Coste \u20ac/kWp": 0.583,
        Lucernario: "false",
        superficie_fv_percent: 49.8,
        superficie_placas: 332.08622077447336,
        horizontal_irradiation_year: 2016.0,
        horizontal_irradiation_mean: 87.55583333333334,
        temperature_year: 2016.0,
        temperature_mean: 11.225,
        direct_normal_irradiation_year: 2016.0,
        direct_normal_irradiation_mean: 78.31916666666665,
        optimal_angle_irradiation_year: 2016.0,
        optimal_angle_irradiation_mean: 102.87416666666668,
        E_d: 229.62,
        E_m: 6984.21,
        E_y: 83810.58,
        "H(i)_d": 2.89,
        "H(i)_m": 87.77,
        "H(i)_y": 1053.26,
        SD_m: 254.03,
        SD_y: 3048.33,
        l_aoi: -4.66,
        l_spec: "1.67",
        l_tg: -4.55,
        l_total: -20.43,
        solar_energy_total: 532709.8678344209,
        ton_co2_avoid: 137.65222984841435,
        energy_price_saved: 97113.00890621492,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.04101788043458037, 51.49445661420307],
            [0.04150470626290928, 51.494434236676376],
            [0.0419067620427267, 51.494411273951044],
            [0.04188906040240205, 51.49431291204106],
            [0.04152939015225118, 51.49433616644443],
            [0.04102012127360422, 51.494356342802675],
            [0.04101788043458037, 51.49445661420307],
          ],
        ],
      },
    },
    {
      id: "122",
      type: "Feature",
      properties: {
        Fecha_cons: "1964-01-01",
        Fecha_fin: "1964-01-01",
        Web_Catast: "Informaci\u00f3n no disponible",
        Ref_Catast: "Informaci\u00f3n no disponible",
        Uso: "Industrial",
        Realizado: "Agforest S.L.",
        Unidad: "m2",
        Provincia: "London",
        Municipio: "London",
        Titular: "Privado",
        A_Catast: 1440.89593674,
        Decada: "1960",
        Antiguedad: 60,
        Tp_Amianto: "Probable amianto azul, marron y blanco",
        Niv_Riesgo: "Muy Alto",
        Calendario: "2025-06-01",
        Prioridad: "Muy Alta",
        centroid_x: 711238.790517,
        centroid_y: 5709156.96561,
        Fecha_ejec: "2024-05-29",
        a_amianto: 1440.89593674,
        Reti_cost: 14758.9593674,
        CuBasic_C: 28817.9187348,
        LiBasic_C: 1743.07512409,
        CuNormal_C: 129680.63430699999,
        LiNormal_C: 5777.58374698,
        m3_amianto: 8.64537562044,
        ratio: 100.0,
        id: 103,
        superficie_cubierta: 1344.0,
        Potencia_instalada_kWp: 200.0,
        n_modulos: 322.0,
        potencia_modulos: 620.0,
        ratio_kWp_m2: 0.1485,
        "Coste \u20ac/kWp": 0.569,
        Lucernario: "false",
        superficie_fv_percent: 50.3,
        superficie_placas: 723.4776628401444,
        horizontal_irradiation_year: null,
        horizontal_irradiation_mean: null,
        temperature_year: null,
        temperature_mean: null,
        direct_normal_irradiation_year: null,
        direct_normal_irradiation_mean: null,
        optimal_angle_irradiation_year: null,
        optimal_angle_irradiation_mean: null,
        E_d: null,
        E_m: null,
        E_y: null,
        "H(i)_d": null,
        "H(i)_m": null,
        "H(i)_y": null,
        SD_m: null,
        SD_y: null,
        l_aoi: null,
        l_spec: null,
        l_tg: null,
        l_total: null,
        solar_energy_total: null,
        ton_co2_avoid: null,
        energy_price_saved: null,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.04267129304134027, 51.49415014160002],
            [0.043648933368816506, 51.49409873021865],
            [0.043642237763156795, 51.493998282689546],
            [0.043627796170043885, 51.49390671061019],
            [0.04263686485228509, 51.49396714121424],
            [0.04264911181623447, 51.49402580819175],
            [0.04267129304134027, 51.49415014160002],
          ],
        ],
      },
    },
  ],
};
export default data;
